import React, {useEffect, useState} from "react";
import Modal from "../../Modal/Modal";
import myaxios from "../../Axios";
import Notif from "../../Notif";
import Item from "../../../containers/mail/EmailList/Item";
import DragAndDrop from "./DragAndDrop";

type Props = {isOpen: boolean,close:any, allowed_file_extensions: string}
interface Item {id:number,name:string,type:string}

const Cloud: React.FC<Props> = ({ isOpen, close, allowed_file_extensions }) => {
    const [list, setList] = useState<Item[]>([])
    const [loading, setLoading] = useState<boolean>(true)

    const getData = () => {
        myaxios.get(`/ajax/profile/files?sort=-created_at`).then(res => {
            if(res.status === 200 || res.status === 201){
                setList(res.data.items)
            }
            setLoading(false)
        }).catch(() => setLoading(false))
    }

    const handleSelectedFile = (event:any) => {
        setLoading(true)
        const data = new FormData();
        data.append('UploadForm[file]', event.target.files[0], event.target.files[0].name);

        myaxios.post(`/ajax/file/upload?related_type=34`, data).then(res => {
            if(res.status === 200 || res.status === 201){
                if(res.data.status === "uploaded"){
                    getData();
                    Notif("save", "Файл успешно загружен!");
                } else {
                    if(!res.data.status){
                        if(res.data.errors && res.data.errors.file && res.data.errors.file[0]){
                            Notif("Error", res.data.errors.file[0])
                        }
                    }
                }
            }
            setLoading(false)
        }).catch(() => setLoading(false))
    };

    const deleteFile = (id:string | number) => {
        setLoading(true)
        myaxios.delete(`/ajax/file/delete/${id}`).then(res => {
            if(res.status === 200 || res.status === 201 || res.status === 204){
                getData()
                Notif("delete")
            }
        }).catch(() => setLoading(false))
    }

    useEffect(() => {
        if(isOpen) getData()
    }, [isOpen])

    const handleFiles = (ev:any) => {
        ev.preventDefault();
        ev.stopPropagation()
    }

    const handleDrop = (files:any) => {
        console.log("file", files)
        /*let fileList = state.files
        for (var i = 0; i < files.length; i++) {
            if (!files[i].name) return
            fileList.push(files[i].name)
        }
        this.setState({files: fileList})*/
    }
    return (
        <Modal title="Облако" isOpen={isOpen} close={close} save={close} loading={loading}>



            {/*<DragAndDrop handleDrop={handleDrop}>
                <div style={{height: 300, width: 600, background: "#f5f5f5"}}>

                </div>
            </DragAndDrop>*/}










            <label
                data-tooltip="Отправить файл"
                htmlFor="file_message_upload" className="cloud__update-button">
                <span>Загрузить файл</span>
                <input
                    name="UploadForm[file]"
                    onChange={e => handleSelectedFile(e)}
                    id="file_message_upload" type="file"
                    style={{ opacity: 0, width: "0", height: "0", overflow: "hidden" }}
                    //accept={allowed_file_extensions && allowed_file_extensions.split().map(item =>  ( "." + item ) )}
                />
            </label>
            <div className="contact-page sp-table">
                <table>
                    <thead>
                        <tr className="fst-tr">
                            <td>ID</td>
                            <td>Название</td>
                            <td>Тип</td>
                            <td />
                        </tr>
                    </thead>
                    <tbody>
                    {
                        list.map(item => (<tr key={item.id} className="pull-up">
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td>{item.type}</td>
                                <td>
                                    <div className="toogle-bottom fl-r"><a className="is" ><i className="flaticon-more" /></a>
                                        <ul className="toogle-list">
                                            <li><a><b>Скачать</b></a></li>
                                            <li><a onClick={() => deleteFile(item.id)} className="table-del"><b>Удалить</b></a></li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>
        </Modal>
    )
}

export default Cloud



