import {
    createProductService, deleteProductService, getlinkedPriceBooks,
    getProductCategoryService, getProductListService,
    getSingleProductService,
    updateProductService
} from "../../../services/product/product";

export const createProductTest = async (JSON) => {
    try {
        const res = await createProductService(JSON)
        if (res && res.status === 201) {
            return {status: true, product_id: res.data.id}
        } else {
            console.error('product status error')
            return {status: false}
        }
    } catch (e) {
        console.error('createProduct error', e)
        return { status: false }
    }
}

export const updateProductTest = async (product_id, JSON) => {
    try {
        const res = await updateProductService(product_id, JSON)

        if (res && res.status === 200) {
            return { status: true }
        } else {
            console.error('product status error')
            return { status: false }
        }
    } catch (e) {
        console.error('updateProduct error', e)
        return { status: false }
    }
}

export const getProductCategoryTest = async () => {
    try {
        const res = await getProductCategoryService()

        if (res && res.status === 200) {
            return { status: true, data: res.data.items }
        } else {
            console.error('product category get status error')
            return { status: false }
        }
    } catch (e) {
        console.error('product category get catch error', e)
        return { status: false }
    }
}

export const getSingleProductTest = async (id) => {
    try {
        const res = await getSingleProductService(id)

        if (res && res.status === 200) {
            return { status: true, data: res.data }
        } else {
            console.error('single product status error')
            return { status: false }
        }
    } catch (e) {
        console.error('single product catch error', e)
        return { status: false }
    }
}

export const getProductListTest = async (sort, expand, filter) => {
    try {
        const res = await getProductListService(sort, expand, filter)

        if (res && res.status === 200) {
            return { status: true, data: res.data.items }
        } else {
            console.error('product list status error')
            return { status: false }
        }
    } catch (e) {
        console.error('product list catch error', e)
        return { status: false }
    }
}

export const getLinkedPriceBooksTest = async (product_id) => {
    try {
        const res = await getlinkedPriceBooks({ product_id })

        if (res && res.status === 200) {
            return { status: true, data: res.data.items }
        } else {
            console.error('product list status error')
            return { status: false }
        }
    } catch (e) {
        console.error('product list catch error', e)
        return { status: false }
    }
}

export const deleteProductTest = async (id) => {
    try {
        const res = await deleteProductService(id)

        if (res && res.status === 200) {
            return { status: true }
        } else {
            console.error('delete single product status error')
            return { status: false }
        }
    } catch (e) {
        console.error('delete single product catch error', e)
        return { status: false }
    }
}