import React from 'react';
import myaxios from '../../components/Axios';
import Notif from '../Notif';
import Loader from '../../components/UI/Loader';
import DropzoneComponent from 'react-dropzone-component';
import '../../../node_modules/dropzone/dist/min/dropzone.min.css';
import {GlobalState} from '../../Layout/Layout';
var ReactDOMServer = require('react-dom/server');


const endpoint = '/ajax/file/upload';

class FileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.dddd = this.dddd.bind(this);
        this.state = {
            selectedFile: null,
            loaded: 0,
            loading: false,
            componentConfig: {
                iconFiletypes: ['.jpg', '.png', '.gif', '.psd', '.rar', '.xlsx'],
                showFiletypeIcon: true,
                postUrl: `/ajax/file/upload?related_type=${this.props.relatedType}${this.props.related_id ? "&related_id=" + this.props.related_id : ""}`
            },
            // &related_id=${this.props.related_id}
            //eventHandlers: { addedfile: (file) => { if(file.status === "added"){ this.props.upd() } } }
        }
    }
    handleselectedFile = event => {
        this.setState({
            selectedFile: event.target.files[0],
            loaded: 0,
        })
    };

    toProps = (data) => {
        this.props.add("add",null,data)
    }

    handleUpload = () => {
        alert('123')
        return;
        if(this.state.selectedFile !== null) {
            this.setState({ loading: true });
            const data = new FormData();
            console.log('this.state.selectedFile', this.state.selectedFile)
            data.append('UploadForm[file]', this.state.selectedFile, this.state.selectedFile.name);

            let postString = `?related_type=${this.props.relatedType}`;
            if(this.props.related_id){
                postString += `&related_id=${this.props.related_id}`
            }
            myaxios.post(endpoint + postString, data, {
                onUploadProgress: ProgressEvent => {
                    this.setState({loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100, selectedFile: null
                    })}}).then(res => {
                        if(res.status === 200) {
                            this.toProps()
                            if(res.data.status === "uploaded"){
                                setTimeout(this.props.add("add",null,res.data), 200);
                                this.setState({ loading: false });
                            }
                            else {
                                Notif("Error", res.data.errors.file[0] || "Ошибка загрузки");
                                this.setState({ loading: false });
                            }
                        }
            }).catch((error) => { this.setState({ loading: false });  })}};

    dddd = () => {
        alert();
    }
    render() {
        return this.state.loading ? <Loader contain /> : (
            <div className="previewComponent">
                <GlobalState.Consumer>
                    {context => (
                        <DropzoneComponent config={this.state.componentConfig}
                                           djsConfig={
                                               {
                                                   paramName: 'UploadForm[file]',
                                                   dictDefaultMessage: "Перетащите файлы сюда",
                                                   dictFileTooBig: `Размер файла {{filesize}}Мб выше допустимого {{maxFilesize}}Мб`,
                                                   dictInvalidFileType: "Недопустимый тип файла",
                                                   dictUploadCanceled: "Вы отменили загрузку",
                                                   acceptedFiles: parseStr(context.state.allowed_file_extensions),
                                                   maxFilesize: (context.state.allowed_file_max_size / 1024)  / 1024,
                                                   init: function(){
                                                       this.on("removedfile", function(file) {
                                                           if(file.xhr){
                                                               let a = JSON.parse(file.xhr.response);
                                                               myaxios.delete(`/ajax/file/delete/${a.id}`).then(res => {
                                                                   if(res){
                                                                       if(res.status === 200 || res.status === 201 || res.status === 204){
                                                                           Notif("delete", "Файл успешно удален!")
                                                                       }
                                                                   }
                                                               })
                                                           }
                                                       })
                                                   },
                                                   success: (e) => this.toProps(e.xhr.response),
                                                   addRemoveLinks: false,
                                                   /*previewTemplate: ReactDOMServer.renderToStaticMarkup(
                                                       <div className="dz-preview dz-file-preview">
                                                           <div className="dz-details">
                                                               <div className="dz-filename"><span data-dz-name="true"></span></div>
                                                               <img data-dz-thumbnail="true" />
                                                           </div>
                                                           <div className="dz-progress"><span className="dz-upload" data-dz-uploadprogress="true"></span></div>
                                                           <div className="dz-success-mark"><span>✔</span></div>
                                                           <div className="dz-error-mark"><span>✘</span></div>
                                                           <div className="dz-error-message"><span data-dz-errormessage="true"></span></div>
                                                       </div>
                                                   )*/
                                               }
                                           }
                        eventHandlers={this.state.eventHandlers}
                        /* djsConfig={djsConfig}*/ />
                    )}
                </GlobalState.Consumer>


                {/*<input className="btn-m" type="file" name="UploadForm[file]" id="" onChange={this.handleselectedFile} />
                <button className="btn-m ml10" onClick={this.handleUpload}>Загрузить</button>
                <em style={{fontSize: "12px"}}> {(this.state.loaded > 1) ? `${Math.round(this.state.loaded, 2)}%` : null} </em>*/}
            </div>
        )
    }
}

function parseStr(string) {
    let a = string.split(", ");
    return "." + a.join(", .")
}

export default FileUpload;