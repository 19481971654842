import React, {useContext, useEffect, useRef, useState} from "react";
import myaxios from "../Axios";
import ProductItem from "./ProductItem";
import Notif from "../Notif";
import AutoSelect from "../UI/AutoSelect";
import Loader from "../UI/Loader";
import WrapModal from "../../Modal/WrapModal";
import Bgdrop from "../../Modal/Bgdrop";
import {GlobalState} from "../../Layout/Layout";
import CustomCheckbox from "../UI/CustomCheckbox";
import {truncator} from '../fieldGen'
import FastActions from "./FactActions";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import InfiniteScroll from "react-infinite-scroll-component";
import barcodeIcon from '../../img/barcode_icon.jpg'

const Products = ({
    type,
    id,
    related,
    isStatus,
    isTotal,
    noMassDo,
    updatePrice = () => {},
    disabledTabStyle,
    isDiscount,
    isPrice,
    isValues,
    isExport,
    allProducts = () => {},
    isComment = false,
    isDate = false,
    children = () => {},
    disable = false
}) => {
    const [products, setProducts] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [open, setOpen] = useState("");
    const [loading, setLoading] = useState(true);
    const [totalPrice, setTotalPrice] = useState(0)
    const [massDo, setMassDo] = useState(false)
    const [wrapCheckbox, setWrapCheckbox] = useState(false)
    const [page, setPage] = useState(1)
    const [totalCount, setTotalCount] = useState(0)
    const [totalProductCount, setTotalProductCount] = useState(0)

    const [fastActionType, setFastActionType] = useState("");
    const [checkProducts, setCheckProducts] = useState([]);

    const gstate = useContext(GlobalState);
    const currencyCode = gstate.state.baseCurrency && gstate.state.baseCurrency.text_symbol;

    useEffect(() => {
        updatePrice(totalPrice)
        allProducts(totalPrice)
    }, [totalPrice])

    const getProducts = () => {
        myaxios.get(`/ajax/${type}/${id}/products?per-page=${page * 10}&page=${page}&expand=product`).then(res => {
        // myaxios.get(`/ajax/${type}/${id}/products?per-page=100&expand=product`).then(res => {
            setLoading(false)
            if(!res) return;
            if(res.status === 200 || res.data === 201){
                setProducts(res.data.items)
                setTotalCount(res.data._meta.pageCount)
                setTotalPrice(+res.data.total)
                setTotalProductCount(res.data._meta.totalCount)
            }
        }).catch(error => setLoading(false))
    };

    const showMore = () => {
        if(page < totalCount) {
            myaxios.get(`/ajax/${type}/${id}/products?per-page=10&page=${page + 1}&expand=product`).then(res => {
                if(!res) return;
                setProducts([...products, ...res.data.items])
                setPage(prevState => (prevState + 1))
            })
        }
    }

    const getStatuses = () => {
        myaxios.get(`/ajax/lookup/search?type=ord_item_status`).then(res => {setStatuses(res.data.items)})
    }

    const saveProduct = (item_id, index) => {
        if(disable) {
            Notif('Error', 'Действия с продуктами запрещены')
            return false;
        }
        let obj = {
            price: products[index].price,
            discount: products[index].discount,
            discount_amount: products[index].discount_amount,
            quantity: products[index].quantity,
        };
        if(isStatus) obj.status_id = products[index].order_item_status;
        if(isComment) obj.comment = products[index].comment;
        if(isDate) obj.date = products[index].date;
        if(isTotal) {
            obj.total = products[index].total;
            obj.is_total_modified = products[index].is_total_modified ? 1 : 0
        }
        if(isValues) obj.values = products[index].values

        myaxios.post(`/ajax/${type}/${id}/edit-product-item/${item_id}`, obj).then((res) => {
            if(!res) return;
            if(res.status === 200 || res.status === 201){
                Notif("save");
                products[index] = res.data.item;
                setProducts([...products])
                setTotalPrice(+res.data.total)
                //setTimeout(this.recountSum)
            }
        }).catch((error) => {
            Notif("Error", error)
        })
    }

    const inputRef = useRef(null)
    const [isBarCodeScan, setIsBarCodeScan] = useState(false)

    const updateData = (prod_id, arr) =>{
        if(disable) {
            Notif('Error', 'Действия с продуктами запрещены')
            return false
        }
        myaxios.post(`/ajax/${type}/${id}/link-product/${prod_id}`).then((response) => {
            if(response.status === 200){
                Notif("save", "Продукт успешно привязан");
                setTotalProductCount(prevState => prevState + 1)
                getProducts()
                // setProducts([])
                if(isBarCodeScan) {
                    setTimeout(() => inputRef.current.focus(), 500)
                }
            }
        })
    };

    const deleteProduct = (item_id, index) => {
        if(disable) {
            Notif('Error', 'Действия с продуктами запрещены')
            return false
        }
        if(window.confirm("Удалить элемент?")) {
            myaxios.post(`/ajax/${type}/${id}/unlink-product/${item_id}`, {
                id: id,
            }).then((response) => {
                if(!response) return;
                if((response.status === 200 || response.status === 204) && response.data.id){
                    products.splice(index, 1)
                    setProducts([...products])
                    setTotalPrice(+response.data.total)
                    setTotalProductCount(prevState => prevState - 1)
                    Notif("delete");
                }
            })
        }
    };

    const setItem = (e, type, index) => {
        products[index][type] = e;
        setProducts([...products])
    }

    useEffect(() => {
        getProducts();
        getStatuses();
    }, [id]);

    const createLinkProduct = (id) => {
        updateData(id)
        setOpen('')
    }

    useEffect(() => {
        let arr = products.filter(item => item.checked).length;
        if(arr === 0) setWrapCheckbox(false)
        else if(products.length === arr) setWrapCheckbox(true)
    }, [products])

    const checkAll = () => {
        products.map(item => {
            item.checked = !wrapCheckbox
        })
        setProducts([...products])
        setWrapCheckbox(!wrapCheckbox)
    }

    const openFactActions = (type) => {
        let arr = []
        products.map(item => {
            if(item.checked) arr.push(item.id)
        })
        setCheckProducts(arr)
        if(arr.length > 0) setFastActionType(type)
        else Notif("Error", "Выберите как минимум 1 продукт")

    }

    const saveActiveProducts = () => {
        products.map((item, index) => {
            if(item.active){
                saveProduct(item.id, index)
            }
        })
    }

    const barCodeScanResult = async () => {
        const { status, data } = await myaxios.get(`/ajax/product?filter[sku][like]=${inputRef.current.value}`)
        if(data.items.length > 0) {
            updateData(data.items[0].id)
        } else {
            Notif('Error', 'Продукта с таким штрих-кодом не существует')
            inputRef.current.value = ''
            setTimeout(() => inputRef.current.focus(), 500)

        }
    }

    const BarCode = () => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {isBarCodeScan && <span
                    style={{ fontSize: '13px', color: '#525252', marginRight: '5px', fontWeight: 'bold', textTransform: 'uppercase' }}>{'Сканирование'}</span>}
                <img onClick={() => {
                    setIsBarCodeScan(true)
                    setTimeout(() => inputRef.current.focus(), 500, true)
                }} style={{ height: '34px', marginRight: '10px', cursor: 'pointer' }} src={barcodeIcon} alt=""/>
                <input
                    ref={inputRef}
                    style={{ width: 0, height: 0, opacity: 0 }}
                    //value={barCodeRef && barCodeRef.current && barCodeRef.current.value}
                    onChange={e => inputRef.current.value = e.target.value}
                    onBlur={() => setIsBarCodeScan(false)}
                    onKeyDown={e => {
                        if(e.keyCode === 13) barCodeScanResult().then()
                    }}
                />
            </div>
        )
    }

    return <div>{loading && <Loader contain />}
    <CSSTransition in={!loading} timeout={200} classNames="tabs" unmountOnExit>
        <div className={`product-table-mobile ${!disabledTabStyle && 'tab-content'} sbform`}>
        <div className="productItemsHeader pull-up" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {!disable && <div className="AutoSelectParent__no-label serch-product">
                <AutoSelect
                    placeholder="Поиск по продуктам"
                    link={`/ajax/product/search?name=`}
                    minLength="1"
                    result={"name"}
                    event="name"
                    setField={() => {}}
                    updateData={updateData}
                    //value={search}
                    id={id}
                    createData={() => setOpen("r-open")}
                    creatable
                />
            </div>}
            <div style={{ display: 'flex' }}>
                <BarCode />
                {!noMassDo && <div className="toogle-bottom fl-r">
                    <a onClick={() => setMassDo(!massDo)} className="is"><i className="flaticon-more" /></a>
                    {massDo && <ul className="toogle-list">
                        <li><a onClick={() => openFactActions("price")}><b>Изменить прайс</b></a></li>
                        {/*<li><a onClick={() => openFactActions("stock")}><b>Изменить склад</b></a></li>*/}
                        {isStatus && <li><a onClick={() => openFactActions("status")}><b>Изменить статус</b></a></li>}
                        <li><a onClick={() => openFactActions("discount")}><b>Изменить скидку</b></a></li>
                    </ul>}
                </div>}
                {
                    children({
                        getProducts
                    })
                }
                {isExport && <a data-tooltip="Экспорт"
                                href={`https://${window.location.host.split('.')[0]}.sboxcrm.com/ajax/${type}/${id}/products?export=true`}
                                className="is"><i className="flaticon-folder-2" /></a>}
                <CSSTransition in={!disable && products.filter(item => item.active)[0]} timeout={200} classNames="my-node" unmountOnExit>
                    {!disable && <a onClick={saveActiveProducts} className="productItemsSaveAll btn-m fl-r">Сохранить все</a>}
                </CSSTransition>
            </div>
        </div>

        {/*<Myselect
            style={{ width: "250px", marginLeft: "0px", float: 'left' }}
            options={[

            ]}
            onChange={this.searchType}
            value={this.state.searchType}
        />*/}
        <div className="clear" />
        <table className="newProduct">
            <tr>
                {massDo && <th style={{
                    background: "#fff",
                    border: "none",
                    width: "20px"
                }} >
                    <CustomCheckbox
                        checked={wrapCheckbox}
                        onChange={checkAll}
                    />
                </th>}
                <th width={15} />
                <th />
                <th>Продукт ({totalProductCount})</th>
                <th>Свойства</th>
                {isStatus && <th>Статус</th>}
                <th>К-во</th>
                {isPrice && <th>{`Цена (${currencyCode})`}</th>}
                {isDiscount && <th>Скидка</th>}
                {isPrice && <th>{`Итого (${currencyCode})`}</th>}
                {isDate && <th>{`Дата`}</th>}
                {isComment && <th>{`Описание`}</th>}
                <th />
            </tr>
            <TransitionGroup className="products-contents transition-group-screen">
                <InfiniteScroll
                    dataLength={products.length}
                    next={showMore}
                    hasMore={true}
                    style={{ display: 'contents' }}
                    loader={(page < totalCount) ? <p style={{textAlign: "center"}}>Загрузка</p> : null}
                >
            {
                products.map((item, index) => (
                    <CSSTransition
                        key={index}
                        timeout={{ enter: 200, exit: 200 }}
                        classNames="slide"
                    >
                    <ProductItem
                        isDiscount={isDiscount}
                        isPrice={isPrice}
                        isValues={isValues}
                        truncator={truncator}
                        key={item.id}
                        index={index}
                        item={item}
                        setItem={setItem}
                        save={saveProduct}
                        isStatus={isStatus}
                        isTotal={isTotal}
                        deleteProduct={deleteProduct}
                        statuses={statuses}
                        currencyCode={currencyCode}
                        massDo={massDo}
                        isComment={isComment}
                        isDate={isDate}
                        onChecked={() => {
                            products[index].checked = !products[index].checked; setProducts([...products])
                        }}
                        setActive={(index, value) => {
                            products[index].active = value; setProducts([...products]);
                         }}
                        disable={disable}
                    />
                    </CSSTransition>
                ))
            }
                </InfiniteScroll>
            </TransitionGroup>
        </table>
        {isPrice && <div className="productItemsTotalPrice">
            <em>Итого:</em>
            <b>{totalPrice.toFixed(2)} {currencyCode}</b>
        </div>}

        <React.Fragment>
            <div className={"right-modal scrollbar " + open}><div>
                {(open === "r-open") ? <WrapModal name="Product"  close={() => setOpen("")} saveProduct={createLinkProduct} /> : null}
            </div></div>
            {open ? <Bgdrop close={() => setOpen("")} /> : null}
        </React.Fragment>
        <FastActions
            type={fastActionType}
            setType={() => {
                setFastActionType(null);
                //setMassDo(false)
            }}
            products={checkProducts}
            entity={type}
            entityId={id}
            updateProducts={() => getProducts()}
        />
    </div>
    </CSSTransition></div>
}

export default Products