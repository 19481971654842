import React, {Component} from 'react';
import AutoSelect from '../../UI/AutoSelect';
import Input from '../../UI/Input';
import Myselect from '../../UI/Select';
import Notif from '../../Notif';



export default class EditTags extends Component {
    state = {
        formControl:{
            name: {
                value:''
            },
            time_value:{value:0},
            to: {
                value: [],
                selectvalid: true,
                arr: [],
            },
            type:{value:'create'}
        },
        tags:[],
        id:null
    };
    getInfo = () =>{

    }
    componentWillMount(){
        if(this.props.elArr){
            let formControl = this.state.formControl;
            formControl.name.value = this.props.elArr.metaData ? this.props.elArr.name : '';
            formControl.to.arr = this.props.elArr.metaData ? this.props.elArr.metaData.value : [];
            formControl.time_value.value = this.props.elArr.metaData ? this.props.elArr.time_value : '';
            this.setState({ formControl, id: this.props.elArr.id || null })
        }
    }
    setField = (type, e) => {
        let formControl = this.state.formControl;
        formControl[type].value = e;
        this.setState({ formControl })
    };
    updateDataTo = (id, arr) => {
        if(arr.name === ''){
            return false;
        }
        const formControl = this.state.formControl;
        let tag = true;
        for(let i = 0; i <= this.state.formControl.to.arr.length; i++){
            if(arr.name) {
                if(this.state.formControl.to.arr[i] === arr.name){
                    tag = false;
                }
            } else {
                if(this.state.formControl.to.arr[i] === arr){
                    tag = false;
                }
            }
        }
        if(tag){
            (arr.name) ? formControl.to.arr.push(arr.name) : formControl.to.arr.push(arr)

        } else if(!tag) {
            Notif("Error", "Тег уже добавлен")
        }
        formControl.to.value = '';
        this.setState({ formControl, val: '' })
    };
    deleteItem = id => {
        const formControl = this.state.formControl;
        formControl.to.arr.splice(id, 1);
        this.setState({ formControl })
    };
    sendForm = () => {
        if(this.state.formControl.to.arr.length >= 1){
            if(this.props.elArr){
                setTimeout(
                    this.props.updateNode({
                        id:this.state.id,
                        name: this.state.formControl.to.arr.join(','),
                        time_value: this.state.formControl.time_value.value,
                        is_scheduled: (this.state.formControl.time_value.value > 1),
                        metaData: {
                            type:this.state.formControl.type.value,
                            value:this.state.formControl.to.arr,
                        },
                        nodeType: "node",
                        type:"tags",
                    })
                )
            } else {
                setTimeout(
                    this.props.createNode({
                        name: this.state.formControl.to.arr.join(','),
                        time_value: this.state.formControl.time_value.value,
                        is_scheduled: (this.state.formControl.time_value.value > 1),
                        metaData: {
                            type:this.state.formControl.type.value,
                            value:this.state.formControl.to.arr,
                        },
                        nodeType: "node",
                        type:"tags",
                    })
                )
            }
        } else {
            Notif("Error", "Добавьте как минимум 1 тег")
        }
    };
    render(){
        return <div>
            <div className="rmod-header">
                <b>Редактировать теги</b>
                <a onClick={this.props.close} style={{ marginRight: "60px" }} className="mclose"><i className="flaticon-close" /></a>
                <div className="togglelist"><button onClick={this.sendForm} className="rmod-ok" disabled=""><i className="flaticon-interface-1" /></button></div>
            </div>
            <div className="mod-cont sbform">
                <Myselect
                    label="Действие"
                    value={this.state.formControl.type.value}
                    onChange={e => this.setField("type", e.target.value)}
                    options={[
                        {value:'create', label:'Добавить'},
                        {value:'delete', label:'Удалить'},
                    ]}
                />
                <label htmlFor="">Редактировать теги</label>
                <AutoSelect
                    label={"Кому"}
                    link={`/ajax/${this.props.entity}/suggest?term=`}
                    minLength="0"
                    result="name"
                    event="to"
                    //errorMessage={"Введите корректный Email"}
                    value={this.state.formControl.to.value}
                    shouldValidate={true}
                    setField={event => this.setField(event, "to")}
                    valid={true}
                    updateData={this.updateDataTo}
                    deleteItem={this.deleteItem}
                    createTag
                    multiSelect={this.state.formControl.to.arr}
                    multiSelectValid={true}
                    itemType={`item`}
                />
                <Input
                    tooltip="Процесс запустится через N секунд, после того, как сработает триггер"
                    type="number"
                    value={this.state.formControl.time_value.value}
                    onChange={e => this.setField("time_value", e.target.value)}
                    shouldValidate={false}
                    label="Отложенный запуск на N секунд"
                />
            </div>
        </div>
    }
}
