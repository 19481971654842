import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

const Documents = props => {
    {
        return <div className="sp-table body-table">
            <table style={{borderSpacing: "0"}} className="stagesTable">
                <thead>
                {
                    (props.item && props.item.length >= 1) ? <tr>
                        <td>Название</td>
                        <td>Статус</td>
                        <td>Тип</td>
                        <td style={{width: "30px"}}/>
                    </tr> : null
                }

                </thead>
                <tbody>
                {
                    props.item ? props.item.map((item,index)=>
                        <tr key={index}>
                            <td><a onClick={() => props.history.push('/document/' + item.id)}>{item.name}</a></td>
                            <td>{(item.status) ? item.status.value : null}</td>
                            <td>{(item.type) ? item.type.value : null}</td>
                            <td>
                                <div className="toogle-bottom fl-r">
                                    <a className="is" href="#"><i className="flaticon-more" /></a>
                                    <ul className="toogle-list">
                                        <li><a onClick={() => props.history.push('/document/' + item.id)}><i className="flaticon-edit-1" /> <b>Редактировать</b></a></li>
                                        <li><a onClick={()=>props.deleteObject("document", item.id)} className="table-del"><i className="flaticon-delete-1" /> <b>Удалить</b></a></li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    ) : null
                }
                </tbody>
            </table>
        </div>
    }
}

export default withRouter(Documents);