import React from 'react';
import myaxios from '../../components/Axios';
import Related from '../../components/related/related';
import formValid from '../../components/UI/formValid';
import WrapForm from './WrapForm';
import Information from './tab/information';
import Files from '../../components/files/files'
import History from '../../components/history/history';
import Notif from '../../components/Notif';
import Loader from '../../components/UI/Loader';
import AddTask from '../../Modal/AddTask';
import Task from '../../components/Task';
import Team from '../../Modal/Team';
import Bgdrop from '../../Modal/Bgdrop';
import Address from '../../Modal/Address';
import Requisites from '../../Modal/Requisites';
import Attached from './attached/attached';
import EditTags from '../../Modal/EditTags';
import EndBlock from '../../components/EndBlock';
import { th } from 'date-fns/esm/locale';
import GetPostAditionalFields from '../../components/get_post_aditionalFields';
import SearchTag from '../../components/searchTag';
import NewTask from "../../Modal/AddTask";
import {CSSTransition} from "react-transition-group";
import LeaveModal from "../../components/other/LeaveModal";
import IconButton from "../../components/other/IconButton";
import Input from "../../components/UI/Input";
import noImageIcon from '../../fonts/icons/noimage.svg'
import closeIcon from '../../fonts/icons/cancel.svg'
import UploadAvatar from "../../components/other/UploadAvatar";
import Info from "../lead/tab/info/info";

export default class AccountView extends React.Component {
    render() {
        return (
            <div>

            </div>
        )
    }
}

class AccountView1 extends React.Component {
    state = {
        layout: 'info',
        loading: true,
        inputValue: '',
        formControl: {
            name: {value: '', valid: true, validation: {required: true,minLength: 3}},
            type: {
                value: '',
                options: []
            },
            personal_discount: {
                value:0
            },
            industry: {
                value: ''
            },
            owner_id: {
                value: '',
                valid:true,
                arr:null,
                options: [],
                validation: {required: true}
            },
            wrapTel: {
                value: '',
                valid: true,
                t: 'tel',
                validation: {required: false}
            },
            dopTel: {
                value: '',
                valid: true,
                t: 'tel',
                validation: {required: false}
            },
            email: {
                value: '',
                valid: true,
                t: 'email',
                validation: {required: false, email: true}
            },
            website: {
                value: '',
                valid: true,
                t: 'url',
                validation: {required: false, url: true}
            },
            employees: {
                value: '',
                options: [
                    {id: "1", label: "Частный предприниматель"},
                    {id: "2", label: "2-4"},
                    {id: "3", label: "5-10"},
                    {id: "4", label: "11-20"},
                    {id: "5", label: "21-50"},
                    {id: "6", label: "51-100"},
                    {id: "7", label: "101-200"},
                    {id: "8", label: "201-500"},
                    {id: "9", label: "501-1000"},
                    {id: "10", label: "Больше 1000"},
                ]
            },
            turnover: {
                value: '',
                options: [
                    {id: "1", label: "до 1 млн"},
                    {id: "2", label: "1-5 млн"},
                    {id: "3", label: "5-15 млн"},
                ]
            },
            comment: {value: '',},
            address: {
                value: '',
                options: []
            },
            requisites: {
                value: '',
                options: []
            }
        },
        teamId: null,
        contactInfo:[],
        additionalFields:[],
        isFormValid: true,
        selectTag:[],
        tch: true,
        contact: null,
        contact_id:null,
        tasks: [],
        files: [],
        adresses: [],
        endTask: false,
        taskId: null,
        taskIndex: Number,
        open: '',
        openType: '',
        addressId: null,
        owner:null,
        owner_id:null,
        editorTags:'',
        is_deleted:0,
        img:null,
        invoicesDiff:{payment: 0, cost: 0},
        created_at:'',
        updated_at:'',
        prevDesc: '',
        subscriber:null
    };
    getTeam = () =>{
        myaxios.get(`/ajax/account/${this.props.match.params.id}/team?expand=user&per-page=100`, {
            method: 'get',
        }).then((response) => {
            this.setState({team: response.data.items});
        })
    };
    deleteTeam = id => {
        if(window.confirm()){
            myaxios.post(`/ajax/account/${this.props.match.params.id}/unlink-member/${id}`).then(responce => {
                if (responce.status === 200 || responce.status === 201 || responce.status === 204) {
                    this.getTeam();
                    Notif("save")
                }
            })
        }
    };
    showAddress = () => {
        myaxios.get(`/ajax/account/${this.props.match.params.id}/addresses?per-page=100`, {
            method: 'get',
        }).then((response) => {
            const formControl = this.state.formControl;
            formControl.address.options = response.data.items;
            this.setState({ formControl })
        })
    }
    showRequisites = () => {
        myaxios.get(`/ajax/account/${this.props.match.params.id}/payment-details?per-page=100`, {
            method: 'get',
        }).then((response) => {
            const formControl = this.state.formControl;
            formControl.requisites.options = response.data.items;
            this.setState({ formControl })
        })
    }

    subscribeEmail = email => {
        if(formValid(this.state.formControl.email.value, {email: true, required: true})){
            if(this.state.subscriber){
                myaxios.post(`/ajax/mail/broadcast/subscriber/${this.state.subscriber.id}/send-accept-mail/1`).then(res => {
                    if(res.status === 200 || res.status === 201) Notif("save", "Письмо с подтверждением отправлено!")
                })
            } else {
                myaxios.post(`/ajax/mail/broadcast/subscriber?send_accept_mail=true`, {
                    name: this.state.formControl.name.value,
                    email: this.state.formControl.email.value,
                    account_id: this.props.match.params.id
                }).then(res => {
                    if(res.status === 201) Notif("save", "Письмо с подтверждением отправлено!")
                })
            }
        } else Notif("Error", "Невалидный Email")
    }

    showContact = () => {
        myaxios.get(`/ajax/account/${this.props.match.params.id}?expand=invoicesDiff,contacts,tasks,files,stages,contact,types,contact,addresses,owner,contactFields,additionalFields,segments,rfmSegments`, {
            method: 'get',
        }).then((response) => {
            let formControl = this.state.formControl;
            formControl.name.value = response.data.name || '';
            formControl.wrapTel.value = response.data.phone || '';
            formControl.dopTel.value = response.data.number || '';
            formControl.email.value = response.data.email || '';
            formControl.website.value = response.data.website || '';
            formControl.type.value = response.data.type_id || "";
            formControl.type.options = response.data.types;
            formControl.owner_id.value = response.data.owner_id || "";
            formControl.owner_id.arr = response.data.owner;
            formControl.comment.value = response.data.description || '';
            formControl.industry.value = response.data.industry || '';
            formControl.personal_discount.value = response.data.personal_discount || 0;
            this.setState({
                formControl,
                tasks: response.data.tasks,
                loading: false,
                files: response.data.files,
                contact: response.data.contact,
                contact_id: response.data.main_contact_id,
                editorTags: response.data.editorTags || '',
                is_deleted: response.data.is_deleted,
                img: response.data.photo,
                additionalFields: GetPostAditionalFields("get", response.data.additionalFields) || [],
                contactInfo: GetPostAditionalFields("get", response.data.contactFields) || [],
                invoicesDiff: response.data.invoicesDiff,
                created_at: response.data.created_at,
                updated_at: response.data.updated_at,
                rfmSegments: response.data.rfmSegments || [],
                segments: response.data.segments || [],
                prevDesc: response.data.comment,
                subscriber: response.data.subscriber
            });
        }).catch(()=>{this.setState({ loading: false })});
    };
    isFormValid = () => {
        let formControl = this.state.formControl;
        let additionalFields = this.state.additionalFields;
        let contactInfo = this.state.contactInfo;
        let isFormValid = true;
        Object.keys(contactInfo).map((key, index) => {
            contactInfo[key].valid = formValid(contactInfo[key].value, contactInfo[key].validation);
            isFormValid = contactInfo[key].valid && isFormValid
        });
        Object.keys(additionalFields).map((key, index) => {
            additionalFields[key].valid = formValid(additionalFields[key].value, additionalFields[key].validation);
            isFormValid = additionalFields[key].valid && isFormValid
        });
        Object.keys(formControl).map((key, index) => {
            formControl[key].valid = formValid(formControl[key].value, formControl[key].validation);
            console.log("formValid", formControl[key].value, formValid(formControl[key].value, formControl[key].validation), key)
            isFormValid = formControl[key].valid && isFormValid
        });
        this.setState({
            formControl, additionalFields, contactInfo, isFormValid
        });
        return isFormValid;
    };
    sendForm = event => {
        event.preventDefault();
        if(this.isFormValid()){
            this.setState({ loading: true });
            myaxios.put(`/ajax/account/${this.props.match.params.id}`, {
                name: this.state.formControl.name.value,
                phone: this.state.formControl.wrapTel.value,
                number: this.state.formControl.dopTel.value,
                email: this.state.formControl.email.value,
                website: this.state.formControl.website.value,
                type_id: this.state.formControl.type.value,
                owner_id: this.state.formControl.owner_id.value ? this.state.formControl.owner_id.value : null,
                personal_discount: this.state.formControl.personal_discount.value,
                annual_revenue: this.state.formControl.turnover.value,
                num_of_employees: this.state.formControl.employees.value,
                description: this.state.formControl.comment.value,
                industry: this.state.formControl.industry.value,
                main_contact_id: this.state.contact_id,
                photo: this.state.img,
                editorTags: this.state.editorTags,
                additionalFields: GetPostAditionalFields("post", this.state.additionalFields),
                contactFields: {fields: GetPostAditionalFields('postContact', this.state.contactInfo), entity_type:3, entity_id: this.props.match.params.id},
            }).then(res => {
                if(res.status === 200){
                    Notif("save")
                }
                this.setState({loading: false});
            }).catch((error) => {
                this.setState({tch: true, loading: false});
            });
        } else Notif("Error")
    };

    test = () => {
        myaxios.post(`/ajax/lead`, {name:'test' + String(new Date()) });
        myaxios.post(`/ajax/opportunity`, {name:'test' + String(new Date()) });
        myaxios.post(`/ajax/order`, {name:'test' + String(new Date()) });
        myaxios.post(`/ajax/account`, {name:'test' + String(new Date()) });
        myaxios.post(`/ajax/contact`, {first_name:'test' });
        myaxios.post(`/ajax/document`, {name:'test' + String(new Date()) });
        myaxios.post(`/ajax/product`, {name:'test' + String(new Date()) });
        myaxios.post(`/ajax/task`, {subject:'test' + String(new Date()), remind_at: new Date() });
        myaxios.get(`/ajax/lead`);
        myaxios.get(`/ajax/opportunity`);
    }

    componentDidMount(){
        this.showContact();
        this.showRequisites();
        this.showAddress();
        this.getTeam();

        //setInterval(() =>this.test(), 1000);
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.match.params.id !== this.props.match.params.id) {
            this.showContact();
            this.showRequisites();
            this.showAddress();
            this.getTeam();
        }
    }

    deleteUser = () => {
        if(window.confirm("Удалить контрагента?")) {
            myaxios.delete('/ajax/account/' + this.props.match.params.id).then(res => {
                if(res.status === 200 || res.status === 201 || res.status === 204){
                    this.props.history.push(`/account`);
                    Notif("delete", "Контрагент успешно удален")
                }
            })
        }
    };

    /*Изменение полей*/
    setField = (event, type) => {const formControl = {  ...this.state.formControl }; const control = { ...formControl[type] };
        control.value = event.target.value; formControl[type] = control; this.setState({formControl, tch: false}) };

    /*Задачи*/
    task = (type, arr, index) => {
        console.log(type, arr, index)
        const tasks = this.state.tasks;
        if(type === "add") {tasks.unshift(arr)}
        else if(type === "edit"){tasks[index] = arr}
        else if(type === "delete") {delete tasks[index]}
        this.setState({tasks, open: '', openType:'', taskId:'', taskIndex:''})
    }

    /*Адрес*/
    address = (type, modal, id, arr) => {
        if(type === "open") {
            this.setState({ openType: modal, open: 'r-open', addressId: (id) ? id : null})
        } else if(type === "add") {
            const formControl = this.state.formControl;
            formControl[modal].options.unshift(arr);
            this.setState({ formControl, open: null })
        } else if(type === "delete") {
            if(window.confirm()) {
                if(modal === "address") {
                    myaxios.post(`/ajax/account/${this.props.match.params.id}/remove-address/${arr}`, ).then((response) => {
                        if(response.status === 200){
                            const formControl = this.state.formControl;
                            delete formControl[modal].options[id];
                            this.setState({ formControl, open: null })
                            Notif("delete");
                        }
                    })
                } else if(modal === "requisites"){
                    myaxios.delete(`/ajax/account/${this.props.match.params.id}/payment-detail/${arr}`, ).then((response) => {
                        if(response.status === 201 || response.status === 200) {
                            const formControl = this.state.formControl;
                            delete formControl[modal].options[id];
                            this.setState({ formControl, open: null })
                            Notif("delete");
                        }
                    })
                }
            }
        }
    };

    filesFunc = (type, index, arr) => {
        const files = this.state.files;
        if(type === "del" && arr.status === 200){
            delete files[index];
            Notif("delete", "Файл успешно удален")
        } else if(arr.status === "uploaded"){
            files.unshift({id: arr.id, name: arr.name, created_at: "Только что", type: arr.type});
            Notif("save", "Файл успешно загружен")
        } else if(arr.status === "error"){
            Notif("Error", arr.errors.file[0])
        }
        this.setState({ files })
    };

    render() {
        return (
            <div className="page">
                {this.state.loading && <Loader/>}
                <CSSTransition in={!this.state.loading} timeout={200} classNames="slide" unmountOnExit>
                    <div>
                <LeaveModal when={!this.state.tch} />
                {(this.state.is_deleted === 1) ? <EndBlock deletedName="Контрагент удален" type="contact" is_deleted={this.state.is_deleted} id={this.props.match.params.id} /> :
                <div className="butt-group butt-group-card">
                    <div className="tagsName">
                        <a data-tooltip={this.state.editorTags.length > 0 ? this.state.editorTags : null} onClick={() => this.setState({ open:'r-open', openType: "tags" })} className="success fl-l btni mr5" >
                            Теги ({(this.state.editorTags.length > 0) ? this.state.editorTags.split(',').length : '0'})
                        </a>
                    </div>
                    <a onClick={() => this.setState({ open:'r-open', openType: "task" })} className="success fl-l btni mr5" >
                        <span>Добавить задачу</span>
                        <i className="flaticon-notes mdb" />
                    </a>
                    <SearchTag id={this.props.match.params.id} entity="account" updateData={this.props.updateData} formControl={this.state.formControl} contactInfo={this.state.contactInfo}/>
                    <IconButton href="https://sboxcrm.com/docs/account" />
                    <button onClick={this.sendForm} className="butt-group-card-wrap success fl-r btni ml5" disabled={this.state.tch}>
                        <span>Сохранить</span>
                        <i className="flaticon-interface-1 mdb" />
                    </button>
                    <a title={'/ajax/account/' + this.props.match.params.id} onClick={this.deleteUser} className="butt-group-card-wrap fl-r btni ml5">
                        <span>Удалить</span>
                        <i className="flaticon-delete-2 mdb" />
                    </a>
                </div>}
                <div className="clear" />
                <div className="contact-page">
                    <div className="contact-left">
                        <div className="clear" />
                        <div className="contact-left-col" style={{ marginTop: "45px" }}>
                            <UploadAvatar
                                img={this.state.img}
                                setImg={(img) => this.setState({ img: img, tch:false })}
                                cancelImg={() => this.setState({ img: null, tch:false })}
                                related_id={this.props.match.params.id}
                                related_type="3"
                            />
                            <h3>Данные контрагента</h3>
                            <WrapForm
                                formControl={this.state.formControl}
                                setField={this.setField}
                                handleChange={this.handleChange}
                                isFormValid={this.state.isFormValid}
                                nameValid = {this.state.formControl.name.valid}
                                nameValue = {this.state.formControl.name.value}
                                typeOptions = {this.state.formControl.type.options}
                                typeValue = {this.state.formControl.type.value}
                                industry={this.state.formControl.industry.value}
                                personal_discount={this.state.formControl.personal_discount}
                                owner={this.state.owner}
                                updateData={(id, arr) => {
                                    const formControl = JSON.parse(JSON.stringify(this.state.formControl));
                                    formControl.owner_id.value = id;
                                    formControl.owner_id.arr = arr;
                                    this.setState({ formControl, tch: false })
                                }}
                                unLink={(id, arr) => {
                                    const formControl = JSON.parse(JSON.stringify(this.state.formControl));
                                    formControl.owner_id.value = "";
                                    formControl.owner_id.arr = null;
                                    this.setState({ formControl, tch: false })
                                }}
                            />
                        </div>
                        <Attached
                            setField={this.setField}
                            updateData={(id, arr) => this.setState({ contact:  arr, contact_id: id, tch: false})}
                            unLink={() => this.setState({ contact: null, contact_id: null, tch: false})}
                            contact={this.state.contact}
                            updateDataContact={this.props.updateData}
                        />
                    </div>
                    <div className="contact-right-col">
                        <div className="taskcontainer">
                            {(this.state.tasks) ? this.state.tasks.map((task, index) =>
                                <Task
                                    key={index}
                                    openTask={(id, index) => this.setState({ open: "r-open", openType: "task", taskIndex: index, taskId: id})}
                                    index={index}
                                    id={task.id}
                                    tasktext={task.subject}
                                    end_date={task.end_date}
                                    status={task.status}
                                />
                            ) : null}
                        </div>
                        <div className="tabs-header">
                            <ul>
                                <li><a className={(this.state.layout === "info") ? "active" : null} onClick={() => this.setState({ layout: 'info' })}>Основная информация</a></li>
                                <li><a className={(this.state.layout === "history") ? "active" : null} onClick={() => this.setState({ layout: 'history' })}>Хронология</a></li>
                                <li><a className={(this.state.layout === "related") ? "active" : null} onClick={() => this.setState({ layout: 'related' })}>Связи</a></li>
                                <li><a className={(this.state.layout === "files") ? "active" : null} onClick={() => this.setState({ layout: 'files' })}>Файлы</a></li>
                            </ul>
                        </div>
                        <div className="tab-container">
                            {(this.state.layout === "history") ? <History type="account" id={this.props.match.params.id} /> : null}
                            {(this.state.layout === "info") ? <Information
                                subscribeEmail={this.subscribeEmail}
                                subscriber={this.state.subscriber || {}}
                                setDescription={(e) => {
                                    const formControl = this.state.formControl;
                                    formControl.comment.value = e;
                                    this.setState({ formControl, tch: e === this.state.prevDesc })
                                }}
                                segments={this.state.segments}
                                rfmSegments={this.state.rfmSegments}
                                invoicesDiff={this.state.invoicesDiff}
                                cost={this.state.invoicesDiff ? Number(this.state.invoicesDiff.cost) : 0}
                                payment={this.state.invoicesDiff ? Number(this.state.invoicesDiff.payment) : 0}
                                baseCurrency={this.props.baseCurrency}
                                created_at={this.state.created_at}
                                updated_at={this.state.updated_at}
                                setField={this.setField}
                                isFormValid={this.state.isFormValid}
                                wrapPhone={this.state.formControl.wrapTel.value}
                                wrapPhoneValid={this.state.formControl.wrapTel.valid}
                                dopPhone={this.state.formControl.dopTel.value}
                                dopPhoneValid={this.state.formControl.dopTel.valid}
                                email={this.state.formControl.email.value}
                                emailValid={this.state.formControl.email.valid}
                                website={this.state.formControl.website.value}
                                websiteValid={this.state.formControl.website.valid}
                                employees={this.state.formControl.employees.value}
                                employeesOptions={this.state.formControl.employees.options}
                                turnover={this.state.formControl.turnover.value}
                                turnoverOptions={this.state.formControl.turnover.options}
                                comment={this.state.formControl.comment.value}
                                address={this.state.formControl.address.options}
                                team={this.state.team}
                                openTeam={() => this.setState({ open:"r-open", openType: "team" })}
                                editTeam={arr => this.setState({ open:"r-open", openType: "team", teamId: arr })}
                                deleteTeam={this.deleteTeam}
                                requisites={this.state.formControl.requisites.options}
                                openAddress={this.address}
                                deleteModal={this.address}
                                additionalFields={this.state.additionalFields}
                                updateAdditionalFields={(items) => this.setState({ additionalFields: items, tch: false })}
                                contactInfo={this.state.contactInfo}
                                updateContactInfo={(ContactInfo)=> this.setState({ contactInfo: ContactInfo, tch: false }) }
                                addContactField={
                                    (inputType, label, type) => {
                                        const contactInfo = this.state.contactInfo;
                                        contactInfo.push({
                                            label: label,
                                            type: inputType,
                                            value:'',
                                            valid:false,
                                            validation:{
                                                required:true,
                                                email: (type === "email"),
                                                website: (type === "website"),
                                                number: (type === "number"),
                                            },
                                            t: inputType,
                                            postType: type,
                                            required: true
                                        })
                                        this.setState({contactInfo})
                                    }
                                }
                            /> : null}
                            {(this.state.layout === "related") ?
                                <Related
                                    type="account"
                                    id={this.props.match.params.id}
                                    related="3"
                                    accountEmail={this.state.formControl.email.value}
                                    account_id={this.props.match.params.id}
                                    account={{name: this.state.formControl.name.value}}
                                    tasks
                                    cases
                                    invoices
                                    documents
                                    sms
                                    opportunities
                                    orders
                                    projects
                                    mails
                                    expense
                                /> : null}
                            {(this.state.layout === "files") ? <Files
                                files={this.state.files}
                                filesFunc={this.filesFunc}
                                relatedType="3"
                                related_id={this.props.match.params.id}
                            /> : null}
                        </div>
                    </div>
                </div>
                <React.Fragment>
                    <div className={"right-modal scrollbar " + this.state.open}><div className="save">
                        {/*Задача*/}
                        {(this.state.open === "r-open" && this.state.openType === "task") ?
                            <AddTask
                                task={this.task}
                                id={this.state.taskId}
                                index={this.state.taskIndex}
                                close={() => this.setState({ open:'', openType: '', taskId:'', taskIndex: '' })}
                                tie={this.props.match.params.id}
                                relatedType="3"
                            /> : null}
                        {/*Адресс*/}
                        {(this.state.open === "r-open" && this.state.openType === "address") ?
                            <Address
                                addAddress={this.address}
                                id={this.state.addressId}
                                type="account"
                                parrentId={this.props.match.params.id}
                                refresh={() => {this.showAddress(); this.setState({ open: '', addressId: '' })}}
                                close={() => this.setState({ open:'', openType: '', taskId:'', taskIndex: '', teamId: null })}
                            /> : null}
                        {/*Реквизиты*/}
                        {(this.state.open === "r-open" && this.state.openType === "requisites") ?
                            <Requisites
                                id={this.state.addressId}
                                parrentId={this.props.match.params.id}
                                addAddress={this.address}
                                refresh={() => {this.showRequisites(); this.setState({ open: '', addressId: '' })}}
                                close={() => this.setState({ open:'', openType: '', taskId:'', taskIndex: '', teamId: null })}
                            /> : null}
                        {(this.state.open === "r-open" && this.state.openType === "tags")
                            ? <EditTags
                                close={() => this.setState({ open:'', openType:''})}
                                tags={this.state.editorTags}
                                type="account"
                                id={this.props.match.params.id}
                                refresh={() => {}}
                                refreshState={(tags) => {this.setState({ editorTags: tags })} }
                            /> : null}
                        {(this.state.open === "r-open" && this.state.openType === "team")
                            ? <Team
                                id={(this.state.teamId) ? this.state.teamId.user_id : null}
                                arr={this.state.teamId}
                                entity="account"
                                parrentId={this.props.match.params.id}
                                close={() => this.setState({ open:'', openType:'', teamId: null })}
                                refresh={() => this.getTeam()}
                            /> : null}
                                </div></div>
                    {this.state.open ? <Bgdrop close={() => this.setState({ open:'', openType: '', taskId:'', taskIndex: '', teamId: null })} /> : null}
                </React.Fragment>
            </div>
                </CSSTransition>
            </div>
        )
    }
}