export const createProductJSON = {
    base_price: "",
    category_id: null,
    currency_id: null,
    description: "",
    model: "",
    name: "Product for test",
    purchase_price: "",
    sku: "",
    unit_id: null
}

export const priceBookJSON = {
    description: "test name",
    name: "test description"
}