import React, {useEffect, useState} from "react";
import {getCasePrepareService, getSingleCaseService, deleteCaseService, updateCaseService} from "../../services/case/caseService";
import {parseResponse, parseResponseError} from "../../components/cardViewComponents/parseFunctions";
import {ModalLoader} from "../../components/UI/Loader";
import LeaveModal from "../../components/other/LeaveModal";
import {TagsNew} from "../../components/cardViewComponents/TagsNew";
import {MemoButton} from "../../components/cardViewComponents/MemoButton";
import {GenerateForm} from "../../components/cardViewComponents/GenerateForm";
import {ContactCard} from "../../components/cardViewComponents/ContactCard";
import {AccountCard} from "../../components/cardViewComponents/AccountCard";
import {Tasks} from "../../components/Task";
import {filesFunc, TabsRender} from "../../components/fieldGen";
import {CSSTransition} from "react-transition-group";
import Related from "../../components/related/related";
import Files from "../../components/files/files";
import Input from "../../components/UI/Input";
import {InfoTable} from "../../components/cardViewComponents/InfoTable";
import MainEditor from "../../components/UI/Editor";
import History from "../../components/history/history";
import Notif from "../../components/Notif";
import {validationData} from "../../components/UI/formValid";
import {validationConfig} from "../../components/cardViewComponents/validationConfig";
import {TaskModal, updateEntityTasks} from "../tasks/TaskModal";
import {ConflictModal} from "../../components/cardViewComponents/ConflictModal";
import {FilesDropDown} from "../../components/FilesDropDown";

export const tabsConfig = [
    {value:"information", label:"Информация"},
    {value:"history", label:"Хронология"},
    {value:"related", label:"Связи"},
    {value:"files", label:"Файлы"},
]

const caseConfig = {
    number: {
        label: 'Номер обращения',
        type: 'text',
        className: 'w100',
        validation: { required: true, maxLength: 255 },
        zone: 1
    },
    priority: {
        label: 'Приоритет обращения',
        type: 'select',
        options: [],
        options_from: 'priorities',
        className: 'w100',
        validation: { required: true },
        zone: 1
    },
    status_id: {
        label: 'Статус обращения',
        type: 'select',
        options: [],
        className: 'w100',
        options_from: 'statuses', val: 'id', lab: 'value',
        validation: { required: true },
        zone: 1,
    },
    type_id: {
        label: 'Тип обращения',
        type: 'select',
        options: [],
        className: 'w100',
        options_from: 'types', val: 'id', lab: 'value',
        zone: 1,
    },
    source_id: {
        label: 'Источник обращения',
        type: 'entity',
        link: "/ajax/lookup/search?type=case_source&value=",
        result: 'value',
        entity_from: 'source',
        entityName: 'value',
        className: 'w100',
        minLength: 0,
        zone: 1
        // redirectLink
    },
    owner_id: {
        label: 'Ответственный',
        type: 'entity',
        link: "/ajax/settings/user?filter[name][like]=",
        result: 'name',
        entity_from: 'owner',
        entityName: 'name',
        className: 'w100',
        minLength: 0,
        validation: { required: true },
        zone: 1
        // redirectLink
    },
    name: {
        validation: { required: true, maxLength: 255 },
    },
    reason: {},
    description: {},
    contact_id: { type: 'entity', entity_from: 'contact' },
    account_id: { type: 'entity', entity_from: 'account' },
    editorTags: {},
    created_at: {},
    updated_at: {},
}

export const CaseViewNew = ({ match, history, location }) => {
    const urlParams = new URLSearchParams(location.search);
    const { params: { id } } = match
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState({})
    const [disabled, setDisabled] = useState({})
    const [conflict, setConflict] = useState(false)
    const [modalData, setModalData] = useState(null)
    const [values, setValues] = useState({
        account_id: '',
        account: null,
        contact_id: '',
        contact: null,
        description: '',
        name: '',
        number: '',
        owner_id: '',
        owner: null,
        priority: "low",
        priorities: [
            { value: 'low', label: 'Низкий' },
            { value: 'medium', label: 'Средний' },
            { value: 'high', label: 'Высокий' },
        ],
        reason: '',
        source_id: '',
        source: null,
        status_id: '',
        type_id: '',
        editorTags: "",
        tasks: [],
        files: [],
        tch: true,
        begin_update_datetime: null,
        created_at:'',
        updated_at:'',
    })

    const getPrepare = async () => {
        try {
            const { data, status } = await getCasePrepareService()
            if(status === 200) {
                await getData(data)
            }
        } catch (e) { }
        setLoading(false)
    }

    const getData = async (prepareData) => {
        setLoading(true)

        try {
            const { data, status, response } = await getSingleCaseService({
                id, expand: 'tasks,files,contact,account,owner'
            })

            const parseResponseData = parseResponseError(response);
            if(parseResponseData.notFound) history.push('/404')

            const dataArr = {
                ...values,
                ...parseResponse(caseConfig, data, {}),
                statuses: prepareData.statuses,
                types: prepareData.types,
                files: data.files,
                tasks: data.tasks,
            }

            if(status === 200 && data.id) {
                setValues(dataArr)
            }
        } catch (e) { console.error('getCaseDataError', e) }

        setLoading(false)
    }

    useEffect(() => {
        setLoading(true)
        getPrepare().then()
    }, [])

    const saveItem = async (forcibly) => {
        const { number, name, reason, description, account_id, contact_id, owner_id, type_id, status_id, source_id, priority, editorTags, updated_at } = values
        const errorsData = validationData(values, validationConfig(caseConfig))

        setErrors(errorsData)
        if(Object.keys(errorsData).length > 0) {
            Notif('required-fields')
            return
        }

        setLoading(true)

        try {
            const { data, status, response } = await updateCaseService({ id, data: {
                    number, name, reason, description, account_id, contact_id, owner_id, type_id, status_id, source_id, priority,
                    editorTags, begin_update_datetime: forcibly === 'ok' ? null : updated_at,
                }})

            if(status === 200 && data.id) {
                Notif('save', 'Запись успешно изменена')
                setValues(({ ...values, updated_at: data.updated_at, tch: true, begin_update_datetime: null }))
            }

            const parseResponseData = parseResponseError(response);
            if(parseResponseData.saveConflict) setConflict(true)
            if(parseResponseData.validationErrors) {
                setErrors(parseResponseData.validationErrors)
            }

        } catch (e) { console.error(e) }

        setLoading(false)
    }

    const deleteItem = async () => {
        if(window.confirm('Удалить обращение?')) {
            setLoading(true)
            try {
                const { status } = await deleteCaseService({ id })
                if(status === 200 || status === 204) {
                    setValues(prevState => ({ ...prevState, tch: true }))
                    Notif('delete', 'Обращение успешно удалено')
                    history.push('/case')
                }
            } catch (e) { console.error(e) }
            setLoading(false)
        }
    }

    const changeFields = (key, value, item) => {
        let obj = {}
        if(item) {
            if(item === 'clear') obj[caseConfig[key].entity_from] = null
            else obj[caseConfig[key].entity_from] = item
        }

        setValues({
            ...values, ...obj, [key]: item ? item.id : value,
            begin_update_datetime: !values.begin_update_datetime ? new Date() : values.begin_update_datetime,
            tch: false
        })
    }

    return (
        <>
            {loading && <ModalLoader />}
            <div className="page">
                <LeaveModal when={!values.tch} />
                <div className="newList-btn-group">
                    <div className="newList-btn-group-left">
                        <TagsNew
                            editorTags={values.editorTags} entity={'case'}
                            setValues={data => setValues({ ...values, editorTags: data, tch: false })}
                        />
                        <MemoButton
                            onClick={() => setModalData({ type: 'task' })}
                            mobileIcon={'flaticon-notes mdb'} title={'Добавить задачу'} className={'mr0'} />
                    </div>
                    <div className="newList-btn-group-right">
                        <a onClick={deleteItem} className="butt-group-card-wrap btni ml10">
                            <span>Удалить</span>
                            <i className="flaticon-delete-2 mdb" />
                        </a>
                        <MemoButton onClick={saveItem} disabled={values.tch} mobileIcon={"flaticon-interface-1 mdb"} title={'Сохранить'} className={'ml5'} />
                    </div>
                </div>
                <div className="clear" />

                <div className="contact-page sbform">
                    <div className="contact-left">
                        <div className="contact-left-col">
                            <h3>Данные обращения</h3>
                            <GenerateForm
                                config={caseConfig}
                                { ...{ values, errors, disabled } }
                                onChange={changeFields}
                                onSaveData={saveItem}
                            />
                        </div>
                        {!values.account && <ContactCard
                            contact={values.contact}
                            setDataValues={data => {
                                setValues(({...values, ...data, tch: false}))
                            }}
                        />}
                        {!values.contact && <AccountCard
                            account={values.account}
                            setDataValues={data => {
                                setValues(({...values, ...data, tch: false}))
                            }}
                        />}
                    </div>

                    <div className="contact-right-col">
                        <Tasks tasks={values.tasks} openTask={data => setModalData(data)} />
                        <TabsRender
                            setState={({ layout }) => history.push(`?layout=${layout}`)}
                            state={{ layout: urlParams.get('layout') || tabsConfig[0].value }}
                            arr={tabsConfig}
                        />
                        <div className="tab-container">
                            <CSSTransition in={(urlParams.get('layout') || tabsConfig[0].value) === tabsConfig[0].value} timeout={{enter: 0, exit: 0}} classNames="my-node" unmountOnExit>
                                <div className="tab-content sbform clearFieldPadding">
                                    <InfoTable
                                        arr={[
                                            { value: 'Дата создания', label: values.created_at },
                                            { value: 'Дата обновления', label: values.updated_at },
                                        ]}
                                    />
                                    <div className="connection"><span>Основная информация</span></div>
                                    <div style={{ padding: '0 5px' }}>
                                        <Input
                                            label={'Тема обращения'} shouldValidate
                                            value={values.name}
                                            valid={!errors.name}
                                            errorMessage={errors.name}
                                            onChange={e => setValues({ ...values, name: e.target.value, tch: false })}
                                        />
                                    </div>

                                    <div className="fgroup rc12" style={{ marginTop: '10px' }}>
                                        <MainEditor
                                            label={'Причина обращения'}
                                            value={values.reason}
                                            height={300}
                                            valid={!errors.reason}
                                            errorMessage={errors.reason}
                                            onChange={reason => {
                                                if(reason !== values.reason) {
                                                    setValues({ ...values, reason, tch: false })
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="fgroup rc12" style={{ marginTop: '10px' }}>
                                        <MainEditor
                                            label={'Решение'}
                                            value={values.description}
                                            height={300}
                                            valid={!errors.description}
                                            errorMessage={errors.description}
                                            onChange={description => {
                                                if(description !== values.description) {
                                                    setValues({ ...values, description, tch: false })
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </CSSTransition>
                            <CSSTransition in={urlParams.get('layout') === tabsConfig[1].value} timeout={{enter: 0, exit: 0}} classNames="my-node" unmountOnExit>
                                <History type="case" id={id} />
                            </CSSTransition>
                            <CSSTransition in={urlParams.get('layout') === tabsConfig[2].value} timeout={{enter: 0, exit: 0}} classNames="my-node" unmountOnExit>
                                <Related
                                    type="case" id={id} related="27"
                                    contactEmail={values.contact ? values.email : ''} accountEmail={values.account ? values.account.email : ''}
                                    contact={values.contact} account={values.account} contact_id={values.contact_id} account_id={values.account_id}
                                    case_id={id} tasks mails sms expense isPlates
                                />
                            </CSSTransition>
                            <CSSTransition in={urlParams.get('layout') === tabsConfig[3].value} timeout={{enter: 0, exit: 0}} classNames="my-node" unmountOnExit>
                                <FilesDropDown
                                    related_id={id}
                                    related_type={"27"}
                                    files={values.files}
                                    updateFile={filesData => setValues(prevState => ({
                                        ...prevState,
                                        files: [filesData, ...prevState.files]
                                    }))}
                                    updateFiles={files => setValues(prevState => ({ ...prevState, files }))}
                                />
                            </CSSTransition>
                        </div>
                    </div>
                </div>
            </div>
            {conflict && <ConflictModal
                entity={'case'} id={id} updated_at={values.begin_update_datetime}
                save={() => saveItem('ok')} close={() => setConflict(false)}
            />}
            <TaskModal
                id={modalData && modalData.id} close={() => setModalData(null)} index={modalData && modalData.index}
                task={(type, data, index) => { setValues({ ...values, tasks: updateEntityTasks(values.tasks, type, data, index) }) }}
                related={{ id, type: 27 }} isOpen={modalData && modalData.type === 'task'}
            />
        </>
    )
}