import React, {useContext, useEffect, useState} from "react";
import {
    getProductPrepareService,
    getSingleProductService,
    updateProductService,
    deleteProductService,
    getPriceBookService,
    linkPriceBookToProductService,
    updatePriceBookToProductService, getlinkedPriceBooks, unlinkPriceBookToProductService
} from "../../services/product/product";
import {parseResponse, parseResponseError} from "../../components/cardViewComponents/parseFunctions";
import GetPostAditionalFields from "../../components/get_post_aditionalFields";
import {ModalLoader} from "../../components/UI/Loader";
import LeaveModal from "../../components/other/LeaveModal";
import {TagsNew} from "../../components/cardViewComponents/TagsNew";
import {MemoButton} from "../../components/cardViewComponents/MemoButton";
import IconButton from "../../components/other/IconButton";
import UploadAvatar from "../../components/other/UploadAvatar";
import {GenerateForm} from "../../components/cardViewComponents/GenerateForm";
import {CSSTransition} from "react-transition-group";
import History from "../../components/history/history";
import Files from "../../components/files/files";
import {filesFunc, TabsRender} from "../../components/fieldGen";
import Remainder from "./tab/remainder";
import {InfoTab} from "./components/InfoTab";
import Modal from "../../components/Modal/Modal";
import Myselect from "../../components/UI/Select";
import Input from "../../components/UI/Input";
import {GlobalState} from "../../Layout/Layout";
import Notif from "../../components/Notif";
import {deleteDocumentService, updateDocumentService} from "../../services/document/document";
import {additionalFieldsValidation} from "../../components/cardViewComponents/additionalFieldsValidation";
import {controlErrorValid, validationData} from "../../components/UI/formValid";
import {validationConfig} from "../../components/cardViewComponents/validationConfig";
import {ConflictModal} from "../../components/cardViewComponents/ConflictModal";
import {FilesDropDown} from "../../components/FilesDropDown";
import {CanDelete} from "../../Modal/CanDelete";

export const tabsConfig = [
    {value:"information", label:"Информация"},
    {value:"remains", label:"Остатки"},
    {value:"history", label:"Хронология"},
    {value:"files", label:"Файлы"},
]

export const productConfig = {
    name: {
        label: 'Название',
        type: 'text',
        className: 'w100',
        validation: { required: true, maxLength: 255 },
        zone: 1
    },
    sku: {
        label: 'Артикул',
        type: 'text',
        className: 'w100',
        validation: { required: true, maxLength: 255 },
        zone: 1
    },
    base_price: {
        label: 'Базовая цена',
        type: 'number',
        className: 'w100',
        validation: { number: true, maxLength: 255 },
        zone: 1
    },
    purchase_price: {
        label: 'Закупочная цена',
        type: 'number',
        className: 'w100',
        validation: { number: true, maxLength: 255 },
        zone: 1
    },
    currency_id: {
        label: 'Валюта',
        type: 'select',
        options: [],
        className: 'w100',
        options_from: 'currencies', val: 'id', lab: 'name',
        validation: { required: true },
        zone: 1,
    },
    unit_id: {
        label: 'Единица измерения',
        type: 'select',
        options: [],
        className: 'w100',
        options_from: 'units', val: 'id', lab: 'value',
        zone: 1,
    },
    category_id: {
        label: 'Категория',
        type: 'entity',
        link: "/ajax/lookup/search?type=category&value=",
        result: 'name',
        entity_from: 'category',
        entityName: 'name',
        className: 'w50-p',
        minLength: 0,
        zone: 2,
    },
    provider_id: {
        label: 'Поставщик',
        type: 'entity',
        link: "/ajax/account?fields=id,name&filter[name][like]=",
        result: 'name',
        entity_from: 'provider',
        entityName: 'name',
        className: 'w50-p',
        minLength: 0,
        zone: 2,
        redirect: 'account'
    },
    manufacturer_id: {
        label: 'Производитель',
        type: 'entity',
        link: "/ajax/account?fields=id,name&filter[name][like]=",
        result: 'name',
        entity_from: 'manufacturer',
        entityName: 'name',
        className: 'w50-p',
        minLength: 0,
        zone: 2,
        redirect: 'account'
    },
    tax_id: {
        label: 'Налог',
        type: 'select',
        options: [],
        className: 'w50-p',
        options_from: 'taxes', val: 'id', lab: 'name',
        zone: 2,
    },
    length: {
        label: 'Длина',
        type: 'text',
        className: 'w25-p',
        validation: { maxLength: 255 },
        zone: 2
    },
    width: {
        label: 'Ширина',
        type: 'text',
        className: 'w25-p',
        validation: { maxLength: 255 },
        zone: 2
    },
    height: {
        label: 'Высота',
        type: 'text',
        className: 'w25-p',
        validation: { maxLength: 255 },
        zone: 2
    },
    weight: {
        label: 'Вес',
        type: 'text',
        className: 'w25-p',
        validation: { maxLength: 255 },
        zone: 2
    },
    editorTags: {},
    created_at: {},
    updated_at: {},
    thumbnail: {},
    description: {}
}

export const ProductViewNew = ({ match, history, location }) => {
    const urlParams = new URLSearchParams(location.search);
    const { params: { id } } = match
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState({})
    const [disabled, setDisabled] = useState({})
    const [conflict, setConflict] = useState(false)
    const [modalData, setModalData] = useState(null)
    const [priceBooks, setPriceBooks] = useState([])
    const [values, setValues] = useState({
        name: '',
        sku: '',
        base_price: '',
        purchase_price: '',
        currency_id: '',
        currencies: [],
        unit_id: '',
        units: [],
        tax_id: '',
        taxes: [],
        category_id: '',
        category: null,
        manufacturer_id: '',
        manufacturer: null,
        provider_id: '',
        provider: null,
        length: '',
        width: '',
        height: '',
        weight: '',
        description: '',
        thumbnail: '',
        editorTags: '',
        additionalFields: [],
        files: [],
        tch: true,
        begin_update_datetime: null,
        created_at:'',
        updated_at:'',
    })

    const getPrepare = async () => {
        try {
            const { data, status } = await getProductPrepareService()
            if(status === 200) {
                await getData(data)
            }
        } catch (e) { }
        setLoading(false)
    }

    const getData = async (prepareData) => {
        setLoading(true)

        try {
            const { data, status, response } = await getSingleProductService(id)

            const parseResponseData = parseResponseError(response);
            if(parseResponseData.notFound) history.push('/404')

            const dataArr = {
                ...values,
                ...parseResponse(productConfig, data, prepareData),
                currencies: prepareData.currencies,
                taxes: prepareData.taxes,
                units: prepareData.units,
                files: data.files,
                additionalFields: GetPostAditionalFields("get", data.additionalFields) || []
            }

            if(status === 200) {
                setValues(dataArr)
            }
        } catch (e) { console.error('getProductDataError', e) }

        setLoading(false)
    }

    const saveItem = async (forcibly) => {
        const { name, sku, base_price, purchase_price, currency_id, unit_id, tax_id, category_id, manufacturer_id, provider_id, length, weight,
            width, height, description, thumbnail, editorTags, updated_at } = values

        const additionalFieldsValidationRes = additionalFieldsValidation(values.additionalFields)

        const errorsData = validationData(
            {...values, ...additionalFieldsValidationRes.values},
            {...validationConfig(productConfig), ...additionalFieldsValidationRes.validationRules}
        )

        setErrors(errorsData)
        if(Object.keys(errorsData).length > 0) {
            Notif('required-fields')
            return
        }

        setLoading(true)

        try {
            const { data, status, response } = await updateProductService(id, {
                    name, sku, base_price, purchase_price, currency_id, unit_id, tax_id, category_id, manufacturer_id, provider_id, length, weight, width, height, description, thumbnail,
                    editorTags, begin_update_datetime: null,
                    additionalFields: GetPostAditionalFields("post", values.additionalFields),
                })

            if(status === 200) {
                Notif('save', 'Запись успешно изменена')
                setValues(prevState => ({ ...prevState, updated_at: data.updated_at, tch: true, begin_update_datetime: null }))
            }

            const parseResponseData = parseResponseError(response);
            if(parseResponseData.saveConflict) setConflict(true)
            if(parseResponseData.validationErrors) {
                setErrors(parseResponseData.validationErrors)
            }

        } catch (e) { console.error(e) }

        setLoading(false)
    }

    const deleteItem = async () => {
        if(window.confirm('Удалить продукт?')) {
            setLoading(true)
            try {
                const { status, data } = await deleteProductService(id)
                if(status === 200 || status === 204) {
                    if(!data.canDelete) {
                        Notif('delete', 'Продукт успешно удален')
                        setValues(prevState => ({ ...prevState, tch: true }))
                        history.push('/product')
                    } else if(data.items && typeof data.items === 'object') {
                        setModalData({ type: 'canDelete', items: data.items })
                    } else {
                        Notif('Error', 'При удалении что-то пошло не так')
                    }
                }
            } catch (e) { console.error(e) }
            setLoading(false)
        }
    }

    const changeFields = (key, value, item) => {
        let obj = {}
        if(item) {
            if(item === 'clear') obj[productConfig[key].entity_from] = null
            else obj[productConfig[key].entity_from] = item
        }
        setValues({ ...values, ...obj, [key]: item ? item.id : value,
            begin_update_datetime: !values.begin_update_datetime ? new Date() : values.begin_update_datetime, tch: false
        })
    }

    const getPriceBooks = async () => {
        const { data, status } = await getlinkedPriceBooks({ product_id: id })
        if(status === 200 && data && data.items) {
            setPriceBooks(data.items)
        }
    }

    const unlinkPrice = async (price_id) => {
        if(window.confirm('Отвязать прайс-лист?')) {

        }
        setLoading(true)
        const { status } = await unlinkPriceBookToProductService(id, price_id)
        if(status === 200 || status === 201) {
            await getPriceBooks()
            Notif("save");
        }
        setLoading(false)
    }

    useEffect(() => {
        setLoading(true)
        getPrepare().then()
        getPriceBooks().then()
    }, [id])

    return (
        <>
            {loading && <ModalLoader />}
            <div className="page">
                <LeaveModal when={!values.tch} />
                <div className="newList-btn-group">
                    <div className="newList-btn-group-left">
                        <TagsNew
                            editorTags={values.editorTags} entity={'product'}
                            setValues={data => setValues({ ...values, editorTags: data, tch: false })}
                        />
                    </div>
                    <div className="newList-btn-group-right">
                        <a onClick={deleteItem} className="butt-group-card-wrap btni ml10">
                            <span>Удалить</span>
                            <i className="flaticon-delete-2 mdb" />
                        </a>
                        <MemoButton onClick={saveItem} disabled={values.tch} mobileIcon={"flaticon-interface-1 mdb"} title={'Сохранить'} className={'ml5'} />
                        <IconButton href="https://sboxcrm.com/docs/product" />
                    </div>
                </div>

                <div className="contact-page sbform">
                    <div className="contact-left">
                        <div className="contact-left-col" style={{ marginTop: "45px" }}>
                            <h3>Данные продукта</h3>
                            <UploadAvatar
                                img={values.thumbnail}
                                setImg={(thumbnail) => setValues(({ ...values, thumbnail, tch: false }))}
                                cancelImg={() => setValues(({ ...values, thumbnail: '', tch: false }))}
                                related_id={id}
                                related_type="9"
                            />
                            <GenerateForm
                                config={productConfig}
                                { ...{ values, errors, disabled, loading } }
                                onChange={changeFields}
                                onSaveData={saveItem}
                            />
                            <div className="clear" />
                        </div>
                    </div>

                    <div className="contact-right-col">
                        <TabsRender
                            setState={({ layout }) => history.push(`?layout=${layout}`)}
                            state={{ layout: urlParams.get('layout') || tabsConfig[0].value }}
                            arr={tabsConfig}
                        />

                        <div className="tab-container">
                            <CSSTransition in={(urlParams.get('layout') || tabsConfig[0].value) === tabsConfig[0].value} timeout={200} classNames="my-node" unmountOnExit>
                                <>
                                    <InfoTab
                                        {...{ values, errors, disabled, changeFields, id, saveItem, priceBooks, setModalData, unlinkPrice }}
                                        setValues={data => setValues(({ ...values, ...data, tch: false }))}
                                    />
                                </>
                            </CSSTransition>
                            <CSSTransition in={urlParams.get('layout') === tabsConfig[1].value} timeout={{enter: 0, exit: 0}} classNames="my-node" unmountOnExit>
                                <Remainder id={id} />
                            </CSSTransition>
                            <CSSTransition in={urlParams.get('layout') === tabsConfig[2].value} timeout={{enter: 0, exit: 0}} classNames="my-node" unmountOnExit>
                                <History type="product" id={id} />
                            </CSSTransition>
                            <CSSTransition in={urlParams.get('layout') === tabsConfig[3].value} timeout={{enter: 0, exit: 0}} classNames="my-node" unmountOnExit>
                                <FilesDropDown
                                    related_id={id}
                                    related_type={"9"}
                                    files={values.files}
                                    updateFile={filesData => setValues(prevState => ({
                                        ...prevState,
                                        files: [filesData, ...prevState.files]
                                    }))}
                                    updateFiles={files => setValues(prevState => ({ ...prevState, files }))}
                                />
                            </CSSTransition>
                        </div>
                    </div>
                </div>
            </div>
            {conflict && <ConflictModal
                entity={'product'} id={id} updated_at={values.begin_update_datetime}
                save={() => saveItem('ok')} close={() => setConflict(false)}
            />}
            <PriceBookModal
                isOpen={modalData && modalData.type ==='priceBook'}
                close={() => setModalData(null)}
                currencies={values.currencies}
                history={history}
                update={getPriceBooks}
                id={id}
                item={modalData && modalData.item}
            />
            <CanDelete
                isOpen={modalData && modalData.type === 'canDelete'} close={() => setModalData(null)}
                items={modalData && modalData.items} entity={'product'} delParent={deleteItem} name={'продукт'} id={id} history={history}
            />
        </>
    )
}

const PriceBookModal = ({ isOpen, currencies, id, history, close, update, item }) => {
    const { state } = useContext(GlobalState)
    const [loading, setLoading] = useState(true)
    const [values, setValues] = useState({
        price: '',
        priceBook_id: '',
        currency_id: '',
        priceBooks: []
    })
    const [empty, setEmpty] = useState(false)
    const [priceValid, setPriceValid] = useState('')

    const getData = async () => {
        const { data } = await getPriceBookService()
        if(data.items.length < 2) {
            setEmpty(true)
        } else {
            if(item) {
                setValues({
                    price: item.default_price,
                    priceBooks: data.items,
                    priceBook_id: item.price_book_id,
                    currency_id: item.currency_id
                })
            } else {
                setValues({
                    price: '',
                    priceBooks: data.items,
                    priceBook_id: data.items && data.items[0] && data.items.filter(el => +el.id !== 1)[0].id,
                    currency_id: currencies && currencies[0] && currencies[0].id
                })
            }

        }
        setLoading(false)
    }

    const savePrice = async () => {
        const errorString = controlErrorValid(values.price, {required: true, number: true, maxLength: 255})
        if(errorString) {
            setPriceValid(errorString)
            return false
        } else setPriceValid('')

        setLoading(true)
        const { currency_id, price } = values
        const { data, status } = !item ?
            await linkPriceBookToProductService(id, values.priceBook_id, { currency_id, price }) :
            await updatePriceBookToProductService(id, values.priceBook_id, { currency_id, price })

        if(status === 200 || status === 201) {
            update()
            close()
        }
        setLoading(false)
    }

    useEffect(() => {
        if(isOpen && currencies) {
            getData().then()
        }
    }, [isOpen, currencies, state])

    return (
        <Modal isOpen={isOpen} title={'Прайс-лист'} save={savePrice} close={close} loading={loading}>
            <>
                {
                    empty ? <>
                        <p style={{ fontFamily: 'Montserrat-Light', color: '#646464', textAlign: 'center', marginTop: '10px' }}>Нет дополнительных прайс-листов</p>
                        <button
                            onClick={() => history.push('/stock/pricebook')}
                            style={{ display: 'inherit', margin: '10px auto' }} className="btni">Добавить прайс-лист</button>
                    </> : <>
                        <Myselect
                            label={'Прайс-лист'}
                            value={values.priceBook_id}
                            val={'id'} lab={'name'}
                            options={values.priceBooks.filter(el => +el.id !== 1)}
                            onChange={e => setValues({ ...values, priceBook_id: e.target.value })}
                        />
                        <Input
                            label={'Цена'} shouldValidate
                            value={values.price}
                            onChange={e => setValues({ ...values, price: e.target.value })}
                            valid={!priceValid}
                            errorMessage={priceValid}
                            type={'number'}
                            onKeyDown={e => e.keyCode === 13 && savePrice()}
                        />
                        <Myselect
                            label={'Валюта'}
                            value={values.currency_id}
                            val={'id'} lab={'name'}
                            options={currencies}
                            onChange={e => setValues({ ...values, currency_id: e.target.value })}
                        />
                    </>
                }
            </>
        </Modal>
    )
}