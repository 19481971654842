import React, {useEffect, useState} from "react";
import {NewAutoSelect} from "../NewUi/NewAutoSelect";
import {NavLink} from "react-router-dom";
import {createAccountService} from "../../services/account/accountService";
import Notif from "../Notif";
import {validationData} from "../UI/formValid";

const validationConfig = {
    name: {required: true, maxLength: 255},
    phone: {maxLength: 255},
    email: {maxLength: 255, email: true},
    website: {maxLength: 255, url: true}
}

const initialValue = {
    name: '',
    phone: '',
    email: '',
    website: ''
}

export const AccountCardRender = ({ account, setDataValues }) => {
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [values, setValues] = useState({
        name: '',
        phone: '',
        email: '',
        website: ''
    })

    useEffect(() => {
        setValues(JSON.parse(JSON.stringify(initialValue)))
    }, [account])

    const saveAccount = async () => {
        const errorsData = validationData(values, validationConfig)
        setErrors(errorsData)
        if(Object.keys(errorsData).length > 0) {
            Notif('required-fields')
            return
        }

        setLoading(true)
        try {
            const { data, status } = await createAccountService({ data: values })
            if((status === 200 || status === 201) && data.id) {
                Notif('save', 'Контрагент создан')
                setDataValues({ account: data, account_id: data.id })
            }
        } catch (e) { }
        setLoading(false)
    }

    return (
        <div className={'contact-left-col account_col'}>
            {account && <a onClick={() => setDataValues({ account: null, account_id: '' })} className="lead-form-close">&#10005;</a>}
            <h3>Контрагент</h3>
            {
                account && <div>
                    <div className={'card-block'}>
                        <em>Название</em>
                        <NavLink to={`/account/${account.id}`}>{account.name}</NavLink>
                    </div>
                    {account.phone && <div className={'card-block'}>
                        <em>Телефон</em>
                        <span>{account.phone}</span>
                    </div>}
                    {account.email && <div className={'card-block'}>
                        <em>Email</em>
                        <span>{account.email}</span>
                    </div>}
                    {account.website && <div className={'card-block'}>
                        <em>Сайт</em>
                        <span>{account.website}</span>
                    </div>}
                </div>
            }
            {!account && <>
                <NewAutoSelect
                    label={'Название'}
                    searchBy={'name'}
                    link={`/ajax/account?fields=id,name,email,phone,website&filter[name][like]=`}
                    updateData={(item) => setDataValues({ account: item, account_id: item.id })}
                    entity={null} minLength={1}
                    onChange={e => setValues(({ ...values, name: e.target.value }))}
                    valid={!errors.name} errorMessage={errors.name}
                />
                <NewAutoSelect
                    label={'Email'}
                    searchBy={'email'}
                    link={`/ajax/account?fields=id,name,email,phone,website&filter[email][like]=`}
                    updateData={(item) => setDataValues({ account: item, account_id: item.id })}
                    entity={null} minLength={1} messageEmptyItem={'Email не заполнен'}
                    onChange={e => setValues(({ ...values, email: e.target.value }))}
                    valid={!errors.email} errorMessage={errors.email}
                />
                <NewAutoSelect
                    label={'Номер телефона'}
                    searchBy={'phone'}
                    link={`/ajax/account?fields=id,name,email,phone,website&filter[phone][like]=`}
                    updateData={(item) => setDataValues({ account: item, account_id: item.id })}
                    entity={null} minLength={1} messageEmptyItem={'Телефон не заполнен'}
                    onChange={e => setValues(({ ...values, phone: e.target.value }))}
                    valid={!errors.phone} errorMessage={errors.phone}
                />
                <NewAutoSelect
                    label={'Сайт'}
                    searchBy={'website'}
                    link={`/ajax/account?fields=id,name,email,phone,website&filter[website][like]=`}
                    updateData={(item) => setDataValues({ account: item, account_id: item.id })}
                    entity={null} minLength={1} messageEmptyItem={'Сайт не заполнен'}
                    onChange={e => setValues(({ ...values, website: e.target.value }))}
                    valid={!errors.website} errorMessage={errors.website}
                />
                {values.name.trim().length > 1 && <button
                    onClick={saveAccount}
                    style={{ marginTop: '10px', width: '100%' }}
                    className="success btni">Создать контрагент
                </button>}
            </>}
        </div>
    )
}

export const AccountCard = React.memo(AccountCardRender)