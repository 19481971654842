import React, {useEffect, useState} from "react";
import AceEditor from "react-ace";
import axios from "axios";
import {TRACKER_URL} from "../../components/integration/list/Tracker";
import {MainInfo} from "./TrackerSite/MainInfo";

export const TrackerSettings = ({ location, history, match }) => {
    const [siteSettings, setSiteSettings] = useState({})
    const { params: { id } } = match
    const urlParams = new URLSearchParams(location.search);

    const getData = async () => {
        const { status, data } = await axios.get(`${TRACKER_URL}/v1/site/view?id=${id}&access-token=${localStorage.getItem('tracker-token')}`)
    }

    useEffect(() => {
        getData().then()
    },[])

    return (
        <div className="page">
            <div className="new-tabs">
                <div className="new-tabs__items">
                    <div
                        onClick={() => history.push('?layout=main')}
                        className={`new-tabs__item ${(!urlParams.get('layout') || urlParams.get('layout') === 'main') && 'new-tabs__item-active'}`}
                    >Основные настройки</div>
                    <div
                        onClick={() => history.push('?layout=purpose')}
                        className={`new-tabs__item ${(urlParams.get('layout') === 'purpose') && 'new-tabs__item-active'}`}
                    >Цели</div>
                    <div
                        onClick={() => history.push('?layout=report')}
                        className={`new-tabs__item ${(urlParams.get('layout') === 'report') && 'new-tabs__item-active'}`}
                    >Аналитика</div>
                </div>
                <div className="new-tabs__container">
                    {(!urlParams.get('layout') || urlParams.get('layout') === 'main') && <MainInfo
                        id={id} getData={getData}
                        siteSettings={siteSettings}
                        setSiteSettings={data => setSiteSettings({ ...siteSettings, ...data })}
                    />}
                </div>
            </div>
        </div>
    )
}