import React from "react";
import Bgdrop from "./Bgdrop";
import {CSSTransition} from "react-transition-group";


const CenterModalContainer = ({ children, title, close, isOpen, isDisabledModal }) => {
    return <CSSTransition in={isOpen} timeout={200} classNames="my-node" unmountOnExit>
        <>
            <div className="center-modal">
                {!isDisabledModal &&
                    <div className="center-modal-header">
                        <h2>{title}</h2>
                        <i onClick={close} className="flaticon-close" />
                    </div>
                }
            <div className="center-modal-body">
                {children}
            </div>
        </div>
        <Bgdrop onClick={close} />
    </>
    </CSSTransition>
}

export default CenterModalContainer;