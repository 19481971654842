import {entity} from "../../../components/ListComponentEntity";
import {NavLink} from "react-router-dom";
import React from "react";
import {NewList} from "../../../components/NewList/NewList";

export const ListStocktakeNew = (props) => {
    return (
        <NewList
            { ...props } stockNav additionalFieldsExists isFilterExists
            entity={'stock/stocktake'}
            entity_type={21}
            requiredFields={'id,name,stock_id,date'}
            requiredExpand={'stock'}
            deleteToShowColumn={'id,name,stock_id,date'}
            filterFields={entity.stocktake.fArr}
            singleSearch={entity.stocktake.fArr[0]}
            headingColumns={['Номер', 'Статус', 'Дата']}
            RenderColumn={(item) => (
                <>
                    <td>
                        <NavLink to={`/stock/stocktake/${item.id}`}  className={'nowrap-list-column'}>{item.name}</NavLink>
                    </td>
                    <td>
                        {item.stock && <span className="label-st" style={{ backgroundColor: 'rgb(255, 193, 100)' }}>{item.stock.name}</span>}
                    </td>
                    <td>
                        <div>{item.date}</div>
                    </td>
                </>
            )}
            createButtonRender={() => <button onClick={() => props.updateData('Stocktake')} className="open-modal-button btni">Новая инвентаризация</button>}
            rightNavbarConfig={{
                additionalFields: '/fieldsettings/21',
                columns: true
            }}
            massDoConfig={{ deleteAll: `/ajax/stock/stocktake/delete-all` }}
            contextNav={{ edit: true, task: true, print: true, delete: true }}
            sort={entity.stocktake.fArr.filter(el => el.sort)}
        />
    )
}