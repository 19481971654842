import React, {useEffect, useState} from "react";
import Modal from "../components/Modal";
import {FieldGen, setField, unLink, updateData} from "../components/fieldGen";
import formValid from "../components/UI/formValid";
import myaxios from "../components/Axios";
import Notif from "../components/Notif";
import {withRouter} from "react-router-dom";
import Input from "../components/UI/Input";

const AddEmailForm = ({ close, history, isOpen }) => {
    const [state, setMyState] = useState({
        formControl: {
            name: {
                type: 'input',
                label: 'Название формы',
                value: '',
                valid: true,
                validation: {required: true}
            },
            form_id: {
                type: 'input',
                label: 'Уникальный url формы',
                value: '',
                valid: true,
                validation: {required: true}
            },
            position: {
                type: 'input',
                label: 'id блока, в который вставить форму',
                value: '',
                valid: true,
                validation: {required: true}
            },
        },
        loading: true,
        conditions:0
    })

    const setState = (st, func) => {
        Object.keys(st).map((key) => { state[key] = st[key]});
        setMyState(prevState => { return {...prevState, ...state} });
        if(func) func();
    };

    const isFormValid = () => {
        let formControl = state.formControl;
        let isFormValid = true;
        Object.keys(formControl).map((key, index) => {
            formControl[key].valid = formValid(formControl[key].value, formControl[key].validation)
            isFormValid = formControl[key].valid && isFormValid
        });
        setState({ formControl });
        return isFormValid;
    };

    const save = () => {
        if(!isFormValid()) return false

        let result = {};
        Object.keys(state.formControl).map(key => {result[key] = state.formControl[key].value})
        result.conditions = [{type: "delay", value: state.conditions}]
        myaxios.post(`/ajax/mail/broadcast/form`, result).then(res => {
            if(res.status === 200 || res.status === 201) {
                Notif("save");
                history.push(`/marketing/forms/${res.data.id}`)

            }
        })
    }

    return <Modal isOpen={isOpen} title="Создать форму" save={save} close={close}>
        <FieldGen
            arr={state.formControl}
            setField={(e, type) => setState({formControl: setField(state.formControl, type, e)})}
        />
        <Input
            label="Задержка"
            value={state.conditions}
            onChange={e => setState({ conditions: e.target.value })}
        />
    </Modal>
}

export default withRouter(AddEmailForm)