import React from 'react';
import Items from './Items';
import myaxios from '../../components/Axios';
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from '../../components/UI/Loader';
import Notif from '../../components/Notif';


class Notifications extends React.Component {
    state = {
        notifications: [],
        read: false,
        pageNumber: 1,
        pageCount: 1,
        perPage: 50,
        totalCount: Number,
        loading:true,
        is_seen:0
    };
    showItems = () => {
        myaxios.get(`/ajax/notification?per-page=${this.state.perPage}&filter[is_seen]=${this.state.is_seen}`, {
            method: 'get',
        }).then((response) => {
            this.setState({notifications: response.data.items, totalCount: response.data._meta.totalCount, links: response.data._links, pageCount: response.data._meta.pageCount, loading: false});
        }).catch((error)=>{
            this.setState({ loading: false });
        });
    }
    componentDidMount(){
        this.showItems()
    }
    fetchMoreData = () => {
        if(this.state.pageNumber < this.state.pageCount) {
            this.state.pageNumber++;
            setTimeout(() => {
                myaxios.get(`/ajax/notification?per-page=${this.state.perPage}&filter[is_seen]=${this.state.is_seen}`, {
                    params: {
                        page: this.state.pageNumber
                    }
                }).then((response) => {
                    this.setState({
                        notifications: this.state.notifications.concat(response.data.items)
                    });
                })
            }, 1000);
        }
    };
    render(){
        return (this.state.loading) ? <Loader contain /> : <div className="sbform create-activ">
            <div className="rmod-header"><b>Уведомления</b></div>
            <div className="mod-cont">
                <div className="modal-tab">
                    <a className={this.state.is_seen === 0 ? 'active' : ''} onClick={() => this.setState({ is_seen: 0 }, this.showItems)}>Непрочитанные</a>
                    <a className={this.state.is_seen === 1 ? 'active' : ''} onClick={() => this.setState({ is_seen: 1 }, this.showItems)}>Все</a>
                </div>
                <div className="m-demo mt10">
                    <div className="m-demo__preview">
                        <div className="m-list-timeline">
                            <div className="m-list-timeline__items" id="scrollableDiv">
                                <InfiniteScroll
                                    dataLength={this.state.notifications.length}
                                    next={this.fetchMoreData}
                                    hasMore={true}
                                    scrollableTarget="right-modal"
                                    loader={(this.state.pageNumber < this.state.pageCount) ? <p style={{textAlign: "center"}}>Загрузка</p> : null}
                                >
                                {
                                    this.state.notifications.map((item, index) =>
                                        <Items
                                            close={this.props.close}
                                            key={index}
                                            id={item.id}
                                            type={item.key}
                                            time={item.timeago}
                                            icon={item.icon}
                                            leadLink={item.leadLink}
                                            title={item.leanTitle}
                                            phase={item.phase}
                                            failStatus={item.failStatus}
                                            coment={item.coment}
                                            sum={item.sum}
                                            user={item.user}
                                            taskText={item.message}
                                            taskstatus={item.taskstatus}
                                            invoicelink={item.invoicelink}
                                            invoicenumb={item.invoicenumb}
                                            emailsubject={item.emailsubject}
                                            emaillink={item.emaillink}
                                            emailtype={item.emailtype}
                                            message={item.message}
                                            route={JSON.parse(item.route)}
                                            is_seen={item.is_seen}
                                            //route={item.route}
                                        />
                                    )
                                }
                                </InfiniteScroll>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default Notifications;