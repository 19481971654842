import React, {Component} from 'react';
import AutoSelect from '../../../components/UI/AutoSelect';
import {withRouter} from 'react-router-dom';
import formValid from '../../../components/UI/formValid';
import Notif from '../../../components/Notif';
import myaxios from '../../../components/Axios';

class Attached extends Component{
    state = {
        accountControl: {
            name: {
                value: '',
                valid: true,
                validation: {
                    required: true,
                    minLength: 3
                }
            },
            email: {
                value: '',
                valid: true,
                validation: {
                    required: false,
                    email: true
                }
            },
            phone: {
                value:'',
                valid: true,
                validation: {
                    required: false,
                    phone: true
                }
            },
            website: {
                value:'',
                valid: true,
                validation: {
                    required: false,
                    website: true
                }
            }
        },
        contactControl: {
            name: {
                value: '',
                valid: true,
                validation: {
                    required: true,
                    minLength: 3
                }
            },
            email: {
                value: '',
                valid: true,
                validation: {
                    required: false,
                    email: true
                }
            },
            phone: {
                value:'',
                valid: true,
                validation: {
                    required: false,
                    phone: true
                }
            },
        },
        isFormAccountValid: true,
        isFormContactValid: true,
        tchAccount: true,
        tchContact: true
    };
    setFieldAccount = (event, type) => {
        const accountControl = {  ...this.state.accountControl };
        if(accountControl.email.value.length >= 3 || accountControl.phone.value.length >= 6 || accountControl.website.value.length >= 3){
            this.setState({tchAccount: false})
        } else {
            this.setState({tchAccount: true})
        }
        const control = { ...accountControl[type] };
        control.value = event.target.value;
        accountControl[type] = control;
        this.setState({
            accountControl
        })
    };
    sendFormAccount = event => {
        event.preventDefault();
        if(this.isFormAccountValid()){
            this.setState({ loading: true });
            myaxios.post(`/ajax/account`, {
                name: this.state.accountControl.name.value,
                website: this.state.accountControl.website.value,
                phone: this.state.accountControl.phone.value,
                email: this.state.accountControl.email.value,
            }).then(responce => {
                if(responce.status === 200) {
                    setTimeout(()=>this.props.updateData(responce.data.id,
                        {name: this.state.accountControl.name.value, email: this.state.accountControl.email.value, phone: this.state.accountControl.phone.value, website: this.state.accountControl.website.value}, "account", 3
                        ),300)
                    this.setState({tchAccount: true});
                    Notif("save")}
            }).catch(function (error) {
                Notif("Error", error)
            });
        } else Notif("Error")
    };
    isFormAccountValid = () => {
        let accountControl = this.state.accountControl;
        let isFormAccountValid = true;
        Object.keys(accountControl).map((key, index) => {
            accountControl[key].valid = formValid(accountControl[key].value, accountControl[key].validation)
            isFormAccountValid = accountControl[key].valid && isFormAccountValid
        });this.setState({accountControl, isFormAccountValid});
        return isFormAccountValid;
    };


    setFieldContact = (event, type) => {
        const contactControl = {  ...this.state.contactControl };
        if(contactControl.name.value.length >= 3){
            this.setState({tchContact: false})
        } else {
            this.setState({tchContact: true})
        }
        const control = { ...contactControl[type] };
        control.value = event.target.value;
        contactControl[type] = control;
        this.setState({contactControl})
    };
    sendFormContact = event => {
        event.preventDefault();
        if(this.isFormContactValid()){
            this.setState({ tchContact: true });
            myaxios.post(`/ajax/contact`, {
                first_name: this.state.contactControl.name.value,
                mobile_phone: this.state.contactControl.phone.value,
                email: this.state.contactControl.email.value,
            }).then(responce => {
                if(responce.status === 200 || responce.status === 201 || responce.status === 204) {
                    setTimeout(()=>this.props.updateData(responce.data.id, responce.data, "account", 3
                    ),300)
                    this.setState({tchAccount: true});
                    Notif("save")}
            }).catch(function (error) {
                Notif("Error", error)
            });
        } else Notif("Error")
    };
    isFormContactValid = () => {
        let contactControl = this.state.contactControl;
        let isFormContactValid = true;
        Object.keys(contactControl).map((key, index) => {
            contactControl[key].valid = formValid(contactControl[key].value, contactControl[key].validation)
            isFormContactValid = contactControl[key].valid && isFormContactValid
        });this.setState({contactControl, isFormContactValid});
        return isFormContactValid;
    };
    render(){
        return <div>
            {
                (this.props.contact) ? <div>
                    <div className="contact-left-col contactsAddBlock">
                        <a onClick={()=>this.props.unLink("contact", "2", this.props.contact.id)} className="lead-form-close">x</a>
                        <h3>Клиент</h3>
                        <div>
                            {(this.props.contact.name) ?
                                <div className="card-block">
                                    <em>ФИО</em>
                                    <a onClick={() => this.props.history.push('/contact/' + this.props.contact.id)}>{this.props.contact.name}</a>
                                </div> : null}
                            {(this.props.contact.position) ?
                                <div className="card-block">
                                    <em>Должность</em>
                                    <span>{this.props.contact.position}</span>
                                </div> : null}
                            {(this.props.contact.mobile_phone) ?
                                <div className="card-block">
                                    <em>Мобильный телефон</em>
                                    <span className="toggleMenu">{this.props.contact.mobile_phone}<ul>
                                        <li><a onClick={() => this.props.updateDataContact("Call", {tel: this.props.contact.mobile_phone, id: this.props.contact.id, entity: "contact"} )}>Позвонить</a></li>
                                        <li><a onClick={() => this.props.updateDataContact("SendSms", {tel: this.props.contact.mobile_phone, id: this.props.contact.id, entity: "contact"} )}>Отравить SMS</a></li></ul></span>
                                </div> : null}
                            {(this.props.contact.work_phone) ?
                                <div className="card-block">
                                    <em>Рабочий телефон</em>
                                    <span className="toggleMenu">{this.props.contact.work_phone}<ul>
                                        <li><a onClick={() => this.props.updateDataContact("Call", {tel: this.props.contact.work_phone, id: this.props.contact.id, entity: "contact"})}>Позвонить</a></li>
                                        <li><a onClick={() => this.props.updateDataContact("SendSms", {tel: this.props.contact.work_phone, id: this.props.contact.id, entity: "contact"} )}>Отравить SMS</a></li></ul></span>
                                </div> : null}
                            {(this.props.contact.email) ?
                                <div className="card-block">
                                    <em>Email</em>
                                    <span className="toggleMenu">{this.props.contact.email}<ul>
                                        <li><a onClick={() => this.props.updateDataContact("SendMail", {from: this.props.contact.email})}>Отправить Email</a></li></ul></span>
                                </div> : null}
                        </div>
                    </div>
                </div> : null
            }
            {
                (!this.props.contact) ? <div className="contact-left-col">
                    <h3>Контакт</h3>
                    <form className="sbform" action="#">
                        <AutoSelect
                            label="ФИО"
                            link={`/ajax/contact?filter[name][like]=`}
                            minLength="1"
                            result="name"
                            event="name"
                            addType="contact"
                            endParrams="2"
                            shouldValidate={true}
                            valid={this.state.contactControl.name.valid}
                            setField={this.setFieldContact}
                            updateData={this.props.updateData}
                        />
                        <AutoSelect
                            label="Номер телефона"
                            link={`/ajax/contact/search?phone=`}
                            minLength="1"
                            result="name"
                            event="phone"
                            addType="contact"
                            endParrams="2"
                            shouldValidate={true}
                            valid={this.state.contactControl.phone.valid}
                            setField={this.setFieldContact}
                            updateData={this.props.updateData}
                        />
                        <AutoSelect
                            label="Email"
                            link={`/ajax/contact?filter[email][like]=`}
                            minLength="1"
                            result="email"
                            event="email"
                            addType="contact"
                            endParrams="2"
                            shouldValidate={true}
                            openEntity={this.props.openEntity}
                            valid={this.state.contactControl.email.valid}
                            setField={this.setFieldContact}
                            updateData={this.props.updateData}
                        />
                        <button className="btn-m" onClick={this.sendFormContact} disabled={this.state.tchContact}>Создать контакт</button>
                    </form>
                </div> : null
            }
        </div>
    }};

export default withRouter(Attached);