export const operator = {
    number: [
        {value:'eq', label:'Равно'},
        {value:'neq', label:'Не равно'},
        {value:'>', label:'Больше'},
        {value:'<', label:'Меньше'},
        /*{value:'<=', label:'Меньше равно'},
         {value:'>=', label:'Больше равно'},*/
    ],
    text: [
        {value:'like', label:'Содержит'},
        {value:'nlike', label:'Не содержит'},
    ],
    select: [
        {value:'like', label:'Содержит'},
        {value:'nlike', label:'Не содержит'},
    ],
    date: [
        {value:'like', label:'Равно'},
        {value:'nlike', label:'Не равно'},
        {value:'<', label:'Раньше'},
        {value:'>', label:'Позже'},
    ],
    lookup: [
        {value:'in', label:'Содержит'},
        {value:'nin', label:'Не содержит'},
    ],
    entity: [
        {value:'in', label:'Содержит'},
        {value:'nin', label:'Не содержит'},
    ],
    tags: [
        {value:'in', label:'Содержит'},
        {value:'nin', label:'Не содержит'},
    ],
    products: [
        {value:'in', label:'Содержит'},
        {value:'nin', label:'Не содержит'},
    ]
}