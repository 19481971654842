import React, {useEffect, useState} from "react";
import Notif from "../../components/Notif";
import {
    deleteInvoiceService,
    getInvoicePrepareService,
    getSingleInvoiceService, updateInvoiceService
} from "../../services/invoice/invoiceService";
import {parseResponse, parseResponseError} from "../../components/cardViewComponents/parseFunctions";
import GetPostAditionalFields from "../../components/get_post_aditionalFields";
import {ModalLoader} from "../../components/UI/Loader";
import LeaveModal from "../../components/other/LeaveModal";
import {TagsNew} from "../../components/cardViewComponents/TagsNew";
import {MemoButton} from "../../components/cardViewComponents/MemoButton";
import {DropDownButton} from "../../components/cardViewComponents/DropDownButton";
import PrintPlates from "../../Modal/PrintPlates";
import IconButton from "../../components/other/IconButton";
import {GenerateForm} from "../../components/cardViewComponents/GenerateForm";
import CustomCheckbox from "../../components/UI/CustomCheckbox";
import {ContactCard} from "../../components/cardViewComponents/ContactCard";
import {AccountCard} from "../../components/cardViewComponents/AccountCard";
import {Tasks} from "../../components/Task";
import {filesFunc, TabsRender} from "../../components/fieldGen";
import {CSSTransition} from "react-transition-group";
import Products from "../../components/products";
import Related from "../../components/related/related";
import History from "../../components/history/history";
import Files from "../../components/files/files";
import {InfoTab} from "./components/InfoTab";
import myaxios from "../../components/Axios";
import {TaskModal, updateEntityTasks} from "../tasks/TaskModal";
import CombineOrders from "../../Modal/CombineOrders";
import CenterModalContainer from "../../Modal/centerModalContainer";
import SplitOrder from "../../Modal/splitOrder";
import {additionalFieldsValidation} from "../../components/cardViewComponents/additionalFieldsValidation";
import {validationData} from "../../components/UI/formValid";
import {validationConfig} from "../../components/cardViewComponents/validationConfig";
import {formatDate} from "../../components/ListComponent";
import {ConflictModal} from "../../components/cardViewComponents/ConflictModal";
import {FilesDropDown} from "../../components/FilesDropDown";

export const tabsConfig = [
    {value:"information", label:"Информация"},
    {value:"products", label:"Продукты"},
    {value:"history", label:"Хронология"},
    {value:"related", label:"Связи"},
    {value:"files", label:"Файлы"},
]

const invoiceConfig = {
    prefix_id: {
        label: 'Префикс',
        type: 'select',
        options: [],
        className: 'w30',
        options_from: 'prefixes', val: 'id', lab: 'name',
        validation: { required: true },
        zone: 1,
    },
    inv_id: {
        label: 'Номер',
        type: 'text',
        className: 'w70',
        validation: { required: true, maxLength: 255 },
        zone: 1
    },
    status_id: {
        label: 'Статус',
        type: 'select',
        options: [],
        className: 'w100',
        options_from: 'statuses', val: 'id', lab: 'value',
        validation: { required: true },
        zone: 1,
    },
    stock_id: {
        label: 'Склад',
        type: 'select',
        options: [],
        className: 'w100',
        options_from: 'stocks', val: 'id', lab: 'name',
        validation: { required: true },
        zone: 1,
    },
    created_date: {
        label: 'Дата выставления',
        type: 'date',
        className: 'w100',
        validation: { maxLength: 255 },
        zone: 1
    },
    // payment_date: {
    //     label: 'Предполагаемая оплаты',
    //     type: 'date',
    //     className: 'w100',
    //     validation: { maxLength: 255 },
    //     zone: 1
    // },
    payed_at: {
        label: 'Фактическая дата оплаты',
        type: 'date',
        className: 'w100',
        validation: { maxLength: 255 },
        zone: 1
    },
    owner_id: {
        label: 'Ответственный',
        type: 'entity',
        link: "/ajax/settings/user?filter[name][like]=",
        result: 'name',
        entity_from: 'owner',
        entityName: 'name',
        className: 'w100',
        minLength: 0,
        validation: { required: true },
        zone: 1
        // redirectLink
    },
    is_reserved: {},
    exchange_rate: {
        validation: { required: true, number: true },
    },
    currency_id: {
        validation: { required: true },
    },
    contact_id: { type: 'entity', entity_from: 'contact' },
    account_id: { type: 'entity', entity_from: 'account' },
    provider_id: { type: 'entity', entity_from: 'provider' },
    order_id: { type: 'entity', entity_from: 'order' },
    opportunity_id: { type: 'entity', entity_from: 'opportunity' },
    payment_detail_id: { type: 'entity', entity_from: 'paymentDetail' },
    comment: {},
    name: {},
    cost: {},
    payment: {},
    editorTags: {},
    created_at: {},
    updated_at: {},
}

export const InvoiceViewNew = ({ match, history, location }) => {
    const urlParams = new URLSearchParams(location.search);
    const { params: { id } } = match
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState({})
    const [disabled, setDisabled] = useState({inv_id: true, prefix_id:true})
    const [conflict, setConflict] = useState(false)
    const [modalData, setModalData] = useState(null)
    const [values, setValues] = useState({
        inv_id: '',
        currency_id: '',
        created_date: new Date(),
        payment_date: '',
        payed_at: '',
        status_id: '',
        statuses: [],
        exchange_rate: '',
        provider_id: '',
        provider: null,
        owner_id: '',
        contact_id: '',
        contact: null,
        account_id: '',
        account: null,
        opportunity_id: '',
        opportunity: null,
        order_id: '',
        order: null,
        project_id: '',
        project: null,
        payment_detail_id: '',
        paymentDetail: null,
        defaultAccount: null,
        prefix_id: '',
        payment: '',
        cost: '',
        prefixes: [],
        stock_id: '',
        comment: '',
        name: '',
        files: [],
        tasks: [],
        additionalFields: [],
        receipts: [],
        editorTags: '',
        begin_update_datetime: null,
        updated_at: null,
        tch: true,
        updated_number: 0,
        saved_currency_id: '',
        payments: [],
    })

    const getPrepare = async (saveData = {}) => {
        try {
            const { data, status } = await getInvoicePrepareService()
            if(status === 200) {
                await getData(data, saveData)
            }
        } catch (e) { }
        setLoading(false)
    }

    const getData = async (prepareData, saveData) => {
        setLoading(true)

        try {
            const { data, status, response } = await getSingleInvoiceService({
                id, expand: 'tasks,source,contact,additionalFields,account,order,project,opportunity,files,owner,currencies,stock,owner,provider,paymentDetail,receipts'
            })

            const parseResponseData = parseResponseError(response);
            if(parseResponseData.notFound) history.push('/404')

            const dataArr = {
                ...values,
                ...parseResponse(invoiceConfig, data, {}),
                exchange_rate: data.exchange_rate || 1,
                prefixes: prepareData.prefixes,
                statuses: prepareData.statuses,
                currencies: prepareData.currencies,
                stocks: prepareData.stocks,
                receipts: data.receipts,
                defaultAccount: prepareData.defaultAccount,
                files: data.files,
                tasks: data.tasks,
                additionalFields: GetPostAditionalFields("get", data.additionalFields) || [],
                updated_number: values.updated_number += 1,
                saved_currency_id: data.currency_id,
                ...saveData
            }

            if(status === 200 && data.id) {
                setValues(dataArr)
            }
        } catch (e) { console.error('getOrderDataError', e) }

        setLoading(false)
    }

    useEffect(() => {
        getPrepare().then()
    }, [id])

    const saveItem = async (forcibly) => {
        const { account_id, comment, contact_id, created_date, currency_id, exchange_rate, inv_id, is_reserved, opportunity_id, order_id,
            owner_id, payed_at, payment_date, payment_detail_id, prefix_id, project_id, provider_id, status_id, stock_id, editorTags, updated_at } = values

        const additionalFieldsValidationRes = additionalFieldsValidation(values.additionalFields)

        const errorsData = validationData(
            {...values, ...additionalFieldsValidationRes.values},
            {...validationConfig(invoiceConfig), ...additionalFieldsValidationRes.validationRules}
        )
        console.log('errorsData', errorsData)

        setErrors(errorsData)
        if(Object.keys(errorsData).length > 0) {
            Notif('required-fields')
            return
        }

        setLoading(true)

        try {
            const { data, status, response } = await updateInvoiceService({ id, data: {
                    account_id, comment, contact_id, created_date, currency_id, exchange_rate, inv_id, is_reserved, opportunity_id, order_id,
                    owner_id, payment_date, payment_detail_id, prefix_id, project_id, provider_id, status_id, stock_id,
                    payed_at: +status_id === 4 && !payed_at ? formatDate(new Date()) : payed_at,
                    editorTags, additionalFields: GetPostAditionalFields("post", values.additionalFields),
                    // begin_update_datetime: forcibly === 'ok' ? null : updated_at,
                    begin_update_datetime: null,
                }})

            if(status === 200 && data.id) {
                Notif('save', 'Запись успешно изменена')
                setTimeout(() => getPrepare({ updated_at: data.updated_at, tch: true, begin_update_datetime: null }), 200)
            }

            const parseResponseData = parseResponseError(response);
            if(parseResponseData.saveConflict) setConflict(true)
            if(parseResponseData.validationErrors) {
                setErrors(parseResponseData.validationErrors)
            }

        } catch (e) { console.error(e) }

        setLoading(false)
    }

    const deleteItem = async () => {
        if(window.confirm('Удалить счет?')) {
            setLoading(true)
            try {
                const { status } = await deleteInvoiceService({ id })
                if(status === 200 || status === 204) {
                    setValues(prevState => ({ ...prevState, tch: true }))
                    Notif('delete', 'Счет успешно удален')
                    history.push('/invoice')
                }
            } catch (e) { console.error(e) }
            setLoading(false)
        }
    }

    const changeFields = (key, value, item) => {
        let obj = {}
        if(item) {
            if(item === 'clear') obj[invoiceConfig[key].entity_from] = null
            else obj[invoiceConfig[key].entity_from] = item
        }

        setValues({
            ...values, ...obj, [key]: item ? item.id : value,
            begin_update_datetime: !values.begin_update_datetime ? new Date() : values.begin_update_datetime,
            tch: false
        })
    }

    const createShipment = () => {
        if(window.confirm()){
            myaxios.post(`/ajax/invoice/${id}/create-shipment`).then(res => {
                if (res.status === 200 || res.status === 201) {
                    history.push(`/stock/shipment/${res.data}`);
                    Notif("save", "Отгрузка успешно создана")
                }
            })
        }
    }

    const createReturn = () => {
        if(window.confirm()){
            myaxios.post(`/ajax/invoice/${id}/create-provider-return`).then(res => {
                if (res.status === 200 || res.status === 201) {
                    history.push(`/stock/return/${res.data}`);
                    Notif("save", "Возврат успешно создан")
                }
            })
        }
    }

    const createReceipt = () => {
        if(!window.confirm('Создать чек?')){
            return false;
        }
        myaxios.post(`/ajax/invoice/${id}/create-receipt`).then(res => {
            if(res.status === 200 || res.status === 201){
                Notif("save", "Чек успешно создан!")
                const receipts = JSON.parse(JSON.stringify(values.receipts));
                receipts.push(res.data);
                setValues({ ...values, receipts })
            }
        })
    }

    return (
        <>
            {loading && <ModalLoader />}
            <div className="page">
                <LeaveModal when={!values.tch} />
                <div className="newList-btn-group">
                    <div className="newList-btn-group-left">
                        <TagsNew
                            editorTags={values.editorTags} entity={'invoice'}
                            setValues={data => setValues({ ...values, editorTags: data, tch: false })}
                        />
                        <MemoButton
                            onClick={() => setModalData({ type: 'task' })}
                            mobileIcon={'flaticon-notes mdb'} title={'Добавить задачу'} className={'mr0'} />
                        <DropDownButton
                            label={'Создать документ'}
                            items={({ close }) => <>
                                <li onClick={createShipment}><a>Создать отгрузку на основании счета</a></li>
                                <li onClick={createReturn}><a>Создать возврат на основании счета</a></li>
                                <li onClick={() => { setModalData({ type: 'combineOrders' }); close() }}><a>Обьеденить счета</a></li>
                                <li onClick={() => { setModalData({ type: 'splitOrder' }); close() }}><a>Разделить счет</a></li>
                                <li onClick={createReceipt}><a>Создать чек</a></li>
                            </>}
                        />
                        <PrintPlates entityId={13} entity={`invoice`} id={id} />
                    </div>
                    <div className="newList-btn-group-right">
                        <a onClick={deleteItem} className="butt-group-card-wrap btni ml10">
                            <span>Удалить</span>
                            <i className="flaticon-delete-2 mdb" />
                        </a>
                        <MemoButton onClick={saveItem} disabled={values.tch} mobileIcon={"flaticon-interface-1 mdb"} title={'Сохранить'} className={'ml5'} />
                        <IconButton href="https://sboxcrm.com/docs/invoice" />
                    </div>
                </div>
                <div className="clear" />

                <div className="contact-page sbform">
                    <div className="contact-left">
                        <div className="contact-left-col">
                            <h3>Данные по счету</h3>
                            <GenerateForm
                                config={invoiceConfig}
                                { ...{ values, errors, disabled } }
                                onChange={changeFields}
                                onSaveData={saveItem}
                            />
                            <CustomCheckbox
                                checked={values.is_reserved === 1} label={'Резерв продуктов'} style={{ margin: '10px 0' }}
                                onChange={() => setValues(prevState => ({ ...prevState, is_reserved: values.is_reserved === 1 ? 0 : 1, tch: false }))}
                            />
                            <div className="clear" />
                        </div>

                        {!values.account && <ContactCard
                            contact={values.contact}
                            setDataValues={data => {
                                setValues(({...values, ...data, tch: false}))
                            }}
                        />}
                        {!values.contact && <AccountCard
                            account={values.account}
                            setDataValues={data => {
                                setValues(({...values, ...data, tch: false}))
                            }}
                        />}
                    </div>

                    <div className="contact-right-col">
                        <Tasks tasks={values.tasks} openTask={data => setModalData(data)} />
                        <TabsRender
                            setState={({ layout }) => history.push(`?layout=${layout}`)}
                            state={{ layout: urlParams.get('layout') || tabsConfig[0].value }}
                            arr={tabsConfig}
                        />
                        <div className="tab-container">
                            <CSSTransition in={(urlParams.get('layout') || tabsConfig[0].value) === tabsConfig[0].value} timeout={{enter: 0, exit: 0}} classNames="my-node" unmountOnExit>
                                <InfoTab
                                    {...{ values, errors, disabled, changeFields, id, saveItem }}
                                    invoice_updated_at={values.updated_number}
                                    setValues={data => setValues(prevState => ({ ...prevState, ...data, tch: false }))}
                                    setPaymentValues={data => setValues(prevState => ({ ...prevState, ...data }))}
                                />
                            </CSSTransition>
                            <CSSTransition in={urlParams.get('layout') === tabsConfig[1].value} timeout={{enter: 0, exit: 0}} classNames="my-node" unmountOnExit>
                                <Products
                                    type="invoice" id={id} related="13" isTotal isPrice isDiscount isValues
                                    updatePrice={sum => setValues({ ...values, cost: sum })}
                                />
                            </CSSTransition>
                            <CSSTransition in={urlParams.get('layout') === tabsConfig[2].value} timeout={{enter: 0, exit: 0}} classNames="my-node" unmountOnExit>
                                <History type="invoice" id={id} />
                            </CSSTransition>
                            <CSSTransition in={urlParams.get('layout') === tabsConfig[3].value} timeout={{enter: 0, exit: 0}} classNames="my-node" unmountOnExit>
                                <Related
                                    type="invoice" id={id} related="13" invoice_id={id} invoice={values.name}
                                    contactEmail={(values.contact) ? values.contact.email : null}
                                    accountEmail={(values.account) ? values.account.email : null}
                                    tasks sms mails shipments providerReturn isPlates purchases workorders
                                />
                            </CSSTransition>
                            <CSSTransition in={urlParams.get('layout') === tabsConfig[4].value} timeout={{enter: 0, exit: 0}} classNames="my-node" unmountOnExit>
                                <FilesDropDown
                                    related_id={id}
                                    related_type={"13"}
                                    files={values.files}
                                    updateFile={filesData => setValues(prevState => ({
                                        ...prevState,
                                        files: [filesData, ...prevState.files]
                                    }))}
                                    updateFiles={files => setValues(prevState => ({ ...prevState, files }))}
                                />
                            </CSSTransition>
                        </div>
                    </div>
                </div>
            </div>

            {conflict && <ConflictModal
                entity={'invoice'} id={id} updated_at={values.begin_update_datetime}
                save={() => saveItem('ok')} close={() => setConflict(false)}
            />}
            <TaskModal
                id={modalData && modalData.id} close={() => setModalData(null)} index={modalData && modalData.index}
                task={(type, data, index) => { setValues({ ...values, tasks: updateEntityTasks(values.tasks, type, data, index) }) }}
                related={{ id, type: 13 }} isOpen={modalData && modalData.type === 'task'}
            />

            <CombineOrders
                isOpen={modalData && modalData.type === 'combineOrders'}
                close={() => setModalData(null)} entity={"invoice"} entityId={id}
                refresh={() => { history.push(`?layout=${tabsConfig[0].value}`); Notif('save', 'Счета успешно обьеденены'); setModalData(null) }}
            />

            {modalData && modalData.type === 'splitOrder' &&
            <CenterModalContainer isOpen={modalData && modalData.type === 'splitOrder'} title="Разделить счет" close={() => setModalData(null)}>
                <SplitOrder entity="invoice" orderId={id} history={history} close={() => setModalData(null)} />
            </CenterModalContainer>}
        </>
    )
}