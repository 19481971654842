import React, {useEffect, useState} from "react";
import Modal from "../../components/Modal/Modal";
import {GenerateForm} from "../../components/cardViewComponents/GenerateForm";
import {validationData} from "../../components/UI/formValid";
import {validationConfig} from "../../components/cardViewComponents/validationConfig";
import Notif from "../../components/Notif";
import {createLeadService, getLeadPrepareService} from "../../services/lead/leadService";
import { withRouter } from 'react-router-dom'
import {createEvent} from "../../services/other/events";

const fieldsConfig = {
    name: {
        label: 'Название',
        type: 'text',
        className: 'w100',
        validation: { required: true, maxLength: 255 }, zone: 1
    },
    amount: {
        label: 'Бюджет',
        type: 'number',
        className: 'w60',
        validation: { number: true, maxLength: 255 }, zone: 1
    },
    currency_id: {
        label: 'Валюта',
        type: 'select',
        options: [],
        className: 'w40',
        options_from: 'currencies',
        prepare: 'currencies', val: 'id', lab: 'name',
        validation: { required: true, maxLength: 255 }, zone: 1
    },
    first_name: {
        label: 'Имя',
        type: 'text',
        className: 'w100',
        validation: { maxLength: 255 }, zone: 1
    },
    last_name: {
        label: 'Фамилия',
        type: 'text',
        className: 'w100',
        validation: { maxLength: 255 }, zone: 1
    },
    company: {
        label: 'Компания',
        type: 'text',
        className: 'w100',
        validation: { maxLength: 255 }, zone: 1
    },
    phone: {
        label: 'Номер телефона',
        type: 'text',
        className: 'w100',
        validation: { maxLength: 255 }, zone: 1
    },
    email: {
        label: 'Email',
        type: 'text',
        className: 'w100',
        validation: { maxLength: 255, email: true }, zone: 1
    },
    type_id: {
        label: 'Тип потребности',
        type: 'select',
        options: [],
        className: 'w100',
        options_from: 'types',
        prepare: 'types',
        val: 'id',
        lab: 'value',
        validation: { maxLength: 255 }, zone: 1
    },
    source_id: {
        label: 'Источник лида',
        type: 'entity',
        link: "/ajax/lookup/search?type=ld_source&value=",
        result: 'value',
        entity_from: 'source',
        entityName: 'value',
        className: 'w100',
        minLength: 0, zone: 1
    },
    owner_id: {
        label: 'Ответственный',
        type: 'entity',
        link: "/ajax/settings/user?filter[name][like]=",
        result: 'name',
        entity_from: 'owner',
        entityName: 'name',
        className: 'w100',
        minLength: 0, zone: 1
    },
}

const LeadModalComponent = ({ isOpen, close, history }) => {
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState({})
    const [values, setValues] = useState({
        name: '',
        amount: '',
        currency_id: '',
        currency: null,
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        company: '',
        type_id: null,
        type: null,
        source_id: null,
        source: null,
        owner_id: '',
        status_id: '',
        owner: null,
        tch: false
    })

    const getPrepare = async () => {
        const { status, data } = await getLeadPrepareService(localStorage.getItem('leadFunnel'))
        if(status === 200 && data) {
            setValues({
                ...values,
                currencies: data.currencies,
                currency_id: data.currencies[0].id,
                types: data.types,
                type_id: data.types[0].id,
                name: 'Лид №' + data.nextId,
                status_id: data.statues[0].id
            })
        }
        setLoading(false)
    }

    const saveItem = async () => {
        const errorsData = validationData(values, validationConfig(fieldsConfig));
        setErrors(errorsData)
        if(Object.keys(errorsData).length > 0) {
            Notif('required-fields')
            return false;
        }

        setLoading(true)
        const { name, currency_id, first_name, last_name, source_id, type_id, owner_id, email, amount, phone, status_id, company } = values
        const { data, status } = await createLeadService(
            { data: {
                    name, currency_id, first_name, last_name, source_id, type_id, owner_id, email, amount, phone, status_id, company,
                    sales_funnel_id: localStorage.getItem('leadFunnel')
            }})

        if((status === 200 || status === 201) && data.id) {
            history.push(`/lead/${data.id}`)
            createEvent({ user_id: window.location.host, user_name: "---", event_type: "createLead" }).then()
        }
        setLoading(false)
    }

    useEffect(() => {
        if(isOpen) {
            getPrepare().then()
        }
    }, [isOpen])

    const changeFields = (key, value, item) => {
        let obj = {}
        if(item) {
            if(item === 'clear') obj[fieldsConfig[key].entity_from] = null
            else obj[fieldsConfig[key].entity_from] = item
        }

        setValues({
            ...values, ...obj, [key]: item ? item.id : value, tch: false
        })
    }

    return (
        <Modal isOpen={isOpen} save={saveItem} close={close} loading={loading} showLeaveModal tch={values.tch} title={'Создать лид'}>
            <GenerateForm
                config={fieldsConfig}
                { ...{ values, errors, disabled: {} } }
                onChange={changeFields}
                onSaveData={saveItem}
            />
        </Modal>
    )
}

export const LeadModal = React.memo(LeadModalComponent)