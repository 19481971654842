import React,{ Component } from 'react';
import Input from '../components/UI/Input';
import Myselect from '../components/UI/Select';
import {withRouter} from 'react-router-dom';
import formValid from '../components/UI/formValid';
import Notif from '../components/Notif';
import myaxios from '../components/Axios';
import DateTime from '../components/UI/DateTime';
import ModAutoSelect from '../components/UI/ModAutoSelect';

class AddProjectStage extends Component {
formatDate = (date) => {
    if(date !== null) {
        let dd = date.getDate();
        if (dd < 10) dd = '0' + dd;
        let mm = date.getMonth() + 1;
        if (mm < 10) mm = '0' + mm;
        let yy = date.getFullYear() % 10000;
        if (yy < 10) yy = '0' + yy;
        return yy + '-' + mm + '-' + dd;
    }
};
state = {
    formControl: {
        name: {value: '', valid: true, validation: {required: true}},
        //name: {value: '', valid: true, validation: {required: true,minLength: 3}},
        stage: {value: "internal", options: []},
        status: {value: 'inactive'},
        percentComplete: {value: ''},
        responsible:  {value: '', arr: null},
        field: {value: ''},
        startDate: {value: this.formatDate(new Date()), valid: true},
        endDate: {value: this.formatDate(new Date()), valid: true},
        deadline: {value: this.formatDate(new Date()), valid: true}
    },
    dateTime: {
        startDate: new Date(),
        endDate: new Date(),
        deadline: new Date()
    },
    tch: true
};
    isFormValid = () => {
        let formControl = this.state.formControl;
        let isFormValid = true;
        Object.keys(formControl).map((key, index) => {
            formControl[key].valid = formValid(formControl[key].value, formControl[key].validation)
            isFormValid = formControl[key].valid && isFormValid
        });
        this.setState({
            formControl, isFormValid
        });
        return isFormValid;
    };
    handleChange = (date, type) => {
        const formControl = this.state.formControl;
        formControl[type].value = (this.formatDate(date)) || '';
        this.setState({
            formControl, tch: false
        });
    };
    componentDidMount(){
        if(this.props.id){
            this.importValue();
        }
    }
    sendForm = event => {
        event.preventDefault();
        if(this.isFormValid()){
            this.setState({ tch: true });
            myaxios.post(`/ajax/project/${this.props.projectId}/add-work`, {
                name: this.state.formControl.name.value,
                //type_id: String(this.state.formControl.parent_id.value),
                status: this.state.formControl.status.value,
                execution_percent: this.state.formControl.percentComplete.value,
                owner_id: this.state.formControl.responsible.value,
                start_date: this.state.formControl.startDate.value,
                end_date: this.state.formControl.endDate.value,
                deadline: this.state.formControl.deadline.value,
                parent: this.props.stId,
                metal_type: 'company'
            }).then(responce => {
                if(responce.status === 200) {
                    setTimeout(() => this.props.history.push(`/project/work/${responce.data.data.id}`))
                    setTimeout(() => this.props.close())
                    Notif("save", "Этап успешно создан")
                }
            })
        } else Notif("Error")
    };
    /*Изменение полей*/
    setField = (event, type) => {const formControl = {  ...this.state.formControl }; const control = { ...formControl[type] };
        control.value = event.target.value; formControl[type] = control; this.setState({formControl, tch: false}) };
    /*Валидация формы*/

    updateData = (id, arr, addType, endParrans) => {
        const formControl = this.state.formControl;
        formControl[addType].value = id;
        formControl[addType].arr = arr;
        this.setState({ formControl, tch: false })
    };
    unLink = addType => {
        const formControl = this.state.formControl;
        formControl[addType].value = '';
        formControl[addType].arr = null;
        this.setState({ formControl, tch: false})
    }
render(){
    return <div className="sbform create-activ">
        <div className="rmod-header">
            <a className="mclose" onClick={this.props.close}><i className="flaticon-close" /></a>
            <b>{this.props.id} {(this.props.id) ? "Редактировать этап" : "Добавить этап"}</b>
            <div className="togglelist"><button onClick={(this.props.id) ? this.editForm : this.sendForm} className="rmod-ok" disabled={this.state.tch} ><i className="flaticon-interface-1" /></button></div>
        </div>
        <div className="mod-cont">
            {/*<ModAutoSelect
                label="Этап производства"
                link="/ajax/lookup/search?type=work_type&value="
                minLength="0"
                result="value"
                event="parent_id"
                valid={this.state.formControl.parent_id.valid}
                shouldValidate={false}
                addType="parent_id"
                entity={this.state.formControl.parent_id.arr}
                entityName="value"
                updateData={this.updateData}
                unLink={this.unLink}
                //redirectLink="/order/"
            />*/}
            <Input
                 label="Название этапа"
                 type="text"
                 errorMessage="Поле должно содержать не меньше 3-х символов"
                 value={this.state.formControl.name.value}
                 onChange={event => this.setField(event, "name")}
                 shouldValidate={true}
                 valid={this.state.formControl.name.valid}
             />
            <Input
                label="% выполнения"
                type="text"
                value={this.state.formControl.percentComplete.value}
                onChange={event => this.setField(event, "percentComplete")}
                shouldValidate={false}
            />
            <Myselect
                value={this.state.formControl.status.value}
                label="Статус этапа"
                options={[
                    {value: "inactive", label: "Не начат"},
                    {value: "active", label: "В работе"},
                    {value: "partially", label: "Частично выполнен"},
                    {value: "completed", label: "Завершен"}
                ]}
                onChange={event => this.setField(event, "status")}
                shouldValidate={false}
            />
            <DateTime
                label="Дата начала"
                className="c12"
                event="startDate"
                value={this.state.formControl.startDate.value}
                valid={this.state.formControl.startDate.valid}
                isClearable={true}
                handleChange={this.handleChange}
            />
            <DateTime
                label="Дата завершения"
                className="c12"
                event="endDate"
                value={this.state.formControl.endDate.value}
                valid={this.state.formControl.endDate.valid}
                isClearable={true}
                handleChange={this.handleChange}
            />
            <DateTime
                label="Крайняя дата"
                className="c12"
                event="deadline"
                value={this.state.formControl.deadline.value}
                valid={this.state.formControl.deadline.valid}
                isClearable={true}
                handleChange={this.handleChange}
            />
            <div className="clear"/>
            <ModAutoSelect
                label="Ответственный"
                link="/ajax/settings/user?filter[name][like]="
                minLength="0"
                result="name"
                event="responsible"
                valid={true}
                shouldValidate={true}
                addType="responsible"
                entity={this.state.formControl.responsible.arr}
                entityName="name"
                updateData={this.updateData}
                unLink={this.unLink}
                //redirectLink="/order/"
            />
        </div>
    </div>
}}

export default withRouter(AddProjectStage);