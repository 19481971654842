import React, {useEffect, useState} from "react";
import Modal from "../../Modal";
import Input from "../../UI/Input";
import Myselect from "../../UI/Select";
import {BpDateWithPeriod, parseDataForPeriod, saveTimePeriod} from "../BpHelpers";
import myaxios from "../../Axios";
import {validationData} from "../../UI/formValid";

const validationRules = {
    name: { required: true, maxLength: 255 },
    from: { required: true },
    template: { required: true },
    time_value: { number: true, maxLength: 5 },
}

export const BpActionSendEmail = ({ close, metaData, saveNode }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState({})
    const [accounts, setAccounts] = useState([])
    const [templates, setTemplates] = useState([])
    const [values, setValues] = useState({
        name: '',
        from: '',
        template: '',
        subject: '',
        body: '',
        time_value: 0,
        time_valuePeriod: 'minutes',
        errorMessage: '',
        tch: true
    })

    const getMetaData = () => {
        if(metaData) {
            const { name, time_value, metaData: { body, from, template, subject } } = metaData;
            setValues({
                ...values,
                name, body, from, template, subject,
                time_value: parseDataForPeriod(+time_value).value,
                time_valuePeriod: parseDataForPeriod(+time_value).period
            })
        }
        setLoading(false)
    }

    const getPrepareData = async () => {
        try {
            const { status: accountStatus, data: accountData } = await myaxios.get(`/ajax/mail/account?perPage=100`)
            if(accountStatus === 200 && accountData.items.length > 0) {
                setAccounts(accountData.items.map(item => ({ value: item.username, label: item.username })))
            } else {
                setValues({ ...values, errorMessage: 'Необходимо добавить как минимум 1 почтовый ящик' })
                setLoading(false)
                return
            }

            const { status: templateStatus, data: templateData } = await myaxios.get(`/ajax/template?perPage=100`)
            if(templateStatus === 200 && templateData.items.length > 0) {
                setTemplates(templateData.items)
            } else {
                setValues({ ...values, errorMessage: 'Необходимо добавить как минимум 1 шаблон письма' })
                setLoading(false)
                return
            }
        } catch (e) {}
        getMetaData()
    }

    useEffect(() => {
        setIsOpen(true)
        getPrepareData().then()
    }, [])

    const saveData = () => {
        const validErrors = validationData(values, validationRules)
        if (Object.keys(validErrors).length > 0) {
            setErrors(validErrors)
            return false;
        }
        setErrors({})
        const { name, from, template, subject, body, time_value, time_valuePeriod } = values
        setIsOpen(false)

        setTimeout(() => saveNode({
            id: metaData && metaData.id,
            name,
            nodeType: 'node',
            time_value: saveTimePeriod(time_value, time_valuePeriod),
            is_scheduled: time_value > 0,
            type: "mail",
            metaData: { from, template, subject, body }
        }))
            console.log('validErrors', validErrors)
    }

    const closeModal = () => {
        setIsOpen(false); setTimeout(close, 200);
    }

    if(values.errorMessage) {
        return <Modal showLeaveModal tch={values.tch} isOpen={isOpen} title={'Ошибка'} loading={loading}
                      save={saveData} parentClassName={'bpmModal'} close={closeModal}>
            <div style={{ fontSize: '14px', padding: '5px 0' }}>{values.errorMessage}</div>
        </Modal>
    }

    return (
        <Modal showLeaveModal tch={values.tch} isOpen={isOpen} title={'Отправить Email'} loading={loading}
               save={saveData} parentClassName={'bpmModal'} close={closeModal}>
            <div className="sbform">
                <Input
                    label={'Название'}
                    value={values.name}
                    onChange={e => setValues({...values, name: e.target.value, tch: false })}
                    errorMessage={errors.name} valid={!errors.name} shouldValidate
                />
                <Myselect
                    label={'От (email)'}
                    options={accounts} value={values.from} empty
                    onChange={e => setValues({ ...values, from: e.target.value, tch: false })}
                    errorMessage={errors.from} valid={!errors.from} shouldValidate
                />
                <Myselect
                    label={'Шаблон письма'} val={'id'} lab={'name'}
                    options={templates} value={values.template} empty
                    onChange={e => {
                        const findTemplate = templates.find(el => +el.id === +e.target.value)
                        console.log('12312', findTemplate)
                        setValues({
                            ...values,
                            template: e.target.value,
                            subject: findTemplate.subject,
                            body: findTemplate.body,
                            tch: false
                        })
                    }}
                    errorMessage={errors.template} valid={!errors.template} shouldValidate
                />
                <BpDateWithPeriod
                    label={'Отложенный запуск'}
                    name={'time_value'} periodName={'time_valuePeriod'}
                    values={values} errors={errors}
                    setValues={(type, value) => setValues({...values, [type]: value, tch: false })}
                    tooltip={'Время задержки выполнения процесса'}
                />
            </div>
        </Modal>
    )
}