import {CreateInvoice} from "../../containers/invoice/CreateInvoice";
import {PrintModal} from "../../Modal/PrintModal";
import React from "react";
import {TaskModal} from "../../containers/tasks/TaskModal";
import {LeadModal} from "../../containers/lead/LeadModal";
import {OpportunityModal} from "../../containers/opportunity/OpportunityModal";
import {OrderModal} from "../../containers/order/OrderModal";
import {AccountModal} from "../../containers/account/AccountModal";
import {ContactModal} from "../../containers/contacts/ContactModal";
import {DocumentModal} from "../../containers/document/DocumentModal";
import {CaseModal} from "../../containers/cases/CaseModal";
import {ProductModal} from "../../containers/products/ProductModal";
import {PurchaseModal} from "../../containers/stock/purchase/PurchaseModal";
import {ReplanishModal} from "../../containers/stock/replanish/ReplanishModal";
import {ShipmentModal} from "../../containers/stock/shipment/ShipmentModal";
import {TransferModal} from "../../containers/stock/transfer/TransferModal";
import {ReturnModal} from "../../containers/stock/providerReturn/returnModal";
import {ConsumptionModal} from "../../containers/stock/consumption/ConsumptionModal";

export const ModalConfig = [
    {
        name: 'task',
        Component: (props) => <TaskModal {...props} id={props && props.data && props.data.id} related={props.data && props.data.related} />
    },
    { name: 'invoice', Component: CreateInvoice },
    { name: 'print', Component: PrintModal },
    { name: 'lead', Component: LeadModal },
    { name: 'opportunity', Component: OpportunityModal },
    { name: 'order', Component: OrderModal },
    { name: 'account', Component: AccountModal },
    { name: 'contact', Component: ContactModal },
    { name: 'contact', Component: ContactModal },
    { name: 'document', Component: DocumentModal },
    { name: 'case', Component: CaseModal },
    { name: 'product', Component: ProductModal },
    { name: 'purchase', Component: PurchaseModal },
    { name: 'replanish', Component: ReplanishModal },
    { name: 'shipment', Component: ShipmentModal },
    { name: 'transfer', Component: TransferModal },
    { name: 'return', Component: ReturnModal },
    { name: 'consumption', Component: ConsumptionModal },
]