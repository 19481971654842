import React, {useContext} from "react";
import {InfoTable} from "../../../components/cardViewComponents/InfoTable";
import {GlobalState} from "../../../Layout/Layout";
import Input from "../../../components/UI/Input";
import Myselect from "../../../components/UI/Select";
import St from "react-datepicker";
import AdditionalFields, {AdditionalFieldsChanged} from "../../../components/additionalFields";
import MainEditor from "../../../components/UI/Editor";
import OldPayments from "../../invoice/components/OldPayments";

export const InfoTab = ({ values, errors, setValues, setPaymentValues, id }) => {
    const bc = useContext(GlobalState)
    const { created_at, updated_at, exchange_rate, currency_id, contact, account, payment_cost, cost, currencies,
        addresses, address_id, openAddress } = values
    const isAddress = (contact || account)
    let isCurrency = +bc.state.baseCurrency.id === +currency_id;

    return (
        <div className="tab-content sbform clearFieldPadding">
            <div className="connection" style={{ marginTop: 0 }}><span>Основная информация</span></div>
            <InfoTable
                arr={[
                    { value: 'Дата создания', label: created_at },
                    { value: 'Дата обновления', label: updated_at },
                ]}
            />
            <div className="connection"><span>Оплата</span></div>
            <Input
                tooltip="Сумма оплат всех привязанных счетов. Поле недоступно для редактирования."
                label="Сумма оплаты"
                type="text"
                className={`fgroup ${isCurrency ? 'rc4' : 'rc3'}`}
                value={payment_cost && Number(payment_cost).toFixed(2)}
                disabled valid
            />
            <Input
                tooltip="Сумма всех добавленных товаров в базовой валюте. Поле недоступно для редактирования."
                label="Сумма в б.в."
                type="text"
                className={`fgroup ${isCurrency ? 'rc4' : 'rc3'}`}
                value={cost && Number(cost).toFixed(2)}
                shouldValidate valid
                disabled
            />
            {!isCurrency ? <Input
                tooltip="Сумма всех добавленных товаров в валюте счета валюте. Поле недоступно для редактирования."
                label="Сумма в в.с."
                type="text"
                className="fgroup rc2"
                value={(+cost / Number(exchange_rate)).toFixed(2)}
                shouldValidate={false}
                disabled={true}
            /> : null}
            <Myselect
                label="Валюта"
                className={`fgroup ${isCurrency ? 'rc4' : 'rc2'}`}
                options={currencies}
                value={currency_id}
                onChange={e => {
                    setValues({
                        ...values,
                        currency_id: e.target.value,
                        exchange_rate: currencies.filter(item => +item.id === +e.target.value)[0].value
                    })
                }}
                valid={!errors.currency_id}
                shouldValidate errorMessage={errors.currency_id}
                val="id" lab="char_code"
                disabled={values.payments.length > 0}
            />
            {(!isCurrency || errors.exchange_rate) ? <Input
                label="Курс"
                type="number"
                className="fgroup rc2"
                value={exchange_rate}
                onChange={event => setValues({ ...values, exchange_rate: event.target.value })}
                valid={!errors.exchange_rate}
                shouldValidate errorMessage={errors.exchange_rate}
            /> : null}
            <div className="clear"/>
            <OldPayments
                id={id}
                setValues={(sum, payments) => setPaymentValues({
                    payment: sum,
                    payments: payments || [],
                    currency_id: values.saved_currency_id,
                    exchange_rate: currencies.filter(item => +item.id === +values.saved_currency_id)[0].value
                })}
                values={values}
                type={'order'}
                updated_at={values.updated_number}
            />

            <div className="connection">
                <span>Адрес доставки</span>
                {isAddress ? <button onClick={openAddress} className="btni">+</button> : null}
            </div>
            {(addresses) ? <div>
                {addresses.map((item) =>
                    <div key={item.id}>
                        <input
                            checked={(String(address_id) === String(item.address.id))}
                            type="radio" className="radio"
                            onClick={event => setValues({ ...values, address_id: event.target.value })}
                            value={item.address.id} id={item.address.id} name="address" /><label htmlFor={item.address.id}>{item.address.address}</label>
                    </div>
                )}
            </div> : null}

            <div className="connection"><span>Дополнительная информация</span></div>
            <AdditionalFieldsChanged
                errors={errors}
                items={values.additionalFields}
                updateAdditionalFields={data => setValues({ additionalFields: data, tch: false })}
            />

            <div className="clear" />
            <div className="fgroup rc12">
                <MainEditor
                    label={'Описание'}
                    value={values.description}
                    height={350}
                    valid={!errors.description}
                    errorMessage={errors.description}
                    onChange={description => {
                        if(description !== values.description) {
                            setValues({ description })
                        }
                    }}
                />
            </div>
        </div>
    )
}