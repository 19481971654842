import React from 'react';

function checkStatus(status) {
    switch(status){
        case "CANCEL": return "ОТМЕНЕН"; break;
        case "ANSWER": return "ПРИНЯТ"; break;
        case "NOANSWER": return "НЕТ ОТВЕТА"; break;
        case "BUSY": return "ЗАНЯТ"; break;
    }
}

const CallItem = props => {
  switch (props.type) {
      case "outgoing": return <div className="m-list-timeline__item">
          <span className="m-list-timeline__badge m-list-timeline__badge--success" />
          <span className="rotateIn m-list-timeline__icon flaticon-up-arrow" />
          <span style={{ paddingLeft: "10px" }} className="m-list-timeline__text">Исходящий ({props.duration} с.)&nbsp;<a href="#">{props.client}</a> <br /><a onClick={() => props.callNumber(props.number)}>{props.number}</a> </span>
          <span className="m-list-timeline__time">{props.time}</span></div>; break;
      case "incoming": return <div className="m-list-timeline__item">
          <span className="m-list-timeline__badge m-list-timeline__badge--success" />
          <span className="rotateOut m-list-timeline__icon flaticon-up-arrow" />
          <span style={{ paddingLeft: "10px"}} className="m-list-timeline__text">Входящий ({props.duration} с.)&nbsp;<a href="#">{props.client}</a> <br /><a onClick={() => props.callNumber(props.number)}>{props.number}</a> </span>
          <span className="m-list-timeline__time">{props.time}</span></div>; break;
      /*case "missed": return <div className="m-list-timeline__item">
          <span className="m-list-timeline__badge m-list-timeline__badge--success" />
          <span className="m-list-timeline__icon flaticon-close" />
          <span style={{ paddingLeft: "10px"}} className="m-list-timeline__text">Пропущенный ({props.duration})&nbsp;<a href="#">{props.client}</a> <br /><a href="#">{props.number}</a> </span>
          <span className="m-list-timeline__time">{props.time}</span>
      </div>; break;*/
      default: return null;
  }
};

export default CallItem;