import React from "react";

const Switch = ({ active, onClick }) => {
    return (
        <div className={`toggle ${active && "toggle-on"}`} id='switch' onClick={onClick}>
            <div className='toggle-text-off'>OFF</div>
            <div className='glow-comp'></div>
            <div className='toggle-button'></div>
            <div className='toggle-text-on'>ON</div>
        </div>
    )
}

export default Switch