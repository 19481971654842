import React, {useEffect, useState} from "react";
import Modal from '../../components/Modal/Modal'
import Input from "../../components/UI/Input";
import Myselect from "../../components/UI/Select";
import {createInvoiceService, getInvoicePrepareService} from "../../services/invoice/invoiceService";
import ModAutoSelect from "../../components/UI/ModAutoSelect";
import {validationData} from "../../components/UI/formValid";
import { withRouter } from "react-router-dom";
import Notif from "../../components/Notif";
import {formatDate} from "../../components/ListComponent";
import {createEvent} from "../../services/other/events";

const invoiceValidationConfig = {
    prefix_id: { required: true },
    inv_id: { required: true },
}

const Inv  = ({ isOpen = true, close, data, history, save }) => {
    const [values, setValues] = useState({
        prefix_id: '',
        prefixes: [],
        inv_id: '',
        contact_id: data && data.contact_id,
        contact_data: data && data.contact,
        account_id: data && data.account_id,
        account_data: data && data.account,
        owner_id: '',
        owner_data: null,
        opportunity_id: data && data.opportunity_id,
        order_id: data && data.order_id,
        project_id: data && data.project_id,
    })
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState(true)

    const getPrepare = async () => {
        try {
            const { data, status } = await getInvoicePrepareService()
            if(status === 200 && data.prefixes[0]) {
                setValues(prevState => ({
                    ...prevState,
                    prefixes: data.prefixes,
                    prefix_id: data.prefixes[0].id,
                    inv_id: data.prefixes[0].count
                }))
            }
        } catch (e) {

        }
        setLoading(false)
    }

    useEffect(() => {
        if(isOpen) getPrepare().then()
    }, [isOpen])

    const saveInvoice = async () => {
        setLoading(true)
        const validationConfig = JSON.parse(JSON.stringify(invoiceValidationConfig))
        if(!values.contact_id && !values.account_id) {
            validationConfig.account_id = { required: true }
            validationConfig.contact_id = { required: true }
        }
        let errorsData = validationData(values, validationConfig)
        if(Object.keys(errorsData).length > 0) {
            setErrors(errorsData)
            setLoading(false)
            return false;
        }

        const { prefix_id, inv_id, account_id, contact_id, owner_id, project_id, opportunity_id, order_id } = values;

        try {
            const { data, status } = await createInvoiceService({
                data: {
                    prefix_id, inv_id, account_id, contact_id, owner_id, project_id, opportunity_id, order_id,
                    created_date: formatDate(new Date()),
                    status_id: "1"
                }
            })

            if((status === 200 || status === 201) && data.id) {
                if(save) {
                    save(); setTimeout(close, 200);
                    createEvent({ user_id: window.location.host, user_name: "---", event_type: "createInvoice" }).then()
                } else {
                    history.push(`/invoice/${data.id}`)
                    createEvent({ user_id: window.location.host, user_name: "---", event_type: "createInvoice" }).then()
                }
                Notif('save')
            }
        } catch (e) {
            console.error(e)
        }
        setLoading(false)
    }

    return (
        <Modal isOpen={isOpen} title={'Создать счет'} loading={loading} save={saveInvoice} close={close}>
            <>
                <div style={{ display: 'flex' }}>
                    <Myselect
                        label={'Префикс'} required shouldValidate
                        valid={!errors.prefix_id}
                        options={values.prefixes}
                        value={values.prefix_id}
                        lab={'name'} val={'id'}
                        onChange={e => setValues({
                                ...values,
                                prefix_id: e.target.value,
                                inv_id: e.target.value && values.prefixes.find(el => String(e.target.value) === String(el.id)).count
                            })
                        }
                        className={'w30'}
                    />
                    <Input
                        label={'Номер счета'} required
                        value={values.inv_id}
                        valid={!errors.inv_id}
                        disabled shouldValidate
                        className={'w70'}
                    />
                </div>
                {!values.account_id && <ModAutoSelect
                    label="Контакт"
                    link="/ajax/contact?fields=id,name&filter[name][like]="
                    minLength="1"
                    result="name"
                    errorMessage="Контакт или контрагент должны быть обязательно привязанны к счету"
                    valid={!errors.contact_id}
                    shouldValidate required
                    entity={values.contact_data}
                    entityName="name"
                    updateData={(contact_id, contact_data) => setValues({ ...values, contact_id, contact_data })}
                    unLink={() => setValues({ ...values, contact_id: '', contact_data: null })}
                />}
                {!values.contact_id && <ModAutoSelect
                    label="Контрагент"
                    link="/ajax/account?fields=id,name&filter[name][like]="
                    minLength="1"
                    result="name"
                    errorMessage="Контакт или контрагент должны быть обязательно привязанны к счету"
                    valid={!errors.account_id}
                    shouldValidate required
                    entity={values.account_data}
                    entityName="name"
                    updateData={(account_id, account_data) => setValues({ ...values, account_id, account_data })}
                    unLink={() => setValues({ ...values, account_id: '', account_data: null })}
                />}
                <ModAutoSelect
                    label="Ответственный"
                    link="/ajax/settings/user?fields=id,name&filter[name][like]="
                    minLength="0"
                    result="name"
                    errorMessage="Контакт или контрагент должны быть обязательно привязанны к счету"
                    valid={!errors.owner_id}
                    shouldValidate
                    entity={values.owner_data}
                    entityName="name"
                    updateData={(owner_id, owner_data) => setValues({ ...values, owner_id, owner_data })}
                    unLink={() => setValues({ ...values, owner_id: '', owner_data: null })}
                />
            </>
        </Modal>
    )
}

export const CreateInvoice = withRouter(Inv)