import React, {useContext, useEffect, useState} from "react";
import myaxios from "../components/Axios";
import {NavLink} from "react-router-dom";
import {GlobalState} from "../Layout/Layout";
import Notif from "../components/Notif";
import {CSSTransition, TransitionGroup} from "react-transition-group";

const getProductsFromOrder = async (orderId, entity) => await myaxios.get(`/ajax/${entity}/${orderId}/products`);

const SplitOrder = ({ orderId, close, history, entity }) => {
    const [products, setProducts] = useState([]);
    const [update, setUpdate] = useState(true);
    const [tch, setTch] = useState(true)
    const mycontext = useContext(GlobalState);
    const {state: { baseCurrency: { text_symbol } = {} }} = mycontext;

    useEffect(() => {
        getProductsFromOrder(orderId, entity).then(res => {
            setProducts(res.data.items)
        })
    }, [])

    const check = index => {
        products[index].checked = !products[index].checked
        setProducts(products)
        setUpdate(!update)
        validationChecked()
    };

    const validationChecked = () => {
        let arr = [];
        products.map(item => {
            if(item.checked) arr.push(item.product.id)
        });
        setTch((arr.length === 0))
    }

    const saveOrder = () => {
        let arr = [];
        products.map(item => {
            if(item.checked) arr.push(item.product.id)
        })
        myaxios.post(`/ajax/${entity}/${orderId}/split`, {products: arr}).then(res => {
            if(res) if(res.status === 200 || res.status === 201 || res.status === 204) {
                history.push(`/${entity}/${res.data.id}`);
                setTimeout(() => close(), 200)
                Notif("save", "Запись успешно создана")
            }
        })
    }

    return (
        <div className="modal-description">
            <p>Выберите товары, которые необходимо перенести в другой заказ</p>
            <table className="products-table">
                {update}
                <thead>
                <tr>
                    <th width={40} />
                    <th>Название</th>
                    <th>Статус</th>
                    <th>К-во</th>
                    <th>Сумма</th>
                </tr>
                </thead>
                <tbody>
                <TransitionGroup className="transition-group-screen">
                {
                    products.map((item, index) => (
                            <CSSTransition key={item.id} timeout={{ enter: 200, exit: 200 }} classNames="slide" >
                                <OrderItem key={item.id} item={item} currency={text_symbol} index={index} check={() => check(index)} />
                            </CSSTransition>
                        )
                    )
                }
                </TransitionGroup>
                </tbody>
            </table>
            <div className="products-table-buttons">
                <button className="btni" onClick={close}>Отменить</button>
                <button disabled={tch} style={{ marginLeft: "10px" }} className="success btni" onClick={saveOrder}>Разделить заказ</button>
            </div>
        </div>
    )
}

export default SplitOrder;

const OrderItem = ({ item, currency, check }) => {
    const {status: {value} = {}, product: {name, id, unit = {}} = {}, price, quantity, discount} = item;
    return (
        <tr>
            <td style={{ textAlign: "center" }}>
                <label><input className="checkbox checkboxlead" type="checkbox" name="checkbox-test" checked={item.checked} onClick={check} /><span className="checkbox-custom" /></label>
            </td>
            <td><NavLink to={`product/${id}`}>{name}</NavLink></td>
            <td>{value}</td>
            <td>{quantity} {unit ? unit.value : ''}</td>
            <td>{price} {currency}</td>
        </tr>
    )
}