import React, {useEffect, useState} from 'react';
import Modal from "../components/Modal/Modal";
import myaxios from "../components/Axios";
import {NavLink} from "react-router-dom";

const platesRes = async () => await myaxios(`/ajax/plates`);

var sub = window.location.host.split('.')[0]

const PrintPlates = ({ entity, id, entityId, isOpen, close }) => {
    const [plates, setPlates] = useState([]);

    const getPlates = () => {
        platesRes().then(res => {
            if(res.data.items.length > 0){
                setPlates(res.data.items.filter(item => (item.object_type == entityId)))
            }
        })
    }

    useEffect(() => {
        getPlates()
    },[])

    return <div className="print-block toogle-bottom fl-l aab">
        <button className="success fl-r btni ml5 ">
            <span>Печать</span>
            <i className="flaticon-technology-2 mdb" />
        </button>
        <ul className="toogle-list" style={{left: "0px"}}>
            {
                plates && plates.map(item => (
                        <li><a target="_blank" href={`https://${sub}.sboxcrm.com/ajax/${entity}/${id}/document?plates_id=${item.id}`}>{item.name}</a></li>
                    )
                )
            }
            {plates.length < 1 && <li><NavLink to="/settings/plate/">Добавить шаблон документа</NavLink></li>}
        </ul>
    </div>
}

export default PrintPlates;