import React, {Component} from 'react';
import Textarea from '../../UI/Textarea';
import Input from '../../UI/Input';
import ModAutoSelect from '../../UI/ModAutoSelect';
import formValid from '../../UI/formValid';
import Myselect from '../../UI/Select';
import myaxios from '../../Axios';
import SoundClick from '../../soundClick';
import Notif from '../../Notif';
import MainEditor from "../../UI/Editor";
import {TASK_PRIORITY} from "../../../const";



export default class BpTask extends Component {
    state = {
        formControl: {
            time_value:{value:0},
            name:{value:'',valid:true, validation:{required:true}},
            subject:{value:'',valid:true, validation:{required:true}},
            description:{value:'',valid:true, validation:{required:false}},
            type:{value:'',arr:null,valid:true,validation:{required:true}},
            priority: {
                value:'1',
                options:TASK_PRIORITY,
                valid:true,
            },
            owner:{value:'owner_id',arr:[],valid:true,validation:{required:true}},
            startDate: {value:0},
            endDate: {value:0},
            remind: {value:0},
        },
        subtask_json:[],
        subtask_value:'',
        subtask_active:false,
        subtask_active_index:null,
        conditions: [],
        condition:{
            value:'stage',
            time:'0'
        },
        id:null
    };
    getInfo = () =>{
        myaxios.get('/ajax/task/prepare').then(res => {
            const formControl = this.state.formControl;
            let owner = res.data.users;
            owner.unshift({id:"created_by", name:"Создавший"});
            owner.unshift({id:"owner_id", name:"Текущий ответственный"});
            formControl.type.arr = res.data.types;
            formControl.type.value = formControl.type.value === '' ? res.data.types[0].id : formControl.type.value;
            formControl.owner.arr = owner;
            this.setState({ formControl })
        })
    }
    componentDidMount(){
        this.getInfo();
    }
    componentWillMount(){
        if(this.props.elArr){
            let formControl = this.state.formControl;
            formControl.name.value = this.props.elArr.metaData ? this.props.elArr.name : '';
            formControl.subject.value = this.props.elArr.metaData ? this.props.elArr.metaData.subject : '';
            formControl.owner.value = this.props.elArr.metaData ? this.props.elArr.metaData.owner_id : '';
            formControl.type.value = this.props.elArr.metaData ? this.props.elArr.metaData.type_id : '';
            formControl.startDate.value = this.props.elArr.metaData ? this.props.elArr.metaData.startDate : '';
            formControl.endDate.value = this.props.elArr.metaData ? this.props.elArr.metaData.endDate : '';
            formControl.remind.value = this.props.elArr.metaData ? this.props.elArr.metaData.remind : '';
            formControl.time_value.value = this.props.elArr.metaData ? this.props.elArr.time_value : '';
            formControl.priority.value = this.props.elArr.metaData ? this.props.elArr.metaData.priority : '';
            formControl.description.value = this.props.elArr.metaData ? this.props.elArr.metaData.description : '';
            this.setState({
                formControl,
                filter: this.props.elArr.conditions,
                id: this.props.elArr.id || null,
                subtask_json: this.props.elArr.metaData.subtask_json ? JSON.parse(this.props.elArr.metaData.subtask_json) : []
            })
        }
    }
    setField = (type, e) => {
        const formControl = this.state.formControl;
        if(type === "startDate" && Number(e) > Number(formControl.endDate.value)){
            formControl.endDate.value = Number(e) + 5;
            formControl.remind.value = Number(e) + 10;
            formControl[type].value = e;
        } else {
            formControl[type].value = e;
        }
        this.setState({ formControl })
    };
    addSubtask = (e) => {
        if(this.state.subtask_value.length >= 1){
            let subtask_json = this.state.subtask_json;
            subtask_json.push({text: this.state.subtask_value, status: false})
            this.setState({ subtask_json, tch: false, subtask_value:'' })
        }
    }
    subTaskEdit = (type, index) => {
        let subtask_json = this.state.subtask_json;
        if(type === "setStatus"){
            subtask_json[index].status = !this.state.subtask_json[index].status;
        } else if(type === "delete"){
            delete subtask_json[index];
        }
        this.setState({ subtask_json, tch: false })
    };
    isFormValid = () => {
        let formControl = this.state.formControl;
        let isFormValid = true;
        Object.keys(formControl).map((key, index) => {
            formControl[key].valid = formValid(formControl[key].value, formControl[key].validation);
            isFormValid = formControl[key].valid && isFormValid
        });
        if(Number(formControl.endDate.value) < Number(formControl.startDate.value)){
            isFormValid = false;
            Notif("Fields", "Крайнее время не может быть раньше времени начала");
        }
        if(Number(formControl.remind.value) < Number(formControl.startDate.value)){
            isFormValid = false;
            Notif("Fields", "Напоминание не может быть раньше времени начала");
        }
        this.setState({formControl});
        return isFormValid;
    };
    sendForm = () => {
        if(this.isFormValid()){
            if(this.props.elArr){
                setTimeout(
                    this.props.updateNode({
                        id:this.state.id,
                        name: this.state.formControl.name.value,
                        time_value: this.state.formControl.time_value.value,
                        is_scheduled: (this.state.formControl.time_value.value > 1),
                        metaData: {
                            subject: this.state.formControl.subject.value,
                            description: this.state.formControl.description.value,
                            priority: this.state.formControl.priority.value,
                            type_id: this.state.formControl.type.value,
                            owner_id: this.state.formControl.owner.value,
                            owner_type: (this.state.formControl.owner.value === "created_by" || this.state.formControl.owner.value === "owner_id") ? 'reference' : 'user',
                            subtask_json: JSON.stringify(this.state.subtask_json),
                            startDate: this.state.formControl.startDate.value,
                            endDate: this.state.formControl.endDate.value,
                            remind: this.state.formControl.remind.value,
                        },
                        nodeType: "node",
                        type:"task",
                    })
                )
            } else {
                setTimeout(
                    this.props.createNode({
                        name: this.state.formControl.name.value,
                        time_value: this.state.formControl.time_value.value,
                        is_scheduled: (this.state.formControl.time_value.value > 1),
                        metaData: {
                            subject: this.state.formControl.subject.value,
                            description: this.state.formControl.description.value,
                            priority: this.state.formControl.priority.value,
                            type_id: this.state.formControl.type.value,
                            owner_id: this.state.formControl.owner.value,
                            owner_type: (this.state.formControl.owner.value === "created_by" || this.state.formControl.owner.value === "owner_id") ? 'reference' : 'user',
                            subtask_json: JSON.stringify(this.state.subtask_json),
                            startDate: this.state.formControl.startDate.value,
                            endDate: this.state.formControl.endDate.value,
                            remind: this.state.formControl.remind.value,
                        },
                        nodeType: "node",
                        type:"task",
                    })
                )
            }
        }
    };
    updateData = (id, arr, el, type) => {
        let formControl = this.state.formControl;
        if(type === "add"){
            formControl[el].arr = arr;
            formControl[el].value = id;
        } else {
            formControl[el].arr = null;
            formControl[el].value = '';
        }
        this.setState({ formControl })
    }
    render(){
        return <div>
            <div className="rmod-header">
                <b>Создать задачу</b>
                <a onClick={this.props.close} style={{ marginRight: "60px" }} className="mclose"><i className="flaticon-close" /></a>
                <div className="togglelist"><button onClick={this.sendForm} className="rmod-ok" disabled=""><i className="flaticon-interface-1" /></button></div>
            </div>
            <div className="mod-cont sbform">
                <Input
                    label={"Название"}
                    value={this.state.formControl.name.value}
                    valid={this.state.formControl.name.valid}
                    onChange={e => this.setField("name", e.target.value)}
                    shouldValidate={true}
                />
                <Input
                    value={this.state.formControl.subject.value}
                    valid={this.state.formControl.subject.valid}
                    onChange={e => this.setField("subject", e.target.value)}
                    shouldValidate={true}
                    label="Текст задачи"
                    rows="4"
                />
                <MainEditor
                    label="Описание задачи"
                    value={this.state.formControl.description.value}
                    valid={this.state.formControl.description.valid}
                    onChange={e => this.setField("description", e)}
                />
                <Input
                    tooltip="К-во минут, после того, как сработает триггер"
                    label={"Время начала"}
                    value={this.state.formControl.startDate.value}
                    onChange={e => this.setField("startDate", e.target.value)}
                />
                <Input
                    tooltip="К-во минут, после того, как сработает триггер"
                    label={"Крайнее время"}
                    value={this.state.formControl.endDate.value}
                    onChange={e => this.setField("endDate", e.target.value)}
                />
                <Input
                    tooltip="К-во минут, после того, как сработает триггер"
                    label={"Напоминание"}
                    value={this.state.formControl.remind.value}
                    onChange={e => this.setField("remind", e.target.value)}
                />
                <Myselect
                    label="Тип задачи"
                    options={this.state.formControl.type.arr}
                    value={this.state.formControl.type.value}
                    valid={this.state.formControl.type.valid}
                    onChange={e => this.setField("type", e.target.value)}
                    shouldValidate={true}
                    lab="value"
                    val="id"
                />
                <Myselect
                    label="Приоритет задачи"
                    value={this.state.formControl.priority.value}
                    valid={this.state.formControl.priority.valid}
                    onChange={e => this.setField("priority", e.target.value)}
                    options={this.state.formControl.priority.options}
                />
                <Myselect
                    label="Ответственный"
                    options={this.state.formControl.owner.arr}
                    value={this.state.formControl.owner.value}
                    valid={this.state.formControl.owner.valid}
                    shouldValidate={true}
                    onChange={e => this.setField("owner", e.target.value)}
                    lab="name"
                    val="id"
                />
                <div className="clear" />
                <div className="subtask" style={{ marginTop:"10px" }}>
                    {/*<div className="connection" style={{ marginTop: "10px" }}><span>Подзадачи</span><button onClick={(e) => {this.setState({ subtask_active: !this.state.subtask_active }); e.preventDefault()}} className="btni">+</button></div>*/}
                    <div className="subtaskInp">
                        <Input placeholder="Добавить подзадачу" className="c12" value={this.state.subtask_value} onKeyUp={e => (e.keyCode === 13) ? this.addSubtask() : null} onChange={e => {this.setState({ subtask_value: e.target.value })} } />
                        <i onClick={e => this.addSubtask()} className="flaticon-interface-1" />
                    </div>
                    <table style={{ width: "100%" }}>{
                        this.state.subtask_json ? this.state.subtask_json.map((item, index) =>
                            item ? <tr key={index}>
                                <td style={{ width: "20px" }}>{(item.status) ? <i onClick={() => {this.subTaskEdit("setStatus", index)}} className="flaticon-interface-1" /> : <div onClick={() => {this.subTaskEdit("setStatus", index)}} className="subTskSquire" />}</td>
                                <td style={{ textDecoration: (item.status) ? 'line-through' : '', color: (item.status) ? '#bbb' : '' }}><em onClick={() => {this.subTaskEdit("setStatus", index); SoundClick("click")}}>{item.text}</em></td>
                                <td style={{ width: "15px" }}><a onClick={() => {this.subTaskEdit("delete", index)}} style={{ color: "#61131f" }}>x</a></td>
                            </tr> : null
                        ) : null
                    }</table>
                </div>
                <div className="clear" />
                <Input
                    tooltip="Процесс запустится через N секунд, после того, как сработает триггер"
                    type="number"
                    value={this.state.formControl.time_value.value}
                    onChange={e => this.setField("time_value", e.target.value)}
                    shouldValidate={false}
                    label="Отложенный запуск на N секунд"
                />
            </div>
        </div>
    }
}
