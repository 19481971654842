import React from 'react';
import myaxios from '../components/Axios';
import Loader from '../components/UI/Loader'
class OpenMail extends React.Component {
    state = {
        name: '',
        subject: '',
        from: '',
        date: '',
        text_body: '',
        html_body:'',
        attachments: [],
        loading: true,
        folder: '',
        has_text_body:0,
        has_html_body:0,
        editorTags:'',
        items:[]
    }
    componentDidMount(){
        this.showMail();
    }
    showMail = () => {
        myaxios.get(`/ajax/mail/${this.props.id.mail_account_id}/${this.props.id.folder}/${this.props.id.id}/thread`, {method: 'get',}).then((responce) =>{
            /*this.setState({
                from: responce.data.from,
                subject: responce.data.subject,
                date: responce.data.date,
                text_body: responce.data.text_body,
                html_body:responce.data.html_body,
                attachments: responce.data.attachments,
                mail_account_id: responce.data.mail_account_id,
                folder: responce.data.folder,
                name: responce.data.personal_name,
                has_text_body: responce.data.has_text_body,
                has_html_body: responce.data.has_html_body,
                loading: false,
                editorTags: responce.data.editorTags
            })*/
            this.setState({ items: responce.data.items, loading: false })
        }).catch((error)=>{
            this.setState({ loading: false });
        });
    };
    render() { return this.state.loading ? <Loader contain /> : (<div className="sbform fltr">
        <div className="rmod-header">
            <b>Письмо</b>
            <a onClick={this.props.close} className="rmod-close"><i className="flaticon-close" /></a>
        </div>
        <div className="mod-cont">
            <div className="mailbody" style={{padding: "10px 0 0 0"}}>
                {
                    this.state.items.map((item, index) =>
                        <Mail
                            id={item.id}
                            key={index}
                            subject={item.subject}
                            date={item.date}
                            is_sent={item.is_sent}
                            has_html_body={item.has_html_body}
                            html_body={item.html_body}
                            text_body={item.text_body}
                            attachments={item.attachments}
                            glb={this.props.id}
                        />
                    )
                }
            </div>
        </div>
    </div>)}
}

export default OpenMail;

const Mail = props => {
    return <div className="mailModalItem"
                style={{
                    border: (props.is_sent === 1) ? "2px solid #bbb" : "",
                    background: (props.is_sent === 1) ? "#f7f7f7" : "#fff",
                    marginLeft: (props.is_sent === 1) ? "5%" : "0",
                }}>
        <div className="body-headers">
            <div className="wrap-h">{props.subject}</div>
            <div className="mail-body-time">{props.date}</div>
        </div>
        {
            (props.has_html_body === 1) ? <p dangerouslySetInnerHTML={{__html: props.html_body}} /> : <p dangerouslySetInnerHTML={{__html: props.text_body}} />
        }

        {props.attachments ? props.attachments.length >= 1 ? <em className="mail-files-em">Прикрепленные файлы</em> : null : null}
        <div className="mail-files">
            {/*<a href="#"><img src="https://i1.wp.com/crosstrac.net/wp-content/uploads/2018/04/PDF-Icon.png" alt="" /></a>*/}
            {
                props.attachments ? props.attachments.map((item, index) =>
                    <a key={index} title={item.name}
                       href={`/ajax/mail/${props.glb.mail_account_id}/inbox/${props.id}/download/${item.name}`}
                       className="attachmentItem">{item.name.slice(0, 30)}</a>
                ) : null
            }
        </div>
    </div>
}