import React, {Component, useEffect, useState} from 'react';
import Input from '../components/UI/Input';
import Notif from '../components/Notif';
import myaxios from '../components/Axios';
import MainEditor from "../components/UI/Editor";
import {validationData} from "../components/UI/formValid";
import {ModalLoader} from "../components/UI/Loader";

const requisitesConfig = {
    name: { required: true, maxLength: 255 },
    person: { maxLength: 255 },
    country: { maxLength: 255 },
    details: { maxLength: 255 },
}

export const RequisitesModalNew = ({ parrentId, id, close, refresh }) => {
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState({})
    const [values, setValues] = useState({
        name: '',
        person: '',
        country: '',
        details: '',
        description: '',
        tch: true
    })

    const getInfo = async () => {
        const { status, data } = await myaxios.get(`/ajax/account/${parrentId}/payment-detail/${id}`, {method: 'get',})
        if(status === 200 && data) {
            setValues({ ...values, ...data })
        }
        setLoading(false)
    }

    const saveData = async () => {
        if(values.tch) return

        const errorsData = validationData(values, requisitesConfig)
        setErrors(errorsData)
        if(Object.keys(errorsData).length > 0) {
            Notif('required-fields')
            return
        }

        setLoading(true)
        try {
            const { data, status } = id ?
                await myaxios.put(`/ajax/account/${parrentId}/payment-detail/${id}`, values) :
                await myaxios.post(`/ajax/account/${parrentId}/add-payment-detail`, values)

            if((status === 200 || status === 201) && data) {
                refresh()
            }
        } catch (e) { }
        setLoading(false)
    }

    useEffect(() => {
        if(id) getInfo().then()
        else setLoading(false)
    }, [])

    return (
        <div className="sbform create-activ">
            <div className="rmod-header">
                <b>Реквизиты</b>
                <a className="mclose" onClick={close}><i className="flaticon-close" /></a>
                <div className="togglelist"><button onClick={saveData} className="rmod-ok" disabled={values.tch} ><i className="flaticon-interface-1" /></button></div>
            </div>
            {loading && <ModalLoader />}
            <div className="mod-cont">
                <Input
                    className="c12"
                    label="Название"
                    type="text"
                    value={values.name}
                    onKeyDown={e => e.keyCode === 13 && saveData()}
                    onChange={event => setValues({ ...values, name: event.target.value, tch: false })}
                    valid={!errors.name} errorMessage={errors.name} shouldValidate
                />
                <Input
                    className="c12"
                    label="Страна"
                    type="text"
                    onKeyDown={e => e.keyCode === 13 && saveData()}
                    value={values.country}
                    onChange={event => setValues({ ...values, country: event.target.value, tch: false })}
                    valid={!errors.country} errorMessage={errors.country} shouldValidate
                />
                <Input
                    className="c12"
                    label="Юридическое лицо"
                    type="text"
                    onKeyDown={e => e.keyCode === 13 && saveData()}
                    value={values.person}
                    onChange={event => setValues({ ...values, person: event.target.value, tch: false })}
                    valid={!errors.person} errorMessage={errors.person} shouldValidate
                />
                <MainEditor
                    className="c12"
                    label="Реквизиты"
                    rows="4"
                    value={values.details}
                    onChange={event => {
                        if(values.details !== event) setValues({ ...values, details: event })
                    }}
                    valid={!errors.details} errorMessage={errors.details}
                />
            </div>
        </div>
    )
}

class Requisites extends Component {
    state = {
        formControl: {
            name: {value: ''},
            country: {value: ''},
            jur: {value: ''},
            requisites: {value: ''},
            comment: {value: ''}
        },
        isFormValid: true,
        tch: true,
    };
    getInfo = () => {
        myaxios.get(`/ajax/account/${this.props.parrentId}/payment-detail/${this.props.id}`, {method: 'get',}).then((responce) =>{
            console.log(responce.data)
            const formControl = this.state.formControl;
            formControl.name.value = responce.data.name;
            formControl.jur.value = responce.data.person;
            formControl.country.value = responce.data.country;
            formControl.requisites.value = responce.data.details;
            formControl.comment.value = responce.data.description;
            this.setState({ formControl })
        })
    };
    componentDidMount(){
        if(this.props.id) this.getInfo()
        console.log(this.props)
    }
    emptyFields = () => {
        const form = this.state.formControl;
        if(form.name.value.length > 2) {return true}
    }
    setField = (event, type) => {
        const formControl = {  ...this.state.formControl };
        const control = { ...formControl[type] };
        control.value = event.target.value;
        formControl[type] = control;
        this.setState({
            formControl, tch: !this.emptyFields()
        })
    };
    editForm = event => {
        event.preventDefault();
        if(this.emptyFields()){
            myaxios.put(`/ajax/account/${this.props.parrentId}/payment-detail/${this.props.id}`, {
                name: this.state.formControl.name.value,
                person: this.state.formControl.jur.value,
                country: this.state.formControl.country.value,
                details: this.state.formControl.requisites.value,
                description: this.state.formControl.comment.value
            }).then((response) => {
                if(response.status === 201 || response.status === 200) {
                    setTimeout(this.props.refresh, 300);
                }
            })
            const addr = {};
            setTimeout(() => this.props.addAddress("add", "requisites", null, addr), 300);
        } else {
            (this.emptyFields() === true) ? Notif("required-fields") : Notif("required-fields", "Для создания контакта заполните как минимум одно поле")
        }
    };

    sendForm = event => {
        event.preventDefault();
        if(this.emptyFields()){
            this.setState({ tch: true });
            myaxios.post(`/ajax/account/${this.props.parrentId}/add-payment-detail`, {
                name: this.state.formControl.name.value,
                person: this.state.formControl.jur.value,
                country: this.state.formControl.country.value,
                details: this.state.formControl.requisites.value,
                description: this.state.formControl.comment.value
            }).then((response) => {
                if(response.status === 201 || response.status === 200) {
                    const arr = response.data;
                    this.props.refresh && setTimeout(this.props.refresh, 300);
                    setTimeout(() => this.props.addAddress("add", "requisites", null, arr ), 300);
                }
            })

        } else {
            (this.emptyFields() === true) ? Notif("required-fields") : Notif("required-fields", "Для создания контакта заполните как минимум одно поле")
        }
    };
    render(){
        return <div className="sbform create-activ">
            <div className="rmod-header">
                <b>Реквизиты</b>
                <a className="mclose" onClick={this.props.close}><i className="flaticon-close" /></a>
                <div className="togglelist"><button onClick={(this.props.id) ? this.editForm : this.sendForm} className="rmod-ok" disabled={this.state.tch} ><i className="flaticon-interface-1" /></button></div>
            </div>
            <div className="mod-cont">
                <Input
                    className="c12"
                    label="Название"
                    type="text"
                    value={this.state.formControl.name.value}
                    onChange={event => this.setField(event, "name")}
                />
                <Input
                    className="c12"
                    label="Страна"
                    type="text"
                    value={this.state.formControl.country.value}
                    onChange={event => this.setField(event, "country")}
                />
                <Input
                    className="c12"
                    label="Юридическое лицо"
                    type="text"
                    value={this.state.formControl.jur.value}
                    onChange={event => this.setField(event, "jur")}
                />
                <MainEditor
                    className="c12"
                    label="Реквизиты"
                    rows="4"
                    valid={true}
                    value={this.state.formControl.requisites.value}
                    onChange={event => {
                        const formControl = this.state.formControl;
                        formControl.requisites.value = event;
                        this.setState({ formControl, tch: false })
                    }}
                />
                {/*<MainEditor
                    className="c12"
                    label="Описание"
                    rows="4"
                    valid={true}
                    value={this.state.formControl.comment.value}
                    onChange={event => {
                        const formControl = this.comment.formControl;
                        formControl.comment.value = event;
                        this.setState({ formControl, tch: false })
                    }}
                />*/}
            </div>
        </div>
    }
}

export default Requisites;