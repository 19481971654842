import {NewList} from "../../components/NewList/NewList";
import {entity} from "../../components/ListComponentEntity";
import {NavLink} from "react-router-dom";
import React, {useEffect, useState} from "react";
import localstorageSettings from "../../components/localstorageSettings";
import myaxios from "../../components/Axios";
import {ModalLoader} from "../../components/UI/Loader";
import LeadFunnelDropDown from "../../components/other/LeadFunnelDropDown";

export const NewLeadList = (props) => {
    const [changedFunnel, setChangedFunnel] = useState('')
    const [funnels, setFunnels] = useState([])
    const [loading, setLoading] = useState(true)

    const getLeadFunnel = (data) => {
        const localStorageData = localStorage.getItem('leadFunnel')
        if(localStorageData && data.find(el => +el.id === +localStorageData)) {
            return localStorageData
        } else {
            localStorage.setItem('leadFunnel', data[0].id)
            return data[0].id
        }
    }

    const getStatuses = async () => {
        setLoading(true)
        const { data: funnelData } = await myaxios.get('/ajax/sales-funnel');
        if(funnelData) {
            setFunnels(funnelData.items)
        }
        setLoading(false)
    }

    useEffect(() => {
        getStatuses().then()
    }, [changedFunnel])

    return loading ? <ModalLoader /> : (
        <>
            <NewList
                { ...props }
                entity={'lead'}
                entity_type={23}
                requiredFields={'id,name,status_id,amount,type_id'}
                requiredExpand={`status,currency,type&filter[sales_funnel_id][eq]=${getLeadFunnel(funnels)}`}
                deleteToShowColumn={'name,status_id,amount,payment,type_id'}
                additionalFieldsExists={true}
                isFilterExists={true}
                filterFields={entity.lead.fArr.map((item) => {
                    if(item.value === 'status_id') {
                        return {
                            ...item,
                            filterLink: `/ajax/lookup/search?type=ld_status&sales_funnel_id=16&value=`
                        }
                    } else {
                        return item
                    }
                })}
                singleSearch={entity.lead.fArr[0]}
                headingColumns={['Название', 'Данные о потребности', 'Статус']}
                RenderColumn={(item) => (
                    <>
                        <td>
                            <NavLink to={`/lead/${item.id}`}  className={'nowrap-list-column'}>{item.name}</NavLink>
                            <div className={'nowrap-list-column'}>Бюджет: {item.amount}{item.currency && item.currency.text_symbol}</div>
                        </td>
                        <td>
                            <div>Тип: {item.type && item.type.value}</div>
                        </td>
                        <td>
                            {item.status && <span className="label-st" style={{ backgroundColor: item.status.color ? item.status.color : 'rgb(255, 193, 100)' }}>{item.status.value}</span>}
                        </td>
                    </>
                )}
                createButtonRender={(setModalType) => <>
                        <a onClick={() => {props.history.push(`/lead/kanban`); localstorageSettings("post", "leadView", "kanban")}} className="btns"><i className="flaticon-web" /></a>
                        <button onClick={() => setModalType('lead')} className="open-modal-button btni">Добавить лид</button>
                    </>}
                analytics={'/lead/report/'}
                rightNavbarConfig={{
                    export: true,
                    additionalFields: '/fieldsettings/23',
                    columns: true
                }}
                massDoConfig={{ deleteAll: `/ajax/lead/delete-all` }}
                contextNav={{ edit: true, task: true, print: false, delete: true }}
                sort={entity.lead.fArr.filter(el => el.sort)}
                dropdownRender={() => <>
                    {funnels.length > 0 && <LeadFunnelDropDown
                        arr={funnels}
                        chosenFunnel={localStorage.getItem('leadFunnel') || (funnels[0] && funnels[0].id)}
                        onChange={e => {
                            setChangedFunnel(e)
                            localStorage.setItem('leadFunnel', e)
                        }}
                    />}
                </>}
            />
        </>
    )
}