import React, {useEffect, useState} from 'react';
import {FieldGen, setField, updateData, unLink, postDt} from '../components/fieldGen';
import myaxios from "../components/Axios";
import formValid from "../components/UI/formValid";
import Notif from "../components/Notif";
import Loader from "../components/UI/Loader";
import withGlobalState from "../components/other/withGlobalState";

const PaymentsModal = props => {
    const [myState, setMyState] = useState({
        formControl: {
            name: {
                type: 'input',
                label: 'Название платежа',
                value: '',
                valid: true,
                validation: {required: true}
            },
            balance_id: {
                type: "select",
                label: "Кошелек",
                value: "",
                options: [],
                valid: true,
                validation: {required: true},
                lab: "name",
                val: "id"
            },
            amount: {
                type: 'input',
                label: 'Сумма',
                inputType:'number',
                value: '',
                valid: true,
                cls:'c4',
                validation: {required: true}
            },
            currency_id: {
                type: 'modAutoSelect',
                value:'',
                cls:'c4',
                label: 'Валюта',
                link: '/ajax/lookup/search?type=currency&value=',
                result:'text_symbol',
                valid: true,
                entity:null,
                entityName:"text_symbol"
            },
            exchange_rate: {
                type: 'input',
                label: 'Курс в б.в.',
                inputType:'number',
                tooltip:'Курс конвертации в валюту счета',
                value: '1',
                valid: true,
                cls:'c4',
                validation: {required: true}
            },
            payment_method_id: {
                type: 'modAutoSelect',
                cls:'c12',
                label: 'Метод оплаты',
                link: '/ajax/lookup/search?type=payment_method&value=',
                value:'',
                result:'value',
                valid: true,
                entity:null,
                entityName:"value",
                validation: {required: true}
            },
            status: {
                type:'select',
                cls:'c12',
                label:'Статус',
                value:'4',
                valid:true,
                options:[
                    {value:'1', label:'Новый'},
                    {value:'2', label:'Истек'},
                    {value:'3', label:'Ожидает'},
                    {value:'4', label:'Оплачен'},
                    {value:'5', label:'Неудачный'},
                ]
            },
            payment_date: {
                type: 'dateTime',
                label: 'Дата платежа',
                value: new Date(),
                cls:'c12',
                valid: true,
                validation: {required: true}
            },
            contact_id: {value:props.contact_id},
            account_id: {value:props.account_id},

        },
        baseCurrencyCurs: 0,
        baseCurrencyId: 0,
        allBalances: [],
        loading: true
    });
    const sState = (st, func) => {
        Object.keys(st).map((key) => { myState[key] = st[key]});
        setMyState(prevState => { return {...prevState, ...myState} });
        if(func) func();
    };
    const isFormValid = () => {
        let isFormValid = true;
        Object.keys(myState.formControl).map((key, index) => {
            myState.formControl[key].valid = formValid(myState.formControl[key].value, myState.formControl[key].validation);
            isFormValid = myState.formControl[key].valid && isFormValid;
        });
        sState({ formControl: myState.formControl })
        return isFormValid;
    };
    const getBaseCurrency = () => {
        myaxios.get(`/ajax/lookup/search?type=currency&value=`).then(res => {
            res.data.items.map(item => {
                if(item.id === Number(props.currency_id)){
                    sState({ baseCurrencyId: item.id, baseCurrencyCurs: item.value });
                    if(!props.id) {
                        const formControl = myState.formControl;
                        formControl.currency_id.value = item.id;
                        formControl.currency_id.entity = item;
                        sState({ formControl })
                    }
                }}
            )
        })

        myaxios.get(`/ajax/payment/prepare`).then(res => {
            const formControl = myState.formControl;
            if(!props.id) formControl.name.value = 'PAY-' + res.data.nextId;

            let items = [];
            if(props.currency_id) {
                items = res.data.balances.filter(el => props.currency_id == el.currency_id)
            } else {
                items = res.data.balances
            }
            formControl.balance_id.options = items || []
            formControl.balance_id.value = items[0] ? items[0].id : ""
            sState({ formControl, allBalances: res.data.balances })
        })
    }

    const getData = () => {
        sState({ loading: true })
        myaxios.get(`/ajax/invoice/payment/${props.id}`).then(res => {
            const formControl = myState.formControl;
            formControl.name.value = res.data.name;
            formControl.amount.value = res.data.amount;
            formControl.payment_date.value = res.data.payment_date;
            formControl.status.value = String(res.data.status);
            formControl.contact_id.value = res.data.contact_id;
            formControl.account_id.value = res.data.contact_id;
            formControl.payment_method_id.value = res.data.payment_method_id;
            formControl.payment_method_id.entity = res.data.paymentMethod;
            formControl.currency_id.value= res.data.currency_id;
            formControl.currency_id.entity = res.data.currency;
            formControl.exchange_rate.value = res.data.exchange_rate;
            sState({ formControl: formControl, loading: false })
        })
    }
    useEffect(() => {
        (props.id) ? getData() : sState({ loading: false });
        getBaseCurrency();
    },[]);

    const postData = () => {
        const postLink = (props.withoutInvoice) ? `/ajax/payment` : `/ajax/invoice/${props.invoice_id}/payment`
        if(isFormValid()){
            if(props.id){
                myaxios.put(`/ajax/invoice/payment/${props.id}`, postDt(myState.formControl)).then(res => {
                    if(res.status === 200){
                        setTimeout(() => {props.saveProduct(); props.close()})
                    }
                })
            } else {
                myaxios.post(postLink, postDt(myState.formControl)).then(res => {
                    if(res.status === 200) {
                        setTimeout(() => {props.saveProduct(); props.close()})
                        if(myState.formControl.status.value == "4" && myState.formControl.amount.value == "5000") props.setSteps("invoices", true, true)
                    }}
                )
            }
        } else Notif('Error');
    }
    return myState.loading ? <Loader contain /> : <div className="sbform create-activ">
        <div className="rmod-header">
            <b>Платежи</b>
            <div className="togglelist"><a onClick={postData} className="rmod-ok" target="_blank"><i className="flaticon-folder-3" /></a></div>
        </div>
        <div className="mod-cont">
            <FieldGen
                arr={myState.formControl}
                setField={(e, type) => {
                    const formControl = setField(myState.formControl, type, e)
                    sState({ formControl  })
                }}
                updateData={(id, arr, type) => {
                    const fc = updateData(myState.formControl,id, arr, type);
                    if(type === "currency_id"){
                        fc.exchange_rate.value = arr.value / myState.baseCurrencyCurs;

                        const items = myState.allBalances.filter(el => el.currency_id == id)
                        fc.balance_id.options = items || [];
                        fc.balance_id.value = items[0] ? items[0].id : "";
                    }
                    sState({ formControl: fc })
                } }
                unLink={(type) => {
                    const formControl = unLink(myState.formControl, type);
                    if(type === "currency_id") formControl.balance_id.options = myState.allBalances;
                    sState({formControl})
                }}
            />
        </div>
    </div>
};

export default withGlobalState(PaymentsModal);
