import React, {Component} from 'react';

const AddressItem = props => {
    return <tr>
        <td><a onClick={props.open} href={null}>{props.type}</a></td>
        <td>{props.country}</td>
        <td>{props.city}</td>
        <td>{props.address}</td>
        <td>{props.inx}</td>
        <td><div className="toogle-bottom fl-r">
            <a className="is" href="#"><i className="flaticon-more" /></a>
            <ul className="toogle-list">
                <li><a onClick={props.open} href={null}><i className="flaticon-edit-1" /> <b>Редактировать</b></a></li>
                <li><a onClick={props.delete} className="table-del"><i className="flaticon-delete-1" /> <b>Удалить</b></a></li>
            </ul>
        </div>
        </td>
    </tr>
}

export default AddressItem;