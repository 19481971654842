import React, {useCallback, useRef, useState} from "react";
import {useOnClickOutside} from "../hooks/useOnClickOutside";
import {CSSTransition} from "react-transition-group";

const LeadFunnelDropDown = ({ arr = [], chosenFunnel, onChange }) => {
    const [isOpen, setIsOpen] = useState(false)
    if(arr.length <= 1 && !chosenFunnel) {
        return <div />
    }
    const dropdownRef = useRef(null)
    useOnClickOutside({ ref: dropdownRef, handler: () => setIsOpen(false) })

    const changeValue = useCallback((id) => {
        onChange(id)
        setIsOpen(false)
    }, [chosenFunnel, arr])

    const chosen = arr.find(el => +chosenFunnel === +el.id) || {}
    return (
        <>
            <li ref={dropdownRef} onClick={() => setIsOpen(!isOpen)} style={{ position: 'relative' }}>
                <a>
                    <i className="flaticon-cogwheel-1" />
                    <b> Воронка: {chosen.name}</b>
                </a>
                <CSSTransition in={isOpen} timeout={200} classNames="my-node" unmountOnExit>
                    <div className={'toogle-list scrollbar'} style={{ display: "block", top: '0', right: '185px', maxHeight: '200px' }}>
                        {
                            arr.filter(el => +el.id !== chosenFunnel).map(item => {
                                const isActive = +chosenFunnel === +item.id
                                return (
                                    <li
                                        onClick={() => changeValue(item.id)}
                                        className={`toogle-list-sort ${isActive && 'toogle-list-active'}`}
                                    >
                                        <a><b>{item.name}</b></a>
                                    </li>
                                )
                            })
                        }
                    </div>
                </CSSTransition>
            </li>
        </>
    )
}

export default React.memo(LeadFunnelDropDown)