export const fieldConfig = {
    name: {
        type: 'input',
        label: 'Название',
        value:'',
        inputType:'text',
        valid: true,
        validation: {required: true},
        zone: 1
    },
    amount: {
        type: 'input',
        label: 'Сумма',
        cls: "c8",
        value:'',
        inputType:'number',
        post: "number",
        valid: true,
        validation: {required: true},
        zone: 1
    },
    currency_id: {
        type: 'modAutoSelect',
        cls:'c4',
        label: 'Валюта',
        link: '/ajax/lookup/search?type=currency&value=',
        value:'',
        result:'text_symbol',
        valid: true,
        entity:null,
        from:'currency',
        entityName:"text_symbol",
        validation: {required: true},
        zone: 1
    },
    status: {
        type: 'select',
        label: 'Статус',
        post: "number",
        value:'',
        options:[],
        valid: true,
        validation: {required: true},
        lab: "value",
        val: "id",
        zone: 1
    },
    datetime: {
        type: 'dateTime',
        label: 'Дата и время',
        value:'',
        valid: true,
        validation: {required: true},
        zone: 1
    },
    payment_method_id: {
        type: 'select',
        label: 'Тип оплаты',
        value:'',
        options:[],
        valid: true,
        validation: {required: true},
        post: "number",
        lab: "value",
        val: "id",
        from:'paymentMethod',
        zone: 1
    },
    category_id: {
        type: 'modAutoSelect',
        cls:'c12',
        label: 'Категория',
        post: "number",
        link: '/ajax/lookup/search?type=expense_category&value=',
        value:'',
        result:'value',
        valid: true,
        entity:null,
        entityName:"value",
        from:'category',
        validation: {required: true},
        zone: 1
    },
    owner_id: {
        type: 'modAutoSelect',
        cls:'c12',
        label: 'Ответственный',
        link: '/ajax/settings/user?filter[name][like]=',
        value:'',
        result:'name',
        valid: true,
        entity:null,
        from:'owner',
        entityName:"name",
        validation: {required: true},
        zone: 1
    },
    lead_id: {
        type: 'modAutoSelect',
        cls:'rc6',
        label: 'Лид',
        link: '/ajax/lead?filter[name][like]=',
        value:'',
        result:'name',
        valid: true,
        entity:null,
        from:'lead',
        entityName:"name",
        redirectLink: "/lead/",
        zone: 2
    },

    opportunity_id: {
        type: 'modAutoSelect',
        cls:'rc6',
        label: 'Сделка',
        link: '/ajax/opportunity?filter[name][like]=',
        value:'',
        result:'name',
        valid: true,
        entity:null,
        from:'opportunity',
        entityName:"name",
        redirectLink: "/opportunity/",
        zone: 2
    },
    order_id: {
        type: 'modAutoSelect',
        cls:'rc6',
        label: 'Заказ',
        link: '/ajax/order?filter[name][like]=',
        value:'',
        result:'name',
        valid: true,
        entity:null,
        from:'order',
        entityName:"name",
        redirectLink:"/order/",
        zone: 2
    },
    project_id: {
        type: 'modAutoSelect',
        cls:'rc6',
        label: 'Проект',
        link: '/ajax/project?filter[name][like]=',
        value:'',
        result:'name',
        valid: true,
        entity:null,
        from:'project',
        entityName:"name",
        redirectLink:"/project/",
        zone: 2
    },
    case_id: {
        type: 'modAutoSelect',
        cls:'rc6',
        label: 'Обращение',
        link: '/ajax/case?filter[name][like]=',
        value:'',
        result:'name',
        valid: true,
        entity:null,
        from:'case',
        entityName:"name",
        redirectLink:"/case/",
        zone: 2
    },
    description: {
        type: 'editor',
        label: 'Комментарий',
        className:'rc12',
        value:'',
        inputType:'text',
        valid: true,
        rows:6,
        zone: 2
    },
    contact_id:{
        type:'emptySelect',
        value:'',
        entity:null,
        from:'contact',
    },
    account_id:{
        type:'emptySelect',
        value:'',
        entity:null,
        from:'account',
    }
}