import React, {Component} from 'react';
import Input from '../../UI/Input';
import formValid from '../../UI/formValid';
import myaxios from '../../Axios';
import Myselect from '../../UI/Select';
import Textarea from "../../UI/Textarea";

export default class SendSms extends Component {
    state = {
        formControl: {
            name:{value:'',valid:true, validation:{required:true}},
            to:{value:'mobile_phone',arr:[{value:'mobile_phone',label:'Мобильный телефон'},{value:'work_phone',label:'Рабочий телефон'}],valid:true,validation:{required:true}},
            body:{value:'',valid:true, arr:[], validation:{required:true}},
            time_value:{value:0},
        },
    };

    componentWillMount(){
        console.log("this.props", this.props);
        if(this.props.elArr){
            let formControl = this.state.formControl;
            formControl.name.value = this.props.elArr.metaData ? this.props.elArr.name : '';
            formControl.to.value = this.props.elArr.metaData ? this.props.elArr.metaData.to : '';
            formControl.body.value = this.props.elArr.metaData ? this.props.elArr.metaData.body : '';
            formControl.time_value.value = this.props.elArr.metaData ? this.props.elArr.time_value : '';
            this.setState({ formControl })
        }
    }
    setField = (type, e) => {
        let formControl = this.state.formControl;
        if(type === "template"){
            for(let i = 0; i < formControl.template.arr.length; i++){
                if(e == formControl.template.arr[i].id){
                    formControl.subject.value = formControl.template.arr[i].subject;
                    formControl.body.value = formControl.template.arr[i].body;
                }
            }
        }
        formControl[type].value = e;
        this.setState({ formControl })
    };
    isFormValid = () => {
        let formControl = this.state.formControl;
        let isFormValid = true;
        Object.keys(formControl).map((key, index) => {
            formControl[key].valid = formValid(formControl[key].value, formControl[key].validation);
            isFormValid = formControl[key].valid && isFormValid
        });
        this.setState({formControl});
        return isFormValid;
    };
    sendForm = () => {
        if(this.isFormValid()){
            if(this.props.elArr){
                setTimeout(
                    this.props.updateNode({
                        name: this.state.formControl.name.value,
                        time_value: this.state.formControl.time_value.value,
                        is_scheduled: (this.state.formControl.time_value.value > 1),
                        metaData: {
                            to: this.state.formControl.to.value,
                            body: this.state.formControl.body.value,
                        },
                        nodeType: "node",
                        type:"sendSms",
                    })
                )
            } else {
                setTimeout(
                    this.props.createNode({
                        name: this.state.formControl.name.value,
                        time_value: this.state.formControl.time_value.value,
                        is_scheduled: (this.state.formControl.time_value.value > 1),
                        metaData: {
                            to: this.state.formControl.to.value,
                            body: this.state.formControl.body.value,
                        },
                        nodeType: "node",
                        type:"sendSms",
                        filter:this.state.filter
                    })
                )
            }
        }
    };
    render(){
        return <div>
            <div className="rmod-header">
                <b>Отправить Email</b>
                <a onClick={this.props.close} style={{ marginRight: "60px" }} className="mclose"><i className="flaticon-close" /></a>
                <div className="togglelist"><button onClick={this.sendForm} className="rmod-ok" disabled=""><i className="flaticon-interface-1" /></button></div>
            </div>
            <div className="mod-cont sbform">
                <Input
                    label={"Название"}
                    value={this.state.formControl.name.value}
                    valid={this.state.formControl.name.valid}
                    onChange={e => this.setField("name", e.target.value)}
                    shouldValidate={true}
                />
                <Myselect
                    empty
                    tooltip={"К каждой сущности привязывается контакт либо контрагент. Выберите тип номера телефона, из которого бизнес-процесс будет брать значение для отправки SMS"}
                    label="Кому"
                    options={this.state.formControl.to.arr}
                    value={this.state.formControl.to.value}
                    valid={this.state.formControl.to.valid}
                    shouldValidate={true}
                    onChange={e => this.setField("to", e.target.value)}
                />
                <Textarea
                    label="Текст сообщения"
                    value={this.state.formControl.body.value}
                    valid={this.state.formControl.body.valid}
                    shouldValidate={true}
                    onChange={e => this.setField("body", e.target.value)}
                    rows="8"
                />
                <Input
                    tooltip="Процесс запустится через N секунд, после того, как сработает триггер"
                    type="number"
                    value={this.state.formControl.time_value.value}
                    onChange={e => this.setField("time_value", e.target.value)}
                    shouldValidate={false}
                    label="Отложенный запуск на N секунд"
                />
            </div>
        </div>
    }
}
