import {
    createPriceBookService,
    updatePriceBookService,
    getPriceBookService,
    deletePriceBookService,
    linkPriceBookToProductService, unlinkPriceBookToProductService
} from "../../../services/product/product";

export const getPriceBookTest = async (query) => {
    try {
        const res = await getPriceBookService(query)
        if (res && res.status === 200) {
            return {status: true, data: res.data.items}
        } else {
            console.error('getPriceBookTest status error')
            return {status: false}
        }
    } catch (e) {
        console.error('getPriceBookTest catch error', e)
        return { status: false }
    }
}

export const createPriceBookTest = async (JSON) => {
    try {
        const res = await createPriceBookService(JSON)
        if (res && res.status === 201) {
            return {status: true, id: res.data.id}
        } else {
            console.error('createPriceBookTest status error')
            return {status: false}
        }
    } catch (e) {
        console.error('createPriceBookTest catch error', e)
        return { status: false }
    }
}

export const updatePriceBookTest = async (id, JSON) => {
    try {
        const res = await updatePriceBookService(id, JSON)
        if (res && res.status === 200) {
            return {status: true}
        } else {
            console.error('updatePriceBookTest status error')
            return {status: false}
        }
    } catch (e) {
        console.error('updatePriceBookTest catch error', e)
        return { status: false }
    }
}

export const deletePriceBookTest = async (id) => {
    try {
        const res = await deletePriceBookService(id)
        if (res && res.status === 200) {
            return {status: true}
        } else {
            console.error('deletePriceBookService status error')
            return {status: false}
        }
    } catch (e) {
        console.error('deletePriceBookService catch error', e)
        return { status: false }
    }
}

export const linkPriceBookTest = async (id, price_book_id, data) => {
    try {
        const res = await linkPriceBookToProductService(id, price_book_id, data)
        if (res && res.status === 200) {
            return {status: true}
        } else {
            console.error('linkPriceBookTest status error')
            return {status: false}
        }
    } catch (e) {
        console.error('linkPriceBookTest catch error', e)
        return { status: false }
    }
}

export const unlinkPriceBookTest = async (id, price_book_id) => {
    try {
        const res = await unlinkPriceBookToProductService(id, price_book_id)
        if (res && res.status === 200) {
            return {status: true}
        } else {
            console.error('unlinkPriceBookTest status error')
            return {status: false}
        }
    } catch (e) {
        console.error('unlinkPriceBookTest catch error', e)
        return { status: false }
    }
}