import React, {Component} from 'react';
import Myselect from '../components/UI/Select';
import Textarea from '../components/UI/Textarea';
import Notif from '../components/Notif';
import myaxios from '../components/Axios';
import {withRouter} from 'react-router-dom';
import formValid from '../components/UI/formValid';
import ModAutoSelect from '../components/UI/ModAutoSelect';

class EndOpportunity extends Component {
    state = {
        formControl: {
            status: {value: '', arr:null, valid:true, validation:{required:true}},
            comment: {value:'', valid:true, validation:{required:true}}
        },
        options: [
            {value: 'gone', label: 'Ушли к конкурентам'},
            {value: 'expensive', label: 'Дорого'},
            {value: 'not_relevant', label: 'Предложение не актуально'},
            {value: 'bad_delivery', label: 'Не устроила доставка'},
            {value: 'other', label: 'Другое'},
        ],

    };
    failedOpportunity = () => {
        if(this.isFormValid()) {
            myaxios.post(`/ajax/opportunity/${this.props.id}/close`, {
                reason: this.state.formControl.comment.value,
                failure_reason_id: this.state.formControl.status.value
            }).then(responce => {
                if (responce.status === 200 || responce.status === 201 || responce.status === 204) {
                    this.props.history.push('/opportunity/');
                    Notif("save", "Сделка успешно завершена")
                }
            })
        } else Notif("Error")
    };
    setField = (event, type) => {
        const formControl = {  ...this.state.formControl };
        const control = { ...formControl[type] };
        control.value = event.target.value;
        formControl[type] = control;
        this.setState({ formControl })
    };
    isFormValid = () => {
        let formControl = this.state.formControl;
        let isFormValid = true;
        Object.keys(formControl).map((key, index) => {
            formControl[key].valid = formValid(formControl[key].value, formControl[key].validation)
            isFormValid = formControl[key].valid && isFormValid
        });
        this.setState({
            formControl, isFormValid
        });
        return isFormValid;
    };
    updateData = (id, arr, addType, endParrans) => {
        const formControl = this.state.formControl;
        formControl[addType].value = id;
        formControl[addType].arr = arr;
        this.setState({ formControl, tch: false })
    };
    unLink = addType => {
        const formControl = this.state.formControl;
        formControl[addType].value = '';
        formControl[addType].arr = null;
        this.setState({ formControl, tch: false})
    }
    render(){
        return <form className="sbform modal-ok">
            <div className="modal-head">
                <a onClick={() => {this.props.close()}} className="modal-head-close" href={null}><i className="flaticon-close" /></a>
                <b>Завершить сделку</b>
            </div>
            <div className="modal-body">
                <ModAutoSelect
                    label="Причина"
                    link="/ajax/lookup/search?type=flr_reason&value="
                    minLength="0"
                    result="value"
                    event="status"
                    errorMessage="Выберите причину провала"
                    valid={this.state.formControl.status.valid}
                    shouldValidate={true}
                    addType="status"
                    entity={this.state.formControl.status.arr}
                    entityName="value"
                    updateData={this.updateData}
                    unLink={this.unLink}
                    //redirectLink="/order/"
                />
                <Textarea
                    label="Комментарий"
                    rows="4"
                    onChange={event => this.setField(event, 'comment')}
                    value={this.state.formControl.comment.value}
                    valid={this.state.formControl.comment.valid}
                    errorMessage="Опишите причину провала сделки"
                    shouldValidate={true}
                />
                <a onClick={this.failedOpportunity} className="m-red" href={null}>Завершить</a>
            </div>
        </form>
    }
}

export default withRouter(EndOpportunity);