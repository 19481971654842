import React, {Component} from 'react';
import Input from '../components/UI/Input';
import formValid from '../components/UI/formValid';
import Notif from '../components/Notif';
import myaxios from '../components/Axios';
import {withRouter} from 'react-router-dom';
import Myselect from '../components/UI/Select';
import AutoSelect from '../components/UI/AutoSelect';
import ModAutoSelect from '../components/UI/ModAutoSelect';

class Qualification extends Component {
    state = {
        contactArr: {
            first_name: {value:'',valid:true,validation:{required:true}},
            last_name:{value:''},
            middle_name:{value:''},
            phone:{value:''},
            email:{value:'',valid:true,validation:{required:false,email:true}}
        },
        accountArr:{
            name: {value:'',valid:true,validation:{required:true}},
            phone:{value:''},
            email:{value:'',valid:true,validation:{required:false,email:true}}
        },
        opportunityArr:{
            name: {value:'',valid:true,validation:{required:true}},
        },
        formControl:{
            responsible:{value:'',arr:null,valid:true, validation:{required:true}}
        },
        contact:false,
        account:false,
        opportunity:false,
        order:false,
        isFormValid: true,
        response: true,
        convertContact:null,
        convertAccount:null,
        convertOpportunity:null,
        opportunityNumber: 0
    };
    getOppNumber = () => {
        let opportunityArr = this.state.opportunityArr;
        myaxios.get(`/ajax/opportunity/prepare`, {method: 'get',}).then((responce) =>{
            opportunityArr.name.value = `Сделка #${responce.data.nextId}`
            this.setState({ opportunityArr })
        })
    }
    componentDidMount(){
        let contactArr = this.state.contactArr;
        let accountArr = this.state.accountArr;
        let opportunityArr = this.state.opportunityArr;
        let formControl = this.state.formControl;
        contactArr.first_name.value = this.props.first_name;
        contactArr.last_name.value = this.props.last_name;
        contactArr.middle_name.value = this.props.middle_name;
        contactArr.phone.value = this.props.phone;
        contactArr.email.value = this.props.email;
        accountArr.name.value = this.props.account;
        accountArr.phone.value = this.props.phone;
        accountArr.email.value = this.props.email;
        //opportunityArr.name.value = `Сконвертированная сделки из лида ${this.props.name}`;
        formControl.responsible.value = this.props.owner ? this.props.owner.id : '';
        formControl.responsible.arr = this.props.owner ? this.props.owner.arr : null;
        this.setState({
            contactArr, accountArr, opportunityArr, formControl, tch: false
        });
        this.getOppNumber();
    }

    setField = (event, type, obj) => {
        let contactArr = this.state.contactArr;
        let accountArr = this.state.accountArr;
        let opportunityArr = this.state.opportunityArr;
        if(obj === "contactArr"){
            contactArr[type].value = event.target.value
        }

        if(obj === "accountArr"){
            accountArr[type].value = event.target.value
        }

        if(obj === "opportunityArr"){
            opportunityArr[type].value = event.target.value
        }

        this.setState({
            contactArr, accountArr, opportunityArr, tch: false
        });
    };
    isEmpty(){
        if((this.state.contact || this.state.account || this.state.opportunity)){
            return true
        } else {
            return false
        }
    }
    sendForm = event => {
        event.preventDefault();
        if(this.isFormValid() && this.isEmpty()){
            this.setState({ tch: true });
            myaxios.post(`/ajax/lead/${this.props.id}/convert`, {
                new_contact: this.state.contact,
                new_account: this.state.account,
                new_opportunity: this.state.opportunity,
                contactFields: {
                    first_name: this.state.contactArr.first_name.value,
                    last_name: this.state.contactArr.last_name.value,
                    middle_name: this.state.contactArr.middle_name.value,
                    mobile_phone: this.state.contactArr.phone.value,
                    email: this.state.contactArr.email.value
                },
                accountFields: {
                    account_name: this.state.accountArr.name.value,
                    account_phone: this.state.contactArr.phone.value,
                    account_email: this.state.contactArr.email.value
                },
                opportunityFields: {
                    name: this.state.opportunityArr.name.value
                },
                owner: this.state.formControl.responsible.value
            }).then((response) => {
                if(response.status === 201 || response.status === 200 || response.status === 204) {
                    Notif("save", "Лид успешно квалифицирован");
                    if(response.data.contact) this.setState({ convertContact: response.data.contact.id, response: false });
                    if(response.data.account) this.setState({ convertAccount: response.data.account.id, response: false });
                    if(response.data.opportunity) this.setState({ convertOpportunity: response.data.opportunity.id, response: false });
                }
            })
        } else {
            if(this.isEmpty()){
                Notif("Fields");
            } else {
                Notif("Fields", "Выберите как минимум одно значение");
            }
        }
    };
    isFormValid = () => {
        let contactArr = this.state.contactArr;
        let accountArr = this.state.accountArr;
        let opportunityArr = this.state.opportunityArr;
        let formControl = this.state.formControl;
        let isFormValid = true;

        if(this.state.contact){
            Object.keys(contactArr).map((key, index) => {
                contactArr[key].valid = formValid(contactArr[key].value, contactArr[key].validation);
                isFormValid = contactArr[key].valid && isFormValid
            });
        }

        if(this.state.account === true){
            Object.keys(accountArr).map((key, index) => {
                accountArr[key].valid = formValid(accountArr[key].value, accountArr[key].validation);
                isFormValid = accountArr[key].valid && isFormValid
            });
        }

        if(this.state.opportunity){
            Object.keys(opportunityArr).map((key, index) => {
                opportunityArr[key].valid = formValid(opportunityArr[key].value, opportunityArr[key].validation);
                isFormValid = opportunityArr[key].valid && isFormValid
            });
        }

            /*Object.keys(formControl).map((key, index) => {
                formControl[key].valid = formValid(formControl[key].value, formControl[key].validation);
                isFormValid = formControl[key].valid && isFormValid
            });*/

        this.setState({
            contactArr, accountArr, opportunityArr, formControl, isFormValid
        });
        return isFormValid;
    };
    updateData = (id, arr, addType, endParrans) => {
        const formControl = this.state.formControl;
        formControl[addType].value = id;
        formControl[addType].arr = arr;
        this.setState({ formControl, tch: false })
    };
    unLink = addType => {
        const formControl = this.state.formControl;
        formControl[addType].value = '';
        formControl[addType].arr = null;
        this.setState({ formControl, tch: false})
    }
    render(){
        return <form className="sbform create-activ">
            <div className="rmod-header">
                <b>Перевод в продажу</b>
                <div className="togglelist"><a onClick={this.sendForm} className="rmod-ok"><i className="flaticon-interface-1" /></a></div>
            </div>
            {this.state.response ? <div className="mod-cont">
                <label style={{padding: "0px", margin: "10px 0px 0px 0px"}}>
                    <input onChange={(e) => this.setField(e, "required")} checked={this.state.contact} onClick={() => this.setState({ contact: (!this.state.contact) })} className="checkbox checkboxlead" type="checkbox" name="checkbox-test" />
                    <span className="checkbox-custom" /><em style={{ display: "inline-block", marginLeft: "5px", position: "relative", top: "1px"}}>Контакт</em>
                </label>
                <div style={{ display: this.state.contact ? 'block' : 'none' }}>
                    <Input required label="Имя" shouldValidate={true} value={this.state.contactArr.first_name.value} valid={this.state.contactArr.first_name.value} onChange={e => this.setField(e, "first_name", "contactArr")} />
                    <Input label="Фамилия" value={this.state.contactArr.last_name.value} onChange={e => this.setField(e, "last_name", "contactArr")} />
                    <Input label="Отчество" value={this.state.contactArr.middle_name.value} onChange={e => this.setField(e, "middle_name", "contactArr")} />
                    <Input label="Телефон" value={this.state.contactArr.phone.value} onChange={e => this.setField(e, "phone", "contactArr")} />
                    <Input label="Email" shouldValidate={true} value={this.state.contactArr.email.value} valid={this.state.contactArr.email.valid} onChange={e => this.setField(e, "email", "contactArr")} />
                </div>

                <label style={{padding: "0px", margin: "10px 0px 0px 0px"}}>
                    <input onChange={(e) => this.setField(e, "required")} checked={this.state.account} onClick={() => this.setState({ account: (!this.state.account) })} className="checkbox checkboxlead" type="checkbox" name="checkbox-test" />
                    <span className="checkbox-custom" /><em style={{ display: "inline-block", marginLeft: "5px", position: "relative", top: "1px"}}>Контрагент</em>
                </label>
                <div style={{ display: this.state.account ? 'block' : 'none' }}>
                    <Input shouldValidate={true} required label="Контрагент" value={this.state.accountArr.name.value} valid={this.state.accountArr.name.value} onChange={e => this.setField(e, "name", "accountArr")} />
                    <Input label="Телефон" value={this.state.accountArr.phone.value} onChange={e => this.setField(e, "phone", "accountArr")} />
                    <Input label="Email" value={this.state.accountArr.email.value} valid={this.state.accountArr.email.value} onChange={e => this.setField(e, "email", "accountArr")} />
                </div>

                <label style={{padding: "0px", margin: "10px 0px 0px 0px"}}>
                    <input onChange={(e) => this.setField(e, "required")} checked={this.state.opportunity} onClick={() => this.setState({ opportunity: (!this.state.opportunity) })} className="checkbox checkboxlead" type="checkbox" name="checkbox-test" />
                    <span className="checkbox-custom" /><em style={{ display: "inline-block", marginLeft: "5px", position: "relative", top: "1px"}}>Сделка</em>
                </label>
                <div style={{ display: this.state.opportunity ? 'block' : 'none' }}>
                    <Input shouldValidate={true} required label="Имя" value={this.state.opportunityArr.name.value} valid={this.state.opportunityArr.name.value} onChange={e => this.setField(e, "name", "opportunityArr")} />
                </div>




                {/*<label style={{padding: "0px", margin: "10px 0px 0px 0px"}}>*/}
                    {/*<input onChange={(e) => this.setField(e, "required")} checked={this.state.order} onClick={() => this.setState({ order: (!this.state.order) })} className="checkbox checkboxlead" type="checkbox" name="checkbox-test" />*/}
                    {/*<span className="checkbox-custom" /><em style={{ display: "inline-block", marginLeft: "5px", position: "relative", top: "1px"}}>Заказ</em>*/}
                {/*</label>*/}
                <form onSubmit={this.sendForm} className="sbform">
                    <ModAutoSelect
                        label="Ответственный"
                        link="/ajax/settings/user?filter[name][like]="
                        minLength="0"
                        result="name"
                        event="responsible"
                        valid={this.state.formControl.responsible.valid}
                        shouldValidate={false}
                        addType="responsible"
                        entity={this.state.formControl.responsible.arr}
                        entityName="name"
                        updateData={this.updateData}
                        unLink={this.unLink}
                    />
                </form>
            </div> :
            <div className="mod-cont">
                {this.state.convertContact ? <a onClick={() => this.props.history.push('/contact/' + this.state.convertContact)} className="qualificationBlock">
                    <i className="flaticon-user" />
                    <span>Контакт успешно создан</span>
                </a> : null}
                {this.state.convertAccount ? <a onClick={() => this.props.history.push('/account/' + this.state.convertAccount)} className="qualificationBlock">
                    <i className="flaticon-users" />
                    <span>Контрагент успешно создан</span>
                </a> : null}
                    {this.state.convertOpportunity ? <a onClick={() => this.props.history.push('/opportunity/' + this.state.convertOpportunity)} className="qualificationBlock">
                    <i className="flaticon-share" />
                    <span>Сделка успешно создана</span>
                </a> : null}
            </div>}
        </form>
    }
}
export default withRouter(Qualification)