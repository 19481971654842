import React from "react";
import {NavLink, withRouter} from "react-router-dom";

const StockNav = props => {
    return (
        <div className="stocktop">
            <NavLink to="/stock/purchase" className="stockItem"><i className="flaticon-cart" /><span>Закупка</span></NavLink>
            <NavLink to="/stock/replanish" className="stockItem"><i className="flaticon-file-1" /><span>Приход</span></NavLink>
            <NavLink to="/stock/shipment" className="stockItem"><i className="flaticon-transport" /><span>Отгрузка</span></NavLink>
            <NavLink to="/stock/transfer" className="stockItem"><i className="flaticon-route" /><span>Перемещение</span></NavLink>
            <NavLink to="/stock/return" className="stockItem"><i className="flaticon-logout" /><span>Возврат</span></NavLink>
            <NavLink to="/stock/consumption" className="stockItem"><i className="flaticon-lock" /><span>Списание</span></NavLink>
            <NavLink to="/stock/stocktake" className="stockItem"><i className="flaticon-interface-9" /><span>Инвентаризация</span></NavLink>
            {/*<NavLink to="/stock/workorder" className="stockItem"><i className="flaticon-tool" /><span>Заказ на производство</span></NavLink>*/}
            {/*<NavLink to="/stock/pricebook" className="stockItem"><i className="flaticon-list-3" /><span>Прайс-листы</span></NavLink>
            <NavLink to="/stock/stocklist" className="stockItem"><i className="flaticon-open-box" /><span>Склады</span></NavLink>*/}
        </div>
    )
}

export default withRouter(StockNav)