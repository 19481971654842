import {entity} from "../../components/ListComponentEntity";
import {NavLink} from "react-router-dom";
import React from "react";
import {NewList} from "../../components/NewList/NewList";

export const ListOrderNew = (props) => {
    return (
        <NewList
            { ...props }
            entity={'order'}
            entity_type={14}
            requiredFields={'id,name,status_id,cost,payment,contact_id,account_id'}
            requiredExpand={'contact,account,currency,status'}
            deleteToShowColumn={'id,name,status_id,cost,contact_id,account_id,currency_id'}
            additionalFieldsExists={true}
            isFilterExists={true}
            filterFields={entity.order.fArr}
            singleSearch={entity.order.fArr[0]}
            headingColumns={['Заказ/сумма', 'Клиент', 'Статус']}
            analytics={'/order/report/'}
            RenderColumn={(item) => (
                <>
                    <td>
                        <NavLink to={`/order/${item.id}`}  className={'nowrap-list-column'}>{item.name}</NavLink>
                        <div>К оплате: {item.cost}{item.currency && item.currency.text_symbol}</div>
                    </td>
                    <td>
                        {item.contact && <div className={'nowrap-list-column'}>Клиент: <NavLink to={`/contact/${item.contact.id}`}>{item.contact.name}</NavLink></div>}
                        {item.account && <div className={'nowrap-list-column'}>Клиент: <NavLink to={`/account/${item.account.id}`}>{item.account.name}</NavLink></div>}
                    </td>
                    <td>
                        {item.status && <span className="label-st" style={{ backgroundColor: 'rgb(255, 193, 100)' }}>{item.status.value}</span>}
                    </td>
                </>
            )}
            createButtonRender={(setModalType) => <>
                <button onClick={() => setModalType('order')} className="open-modal-button btni">Новый заказ</button>
                <button onClick={() => props.history.push(`/order-cash-window`)} className="open-modal-button btni ml5">Кассовое окно</button>

            </>}
            rightNavbarConfig={{
                export: true,
                additionalFields: '/fieldsettings/14',
                columns: true
            }}
            massDoConfig={{ deleteAll: `/ajax/order/delete-all` }}
            contextNav={{ edit: true, task: true, print: true, delete: true }}
            sort={entity.order.fArr.filter(el => el.sort)}
        />
    )
}