import React, {Component} from 'react';
import Input from '../components/UI/Input';
import DateTime from '../components/UI/DateTime';
import Myselect from '../components/UI/Select';
import MultiSelect from '../components/UI/MultiSelect';
import Textarea from '../components/UI/Textarea';
import {GlobalState} from '../Layout/Layout';


//Текст, число, список, дата, мультисписок, ссылка

const Fields = [
    {
        id: "1",
        label: "Название",
        fieldType: "input", //текст, select, date, datetime, multiselect, number, процент, ссылка, почта, текстовая область
        options: [], // для селекта и мультиселекта
        validation: {} // обьект с парамметрами для валидации
    }
];

function errorMessage(type) {
    switch(type){
        case "text": return "Заполните поле";
        case "url": return "Введите корректный URL";
        case "email": return "Введите корректный Email";
        case "number": return "Значение должно быть числом";
        default: return "Заполните поле";
    }
}


export class AdditionalFieldsChanged extends Component {
    setType(type, arr, index, role){
        if(arr.categories){
            if(!arr.categories.includes(this.props.formControl.category.value)){
                return false
            }
        }
        switch(type){
            case "input":
                return arr.visible[role] === "3" ? null : <Input
                    onChange={e => this.setField(e, index)}
                    label={arr.label}
                    type={arr.type}
                    value={arr.value}
                    shouldValidate={true}
                    className={`fgroup ${arr.width}`}
                    valid={!this.props.errors['f_' + arr.id]}
                    errorMessage={this.props.errors['f_' + arr.id]}
                    tooltip={arr.description}
                    disabled={arr.visible[role] === "2"}
                />;
            case "select":
                return arr.visible[role] === "3" ? null :
                    <Myselect
                        empty
                        tooltip={arr.description}
                        onChange={e => this.setField(e, index)}
                        label={arr.label}
                        options={arr.arr}
                        value={arr.value}
                        className={`fgroup ${arr.width}`}
                        disabled={arr.visible[role] === "2"}
                        valid={!this.props.errors['f_' + arr.id]}
                        errorMessage={this.props.errors['f_' + arr.id]}
                        shouldValidate={true}
                    />;
            case "date": return arr.visible[role] === "3" ? null : <DateTime
                tooltip={arr.description}
                label={arr.label}
                className={`fgroup ${arr.width}`}
                event={index}
                dateFormat="dd-MMMM-yyyy"
                value={arr.value}
                valid={!this.props.errors['f_' + arr.id]}
                errorMessage={this.props.errors['f_' + arr.id]}
                isClearable={true}
                disabled={arr.visible[role] === "2"}
                handleChange={this.handleChange}
            />;
            case "dateTime": return arr.visible[role] === "3" ? null : <DateTime
                tooltip={arr.description}
                label={arr.label}
                className={`fgroup ${arr.width}`}
                event={index}
                value={arr.value}
                isClearable={true}
                handleChange={this.handleChangeDate}
                showTimeSelect
                timeIntervals="15"
                dateFormat="dd-MMMM-yyyy HH:mm"
                timeFormat="HH:mm"
                valid={!this.props.errors['f_' + arr.id]}
                errorMessage={this.props.errors['f_' + arr.id]}
                disabled={arr.visible[role] === "2"}
            />;
            case "textarea":
                return arr.visible[role] === "3" ? null : <Textarea
                    tooltip={arr.description}
                    onChange={e => this.setField(e, index)}
                    label={arr.label}
                    type={arr.inputType}
                    value={arr.value}
                    className={`fgroup ${arr.width}`}
                    valid={!this.props.errors['f_' + arr.id]}
                    errorMessage={this.props.errors['f_' + arr.id]}
                    disabled={arr.visible[role] === "2"}
                    shouldValidate={true} />;
            case "multiselect":
                return arr.visible[role] === "3" ? null : <MultiSelect
                    tooltip={arr.description}
                    label={arr.label}
                    className={`fgroup ${arr.width}`}
                    custom={arr.arr}
                    options={arr.value}
                    labelType="label"
                    updateData={(arr) => this.updateData(arr, index)}
                    shouldValidate={true}
                    disabled={arr.visible[role] === "2"}
                    valid={!this.props.errors['f_' + arr.id]}
                    errorMessage={this.props.errors['f_' + arr.id]}
                />;
            default: return 'Null'
        }
    }
    formatDate = (date) => {
        if(date !== null) {
            let dd = date.getDate();
            if (dd < 10) dd = '0' + dd;
            let mm = date.getMonth() + 1;
            if (mm < 10) mm = '0' + mm;
            let yy = date.getFullYear() % 10000;
            if (yy < 10) yy = '0' + yy;
            return yy + '-' + mm + '-' + dd;
        }
    }
    setField(e, index){
        const items = this.props.items;
        items[index].value = e.target.value;
        this.props.updateAdditionalFields(items)
    }
    handleChange = (date, type) => {
        const items = this.props.items;
        items[type].value = this.formatDate(date) || '';
        this.props.updateAdditionalFields(items)
    };
    handleChangeDate = (date, type) => {
        const items = this.props.items;
        items[type].value = (date) ? date : '';
        this.props.updateAdditionalFields(items)
    };
    updateData = (arr, index) => {

        let formControl = this.props.items;
        formControl[index].value = arr;
        this.props.updateAdditionalFields(formControl)
        // let formControl = this.props.items;
        // if(type === "add") {
        //     formControl[index].options[id] = (formControl[index].custom[id]);
        //     delete formControl[index].custom[id];
        // }else {
        //     formControl[index].custom[id] = formControl[index].options[id];
        //     delete formControl[index].options[id];
        // }
        // this.props.updateAdditionalFields(formControl)
    }
    render(){
        return <div>
            {
                this.props.items.map((item, index) =>
                    <GlobalState.Consumer>
                        {context => (
                            this.setType(item.postType, item, index, context.state.userRole)
                        )}
                    </GlobalState.Consumer>
                )
            }
        </div>
    }
}


class AdditionalFields extends Component {
    setType(type, arr, index, role){
        if(arr.categories){
            if(!arr.categories.includes(this.props.formControl.category.value)){
                return false
            }
        }
        switch(type){
            case "input": 
                return arr.visible[role] === "3" ? null : <Input
                    onChange={e => this.setField(e, index)}
                    label={arr.label}
                    type={arr.type}
                    value={arr.value}
                    className={`fgroup ${arr.width}`}
                    valid={arr.valid}
                    shouldValidate={true}
                    errorMessage={errorMessage(arr.type)}
                    tooltip={arr.description}
                    disabled={arr.visible[role] === "2"}
                />;
            case "select": 
                return arr.visible[role] === "3" ? null :
                    <Myselect
                    empty
                    tooltip={arr.description}
                    onChange={e => this.setField(e, index)}
                    label={arr.label}
                    options={arr.arr}
                    value={arr.value}
                    className={`fgroup ${arr.width}`}
                    valid={arr.valid}
                    disabled={arr.visible[role] === "2"}
                    errorMessage={`Выберите как минимум 1 значение`}
                    shouldValidate={true}
                    />;
            case "date": return arr.visible[role] === "3" ? null : <DateTime
                tooltip={arr.description}
                label={arr.label} 
                className={`fgroup ${arr.width}`} 
                event={index}
                dateFormat="dd-MMMM-yyyy"
                value={arr.value}
                valid={arr.valid}
                errorMessage={`Поле не может быть пустым`}
                isClearable={true}
                disabled={arr.visible[role] === "2"}
                handleChange={this.handleChange}
            />;
            case "dateTime": return arr.visible[role] === "3" ? null : <DateTime
                tooltip={arr.description}
                label={arr.label} 
                className={`fgroup ${arr.width}`} 
                event={index}
                value={arr.value}
                valid={arr.valid}
                isClearable={true}
                handleChange={this.handleChangeDate}
                showTimeSelect
                timeIntervals="15"
                dateFormat="dd-MMMM-yyyy HH:mm"
                errorMessage={`Поле не может быть пустым`}
                timeFormat="HH:mm"
                disabled={arr.visible[role] === "2"}
            />;
            case "textarea": 
                return arr.visible[role] === "3" ? null : <Textarea
                tooltip={arr.description}
                onChange={e => this.setField(e, index)} 
                label={arr.label} 
                type={arr.inputType} 
                value={arr.value} 
                className={`fgroup ${arr.width}`} 
                valid={arr.valid}
                errorMessage={`Поле не может быть пустым`}
                disabled={arr.visible[role] === "2"}
                shouldValidate={true} />;
            case "multiselect":
                return arr.visible[role] === "3" ? null : <MultiSelect
                tooltip={arr.description}
                label={arr.label}
                className={`fgroup ${arr.width}`}
                custom={arr.arr}
                options={arr.value}
                labelType="label"
                updateData={(arr) => this.updateData(arr, index)}
                valid={arr.valid}
                shouldValidate={true}
                disabled={arr.visible[role] === "2"}
                errorMessage={`Выберите как минимум 1 значение`}
            />;
        }
    }
    formatDate = (date) => {
        if(date !== null) {
            let dd = date.getDate();
            if (dd < 10) dd = '0' + dd;
            let mm = date.getMonth() + 1;
            if (mm < 10) mm = '0' + mm;
            let yy = date.getFullYear() % 10000;
            if (yy < 10) yy = '0' + yy;
            return yy + '-' + mm + '-' + dd;
        }
    }
    setField(e, index){
        const items = this.props.items;
        items[index].value = e.target.value;
        this.props.updateAdditionalFields(items)
    }
    handleChange = (date, type) => {
        const items = this.props.items;
        items[type].value = this.formatDate(date) || '';
        this.props.updateAdditionalFields(items)
    };
    handleChangeDate = (date, type) => {
        const items = this.props.items;
        items[type].value = (date) ? date : '';
        this.props.updateAdditionalFields(items)
    };
    updateData = (arr, index) => {

        let formControl = this.props.items;
        formControl[index].value = arr;
        this.props.updateAdditionalFields(formControl)
        // let formControl = this.props.items;
        // if(type === "add") {
        //     formControl[index].options[id] = (formControl[index].custom[id]);
        //     delete formControl[index].custom[id];
        // }else {
        //     formControl[index].custom[id] = formControl[index].options[id];
        //     delete formControl[index].options[id];
        // }
        // this.props.updateAdditionalFields(formControl)
    }
    render(){
    return <div>
        {
            this.props.items.map((item, index) =>
                <GlobalState.Consumer>
                    {context => (
                        this.setType(item.postType, item, index, context.state.userRole)
                    )}
                </GlobalState.Consumer>
            )
        }
    </div>
}}

export default AdditionalFields;