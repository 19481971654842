import React, {useEffect, useState} from "react";
import myaxios from "../../components/Axios";
import {deleteTaskService, updateTaskService} from "../../services/task/taskService";
import Notif from "../../components/Notif";
import {TaskModal} from "./TaskModal";
import {ListFilter} from "../../components/NewList/ListFilter";
import {entity} from "../../components/ListComponentEntity";
import {convertFilter} from "../../components/NewList/components/convertFilter";
import localstorageSettings from "../../components/localstorageSettings";
import {CustomKanban} from "../../components/CustomKanban/CustomKanban";
import Input from "../../components/UI/Input";

const statuses = {
    "7": {label: "Backlog", background: "#bbb", color: "#2b2b2b", border: "#bbb"},
    "3": {label: "Не начата", background: "rgb(214, 214, 214)", color: "#2b2b2b", border: "rgb(214, 214, 214)"},
    "6": {label: "Уточненние", background: "#fff", color: "#ffc164", border: "#ffc164"},
    "4": {label: "В работе", background: "#fff", color: "#EF6F00", border: "#EF6F00"},
    "2": {label: "Приостановлена", background: "rgb(214, 214, 214)", color: "#2b2b2b", border: "rgb(214, 214, 214)"},
    "5": {label: "На проверке", background: "#fff", color: "#ffc164", border: "#ffc164"},
    "1": {label: "Завершена", background: "#62a091", color: "#fff", border: "#62a091"},
}

const statusesNew = [
    // {id: 7, title: "Backlog", background: "#bbb", color: "#2b2b2b", border: "#bbb"},
    {id: 3, title: "Не начата", background: "rgb(214, 214, 214)", color: "#2b2b2b", border: "rgb(214, 214, 214)"},
    {id: 6, title: "Уточненние", background: "#fff", color: "#ffc164", border: "#ffc164"},
    {id: 4, title: "В работе", background: "#fff", color: "#EF6F00", border: "#EF6F00"},
    {id: 2, title: "Приостановлена", background: "rgb(214, 214, 214)", color: "#2b2b2b", border: "rgb(214, 214, 214)"},
    {id: 5, title: "На проверке", background: "#fff", color: "#ffc164", border: "#ffc164"},
    // {id: 1, title: "Завершена", background: "#62a091", color: "#fff", border: "#62a091"},
]

const priorities = (type) => {
    switch (type) {
        case "1": return {label: "Низкий", color: '#2b2b2b'};
        case "2": return {label: "Средний", color: '#62a091'};
        case "3": return {label: "Высокий", color: '#EF6F00'};
        case "4": return {label: "Критический", color: '#c2263f'};
        default: return {label: "Без приоритета", color: '#2b2b2b'}
    }
}

const formatDate = (dt) => {
    if(dt){
        let date = new Date(dt)
        let dd = date.getDate();
        if (dd < 10) dd = '0' + dd;
        let mm = date.getMonth() + 1;
        if (mm < 10) mm = '0' + mm;
        let yy = date.getFullYear() % 10000;
        if (yy < 10) yy = '0' + yy;
        return dd + '-' + mm;
    }
}

const KanbanItem = ({ content, onClick, allStatuses }) => {
    const {id, subject, description, status_id = "7", priority, owner, subtask_json = "[]", end_date, is_overdue} = content
    const status = allStatuses.find(el => String(el.id) === String(status_id))
    let subTaskLength = (JSON.parse(subtask_json) || []).filter(item => item);
    let subTaskSuccess = (JSON.parse(subtask_json) || []).filter(item => item && item.status);
    const isOverdue = is_overdue === 1

    return (
        <div className="kanbanItem" onClick={onClick}>
            <div className="kanbanItemStatuses">
                <span className="kanbanItemStatus" style={{
                    color: status && status.color,
                    background: 'white',
                    border: `1px solid ${status && status.color}` }}>
                    {status && status.value}
                </span>
                <span className="kanbanItemPriority" style={{ color: priorities(priority).color }}>
                    {priorities(priority).label}
                </span>
            </div>
            <div className="kanbanItemDescription"
                 // onClick={() => history.push(`/task/view/${id}`)}
            >
                <h3 className={isOverdue && 'is_overdue_task'}>{subject}</h3>
                <p>{description && description.replace(/<\/?[^>]+>/g,'').substring(0, 60)}</p>
            </div>
            <div className="kanbanItemFooter">
                <div className="kanbanItemOwners">
                    <div data-tooltip={owner && owner.name} className="kanbanItemOwner">{owner && owner.name.substring(0, 2)}</div>
                </div>
                <div className="kanbanItemFooterRight">
                    <div className="kanbanItemSubTask">
                        <i className="flaticon-list-2" />
                        <span>{subTaskSuccess.length} из {subTaskLength.length}</span>
                    </div>
                    <div className="kanbanItemDate">
                        <i className={`flaticon-clock-1 ${isOverdue && 'is_overdue_task'}`} />
                        <span className={isOverdue && 'is_overdue_task'}>{end_date ? formatDate(end_date) : "Не задано"}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

const KanbanHeader = ({ id, value, color, taskTypePeriod, allStatuses }) => {
    const status = allStatuses.find(el => String(el.id) === String(id))
    return (
        <div className="react-kanban-column-header">
            <span style={{ borderBottom: `3px solid ${taskTypePeriod ? color : status.color}`, display: "block", paddingBottom: "5px", fontSize: "14px" }}>{value}</span>
            {/*<div className="sbform">
                <Input />
            </div>*/}
        </div>
    )
}


let page = 1
let metaData = 0
function TaskKanbanConfig ({ isFilterExists = true, entity_type = 5, userId = 1, requiredExpand = 'owner', requiredFields, history  }) {
    //const [page, setPage] = useState(1)
    //const [metaData, setMetaData] = useState(0)

    const [headerColumns, setHeaderColumns] = useState([])
    const [allStatuses, setAllStatuses] = useState([])
    const [items, setItems] = useState([])
    const [loading, setLoading] = useState(true)

    const [dragElement, setDragElement] = useState(null)
    const [activeCol, setActiveCol] = useState(null)

    const [filters, setFilters] = useState([])
    const [columns, setColumns] = useState([])
    const [sortActive, setSortActive] = useState('-created_at')
    const [filterId, setFilterId] = useState('')

    const filterLink = async () => {
        const filterData = await myaxios.get(`/ajax/filter?filter[entity_type]=${entity_type}&[user_id]=${userId}`)
        let filterLink = '';

        if(isFilterExists && filterData.data.items && filterData.data.items[0]) {
            setFilters(filterData.data.items[0].filterAttributes || [])
            if(filterData.data.items[0]){
                filterLink = (convertFilter(filterData.data.items[0].filterAttributes || []))
                setFilterId(filterData.data.items[0].id)
                setColumns(filterData.data.items[0].columns || [])
                setSortActive(filterData.data.items[0].order || '-created_at')
            }
        }

        const sortOrder = (filterData.data && filterData.data.items && filterData.data.items[0] && filterData.data.items[0].order) || '-created_at'

        return `/ajax/task/kanban?sort=${sortOrder}&expand=${requiredExpand}${filterLink}&per-page=15`
    }

    const getData = async () => {
        const link = await filterLink()
        const { status, data } = await myaxios.get(link + `&page=${page}`)
        if(status === 200 && data) {
            setItems(data.items)
            //setMetaData(prevState => data._meta.pageCount)
            metaData = data._meta.pageCount
        }
        setLoading(false)
    }

    const fetchMoreData = async () => {
        console.log('page', page, metaData)

        if(page < metaData) {
            setLoading(true)
            const newPage = page + 1;
            //setPage(prevState => (newPage))
            page = newPage
            const link = await filterLink()
            const { data } = await myaxios.get(`${link}&page=${newPage}`)
            setItems(prevState => ([
                ...prevState,
                ...data.items
            ]))
            setLoading(false)
        }
    };

    const isShowBacklog = localStorage.getItem('isShowBacklog') || false
    const isShowFinished = localStorage.getItem('isShowFinished') || false

    const getStatuses = async () => {
        const { status, data } = await myaxios.get(`/ajax/settings/lookup?type=tsk_status`)
        if(status === 200) {
            const statusesData = data.items;
            if(!isShowBacklog) statusesData.splice(data.items.findIndex(el => +el.id === 7), 1)
            if(!isShowFinished) statusesData.splice(data.items.findIndex(el => +el.id === 1), 1)
            setAllStatuses(statusesData)
        }
    }

    useEffect(() => {
        page = 1
        getStatuses().then()
        getData().then()
    }, [localStorage.getItem('isShowBacklog'), localStorage.getItem('isShowFinished')])

    const deleteElement = async (elId) => {
        if(!elId) return;
        if(!window.confirm('Удалить задачу?')) return;

        try {
            const { status, data } = await deleteTaskService({ id: elId })
            if((status === 200 || status === 204) && data.id) {
                setItems(items.filter(el => el.id !== elId))
                Notif('delete', 'Запись успешно удалена')
            }
        } catch (e) { console.error(e) }
        setLoading(false)
    }

    const drugEnd = async (item) => {
        if(activeCol === 'delete') {
            await deleteElement(dragElement)
            setDragElement(null)
            setActiveCol(null)
            return;
        }

        if(activeCol === "edit") {
            setOpenTaskId(dragElement)
            setDragElement(null)
            setActiveCol(null)
            return;
        }

        setLoading(true)
        const { status, data } = await updateTaskService({ id: item.id, data: {
                subject: item.subject,
                status_id: activeCol
            }})

        if(status === 200 && data.id) {
            const newItems = JSON.parse(JSON.stringify(items))
            const i = newItems.findIndex(el => el.id === item.id)
            newItems[i].status_id = activeCol
            setItems(newItems)
            setActiveCol(null)
            setDragElement(null)

            Notif('save', 'Задача успешно обновлена')
        }
        setLoading(false)
    }

    const saveFilter = async (params = [], clearId, editedColumns, editedSort) => {
        setLoading(true)

        const postData = {
            name: 'task',
            entity_type: entity_type,
            user_id: userId || 1,
            filter_attributes: clearId ? filters.filter(el => el.id != clearId) : [...filters, ...params],
            columns: editedColumns || columns,
            order: editedSort || sortActive
        }

        const { status, data } = filterId ?
            await myaxios.put(`/ajax/filter/${filterId}`, postData) : await myaxios.post(`/ajax/filter`, postData)

        if(status === 200 || status === 201) {
            Notif('save', 'Фильтр применен')
            await getData()
        }

        setLoading(false)
    };

    const [isTaskOpen, setIsTaskOpen] = useState(false)
    const [openTaskId, setOpenTaskId] = useState(false)

    return <div className={'page'} style={{ display: 'flex', flexDirection: 'column', height: `calc(100vh - 75px)`, position: 'relative', paddingBottom: 0 }}>
        <ListFilter
            saveFilter={saveFilter}
            filters={filters}
            rightNavbarConfig={{  }}
            setIsColumnsOpen={() => {}}
            // singleSearch={entity.task.fArr[0]}
            updateData={() => {}}
            entity={'task'}
            sort={entity.task.fArr.filter(el => el.sort)}
            sortActive={sortActive}
            filterFields={entity.task.fArr}
            LeftRender={() => (
                <>
                    {/*<a onClick={() => {history.push('/task/calendar'); localstorageSettings("post", `${'task'}View`, "calendar")}}className="btns" ><i className="flaticon-calendar-3" /></a>*/}
                    <a onClick={() => {history.push('/task/list'); localstorageSettings("post", `${'task'}View`, "list")}} className="btns"><i className="flaticon-list-2" /></a>
                    <a onClick={() => setIsTaskOpen(true)} className="open-modal-button btni">Создать задачу</a>
                </>
            )}
            dropdownRender={() => (
                <>
                    <li><a onClick={() => {
                        !isShowFinished ? localStorage.setItem('isShowFinished', 'true') : localStorage.removeItem('isShowFinished')
                        getStatuses().then()
                    }}><i className={'flaticon-list-2'} />{isShowFinished ? ' Скрыть' : ' Показать'} Завершенные</a></li>
                    <li><a onClick={() => {
                        !isShowBacklog ? localStorage.setItem('isShowBacklog', 'true') : localStorage.removeItem('isShowBacklog')
                        getStatuses().then()
                    }}><i className={'flaticon-layers'} />{isShowBacklog ? ' Скрыть' : ' Показать'}  Backlog</a></li>
                    <li><a onClick={() => history.push('/settings/task')}><i className="flaticon-cogwheel-1" /> <b>Настроить статус задач</b></a></li>
                </>
            )}

        />

        <CustomKanban
            {...{ dragElement, setDragElement, activeCol, setActiveCol, columns: headerColumns, items, drugEnd, loading }}
            columns={allStatuses}
            header={item => <KanbanHeader {...item} allStatuses={allStatuses} />}
            kanbanItem={item => <KanbanItem content={item} onClick={() => setOpenTaskId(item.id)} allStatuses={allStatuses} />}
            itemKey={'status_id'}
            filters
            rightNavbarConfig
            singleSearch
            entity
            sort
            sortActive
            filterFields
            LeftRender
            actionButtons={[
                {color: 'linear-gradient(45deg, #d37873 0%,#c96e6e 47%,#c16a6a 100%)', value: 'delete', label: 'Удалить'},
                {color: 'linear-gradient(45deg, #929292 0%,#656565 47%,#948a8a 100%)', value: 'edit', label: 'Редактировать'},
                {color: 'linear-gradient(45deg,#80aeba 2%,#699499 46%,#669295)', value: 1, label: 'Завершить'},
            ]}
            loadMore={fetchMoreData}
            page={page} metaData={metaData}
        />

        {(openTaskId || isTaskOpen) && <TaskModal
            isOpen={openTaskId || isTaskOpen}
            close={() => {
                setIsTaskOpen(false)
                setOpenTaskId('')
            }}
            task={(type, data) => {
                if(type === 'edit') {
                    const newItems = JSON.parse(JSON.stringify(items))
                    newItems[items.findIndex(el => el.id === data.id)] = data;
                    setItems(newItems)
                } else if(type === 'add') {
                    setItems([ ...items, data ])
                } else if(type === 'delete') {
                    setItems(items.filter(el => el.id !== data))
                }
            }}
            id={openTaskId}
        />
        }

    </div>
}

export default TaskKanbanConfig