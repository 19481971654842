import React, {useEffect, useState} from "react";
import {ModalConfig} from "../../Modal/ModalConfig";

export const ModalListContainer = ({ type, data, history, close, entity_type, entity, onUpdateResult }) => {
    const [ChooseElement, setChooseElement] = useState(null)
    const [open, setOpen] = useState(false)

    useEffect(() => {
        if(!type) return;
        const chData = ModalConfig.find(el => el.name === type)
        setChooseElement(chData)
        if(chData) setTimeout(() => setOpen(true), 100)
    }, [type])

    if(!ChooseElement) {
        return <></>
    }

    return (
        <>
            <ChooseElement.Component
                save={onUpdateResult}
                isOpen={open}
                title={ChooseElement.title}
                {...{
                    data, history, entity_type, entity
                }}
                close={() => {
                    setOpen(false)
                    setTimeout(() => { close(); setChooseElement(null) }, 100)

                }}
            />
        </>
    )
}