import React, {useEffect, useState} from "react";
import Modal from "../../Modal/Modal";
import {outItems, Item, steps} from "./Config"
import Bgdrop from "../../../Modal/Bgdrop";
// @ts-ignore
import {withRouter} from "react-router-dom";
// @ts-ignore
import {CSSTransition} from "react-transition-group";
import Notif from "../../Notif";
import {putWizard} from "../needFunctions";
import Joyride from "react-joyride";
import {createEvent} from "../../../services/other/events";

type Props = {
    isOpen: boolean,
    close: () => void,
    userId: number | null,
    allSteps: any,
    setSteps: (key:string, data:boolean | string | number, type?:boolean) => void
}

const Education: React.FC<Props> = ({ isOpen= true, close, userId= 1, allSteps, setSteps}) => {
    const [items, setItems] = useState<Array<Item>>([])
    const [open, setOpen] = useState<boolean>(false)
    const [userID, setUserID] = useState<number | null>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const [chosenVideo, setChosenVideo] = useState<Item | null>(null)
    const [index, setIndex] = useState<number>(0)
    const [startEducate, setStateEducate] = useState<boolean>(false)

    useEffect(() => {
        if(isOpen && userId && allSteps){
            setOpen(true)
            setLoading(false)
            if(!allSteps.isStartEducate) setTimeout(() => setStateEducate(true), 500)
            setUserID(userID)


            let arr = JSON.parse(JSON.stringify(outItems));
            arr = arr.map((el: Item) => (({
                ...el, completed: (allSteps.adminEducate[el.key]) || false
            })))
            setItems([...arr])
            //console.log("arr", arr)

        }
    },[isOpen])

    return (
        <div className="educate-modal">
            <Modal loading={loading} title="Обучение" isOpen={open && !chosenVideo} close={() => { close(); setOpen(false) }} classname="scroll-important">
                <div className="example-preview">
                    <div className="timeline timeline-5">
                        <div className="timeline-items">
                            {
                                items.map((item:Item, index:number) => {
                                    return item.type === "ordinary" ? (
                                        <div
                                            id={item.key}
                                            onClick={() => {
                                                if(!item.video) Notif("Error", "Видео будет доступно через несколько дней")
                                                else if(item.completed || items[index - 1].completed) setChosenVideo(item)
                                                else Notif("Error", "Чтобы открыть текущий пункт, выполните предыдущие задания.")
                                            }}
                                            style={{ opacity: (items[index - 1] && items[index - 1].completed) || item.completed ? 1 : 0.5 }}
                                            className={`timeline-item ${
                                            item.completed ? "timeline-item-completed" :
                                                items[index - 1].completed ? "timeline-item-work" : "timeline-item-disabled"
                                        }`}>
                                            <div className="timeline-media bg-light-primary">
                                            <span className="svg-icon svg-icon-primary svg-icon-md">
                                                <i className={item.icon} />
                                            </span>
                                            </div>
                                            <div className="timeline-desc timeline-desc-light-primary">
                                                <span className="font-weight-bolder text-primary">{item.name}</span>
                                                <p className="font-weight-normal text-dark-50 pb-2">{item.completed ? "Задание выполнено" : "Домашнее задание не выполнено"}</p>
                                            </div>
                                        </div>
                                        ) : (
                                        <div
                                             style={{ opacity: (items[index - 1] && items[index - 1].completed) || item.completed ? 1 : 0.5 }}
                                             className="timeline-item-complete"
                                             id={item.key}
                                        >
                                            <div>
                                                <i className={item.icon} />
                                            </div>
                                            <div>
                                                <span>{item.name}</span>
                                                <em><b>Награда: </b>{item.desc}</em>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </Modal>

            <CSSTransition in={!!chosenVideo} timeout={200} classNames="my-node" unmountOnExit>
                <div>
                    <div className="center-modal" style={{ background: "none", boxShadow: "none" }}>
                        <div className="center-modal-body">
                            <iframe width="100%" height="100%" src={`https://www.youtube.com/embed/${chosenVideo && chosenVideo.video}`} frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                            <div style={{ padding: "10px", background: "#fff" }}>
                                {chosenVideo && chosenVideo.homeWork && <div className={"video-desc-container"}>
                                    <i className="flaticon-laptop" />
                                    <p>Д/З: {chosenVideo.homeWork}</p>
                                    <button onClick={close}>Выполнить задание</button>
                                </div>}
                                {chosenVideo && chosenVideo.docs && <div className={"video-desc-container"}>
                                    <i style={{color: "#669295"}} className="flaticon-questions-circular-button" />
                                    <a target="_blank" href={chosenVideo.docs} style={{color: "#669295", textDecoration: "underline"}}>Ссылка на документацию</a>
                                </div>}
                            </div>
                        </div>
                    </div>
                    <Bgdrop close={() => setChosenVideo(null)} />
                </div>
            </CSSTransition>


            {allSteps && !allSteps.isStartEducate && <Joyride
                // @ts-ignore
                steps={steps[index]}
                //stepIndex={index}
                continuous={false}
                run={startEducate}
                showProgress={true}
                disableCloseOnEsc={true}
                showSkipButton={false}
                scrollToFirstStep={true}
                callback={(data) => {
                    if(data.status === "finished" && index === 0){
                        createEvent({
                            user_id: userId + "",
                            user_name: "",
                            event_type: "finishedZeroStepEducation"
                        }).then()
                        setChosenVideo(items[1])
                        setStateEducate(false)
                        setTimeout(() => setIndex(1),300)
                        setTimeout(() => setStateEducate(true),600)
                    } else if(data.status === "finished" && index === 1){
                        setChosenVideo(null)
                        setStateEducate(false)
                        setTimeout(() => setIndex(2),300)
                        setTimeout(() => setStateEducate(true),600)
                    } else if(index === 2){
                        if(data.index === 0){
                            const newItems = JSON.parse(JSON.stringify(items))
                            newItems[1].completed = true
                            setItems(newItems)
                        } else if(data.index === 2){
                            const newItems = JSON.parse(JSON.stringify(items))
                            for (let i = 2; i <= 11; i++){ newItems[i].completed = true }
                            setItems(newItems)

                            if(data.status === "finished" && data.lifecycle === "complete"){
                                const newItems2 = JSON.parse(JSON.stringify(items))
                                for (let i = 1; i <= 11; i++){ newItems2[i].completed = false }
                                setTimeout(() => setItems([...newItems2]),500)
                                setSteps("isStartEducate", true)
                                setTimeout(() => setSteps("endStudy", true), 500)
                            }
                        }
                    }
                }}
                styles={{
                    options: {
                        primaryColor: '#669295'
                    }
                }}
                locale={{ back: 'Назад', close: 'вперед', last: 'Назад', next: 'Вперед', skip: 'Пропустить' }}
            />}
        </div>
    )
}

export default withRouter(Education)