import React, {useEffect, useState} from "react";
import myaxios from "../../Axios";
import {ModalLoader} from "../../UI/Loader";
import {searchEntity, searchEntityByName, searchEntityId} from "../BpHelpers";
import Modal from "../../Modal/Modal";
import Input from "../../UI/Input";
import Myselect from "../../UI/Select";
import { allEntities } from '../BpHelpers'
import {validationData} from "../../UI/formValid";

function searchType(id){
    switch(id){
        case "record_update": return "Обновление записи";
        case "record_create": return "Создание записи";
        case "stage_changed": return "Изменение этапа";
        case "owner_changed": return "Изменение ответственного";
        case "inbox_mail": return "Входящее письмо";
        case "incoming_call": return "Входящий звонок";
        case "invocable": return "Вложенный процесс";
        case "cron": return "Крон";
        case "rfm_status_changed": return "Изменение RFM статуса";
        default: return "";
    }
}

export const BpListNew = ({ history }) => {
    const [items, setItems] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(true)

    const getData = async () => {
        try {
            const { status, data } = await myaxios.get(`/ajax/processbuilder?per-page=100`)
            if(status === 200) {
                setItems(data.items)
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        getData().then()
    }, [])

    const deleteItem = async (id) => {
        if(!window.confirm('Удалить процесс')) return false;

        setLoading(true)
        try {
            const { status, data } = await myaxios.delete(`/ajax/processbuilder/${id}`)
            if(status === 200 || status === 204) {
                await getData()
            }
        } catch (e) {}
        setLoading(false)
    }

    return (
        <div className={'page'}>
            {loading && <ModalLoader />}
            <div>
                <div className="newList-btn-group">
                    <div className="newList-btn-group-left">
                        <button onClick={() => setIsOpen(true)} className="open-modal-button btni">Добавить процесс</button>
                    </div>
                </div>
            </div>
            <div className="contact-page sp-table">
                <table>
                    <thead>
                        <tr className="fst-tr">
                            <td>ID</td>
                            <td>Название</td>
                            <td>Тип</td>
                            <td>Триггер</td>
                            <td>Дата добавления</td>
                            <td>Дата обновления</td>
                            <td />
                        </tr>
                    </thead>
                    <tbody>
                    {
                        (items || []).map(item => (
                            <tr className={'pull-up'} onDoubleClick={() => history.push(`/marketing/process/${item.id}`)}>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td>{searchEntityId(item.related_type)}</td>
                                <td>{searchType(item.activation_type)}</td>
                                <td>{item.created_at}</td>
                                <td>{item.updated_at}</td>
                                <td>
                                    <div className="toogle-bottom fl-r">
                                        <a className="is">
                                            <i className="flaticon-more" />
                                        </a>
                                        <ul className={'toogle-list'}>
                                            <li><a onClick={() => history.push(`/marketing/process/${item.id}`)} className={'table-del'}>
                                                <b>Редактировать</b>
                                            </a></li>
                                            <li><a onClick={() => deleteItem(item.id)} className={'table-del'}>
                                                <b>Удалить</b>
                                            </a></li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>
            <CreateProcessModal
                isOpen={isOpen}
                getData={getData}
                setIsOpen={setIsOpen}
            />
        </div>
    )
}

const staticData = [
    { value: 'cron', label: 'Крон' },
    { value: 'stage_changed', label: 'Изменение этапа' },
    { value: 'owner_changed', label: 'Изменение ответственного' },
    { value: 'rfm_status_changed', label: 'При смене RFM сегмента' }
]

const recordChange = [
    { value: 'record_update', label: 'Изменение записи' },
    { value: 'record_create', label: 'Создание записи' },
]

const entityData = {
    1: ['cron', 'stage_changed', 'owner_changed'],
    2: ['cron', 'stage_changed', 'owner_changed', 'rfm_status_changed'],
    3: ['cron', 'stage_changed', 'owner_changed', 'rfm_status_changed'],
    6: ['cron', 'stage_changed', 'owner_changed'],
    9: ['cron'],
    13: ['cron', 'stage_changed', 'owner_changed'],
    14: ['cron', 'stage_changed', 'owner_changed'],
    23: ['cron', 'stage_changed', 'owner_changed'],
    24: ['cron', 'stage_changed', 'owner_changed'],
}

const validationRules = {
    name: { required: true, maxLength: 255 },
    related_type: { required: true },
    type: { required: true },
}

const CreateProcessModal = ({ isOpen, getData, setIsOpen }) => {
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState({})
    const [salesFunnel, setSalesFunnel] = useState([])
    const [values, setValues] = useState({
        activation_type: "",
        name: "",
        related_type: "23",
        state: "active",
        type: "",
        sales_funnel_id: '',
        tch: true
    })

    const getLeadFunnels = async () => {
        setLoading(true)
        try {
            const { status, data } = await myaxios.get(`/ajax/sales-funnel`)
            if (status === 200) {
                setSalesFunnel(data.items)
                setValues({ ...values, sales_funnel_id: data.items[0].id })
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        if(isOpen) getLeadFunnels().then()
    }, [isOpen])

    const createProcess = async () => {
        const validErrors = validationData(values, {...validationRules, activation_type: { required: values.type !== 'invocable' } })
        if (Object.keys(validErrors).length > 0) {
            setErrors(validErrors)
            return false;
        }
        setErrors({})

        setLoading(true)
        const { activation_type, name, related_type, type, sales_funnel_id } = values;
        try {
            const { status, data } = await myaxios.post(`/ajax/processbuilder`, {
                activation_type, name, related_type, type, sales_funnel_id: +related_type === 23 && sales_funnel_id,
                description: '', state: 'active'
            })
            if(status === 201) {
                setIsOpen(false)
                getData()
            }
        } catch (e) {}
        setLoading(false)
    }

    return (
        <Modal title={'Добавить процесс'} isOpen={isOpen} close={() => setIsOpen(false)} tch={values.tch} showLeaveModal loading={loading} save={createProcess}>
            <div className="sbform">
                <Input
                    label={'Название'}
                    value={values.name}
                    onChange={e => setValues({...values, name: e.target.value, tch: false })}
                    errorMessage={errors.name} valid={!errors.name} shouldValidate
                />
                <Myselect
                    label={'Сущность'}
                    options={allEntities.map(item => ({ value: searchEntityByName(item), label: searchEntity(searchEntityByName(item)) }))}
                    value={values.related_type} empty
                    onChange={e => setValues({ ...values, related_type: e.target.value, type: '', activation_type: '', tch: false })}
                    errorMessage={errors.related_type} valid={!errors.related_type} shouldValidate
                />
                {+values.related_type === 23 && <Myselect
                    label={'Воронка'} lab={'name'} val={'id'}
                    options={salesFunnel || []}
                    value={values.sales_funnel_id}
                    onChange={e => setValues({ ...values, sales_funnel_id: e.target.value, tch: false })}
                />}
                <Myselect
                    label={'Тип'}
                    options={[
                        { value: 'event', label: 'Событие' },
                        { value: 'record_change', label: 'Изменение записи' },
                        { value: 'invocable', label: 'Вложенный процесс' }
                    ]} value={values.type} empty
                    onChange={e => setValues({ ...values, type: e.target.value, tch: false })}
                    errorMessage={errors.type} valid={!errors.type} shouldValidate
                />
                {values.related_type && values.type && values.type !== 'invocable' && <Myselect
                    label={'Триггер'}
                    options={
                        values.type !== 'record_change' ? staticData.filter((el) => entityData[values.related_type].includes(el.value)) : recordChange
                    }
                    value={values.activation_type} empty
                    onChange={e => setValues({ ...values, activation_type: e.target.value, tch: false })}
                    errorMessage={errors.activation_type} valid={!errors.activation_type} shouldValidate
                />}
            </div>
        </Modal>
    )
}