import React, {useState} from "react";
import Modal from "../../components/Modal/Modal";
import MainEditor from "../../components/UI/Editor";
import Input from "../../components/UI/Input";

const NewFeed = ({ open, close, save, title }) => {
    const [state, setMyState] = useState({
        title: "",
        content: "",
        img:null
    });
    const setState = (st, func) => {
        Object.keys(st).map((key) => { state[key] = st[key]});
        setMyState(prevState => { return {...prevState, ...state} });
        if(func) func();
    };

    return (
        <Modal isOpen={open} close={close} save={() => save(state.title, state.content)} title={"Добавить новость"}>
            <Input
                label="Название"
                value={state.title}
                onChange={e => setState({ title: e.target.value })}
                valid={state.title.length > 0}
                errorMessage={"Поле не может быть пустым"}
                shouldValidate={true}

            />
            <MainEditor
                label="Текст записи"
                value={state.content}
                onChange={e => setState({ content: e })}
                valid={state.content.replace(/<\/?[^>]+(>|$)/g, "").length > 10}
                errorMessage={"Текст записи должен содержать как минимум 10 символов"}
            />
        </Modal>
    )
}

export default NewFeed