import myaxios from "../../components/Axios";

type ExpenseProps = {
    account_id?: number | null,
    amount: number,
    balance_id: string | null,
    case_id?: null | number,
    category_id: null | number,
    contact_id?: null | number,
    currency_id: null | number,
    datetime: string,
    document_id?: null | number,
    lead_id?: null | number,
    name: string,
    opportunity_id?: null | number,
    order_id?: null | number,
    owner_id?: null | number,
    project_id?: null | number,
    purchase_id?: null | number,
    status: number
}

export const expensePrepareService = async () => myaxios.get<any>(`/ajax/expense/prepare`);
export const createExpenseService = async (data: ExpenseProps) => myaxios.post(`/ajax/expense`, data);
export const deleteExpenseService = async ({id}: { id: string }) => myaxios.delete(`/ajax/expense/${id}`)


// expense_category