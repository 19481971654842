import React, {useEffect, useState} from "react";
import Modal from "../../components/Modal/Modal";
import Myselect from "../../components/UI/Select";
import Input from "../../components/UI/Input";
import DateTime from "../../components/UI/DateTime";
import {NewAutoSelect} from "../../components/NewUi/NewAutoSelect";
import myaxios from "../../components/Axios";
import {validationData} from "../../components/UI/formValid";
import Notif from "../../components/Notif";
import Textarea from "../../components/UI/Textarea";
import {createEvent} from "../../services/other/events";

function formatDate(date){
    if(date !== null) {
        date = new Date(date)
        let dd = date.getDate();
        if (dd < 10) dd = '0' + dd;
        let mm = date.getMonth() + 1;
        if (mm < 10) mm = '0' + mm;
        let yy = date.getFullYear() % 10000;
        if (yy < 10) yy = '0' + yy;

        let hh = date.getHours();
        if (hh < 10) hh = '0' + hh;

        let min = date.getMinutes();
        if (min < 10) min = '0' + min;

        return yy + '-' + mm + '-' + dd + " " + hh + ":" + min;
    }
}

const validationConfig = {
    amount: {
        required: true,
        number: true,
        maxLength: 255
    },
    balance_id: { required: true },
    currency_id: { required: true },
    exchange_rate: { required: true, number: true, maxLength: 255 },
    date: { required: true },
    description: { maxLength: 255 }
}

const initialState = {
    amount: '',
    balance_id: '',
    balances: [],
    category_id: null,
    category: null,
    currency_id: '',
    currencies: [],
    exchange_rate: '1',
    description: '',
    date: new Date(),
    owner_id: null,
    owner: null
}

export const PaymentModal = ({ isOpen, currencies, balances, id, close, updateData, type, entityId, entity_currency_id }) => {
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState({})
    const [values, setValues] = useState({})

    const getPaymentData = async () => {
        setLoading(true)
        if(id) {
            const { status, data } = await myaxios.get(`/ajax/payments/${id}?expand=owner,category`)
            const { amount, balance_id, category_id, currency_id, exchange_rate, date, owner_id, owner, category, description } = data;

            if(status === 200) {
                setValues({
                    ...JSON.parse(JSON.stringify(initialState)),
                    balances: balances,
                    currencies,
                    amount, balance_id, category_id, currency_id, exchange_rate: exchange_rate || 1, date, owner_id, owner, category, description
                })
            }
        } else {
            const entityBalances = !!entity_currency_id ? balances.filter(el => el.currency_id === entity_currency_id) : balances;

            setValues({
                ...JSON.parse(JSON.stringify(initialState)),
                balances: entityBalances,
                balance_id: entityBalances.length > 0 ? entityBalances[0].id : '',
                currencies,
                currency_id: entityBalances.length > 0 && currencies.find(el => el.id === entityBalances[0].currency_id).id
            })
        }
        setLoading(false)
    }

    useEffect(() => {
        if(isOpen) getPaymentData().then()
        setErrors({})
    },[balances, currencies, id])

    const saveData = async () => {
        let createLink = `/ajax/payments`;
        let updateLink = `/ajax/payments/${id}`;

        if(type === 'invoice') {
            updateLink = `/ajax/invoicepaymentnew/${entityId}/payment/${id}`
            createLink = `/ajax/invoicepaymentnew/${entityId}/payment`
        } else if(type === 'order') {
            updateLink = `/ajax/order/${entityId}/payments/${id}`
            createLink = `/ajax/order/${entityId}/payments`
        }

        const errorsData = validationData(values, validationConfig);
        setErrors(errorsData)
        if(Object.keys(errorsData).length > 0) {
            Notif('required-fields')
            return false;
        }

        const { amount, balance_id, category_id, currency_id, exchange_rate, date, owner_id, description } = values
        const postData = { amount: +amount, balance_id, category_id, currency_id, exchange_rate: +exchange_rate, date: formatDate(date), owner_id, description }

        const { status, data } = id ? await myaxios.put(updateLink, postData) : await myaxios.post(createLink, postData)
        if(status === 200 || status === 201) {
            updateData()
            createEvent({ user_id: window.location.host, user_name: "---", event_type: "createPayment" }).then()
        }
    }

    return (
        <Modal isOpen={isOpen} title={'Платеж'} save={saveData} close={close} loading={loading}>
            <div>
                <Myselect
                    label={'Кошелек'}
                    value={values.balance_id}
                    options={values.balances} val={'id'} lab={'name'}
                    onChange={e => {
                        const balance = values.balances.find(el => el.id === e.target.value)
                        const currency = values.currencies.find(el => el.id === balance.currency_id)
                        setValues({
                            ...values,
                            balance_id: e.target.value,
                            currency_id: currency && currency.id
                        })
                    }}
                    valid={!errors.balance_id} errorMessage={errors.balance_id} shouldValidate
                />
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '70%' }}>
                        <Input
                            label={'Сумма'}
                            value={values.amount}
                            onChange={e => setValues({ ...values, amount: e.target.value })}
                            valid={!errors.amount} errorMessage={errors.amount} shouldValidate
                        />
                    </div>
                    <div style={{ width: '30%' }}>
                        <Myselect
                            label={'Валюта'} val={'id'} lab={'name'} disabled
                            value={values.currency_id}
                            options={values.currencies}
                            onChange={e => setValues({ ...values, currency_id: e.target.value })}
                            valid={!errors.currency_id} errorMessage={errors.currency_id} shouldValidate
                        />
                    </div>
                </div>
                <DateTime
                    label={'Дата'}
                    value={values.date}
                    timeIntervals="1"
                    showTimeSelect
                    dateFormat="dd-MM-yyyy HH:mm"
                    timeFormat="HH:mm"
                    handleChange={date => setValues({ ...values, date })}
                    valid={!errors.date} errorMessage={errors.date}
                />
                <NewAutoSelect
                    label={'Категория'}
                    searchBy={'value'}
                    entity={values.category}
                    link={'/ajax/settings/lookup?type=payment_category&value='}
                    updateData={(category) => setValues({ ...values, category_id: category && category.id, category })}
                    onEnterData={(category) => setValues({ ...values, category_id: category && category.id, category })}
                    clear
                />
                <Textarea
                    label={'Описание'}
                    value={values.description}
                    onChange={e => setValues({ ...values, description: e.target.value })}
                    valid={!errors.description} errorMessage={errors.description} shouldValidate rows={5}
                />
            </div>
        </Modal>
    )
}