import React, {useContext, useEffect, useRef, useState} from "react";
import Loader, {ModalLoader} from "../../components/UI/Loader";
import LeaveModal from "../../components/other/LeaveModal";
import IconButton from "../../components/other/IconButton";
import UploadAvatar from "../../components/other/UploadAvatar";
import {Tasks} from "../../components/Task";
import {filesFunc, TabsRender} from "../../components/fieldGen";
import {MemoButton} from "../../components/cardViewComponents/MemoButton";
import {validationData} from "../../components/UI/formValid";
import Notif from "../../components/Notif";
import {parseResponse, parseResponseError} from "../../components/cardViewComponents/parseFunctions";
import {GenerateForm} from "../../components/cardViewComponents/GenerateForm";
import {contactConfig, tabsConfig} from "./contactConfig";
import {CSSTransition} from "react-transition-group";
import History from "../../components/history/history";
import Related from "../../components/related/related";
import Files from "../../components/files/files";
import {contactFieldsValidation, transformPostContactFieldsData,transformSaveContactFields
} from "../../components/cardViewComponents/ContactFields";
import GetPostAditionalFields from "../../components/get_post_aditionalFields";
import {InfoTab} from "./components/InfoTab";
import {validationConfig} from "../../components/cardViewComponents/validationConfig";
import {TagsNew} from "../../components/cardViewComponents/TagsNew";
import {TaskModal, updateEntityTasks} from "../tasks/TaskModal";
import {
    deleteContactService,
    getSingleContactService,
    updateContactService
} from "../../services/contact/contactService";
import {additionalFieldsValidation} from "../../components/cardViewComponents/additionalFieldsValidation";
import {AccountCard} from "../../components/cardViewComponents/AccountCard";
import {CanDelete} from "../../Modal/CanDelete";
import {ConflictModal} from "../../components/cardViewComponents/ConflictModal";
import {FilesDropDown} from "../../components/FilesDropDown";
import {GlobalState} from "../../Layout/Layout";
import {MessengersInCards} from "../../components/cardViewComponents/MessengersInCards";
import {CardSendPulse} from "../../components/cardViewComponents/Integrations/CardSendPulse";
import is from 'is_js'

export const ContactViewNew = ({ match, history, location }) => {
    const { setSteps, state: { integrations }} = useContext(GlobalState)
    const urlParams = new URLSearchParams(location.search);
    const { params: { id } } = match
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState({})
    const [disabled, setDisabled] = useState({})
    const [conflict, setConflict] = useState(false)
    const [modalData, setModalData] = useState(null)
    const [values, setValues] = useState({
        account_id: '',
        account: null,
        birthdate: null,
        description: "",
        email: "",
        first_name: "",
        last_name: "",
        middle_name: "",
        mobile_phone: "",
        owner_id: null,
        owner: null,
        personal_discount: 0,
        position: "",
        type_id: '',
        types: [],
        work_phone: "",
        photo: '',
        created_at: '',
        updated_at: '',
        editorTags: '',
        files: [],
        tasks: [],
        additionalFields: [],
        contactFields: [],
        begin_update_datetime: null,
        subscriber: null,
        chats: [],
        tch: true
    })

    const getContactData = async () => {
        try {
            const { data, status, response } = await getSingleContactService({
                id, expand: 'invoicesDiff,tasks,account,types,files,owner,contactFields,additionalFields,chats,segments,rfmSegments,subscriber'
            })

            const parseResponseData = parseResponseError(response);
            if(parseResponseData.notFound) history.push('/404')

            if(status === 200 && data.id) {
                setValues(({
                    ...values,
                    ...parseResponse(contactConfig, data, {}),
                    types: data.types,
                    files: data.files,
                    tasks: data.tasks,
                    chats: data.chats,
                    subscriber: data.subscriber,
                    additionalFields: GetPostAditionalFields("get", data.additionalFields) || [],
                    contactFields: transformSaveContactFields(data.contactFields),
                }))
            }
        } catch (e) { console.error('getContactDataError', e) }

        setLoading(false)
    }

    const saveItem = async (forcibly) => {
        const { account_id, birthdate, description, email, first_name, last_name, middle_name, mobile_phone, owner_id,
            personal_discount, position, type_id, work_phone, photo, editorTags, updated_at } = values

        const additionalFieldsValidationRes = additionalFieldsValidation(values.additionalFields)
        const contactFieldsValidationRes = contactFieldsValidation(values.contactFields)

        const errorsData = validationData(
            {...values, ...contactFieldsValidationRes.values, ...additionalFieldsValidationRes.values},
            {...validationConfig(contactConfig), ...contactFieldsValidationRes.validationRules, ...additionalFieldsValidationRes.validationRules}
        )

        setErrors(errorsData)
        if(Object.keys(errorsData).length > 0) {
            Notif('required-fields')
            return
        }

        setLoading(true)

        try {
            const { data, status, response } = await updateContactService({ id, data: {
                    account_id, birthdate, description, email, first_name, last_name, middle_name, mobile_phone, owner_id,
                    personal_discount, position, type_id, work_phone, photo, updated_at, editorTags, begin_update_datetime: forcibly === 'ok' ? null : updated_at,
                    contactFields: transformPostContactFieldsData(values.contactFields, id, 2 ),
                    additionalFields: GetPostAditionalFields("post", values.additionalFields),
                }})

            if(status === 200 && data.id) {
                Notif('save', 'Запись успешно изменена')
                setValues(({ ...values, updated_at: data.updated_at, tch: true, begin_update_datetime: null }))

                if(values.first_name.toLowerCase() === 'иван' && values.email === 'temp@website.com') setSteps("edit-contact", true, true)
            }

            const parseResponseData = parseResponseError(response);
            if(parseResponseData.saveConflict) setConflict(true)
            if(parseResponseData.validationErrors) {
                setErrors(parseResponseData.validationErrors)
            }

        } catch (e) { console.error(e) }

        setLoading(false)
    }

    const deleteItem = async () => {
        if(!window.confirm('Удалить элемент?')) return false

        setLoading(true)
        try {
            const { status, data } = await deleteContactService({ id })
            if(status === 200 || status === 204) {
                if(!data.canDelete) {
                    Notif('delete', 'Контакт успешно удален')
                    setValues(prevState => ({ ...prevState, tch: true }))
                    history.push('/contact')
                } else if(data.items && typeof data.items === 'object') {
                    setModalData({ type: 'canDelete', items: data.items })
                } else {
                    Notif('Error', 'При удалении что-то пошло не так')
                }
            }
        } catch (e) { console.error(e) }
        setLoading(false)
    }

    useEffect(() => {
        getContactData().then()
    }, [id])

    const changeFields = (key, value, item) => {
        let obj = {}
        if(item) {
            if(item === 'clear') obj[contactConfig[key].entity_from] = null
            else obj[contactConfig[key].entity_from] = item
        }

        setValues({
            ...values, ...obj, [key]: item ? item.id : value,
            begin_update_datetime: !values.begin_update_datetime ? new Date() : values.begin_update_datetime,
            tch: false
        })
    }

    return (
        <>
            {loading && <ModalLoader />}
            <div className="page">
                <LeaveModal when={!values.tch} />
                <div className="newList-btn-group">
                    <div className="newList-btn-group-left">
                        <TagsNew
                            editorTags={values.editorTags} entity={'contact'}
                            setValues={data => setValues({ ...values, editorTags: data, tch: false })}
                        />
                        <MemoButton
                            onClick={() => setModalData({ type: 'task' })}
                            mobileIcon={'flaticon-notes mdb'} title={'Добавить задачу'} className={'mr5'}
                        />
                        {integrations && integrations.mail && values.email && is.email(values.email) &&
                            <CardSendPulse setValues={data => setValues({ ...values, ...data })} subscriber={values.subscriber} id={id} related_type={2} />
                        }
                        <MessengersInCards chats={values.chats} contact={{ id, name: `${values.first_name} ${values.last_name}` }} />
                    </div>
                    <div className="newList-btn-group-right">
                        <a onClick={deleteItem} className="butt-group-card-wrap btni ml10">
                            <span>Удалить</span>
                            <i className="flaticon-delete-2 mdb" />
                        </a>
                        <MemoButton onClick={saveItem} disabled={values.tch} mobileIcon={"flaticon-interface-1 mdb"} title={'Сохранить'} className={'ml5'} />
                        <IconButton href="https://sboxcrm.com/docs/contact" />
                    </div>
                </div>

                <div className="contact-page sbform">
                    <div className="contact-left">
                        <div className="contact-left-col" style={{ marginTop: "45px" }}>
                            <h3>Данные контакта</h3>
                            <UploadAvatar
                                img={values.photo}
                                setImg={(photo) => setValues(({ ...values, photo, tch: false }))}
                                cancelImg={() => setValues(({ ...values, photo: '', tch: false }))}
                                related_id={id}
                                related_type="2"
                            />
                            <GenerateForm
                                config={contactConfig}
                                { ...{ values, errors, disabled } }
                                onChange={changeFields}
                                onSaveData={saveItem}
                            />
                            <div className="clear" />
                        </div>

                        <AccountCard
                            account={values.account}
                            setDataValues={data => setValues(({ ...values, ...data, tch: false }))}
                        />
                    </div>

                    <div className="contact-right-col">
                        <Tasks tasks={values.tasks} openTask={data => setModalData(data)} />
                        <TabsRender
                            setState={({ layout }) => history.push(`?layout=${layout}`)}
                            state={{ layout: urlParams.get('layout') || tabsConfig[0].value }}
                            arr={tabsConfig} />

                        <div className="tab-container">
                            <CSSTransition in={(urlParams.get('layout') || tabsConfig[0].value) === tabsConfig[0].value} timeout={200} classNames="my-node" unmountOnExit>
                                <InfoTab
                                    {...{ values, errors, disabled, changeFields, id, saveItem }}
                                    setContactFields={data => setValues({ ...values, contactFields: data, tch: false })}
                                    setValues={data => setValues(({ ...values, ...data, tch: false }))}
                                />
                            </CSSTransition>

                            <CSSTransition in={urlParams.get('layout') === tabsConfig[1].value} timeout={{enter: 0, exit: 0}} classNames="my-node" unmountOnExit>
                                <History type="contact" id={id} />
                            </CSSTransition>

                            <CSSTransition in={urlParams.get('layout') === tabsConfig[2].value} timeout={{enter: 0, exit: 0}} classNames="my-node" unmountOnExit>
                                <Related
                                    type="contact" related={2}
                                    contact={{name: values.first_name }}
                                    contactEmail={values.email}
                                    contact_id={id} account={null}
                                    id={id} tasks cases invoices documents sms opportunities orders
                                    projects mails expense shipments
                                />
                            </CSSTransition>
                            <CSSTransition in={urlParams.get('layout') === tabsConfig[3].value} timeout={{enter: 0, exit: 0}} classNames="my-node" unmountOnExit>
                                <FilesDropDown
                                    related_id={id}
                                    related_type={"2"}
                                    files={values.files}
                                    updateFile={filesData => setValues(prevState => ({
                                        ...prevState,
                                        files: [filesData, ...prevState.files]
                                    }))}
                                    updateFiles={files => setValues(prevState => ({ ...prevState, files }))}
                                />
                            </CSSTransition>
                        </div>
                    </div>
                </div>
                {conflict && <ConflictModal
                    entity={'contact'} id={id} updated_at={values.begin_update_datetime}
                    save={() => saveItem('ok')} close={() => setConflict(false)}
                />}
                <TaskModal
                    id={modalData && modalData.id}
                    close={() => setModalData(null)}
                    index={modalData && modalData.index}
                    task={(type, data, index) => {
                        setValues({ ...values, tasks: updateEntityTasks(values.tasks, type, data, index) })
                    }}
                    related={{ id, type: 2 }}
                    isOpen={modalData && modalData.type === 'task'}
                />
                <CanDelete
                    isOpen={modalData && modalData.type === 'canDelete'} close={() => setModalData(null)}
                    items={modalData && modalData.items} entity={'contact'} delParent={deleteItem} name={'контакт'}
                    history={history}
                />
            </div>
        </>
    )
}