export const outItems = [
    {
        key:"about-crm",
        icon:"flaticon-user",
        name:"Зачем нужна CRM",
        type: "ordinary",
        video: "OIUMHS5lDgI",
        completed:true
    },
    {
        key:"contact",
        icon:"flaticon-user",
        name:"Как добавить контакт",
        type: "ordinary",
        video: "QfA_t7AkBdY",
        docs: 'https://sboxcrm.com/docs/contact',
        homeWork: "Необходимо создать контакт из основного раздела.",
        completed:false
    },
    {
        key:"edit-contact",
        icon:"flaticon-edit-1",
        name:"Работа с карточкой контакта",
        type: "ordinary",
        docs: 'https://sboxcrm.com/docs/contact',
        video: "cBuTSwVPH2w",
        homeWork: "Измените имя контакта на: 'Иван', задайте email 'temp@website.com' и нажмите на кнопку сохранить",
        completed:false
    },
    {
        key:"extra-fields",
        icon:"flaticon-cogwheel-1",
        name:"Дополнительные поля",
        type: "ordinary",
        video: "zI3BXN3qoAs",
        docs: 'https://sboxcrm.com/docs/extrafields',
        homeWork: "Создайте любое дополнительное поле для карточки контакта",
        completed:false
    },
    {
        key:"file-import",
        icon:"flaticon-folder-3",
        name:"Импорт контактов",
        type: "ordinary",
        video: "7HVJYh7E4Q8",
        docs: 'https://sboxcrm.com/docs/import',
        homeWork: "Импортируйте excel файл в раздел контактов",
        completed:false
    },
    {
        key:"about-list",
        icon:"flaticon-list",
        name:"Работа со списком",
        type: "ordinary",
        video: "lewF4E0XZtQ",
        docs: 'https://sboxcrm.com/docs/list',
        homeWork: "Найдите контакт по ключу 'ФИО' со значением 'Иван'",
        completed:false
    },
    {
        key:"tasks",
        icon:"flaticon-notes",
        name:"Работа с задачами",
        type: "ordinary",
        video: "3XHAadtXUqc",
        docs: 'https://sboxcrm.com/docs/task',
        homeWork: "Создайте задачу из любого контакта с текстом 'Позвонить клиенту'",
        completed:false
    },
    {
        key:"orders",
        icon:"flaticon-cart",
        name:"Работа с заказами",
        type: "ordinary",
        video: "VqnzQ0MXyqU",
        docs: "https://sboxcrm.com/docs/order",
        homeWork: "Создайте и завершите заказ",
        completed:false
    },
    {
        key:"invoices",
        icon:"flaticon-coins",
        name:"Работа со счетами",
        type: "ordinary",
        video: "gQc1lRX6Eys",
        docs: "https://sboxcrm.com/docs/invoice",
        homeWork: "Добавьте платеж со статусом `Оплачен` и суммой 5000 (любой валютой)",
        completed:false
    },
    {
        key:"users",
        icon:"flaticon-users",
        name:"Работа с пользователями и правами",
        type: "ordinary",
        video: "wl_ARJRoLT8",
        docs: "https://sboxcrm.com/docs/users",
        homeWork: "Создайте новое подразделение с названием `test`, после чего создайте пользователя с этип подразделением",
        completed:false
    },
    {
        key:"notify",
        icon:"flaticon-music-2",
        name:"Настройка уведомлений",
        video: "1slvd5Fvlnk",
        docs: "https://sboxcrm.com/docs/profile",
        type: "ordinary",
        homeWork: "Включите все виды уведомлений для задач",
        completed:false
    },
    {
        key:"first-level-completed",
        icon:"flaticon-clock-1",
        name:"Уровень Основы работы с CRM завершен!",
        desc: "7 дней",
        type: "completed",
        completed:false
    },
    {
        key:"rules",
        icon:"flaticon-cogwheel",
        name:"Бизнес правила",
        type: "ordinary",
        video: "lIA4Si5yJRk",
        docs: "https://sboxcrm.com/docs/b_rules",
        homeWork: "Создать бизнес правило для поля бюджет раздела сделки",
        completed:false
    },
    {
        key:"process",
        icon:"flaticon-map",
        name:"Бизнес-процессы",
        type: "ordinary",
        video: "Bi1NzmTmGSg",
        docs: "https://sboxcrm.com/docs/bpinformation",
        homeWork: "Создать бизнес процесс для контакта с типом 'Изменение записи' и триггером 'Добавление'",
        completed:false
    },
    {
        key:"inside-chat",
        icon:"flaticon-speech-bubble-1",
        name:"Чат с сотрудниками",
        type: "ordinary",
        video: "WrMPd1kWItQ",
        homeWork: "Отправьте сообщение `Начнем работу` любому из сотрудников",
        completed:false
    },
    {
        key:"messengers",
        icon:"flaticon-chat-1",
        name:"Чат в мессенджерах",
        type: "ordinary",
        video: "NQmhuvJPex8",
        homeWork: "Подключите любой из мессенджеров и измените режим - живой чат/чат-бот и обратно",
        completed:false
    },
    {
        key:"print",
        icon:"flaticon-technology-2",
        name:"Печать документов",
        type: "ordinary",
        video: "yGZULMBd29Q",
        homeWork: "Создайте шаблон документа для счета и выведите дату обновления счета через шаблон model",
        completed:false
    },
    /*{
        key:"messengers",
        icon:"flaticon-email",
        name:"Подключение почты",
        type: "ordinary",
        completed:false
    },*/
    {
        key:"letter-templates",
        icon:"flaticon-background",
        name:"Настройка шаблонов писем",
        video: "vbW0A8zRY_0",
        type: "ordinary",
        homeWork: "Создайте шаблон письма и через режим конструктора вставьте любое изображение",
        completed:false
    },
    {
        key:"second-level-completed",
        icon:"flaticon-clock-1",
        name:"Уровень Продвинутые функции CRM завершен!",
        desc: "7 дней",
        type: "completed",
        completed:false
    },
    {
        key:"segmentation",
        icon:"flaticon-pie-chart",
        name:"Сегментация",
        type: "ordinary",
        completed:false
    },
    {
        key:"rfm-segmentation",
        icon:"flaticon-analytics",
        name:"RFM сегментация",
        type: "ordinary",
        completed:false
    },
    {
        key:"sms-sending",
        icon:"flaticon-chat-1",
        name:"SMS рассылка",
        type: "ordinary",
        completed:false
    },
    {
        key:"messengers-sending",
        icon:"flaticon-paper-plane",
        name:"Рассылка в мессенджерах",
        type: "ordinary",
        completed:false
    },
    {
        key:"chat-bot",
        icon:"flaticon-map",
        name:"Чат-бот",
        type: "ordinary",
        completed:false
    },
    {
        key:"email-sending",
        icon:"flaticon-email",
        name:"Email рассылка",
        type: "ordinary",
        completed:false
    },
    {
        key:"bp-segments",
        icon:"flaticon-signs",
        name:"Бизнес-процесс на основе сегментов",
        type: "ordinary",
        completed:false
    },
    {
        key:"third-level-completed",
        icon:"flaticon-clock-1",
        name:"Уровень CRM-маркетинг завершен!",
        desc: "7 дней",
        type: "completed",
        completed:false
    },
]

export type Item = {
    key:string,
    icon:string,
    name:string,
    type:string,
    desc?: string,
    video?: string,
    homeWork?: string,
    docs?: string,
    completed:boolean,
}

export const steps = [
    [
        {
            target: '.educate-modal .mod-cont',
            content: 'Здесь находятся список всех уроков. Серым отмечаются задания, которые еще закрыты.',
            disableBeacon: true,
            placement: 'left',
            spotlightClicks: false,
            styles: {options: {zIndex: 10000}},
            hideCloseButton: true
        },
        {
            target: '#contact',
            content: 'Красным цветом отображается текущий урок, который необходимо просмотреть и выполнить задание.',
            disableBeacon: true,
            placement: 'left',
            spotlightClicks: false,
            styles: {options: {zIndex: 10000}},
            hideCloseButton: true,
            controlled: true
        },
    ],
    [
        {
            target: '.center-modal-body iframe',
            content: 'Для начала просмотрите видео к уроку',
            disableBeacon: true,
            placement: 'right',
            spotlightClicks: false,
            styles: {options: {zIndex: 10000}},
            hideCloseButton: true
        },
        {
            target: '.video-desc-container',
            content: 'Далее выполните задание к уроку.',
            disableBeacon: true,
            placement: 'left',
            spotlightClicks: false,
            styles: {options: {zIndex: 10000}},
            hideCloseButton: true
        },
    ],
    [
        {
            target: '#contact',
            content: 'После выполнения задания пункт станет зеленым.',
            disableBeacon: true,
            placement: 'left',
            spotlightClicks: false,
            styles: {options: {zIndex: 10000}},
            hideCloseButton: true,
            controlled: true
        },
        {
            target: '#edit-contact',
            content: 'И теперь доступен следующий урок',
            disableBeacon: true,
            placement: 'left',
            spotlightClicks: false,
            styles: {options: {zIndex: 10000}},
            hideCloseButton: true,
            controlled: true
        },
        {
            target: '#first-level-completed',
            content: 'После выполнения всех заданий раздела вы получите 7 дополнительных дней использования CRM.',
            disableBeacon: true,
            placement: 'top',
            spotlightClicks: false,
            styles: {options: {zIndex: 10000}},
            hideCloseButton: true,
            controlled: true
        },
    ]
]