import React, {useEffect, useState} from "react";
import axios from "axios";
import {TRACKER_URL} from "../../../components/integration/list/Tracker";
import {BpDateWithPeriod, parseDataForPeriod, saveTimePeriod} from "../../../components/BussinessProcessNew/BpHelpers";
import {validationData} from "../../../components/UI/formValid";
import Modal from "../../../components/Modal";
import Input from "../../../components/UI/Input";
import Notif from "../../../components/Notif";

export const TrackerSiteList = ({ history }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [list, setList] = useState([])

    const getSiteList = async () => {
        try {
            const { status, data } = await axios.get(`${TRACKER_URL}/v1/site?access-token=${localStorage.getItem('tracker-token')}`)
            if (status === 200 && data) {
                if (data.length > 0) {
                    history.push(`/tracker/${data[0].id}`)
                } else {
                    setIsOpen(true)
                }
            }
        } catch (e) {

        }
    }

    useEffect(() => {
        getSiteList().then()
    }, [])

    return (
        <div className={'page'}>

            {isOpen && <CreateTrackerSite close={() => setIsOpen(false)} history={history} />}
        </div>
    )
}

const validationRules = {
    name: { required: true, maxLength: 255 },
    url: { required: true, maxLength: 255, url: true }
}

const CreateTrackerSite = ({ close, history }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [values, setValues] = useState({
        name: '',
        url: '',
        tch: true
    })

    useEffect(() => { setIsOpen(true) }, [])

    const saveData = async () => {
        const validErrors = validationData(values, validationRules)
        if(Object.keys(validErrors).length > 0) {
            setErrors(validErrors)
            return false;
        }
        setErrors({})
        setIsOpen(false)

        setLoading(true)
        try {
            const { status, data } = await axios.post(`${TRACKER_URL}/v1/site/create?access-token=${localStorage.getItem('tracker-token')}`, {
                user_id: localStorage.getItem('tracker-userId'),
                name: values.name,
                url: values.url
            })
            if (status === 201) {
                history.push(`/tracker/${data.id}`)
            }
        } catch (e) {
            Notif('500')
        }
        setLoading(false)
    }

    const closeModal = () => {
        setIsOpen(false); setTimeout(close, 200);
    }

    return (
        <Modal showLeaveModal tch={values.tch} isOpen={isOpen} title={'Добавить сайт'} loading={loading}
               save={saveData} parentClassName={'bpmModal'} close={closeModal}>
            <div className={'sbform'}>
                <Input
                    label={'Название'}
                    value={values.name}
                    onChange={e => setValues({...values, name: e.target.value, tch: false })}
                    errorMessage={errors.name} valid={!errors.name} shouldValidate
                />
                <Input
                    label={'URL сайта'}
                    value={values.url}
                    onChange={e => setValues({...values, url: e.target.value, tch: false })}
                    errorMessage={errors.url} valid={!errors.url} shouldValidate
                />
            </div>
        </Modal>
    )
}