import React, {useContext, useEffect, useState} from "react";
import {FullModal} from "../../Modal/FullModal";
import {searchEntityId} from "../BpHelpers";
import {GlobalState} from "../../../Layout/Layout";

export const AllActions = ({ close, setModalData, index, actionsData, entity_type }) => {
    const { state: { integrations, email }} = useContext(GlobalState)
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        setIsOpen(true)
    }, [])

    const closeModal = () => {
        setIsOpen(false)
        setTimeout(close, 300)
    }
    const chooseItem = (type) => {
        setIsOpen(false)
        setTimeout(() => setModalData({ type, index }), 300)
    }
    
    return (
        <FullModal isOpen={isOpen} close={closeModal}>
            <div className={'full-modal-body'} style={{ background: '#fff', width: '100%', padding: '5px', flexWrap: 'wrap' }}>
                {
                    actionsData.filter(el => el.allowEntities.includes(searchEntityId(entity_type))).map(item => {
                        if (item.type === 'mail' && !email) return <></>
                        if (item.type === 'sendSms' && !integrations.sms) return <></>
                        if (item.type === 'sendpulse_addressbook' && !integrations.mail) return <></>


                        return (
                            <div key={item.type} className="new-bg-item">
                                <i className={item.icon} />
                                <span>{item.name}</span>
                                <button onClick={() => chooseItem(item.type)}>Добавить</button>
                            </div>
                        )
                    })
                }
            </div>
        </FullModal>
    )
}