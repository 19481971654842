import React, {useEffect, useState} from "react";
import Modal from "../../Modal";
import Myselect from "../../UI/Select";
import myaxios from "../../Axios";
import {BpDateWithPeriod, parseDataForPeriod, saveTimePeriod, searchEntityId} from "../BpHelpers";
import Notif from "../../Notif";

export const BpEditTags = ({ close, metaData, saveNode, entity_type }) => {
    const [isError, setIsError] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [tags, setTags] = useState([])
    const [chosenTags, setChosenTags] = useState([])
    const [errors, setErrors] = useState({})
    const [values, setValues] = useState({
        time_value: 0,
        time_valuePeriod: 'minutes',
        tch: true,
        type: 'create',
    })

    const getPrepareData = async () => {
        try {
            const { status, data } = await myaxios.get(`/ajax/${searchEntityId(entity_type)}/suggest`)
            if(status === 200) {
                if(data.length === 0) {
                    setIsError(true)
                    return
                }
                setTags(data.items)
                getData()
            } else {
                Notif('Error')
            }
        } catch (e) {}
    }

    const getData = () => {
        if(metaData) {
            const { time_value, metaData: { type, value } } = metaData
            setValues({
                ...values,
                time_value: parseDataForPeriod(time_value).value,
                time_valuePeriod: parseDataForPeriod(time_value).period,
                type
            })
            setChosenTags(value)
        }
        setLoading(false)
    }

    useEffect(() => {
        setIsOpen(true)
        getPrepareData().then()
    },[])

    const saveData = () => {
        if(chosenTags.length === 0) {
            Notif('Error', 'Необходимо выбрать как минимум 1 тег')
            setLoading(false)
            return false
        }

        setIsOpen(false)
        setTimeout(() => saveNode({
            id: metaData && metaData.id,
            name: chosenTags.join(),
            nodeType: 'node',
            time_value: saveTimePeriod(values.time_value, values.time_valuePeriod),
            is_scheduled: values.time_value > 0,
            type: "tags",
            metaData: {
                type: values.type,
                value: chosenTags
            }
        }), 100)
    }

    const closeModal = () => {
        setIsOpen(false); setTimeout(close, 200);
    }

    if (isError) {
        return (
            <Modal showLeaveModal tch={values.tch} isOpen={isOpen} title={'Ошибка'} loading={loading}
                   save={saveData} parentClassName={'bpmModal'} close={closeModal}>
                <div style={{ fontSize: '14px', padding: '5px 0' }}>Необходимо добавить как минимум 1 тег</div>
            </Modal>
        )
    }

    return (
        <Modal showLeaveModal tch={values.tch} isOpen={isOpen} title={'Уведомление'} loading={loading}
               save={saveData} parentClassName={'bpmModal'} close={closeModal}>
            <div className="sbfrom">
                <Myselect
                    value={values.type}
                    options={[
                        { value: 'create', label: 'Добавить' },
                        { value: 'delete', label: 'Удалить' }
                    ]}
                    onChange={e => setValues({ ...values, type: e.target.value, tch: false })}
                />
                <BpDateWithPeriod
                    label={'Отложенный запуск'}
                    name={'time_value'} periodName={'time_valuePeriod'}
                    values={values} errors={errors}
                    setValues={(type, value) => setValues({...values, [type]: value, tch: false })}
                    tooltip={'Время задержки выполнения процесса'}
                />

                <div className="connection"><span>Выберите теги (выбрано {chosenTags.length} из {tags.length})</span></div>
                <div className={'bp-tags-items'}>
                    {
                        tags.map(item => {
                            return (
                                <div
                                    onClick={() => {
                                        if(chosenTags.includes(item.name)) {
                                            setChosenTags(chosenTags.filter(el => el !== item.name))
                                        } else {
                                            setChosenTags([...chosenTags, item.name])
                                        }
                                        setValues({ ...values, tch: false })
                                    }}
                                    className={`bp-tags-item ${chosenTags.includes(item.name) && 'bp-tags-item-active'}`}>
                                    {item.name}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </Modal>
    )
}