import React, {useContext, useEffect, useState} from "react";
import MainEditor from "../components/UI/Editor";
import {unLink, updateData} from "../components/fieldGen";
import DateTime from "../components/UI/DateTime";
import myaxios from "../components/Axios";
import Input from "../components/UI/Input";
import SoundClick from "../components/soundClick";
import Myselect from "../components/UI/Select";
import ModAutoSelect from "../components/UI/ModAutoSelect";
import DropzoneComponent from 'react-dropzone-component';
import Notif from "../components/Notif";
import {GlobalState} from "../Layout/Layout";
import AutoSelect from "../components/UI/AutoSelect";
import formValid from "../components/UI/formValid";
import {TASK_PRIORITY} from "../const";
import Loader from "../components/UI/Loader";
import {ATTACHED_NUMBER_TO_TEXT, ATTACHED_NUMBER_TO_STRING} from '../const';
import {withRouter} from 'react-router-dom';
import FilesItem from "../components/files/files-item";
import {CSSTransition} from "react-transition-group";
import withGlobalState from "../components/other/withGlobalState";
import {createEvent} from "../services/other/events";

const AddTask = props => {
    const context = useContext(GlobalState);
    const [state, setMyState] = useState({
        formControl:{
            subject: {
                value:'',
                valid:true,
                validation: {required: true}
            },
            description: {
                value:'',
                valid:true,
                validation: {required: false}
            },
            comment: {
                value:'',
                valid:true,
                validation: {required: false}
            },
            status_id: {
                value:3,
                valid:true,
                validation: {required: true}
            },
            start_date: {
                value: props.startDate || null,
                valid: true,
            },
            end_date: {
                value: props.endDate || null,
                valid: true,
            },
            remind_at: {
                value: null,
                valid: true,
            },
            priority: {
                value:'1',
                options:TASK_PRIORITY,
                valid:true,
            },
            type_id: {
                value:'',
                options:[],
                valid:true,
            },
            owner_id: {
                value:'',
                entity:null,
                valid:true,
            },

        },
        subtask_json:[],
        subtask_value:'',
        subtask_active:false,
        subtask_active_index:null,
        executors:[],
        executor: false,
        loading:true,
        files: [],
        filesList:[],
        related_id: '',
        related_type: '',
        related_name: "Лид №1",
        owners: [],
        user: false,
        load:false,
        showAddUser: false,
        editorTags:'',
        open: ""
    });

    const setState = (st, func) => {
        Object.keys(st).map((key) => { state[key] = st[key]});
        setMyState(prevState => { return {...prevState, ...state} });
        if(func) func();
    };

    const setField = (type, e) => {
        const formControl = state.formControl;
        formControl[type].value = e;
        setState({ formControl })
    };

    useEffect(() => {
        setTimeout(prepare, 150);
    }, [])


    const prepare = async () => {
        await myaxios.get(`/ajax/task/prepare`, {method: 'get',
        }).then((response) => {
            const formControl = state.formControl;
            formControl.type_id.options = response.data.types;
            formControl.type_id.value = response.data.types[0].id;
            if(!props.id) {
                formControl.owner_id.value = Number(context.state.userId)
                formControl.owner_id.entity = {id: Number(context.state.userId), name: context.state.user ? context.state.user.name : "я"}
            }
            setState({ formControl}, getData())
        })
    };


    const getData = () => {
        (props.id) ? myaxios.get(`/ajax/task/${props.id}?expand=owner,types,connected,owners,files`).then(res => {
            const formControl = state.formControl;
            formControl.comment.value = res.data.comment || '';
            formControl.description.value = res.data.description || '';
            formControl.subject.value = res.data.subject || '';
            formControl.start_date.value = res.data.start_date || '';
            formControl.end_date.value = res.data.end_date || '';
            formControl.status_id.value = res.data.status_id || '';
            formControl.owner_id.value = res.data.owner_id || '';
            formControl.owner_id.entity = res.data.owner;
            formControl.remind_at.value = res.data.remind_at || '';
            formControl.type_id.value = res.data.type_id || '';
            formControl.priority.value = res.data.priority || '';
            setState({
                subtask_json: JSON.parse(res.data.subtask_json) || [],
                editorTags: res.data.editorTags,
                user: Number(context.state.userId) !== Number(res.data.owner_id),
                related_id: res.data.connected ? res.data.connected.related_id : null,
                related_type: res.data.connected ? res.data.connected.related_type : null,
                related_name: res.data.connected ? res.data.connected.related_name : null,
            })
            setState({ filesList: res.data.files })
            setState({ owners: res.data.owners, loading: false })
        }) : setState({
            loading: false
        })
    };

    const dateValidation = () => {
        let startDate = Date.parse(state.formControl.start_date.value);
        let endDate = Date.parse(state.formControl.end_date.value);
        let valid = true;
        let now = new Date();
        if(endDate < startDate){
            valid = false;
            Notif("Error", "Дата начала не может быть позже даты завершения");
        }
        return valid;
    }

    const isFormValid = () => {
        let formControl = state.formControl;
        let isFormValid = true;
        Object.keys(formControl).map((key, index) => {
            formControl[key].valid = formValid(formControl[key].value, formControl[key].validation, formControl[key]);
            isFormValid = formControl[key].valid && isFormValid
        });
        setState({ formControl, isFormValid });
        return isFormValid;
    };

    const postObj = {
        comment: state.formControl.comment.value,
        description: state.formControl.description.value,
        subject: state.formControl.subject.value,
        start_date: state.formControl.start_date.value ? state.formControl.start_date.value.toLocaleString() : null,
        end_date: state.formControl.end_date.value ? state.formControl.end_date.value.toLocaleString() : null,
        remind_at: state.formControl.remind_at.value ? state.formControl.remind_at.value.toLocaleString() : null,
        status_id: state.formControl.status_id.value,
        owner_id: state.formControl.owner_id.value,
        type_id: state.formControl.type_id.value,
        priority: state.formControl.priority.value,
        subtask_json: JSON.stringify(state.subtask_json),
        related_id: (props.tie) ? props.tie : state.related_id,
        related_type: (props.relatedType) ? props.relatedType : state.related_type,
        files: state.files,
        editorTags: state.editorTags
    };

    const postData  = async () => {
        if(isFormValid() && dateValidation() ) {
            let files = [];
            for(let i = 0; i <= state.filesList.length - 1; i++){
                if(state.filesList[i]){
                    files.unshift(state.filesList[i].id)
                }
            }
            postObj.owners = sendOwners();
            postObj.files = files;
            await myaxios.post(`/ajax/task`, postObj).then(res => {
                if(res.status === 200 || res.status === 201){
                    createEvent({
                        event_type: "createTask",
                        user_id: state.formControl.owner_id.value + "", user_name: "---"
                    })
                    if(props.relatedType == 2 && state.formControl.subject.value === "Позвонить клиенту") props.setSteps("tasks", true, true)
                    if(props.related) {
                        setTimeout(() => props.close());
                        setTimeout(() => props.save())
                    } else {
                        setTimeout(() => props.close());
                        //props.history.push(`/task/view/${res.data.id}`)
                        setTimeout(() => {props.task("add", res.data)}, 200);
                    }
                }
            })
        }
    };

    const putData = async () => {
        if(dateValidation && isFormValid) {
            let files = [];
            for(let i = 0; i <= state.filesList.length - 1; i++){
                if(state.filesList[i]){
                    files.unshift(state.filesList[i].id)
                }
            }
            postObj.owners = sendOwners();
            postObj.files = files;
            await myaxios.put(`/ajax/task/${props.id}`, postObj).then(res => {
                if(props.related) {
                    setTimeout(() => props.save())
                } else {
                    setTimeout(() => props.close());
                    setTimeout(() => {props.task("edit", res.data, props.index)}, 200);
                }
            })
        }
    };

    const addSubtask = (e) => {
        if(state.subtask_value.length >= 1){
            let subtask_json = state.subtask_json;
            subtask_json.push({text: state.subtask_value, status: false})
            setState({ subtask_json, tch: false, subtask_value:'' })
        }
    };
    const subTaskEdit = (type, index) => {
        let subtask_json = state.subtask_json;
        if(type === "setStatus"){
            subtask_json[index].status = !state.subtask_json[index].status;
        } else if(type === "delete"){
            delete subtask_json[index];
        }
        setState({ subtask_json, tch: false })
    };

    const deleteTask = event => {
        event.preventDefault();
        if(window.confirm("Удалить задачу?")) {
            myaxios.delete('/ajax/task/' + props.id).then(response => {
                if(response){
                    if(response.status === 200 || response.status === 201 || response.status === 204) {
                        Notif("delete", "Задача успешно удалена");
                        setTimeout(() => props.close());
                        setTimeout(() => {props.task("delete", null, props.index)}, 200);
                    }
                }
            })
        }
    };

    const files = arr => {
        const filesList = state.filesList;
        filesList.unshift(arr);
        setState({filesList});
    };
    const delFileArr = id => {
        const filesList = state.filesList;
        if(id && filesList.length >= 1){
            for(let i = 0; i <= filesList.length; i++){
                if(filesList[i]){
                    if(filesList[i].id === id) {
                        delete filesList[i]
                    }
                }
            }
            setState({filesList});
        }
    };

    const sendOwners = () => {
        let a = [];
        state.owners.map(item => item ? a.push(item.id) : null);
        return a;
    }
    const sortOwners = (id) => {
        let a = true;
        state.owners.map(item => (item.id === id) ? a = false : null)
        return a;
    };
    const addOwners = (id, arr) => {
        let owners = state.owners;
        if(sortOwners(id)) owners.push(arr);
        setState({ owners, tch: false, showAddUser: false })
    };
    const deleteOwners = id => {
        let owners = state.owners;
        for(let i = 0; i < owners.length; i++){
            if(owners[i].id === id) owners.splice(i, 1);
        }
        setState({ owners, tch: false })
    };

    const delFile = (id, index) => {
        if(window.confirm()){
            myaxios.delete(`/ajax/file/delete/${id}`).then(res => {
                if(res) if(res.status === 200 || res.status === 201 || res.status === 204) {
                    delFileArr(id)
                    Notif("delete")
                }
            })
        }
    };

    const onKeyPost = (e) => {
        if(e.ctrlKey && e.keyCode === 13) onKeyPostEditor()
    };

    const onKeyPostEditor = (e) => {
        if(props.id) putData();
        else postData();
    }

    const {formControl} = state;
    return <div className="sbform create-activ">
        {state.loading && <Loader contain />}
        <CSSTransition in={true} timeout={50} classNames="my-node" unmountOnExit>
            <div>
        <div className="rmod-header">
            <b>{(props.id) ? "Редактировать задачу" : "Создать задачу"}</b>
            <a onClick={props.close} className="mclose"><i className="flaticon-close" /></a>
            {
                <div className="togglelist"><button onClick={props.id ? putData : postData} className="rmod-ok"><i className="flaticon-interface-1" /></button>
                    {(props.id && !state.user) ? <ul>
                        <li><button style={{ background: "#c2263f", borderTop: "none" }} onClick={deleteTask}><i className="flaticon-delete-1" /> Удалить</button></li>
                    </ul> : null}
                </div>
            }
        </div>
        <div className="mod-cont">
            <Input
                label="Тема задачи"
                value={formControl.subject.value}
                valid={formControl.subject.valid}
                shouldValidate={true}
                disabled={state.user}
                onChange={e => setField("subject", e.target.value)}
                onKeyDown={e => onKeyPost(e)}
            />
            <MainEditor
                label="Описание задачи"
                value={formControl.description.value}
                valid={state.formControl.description.valid}
                shouldValidate={true}
                onChange={e => setField("description", e)}
                onSaveContent={onKeyPostEditor}
            />
            {formControl.status_id.value !== 3 && formControl.status_id.value !== 7 ? <MainEditor
                label="Комментарий исполнителя"
                value={formControl.comment.value}
                valid={state.formControl.comment.valid}
                shouldValidate={true}
                onChange={e => setField("comment", e)}
                onSaveContent={onKeyPostEditor}
            /> : null}
            <div className="sbform">
                <label style={{ marginTop:"10px" }}>Статус выполнения</label>
                <div className="task-statuses">
                    {!props.id && <button className={formControl.status_id.value === 7 ? "task_active" : ''} onClick={() => setField("status_id", 7)}>Backlog</button>}
                    <button className={formControl.status_id.value === 3 ? "task_active" : ''} onClick={() => setField("status_id", 3)}>Не начата</button>
                    <button className={formControl.status_id.value === 6 ? "task_active" : ''} onClick={() => setField("status_id", 6)}>Уточнение</button>
                    <button className={formControl.status_id.value === 4 ? "task_active" : ''}  onClick={() => setField("status_id", 4)}>В работе</button>
                    <button className={formControl.status_id.value === 2 ? "task_active" : ''} onClick={() => setField("status_id", 2)}>Приостановлена</button>
                    <button className={formControl.status_id.value === 5 ? "task_active" : ''} onClick={() => setField("status_id", 5)}>На проверке</button>
                    {props.id && <button className={formControl.status_id.value === 1 ? "task_active" : ''} onClick={() => setField("status_id", 1)}>Завершена</button>}
                </div>
            </div>
            <div className="task-date">
                <div style={{ display: 'flex' }}>
                    <i className="flaticon-calendar-3" />
                    <DateTime
                        label="Дата начала"
                        value={formControl.start_date.value}
                        valid={formControl.start_date.valid}
                        event={"start_date"}
                        handleChange={e => setField("start_date", e)}
                        dateFormat="dd MMMM yyyy - HH:mm"
                        timeFormat="HH:mm"
                        timeIntervals="5"
                        showTimeSelect
                        disabled={state.user}
                    />
                </div>
                <div style={{ display: 'flex' }}>
                    <i className="flaticon-calendar-2" />
                    <DateTime
                        label="Крайняя дата"
                        value={formControl.end_date.value || '0000-00-00'}
                        valid={formControl.end_date.valid}
                        event={"end_date"}
                        handleChange={e => setField("end_date", e)}
                        dateFormat="dd MMMM yyyy - HH:mm"
                        timeFormat="HH:mm"
                        timeIntervals="5"
                        showTimeSelect
                        disabled={state.user}
                    />
                </div>
                <div style={{ display: 'flex' }} className="task-remind">
                    <i className="flaticon-alert-1" />
                    <DateTime
                        label="Напомнить"
                        value={formControl.remind_at.value}
                        valid={formControl.remind_at.valid}
                        event={"remind_at"}
                        handleChange={e => setField("remind_at", e)}
                        dateFormat="dd MMMM yyyy - HH:mm"
                        timeFormat="HH:mm"
                        timeIntervals="5"
                        showTimeSelect
                        disabled={state.user}
                    />
                </div>
            </div>
            <hr className="task-hr"/>
            <div className="subtask">
                <table style={{ width: "100%" }}>{
                    state.subtask_json ? state.subtask_json.map((item, index) =>
                        item ? <tr key={index}>
                            <td style={{ width: "20px" }}>{(item.status) ? <i onClick={() => {subTaskEdit("setStatus", index)}} className="flaticon-interface-1" /> : <div onClick={() => {subTaskEdit("setStatus", index)}} className="subTskSquire" />}</td>
                            <td style={{ textDecoration: (item.status) ? 'line-through' : '', color: (item.status) ? '#bbb' : '' }}><em onClick={() => {subTaskEdit("setStatus", index); SoundClick("click")}}>{item.text}</em></td>
                            <td style={{ width: "15px" }}><a onClick={() => {subTaskEdit("delete", index)}} style={{ color: "#61131f" }}>x</a></td>
                        </tr> : null
                    ) : null
                }</table>
                <div className="subtaskInp">
                    <i onClick={e => addSubtask()} className="flaticon-add" />
                    <Input placeholder="Введите текст подзадачи и нажмите на ENTER" className="c12" value={state.subtask_value} onKeyUp={e => (e.keyCode === 13) ? addSubtask() : null} onChange={e => {setState({ subtask_value: e.target.value })} } />
                </div>
                <div className="clear" />
            </div>
            <hr className="task-hr"/>

            <Myselect
                label="Приоритет задачи"
                value={formControl.priority.value}
                valid={formControl.priority.valid}
                onChange={e => setField("priority", e.target.value)}
                options={formControl.priority.options}
                disabled={state.user}
            />

            <Myselect
                label="Тип задачи"
                value={formControl.type_id.value}
                valid={formControl.type_id.valid}
                onChange={e => setField("type_id", e.target.value)}
                options={formControl.type_id.options}
                lab="value"
                val="id"
                disabled={state.user}
            />

            {state.related_id ?
                <div className={`inp`}><label>Связь</label>
                    <div className="rsb">
                        <div className="clear" />
                        <a onClick={() => props.history.push('/' + ATTACHED_NUMBER_TO_STRING[String(state.related_type)] + '/' + state.related_id)}>{state.related_name}</a>
                    </div>
                </div>
                : null}

            <EditTags
                tags={state.editorTags}
                type="task"
                refreshState={e => setState({ editorTags: e })}
            />

            <ModAutoSelect
                label="Ответственный"
                link="/ajax/settings/user?filter[name][like]="
                minLength="0"
                result="name"
                event="owner_id"
                valid={formControl.owner_id.valid}
                shouldValidate={true}
                addType="owner_id"
                entity={formControl.owner_id.entity}
                entityName="name"
                updateData={(id, arr, type) => setState({ formControl: updateData(state.formControl,id, arr, type), tch: false })}
                unLink={(type) => setState({ formControl: unLink(state.formControl, type), tch: false})}
                disabled={state.user}
            />

            <div className="connection"><span>Исполнители</span><div className="toggleparrent"><button onClick={() => setState({ showAddUser: true })} className="btni">+</button></div></div>
            {state.showAddUser ? <AutoSelect
                className="taskteam"
                placeholder="Добавить в команду"
                link={'/ajax/settings/user?filter[name][like]='}
                shouldValidate={false}
                valid={true}
                minLength="0"
                result="name"
                setField={()=>{}}
                updateData={addOwners}
            /> : null}
            {
                state.owners.map((item, index) =>
                    <ul className="ownersMap">
                        <li>{item.name} <a onClick={() => deleteOwners(item.id)}>x</a></li>
                    </ul>
                )
            }

            <div className="connection"><span>Файлы</span></div>

            <div className="contact-page sp-table" style={{ marginTop: 0 }}>
                <table>
                    <thead></thead>
                    <tbody>
                    {
                        (state.filesList) ? state.filesList.map((item, index) =>
                            <FilesItem linkDownload={`/ajax/file/download/${item.id}`} index={index} key={index} del={delFile} id={item.id} type={item.type} img={item.img} title={item.name} createDate={item.created_at}/>
                        ) : null
                    }
                    </tbody>
                </table>
            </div>
            <div className="task-dropzone">
                <FileUpload files={files} delFile={delFile} />
            </div>
        </div>
            </div>
        </CSSTransition>
    </div>
}

export default withRouter(withGlobalState(AddTask));

const endpoint = '/ajax/file/upload?related_type=5';

class FileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFile: null,
            loaded: 0,
            componentConfig: {
                iconFiletypes: ['.jpg', '.png', '.gif', '.psd', '.rar', '.xlsx'],
                showFiletypeIcon: true,
                postUrl: endpoint,
            },
            //eventHandlers: { 'addedfile': (file) => { console.log(file.xhr) } }
        }
    }
    handleselectedFile = event => {
        this.setState({
            selectedFile: event.target.files[0],
            loaded: 0,
        })
    };
    handleUpload = e => {
        e.preventDefault();
        if(this.state.selectedFile !== null) {
            const data = new FormData();
            data.append('UploadForm[file]', this.state.selectedFile, this.state.selectedFile.name);
            myaxios.post(endpoint + `?related_type=5`, data, {
                onUploadProgress: ProgressEvent => {
                    this.setState({loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100, selectedFile: null
                    })}}).then(res => {
                if(res.data.status === "uploaded") {
                    let arr = {id: res.data.id, name: res.data.name};
                    setTimeout(this.props.files(arr), 200);
                    Notif("save", "Файл успешно загружен");
                }
                if(res.data.status === "error") {
                    Notif("Error", res.data.errors.file[0])
                }
            })}};
    render() {
        var aaa = this.props.delFile;
        return (
            <div className="previewComponent mailDropzone">
                <GlobalState.Consumer>
                    {context => (
                        <DropzoneComponent config={this.state.componentConfig}
                                           djsConfig={
                                               {
                                                   //clickable: false,
                                                   uploadMultiple: false,
                                                   paramName: 'UploadForm[file]',
                                                   dictDefaultMessage: "Перетащите файлы сюда",
                                                   dictFileTooBig: `Размер файла {{filesize}}Мб выше допустимого {{maxFilesize}}Мб`,
                                                   dictInvalidFileType: "Недопустимый тип файла",
                                                   dictUploadCanceled: "Вы отменили загрузку",
                                                   acceptedFiles: parseStr(context.state.allowed_file_extensions),
                                                   maxFilesize: (context.state.allowed_file_max_size / 1024)  / 1024,
                                                   addRemoveLinks: true,
                                                   init: function(){
                                                       this.on("removedfile", function(file) {
                                                           if(file.xhr){
                                                               let a = JSON.parse(file.xhr.response);
                                                               myaxios.delete(`/ajax/file/delete/${a.id}?related_type=10`).then(res => {})
                                                           }
                                                       })
                                                       this.on("removedfile", (file) => {
                                                           if(file.xhr){
                                                               if(file.xhr.response){
                                                                   setTimeout(() => aaa(JSON.parse(file.xhr.response).id), 100)
                                                               }
                                                           }
                                                       })
                                                   },
                                                   success: (file) => {
                                                       if(file.xhr.response){
                                                           setTimeout(this.props.files({id: JSON.parse(file.xhr.response).id, name: JSON.parse(file.xhr.response).name}), 100)
                                                       }
                                                   },
                                               }
                                           }
                                           eventHandlers={this.state.eventHandlers}
                        />
                    )}
                </GlobalState.Consumer>
            </div>
        )
    }
}

function parseStr(string) {
    let a = string.split(", ");
    return "." + a.join(", .")
}


const EditTags = props => {
    const [state, setMyState] = useState({
        formControl: {
            to: {
                value: [],
                selectvalid: true,
                arr: [],
            },
        },
        tch: false,
        loading: false
    })


    const setState = (st, func) => {
        Object.keys(st).map((key) => { state[key] = st[key]});
        setMyState(prevState => { return {...prevState, ...state} });
        if(func) func();
    };

    useEffect(() => {
        const formControl = state.formControl;
        if(props.tags){
            formControl.to.arr = props.tags.split(',');
        }
        setState({ formControl });
    }, []);

    useEffect(() => {addTag()}, [state])

    const addTag = (event, id, tag) => {
        let a = state.formControl.to.arr.join(',');
        setTimeout(props.refreshState(a))
    };
    const setField = (event, type) => {
        const formControl = {  ...state.formControl };
        const control = { ...formControl[type] };
        control.value = event.target.value;
        formControl[type] = control;
        setState({
            formControl, tch: false
        })
    };
    const updateDataTo = (id, arr) => {
        if(arr.name === ''){
            return false;
        }
        const formControl = state.formControl;
        let tag = true;
        for(let i = 0; i <= state.formControl.to.arr.length; i++){
            if(arr.name) {
                if(state.formControl.to.arr[i] === arr.name){
                    tag = false;
                }
            } else {
                if(state.formControl.to.arr[i] === arr){
                    tag = false;
                }
            }
        }
        if(tag){
            (arr.name) ? formControl.to.arr.push(arr.name) : formControl.to.arr.push(arr)

        } else if(!tag) {
            Notif("Error", "Тег уже добавлен")
        }
        formControl.to.value = '';
        setState({ formControl, val: '' })
    };
    const deleteItem = id => {
        const formControl = state.formControl;
        formControl.to.arr.splice(id, 1);
        setState({ formControl })
    };
    return (state.loading) ? <Loader contain /> : <div className="sbform create-activ">
        <div className="clear" />
        <label htmlFor="">Редактировать теги</label>
        <AutoSelect
            label={"Кому"}
            link={`/ajax/${props.type}/suggest?term=`}
            minLength="0"
            result="name"
            event="to"
            //errorMessage={"Введите корректный Email"}
            value={state.formControl.to.value}
            shouldValidate={true}
            setField={event => setField(event, "to")}
            valid={true}
            updateData={updateDataTo}
            deleteItem={deleteItem}
            createTag
            multiSelect={state.formControl.to.arr}
            multiSelectValid={true}
            itemType={`item`}
        />
    </div>
};
