const Components = {
    sendMessage: {
        title:'Отправить сообщение',
        type: 'message',
        formControl: {
            name: {
                label: 'Название',
                value:'',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'input',
                type:'text',
                isEmoji: false,
                isPaste: false,
                parseType: false,
                invest: null
            },
            value: {
                label: 'Сообщение',
                value:'',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'textarea',
                type:'text',
                isEmoji: true,
                isPaste: true,
                parseType: 'standart',
                invest:'meta_data'
            },
            type: {value:'text',invest:'meta_data'},
            conditions_met: {
                label: 'Что делать, если значения в фильтре не совпали',
                value:'continue',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'select',
                options:[{value:'continue', label:'Продолжить выполнение'}, {value:'stop', label:'Остановить выполнение'}],
                type:'text',
                empty: true,
                parseType: 'standart',
                invest:null
            },
        },
        post: {
            link:'/ajax/bot/task',
            data:['name', 'value', 'type']
        },
        get: {
            link:'/ajax/bot/task',
            data:[{name:'name', to:'value'}, {name:'value', to: 'value', invest:'meta_data'}]
        },
    },
    send_mail: {
        title:'Отправить Email',
        type: 'send_mail',
        formControl: {
            name: {
                label: 'Название',
                value:'',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'input',
                type:'text',
                isEmoji: false,
                isPaste: false,
                parseType: false,
                invest: null
            },
            account_id: {
                label: 'Почтовый ящик (от)',
                value:'continue',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'select',
                options:[],
                type:'text',
                empty: true,
                parseType: 'standart',
                invest: 'meta_data',
                val:'id',
                lab:'username'
            },
            to: {
                label: 'Кому',
                value:'',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'input',
                type:'text',
                isEmoji: false,
                isPaste: true,
                parseType: 'standart',
                invest: 'meta_data',
            },
            subject: {value:'text',invest:'meta_data'},
            value: {value:'text',invest:'meta_data'},
            body: {value:'text',invest:'meta_data'},
            template: {
                label: 'Шаблон',
                value:'',
                options:[],
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'select',
                type:'text',
                isEmoji: false,
                isPaste: false,
                parseType: false,
                empty: true,
                invest: 'meta_data',
                val:"id",
                lab:"name",
                tooltip: 'Шаблон вы можете создать в разделе Настройки -> Шаблоны писем'
            },
            type: {value:'text',invest:'meta_data'},
            conditions_met: {
                label: 'Что делать, если значения в фильтре не совпали',
                value:'continue',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'select',
                options:[{value:'continue', label:'Продолжить выполнение'}, {value:'stop', label:'Остановить выполнение'}],
                type:'text',
                empty: true,
                parseType: 'standart',
                invest:null
            },
        },
        post: {
            link:'/ajax/bot/task',
            data:['name', 'value', 'type']
        },
        get: {
            link:'/ajax/bot/task',
            data:[
                {name:'name', to:'value'},
                {name:'value', to: 'value', invest:'meta_data'},
                {name:'template', to: 'value', invest:'meta_data'},
                {name:'subject', to: 'value', invest:'meta_data'},
                {name:'to', to: 'value', invest:'meta_data'},
                {name:'account_id', to: 'value', invest:'meta_data'},
            ]
        },
        prepare: {
            link:'/ajax/template',
            options:'template'
        }
    },
    question: {
        title:'Вопрос',
        type: 'question',
        formControl: {
            name: {
                label: 'Название',
                value:'',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'input',
                type:'text',
                isEmoji: false,
                isPaste: false,
                parseType: false,
                invest: null
            },
            type: {
                label: 'Тип вопроса',
                value:'',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'select',
                options:[{value:'text', label:'Текст'}, {value:'choice', label:'Выбор'}],
                type:'text',
                empty: true,
                parseType: 'standart',
                invest:'meta_data'
            },
            question: {
                label: 'Вопрос',
                value:'',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'textarea',
                type:'text',
                isEmoji: true,
                isPaste: true,
                parseType: 'standart',
                invest:'meta_data'
            },
            invalid_answers_limit: {
                label: 'К-во неправильных ответов',
                value:5,
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'input',
                type:'number',
                invest:'meta_data',
                noClear: true
            },
            expires_after: {
                label: 'Время ожидания ответа (минут)',
                value:5,
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'input',
                type:'number',
                invest:'meta_data',
                noClear: true
            },
            validation: {
                label: 'Валидация введенного значения',
                value:'continue',
                valid: true,
                validation: {required: false},
                errorMessage:'Поле обязательно для заполнения',
                field: 'select',
                options:[{value:'NULL', label:'Без валидации'}, {value:'email', label:'Валидация на Email'}],
                type:'text',
                empty: true,
                parseType: 'standart',
                invest:'meta_data'
            },
            conditions_met: {
                label: 'Что делать, если значения в фильтре не совпали',
                value:'continue',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'select',
                options:[{value:'continue', label:'Продолжить выполнение'}, {value:'stop', label:'Остановить выполнение'}],
                type:'text',
                empty: true,
                parseType: 'standart',
                invest:null
            },
        },
        post: {
            link:'/ajax/bot/task',
            data:['name', 'question', 'type']
        },
        get: {
            link:'/ajax/bot/task',
            data:[
                {name:'name', to:'value'},
                {name:'question', to: 'value', invest:'meta_data'},
                {name:'type', to:'value', invest:'meta_data'},
                {name:'invalid_answers_limit', to:'value', invest:'meta_data'},
                {name:'expires_after', to:'value', invest:'meta_data'},
                {name:'validation', to:'value', invest:'meta_data'},
            ]
        },
    },
    picture: {
        title:'Отправить картинку',
        type: 'picture',
        formControl: {
            name: {
                label: 'Название',
                value:'',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'input',
                type:'text',
                isEmoji: false,
                isPaste: false,
                parseType: false,
                invest: null
            },
            media: {
                label: 'URL картинки',
                value:'',
                valid: true,
                validation: {required: true, website: true},
                errorMessage:'Введите корректный URL',
                field: 'input',
                type:'url',
                isEmoji: false,
                isPaste: false,
                parseType: 'standart',
                invest:'meta_data'
            },
            type: {value:'picture',invest:'meta_data'},
            value: {
                label: 'Подпись',
                value:'',
                valid: true,
                validation: {required: false},
                errorMessage:'Поле обязательно для заполнения',
                field: 'input',
                type:'text',
                isEmoji: false,
                isPaste: false,
                parseType: false,
                invest:'meta_data'
            },
            conditions_met: {
                label: 'Что делать, если значения в фильтре не совпали',
                value:'continue',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'select',
                options:[{value:'continue', label:'Продолжить выполнение'}, {value:'stop', label:'Остановить выполнение'}],
                type:'text',
                empty: true,
                parseType: 'standart',
                invest:null
            },
        },
        post: {
            link:'/ajax/bot/task',
            data:['name', 'media', 'type', 'value']
        },
        get: {
            link:'/ajax/bot/task',
            data:[{name:'name', to:'value'}, {name:'media', to: 'value', invest:'meta_data'}]
        },
    },
    delay: {
        title:'Задержка',
        type: 'delay',
        formControl: {
            name: {
                label: 'Название',
                value:'',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'input',
                type:'text',
                isEmoji: false,
                isPaste: false,
                parseType: false,
                invest: null
            },
            time_value: {
                label: 'Задержка (в секундах)',
                value:'',
                valid: true,
                validation: {required: true, number: true},
                errorMessage:'Заполните обязательное поле',
                field: 'input',
                type:'number',
                isEmoji: false,
                isPaste: false,
                parseType: 'standart',
                //invest:'meta_data'
            },
            conditions_met: {
                label: 'Что делать, если значения в фильтре не совпали',
                value:'continue',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'select',
                options:[{value:'continue', label:'Продолжить выполнение'}, {value:'stop', label:'Остановить выполнение'}],
                type:'text',
                empty: true,
                parseType: 'standart',
                invest:null
            },
        },
        post: {
            link:'/ajax/bot/task',
            data:['name', 'time_value', 'type']
        },
        get: {
            link:'/ajax/bot/task',
            data:[{name:'name', to:'value'}, {name:'time_value', to: 'value'}]
        },
    },
    rich_media: {
        title:'Отправить карточку',
        type: 'rich_media',
        formControl: {
            name: {
                label: 'Название',
                value:'',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'input',
                type:'text',
                isEmoji: false,
                isPaste: false,
                parseType: false,
                invest: null
            },
            type: {value:'rich_media',invest:'meta_data',field: 'hidden'},
            value: {value:'',invest:'meta_data',field: 'hidden'},
            title: {
                label: 'Title',
                value:'',
                valid: true,
                validation: {required: true},
                errorMessage:'Заполните обязательное поле',
                field: 'input',
                type:'text',
                isEmoji: false,
                isPaste: true,
                parseType: 'standart',
                invest:'meta_data'
            },
            text: {
                label: 'Текст',
                value:'',
                valid: true,
                validation: {required: true},
                errorMessage:'Заполните обязательное поле',
                field: 'textarea',
                type:'number',
                isEmoji: true,
                isPaste: true,
                parseType: 'standart',
                invest:'meta_data'
            },
            image_url: {
                label: 'Ссылка для картинки',
                value:'',
                valid: true,
                validation: {required: true, website: true},
                errorMessage:'Заполните обязательное поле',
                field: 'input',
                type:'number',
                isEmoji: false,
                isPaste: false,
                parseType: 'standart',
                invest:'meta_data'
            },
            button_link: {
                label: 'Ссылка для кнопки',
                value:'',
                valid: true,
                validation: {required: true, website: true},
                errorMessage:'Заполните обязательное поле',
                field: 'input',
                type:'number',
                isEmoji: false,
                isPaste: true,
                parseType: 'standart',
                invest:'meta_data'
            },
            button_text: {
                label: 'Текст кнопки',
                value:'',
                valid: true,
                validation: {required: true},
                errorMessage:'Заполните обязательное поле',
                field: 'input',
                type:'number',
                isEmoji: false,
                isPaste: false,
                parseType: 'standart',
                invest:'meta_data'
            },
            conditions_met: {
                label: 'Что делать, если значения в фильтре не совпали',
                value:'continue',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'select',
                options:[{value:'continue', label:'Продолжить выполнение'}, {value:'stop', label:'Остановить выполнение'}],
                type:'text',
                empty: true,
                parseType: 'standart',
                invest:null
            },
        },
        post: {
            link:'/ajax/bot/task',
            data:['name', 'rich_media', 'type']
        },
        get: {
            link:'/ajax/bot/task',
            data:[
                {name:'name', to:'value'},
                {name:'type', to: 'value', invest:'meta_data'},
                {name:'image_url', to: 'value', invest:'meta_data'},
                {name:'title', to: 'value', invest:'meta_data'},
                {name:'text', to: 'value', invest:'meta_data'},
                {name:'button_link', to: 'value', invest:'meta_data'},
                {name:'button_text', to: 'value', invest:'meta_data'}
            ]
        },
    },
    invoice_payment: {
        title:'Платеж',
        type: 'invoice_payment',
        formControl: {
            name: {
                label: 'Название',
                value:'',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'input',
                type:'text',
                isEmoji: false,
                isPaste: false,
                parseType: false,
                invest: null
            },
            type: {value:'invoice_payment',invest:'meta_data',field: 'hidden'},
            value: {
                label: 'Сумма оплаты',
                value:'',
                valid: true,
                validation: {required: true},
                errorMessage:'Заполните обязательное поле',
                field: 'input',
                type:'number',
                isEmoji: false,
                isPaste: false,
                parseType: 'standart',
                invest:'meta_data'
            },
            currency_id: {
                label: 'Валюта',
                value:'',
                options:[],
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'select',
                type:'text',
                isEmoji: false,
                isPaste: false,
                parseType: false,
                empty: true,
                invest: 'meta_data',
                val:"id",
                lab:"name",
                tooltip: 'Шаблон вы можете создать в разделе Настройки -> Шаблоны писем'
            },
            title: {
                label: 'Title',
                value:'',
                valid: true,
                validation: {required: true},
                errorMessage:'Заполните обязательное поле',
                field: 'input',
                type:'text',
                isEmoji: false,
                isPaste: false,
                parseType: 'standart',
                invest:'meta_data'
            },
            text: {
                label: 'Текст',
                value:'',
                valid: true,
                validation: {required: true},
                errorMessage:'Заполните обязательное поле',
                field: 'textarea',
                type:'number',
                isEmoji: true,
                isPaste: true,
                parseType: 'standart',
                invest:'meta_data'
            },
            image_url: {
                label: 'Ссылка для картинки',
                value:'',
                valid: true,
                validation: {required: true, website: true},
                errorMessage:'Заполните обязательное поле',
                field: 'input',
                type:'number',
                isEmoji: false,
                isPaste: false,
                parseType: 'standart',
                invest:'meta_data'
            },
            button_link: {
                label: 'Ссылка для кнопки',
                value:'',
                valid: true,
                validation: {required: true, website: true},
                errorMessage:'Заполните обязательное поле',
                field: 'input',
                type:'number',
                isEmoji: false,
                isPaste: false,
                parseType: 'standart',
                invest:'meta_data'
            },
            button_text: {
                label: 'Текст кнопки',
                value:'',
                valid: true,
                validation: {required: true},
                errorMessage:'Заполните обязательное поле',
                field: 'input',
                type:'number',
                isEmoji: false,
                isPaste: false,
                parseType: 'standart',
                invest:'meta_data'
            },
            conditions_met: {
                label: 'Что делать, если значения в фильтре не совпали',
                value:'continue',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'select',
                options:[{value:'continue', label:'Продолжить выполнение'}, {value:'stop', label:'Остановить выполнение'}],
                type:'text',
                empty: true,
                parseType: 'standart',
                invest:null
            },
        },
        post: {
            link:'/ajax/bot/task',
            data:['name', 'rich_media', 'type']
        },
        get: {
            link:'/ajax/bot/task',
            data:[
                {name:'name', to:'value'},
                {name:'value', to:'value', invest:'meta_data'},
                {name:'type', to: 'value', invest:'meta_data'},
                {name:'image_url', to: 'value', invest:'meta_data'},
                {name:'title', to: 'value', invest:'meta_data'},
                {name:'text', to: 'value', invest:'meta_data'},
                {name:'button_link', to: 'value', invest:'meta_data'},
                {name:'button_text', to: 'value', invest:'meta_data'},
                {name:'currency_id', to: 'value', invest:'meta_data'},
            ]
        },
        prepare: {
            link:'/ajax/lookup/search?type=currency',
            options:'currency_id'
        }
    },
    trigger_flow: {
        title:'Переключить тонель',
        type: 'trigger_flow',
        formControl: {
            name: {
                label: 'Название',
                value:'',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'input',
                type:'text',
                isEmoji: false,
                isPaste: false,
                parseType: false,
                invest: null
            },
            type: {value:'trigger_flow',invest:'meta_data',field: 'hidden'},
            value: {
                label: 'Текст триггера',
                value:'',
                valid: true,
                validation: {required: true},
                errorMessage:'Заполните обязательное поле',
                field: 'input',
                type:'text',
                isEmoji: false,
                isPaste: false,
                parseType: 'standart',
                invest:'meta_data'
            },
            conditions_met: {
                label: 'Что делать, если значения в фильтре не совпали',
                value:'continue',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'select',
                options:[{value:'continue', label:'Продолжить выполнение'}, {value:'stop', label:'Остановить выполнение'}],
                type:'text',
                empty: true,
                parseType: 'standart',
                invest:null
            },
        },
        post: {
            link:'/ajax/bot/task',
            data:['name', 'rich_media', 'type']
        },
        get: {
            link:'/ajax/bot/task',
            data:[
                {name:'name', to:'value'},
                {name:'type', to: 'value', invest:'meta_data'},
                {name:'value', to: 'value', invest:'meta_data'},
            ]
        },
    },
    start_live_chat: {
        title:'Начать чат',
        type: 'start_live_chat',
        formControl: {
            name: {
                label: 'Название',
                value:'',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'input',
                type:'text',
                isEmoji: false,
                isPaste: false,
                parseType: false,
                invest: null
            },
            type: {value:'start_live_chat',invest:'meta_data',field: 'hidden'},
            value: {
                label: 'Текст для начала чата',
                value:'',
                valid: true,
                validation: {required: true},
                errorMessage:'Заполните обязательное поле',
                field: 'input',
                type:'text',
                isEmoji: false,
                isPaste: false,
                parseType: 'standart',
                invest:'meta_data'
            },
            conditions_met: {
                label: 'Что делать, если значения в фильтре не совпали',
                value:'continue',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'select',
                options:[{value:'continue', label:'Продолжить выполнение'}, {value:'stop', label:'Остановить выполнение'}],
                type:'text',
                empty: true,
                parseType: 'standart',
                invest:null
            },
        },
        post: {
            link:'/ajax/bot/task',
            data:['name', 'rich_media', 'type']
        },
        get: {
            link:'/ajax/bot/task',
            data:[
                {name:'name', to:'value'},
                {name:'type', to: 'value', invest:'meta_data'},
                {name:'value', to: 'value', invest:'meta_data'},
            ]
        },
    },
    stop_live_chat: {
        title:'Завершить чат',
        type: 'stop_live_chat',
        formControl: {
            name: {
                label: 'Название',
                value:'',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'input',
                type:'text',
                isEmoji: false,
                isPaste: false,
                parseType: false,
                invest: null
            },
            conditions_met: {
                label: 'Что делать, если значения в фильтре не совпали',
                value:'continue',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'select',
                options:[{value:'continue', label:'Продолжить выполнение'}, {value:'stop', label:'Остановить выполнение'}],
                type:'text',
                empty: true,
                parseType: 'standart',
                invest:null
            },
        },
        post: {
            link:'/ajax/bot/task',
            data:['name', 'rich_media', 'type']
        },
        get: {
            link:'/ajax/bot/task',
            data:[
                {name:'name', to:'value'},
            ]
        },
    },
    attributes: {
        title:'Сохранить поле',
        type: 'set_attribute',
        formControl: {
            name: {
                label: 'Название',
                value:'',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'input',
                type:'text',
                isEmoji: false,
                isPaste: false,
                parseType: false,
                invest: null
            },
            conditions_met: {
                label: 'Что делать, если значения в фильтре не совпали',
                value:'continue',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'select',
                options:[{value:'continue', label:'Продолжить выполнение'}, {value:'stop', label:'Остановить выполнение'}],
                type:'text',
                empty: true,
                parseType: 'standart',
                invest:null
            },
            type: {value:'set_attribute',invest:'meta_data',field: 'hidden'},
        },
        post: {
            link:'/ajax/bot/task',
            data:['name', 'rich_media', 'type']
        },
        get: {
            link:'/ajax/bot/task',
            data:[
                {name:'name', to:'value'},
            ]
        },
    },
    sendMessageTrigger: {
        title:'Входящее сообщение (триггер)',
        type: 'message',
        trigger:true,
        formControl: {
            use_shortcuts: {value:'false',invest:'meta_data',field: 'hidden'},
            additional_welcome_message: {
                label: 'Приветственное сообщение',
                value:'',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'textarea',
                type:'text',
                isEmoji: false,
                isPaste: false,
                parseType: false,
                invest: 'meta_data'
            },
            value: {
                label: 'Список триггерных фраз (по одной в строке)',
                value:'',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'textarea',
                type:'text',
                isEmoji: false,
                isPaste: false,
                parseType: false,
                invest: 'meta_data'
            },
            conditions_met: {
                label: 'Что делать, если значения в фильтре не совпали',
                value:'continue',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'select',
                options:[{value:'continue', label:'Продолжить выполнение'}, {value:'stop', label:'Остановить выполнение'}],
                type:'text',
                empty: true,
                parseType: 'standart',
                invest:null
            },
        },
        post: {
            link:'/ajax/bot/trigger',
            data:['name', 'rich_media', 'type']
        },
        get: {
            link:'/ajax/bot/trigger',
            data:[
                {name:'use_shortcuts', to:'value'},
                {name:'additional_welcome_message', to:'value', invest:'meta_data'},
                {name:'value', to:'value', invest:'meta_data'},
            ]
        },
    },
    broadcast: {
        title:'Рассылка (триггер)',
        type: 'broadcast',
        trigger:true,
        formControl: {
            use_shortcuts: {value:'false',invest:'meta_data',field: 'hidden'},
            value: {
                label: 'Название',
                value:'',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'input',
                type:'text',
                isEmoji: false,
                isPaste: false,
                parseType: false,
                invest: 'meta_data'
            },
        },
        post: {
            link:'/ajax/bot/trigger',
            data:['value']
        },
/*        get: {
            link:'/ajax/bot/trigger',
            data:[
                {name:'use_shortcuts', to:'value'},
                {name:'additional_welcome_message', to:'value', invest:'meta_data'},
                {name:'value', to:'value', invest:'meta_data'},
            ]
        },*/
    },
    payment: {
        title:'При успешной оплате',
        type: 'payment',
        trigger:true,
        formControl: {
            name: {
                label: 'Название',
                value:'',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'input',
                type:'text',
                isEmoji: false,
                isPaste: false,
                parseType: false,
                invest: null
            },
            type: {value:'payment',invest:'meta_data',field: 'hidden'},
            value: {
                label: 'SLUG оплаты',
                value:'',
                valid: true,
                validation: {required: true},
                errorMessage:'Заполните обязательное поле',
                field: 'input',
                type:'text',
                isEmoji: false,
                isPaste: false,
                parseType: 'standart',
                invest:'meta_data'
            },
            conditions_met: {
                label: 'Что делать, если значения в фильтре не совпали',
                value:'continue',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'select',
                options:[{value:'continue', label:'Продолжить выполнение'}, {value:'stop', label:'Остановить выполнение'}],
                type:'text',
                empty: true,
                parseType: 'standart',
                invest:null
            },
        },
        post: {
            link:'/ajax/bot/trigger',
            data:['name', 'value']
        },
        get: {
            link:'/ajax/bot/trigger',
            data:[
                {name:'name', to:'value'},
                {name:'type', to: 'value', invest:'meta_data'},
                {name:'value', to: 'value', invest:'meta_data'},
            ]
        },
    },
    cachAllTrigger: {
        title:'Триггер на любое действие',
        type: "catch-all",
        trigger:true,
        formControl: {
            //use_shortcuts: {value:'false',invest:'meta_data',field: 'hidden'},
            value: {
                label: 'Список триггерных фраз (по одной в строке)',
                value:'',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'textarea',
                type:'text',
                isEmoji: false,
                isPaste: false,
                parseType: false,
                invest: 'meta_data'
            },
            conditions_met: {
                label: 'Что делать, если значения в фильтре не совпали',
                value:'continue',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'select',
                options:[{value:'continue', label:'Продолжить выполнение'}, {value:'stop', label:'Остановить выполнение'}],
                type:'text',
                empty: true,
                parseType: 'standart',
                invest:null
            },
        },
        post: {
            link:'/ajax/bot/trigger',
            data:['name', 'rich_media', 'type']
        },
        get: {
            link:'/ajax/bot/trigger',
            data:[
                {name:'value', to:'value', invest:'meta_data'},
            ]
        },
    },
    newFlowTrigger: {
        title:'Входящее сообщение (триггер)',
        type: "trigger_flow",
        trigger:true,
        formControl: {
            //use_shortcuts: {value:'false',invest:'meta_data',field: 'hidden'},
            value: {
                label: 'Список триггерных фраз (по одной в строке)',
                value:'',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'textarea',
                type:'text',
                isEmoji: false,
                isPaste: false,
                parseType: false,
                invest: 'meta_data'
            },
            conditions_met: {
                label: 'Что делать, если значения в фильтре не совпали',
                value:'continue',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'select',
                options:[{value:'continue', label:'Продолжить выполнение'}, {value:'stop', label:'Остановить выполнение'}],
                type:'text',
                empty: true,
                parseType: 'standart',
                invest:null
            },
        },
        post: {
            link:'/ajax/bot/trigger',
            data:['name', 'rich_media', 'type']
        },
        get: {
            link:'/ajax/bot/trigger',
            data:[
                {name:'value', to:'value', invest:'meta_data'},
            ]
        },
    },
    InsertWebHook: {
        title:'Входящее сообщение (триггер)',
        type: "trigger_flow",
        trigger:true,
        formControl: {
            //use_shortcuts: {value:'false',invest:'meta_data',field: 'hidden'},
            uri: {
                label: 'Список триггерных фраз (по одной в строке)',
                value:'',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: null,
                type:'text',
                isEmoji: false,
                isPaste: false,
                parseType: false,
                invest: 'meta_data'
            },
            /*conditions_met: {
                label: 'Что делать, если значения в фильтре не совпали',
                value:'continue',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'select',
                options:[{value:'continue', label:'Продолжить выполнение'}, {value:'stop', label:'Остановить выполнение'}],
                type:'text',
                empty: true,
                parseType: 'standart',
                invest:null
            },*/
        },
        post: {
            link:'/ajax/bot/trigger',
            data:['name', 'rich_media', 'type']
        },
        get: {
            link:'/ajax/bot/trigger',
            data:[
                {name:'uri', to:'value', invest:'meta_data'},
            ]
        },
    },
    SendRequest: {
        title:'Отправить HTTP запрос',
        type: "http_request",
        trigger:false,
        formControl: {
            //use_shortcuts: {value:'false',invest:'meta_data',field: 'hidden'},
            name: {
                label: 'Название',
                value:'',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'input',
                type:'text',
                isEmoji: false,
                isPaste: false,
                parseType: false,
                invest: null
            },
            uri: {
                label: 'Request URL',
                value:'',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'input',
                type:'text',
                isEmoji: false,
                isPaste: false,
                parseType: false,
                invest: 'meta_data'
            },
            method: {
                label: 'Метод',
                value:'continue',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'select',
                options:[
                    {value:'GET', label:'GET'},
                    {value:'POST', label:'POST'},
                    {value:'PUT', label:'PUT'},
                    {value:'PATCH', label:'PATCH'},
                    {value:'DELETE', label:'DELETE'},
                    ],
                type:'text',
                empty: true,
                parseType: 'standart',
                invest: 'meta_data'
            },
            content_type: {
                label: 'Тип данных',
                value:'continue',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'select',
                options:[
                    {value:'none', label:'Нет'},
                    {value:'form', label:'FORM'},
                    {value:'json', label:'JSON'},
                    {value:'raw_body', label:'Raw Body'},
                    ],
                type:'text',
                empty: true,
                parseType: 'standart',
                invest: 'meta_data'
            },
            conditions_met: {
                label: 'Что делать, если значения в фильтре не совпали',
                value:'continue',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'select',
                options:[{value:'continue', label:'Продолжить выполнение'}, {value:'stop', label:'Остановить выполнение'}],
                type:'text',
                empty: true,
                parseType: 'standart',
                invest: 'meta_data'
            },
        },
        post: {
            link:'/ajax/bot/task',
            data:['name', 'rich_media', 'type']
        },
        get: {
            link:'/ajax/bot/task',
            data:[
                {name:'name', to:'value'},
                {name:'uri', to:'value', invest:'meta_data'},
                {name:'method', to:'value', invest:'meta_data'},
                {name:'content_type', to:'value', invest:'meta_data'},
                {name:'conditions_met', to:'value', invest:'meta_data'},
            ]
        },
    },
    send_sms: {
        title: 'Отправить SMS',
        type: 'send_sms',
        formControl: {
            name: {
                label: 'Название',
                value: '',
                valid: true,
                validation: {required: true},
                errorMessage: 'Поле обязательно для заполнения',
                field: 'input',
                type: 'text',
                isEmoji: false,
                isPaste: false,
                parseType: false,
                invest: null
            },
            to: {
                label: 'Кому',
                value: '',
                valid: true,
                validation: {required: true},
                errorMessage: 'Поле обязательно для заполнения',
                field: 'input',
                type: 'text',
                isEmoji: false,
                isPaste: true,
                parseType: 'standart',
                invest: 'meta_data',
            },
            body: {
                label: 'Текст сообщения',
                value: '',
                valid: true,
                validation: {required: true},
                errorMessage: 'Поле обязательно для заполнения',
                field: 'textarea',
                type: 'text',
                isEmoji: false,
                isPaste: true,
                parseType: 'standart',
                invest: 'meta_data',
            },
            /*conditions_met: {
                label: 'Что делать, если значения в фильтре не совпали',
                value: 'continue',
                valid: true,
                validation: {required: true},
                errorMessage: 'Поле обязательно для заполнения',
                field: 'select',
                options: [{value: 'continue', label: 'Продолжить выполнение'}, {
                    value: 'stop',
                    label: 'Остановить выполнение'
                }],
                type: 'text',
                empty: true,
                parseType: 'standart',
                invest: 'meta_data'
            },*/
        },
        post: {
            link:'/ajax/bot/task',
            data:['name', 'rich_media', 'type']
        },
        get: {
            link:'/ajax/bot/task',
            data:[
                {name:'name', to:'value'},
                {name:'to', to:'value', invest:'meta_data'},
                {name:'body', to:'value', invest:'meta_data'},
            ]
        },
    }
}

export default Components
