import React, {useContext, useEffect, useState} from "react";
import myaxios from "../../components/Axios";
import {unLink, updateData, filesFunc} from "../../components/fieldGen";
import Notif from "../../components/Notif";
import Loader from "../../components/UI/Loader";
import History from "../../components/history/history";
import Related from "../../components/related/related";
import Files from "../../components/files/files";
import Tags from '../../components/Tags';
import {withRouter} from "react-router-dom";
import EditTags from "../../Modal/EditTags";
import Bgdrop from "../../Modal/Bgdrop";
import {GlobalState} from "../../Layout/Layout";
import {ATTACHED_NUMBER_TO_STRING, TASK_PRIORITY} from "../../const";
import Input from "../../components/UI/Input";
import Myselect from "../../components/UI/Select";
import ModAutoSelect from "../../components/UI/ModAutoSelect";
import AutoSelect from "../../components/UI/AutoSelect";
import formValid from "../../components/UI/formValid";
import MainEditor from "../../components/UI/Editor";
import DateTime from "../../components/UI/DateTime";
import SoundClick from "../../components/soundClick";
import {CSSTransition} from "react-transition-group";
import LeaveModal from "../../components/other/LeaveModal";
import IconButton from "../../components/other/IconButton";
import {TaskModal} from "./TaskModal";

export const TaskView = ({ history, match }) => {
    return (
        <TaskModal
            close={() => history.push('/task')}
            id={match.params.id}
            isOpen={true}
            task={(type) => type === 'delete' && history.push('/task')}
            taskView={true}
        />
    )
}

const TaskView2 = props => {
    const context = useContext(GlobalState);
    const [state, setMyState] = useState({
        formControl:{
            subject: {
                value:'',
                valid:true,
                validation: {required: true}
            },
            description: {
                value:'',
                valid:true,
                validation: {required: false}
            },
            comment: {
                value:'',
                valid:true,
                validation: {required: false}
            },
            status_id: {
                value:3,
                valid:true,
                validation: {required: true}
            },
            start_date: {
                value: null,
                valid: true,
            },
            end_date: {
                value: null,
                valid: true,
            },
            remind_at: {
                value: null,
                valid: true,
            },
            priority: {
                value:'1',
                options:TASK_PRIORITY,
                valid:true,
            },
            type_id: {
                value:'',
                options:[],
                valid:true,
            },
            owner_id: {
                value:'',
                entity:null,
                valid:true,
            },

        },
        subtask_json:[],
        subtask_value:'',
        subtask_active:false,
        subtask_active_index:null,
        executors:[],
        executor: false,
        loading:true,
        files: [],
        filesList:[],
        related_id: '',
        related_type: '',
        related_name: "Лид №1",
        owners: [],
        user: false,
        user_id: null,
        load:false,
        showAddUser: false,
        editorTags:'',
        open: "",
        tch: true,
        layout: "information",
        total_time:0
    });
    const close = () => setState({ open:'', openType: '', taskId:'', taskIndex: '', teamId: null });

    const setState = (st, func) => {
        Object.keys(st).map((key) => { state[key] = st[key]});
        setMyState(prevState => { return {...prevState, ...state} });
        if(func) func();
    };

    const setField = (type, e) => {
        const formControl = state.formControl;
        formControl[type].value = e;
        setState({ formControl, tch: false })
    };

    useEffect(() => {
        prepare();
        getUser()
    }, [props.match.params.id]);


    const prepare = () => {
        myaxios.get(`/ajax/task/prepare`, {method: 'get',
        }).then((response) => {
            const formControl = state.formControl;
            formControl.type_id.options = response.data.types;
            formControl.type_id.value = response.data.types[0].id;
            setState({ formControl}, getData())
        })
    };

    const getUser = () => {
        if(!context.state.userId) {
            myaxios.get(`/ajax/profile`).then(res => {
                if(!res.data) return
                if(res.data.user){
                    setState({ user_id: res.data.user.id })
                }
            })
        } else {
            setState({ user_id: context.state.userId })
        }
    }


    const getData = () => {
    myaxios.get(`/ajax/task/${props.match.params.id}?expand=owner,types,connected,owners,files`).then(res => {
        const formControl = state.formControl;
        formControl.comment.value = res.data.comment || '';
        formControl.description.value = res.data.description || '';
        formControl.subject.value = res.data.subject || '';
        formControl.start_date.value = res.data.start_date || '';
        formControl.end_date.value = res.data.end_date || '';
        formControl.status_id.value = res.data.status_id || '';
        formControl.owner_id.value = res.data.owner_id || '';
        formControl.owner_id.entity = res.data.owner;
        formControl.remind_at.value = res.data.remind_at || '';
        formControl.type_id.value = res.data.type_id || '';
        formControl.priority.value = res.data.priority || '';
        setState({
            subtask_json: JSON.parse(res.data.subtask_json) || [],
            editorTags: res.data.editorTags,
            user: state.user_id !== Number(res.data.owner_id),
            related_id: res.data.connected ? res.data.connected.related_id : null,
            related_type: res.data.connected ? res.data.connected.related_type : null,
            related_name: res.data.connected ? res.data.connected.related_name : null,
            //total_time: new Date(res.data.total_time * 1000).toISOString().substr(11, 8)
        })
        setState({ filesList: res.data.files })
        setState({ owners: res.data.owners, loading: false })
        getTotalTime(res.data.begin_time, res.data.total_time, res.data.status_id);
    })}

    const getTotalTime = (begin_time, total_time, status_id) => {
        let time;
        if(status_id === 41){
            const result = (+new Date()) - (+new Date(begin_time));
            const resultTime = new Date(result);
            time = new Date(((resultTime.getTime() / 1000) + total_time) * 1000).toISOString().substr(11, 8)
        } else {
            time = new Date(total_time * 1000).toISOString().substr(11, 8)
        }
        setState({ total_time: time })
    }

    const dateValidation = () => {
        let startDate = Date.parse(state.formControl.start_date.value);
        let endDate = Date.parse(state.formControl.end_date.value);
        let valid = true;
        let now = new Date();
        if(endDate < startDate){
            valid = false;
            Notif("Error", "Дата начала не может быть позже даты завершения");
        }
        return valid;
    }

    const isFormValid = () => {
        let formControl = state.formControl;
        let isFormValid = true;
        Object.keys(formControl).map((key, index) => {
            formControl[key].valid = formValid(formControl[key].value, formControl[key].validation, formControl[key]);
            isFormValid = formControl[key].valid && isFormValid
        });
        setState({ formControl, isFormValid });
        return isFormValid;
    };

    const postObj = {
        comment: state.formControl.comment.value,
        description: state.formControl.description.value,
        subject: state.formControl.subject.value,
        start_date: state.formControl.start_date.value ? state.formControl.start_date.value.toLocaleString() : null,
        end_date: state.formControl.end_date.value ? state.formControl.end_date.value.toLocaleString() : null,
        remind_at: state.formControl.remind_at.value ? state.formControl.remind_at.value.toLocaleString() : null,
        status_id: state.formControl.status_id.value,
        owner_id: state.formControl.owner_id.value,
        type_id: state.formControl.type_id.value,
        priority: state.formControl.priority.value,
        subtask_json: JSON.stringify(state.subtask_json),
        related_id: state.related_id,
        related_type: state.related_type,
        files: state.files,
        editorTags: state.editorTags
    };

    const putData = async () => {
        if(dateValidation && isFormValid) {
            setState({ tch: true, loading: true })
            let files = [];
            for(let i = 0; i <= state.filesList.length - 1; i++){
                if(state.filesList[i]){
                    files.unshift(state.filesList[i].id)
                }
            }
            postObj.owners = sendOwners();
            postObj.files = files;
            await myaxios.put(`/ajax/task/${props.match.params.id}`, postObj).then(res => {
                if(res.status === 200 || res.status === 201 || res.status === 204){
                    Notif("save")
                }
                setState({ loading: false })
            }).catch(() => setState({ loading: false }))
        }
    };

    const addSubtask = (e) => {
        if(state.subtask_value.length >= 1){
            let subtask_json = state.subtask_json;
            subtask_json.push({text: state.subtask_value, status: false})
            setState({ subtask_json, tch: false, subtask_value:'' })
        }
    };
    const subTaskEdit = (type, index) => {
        let subtask_json = state.subtask_json;
        if(type === "setStatus"){
            subtask_json[index].status = !state.subtask_json[index].status;
        } else if(type === "delete"){
            delete subtask_json[index];
        }
        setState({ subtask_json, tch: false })
    };

    const deleteTask = event => {
        event.preventDefault();
        if(window.confirm("Удалить задачу?")) {
            myaxios.delete('/ajax/task/' + props.match.params.id).then(response => {
                if(response.status === 200 || response.status === 201 || response.status === 204) {
                    Notif("delete", "Задача успешно удалена");
                    props.history.push(`/task/list/`)
                }
            })
        }
    };

    const files = arr => {
        const filesList = state.filesList;
        filesList.unshift(arr);
        setState({filesList});
    };
    const delFile = id => {
        const filesList = state.filesList;
        if(id && filesList.length >= 1){
            for(let i = 0; i <= filesList.length; i++){
                if(filesList[i]){
                    if(filesList[i].id === id) {
                        delete filesList[i]
                    }
                }
            }
            setState({filesList});
        }
    };

    const sendOwners = () => {
        let a = [];
        state.owners.map(item => item ? a.push(item.id) : null);
        return a;
    }
    const sortOwners = (id) => {
        let a = true;
        state.owners.map(item => (item.id === id) ? a = false : null)
        return a;
    };
    const addOwners = (id, arr) => {
        let owners = state.owners;
        if(sortOwners(id)) owners.push(arr);
        setState({ owners, tch: false, showAddUser: false })
    };
    const deleteOwners = id => {
        let owners = state.owners;
        for(let i = 0; i < owners.length; i++){
            if(owners[i].id === id) owners.splice(i, 1);
        }
        setState({ owners, tch: false })
    };

    const formControl = state.formControl;
    return <div className="page">
        {state.loading && <Loader contain />}
        <CSSTransition in={!state.loading} timeout={200} classNames="slide" unmountOnExit>
            <div>
                <LeaveModal when={!state.tch} />
        <div className="butt-group butt-group-card">
            <Tags editorTags={state.editorTags} setState={setState} />
            <IconButton href="https://sboxcrm.com/docs/task" />
            <button onClick={putData} disabled={state.tch} className="butt-group-card-wrap success fl-r btni ml5">
                <span>Сохранить</span>
                <i className="flaticon-interface-1 mdb" />
            </button>
            <button onClick={deleteTask} className="butt-group-card-wrap fl-r btni ml5">
                <span>Удалить</span>
                <i className="flaticon-delete-2 mdb" />
            </button>
        </div>
        <div className="clear" />
        <div className="contact-page">
            <div className="contact-left">
                <div className="contact-left-col sbform">
                    <h3>Задача №{props.match.params.id}</h3>
                    <Input
                        label="Тема задачи"
                        value={formControl.subject.value}
                        valid={formControl.subject.valid}
                        disabled={state.user}
                        onChange={e => setField("subject", e.target.value)}
                    />
                    <Myselect
                        label="Приоритет задачи"
                        value={formControl.priority.value}
                        valid={formControl.priority.valid}
                        onChange={e => setField("priority", e.target.value)}
                        options={formControl.priority.options}
                        //disabled={state.user}
                    />

                    <Myselect
                        label="Тип задачи"
                        value={formControl.type_id.value}
                        valid={formControl.type_id.valid}
                        onChange={e => setField("type_id", e.target.value)}
                        options={formControl.type_id.options}
                        lab="value"
                        val="id"
                        //disabled={state.user}
                    />

                    <div className="">
                        <div>
                            <DateTime
                                label="Дата начала"
                                value={formControl.start_date.value}
                                valid={formControl.start_date.valid}
                                event={"start_date"}
                                handleChange={e => setField("start_date", e)}
                                dateFormat="dd MMMM yyyy - HH:mm"
                                timeFormat="HH:mm"
                                timeIntervals="5"
                                showTimeSelect
                                //disabled={state.user}
                            />
                        </div>
                        <div>
                            <DateTime
                                label="Крайняя дата"
                                value={formControl.end_date.value}
                                valid={formControl.end_date.valid}
                                event={"end_date"}
                                handleChange={e => setField("end_date", e)}
                                dateFormat="dd MMMM yyyy - HH:mm"
                                timeFormat="HH:mm"
                                timeIntervals="5"
                                showTimeSelect
                                //disabled={state.user}
                            />
                        </div>
                        <div className="task-remind">
                            <DateTime
                                label="Напомнить"
                                value={formControl.remind_at.value}
                                valid={formControl.remind_at.valid}
                                event={"remind_at"}
                                handleChange={e => setField("remind_at", e)}
                                dateFormat="dd MMMM yyyy - HH:mm"
                                timeFormat="HH:mm"
                                timeIntervals="5"
                                showTimeSelect
                                //disabled={state.user}
                            />
                        </div>
                    </div>
                    {/*<Input
                        label="Время работы над задачей"
                        value={state.total_time}
                    />*/}

                    {state.related_id ?
                        <div className={`inp`}><label>Связь</label>
                            <div className="rsb">
                                <div className="clear" />
                                <a onClick={() => props.history.push('/' + ATTACHED_NUMBER_TO_STRING[String(state.related_type)] + '/' + state.related_id)}>{state.related_name}</a>
                            </div>
                        </div>
                        : null}
                    <ModAutoSelect
                        label="Ответственный"
                        link="/ajax/settings/user?filter[name][like]="
                        minLength="0"
                        result="name"
                        event="owner_id"
                        valid={formControl.owner_id.valid}
                        shouldValidate={true}
                        addType="owner_id"
                        entity={formControl.owner_id.entity}
                        entityName="name"
                        updateData={(id, arr, type) => setState({ formControl: updateData(state.formControl,id, arr, type), tch: false })}
                        unLink={(type) => setState({ formControl: unLink(state.formControl, type), tch: false})}
                        //disabled={state.user}
                    />

                    <div className="connection"><span>Исполнители</span><div className="toggleparrent"><button onClick={() => setState({ showAddUser: true })} className="btni">+</button></div></div>
                    {state.showAddUser ? <AutoSelect
                        className="taskteam"
                        placeholder="Добавить в команду"
                        link={'/ajax/settings/user?filter[name][like]='}
                        shouldValidate={false}
                        valid={true}
                        minLength="0"
                        result="name"
                        setField={()=>{}}
                        updateData={addOwners}
                    /> : null}
                    {
                        state.owners.map((item, index) =>
                            <ul className="ownersMap">
                                <li>{item.name} <a onClick={() => deleteOwners(item.id)}>x</a></li>
                            </ul>
                        )
                    }
                    <div className="clear" />
                </div>
            </div>

            <div className="contact-right-col">
                <div className="tabs-header">
                    <ul>
                        <li><a className={(state.layout === "information") ? "active" : null} onClick={() => setState({ layout: 'information' })}>Основная информация</a></li>
                        <li><a className={(state.layout === "history") ? "active" : null} onClick={() => setState({ layout: 'history' })}>Хронология</a></li>
                        {/*<li><a className={(state.layout === "related") ? "active" : null} onClick={() => setState({ layout: 'related' })}>Связи</a></li>*/}
                        <li><a className={(state.layout === "files") ? "active" : null} onClick={() => setState({ layout: 'files' })}>Файлы</a></li>
                    </ul>
                </div>
                <div className="tab-container sbform">
                    {state.layout === "information" ? <div className="tab-content">
                        <label style={{ marginTop:"0px" }}>Статус выполнения</label>
                        <div className="task-statuses" style={{ marginBottom: "10px" }}>
                            <button className={formControl.status_id.value === 7 ? "task_active" : ''} onClick={() => setField("status_id", 7)}>Backlog</button>
                            <button className={formControl.status_id.value === 3 ? "task_active" : ''} onClick={() => setField("status_id", 3)}>Не начата</button>
                            <button className={formControl.status_id.value === 6 ? "task_active" : ''} onClick={() => setField("status_id", 6)}>Уточнение</button>
                            <button className={formControl.status_id.value === 4 ? "task_active" : ''}  onClick={() => setField("status_id", 4)}>В работе</button>
                            <button className={formControl.status_id.value === 2 ? "task_active" : ''} onClick={() => setField("status_id", 2)}>Приостановлена</button>
                            <button className={formControl.status_id.value === 5 ? "task_active" : ''} onClick={() => setField("status_id", 5)}>На проверке</button>
                            <button className={formControl.status_id.value === 1 ? "task_active" : ''} onClick={() => setField("status_id", 1)}>Завершена</button>
                        </div>

                        <hr className="task-hr"/>
                        <div className="subtask">
                            <table style={{ width: "100%", borderSpacing: "0px 0px" }}>{
                                state.subtask_json ? state.subtask_json.map((item, index) =>
                                    item ? <tr key={index}>
                                        <td style={{ width: "20px" }}>{(item.status) ? <i onClick={() => {subTaskEdit("setStatus", index)}} className="flaticon-interface-1" /> : <div onClick={() => {subTaskEdit("setStatus", index)}} className="subTskSquire" />}</td>
                                        <td style={{ textDecoration: (item.status) ? 'line-through' : '', color: (item.status) ? '#bbb' : '' }}><em onClick={() => {subTaskEdit("setStatus", index); SoundClick("click")}}>{item.text}</em></td>
                                        <td style={{ width: "15px" }}><a onClick={() => {subTaskEdit("delete", index)}} style={{ color: "#61131f" }}>x</a></td>
                                    </tr> : null
                                ) : null
                            }</table>
                            <div className="subtaskInp">
                                <i onClick={e => addSubtask()} className="flaticon-add" />
                                <Input placeholder="Введите текст подзадачи и нажмите на ENTER" className="c12" value={state.subtask_value} onKeyUp={e => (e.keyCode === 13) ? addSubtask() : null} onChange={e => {setState({ subtask_value: e.target.value })} } />
                            </div>
                            <div className="clear" />
                        </div>
                        <hr className="task-hr"/>


                        <MainEditor
                            label="Описание задачи"
                            value={formControl.description.value}
                            valid={state.formControl.description.valid}
                            onChange={e => setField("description", e)}
                        />
                        {formControl.status_id.value !== 3 ? <MainEditor
                            label="Комментарий исполнителя"
                            value={formControl.comment.value}
                            valid={state.formControl.comment.valid}
                            onChange={e => setField("comment", e)}
                        /> : null}
                    </div> : null}
                    {(state.layout === "history") ? <History type="task" id={props.match.params.id} /> : null}
                    {(state.layout === "related") ?
                        <Related
                            type="case"
                            id={props.match.params.id}
                            related="27"
                            contactEmail={state.formControl.contact_id.entity ? state.formControl.contact_id.entity.email : ''}
                            accountEmail={state.formControl.account_id.entity ? state.formControl.account_id.entity.email : ''}
                            contact={state.formControl.contact_id.entity}
                            account={state.formControl.account_id.entity}
                        /> : null}
                    {(state.layout === "files") ? <Files files={state.filesList} filesFunc={(type, index, arr) => setState({filesList: filesFunc(type, index, arr, state.filesList)})} relatedType="5" related_id={props.match.params.id}
                    /> : null}
                </div>
            </div>
        </div>

        <React.Fragment>
            <div className={"right-modal scrollbar " + state.open}><div className="save">
                {(state.open === "r-open" && state.openType === "tags")
                    ? <EditTags
                        close={close}
                        tags={state.editorTags}
                        type="task"
                        id={props.match.params.id}
                        refresh={() => {}}
                        refreshState={(tags) => {setState({ editorTags: tags })} }
                    /> : null}
            </div></div>
            {state.open ? <Bgdrop close={close} /> : null}
        </React.Fragment>
            </div>
        </CSSTransition>
    </div>
};

export default withRouter(TaskView);
