import React, {useEffect, useState} from "react";
import Modal from "../Modal/Modal";
import myaxios from "../Axios";
import {ChatItem} from "./ChatItem";
import {FixedBackgroundProcess} from "../other/FixedBackgroundProcess";

export function msgColor(type) {
    switch(type){
        case "telegram": return "rgb(55, 175, 226)";
        case "viber": return "rgb(131, 73, 149)";
        case "facebook": return "rgb(66, 103, 178)";
        default: return "rgb(187, 187, 187)";
    }
}

export const MessengersList = ({ msgList, close, notifSocket, updateBackgroundData, history, openChats, setOpenChats }) => {
    const [loading, setLoading] = useState(true)
    const [messengersList, setMessengersList] = useState([])

    const getChatsList = async () => {
        const { status, data } = await myaxios.get(`/ajax/site/get-messanger-history`)
        setMessengersList(data.items)
        setLoading(false)
    }

    useEffect(() => {
        if(msgList) getChatsList().then()
    }, [msgList])

    return (
        <>
            <Modal isOpen={msgList} close={close} loading={loading}>
                <div className={'chatListContainer'}>
                    {
                        messengersList.map(({ id, user, name, type, bot }) => (
                            <a
                                onClick={() => {
                                    close()
                                    setOpenChats([...openChats, { id, type, user, name, bot }])
                                }}
                                key={id} className={'chatItem'}>
                                <div className="chatFace"><img src={`${user && user.picture && user.picture.length > 5 ? user.picture : 'https://colegioclassea.com.br/wp-content/themes/PageLand/assets/img/avatar/avatar.jpg'}`} alt=""/></div>
                                <div className="chatName"><h4>{name}</h4><b style={{ color: msgColor(type)}}>{type} {bot ? bot.name : null}</b></div>
                            </a>
                        ))
                    }
                </div>
            </Modal>

            <div className={'chats-container'}>
                {
                    openChats.map((item) => (
                        <ChatItem
                            notifSocket={notifSocket}
                            item={item}
                            key={item.id}
                            close={() => setOpenChats(openChats.filter(el => +el.id !== +item.id))}
                            history={history}
                        />
                    ))
                }
                <FixedBackgroundProcess updateBackgroundData={updateBackgroundData} />
            </div>
        </>
    )
}
