import React from 'react';
import {CSSTransition, TransitionGroup} from "react-transition-group";

export const taskEdit = (type, arr, index, tasksState) => {
    const tasks = tasksState;
    if(type === "add") {tasks.unshift(arr)}
    else if(type === "edit"){tasks[index] = arr}
    else if(type === "delete") {delete tasks[index]};
    tasksState.tasks = tasks;
    tasksState.open = '';
    tasksState.openType = '';
    tasksState.taskIndex = '';
    return tasksState
}

export const Tasks = ({tasks, setState, openTask}) => {
    return <div className="taskcontainer">
        <TransitionGroup className="transition-group-screen">
            {tasks && tasks.map((task, index) =>
                <CSSTransition key={index} timeout={{ enter: 200, exit: 200 }} classNames="slide">
                    <Task
                        key={index}
                        openTask={(id, index) => {
                            setState && setState({ open: "r-open", openType: "task", taskIndex: index, taskId: id})
                            openTask && openTask({ type: 'task', id, index })
                        }}
                        index={index}
                        id={task.id}
                        tasktext={task.subject}
                        end_date={task.end_date}
                        status={task.status}
                    />
                </CSSTransition>
            )}
        </TransitionGroup>
    </div>
}

const Task = props => (
    <div className="taskdiv">
        <a onClick={() => props.openTask(props.id, props.index)}>{props.tasktext.substring(0, 70)}...</a>
        <button onClick={() => props.openTask(props.id, props.index)}>{(props.status) ? props.status.value : null}</button>
        <em>{props.end_date}</em>
    </div>
);



export default Task;