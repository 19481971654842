import React, {useEffect, useState} from "react";
import {FieldGen, setField, updateData, unLink, postDt} from '../../../components/fieldGen';
import myaxios from "../../../components/Axios";
import Notif from "../../../components/Notif";
import formValid from "../../UI/formValid";
import Textarea from "../../UI/Textarea";
import Input from "../../UI/Input";
import Myselect from "../../UI/Select";
import Switch from "../../UI/Switch";

export const Binotel = props => {
    const [users, setUsers] = useState([]);
    const [status, setStatus] = useState(true);

    const getUsers = () => {
        myaxios.get(`/ajax/settings/user?sort=-created_at`).then(res => {
            getBinUsers(res.data.items)
        })
    }

    const getBinUsers = (newUsers) => {
        //const newUsers = JSON.parse(JSON.stringify(users))

        myaxios.get(`/ajax/binotel/user`).then(res => {
            //setUsers(res.data.items)
            res.data.items.map(item => {
                if(newUsers[newUsers.findIndex(el => el.id === item.user_id)]){
                    newUsers[newUsers.findIndex(el => el.id === item.user_id)].number = item.user_number
                }
            })
            setUsers(newUsers)
        })
    }

    const putData = () => {
        const res = users.map(item => ({
            id: item.id,
            number: item.number
        }))

        myaxios.patch(`/ajax/binotel/user`, {
            numbers: res.filter(el => el.number)
        }).then(res => {
            if(res.status === 200 || res.status === 201){
                Notif("save", "Данные успешно сохранены");
                setNewStatus()
            }
        })
    }

    const setNewStatus = () => {
        if(status) {
            myaxios.patch(`/ajax/binotel/enable`).then(res => {
                props.refresh();
            })
        } else {
            myaxios.patch(`/ajax/binotel/disable`).then(res => {
                props.refresh();
            })
        }
    }

    useEffect(() => {
        setStatus(!!props.is_enabled)
        getUsers()
    }, [])

    return <div className="sbform create-activ">
        <div className="rmod-header">
            <b>Binotel</b>
            <a onClick={props.close} className="rmod-close"><i className="flaticon-close" /></a>
            <div className="togglelist"><a onClick={putData} className="rmod-ok" target="_blank"><i className="flaticon-folder-3" /></a></div>
        </div>
        <div className="mod-cont second-table sbform">
            <Switch
                active={status}
                onClick={() => setStatus(!status)}
            />
            <table style={{ width: "100%" }}>
                <tr>
                    <th>Пользователь</th>
                    <th>Номер</th>
                </tr>
                {
                    users.map((item, i) => (
                        <tr key={item.id}>
                            <td>{item.name}</td>
                            <td>
                                <Input
                                    value={item.number}
                                    onChange={e => {
                                        const newUsers = JSON.parse(JSON.stringify(users))
                                        newUsers[i].number = e.target.value;
                                        setUsers(newUsers)
                                    }}
                                />
                            </td>
                        </tr>
                    ))
                }
            </table>
        </div>
    </div>
}


export const TurboSMS = props => {
    const [state, setMyState] = useState({
        formControl: {
            sender: {
                type: 'input',
                label: 'Подпись',
                value: '',
                valid: true,
                validation: {required: true}
            },
            login: {
                type: 'input',
                label: 'Логин',
                value: '',
                valid: true,
                validation: {required: true}
            },
            password: {
                type: 'input',
                label: 'Пароль',
                value: '',
                valid: true,
                validation: {required: true}
            },
            is_enabled: {
                type:'select',
                cls:'c12',
                label:'Статус',
                value:'',
                valid:true,
                options:[
                    {value:'0', label:'Отключено'},
                    {value:'1', label:'Включено'},
                ]
            },
        },
        account: null
    });
    const isFormValid = () => {
        let formControl = state.formControl;
        let isFormValid = true;
        Object.keys(formControl).map((key, index) => {
            formControl[key].valid = formValid(formControl[key].value, formControl[key].validation)
            isFormValid = formControl[key].valid && isFormValid
        });
        setState({formControl:formControl});
        return isFormValid;
    };
    const setState = (st, func) => {
        Object.keys(st).map((key) => { state[key] = st[key]});
        setMyState(prevState => { return {...prevState, ...state} });
        if(func) func();
    };
    const getItem = () => {
        if(props.data){
            const formControl = state.formControl;
            formControl.sender.value =  props.data.sender || '';
            formControl.login.value =  props.data.login || '';
            formControl.password.value =  props.data.password || '';
            formControl.is_enabled.value =  String(props.is_enabled) || '';
            setState({ formControl })
        }
    };
    useEffect(() => {
        if(props.id) getItem()
    },[])

    const postData = () => {
        if(isFormValid()){
            myaxios.put(`/ajax/config/${props.id}`, {
                data: {
                    value: {
                        sender: state.formControl.sender.value,
                        login: state.formControl.login.value,
                        password: state.formControl.password.value,
                    },
                    is_enabled: state.formControl.is_enabled.value
                },
            }).then(res => {
                if(res.status === 200 || res.status === 201 || res.status === 204){
                    props.refresh();
                    Notif("save", "Настройки успешно сохранены");
                }
            })
        }
    }

    return <div className="sbform create-activ">
        <div className="rmod-header">
            <b>TurboSMS</b>
            <a onClick={props.close} className="rmod-close"><i className="flaticon-close" /></a>
            <div className="togglelist"><a onClick={postData} className="rmod-ok" target="_blank"><i className="flaticon-folder-3" /></a></div>
        </div>
        <div className="mod-cont">
            <FieldGen
                arr={state.formControl}
                setField={(e, type) => setState({formControl: setField(state.formControl, type, e)})}
                updateData={(id, arr, type) => { setState({ formControl: updateData(state.formControl,id, arr, type) })}}
                unLink={(type) => setState({ formControl: unLink(state.formControl, type)})}
            />
        </div>
    </div>
}

export const Wayforpay = props => {
    const [state, setMyState] = useState({
        formControl: {
            account: {
                type: 'input',
                label: 'account',
                value: '',
                valid: true,
                validation: {required: true}
            },
            secret: {
                type: 'input',
                label: 'secret',
                value: '',
                valid: true,
                validation: {required: true}
            },
            is_enabled: {
                type:'select',
                cls:'c12',
                label:'Статус',
                value:'',
                valid:true,
                options:[
                    {value:'0', label:'Отключено'},
                    {value:'1', label:'Включено'},
                ]
            },
        }
    });
    const isFormValid = () => {
        let formControl = state.formControl;
        let isFormValid = true;
        Object.keys(formControl).map((key, index) => {
            formControl[key].valid = formValid(formControl[key].value, formControl[key].validation)
            isFormValid = formControl[key].valid && isFormValid
        });
        setState({formControl:formControl});
        return isFormValid;
    };
    const setState = (st, func) => {
        Object.keys(st).map((key) => { state[key] = st[key]});
        setMyState(prevState => { return {...prevState, ...state} });
        if(func) func();
    };
    const getItem = () => {
        if(props.data){
            const formControl = state.formControl;
            formControl.account.value =  props.data.account || '';
            formControl.secret.value =  props.data.secret || '';
            formControl.is_enabled.value =  String(props.is_enabled) || '';
            setState({ formControl })
        }
    };
    useEffect(() => {
        if(props.id) getItem()
    },[]);

    const postData = () => {
        if(isFormValid()){
            myaxios.put(`/ajax/config/${props.id}`, {
                data: {
                    value: {
                        account: state.formControl.account.value,
                        secret: state.formControl.secret.value,
                    },
                    is_enabled: state.formControl.is_enabled.value
                },
            }).then(res => {
                if(res.status === 200 || res.status === 201 || res.status === 204){
                    props.refresh()
                    Notif("save", "Настройки успешно сохранены");
                }
            })
        }
    }

    return <div className="sbform create-activ">
        <div className="rmod-header">
            <b>WayForPay</b>
            <a onClick={props.close} className="rmod-close"><i className="flaticon-close" /></a>
            <div className="togglelist"><a onClick={postData} className="rmod-ok" target="_blank"><i className="flaticon-folder-3" /></a></div>
        </div>
        <div className="mod-cont">
            <FieldGen
                arr={state.formControl}
                setField={(e, type) => setState({formControl: setField(state.formControl, type, e)})}
                updateData={(id, arr, type) => { setState({ formControl: updateData(state.formControl,id, arr, type) })}}
                unLink={(type) => setState({ formControl: unLink(state.formControl, type)})}
            />
        </div>
    </div>
}




export const Sender = props => {
    const [code, setCode] = useState({
        code:''
    });
    const getItem = () => {
        myaxios.get(`/ajax/sender/code`).then(res => {
            setCode(JSON.parse(res.data))
        })
    };

    const isUserExist = () => {
        myaxios.post(`/ajax/sender/connect`).then(res => {
            console.log("resresres", res)
            if(!res.data) return false;

            getItem()
        })
    }

    useEffect(() => {
        isUserExist()
    },[]);

    const postData = () => {

    };

    return <div className="sbform create-activ">
        <div className="rmod-header">
            <b>Sender</b>
            <a onClick={props.close} className="rmod-close"><i className="flaticon-close" /></a>
            <div className="togglelist"><a onClick={postData} className="rmod-ok" target="_blank"><i className="flaticon-folder-3" /></a></div>
        </div>
        <div className="mod-cont">
            <Textarea
                label="Вставьте код на каждую страницу сайта, события которой хотите отслеживать"
                style={{ height: "100vh" }}
                value={code}
            />
        </div>
    </div>
}
