import {CrudCreateFunction, CrudGetSingleItemFunction, CrudUpdateFunction, checkUpdatedEntity} from "../CRUD";
import {
    editLinkedProductService,
    getAllLinkedProductsService,
    getProductListService,
    linkProductService, unlinkProductService,

} from "../../../services/product/product";

const updatedData = {
    discount: "12",
    price: "500",
    quantity: "5",
}

export const ProductTest = async ({ entity, entityId }) => {
    // получаем список продуктов
    const getAllProducts = await CrudGetSingleItemFunction({
        service: getProductListService,
        serviceName: "getAllProducts"
    })
    if(!getAllProducts.status && getAllProducts.data.items[0]) return false;

    // Привязываем продукт
    const linkProduct = await CrudCreateFunction({
        service: linkProductService,
        serviceData: {
            productId: getAllProducts.data.items[0].id,
            entity, entityId
        }, serviceName: 'linkProduct', statusCode: 200, checkId: false
    })
    if(!linkProduct.status || !linkProduct.data.status) return false;

    // Проверяем или привязался продукт
    const checkProducts = await CrudGetSingleItemFunction({
        service: getAllLinkedProductsService,
        serviceData: {entity, entityId},
        serviceName: 'checkProducts'
    })
    if(!checkProducts.status && !checkProducts.data.items[0]) {
        console.error("Не удалось привязать продукт к сущности лида")
        return false
    }

    // Изменяем продукт
    const updateLinkedProductRes = await CrudUpdateFunction({
        service: editLinkedProductService,
        serviceData: {
            entity, entityId,
            productId: checkProducts.data.items[0].product_id,
            data: updatedData
        }
    })
    if(!updateLinkedProductRes.status) return false;

    // Проверяем или изменился продукт
    const checkProducts2 = await CrudGetSingleItemFunction({
        service: getAllLinkedProductsService,
        serviceData: {entity, entityId},
        serviceName: 'checkProducts'
    })
    if(!checkProducts2.status) return false;
    if(!checkUpdatedEntity(checkProducts2.data.items[0], updatedData, {price: { type: 'include', value: 'price' } })) return false;

    // Отвязываем продукт
    const unLinkLeadProductRes = await CrudUpdateFunction({
        service: unlinkProductService,
        serviceData: {
            entity, entityId,
            productId: checkProducts.data.items[0].product_id
        }
    })
    if(!unLinkLeadProductRes.status) return false;

    // Проверяем или продукт отвязался
    const checkProducts3 = await CrudGetSingleItemFunction({
        service: getAllLinkedProductsService,
        serviceData: {entity, entityId},
        serviceName: 'checkProducts'
    })
    if (!checkProducts3.status || checkProducts3.data.items.length !== 0){
        console.error('Продукт не был отвязан от лида')
        return false
    }

    return true
}