import React from 'react';
import AddTask from './AddTask';
import AddEmailBox from '../Modal/AddEmailBox';
import AddUser from '../Modal/AddUser';
import AllEmailBox from '../Modal/AllEmailBox';
import Call from '../Modal/Call/Call';
import SendMail from '../Modal/SendMail';
import Notifications from './Notifications/List';
import AddInvoice from './AddInvoice';
import MessageList from './Chat/MessageList';
import Export from '../Modal/export';
import SendSms from '../Modal/SendSms';
import ForwardMail from '../Modal/ForwardMail';
import AddKnowledgeBasePost from '../Modal/AddKnowledgeBasePost';
import MessagersList from '../Modal/Messangers/MessagersList';
import Rfm from '../Modal/rfm';
import WrapModal from './WrapModal';
import Task from "./AddTask";
import {Segment} from './Segment';
import AddProviderReturn from "./AddProviderReturn";

const RightModal = (props) => {
    switch(props.title){
        case "AddTask": return <div className="save">
            <Task id={props.id} close={props.close} task={(type, data) => props.history.push(`/task/view/${data.id}`)} />
        </div>;

        case "AddEmailBox": return <div className="save">
            <AddEmailBox id={props.id} close={props.close} />
        </div>;

        case "AddUser": return <div className="save">
            <a className="mclose" onClick={props.close}><i className="flaticon-close" /></a>
            <AddUser id={props.id} close={props.close} />
        </div>;

        case "AllEmailBox": return <div>
            <a className="mclose" onClick={props.close}><i className="flaticon-close" /></a>
            <AllEmailBox />
        </div>;

        case "Call": return <div>
            <a className="mclose" onClick={props.close}><i className="flaticon-close" /></a>
            <Call phone={props.phone} close={props.close} id={props.id} />
        </div>;

        case "Product": return <div>
            <WrapModal close={props.close} name="Product" />
        </div>;

        case "SendMail": return <div>
            <SendMail updateData={props.updateData} email={props.email} id={props.id} arg={props.arg} close={props.close} />
        </div>;

        case "ForwardMail": return <div>
            <ForwardMail id={props.id} close={props.close} />
        </div>;

        case "AddOpportunity": return <div>
            <WrapModal close={props.close} name="AddOpportunity" />
        </div>;

        case "AddLead": return <div>
            <WrapModal close={props.close} name="AddLead" />
        </div>;

        case "AddContact": return <div>
            <WrapModal close={props.close} name="AddContact" setStep={props.setStep} />
        </div>;

        case "Notifications": return <div>
            <a className="mclose" onClick={props.close}><i className="flaticon-close" /></a>
            <Notifications close={props.close} />
        </div>;

        case "AddAccount": return <div>
            <WrapModal close={props.close} name="AddAccount" />
        </div>;

        case "AddProject": return <div>
            <WrapModal close={props.close} name="AddProject" />
        </div>;

        case "AddInvoice": return <div className="save">
            <a className="mclose" onClick={props.close}><i className="flaticon-close" /></a>
            <AddInvoice close={props.close} id={props.id} />
        </div>;

        case "AddOrder": return <div>
            <WrapModal close={props.close} name="AddOrder" />
        </div>;

        case "Shipment": return <div>
            <WrapModal close={props.close} name="Shipment" />
        </div>;

        case "Replanish": return <div>
            <WrapModal close={props.close} name="Replanish" />
        </div>;

        case "Transfer": return <div>
            <WrapModal close={props.close} name="Transfer" />
        </div>;

        case "Purchase": return <div className="save">
            <WrapModal close={props.close} name="Purchase" />
        </div>;

        case "Consumption": return <div>
            <WrapModal close={props.close} name="Consumption" />
        </div>;

        case "Stocktake": return <div>
            <WrapModal close={props.close} name="Stocktake" />
        </div>;

        case "MessageList": return <div className="save">
            <MessageList close={() => {props.close(); props.onMsg()}} id={props.id} />
        </div>;

        case "MessagersList": return <div className="save">
            <MessagersList close={() => {props.close(); props.onMsg()}} id={props.id} />
        </div>;

        case "Export": return <div className="save">
            <Export close={props.close} id={props.id} />
        </div>;

        case "SendSms": return <div className="save">
            <SendSms sms={props.sms} close={props.close} id={props.id} />
        </div>;

        case "AddKnowledgeBasePost": return <div className="save">
            <AddKnowledgeBasePost close={props.close} id={props.id} />
        </div>;

        case "AddDocument": return <div>
            <WrapModal close={props.close} name="AddDocument" />
        </div>;

        case "RfmAccount": return <div>
            <Rfm close={props.close} name="Rfm" id={props.id} type="account" />
        </div>;

        case "RfmContact": return <div>
            <Rfm close={props.close} name="Rfm" id={props.id} type="contact" />
        </div>;

        case "Segment": return <div>
            <Segment {...props} close={props.close} id={props.id} />
        </div>;

        case "AddCases": return <div>
            <WrapModal close={props.close} id={props.id} name="AddCases" />
        </div>; // AddProviderReturn

        case "AddProviderReturn": return <div>
            <AddProviderReturn {...props} close={props.close} id={props.id} isOpen={true} />
        </div>;

        default: return '';
    }
};

export default React.memo(RightModal);