import React, {useContext, useEffect, useState} from "react";
import Modal from "../../Modal";
import {GlobalState} from "../../../Layout/Layout";
import Input from "../../UI/Input";
import Myselect from "../../UI/Select";
import Textarea from "../../UI/Textarea";
import {BpDateWithPeriod, parseDataForPeriod, saveTimePeriod} from "../BpHelpers";
import {validationData} from "../../UI/formValid";

const validationRules = {
    name: { required: true, maxLength: 255 },
    body: { required: true },
}

export const BpActionSendSms = ({ close, metaData, saveNode }) => {
    const { state } = useContext(GlobalState)
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState({})
    const [values, setValues] = useState({
        name: '',
        body: "",
        to: "mobile_phone",
        time_value: 0,
        time_valuePeriod: 'minutes',
        tch: true
    })
    
    const getData = () => {
        const { name, time_value, metaData: { to, body }} = metaData
        setValues({
            ...values, name, body, to,
            time_value: parseDataForPeriod(time_value).value,
            time_valuePeriod: parseDataForPeriod(time_value).period,
        })
        setLoading(false)
    }

    useEffect(() => {
        setIsOpen(true)
        if(metaData) {
            getData()
        } else {
            setLoading(false)
        }
    }, [])

    const saveData = () => {
        const validErrors = validationData(values, validationRules)
        if(Object.keys(validErrors).length > 0) {
            setErrors(validErrors)
            return false;
        }
        setErrors({})
        setIsOpen(false)

        const { name, to, body, time_value, time_valuePeriod } = values
        setTimeout(() => saveNode({
            id: metaData && metaData.id,
            name,
            nodeType: 'node',
            time_value: saveTimePeriod(time_value, time_valuePeriod),
            is_scheduled: time_value > 0,
            type: "sendSms",
            metaData: { to, body }
        }), 100)
    }

    const closeModal = () => {
        setIsOpen(false); setTimeout(close, 200);
    }

    return (
        <Modal showLeaveModal tch={values.tch} isOpen={isOpen} title={'Отправить SMS'} loading={loading}
               save={saveData} parentClassName={'bpmModal'} close={closeModal}>
            {state.sms ? <div className="sbform">
                <Input
                    label={'Название'}
                    value={values.name}
                    onChange={e => setValues({...values, name: e.target.value, tch: false })}
                    errorMessage={errors.name} valid={!errors.name} shouldValidate
                />
                <Myselect
                    tooltip={'К каждой сущности привязывается контакт либо контрагент. Выберите поле номера ' +
                        'телефона, из которого бизнес-процесс будет брать значение у контакта для отправки SMS'}
                    label={'Кому'}
                    options={[
                        { value: 'mobile_phone', label:'Мобильный телефон' },
                        { value: 'work_phone', label:'Рабочий телефон' },
                    ]} value={values.to}
                    onChange={e => setValues({ ...values, to: e.target.value })}
                    errorMessage={errors.to} valid={!errors.to} shouldValidate
                />
                <Textarea
                    label={'Текст сообщения'}
                    value={values.body} rows={'6'}
                    onChange={e => setValues({...values, body: e.target.value, tch: false })}
                    errorMessage={errors.body} valid={!errors.body} shouldValidate
                />
                <BpDateWithPeriod
                    label={'Отложенный запуск'}
                    name={'time_value'} periodName={'time_valuePeriod'}
                    values={values} errors={errors}
                    setValues={(type, value) => setValues({...values, [type]: value, tch: false })}
                    tooltip={'Время задержки выполнения процесса'}
                />
            </div> : 'Необходимо подключить SMS интеграцию'}
        </Modal>
    )
}