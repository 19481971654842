import React, { Component } from 'react';
import Input from '../../../../components/UI/Input';
import axios from 'axios';
import {withRouter} from 'react-router-dom';


function truncator(numToTruncate, intDecimalPlaces) {
    let numPower = Math.pow(10, intDecimalPlaces); // "numPowerConverter" might be better
    return ~~(numToTruncate * numPower)/numPower;
}

class ProductItem extends Component{
    state = {
        static: true,
        priceBookArr:[],
        currency:{}
    };
    handlerInput = () => {
        this.setState({ static: false })
    };
    getProd = (id) => {
        axios.get(`/ajax/product/${id}/prices`).then(response => {
            this.setState({ priceBookArr: response.data.items, currency: response.data[0] })
        })
    }
    setProd = (price, id, currency) => {
        let value = price;
        if(currency.name !== this.state.currency.name){
            value = price * currency.value;
        }
        this.props.setPrice(value, id);
        this.setState({ static: false });
        this.onblurr();
    }
    onblurr = () => {
        this.setState({ priceBookArr:[] })
    }
    render(){
        return  <tr className="pull-up">
            <td><a onClick={() => this.props.history.push('/product/' + this.props.id)}>{this.props.name}</a></td>
            <td>
                <Input
                    type="number"
                    value={this.props.quantity}
                    onKeyUp={this.handlerInput}
                    onChange={event => {
                        this.props.setField(event, this.props.event, "quantity");
                    }}
                />
            </td>
            <td>
                <Input
                    type="number"
                    value={this.props.discount}
                    onKeyUp={this.handlerInput}
                    onChange={event => {
                        this.props.setField(event, this.props.event, "discount");
                    }}
                    min="0"
                    max="100"
                />
            </td>
            <td>
                <div className="prbookrel" >
                    <Input
                        type="number"
                        value={this.props.sum}
                        onKeyUp={this.handlerInput}
                        onChange={event => {
                            this.props.setField(event, this.props.event, "price");
                        }}
                        onFocus={() => this.getProd(this.props.id)}
                    />
                    <div className="prbookabs">
                        {
                            this.state.priceBookArr ? this.state.priceBookArr.map((item, index) =>
                                <a onClick={() => this.setProd(item.default_price, this.props.event, item.currency)}>
                                    {item.priceBook ? item.priceBook.name : null}: {item.default_price}{item.currency ? <em>{item.currency.text_symbol}</em> : null}
                                </a>
                            ) : null
                        }
                        {this.state.priceBookArr.length >= 1 ? <a onClick={() => this.setState({ priceBookArr:[] })} className="prbookcls">Закрыть</a> : null}
                    </div>
                </div>
            </td>
            <td>{truncator(this.props.totalPrice, 2)}</td>
            <td>
                <button onClick={() => {this.props.saveItem(this.props.id); this.setState({ static: true,priceBookArr:[] }) }} disabled={this.state.static} className="btn-m serch-button">Сохранить</button>
                <div className="toogle-bottom fl-r"><a onClick={() => {this.props.delete(this.props.id) }} className="is prod-del" href="#"><i className="flaticon-delete-1" /></a></div>
            </td>
        </tr>
    }
}
export default withRouter(ProductItem);