import React, {Component} from 'react';
import Input from '../../components/UI/Input';
import Myselect from '../../components/UI/Select';
import AutoSelect from '../../components/UI/AutoSelect';
import ModAutoSelect from '../../components/UI/ModAutoSelect';

const WrapForm = props => {
    return <form id="leadform" className="sbform">
        <Input
            label="Название сделки"
            type="text"
            errorMessage={'Поле не может содержать меньше 3-х символов'}
            value={props.nameValue}
            onChange={event => props.setField(event, "name")}
            shouldValidate={props.shouldValidate}
            valid={props.nameValid}
            isFormValid={props.isFormValid}
        />
        <Input
            label="Бюджет"
            type="text"
            className="c8"
            value={props.amountValue}
            errorMessage="Значение должно быть числом"
            shouldValidate={true}
            valid={props.amountValid}
            onChange={event => props.setField(event, "amount")}
        />
        <ModAutoSelect
            required
            label="Валюта"
            link="/ajax/lookup/search?type=currency&value="
            minLength="0"
            result="text_symbol"
            event="currency"
            valid={props.currency.valid}
            shouldValidate={true}
            addType="currency"
            entity={props.currency.arr}
            entityName="text_symbol"
            updateData={props.updateData}
            unLink={props.unLink}
            className="c4"
            //redirectLink="/order/"
        />
        <div className="clear" />
        <Myselect
            value={props.stageOptionsValue}
            label="Этап сделки"
            options={props.stageOptions}
            onChange={event => props.setField(event, "stage")}
            shouldValidate={false}
            isFormValid={props.isFormValid}
            val="id"
            lab="value"
        />
        <Myselect
            value={props.is_repeat}
            label="Продажа"
            options={[
                {value: 0, label: 'Первая'},
                {value: 1, label: 'Повторная'},
                ]}
            onChange={event => props.setField(event, "is_repeat")}
            shouldValidate={false}
        />
        <div className="relt">
            {(props.source) ?
                <div className="inp"><label>Источник сделки</label>
                    <div className="rsb"><b>{props.source.value}</b>
                        <a title="Отвязать" onClick={()=> props.unLink("source")} className="unlink">x</a></div></div> :
                <AutoSelect
                    label="Источник сделки"
                    className="fgroup"
                    link={`/ajax/lookup/search?type=ld_source&value=`}
                    minLength={`0`}
                    result="value"
                    event="source"
                    addType="source"
                    endParrams="1"
                    setField={()=>{}}
                    updateData={props.updateData}
                />}
        </div>
        <ModAutoSelect
            required
            label="Ответственный"
            link="/ajax/settings/user?filter[name][like]="
            minLength="0"
            result="name"
            event="owner"
            valid={true}
            shouldValidate={true}
            addType="owner"
            entity={props.owner}
            entityName="name"
            updateData={props.updateData}
            unLink={props.unLink}
            ownerLink={props.owner ? props.owner.contact : null}
        />
    </form>
};

export default WrapForm;