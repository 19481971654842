import React, {Component} from 'react';
import ModAutoSelect from '../../components/UI/ModAutoSelect';

//

export default class AddMail extends Component {
    state = {
        arr:null,
        id:'',
        valid: true
    };
    upData = (id, arr) => {
        this.setState({ arr: arr, id: id })
    };
    sendData = () => {
        if(this.state.arr){

        } else {
            this.setState({ valid: false })
        }
    }
    render(){
        return <div>
            <div className="rmod-header">
                <b>Почта</b>
                <a onClick={this.props.close} style={{ marginRight: "60px" }} className="mclose"><i className="flaticon-close" /></a>
                <div className="togglelist"><button onClick={this.upData} className="rmod-ok" disabled=""><i className="flaticon-interface-1" /></button></div>
            </div>
            <div className="mod-cont sbform">
                <ModAutoSelect
                    label="Почтовый ящик"
                    link="/ajax/mail/account?filter[username][like]="
                    minLength="0"
                    result="username"
                    event="responsible"
                    valid={this.state.valid}
                    shouldValidate={false}
                    addType="responsible"
                    entity={this.state.arr}
                    entityName="username"
                    updateData={this.upData}
                    unLink={() => this.setState({ arr:null, id:'' })}
                    //redirectLink="/order/"
                />
            </div>
        </div>
    }
}