import {CrudCreateFunction, CrudDeleteFunction, CrudGetSingleItemFunction} from "../CRUD";
import {createExpenseService, expensePrepareService, deleteExpenseService} from "../../../services/expense/expenseService";
import {getLookUpTest} from "../Settings/LookUpTest";
import {getConnectionsService} from "../../../services/other/connections";

const itemExists = async (entity, id, name, count, itemId) => {
    const res = await CrudGetSingleItemFunction({
        service: getConnectionsService,
        serviceData: {
            entity, id, name
        }
    })
    if (!res.status) return false;

    if(count && res.data.items.length !== count) {
        console.error(`К-во элементов ${entity} в связях ${name} не соответствует ожиданиям`)
        return false
    }

    if(itemId && !res.data.items.find(el => el.id == itemId)) {
        console.error(`В связях ${name} сущности ${entity} нет необходимого id`)
    }

    return true;
}

export const Connections = async ({ list, entityName, entityId }) => {
    if(list.expense) {
        // Получаем валюту
        const getCurrency = await getLookUpTest('currency');
        if(!getCurrency.status) return false;

        // Получаем подготовительные данные
        const getPrepareRes = await CrudGetSingleItemFunction({
            service: expensePrepareService,
            statusCode: 200
        });
        if(!getPrepareRes.status) return false;
        const { balances } = getPrepareRes.data;

        // Создаем расход
        const createExpenseRes = await CrudCreateFunction({
            service: createExpenseService,
            serviceData: {
                name: 'test',
                amount: 100,
                balance_id: balances[0].id,
                currency_id: getCurrency.data[0].id,
                datetime: new Date(),
                status: 1,
                [entityName + '_id']: entityId
            }
        })
        if(!createExpenseRes.status && !createExpenseRes.data && !createExpenseRes.data.id) return false;

        // Проверяем расход в связях
        const isExistRes = await itemExists(entityName, entityId, 'expenses', 1, createExpenseRes.data.id)
        if(!isExistRes) return false;

        // Удаляем расход
        const deleteExpenseRes = await CrudDeleteFunction({
            service: deleteExpenseService,
            serviceData: {
                id: createExpenseRes.data.id
            },
            statusCode: 200
        })
        if(!deleteExpenseRes.status) return false;

        // Проверяем расход в связях
        const isExistAfterDeleteRes = await itemExists(entityName, entityId, 'expenses', 0)
        if(!isExistAfterDeleteRes) return false;
    }

    return true
}