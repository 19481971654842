import React from 'react';
import {withRouter} from 'react-router-dom';

const Payments = props => {
    return <div className="sp-table body-table">
        <table style={{borderSpacing: "0"}} className="stagesTable">
            <thead>
            {
                (props.item.length >= 1) ? <tr>
                    <td>Название</td>
                    <td>Дата оплаты</td>
                    <td>Тип</td>
                    <td>Сумма</td>
                    <td data-tooltip="Сумма в валюте счета">в в.с.</td>
                    <td>Статус</td>
                    <td style={{width: "30px"}}/>
                </tr> : null
            }

            </thead>
            <tbody>
            {
                props.item.map((item,index)=>
                    <tr key={index}>
                        <td><a onClick={() => props.openModal("payment", item.id)}>{item.name}</a></td>
                        <td>{item.payment_date}</td>
                        <td>{item.paymentMethod ? item.paymentMethod.value : ''}</td>
                        <td>{item.amount} {item.currency ? item.currency.text_symbol : ''}</td>
                        <td>{item.amount * item.exchange_rate}</td>
                        <td>{status(item.status)}</td>
                        <td>
                            <div className="toogle-bottom fl-r">
                                <a className="is" href="#"><i className="flaticon-more" /></a>
                                <ul className="toogle-list">
                                    <li><a onClick={() => props.openModal("payment", item.id)}><i className="flaticon-edit-1" /> <b>Редактировать</b></a></li>
                                    <li><a onClick={()=>props.deleteObject("invoice/payment", item.id)} className="table-del"><i className="flaticon-delete-1" /> <b>Удалить</b></a></li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                )
            }
            </tbody>
        </table>
    </div>
}

export default withRouter(Payments);

function status(val) {
    switch (String(val)) {
        case "1": return "Новый";
        case "2": return "Истек";
        case "3": return "Ожидает";
        case "4": return "Оплачен";
        case "5": return "Неудачный";
        default: return "";
    }
}