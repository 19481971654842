import React, {Component} from 'react';
import Input from '../components/UI/Input';
import formValid from '../components/UI/formValid';


class ContactFields extends Component {
    setField(e, id){
        let contactInfo = this.props.contactInfo;
        contactInfo[id].value = e.target.value;
        contactInfo[id].valid = formValid(e.target.value, contactInfo[id].validation)
        this.props.updateContactInfo(contactInfo);
    }
    deleteField(id){
        if(window.confirm()){
            let contactInfo = this.props.contactInfo;
            delete contactInfo[id];
            this.props.updateContactInfo(contactInfo);
        }
    }
    errorMessage(type){
        switch(type){
            case "text": return "Заполните поле"; break;
            case "number": return "Поле должно быть числом"; break;
            case "email": return "Введите корректный Email"; break;
            case "url": return "Введите корректный web адрес"; break;
            default: return "Заполните поле";
        }
    }
    render(){
    return <div><div className="clear"></div>
    {
        this.props.contactInfo ? this.props.contactInfo.map((item, index) =>
            <div className="parrentContactField">
            <Input
                key={index}
                required
                label={item.label}
                value={item.value}
                type={item.type}
                valid={item.valid}
                t={item.type}
                shouldValidate={true}
                className="fgroup rc6"
                onChange={event => this.setField(event, index)}
                del={true}
                deleteFunc={()=> this.deleteField(index)}
                errorMessage={this.errorMessage(item.type)}
            />
            </div>
        ) : null
    }
    </div>
}}
export default ContactFields;