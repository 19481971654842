import {leadConvertService} from "../../../services/lead/leadService";
import {CrudGetSingleItemFunction, CrudUpdateFunction, checkUpdatedEntity} from "../CRUD";
import {getSingleContactService} from "../../../services/contact/contactService";
import {getSingleAccountService} from "../../../services/account/accountService";
import {getAddressService} from "../../../services/other/address";

const contactFields = {
    email: "test@test.test",
    first_name: "first",
    last_name: "last",
    middle_name: "middle",
    mobile_phone: "123456789",
}

const accountFields = {
    account_email: "test@test.test",
    account_name: "Контрагент",
    account_phone: "123456789",
}

const accountValues = {
    email: "test@test.test",
    name: "Контрагент",
    phone: "123456789",
}

const opportunityFields = {
    name: 'test opportunity'
}

export const convertLead = async (id) => {

    // конвертируем в контакт
    const convertLeadToContactRes = await CrudUpdateFunction({
        service: leadConvertService,
        serviceData: {
            id,
            data: {
                new_account: false,
                new_contact: true,
                new_opportunity: false,
                contactFields
            }
        },
        serviceName: "covertLeadRes",
        statusCode: 201
    })
    if(!convertLeadToContactRes.status || !convertLeadToContactRes.data.contact) {
        console.error("Не удалось конвертировать лид в контакт")
        return false;
    }

    const contactDataRes = await CrudGetSingleItemFunction({
        service: getSingleContactService,
        serviceData: { id: convertLeadToContactRes.data.contact.id },
        serviceName: "contactDataRes"
    })
    if(!contactDataRes.status) return false;
    if(!checkUpdatedEntity(contactDataRes.data, contactFields, {})) {
        console.error("Поля у конвертируемого контакта не совпадают");
        return false;
    }

    // Получаем и сравниваем адрес
    const getAddressRes = await CrudGetSingleItemFunction({
        service: getAddressService,
        serviceData: {
            entityId: contactDataRes.data.id, entity: "contact"
        }
    })
    if (!getAddressRes.status || !getAddressRes.data.items[0]) return false;





    // конвертируем в контрагента
    const convertLeadToAccountRes = await CrudUpdateFunction({
        service: leadConvertService,
        serviceData: {
            id,
            data: {
                new_account: true,
                new_contact: false,
                new_opportunity: false,
                accountFields
            }
        },
        serviceName: "convertLeadToAccountRes",
        statusCode: 201
    })
    if(!convertLeadToAccountRes.status || !convertLeadToAccountRes.data.account) {
        console.error("Не удалось конвертировать лид в контрагента")
        return false;
    }

    const accountDataRes = await CrudGetSingleItemFunction({
        service: getSingleAccountService,
        serviceData: { id: convertLeadToAccountRes.data.account.id },
        serviceName: "accountDataRes"
    })
    if(!accountDataRes.status) return false;
    if(!checkUpdatedEntity(accountDataRes.data, accountValues, {})) {
        console.error("Поля у конвертируемого контрагента не совпадают");
        return false;
    }

    // Получаем и сравниваем адрес контрагента
    const getAddressAccountRes = await CrudGetSingleItemFunction({
        service: getAddressService,
        serviceData: {
            entityId: accountDataRes.data.id, entity: "account"
        }
    })
    if (!getAddressAccountRes.status || !getAddressAccountRes.data.items[0]) return false;




    // конвертируем в сделку
    const convertLeadToOpportunityRes = await CrudUpdateFunction({
        service: leadConvertService,
        serviceData: {
            id,
            data: {
                new_account: false,
                new_contact: false,
                new_opportunity: true,
                opportunityFields
            }
        },
        serviceName: "convertLeadToOpportunityRes",
        statusCode: 201
    })
    if(!convertLeadToOpportunityRes.status || !convertLeadToOpportunityRes.data.opportunity) {
        console.error("Не удалось конвертировать лид в сделку")
        return false;
    }


    // конвертируем в контакт и сделку
    const convertLeadToOpportunityContactRes = await CrudUpdateFunction({
        service: leadConvertService,
        serviceData: {
            id,
            data: {
                new_account: false,
                new_contact: true,
                new_opportunity: true,
                opportunityFields,
                contactFields
            }
        },
        serviceName: "convertLeadToOpportunityRes",
        statusCode: 201
    })
    if(
        !convertLeadToOpportunityContactRes.status ||
        !convertLeadToOpportunityContactRes.data.opportunity ||
        !convertLeadToOpportunityContactRes.data.contact
    ) {
        console.error("Не удалось конвертировать лид в контакт и сделку")
        return false;
    }

    return true;
}