import React from 'react';

function isInvalid({valid, shouldValidate, isFormValid}) {
    return !valid && shouldValidate && !isFormValid
}
const Input = props => {
    const inputType = props.type || "text";
    const htmlFor = `${Math.random()}`;
    const val = props.value || "";
    const inpWidth = `${(val.length + 1) * 8 }px`;
    let stl;
    let goTo;
    {stl = (isInvalid(props)) ? "1px solid #f4516c" : null}
    /*switch(inputType){
        case "email": goTo = <a onClick={() => props.openEntity("SendMail", {from: props.value} )} data-tooltip="Отправить email" ><i className="flaticon-email"/></a>; break
    }*/
    if(props.del){ goTo = <a className="delInpBut" onClick={() => props.deleteFunc()} data-tooltip="Удалить" ><i className="flaticon-delete-1"/></a> }
    return <div style={ props.style }  className={props.className}>
        {
            (props.label) ? <label data-tooltip={props.tooltip} style={{display: "block"}} htmlFor={htmlFor}>
                {(props.tooltip) ? <div className="squerTooltipe"/> : null} {props.label} {(props.required) ? <span style={{color: "red"}}>*</span> : null}</label> : null
        }
        <div id="inputParrent" className="inputParrent">
            {goTo}
        <input
            style={{border: stl, width: (props.multiSelect) ? inpWidth : ''}}
            id={props.id || htmlFor}
            type={inputType}
            defaultValue={props.defaultValue}
            value={props.value}
            className={props.className}
            onClick={props.onClick}
            onChange={props.onChange}
            onKeyUp={props.onKeyUp}
            onKeyDown={props.onKeyDown}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            placeholder={props.placeholder}
            disabled={props.disabled}
            min={props.min}
            max={props.max}
            title={props.value}
            autocomplete={props.autocomplete}
            name={props.name}
        />
            {inputType === "withColor" && <input type="color" onChange={props.onChange} value={props.value} style={{
                position:"absolute", right: 0, top: 0
            }} />}
            {props.children}
        </div>
        {
            (isInvalid(props)) ? <span className="errorMsg">{(props.errorMessage) || "Заполните поле"}</span> : null
        }

    </div>
};

export default Input;