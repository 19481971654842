import React, {Component} from 'react';
import Input from '../../components/UI/Input';
import Myselect from '../../components/UI/Select';
import AutoSelect from '../../components/UI/AutoSelect';
import ModAutoSelect from '../../components/UI/ModAutoSelect';

const WrapForm = props => {
  return <form id="leadform" className="sbform">
      <Input
          label="Название"
          type="text"
          errorMessage={'Поле не может содержать меньше 3-х символов'}
          value={props.nameValue}
          onChange={event => props.setField(event, "name")}
          shouldValidate={true}
          valid={props.nameValid}
          isFormValid={props.isFormValid}
      />
      <Myselect
          label="Тип"
          value={props.typeValue}
          options={props.typeOptions}
          onChange={event => props.setField(event, "type")}
          shouldValidate={false}
          val="id"
          lab="value"
      />
      <Input
          label="Отрасль"
          type="text"
          errorMessage={'Поле не может содержать меньше 3-х символов'}
          value={props.industry}
          onChange={event => props.setField(event, "industry")}
          shouldValidate={false}
          isFormValid={props.isFormValid}
      />
      <Input
          label="Персональная скидка (%)"
          type="number"
          errorMessage={'Поле не может содержать меньше 3-х символов'}
          value={props.personal_discount.value}
          onChange={event => {if(event.target.value >= 0 && event.target.value <= 100) props.setField(event, "personal_discount")}}
          shouldValidate={false}
      />
      {/*ownerLink={`/contact/${this.state.formControl.owner.arr ? (this.state.formControl.owner.arr.contact ? this.state.formControl.owner.arr.contact.id : null) : null}`}*/}
      <ModAutoSelect
          required
          label="Ответственный"
          link="/ajax/settings/user?filter[name][like]="
          minLength="0"
          result="name"
          event="owner"
          valid={props.formControl.owner_id.valid}
          shouldValidate={true}
          addType="owner"
          entity={props.formControl.owner_id.arr}
          entityName="name"
          updateData={props.updateData}
          unLink={props.unLink}
          //ownerLink={props.owner ? props.owner.contact : null}
      />
  </form>
};

export default WrapForm;