import React from "react"
import ListComponent from "./ListComponent";
import {NavLink} from "react-router-dom";

export const LeadListOther = (props) => {
    return (<ListComponent {...props} name="lead" />)
}

export const OpportunityListOther = (props) => {
    return (<ListComponent {...props} name="opportunity" />)
}

export const TaskListOther = (props) => {
    return (<ListComponent {...props} name="task" />)
}

export const OrderListOther = (props) => {
    return (<ListComponent {...props} name="order" />)
}

export const AccountListOther = (props) => {
    return (<ListComponent {...props} name="account" />)
}

export const ContactListOther = (props) => {
    return (<ListComponent {...props} name="contact" />)
}

export const InvoiceListOther = (props) => {
    return (<ListComponent {...props} name="invoice" />)
}

export const CaseListOther = (props) => {
    return (<ListComponent {...props} name="cases" />)
}

export const ProjectListOther = (props) => {
    return (<ListComponent {...props} name="project" />)
}

export const DocumentListOther = (props) => {
    return (<ListComponent {...props} name="document" />)
}

export const ProductListOther = (props) => {
    return (<ListComponent {...props} name="product" />)
}

export const PurchaseListOther = (props) => {
    return (<ListComponent {...props} name="purchase" />)
}

export const ReplanishListOther = (props) => {
    return (<ListComponent {...props} name="replanish" />)
}

export const ShipmentListOther = (props) => {
    return (<ListComponent {...props} name="shipment" />)
}

export const TransferListOther = (props) => {
    return (<ListComponent {...props} name="transfer" />)
}

export const ConsumptionListOther = (props) => {
    return (<ListComponent {...props} name="consumption" />)
}

export const StockReturnListOther = (props) => {
    return (<ListComponent {...props} name="stockReturn" />)
}

export const StocktakeListOther = (props) => {
    return (<ListComponent {...props} name="stocktake" />)
}