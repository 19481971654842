import React, {useEffect, useState} from "react";
import Modal from "../../components/Modal/Modal";
import {GenerateForm} from "../../components/cardViewComponents/GenerateForm";
import {validationData} from "../../components/UI/formValid";
import {validationConfig} from "../../components/cardViewComponents/validationConfig";
import Notif from "../../components/Notif";
import {getCasePrepareService, createCaseService} from "../../services/case/caseService";
import {createEvent} from "../../services/other/events";

const fieldsConfig = {
    number: {
        label: 'Номер обращения',
        type: 'text',
        className: 'w100',
        validation: { required: true, maxLength: 255 },
        zone: 1
    },
    name: {
        label: 'Тема обращения',
        type: 'text',
        className: 'w100',
        validation: { required: true, maxLength: 255 },
        zone: 1
    },
    priority: {
        label: 'Приоритет обращения',
        type: 'select',
        options: [],
        options_from: 'priorities',
        className: 'w100',
        validation: { required: true },
        zone: 1
    },
    status_id: {
        label: 'Статус обращения',
        type: 'select',
        options: [],
        className: 'w100',
        options_from: 'statuses', val: 'id', lab: 'value',
        validation: { required: true },
        zone: 1,
    },
    type_id: {
        label: 'Тип обращения',
        type: 'select',
        options: [],
        className: 'w100',
        options_from: 'types', val: 'id', lab: 'value',
        zone: 1,
    },
    owner_id: {
        label: 'Ответственный',
        type: 'entity',
        link: "/ajax/settings/user?filter[name][like]=",
        result: 'name',
        entity_from: 'owner',
        entityName: 'name',
        className: 'w100',
        minLength: 0,
        zone: 1
        // redirectLink
    },
}

export const CaseModal = ({ isOpen, close, history, data: modalData = {}, save }) => {
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState({})
    const [disabled, setDisabled] = useState({})
    const [values, setValues] = useState({
        number: '',
        name: '',
        priority: 'low',
        priorities: [
            { value: 'low', label: 'Низкий' },
            { value: 'medium', label: 'Средний' },
            { value: 'high', label: 'Высокий' },
        ],
        status_id: '',
        statuses: [],
        type_id: '',
        types: [],
        owner_id: '',
        owner: [],
        tch: true
    })

    const getPrepare = async () => {
        const { status, data } = await getCasePrepareService()
        if((status === 200 || status === 201) && data) {
            setValues({
                ...values,
                number: 'Тикет №' + data.nextId,
                statuses: data.statuses,
                status_id: data.statuses[0].id,
                types: data.types,
                type_id: data.types[0].id
            })
        }
        setLoading(false)
    }

    const saveItem = async () => {
        const errorsData = validationData(values, validationConfig(fieldsConfig));
        setErrors(errorsData)
        if(Object.keys(errorsData).length > 0) {
            Notif('required-fields')
            return false;
        }

        setLoading(true)
        const { name, number, status_id, type_id, priority, owner_id } = values
        const { status, data } = await createCaseService({ data: {
            name, number, status_id, type_id, priority, owner_id, contact_id: modalData && modalData.contact_id, account_id: modalData && modalData.account_id,
        } })
        if((status === 200 || status === 201) && data.id) {
            if(save) {
                createEvent({ user_id: window.location.host, user_name: "---", event_type: "createCase" }).then();
                save(); setTimeout(close, 200);
            } else {
                createEvent({ user_id: window.location.host, user_name: "---", event_type: "createCase" }).then();
                history.push(`/case/${data.id}`)
            }
            Notif('save')
        }
        setLoading(false)
    }

    useEffect(() => {
        getPrepare().then()
    }, [])

    const changeFields = (key, value, item) => {
        let obj = {}
        if(item) {
            if(item === 'clear') obj[fieldsConfig[key].entity_from] = null
            else obj[fieldsConfig[key].entity_from] = item
        }

        setValues({ ...values, ...obj, [key]: item ? item.id : value, tch: false })
    }

    return (
        <Modal isOpen={isOpen} save={saveItem} close={close} loading={loading} showLeaveModal tch={values.tch} title={'Создать лид'}>
            <GenerateForm
                config={fieldsConfig}
                { ...{ values, errors, disabled } }
                onChange={changeFields}
                onSaveData={saveItem}
            />
        </Modal>
    )
}