import React, {useState} from "react";
import Modal from "../components/Modal";
import Loader from "../components/UI/Loader";
import {FieldGen, isFormValid, postDt, setField} from "../components/fieldGen";
import myaxios from "../components/Axios";
import Notif from "../components/Notif";
import {withRouter} from "react-router-dom";
import entityFields from '../components/bp/actions/entityFieldsArr'
import {NewAutoSelect} from "../components/NewUi/NewAutoSelect";


const AddRule = ({ isOpen, close, history }) => {
    const [state, setMyState] = useState({
        formControl: {
            name: {
                type: 'input',
                label: 'Название правила',
                value:'',
                inputType:'text',
                valid: true,
                validation: {required: true},
                zone: 1
            },
            object_type: {
                type: 'select',
                label: 'Раздел',
                post: "number",
                value:'23',
                options:[
                    {value:"23", label:"Лиды"},
                    {value:"1", label:"Сделки"},
                    {value:"14", label:"Заказы"},
                    {value:"3", label:"Контрагенты"},
                    {value:"2", label:"Контакты"},
                    {value:"13", label:"Счета"},
                    {value:"6", label:"Проекты"},
                    {value:"26", label:"Документы"},
                    /*{value:"27", label:"Поддержка"},*/

                ],
                valid: true,
                validation: {required: true},
                zone: 1
            },
        },
        loading:false,
        valid:false
    })
    const [leadFunnel, setLeadFunnel] = useState(null)

    const setState = (st, func) => {
        Object.keys(st).map((key) => { state[key] = st[key]});
        setMyState(prevState => { return {...prevState, ...state} });
        if(func) func();
    };

    const postData = () => {
        if(!state.valid) return
        if(state.formControl.object_type.value === '23' && !leadFunnel) {
            Notif('Error', 'Для лида должна быть обязательно указана воронка')
            return;
        }

        setState({ tch: true, loading: true })
        const postData = postDt(state.formControl)
        if(state.formControl.object_type.value === '23') {
            postData.sales_funnel_id = leadFunnel.id
        }
        myaxios.post(`/ajax/businessrule`, postData).then(res => {
            if(!res) return Notif("Error");
            if(res.status === 200 || res.status === 201){
                Notif("save")
                history.push(`businessrule/${res.data.id}`)
            }
            setState({ loading: false })
        }).catch(error => setState({ loading: false }))
    }


    return state.loading ? <Loader contain /> : <Modal save={() => setState(isFormValid(state), postData())} isOpen={isOpen} close={close} title="Добавить правило">
        <FieldGen
            arr={state.formControl}
            setField={(e, type) => setState({formControl: setField(state.formControl, type, e)})}
        />
        {state.formControl.object_type.value === '23' && <NewAutoSelect
            label={'Категория'}
            searchBy={'name'}
            entity={leadFunnel}
            link={'/ajax/sales-funnel?filter[name][like]='}
            updateData={(leadFunnel) => setLeadFunnel(leadFunnel)}
            onEnterData={(leadFunnel) => setLeadFunnel(leadFunnel)}
            clear
        />}
    </Modal>
}

export default withRouter(AddRule)