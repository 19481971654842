import React, {useEffect, useState} from "react";
import {FieldGen, setField, unLink, updateData} from "../../fieldGen";
import formValid from "../../UI/formValid";
import { Picker } from 'emoji-mart';
import { stat } from "fs";


export const SocialMsg = props => {
    const [state, setMyState] = useState({
        id: null,
        formControl: {
            name: {
                type: 'input',
                label: 'Название',
                value: '',
                valid: true,
                validation: {required: true}
            },
            time_value: {
                type: 'input',
                label: 'Отложенный запуск на N секунд',
                tooltip: 'Процесс запустится через N секунд, после того, как сработает триггер',
                inputType: 'number',
                value: '',
                valid: true,
                validation: {required: false}
            },
            type: {
                type: 'select',
                label: 'Тип мессенджера',
                value: '',
                options:[
                    {value:'facebook', label:'Facebook'},
                    {value:'telegram', label:'Telegram'},
                    {value:'viber', label:'Viber'},
                ],
                valid: true,
                empty: true,
                validation: {required: true}
            },
            bot_id: {
                type: 'input',
                tooltip: 'ID бота, который будет отправлять сообщения в мессенджере. ID бота вы можете узнать в разделе настройки -> CRM маркетинг -> мессенджеры -> настройки',
                label: 'Bot ID',
                inputType: 'number',
                value: '',
                valid: true,
                validation: {required: false}
            },
            text: {
                value:'',
                valid: true,
                validation: {required: true}
            }
        }
    });
    const setState = (st, func) => {
        Object.keys(st).map((key) => { state[key] = st[key]});
        setMyState(prevState => { return {...prevState, ...state} });
        if(func) func();
    };
    useEffect(() => {
        getParams();
    },[]);

    const getParams = () => {
        if(props.elArr){
            let formControl = state.formControl;
            formControl.name.value = props.elArr.metaData ? props.elArr.name : '';
            formControl.type.value = props.elArr.metaData ? props.elArr.metaData.type : '';
            formControl.time_value.value = props.elArr.metaData ? props.elArr.time_value : '';
            formControl.bot_id.value = props.elArr.metaData ? props.elArr.metaData.bot_id : '';
            formControl.text.value = props.elArr.metaData ? props.elArr.metaData.text : '';
            setState({ formControl, id: props.elArr.id || null })
        }
    }

    const isFormValid = () => {
        let formControl = state.formControl;
        let isFormValid = true;
        Object.keys(formControl).map((key, index) => {
            formControl[key].valid = formValid(formControl[key].value, formControl[key].validation);
            isFormValid = formControl[key].valid && isFormValid
        });
        setState({formControl});
        return isFormValid;
    };

    const sendForm = () => {
        if(isFormValid()){
            if(props.elArr){
                setTimeout(
                    props.updateNode({
                        id:state.id,
                        name: state.formControl.name.value,
                        time_value: state.formControl.time_value.value,
                        is_scheduled: (state.formControl.time_value.value > 1),
                        metaData: {
                            type: state.formControl.type.value,
                            bot_id: state.formControl.bot_id.value,
                            text: state.formControl.text.value,
                        },
                        nodeType: "node",
                        type:"sendMessenger",
                    })
                )
            } else {
                setTimeout(
                    props.createNode({
                        name: state.formControl.name.value,
                        time_value: state.formControl.time_value.value,
                        is_scheduled: (state.formControl.time_value.value > 1),
                        metaData: {
                            type: state.formControl.type.value,
                            bot_id: state.formControl.bot_id.value,
                            text: state.formControl.text.value,
                        },
                        nodeType: "node",
                        type:"sendMessenger",
                    })
                )
            }
        }
    };

    return <div>
        <div className="rmod-header">
            <b>Отправить сообщения в мессенджеры</b>
            <a onClick={props.close} style={{ marginRight: "60px" }} className="mclose"><i className="flaticon-close" /></a>
            <div className="togglelist"><button onClick={sendForm} className="rmod-ok"><i className="flaticon-interface-1" /></button></div>
        </div>
        <div className="mod-cont sbform">
            <FieldGen
                arr={state.formControl}
                setField={(e, type) => {
                    setState({ formControl: setField(state.formControl, type, e) })
                }}
                updateData={(id, arr, type) => setState({ formControl: updateData(state.formControl,id, arr, type) })}
                unLink={(type) => setState({ formControl: unLink(state.formControl, type)})}
            />
            <FlowTextarea
                label={"Текст сообщения"}
                value={state.formControl.text.value}
                valid={state.formControl.text.valid}
                onChange={(e) => {
                    setState({ formControl: setField(state.formControl, "text", e.target.value) })
                }}
                isEmoji
                paste={(e, t) => {
                    const formControl = state.formControl;
                    formControl.text.value = formControl.text.value + '' + t;
                    setState({ formControl })
                }}
            />
        </div>
    </div>
}

const FlowTextarea = props => {
    return <div>
        {
            (props.label) ? <label data-tooltip={props.tooltip} style={{display: "block"}}>
                {(props.tooltip) ? <div className="squerTooltipe"/> : null} {props.label} {(props.required) ? <span style={{color: "red"}}>*</span> : null}</label> : null
        }
        <div className="flowTextarea flowInput" style={{ border: props.valid ? null : '1px solid #f4516c' }}>
            <textarea rows={4} value={props.value} onChange={props.onChange} />
            {props.isPaste ? <b><i className="flaticon-network" />
                <ul className="flowToogleBlock scrollbar">
                    {
                        props.parseType ? props.parseType.map((paste, indexp) =>
                            <li key={indexp}><a onClick={() => props.paste(props.value, paste.value)}>{paste.label}</a></li>
                        ) : null
                    }
                </ul>
            </b> : null}
            {props.isEmoji ? <b><i className="flaticon-user" />
                <ul style={{ maxHeight: 350 }} className="flowToogleBlock scrollbar">
                    <Picker onSelect={(a) => props.paste(props.value, a.native)}  i18n={{ search: 'Recherche', categories: { search: 'Résultats de recherche', recent: 'Récents' } }} />
                </ul>
            </b> : null}
        </div>
        {props.valid ? null : <span className="errorMsg">{(props.errorMessage) || "Заполните поле"}</span>}
    </div>
}