import React, {useEffect, useState} from "react";
import Modal from "../../Modal/Modal";
import {FieldGen, setField, unLink, updateData} from "../../fieldGen";
import {CSSTransition} from "react-transition-group";

const AddPaymentAction = ({ close, isOpen, elArr, createNode, updateNode }) => {
    const [state, setMyState] = useState({
        formControl: {
            name: {
                type: 'input',
                label: 'Название платежа',
                value: '',
                valid: true,
                validation: {required: true}
            },
            amount: {
                type: 'input',
                label: 'Сумма',
                value: '',
                valid: true,
                cls:'c4',
                validation: {required: true}
            },
            currency_id: {
                label: 'Валюта',
                type: 'input',
                value: '',
                valid: true,
                cls:'c4',
                validation: {required: true}
            },
            exchange_rate: {
                type: 'input',
                label: 'Курс в б.в.',
                tooltip:'Курс конвертации в валюту счета',
                value: '1',
                valid: true,
                cls:'c4',
                validation: {required: true}
            },
            balance_id: {
                label: 'Кошелек ID',
                type: 'input',
                value: '1',
                valid: true,
                cls:'c12',
                validation: {required: true}
            },
            status: {
                type:'select',
                cls:'c12',
                label:'Статус',
                value:'1',
                valid:true,
                options:[
                    {value:'1', label:'Новый'},
                    {value:'2', label:'Истек'},
                    {value:'3', label:'Ожидает'},
                    {value:'4', label:'Оплачен'},
                    {value:'5', label:'Неудачный'},
                ]
            },
            payment_date: {
                type: 'input',
                label: 'Дата оплаты',
                value: '',
                valid: true,
                cls:'c12',
                validation: {required: true}
            },
        },
        valid: false
    })

    const setState = (st, func) => {
        Object.keys(st).map((key) => { state[key] = st[key]});
        setMyState(prevState => { return {...prevState, ...state} });
        if(func) func();
    };

    const sendForm = () => {
        let arr = {
            name: state.formControl.name.value,
            time_value: 0,
            is_scheduled: false,
            metaData: {
                data: {
                    fields: [
                        {field: "name", value: state.formControl.name.value, object: "string"},
                        {field: "amount", value: state.formControl.amount.value, object: "string"},
                        {field: "currency_id",value: state.formControl.currency_id.value, object: "string"},
                        {field: "exchange_rate",value: state.formControl.exchange_rate.value, object: "string"},
                        {field: "balance_id",value: state.formControl.balance_id.value, object: "string"},
                        {field: "status",value: state.formControl.status.value, object: "string"},
                        {field: "payment_date",value: state.formControl.payment_date.value, object: "string"},
                        {field: "invoice_id", value:"{{entity.id}}", object: "string"},
                    ]
                }
            },
            nodeType: "node",
            type:"createPayment",
        }
        if(elArr) arr.id = state.id;
        (elArr) ? updateNode(arr) : createNode(arr)
    }

    const getParams = () => {
        let formControl = state.formControl
        Object.keys(formControl).map(key => {
            const val = elArr.metaData.data.fields.filter(item => item.field === key)[0]
            formControl[key].value = val ? val.value : ''
        })
        setState({ formControl, id: elArr.id })
    }

    useEffect(() => {
        if(elArr) getParams()
    }, [])

    return (
        <Modal title="Добавить платеж" close={close} isOpen={isOpen} save={sendForm}>
                <FieldGen
                    arr={state.formControl}
                    setField={(e, type) => setState({formControl: setField(state.formControl, type, e)})}
                    updateData={(id, arr, type) => {
                        const fc = updateData(state.formControl,id, arr, type);
                        if(type === "currency_id") fc.exchange_rate.value = arr.value / state.baseCurrencyCurs;
                        setState({ formControl: fc })
                    } }
                    unLink={(type) => setState({ formControl: unLink(state.formControl, type)})}
                />
        </Modal>
    )
}

export default AddPaymentAction