import React, {Component} from 'react';
import Input from '../components/UI/Input';
import Myselect from '../components/UI/Select';
import Textarea from '../components/UI/Textarea';
import DateTime from '../components/UI/DateTime';
import {withRouter} from 'react-router-dom';
import formValid from '../components/UI/formValid';
import Notif from '../components/Notif';
import myaxios from '../components/Axios';
import AutoSelect from '../components/UI/AutoSelect';
import ModAutoSelect from '../components/UI/ModAutoSelect';
import Loader from '../components/UI/Loader';
import {createEvent} from "../services/other/events";

const Components = {
    AddAccount: {
        title:'Добавить контрагента',
        formControl: {
            name: {
                label: 'Название',
                value:'',
                valid: true,
                validation: {required: true},
                errorMessage:'Поле обязательно для заполнения',
                field: 'autoselect',
                type:'text',
                link: '/ajax/account?filter[name][like]=',
                minLength: 1,
                result: 'name',
                event: 'name',
                redirect:'/account/'
            },
            phone: {
                label: 'Номер телефона',
                value:'',
                valid: true,
                validation: {required: false},
                errorMessage:'Поле обязательно для заполнения',
                field: 'autoselect',
                type:'number',
                link: '/ajax/account?filter[phone][like]=',
                minLength: 3,
                result: 'phone',
                event: 'phone',
                redirect:'/account/'
            },
            email: {
                label: 'Email',
                value:'',
                valid: true,
                validation: {required: false, email:true},
                errorMessage:'Введите корректный email',
                field: 'autoselect',
                type:'text',
                link: '/ajax/account?filter[email][like]=',
                minLength: 3,
                result: 'email',
                event: 'email',
                redirect:'/account/'
            },
            website: {
                label: 'Сайт',
                value:'',
                valid: true,
                validation: {required: false, website: true},
                errorMessage:'Введите корректный URL',
                field: 'autoselect',
                type:'text',
                link: '/ajax/account?filter[website][like]=',
                minLength: 5,
                result: 'website',
                event: 'website',
                redirect:'/account/'
            },
            type_id: {
                label: 'Тип компании',
                value:'',
                valid: true,
                validation: {required: false},
                arr:null,
                errorMessage:'Поле обязательно для заполнения',
                field: 'modautoselect',
                type:'text',
                link: '/ajax/lookup/search?type=acc_type&value=',
                minLength: 0,
                result: 'value',
                event: 'value',
                entityName:'value',
                addType:'type_id',
                redirect:null
            },
            owner_id: {
                label: 'Ответственный',
                value:'',
                valid: true,
                validation: {required: false},
                arr:null,
                errorMessage:'Поле обязательно для заполнения',
                field: 'modautoselect',
                type:'text',
                link: "/ajax/settings/user?filter[name][like]=",
                minLength: 0,
                result: 'name',
                event: 'owner_id',
                entityName:'name',
                addType:'owner_id',
                redirect:null
            },
        },
        post: {
            link:'/ajax/account',
            data:['name', 'website', 'phone', 'email', 'type_id', 'owner_id'],
            redirect: '/account/'
        },
    },



    AddContact: {
        title:'Добавить контакт',
        formControl: {
            first_name: {
                label: 'Имя',
                value:'',
                valid: true,
                validation: {required: true},
                field: 'autoselect',
                className:"first_field",
                type:'text',
                link: '/ajax/contact?filter[name][like]=',
                minLength: 1,
                result: 'name',
                event: 'name',
                redirect:'/contact/'
            },
            last_name: {
                label: 'Фамилия',
                value:'',
                valid: true,
                validation: {required: false},
                field: 'autoselect',
                className:"second_field",
                type:'number',
                link: '/ajax/contact?filter[name][like]=',
                minLength: 3,
                result: 'name',
                event: 'name',
                redirect:'/contact/'
            },
            mobile_phone: {
                label: 'Номер телефона',
                value:'',
                valid: true,
                validation: {required: false, number: true},
                errorMessage:'Введите корректный номер',
                field: 'autoselect',
                type:'text',
                link: '/ajax/contact?filter[mobile_phone][like]=',
                minLength: 3,
                result: 'mobile_phone',
                event: 'mobile_phone',
                redirect:'/contact/'
            },
            email: {
                label: 'Email',
                value:'',
                valid: true,
                validation: {required: false, email:true},
                errorMessage:'Введите корректный URL',
                field: 'autoselect',
                type:'text',
                link: '/ajax/contact?filter[email][like]=',
                minLength: 5,
                result: 'email',
                event: 'email',
                redirect:'/contact/'
            },
            type_id: {
                label: 'Тип контакта',
                value:'',
                valid: true,
                validation: {required: false},
                arr:null,
                field: 'modautoselect',
                type:'text',
                link: '/ajax/lookup/search?type=ct_type&value=',
                minLength: 0,
                result: 'value',
                event: 'value',
                entityName:'value',
                addType:'type_id',
                redirect:null
            },
            owner_id: {
                label: 'Ответственный',
                value:'',
                valid: true,
                validation: {required: false},
                arr:null,
                field: 'modautoselect',
                type:'text',
                link: "/ajax/settings/user?filter[name][like]=",
                minLength: 0,
                result: 'name',
                event: 'owner_id',
                entityName:'name',
                addType:'owner_id',
                redirect:null
            },
        },
        post: {
            link:'/ajax/contact',
            data:['name', 'website', 'phone', 'email', 'type_id', 'owner_id'],
            redirect: '/contact/'
        },
    },



    Product: {
        title:'Добавить продукт',
        post: {
            link:'/ajax/product',
            data:['name', 'website', 'sku', 'phone', 'email', 'type_id', 'owner_id'],
            redirect: '/product/'
        },
        prepare: {
            link: '/ajax/product/prepare',
            data:[
                {name:'currency_id', to:'options', from:'currencies'},
                {name:'unit_id', to:'options', from:'units'},
                {name:'category_id', to:'options', from:'categories'},
            ]
        },
        formControl: {
            category_id: {
                label: 'Категория',
                value:'',
                valid: true,
                validation: {required: true},
                arr:null,
                field: 'modautoselect',
                type:'text',
                link: "/ajax/lookup/search?type=category&value=",
                minLength: 0,
                result: 'name',
                event: 'category_id',
                entityName:'name',
                addType:'category_id',
                redirect:null
            },
            name: {
                label: 'Название',
                value:'',
                valid: true,
                validation: {required: true},
                field: 'input',
                type:'text',
            },
            model: {
                label: 'Артикул',
                value:'',
                valid: true,
                validation: {required: false},
                field: 'input',
                type:'text',
            },
            base_price: {
                label: 'Базовая цена',
                value:'',
                valid: true,
                validation: {required: false},
                field: 'input',
                type:'number',
            },
            purchase_price: {
                label: 'Закупочная цена',
                value:'',
                valid: true,
                validation: {required: false},
                field: 'input',
                type:'number',
            },
            unit_id: {
                label: 'Единица измерения',
                value:'',
                valid: true,
                validation: {required: false},
                field: 'select',
                options:[],
                lab: 'value',
                val: 'id'
            },
            currency_id: {
                label: 'Валюта',
                value:'',
                valid: true,
                validation: {required: true},
                field: 'select',
                options:[],
                lab: 'text_symbol',
                val: 'id'
            },
            description: {
                label: 'Комментарий',
                value:'',
                valid: true,
                validation: {required: false},
                field: 'textarea',
                type:'text',
                rows:3,
                cols:30
            },
            sku: {
                value:''
            }
        }
    },



    AddDocument: {
        title:'Добавить документ',
        post: {
            link:'/ajax/document',
            data:['name', 'website', 'phone', 'email', 'type_id', 'owner_id'],
            redirect: '/document/'
        },
        prepare: {
            link: '/ajax/document/prepare',
            data:[
                {name:'status_id', to:'options', from:'statuses'},
                {name:'type_id', to:'options', from:'types'},
                {name:'name', to:'value', from:'nextId',prefix:'Документ №'},
            ]
        },
        rules:{required:'client', contact:'account', contactType: 'contact_id'},
        formControl: {
            name: {
                label: 'Название',
                value: '',
                valid: true,
                validation: {required: true},
                field: 'input',
                type: 'text',
            },
            status_id: {
                label: 'Статус договора',
                value: '',
                valid: true,
                validation: {required: false},
                field: 'select',
                options: [],
                lab: 'value',
                val: 'id'
            },
            type_id: {
                label: 'Тип договора',
                value: '',
                valid: true,
                validation: {required: false},
                field: 'select',
                options: [],
                lab: 'value',
                val: 'id'
            },
            account_id: {
                label: 'Контрагент',
                value:'',
                valid: true,
                validation: {required: false},
                arr:null,
                field: 'modautoselect',
                type:'text',
                link: "/ajax/account?filter[name][like]=",
                minLength: 0,
                result: 'name',
                event: 'account',
                entityName:'name',
                addType:'account_id',
                redirect:null
            },
            contact_id: {
                label: 'Контакт',
                value:'',
                valid: true,
                validation: {required: false},
                arr:null,
                field: 'modautoselect',
                type:'text',
                link: "/ajax/contact?filter[name][like]=",
                isAccount: true,
                minLength: 0,
                result: 'name',
                event: 'contact',
                entityName:'name',
                addType:'contact_id',
                redirect:null
            },
            owner_id: {
                label: 'Ответственный',
                value:'',
                valid: true,
                validation: {required: false},
                arr:null,
                field: 'modautoselect',
                type:'text',
                link: "/ajax/settings/user?filter[name][like]=",
                minLength: 0,
                result: 'name',
                event: 'owner_id',
                entityName:'name',
                addType:'owner_id',
                redirect:null
            },
            opportunity_id:{value:'', field:'hidden'},
            project_id:{value:'', field:'hidden'},
            order_id:{value:'', field:'hidden'},
        }
    },


    AddProject: {
        title:'Добавить проект',
        post: {
            link:'/ajax/project',
            data:['name', 'website', 'phone', 'email', 'type_id', 'owner_id'],
            redirect: '/project/'
        },
        prepare: {
            link: '/ajax/project/prepare',
            data:[
                {name:'name', to:'value', from:'nextId',prefix:'Проект №'},
            ]
        },
        formControl: {
            name: {
                label: 'Название проекта',
                value: '',
                valid: true,
                validation: {required: true},
                field: 'input',
                type: 'text',
            },
            type: {
                label: 'Тип проекта',
                value: "external",
                valid: true,
                validation: {required: true},
                field: 'select',
                options: [
                    {value: '', label: ""},
                    {value: 'external', label: "Внешний проект"},
                    {value: 'internal', label: "Внутренний проект"},
                    {value: 'escort', label: "Сопровождение"},
                ],
                lab: 'label',
                val: 'value'
            },
            owner_id: {
                label: 'Ответственный',
                value:'',
                valid: true,
                validation: {required: false},
                arr:null,
                field: 'modautoselect',
                type:'text',
                link: "/ajax/settings/user?filter[name][like]=",
                minLength: 0,
                result: 'name',
                event: 'owner_id',
                entityName:'name',
                addType:'owner_id',
                redirect:null
            },
            contact_id:{value:'', field:'hidden'},
            account_id:{value:'', field:'hidden'},
            opportunity_id:{value:'', field:'hidden'},
        }
    },
    AddOpportunity: {
        title:'Добавить сделку',
        post: {
            link:'/ajax/opportunity',
            data:['name', 'website', 'phone', 'email', 'type_id', 'owner_id'],
            redirect: '/opportunity/'
        },
        prepare: {
            link: '/ajax/opportunity/prepare',
            data:[
                {name:'name', to:'value', from:'nextId',prefix:'Сделка №'},
            ]
        },
        rules:{contact:'account', contactType: 'main_contact_id'},
        formControl: {
            name: {
                label: 'Название cделки',
                value: '',
                valid: true,
                validation: {required: true},
                field: 'input',
                type: 'text',
            },
            amount: {
                label: 'Бюджет',
                value: '',
                valid: true,
                validation: {required: false},
                field: 'input',
                type: 'number',
                className: 'c8',
            },
            currency_id: {
                label: 'Валюта',
                value:'',
                valid: true,
                validation: {required: false},
                arr:null,
                field: 'modautoselect',
                type:'text',
                link: "/ajax/lookup/search?type=currency&value=",
                minLength: 0,
                result: 'text_symbol',
                event: 'currency',
                entityName:'text_symbol',
                addType:'currency_id',
                className: 'c4',
                redirect:null
            },
            account_id: {
                label: 'Контрагент',
                value:'',
                valid: true,
                validation: {required: false},
                arr:null,
                //field: 'modautoselect',
                field:'hidden',
                type:'text',
                link: "/ajax/account?filter[name][like]=",
                minLength: 0,
                result: 'name',
                event: 'account',
                entityName:'name',
                addType:'account_id',
                hiddenIf: 'main_contact_id',
                redirect:null
            },
            main_contact_id: {
                label: 'Контакт',
                value:'',
                valid: true,
                validation: {required: false},
                arr:null,
                //field: 'modautoselect',
                field:'hidden',
                type:'text',
                link: "/ajax/contact?filter[name][like]=",
                isAccount: true,
                minLength: 0,
                result: 'name',
                event: 'contact',
                entityName:'name',
                addType:'main_contact_id',
                hiddenIf: 'main_contact_id',
                redirect:null
            },
            owner_id: {
                label: 'Ответственный',
                value:'',
                valid: true,
                validation: {required: false},
                arr:null,
                field: 'modautoselect',
                type:'text',
                link: "/ajax/settings/user?filter[name][like]=",
                minLength: 0,
                result: 'name',
                event: 'owner_id',
                entityName:'name',
                addType:'owner_id',
                redirect:null
            },
            project_id: { value:'', field: 'hidden' },
            order_id: { value:'', field: 'hidden' }
        }
    },
    AddCases: {
        title:'Добавить сделку',
        post: {
            link:'/ajax/case',
            data:['name', 'website', 'phone', 'email', 'type_id', 'owner_id'],
            redirect: '/case/'
        },
        prepare: {
            link: '/ajax/case/prepare',
            data:[
                {name:'number', to:'value', from:'nextId',prefix:'Тикет №'},
                {name:'type_id', to:'options', from:'types'},
                {name:'status_id', to:'options', from:'statuses'},
            ]
        },

        rules:{contact:'account', contactType: 'contact_id'},
        formControl: {
            number: {
                label: 'Название обращения',
                value: '',
                valid: true,
                validation: {required: true},
                field: 'input',
                type: 'text',
            },
            name: {
                label: 'Тема обращения',
                value: '',
                valid: true,
                validation: {required: true},
                field: 'input',
                type: 'text',
            },
            type_id: {
                label: 'Тип обращения',
                value: '',
                valid: true,
                empty: true,
                validation: {required: true},
                field: 'select',
                options: [],
                lab: 'value',
                val: 'id'
            },
            status_id: {
                label: 'Статус обращения',
                value: '',
                valid: true,
                empty: true,
                validation: {required: true},
                field: 'select',
                options: [],
                lab: 'value',
                val: 'id'
            },
            priority: {
                label: 'Приоритет',
                value: 'low',
                valid: true,
                empty: true,
                validation: {required: true},
                field: 'select',
                options:[
                    {value:'low', label:'Низкий'},
                    {value:'medium', label:'Средний'},
                    {value:'high', label:'Высокий'},
                ],
            },
            owner_id: {
                label: 'Ответственный',
                value:'',
                valid: true,
                validation: {required: false},
                arr:null,
                field: 'modautoselect',
                type:'text',
                link: "/ajax/settings/user?filter[name][like]=",
                minLength: 0,
                result: 'name',
                event: 'owner_id',
                entityName:'name',
                addType:'owner_id',
                redirect:null
            },
            contact_id: {value:'', field: 'hidden'},
            account_id: {value:'', field: 'hidden'},
        }
    },
    AddLead: {
        title:'Добавить лид',
        post: {
            link:'/ajax/lead',
            data:['name', 'website', 'phone', 'email', 'type_id', 'owner_id'],
            redirect: '/lead/'
        },
        prepare: {
            link: '/ajax/lead/prepare',
            data:[
                {name:'type_id', to:'options', from:'types'},
                {name:'need', to:'options', from:'needs'},
                {name:'name', to:'value', from:'nextId',prefix:'Лид №'},
            ]
        },
        formControl: {
            name: {
                label: 'Название лида',
                value:'',
                valid: true,
                validation: {required: true},
                field: 'autoselect',
                type:'text',
                link: '/ajax/lead?filter[name][like]=',
                minLength: 1,
                result: 'name',
                event: 'name',
                redirect:'/lead/',
                required: true
            },
            amount: {
                label: 'Бюджет',
                value: '',
                valid: true,
                validation: {required: false, number: true},
                field: 'input',
                type: 'number',
                className: 'c8',
            },
            currency_id: {
                label: 'Валюта',
                value:'',
                valid: true,
                validation: {required: false},
                arr:null,
                field: 'modautoselect',
                type:'text',
                link: "/ajax/lookup/search?type=currency&value=",
                minLength: 0,
                result: 'text_symbol',
                event: 'currency',
                entityName:'text_symbol',
                addType:'currency_id',
                className: 'c4',
                redirect:null
            },
            first_name: {
                label: 'Имя',
                value:'',
                valid: true,
                validation: {required: false},
                field: 'autoselect',
                type:'text',
                link: '/ajax/lead?filter[first_name][like]=',
                minLength: 1,
                result: 'first_name',
                event: 'first_name',
                redirect:'/lead/',
            },
            company: {
                label: 'Компания',
                value:'',
                valid: true,
                validation: {required: false},
                field: 'autoselect',
                type:'text',
                link: '/ajax/lead?filter[company][like]=',
                minLength: 1,
                result: 'company',
                event: 'company',
                redirect:'/lead/',
            },
            email: {
                label: 'Email',
                value:'',
                valid: true,
                validation: {required: false, email: true},
                field: 'autoselect',
                type:'text',
                link: '/ajax/lead?filter[email][like]=',
                minLength: 1,
                result: 'email',
                event: 'email',
                redirect:'/lead/',
            },
            phone: {
                label: 'Номер телефона',
                value:'',
                valid: true,
                validation: {required: false},
                field: 'autoselect',
                type:'text',
                link: '/ajax/lead?filter[phone][like]=',
                minLength: 1,
                result: 'phone',
                event: 'phone',
                redirect:'/lead/',
            },
            type_id: {
                label: 'Тип потребности',
                value: 'external',
                valid: true,
                validation: {required: false},
                field: 'select',
                empty: true,
                options: [],
                lab: 'value',
                val: 'id'
            },
            need: {
                label: 'Зрелость потребности',
                value: 'external',
                valid: true,
                validation: {required: false},
                field: 'select',
                empty: true,
                options: [],
                lab: 'value',
                val: 'id'
            },
            owner_id: {
                label: 'Ответственный',
                value:'',
                valid: true,
                validation: {required: false},
                arr:null,
                field: 'modautoselect',
                type:'text',
                link: "/ajax/settings/user?filter[name][like]=",
                minLength: 0,
                result: 'name',
                event: 'owner_id',
                entityName:'name',
                addType:'owner_id',
                redirect:null
            },
        }
    },


    AddOrder: {
        title:'Добавить заказ',
        post: {
            link:'/ajax/order',
            data:['name', 'website', 'phone', 'email', 'type_id', 'owner_id'],
            redirect: '/order/'
        },
        prepare: {
            link: '/ajax/order/prepare',
            data:[
                {name:'status_id', to:'options', from:'statuses'},
                {name:'name', to:'value', from:'nextId',prefix:'ORD-'},
            ]
        },
        formControl: {
            name: {
                label: 'Номер заказа',
                value: '',
                valid: true,
                validation: {required: true},
                field: 'input',
                type: 'text',
                required: true
            },
            status_id: {
                label: 'Статус заказа',
                value: 'external',
                valid: true,
                validation: {required: false},
                field: 'select',
                options: [],
                lab: 'value',
                val: 'id'
            },
            account_id: {
                label: 'Контрагент',
                value:'',
                valid: true,
                validation: {required: false},
                arr:null,
                //field: 'modautoselect',
                field:'hidden',
                type:'text',
                link: "/ajax/account?filter[name][like]=",
                minLength: 0,
                result: 'name',
                event: 'account',
                entityName:'name',
                addType:'account_id',
                hiddenIf: 'main_contact_id',
                redirect:null
            },
            contact_id: {
                label: 'Контакт',
                value:'',
                valid: true,
                validation: {required: false},
                arr:null,
                //field: 'modautoselect',
                field:'hidden',
                type:'text',
                link: "/ajax/contact?filter[name][like]=",
                isAccount: true,
                minLength: 0,
                result: 'name',
                event: 'contact',
                entityName:'name',
                addType:'contact_id',
                hiddenIf: 'contact_id',
                redirect:null
            },
            owner_id: {
                label: 'Ответственный',
                value:'',
                valid: true,
                validation: {required: false},
                arr:null,
                field: 'modautoselect',
                type:'text',
                link: "/ajax/settings/user?filter[name][like]=",
                minLength: 0,
                result: 'name',
                event: 'owner_id',
                entityName:'name',
                addType:'owner_id',
                redirect:null
            },
            opportunity_id:{value:'', field:'hidden'},
        }
    },


    AddPayment: {
        title:'Создать платеж',
        post: {
            link:'/ajax/invoice/payment',
            data:['name', 'website', 'phone', 'email', 'type_id', 'owner_id'],
            redirect: '/payment/'
        },
        /*prepare: {
            link: '/ajax/payment/prepare',
            data:[
                {name:'status_id', to:'options', from:'statuses'}
            ]
        },*/
        formControl: {
            name: {
                label: 'Название платежа',
                value: '',
                valid: true,
                validation: {required: true},
                field: 'input',
                type: 'text',
                required: true
            },
            amount: {
                label: 'Сумма оплаты',
                value: '',
                valid: true,
                validation: {required: false, number: true},
                field: 'input',
                type: 'number',
                className: 'c8',
            },
            currency_id: {
                label: 'Валюта',
                value:'',
                valid: true,
                validation: {required: false},
                arr:null,
                field: 'modautoselect',
                type:'text',
                link: "/ajax/lookup/search?type=currency&value=",
                minLength: 0,
                result: 'text_symbol',
                event: 'currency',
                entityName:'text_symbol',
                addType:'currency_id',
                className: 'c4',
                redirect:null
            },
            status_id: {
                label: 'Статус платежа',
                value: 1,
                valid: true,
                validation: {required: false},
                field: 'select',
                options: [
                    {value:'1',label:'Создан'},
                    {value:'2',label:'Истекший'},
                    {value:'3',label:'В ожидании'},
                ],
                //lab: 'value',
                //val: 'id'
            },
            date: {
                label: 'Дата платежа',
                className:'',
                event: 'date',
                value: new Date(),
                valid: true,
                isClearable: true,
                showTimeSelect: true,
                timeIntervals: 5,
                dateFormat: "dd-MMMM-yyyy HH:mm",
                field: 'date',
            },
            description: {
                label: 'Примечание',
                value: '',
                valid: true,
                validation: {required: false},
                field: 'textarea',
                type: 'text',
            },
            /*owner_id: {
                label: 'Ответственный',
                value:'',
                valid: true,
                validation: {required: false},
                arr:null,
                field: 'modautoselect',
                type:'text',
                link: "/ajax/settings/user?filter[name][like]=",
                minLength: 0,
                result: 'name',
                event: 'owner_id',
                entityName:'name',
                addType:'owner_id',
                redirect:null
            },*/
            account_id:{value:'', field:'hidden'},
            contact_id:{value:'', field:'hidden'},
            invoice_id:{value:'', field:'hidden'},
        }
    },


    Shipment: {
        title:'Добавить отгрузку',
        post: {
            link: '/ajax/stock/shipment',
            data: ['name', 'website', 'phone', 'email', 'type_id', 'owner_id'],
            redirect: '/stock/shipment/'
        },
        prepare: {
            link: '/ajax/stock/shipment/prepare',
            data:[
                {name:'name', to:'value', from:'nextId',prefix:'Отгрузка №'},
            ]
        },
        formControl: {
            name: {
                label: 'Название',
                value: '',
                valid: true,
                validation: {required: true},
                field: 'input',
                type: 'text',
                required: true
            },
            date: {
                label: 'Дата отгрузки',
                className:'',
                event: 'date',
                value: new Date(),
                valid: true,
                isClearable: true,
                showTimeSelect: true,
                timeIntervals: 5,
                dateFormat: "dd-MMMM-yyyy HH:mm",
                field: 'date',
            },
            order_id: {
                label: 'Заказ',
                value:'',
                valid: true,
                validation: {required: false},
                arr:null,
                field: 'modautoselect',
                type:'text',
                link: "/ajax/order?filter[name][like]=",
                minLength: 0,
                result: 'name',
                event: 'name',
                entityName:'name',
                addType:'order_id',
                redirect:null
            },
            stock_id: {
                label: 'Склад',
                value:'',
                valid: true,
                validation: {required: true},
                arr:null,
                field: 'modautoselect',
                type:'text',
                link: "/ajax/stock?filter[name][like]=",
                minLength: 0,
                result: 'name',
                event: 'name',
                entityName:'name',
                addType:'stock_id',
                redirect:null,
                required: true
            },
            // provider_id: {
            //     label: 'Поставщик',
            //     value:'',
            //     valid: true,
            //     validation: {required: false},
            //     arr:null,
            //     field: 'modautoselect',
            //     type:'text',
            //     link: "/ajax/account?filter[name][like]=",
            //     minLength: 0,
            //     result: 'name',
            //     event: 'name',
            //     entityName:'name',
            //     addType:'provider_id',
            //     redirect:null
            // },
            contact_id: {
                label: 'Контакт',
                value:'',
                valid: true,
                validation: {required: false},
                arr:null,
                field: 'modautoselect',
                type:'text',
                link: "/ajax/contact?filter[name][like]=",
                minLength: 0,
                result: 'name',
                event: 'name',
                entityName:'name',
                addType:'contact_id',
                redirect:null
            },
            account_id: {
                label: 'Контрагент',
                value:'',
                valid: true,
                validation: {required: false},
                arr:null,
                field: 'modautoselect',
                type:'text',
                link: "/ajax/account?filter[name][like]=",
                minLength: 0,
                result: 'name',
                event: 'name',
                entityName:'name',
                addType:'account_id',
                redirect:null
            },
            number:{value:1, field:'hidden'},
            is_completed:{value:0, field:'hidden'},
        }
    },



    Replanish: {
        title:'Добавить приход',
        post: {
            link: '/ajax/stock/replanish',
            data: ['name', 'website', 'phone', 'email', 'type_id', 'owner_id'],
            redirect: '/stock/replanish/'
        },
        prepare: {
            link: '/ajax/stock/replanish/prepare',
            data:[
                {name:'name', to:'value', from:'nextId',prefix:'Приход №'},
            ]
        },
        formControl: {
            name: {
                label: 'Название',
                value: '',
                valid: true,
                validation: {required: true},
                field: 'input',
                type: 'text',
                required: true
            },
            date: {
                label: 'Дата отгрузки',
                className:'',
                event: 'date',
                value: new Date(),
                valid: true,
                isClearable: true,
                showTimeSelect: true,
                timeIntervals: 5,
                dateFormat: "dd-MMMM-yyyy HH:mm",
                field: 'date',
            },
            order_id: {
                label: 'Заказ',
                value:'',
                valid: true,
                validation: {required: false},
                arr:null,
                field: 'modautoselect',
                type:'text',
                link: "/ajax/order?filter[name][like]=",
                minLength: 0,
                result: 'name',
                event: 'name',
                entityName:'name',
                addType:'order_id',
                redirect:null
            },
            stock_id: {
                label: 'Склад',
                value:'',
                valid: true,
                validation: {required: true},
                arr:null,
                field: 'modautoselect',
                type:'text',
                link: "/ajax/stock?filter[name][like]=",
                minLength: 0,
                result: 'name',
                event: 'name',
                entityName:'name',
                addType:'stock_id',
                redirect:null,
                required: true
            },
            provider_id: {
                label: 'Поставщик',
                value:'',
                valid: true,
                validation: {required: false},
                arr:null,
                field: 'modautoselect',
                type:'text',
                link: "/ajax/account?filter[name][like]=",
                minLength: 0,
                result: 'name',
                event: 'name',
                entityName:'name',
                addType:'provider_id',
                redirect:null
            },
            account_id: {
                label: 'Контрагент',
                value:'',
                valid: true,
                validation: {required: false},
                arr:null,
                field: 'modautoselect',
                type:'text',
                link: "/ajax/account?filter[name][like]=",
                minLength: 0,
                result: 'name',
                event: 'name',
                entityName:'name',
                addType:'account_id',
                redirect:null
            },
            number:{value:1, field:'hidden'},
            is_completed:{value:0, field:'hidden'},
        }
    },


    Transfer: {
        title:'Добавить перемещение',
        post: {
            link: '/ajax/stock/transfer',
            data: ['name', 'website', 'phone', 'email', 'type_id', 'owner_id'],
            redirect: '/stock/transfer/'
        },
        prepare: {
            link: '/ajax/stock/transfer/prepare',
            data:[
                {name:'name', to:'value', from:'nextId',prefix:'Перемещение №'},
            ]
        },
        formControl: {
            name: {
                label: 'Название',
                value: '',
                valid: true,
                validation: {required: true},
                field: 'input',
                type: 'text',
                required: true
            },
            date: {
                label: 'Дата отгрузки',
                className:'',
                event: 'date',
                value: new Date(),
                valid: true,
                isClearable: true,
                showTimeSelect: true,
                timeIntervals: 5,
                dateFormat: "dd-MMMM-yyyy HH:mm",
                field: 'date',
            },
            from_id: {
                label: 'Со склада',
                value:'',
                valid: true,
                validation: {required: true},
                arr:null,
                field: 'modautoselect',
                type:'text',
                link: "/ajax/stock?filter[name][like]=",
                minLength: 0,
                result: 'name',
                event: 'name',
                entityName:'name',
                addType:'from_id',
                redirect:null,
                required: true
            },
            to_id: {
                label: 'На склад',
                value:'',
                valid: true,
                validation: {required: true},
                arr:null,
                field: 'modautoselect',
                type:'text',
                link: "/ajax/stock?filter[name][like]=",
                minLength: 0,
                result: 'name',
                event: 'name',
                entityName:'name',
                addType:'to_id',
                redirect:null,
                required: true
            },
            account_id: {
                label: 'Контрагент',
                value:'',
                valid: true,
                validation: {required: false},
                arr:null,
                field: 'modautoselect',
                type:'text',
                link: "/ajax/account?filter[name][like]=",
                minLength: 0,
                result: 'name',
                event: 'name',
                entityName:'name',
                addType:'account_id',
                redirect:null
            },
            is_completed:{value:0, field:'hidden'},
        }
    },



    Purchase: {
        title:'Добавить закупку',
        post: {
            link: '/ajax/stock/purchase',
            data: ['name', 'website', 'phone', 'email', 'type_id', 'owner_id'],
            redirect: '/stock/purchase/'
        },
        prepare: {
            link: '/ajax/stock/purchase/prepare',
            data:[
                {name:'name', to:'value', from:'nextId',prefix:'Закупка №'},
                {name:'status_id', to:'options', from:'statuses'}
            ]
        },
        formControl: {
            name: {
                label: 'Название',
                value: '',
                valid: true,
                validation: {required: true},
                field: 'input',
                type: 'text',
                required: true
            },
            status_id: {
                label: 'Статус',
                value: 'external',
                valid: true,
                validation: {required: false},
                field: 'select',
                options: [],
                lab: 'value',
                val: 'id'
            },
            pickup_date: {
                label: 'Дата создания',
                className:'',
                event: 'pickup_date',
                value: new Date(),
                valid: true,
                isClearable: true,
                showTimeSelect: true,
                timeIntervals: 5,
                dateFormat: "dd-MMMM-yyyy HH:mm",
                field: 'date',
            },
            purchase_date: {
                label: 'Дата поставки',
                className:'',
                event: 'purchase_date',
                value: new Date(),
                valid: true,
                isClearable: true,
                showTimeSelect: true,
                timeIntervals: 5,
                dateFormat: "dd-MMMM-yyyy HH:mm",
                field: 'date',
            },
            stock_id: {
                label: 'Склад',
                value:'',
                valid: true,
                validation: {required: true},
                arr:null,
                field: 'modautoselect',
                type:'text',
                link: "/ajax/stock?filter[name][like]=",
                minLength: 0,
                result: 'name',
                event: 'name',
                entityName:'name',
                addType:'stock_id',
                redirect:null,
                required: true
            },
            provider_id: {
                label: 'Поставщик',
                value:'',
                valid: true,
                validation: {required: false},
                arr:null,
                field: 'modautoselect',
                type:'text',
                link: "/ajax/account?filter[name][like]=",
                minLength: 0,
                result: 'name',
                event: 'name',
                entityName:'name',
                addType:'provider_id',
                redirect:null
            },
            account_id: {
                label: 'Контрагент',
                value:'',
                valid: true,
                validation: {required: false},
                arr:null,
                field: 'modautoselect',
                type:'text',
                link: "/ajax/account?filter[name][like]=",
                minLength: 0,
                result: 'name',
                event: 'name',
                entityName:'name',
                addType:'account_id',
                redirect:null
            },
            owner_id: {
                label: 'Ответственный',
                value:'',
                valid: true,
                validation: {required: false},
                arr:null,
                field: 'modautoselect',
                type:'text',
                link: "/ajax/settings/user?filter[name][like]=",
                minLength: 0,
                result: 'name',
                event: 'owner_id',
                entityName:'name',
                addType:'owner_id',
                redirect:null
            },
            is_completed:{value:0, field:'hidden'},
        }
    },

    Consumption: {
        title:'Добавить списание',
        post: {
            link: '/ajax/stock/consumption',
            data: ['name', 'website', 'phone', 'email', 'type_id', 'owner_id'],
            redirect: '/stock/consumption/'
        },
        prepare: {
            link: '/ajax/stock/consumption/prepare',
            data:[
                {name:'name', to:'value', from:'nextId',prefix:'Списание №'},
            ]
        },
        formControl: {
            name: {
                label: 'Название',
                value: '',
                valid: true,
                validation: {required: true},
                field: 'input',
                type: 'text',
                required: true
            },
            date: {
                label: 'Дата списания',
                className:'',
                event: 'date',
                value: new Date(),
                valid: true,
                isClearable: true,
                showTimeSelect: true,
                timeIntervals: 5,
                dateFormat: "dd-MMMM-yyyy HH:mm",
                field: 'date',
            },
            stock_id: {
                label: 'Склад',
                value:'',
                valid: true,
                validation: {required: true},
                arr:null,
                field: 'modautoselect',
                type:'text',
                link: "/ajax/stock?filter[name][like]=",
                minLength: 0,
                result: 'name',
                event: 'name',
                entityName:'name',
                addType:'stock_id',
                redirect:null,
                required: true
            },
            provider_id: {
                label: 'Поставщик',
                value:'',
                valid: true,
                validation: {required: false},
                arr:null,
                field: 'modautoselect',
                type:'text',
                link: "/ajax/account?filter[name][like]=",
                minLength: 0,
                result: 'name',
                event: 'name',
                entityName:'name',
                addType:'provider_id',
                redirect:null
            },
            account_id: {
                label: 'Контрагент',
                value:'',
                valid: true,
                validation: {required: false},
                arr:null,
                field: 'modautoselect',
                type:'text',
                link: "/ajax/account?filter[name][like]=",
                minLength: 0,
                result: 'name',
                event: 'name',
                entityName:'name',
                addType:'account_id',
                redirect:null
            },
            is_completed:{value:0, field:'hidden'},
        }
    },


    Stocktake: {
        title:'Добавить инвентаризацию',
        post: {
            link: '/ajax/stock/stocktake',
            data: ['name', 'website', 'phone', 'email', 'type_id', 'owner_id'],
            redirect: '/stock/stocktake/'
        },
        prepare: {
            link: '/ajax/stock/stocktake/prepare',
            data:[
                {name:'name', to:'value', from:'nextId',prefix:'Инвентаризация №'},
            ]
        },
        formControl: {
            name: {
                label: 'Название',
                value: '',
                valid: true,
                validation: {required: true},
                field: 'input',
                type: 'text',
                required: true
            },
            date: {
                label: 'Дата создания',
                className:'',
                event: 'date',
                value: new Date(),
                valid: true,
                isClearable: true,
                showTimeSelect: true,
                timeIntervals: 5,
                dateFormat: "dd-MMMM-yyyy HH:mm",
                field: 'date',
            },
            stock_id: {
                label: 'Склад',
                value:'',
                valid: true,
                validation: {required: true},
                arr:null,
                field: 'modautoselect',
                type:'text',
                link: "/ajax/stock?filter[name][like]=",
                minLength: 0,
                result: 'name',
                event: 'name',
                entityName:'name',
                addType:'stock_id',
                redirect:null,
                required: true
            },
            owner_id: {
                label: 'Ответственный',
                value:'',
                valid: true,
                validation: {required: false},
                arr:null,
                field: 'modautoselect',
                type:'text',
                link: "/ajax/settings/user?filter[name][like]=",
                minLength: 0,
                result: 'name',
                event: 'owner_id',
                entityName:'name',
                addType:'owner_id',
                redirect:null
            },
            is_completed:{value:0, field:'hidden'},
        }
    },
};

class WrapModal extends Component {
    state = {
        formControl: Components[this.props.name].formControl,
        tch: false,
        loading: true,
        filter:[]
    };
    getProps = () => {
        const formControl = this.state.formControl;
        if(this.props.propsData){
            this.props.propsData.map(item => {

                if(item.to === 'value'){
                    formControl[item.name][item.to] = item.value !== null ? item.value : ''
                } else {
                    formControl[item.name][item.to] = item.value
                }
            })
        }
        this.setState({ loading: false })
    }
    getPrepeare = () => {
        if(Components[this.props.name].prepare){
            const pItems = Components[this.props.name].prepare;
            myaxios.get(pItems.link, {
                method: 'get',
            }).then((res) => {
                const formControl = this.state.formControl;
                pItems.data.map((item) => {
                    formControl[item.name][item.to] = res.data[item.from];
                    if(item.to === "options") formControl[item.name].value = res.data[item.from].length >= 1 ? res.data[item.from][0][formControl[item.name].val] : '';
                    if(item.prefix) formControl[item.name][item.to] = item.prefix + formControl[item.name][item.to]
                });
                this.setState({ formControl });
                if(this.props.id){
                    this.getProps();
                    this.getContent();
                } else {
                    this.getProps();
                    this.setState({ loading: false });
                }
            })
        } else {
            this.getProps();
            if(this.props.id){
                this.getContent();
            }
        }
    }

    componentDidMount(){
        const formControl = this.state.formControl;
        Object.keys(formControl).map((key, index) => {
            formControl[key].value = "";
            formControl[key].arr = null;
        });
        this.setState({ formControl })
        this.getPrepeare();
    }
    toArray = () => {
        let arr = [];
        const formControl = this.state.formControl;
        Object.keys(this.state.formControl).map((key, index) => {
            arr.push({
                fieldType: key,
                value: formControl[key].value,
                label: formControl[key].label,
                valid: formControl[key].valid,
                errorMessage: formControl[key].errorMessage,
                field: formControl[key].field,
                type:formControl[key].type,
                link: formControl[key].link,
                minLength: formControl[key].minLength,
                result: formControl[key].result,
                event: formControl[key].event,
                entityName: formControl[key].entityName,
                addType: formControl[key].addType,
                arr: formControl[key].arr,
                redirect: formControl[key].redirect,
                rows: formControl[key].rows,
                options: formControl[key].options,
                lab: formControl[key].lab,
                val: formControl[key].val,
                className: formControl[key].className,
                hiddenIf: formControl[key].hiddenIf,
                required: formControl[key].required,
                isClearable: formControl[key].isClearable,
                showTimeSelect: formControl[key].showTimeSelect,
                timeIntervals: formControl[key].timeIntervals,
                dateFormat: formControl[key].dateFormat,
                empty: formControl[key].empty,
            })
        });
        return arr;
    };

    postContent = () => {
        if(this.isFormValid() && this.rules()){
            this.setState({ tch: true, loading: true });
            let arr = {};
            Object.keys(this.state.formControl).map((key, index) => {
                if(this.state.formControl[key].field === "date"){
                    arr[key] = this.state.formControl[key].value.toLocaleString();
                } else {
                    arr[key] = this.state.formControl[key].value;
                }
            });

            myaxios.post(Components[this.props.name].post.link, arr).then(res => {
                if(res.status === 200 || res.status === 201 || res.status === 204){
                    if(this.props.name === "AddContact") setTimeout(() => this.props.setStep("contact", true, true), 500)

                    if(this.props.saveProduct) {
                        setTimeout(() => {this.props.saveProduct(res.data.id)}, 300)
                    } else {
                        if(Components[this.props.name].post.redirect){
                            this.props.history.push(Components[this.props.name].post.redirect + res.data.id);
                        }
                    }
                    Notif('save');
                    setTimeout(this.props.close, 300)
                    createEvent({ event_type: this.props.name, user_id: this.props.user_id }).then();
                }
                this.setState({ loading: false })
            }).catch(error => {
                this.setState({ loading: false })
            })
        } else {
            Notif('Fields', 'Заполните обязательные поля');
        }
    };
    getContent = () => {
        myaxios.get(Components[this.props.name].get.link + '/' + this.props.id).then(res => {
            const formControl = this.state.formControl;
            Components.sendMessage.get.data.map((item, index) => {
                formControl[item].value = res.data[item];
            });
            this.setState({ formControl, loading: false })
        })
    };
    setField = (type, e, event) => {
        const formControl = this.state.formControl;
        formControl[type].value = e;
        formControl[type].valid = formValid(formControl[type].value, formControl[type].validation)
        this.setState({ formControl, tch: false })
    }
    keyUp = (e, type) => {
        if(this.props.name === "AddContact"){
            if(e.keyCode === 9 && type === "first_name" && e.target.value.length >= 3){
                this.props.setStep(3)
            }
        }
    }
    isFormValid = () => {
        let formControl = this.state.formControl;
        let isFormValid = true;
        Object.keys(formControl).map((key, index) => {
            formControl[key].valid = formValid(formControl[key].value, formControl[key].validation)
            isFormValid = formControl[key].valid && isFormValid
        });
        this.setState({ formControl });
        return isFormValid;
    };
    rules = () => {
        let formValid = true;
        if(Components[this.props.name].rules){
            if(Components[this.props.name].rules.required === 'client'){
                if(this.state.formControl[Components[this.props.name].rules.contactType].arr === null && this.state.formControl.account_id.arr === null){
                    formValid = false;
                    Notif('Fields', 'Контрагент или контакт должны быть обязательно привязаны к сущности.');
                }
            }
        }
        return formValid;
    }
    updateData = (id,arr,type) => {
        const formControl = this.state.formControl;
        formControl[type].arr = arr;
        formControl[type].value = id;
        if(Components[this.props.name].rules && type === 'account_id'){
            if(Components[this.props.name].rules.contact === 'account'){
                formControl[Components[this.props.name].rules.contactType].link = `/ajax/contact?filter[account_id]=${id}&filter[name][like]=`;
                this.unLink([Components[this.props.name].rules.contactType]);
            }
        }
        this.setState({ formControl });
    };
    unLink = (type) => {
        const formControl = this.state.formControl;
        formControl[type].arr = null;
        formControl[type].value = '';
        if(Components[this.props.name].rules && type === 'account_id'){
            if(Components[this.props.name].rules.contact === 'account'){
                formControl[Components[this.props.name].rules.contactType].link = `/ajax/contact?filter[name][like]=`
            }
        }
        this.setState({ formControl });
    }
    render(){
        return this.state.loading ? <Loader contain /> : <div className="sbform create-activ">
            <div className="rmod-header"><b>{Components[this.props.name].title}</b>
                <a onClick={this.props.close} style={{ marginRight: 60 }} className="mclose"><i className="flaticon-close" /></a>
                <div className="togglelist"><button onClick={this.postContent} className="save-modal-button rmod-ok" disabled={this.state.tch}><i className="flaticon-interface-1" /></button></div>
            </div>
            <div className="mod-cont">
                {
                    this.toArray().map((item, index) =>
                        <Field
                            key={index}
                            item={item}
                            setField={(e, type, event) => this.setField(type, e, event)}
                            keyUp={(e) => this.keyUp(e, item.fieldType)}
                            updateDataAutoSelect={(id) => {
                                this.props.history.push(item.redirect + id);
                                setTimeout(this.props.close, 300);
                            }}
                            updateData={this.updateData}
                            unLink={this.unLink}
                        />
                    )
                }
            </div>
        </div>
    }

}

export default withRouter(WrapModal);

const Field = props => {
    const item = props.item;
    switch(item.field){
        case 'input':
            return <Input
                label={item.label}
                type={item.type || 'text'}
                valid={item.valid}
                value={item.value}
                shouldValidate={true}
                className={item.className}
                required={item.required}
                errorMessage={item.errorMessage}
                onChange={e => props.setField(e.target.value, item.fieldType, e)}
                onKeyDown={props.keyUp}
            />;
        case 'select':
            return <Myselect
                label={item.label}
                valid={item.valid}
                value={item.value}
                options={item.options}
                shouldValidate={true}
                className={item.className}
                errorMessage={item.errorMessage}
                onChange={e => props.setField(e.target.value, item.fieldType, e)}
                lab={item.lab}
                val={item.val}
                required={item.required}
                empty={item.empty}
            />;
        case 'modautoselect':
            return <div><ModAutoSelect
                label={item.label}
                link={item.link}
                minLength={item.minLength}
                result={item.result}
                event={item.event}
                valid={item.valid}
                shouldValidate={true}
                addType={item.addType}
                entity={item.arr}
                entityName={item.entityName}
                updateData={props.updateData}
                unLink={() => props.unLink(item.addType)}
                redirectLink={item.redirect}
                className={item.className}
                required={item.required}
                keyUp={props.keyUp}
            /> <div className="clear" /> </div>;
        case 'autoselect':
            return <AutoSelect
                label={item.label}
                link={item.link}
                minLength={item.minLength}
                result={item.result}
                event={item.event}
                errorMessage={item.errorMessage}
                value={item.value}
                valid={item.valid}
                shouldValidate={true}
                setField={e => props.setField(e.target.value, item.fieldType, e)}
                updateData={props.updateDataAutoSelect}
                className={item.className}
                required={item.required}
                onKeyDown={props.keyUp}
            />; break;
        case 'textarea':
            return <Textarea
                label={item.label}
                type={item.type || 'text'}
                valid={item.valid}
                value={item.value}
                shouldValidate={true}
                className={item.className}
                errorMessage={item.errorMessage}
                onChange={e => props.setField(e.target.value, item.fieldType, e)}
                cols={item.cols}
                rows={item.rows}
                required={item.required}
            />;
        case 'date':
            return <DateTime
                label={item.label}
                className={item.className}
                event={item.event}
                value={item.value}
                valid={item.valid}
                handleChange={e => props.setField(e, item.fieldType, e)}
                isClearable={item.isClearable}
                showTimeSelect={item.showTimeSelect}
                timeIntervals={item.timeIntervals}
                dateFormat={item.dateFormat}
                timeFormat="HH:mm"
            />;
        case 'hidden': return null;
        default: return <p>Не определено</p>
    }
}
