export const CrudGetSingleItemFunction = async ({ service, statusCode= 200, serviceName, serviceData }) => {
    try {
        const res = await service(serviceData)
        if (res && res.status === statusCode) {
            return {status: true, data: res.data}
        } else {
            console.error(`${serviceName} status error`)
            return {status: false}
        }
    } catch (e) {
        console.error(`${serviceName} catch error`, e)
        return { status: false }
    }
}


export const CrudUpdateFunction = async ({ service, statusCode = 200, serviceName, serviceData }) => {
    try {
        const res = await service(serviceData)
        if (res && res.status === statusCode) {
            return {status: true, data: res.data}
        } else {
            console.error(`${serviceName} status error`)
            return {status: false}
        }
    } catch (e) {
        console.error(`${serviceName} catch error`, e)
        return { status: false }
    }
}

export const CrudCreateFunction = async ({ service, statusCode = 201, serviceName, serviceData, checkId = true }) => {
    try {
        const res = await service(serviceData)
        if (res && res.status === statusCode) {
            if(checkId) {
                if(!res.data.id) {
                    console.error(`${serviceName} id was been empty`)
                    return {status: false, data: res.data}
                } else {
                    return {status: true, data: res.data}
                }
            } else {
                return {status: true, data: res.data}
            }
        } else {
            console.error(`${serviceName} status error`)
            return {status: false}
        }
    } catch (e) {
        console.error(`${serviceName} catch error`, e)
        return { status: false }
    }
}

export const CrudDeleteFunction = async ({ service, statusCode = 204, serviceName, serviceData, checkId = true  }) => {
    try {
        const res = await service(serviceData)
        if (res && res.status === statusCode) {
            return {status: true, data: res.data}
        } else {
            console.error(`${serviceName} status error`)
            return {status: false}
        }
    } catch (e) {
        console.error(`${serviceName} catch error`, e)
        return { status: false }
    }
}

export function checkUpdatedEntity(data, values, config) {
    Object.keys(config).map(key => {
        if(config[key].type === 'id' && data[key].id != values[config[key].value]) {
            console.error(`Обьект ${key} не содержит необходимого id`);
            return false;
        }

        if(config[key].type === 'include' && !data[config[key].value].includes(values[config[key].value])) {
            console.error(`Значение ${key} не содержит необходимого значения`);
            return false;
        }
    })

    Object.keys(values).map(key => {
        if(!config[key] && (data[key] != values[key])) {
            console.error(`Значение ${key} не ровно необходимому значению`);
            return false
        }
    })
    return true;
}