import React, {useState} from "react";

export const FeedPost = ({ id, title, content, picture, author_id, author, humanDate }) => {
    const [isOpen, setOpen] = useState(false)
    return (
        <div className="feed__post">
            <div className="feed__post-left">
                <div className="feed__post-picture">
                    {picture ? <img src={picture} alt=""/> : <div className="feed__post-picture-name">{author && author.name.substr(0, 2)}</div>}
                </div>
            </div>
            <div className="feed__post-right">
                <div className="feed__post-header">
                    <h4>{author.name}</h4>
                    <span>{humanDate}</span>
                </div>
                <h5 className="feed__post-title">{title}</h5>
                <p className="editBlc" style={{ height: isOpen ? "" : "200px", overflow: "hidden" }} dangerouslySetInnerHTML={{ __html: content }} />
                {!isOpen && <div>
                    <hr className="feed__hr" />
                    <b onClick={() => setOpen(true)}>&#10092;</b>
                </div>}
            </div>
        </div>
    )
}