import React, {Component} from 'react';
import click from '../fonts/click.mp3';
import error from '../fonts/error.wav';
import del from '../fonts/delete.mp3';
import info from '../fonts/info.mp3';
import msg from '../fonts/msg.mp3';
import localstorageSettings from '../components/localstorageSettings';
const SoundClick = (sound) => {
    if(localstorageSettings("get","sound")){
        /*let audio = new Audio();
        switch(sound){
            case 'click': audio.src = click; break;
            case 'delete': audio.src = del; break;
            case 'error': audio.src = error; break;
            case 'info': audio.src = info; break;
            case 'msg': audio.src = msg; break;
        }
        audio.autoplay = true;*/
    }

};

export default SoundClick;