import React from 'react';

function localstorageSettings(type, item,  value) {
    let a = localStorage.getItem('settings');
    if(a){
        a = JSON.parse(a);
    } else {
        a = {sound: true}
    }
    if(type === "post"){
        a[item] = value;
        localStorage.setItem('settings', JSON.stringify(a));
    } else {
        return a[item]
    }
}

export default localstorageSettings;