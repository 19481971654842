import React, {useContext, useEffect, useRef, useState} from "react";
import Loader, {ModalLoader} from "../../components/UI/Loader";
import {
    deleteLeadService,
    getLeadPrepareService,
    getSingleLeadService,
    updateLeadService
} from "../../services/lead/leadService";
import LeaveModal from "../../components/other/LeaveModal";
import IconButton from "../../components/other/IconButton";
import UploadAvatar from "../../components/other/UploadAvatar";
import {Tasks} from "../../components/Task";
import {filesFunc, TabsRender} from "../../components/fieldGen";
import {MemoButton} from "../../components/cardViewComponents/MemoButton";
import {validationData} from "../../components/UI/formValid";
import Notif from "../../components/Notif";
import {parseResponse, parseResponseError} from "../../components/cardViewComponents/parseFunctions";
import {GenerateForm} from "../../components/cardViewComponents/GenerateForm";
import {leadConfig, tabsConfig} from "./leadConfig";
import {CSSTransition} from "react-transition-group";
import History from "../../components/history/history";
import Related from "../../components/related/related";
import Files from "../../components/files/files";
import {contactFieldsValidation, transformPostContactFieldsData,transformSaveContactFields
} from "../../components/cardViewComponents/ContactFields";
import GetPostAditionalFields from "../../components/get_post_aditionalFields";
import {InfoTab} from "./components/InfoTab";
import Product from "./tab/product/product";
import {validationConfig} from "../../components/cardViewComponents/validationConfig";
import {TagsNew} from "../../components/cardViewComponents/TagsNew";
import {TaskModal, updateEntityTasks} from "../tasks/TaskModal";
import Qualification from "../../Modal/Qualification";
import Bgdrop from "../../Modal/Bgdrop";
import {additionalFieldsValidation} from "../../components/cardViewComponents/additionalFieldsValidation";
import {ConflictModal} from "../../components/cardViewComponents/ConflictModal";
import {Comments} from "../../components/cardViewComponents/Comments/Comments";
import {FilesDropDown} from "../../components/FilesDropDown";
import {MessengersInCards} from "../../components/cardViewComponents/MessengersInCards";
import {GlobalState} from "../../Layout/Layout";
import is from "is_js";
import {CardSendPulse} from "../../components/cardViewComponents/Integrations/CardSendPulse";

export const LeadViewNew = ({
    match,
    history,
    location
}) => {
    const { state: { integrations }} = useContext(GlobalState)
    const urlParams = new URLSearchParams(location.search);
    const { params: { id } } = match
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState({})
    const [disabled, setDisabled] = useState({})
    const [conflict, setConflict] = useState(false)
    const [modalData, setModalData] = useState(null)
    const [values, setValues] = useState({
        name: '',
        company: '',
        email: '',
        phone: '',
        status_id: '',
        statuses: [],
        amount: '',
        currency_id: '',
        currencies: [],
        first_name: '',
        last_name: '',
        middle_name: '',
        description: '',
        type_id: '',
        types: [],
        source_id: '',
        source: null,
        owner_id: '',
        owner: null,
        add_way_id: '',
        photo: '',
        created_at: '',
        updated_at: '',
        editorTags: '',
        files: [],
        tasks: [],
        additionalFields: [],
        contactFields: [],
        chats: [],
        subscriber: null,
        begin_update_datetime: null,
        tch: true
    })

    const getLeadData = async () => {
        // const { data: prepareData, status: prepareStatus } = await getLeadPrepareService(localStorage.getItem('leadFunnel'))
        setLoading(true)
            const { data, status, response } = await getSingleLeadService({
                id, expand: 'contacts,account,tasks,files,stages,owner,source,contactFields,additionalFields,currency,subscriber,addWay,statuses,currencies,types,chats'
            })

            const parseResponseData = parseResponseError(response);
            if(parseResponseData.notFound) history.push('/404')

            if(status === 200) {

                setValues({
                    ...values,
                    ...parseResponse(leadConfig, data),
                    statuses: data.statuses,
                    currencies: data.currencies,
                    types: data.types,
                    files: data.files,
                    tasks: data.tasks,
                    chats: data.chats,
                    subscriber: data.subscriber,
                    additionalFields: GetPostAditionalFields("get", data.additionalFields) || [],
                    contactFields: transformSaveContactFields(data.contactFields),
                })
            }

        setLoading(false)
    }

    const saveItem = async (forcibly) => {
        const { name, company, email, photo, phone, status_id, amount, currency_id, first_name, last_name,
            middle_name, description, type_id, source_id, owner_id, add_way_id, editorTags, created_at, updated_at, begin_update_datetime } = values

        const additionalFieldsValidationRes = additionalFieldsValidation(values.additionalFields)
        const contactFieldsValidationRes = contactFieldsValidation(values.contactFields)

        const errorsData = validationData(
            {...values, ...contactFieldsValidationRes.values, ...additionalFieldsValidationRes.values},
            {...validationConfig(leadConfig), ...contactFieldsValidationRes.validationRules, ...additionalFieldsValidationRes.validationRules}
        )

        setErrors(errorsData)
        if(Object.keys(errorsData).length > 0) {
            Notif('required-fields')
            return
        }

        setLoading(true)

        try {
            const { data, status, response } = await updateLeadService({ id, data: {
                    name, company, email, photo, phone, status_id, amount, currency_id, first_name, last_name,
                    middle_name, description, type_id, source_id, owner_id, add_way_id, editorTags, begin_update_datetime: forcibly === 'ok' ? null : updated_at,
                    contactFields: transformPostContactFieldsData(values.contactFields, id, 23 ),
                    additionalFields: GetPostAditionalFields("post", values.additionalFields),
                }})

            if(status === 200 && data.id) {
                Notif('save', 'Запись успешно изменена')
                setValues(({ ...values, updated_at: data.updated_at, tch: true, begin_update_datetime: null }))
            }

            const parseResponseData = parseResponseError(response);
            if(parseResponseData.saveConflict) setConflict(true)
            if(parseResponseData.validationErrors) {
                setErrors(parseResponseData.validationErrors)
            }

        } catch (e) { console.error(e) }

        setLoading(false)
    }

    const deleteItem = async () => {
        if(!window.confirm('Удалить элемент?')) return false

        setLoading(true)
        try {
            const { status } = await deleteLeadService({ id })
            if(status === 204) {
                setValues(prevState => ({ ...prevState, tch: true }))
                Notif('delete', 'Лид успешно удален')
                history.push('/lead')
            }
        } catch (e) { console.error(e) }
        setLoading(false)
    }

    useEffect(() => {
        getLeadData().then()
    }, [id])

    const changeFields = (key, value, item) => {
        let obj = {}
        if(item) {
            if(item === 'clear') obj[leadConfig[key].entity_from] = null
            else obj[leadConfig[key].entity_from] = item
        }

        setValues({
            ...values, ...obj, [key]: item ? item.id : value,
            begin_update_datetime: !values.begin_update_datetime ? new Date() : values.begin_update_datetime,
            tch: false
        })
    }

    return (
        <div>
            {loading && <ModalLoader />}
            <div className="page">
                <LeaveModal when={!values.tch} />
                <div className="newList-btn-group">
                    <div className="newList-btn-group-left">
                        <TagsNew
                            editorTags={values.editorTags} entity={'lead'}
                            setValues={data => setValues({ ...values, editorTags: data, tch: false })}
                        />
                        <MemoButton
                            onClick={() => setModalData({ type: 'task' })}
                            mobileIcon={'flaticon-notes mdb'} title={'Добавить задачу'} className={'mr5'} />
                        {integrations && integrations.mail && values.email && is.email(values.email) &&
                            <CardSendPulse setValues={data => setValues({ ...values, ...data })} subscriber={values.subscriber} id={id} related_type={23} />
                        }
                        <MessengersInCards chats={values.chats || []} />
                    </div>
                    <div className="newList-btn-group-right">
                        <a onClick={deleteItem} className="butt-group-card-wrap btni ml10">
                            <span>Удалить</span>
                            <i className="flaticon-delete-2 mdb" />
                        </a>
                        <div className="toogle-bottom">
                            <button onClick={() => setModalData({ type: 'qualification' })} className="mdn success btni ml5">Перевести в продажу</button>
                        </div>
                        <MemoButton onClick={saveItem} disabled={values.tch} mobileIcon={"flaticon-interface-1 mdb"} title={'Сохранить'} className={'ml5'} />
                        <IconButton href="https://sboxcrm.com/docs/lead" />
                    </div>
                </div>

                <div className="contact-page sbform">
                    <div className="contact-left">
                        <div className="contact-left-col" style={{ marginTop: "45px" }}>
                            <h3>Данные лида</h3>
                            <UploadAvatar
                                img={values.photo}
                                setImg={(photo) => setValues(({ ...values, photo, tch: false }))}
                                cancelImg={() => setValues(({ ...values, photo: '', tch: false }))}
                                related_id={id}
                                related_type="23"
                            />

                            {/*<NewAutoSelect
                                label={'t11111'}
                                link={`/ajax/contact?fields=id,name&filter[name][like]=`}
                                updateData={(item) => setA1(item)}
                                entity={a1}
                            />*/}

                            <GenerateForm
                                config={leadConfig}
                                { ...{ values, errors, disabled } }
                                onChange={changeFields}
                                onSaveData={saveItem}
                            />
                            <div className="clear" />
                        </div>
                    </div>

                    <div className="contact-right-col">
                        <Tasks tasks={values.tasks} openTask={data => setModalData(data)} />
                        <TabsRender
                            setState={({ layout }) => history.push(`?layout=${layout}`)}
                            state={{ layout: urlParams.get('layout') || tabsConfig[0].value }}
                            arr={tabsConfig} />

                        <div className="tab-container">
                            <CSSTransition in={(urlParams.get('layout') || tabsConfig[0].value) === tabsConfig[0].value} timeout={200} classNames="my-node" unmountOnExit>
                                <InfoTab
                                    {...{ values, errors, disabled, changeFields, id, saveItem }}
                                    setContactFields={data => setValues({ ...values, contactFields: data, tch: false })}
                                    setValues={data => setValues(({ ...values, ...data, tch: false }))}
                                />
                            </CSSTransition>

                            <CSSTransition in={urlParams.get('layout') === tabsConfig[1].value} timeout={{enter: 0, exit: 0}} classNames="my-node" unmountOnExit>
                                <History type="lead" id={id} />
                            </CSSTransition>

                            <CSSTransition in={urlParams.get('layout') === tabsConfig[2].value} timeout={{enter: 0, exit: 0}} classNames="my-node" unmountOnExit>
                                <Product products={[]} type="lead" id={id} related="1" />
                            </CSSTransition>

                            <CSSTransition in={urlParams.get('layout') === tabsConfig[3].value} timeout={{enter: 0, exit: 0}} classNames="my-node" unmountOnExit>
                                <Related type="lead" id={id} lead_id={id} related="23" tasks sms mails expense />
                            </CSSTransition>

                            <CSSTransition in={urlParams.get('layout') === tabsConfig[4].value} timeout={{enter: 0, exit: 0}} classNames="my-node" unmountOnExit>
                                <FilesDropDown
                                    related_id={id}
                                    related_type={"23"}
                                    files={values.files}
                                    updateFile={filesData => setValues(prevState => ({
                                        ...prevState,
                                        files: [filesData, ...prevState.files]
                                    }))}
                                    updateFiles={files => setValues(prevState => ({ ...prevState, files }))}
                                />
                            </CSSTransition>
                        </div>
                    </div>
                </div>
                {conflict && <ConflictModal
                    entity={'lead'} id={id} updated_at={values.begin_update_datetime}
                    save={() => saveItem('ok')} close={() => setConflict(false)}
                />}
                <TaskModal
                    id={modalData && modalData.id} close={() => setModalData(null)} related={{ id, type: 23 }}
                    index={modalData && modalData.index} isOpen={modalData && modalData.type === 'task'}
                    task={(type, data, index) => {
                        setValues({ ...values, tasks: updateEntityTasks(values.tasks, type, data, index) })
                    }}
                />
                <div className={`right-modal scrollbar ${modalData && modalData.type === 'qualification' && 'r-open'}`}><div className="save">
                    {modalData && modalData.type === 'qualification' && <Qualification
                        id={id} name={values.name} first_name={values.first_name} last_name={values.last_name} owner={values.owner}
                        middle_name={values.middle_name} account={values.company} phone={values.phone} email={values.email}
                    />}
                </div>

                </div>
                {modalData && modalData.type === 'qualification' &&  <Bgdrop close={() => setModalData(null)} />}
            </div>
        </div>
    )
}