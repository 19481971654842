import React, {useEffect, useState} from "react";
import Modal from "../Modal/Modal";
import myaxios from "../Axios";
import {NavLink} from "react-router-dom";
import {ATTACHED_NUMBER_TO_STRING} from "../../const";

const TurnoverDetail = ({ isOpen, close, filter, id, convertFilter = () => {}, getUrlParams }) => {
    const [detail, setDetail] = useState([]);

    useEffect(() => {
        if(isOpen) {
            myaxios.get(`/ajax/stock/analytics/${id.product.id}?per-page=50&start_date=${getUrlParams("start_date")}&end_date=${getUrlParams("end_date")}&stock_id=${getUrlParams("stock_id")}`).then(res => {
                setDetail([...res.data.items])
            })
        }
    },[isOpen])
    return (
        <Modal title="Детали товарооборота" isOpen={isOpen} close={close}>
            <div className="sp-table body-table">
                <table style={{ borderSpacing: "0px 2px", display: "table", width: "100%" }}>
                    <tbody>
                    {
                        detail.map((item, index) => (
                            <tr key={index}>
                                <td><NavLink to={`/${ATTACHED_NUMBER_TO_STRING[item.related_type]}/${item.related_id}`}>{item.entity && item.entity.name}</NavLink></td>
                                <td>{item.quantity}</td>
                                <td>{item.entity && item.entity.completed_at}</td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>
        </Modal>
    )
}

export default TurnoverDetail