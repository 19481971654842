import React, {useEffect, useState} from 'react';
import Notif from '../components/Notif';
import AutoSelect from '../components/UI/AutoSelect';
import myaxios from '../components/Axios';
import Loader from '../components/UI/Loader';



const EditTags = props => {
    const [state, setMyState] = useState({
        formControl: {
            to: {
                value: [],
                selectvalid: true,
                arr: [],
            },
        },
        tch: false,
        loading: false
    })


    const setState = (st, func) => {
        Object.keys(st).map((key) => { state[key] = st[key]});
        setMyState(prevState => { return {...prevState, ...state} });
        if(func) func();
    };

    useEffect(() => {
        const formControl = state.formControl;
        if(props.tags){
            formControl.to.arr = props.tags.split(',');
        }
        setState({ formControl });
    }, [])

    const addTag = (event, id, tag) => {
        event.preventDefault();
        if(event.keyCode === 13) {
            return false;
        }
        let a = state.formControl.to.arr.join(',');
        myaxios.post(`/ajax/${props.type}/${props.id}/mark?labels=${a}`).then(response => {
            if(response.status === 200 || response.status === 201 || response.status === 204) {
                Notif("save", 'Теги успешно обновлены');
                setTimeout(() => { props.close();  props.refresh() });
                setTimeout(props.refreshState(a))
            } else {
                return false;
            }
        })
    };
    const setField = (event, type) => {
        const formControl = {  ...state.formControl };
        const control = { ...formControl[type] };
        control.value = event.target.value;
        formControl[type] = control;
        setState({
            formControl, tch: false
        })
    };
    const updateData = (id,arr) =>{
        const formControl = state.formControl;
        formControl.from.value = arr.username;
        formControl.from.id = id;
        setState({ formControl, tch: false })
    };
    const updateDataTo = (id, arr) => {
        console.log(id, arr)
        if(arr.name === ''){
            return false;
        }
        const formControl = state.formControl;
        let tag = true;
        for(let i = 0; i <= state.formControl.to.arr.length; i++){
            if(arr.name) {
                if(state.formControl.to.arr[i] === arr.name){
                    tag = false;
                }
            } else {
                if(state.formControl.to.arr[i] === arr){
                    tag = false;
                }
            }
        }
        if(tag){
            (arr.name) ? formControl.to.arr.push(arr.name) : formControl.to.arr.push(arr)

        } else if(!tag) {
            Notif("Error", "Тег уже добавлен")
        }
        formControl.to.value = '';
        setState({ formControl, val: '' })
    };
    const deleteItem = id => {
        const formControl = state.formControl;
        formControl.to.arr.splice(id, 1);
        setState({ formControl })
    };
    return (state.loading) ? <Loader contain /> : <div className="sbform create-activ">
        <div className="rmod-header">
            <b>Редактировать теги</b>
            <a onClick={props.close} style={{right: "70px"}} className="mclose" href={null}><i className="flaticon-close" /></a>
            <div className="togglelist">
                <a onClick={addTag} className="rmod-ok" disabled={state.tch}><i className="flaticon-paper-plane" /></a>
            </div>
        </div>
        <div className="clear" />
        <div className="mod-cont">
            <label data-tooltip="Для добавления тега, введите название тега и нажмите Enter либо выберите из списка существующих">Редактировать теги</label>
            <AutoSelect
                label={"Кому"}
                link={`/ajax/${props.type}/suggest?term=`}
                minLength="0"
                result="name"
                event="to"
                //errorMessage={"Введите корректный Email"}
                value={state.formControl.to.value}
                shouldValidate={true}
                setField={event => setField(event, "to")}
                valid={true}
                updateData={updateDataTo}
                deleteItem={deleteItem}
                createTag
                multiSelect={state.formControl.to.arr}
                multiSelectValid={true}
                itemType={`item`}
            />
        </div>
    </div>
};



export default EditTags;