import React,{ Component } from 'react';
import Input from '../components/UI/Input';
import Myselect from '../components/UI/Select';
import Notif from '../components/Notif';
import myaxios from '../components/Axios';
import AutoSelect from '../components/UI/AutoSelect';
import formValid from '../components/UI/formValid';
import Textarea from '../components/UI/Textarea';
import ModAutoSelect from '../components/UI/ModAutoSelect';
import {withRouter} from 'react-router-dom';

class Team extends Component {
    state = {
        formControl: {
            user: {value:null,arr:null,valid:true, validation:{required: (!this.props.id)}},
            role: {value:''},
            description: {value: ''}
        },
        isFormValid: true,
        tch: true
    };
    componentDidMount(){
        if(this.props.id) {
            const formControl = this.state.formControl;
            formControl.user.arr = this.props.arr.user;
            formControl.role.value = this.props.arr.role;
            formControl.description.value = this.props.arr.description;
            this.setState({ formControl })
        }
    }
    setField = (event, type) => {
        const formControl = {  ...this.state.formControl };
        const control = { ...formControl[type] };
        control.value = event.target.value;
        formControl[type] = control;
        this.setState({
            formControl, tch: false
        })
    };
    isFormValid = () => {
        let formControl = this.state.formControl;
        let isFormValid = true;
        Object.keys(formControl).map((key, index) => {
            formControl[key].valid = formValid(formControl[key].value, formControl[key].validation)
            isFormValid = formControl[key].valid && isFormValid
        });
        this.setState({
            formControl, isFormValid
        });
        return isFormValid;
    };
    sendForm = event => {
        event.preventDefault();
        if(this.isFormValid()){
            this.setState({ loading: true, tch: true });
            myaxios.post(`/ajax/${this.props.entity}/${this.props.parrentId}/link-member/${this.state.formControl.user.value}`, {
                role: this.state.formControl.role.value,
                description: this.state.formControl.description.value
            }).then(responce => {
                if(responce.status === 200 || responce.status === 201 || responce.status === 204) {
                    setTimeout(this.props.close, 300)
                    setTimeout(this.props.refresh, 200)
                    Notif("save")
                } else {
                    Notif("Error", "Повторите запрос позже или обратитесь в техническую поддержку")
                }
            }).catch((error) => {
                this.setState({loading: false});
            });
        } else {
            Notif("required-fields", "Заполните обязательные поля")
        }
    };
    editForm = event => {
        event.preventDefault();
            this.setState({ loading: true });
            myaxios.post(`/ajax/${this.props.entity}/${this.props.parrentId}/edit-member-item/${this.props.id}`, {
                role: this.state.formControl.role.value,
                description: this.state.formControl.description.value
            }).then(responce => {
                if(responce.status === 200 || responce.status === 201 || responce.status === 204) {
                    setTimeout(this.props.close, 100)
                    setTimeout(this.props.refresh, 200)
                    Notif("save")
                } else {
                    Notif("Error", "Повторите запрос позже или обратитесь в техническую поддержку")
                }
            }).catch((error) => {
                this.setState({loading: false});
            });
    };
    updateData = (id, arr, addType, endParrans) => {
        const formControl = this.state.formControl;
        formControl[addType].arr = arr;
        formControl[addType].value = id;
        this.setState({ formControl, tch: false })
    };
    unLink = (type, typeID, id) => {
        const formControl = this.state.formControl;
        formControl[type].arr = null;
        formControl[type].value = null;
        this.setState({ formControl, tch: false })
    };
    render(){
        return <div className="sbform create-activ">
            <div className="rmod-header">
                <b>Команда</b>
                <a className="mclose" onClick={this.props.close}><i className="flaticon-close" /></a>
                <div className="togglelist"><button onClick={(this.props.id) ? this.editForm : this.sendForm} className="rmod-ok" disabled={this.state.tch} ><i className="flaticon-interface-1" /></button></div>
            </div>
            <div className="mod-cont">
                <div className="clear" />
                <ModAutoSelect
                    required
                    label="Ответственный"
                    link="/ajax/settings/user?filter[name][like]="
                    minLength="0"
                    result="name"
                    event="user"
                    valid={this.state.formControl.user.valid}
                    shouldValidate={false}
                    addType="user"
                    entity={this.state.formControl.user.arr}
                    entityName="name"
                    updateData={this.updateData}
                    unLink={(this.props.id) ? () => {} : this.unLink}
                    ownerLink={this.state.formControl.user.arr ? this.state.formControl.user.arr.contact : null}
                />
                {/*<div className="relt">
                    {
                        (this.state.formControl.user.arr) ?
                            <div className="inp">
                                <label>Контакт</label>
                                <div className="fgroup rsb">
                                    <b>{this.state.formControl.user.arr.name}</b>
                                    {(this.props.id) ? null : <a title="Отвязать" onClick={()=>this.unLink("user")} className="unlink">x</a>}
                                </div>
                            </div> :
                            <AutoSelect
                                label="Контакт"
                                link={`/ajax/settings/user?filter[name][like]=`}
                                errorMessage={'Поле не может быть пустым'}
                                shouldValidate={true}
                                valid={this.state.formControl.user.valid}
                                minLength="0"
                                result="name"
                                addType="user"
                                setField={()=>{}}
                                updateData={this.updateData}
                            />
                    }
                </div>*/}
                <div className="clear" />
                <Input
                    label="Роль контакта"
                    type="text"
                    value={this.state.formControl.role.value}
                    onChange={event => this.setField(event, "role")}
                />
                <Textarea
                    label="Описание"
                    value={this.state.formControl.description.value}
                    onChange={event => this.setField(event, "description")}
                    cols="30"
                    rows="6"
                />
            </div>
        </div>
    }
}

export default withRouter(Team);