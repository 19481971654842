import React, {useEffect, useState} from "react";
import Modal from "../../Modal/Modal";
import Input from "../../UI/Input";
import myaxios from "../../Axios";
import {validationData} from "../../UI/formValid";
import Notif from "../../Notif";

const validationRules = {
    client_id: { required: true, maxLength: 255 },
    client_secret: { required: true, maxLength: 255 },
}

export const SendPulse = ({ close, metaData, saveNode }) => {
    const [sync, setSync] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState({})
    const [values, setValues] = useState({
        client_id: '',
        client_secret: '',
        time_value: 0,
        time_valuePeriod: 'minutes',
        tch: true
    })

    useEffect(() => {
        setIsOpen(true)
        if (metaData) {
            setValues({
                ...values,
                client_id: metaData.client_id,
                client_secret: metaData.client_secret
            })
            setLoading(false)
        } else {
            setLoading(false)
        }
    }, [])

    const closeModal = () => {
        setIsOpen(false); setTimeout(close, 50);
    }

    const updateSegments = async () => {
        setLoading(true)
        try {
            const { status, data } = await myaxios.get(`/ajax/integrations/sendpulse/subscriber/sync`)
            if (status === 200) {
                Notif('save', 'Обновление сегментов запущено.')
                setSync(true)
            }
        } catch (e) {}
        setLoading(false)
    }

    const disconnectService = async () => {
        setLoading(true)
        try {
            const { status, data } = await myaxios.post(`/ajax/integrations/sendpulse/default/disconnect`)
            if (status === 200) {
                saveNode()
            }
        } catch (e) {}
    }

    const saveData = async () => {
        const validErrors = validationData(values, validationRules)
        if(Object.keys(validErrors).length > 0) {
            setErrors(validErrors)
            return false;
        }
        setErrors({})

        setLoading(true)
        try {
            const { status, data } = await myaxios.post(`/ajax/integrations/sendpulse/default/connect`, {
                client_secret: values.client_secret, client_id: values.client_id
            })
            if (status === 200 || status === 201) {
                saveNode()
                Notif('save', 'Данные успешно изменены')
            }
        } catch (e) {}
        setLoading(false)
    }

    return (
        <Modal showLeaveModal tch={values.tch} isOpen={isOpen} title={'Sendpulse'} loading={loading}
               save={saveData} parentClassName={'bpmModal'} close={closeModal}>
            <div className={'sbform'}>
                <Input
                    label={'Client ID'}
                    value={values.client_id} type={'password'}
                    onChange={e => setValues({...values, client_id: e.target.value, tch: false })}
                    errorMessage={errors.client_id} valid={!errors.client_id} shouldValidate
                    disabled={metaData && !Array.isArray(metaData)}
                />
                <Input
                    label={'Client secret'}
                    value={values.client_secret} type={'password'}
                    onChange={e => setValues({...values, client_secret: e.target.value, tch: false })}
                    errorMessage={errors.client_secret} valid={!errors.client_secret} shouldValidate
                    disabled={metaData && !Array.isArray(metaData)}
                />
                {metaData && !Array.isArray(metaData) && <button onClick={disconnectService} className="open-modal-button btni mt10">Отключить интеграцию</button>}
                {metaData && !Array.isArray(metaData) && <button disabled={sync} onClick={updateSegments} className="ml5 success btni mt10">Обновить сегменты</button>}

            </div>
        </Modal>
    )
}