import React, {useEffect, useState} from "react";
import Modal from "../components/Modal";
import {FieldGen, setField, unLink, updateData} from "../components/fieldGen";
import formValid from "../components/UI/formValid";
import myaxios from "../components/Axios";
import Notif from "../components/Notif";
import {withRouter} from "react-router-dom";

const AddPlate = ({ close, history, isOpen }) => {
    const [state, setMyState] = useState({
        formControl: {
            name: {
                type: 'input',
                label: 'Название',
                value: '',
                valid: true,
                validation: {required: true}
            },
            filename: {
                type: 'input',
                label: 'Название файла (на латинице)',
                options:[],
                value: '',
                valid: true,
                validation: {required: true}
            },
            object_type: {
                type: 'select',
                label: 'Привязать к',
                options:[],
                value: '',
                valid: true,
                validation: {required: true}
            },
            page_orientation: {
                type: 'select',
                label: 'Ориентация листа',
                options:[],
                value: '',
                valid: true,
                validation: {required: true}
            },
            page_format: {
                type: 'select',
                label: 'Формат листа',
                options:[],
                value: '',
                valid: true,
                validation: {required: true}
            },
            source_code: {
                type: 'select',
                label: 'Тип ',
                options:[
                    {value:"editor", label:"Редактор"},
                    {value:"html", label:"HTML код"},
                ],
                value: 'editor',
                valid: true,
                validation: {required: true}
            },
            type: {
                value: 'object',
            },
            body: {value:""}
        },
        loading: true
    })

    const setState = (st, func) => {
        Object.keys(st).map((key) => { state[key] = st[key]});
        setMyState(prevState => { return {...prevState, ...state} });
        if(func) func();
    };

    useEffect(() => {
        myaxios.get(`/ajax/plates/prepare`).then(res => {
            state.formControl.object_type.options = res.data.object_types;
            state.formControl.object_type.value = res.data.object_types[0].value;

            state.formControl.page_format.options = res.data.page_formats;
            state.formControl.page_format.value = Object.keys(res.data.page_formats)[0];

            state.formControl.page_orientation.options = res.data.page_orientations;
            state.formControl.page_orientation.value = Object.keys(res.data.page_orientations)[0];

            setState(state)
            setState({ loading: false })
        })
    },[])

    const isFormValid = () => {
        let formControl = state.formControl;
        let isFormValid = true;
        Object.keys(formControl).map((key, index) => {
            formControl[key].valid = formValid(formControl[key].value, formControl[key].validation)
            isFormValid = formControl[key].valid && isFormValid
        });
        setState({ formControl });
        return isFormValid;
    };

    const save = () => {
        if(!isFormValid()) return false

        let result = {};
        Object.keys(state.formControl).map(key => {result[key] = state.formControl[key].value})

        result.source_code = JSON.stringify({type: state.formControl.source_code.value, body:""})

        myaxios.post(`/ajax/plates`, result).then(res => {
            if(res.status === 200 || res.status === 201) {
                Notif("save");
                history.push(`/settings/plate/${res.data.id}`)

            }
        })
    }

    return <Modal isOpen={isOpen} title="Добавить шаблон" save={save} close={close}>
        <FieldGen
            arr={state.formControl}
            setField={(e, type) => setState({formControl: setField(state.formControl, type, e)})}
        />
    </Modal>
}

export default withRouter(AddPlate)