const workOrderConfig = {
    name: {
        type: 'input',
        label: 'Название',
        value:'',
        valid: true,
        validation: {required: true},
        zone: 1
    },
    description: {
        empty: true,
        type: 'select',
        label: 'Кому',
        value:'Виталий на офис-склад',
        options:[
            {value:"Виталий на офис-склад", label:"Виталий на офис-склад"},
            {value:"Виталий под клиентов", label:"Виталий под клиентов"},
            {value:"Петр под клиентов", label:"Петр под клиентов"},
            {value:"Петр склад", label:"Петр склад"},
        ],
        //inputType:'text',
        valid: true,
        validation: {required: true},
        zone: 1
    },
    account_id: {
        type: 'modAutoSelect',
        label: 'Контрагент',
        link: '/ajax/account?filter[name][like]=',
        value:'',
        result:'name',
        valid: true,
        entity:null,
        from:'account',
        entityName:"name",
        zone: 1
    },
    status_id: {
        type: 'select',
        label: 'Статус',
        post: "number",
        value:'',
        options:[],
        valid: true,
        validation: {required: true},
        lab: "value",
        minLength: 0,
        val: "id",
        zone: 1
    },
    stock_id: {
        type: 'modAutoSelect',
        //cls:'c12',
        label: 'Склад',
        link: '/ajax/stock?filter[name][like]=',
        value:'',
        result:'name',
        valid: true,
        entity:null,
        from:'stock',
        entityName:"name",
        minLength: 0,
        validation: {required: true},
        zone: 1
    },
    end_date: {
        type: 'dateTime',
        label: 'Дата создания',
        value:'',
        valid: true,
        zone: 1
    },
    /*invoice_id: {
        type: 'modAutoSelect',
        cls:'c12',
        label: 'Счет',
        link: '/ajax/invoice?filter[name][like]=',
        value:'',
        result:'name',
        valid: true,
        entity:null,
        from:'invoice',
        minLength: 0,
        entityName:"name",
        redirectLink:"/invoice/",
        zone: 1
    },
    order_id: {
        type: 'modAutoSelect',
        cls:'c12',
        label: 'Заказ',
        link: '/ajax/order?filter[name][like]=',
        value:'',
        result:'name',
        valid: true,
        entity:null,
        from:'order',
        minLength: 0,
        entityName:"name",
        redirectLink: "/order/",
        zone: 1
    },
    description: {
        type: 'editor',
        cls:'c12',
        label: 'Описание',
        value:'',
        inputType:'text',
        valid: true,
        zone: 2
    },*/
    /*account_id: {
        type: 'modAutoSelect',
        cls:'c12',
        label: 'Контрагент',
        link: '/ajax/account?filter[name][like]=',
        value:'',
        result:'name',
        valid: true,
        entity:null,
        from:'account',
        minLength: 0,
        entityName:"name",
        zone: 1
    },*/
    owner_id: {
        type: 'modAutoSelect',
        cls:'c12',
        label: 'Ответственный',
        link: '/ajax/settings/user?filter[name][like]=',
        value:'',
        result:'name',
        valid: true,
        entity:null,
        from:'owner',
        entityName:"name",
        //validation: {required: true},
        zone: 1
    },
    is_completed: {
        type: 'checkbox',
        label: 'Проведено',
        value:0,
        valid: true,
        zone: 1
    },
}

export default workOrderConfig