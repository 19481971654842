import React, {Component} from 'react';
import Textarea from '../../UI/Textarea';
import Input from '../../UI/Input';
import ModAutoSelect from '../../UI/ModAutoSelect';
import formValid from '../../UI/formValid';
import myaxios from '../../Axios';
import entityFields from './entityFieldsArr';
import DateTime from '../../UI/DateTime';
import Notif from '../../Notif'
import Loader from '../../../components/UI/Loader';

function searchEntityId(id){
    switch(id){
        case "opportunity": return 1;
        case 'contact': return 2;
        case 'account': return 3;
        case 'project': return 6;
        case 'product': return 9;
        case 'invoice': return 13;
        case "order": return 14;
        case "lead": return 23;
        case "document": return 26;
    }
}

function searchEntityName(id){
    switch(id){
        case 1: return "opportunity";
        case 2: return 'contact';
        case 3: return 'account';
        case 6: return 'project';
        case 9: return 'product';
        case 13: return 'invoice';
        case 14: return "order";
        case 23: return "lead";
        case 26: return "document";
    }
}

export default class AddEntity extends Component {
    state = {
        formControl: {
            name:{value:'',valid:true, validation:{required:true}},
            time_value:{value:0},
        },
        fields:[],
        filter:[],
        operators: {
            text: [{value:"==", label:"Значение"},],
            multy: [{value:"==", label:"Значение"}],
            select: [{value:"==", label:"Значение"}],
            multiselect: [{value:"==", label:"Значение"}],
            number: [{value:"==", label:"Значение"}],
            date: [{value:"==", label:"Значение"}],
        },
        thisAdditionalFields:[],
        thisEntity: this.props.entity,
        AdditionalFields:[],
        entity:'',
        entityValid:true,
        isRelated:false,
        related:false,
        id:null,
        isFormula:false,
        formulaValue:'',
        formulaParentType:'',
        formulaChildType:'',
        loading: false
    };
    setField = (type, e ) => {
        const formControl = this.state.formControl;
        formControl[type].value = e;
        formControl[type].valid = formValid(e, formControl[type].validation);
        this.setState({ formControl })
    };
    getThisEntity = (arr, id) => {
        if(this.props.entityId !== 13) {
            this.setState({ thisAdditionalFields: entityFields[this.props.entity] })
        } else {
            myaxios.get(`/ajax/eav/attribute?entity_type=${this.props.entityId}`).then(response => {
                this.setState({ thisAdditionalFields: AditionalFilter("filter", entityFields[this.props.entity], response.data.items)})
            });
        }
    };
    getAdditionalParams = (arr, id) => {
        if(id === 13) {
            this.setState({ fields: arr })
        } else {
            myaxios.get(`/ajax/eav/attribute?entity_type=${id}`).then(response => {
                this.setState({ fields: AditionalFilter("filter", arr, response.data.items), loading: false  })
            });
        }
    };
    searchType = (object) => {
        switch(object){
            case "lookup": return "lookup";
            case "entity": return "entity";
            default: return "string";
        }
    }
    searchEntity = (t) => {
        this.getAdditionalParams(entityFields[t], searchEntityId(t));
        const filter = [];
        for(let i = 0; i < entityFields[t].length; i++){
            if(entityFields[t][i].r){
                filter.push({
                    field:entityFields[t][i].value,
                    obj: null,
                    //object:this.searchType(entityFields[t][i].object),
                    object: (entityFields[t][i].object === "entity" || entityFields[t][i].object === "lookup") ? "lookup" : "string",
                    link:entityFields[t][i].link,
                    operator: "==",
                    type: entityFields[t][i].type,
                    value: "",
                    r:entityFields[t][i].r
                })
            }
        }
        this.setState({
            entity: t,
            //isRelated: entityFields[t][i].r,
            filter: filter,
            //fields: entityFields[t.target.value]
        });
    }
    componentDidMount(){
        this.getThisEntity();
        this.getAdditionalParams(entityFields[this.props.entity], this.props.entityId);
        setTimeout(this.aaa, 400)
    }
    searchIndex = (arr, id) => {
        for(let i = 0; i < arr.length; i++){
            if(arr[i].value === id) {
                return arr[i].r
            }
        }
    }
    aaa = () => {
        if(this.props.elArr){
            let formControl = this.state.formControl;
            formControl.name.value = this.props.elArr.metaData ? this.props.elArr.name : '';
            formControl.time_value.value = this.props.elArr.metaData ? this.props.elArr.time_value : '';
            //formControl.subject.value = this.props.elArr.metaData ? this.props.elArr.metaData.subject : '';
            this.searchEntity(searchEntityName(this.props.elArr.metaData.data.recordType));
            let filter = [];
            for(let i = 0; i < this.props.elArr.metaData.data.fields.length; i++){
                filter.push({
                    field: this.props.elArr.metaData.data.fields[i].field,
                    link: "",
                    obj: this.props.elArr.metaData.data.fields[i].obj,
                    object: this.props.elArr.metaData.data.fields[i].object,
                    operator: "",
                    r: this.searchIndex(entityFields[searchEntityName(this.props.elArr.metaData.data.recordType)], this.props.elArr.metaData.data.fields[i].field),
                    type: this.props.elArr.metaData.data.fields[i].type,
                    value: this.props.elArr.metaData.data.fields[i].value,
                })
            }
            this.setState({ formControl, filter, id: this.props.elArr.id || null })
        }
    }
    componentWillMount(){

    }
    isFormValid = () => {
        let formControl = this.state.formControl;
        let filter = this.state.filter;
        let isFormValid = true;
        Object.keys(formControl).map((key, index) => {
            formControl[key].valid = formValid(formControl[key].value, formControl[key].validation);
            isFormValid = formControl[key].valid && isFormValid
        });
        for(let i = 0; i < filter.length; i++){
            if(filter[i].value.length < 1){
                isFormValid = false;
            }
        }
        let entityValid = true;
        if(this.state.entity.length < 1){
            entityValid = false;
            isFormValid = false;
        }
        this.setState({formControl, entityValid: entityValid});
        return isFormValid;
    };
    sendForm = () => {
        if(this.isFormValid()){
            if(this.props.elArr){
                setTimeout(
                    this.props.updateNode({
                        id:this.state.id,
                        name: this.state.formControl.name.value,
                        time_value: this.state.formControl.time_value.value,
                        is_scheduled: (this.state.formControl.time_value.value > 1),
                        metaData: {
                            data: {
                                recordType: searchEntityId(this.state.entity),
                                fields: this.state.filter
                            }
                        },
                        nodeType: "node",
                        type:"createRecord",
                    })
                )
            } else {
                setTimeout(
                    this.props.createNode({
                        name: this.state.formControl.name.value,
                        time_value: this.state.formControl.time_value.value,
                        is_scheduled: (this.state.formControl.time_value.value > 1),
                        metaData: {
                            data: {
                                recordType: searchEntityId(this.state.entity),
                                fields: this.state.filter
                            }
                        },
                        nodeType: "node",
                        type:"createRecord",
                    })
                )
            }
        } else {
            Notif("Fields")
        }
    };
    searchValue = (val, type) => {
        for(let i = 0; i < this.state.fields.length; i++){
            if(this.state.fields[i]){
                if(this.state.fields[i].value == val){
                    return this.state.fields[i][type]
                }
            }
        }
    };
    searchObj = (type, value) => {

    }
    setFilter = (index,type,value, t) => {
        const filter = this.state.filter;
        if(type === "field"){
            filter[index]["type"] = t.target[t.target.selectedIndex].getAttribute('data-sel');
            filter[index]["link"] = t.target[t.target.selectedIndex].getAttribute('data-link');
            filter[index]["object"] = this.searchType(t.target[t.target.selectedIndex].getAttribute('data-object'));
            filter[index].value = "";
            filter[index].obj = null;
        }
        filter[index][type] = value;
        console.log("reference", value, type);
        if(value == "reference" && type == "type"){
            filter[index].object = "reference"
        } else if(value == "formula" && type == "type"){
            filter[index].object = "formula"
        } else {
            if(type !== "value"){
                if(this.searchValue(filter[index].field, "object")){
                    if(this.searchValue(filter[index].field, "object") === "lookup" || this.searchValue(filter[index].field, "object") === "entity"){
                        filter[index].object = "lookup"
                    }
                } else {
                    filter[index].object = "string"
                }
            }
        }

        /*if(type === "object"){
            if(this.searchValue(value, "object")){
                if(this.searchValue(value, "object") === "lookup" || this.searchValue(value, "object") === "entity"){
                    filter[index].object = "lookup"
                }
            } else {
                filter[index].object = "string"
            }
        }
        if(type === "type"){
            if(value === "reference" || value === "formula"){
                filter[index].object = value
            }
        }
        if(type !== "object"){

        }*/
        //console.log(index,type,value,filter[index].field)

        console.log(filter[index],value)
        this.setState({ filter })
    };
    delItem = index => {
        const filter = this.state.filter;
        filter.splice(index, 1);
        this.setState({ filter })
    };
    addItem = () => {
        const filter = this.state.filter;
        filter.push({field: '', type:'', operator:'==', obj:null, value:''});
        this.setState({ filter })
    };
    updData = (index,id, arr) => {
        const filter = this.state.filter;
        filter[index].obj = arr;
        filter[index].value = id;
        this.setState({ filter })
    }
    updateData = (id, arr, el, type) => {
        let formControl = this.state.formControl;
        if(type === "add"){
            formControl[el].arr = arr;
            formControl[el].value = id;
        } else {
            formControl[el].arr = null;
            formControl[el].value = '';
        }
        this.setState({ formControl })
    }
    render(){
        return this.state.loading ? <Loader contain /> : this.state.isFormula ?
            <Formula
                close={() => this.setState({ isFormula: false })}
                fields={this.state.fields}
                filter={this.state.filter}
                formulaValue={this.state.formulaValue}
                changeFilter={filter => this.setState({ filter })}
                formulaParentType={this.state.formulaParentType}
                formulaChildType={this.state.formulaChildType}
                changeParent={e => this.setState({ formulaParentType: e })}
                changeChild={e => this.setState({ formulaChildType: e })}
                entity={this.props.entity}

            />
            : <div>
                <div className="rmod-header">
                    <b>Добавить сущность</b>
                    <a onClick={this.props.close} style={{ marginRight: "60px" }} className="mclose"><i className="flaticon-close" /></a>
                    <div className="togglelist"><button onClick={this.sendForm} className="rmod-ok" disabled=""><i className="flaticon-interface-1" /></button></div>
                </div>
                <div className="mod-cont sbform">
                    <div className="sbform">
                        <Input
                            label={"Название"}
                            value={this.state.formControl.name.value}
                            valid={this.state.formControl.name.valid}
                            onChange={e => this.setField("name", e.target.value)}
                            shouldValidate={true}
                        />
                        <Myselect
                            empty={this.state.entity.length < 1}
                            label="Выбрать сущность"
                            options={[
                                {value:'lead', label: "Лид"},
                                {value:'opportunity', label: "Сделка"},
                                {value:'order', label: "Заказ"},
                                {value:'account', label: "Контрагент"},
                                {value:'contact', label: "Контакт"},
                                {value:'invoice', label: "Счет"},
                                {value:'project', label: "Проект"},
                                {value:'document', label: "Документ"},
                                {value:'product', label: "Продукт"},
                            ]}
                            value={this.state.entity}
                            valid={this.state.entityValid}
                            shouldValidate={true}
                            onChange={e => this.searchEntity(e.target.value)}
                        />
                        {this.state.isRelated ? <Myselect
                            label="Создать связь"
                            options={[{value:'false',label:'Нет'},{value:'true',label:'Да'}]}
                            value={this.state.related}
                            onChange={e => this.setState({ related: e.target.value })}
                        /> : null}
                        <Input
                            tooltip="Процесс запустится через N секунд, после того, как сработает триггер"
                            type="number"
                            value={this.state.formControl.time_value.value}
                            onChange={e => this.setField("time_value", e.target.value)}
                            shouldValidate={false}
                            label="Отложенный запуск на N секунд"
                        />
                        <div className="connection" style={{marginTop: "10px"}}><span>Выберите поля для изменения</span>
                            <div className="toggleparrent"><button onClick={this.addItem} className="btni">+</button></div></div>
                        <table style={{width:"100%"}} className="filterBpm">
                            <thead>
                            <tr>
                                <td style={{padding:"0px 5px"}}>Поле</td>
                                <td style={{padding:"0px 5px"}}>Оператор</td>
                                <td style={{padding:"0px 5px"}}>Значение</td>
                                {this.state.filter.length > 1 ? <td /> : null}
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.filter.map((item, index) =>
                                    <FilterItem
                                        setFilter={this.setFilter}
                                        delItem={this.delItem}
                                        updData={this.updData}
                                        searchValue={this.searchValue}
                                        searchType={this.searchType}
                                        handleOption={this.handleOption}
                                        getFormula={(i) => this.setState({ isFormula: true, formulaValue: i })}
                                        key={index}
                                        ind={index}
                                        fields={entityFields[this.state.entity]}
                                        filter={this.state.filter}
                                        operators={this.state.operators}
                                        thisAdditionalFields={this.state.thisAdditionalFields}
                                    />
                                )
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
    }
}

const FilterItem = props => {
    return <tr>
        <td>
            <Myselect
                onChange={e => props.setFilter(props.ind, "field", e.target.value, e)}
                empty={props.filter[props.ind].type.length === 0}
                value={props.filter[props.ind].field}
                disabled={props.filter[props.ind].r}
                options={props.fields} /></td>
        <td style={{ width:"100px" }}>
            <Myselect
                /*onChange={e => {props.setFilter(props.ind, "type", e.target.value, e); props.setFilter(props.ind, "object", e.target.value, e); }}
                value={props.filter[props.ind].object}
                options={[
                    {value:props.searchValue(props.filter[props.ind].field, "type") ,label:"Значение",type:'number',link:'',arr:[], filterSearch:'value', minLength:"99999",edit:true,object:''},
                    {value:"reference",label:"Ссылка на поле",type:'reference',link:'',arr:[], filterSearch:'value', minLength:"99999",edit:true,object:''},
                    {value:"formula",label:"Формула",type:'formula',link:'',arr:[], filterSearch:'value', minLength:"99999",edit:true,object:''},
                ]}
*/
                onChange={e => {
                    //props.setFilter(props.ind, "object", props.filter[props.ind].field, e);
                    props.setFilter(props.ind, "type", e.target.value, e);
                }}
                value={props.filter[props.ind].type}
                options={[
                    {value:props.searchValue(props.filter[props.ind].field, "type") ,label:"Значение",type:'number',link:'',arr:[], filterSearch:'value', minLength:"99999",edit:true,object:''},
                    {value:"reference",label:"Ссылка на поле",type:'reference',link:'',arr:[], filterSearch:'value', minLength:"99999",edit:true,object:''},
                    {value:"formula",label:"Формула",type:'formula',link:'',arr:[], filterSearch:'value', minLength:"99999",edit:true,object:''},
                ]}
            /></td>
        <td>
            {(props.filter[props.ind].type === "text") ?
                <Input
                    disabled={ props.filter[props.ind].field.length < 1 }
                    onChange={e => props.setFilter(props.ind, "value", e.target.value)}
                    value={props.filter[props.ind].value}
                    shouldValidate={true}
                    valid={props.filter[props.ind].value.length >= 1}
                />
                : null}
            {(props.filter[props.ind].type === "number") ?
                <Input
                    type="number"
                    disabled={ props.filter[props.ind].field.length < 1 }
                    onChange={e => props.setFilter(props.ind, "value", e.target.value)}
                    value={props.filter[props.ind].value}
                    shouldValidate={true}
                    valid={props.filter[props.ind].value.length >= 1}
                />
                : null}
            {(props.filter[props.ind].type === "date") ?
                <DateTime
                    value={props.filter[props.ind].value}
                    valid={props.filter[props.ind].value ? String(props.filter[props.ind].value).length > 4 : false}
                    isClearable={true}
                    handleChange={e => props.setFilter(props.ind, "value", e)}
                    dateFormat="dd-MMMM-yyyy"
                    todayButton
                />
                : null}
            {(props.filter[props.ind].type === "multy") ?
                <ModAutoSelect
                    link={props.searchValue(props.filter[props.ind].field, "link")}
                    minLength={props.searchValue(props.filter[props.ind].field, "minLength")}
                    result={props.searchValue(props.filter[props.ind].field, "filterSearch")}
                    event="status"
                    valid={(props.filter[props.ind].obj)}
                    shouldValidate={false}
                    addType="status"
                    entity={props.filter[props.ind].obj}
                    entityName={props.searchValue(props.filter[props.ind].field, "filterSearch")}
                    updateData={(id, arr, par2) => props.updData(props.ind,id, arr)}
                    unLink={() => props.setFilter(props.ind, "obj", null)}
                />
                : null}
            {(props.filter[props.ind].type === "multiselect" || props.filter[props.ind].type === "select") ?
                <Myselect
                    empty
                    value={props.filter[props.ind].value}
                    options={props.searchValue(props.filter[props.ind].field, "arr")}
                    //valid={props.filter[props.ind].value ? String(props.filter[props.ind].value).length > 4 : false}
                    val="id"
                    onChange ={e => props.setFilter(props.ind, "value", e.target.value)}
                />
                : null}
            {(props.filter[props.ind].type === "reference") ?
                <MyselectReference
                    empty
                    value={props.filter[props.ind].value}
                    options={props.thisAdditionalFields}
                    //valid={props.filter[props.ind].value ? String(props.filter[props.ind].value).length > 4 : false}
                    //val="id"
                    onChange ={e => props.setFilter(props.ind, "value", e.target.value)}
                />
                : null}
            {(props.filter[props.ind].type === "formula") ?
                <Input
                    type="text"
                    disabled={false}
                    onChange={e => props.setFilter(props.ind, "value", e.target.value)}
                    value={props.filter[props.ind].value}
                    shouldValidate={true}
                    valid={props.filter[props.ind].value.length >= 1}
                    onClick={ () => props.getFormula(props.ind) }
                />
                : null}

        </td>
        {props.filter.length > 0 && !props.filter[props.ind].r ? <td style={{ width:"15px" }}>
            <a onClick={() => props.delItem(props.ind)} style={{ fontSize:"19px", color:"#c2263f", display:"block", textAlign:"center" }}>x</a>
        </td> : null}
    </tr>
};

function isInvalid({valid, shouldValidate, isFormValid}) {
    return !valid && shouldValidate && !isFormValid
}

const Myselect = props => {
    const vl = props.value || "";
    let val = props.val ? props.val : "value";
    return <div className={props.className}>
        {(props.label) ? <label data-tooltip={props.tooltip}>{(props.tooltip) ? <div className="squerTooltipe"/> : null} {props.label}</label> : null}
        <select value={vl} onChange={props.onChange} style={props.style} disabled={props.disabled}>
            {(props.empty) ? <option value={``} /> : null }
            {
                props.options ? props.options.map((i, index)=>
                    <option style={{ display: i.edit === false || i.r === true ? "none" : "" }} key={index} data-link={i.link} data-sel={i.type} value={i[val]}>{i.label}</option>
                ) : null
            }
        </select>
        {
            (isInvalid(props)) ? <span className="errorMsg">{(props.errorMessage) || "Заполните поле"}</span> : null
        }
    </div>
};

const MyselectReference = props => {
    const vl = props.value || "";
    let val = props.val ? props.val : "value";
    return <div className={props.className}>
        {(props.label) ? <label data-tooltip={props.tooltip}>{(props.tooltip) ? <div className="squerTooltipe"/> : null} {props.label}</label> : null}
        <select value={vl} onChange={props.onChange} style={props.style} disabled={props.disabled}>
            {(props.empty) ? <option value={``} /> : null }
            {
                props.options ? props.options.map((i, index)=>
                    <option key={index} data-link={i.link} data-sel={i.type} value={i[val]}>{i.label}</option>
                ) : null
            }
        </select>
        {
            (isInvalid(props)) ? <span className="errorMsg">{(props.errorMessage) || "Заполните поле"}</span> : null
        }
    </div>
};

function sort(arr, value) {
    let a = true;
    arr.map(item => {
        if(item.value == value){
            a = false;
        }
    });
    return a;
}
function AditionalFilter(type, arr, additional) {
    if(type === "filter"){
        let prms = arr;
        for(let i=0; i<additional.length; i++){
            if(sort(arr, additional[i].field_id)){
                prms.push({
                    value: additional[i].field_id,
                    label: additional[i].label,
                    type: (additional[i].type === "multiselect") ? "multiselect" : (additional[i].type === "select") ? "select" : (additional[i].type === "date" || additional[i].type === "dateTime") ? 'date' : "text",
                    filterSearch:'name',
                    arr:additional[i].options,
                    link:"",
                    filterLength:"9999999999"
                })
            }
        }
        return prms
    }
}

const Formula = props => {
    function vl(v) {
        switch(props.formulaParentType){
            case "fields": return props.fields; break;
            case "operators": return [
                {value:'+', label: "Плюс +"},
                {value:'-', label: "Минус -"},
                {value:'*', label: "Умножить *"},
                {value:'/', label: "Разделить /"},
            ]; break;
            case "fns": return []; break;
            case "glb": return []; break;
        }
    }
    return <div>
        <div className="rmod-header">
            <b>Редактировать формулу</b>
            <a onClick={props.close} style={{ marginRight: "0px" }} className="mclose"><i className="flaticon-close" /></a>
            {/*<div className="togglelist"><button className="rmod-ok" disabled=""><i className="flaticon-interface-1" /></button></div>*/}
        </div>
        <div className="mod-cont sbform formulaTxt">
            <Myselect
                empty
                label="Типы значений"
                className="rc6"
                options={[
                    {value:"fields", label:"Поля"},
                    {value:"operators", label:"Операторы"},
                    {value:"fns", label:"Функции"},
                    {value:"glb", label:"Глобальные переменные"},
                ]}
                value={props.formulaParentType}
                onChange={e => {props.changeParent(e.target.value); props.changeChild('')} }
            />
            <Myselect
                empty
                label="Допустимые значения"
                className="rc6"
                options={vl(props.formulaParentType)}
                value={props.formulaChildType}
                onChange={e => props.changeChild(e.target.value)}
            />
            <div className="clear" />
            <Input label="Переменная" value={(props.formulaParentType === "fields" && props.formulaChildType !== "" ) ? `{${props.entity}.${props.formulaChildType}}` : props.formulaChildType}/>
            <Textarea
                rows="6"
                label="формула"
                value={props.filter[props.formulaValue].value}
                onChange={e => {
                    const filter = props.filter;
                    filter[props.formulaValue].value = e.target.value;
                    props.changeFilter(filter)
                }}
            />
        </div>
    </div>
}
