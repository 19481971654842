import React,{ Component } from 'react';
import {withRouter} from 'react-router-dom';
import Myselect from '../components/UI/Select';
import Loader from '../components/UI/Loader';
//import MultiSelect from '../components/UI/MultiSelect';
import myaxios from '../components/Axios';
import Notif from '../components/Notif';

class Export extends Component {
    state = {
        formControl: {
            type: {value: "pdf"},
            select: {
                value: '',
                custom:[],
                options:[],
            }
        },
        tch: false,
        loading: false
    }
    setField = (event, type) => {
        const formControl = {  ...this.state.formControl };
        const control = { ...formControl[type] };
        control.value = event.target.value;
        formControl[type] = control;
        this.setState({
            formControl, tch: false
        })
    };
    updateData = (type, id) => {
        let formControl = this.state.formControl;
        if(type === "add") {
            formControl.select.options[id] = (formControl.select.custom[id]);
            delete formControl.select.custom[id];
        } else {
            formControl.select.custom[id] = formControl.select.options[id];
            delete formControl.select.options[id];
        }
        this.setState({ formControl })
    }
    getExport = () => {
        myaxios.get(`/ajax/${this.props.id.type}/prepare`, {
            method: 'get',
        }).then((response) => {
            const formControl = this.state.formControl;
            let i = 0;
            if(response.data.fields){
                Object.keys(response.data.fields).map((key, index) => {
                    formControl.select.options[i] = ({id: i, value: key, name: response.data.fields[key] })
                    i++;
                });
            }
            this.setState({ formControl })
        })
    };
    componentDidMount(){
        this.getExport();
    }
    filedsString = () => {
        let a = [];
        this.state.formControl.select.options.map(item =>
            a.push(item.value)
        )
        return a.join(',');
    }

    sendExportRequest = async () => {
        const { status, data } =
            await myaxios.get(`/ajax/${this.props.id.type}/export?format=${this.state.formControl.type.value}${this.props.id.filter}&fields=${this.filedsString()}`)

        if (status === 200) {
            this.props.close()
            Notif('save', 'Экспорт запущен. ')
        }
    }

    render(){
        return (this.state.loading) ? <Loader contain /> : <div className="sbform create-activ">
            <div className="rmod-header">
                <b>Экспорт</b>
                <div className="togglelist"><a className="rmod-ok" onClick={this.sendExportRequest} /* disabled={this.state.tch}*/ ><i className="flaticon-folder-3" /></a></div>
            </div>
            <div className="mod-cont">
                <Myselect
                    label="Тип экспорта"
                    options={[
                        {value: "pdf", label: "PDF"},
                        {value: "excel", label: "Excel"},
                        {value: "csv", label: "CSV"},
                    ]}
                    value={this.state.formControl.type.value}
                    onChange={(event) => this.setField(event, "type")}
                />
                <MultiSelect
                    className="fgroup"
                    label="Поля для экспорта"
                    custom={this.state.formControl.select.custom}
                    options={this.state.formControl.select.options}
                    updateData={this.updateData}
                    valid={true}
                    errorMessage="ыввфыа ффывафываыфв фываыфв"
                />
            </div>
        </div>
    }
}

export default withRouter(Export)


function toggleBlock() {
    let display = document.getElementById('parentSelectAbsolute');
    if(display.style.display === "block") {
        display.style.display = "none"
    } else {
        display.style.display = "block";
    }
}
function onBlurFunc() {
    let display = document.getElementById('parentSelectAbsolute');
    display.style.display = "none"
}

const MultiSelect = props => {
    return <div className="parentSelectDiv fgroup">
        {(props.label) ? <label>{props.label}</label> : null}
        <div className={`parentSelectRelative ${(props.valid) ? '' : 'requiredInput'}`}>
            {(props.options.length > 0) ? props.options.map((item, index) =>
                <div className="itemContainerItem">{item.name} <a title="Отвязать" onClick={() => props.updateData("delete",item.id)} className="unlink">x</a></div>
            ) : <span>Выбрать</span>}
            <a onClick={toggleBlock} className="parentSelectDivIcon"><i className="flaticon-list-2" /></a>
        </div>
        {(props.valid) ? '' : <span className="errorMsg">{props.errorMessage}</span>}
        <button className="clearButtonStyle" onBlur={onBlurFunc}>
            <ul id="parentSelectAbsolute" className="parentSelectAbsolute">
                {(props.custom.length > 0) ? props.custom.map((item, index) =>
                    <li key={index} onClick={() => props.updateData("add",item.id)}>
                        <i className="flaticon-add-circular-button" /><b>{item.name}</b>
                    </li> ) : <li>Пусто</li>
                }
            </ul></button>
    </div>
}