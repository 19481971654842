import React, {useEffect, useState} from "react";
import myaxios from "../Axios";
import fileIcon from '../../fonts/icons/file.svg'
import file2Icon from '../../fonts/icons/image.svg'
import Textarea from "../UI/Textarea";

const Test = () => {
    const handleSelectedFile = event => {
        //event.target.files[0],
        const data = new FormData();
        data.append('UploadForm[file]', event.target.files[0], event.target.files[0].name);

        myaxios.post(`https://dev.sboxcrm.com/ajax/file/upload?related_type=23&related_id=244`, data).then(res => {

        })
    };

    const [st, setSt] = useState({})

    const testtt = () => {
        setSt(prevState => ({ ...prevState, a: "1"  }))
        setSt(prevState => ({ ...prevState, b: "2"  }))
        test2()
    }

    const test2 = () => {

    }

    useEffect(() => {
        console.log("132321")
    }, [st])

    return (
        <div className="page">
            <button onClick={testtt}>test</button>
            <div className="sendMessageBlock sbform " style={{ width: "500px" }}>
                <button><i className="flaticon-paper-plane" /></button>
                <Textarea  />
                <label htmlFor="file_message_upload" className="file_message_upload file_message_upload__file">
                    <img src={fileIcon} alt=""/>
                    <input
                        name="UploadForm[file]"
                        onChange={e => handleSelectedFile(e, "file")}
                        id="file_message_upload" type="file"
                        style={{ opacity: 0, width: "0", height: "0", overflow: "hidden" }}
                    />
                </label>
                <label htmlFor="image_message_upload" className="file_message_upload file_message_upload__image">
                    <img src={file2Icon} alt=""/>
                    <input
                        name="UploadForm[file]"
                        onChange={e => handleSelectedFile(e, "image")}
                        id="image_message_upload" type="file"
                        style={{ opacity: 0, width: "0", height: "0", overflow: "hidden" }}
                    />
                </label>
            </div>
        </div>
    )
}

export default Test