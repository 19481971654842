import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

function searchIndex(arr, id){
    for(let i = 0; i < arr.length; i++){
        if(arr[i]){
            if(arr[i].value == id){
                return i
            }
        }
    }
}

const Contact = props => {
    var lead = props.contact;
    lead.owner_id = lead.owner ? lead.owner.name : lead.owner_id
    if(lead.additionalFields){
        for(let i = 0; i < lead.additionalFields.length; i++){
            if(lead.additionalFields[i]){
                if(lead.additionalFields[i].type.name === "select" && lead.additionalFields[i].value){
                    lead[lead.additionalFields[i].field_id] = lead.additionalFields[i].options[searchIndex(lead.additionalFields[i].options, lead.additionalFields[i].value)].label || ''
                } else if(lead.additionalFields[i].type.name === "multiselect" && lead.additionalFields[i].value.length > 0){
                    //console.log(lead.additionalFields[i].options[searchIndex(lead.additionalFields[i].options, lead.additionalFields[i].value[0])].label)
                    let a = ``;
                    let val = lead.additionalFields[i].value;
                    let opt = lead.additionalFields[i].options;
                    for(let z = 0; z < val.length; z++){
                        a += opt[searchIndex(opt,val[z])].label;
                        a += ', ';
                        lead[lead.additionalFields[i].field_id] = a;
                    }
                } else {
                    lead[lead.additionalFields[i].field_id] = lead.additionalFields[i].value || ''
                }
            }
        }
    }

    return <tr className="pull-up">
        <td><label><input
            className="checkbox checkboxlead"
            type="checkbox"
            name="checkbox-test"
            onClick={props.delete}
            //onClick={this.setChecked}
            //defaultChecked={this.state.checked}
        /><span className="checkbox-custom"/></label></td>

        {/*<td><a onClick={() => props.history.push('/contacts/' + props.id)}>{props.title}</a></td>*/}
        <td><span className="img">{(props.name) ? props.name.slice(0,2) : null}</span></td>
        <td>
            <a onClick={() => props.history.push('/contact/' + props.id)} className="os">{(props.lastName) ? props.lastName : null} {(props.name) ? props.name : null} {(props.patronymic) ? props.patronymic : null}</a><br />
            <b>{props.prof} {(props.company) ? <a href="#">{props.company}</a> : null}</b>
        </td>
        <td>
            {(props.status) ? <span className="label-st" style={{ backgroundColor: props.status.color}}>{props.status.value}</span> : null}
        </td>
        <td><div className="toggleMenu">{props.email}<ul><li><a onClick={() => props.updateData("SendMail", {from: props.email})}>Отправить Email</a></li></ul></div></td>
        <td>{(props.mobileTel) ? <div className="toggleMenu">Моб: {props.mobileTel}<ul><li><a onClick={() => props.updateData("Call", {tel: props.mobileTel, entity: "contact", id: props.id})}>Позвонить</a></li><li><a onClick={() => props.updateData("SendSms", {tel:props.mobileTel, entity: "contact", id: props.id})}>Отравить SMS</a></li></ul></div> : null}
            {(props.workTel) ? <div className="toggleMenu">Раб: {props.workTel}<ul><li><a onClick={() => props.updateData("Call", {tel: props.mobileTel, entity: "contact", id: props.id})}>Позвонить</a></li><li><a onClick={() => props.updateData("SendSms", {tel:props.mobileTel, entity: "contact", id: props.id})}>Отравить SMS</a></li></ul></div> : null}</td>
        {
            props.extraData.map((item, index) =>
                <td key={index} data-tooltip={lead[item]}>{(typeof lead[item] === "string") ? `${lead[item].slice(0,20)} ${lead[item].length > 20 ? '...' : ''}` : lead[item]}</td>
            )
        }
        <td>
            <div className="toogle-bottom fl-r">
                <a className="is"><i className="flaticon-more"/></a>
                <ul className="toogle-list">
                    <li><a onClick={() => props.history.push('/contact/' + props.id)}><i
                        className="flaticon-edit-1">{null}</i> <b>Редактировать</b></a></li>
                    <li><a onClick={props.delItem} className="table-del" href={null}><i
                        className="flaticon-delete-1">{null}</i> <b>Удалить</b></a></li>
                    <li><a href="#"><i className="flaticon-profile-1" /> <b>Сохранить в PDF</b></a></li>
                </ul>
            </div>
        </td>
    </tr>
}
export default withRouter(Contact);