import React, {useEffect, useState} from "react";
import Modal from "../../Modal/Modal";
import Input from "../../UI/Input";
import myaxios from "../../Axios";
import {validationData} from "../../UI/formValid";
import Notif from "../../Notif";
import axios from "../../Axios";
import {NewAutoSelect} from "../../NewUi/NewAutoSelect";

export const TRACKER_URL = 'http://oneweb-tracker/web'

const validationConfig = {
    user: { required: true },
    password: { required: true, maxLength: 255 },
}

const getSubDomain = () => {
    if (window.location.host === 'localhost:3000') {
        return 'dev'
    }
    const host = window.location.host.split('.')
    return host[0];
}

export const Tracker = ({ close, metaData, saveNode, history }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState({})
    const [isLogged, setIsLogged] = useState(false)
    const [users, setUsers] = useState([])
    const [apiArr, setApiArr] = useState([])
    const [values, setValues] = useState({
        email: null,
        crm_api_client_id: null,
        user: null,
        api: null,
        password: 'admin1234',
        tch: true
    })
    
    const getInitialData = async () => {
        try {
            const { status, data } = await axios.post(`${TRACKER_URL}/v1/visit/check-user`, { crm_domain: getSubDomain() })
            if (status === 200 && data.success) {
                setIsLogged(true)
                setValues({
                    ...values,
                    email: data.email,
                    crm_api_client_id: data.crm_api_client_id
                })
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        setIsOpen(true)
        getInitialData().then()
    }, [])

    const saveData = async () => {
        const errorsData = validationData(values, validationConfig);
        setErrors(errorsData)
        if(Object.keys(errorsData).length > 0) {
            Notif('required-fields')
            return false;
        }

        setLoading(true)
        try {
            const { status, data } = await axios.post(TRACKER_URL + '/signup', {
                username: values.user.name,
                email: values.user.email,
                password: values.password,
                name: values.user.name,
                crm_domain: getSubDomain(),
                crm_user_name: values.user.email,
                crm_user_password: values.password,
                crm_api_client_id: values.api.client_id,
                crm_api_client_secret: values.api.client_secret
            })

            if (status === 201) {
                setValues({
                    ...values,
                    email: data.email,
                    crm_api_client_id: data.crm_api_client_id
                })
                localStorage.setItem('tracker-token', data.data.auth_key)
                localStorage.setItem('tracker-userId', data.data.id)
                setIsLogged(true)
            }
        } catch (e) {}
        setLoading(false)
    }

    const closeModal = () => {
        setIsOpen(false); setTimeout(close, 50);
    }

    if (isLogged) {
        return (
            <Modal showLeaveModal tch={values.tch} isOpen={isOpen} title={'CRM Tracker'} loading={loading}
                   save={saveData} parentClassName={'bpmModal'} close={closeModal}>
                <div className="sbform">
                    <Input
                        label={'Клиент для API запросов'} disabled
                        value={values.email}
                    />
                    <Input
                        label={'API'} disabled
                        value={values.crm_api_client_id}
                    />
                    <button onClick={() => history.push('/tracker')} className="success btni mt10">Перейти к настройкам и данным из CRM Tracker</button>
                </div>
            </Modal>
        )
    }

    return (
        <Modal showLeaveModal tch={values.tch} isOpen={isOpen} title={'CRM Tracker'} loading={loading}
               save={saveData} parentClassName={'bpmModal'} close={closeModal}>
            <div className="sbform">
                <NewAutoSelect
                    tooltip={'Ключи для APi можно генерировать в разделе настройки -> API'}
                    label={'API ключ'}
                    searchBy={'name'}
                    entity={values.api}
                    link={'/ajax/apps?filter[name][like]='}
                    updateData={(api) => setValues({ ...values, api, tch: false })}
                    valid={!errors.api} errorMessage={errors.api} shouldValidate
                />
                <NewAutoSelect
                    tooltip={'Пользователь, через которого tracker будет создавать записи и наследовать права.'}
                    label={'Пользователь для API'}
                    searchBy={'name'}
                    entity={values.user}
                    link={'/ajax/settings/user?filter[name][like]='}
                    updateData={(user) => setValues({ ...values, user, tch: false })}
                    valid={!errors.user} errorMessage={errors.user} shouldValidate
                />
                {values.user && <Input
                    label={`Пароль для пользователя ${values.user && values.user.name}`}
                    value={values.password}
                    onChange={e => setValues({ ...values, password: e.target.value })}
                    valid={!errors.password} errorMessage={errors.password} shouldValidate
                />}
            </div>
        </Modal>
    )
}