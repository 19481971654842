import React, {useEffect, useState} from "react";
import {getSingleProcessService, updateSingleProcessService} from "../../services/other/bpProcess";
import {actionsData, searchEntity} from "./BpHelpers";
import ScrollContainer from "react-indiana-drag-scroll";
import LeaveModal from "../other/LeaveModal";
import {ProcessConditionModal} from "./BusinessProcessModals/Conditions";
import {BpActionTask} from "./BusinessProcessModals/BpActionTask";
import {AllActions} from "./BusinessProcessModals/AllActions";
import {BpActionSendEmail} from "./BusinessProcessModals/BpActionSendEmail";
import {BpSendNotification} from "./BusinessProcessModals/BpSendNotification";
import Notif from "../Notif";
import {BpEditTags} from "./BusinessProcessModals/BpEditTags";
import {BpNestedProcess} from "./BusinessProcessModals/BpNestedProcess";
import Loader, {ModalLoader} from "../UI/Loader";
import {BpChangeField} from "./BusinessProcessModals/BpChangeField";
import {BpCreateRecord} from "./BusinessProcessModals/BpcreateRecord";
import {BpHttpRequest} from "./BusinessProcessModals/BpHttpRequest";
import {BpActionSendSms} from "./BusinessProcessModals/BpActionSendSms";
import {BpCreatePayment} from "./BusinessProcessModals/BpCreatePayment";
import {ActionSendPulse} from "./BusinessProcessModals/Integrations/Sendpulse";

const actionsTypes = (type) => {
    return actionsData.find(el => el.type === type)
}

export const BusinessProcessNew = ({ history, match }) => {
    const { params: { id } } = match
    const [info, setInfo] = useState({
        name: '',
        entity: '',
        related_type:'',
        activation_type: '',
        getJSON: '',
        type: ''
    })
    const [nodes, setNodes] = useState([])
    const [modalData, setModalData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [tch, setTch] = useState(true)

    const getProcessData = async () => {
        setLoading(true)
        try {
            const { status, data } = await getSingleProcessService(id)
            console.log(data)
            if(status === 200) {
                setInfo({
                    name: data.name,
                    entity: searchEntity(data.related_type),
                    related_type: data.related_type,
                    activation_type: data.activation_type,
                    type: data.type
                })

                setNodes([{
                    id:1,
                    name: searchEntity(data.related_type),
                    nodeType: "entity",
                    actions:[]
                }, ...data.nodes])
            }
        } catch (e) {}
        setLoading(false)
    }

    const saveProcess = async () => {
        setLoading(true)
        try {
            const { status, data } = await updateSingleProcessService(id, {
                name: info.name, nodes: nodes.filter(el => el.nodeType !== 'entity')
            })
            if (status === 200) {
                Notif('save', 'Процесс успешно сохранен')
                setTch(true)
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        getProcessData().then()
    }, [id])

    const deleteItem = (parent, child) => {
        if(!window.confirm("Удалить элемент?")){ return false; }
        const parsedNodes = JSON.parse(JSON.stringify(nodes))
        if(child !== null) parsedNodes[parent].actions.splice(child, 1);
        else parsedNodes.splice(parent, 1);
        setNodes(parsedNodes)
        setTch(false)
    };

    const changeConditionsNodes = (type, data, yIndex) => {
        if (data) {
            const parsedNodes = JSON.parse(JSON.stringify(nodes))
            if(yIndex) {
                parsedNodes[yIndex].conditions = data.items;
                parsedNodes[yIndex].name = data.name;
            } else {
                parsedNodes.push({
                    name: data.name,
                    conditions: data.items,
                    condition: '',
                    nodeType: "condition",
                    actions: [],
                    id: null
                })
            }
            setNodes(parsedNodes)
            setTch(false)
        }
    }
    
    const changeActionsNodes = (nodeData) => {
        let parsedNodes = JSON.parse(JSON.stringify(nodes))
        if(modalData.metaData) {
            parsedNodes[modalData.index].actions[modalData.childIndex] = nodeData
        } else {
            parsedNodes[modalData.index].actions.push(nodeData)
        }
        setNodes(parsedNodes)
        setModalData(null)
        setTch(false)
    }

    return (
        <div className={'page'}>
            {loading && <ModalLoader />}
            <LeaveModal when={!tch} />
            <div className="butt-group">
                <button onClick={() => history.push('/marketing/process/')} className="success fl-l btni ml10">Назад</button>
                <button onClick={saveProcess} disabled={tch} className="success fl-r btni ml10">Сохранить</button>
            </div>
            <div className="funnelRightBar miniBuilder" style={{ width: "100%", marginTop:"10px"}}>
                <h2 style={{ borderBottom: "4px solid #858585" }}>{info.name}</h2>
                <ScrollContainer className="scroll-container" vertical={true} horizontal={true} ignoreElements=".minIcon, .okBtn">
                    <div className="fullContain">
                        <div className="">
                            {
                                nodes.map((item, indexTop) => (
                                    <div className={`${item.nodeType}`} key={indexTop}>
                                        <div
                                            onClick={item.nodeType === "condition" ? () => setModalData({ type: 'condition', name: item.name, data: item.conditions, yIndex: indexTop }) : null}
                                            className={`fLiftIt wrapBpBlock ${item.nodeType}Block`} style={{ float: "left", cursor: "pointer" }}>
                                            <em style={{textDecoration:"underline"}}>{item.name}</em>
                                            {
                                                (indexTop !== 0 && indexTop !== 1) ? <a onClick={(e) => {
                                                    e.stopPropagation()
                                                    deleteItem(indexTop, null)
                                                } } className="bpmItemDelete" style={{zIndex:"1000"}}>
                                                    <div className="flaticon-delete-1" />
                                                </a> : null
                                            }
                                        </div>

                                        {
                                            item.actions.map((item, index) => (
                                                <div
                                                    onClick={() => setModalData({
                                                        type: item.type,
                                                        metaData: item,
                                                        index: indexTop,
                                                        childIndex: index
                                                    })}
                                                    key={index} className={`fLiftIt toRightBp  ${item.nodeType}`}>
                                                    <div className="bpItemCl">
                                                        <div className="icon"><i className={actionsTypes(item.type).icon}/></div>
                                                        <a>{actionsTypes(item.type).name}</a>
                                                        <em>{item.name}</em>
                                                        <a onClick={(e) => {
                                                            e.stopPropagation()
                                                            deleteItem(indexTop, index)
                                                        }} className="bpmItemDelete" style={{zIndex:"1000"}}>
                                                            <div className="flaticon-delete-1" />
                                                        </a>
                                                    </div>
                                                </div>
                                            ))
                                        }

                                        {item.nodeType !== "entity" && <a onClick={() =>
                                            setModalData({
                                                type: 'actions', index: indexTop
                                            })}
                                                                          className="addActivityBp">
                                            <i data-tooltip="Добавить действие" className="flaticon-file-1" />
                                        </a>}
                                        <div className="clear" />
                                    </div>
                                ))
                            }

                            <a onClick={() => setModalData({ type: 'condition', item: null })} className="fullContainBtn">Добавить условие</a>
                        </div>
                    </div>
                </ScrollContainer>
            </div>
            {modalData && <ProcessModalContainer
                entity_type={info.related_type}
                data={modalData}
                close={() => setModalData(null)}
                saveNode={changeActionsNodes}
                { ...{ actionsData, changeConditionsNodes, setModalData } }
            />}
        </div>
    )
}

const ProcessModalContainer = ({ data, close, changeConditionsNodes, entity_type, saveNode, setModalData, actionsData }) => {
    switch (data.type) {
        case 'condition': return (
            <ProcessConditionModal close={close} data={data} changeNodes={changeConditionsNodes} entity_type={entity_type} />
        )
        case 'actions': return (
            <AllActions close={close} setModalData={setModalData} index={data.index} actionsData={actionsData} entity_type={entity_type} />
        )
        case 'task': return (
            <BpActionTask close={close} metaData={data.metaData} saveNode={saveNode} />
        )
        case 'mail': return (
            <BpActionSendEmail close={close} metaData={data.metaData} saveNode={saveNode} />
        )
        case 'notification': return (
            <BpSendNotification close={close} metaData={data.metaData} saveNode={saveNode} />
        )
        case 'tags': return (
            <BpEditTags close={close} metaData={data.metaData} saveNode={saveNode} entity_type={entity_type} />
        )
        case 'process': return (
            <BpNestedProcess close={close} metaData={data.metaData} saveNode={saveNode} />
        )
        case 'fields': return (
            <BpChangeField close={close} metaData={data.metaData} saveNode={saveNode} entity_type={entity_type} />
        )
        case 'createRecord': return (
            <BpCreateRecord close={close} metaData={data.metaData} saveNode={saveNode} entity_type={entity_type} />
        )
        case 'http_request': return (
            <BpHttpRequest close={close} metaData={data.metaData} saveNode={saveNode} />
        )
        case 'sendSms': return (
            <BpActionSendSms close={close} metaData={data.metaData} saveNode={saveNode} />
        )
        case 'createPayment': return (
            <BpCreatePayment close={close} metaData={data.metaData} saveNode={saveNode} />
        )
        case 'sendpulse_addressbook': return (
            <ActionSendPulse close={close} metaData={data.metaData} saveNode={saveNode} />
        )
        default: return <div />
    }
}