import React from 'react';
import EditEmailBox from './EditEmailBox';
import formValid from '../components/UI/formValid';
import Notif from '../components/Notif';
import myaxios from '../components/Axios';
import Emails from '../components/Emails';

class AddEmailBox extends React.Component {
    state = {
        box: [],
        addEmail: false,
        tch: false,
        other: 'block',
        formControl: {
            email: {
                value: '',
                valid: true,
                validation: {required: true, email: true}
            },
            password: {
                value: '',
                valid: true,
                validation: {required: true, minLength: 3}
            },
            imapServer: {
                value: '',
                valid: true,
                validation: {required: true, minLength: 3}
            },
            imapPort: {
                value: '',
                valid: true,
                validation: {required: true, number: true}
            },
            imapCipher: {value: '1'},
            smtpServer: {value: ''},
            smtpPort: {value: ''},
            smtpCipher: {value: '1'},
            availability: {value: 1},
            encryption: {value: 'ssl'},
            encryption_smtp: {value: "tls"}
        },
        isFormValid: true,
        edit: null
    };

    showEmails(){
        myaxios.get(`/ajax/mail/account`, {method: 'get',}).then((responce) =>{
            this.setState({ box: responce.data.items })
        })
    }
    editAccount = () => {
        myaxios.put(`/ajax/mail/account/${this.state.edit}`, {
            username: this.state.formControl.email.value,
            password_hash: this.state.formControl.password.value,
            host: this.state.formControl.imapServer.value,
            port: this.state.formControl.imapPort.value,
            host_smtp: this.state.formControl.smtpServer.value,
            port_smtp: this.state.formControl.smtpPort.value,
            encryption: this.state.formControl.encryption.value,
            encryption_smtp: this.state.formControl.encryption_smtp.value,
            is_main: 1,
            status: "active",
            is_public: 1
        }).then(res => {
            if(res) if(res.status === 200 || res.status === 201 || res.status === 204){
                this.setState({ edit: null, addEmail: false });
                Notif("save")
            }
        })
    };

    openAccount = (id) => {
        const account = this.state.box.filter(item => item.id === id)[0];
        const formControl = this.state.formControl;
        formControl.email.value = account.username;
        formControl.password.value = account.password_hash;
        formControl.imapServer.value = account.host;
        formControl.imapPort.value = account.port;
        formControl.smtpServer.value = account.host_smtp;
        formControl.smtpPort.value = account.port_smtp;
        formControl.availability.value = account.is_public;
        formControl.encryption_smtp.value = account.encryption_smtp;
        formControl.encryption.value = account.encryption;
        this.setState({ formControl, addEmail: true, edit: id });
    };

    componentDidMount(){
        this.showEmails();
    }
    deleteMailBox = (e, id, index) => {
        e.preventDefault();
        if(window.confirm()){
            myaxios.delete(`/ajax/mail/account/${id}`, {method: 'get',}).then((responce) =>{
                console.log(responce.status)
                if(responce.status === 200) {
                    const box = this.state.box;
                    delete box[index];
                    this.setState({box});
                    Notif("delete", "Почтовый ящик успешно удален")
                } else {
                    Notif("Error")
                }
            })
        }
    }
    addEmailShow = () => {
        this.setState({ addEmail: (this.state.addEmail) ? false : true })
    };

    isFormValid = () => {
        let formControl = this.state.formControl;
        let isFormValid = true;
        Object.keys(formControl).map((key, index) => {
            formControl[key].valid = formValid(formControl[key].value, formControl[key].validation)
            isFormValid = formControl[key].valid && isFormValid
        });
        this.setState({
            formControl, isFormValid
        });
        return isFormValid;
    };
    sendForm = event => {
        event.preventDefault();
        if(this.isFormValid()){
            this.setState({ loading: true, tch: true });
            myaxios.post(`/ajax/mail/account`, {
                username: this.state.formControl.email.value,
                password_hash: this.state.formControl.password.value,
                host: this.state.formControl.imapServer.value,
                port: this.state.formControl.imapPort.value,
                host_smtp: this.state.formControl.smtpServer.value,
                port_smtp: this.state.formControl.smtpPort.value,
                encryption: this.state.formControl.encryption.value,
                encryption_smtp: this.state.formControl.encryption_smtp.value,
                is_main: 1,
                status: "active",
                is_public: 1
            }).then(response => {
                console.log(response);
                if(response.status === 201){
                    const box = this.state.box;
                    const formControl = this.state.formControl;
                    Object.keys(formControl).map((key) => {formControl[key].value = ''});
                    box.unshift({id: response.data.id, username: response.data.username});
                    this.setState({box, addEmail: false});
                    Notif("save", "Почтовый ящик успешно добавлен, в ближайшее время письма будут синхронизированы")
                } else {
                    Notif("Error", response.data.message[0])
                }
            }).catch((error)=>{
                this.setState({ loading: false });
            });
        } else Notif("Error")
    };
    /*Изменение полей*/
    setField = (event, type) => {const formControl = {  ...this.state.formControl }; const control = { ...formControl[type] };
        control.value = event.target.value; formControl[type] = control; this.setState({formControl, tch: false}) };
    /*Валидация формы*/
    render(){
        return <div className="sbform create-activ">
            <div className="rmod-header">
                <b>Добавить ящик</b>
                <a className="mclose" onClick={this.props.close}><i className="flaticon-close" /></a>
                <div className="togglelist"><button disabled={(this.state.tch && this.state.addEmail === false)} onClick={this.state.edit ? this.editAccount : this.sendForm} className="rmod-ok"><i className="flaticon-add" /></button></div>
            </div>
            <div className="mod-cont">
                <table className="stagesTable sp-table body-table " style={{width: "100%", display: (this.state.addEmail) ? "none" : "table"}} >
                    <thead>
                    <tr>
                        <td>Название</td>
                        <td />
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.state.box.map((item, index) =>
                            <Emails
                                key={index}
                                id={item.id}
                                index={index}
                                username={item.username}
                                deleteMailBox={this.deleteMailBox}
                                close={this.props.close}
                                new_message_count={item.new_message_count}
                                editAccount={this.openAccount}
                            />
                        )
                    }
                    </tbody>
                    {
                        (!this.state.addEmail) ? <a onClick={this.addEmailShow} className="btn-m mt10" href={null}>Добавить ящик</a> : null
                    }
                </table>
                {
                    (this.state.addEmail === true) ? <EditEmailBox
                        addEmail={this.addEmail}
                        other={this.state.other}
                        cansel={() => {
                            const formControl = this.state.formControl;
                            formControl.email.value = '';
                            formControl.password.value = '';
                            formControl.imapServer.value = '';
                            formControl.imapPort.value = '';
                            formControl.smtpServer.value = '';
                            formControl.smtpPort.value = '';
                            formControl.encryption_smtp.value = '';
                            formControl.encryption.value = '';
                            formControl.availability.value = '0';
                            this.setState({ formControl, addEmail: false, edit: null });
                        }
                        }
                        setField={this.setField}
                        isFormValid={this.state.isFormValid}
                        email={this.state.formControl.email.value}
                        password={this.state.formControl.password.value}
                        imapServer={this.state.formControl.imapServer.value}
                        imapPort={this.state.formControl.imapPort.value}
                        imapCipher={this.state.formControl.imapCipher.value}
                        smtpServer={this.state.formControl.smtpServer.value}
                        smtpPort={this.state.formControl.smtpPort.value}
                        smtpCipher={this.state.formControl.smtpCipher.value}
                        availability={this.state.formControl.availability.value}
                        encryption_smtp={this.state.formControl.encryption_smtp.value}
                        encryption={this.state.formControl.encryption.value}
                    /> : null
                }
            </div>
        </div>
    }
}

export default AddEmailBox
