import React, {Component} from 'react';
import myaxios from '../../components/Axios';
import formValid from '../../components/UI/formValid';
import WrapForm from './WrapForm';
import Information from './tab/information';
import Files from '../../components/files/files'
import Notif from '../../components/Notif';
import Loader from '../../components/UI/Loader';
import Task from "../../components/Task";
import AddTask from '../../Modal/AddTask';
import Bgdrop from '../../Modal/Bgdrop';
import Related from '../../components/related/related';
import Attached from './attached/attached';
import AddProjectStage from '../../Modal/AddProjectStage';
import Team from '../../Modal/Team';
import EditTags from '../../Modal/EditTags';
import GetPostAditionalFields from '../../components/get_post_aditionalFields';
import GantChart from '../../components/gantChart';
import {CSSTransition} from "react-transition-group";
import LeaveModal from "../../components/other/LeaveModal";

const obj = {1: "one", 2: "two"};
const arr = [1,2.3];
/*let list = [];
for(let key in obj){
    list.push(
        {value: key, label: obj[key]}
    )
}*/

{/*<div key={key} className="card-panel">{obj[key]}</div>*/}

export default class ProjectView extends Component {
    state = {
        layout: 'information',
        loading: true,
        formControl: {
            name: {value: '', valid: true, validation: {required: true}},
            percentComplete: {value: ''},
            status: {value: '', options: []},
            type: {value: ''},
            responsible: {value: ''},
            comment: {value: ''},
            stages: {value: '', options: []},
            priority: {value:'', valid: true, validation: {number: true}},
            description:{value:''}
        },
        dateTime: {
            startDate: '',
            endDate: '',
            deadline: ''
        },
        attached: {
            account: {id:null, arr:null},
            contact:  {id:null, arr:null},
            opportunity: {id:null, arr:null},
            owner: {id:null, arr:null},
        },
        additionalFields:[],
        isFormValid: true,
        tch: true,
        tasks: [],
        files: [],
        endTask: false,
        taskId: null,
        taskIndex: Number,
        stageId: '',
        open: '',
        openType: '',
        team:[],
        teamId: null,
        user: (this.props.user) ? this.props.user.role.name : '',
        editorTags:'',
        gant: false,
        prevDesc:''
    };
    showProject = async () => {
        await myaxios.get(`/ajax/project/${this.props.match.params.id}?expand=allTasks,contact,opportunity,account,stages,files,owner,statuses,additionalFields`, {
            method: 'get',
        }).then((response) => {
            let formControl = this.state.formControl;
            let dateTime = this.state.dateTime;
            let attached = this.state.attached;
            let tasks = this.state.tasks;
            formControl.name.value = response.data.name || '';
            formControl.percentComplete.value = response.data.execution_percent || '';
            formControl.status.value = response.data.status || '';
            formControl.status.options = response.data.statuses;
            formControl.type.value = response.data.type || '';
            formControl.responsible.value = response.data.owner_id || '';
            formControl.comment.value = response.data.description || '';
            formControl.priority.value = response.data.priority || '';
            formControl.description.value = response.data.description || '';
            dateTime.startDate = response.data.start_date;
            dateTime.endDate = response.data.end_date;
            dateTime.deadline = response.data.deadline;
            //formControl.stages.options = response.data.stages;
            attached.account.arr = response.data.account;
            attached.contact.arr = response.data.contact;
            attached.opportunity.arr = response.data.opportunity;
            attached.owner.arr = response.data.owner;
            attached.account.id = response.data.account_id;
            attached.contact.id = response.data.contact_id;
            attached.opportunity.id = response.data.opportunity_id;
            attached.owner.id = response.data.owner_id;
            tasks = response.data.tasks;
            this.setState({
                formControl,
                dateTime,
                attached,
                prevDesc: response.data.description,
                tasks: response.data.allTasks,
                loading: false,
                files: response.data.files,
                editorTags: response.data.editorTags || '',
                additionalFields: GetPostAditionalFields("get", response.data.additionalFields) || [], });
        }).catch((error)=>{
            this.setState({ loading: false });
        });
    };
    showStage = async () => {
        await myaxios.get(`/ajax/project/${this.props.match.params.id}/work-list?per-page=100`).then(res => {
            const formControl = this.state.formControl;
            formControl.stages.options = res.data.items;
            this.setState({ formControl })
        })
    }
    sendForm = event => {
        event.preventDefault();
        if(this.isFormValid()){
            this.setState({ tch: true, loading: true });
            myaxios.put(`/ajax/project/${this.props.match.params.id}`, {
                name: this.state.formControl.name.value,
                execution_percent: this.state.formControl.percentComplete.value,
                status: this.state.formControl.status.value,
                type: this.state.formControl.type.value,
                description: this.state.formControl.description.value,
                start_date: this.state.dateTime.startDate,
                end_date: this.state.dateTime.endDate,
                deadline: this.state.dateTime.deadline,
                owner_id: this.state.attached.owner.id,
                contact_id: this.state.attached.contact.id,
                opportunity_id: this.state.attached.opportunity.id,
                account_id: this.state.attached.account.id,
                editorTags: this.state.editorTags,
                priority: this.state.formControl.priority.value,
                additionalFields: GetPostAditionalFields("post", this.state.additionalFields),
            }).then(res => {
                if(!res) return;
                if(res.status === 200) Notif("save")
                this.setState({ loading: false});
            }).catch((error)=>{
                this.setState({ loading: false });
            });
        } else Notif("Error")
    };
    getTeam = () =>{
        myaxios.get(`/ajax/project/${this.props.match.params.id}/team?expand=user&per-page=100`, {
            method: 'get',
        }).then((response) => {
            this.setState({team: response.data.items});
        })
    }
    componentDidMount(){
        this.showProject();
        this.showStage();
        this.getTeam();
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.match.params.id !== this.props.match.params.id) {
            this.showProject();
            this.showStage();
            this.getTeam();
        }
    }
    isFormValid = () => {
        let formControl = this.state.formControl;
        let additionalFields = this.state.additionalFields;
        let contactInfo = this.state.contactInfo;
        let isFormValid = true;
        Object.keys(additionalFields).map((key, index) => {
            additionalFields[key].valid = formValid(additionalFields[key].value, additionalFields[key].validation);
            isFormValid = additionalFields[key].valid && isFormValid
        });
        Object.keys(formControl).map((key, index) => {
            formControl[key].valid = formValid(formControl[key].value, formControl[key].validation);
            isFormValid = formControl[key].valid && isFormValid
        });
        this.setState({
            formControl, additionalFields, contactInfo, isFormValid
        });
        return isFormValid;
    };

    formatDate = (date) => {
        if(date !== null) {
            let dd = date.getDate();
            if (dd < 10) dd = '0' + dd;
            let mm = date.getMonth() + 1;
            if (mm < 10) mm = '0' + mm;
            let yy = date.getFullYear() % 10000;
            if (yy < 10) yy = '0' + yy;
            return yy + '-' + mm + '-' + dd;
        }
    }
    handleChange = (date, type) => {
        const dateTime = this.state.dateTime;
        dateTime[type] = this.formatDate(date);
        this.setState({
            dateTime, tch: false
        });
    };
    deleteUser = () => {
        if(window.confirm("Удалить проект?")) {
            myaxios.delete('/ajax/project/' + this.props.match.params.id).then(res => {
                if(res.status === 200 || res.status === 201 || res.status === 204)
                this.props.history.push(`/project`);
                Notif("delete", "Проект успешно удален")
            })
        }
    };






    /*Изменение полей*/
    setField = (event, type) => {const formControl = {  ...this.state.formControl }; const control = { ...formControl[type] };
        control.value = event.target.value; formControl[type] = control; this.setState({formControl, tch: false}) };
    /*Валидация формы*/

    /*Задачи*/
    task = (type, arr, index) => {
        const tasks = this.state.tasks;
        if(type === "add") {tasks.unshift(arr)}
        else if(type === "edit"){tasks[index] = arr}
        else if(type === "delete") {delete tasks[index]}
        this.setState({tasks, open: '', openType:'', taskId:'', taskIndex:''})
    }

    /*Адрес*/
    stagesFunc = (type, modal, id, arr, index) => {
        const formControl = this.state.formControl;
        if(type === "open") {
            this.setState({ openType: modal, open: 'r-open', stageId: (id) ? id : null})
        } else if(type === "add") {
            formControl[modal].options.unshift(arr);
            this.setState({ formControl, open: null })
        } else if(type === "delete") {
            if(window.confirm()) {
                myaxios.delete('/ajax/project/work/' + id).then(res => {
                    if(res.status === 200) {
                        this.showStage()
                    }
                    Notif("delete", "Этап успено удален")
                })

            }
        }
    };


    filesFunc = (type, index, arr) => {
        const files = this.state.files;
        if(type === "del" && arr.status === 200){
            delete files[index];
            Notif("delete", "Файл успешно удален")
        } else if(arr.status === "uploaded"){
            files.unshift({id: arr.id, name: arr.name, created_at: "Только что", type: arr.type});
            Notif("save", "Файл успешно загружен")
        } else if(arr.status === "error"){
            Notif("Error", arr.errors.file[0])
        }
        this.setState({ files })
    };
    updateData = (id, arr, addType, endParrans) => {
        const attached = this.state.attached;
        attached[addType].arr = arr;
        attached[addType].id = id;
        this.setState({ attached, tch: false })
    };
    unLink = (type, typeID, id) => {
        if(type === "account" || type === "contact") {
            this.unLink("opportunity")
        }
        if(type === "provider") this.unLink("paymentDetail");
        const attached = this.state.attached;
        attached[type].arr = null;
        attached[type].id = null;
        this.setState({ attached, tch: false })
    };

    deleteTeam = id => {
        if(window.confirm()){
            myaxios.post(`/ajax/project/${this.props.match.params.id}/unlink-member/${id}`).then(responce => {
                if (responce.status === 200 || responce.status === 201 || responce.status === 204) {
                    this.getTeam();
                    Notif("save")
                }
            })
        }
    }
    render() {
        return (
            <div className="page">
                {this.state.loading && <Loader/>}
                <CSSTransition in={!this.state.loading} timeout={200} classNames="slide" unmountOnExit>
                    <div>
                        <LeaveModal when={!this.state.tch} />
                <div className="butt-group butt-group-card">
                    <div className="tagsName">
                        <a data-tooltip={this.state.editorTags.length > 0 ? this.state.editorTags : null} onClick={() => this.setState({ open:'r-open', openType: "tags" })} className="success fl-l btni mr10" >
                            Теги ({(this.state.editorTags.length > 0) ? this.state.editorTags.split(',').length : '0'})</a>
                    </div>
                    <a onClick={() => this.setState({ open:'r-open', openType: "task" })} className="success fl-l btni">
                        <span>Добавить задачу</span>
                        <i className="flaticon-notes mdb" />
                    </a>
                    <button onClick={this.sendForm} className="butt-group-card-wrap success fl-r btni ml10" disabled={this.state.tch}>
                        <span>Сохранить</span>
                        <i className="flaticon-interface-1 mdb" />
                    </button>
                    <a style={{marginRight: "-5px", marginLeft: "5px", background: "#62a091", padding: "8px"}} data-tooltip="Диаграмма ганта" onClick={() => this.setState({ gant: (!this.state.gant) })} className="mdn fl-r btns"><i className="flaticon-layers">{null}</i></a>
                    <a title={'/ajax/project/' + this.props.match.params.id} onClick={this.deleteUser} className="butt-group-card-wrap fl-r btni ml10">
                        <span>Удалить</span>
                        <i className="flaticon-delete-2 mdb" />
                    </a>
                </div>
                <div className="clear" />
                {this.state.gant ? <GantChart style={{marginTop: "20px"}} link="/ajax/project/work" items={this.state.formControl.stages.options} start="start_date" end="end_date" /> : null}
                <div className="contact-page">
                    <div className="contact-left">
                        <div className="contact-left-col">
                            <h3>Данные проекта</h3>
                            <WrapForm
                                setField={this.setField}
                                handleChange={this.handleChange}
                                isFormValid={this.state.isFormValid}
                                nameValid = {this.state.formControl.name.valid}
                                name = {this.state.formControl.name.value}
                                percentComplete = {this.state.formControl.percentComplete.value}
                                status = {this.state.formControl.status.value}
                                statusOptions = {this.state.formControl.status.options}
                                type = {this.state.formControl.type.value}
                                responsible = {this.state.formControl.responsible.value}
                                owner={this.state.attached.owner.arr}
                                opportunity={this.state.attached.opportunity.arr}
                                priority={this.state.formControl.priority.value}
                                priority_valid={this.state.formControl.priority.valid}
                                unLink={this.unLink}
                                updateData={this.updateData}
                            />
                        </div>
                        {(this.state.user !== "drawer") ? <Attached
                            setField={this.setField}
                            updateData={this.updateData}
                            unLink={this.unLink}
                            account={this.state.attached.account.arr}
                            contact={this.state.attached.contact.arr}
                            updateDataContact={this.props.updateData}
                        /> : null}
                    </div>
                    <div className="contact-right-col">
                        <div className="taskcontainer">
                            {(this.state.tasks) ? this.state.tasks.map((task, index) =>
                                <Task
                                    key={index}
                                    openTask={(id, index) => this.setState({ open: "r-open", openType: "task", taskIndex: index, taskId: id})}
                                    index={index}
                                    id={task.id}
                                    tasktext={task.subject}
                                    end_date={task.end_date}
                                    status={task.status}
                                />
                            ) : null}
                        </div>
                        <div className="tabs-header">
                            <ul>
                                <li><a className={(this.state.layout === "information") ? "active" : null} onClick={() => this.setState({ layout: 'information' })}>Основная информация</a></li>
                                <li><a className={(this.state.layout === "related") ? "active" : null} onClick={() => this.setState({ layout: 'related' })}>Связи</a></li>
                                <li><a className={(this.state.layout === "files") ? "active" : null} onClick={() => this.setState({ layout: 'files' })}>Файлы</a></li>
                            </ul>
                        </div>
                        <div className="tab-contai er">

                            {(this.state.layout === "information") ? <Information
                                setDescription={(e) => {
                                    console.log("e", e)
                                    const formControl = this.state.formControl;
                                    formControl.description.value = e;
                                    this.setState({ formControl, tch: e === this.state.prevDesc })
                                }}
                                setField={this.setField}
                                isFormValid={this.state.isFormValid}
                                startDate={this.state.dateTime.startDate}
                                endDate={this.state.dateTime.endDate}
                                deadline={this.state.dateTime.deadline}
                                comment={this.state.formControl.comment.value}
                                handleChange={this.handleChange}
                                parentId={this.props.match.params.id}
                                stages={this.state.formControl.stages.options}
                                stagesFunc={this.stagesFunc}
                                team={this.state.team}
                                additionalFields={this.state.additionalFields}
                                updateAdditionalFields={(items) => this.setState({ additionalFields: items, tch: false })}
                                description={this.state.formControl.description.value}
                                openTeam={() => this.setState({ open:"r-open", openType: "team" })}
                                editTeam={arr => this.setState({ open:"r-open", openType: "team", teamId: arr })}
                                deleteTeam={this.deleteTeam}
                            /> : null}
                            {(this.state.layout === "related") ?
                                <Related
                                    type="project"
                                    related="6"
                                    id={this.props.match.params.id}
                                    project_id={this.props.match.params.id}
                                    contactEmail={(this.state.attached.contact.arr) ? this.state.attached.contact.arr.email : null}
                                    accountEmail={(this.state.attached.account.arr) ? this.state.attached.account.arr.email : null}
                                    opportunity_id={this.state.attached.opportunity.id}
                                    contact_id={this.state.attached.contact.id}
                                    account_id={this.state.attached.account.id}
                                    account={this.state.attached.account.arr}
                                    contact={this.state.attached.contact.arr}
                                    tasks
                                    invoices
                                    documents
                                    sms
                                    mails
                                    expense
                                /> : null}

                            {(this.state.layout === "files") ? <Files files={this.state.files} filesFunc={this.filesFunc} relatedType="6" related_id={this.props.match.params.id}
                            /> : null}



                        </div>
                    </div>
                </div>

                <React.Fragment>
                    <div className={"right-modal scrollbar " + this.state.open}><div className="save">
                        {/*Задача*/}
                        {(this.state.open === "r-open" && this.state.openType === "task") ?
                            <AddTask
                                task={this.task}
                                id={this.state.taskId}
                                index={this.state.taskIndex}
                                close={() => this.setState({ open:'', openType: '', taskId:'', taskIndex: '' })}
                                tie={this.props.match.params.id}
                                relatedType="6"
                            /> : null}
                        {/*Адресс*/}
                        {(this.state.open === "r-open" && this.state.openType === "stages") ?
                            <AddProjectStage
                                addStage={this.stagesFunc}
                                projectId={this.props.match.params.id}
                                id={this.state.stageId}
                                close={() => this.setState({ open:'', openType:'', teamId: null })}
                            /> : null}

                        {(this.state.open === "r-open" && this.state.openType === "team")
                            ? <Team
                                id={(this.state.teamId) ? this.state.teamId.user_id : null}
                                arr={this.state.teamId}
                                entity="project"
                                parrentId={this.props.match.params.id}
                                close={() => this.setState({ open:'', openType:'', teamId: null })}
                                refresh={() => this.getTeam()}
                            /> : null}
                        {(this.state.open === "r-open" && this.state.openType === "tags")
                            ? <EditTags
                                close={() => this.setState({ open:'', openType:''})}
                                tags={this.state.editorTags}
                                type="project"
                                id={this.props.match.params.id}
                                refresh={() => {}}
                                refreshState={(tags) => {this.setState({ editorTags: tags })} }
                            /> : null}
                        {/*Реквизиты*/}
                        {/*{(this.state.open === "r-open" && this.state.openType === "requisites") ?
                            <Requisites
                                addAddress={this.address}
                                id={this.state.addressId}
                            /> : null}*/}

                    </div></div>
                    {this.state.open ? <Bgdrop close={() => this.setState({ open:'', openType: '', taskId:'', taskIndex: '', teamId: null  })} /> : null}
                </React.Fragment>
                    </div>
                </CSSTransition>
            </div>
        )
    }
}