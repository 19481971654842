import React, {useState} from "react";
import cancelIcon from "../../fonts/icons/cancel.svg";
import {CSSTransition} from "react-transition-group";
import History from "../history/history";
import {formatDateTime} from "./formatDate";

export const ConflictModal = ({ updated_at, entity, id, close, save }) => {
    const [show, setShow] = useState(true)

    return (
        <CSSTransition in={true} timeout={200} classNames="slide" unmountOnExit>
            <div className="leave-modal">
                <div className="leave-modal-container" style={{ maxHeight: '500px', overflow: 'auto', width: '500px' }}>
                    <img className="leave-modal-image" src={cancelIcon} alt=""/>
                    <h3 className="leave-modal-title">{"Конфликт при сохранении"}</h3>
                    <p className="leave-modal-description">{"Запись была изменена другим пользователем. Вы можете сохранить запись принудительно или отменить изменения"}</p>
                    <button className="leave-modal-confirm" onClick={() => close()}>Закрыть</button>
                    <button className="leave-modal-cancel" onClick={() => {
                        save(); close()
                    }}>Сохранить принудительно</button>

                    <div className="clear" />
                    <h2
                        onClick={() => setShow(!show)}
                        style={{
                        color: '#6c6767', fontSize: '16px', marginTop: '30px', marginBottom: '-15px', cursor: 'pointer',
                        fontWeight: 'bold', textDecoration: 'underline', fontFamily: 'Montserrat-Black'
                    }}>{show ? 'Скрыть' : 'Показать'} список изменений</h2>
                    {show && <History type={entity} id={id} created_at={formatDateTime(updated_at)} />}
                </div>
            </div>
        </CSSTransition>
    )
}