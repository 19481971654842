import React, {Component} from 'react';
import Input from '../components/UI/Input';
import Myselect from '../components/UI/Select';

const EditEmailBox = props => {
return <div className="formlabel">
      <Input
          label="Адрес почты"
          className="rc12"
          value={props.email}
          onChange={event => props.setField(event, "email")}
          shouldValidate={true}
          isFormValid={props.isFormValid}
          errorMessage="Введите корректный Email"
      />
      <div style={{display: (props.other)}}>
      <Input
          label="Пароль"
          type="password"
          className="rc12"
          value={props.password}
          onChange={event => props.setField(event, "password")}
          shouldValidate={true}
          isFormValid={props.isFormValid}
          errorMessage="Пароль не может быть пустым"
      />
      <Input
          label="Сервер IMAP"
          className="rc12"
          value={props.imapServer}
          onChange={event => props.setField(event, "imapServer")}
          shouldValidate={true}
          isFormValid={props.isFormValid}
          errorMessage="Поле не может быть пустым"
      />
      <Input
          label="Порт IMAP"
          type="number"
          className="rc4"
          value={props.imapPort}
          onChange={event => props.setField(event, "imapPort")}
          shouldValidate={true}
          isFormValid={props.isFormValid}
          errorMessage="Поле не может быть пустым"
      />
      <Myselect
          label="Шифрование"
          className="rc8"
          options={[
              {value: 'null', label: 'Без шифрования'},
              {value: 'ssl', label: 'SSl'},
              {value: 'tls', label: 'TLS'},
          ]}
          onChange={event => props.setField(event, "encryption")}
          value={props.encryption}
          shouldValidate={false}
      />
      <div className="clear" />
      <Input
          label="Сервер SMTP"
          className="rc12"
          value={props.smtpServer}
          onChange={event => props.setField(event, "smtpServer")}
          shouldValidate={false}
          isFormValid={props.isFormValid}
      />
      <Input
          label="Порт SMTP"
          type="number"
          className="rc4"
          value={props.smtpPort}
          onChange={event => props.setField(event, "smtpPort")}
          shouldValidate={false}
          isFormValid={props.isFormValid}
      />
      <Myselect
          label="Шифрование"
          className="rc8"
          options={[
              {value: 'null', label: 'Без шифрования'},
              {value: 'ssl', label: 'SSl'},
              {value: 'tls', label: 'TLS'},
          ]}
          value={props.encryption_smtp}
          onChange={event => props.setField(event, "encryption_smtp")}
          shouldValidate={false}
      />
      <Myselect
          label="Доступность"
          className="rc12"
          options={[
              {value: 1, label: "Персональный"},
              {value: 2, label: "Корпоративный"},
          ]}
          value={props.availability}
          onChange={event => props.setField(event, "availability")}
          shouldValidate={false}
      />
      <div className="rc12">
          <button onClick={props.cansel} className="rem">отменить</button>
      </div>
      </div>
</div>
}
export default EditEmailBox;