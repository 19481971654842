import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';

const Mails = props => {
    return <div className="sp-table body-table">
        <table style={{borderSpacing: "0"}} className="stagesTable">
            <thead>
            {
                (props.item.length >= 1) ?
                    <tr>
                        <td>От/Кому</td>
                        <td></td>
                        <td>Тема</td>
                        <td>Дата</td>
                    </tr> : null
            }

            </thead>
            <tbody>
            {
                props.item.map((item,index)=>
                    <tr key={index}>
                        <td onClick={() => props.op(item.thread_id, item.folder, item.mail_account_id)}><a>{(item.folder === "sent") ? item.to : item.from}</a></td>
                        <td>{(item.folder === "sent") ? "Отправленные" : "Входящее" }</td>
                        <td>{item.subject}</td>
                        <td>{item.date}</td>
                        <td>
                            <div className="toogle-bottom fl-r">
                                <a className="is"><i className="flaticon-more" /></a>
                                <ul className="toogle-list">
                                    <li><a onClick={()=>props.openModal("email", {id: item.id, from: item.from, subject: item.subject} )}><i className="flaticon-edit-1" /> <b>Ответить</b></a></li>
                                    <li><a onClick={() => props.history.push(`/mail/${item.mail_account_id}/${item.folder}/${item.thread_id}`)}><i className="flaticon-medical" /> <b>Открыть</b></a></li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                )
            }
            </tbody>
        </table>
    </div>
}

export default withRouter(Mails);