import Input from "../UI/Input";
import Myselect from "../UI/Select";
import React, {useEffect, useState} from "react";
import {NewAutoSelect} from "../NewUi/NewAutoSelect";
import Modal from "../Modal";
import Textarea from "../UI/Textarea";

export const allEntities = ['opportunity', 'contact', 'account', 'project', 'product', 'invoice', 'order', 'lead', 'document']
export const actionsData = [
    {
        name: 'Добавить задачу', type: 'task',
        icon: 'flaticon-notes', isIcon: true,
        allowEntities: allEntities.filter(el => el !== 'product'),
    },{
        name: 'Отправить Email', type: 'mail',
        icon: 'flaticon-email', isIcon: true,
        allowEntities: allEntities.filter(el => el !== 'product')
    },
    {
        name: 'Показать уведомление', type: 'notification',
        icon: 'flaticon-music-2', isIcon: true,
        allowEntities: allEntities
    },
    {
        name: 'Работа с тегами', type: 'tags',
        icon: 'flaticon-interface-10', isIcon: true,
        allowEntities: allEntities
    },
    {
        name: 'Вложенный процесс', type: 'process',
        icon: 'flaticon-background', isIcon: true,
        allowEntities: allEntities.filter(el => el !== 'product' || el !== 'lead')
    },
    {
        name: 'Изменить поле', type: 'fields',
        icon: 'flaticon-interface-9', isIcon: true,
        allowEntities: allEntities
    },
    {
        name: 'Создать сущность', type: 'createRecord',
        icon: 'flaticon-file-1', isIcon: true,
        allowEntities: allEntities
    },
    {
        name: 'Добавить платеж', type: 'createPayment',
        icon: 'flaticon-coins', isIcon: true,
        allowEntities: ['invoice']
    },
    {
        name: 'Отправить SMS', type: 'sendSms',
        icon: 'flaticon-speech-bubble-1', isIcon: true,
        allowEntities: allEntities
    },
    {
        name: 'HTTP запрос', type: 'http_request',
        icon: 'flaticon-internet', isIcon: true,
        allowEntities: allEntities
    },
    {
        name: 'SendPulse', type: 'sendpulse_addressbook',
        icon: 'flaticon-email', isIcon: true,
        allowEntities: ['lead', 'contact', 'account']
    },
]

export function searchEntity(id){
    switch(id){
        case 1: return "Сделки";
        case 2: return "Контакты";
        case 3: return "Контрагенты";
        case 6: return "Проекты";
        case 9: return "Продукты";
        case 13: return "Счета";
        case 14: return "Заказы";
        case 23: return "Лиды";
        case 26: return "Документы";
        default: return "Неизвестно";
    }
}

export function searchEntityId(id){
    switch(id){
        case 1: return "opportunity";
        case 2: return "contact";
        case 3: return "account";
        case 6: return "project";
        case 9: return "product";
        case 13: return "invoice";
        case 14: return "order";
        case 23: return "lead";
        case 26: return "document";
        default: return "opportunity";
    }
}

export function searchEntityByName(id){
    switch(id){
        case 'opportunity': return 1;
        case "contact": return 2;
        case "account": return 3;
        case "project": return 6;
        case "product": return 9;
        case "invoice": return 13;
        case "order": return 14;
        case "lead": return 23;
        case "document": return 26;
        default: return 1;
    }
}

export const bpOperators = {
    text: [{value:"==", label:"Равно"}, {value:"!=", label:"Не равно"}, {value:"match", label:"Содержит"}, {value:"nmatch", label:"Не содержит"}],
    multy: [{value:"==", label:"Равно"}, {value:"!=", label:"Не равно"}, {value:"match", label:"Содержит"}, {value:"nmatch", label:"Не содержит"}],
    select: [{value:"==", label:"Равно"}, {value:"!=", label:"Не равно"}, {value:"match", label:"Содержит"}, {value:"nmatch", label:"Не содержит"}],
    multiselect: [{value:"==", label:"Равно"}, {value:"!=", label:"Не равно"}, {value:"match", label:"Содержит"}, {value:"nin", label:"Не содержит"}],
    number: [{value:"==", label:"Равно"}, {value:"!=", label:"Не равно"}, {value:">", label:"Больше"}, {value:"<", label:"Меньше"}],
    date: [{value:"==", label:"Равно"}, {value:"!=", label:"Не равно"}, {value:">", label:"Позже"}, {value:"<", label:"Раньше"}],
}

export const saveTimePeriod = (value, period) => {
    switch (period) {
        case 'minutes': return value * 60
        case 'hours': return value * 3600
        case 'days': return value * 86400
        default: return value
    }
}

export const parseDataForPeriod = (value) => {
    if(value >= 86400 && Number.isInteger(value / 86400)) {
        return { period: 'days', value: value / 86400 }
    } else if(value >= 3600 && Number.isInteger(value / 3600)) {
        return { period: 'hours', value: value / 3600 }
    } else if(value >= 60 && Number.isInteger(value / 60)) {
        return { period: 'minutes', value: value / 60 }
    } else if(value > 0 && value < 60) {
        return { period: 'seconds', value }
    } else {
        return { period: 'minutes', value: 0 }
    }
}

export const BpDateWithPeriod = ({ label, name, periodName, values, errors, setValues, tooltip }) => {
    return (
        <div style={{ display: 'flex' }}>
            <Input
                tooltip={tooltip}
                label={label}
                value={values[name]} type={'number'} className={'w70'}
                onChange={e => setValues([name], e.target.value)}
                errorMessage={errors[name]} valid={!errors[name]}
            />
            <Myselect
                label={'Период'} className={'w30'}
                value={values[periodName]}
                options={[
                    { value: 'seconds', label: 'Секунд' },
                    { value: 'minutes', label: 'Минут' },
                    { value: 'hours', label: 'Часов' },
                    { value: 'days', label: 'Дней' },
                ]}
                onChange={e => setValues([periodName], e.target.value)}
            />
        </div>
    )
}

export const FieldsData = ({ fieldConfig, type, value, obj, onChange, updateData }) => {
    switch (fieldConfig.type) {
        case 'text': return (
            <Input
                value={value} type={'text'}
                onChange={e => onChange(e.target.value)}
            />
        )
        case 'date': return (
            <Input
                value={value} type={'text'}
                onChange={e => onChange(e.target.value)}
            />
        )
        case 'number': return (
            <Input
                value={value}
                type={'number'}
                onChange={e => onChange(e.target.value)}
            />
        )
        case 'select': return (
            <Myselect
                value={value} empty val={'id'}
                options={fieldConfig.options}
                onChange={e => onChange(e.target.value)}
            />
        )
        case 'multiselect': return (
            <Myselect
                value={value} empty val={'id'}
                options={fieldConfig.options}
                onChange={e => onChange(e.target.value)}
            />
        )
        case 'multy': return (
            <NewAutoSelect
                link={fieldConfig.link}
                searchBy={fieldConfig.filterSearch}
                entity={obj}
                updateData={(val) => updateData(val)}
            />
        )
        default: return <div />
    }
}

export const Formula = ({ formula, setFields, setFormula, attr }) => {
    const [value, setValue] = useState('')

    useEffect(() => {
        if(formula) {
            setValue(formula.value)
        }
    }, [])

    const saveData = () => {
        const parsedFields = JSON.parse(JSON.stringify(formula.fields))
        parsedFields[formula.index].value = value;
        setFields(parsedFields)
        setFormula(null)
    }

    return (
        <Modal isOpen={true} title={'Изменить формулу'} save={saveData} close={() => setFormula(null)}>
            <>
                <Textarea
                    label={'Формула'}
                    value={value}
                    rows={'6'}
                    onChange={e => setValue(e.target.value)}
                />
                <div className="second-table" style={{ marginTop: '20px' }}>
                    <h2>Доступные значения</h2>
                    <table style={{ width: '100%' }}>
                        <tr>
                            <th>Поле</th>
                            <th>Значения</th>
                        </tr>
                        <tr>
                            <td>Доступные значения</td>
                            <td>+ - * / ( )</td>
                        </tr>
                        {
                            attr.map(el => ({ ...el, value: `{${el.value}}` })).filter(el => el.type !== 'multy' && el.type !== 'multiselect').map(item => (
                                <tr>
                                    <td>{item.label}</td>
                                    <td>{item.value}</td>
                                </tr>
                            ))
                        }
                    </table>
                </div>
            </>
        </Modal>
    )
}