import React, {Component} from 'react';

function isInvalid({valid, shouldValidate, isFormValid}) {
    return !valid && shouldValidate && !isFormValid
}

const Textarea = props => {
    let stl;
    const val = props.value || "";
    {
        stl = (isInvalid(props)) ? "1px solid #f4516c" : null
    }
    return <div className={props.className}>
    {
        (props.label) ? <label data-tooltip={props.tooltip}>
            {(props.tooltip) ? <div className="squerTooltipe"/> : null} {props.label} {(props.required) ? <span style={{color: "red"}}>*</span> : null}</label> : null
    }

    <textarea
        style={{border: stl, ...props.style}}
        cols={props.cols}
        rows={props.rows}
        defaultValue={props.defaultValue}
        value={val}
        className={props.className}
        onChange={props.onChange}
        onKeyUp={props.onKeyUp}
        onKeyDown={props.onKeyDown}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        placeholder={props.placeholder}
        disabled={props.disabled}
    />
    {
        (isInvalid(props)) ? <span className="errorMsg">{(props.errorMessage) || "Заполните поле"}</span> : null
    }
    </div>
};

export default Textarea;