import React, {useEffect, useState} from "react";
import Myselect from "../../UI/Select";
import {BpDateWithPeriod, parseDataForPeriod, saveTimePeriod} from "../BpHelpers";
import Modal from "../../Modal";
import myaxios from "../../Axios";
import Input from "../../UI/Input";
import {validationData} from "../../UI/formValid";

const validationRules = {
    name: { required: true, maxLength: 255 },
    body: { required: true, maxLength: 255 },
    time_value: { number: true, maxLength: 255 }
}

export const BpSendNotification = ({ close, metaData, saveNode }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState({})
    const [users, setUsers] = useState([])
    const [values, setValues] = useState({
        name: '',
        time_value: 0,
        time_valuePeriod: 'minutes',
        body: "",
        owner: "owner_id",
        tch: true
    })

    const getPrepareData = async () => {
        try {
            const { status, data } = await myaxios.get(`/ajax/settings/user`)
            setUsers(data.items)
            getData()
        } catch (e) {}
    }

    const getData = () => {
        if(metaData) {
            const { name, time_value, metaData: { body, owner } } = metaData
            setValues({
                ...values,
                name,
                body,
                owner,
                time_value:  parseDataForPeriod(time_value).value,
                time_valuePeriod: parseDataForPeriod(time_value).period
            })
        }
        setLoading(false)
    }

    useEffect(() => {
        setIsOpen(true)
        getPrepareData().then()
    }, [])

    const saveData = () => {
        const validErrors = validationData(values, validationRules)
        if(Object.keys(validErrors).length > 0) {
            setErrors(validErrors)
            return false;
        }
        setErrors({})
        setIsOpen(false)

        const { name, body, time_value, time_valuePeriod, owner } = values
        setTimeout(() => saveNode({
            id: metaData && metaData.id,
            name,
            nodeType: 'node',
            time_value: saveTimePeriod(time_value, time_valuePeriod),
            is_scheduled: time_value > 0,
            type: "notification",
            metaData: {
                body,
                owner,
                owner_type: owner === 'owner_id' || owner === 'created_by' ? "reference" : "user",
            }
        }), 100)
    }

    const closeModal = () => {
        setIsOpen(false); setTimeout(close, 200);
    }

    return (
        <Modal showLeaveModal tch={values.tch} isOpen={isOpen} title={'Уведомление'} loading={loading}
               save={saveData} parentClassName={'bpmModal'} close={closeModal}>
            <div className="sbform">
                <Input
                    label={'Название'}
                    value={values.name}
                    onChange={e => setValues({...values, name: e.target.value, tch: false })}
                    errorMessage={errors.name} valid={!errors.name} shouldValidate
                />
                <Input
                    label={'Текст уведомления'}
                    value={values.body}
                    onChange={e => setValues({...values, body: e.target.value, tch: false })}
                    errorMessage={errors.body} valid={!errors.body} shouldValidate
                />
                <Myselect
                    label={'Ответственный'} val={'id'} lab={'name'}
                    options={[
                        { id: 'owner_id', name: 'Текущий ответственный' },
                        { id: 'created_by', name: 'Создавший запись' },
                        ...users
                    ]} value={values.owner}
                    onChange={e => setValues({ ...values, owner: e.target.value })}
                />
                <BpDateWithPeriod
                    label={'Отложенный запуск'}
                    name={'time_value'} periodName={'time_valuePeriod'}
                    values={values} errors={errors}
                    setValues={(type, value) => setValues({...values, [type]: value, tch: false })}
                    tooltip={'Время задержки выполнения процесса'}
                />
            </div>
        </Modal>
    )
}