import React, {useEffect, useState} from "react";
import Modal from "../components/Modal/Modal";
import {FieldGen, setField, unLink, updateData, isFormValid} from "../components/fieldGen";
import MultiSelect from "../components/UI/MultiSelect";
import myaxios from "../components/Axios";
import Notif from "../components/Notif";
import {withRouter} from "react-router-dom";
import Loader from "../components/UI/Loader";

function convertItems(arr){
    let result = [];
    for (let i in arr){
        result.push({value:arr[i].id, label: arr[i].name })
    }
    return result
}

const MessengersSending = ({ isOpen, close, history }) => {
    const [state, setMyState] = useState({
        formControl: {
            name: {
                type: 'input',
                label: 'Название',
                value:'',
                inputType:'text',
                valid: true,
                validation: {required: true},
            },
            broadcast_type: {
                type: 'select',
                label: 'Тип рассылки',
                value:'segment',
                options:[
                    {value:"entity", label:"По разделам"},
                    {value:"segment", label:"По сегментам"},
                ],
                valid: true,
                validation: {required: true},
            },
            body: {
                type: 'textarea',
                label: 'Текст рассылки',
                value:'',
                inputType:'text',
                valid: true,
                rows: 6,
                validation: {required: true},
            },
        },
        arr:[],
        entity: [
            {value: 'lead', label: "Лиды"},
            {value: 'contact', label: "Контакты"},
            {value: 'account', label: "Контрагенты"},
        ],
        segment: [],
        valid:true,
        loading: false
    })
    const setState = (st, func) => {
        Object.keys(st).map((key) => { state[key] = st[key]});
        setMyState(prevState => { return {...prevState, ...state} });
        if(func) func();
    };

    const getAllSegments = () => {
        myaxios.get(`/ajax/settings/segment?per-page=50`).then(res => setState({ segment: convertItems(res.data.items) }))
    }

    const getAllFlows = () => {
        myaxios.get(`/ajax/bot`)
    }

    useEffect(() => {
        getAllSegments()
    }, [])

    useEffect(() => {}, [state])

    const save = () => {
        if(state.arr.length < 1 || !state.valid) return;

        let obj = {
            name: state.formControl.name.value,
            broadcast_type: state.formControl.broadcast_type.value,
            body: state.formControl.body.value,
        }
        if(state.formControl.broadcast_type.value === "segment") obj.segments = state.arr
        else obj.entities = state.arr

        setState({ loading: true })
        myaxios.post(`/ajax/sms/broadcast-test`, obj).then(res => {
            if(res) if(res.status === 200 || res.status === 201) {
                Notif("Save", "В ближайшее время сообщение будет разослано")
                history.push(`/marketing/sms/${res.data}`);
                close();
            }
            setState({ loading: false })
        }).catch(() => setState({ loading: false }))
    }

    return state.loading ? <Loader contain /> : <Modal isOpen={isOpen} save={() => setState(isFormValid(state), save())} close={close} title="Рассылка в мессенджеры">
        <FieldGen
            arr={state.formControl}
            setField={(e, type) => {
                const formControl = setField(state.formControl, type, e);
                if(type === "template"){
                    let item = state.formControl.template.options.filter(item => item.id == e)[0]
                    formControl.body.value = item && item.body
                }
                setState({
                    formControl: formControl,
                    tch: false,
                    arr: (type === "broadcast_type") ? [] : state.arr
                })}
            }
            updateData={(id, arr, type) => {
                setState({ formControl: updateData(state.formControl,id, arr, type), tch: false })
            }}
            unLink={(type) => setState({ formControl: unLink(state.formControl, type), tch: false})}
        />
        <MultiSelect
            label="Сегменты"
            custom={state[state.formControl.broadcast_type.value]}
            valid={state.arr.length > 0}
            options={state.arr}
            //updateData={arr => setState({ segments: arr })}
            updateData={(arr) => setState({ arr: arr })}
        />
    </Modal>
}

export default withRouter(MessengersSending)