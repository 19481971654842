import {getLookUpTest} from "../Settings/LookUpTest";
import {CrudCreateFunction, CrudDeleteFunction, CrudGetSingleItemFunction, CrudUpdateFunction, checkUpdatedEntity} from "../CRUD";
import {
    createLeadService,
    deleteLeadService,
    getLeadPrepareService, getSingleLeadService,
    updateLeadService,
} from "../../../services/lead/leadService";
import {addressesTest} from "../other/addressTest";
import {Connections} from "../other/connections";
import {createTaskService, testTaskData, deleteTaskService} from "../../../services/task/taskService";
import {ProductTest} from "../other/productTest";
import {convertLead} from "./convertLead";
import {backupTest} from "../other/backupTest";
import {ListTest} from "../other/listTest";
import {entity} from "../../ListComponentEntity";

const LeadJson = {
    amount: "5000",
    company: "company",
    currency_id: "",
    email: "testemail@mail.test",
    first_name: "name",
    name: "test lead",
    owner_id: "",
    phone: "012345678",
    type_id: null
}

const LeadJsonAllFields = {
    add_way_id: 6,
    amount: "5000",
    company: "Контрагент",
    currency_id: 2,
    description: "<p>aaaaaaa</p>",
    editorTags: "",
    email: "asdaas@saddsa.asd",
    first_name: "Имя",
    last_name: "Фамилия",
    middle_name: "Отчество",
    name: "Лид №281",
    need: "1",
    owner_id: 29,
    phone: "01234567",
    photo: null,
    source_id: 4,
    status_id: 1,
    type_id: 1,
}

const LeadJsonAllFieldsConfig = {
    owner: { type: 'id', value: 'owner_id' },
    currency: { type: 'id', value: 'currency_id' },
    amount: { type: 'include', value: 'amount' },
    needObject: { type: 'id', value: 'need' },
    source: { type: 'id', value: 'source_id' },
    status: { type: 'id', value: 'status_id' },
    type: { type: 'id', value: 'type_id' }
}

const leadExpandView = `contacts,account,tasks,files,stages,owner,source,contactFields,additionalFields,currency,subscriber`

export const leadTest = async () => {
    // Создаем бекап
    const createBackupRes = await backupTest("create");
    if(!createBackupRes.status) {
        console.error("create backup have been failed")
        return false
    }

    // ----- Получаем все валюты
    const getCurrenciesRes = await getLookUpTest('currency');
    if(!getCurrenciesRes.status) return false;

    // ----- Получаем и проверяем предварительные данные в Prepare
    const getLeadPrepareRes = await CrudGetSingleItemFunction({
        service: getLeadPrepareService,
        serviceName: "getLeadPrepare"
    })
    if(!getLeadPrepareRes.data.nextId) {
        console.error("nextId is empty")
        return false
    }
    if(!getLeadPrepareRes.data.types[0]) {
        console.error("Lead prepare types empty")
        return false
    }
    if(!getLeadPrepareRes.data.needs[0]) {
        console.error("Lead prepare needs empty")
        return false
    }
    if(!getLeadPrepareRes.status) return false;

    // ----- создаем лид и проверяем начальные данные

    // Проверить или приходят статусы
    // Проверить или проставляется ответственный, статус, валюта, тип и предзаполненные поля
    LeadJson.type_id = getLeadPrepareRes.data.types[0].id;
    const createLeadRes = await CrudCreateFunction({
        service: createLeadService,
        serviceData: { data: LeadJson },
        serviceName: "createLead"
    })
    if(!createLeadRes.status) return false;
    if(
        //!createLeadRes.data.owner_id || FIX_ME
        !createLeadRes.data.amount.includes('5000') ||
        !createLeadRes.data.currency_id ||
        !createLeadRes.data.status_id ||
        createLeadRes.data.type_id + "" !== getLeadPrepareRes.data.types[0].id + "" ||
        createLeadRes.data.company !== "company" ||
        createLeadRes.data.email !== "testemail@mail.test" ||
        createLeadRes.data.first_name !== "name" ||
        createLeadRes.data.name !== "test lead" ||
        createLeadRes.data.phone !== "012345678"
    ) {
        console.error("Создались не все поля при создании лида")
        return false;
    }

    // Проставить и проверить все остальные заполненные поля
    const leadUpdateRes = await CrudUpdateFunction({
        service: updateLeadService,
        serviceData: {
            id: createLeadRes.data.id,
            data: LeadJsonAllFields
        },
        serviceName: "updateLead"
    })
    if(!leadUpdateRes.status) return false;

    // Получаем значение обновленного лида
    const getLeadUpdatedRes = await CrudGetSingleItemFunction({
        service: getSingleLeadService,
        serviceData: {
            id: createLeadRes.data.id,
            expand: leadExpandView
        },
        serviceName: "getLeadUpdatedRes"
    })

    // Проверяем значение обновленного лида
    if(!getLeadUpdatedRes.status) return false;
    if(!checkUpdatedEntity(getLeadUpdatedRes.data, LeadJsonAllFields, LeadJsonAllFieldsConfig)) return false;


    // Тестируем адреса
    const addressTestRes = await addressesTest({ entity: 'lead', entityId: createLeadRes.data.id })
    if(!addressTestRes) {
        console.error("addressTestRes have been failed")
        return false;
    }

    // тестируем связи
    const connectionsRes = await Connections({
        list: {expense: true},
        entityId: createLeadRes.data.id,
        entityName: 'lead'
    });
    if(!connectionsRes) {
        console.error('connections test have been failed');
        return false;
    }

    // создаем задачу
    const createLeadTaskRes = await CrudCreateFunction({
        service: createTaskService,
        serviceData: {
            data: {
                ...testTaskData,
                related_type: 23,
                related_id: createLeadRes.data.id
            }
        },
        serviceName: "createTaskRes"
    })
    if(!createLeadTaskRes.status) return false;

    // Проверяем задачи
    const getLeadUpdatedTasksRes = await CrudGetSingleItemFunction({
        service: getSingleLeadService,
        serviceData: {
            id: createLeadRes.data.id,
            expand: "tasks"
        },
        serviceName: "getLeadUpdatedTasksRes"
    })
    if(!getLeadUpdatedTasksRes.status && !getLeadUpdatedRes.data.tasks[0]) {
        console.error("Задача не была создана у лида")
        return false;
    }

    // Удаление задачи
    const deleteLeadTaskRes = await CrudDeleteFunction({
        service: deleteTaskService,
        serviceData: {
            id: createLeadTaskRes.data.id
        }, serviceName: "deleteLeadTaskRes",
        statusCode: 200
    })
    if(!deleteLeadTaskRes.status) return false;

    // Проверка создания задачи
    const getLeadDeleteTasksRes = await CrudGetSingleItemFunction({
        service: getSingleLeadService,
        serviceData: {
            id: createLeadRes.data.id,
            expand: "tasks"
        },
        serviceName: "getLeadUpdatedTasksRes"
    })
    if(!getLeadDeleteTasksRes.status && getLeadDeleteTasksRes.data.tasks.length !== 0) {
        console.error("Задача не была удалена у лида")
        return false;
    }

    // Тестирование продуктов
    const productTestRes = await ProductTest({ entity: 'lead', entityId: createLeadRes.data.id })
    if(!productTestRes) {
        console.error("Product test have been failed!")
        return false;
    }

    const convertLeadRes = await convertLead(createLeadRes.data.id);
    if(!convertLeadRes) {
        console.error("Ошибка конвертации лида")
        return false;
    }

    // Удаляем лид
    const deleteLeadRes = await CrudDeleteFunction({
        service: deleteLeadService,
        serviceData: { id: createLeadRes.data.id },
        serviceName: "deleteLeadRes",
    })
    if(!deleteLeadRes.status) return false;

    // Тестируем список
    const listTestRes = await ListTest({
        filterArr: entity.lead.fArr,
        entityName: 'lead'
    })
    if(!listTestRes) {
        console.error("Test list have been failed!");
        return false;
    }

    // Восстанавливаем бекап
    const restoreBackupRes = await backupTest("restore", createBackupRes.id);
    if(!restoreBackupRes) {
        console.error("restore backup have been failed");
        return false;
    }

    await console.log("Lead Test have been success");
    return true

}