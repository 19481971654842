import React, {Component} from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import Bgdrop from '../../Modal/Bgdrop';
import AddActions from './AddActions';
import AddMail from './AddMail';
import AddTelegram from './AddTelegram';
import BpTask from './actions/AddTask';
import ConditionBlock from './ConditionBlock';
import SendMail from './actions/SendMail';
import myaxios from '../Axios';
import Notif from '../Notif';
import EditTags from "./actions/EditTags";
import AddNotification from './actions/AddNotification';
import UpdateFields from './actions/UpdateFields';
import AddEntity from './actions/AddEntity';
import CreateRelation from './actions/createRelation';
import CreateRelationUser from './actions/createRelationUser';
import SendSms from "./actions/SendSms";
import {withRouter} from 'react-router-dom';
import {SocialMsg} from "./actions/SocialMsg";
import CallProcess from "./actions/CallProcess";
import GoogleAds from './actions/GoogleAds';
import FacebookAds from './actions/FacebookAds';
import fbIcon from '../../fonts/icons/facebook-messanger.svg'
import viberIcon from '../../fonts/icons/viber.svg'
import googleIcon from '../../fonts/icons/google.svg'
import HttpRequest from "./actions/HttpRequest";
import AddPaymentAction from "./actions/AddPayment";
import {CSSTransition} from "react-transition-group";

//https://www.npmjs.com/package/react-indiana-drag-scroll

function searchEntity(id){
    switch(id){
        case 1: return "Сделки";
        case 2: return "Контакты";
        case 3: return "Контрагенты";
        case 6: return "Проекты";
        case 9: return "Продукты";
        case 13: return "Счета";
        case 14: return "Заказы";
        case 23: return "Лиды";
        case 26: return "Документы";
        default: return "Неизвестно";
    }
}

function searchEntityId(id){
    switch(id){
        case 1: return "opportunity";
        case 2: return "contact";
        case 3: return "account";
        case 6: return "project";
        case 9: return "product";
        case 13: return "invoice";
        case 14: return "order";
        case 23: return "lead";
        case 26: return "document";
        default: return "opportunity";
    }
}

class BPview extends Component {
    state = {
        name:'MyProcess',
        entity: "contact",
        related_type:'',
        getJSON: '',
        tch: true,
        open:"",
        openType:"",
        bpType:'',
        parentIndex:null,
        childIndex:null,
        elArr:null,
        openArr:{parent:null,child:null,arr:{}},
        nodes: [
            {
                id:1,
                name: "Контакт",
                nodeType: "entity",
                actions:[]
            },
            {
                id:1,
                name: "Название условия",
                nodeType: "condition",
                condition:"",
                conditions:[],
                actions:[
                    {
                        id:2,
                        name: "Задача",
                        nodeType: "node",
                        type:"task",
                        metaData: {
                            name:'',
                            subject:'',
                            type_id:'',
                            //type_id:'',
                            //type:{id:'', arr:{}},
                        },
                        filter:[],
                    },
                ]
            },
        ]
    };
    getProcess = () => {
        myaxios.get(`/ajax/processbuilder/${this.props.match.params.id}`).then(res => {
            let nodes = res.data.nodes;
            nodes.unshift({
                id:1,
                name: searchEntity(res.data.related_type),
                nodeType: "entity",
                actions:[]
            })
            this.setState({ bpType: res.data.type, nodes: nodes, name: res.data.name, entity: searchEntity(res.data.related_type), related_type: res.data.related_type })
        })
    };
    putProcess = () => {
        const nodes = this.state.nodes;
        nodes.splice(0, 1);
        myaxios.put(`/ajax/processbuilder/${this.props.match.params.id}`, {
            name: this.state.name,
            nodes: nodes,
        }).then(res => {
            if(res.status === 200 || res.status === 201 || res.status === 204){
                Notif("save", "Процес успешно сохранен");
                let nodes = res.data.nodes;
                nodes.unshift({id:1, name: this.state.entity, nodeType: "entity", actions:[] })
                this.setState({ nodes: nodes })
            }
        })
    }
    componentDidMount(){
        this.getProcess();
    }
    close = () => {
        this.setState({open:"",openType:"",openArr:{parent:null,child:null,arr:{}}, elArr:null, parentIndex: null, childIndex:null });
    };
    updateDataCondition = arr => {
        const nodes = this.state.nodes;
        if(arr.child){
            nodes[arr.parent][arr.child] = arr.arr;
        } else {
            nodes[arr.parent] = arr.arr;
        }
        this.setState({ nodes })
    };
    createCondition = arr => {
        const nodes = this.state.nodes;
        nodes.push(arr.arr);
        this.setState({ nodes })
    };
    createNode = arr => {
        const nodes = this.state.nodes;
        nodes[this.state.parentIndex].actions.push(arr);
        this.setState({ nodes });
        this.close();
    };
    updateNode = arr => {
        const nodes = this.state.nodes;
        nodes[this.state.parentIndex].actions[this.state.childIndex] = arr;
        this.setState({ nodes });
        this.close();
    }
    deleteItem = (parent, child) => {
        if(window.confirm("Удалить элемент?")){
            const nodes = this.state.nodes;
            if(child !== null){
                nodes[parent].actions.splice(child, 1);
            } else {
                nodes.splice(parent, 1);
            }
            this.setState({ nodes });
        }
    };
    render(){
        return <div className="page">
            <div className="butt-group">
                <button onClick={() => this.props.history.push('/marketing/processbuilder/')} className="success fl-l btni ml10">Назад</button>
                <button className="success fl-r btni ml10" onClick={this.putProcess}>Сохранить</button>
            </div>
            <p>{this.state.getJSON}</p>
            <div style={{ marginTop: "10px" }} className="clear" />
            <div className="funnelRightBar miniBuilder" style={{ width: "100%", margin:"0"}}>
                <h2 style={{ borderBottom: "4px solid #858585" }}>{this.state.name}</h2>
                <ScrollContainer className="scroll-container" vertical={true} horizontal={true} ignoreElements=".minIcon, .okBtn">
                    <div className="fullContain">
                        <div className="">
                            {/*<h2>Старт процесса</h2>*/}
                            {
                                this.state.nodes.map((item, indexTop) =>
                                    <div className={`${item.nodeType}`} key={indexTop}>
                                        <div className={`fLiftIt wrapBpBlock ${item.nodeType}Block`} style={{ float: "left", cursor: "pointer" }}>
                                            {/*<div className="icon"><i className="flaticon-interface-5"/></div>*/}
                                            {/*<a>Форма #200142412</a>*/}
                                            <em style={{textDecoration:"underline"}} onClick={item.nodeType === "condition" ? () => this.setState({ open: "r-open", openType: "condition", openArr:{parent:indexTop,child:null,arr:item,type:"edit"} }) : null}>{item.name}</em>
                                            {
                                                (indexTop !== 0 && indexTop !== 1) ? <a onClick={() => {this.deleteItem(indexTop, null) } } className="bpmItemDelete" style={{zIndex:"1000"}}>
                                                    <div className="flaticon-delete-1" />
                                                </a> : null
                                            }
                                        </div>
                                        {
                                            item.actions.map((item, index) =>
                                                <div key={index} className={`fLiftIt toRightBp  ${item.nodeType}`}>
                                                    <ActionType
                                                        type={item.type}
                                                        metaData={item.metaData}
                                                        deleteItem={this.deleteItem}
                                                        name={item.name}
                                                        index={index}
                                                        indexTop={indexTop}
                                                        open={(type) => this.setState({ open: "r-open", openType:type, parentIndex:indexTop, childIndex:index,elArr: item })}
                                                    />
                                                </div>
                                            )
                                        }
                                        {item.nodeType !== "entity" ? <a onClick={() => this.setState({ open: "c-open", openType: "addActions", parentIndex: indexTop })} className="addActivityBp"><i data-tooltip="Добавить действие" className="flaticon-file-1" /></a> : null}
                                        <div className="clear" />
                                    </div>
                                )
                            }
                            <a onClick={() => this.setState({ open: "r-open", openType: "condition", openArr:{type:"create"} })} className="fullContainBtn">Добавить условие</a>
                        </div>


                    </div>
                </ScrollContainer>

            </div>
            <React.Fragment>
                <div className="bpmModal">
                    {/* Источник - центр */}
                    {this.state.open === "c-open" ? <div  className={`topModalBlock scrollbar ${this.state.open}`}>
                        {this.state.openType === "addActions" ? <AddActions bpType={this.state.bpType} openEntity={(open, openType) => this.setState({ open: open, openType:openType })} entityId={this.state.related_type} close={this.close} /> : null}
                    </div> : null}

                    {/* Источник - добавить Email */}
                    {this.state.open === "r-open" && this.state.openType === "addMail" ? <div className={`right-modal scrollbar ${this.state.open}`}><AddMail close={this.close} /></div> : null}

                    {/* Источник - добавить телеграм бота */}
                    {this.state.open === "r-open" && this.state.openType === "condition" ? <div className={`right-modal scrollbar ${this.state.open}`}><ConditionBlock entity={searchEntityId(this.state.related_type)} entityId={this.state.related_type} createCondition={this.createCondition} updateDataCondition={this.updateDataCondition} data={this.state.openArr} close={this.close} /></div> : null}

                    {/* БП - добавить телеграм бота */}
                    {this.state.open === "r-open" && this.state.openType === "task" ? <div className={`right-modal scrollbar ${this.state.open}`}><BpTask elArr={this.state.elArr} parentIndex={this.state.parentIndex} childIndex={this.state.childIndex} createNode={this.createNode} updateNode={this.updateNode} close={this.close} /></div> : null}
                    {this.state.open === "r-open" && this.state.openType === "sendSms" ? <div className={`right-modal scrollbar ${this.state.open}`}><SendSms entity={searchEntityId(this.state.related_type)} elArr={this.state.elArr} parentIndex={this.state.parentIndex} childIndex={this.state.childIndex} createNode={this.createNode} updateNode={this.updateNode} close={this.close} /></div> : null}
                    {this.state.open === "r-open" && this.state.openType === "mail" ? <div className={`right-modal scrollbar ${this.state.open}`}><SendMail elArr={this.state.elArr} parentIndex={this.state.parentIndex} childIndex={this.state.childIndex} createNode={this.createNode} updateNode={this.updateNode} close={this.close} /></div> : null}
                    {this.state.open === "r-open" && this.state.openType === "tags" ? <div className={`right-modal scrollbar ${this.state.open}`}><EditTags entity={searchEntityId(this.state.related_type)} entityId={this.state.related_type} elArr={this.state.elArr} parentIndex={this.state.parentIndex} childIndex={this.state.childIndex} createNode={this.createNode} updateNode={this.updateNode} close={this.close} /></div> : null}
                    {this.state.open === "r-open" && this.state.openType === "notification" ? <div className={`right-modal scrollbar ${this.state.open}`}><AddNotification entity={searchEntityId(this.state.related_type)} entityId={this.state.related_type} elArr={this.state.elArr} parentIndex={this.state.parentIndex} childIndex={this.state.childIndex} createNode={this.createNode} updateNode={this.updateNode} close={this.close} /></div> : null}
                    {this.state.open === "r-open" && this.state.openType === "fields" ? <div className={`right-modal scrollbar ${this.state.open}`}><UpdateFields entity={searchEntityId(this.state.related_type)} entityId={this.state.related_type} elArr={this.state.elArr} parentIndex={this.state.parentIndex} childIndex={this.state.childIndex} createNode={this.createNode} updateNode={this.updateNode} close={this.close} /></div> : null}
                    {this.state.open === "r-open" && this.state.openType === "createRecord" ? <div className={`right-modal scrollbar ${this.state.open}`}><AddEntity entity={searchEntityId(this.state.related_type)} entityId={this.state.related_type} elArr={this.state.elArr} parentIndex={this.state.parentIndex} childIndex={this.state.childIndex} createNode={this.createNode} updateNode={this.updateNode} close={this.close} /></div> : null}
                    {this.state.open === "r-open" && this.state.openType === "createRelation" ? <div className={`right-modal scrollbar ${this.state.open}`}><CreateRelation entity={searchEntityId(this.state.related_type)} entityId={this.state.related_type} elArr={this.state.elArr} parentIndex={this.state.parentIndex} childIndex={this.state.childIndex} createNode={this.createNode} updateNode={this.updateNode} close={this.close} /></div> : null}
                    {this.state.open === "r-open" && this.state.openType === "createRelationUser" ? <div className={`right-modal scrollbar ${this.state.open}`}><CreateRelationUser entity={searchEntityId(this.state.related_type)} entityId={this.state.related_type} elArr={this.state.elArr} parentIndex={this.state.parentIndex} childIndex={this.state.childIndex} createNode={this.createNode} updateNode={this.updateNode} close={this.close} /></div> : null}
                    {this.state.open === "r-open" && this.state.openType === "sendMessenger" ? <div className={`right-modal scrollbar ${this.state.open}`}><SocialMsg entity={searchEntityId(this.state.related_type)} entityId={this.state.related_type} elArr={this.state.elArr} parentIndex={this.state.parentIndex} childIndex={this.state.childIndex} createNode={this.createNode} updateNode={this.updateNode} close={this.close} /></div> : null}
                    {this.state.open === "r-open" && this.state.openType === "gl_audience_add" ? <div className={`right-modal scrollbar ${this.state.open}`}><GoogleAds entity={searchEntityId(this.state.related_type)} entityId={this.state.related_type} elArr={this.state.elArr} parentIndex={this.state.parentIndex} childIndex={this.state.childIndex} createNode={this.createNode} updateNode={this.updateNode} close={this.close} /></div> : null}
                    {this.state.open === "r-open" && this.state.openType === "fb_audience_add" ? <div className={`right-modal scrollbar ${this.state.open}`}><FacebookAds entity={searchEntityId(this.state.related_type)} entityId={this.state.related_type} elArr={this.state.elArr} parentIndex={this.state.parentIndex} childIndex={this.state.childIndex} createNode={this.createNode} updateNode={this.updateNode} close={this.close} /></div> : null}
                    {this.state.open === "r-open" && this.state.openType === "http_request" ? <div className={`right-modal scrollbar ${this.state.open}`}><HttpRequest entity={searchEntityId(this.state.related_type)} entityId={this.state.related_type} elArr={this.state.elArr} parentIndex={this.state.parentIndex} childIndex={this.state.childIndex} createNode={this.createNode} updateNode={this.updateNode} close={this.close} /></div> : null}
                    {this.state.open === "r-open" && this.state.openType === "process" ? <div className={`right-modal scrollbar ${this.state.open}`}><CallProcess {...this.props} entity={searchEntityId(this.state.related_type)} entityId={this.state.related_type} elArr={this.state.elArr} parentIndex={this.state.parentIndex} childIndex={this.state.childIndex} createNode={this.createNode} updateNode={this.updateNode} close={this.close} /></div> : null}
                    <CSSTransition in={this.state.open === "r-open" && this.state.openType === "createPayment"} timeout={200} classNames="slide" unmountOnExit>
                        <AddPaymentAction isOpen={this.state.open === "r-open" && this.state.openType === "createPayment"} {...this.props} entity={searchEntityId(this.state.related_type)} entityId={this.state.related_type} elArr={this.state.elArr} parentIndex={this.state.parentIndex} childIndex={this.state.childIndex} createNode={this.createNode} updateNode={this.updateNode} close={this.close} />
                    </CSSTransition>
                    {(this.state.open === "c-open" || this.state.open === "r-open") ? <Bgdrop close={this.close} /> : null}
                </div>
            </React.Fragment>
        </div>
    }
}

const ActionType = props => {
    let type = "";

    if(props.type === "createRelation"){
        if(props.metaData.data.recordType === 53 || props.metaData.data.recordType === 54 || props.metaData.data.recordType === 55 || props.metaData.data.recordType === 56){
            type = "createRelationUser"
        } else {
            type = props.type;
        }
    } else if(props.type === "sendMessenger"){
        type = props.metaData.type
    } else {
        type = props.type;
    }
    switch(type){
        case "task":
            return <div className="bpItemCl">
                <div className="icon"><i className="flaticon-notes"/></div>
                <a onClick={()=>props.open(props.type)}>Добавить задачу</a>
                <em onClick={() => props.open(props.type)}>{props.name}</em>
                <a onClick={() => props.deleteItem(props.indexTop, props.index)} className="bpmItemDelete" style={{zIndex:"1000"}}>
                    <div className="flaticon-delete-1" />
                </a>
            </div>;
        case "process":
            return <div className="bpItemCl">
                <div className="icon"><i className="flaticon-background"/></div>
                <a onClick={()=>props.open(props.type)}>Вложенный процесс</a>
                <em onClick={() => props.open(props.type)}>{props.name}</em>
                <a onClick={() => props.deleteItem(props.indexTop, props.index)} className="bpmItemDelete" style={{zIndex:"1000"}}>
                    <div className="flaticon-delete-1" />
                </a>
            </div>;
        case "mail":
            return <div className="bpItemCl">
                <div className="icon"><i className="flaticon-email"/></div>
                <a onClick={()=>props.open(props.type)}>Отправить Email</a>
                <em onClick={()=>props.open(props.type)}>{props.name}</em>
                <a onClick={() => props.deleteItem(props.indexTop, props.index)} className="bpmItemDelete" style={{zIndex:"1000"}}>
                    <div className="flaticon-delete-1" />
                </a>
            </div>;
        case "tags":
            return <div className="bpItemCl">
                <div className="icon"><i className="flaticon-interface-10"/></div>
                <a onClick={()=>props.open(props.type)}>Редактировать теги</a>
                <em onClick={()=>props.open(props.type)}>{props.name}</em>
                <a onClick={() => props.deleteItem(props.indexTop, props.index)} className="bpmItemDelete" style={{zIndex:"1000"}}>
                    <div className="flaticon-delete-1" />
                </a>
            </div>;
        case "fields":
            return <div className="bpItemCl">
                <div className="icon"><i className="flaticon-interface-9"/></div>
                <a onClick={()=>props.open(props.type)}>Изменить поля</a>
                <em onClick={()=>props.open(props.type)}>{props.name}</em>
                <a onClick={() => props.deleteItem(props.indexTop, props.index)} className="bpmItemDelete" style={{zIndex:"1000"}}>
                    <div className="flaticon-delete-1" />
                </a>
            </div>;
        case "notification":
            return <div className="bpItemCl">
                <div className="icon"><i className="flaticon-music-2"/></div>
                <a onClick={()=>props.open(props.type)}>Уведомление</a>
                <em onClick={()=>props.open(props.type)}>{props.name}</em>
                <a onClick={() => props.deleteItem(props.indexTop, props.index)} className="bpmItemDelete" style={{zIndex:"1000"}}>
                    <div className="flaticon-delete-1" />
                </a>
            </div>;
        case "createRecord":
            return <div className="bpItemCl">
                <div className="icon"><i className="flaticon-file-1"/></div>
                <a onClick={()=>props.open(props.type)}>Добавить сущность</a>
                <em onClick={()=>props.open(props.type)}>{props.name}</em>
                <a onClick={() => props.deleteItem(props.indexTop, props.index)} className="bpmItemDelete" style={{zIndex:"1000"}}>
                    <div className="flaticon-delete-1" />
                </a>
            </div>;
        case "createRelation":
            return <div className="bpItemCl">
                <div className="icon"><i className="flaticon-file-1"/></div>
                <a onClick={()=>props.open(props.type)}>Добавить продукт</a>
                <em onClick={()=>props.open(props.type)}>{props.name}</em>
                <a onClick={() => props.deleteItem(props.indexTop, props.index)} className="bpmItemDelete" style={{zIndex:"1000"}}>
                    <div className="flaticon-delete-1" />
                </a>
            </div>;
        case "createRelationUser":
            return <div className="bpItemCl">
                <div className="icon"><i className="flaticon-file-1"/></div>
                <a onClick={()=>props.open("createRelationUser")}>Добавить в команду</a>
                <em onClick={()=>props.open("createRelationUser")}>{props.name}</em>
                <a onClick={() => props.deleteItem(props.indexTop, props.index)} className="bpmItemDelete" style={{zIndex:"1000"}}>
                    <div className="flaticon-delete-1" />
                </a>
            </div>;
        case "sendSms":
            return <div className="bpItemCl">
                <div className="icon"><i className="flaticon-speech-bubble-1"/></div>
                <a onClick={()=>props.open("sendSms")}>Отправить SMS</a>
                <em onClick={()=>props.open("sendSms")}>{props.name}</em>
                <a onClick={() => props.deleteItem(props.indexTop, props.index)} className="bpmItemDelete" style={{zIndex:"1000"}}>
                    <div className="flaticon-delete-1" />
                </a>
            </div>;
        case "facebook":
            return <div className="bpItemCl" style={{ backgroundColor:"#3578e5" }}>
                <div className="icon"><img style={{ width: "30px", marginTop: "2px" }} src={fbIcon} /></div>
                <a style={{ color: "#fff" }} onClick={()=>props.open("sendMessenger")}>Facebook</a>
                <em style={{ color: "#fff" }} onClick={()=>props.open("sendMessenger")}>{props.name}</em>
                <a onClick={() => props.deleteItem(props.indexTop, props.index)} className="bpmItemDelete" style={{zIndex:"1000"}}>
                    <div className="flaticon-delete-1" />
                </a>
            </div>;
        case "viber":
            return <div className="bpItemCl" style={{ backgroundColor:"#574e92" }}>
                <div className="icon"><img style={{ width: "30px", marginTop: "2px" }} src={viberIcon} /></div>
                <a style={{ color: "#fff" }} onClick={()=>props.open("sendMessenger")}>Viber message</a>
                <em style={{ color: "#fff" }} onClick={()=>props.open("sendMessenger")}>{props.name}</em>
                <a onClick={() => props.deleteItem(props.indexTop, props.index)} className="bpmItemDelete" style={{zIndex:"1000"}}>
                    <div className="flaticon-delete-1" />
                </a>
            </div>;
        case "telegram":
            return <div className="bpItemCl" style={{ backgroundColor:"#499dd9" }}>
                <div className="icon"><i style={{ color: "#fff" }} className="flaticon-paper-plane"/></div>
                <a style={{ color: "#fff" }} onClick={()=>props.open("sendMessenger")}>Telegram message</a>
                <em style={{ color: "#fff" }} onClick={()=>props.open("sendMessenger")}>{props.name}</em>
                <a onClick={() => props.deleteItem(props.indexTop, props.index)} className="bpmItemDelete" style={{zIndex:"1000"}}>
                    <div className="flaticon-delete-1" />
                </a>
            </div>;
        case "fb_audience_add":
            return <div className="bpItemCl" style={{ backgroundColor:"#3578e5" }}>
                <div className="icon"><i style={{ color: "#fff" }} className="flaticon-facebook-letter-logo"/></div>
                <a style={{ color: "#fff" }} onClick={()=>props.open("fb_audience_add")}>Facebook Ads</a>
                <em style={{ color: "#fff" }} onClick={()=>props.open("fb_audience_add")}>{props.name}</em>
                <a onClick={() => props.deleteItem(props.indexTop, props.index)} className="bpmItemDelete" style={{zIndex:"1000"}}>
                    <div className="flaticon-delete-1" />
                </a>
            </div>;
        case "gl_audience_add":
            return <div className="bpItemCl" style={{ backgroundColor:"rgba(134, 191, 242, 0.2)" }}>
                <div className="icon"><img style={{ width: "30px", marginTop: "2px" }} src={googleIcon} /></div>
                <a style={{ color: "#2b2b2b" }} onClick={()=>props.open("gl_audience_add")}>Google Ads</a>
                <em style={{ color: "#2b2b2b" }} onClick={()=>props.open("gl_audience_add")}>{props.name}</em>
                <a onClick={() => props.deleteItem(props.indexTop, props.index)} className="bpmItemDelete" style={{zIndex:"1000"}}>
                    <div className="flaticon-delete-1" />
                </a>
            </div>;
        case "http_request":
            return <div className="bpItemCl">
                <div className="icon"><i className="flaticon-internet"/></div>
                <a onClick={()=>props.open("http_request")}>HTTP Request</a>
                <em onClick={()=>props.open("http_request")}>{props.name}</em>
                <a onClick={() => props.deleteItem(props.indexTop, props.index)} className="bpmItemDelete" style={{zIndex:"1000"}}>
                    <div className="flaticon-delete-1" />
                </a>
            </div>;
        case "createPayment":
            return <div className="bpItemCl">
                <div className="icon"><i className="flaticon-coins"/></div>
                <a onClick={()=>props.open("createPayment")}>Добавить платеж</a>
                <em onClick={()=>props.open("createPayment")}>{props.name}</em>
                <a onClick={() => props.deleteItem(props.indexTop, props.index)} className="bpmItemDelete" style={{zIndex:"1000"}}>
                    <div className="flaticon-delete-1" />
                </a>
            </div>;
        default: return <div className="bpItemCl" />
    }
};

export default withRouter(BPview);
