import React, {useState} from "react";
import Modal from "../components/Modal";
import Loader from "../components/UI/Loader";
import {FieldGen, FieldGenZone, isFormValid, postDt, setField, unLink, updateData} from "../components/fieldGen";
import myaxios from "../components/Axios";
import Notif from "../components/Notif";
import {withRouter} from "react-router-dom";
import providerReturnConfig from "../containers/stock/providerReturn/providerReturnConfig";

const AddProviderReturn = ({ isOpen, close, history }) => {
    const [state, setMyState] = useState({
        formControl: providerReturnConfig,
        loading:false,
        valid:false
    })

    const setState = (st, func) => {
        Object.keys(st).map((key) => { state[key] = st[key]});
        setMyState(prevState => { return {...prevState, ...state} });
        if(func) func();
    };

    const postData = () => {
        if(!state.valid) return

        setState({ tch: true, loading: true })
        myaxios.post(`/ajax/stock/provider-return`, postDt(state.formControl)).then(res => {
            if(!res) return Notif("Error");
            if(res.status === 200 || res.status === 201){
                Notif("save")
                close && close()
                history.push(`/stock/return/${res.data.id}`)
            }
            setState({ loading: false })
        }).catch(error => setState({ loading: false }))
    }


    return state.loading ? <Loader contain /> : <Modal save={() => setState(isFormValid(state), postData())} isOpen={isOpen} close={close} title="Добавить возврат">
        <FieldGen
            arr={state.formControl}
            setField={(e, type) => setState({formControl: setField(state.formControl, type, e)})}
            updateData={(id, arr, type) => setState({ formControl: updateData(state.formControl,id, arr, type), tch: false })}
            unLink={(type) => setState({ formControl: unLink(state.formControl, type), tch: false})}
        />
    </Modal>
}

export default withRouter(AddProviderReturn)