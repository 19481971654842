import React, {Component} from 'react';
import Input from '../../components/UI/Input';

//

export default class AddMail extends Component {
    state = {
        value:'',
        valid: true
    };
    sendData = () => {
        if(this.state.arr){

        } else {
            this.setState({ valid: false })
        }
    }
    render(){
        return <div>
            <div className="rmod-header">
                <b>Telegram бот</b>
                <a onClick={this.props.close} style={{ marginRight: "60px" }} className="mclose"><i className="flaticon-close" /></a>
                <div className="togglelist"><a className="rmod-ok"><i className="flaticon-interface-1" /></a>
                    <ul>
                        <li><button onClick={this.saveForm} disabled={this.state.tch}><i className="flaticon-folder-2" /> Сохранить</button></li>
                        <li><button onClick={this.deleteTask}><i className="flaticon-delete-1" /> Отключить</button></li>
                    </ul>
                </div>
            </div>
            <div className="mod-cont sbform">
                <Input
                    label="Токен Telegram бота"
                    value={this.state.value}
                    onChange={e => this.setState({ value: e.target.value })}
                />
            </div>
        </div>
    }
}