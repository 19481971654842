import React, {useContext, useEffect, useRef, useState} from "react";
import Input from "../../../../components/UI/Input";
import {ContactCard} from "../../../../components/cardViewComponents/ContactCard";
import Products from "../../../../components/products";
import Myselect from "../../../../components/UI/Select";
import CustomCheckbox from "../../../../components/UI/CustomCheckbox";
import {NewAutoSelect} from "../../../../components/NewUi/NewAutoSelect";
import {
    createOrderService,
    deleteOrderService,
    getPrepareOrderService,
    updateOrderService
} from "../../../../services/order/orderService";
import {tabsConfig} from "../../../invoice/InvoiceViewNew";
import {CSSTransition} from "react-transition-group";
import Notif from "../../../../components/Notif";
import {getBalanceService} from "../../../../services/other/balance";
import {ModalLoader} from "../../../../components/UI/Loader";
import PrintPlates from "../../../../Modal/PrintPlates";
import {MemoButton} from "../../../../components/cardViewComponents/MemoButton";
import myaxios from "../../../../components/Axios";
import {GlobalState} from "../../../../Layout/Layout";
import {NavLink} from "react-router-dom";
import {validationData} from "../../../../components/UI/formValid";

export const CashWindow = () => {
    const [cashTitle, setCashTitle] = useState({
        1: ' Нет заказа',
        2: ' Нет заказа',
        3: ' Нет заказа',
    })
    const [cash, setCash] = useState(1)
    const [loading, setLoading] = useState(true)
    const [values, setValues] = useState({
        statuses: [],
        stocks: [],
        balances: []
    })

    const getPrepareData = async () => {
        try {
            const { status, data } = await getPrepareOrderService()
            if(status !== 200) {
                Notif('Error')
                setLoading(false)
                return;
            }
            const { status: balanceStatus, data: balanceData } = await getBalanceService()
            if(balanceStatus !== 200) {
                Notif('Error')
                setLoading(false)
                return;
            }
            setValues({
                statuses: data.statuses,
                stocks: data.stocks,
                balances: balanceData.items
            })
            setLoading(false)
        } catch (e) {
            Notif('Error')
            setLoading(false)
        }
    }

    useEffect(() => {
        getPrepareData().then()
    },[])

    if(loading) {
        return <ModalLoader />
    }

    return (
        <div className="page sbform">
            <div className="stocktop">
                <div className="stocktop__scroll">
                    <a onClick={() => setCash(1)} className="stockItem">
                        <i className={cash === 1 ? 'flaticon-pin' : 'flaticon-tool'} />
                        <span style={{ color: cash === 1 && '#669295' }}>{cashTitle[1]}</span>
                    </a>
                    <a onClick={() => setCash(2)} className="stockItem">
                        <i className={cash === 2 ? 'flaticon-pin' : 'flaticon-tool'} />
                        <span style={{ color: cash === 2 && '#669295' }}>{cashTitle[2]}</span>
                    </a>
                    <a onClick={() => setCash(3)} className="stockItem">
                        <i className={cash === 3 ? 'flaticon-pin' : 'flaticon-tool'} />
                        <span style={{ color: cash === 3 && '#669295' }}>{cashTitle[3]}</span>
                    </a>
                </div>
            </div>
            <CashWindowItem
                statuses={values.statuses}
                stocks={values.stocks}
                balances={values.balances}
                isOpen={cash === 1}
                setCashTitle={title => setCashTitle({ ...cashTitle, 1: title })}
            />
            <CashWindowItem
                statuses={values.statuses}
                stocks={values.stocks}
                balances={values.balances}
                isOpen={cash === 2}
                setCashTitle={title => setCashTitle({ ...cashTitle, 2: title })}
            />
            <CashWindowItem
                statuses={values.statuses}
                stocks={values.stocks}
                balances={values.balances}
                isOpen={cash === 3}
                setCashTitle={title => setCashTitle({ ...cashTitle, 3: title })}
            />
        </div>
    )
}

const initialState = {
    name: '',
    status_id: null,
    stock_id: null,
    owner_id: null,
    owner: null,
    contact_id: null,
    contact: null,
    is_reserved: 0,
    balance_id: null,
    cost: 0,
    payment: 0,
    shipment: 2
}

const cashOrderWindowValidConfig = {
    name: { required: true },
    status_id: { required: true },
    stock_id: { required: true },
    // owner_id: { required: true },
    balance_id: { required: true },
    // payment: { required: true },
}

const CashWindowItem = ({ statuses = [], stocks = [], balances = [], isOpen, setCashTitle }) => {
    const { state } = useContext(GlobalState)
    const cashWindowMainRef = useRef(null)
    const [orderId, setOrderId] = useState(null)
    const [values, setValues] = useState(initialState)
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [successData, setSuccessData] = useState(null)
    const [isStatusTouched, setIsStatusTouched] = useState(false)

    const createOrder = async () => {
        setLoading(true)

        try {
            const copyInitialState = JSON.parse(JSON.stringify(initialState))
            const { data } = await getPrepareOrderService()

            const { status, data: orderData } = await createOrderService({
                data: {
                    name: `Заказ №${data.nextId}`,
                    status_id: statuses[0].id,
                    stock_id: stocks[0].id
                }
            })

            if(status === 201) {
                copyInitialState.name = orderData.name;
                copyInitialState.balance_id = balances[0].id;
                copyInitialState.status_id = orderData.status_id;
                copyInitialState.stock_id = orderData.stock_id;
                setValues(copyInitialState)

                setOrderId(orderData.id)
                setCashTitle(`${orderData.name} (в процессе)`)
            }

        } catch (e) {}
        setLoading(false)
    }

    const saveOrder = async () => {
        const errorsData = validationData(values, cashOrderWindowValidConfig);

        setErrors(errorsData)
        if(Object.keys(errorsData).length > 0) {
            Notif('required-fields')
            return
        }

        setLoading(true)
        const { name, status_id, stock_id, contact_id, is_reserved, owner_id, balance_id, payment, shipment } = values;

        const { status, data } = await updateOrderService({ id: orderId, data: {
                name, owner_id, stock_id , contact_id, is_reserved, status_id: isStatusTouched ? status_id : 8
            }
        })

        setSuccessData(prevState => ({
            ...prevState,
            order: data
        }))

        setCashTitle('Нет заказа')

        if(values.payment > 0) {
            const { data: paymentData } = await myaxios.post(`/ajax/order/${orderId}/payments`, { balance_id, amount: payment, currency_id: state.baseCurrency.id, date: "2022-07-14 00:04", exchange_rate: 1 })
            setSuccessData(prevState => ({
                ...prevState,
                payment: paymentData
            }))
        }

        if(shipment >= 1) {
            const { data: shipmentData, status: shipmentStatus } = await myaxios.post(`/ajax/order/${orderId}/create-shipment`)
            if (shipmentStatus === 200 || shipmentStatus === 201 || shipmentStatus === 204) {
                setSuccessData(prevState => ({
                    ...prevState,
                    shipment: shipmentData
                }))
                if(shipment === 2) {
                    const {} = await myaxios.put(`/ajax/stock/shipment/${shipmentData}`, {
                        name: shipmentData.name, is_completed: 1
                    })
                }
            }
        }

        setOrderId('')
        setLoading(false)
        if(cashWindowMainRef) cashWindowMainRef.current.focus()
    }

    useEffect(() => {
        if(cashWindowMainRef && isOpen) cashWindowMainRef.current.focus()
    }, [isOpen])

    const deleteItem = async () => {
        if(window.confirm('Удалить заказ?')) {
            setLoading(true)
            try {
                const { status } = await deleteOrderService({ id: orderId })
                if(status === 200 || status === 204) {
                    Notif('delete', 'Заказ успешно удален')
                    setOrderId(null)
                    setSuccessData(null)
                    setCashTitle('Нет заказа')
                    if(cashWindowMainRef) cashWindowMainRef.current.focus()
                }
            } catch (e) { console.error(e) }
            setLoading(false)
        }
    }

    if(!isOpen) {
        return <></>
    }

    return (
        <div style={{ position: 'relative' }}>
            {loading && <ModalLoader />}
            <div className="newList-btn-group" style={{ marginBottom: '10px' }}>
                <div className="newList-btn-group-left">
                    <PrintPlates entityId={14} entity={`order`} id={orderId} />
                </div>
                <div className="newList-btn-group-right">
                    <a onClick={deleteItem} className="butt-group-card-wrap btni ml10">
                        <span>Удалить</span>
                        <i className="flaticon-delete-2 mdb" />
                    </a>
                    <MemoButton onClick={saveOrder} disabled={values.tch} mobileIcon={"flaticon-interface-1 mdb"} title={'Завершить'} className={'ml5'} />
                </div>
            </div>
            <div className={'cash-window-flex'}>
                <div className="cash-window-block">
                    <h3>Данные заказа</h3>
                    <Input
                        label={'Название'} required
                        value={values.name}
                        onChange={e => setValues({ ...values, name: e.target.value })}
                        valid={!errors.name} errorMessage={errors.name} shouldValidate={true}
                    />
                    <Myselect
                        label={'Статус заказа'}
                        value={values.status_id}
                        options={statuses} val={'id'} lab={'value'}
                        onChange={e => {
                            setValues({ ...values, status_id: e.target.value })
                            if(+e.target.value !== values.status_id) setIsStatusTouched(true)
                        }}
                        valid={!errors.status_id} errorMessage={errors.status_id} shouldValidate={true}
                    />
                    <Myselect
                        label={'Склад'}
                        value={values.stock_id}
                        options={stocks} val={'id'} lab={'name'}
                        onChange={e => setValues({ ...values, stock_id: e.target.value })}
                        valid={!errors.stock_id} errorMessage={errors.stock_id} shouldValidate={true}
                    />
                    <NewAutoSelect
                        label={'Ответственный'}
                        searchBy={'name'}
                        link={`/ajax/contact?fields=id,name,first_name,email,mobile_phone,work_phone&filter[name][like]=`}
                        updateData={(item) => setValues({ ...values, owner: item, owner_id: item.id })}
                        entity={values.owner} minLength={1}
                        valid={!errors.owner_id} errorMessage={errors.owner_id} required
                    />
                    <CustomCheckbox
                        checked={values.is_reserved === 1} label={'Резерв продуктов'} style={{ margin: '10px 0' }}
                        onChange={() => setValues(prevState => ({ ...prevState, is_reserved: values.is_reserved === 1 ? 0 : 1, tch: false }))}
                    />
                </div>
                <div className="cash-window-block">
                    <ContactCard
                        contact={values.contact}
                        setDataValues={data => {
                            setValues(({...values, ...data, tch: false}))
                        }}
                    />
                </div>
                <div className="cash-window-block">
                    <h3>Данные оплаты/склад</h3>
                    <Myselect
                        label={'Кошелек'}
                        value={values.balance_id}
                        options={balances} val={'id'} lab={'name'}
                        onChange={e => setValues({ ...values, balance_id: e.target.value })}
                        valid={!errors.balance_id} errorMessage={errors.balance_id} shouldValidate={true}
                    />
                    <Input
                        label={'Сумма заказа'}
                        value={values.cost}
                        valid={!errors.cost} errorMessage={errors.cost} shouldValidate={true}
                        disabled
                    />
                    <div style={{ display: 'flex' }}>
                        <div style={{ width: '70%' }}>
                            <Input
                                label={'Сумма оплаты'}
                                value={values.payment}
                                onChange={e => setValues({ ...values, payment: e.target.value })}
                                valid={!errors.payment} errorMessage={errors.payment} required shouldValidate={true}
                            />
                        </div>
                        <Input
                            label={'Сдача'}
                            value={values.payment - values.cost}
                            disabled
                        />
                    </div>
                    <Myselect
                        label={'Отгрузка'}
                        value={values.shipment}
                        options={[
                            {value:0, label:'Не создавать'},
                            {value:1, label:'Создать'},
                            {value:2, label:'Создать и провести'},
                        ]}
                        onChange={e => setValues({ ...values, shipment: e.target.value })}
                    />
                </div>
            </div>

            {orderId && <div className="cash-window-block" style={{ width: '100%', margin: '0px 0 0 0' }}>
                <Products
                    type="order" id={orderId} related="14" isTotal isPrice isDiscount isValues
                    updatePrice={sum => setValues({ ...values, cost: sum, payment: sum })}
                />
            </div>}

            {!orderId && <div style={{ position: 'absolute', background: 'rgba(255,255,255,0.6)', width: '100%', height: '100%', top: 0, left: 0, display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {
                        successData && (
                            <div className={'second-table'} style={{ background: '#FFF', boxShadow: '1px 0px 20px rgb(0 0 0 / 20%)', width: '900px', marginBottom: '5px' }}>
                                <table style={{ width: '100%' }}>
                                    <tr>
                                        <th>Заказ</th>
                                        <th>Печать заказа</th>
                                        {+values.shipment > 0 && successData.shipment && <th>Отгрузка</th>}
                                        {+values.shipment > 0 && successData.shipment && <th>Печать отгрузки</th>}
                                        {+values.payment > 0 && successData.payment && <th>Платеж</th>}
                                    </tr>
                                    <tr>
                                        <td>
                                            <a target={'_blank'} href={`/order/${successData && successData.order.id}`}>
                                                Заказ: {successData && successData.order.name}
                                            </a>
                                        </td>
                                        <td>
                                            {successData && successData.order && <PrintPlates entityId={14} entity={`order`} id={successData.order.id} />}
                                        </td>
                                        {+values.shipment > 0 && successData.shipment && <td>
                                            <a target={'_blank'} href={`/stock/shipment/${successData && successData.shipment}`}>
                                                Отгрузка №{successData && successData.order.name}
                                            </a>
                                        </td>}
                                        {+values.shipment > 0 && successData.shipment && <td>
                                            {successData && successData.shipment && <PrintPlates entityId={19} entity={`stock/shipment`} id={successData.shipment.id} />}
                                        </td>}
                                        {+values.payment > 0 && successData.payment && <td>Платеж: {successData && successData.payment.name}</td>}
                                    </tr>
                                </table>
                            </div>
                        )
                    }
                    <button style={{ fontSize: '18px', padding: '13px 30px' }} onClick={createOrder} className={'btni'}>Создать заказ</button>
                </div>
            </div>}
            <input
                ref={cashWindowMainRef} style={{ width: 0, height: 0, border: 0 }}
                onKeyDown={e => e.keyCode === 13 && createOrder()}
            />
        </div>
    )
}