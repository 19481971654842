import React, {useEffect, useState} from "react";
import Input from "../../UI/Input";
import formValid from "../../UI/formValid";
import Notif from "../../Notif";

const CallProcess = (props) => {
    const [state, setMyState] = useState({
        formControl: {
            time_value:{value:0},
            name:{value:'',valid:true, validation:{required:true}},
            id:{value:'',valid:true, validation:{required:true}},
        },
        is_scheduled:false,
        conditions: [],
        id:null
    });

    const getParams = () => {
        if(props.elArr){
            let formControl = state.formControl;
            formControl.name.value = props.elArr.metaData ? props.elArr.name : '';
            formControl.id.value = props.elArr.metaData ? props.elArr.metaData.data.id : '';
            formControl.time_value.value = props.elArr.metaData ? props.elArr.time_value : '';
            setState({ formControl, id: props.elArr.id || null })
        }
    }

    const setState = (st, func) => {
        Object.keys(st).map((key) => { state[key] = st[key]});
        setMyState(prevState => { return {...prevState, ...state} });
        if(func) func();
    };

    useEffect(() => {
        getParams();
    },[]);

    const setField = (type, e) => {
        let formControl = state.formControl;
        formControl[type].value = e;
        setState({ formControl })
    };

    const isFormValid = () => {
        let formControl = state.formControl;
        let isFormValid = true;
        Object.keys(formControl).map((key, index) => {
            formControl[key].valid = formValid(formControl[key].value, formControl[key].validation);
            isFormValid = formControl[key].valid && isFormValid
        });
        if(formControl.id.value === props.match.params.id) {
            Notif("Error", "Процесс не может вызвать сам себя")
            formControl.id.valid = false
            isFormValid = false
        }
        setState({formControl});
        return isFormValid;
    };

    const sendForm = () => {
        if(isFormValid()){
            if(props.elArr){
                setTimeout(
                    props.updateNode({
                        id:state.id,
                        name: state.formControl.name.value,
                        time_value: state.formControl.time_value.value,
                        is_scheduled: (state.formControl.time_value.value > 1),
                        metaData: {
                            data: {id: state.formControl.id.value}
                        },
                        nodeType: "node",
                        type:"process",
                    })
                )
            } else {
                setTimeout(
                    props.createNode({
                        name: state.formControl.name.value,
                        time_value: state.formControl.time_value.value,
                        is_scheduled: (state.formControl.time_value.value > 1),
                        metaData: {
                            data: {id: state.formControl.id.value}
                        },
                        nodeType: "node",
                        type:"process",
                    })
                )
            }
        }
    };
    return <div>
        <div className="rmod-header">
            <b>Вызвать вложенный процесс</b>
            <a onClick={props.close} style={{ marginRight: "60px" }} className="mclose"><i className="flaticon-close" /></a>
            <div className="togglelist"><button onClick={sendForm} className="rmod-ok" disabled=""><i className="flaticon-interface-1" /></button></div>
        </div>
        <div className="mod-cont sbform">
            <Input
                label={"Название действия"}
                value={state.formControl.name.value}
                valid={state.formControl.name.valid}
                onChange={e => setField("name", e.target.value)}
                shouldValidate={true}
            />
            <Input
                value={state.formControl.id.value}
                valid={state.formControl.id.valid}
                onChange={e => setField("id", e.target.value)}
                shouldValidate={true}
                label="ID процессса, который необходимо вызвать"
            />
            <Input
                tooltip="Процесс запустится через N секунд, после того, как сработает триггер"
                type="number"
                value={state.formControl.time_value.value}
                onChange={e => setField("time_value", e.target.value)}
                shouldValidate={false}
                label="Отложенный запуск на N секунд"
            />
        </div>
    </div>
}

export default CallProcess;
