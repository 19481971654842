import {NewList} from "../../components/NewList/NewList";
import {entity} from "../../components/ListComponentEntity";
import {NavLink} from "react-router-dom";
import React from "react";
import localstorageSettings from "../../components/localstorageSettings";

const priority = {
    '1': { label: 'Низкий', color: 'rgb(177, 177, 177)' },
    '2': { label: 'Средний', color: 'rgb(230, 213, 59)' },
    '3': { label: 'Высокий', color: 'rgb(239, 154, 26)' },
    '4': { label: 'Критический', color: 'rgb(193, 106, 106)' },
}

export const ListTaskNew = (props) => {
    return (
        <NewList
            { ...props }
            entity={'task'}
            entity_type={5}
            requiredFields={'id,subject,status_id,priority,type,editorTags'}
            requiredExpand={'status'}
            deleteToShowColumn={'id,name,type,work_phone,mobile_phone,email'}
            additionalFieldsExists={false}
            isFilterExists={true}
            filterFields={entity.task.fArr}
            singleSearch={entity.task.fArr[0]}
            headingColumns={['Название', 'Приоритет', 'Статус', 'Тип']}
            RenderColumn={(item) => (
                <>
                    <td onClick={() => { item.setModalType('task'); item.setModalData({ id: item.id }) }}>
                        <a className={'nowrap-list-column'}>{item.subject}</a>
                        <div>{item.editorTags}</div>
                    </td>
                    <td onClick={() => { item.setModalType('task'); item.setModalData({ id: item.id }) }}>
                        {item.priority && <span className="label-st" style={{ backgroundColor: priority[item.priority].color }}>{priority[item.priority].label}</span>}
                    </td>
                    <td onClick={() => { item.setModalType('task'); item.setModalData({ id: item.id }) }}>
                        {item.status && <span className="label-st" style={{ backgroundColor: 'rgb(255, 193, 100)' }}>{item.status.value}</span>}
                    </td>
                    <td onClick={() => { item.setModalType('task'); item.setModalData({ id: item.id }) }}>
                        {item.type && <span className="label-st" style={{ backgroundColor: item.type.color ? item.type.color : 'rgb(255, 193, 100)' }}>{item.type.value}</span>}
                    </td>
                </>
            )}
            createButtonRender={(setModalType) => <>
                {/*<b onClick={() => {props.history.push(`/task/calendar`); localstorageSettings("post", "taskView", "calendar")} } className="btns"><i className="flaticon-calendar-3" /></b>*/}
                <a onClick={() => {props.history.push(`/task/kanban`); localstorageSettings("post", "taskView", "kanban")}} className="btns"><i className="flaticon-web" /></a>
                <button onClick={() => setModalType('task')} className="open-modal-button btni">Добавить задачу</button>
            </>}
            onCreatedResult={'update-list'}
            rightNavbarConfig={{
                // export: true,
                // additionalFields: '/fieldsettings/5',
                columns: true,
            }}
            massDoConfig={{ deleteAll: `/ajax/task/delete-all` }}
            contextNav={{ edit: true, task: false, print: false, delete: true }}
            sort={entity.task.fArr.filter(el => el.sort)}
        />
    )
}