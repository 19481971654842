import {entity} from "../../components/ListComponentEntity";
import {NavLink} from "react-router-dom";
import React from "react";
import {NewList} from "../../components/NewList/NewList";

export const InvoiceNewList = (props) => {
    return (
        <NewList
            { ...props }
            entity={'invoice'}
            entity_type={13}
            requiredFields={'id,name,status_id,cost,payment,contact_id,account_id'}
            requiredExpand={'contact,account,currency,status'}
            deleteToShowColumn={'id,name,status_id,cost,payment,contact_id,account_id'}
            additionalFieldsExists={true}
            isFilterExists={true}
            filterFields={entity.invoice.fArr}
            singleSearch={entity.invoice.fArr[0]}
            headingColumns={['Счет/клиент', 'Статус', 'Сумма']}
            analytics={'/invoice/report/'}
            RenderColumn={(item) => (
                <>
                    <td>
                        <NavLink to={`/invoice/${item.id}`}  className={'nowrap-list-column'}>{item.name}</NavLink>
                        {item.contact && <div className={'nowrap-list-column'}>Клиент: <NavLink to={`/contact/${item.contact.id}`}>{item.contact.name}</NavLink></div>}
                        {item.account && <div className={'nowrap-list-column'}>Клиент: <NavLink to={`/account/${item.account.id}`}>{item.account.name}</NavLink></div>}
                    </td>
                    <td>
                        {item.status && <span className="label-st" style={{ backgroundColor: 'rgb(255, 193, 100)' }}>{item.status.value}</span>}
                    </td>
                    <td>
                        <div>К оплате: {item.cost}{item.currency && item.currency.text_symbol}</div>
                        <div>Оплачено: {item.payment}{item.currency && item.currency.text_symbol}</div>
                    </td>
                </>
            )}
            createButtonRender={(setModalType) => <>
                <button onClick={() => setModalType('invoice')} className="open-modal-button btni">Новый счет</button>
                <button onClick={() => props.history.push(`/invoice-cash-window`)} className="open-modal-button btni ml5">Кассовое окно</button>
            </>}
            rightNavbarConfig={{
                export: true,
                additionalFields: '/fieldsettings/13',
                columns: true
            }}
            massDoConfig={{ deleteAll: `/ajax/invoice/delete-all` }}
            contextNav={{ edit: true, task: true, print: true, delete: true }}
            sort={entity.invoice.fArr.filter(el => el.sort)}
        />
    )
}