import React from "react";
import ChIcon from "../../../img/iconmonstr-check-mark-thin.svg";

export const CheckboxSubtask = ({ checked, deleteItem }) => {
    return (
        <div className={`subtask-checkbox ${checked && 'subtask-checkbox-active'}`}>
            {checked && <img src={ChIcon} alt={''} />}
        </div>
    )
}

export const SubTaskItem = ({ status, text, onClick, index, deleteItem }) => {
    return (
        <div
            data-tooltip={'CTRL + клик для удаления'}
            onContextMenu={(e) => {
                e.preventDefault()
            }}
            className="subtask-item" onClick={onClick}>
            <CheckboxSubtask checked={status} />
            <span>{text}</span>
        </div>
    )
}