import React, {useEffect, useState} from "react";
import Modal from "../../Modal/Modal";
import Input from "../../UI/Input";
import MainEditor from "../../UI/Editor";
import Myselect from "../../UI/Select";
import {SubTaskItem} from "../../../containers/tasks/TaskComponents/SubTaskItem";
import {getTaskPrepareService} from "../../../services/task/taskService";
import Notif from "../../Notif";
import {validationData} from "../../UI/formValid";
import {BpDateWithPeriod, parseDataForPeriod, saveTimePeriod} from "../BpHelpers";

const validationRules = {
    name: { required: true, maxLength: 255 },
    subject: { required: true, maxLength: 255 },
    description: { maxLength: 50000 },
    time_value: { number: true },
    remind: { number: true },
    startDate: { number: true },
    endDate: { number: true },
}

export const BpActionTask = ({ close, metaData, saveNode }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [subtaskValue, setSubtaskValue] = useState('')
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState({})
    const [values, setValues] = useState({
        name: '',
        time_value: 0,
        description: "",
        endDate: "",
        priority: "1",
        remind: "",
        startDate: "",
        subject: "",
        subtask_json: [],
        type_id: "",
        owner_type: "reference",
        owner_id: null,
        startDatePeriod: 'minutes',
        endDatePeriod: 'minutes',
        remindPeriod: 'minutes',
        time_valuePeriod: 'minutes',
        types: [],
        users: [],
        tch: true
    })

    const getPrepareData = async () => {
        try {
            const { status, data } = await getTaskPrepareService()
            if(status !== 200) {
                Notif('Error', 'Что-то пошло не так!')
                close()
            }

            if(metaData) {
                const {
                    name, time_value, metaData: {
                        type_id, description, endDate, priority, remind, startDate, subject, subtask_json, owner_id
                    }
                } = metaData
                setValues({
                    ...values, name, type_id, description, priority, subject, owner_id,
                    users: data.users,
                    types: data.types,
                    time_value: parseDataForPeriod(time_value).value,
                    time_valuePeriod: parseDataForPeriod(time_value).period,
                    endDate: parseDataForPeriod(endDate).value,
                    endDatePeriod: parseDataForPeriod(endDate).period,
                    startDate: parseDataForPeriod(startDate).value,
                    startDatePeriod: parseDataForPeriod(startDate).period,
                    remind: parseDataForPeriod(remind).value,
                    remindPeriod: parseDataForPeriod(remind).period,
                    subtask_json: subtask_json ? JSON.parse(subtask_json) : []
                })
            } else {
                setValues({
                    ...values,
                    owner_id: 'owner_id',
                    type_id: data.types[0].id,
                    users: data.users,
                    types: data.types,
                })
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        getPrepareData().then()
        setIsOpen(true)
    }, [])

    const saveData = () => {
        const validErrors = validationData(values, validationRules)
        if(Object.keys(validErrors).length > 0) {
            setErrors(validErrors)
            return false;
        }
        setErrors({})

        const { name, type_id, description, endDate, priority, remind, startDate, subject, subtask_json, time_value,
            owner_id, startDatePeriod, endDatePeriod, remindPeriod, time_valuePeriod } = values

        setIsOpen(false)
        setTimeout(() => saveNode({
            id: metaData && metaData.id,
            name,
            nodeType: 'node',
            time_value: saveTimePeriod(time_value, time_valuePeriod),
            is_scheduled: time_value > 0,
            type: "task",
            metaData: {
                description, owner_id, priority, subject, type_id,
                endDate: saveTimePeriod(endDate, endDatePeriod),
                owner_type: owner_id === 'owner_id' || owner_id === 'created_by' ? "reference" : "user",
                remind: saveTimePeriod(remind, remindPeriod),
                startDate: saveTimePeriod(startDate, startDatePeriod),
                subtask_json: JSON.stringify(subtask_json),
            }
        }), 200)
    }

    const closeModal = () => {
        setIsOpen(false); setTimeout(close, 200);
    }

    return (
        <Modal showLeaveModal tch={values.tch} isOpen={isOpen} title={'Задача'} loading={loading}
               save={saveData} parentClassName={'bpmModal'} close={closeModal}>
            <div className="sbform">
                <Input
                    label={'Название'}
                    value={values.name}
                    onChange={e => setValues({...values, name: e.target.value, tch: false })}
                    errorMessage={errors.name} valid={!errors.name} shouldValidate
                />
                <Input
                    label={'Тема задачи'}
                    value={values.subject}
                    onChange={e => setValues({...values, subject: e.target.value, tch: false })}
                    errorMessage={errors.subject} valid={!errors.subject} shouldValidate
                />
                <MainEditor
                    label={'Описание задачи'}
                    value={values.description}
                    onChange={e => setValues({...values, description: e, tch: false })}
                    errorMessage={errors.description} valid={!errors.description}
                />
                <BpDateWithPeriod
                    label={'Время начала задачи'}
                    name={'startDate'} periodName={'startDatePeriod'}
                    values={values} errors={errors}
                    setValues={(type, value) => setValues({...values, [type]: value, tch: false })}
                    tooltip={'К-во минут, после того, как сработает триггер'}
                />
                <BpDateWithPeriod
                    label={'Крайняя дата задачи'}
                    name={'endDate'} periodName={'endDatePeriod'}
                    values={values} errors={errors}
                    setValues={(type, value) => setValues({...values, [type]: value, tch: false })}
                    tooltip={'К-во минут, после того, как сработает триггер'}
                />
                <BpDateWithPeriod
                    label={'Напоминание о задаче'}
                    name={'remind'} periodName={'remindPeriod'}
                    values={values} errors={errors}
                    setValues={(type, value) => setValues({...values, [type]: value, tch: false })}
                    tooltip={'К-во минут, после того, как сработает триггер'}
                />

                <div className="new-task-subtask">
                    <h4>Список подзадач</h4>
                    {
                        values.subtask_json.map((item, index) => (
                            <SubTaskItem
                                index={index} {...item}
                                onClick={(e) => {
                                    if(e.ctrlKey) {
                                        setValues(prevState => ({ ...prevState, subtask_json: prevState.subtask_json.filter((el, i) => i !== index), tch: false }))
                                    } else {
                                        const items = values.subtask_json;
                                        items[index].status = !item.status;
                                        setValues({ ...values, subtask_json: items, tch: false })
                                    }

                                }}
                            />
                        ))
                    }
                    <Input
                        value={subtaskValue}
                        placeholder={'Введите текст подзадачи и нажмите на ENTER'}
                        onChange={(e) => setSubtaskValue(e.target.value)}
                        onKeyDown={e => {
                            if(e.keyCode === 13 && subtaskValue.trim().length > 0) {
                                const items = values.subtask_json;
                                items.push({ status: false, text: subtaskValue })
                                setValues(prevState => ({ ...prevState, subtask_json: items, tch: false }))
                                setSubtaskValue('')
                            }
                        }}
                    />
                </div>
                <Myselect
                    label={'Тип задачи'} val={'id'} lab={'value'}
                    options={values.types} value={values.type}
                    onChange={e => setValues({ ...values, type: e.target.value })}
                />
                <Myselect
                    label={'Приоритет'}
                    options={[
                        { value: '1', label:'Низкий' },
                        { value: '2', label:'Средний' },
                        { value: '3', label:'Высокий' },
                        { value: '4', label:'Критический' },
                    ]} value={values.priority}
                    onChange={e => setValues({ ...values, priority: e.target.value })}
                />
                <Myselect
                    label={'Ответственный'} val={'id'} lab={'name'}
                    options={[
                        { id: 'owner_id', name: 'Текущий ответственный' },
                        { id: 'created_by', name: 'Создавший запись' },
                        ...values.users
                    ]} value={values.owner_id}
                    onChange={e => setValues({ ...values, owner_id: e.target.value })}
                />
                <BpDateWithPeriod
                    label={'Отложенный запуск'}
                    name={'time_value'} periodName={'time_valuePeriod'}
                    values={values} errors={errors}
                    setValues={(type, value) => setValues({...values, [type]: value, tch: false })}
                    tooltip={'Время задержки выполнения процесса'}
                />
            </div>
        </Modal>
    )
}