import React, {Component} from 'react';

function sort(arr, type, contactInfo){
    let a = [];
    let inpArr = [];
    Object.keys(arr).map((key, index) => {
        inpArr.push(arr[key])
    });

    let newArr = inpArr.concat(contactInfo);
    for(let i=0; i<newArr.length; i++){
        if(newArr[i]){
            if(newArr[i].t){
                if(newArr[i].t == type && newArr[i].value.length > 3){
                    a.push(newArr[i].value)
                }
            }
        }
    }
    return a;
}

const SearchTag = props =>{
    return <div className="mdn fl-l">
        <div className="toogle-bottom">
            <button className="success fl-l mr5 btns btn-inside"><i className="flaticon-email" /></button>
            <ul className="toogle-list">
                <li style={{ padding: "5px 10px 2px 10px" }}><a style={{ fontSize: "12px", color: "#b2b2b2" }}>Отправить Email</a></li>
                {sort(props.formControl, "email", props.contactInfo).map((item, i) => <li key={i}><a onClick={() => props.updateData("SendMail", {from: item})}><b>{item}</b></a></li>)}
            </ul>
        </div>


        <div className="toogle-bottom">
        <button className="success fl-l mr5 btns btn-inside"><i className="flaticon-chat-1" /></button>
            <ul className="toogle-list">
                <li style={{ padding: "5px 10px 2px 10px" }}><a style={{ fontSize: "12px", color: "#b2b2b2" }}>Отправить SMS</a></li>
                {sort(props.formControl, "tel", props.contactInfo).map((item,i) => <li key={i}><a onClick={() => props.updateData("SendSms", {tel: item, entity: props.entity, id: props.id})}><b>{item}</b></a></li>)}
            </ul>
        </div>

        <div className="toogle-bottom">
            <button className="success fl-l mr5 btns btn-inside"><i className="flaticon-support" /></button>
            <ul className="toogle-list">
                <li style={{ padding: "5px 10px 2px 10px" }}><a style={{ fontSize: "12px", color: "#b2b2b2" }}>Позвонить</a></li>
                {sort(props.formControl, "tel", props.contactInfo).map((item,i) => <li key={i}><a onClick={() => props.updateData("Call", {tel: item, entity: props.entity, id: props.id})}><b>{item}</b></a></li>)}
            </ul>
        </div>

        {
            props.chats ? props.chats.map((item, i) =>
                <Icon key={i} item={item} updateData={props.updateData} />
            ) : null
        }
    </div>
}

export default SearchTag;

const Icon = props => {
    const item = props.item;
    switch (item.platform) {
        case "facebook": return <button onClick={() => props.updateData("MessagersList", {id: item.id, type: item.platform, name: item.username})} data-tooltip="Отправить сообщение в facebook messenger" className="success fl-l mr5 btns btn-inside"><i className="flaticon-facebook-letter-logo" /></button>;
        case "telegram": return <button onClick={() => props.updateData("MessagersList", {id: item.id, type: item.platform, name: item.username})} data-tooltip="Отправить сообщение в telegram messenger" className="success fl-l mr5 btns btn-inside"><i className="flaticon-paper-plane" /></button>;
        case "viber": return <button onClick={() => props.updateData("MessagersList", {id: item.id, type: item.platform, name: item.username})} data-tooltip="Отправить сообщение в Viber messenger" className="success fl-l mr5 btns btn-inside"><img width={20} src="https://space-level.com/sboxcn/viber_white_icon.png"/></button>;
        default: return "";
    }
}