import React, {useCallback, useEffect, useRef, useState} from "react";
import myaxios from "../../components/Axios";
import {deleteTaskService, updateTaskService} from "../../services/task/taskService";
import Notif from "../../components/Notif";
import {TaskModal} from "../tasks/TaskModal";
import {ListFilter} from "../../components/NewList/ListFilter";
import {entity} from "../../components/ListComponentEntity";
import {convertFilter} from "../../components/NewList/components/convertFilter";
import localstorageSettings from "../../components/localstorageSettings";
import {CustomKanban} from "../../components/CustomKanban/CustomKanban";
import {CSSTransition} from "react-transition-group";
import {useOnClickOutside} from "../../components/hooks/useOnClickOutside";
import LeadFunnelDropDown from "../../components/other/LeadFunnelDropDown";
import {ModalLoader} from "../../components/UI/Loader";
import {ModalListContainer} from "../../components/NewList/components/ModalListContainer";
import {deleteLeadService, updateLeadService} from "../../services/lead/leadService";
import {deleteOpportunityService, updateOpportunityService} from "../../services/opportunity/opportunityService";
import {buildExpandLink, ExtraColumnsRender} from "../../components/NewList/components/ExtraColumnsRender";

const formatDate = (dt) => {
    if(dt){
        let date = new Date(dt)
        let dd = date.getDate();
        if (dd < 10) dd = '0' + dd;
        let mm = date.getMonth() + 1;
        if (mm < 10) mm = '0' + mm;
        let yy = date.getFullYear() % 10000;
        if (yy < 10) yy = '0' + yy;
        return dd + '-' + mm;
    }
}

const KanbanItem = ({ content, onClick, allStatuses, columns = [], additionalFields = [] }) => {
    const {id, name, description, stage_id, owner, status, amount, currency = {}, type, tasks, first_name, last_name, company} = content

    return (
        <div className="kanbanItem" onClick={onClick}>
            <div className="kanbanItemStatuses">
                <span className="kanbanItemStatus" style={{
                    color: type && type.color,
                    background: 'white',
                    border: `1px solid ${type && type.color}` }}>
                    {type && type.value}
                </span>
                <span className="kanbanItemPriority" >
                    {amount} {currency && currency.text_symbol}
                </span>
            </div>
            <div className="kanbanItemDescription"
                // onClick={() => history.push(`/task/view/${id}`)}
            >
                <h3>{name}</h3>
                {(first_name || last_name) && <p style={{ display: 'block' }}>
                    {last_name} {first_name} {company && `- ${company}`}
                </p>}
                <div>
                    {/*<ExtraColumnsRender
                        item={content}
                        columns={columns}
                        filterFields={[...entity.opportunity.fArr, ...additionalFields]}
                    />*/}
                </div>
            </div>
            <div className="kanbanItemFooter">
                <div className="kanbanItemOwners">
                    {owner ? <div data-tooltip={owner.name} className="kanbanItemOwner">{owner && owner.name.substring(0, 2)}</div> :
                        <div className="kanbanItemOwner">&#9866;</div>}
                </div>
                <div className="kanbanItemFooterRight">
                    <div className="kanbanItemDate">
                        <i className="flaticon-list-2" />
                        {tasks && <span>{tasks.length > 0 ? `Задач: ${tasks.length}` : 'Нет задач'}</span>}
                    </div>
                </div>
            </div>
        </div>
    )
}

const KanbanHeader = ({ id, value, color, taskTypePeriod, allStatuses }) => {
    const status = allStatuses.find(el => String(el.id) === String(id))
    return (
        <div className="react-kanban-column-header">
            <span style={{ borderBottom: `3px solid ${taskTypePeriod ? color : status.color}`, display: "block", paddingBottom: "5px", fontSize: "14px" }}>{value}</span>
        </div>
    )
}

let page = 1
let metaData = 0
function OpportunityKanban ({ isFilterExists = true, entity_type = 1, userId = 1, requiredExpand, requiredFields, history  }) {
    //const [page, setPage] = useState(1)
    //const [metaData, setMetaData] = useState(0)
    const [systemic, setSystemic] = useState([])
    const [headerColumns, setHeaderColumns] = useState([])
    const [allStatuses, setAllStatuses] = useState([])
    const [items, setItems] = useState([])
    const [additionalFields, setAdditionalFields] = useState([])
    const [loading, setLoading] = useState(true)

    const [dragElement, setDragElement] = useState(null)
    const [activeCol, setActiveCol] = useState(null)

    const [filters, setFilters] = useState([])
    const [columns, setColumns] = useState([])
    const [sortActive, setSortActive] = useState('-created_at')
    const [filterId, setFilterId] = useState('')

    const filterLink = async () => {
        const filterData = await myaxios.get(`/ajax/filter?filter[entity_type]=${entity_type}&[user_id]=${userId}`)
        let filterLink = '';
        let resColumns = ''
        let resColumnsData = ''
        let cols = []

        if(isFilterExists && filterData.data.items && filterData.data.items[0]) {
            setFilters(filterData.data.items[0].filterAttributes || [])
            if(filterData.data.items[0]){
                cols = filterData.data.items[0].columns
                filterLink = (convertFilter(filterData.data.items[0].filterAttributes || []))
                setFilterId(filterData.data.items[0].id)
                setColumns(filterData.data.items[0].columns || [])
                setSortActive(filterData.data.items[0].order || '-created_at')
            }
        }

        const sortOrder = (filterData.data && filterData.data.items && filterData.data.items[0] && filterData.data.items[0].order) || '-created_at'
        resColumns = filterData.data && filterData.data.items && filterData.data.items[0] && filterData.data.items[0].columns
        resColumnsData = resColumns && resColumns.length > 0 ? `,${resColumns.join(',')}` : ''
        return `/ajax/opportunity/kanban?sort=${sortOrder}${filterLink}&per-page=15&expand=owner,currency,source,tasks${buildExpandLink(cols.join(','))}`
    }

    const getData = async (statuses) => {
        const link = await filterLink(statuses)
        const { status, data } = await myaxios.get(link + `&page=${page}`)
        if(status === 200 && data) {
            setItems(data.items)
            setAdditionalFields(data.attributes)
            //setMetaData(prevState => data._meta.pageCount)
            metaData = data._meta.pageCount
        }
        setLoading(false)
    }

    const fetchMoreData = async () => {
        console.log('page', page, metaData)

        if(page < metaData) {
            setLoading(true)
            const newPage = page + 1;
            //setPage(prevState => (newPage))
            page = newPage
            const link = await filterLink()
            const { data } = await myaxios.get(`${link}&page=${newPage}`)
            setItems(prevState => ([
                ...prevState,
                ...data.items
            ]))
            setLoading(false)
        }
    };

    const getStatuses = async () => {
        setLoading(true)
        const { status, data } = await myaxios.get(`/ajax/opportunity/prepare`)
        if(status === 200) {
            setSystemic(data.stages.filter(el => +el.is_won === 1 || +el.is_failed === 1))
            setAllStatuses(data.stages.filter(el => +el.is_won !== 1 && +el.is_failed !== 1))
            await getData(data.stages)
        }
        setLoading(false)
    }

    useEffect(() => {
        page = 1
        getStatuses().then()
    }, [])

    const deleteElement = async (elId) => {
        if(!elId) return;
        if(!window.confirm('Удалить лид?')) return;

        try {
            const { status, data } = await deleteOpportunityService({ id: elId })
            if(status === 200 || status === 204) {
                setItems(items.filter(el => el.id !== elId))
                Notif('delete', 'Запись успешно удалена')
            }
        } catch (e) { console.error(e) }
        setLoading(false)
    }

    const drugEnd = async (item) => {
        if(activeCol === 'delete') {
            await deleteElement(dragElement)
            setDragElement(null)
            setActiveCol(null)
            return;
        }

        if(activeCol === "edit") {
            setDragElement(null)
            setActiveCol(null)
            return;
        }

        setLoading(true)
        const { status, data } = await updateOpportunityService({ id: item.id, data: {
                title: item.title,
                stage_id: activeCol
            }})

        if(status === 200 && data.id) {
            const newItems = JSON.parse(JSON.stringify(items))
            const i = newItems.findIndex(el => el.id === item.id)
            newItems[i].stage_id = activeCol
            setItems(newItems)
            setActiveCol(null)
            setDragElement(null)

            Notif('save', 'Задача успешно обновлена')
        }
        setLoading(false)
    }

    const saveFilter = async (params = [], clearId, editedColumns, editedSort) => {
        setLoading(true)

        const postData = {
            name: 'task',
            entity_type: entity_type,
            user_id: userId || 1,
            filter_attributes: clearId ? filters.filter(el => el.id != clearId) : [...filters, ...params],
            columns: editedColumns || columns,
            order: editedSort || sortActive
        }

        const { status, data } = filterId ?
            await myaxios.put(`/ajax/filter/${filterId}`, postData) : await myaxios.post(`/ajax/filter`, postData)

        if(status === 200 || status === 201) {
            Notif('save', 'Фильтр применен')
            await getData()
        }

        setLoading(false)
    };

    const [modalType, setModalType] = useState(false)
    const [modalData, setModalData] = useState(false)

    return <div className={'page'} style={{ display: 'flex', flexDirection: 'column', height: `calc(100vh - 75px)`, position: 'relative', paddingBottom: 0 }}>
        {loading && <ModalLoader />}
        <ListFilter
            saveFilter={saveFilter}
            filters={filters}
            rightNavbarConfig={{
                export: true,
                additionalFields: '/fieldsettings/1',
            }}
            setIsColumnsOpen={() => {}}
            // singleSearch={entity.task.fArr[0]}
            updateData={() => {}}
            entity={'opportunity'}
            sort={entity.opportunity.fArr.filter(el => el.sort)}
            sortActive={sortActive}
            filterFields={entity.opportunity.fArr}
            LeftRender={() => (
                <>
                    <a onClick={() => {history.push('/opportunity/list'); localstorageSettings("post", `${'opportunity'}View`, "list")}} className="btns"><i className="flaticon-list-2" /></a>
                    <a onClick={() => setModalType('opportunity')} className="open-modal-button btni">Создать сделку</a>
                </>
            )}
            dropdownRender={() => (
                <>
                    <li><a onClick={() => history.push(`/settings/opportunity`)}><i className="flaticon-cogwheel-1" /> <b>Настроить статусы воронки</b></a></li>
                </>
            )}

        />

        <CustomKanban
            {...{ dragElement, setDragElement, activeCol, setActiveCol, columns: headerColumns, items, drugEnd, loading }}
            columns={allStatuses}
            header={item => <KanbanHeader {...item} allStatuses={allStatuses} />}
            kanbanItem={item => <KanbanItem
                content={item}
                onClick={() => history.push(`/opportunity/${item.id}`)}
                allStatuses={allStatuses} columns={columns} additionalFields={additionalFields}
            />}
            itemKey={'stage_id'}
            filters
            rightNavbarConfig
            singleSearch
            entity
            sort
            sortActive
            filterFields
            LeftRender
            actionButtons={[
                {color: 'linear-gradient(45deg, #d37873 0%,#c96e6e 47%,#c16a6a 100%)', value: 'delete', label: 'Удалить'},
                /*{color: 'linear-gradient(45deg, #929292 0%,#656565 47%,#948a8a 100%)', value: systemic.find(el => +el.is_failed === 1) && systemic.find(el => +el.is_failed === 1).id, label: 'Провален'},
                {color: 'linear-gradient(45deg,#80aeba 2%,#699499 46%,#669295)', value: systemic.find(el => +el.is_won === 1) && systemic.find(el => +el.is_won === 1).id, label: 'Успешен'},*/
            ]}
            loadMore={fetchMoreData}
            page={page} metaData={metaData}
        />

        <ModalListContainer
            entity_type={1}
            entity={'opportunity'}
            history={history}
            type={modalType}
            data={modalData}
            close={() => { setModalType(''); setModalData(null) }}
            onUpdateResult={() => {}}
        />
    </div>
}

export default OpportunityKanban