import React, {Component} from 'react';
import Input from '../components/UI/Input';
import Notif from '../components/Notif';
import formValid from '../components/UI/formValid';
import myaxios from '../components/Axios';
import Loader from "../components/UI/Loader";
import {createEvent} from "../services/other/events";

class Login extends Component {
    state = {
        formControl: {
            user: {value:'', valid:true, validation: {required: true, minlength: 4}},
            password: {value:'', valid:true, validation: {required: true, minlength: 6}}
        },
        check:true,
        verification: false,
        code: '',
        loading: false
    };
    setField = (event, type) =>{
        const formControl = this.state.formControl;
        formControl[type].value = event.target.value;
        this.setState({ formControl })
    };
    isFormValid = () => {
        let formControl = this.state.formControl;
        let isFormValid = true;
        Object.keys(formControl).map((key, index) => {
            formControl[key].valid = formValid(formControl[key].value, formControl[key].validation)
            isFormValid = formControl[key].valid && isFormValid
        });
        this.setState({
            formControl, isFormValid
        });
        return isFormValid;
    };
    sendForm = event => {
        this.setState({ loading: true })
        event.preventDefault();
        if(this.isFormValid()){
            myaxios.post("/ajax/site/login", {
                username: this.state.formControl.user.value,
                password: this.state.formControl.password.value,
                rememberMe: this.state.check ? 1 : 0
            }).then(res => {
                console.log("res.data login",res.data)
                if(res.status === 200) {
                    if(res.data.status === "verification") {
                        this.setState({ verification: true, loading: false  })
                        //setTimeout(this.props.showAuth, 100)
                    } else if(res.data.status === "logged"){
                        setTimeout(() => window.location.reload(), 100);
                        createEvent({ user_id: "---", user_name: "---", event_type: "login" }).then();
                    } else {
                        Notif("Error", res.data.errors.password[0])
                    }
                }
                this.setState({ loading: false  })
            }).catch(() => this.setState({ loading: false  }))
        } else Notif("Error")
        setTimeout(this.props.inAuth, 200);
    };

    confirm  = () => {
        myaxios.post(`/ajax/site/confirm-otc-login`, {code: this.state.code}).then(res => {
            if(res.status === 200) {
                if(res.data.status === "logged"){
                    setTimeout(this.props.showAuth, 100)
                } else {
                    Notif("Error", "Неправильный код")
                }
            }
        })
    }

    render(){
        return this.state.loading ? <Loader contain /> : <div className="login">
            <div className="sidebarLogin sbform">
                {this.state.verification ? <div>
                        <img src="https://sboxcrm.com/images/logocrm.png" alt=""/>
                        <Input
                            label="Код подтверждения"
                            value={this.state.code}
                            onChange={e => this.setState({ code: e.target.value })}
                        />
                        <button onClick={this.confirm}>Войти</button>
                    </div> :
                    <div>
                        <img src="https://sboxcrm.com/images/logocrm.png" alt=""/>
                        <Input
                            className="rc12"
                            type="text"
                            placeholder="Логин"
                            value={this.state.formControl.user.value}
                            valid={this.state.formControl.user.valid}
                            shouldValidate={true}
                            onChange={event => this.setField(event, "user")}
                        />
                        <Input
                            className="rc12"
                            type="password"
                            placeholder="Пароль"
                            value={this.state.formControl.password.value}
                            valid={this.state.formControl.password.valid}
                            shouldValidate={true}
                            onChange={event => this.setField(event, "password")}
                        />
                        <label style={{padding: "4px", margin: "7px 0px 3px 5px;"}}>
                            <input checked={this.state.check} onClick={() => this.setState({ check: (!this.state.check) })} className="checkbox checkboxlead" type="checkbox" name="checkbox-test" />
                            <span className="checkbox-custom" />
                            <em style={{ display: "inline-block", marginLeft: "6px", position: "relative", top: "2px"}}>Запомнить пароль</em></label>
                        <button onClick={this.sendForm}>Войти</button>
                        <a style={{ fontSize: "14px", textAlign:"center", display:"block", marginTop:"3px" }} onClick={() => this.props.reset()}>Сбросить пароль</a>
                    </div>}
            </div>
        </div>
    }
}

export default Login;
