const providerReturnConfig = {
    name: {
        type: 'input',
        label: 'Название',
        value:'',
        inputType:'text',
        valid: true,
        validation: {required: true},
        zone: 1
    },
    date: {
        type: 'date',
        label: 'Дата возврата',
        value:'',
        valid: true,
        zone: 1
    },
    stock_id: {
        type: 'modAutoSelect',
        cls:'c12',
        label: 'Склад',
        link: '/ajax/stock?filter[name][like]=',
        value:'',
        result:'name',
        valid: true,
        entity:null,
        from:'stock',
        entityName:"name",
        validation: {required: true},
        zone: 1
    },
    invoice_id: {
        type: 'modAutoSelect',
        label: 'Счет',
        cls:'c12',
        link: '/ajax/invoice?filter[name][like]=',
        value:'',
        result:'name',
        valid: true,
        entity:null,
        from:'invoice',
        entityName:"name",
        zone: 1
    },
    order_id: {
        type: 'modAutoSelect',
        label: 'Заказ',
        cls:'c12',
        link: '/ajax/order?filter[name][like]=',
        value:'',
        result:'name',
        valid: true,
        entity:null,
        from:'order',
        entityName:"name",
        zone: 1
    },
    owner_id: {
        type: 'modAutoSelect',
        cls:'c12',
        label: 'Ответственный',
        link: '/ajax/settings/user?filter[name][like]=',
        value:'',
        result:'name',
        valid: true,
        entity:null,
        from:'owner',
        entityName:"name",
        validation: {required: true},
        zone: 1
    },
    account_id: {
        type: 'modAutoSelect',
        cls:'c12 ',
        label: 'Контрагент',
        link: '/ajax/account?filter[name][like]=',
        value:'',
        result:'name',
        valid: true,
        entity:null,
        from:'account',
        entityName:"name",
        zone: 1
    },
    contact_id: {
        type: 'modAutoSelect',
        cls:'c12 ',
        label: 'Контакт',
        link: '/ajax/contact?filter[name][like]=',
        value:'',
        result:'name',
        valid: true,
        entity:null,
        from:'contact',
        entityName:"name",
        zone: 1
    },
    is_completed: {
        type: 'checkbox',
        label: 'Проведено',
        value:'',
        valid: true,
        zone: 1
    },
}

export default providerReturnConfig