export const TASK_PRIORITY = [
    {value:'1', label:'Низкий'},
    {value:'2', label:'Средний'},
    {value:'3', label:'Высокий'},
    {value:'4', label:'Критический'}
]

export const TASK_PRIORITY_OBJ = {
    '1':'Низкий',
    '2':'Средний',
    '3':'Высокий',
    '4':'Критический'
};

export const TASK_PRIORITY_OBJ_COLORS = {
    '1':'rgb(214, 214, 214)',
    '2':'#62a091',
    '3':'rgb(250, 141, 35)',
    '4':'#c2263f'
};

export const TASK_STATUSES = [

];

export const ATTACHED_NUMBER_TO_TEXT = {
    "1": "Сделка",
    "2": "Контакт",
    "3": "Контрагент",
    "4": "Коментарий",
    "5": "Задача",
    "6": "Проект",
    "7": "Этап проекта",
    "8": "Пользователь",
    "9": "Продукт",
    "10": "Email сообщение",
    "11": "Этап сделки",
    "12": "Валюта",
    "13": "Счет",
    "14": "Заказ",
    "15": "Склад",
    "16": "Закупка",
    "17": "Приход",
    "18": "Списание",
    "19": "Отгрузка",
    "20": "Перемещение",
    "21": "Инвентаризация",
    "22": "Возврат поставщику",
    "23": "Лид",
    "24": "binotel_call",
    "25": "turbo_sms",
    "26": "Документ",
    "32": "Возврат",
    "41": "Заказ на производство",
}

export const ATTACHED_NUMBER_TO_STRING = {
    "1": "opportunity",
    "2": "contact",
    "3": "account",
    "4": "comment",
    "5": "task",
    "6": "project",
    "7": "project/work",
    "8": "user",
    "9": "product",
    "10": "mail_message",
    "11": "opportunity_stage",
    "12": "currency",
    "13": "invoice",
    "14": "order",
    "15": "stock",
    "16": "stock/purchase",
    "17": "stock/replanish",
    "18": "stock/consumption",
    "19": "stock/shipment",
    "20": "stock/transfer",
    "21": "stock/stocktake",
    "22": "stock/purchase_return",
    "23": "lead",
    "24": "binotel_call",
    "25": "turbo_sms",
    "26": "document",
    "32": "stock/return",
    "41": "stock/workorder",
}