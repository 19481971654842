import Products from "../../products";
import Input from "../../UI/Input";
import Myselect from "../../UI/Select";
import React from "react";
import {Link} from "react-router-dom";
import CustomCheckbox from "../../UI/CustomCheckbox";

export const SecondStepView = ({
    contactFields,
    setContactFields,
    errors,
    entities,
    toNextStep,
    updateData,
    close,
    createdEntities,
    saveResult,
    isFinish
}) => {
    return (
        <div className="modal_second_step sbform emptyLabelPadding">
            <div style={{ paddingTop: '5px' }} className="connection">
                <span>Созданые данные</span>
            </div>
            {createdEntities.contact && <div>
                <Link onClick={close} className={'created-entity-link'} to={`/contact/${createdEntities.contact.id}`}>{createdEntities.contact.name}</Link>
            </div>}
            {createdEntities.invoice && <div>
                <Link onClick={close} className={'created-entity-link'} to={`/invoice/${createdEntities.invoice.id}`}>{createdEntities.invoice.name}</Link>
            </div>}
            {createdEntities.shipment && <div>
                <Link onClick={close} className={'created-entity-link'} to={`/shipment/${createdEntities.shipment}`}>{`Shipment #${createdEntities.shipment}`}</Link>
            </div>}

            <div style={{ paddingTop: '20px' }} />
            {!isFinish && <Products
                type="invoice"
                id={createdEntities.invoice && createdEntities.invoice.id}
                related="13"
                isTotal isPrice isDiscount isValues isExport
                //updatePrice={sum => this.setState({ sumProducts: sum })}
            />}
            {
                !isFinish && <div>
                    <div style={{ paddingTop: '20px' }} className="connection">
                        <span>Платеж</span>
                    </div>
                    <div className="rc4">
                        <Input
                            label={'Название'}
                            value={contactFields.payment_name}
                            onChange={e => setContactFields({...contactFields, payment_name: e.target.value })}
                            valid={!errors.payment_name} shouldValidate disabled
                        />
                    </div>
                    <div className="rc4">
                        <Input
                            label={'Сумма'}
                            value={contactFields.payment_amount}
                            onChange={e => setContactFields({...contactFields, payment_amount: e.target.value })}
                            valid={!errors.payment_amount} shouldValidate
                        />
                    </div>
                    <div className="rc4">
                        <Myselect
                            label={"Кошелек"}
                            options={entities.balance_id}
                            value={contactFields.balance_id}
                            val={'id'} lab={'name'}
                            onChange={(event) => setContactFields({ ...contactFields, balance_id: event.target.value })}
                        />
                    </div>

                    <div style={{ paddingTop: '20px' }} className="connection">
                        <span>Отгрузка</span>
                    </div>
                    <CustomCheckbox
                        label={'Создать отгрузку?'}
                        checked={contactFields.isCreateShipment}
                        onChange={() => setContactFields({ ...contactFields, isCreateShipment: !contactFields.isCreateShipment })}
                    />
                </div>
            }

            <div className="clear" />
            <div className="center-modal-bottom">
                <div className="button-group">
                    <button onClick={close} className="grey btni ml10">Отменить</button>
                    <button onClick={saveResult} className="success btni ml10">Завершить</button>
                </div>
            </div>
        </div>
    )
}