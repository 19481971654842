import React, {Component} from 'react';

function GetPostAditionalFields(param, response){
    if(param === "get"){
        let getArr = [];
        response.map(item => {
            let options = JSON.parse(item.field_options.rules);
            getArr.push({
                id: item.id,
                value: item.value,
                label: item.label,
                postType: item.type.name,
                arr: item.options,
                t: options.input_type,
                stage_id: item.stage_id,
                description: item.description,
                type: options.input_type,
                visible: options.visible,
                width: options.width || 'rc6',
                categories: options.categories,
                valid: true,
                validation:{required: (item.field_options.is_required === 1), email: options.email || false, number: options.number || false, website: options.website || false}
            })
        });
        return getArr;
    } else if(param === "post"){
        const postArr = [];
        response.map(item => {
            postArr.push({ id: item.id, value: item.value, stage_id: item.stage_id })
        })
        return postArr;
    } else if(param === "postContact"){
        let sendArr = [];
        response.map(item =>{
            sendArr.push({
                id: (item.id) ? item.id : null,
                value: item.value,
                label: item.label,
                type: item.postType,
                field_options: {
                    required: item.validation.required,
                    email: item.validation.email ? item.validation.email : false,
                    number: (item.validation.number) ? item.validation.number : false,
                    website: (item.validation.website) ? item.validation.website : false,
                    width: item.width,
                    input_type: item.type
                },
                options: item.arr,
            })
        })
        return sendArr;
    }
}

export default GetPostAditionalFields;