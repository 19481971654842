import React, {useContext} from "react";
import {InfoTable} from "../../../components/cardViewComponents/InfoTable";
import Input from "../../../components/UI/Input";
import Myselect from "../../../components/UI/Select";
import {AdditionalFieldsChanged} from "../../../components/additionalFields";
import MainEditor from "../../../components/UI/Editor";
import {GlobalState} from "../../../Layout/Layout";
import OldPayments from "./OldPayments";
import {NewAutoSelect} from "../../../components/NewUi/NewAutoSelect";
import myaxios from "../../../components/Axios";
import TableItem from "../../../components/other/Checkbox/TableItem";

export const InfoTab = ({ values, errors, setValues, id, invoice_updated_at, setPaymentValues }) => {
    const bc = useContext(GlobalState)
    const { created_at, updated_at, exchange_rate, currency_id, payment, cost, currencies } = values
    let isCurrency = +bc.state.baseCurrency.id === +currency_id;

    const updateRequisites = async (data_id) => {
        const { status, data } = await myaxios.get(`/ajax/account/${data_id}/payment-details`)
        if (status === 200 && data && data.items && data.items[0]) {
            setValues({ payment_detail_id: data.items[0].id, paymentDetail: data.items[0] })
        }
    }

    return (
        <div className="tab-content sbform clearFieldPadding">
            <div className="connection" style={{ marginTop: 0 }}><span>Основная информация</span></div>
            <InfoTable
                arr={[
                    { value: 'Дата создания', label: created_at },
                    { value: 'Дата обновления', label: updated_at },
                ]}
            />
            {(values.receipts || []).length > 0 && <div>
                <TableItem receipts={values.receipts} />
            </div>}
            <div className="connection"><span>Оплата</span></div>
            <Input
                tooltip="Сумма оплат. Поле недоступно для редактирования."
                label="Сумма оплаты"
                type="text"
                className={`fgroup ${isCurrency ? 'rc4' : 'rc3'}`}
                value={payment && Number(payment).toFixed(2)}
                disabled valid
            />
            <Input
                tooltip="Сумма всех добавленных товаров в базовой валюте. Поле недоступно для редактирования."
                label="Сумма в б.в."
                type="text"
                className={`fgroup ${isCurrency ? 'rc4' : 'rc3'}`}
                value={cost && Number(cost).toFixed(2)}
                shouldValidate valid
                disabled
            />
            {!isCurrency ? <Input
                tooltip="Сумма всех добавленных товаров в валюте счета валюте. Поле недоступно для редактирования."
                label="Сумма в в.с."
                type="text"
                className="fgroup rc2"
                value={(+cost / Number(exchange_rate)).toFixed(2)}
                shouldValidate={false}
                disabled={true}
            /> : null}
            <Myselect
                label="Валюта"
                className={`fgroup ${isCurrency ? 'rc4' : 'rc2'}`}
                options={currencies}
                value={currency_id}
                onChange={e => {
                    setValues({
                        ...values,
                        currency_id: e.target.value,
                        exchange_rate: currencies.filter(item => +item.id === +e.target.value)[0].value
                    })
                }}
                valid={!errors.currency_id}
                shouldValidate errorMessage={errors.currency_id}
                val="id" lab="char_code"
                disabled={values.payments.length > 0}
            />
            {(!isCurrency || errors.exchange_rate) ? <Input
                label="Курс"
                type="number"
                className="fgroup rc2"
                value={exchange_rate}
                onChange={event => setValues({ ...values, exchange_rate: event.target.value })}
                valid={!errors.exchange_rate}
                shouldValidate errorMessage={errors.exchange_rate}
            /> : null}
            <div className="clear"/>

            <OldPayments
                id={id}
                setValues={(sum, payments) => setPaymentValues({
                    ...values,
                    payment: sum,
                    payments: payments || [],
                    currency_id: values.saved_currency_id,
                    exchange_rate: currencies.filter(item => +item.id === +values.saved_currency_id)[0].value
                })}
                values={values}
                type={'invoice'}
                updated_at={invoice_updated_at}
            />

            <div className="connection"><span>Связи</span></div>
            <div className="form-container">
                <NewAutoSelect
                    label={'Продавец'}
                    link={'/ajax/account?fields=id,name&filter[name][like]='}
                    entity={values.provider}
                    className={'w50-p'}
                    updateData={(item) => {
                        setValues({
                            provider_id: item && item.id, provider: item,
                            payment_detail_id: '', paymentDetail: null
                        })
                        if(item) setTimeout(() => updateRequisites(item.id).then(), 1000)
                    }}
                    clear redirect={'account'}
                />
                <NewAutoSelect
                    label={'Реквизиты продавца'}
                    link={`/ajax/account/${values.provider_id}/payment-details`}
                    entity={values.paymentDetail}
                    className={'w50-p'}
                    updateData={(item) => setValues({ payment_detail_id: item && item.id, paymentDetail: item })}
                    clear hidePerPage refresh
                    disabled={!values.provider}
                />
                {(!values.opportunity && !values.project) && <NewAutoSelect
                    label={'Заказ'} link={`/ajax/order?fields=id,name&filter[name][like]=`}
                    entity={values.order} className={'w50-p'} clear redirect={'order'}
                    updateData={(item) => setValues({ order_id: item && item.id, order: item })}
                />}
                {(!values.order && !values.project) && <NewAutoSelect
                    label={'Сделка'} link={`/ajax/opportunity?fields=id,name&filter[name][like]=`}
                    entity={values.opportunity} className={'w50-p'} clear redirect={'opportunity'}
                    updateData={(item) => setValues({ opportunity_id: item && item.id, opportunity: item })}
                />}
                {(!values.order && !values.opportunity) && <NewAutoSelect
                    label={'Проект'} link={`/ajax/project?fields=id,name&filter[name][like]=`}
                    entity={values.project} className={'w50-p'} clear redirect={'project'}
                    updateData={(item) => setValues({ project_id: item && item.id, project: item })}
                />}
            </div>

            <div className="connection"><span>Дополнительная информация</span></div>
            <AdditionalFieldsChanged
                errors={errors}
                items={values.additionalFields}
                updateAdditionalFields={data => setValues({ additionalFields: data, tch: false })}
            />
            <div className="clear" />
            <div className="fgroup rc12">
                <MainEditor
                    label={'Описание'}
                    value={values.comment}
                    height={350}
                    valid={!errors.comment}
                    errorMessage={errors.comment}
                    onChange={comment => {
                        if(comment !== values.comment) {
                            setValues({ comment })
                        }
                    }}
                />
            </div>
        </div>
    )
}