import React, {Component} from 'react';
import Input from '../../components/UI/Input';
import Myselect from '../../components/UI/Select';
import ModAutoSelect from '../../components/UI/ModAutoSelect';
import AutoSelect from '../../components/UI/AutoSelect';
import {withRouter} from 'react-router-dom';

const status = [
    {value: 1, label: "Не начат"},
    {value: 2, label: "В работе"},
    {value: 3, label: "Частично выполнен"},
    {value: 4, label: "Завершен"},
];
const type = [
    {value: 'external', label: "Внешний проект"},
    {value: 'internal', label: "Внутренний проект"},
    {value: 'escort', label: "Сопровождение"},
];

const WrapForm = props => {
  return <form id="leadform" className="sbform">
      <Input
          label="Название"
          type="text"
          valid={props.nameValid}
          errorMessage={'Поле не может содержать меньше 3-х символов'}
          value={props.name}
          onChange={event => props.setField(event, "name")}
          shouldValidate={true}
          isFormValid={props.isFormValid}
      />
      <Myselect
          label="Состояние"
          //options={props.statusOptions}
          options={props.statusOptions}
          value={props.status}
          onChange={event => props.setField(event, "status")}
          shouldValidate={false}
      />
      <Input
          label="Приоритет проекта"
          type="text"
          errorMessage={'Значение должно быть числом'}
          value={props.priority}
          valid={props.priority_valid}
          shouldValidate={true}
          onChange={event => props.setField(event, "priority")}
      />
      <Myselect
          label="Тип"
          options={type}
          value={props.type}
          onChange={event => props.setField(event, "type")}
          shouldValidate={false}
      />
      <div className="relt">
          {
              (props.opportunity) ?
                  <div className="inp">
                      <label>Сделка</label>
                      <div className="fgroup rsb">
                          <a onClick={() => props.history.push('/opportunity/' + props.opportunity.id)}>{props.opportunity.name}</a>
                          <a title="Отвязать" onClick={()=>props.unLink("opportunity")} className="unlink">x</a>
                      </div>
                  </div> :
                  <AutoSelect
                      label="Сделка"
                      link={`/ajax/opportunity?filter[name][like]=`}
                      minLength="1"
                      result="name"
                      addType="opportunity"
                      setField={()=>{}}
                      updateData={props.updateData}
                  />
          }
      </div>
      <div className="clear"/>
      <ModAutoSelect
          required
          label="Ответственный"
          link="/ajax/settings/user?filter[name][like]="
          minLength="0"
          result="name"
          event="owner"
          valid={true}
          shouldValidate={true}
          addType="owner"
          entity={props.owner}
          entityName="name"
          updateData={props.updateData}
          unLink={props.unLink}
          ownerLink={props.owner ? props.owner.contact : null}
      />
      <div className="clear"/>
  </form>
};

export default withRouter(WrapForm);