import React, {useEffect, useState} from "react";
import Notif from "../../Notif";
import Modal from "../../Modal";
import Input from "../../UI/Input";
import myaxios from "../../Axios";
import Myselect from "../../UI/Select";
import entityFieldsArr from "../../bp/actions/entityFieldsArr";
import {bpOperators, FieldsData, searchEntityId} from "../BpHelpers";
import {NewAutoSelect} from "../../NewUi/NewAutoSelect";

export const ProcessConditionModal = ({ close, data, changeNodes, entity_type }) => {
    const [isFieldOpen, setIsFieldOpen] = useState(true)
    const [loading, setLoading] = useState(true)
    const [attributes, setAttributes] = useState([])
    const [name, setName] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const [conditions, setConditions] = useState([])
    const [tch, setTch] = useState(true)
    
    const getAdditionalFields = async () => {
        try {
            const { status, data } = await myaxios.get(`/ajax/eav/attribute?entity_type=${entity_type}`)
            if(status === 200) setAttributes(data.items)
        } catch (e) {

        }
        setLoading(false)
    }

    useEffect(() => {
        if(data) {
            if(data.data) {
                setConditions(data.data)
                setName(data.name)
            }
            setTimeout(setIsOpen, 100, true)
            getAdditionalFields().then()
        }
    }, [data])

    const saveNode = () => {
        // if(conditions.length === 0) {
        //     Notif('Error', 'Необходимо заполнить условия!'); return;
        // }

        if(name.length === 0) {
            Notif('Error', 'Название должно быть заполнено!'); return;
        }

        if(conditions.find(el => !el.value)) {
            Notif('Error', 'Не заполнены значения у условий!'); return;
        }

        changeNodes('conditions', {name: name, items: conditions}, data.yIndex);
        Notif('save', 'Условия обновлены!')
        close()
    }

    const addElement = () => {
        const field = entityFieldsArr[searchEntityId(entity_type)][0]
        const parsedConditions = JSON.parse(JSON.stringify(conditions))
        parsedConditions.push({
            field: field.value,
            type: field.type,
            operator: bpOperators[field.type][0].value,
            obj:null,
            value:''
        })
        setConditions(parsedConditions)
    }

    const deleteElement = (index) => {
        setConditions(conditions.filter((e, i) => i !== index))
    }

    return (
        <Modal
            isOpen={isOpen} tch={tch} showLeaveModal title={'Условие'}
            close={() => {setIsOpen(false); setTimeout(close, 100);}}
            save={saveNode} loading={loading} parentClassName={'bpmModal'}
        >
            <div>
                <Input
                    label={'Название'}
                    value={name}
                    onChange={e => {
                        setName(e.target.value);
                        setTch(false)
                    }}
                />
                <div className="connection" style={{ marginTop: '10px' }}><span>Фильтр</span>
                    <div className="toggleparrent">
                        <button onClick={addElement} className="btni">+</button>
                    </div>
                </div>
                {!loading && <table className="filterBpm" style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <td style={{ padding: '0px 5px', width: '200px' }}>Поле</td>
                            <td style={{ padding: '0px 5px', width: '150px' }}>Оператор</td>
                            <td style={{ padding: '0px 5px' }}>Значение</td>
                            <td style={{ width: '10px' }} />
                        </tr>
                    </thead>
                    <tbody>
                    {
                        conditions.map((item, index) => {
                            const entityConfigArr = [
                                ...entityFieldsArr[searchEntityId(entity_type)],
                                ...attributes.map(el => ({
                                    ...el, value: el.field_id,
                                    type: el.type === 'input' ? JSON.parse(el.field_options.rules).input_type : el.type
                                }))
                            ] || []
                            const field = entityConfigArr.find(el => String(el.value) === String(item.field))

                            return (
                                <tr key={index}>
                                    <td>
                                        <Myselect
                                            value={item.field}
                                            options={entityConfigArr}
                                            onChange={e => {
                                                const parsedConditions = JSON.parse(JSON.stringify(conditions))
                                                const findField = entityConfigArr.find(el => String(el.value) === String(e.target.value))
                                                parsedConditions[index].field = e.target.value
                                                parsedConditions[index].type = findField.type
                                                parsedConditions[index].object = findField.object
                                                parsedConditions[index].value = ''
                                                parsedConditions[index].obj = null
                                                parsedConditions[index].operator = bpOperators[findField.type][0].value
                                                setConditions(parsedConditions)
                                                setIsFieldOpen(true)
                                            }}
                                            onFocus={() => setIsFieldOpen(false)}
                                            onBlur={() => setIsFieldOpen(true)}
                                        />
                                    </td>
                                    <td>
                                        <Myselect
                                            value={item.operator}
                                            options={field && bpOperators[field.type]}
                                            onChange={e => {
                                                const parsedConditions = JSON.parse(JSON.stringify(conditions))
                                                parsedConditions[index].operator = e.target.value
                                                setConditions(parsedConditions)
                                                setTch(false)
                                            }}
                                        />
                                    </td>
                                    <td>
                                        {isFieldOpen && field ?
                                            <FieldsData
                                                { ...item }
                                                fieldConfig={field}
                                                onChange={val => {
                                                    const parsedConditions = JSON.parse(JSON.stringify(conditions))
                                                    parsedConditions[index].value = val
                                                    setConditions(parsedConditions)
                                                    setTch(false)
                                                }}
                                                updateData={(val) => {
                                                    const parsedConditions = JSON.parse(JSON.stringify(conditions))
                                                    parsedConditions[index].value = val.id
                                                    parsedConditions[index].obj = val
                                                    setConditions(parsedConditions)
                                                    setTch(false)
                                                }}
                                            />
                                            : 'no data'}
                                    </td>
                                    <td>
                                        <span
                                            onClick={() => deleteElement(index)}
                                            style={{ fontSize: '20px', color: '#c2263f', cursor: 'pointer', display: 'block' }}>&#10005;</span>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>}
            </div>
        </Modal>
    )
}