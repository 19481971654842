import { getCurrencyService } from "../../../services/lookup/lookupSettings";

export const getLookUpTest = async (name) => {
    try {
        const res = await getCurrencyService(name)

        if (res && res.status === 200) {
            return { status: true, data: res.data.items }
        } else {
            console.error('get currency status error')
            return { status: false }
        }
    } catch (e) {
        console.error(`get currency catch error`, e)
        return { status: false }
    }
}