import axios from "axios";
const panelUrl = window.location.host === 'localhost:3000' ? 'https://crmpanel.space-level.com/api' : 'https://crmpanel.space-level.com/api';

type ErrorType = {
    user_id: string,
    user_name?: string,
    error: string
}

type EventType = {
    event_type: string,
    user_id: string,
    user_name?: string
}

export const createError = (data: ErrorType) => axios.post(`${panelUrl}/errors`, { ...data, subdomain: window.location.host })

export const createEvent = (data: EventType) => {
    if(!window.location.host.includes('localhost:') && window.location.host !== 'kovka.sboxcrm.com') {
        return axios.post(`${panelUrl}/events`, {...data, subdomain: window.location.host})
    } else {
        return new Promise(() => {})
    }
}