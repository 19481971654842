import React from "react";
import {NavLink, withRouter} from "react-router-dom";

const TableItem = ({ history, receipts, createReceipt }) => {
    return (
        <div className="sp-table body-table">
            <div data-tooltip="Сумма всех прикрепленных продуктов" className="connection" style={{ marginTop: "10px"}}><span>Чеки</span></div>
            <table className="stagesTable" style={{borderSpacing: "0px"}}>
                <thead>
                    <tr>
                        <td>ID</td>
                        <td>Статус</td>
                        <td>Сумма</td>
                        <td>Дата</td>
                        <td />
                    </tr>
                </thead>
                <tbody>
                {
                    receipts.map((item, index) => (
                        <tr key={item.id}>
                            <td><NavLink to={`/checkbox/receipt/${item.id}`}>receipt-{item.id}</NavLink></td>
                            <td>{item.status}</td>
                            <td>{item.total_payment}</td>
                            <td>{item.created_at}</td>
                            <td style={{width: "200px", padding: "0"}}><button className="btn-m" style={{width: "200px"}}>Фискализировать чек</button></td>
                            <td>
                                <div className="toogle-bottom fl-r"><a className="is"><i className="flaticon-more" /></a>
                                    <ul className="toogle-list">
                                        <li><NavLink to={`/checkbox/receipt/${item.id}`}><b>Редактировать</b></NavLink></li>
                                        <li><a target="_blank" href={`https://${window.location.host}/ajax/checkbox/receipt/${item.id}/html`}><b>Печать HTML</b></a></li>
                                        <li><a target="_blank" href={`https://${window.location.host}/ajax/checkbox/receipt/${item.id}/pdf`}><b>Печать PDF</b></a></li>
                                        <li><a target="_blank" href={`https://${window.location.host}/ajax/checkbox/receipt/${item.id}/qr`}><b>Печать QR</b></a></li>
                                        {/*<li><a className="table-del"><i className="flaticon-delete-1" /><b>Удалить</b></a></li>*/}
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </div>
    )
}

export default withRouter(TableItem)