import {checkUpdatedEntity, CrudCreateFunction, CrudGetSingleItemFunction, CrudUpdateFunction} from "../CRUD";
import {
    addAddressService,
    deleteAddressService,
    getAddressService,
    updateAddressService
} from "../../../services/other/address";

const data = {
    address_type: "factual",
    city: "Город",
    country: "Страна",
    is_main: true,
    postal_code: "13312",
    region: "Область",
    street: "Адрес",
}

const updatedData = {
    address_type: "delivery_address",
    city: "Город123",
    country: "Страна456",
    postal_code: "1331277",
    region: "Область126",
    street: "Адрес8876",
}

export const addressesTest = async ({ entityId, entity }) => {

    // Создаем адрес
    const addAddressRes = await CrudCreateFunction({
        service: addAddressService,
        serviceData: {
            entityId, data, entity
        },
        serviceName: 'addAddressRes',
        statusCode: 200
    })
    if(!addAddressRes.status) return false;

    // Получаем и сравниваем обновленный адрес
    const getAddressRes = await CrudGetSingleItemFunction({
        service: getAddressService,
        serviceData: {
            entityId, entity
        }
    })
    if (!getAddressRes.status) return false;
    if(getAddressRes.data.items.length !== 1) {
        console.error('Неверное к-во адресов в списке сущности ' + entity)
    }
    if(!checkUpdatedEntity({...getAddressRes.data.items[0].address, is_main: true}, data, {})) return false;

    // обновляем адрес
    const updateAddressRes = await CrudUpdateFunction({
        service: updateAddressService,
        serviceData: {
            entityId, entity, data: updatedData,
            addressId: getAddressRes.data.items[0].address_id,
        }
    })
    if(!updateAddressRes.status) return false;


    // получаем и сравниваем обновленный адрес
    const getAddressRes2 = await CrudGetSingleItemFunction({
        service: getAddressService,
        serviceData: {
            entityId, entity
        }
    })
    if (!getAddressRes2.status) return false;
    if(getAddressRes2.data.items.length !== 1) {
        console.error('Неверное к-во адресов в списке сущности ' + entity)
    }
    if(!checkUpdatedEntity({...getAddressRes2.data.items[0].address}, updatedData, {})) return false;

    // Удаляем адрес
    const deleteAddressRes = await CrudCreateFunction({
        service: deleteAddressService,
        serviceData: {
            entityId, entity,
            addressId: getAddressRes.data.items[0].address_id,
        },
        statusCode: 200
    })

    if(!deleteAddressRes.status) return false;

    const getAddressRes3 = await CrudGetSingleItemFunction({
        service: getAddressService,
        serviceData: {
            entityId, entity
        }
    })
    if (!getAddressRes3.status) return false;
    if(getAddressRes3.data.items.length !== 0) {
        console.error('Неверное к-во адресов в списке сущности после удаления ' + entity)
    }

    // Создаем адрес для теста
    const addAddressRes2 = await CrudCreateFunction({
        service: addAddressService,
        serviceData: {
            entityId, data, entity
        },
        serviceName: 'addAddressRes',
        statusCode: 200
    })
    if(!addAddressRes2.status) return false;

    return true
}