import React, {useEffect, useState} from "react";
import myaxios from "../../Axios";
import Myselect from "../../UI/Select";
import Loader from "../../UI/Loader";
import Input from "../../UI/Input";

//const appRoot = document.getElementById('google-adsense');
window.gapi = null;
const GoogleAds = (props) => {
    const [state, setMyState] = useState({
        accounts: [],
        account: '',
        audiences: [],
        audience: '',
        name: '',
        loading: false
    })

    const setState = (st) => {
        Object.keys(st).map((key) => {
            state[key] = st[key]
        });
        setMyState(prevState => {
            return {...prevState, ...state}
        });
    };

    const getAudience = (accountId) => {
        setState({loading: true})
        if (accountId) myaxios.get(`/ajax/google/audiences?id=${accountId}`).then(res => {
            if(res) setState({audiences: res.data, audience: res.data[0] ? res.data[0].id : '', loading: false})
        })
    };

    const getAcc = () => {
        myaxios.get(`/ajax/google/customer-accounts`).then(res => {
            if(res) setState({accounts: res.data})
        })
    }

    useEffect(() => {
        getAcc();
        if(props.elArr){
            setState({
                customer_id: props.elArr.metaData ? props.elArr.metaData.customer_id : "",
                auditory_id: props.elArr.metaData ? props.elArr.metaData.auditory_id : "",
                name: props.elArr.metaData ? props.elArr.name : "",
            })
            if(props.elArr.metaData.customer_id) getAudience(props.elArr.metaData.customer_id)
        }
    }, [])

    const sendForm = () => {
        if (state.name.length === 0) return;
        if (props.elArr) {
            setTimeout(
                props.updateNode({
                    id: state.id,
                    name: state.name,
                    time_value:0,
                    is_scheduled:0,
                    metaData: {
                        auditory_id: state.audience,
                        customer_id: state.account
                    },
                    nodeType: "node",
                    type: "gl_audience_add",
                })
            )
        } else {
            setTimeout(
                props.createNode({
                    name: state.name,
                    is_scheduled:0,
                    time_value:0,
                    metaData: {
                        auditory_id: state.audience,
                        customer_id: state.account
                    },
                    nodeType: "node",
                    type: "gl_audience_add",
                })
            )
        }

    };

    return state.loading ? <Loader contain/> : <div>
        <div className="rmod-header">
            <b>Google Ads</b>
            <a onClick={props.close} style={{marginRight: "60px"}} className="mclose"><i
                className="flaticon-close"/></a>
            <div className="togglelist">
                <button onClick={sendForm} className="rmod-ok" disabled=""><i className="flaticon-interface-1"/>
                </button>
            </div>
        </div>
        <div className="mod-cont sbform">
            <button onClick={() => {
                window.open("https://accounts.google.com/o/oauth2/v2/auth?response_type=code&access_type=offline&client_id=1082383311859-lbme2bqua9scai5de3pi3ek17s38a1vo.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fsboxcrm.com%2Fapi%2Fv1%2Fgoogle%2Fauth&state=dev&scope=email%20profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fadwords%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar%20openid&prompt=consent")
            }}>Войти
            </button>
            <Input
                label="Название"
                value={state.name}
                onChange={e => setState({name: e.target.value})}
            />
            <Myselect
                label={"Кампании Google Ads"}
                options={state.accounts}
                value={state.account}
                val={`id`}
                lab={'name'}
                onChange={e => {
                    setState({account: e.target.value});
                    getAudience(e.target.value)
                }}
            />
            <Myselect
                label={"Аудитории"}
                options={state.audiences}
                value={state.audience}
                val={`id`}
                lab={'name'}
                onChange={e => setState({audience: e.target.value})}
            />
            {/*<GoogleLogin
                clientId={"1082383311859-lbme2bqua9scai5de3pi3ek17s38a1vo.apps.googleusercontent.com"}
                buttonText="Login"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}
                scope="email profile https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/calendar openid https://www.googleapis.com/auth/adwords"
                accessType="offline"
                responseType="code"
                prompt={"consent"}
            />*/}
        </div>
    </div>
}

export default GoogleAds
