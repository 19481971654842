import React, {useEffect, useRef, useState} from "react";
import myaxios from "../Axios";
import {LoaderOld} from "../UI/Loader";
import emojiIcon from "../../img/emojiIcon.svg";
import {Picker} from "emoji-mart";
import 'emoji-mart/css/emoji-mart.css'
import {msgColor} from "./MessengersList";
import fileIcon from '../../img/icons8-file-100.png'
import {useOnClickOutside} from "../hooks/useOnClickOutside";
import is from 'is_js'

function findAndReplaceLink(text) {
    if(text){
        var urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, function(url) {
            return '<a target="_blank" href="' + url + '">' + url + '</a>';
        })
    } else {
        return text
    }
}

export const ChatItem = ({ item, close, notifSocket, history }) => {
    const [loading, setLoading] = useState(true)
    const [openImg, setOpenImg] = useState('')
    const [hide, setHide] = useState(false)
    const [textMessage, setTextMessage] = useState('')
    const [buttonLoading, setButtonLoading] = useState(false)
    const [isChatStarted, setIsChatStarted] = useState(false)
    const [items, setItems] = useState([])
    const [page, setPage] = useState(1)
    const [metaData, setMetaData] = useState({
        totalCount: 0,
        pageCount: 0
    })
    const [lead, setLead] = useState(null)
    const [contact, setContact] = useState(null)
    const [unSeenMessages, setUnSeenMessages] = useState(0)
    const [cursorIndex, setCursorIndex] = useState(0)
    const [isFullModalSize, setIsFullModalSize] = useState(false)
    const [isShowDropDown, setIsShowDropDown] = useState(false)
    const inputRef = useRef(null)
    const dropdownRef = useRef(null)
    useOnClickOutside({ ref: dropdownRef, handler: () => setIsShowDropDown(false) })

    const { id, type, user, name, bot } = item

    const getChats = async () => {
        const { status, data } =
            await myaxios.get(`/ajax/${type}/chat/get-all-messages/${user && user.id}?expand=sender&sort=created-at&per-page=50`)
        if(status === 200 && data && data.items) {
            setItems(data.items)
            setIsChatStarted(data.start)
            if(data._meta) {
                setMetaData({
                    pageCount: data._meta.pageCount,
                    totalCount: data._meta.totalCount
                })
            }
        }
        setLoading(false)
    }

    const fetchMoreData = async () => {
        if(page < metaData.pageCount) {
            setLoading(true)
            const newPage = page + 1;
            setPage(newPage)
            setTimeout(() => {
                myaxios.get(`/ajax/${type}/chat/get-all-messages/${user && user.id}?per-page=50&page=${newPage}`, {
                }).then((response) => {
                    setItems(prevState => ([
                        ...prevState,
                        ...response.data.items
                    ]))
                })
            }, 500);
            setLoading(false)
        }
    };

    const sendMessage = async () => {
        if(textMessage.trim().length < 1) {
            return;
        }
        setButtonLoading(true)
        const { status, data } = await myaxios.post(`/ajax/${type}/chat/send/${user && user.id}`, {
            type: 'text',
            message: textMessage,
            file_id: null
        })
        if((status === 200 || status === 201) && data && data.success) {
            setItems([{direction: 0, message_type: 'text', text: textMessage, time: "Только что"}, ...items])
            setTextMessage('')
        }
        setButtonLoading(false)
    }

    const startChat = async () => {
        setLoading(true)
        const { status, data } = await myaxios.post(`/ajax/${type}/chat/start/${user && user.id}`)
        if(status === 200 || status === 201) {
            setLoading(false)
            setIsChatStarted(true)
        }
    }
    const stopChat = async () => {
        setLoading(true)
        try {
            const { status, data } = await myaxios.post(`/ajax/${type}/chat/${user && user.id}/stop`)
            if(status === 200 && data && data.success) {
                setIsChatStarted(false)
            }
        } catch (e) {}
        setLoading(false)
    }

    const convertToLead = async () => {
        setLoading(true)
        try {
            const { status, data } = await myaxios.post(`/ajax/bot/user/${user.id}/convert-to-lead`)
            if (status === 201) setLead(data)
        } catch (e) {}
        setLoading(false)
    }

    const convertToContact = async () => {
        setLoading(true)
        try {
            const { status, data } = await myaxios.post(`/ajax/bot/user/${user.id}/convert-to-contact`)
            if (status === 201) setContact(data)
        } catch (e) {}
        setLoading(false)
    }

    const addNewMessage = (item) => {
        setItems(prevState => ([ {
            direction: 1, message_type: item.message_type, text: item.message, time: "Только что"
        }, ...prevState ]))
    }

    useEffect(() => {
        notifSocket.addEventListener('message', function (event) {
            console.log('asdasd check')
            addNewMessage(JSON.parse(event.data))
            setUnSeenMessages(prevState => prevState + 1)
        });
    }, [])

    useEffect(() => {
        getChats().then()

        // return () => notifSocket.removeEventListener('message', this, false)

        /*return function cleanupListener() {
            notifSocket.removeEventListener('message', () => {})
        }*/

    }, [id, type])

    const handleSelectedFile = (event, type) => {
        setLoading(true)
        const data = new FormData();
        data.append('UploadForm[file]', event.target.files[0], event.target.files[0].name);

        myaxios.post(`/ajax/file/upload?related_type=34`, data).then(res => {
            sendMessage({id: res.data.id}).then()
            this.submitMessage(res.data.id, type)
        }).catch(e => {})

        setLoading(false)
    };

    const MessageItem = (message) => {
        if(message.message_type === 'text') {
            return findAndReplaceLink(message.text)
        } else if(message.message_type === 'photo' || message.message_type === 'picture') {
            return <img onClick={() => setOpenImg(message.message || message.text)} style={{ maxWidth: '100%' }} src={message.message || message.text} alt="" />
        } else if(message.message_type === 'file') {
            return <a className={'chat-item__file-item'} href={message.message}>
                <img style={{ maxWidth: '40px' }} src={fileIcon} alt="" />
                <span>request_main_text_file.png</span>
            </a>
        }
    }

    const messageRender = (message) => {
        if(+message.direction === 1) {
            return (
                <div className={'chat-item__my-message'}>
                    <div className="chat-item__time">{message.time}</div>
                    <div className="chat-item__style">
                        {MessageItem(message)}
                    </div>
                </div>
            )
        } else {
            return (
                <div className={'chat-item__out-message'}>
                    <div className="chat-item__time">{message.time}</div>
                    <div className="chat-item__style">
                        {MessageItem(message)}
                    </div>
                </div>
            )
        }
    }

    const parseText = (text) => {
        if(textMessage.length < cursorIndex) {
            return false;
        }
        setTextMessage(textMessage.substr(0, cursorIndex) + text + textMessage.substr(cursorIndex, textMessage.length))
    }

    return (
        <div className={`${isFullModalSize && 'chat-item__fullSizeModal'} chat-item`} style={{ position: 'relative', height: hide ? '50px' : '' }}>
            {loading && <LoaderOld contain />}
            <div className="chat-item__header">
                <div
                    onClick={() => setIsShowDropDown(!isShowDropDown)}
                    className="chat-item__user">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="chat-item__avatar">
                            <img src={`${user && user.picture && user.picture.length > 5 ? user.picture : 'https://colegioclassea.com.br/wp-content/themes/PageLand/assets/img/avatar/avatar.jpg'}`} alt=""/>
                        </div>
                        <div className="chat-item__name"><h4>{name}</h4><b style={{ color: msgColor(type)}}>{type} {bot ? bot.name : null}</b></div>
                        <div style={{ display: 'flex' }}>
                            {unSeenMessages > 0 && <div className="chat-item__unseenMessages">{unSeenMessages}</div>}
                            <span className={'chat-item__header-show'}>&#10094;</span>
                        </div>
                    </div>
                </div>
                <div className="chat-item__buttons">
                    <div onClick={() => {
                        setIsFullModalSize(!isFullModalSize);
                        setHide(false)
                    }} className="chat-item__buttons-hide">
                        <i style={{ fontSize: '17px' }} className={'flaticon-arrows'} />
                    </div>
                    <div onClick={() => {
                        setHide(!hide)
                        if(!hide) setIsFullModalSize(false)
                    }} className="chat-item__buttons-hide">_</div>
                    <div onClick={close} className="chat-item__buttons-close">&#10005;</div>
                </div>
                {
                    isShowDropDown && <div className="chat-item__header-dropDown" ref={dropdownRef}>
                        <button onClick={() => history.push(`/marketing/messengers/users/${user.id}`)}>Информация о пользователе</button>
                        {!user.lead && !lead && <button onClick={convertToLead}>Создать лид</button>}
                        {!user.contact && !contact && <button onClick={convertToContact}>Создать контакт</button>}
                        {isChatStarted && <button onClick={stopChat}>Завершить чат</button>}
                        {(user.contact || contact) && <button onClick={() => history.push(`/contact/${user.contact ? user.contact.id : contact.id}`)}>
                            Контакт: {user.contact ? `${user.contact.name}` : `${contact.name}`}
                        </button>}
                        {(user.lead || lead) && <button onClick={() => history.push(`/lead/${user.lead ? user.lead.id : lead.id}`)}>
                            Лид: {user.lead ? `${user.lead.name}` : `${lead.name}`}
                        </button>}
                    </div>
                }
            </div>
            {!hide && <div className="chat-item__body">
                <div id={`infinite-message-render-${id}`} className="chat-item__messages scrollbar">
                    {
                        items.map((item) => messageRender(item))
                    }
                    {page < metaData.pageCount &&
                    <button className={'chat-item__loadMore'} onClick={fetchMoreData}>Загрузить еще</button>}
                </div>
            </div>}
            {!hide && <div className="chat-item__footer">
                {
                    !isChatStarted && <button onClick={() => {startChat().then(); setUnSeenMessages(0)}} className={'chat-item__startChat'}>Начать чат</button>
                }
                {isChatStarted && <div className="chat-item__message-container">
                    <input
                        ref={inputRef}
                        className={'chat-item__message-container-input'}
                        value={textMessage}
                        onChange={e => {
                            setTextMessage(e.target.value)
                            setUnSeenMessages(0)
                        }}
                        onKeyDown={(e) => e.keyCode === 13 && textMessage.trim().length >= 1 && sendMessage()}
                        onKeyUp={e => setCursorIndex(e && e.target && e.target.selectionStart)}
                        onClick={e => setCursorIndex(e && e.target && e.target.selectionStart)}
                        onFocus={() => setUnSeenMessages(0)}
                    />

                    <div className={'chat-item__message-container-emoji'}>
                        <img className={'chat-item__message-container-emoji-img'} src={emojiIcon} alt=""/>
                        <Picker
                            onClick={(e, event) => parseText(e.native)}
                            style={{ position: 'absolute', bottom: '20px', right: '0px', display: 'none' }}
                        />
                    </div>

                    <button
                        className={'chat-item__message-container-button'}
                        disabled={buttonLoading || textMessage.trim().length < 1} onClick={sendMessage}><i className={'flaticon-paper-plane'} /></button>
                </div>}
            </div>}
            {openImg && <div onClick={() => setOpenImg('')} style={{
                position: 'fixed', width: '100%', height: '100vh', left: 0, top: 0, right: 0, bottom: 0, zIndex: 6,
                display: "flex", alignItems: 'center', justifyContent: "center", background: 'rgba(000,000,000,0.6)'
            }}>
                <img style={{ maxWidth: '1000px' }} src={openImg} alt={''} />
            </div>}
        </div>
    )
}