import React from 'react';
import ReactDOM from 'react-dom';
import './css/style.css';
import './css/flaticon.css';
import './main.js';
import {BrowserRouter, Route, Link, Redirect, withRouter, Switch} from 'react-router-dom';
import Layout from './Layout/Layout.js';
import myaxios from './components/Axios';
import Login from './Layout/Login';
import ResetPassword from "./Layout/RezetPassword";
import TestTask from "./main";
import NoInternetConnection from "./Layout/NoInernet";

class Auth extends React.Component {
    state = {
        auth: true,
        reset: false
    };
    showAuth = () => {
        myaxios.get(`/ajax/site/is-guest`, {method: 'get',}).then((responce) =>{
            this.setState({auth: responce.data});
            this.show();
        })
    };
    exitFunc = () => {
        myaxios.post(`/ajax/site/logout`, {method: 'get',}).then((responce) =>{
            this.setState({auth: true})
        })
    };
    show = () => {
        var full = window.location.host
        var parts = full.split('.');
        var sub = parts[0]
        if(sub == "localhost:3000"){
            if(this.state.auth !== false) {
                return <BrowserRouter><Layout exit={this.exitFunc} /></BrowserRouter>
            } else {
                return <Login showAuth={this.showAuth} />
            }
        } else {
            if(this.state.auth === false) {
                return <BrowserRouter><Layout exit={this.exitFunc} /></BrowserRouter>
            } else {
                if(!this.state.reset){
                    return <BrowserRouter><Login reset={() => this.setState({ reset: true })} showAuth={this.showAuth} /></BrowserRouter>
                } else return <ResetPassword reset={() => this.setState({ reset: false })} />

            }
        }
    };
    componentDidMount(){
        this.showAuth()
    }
    render(){
        return <div>
            {this.show()}
        </div>
    }
}


ReactDOM.render(
    <div>
        <NoInternetConnection>
            <Auth/>
        </NoInternetConnection>
        {/*<BrowserRouter><Layout /></BrowserRouter>*/}
        <div className="notif" id="notif" />
    </div>,
    document.getElementById("root")
);

