import React,{ Component } from 'react';
import {withRouter} from 'react-router-dom';
import myaxios from '../components/Axios';
import Notif from '../components/Notif';
import MultiSelect from '../components/UI/MultiSelect';


class EntityListSettings extends Component {
    state = {
        formControl: {
            type: {value: "pdf"},
            select: {
                value: '',
                custom:[],
                options:[],
            }
        },
        tch: true,
        loading: false
    }
    updateData = (arr) => {
        let formControl = this.state.formControl;
        formControl.select.options = arr;
        this.setState({ formControl, tch: false })
    }
    sendForm = () => {
        if(this.props.filterId){
            myaxios.put(`/ajax/filter/${this.props.filterId}`, {
                name: this.props.exportType,
                entity_type: this.props.entityNumber,
                user_id: this.props.userId,
                filter_attributes: this.props.myfilter.filter(item => item.value),
                columns:this.state.formControl.select.options
            }).then(res => {
                if(res.status === 200 || res.status === 201 || res.status === 204){
                    Notif("save");
                    setTimeout(this.props.refresh)
                }
            })
        } else {
            myaxios.post(`/ajax/filter`, {
                name: this.props.exportType,
                entity_type: this.props.entityNumber,
                user_id: this.props.userId,
                filter_attributes: this.props.myfilter.filter(item => item.value),
                columns:this.state.formControl.select.options
            }).then(res => {
                if(res.status === 200 || res.status === 201 || res.status === 204){
                    Notif("save");
                    setTimeout(this.props.refresh)
                }
            })
        }

        /*myaxios.put(`/ajax/settings/extra-data?entity=${this.props.opportunity}`, {
            data: {[this.props.field_settings]: JSON.stringify(this.state.formControl.select.options)}
        }).then(res => {
            if(res.status === 200){
                Notif("save");
                setTimeout(this.props.refresh)
            }
        })*/
    };
    searchArr = (arr, item) => {
        let a = true;
        for(let i = 0; i < arr.length; i++){
            if(arr[i] == item){
                a = false;
            }
        }
        return a;
    }
    clearArr = () => {
        let filter = this.props.filter;
        let thisCustom = [];
        for(let i = 0; i < filter.length; i++){
            if(filter[i]){
                if(this.searchArr(this.props.required, filter[i].value)){
                    thisCustom.push({value: filter[i].value, label: filter[i].label});
                }
            }
        }
        let formControl = this.state.formControl;
        formControl.select.custom = thisCustom;
        formControl.select.options = this.props.custom;
        this.setState({ formControl })
    }
    componentWillMount(){
        this.clearArr();
    }
    render(){
        return <div>
            <div className="rmod-header">
                <b>Настройка колонок</b>
                <a onClick={this.props.close} style={{ marginRight: "60px" }} className="mclose"><i className="flaticon-close" /></a>
                <div className="togglelist"><button onClick={this.sendForm} className="rmod-ok" disabled={this.state.tch}><i className="flaticon-interface-1" /></button></div>
            </div>
            <div className="mod-cont sbform">
                <MultiSelect
                    //label={""}
                    //className={`fgroup ${arr.width}`}
                    custom={this.state.formControl.select.custom}
                    options={this.state.formControl.select.options}
                    valid={true}
                    updateData={(arr) => this.updateData(arr)}
                />
            </div>
        </div>
    }
}

export default withRouter(EntityListSettings)