import {NewList} from "../../components/NewList/NewList";
import {entity} from "../../components/ListComponentEntity";
import {NavLink} from "react-router-dom";
import React from "react";
import localstorageSettings from "../../components/localstorageSettings";

export const ListOpportunityNew = (props) => {
    return (
        <NewList
            { ...props }
            entity={'opportunity'}
            entity_type={1}
            requiredFields={'id,name,stage_id,amount,main_contact_id,account_id'}
            requiredExpand={'stage,currency,contact,account'}
            deleteToShowColumn={'name,status_id,amount,type_id,stage_id,currency_id,main_contact_id,account_id'}
            additionalFieldsExists={true}
            isFilterExists={true}
            filterFields={entity.opportunity.fArr}
            singleSearch={entity.opportunity.fArr[0]}
            headingColumns={['Название/Бюджет', 'Клиент', 'Статус']}
            RenderColumn={(item) => (
                <>
                    <td>
                        <NavLink to={`/opportunity/${item.id}`}  className={'nowrap-list-column'}>{item.name}</NavLink>
                        <div className={'nowrap-list-column'}>Бюджет: {item.amount}{item.currency && item.currency.text_symbol}</div>
                    </td>
                    <td>
                        <div>
                            {item.contact && <div className={'nowrap-list-column'}>Клиент: <NavLink to={`/contact/${item.contact.id}`}>{item.contact.name}</NavLink></div>}
                            {item.account && <div className={'nowrap-list-column'}>Клиент: <NavLink to={`/account/${item.account.id}`}>{item.account.name}</NavLink></div>}
                        </div>
                    </td>
                    <td>
                        {item.stage && <span className="label-st" style={{ backgroundColor: item.stage.color ? item.stage.color : 'rgb(255, 193, 100)' }}>{item.stage.value}</span>}
                    </td>
                </>
            )}
            createButtonRender={(setModalType) => <>
                <a onClick={() => {props.history.push(`/opportunity/kanban`); localstorageSettings("post", "opportunityView", "kanban")}} className="btns"><i className="flaticon-web" /></a>
                <button onClick={() => setModalType('opportunity')} className="open-modal-button btni">Добавить сделку</button>
            </>}
            analytics={'/opportunity/report/'}
            rightNavbarConfig={{
                export: true,
                additionalFields: '/fieldsettings/1',
                columns: true,
                import: '/import/opportunity/'
            }}
            massDoConfig={{ deleteAll: `/ajax/opportunity/delete-all` }}
            contextNav={{ edit: true, task: true, print: false, delete: true }}
            sort={entity.opportunity.fArr.filter(el => el.sort)}
        />
    )
}