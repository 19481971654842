import {InfoTable} from "../../../components/cardViewComponents/InfoTable";
import {ContactFieldsData, ContactFieldsRender} from "../../../components/cardViewComponents/ContactFields";
import {GenerateForm} from "../../../components/cardViewComponents/GenerateForm";
import {leadConfig} from "../leadConfig";
import {AdditionalFieldsChanged} from "../../../components/additionalFields";
import React from "react";
import {AddressesModalNew} from "../../../components/cardViewComponents/AddressesModalNew";
import MainEditor from "../../../components/UI/Editor";

export const InfoTab = ({ id, values, errors, disabled, setValues, changeFields, saveItem, setContactFields }) => {
    return (
        <>
            <div className="tab-content sbform">
                <div className="connection" style={{ marginTop: 0 }}><span>Основная информация</span></div>
                <InfoTable
                    arr={[
                        { value: 'Дата создания', label: values.created_at },
                        { value: 'Дата обновления', label: values.updated_at }
                    ]}
                />
                <ContactFieldsData
                    errors={errors}
                    contactFields={values.contactFields}
                    setValues={setContactFields}
                />
                <GenerateForm
                    config={leadConfig}
                    { ...{ values, errors, disabled } }
                    onChange={changeFields}
                    onSaveData={saveItem}
                    zone={2}
                />
                <ContactFieldsRender
                    errors={errors}
                    contactFields={values.contactFields}
                    setValues={setContactFields}
                />
                <AddressesModalNew entity={'lead'} id={id} />
                <div className="connection"><span>Дополнительная информация</span></div>
                <AdditionalFieldsChanged
                    errors={errors}
                    items={values.additionalFields}
                    updateAdditionalFields={data => setValues({ additionalFields: data, tch: false })}
                />
                <div className="clear" />
                <div className="fgroup rc12">
                    <MainEditor
                        label={'Описание'}
                        value={values.description}
                        height={350}
                        valid={!errors.description}
                        errorMessage={errors.description}
                        onChange={description => {
                            if(description !== values.description) {
                                setValues({ description })
                            }
                        }}
                    />
                </div>

            </div>
        </>
    )
}