import Input from "../../UI/Input";
import ModAutoSelect from "../../UI/ModAutoSelect";
import Myselect from "../../UI/Select";
import DateTime from "../../UI/DateTime";
import React from "react";
import {Link} from "react-router-dom";
import AutoSelect from "../../UI/AutoSelect";

export const CommonModalView = ({
    contactFields,
    setContactFields,
    errors,
    entities,
    toNextStep,
    updateData,
    close,
    createdEntities,
    setCreatedEntities
}) => {
    return (
            <div className="modal_first_step">
                <div className={'sbform emptyLabelPadding'}>
                    <div className="connection">
                        <span>Контакт</span>
                    </div>
                    {createdEntities.contact ?
                        <div>
                            <Link className={'created-entity-link'} to={`/contact/${createdEntities.contact.id}`}>{createdEntities.contact.name}</Link>
                        </div> :
                    <div>
                        <div className="rc4">
                            <AutoSelect
                                label="Имя"
                                link={'/ajax/contact?filter[name][like]='}
                                errorMessage={'Поле не может быть пустым'}
                                shouldValidate
                                valid={!errors.first_name}
                                value={contactFields.first_name}
                                minLength="2"
                                result="name"
                                setField={(e)=> setContactFields({...contactFields, first_name: e.target.value })}
                                updateData={(id, data) => setCreatedEntities({ ...createdEntities, contact: data })}
                            />
                        </div>
                        <div className="rc4">
                            <AutoSelect
                                label="Фамилия"
                                link={'/ajax/contact?filter[last_name][like]='}
                                errorMessage={'Поле не может быть пустым'}
                                shouldValidate
                                valid={!errors.last_name}
                                value={contactFields.last_name}
                                minLength="10"
                                result="last_name"
                                setField={(e)=> setContactFields({...contactFields, last_name: e.target.value })}
                                updateData={(id, data) => setCreatedEntities({ ...createdEntities, contact: data })}
                            />
                        </div>
                        <div className="rc4">
                            <AutoSelect
                                label="Номер телефона"
                                link={'/ajax/contact/search?phone='}
                                errorMessage={'Поле не может быть пустым'}
                                shouldValidate
                                valid={!errors.mobile_phone}
                                value={contactFields.mobile_phone}
                                minLength="1"
                                result="name"
                                setField={(e)=> setContactFields({...contactFields, mobile_phone: e.target.value })}
                                updateData={(id, data) => setCreatedEntities({ ...createdEntities, contact: data })}
                            />
                        </div>
                        <div className="rc4">
                            <AutoSelect
                                label="Email"
                                link={'/ajax/contact?filter[email][like]='}
                                errorMessage={'Поле не может быть пустым'}
                                shouldValidate
                                valid={!errors.email}
                                value={contactFields.email}
                                minLength="6"
                                result="email"
                                setField={(e)=> setContactFields({...contactFields, email: e.target.value })}
                                updateData={(id, data) => setCreatedEntities({ ...createdEntities, contact: data })}
                            />
                        </div>
                        <div className="rc4">
                            <ModAutoSelect
                                label={'Тип контакта'}
                                link={'/ajax/lookup/search?type=ct_type&value='}
                                minLength={0}
                                result="value"
                                event="type_id"
                                valid={!errors.type_id}
                                shouldValidate
                                addType="type_id"
                                entity={entities.type_id}
                                entityName="value"
                                updateData={(id, arr) => updateData('type_id', arr, id)}
                                unLink={() => updateData('type_id', null, '')}
                            />
                        </div>
                        <div className="rc4">
                            <ModAutoSelect
                                label={'Ответственный'}
                                link={'/ajax/settings/user?filter[name][like]='}
                                minLength={0}
                                result="name"
                                event="owner_id"
                                shouldValidate valid
                                addType="owner_id"
                                entity={entities.owner_id}
                                entityName="name"
                                updateData={(id, arr) => updateData('owner_id', arr, id)}
                                unLink={() => updateData('owner_id', null, '')}
                            />
                        </div>
                    </div>}


                    {createdEntities.invoice ?
                        <div>
                            <Link className={'created-entity-link'} to={`/invoice/${createdEntities.invoice.id}`}>{createdEntities.invoice.name}</Link>
                        </div> :
                        <div>
                            <div style={{ paddingTop: '20px' }} className="connection">
                                <span>Счет</span>
                            </div>
                            <div className="rc4">
                                <ModAutoSelect
                                    label={'Префикс'}
                                    link={'/ajax/settings/lookup?type=inv_prefix&value='}
                                    minLength={0}
                                    result="name"
                                    event="prefix_id" valid shouldValidate disabled
                                    addType="prefix_id"
                                    entity={entities.prefix_id}
                                    entityName="name"
                                />
                            </div>
                            <div className="rc4">
                                <ModAutoSelect
                                    label={'Продавец'}
                                    link={'/ajax/account?filter[type_id]=1&filter[name][like]='}
                                    minLength={0}
                                    result="name"
                                    event="provider_id"
                                    valid={!errors.provider_id}
                                    shouldValidate
                                    addType="provider_id"
                                    entity={entities.provider_id}
                                    entityName="name"
                                    updateData={(id, arr) => updateData('provider_id', arr, id)}
                                    unLink={() => updateData('provider_id', null, '')}
                                />
                            </div>
                            <div className="rc4">
                                <ModAutoSelect
                                    label={'Реквизиты продавца'}
                                    link={`/ajax/account/${contactFields.provider_id}/payment-details`}
                                    minLength={0}
                                    result="name"
                                    event="payment_detail_id"
                                    valid={!errors.payment_detail_id}
                                    shouldValidate
                                    disabled={!entities.provider_id}
                                    addType="payment_detail_id"
                                    entity={entities.payment_detail_id}
                                    entityName="name"
                                    updateData={(id, arr) => updateData('payment_detail_id', arr, id)}
                                    unLink={() => updateData('payment_detail_id', null, '')}
                                />
                            </div>
                            <div className="rc4">
                                <Myselect
                                    label={"Склад"}
                                    options={entities.stock_id}
                                    value={contactFields.stock_id}
                                    val={'id'} lab={'name'}
                                    onChange={(event) => setContactFields({ ...contactFields, stock_id: event.target.value })}
                                />
                            </div>
                            <div className="rc4">
                                <Myselect
                                    label={"Статус"}
                                    options={entities.status_id}
                                    value={contactFields.status_id}
                                    val={'id'} lab={'value'}
                                    onChange={(event) => setContactFields({ ...contactFields, status_id: event.target.value })}
                                />
                            </div>
                            <div className="rc4">
                                <DateTime
                                    label={'Предполагаемая дата оплаты'}
                                    value={contactFields.payment_date}
                                    handleChange={e => setContactFields({ ...contactFields, payment_date: e })}
                                    dateFormat="dd-MMMM-yyyy"
                                    valid={!errors.payment_date} shouldValidate
                                />
                            </div>
                            <div className="rc4">
                                <DateTime
                                    label={'Фактическая дата оплаты'}
                                    value={contactFields.payed_at}
                                    handleChange={e => setContactFields({ ...contactFields, payed_at: e })}
                                    dateFormat="dd-MMMM-yyyy"
                                    valid={!errors.payed_at} shouldValidate
                                />
                            </div>
                        </div>
                    }

                    <div className="clear" />
                    <div className="center-modal-bottom">
                        <div className="button-group">
                            <button onClick={close} className="grey btni ml10">Отменить</button>
                            <button onClick={toNextStep} className="success btni ml10">Перейти к продуктам</button>
                        </div>
                    </div>
                </div>
            </div>
    )
}