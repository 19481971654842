export const tabsConfig = [
    {value:"information", label:"Основная информация"},
    {value:"history", label:"Хронология"},
    {value:"related", label:"Связи"},
    {value:"files", label:"Файлы"},
]

export const contactConfig = {
    last_name: {
        label: 'Фамилия',
        type: 'text',
        className: 'w100',
        validation: { maxLength: 255 },
        zone: 1
    },
    first_name: {
        label: 'Имя',
        type: 'text',
        className: 'w100',
        validation: { required: true, maxLength: 255 },
        zone: 1
    },
    middle_name: {
        label: 'Отчество',
        type: 'text',
        className: 'w100',
        validation: { maxLength: 255 },
        zone: 1
    },
    type_id: {
        label: 'Тип контакта',
        type: 'select',
        options: [],
        className: 'w100',
        options_from: 'types', val: 'id', lab: 'value',
        validation: { required: true },
        zone: 1,
    },
    personal_discount: {
        label: 'Персональная скидка (%)',
        type: 'number',
        className: 'w100',
        validation: { maxLength: 255, minNumber: 0, maxNumber: 100, number: true },
        zone: 1
    },
    owner_id: {
        label: 'Ответственный',
        type: 'entity',
        link: "/ajax/settings/user?filter[name][like]=",
        result: 'name',
        entity_from: 'owner',
        entityName: 'name',
        className: 'w100',
        minLength: 0,
        validation: { required: true },
        zone: 1
        // redirectLink
    },
    account_id: {
        type: 'entity',
        entity_from: 'account',
        // redirectLink
    },
    position: {
        label: 'Должность',
        type: 'text',
        className: 'w50-p',
        validation: { maxLength: 255 },
        zone: 2
    },
    mobile_phone: {
        label: 'Мобильный телефон',
        type: 'number',
        className: 'w50-p',
        validation: { maxLength: 255 },
        zone: 2
    },
    work_phone: {
        label: 'Рабочий телефон',
        type: 'number',
        className: 'w50-p',
        validation: { maxLength: 255 },
        zone: 2
    },
    email: {
        label: 'Email',
        type: 'text',
        className: 'w50-p',
        validation: { maxLength: 255, email: true },
        zone: 2
    },
    birthdate: {
        label: 'Дата рождения',
        type: 'date',
        className: 'w50-p',
        validation: { maxLength: 255},
        zone: 2
    },
    description: {
        type: 'text',
        validation: { maxLength: 2000 },
    },
    photo: {},
    editorTags: {},
    created_at: {},
    updated_at: {},
    segments: {},
    rfmSegments: {},
    invoicesDiff: {},
}