import React, {useState} from "react";
import Joyride from 'react-joyride';
import Input from "../../UI/Input";
import {putWizard} from "../needFunctions.ts";
import myaxios from "../../Axios";
import Notif from "../../Notif";
import axios from "axios"

class JoyrideMain extends React.Component {
    state = {
        steps: [
            {
                target: '.contact',
                content: 'Чтобы создать контакт, перейдите в раздел "Контакты".',
                disableBeacon: true,
                disableOverlayClose: true,
                hideCloseButton: true,
                hideFooter: true,
                placement: 'bottom',
                spotlightClicks: true,
                styles: {options: {zIndex: 10000}},
            },
            {
                target: '.open-modal-button',
                content: 'Далее нажмите на кнопку добавить контакт, после чего откроется всплывающее окно.',
                disableBeacon: true,
                disableOverlayClose: true,
                hideCloseButton: true,
                hideFooter: true,
                placement: 'right',
                spotlightClicks: true,
                styles: {options: {zIndex: 10000}},
                // content: 'This is our sidebar, you can find everything you need here',
                // placement: 'right',
                // disableBeacon: true,
                // disableOverlayClose: true,
                // hideCloseButton: true,
                // hideFooter: true,
                // spotlightPadding: 0,
                // styles: {options: {zIndex: 10000}},
                // event:"hover"
            },
            {
                target: '.first_field',
                content: 'Введите имя контакта, после чего нажмите на кнопку TAB.',
                disableBeacon: true,
                disableOverlayClose: true,
                hideCloseButton: true,
                hideFooter: true,
                placement: 'bottom',
                spotlightClicks: true,
                styles: {options: {zIndex: 10000}},
            },
            /*{
                target: '.second_field',
                content: 'Введите фамилию контакта и намите кнопку Enter или пропустите с помощью кнопки TAB',
                disableBeacon: true,
                disableOverlayClose: true,
                hideCloseButton: true,
                hideFooter: true,
                placement: 'bottom',
                spotlightClicks: true,
                styles: {options: {zIndex: 10000}},
            },*/
            {
                target: '.save-modal-button',
                content: 'Нажмите на кнопку сохранения.',
                disableBeacon: true,
                disableOverlayClose: true,
                hideCloseButton: true,
                hideFooter: true,
                placement: 'left',
                spotlightClicks: true,
                styles: {options: {zIndex: 10000}},
            },
        ],
        start:false,
        help: false,
        name:"",
        phone:""
    };

    componentDidMount() {
        if(!this.props.steps.isFirstModal) setTimeout(() => this.setState({ run: true }), 1000)
    }

    sendToMail = () => {
        if(this.state.phone.length >= 7){
            myaxios.post(`/ajax/site/send-email`, {
                body: `
                Пользователь оставил заявку на внедрение CRM системы 
                Имя пользователя: ${this.state.name}
                Номер телефона пользователя: ${this.state.phone}
            `,
                subject:"Новая заявка из CRM системы",
                to:"techua.com@gmail.com"
            }).then(res => {
                this.props.setSteps("endStudy", false)
                Notif("save", "Заявка успешно отправлена! Мы свяжемся с Вами в ближайшее время!")
            })
        } else {
            Notif("Fields", "Телефон должен содержать минимум 7 символов")
        }
    }

    render () {
        const { steps } = this.state;

        return (
            <div className="app">
                {this.state.run && <div className="first_step">
                    <div className="support-modal">
                        <div className="support-modal__right">
                            <img src="https://sboxcrm.com/img/logo.png" alt="" />
                                <p>
                                    Благодарим вас за регистрацию. На 14 дней вам доступен полный функционал CRM. Ваши
                                    данные надежно защищены!
                                    {/*<a href="https://sboxcrm.com/docs" target="_blank">Поддержка</a>*/}
                                    <a href="https://sboxcrm.com/docs" target="_blank">Онлайн документация</a>
                                </p>
                        </div>
                        <div className="support-modal__left">
                            <h2>Добро пожаловать в SpaceBOX CRM</h2>
                            {/*<p>Ваша компания сможет полноценно работать CRM системой уже с первого дня, ведь все
                                необходимое для работы уже здесь. Мы сделали все возможное,
                                чтобы интерфейс был максимально понятен на интуитивном уровне. <br/><br/>

                                При возникновении вопросов обращайтесь в техническую поддержку.
                            </p>*/}
                            <p>
                                В SpaceBOX CRM есть много интересных функций для работы, автоматизации и увеличения прибыли в вашем бизнесе. Чтобы узнать про необходимые функции CRM мы подготовили для вас программу обучения.
                                Программа состоит из 4-х уровней:
                                <ul>
                                    <li>Основы CRM</li>
                                    <li>Продвинутые функции CRM</li>
                                    <li>CRM маркетинг</li>
                                    <li>Маркетинг на основе данных</li>
                                </ul>

                                <br />
                                В каждом уровне есть определенные задания, которые вам необходимо выполнить, после чего откроется следующий уровень.
                                После прохождения уровня, мы дарим вам определенное количество дней для работы в системе как бонус.<br /><br />
                                После прохождения полного обучения, вы узнаете про весь функционал нашей CRM и как правильно его использовать в вашем бизнесе.
                            </p>

                            <button onClick={() => {
                                this.props.setSteps("adminUserId", +this.props.userId || 1)
                                setTimeout(() => this.props.setSteps("isFirstModal", true), 500)
                                this.setState({ run: false })
                                setTimeout(() => this.props.setEducated(), 1000)
                            }}>Приступить к обучению</button>
                            {/*<button onClick={() => {
                                this.props.setSteps("adminUserId", +this.props.userId || 1)
                                setTimeout(() => this.props.setSteps("isFirstModal", true), 500)
                                this.setState({ run: false })
                            }} style={{background: "linear-gradient(45deg, #d37873 0%,#c96e6e 47%,#c16a6a 100%)"}}>Пропустить</button>*/}
                        </div>
                    </div>
                </div>}

                {this.props.steps.endStudy && <div className="first_step">
                    <div className="support-modal">
                        <div className="support-modal__right">
                            <img src="https://sboxcrm.com/img/logo.png" alt="" />
                            <p>
                                Благодарим вас за регистрацию. На 14 дней вам доступен полный функционал CRM. Ваши
                                данные надежно защищены!
                                <a href="https://sboxcrm.com/docs" target="_blank">Поддержка</a>
                                <a href="https://sboxcrm.com/docs" target="_blank">Онлайн документация</a>
                            </p>
                        </div>
                        <div className="support-modal__left">
                            {/*<h2>Теперь вы умеете создавать контакты.</h2>*/}
                            {!this.state.help ? <div>
                                <p style={{ marginBottom:"15px" }}>
                                    В SpaceBox есть еще очень много интересного функционала, о котором вам предстоит узнать. Чтобы сделать это быстрее, запишитесь на бесплатную демонстрацию CRM .
                                    Наш специалист изучит вашу деятельность, продемонстрирует необходимые вам функции, поможет настроить
                                    и при необходимости научит ими пользоваться.
                                    <br /><br />
                                    Такой подход поможет значительно сэкономить ваше время. Также вы можете самостоятельно изучить нашу <a target="_blank" href="https://sboxcrm.com/docs">документацию</a>.
                                    <br/><br/>Также мы можем помочь Вам подключить с внедрением CRM в вашу компанию. Чтобы получить помощь с внедрением системы,
                                    нажмите на кнопку ниже, введите номер телефона и наш сотрудник свяжется с вами в течении 15 минут.
                                </p>

                                <button onClick={() => this.setState({ help: true, run:false })}>Заказать бесплатную демонстрацию CRM</button>
                                <button onClick={() => {
                                    this.props.setSteps("endStudy", false)
                                }} style={{background: "linear-gradient(45deg, #d37873 0%,#c96e6e 47%,#c16a6a 100%)"}}>Завершить обучение</button>
                            </div> :
                            <div className="sbform">
                                <p style={{ marginBottom: "12px" }}>
                                    Спасибо за доверие! Вы стали на шаг ближе к запуску успешного CRM-маркетинга в вашей компании. Оставьте свои контактные данные и мы свяжемся с Вами в течении 15 минут.
                                </p>
                                <Input
                                    label="Ваше Имя"
                                    value={this.state.name}
                                    onChange={e => this.setState({ name: e.target.value })}
                                />
                                <Input
                                    style={{ marginBottom: "10px" }}
                                    label="Номер телефона"
                                    value={this.state.email}
                                    onChange={e => this.setState({ phone: e.target.value })}
                                />
                                <button onClick={this.sendToMail}>Отправить</button>
                                <button onClick={() => {
                                    this.props.setSteps("endStudy", false)
                                }} style={{background: "linear-gradient(45deg, #d37873 0%,#c96e6e 47%,#c16a6a 100%)"}}>Завершить</button>
                            </div>}
                        </div>
                    </div>
                </div>}
            </div>
        );
    }
}

export default JoyrideMain