import {entity} from "../../../components/ListComponentEntity";
import {NavLink} from "react-router-dom";
import React from "react";
import {NewList} from "../../../components/NewList/NewList";

export const ListPurchaseNew = (props) => {
    return (
        <NewList
            { ...props } stockNav
            entity={'stock/purchase'}
            entity_type={16}
            requiredFields={'id,name,status_id,cost'}
            requiredExpand={'status'}
            deleteToShowColumn={'id,name,status_id,cost'}
            additionalFieldsExists={true}
            isFilterExists={true}
            filterFields={entity.purchase.fArr}
            singleSearch={entity.purchase.fArr[0]}
            headingColumns={['Номер', 'Статус', 'Сумма']}
            RenderColumn={(item) => (
                <>
                    <td>
                        <NavLink to={`/stock/purchase/${item.id}`}  className={'nowrap-list-column'}>{item.name}</NavLink>
                    </td>
                    <td>
                        {item.status && <span className="label-st" style={{ backgroundColor: 'rgb(255, 193, 100)' }}>{item.status.value}</span>}
                    </td>
                    <td>
                        <div>К оплате: {item.cost}{item.currency && item.currency.text_symbol}</div>
                    </td>
                </>
            )}
            createButtonRender={(setModalType) => <button onClick={() => setModalType('purchase')} className="open-modal-button btni">Новая закупка</button>}
            rightNavbarConfig={{
                additionalFields: '/fieldsettings/16',
                columns: true
            }}
            massDoConfig={{ deleteAll: `/ajax/stock/purchase/delete-all` }}
            contextNav={{ edit: true, task: true, print: true, delete: true }}
            sort={entity.invoice.fArr.filter(el => el.sort)}
        />
    )
}