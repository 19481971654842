import React, {useEffect, useState} from "react";
import myaxios from "../../../components/Axios";
import {formatDate} from "../../../components/ListComponent";
import DateTime from "../../../components/UI/DateTime";
import Myselect from "../../../components/UI/Select";
import Loader from "../../../components/UI/Loader";

export const ShipmentReport = () => {
    const [loading, setLoading] = useState(true)
    const [stocksArray, setStocksArray] = useState([])
    const [list, setList] = useState([])
    const [sum, setSum] = useState('')
    const [values, setValues] = useState({
        startDate: formatDate(new Date()),
        endDate: formatDate(new Date()),
        stock_id: null,
    })

    const getData = async () => {
        setLoading(true)
        try {
            const { status, data } =
                await myaxios.get(`/ajax/stock/shipment/analytics?fields=id,name,payment_amount&start=${values.startDate}&end=${values.endDate}&expand=id,name,payment_amount`)
            if (status === 200) {
                setList(data.items)
                setSum(data.payment_amount)
            }
        } catch (e) { }
        setLoading(false)
    }

    const getStocks = async () => {
        const { status, data } = await myaxios.get(`/ajax/stock`)
        if(status === 200) {
            setStocksArray([...data.items])
            setValues({ ...values, stock_id: data.items[0].id })
        }
    }

    useEffect(() => {
        getStocks().then()
    }, [])

    useEffect(() => {
        if(values.stock_id) {
            getData().then()
        }
    }, [values])

    return (
        <div className={'page'}>
            {loading && <Loader />}
            <div className={'shipment-analytics-filter sbform'} style={{ display: 'flex' }}>
                <div style={{ width: '25%', padding: '0 0.5%' }}>
                    <DateTime
                        label={'Дата начала'} valid
                        value={values.startDate}
                        dateFormat="dd-MMMM-yyyy"
                        handleChange={e => setValues({ ...values, startDate: formatDate(e) })}
                    />
                </div>
                <div style={{ width: '25%', padding: '0 0.5%' }}>
                    <DateTime
                        label={'Дата завершения'} valid
                        value={values.endDate}
                        dateFormat="dd-MMMM-yyyy"
                        handleChange={e => setValues({ ...values, endDate: formatDate(e) })}
                    />
                </div>
                <div style={{ width: '25%', padding: '0 0.5%' }}>
                    <Myselect
                        label={'Склад'} val={'id'} lab={'name'}
                        options={stocksArray}
                        value={values.stock_id}
                        onChange={e => setValues({ ...values, stock_id: e.target.value })}
                    />
                </div>
                <div style={{ width: '25%', padding: '0 0.5%', marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                    <button className={'btni'}>Export</button>
                </div>
            </div>
            <div className={'second-table'}>
                <h2 style={{ marginTop: '20px' }}>Данные</h2>
                <table style={{ width: '500px' }}>
                    <tr>
                        <td>Сумма оплат</td>
                        <td>{sum}</td>
                    </tr>
                </table>
            </div>

            <div className={'second-table'}>
                <h2 style={{ marginTop: '20px' }}>Список</h2>
                <table style={{ width: '100%' }}>
                    <tr>
                        <th>ID</th>
                        <th>Название</th>
                        <th>Сумма</th>
                    </tr>
                    {
                        list.map((item) => (
                            <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td>{item.payment_amount}</td>
                            </tr>
                        ))
                    }
                </table>
            </div>
        </div>
    )
}