import {entity} from "../../ListComponentEntity";
import myaxios from "../../Axios";

const fieldType = (type, field) => {
    if(type === 'text') {
        return `&filter[or][][${field}][like]=test`
    } else if(type === 'number'){
        return `&filter[${field}][eq]=1`
    } else if(type === 'lookup' || type === 'entity' || type === 'tags') {
        return `&filter[${field}][in][]=1`
    } else if(type === 'date') {
        return `&filter[or][][${field}][like]=2022-02-11`
    } else return ''
}

const result = async (arr, entity) => {
    for (const asyncFn of arr) {
        const { status } = await myaxios.get(`/ajax/${entity}?fields=id,${asyncFn.value}${fieldType(asyncFn.fieldType, asyncFn.value)}`);
        if(status !== 200) {
            console.error(entity + ' ' + asyncFn.value + ' field filter error')
            return false
        }
    }
    return true
}

export async function ListTests() {
    const dataLead = await result(entity.lead.fArr, 'lead')
    if(!dataLead) {
        console.error('Lead list error')
        return false
    }

    const dataOpportunity = await result(entity.opportunity.fArr, 'opportunity')
    if(!dataOpportunity) {
        console.error('Opportunity list error')
        return false
    }

    const dataOrder = await result(entity.order.fArr, 'order')
    if(!dataOrder) {
        console.error('Order list error')
        return false
    }

    const dataTask = await result(entity.task.fArr, 'task')
    if(!dataTask) {
        console.error('task list error')
        return false
    }

    const dataAccount = await result(entity.account.fArr, 'account')
    if(!dataAccount) {
        console.error('account list error')
        return false
    }

    const dataContact = await result(entity.contact.fArr, 'contact')
    if(!dataContact) {
        console.error('contact list error')
        return false
    }

    const dataInvoice = await result(entity.invoice.fArr, 'invoice')
    if(!dataInvoice) {
        console.error('invoice list error')
        return false
    }

    const dataProject = await result(entity.project.fArr, 'project')
    if(!dataProject) {
        console.error('project list error')
        return false
    }

    const dataDocument = await result(entity.document.fArr, 'document')
    if(!dataDocument) {
        console.error('document list error')
        return false
    }

    const dataCases = await result(entity.cases.fArr, 'case')
    if(!dataCases) {
        console.error('cases list error')
        return false
    }

    const dataProduct = await result(entity.product.fArr, 'product')
    if(!dataProduct) {
        console.error('product list error')
        return false
    }

    const dataPurchase = await result(entity.purchase.fArr, 'stock/purchase')
    if(!dataPurchase) {
        console.error('purchase list error')
        return false
    }

    const dataReplanish = await result(entity.replanish.fArr, 'stock/replanish')
    if(!dataReplanish) {
        console.error('replanish list error')
        return false
    }

    const dataShipment = await result(entity.shipment.fArr, 'stock/shipment')
    if(!dataShipment) {
        console.error('shipment list error')
        return false
    }

    const dataTransfer = await result(entity.transfer.fArr, 'stock/transfer')
    if(!dataTransfer) {
        console.error('transfer list error')
        return false
    }

    const dataStockReturn = await result(entity.stockReturn.fArr, 'stock/provider-return')
    if(!dataStockReturn) {
        console.error('return list error')
        return false
    }

    const dataConsumption = await result(entity.consumption.fArr, 'stock/consumption')
    if(!dataConsumption) {
        console.error('consumption list error')
        return false
    }

    const dataStockTake = await result(entity.stocktake.fArr, 'stock/stocktake')
    if(!dataStockTake) {
        console.error('stocktake list error')
        return false
    }

    return true
}