import React, {useEffect, useState} from "react";
import Modal from "../../components/Modal/Modal";
import {GenerateForm} from "../../components/cardViewComponents/GenerateForm";
import {validationData} from "../../components/UI/formValid";
import {validationConfig} from "../../components/cardViewComponents/validationConfig";
import Notif from "../../components/Notif";
import {getOpportunityPrepareService} from "../../services/opportunity/opportunityService";
import {createAccountService} from "../../services/account/accountService";
import {createEvent} from "../../services/other/events";

const fieldsConfig = {
    name: {
        label: 'Название',
        type: 'text',
        className: 'w100',
        validation: { required: true, maxLength: 255 }, zone: 1
    },
    phone: {
        label: 'Номер телефона',
        type: 'text',
        className: 'w100',
        validation: { maxLength: 255 }, zone: 1
    },
    email: {
        label: 'Email',
        type: 'text',
        className: 'w100',
        validation: { email: true, maxLength: 255 }, zone: 1
    },
    website: {
        label: 'Сайт',
        type: 'text',
        className: 'w100',
        validation: { url: true, maxLength: 255 }, zone: 1
    },
    type_id: {
        label: 'Тип контрагента',
        type: 'entity',
        link: "/ajax/lookup/search?type=acc_type&value=",
        result: 'value',
        entity_from: 'type',
        entityName: 'value',
        className: 'w100',
        minLength: 0, zone: 1
    },
    owner_id: {
        label: 'Ответственный',
        type: 'entity',
        link: "/ajax/settings/user?filter[name][like]=",
        result: 'name',
        entity_from: 'owner',
        entityName: 'name',
        className: 'w100',
        minLength: 0, zone: 1
    },
}

export const AccountModal = ({ isOpen, close, history }) => {
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [disabled, setDisabled] = useState({})
    const [values, setValues] = useState({
        name: '',
        phone: '',
        email: '',
        website: '',
        type_id: null,
        type: null,
        owner_id: null,
        owner: null,
        tch: true
    })

    const saveItem = async () => {
        const errorsData = validationData(values, validationConfig(fieldsConfig));
        setErrors(errorsData)
        if(Object.keys(errorsData).length > 0) {
            Notif('required-fields')
            return false;
        }

        setLoading(true)
        const { name, phone, email, website, type_id, owner_id } = values
        const { status, data } = await createAccountService({data: { name, phone, email, website, type_id, owner_id }})
        if((status === 200 || status === 201) && data.id) {
            createEvent({ user_id: window.location.host, user_name: "---", event_type: "createAccount" }).then();
            history.push(`/account/${data.id}`)
        }
        setLoading(false)
    }

    const changeFields = (key, value, item) => {
        let obj = {}
        if(item) {
            if(key === 'name' || key === 'phone' || key === 'email' || key === 'website') {
                console.log('item', item)
            } else {
                if(item === 'clear') obj[fieldsConfig[key].entity_from] = null
                else obj[fieldsConfig[key].entity_from] = item
            }
        }

        setValues({ ...values, ...obj, [key]: item ? item.id : value, tch: false })
    }

    return (
        <Modal isOpen={isOpen} save={saveItem} close={close} loading={loading} showLeaveModal tch={values.tch} title={'Новый контрагент'}>
            <GenerateForm
                config={fieldsConfig}
                { ...{ values, errors, disabled } }
                onChange={changeFields}
                onSaveData={saveItem}
            />
        </Modal>
    )
}