import React, {Component} from 'react';
import Textarea from '../../UI/Textarea';
import Input from '../../UI/Input';
import ModAutoSelect from '../../UI/ModAutoSelect';
import formValid from '../../UI/formValid';
import Myselect from '../../UI/Select';
import myaxios from '../../Axios';



export default class AddNotification extends Component {
    state = {
        formControl: {
            time_value:{value:0},
            name:{value:'',valid:true, validation:{required:true}},
            //icon:{value:'',valid:true, validation:{required:true}},
            owner:{value:'owner_id',arr:[],valid:true,validation:{required:true}},
            //title:{value:'',valid:true, validation:{required:true}},
            body:{value:'',valid:true, validation:{required:true}},
        },
        is_scheduled:false,
        conditions: [],
        id:null
    };
    getInfo = () =>{
        myaxios.get('/ajax/task/prepare').then(res => {
            const formControl = this.state.formControl;
            let owner = res.data.users;
            owner.unshift({id:"created_by", name:"Создавший"});
            owner.unshift({id:"owner_id", name:"Текущий ответственный"});
            formControl.owner.arr = owner;
            this.setState({ formControl })
        })
    }
    componentDidMount(){
        this.getInfo();
    }
    componentWillMount(){
        if(this.props.elArr){
            let formControl = this.state.formControl;
            formControl.name.value = this.props.elArr.metaData ? this.props.elArr.name : '';
            //formControl.icon.value = this.props.elArr.metaData ? this.props.elArr.metaData.icon : '';
            formControl.owner.value = this.props.elArr.metaData ? this.props.elArr.metaData.owner : '';
            formControl.time_value.value = this.props.elArr.metaData ? this.props.elArr.time_value : '';
            formControl.body.value = this.props.elArr.metaData ? this.props.elArr.metaData.body : '';
            this.setState({ formControl, id: this.props.elArr.id || null })
        }
    }
    setField = (type, e) => {
        let formControl = this.state.formControl;
        formControl[type].value = e;
        this.setState({ formControl })
    };
    isFormValid = () => {
        let formControl = this.state.formControl;
        let isFormValid = true;
        Object.keys(formControl).map((key, index) => {
            formControl[key].valid = formValid(formControl[key].value, formControl[key].validation);
            isFormValid = formControl[key].valid && isFormValid
        });
        this.setState({formControl});
        return isFormValid;
    };
    sendForm = () => {
        if(this.isFormValid()){
            if(this.props.elArr){
                setTimeout(
                    this.props.updateNode({
                        id:this.state.id,
                        name: this.state.formControl.name.value,
                        time_value: this.state.formControl.time_value.value,
                        is_scheduled: (this.state.formControl.time_value.value > 1),
                        metaData: {
                            //icon: this.state.formControl.icon.value,
                            //title: this.state.formControl.title.value,
                            body: this.state.formControl.body.value,
                            owner: this.state.formControl.owner.value,
                            owner_type: (this.state.formControl.owner.value === "created_by" || this.state.formControl.owner.value === "owner_id") ? 'reference' : 'user'
                        },
                        nodeType: "node",
                        type:"notification",
                    })
                )
            } else {
                setTimeout(
                    this.props.createNode({
                        name: this.state.formControl.name.value,
                        time_value: this.state.formControl.time_value.value,
                        is_scheduled: (this.state.formControl.time_value.value > 1),
                        metaData: {
                            //icon: this.state.formControl.icon.value,
                            //title: this.state.formControl.title.value,
                            body: this.state.formControl.body.value,
                            owner: this.state.formControl.owner.value,
                            owner_type: (this.state.formControl.owner.value === "created_by" || this.state.formControl.owner.value === "owner_id") ? 'reference' : 'user'
                        },
                        nodeType: "node",
                        type:"notification",
                    })
                )
            }
        }
    };
    updateData = (id, arr, el, type) => {
        let formControl = this.state.formControl;
        if(type === "add"){
            formControl[el].arr = arr;
            formControl[el].value = id;
        } else {
            formControl[el].arr = null;
            formControl[el].value = '';
        }
        this.setState({ formControl })
    }
    render(){
        return <div>
            <div className="rmod-header">
                <b>Уведомление</b>
                <a onClick={this.props.close} style={{ marginRight: "60px" }} className="mclose"><i className="flaticon-close" /></a>
                <div className="togglelist"><button onClick={this.sendForm} className="rmod-ok" disabled=""><i className="flaticon-interface-1" /></button></div>
            </div>
            <div className="mod-cont sbform">
                <Input
                    label={"Название действия"}
                    value={this.state.formControl.name.value}
                    valid={this.state.formControl.name.valid}
                    onChange={e => this.setField("name", e.target.value)}
                    shouldValidate={true}
                />
                {/*<Input
                    value={this.state.formControl.title.value}
                    valid={this.state.formControl.title.valid}
                    onChange={e => this.setField("title", e.target.value)}
                    shouldValidate={true}
                    label="Название"
                />*/}
                <Input
                    value={this.state.formControl.body.value}
                    valid={this.state.formControl.body.valid}
                    onChange={e => this.setField("body", e.target.value)}
                    shouldValidate={true}
                    label="Описание"
                />
                {/*<Input
                    value={this.state.formControl.icon.value}
                    valid={this.state.formControl.icon.valid}
                    onChange={e => this.setField("icon", e.target.value)}
                    shouldValidate={true}
                    label="Иконка"
                />*/}
                <Myselect
                    label="Ответственный"
                    options={this.state.formControl.owner.arr}
                    value={this.state.formControl.owner.value}
                    valid={this.state.formControl.owner.valid}
                    shouldValidate={true}
                    onChange={e => this.setField("owner", e.target.value)}
                    lab="name"
                    val="id"
                />
                <Input
                    tooltip="Процесс запустится через N секунд, после того, как сработает триггер"
                    type="number"
                    value={this.state.formControl.time_value.value}
                    onChange={e => this.setField("time_value", e.target.value)}
                    shouldValidate={false}
                    label="Отложенный запуск на N секунд"
                />
            </div>
        </div>
    }
}