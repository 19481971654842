import React, {Component} from 'react';
import Input from '../UI/Input';
import ModAutoSelect from '../UI/ModAutoSelect';
import DateTime from '../UI/DateTime';
import myaxios from '../Axios';
import entityFields from './actions/entityFieldsArr';

// {value: "created_by", label: "Кем создан", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0"},
// {value: "owner_id", label: "Ответственный", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0"},
function sort(arr, value) {
    let a = true;
    arr.map(item => {
        if(item.value == value){
            a = false;
        }
    });
    return a;
}
function AditionalFilter(type, arr, additional) {
    if(type === "filter"){
        let prms = arr;
        for(let i=0; i<additional.length; i++){
            if(sort(arr, additional[i].field_id)){
                prms.push({
                    value: additional[i].field_id,
                    label: additional[i].label,
                    type: (additional[i].type === "multiselect") ? "multiselect" : (additional[i].type === "select") ? "select" : (additional[i].type === "date" || additional[i].type === "dateTime") ? 'date' : "text",
                    filterSearch:'name',
                    arr:additional[i].options,
                    link:"",
                    filterLength:"9999999999"
                })
            }
        }
        return prms
    }
}

export default class FilterBpm extends Component {
    state = {
        fields: [
            {value:'id',label:"ID",type:'number',link:'',arr:[], filterName:'', minLength:"99999",edit:false,object:''},
            {value:'name',label:"ФИО",type:'text',link:'',arr:[], filterSearch:'name', minLength:"99999",edit:false,object:''},
            {value:'position',label:"Должность",type:'text',link:'',arr:[], filterSearch:'name', minLength:"99999",edit:true,object:''},
            {value:'phone',label:"Телефон",type:'number',link:'',arr:[], filterSearch:'value', minLength:"99999",edit:true,object:''},
            {value:'email',label:"Email",type:'text',link:'',arr:[], filterSearch:'name', minLength:"99999",edit:true,object:''},
            {value:'description',label:"Описание",type:'text',link:'',arr:[], filterSearch:'name', minLength:"99999",edit:true,object:''},
            {value:'type_id',label:"Тип контакта",type:'multy',link:'/ajax/lookup/search?type=ct_type&value=',arr:[], filterSearch:'value', minLength:"0",edit:true,object:'lookup'},
            {value:'account_id',label:"Контрагент",type:'multy',link:'/ajax/account?filter[name][like]=',arr:[], filterSearch:'name', minLength:"1",edit:false,object:'entity'},
            {value:'tags',label:"Теги",type:'multy',link:'/ajax/contact/suggest?term=',arr:[], filterSearch:'name', minLength:"0",edit:false,object:'entity'},
            {value:'created_at',label:"Дата создания",type:'date',link:'',arr:[], filterSearch:'value', minLength:"99999",edit:false,object:''},
            {value:'updated_at',label:"Дата изменения",type:'date',link:'',arr:[], filterSearch:'value', minLength:"99999",edit:false,object:''},
            {value:'created_by',label:"Кем создан",type:'multy',link:'/ajax/settings/user?filter[name][like]=',arr:[], filterSearch:'name', minLength:"0",edit:false,object:'entity'},
            {value:'owner_id',label:"Ответственный",type:'multy',link:'/ajax/settings/user?filter[name][like]=',arr:[], filterSearch:'name', minLength:"0",edit:true,object:'entity'},
        ],
        filter: [
            {field: '', type:'', link:'', operator:'==', obj:null, value:''},
            {field: '', type:'', link:'', operator:'!==', obj:null, value:''},
        ],
        condition:{
            value:'stage',
            time:'0'
        },
        multy: [
            {value:"==", label:"Равно"},
            {value:"notEqual", label:"Не равно"},
            {value:"end", label:"И"},
            {value:"or", label:"Или"},
        ],
        operators: {
            text: [{value:"==", label:"Равно"}, {value:"!=", label:"Не равно"}, {value:"match", label:"Содержит"}, {value:"nmatch", label:"Не содержит"}],
            multy: [{value:"==", label:"Равно"}, {value:"!=", label:"Не равно"}, {value:"match", label:"Содержит"}, {value:"nmatch", label:"Не содержит"}],
            select: [{value:"==", label:"Равно"}, {value:"!=", label:"Не равно"}, {value:"match", label:"Содержит"}, {value:"nmatch", label:"Не содержит"}],
            multiselect: [{value:"==", label:"Равно"}, {value:"!=", label:"Не равно"}, {value:"match", label:"Содержит"}, {value:"nin", label:"Не содержит"}],
            number: [{value:"==", label:"Равно"}, {value:"!=", label:"Не равно"}, {value:">", label:"Больше"}, {value:"<", label:"Меньше"}],
            date: [{value:"==", label:"Равно"}, {value:"!=", label:"Не равно"}, {value:">", label:"Позже"}, {value:"<", label:"Раньше"}],
        }
    };
    getAdditionalParams = () => {
        myaxios.get(`/ajax/eav/attribute?entity_type=${this.props.entityId}`).then(response => {
            this.setState({ fields: AditionalFilter("filter", this.state.fields, response.data.items) })
            //this.setState({ additionalParams: response.data, selectOptions: AditionalFilter("filter", filter, response.data.items) })
        });
    };
    searchValue = (val, type) => {
        for(let i = 0; i < this.state.fields.length; i++){
            if(this.state.fields[i]){
                if(this.state.fields[i].value === val){
                    return this.state.fields[i][type]
                }
            }
        }
    }
    componentWillMount(){
        this.getAdditionalParams();
        this.setState({ filter: this.props.filter || [], fields: entityFields[this.props.entity] });
    }
    componentDidUpdate(prevProps, prevState){
        if(prevState !== this.state){
            setTimeout(() => this.props.updFilter(this.state.filter))
        }
    }
    setFilter = (index,type,value, t) => {
        const filter = this.state.filter;
        if(type === "field"){
            filter[index]["type"] = t.target[t.target.selectedIndex].getAttribute('data-sel');
            filter[index]["link"] = t.target[t.target.selectedIndex].getAttribute('data-link');
            filter[index]["object"] = t.target[t.target.selectedIndex].getAttribute('data-object');
            filter[index].value = "";
            filter[index].obj = null;
        }
        if(value == "editorTags"){
            filter[index].operator = "match";
        }
        filter[index][type] = value;
        this.setState({ filter })
    };
    delItem = index => {
        const filter = this.state.filter;
        filter.splice(index, 1);
        this.setState({ filter })
    };
    addItem = () => {
        const filter = this.state.filter;
        filter.push({field: '', type:'', operator:'==', obj:null, value:''});
        this.setState({ filter })
    };
    updData = (index,id, arr, type) => {
        const filter = this.state.filter;
        filter[index].obj = arr;
        if(type === "tags"){
            filter[index].value = arr.name;
        } else {
            filter[index].value = id;
        }

        this.setState({ filter })
    };
    unLink = (index) => {
        const filter = this.state.filter;
        filter[index].obj = null;
        filter[index].value = '';
        this.setState({ filter })
    }
    render(){
        return <div className="sbform">
            <div className="connection" style={{marginTop: "10px"}}><span>Фильтр</span>
                <div className="toggleparrent"><button onClick={this.addItem} className="btni">+</button></div></div>
            <table style={{width:"100%"}} className="filterBpm">
                <thead>
                    <tr>
                        <td style={{padding:"0px 5px"}}>Поле</td>
                        <td style={{padding:"0px 5px"}}>Оператор</td>
                        <td style={{padding:"0px 5px"}}>Значение</td>
                        {this.state.filter.length > 1 ? <td /> : null}
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.filter.map((item, index) =>
                            <FilterItem
                                setFilter={this.setFilter}
                                delItem={this.delItem}
                                updData={this.updData}
                                searchValue={this.searchValue}
                                unLink={this.unLink}
                                handleOption={this.handleOption}
                                key={index}
                                ind={index}
                                fields={this.state.fields}
                                filter={this.state.filter}
                                operators={this.state.operators}
                            />
                        )
                    }
                </tbody>
            </table>
        </div>
    }
}

const FilterItem = props => {
    return <tr>
            <td>
            <Myselect
                onChange={e => {
                    props.setFilter(props.ind, "field", e.target.value, e);
                    props.setFilter(props.ind, "operator", e.target.value == "editorTags" ? "match" : "==")
                }}
                empty={props.filter[props.ind].type.length === 0}
                value={props.filter[props.ind].field}
                options={props.fields} />
            </td>
            <td style={{ width:"100px" }}>
                <Myselect
                    disabled={ props.filter[props.ind].field.length < 1 }
                    onChange={e => props.setFilter(props.ind, "operator", e.target.value)}
                    value={props.filter[props.ind].operator}
                    options={props.filter[props.ind].type.length > 1 ? (props.filter[props.ind].object == "tags") ? [{value:"match", label:"Содержит"}] : props.operators[props.filter[props.ind].type] : props.operators.text}
                /></td>
            <td>
                {(props.filter[props.ind].type === "text") ?
                    <Input
                        disabled={ props.filter[props.ind].field.length < 1 }
                        onChange={e => props.setFilter(props.ind, "value", e.target.value)}
                        value={props.filter[props.ind].value}
                        shouldValidate={true}
                        valid={props.filter[props.ind].value.length >= 1}
                    />
                : null}
                {(props.filter[props.ind].type === "number") ?
                    <Input
                        type="number"
                        disabled={ props.filter[props.ind].field.length < 1 }
                        onChange={e => props.setFilter(props.ind, "value", e.target.value)}
                        value={props.filter[props.ind].value}
                        shouldValidate={true}
                        valid={props.filter[props.ind].value.length >= 1}
                    />
                    : null}
                {(props.filter[props.ind].type === "date") ?
                    <DateTime
                        value={props.filter[props.ind].value}
                        valid={props.filter[props.ind].value ? String(props.filter[props.ind].value).length > 4 : false}
                        isClearable={true}
                        handleChange={e => props.setFilter(props.ind, "value", formatDate(e))}
                        dateFormat="dd-MMMM-yyyy"
                        todayButton
                    />
                    : null}
                {(props.filter[props.ind].type === "multy") ?
                    <ModAutoSelect
                        link={props.searchValue(props.filter[props.ind].field, "link")}
                        minLength={props.searchValue(props.filter[props.ind].field, "minLength")}
                        result={props.searchValue(props.filter[props.ind].field, "filterSearch")}
                        event="status"
                        valid={(props.filter[props.ind].obj)}
                        shouldValidate={false}
                        addType="status"
                        entity={props.filter[props.ind].obj}
                        entityName={props.searchValue(props.filter[props.ind].field, "filterSearch")}
                        updateData={(id, arr, par2) => props.updData(props.ind,id, arr, props.filter[props.ind].object)}
                        unLink={() => props.unLink(props.ind,'', null)}
                    />
                    : null}
                {(props.filter[props.ind].type === "multiselect" || props.filter[props.ind].type === "select") ?
                    <Myselect
                        empty
                        value={props.filter[props.ind].value}
                        options={props.searchValue(props.filter[props.ind].field, "arr")}
                        //valid={props.filter[props.ind].value ? String(props.filter[props.ind].value).length > 4 : false}
                        val="id"
                        onChange ={e => props.setFilter(props.ind, "value", e.target.value)}
                    />
                    : null}

            </td>
            {props.filter.length > 0 ? <td style={{ width:"15px" }}>
                <a onClick={() => props.delItem(props.ind)} style={{ fontSize:"19px", color:"#c2263f", display:"block", textAlign:"center" }}>x</a>
            </td> : null}
        </tr>
};

function isInvalid({valid, shouldValidate, isFormValid}) {
    return !valid && shouldValidate && !isFormValid
}

const Myselect = props => {
    const vl = props.value || "";
    let val = props.val ? props.val : "value";
    return <div className={props.className}>
        <select value={vl} onChange={props.onChange} style={props.style} disabled={props.disabled}>
            {(props.empty) ? <option value={``} /> : null }
            {
                props.options ? props.options.map((i, index)=>
                    <option key={index} data-link={i.link} data-object={i.object} data-sel={i.type} value={i[val]}>{i.label}</option>
                ) : null
            }
        </select>
        {
            (isInvalid(props)) ? <span className="errorMsg">{(props.errorMessage) || "Заполните поле"}</span> : null
        }
    </div>
};

function formatDate(date){
    if(date !== null) {
        let dd = date.getDate();
        if (dd < 10) dd = '0' + dd;
        let mm = date.getMonth() + 1;
        if (mm < 10) mm = '0' + mm;
        let yy = date.getFullYear() % 10000;
        if (yy < 10) yy = '0' + yy;
        return yy + '-' + mm + '-' + dd;
    }
};