import React, {useEffect, useState} from "react";
import Loader from "../../components/UI/Loader";
import Tags from "../../components/Tags";
import {
    FieldGenZone,
    filesFunc,
    isFormValid,
    setField,
    unLink,
    updateData,
    getData,
    postDt
} from "../../components/fieldGen";
import myaxios from "../../components/Axios";
import Notif from "../../components/Notif";
import ClientCard from "../../components/ClientCard";
import {fieldConfig} from './fieldConfig'
import {taskEdit, Tasks} from "../../components/Task";
import History from "../../components/history/history";
import Files from "../../components/files/files";
import EditTags from "../../Modal/EditTags";
import Bgdrop from "../../Modal/Bgdrop";
import {CSSTransition} from "react-transition-group";
import LeaveModal from "../../components/other/LeaveModal";

const ExpenseView = props => {
    const [state, setMyState] = useState({
        formControl: fieldConfig,
        layout: "information",
        files:[],
        editorTags:'',
        loading:true,
        tch:true,
        valid:true,
        open:null,
        openType:'',
        taskId:'',
        taskIndex:'',
    })

    const setState = (st, func) => {
        Object.keys(st).map((key) => { state[key] = st[key]});
        setMyState(prevState => { return {...prevState, ...state} });
        if(func) func();
    };

    const getDt = () => {
        myaxios.get(`/ajax/expense/${props.match.params.id}?expand=currency,category,owner,lead,opportunity,order,project,case,contact,account,files`).then(res => {
            if(!res) return Notif("Error");
            if(res.status === 200 || res.status === 201){
                let formControl = getData(state.formControl, res);
                setState({ formControl, loading: false, files: res.data.files, editorTags: res.data.editorTags })
            }
        })
    }

    const getPrepare = () => {
        myaxios.get(`/ajax/expense/prepare`).then(res => {
            if(!res) return Notif("Error")
            const formControl = state.formControl;
            formControl.name.value = "Расход #" + res.data.nextId;
            formControl.payment_method_id.options = res.data.payment_methods;
            formControl.payment_method_id.value = res.data.payment_methods[0] && res.data.payment_methods[0].id;
            formControl.status.options = res.data.statuses;
            formControl.status.value = res.data.statuses[0] && res.data.statuses[0].key;
            setState({ formControl })
        })
    }

    useEffect(() => {
        getPrepare()
        getDt()
    }, [props.match.params.id])

    const postData = () => {
        if(!state.valid) return

        setState({ tch: true, loading: true })
        let obj = postDt(state.formControl);
        obj.editorTags = state.editorTags;

        myaxios.put(`/ajax/expense/${props.match.params.id}`, obj).then(res => {
            if(!res) return Notif("Error");
            if(res.status === 200 || res.status === 201){
                Notif("save")
                setState({ loading: false, tch: true })
            }
        }).catch(error => setState({ loading: false }))
    }

    const delitem = () => {
        setState({ loading: true })
        if(window.confirm("Удалить обьект?")) myaxios.delete(`/ajax/expense/${props.match.params.id}`).then(res => {
            if(res.status === 200 || res.status === 201 || res.status === 204) props.history.push(`/expense`)
        }).catch(() => setState({ loading: false }))
    }

    return (
        <div className="page">
            {state.loading && <Loader contain />}
            <CSSTransition in={!state.loading} timeout={200} classNames="slide" unmountOnExit>
                <div>
            <LeaveModal when={!state.tch} />
            <div className="butt-group butt-group-card">
                <Tags editorTags={state.editorTags} setState={setState} />
                <button onClick={() => setState(isFormValid(state), postData())} disabled={state.tch} className="butt-group-card-wrap success fl-r btni ml10">
                    <span>Сохранить</span>
                    <i className="flaticon-interface-1 mdb" />
                </button>
                <button onClick={delitem} className="butt-group-card-wrap fl-r btni ml10">
                    <span>Удалить</span>
                    <i className="flaticon-delete-2 mdb" />
                </button>
            </div>
            <div className="clear" />
            <div className="contact-page">
                <div className="contact-left">
                    <div className="contact-left-col">
                        <h3>Данные расхода</h3>
                        <FieldGenZone
                            zone={1}
                            arr={state.formControl}
                            setField={(e, type) => setState({formControl: setField(state.formControl, type, e), tch: false})}
                            updateData={(id, arr, type) => {
                                setState({ formControl: updateData(state.formControl,id, arr, type), tch: false })
                            }}
                            unLink={(type) => setState({ formControl: unLink(state.formControl, type), tch: false})}
                        />
                        <div className="clear" />
                    </div>
                    <ClientCard
                        formControl={state.formControl}
                        updateDataData={(id, arr, type) => setState({ formControl: updateData(state.formControl,id, arr, type), tch: false })}
                        unLink={(type) => setState({ formControl: unLink(state.formControl, type), tch: false})}
                        updateDataContact={props.updateData}
                        contact={!state.formControl.account_id.entity}
                        account={!state.formControl.contact_id.entity}
                        {...props}
                    />
                </div>
                <div className="contact-right-col">
                    <div className="tabs-header">
                        <ul>
                            <li><a className={(state.layout === "information") ? "active" : null} onClick={() => setState({ layout: 'information' })}>Основная информация</a></li>
                            <li><a className={(state.layout === "history") ? "active" : null} onClick={() => setState({ layout: 'history' })}>Хронология</a></li>
                            <li><a className={(state.layout === "files") ? "active" : null} onClick={() => setState({ layout: 'files' })}>Файлы</a></li>
                        </ul>
                    </div>
                    <div className="tab-container">
                        {state.layout === "information" && <div className="tab-content formlabel">
                            <FieldGenZone
                                zone={2}
                                arr={state.formControl}
                                setField={(e, type) => setState({formControl: setField(state.formControl, type, e), tch: false})}
                                updateData={(id, arr, type) => setState({ formControl: updateData(state.formControl,id, arr, type), tch: false })}
                                unLink={(type) => setState({ formControl: unLink(state.formControl, type), tch: false})}
                            />
                        </div>}
                        {(state.layout === "history") && <History type="expense" id={props.match.params.id} />}
                        {(state.layout === "files") && <Files
                            files={state.files}
                            filesFunc={(type, index, arr) => setState({files: filesFunc(type, index, arr, state.files)})}
                            relatedType="31" related_id={props.match.params.id}
                        />}
                    </div>
                </div>
            </div>
            <React.Fragment>
                <div className={"right-modal scrollbar " + state.open}><div className="save">
                    {(state.open === "r-open")
                        ? <EditTags
                            close={() => setState({ open:"" })}
                            tags={state.editorTags}
                            type="expense"
                            id={props.match.params.id}
                            refresh={() => {}}
                            refreshState={(tags) => {setState({ editorTags: tags })} }
                        /> : null}
                </div></div>
                {state.open ? <Bgdrop close={() => setState({ open:"" })} /> : null}
            </React.Fragment>
                </div>
            </CSSTransition>
        </div>
    )
}

export default ExpenseView