import myaxios from "../../components/Axios";

export const getProductCategoryService = () => myaxios.get(`/ajax/lookup/search?type=category&value=`);
export const getProductPrepareService = () => myaxios.get(`/ajax/product/prepare`);
export const getSingleProductService = (id) => myaxios.get(`/ajax/product/${id}?expand=provider,manufacturer,category,additionalFields,files`);
export const createProductService = (data) => myaxios.post(`/ajax/product`, data);
export const deleteProductService = id => myaxios.delete(`/ajax/product/${id}`);
export const updateProductService = (id, data) => myaxios.put(`/ajax/product/${id}`, data);
export const getProductListService = (sort = "", expand = "", filters = "") => myaxios.get(`/ajax/product/${sort}${expand}${filters}`)

// Price books CRUD
export const getPriceBookService = (query = "") => myaxios.get(`/ajax/pricebook?filter[name][like]=${query}`)
export const createPriceBookService = (data) => myaxios.post(`/ajax/pricebook`, data)
export const updatePriceBookService = ({ id, data }) => myaxios.put(`/ajax/pricebook/${id}`, data)
export const deletePriceBookService = (id) => myaxios.delete(`/ajax/pricebook/${id}`)

export const getlinkedPriceBooks = ({ product_id }) => myaxios.get(`/ajax/product/${product_id}/prices`)
export const linkPriceBookToProductService = (id, price_book_id, data) => myaxios.post(`/ajax/product/${id}/add-price-book/${price_book_id}`, data)
export const updatePriceBookToProductService = (id, price_book_id, data) => myaxios.put(`/ajax/product/${id}/update-price/${price_book_id}`, data)
export const unlinkPriceBookToProductService = (id, price_book_id) => myaxios.post(`/ajax/pricebook/${price_book_id}/unlink-product/${id}`)

export const linkProductService = ({ entity, entityId, productId }) => myaxios.post(`/ajax/${entity}/${entityId}/link-product`, { id: productId });
export const editLinkedProductService = ({ entity, entityId, productId, data  }) => myaxios.post(`/ajax/${entity}/${entityId}/edit-product-item/${productId}`, data)
export const unlinkProductService = ({ entity, entityId, productId }) => myaxios.post(`/ajax/${entity}/${entityId}/unlink-product`, { id: productId })
export const getAllLinkedProductsService = ({ entity, entityId }) => myaxios.get(`/ajax/${entity}/${entityId}/products`)