import React, {useEffect, useState} from 'react'
import myaxios from '../../components/Axios';
import Notif from '../../components/Notif';
import {withRouter, Prompt} from 'react-router-dom';
import ScrollContainer from 'react-indiana-drag-scroll';
import Board, { moveCard } from "@lourenci/react-kanban";
import "@lourenci/react-kanban/dist/styles.css";
import Loader from "../../components/UI/Loader";
import {CSSTransition} from "react-transition-group";
import {operator,FilterItem} from '../../components/ListComponent'
import {entity} from "../../components/ListComponentEntity";
import Bgdrop from "../../Modal/Bgdrop";
import localstorageSettings from "../../components/localstorageSettings";
import KanbanFastAdd from "../../components/other/KanbanFastAdd";

function searchIndex(arr, id, par){
    for(let i = 0; i < arr.length; i++){
        if(arr[i]){
            if(par){
                if(arr[i].id == id){
                    return i
                }
            } else {
                if(arr[i].value == id){
                    return i
                }
            }
        }
    }
}

const KanbanBoard = ({ updateData, history, Item, Header, name, userId, fastAddConfig, addButton, withStatusValue, list }) => {
    const [board, setBoard] = useState({
        columns: []
    })
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(10);
    const [itemsCount, setItemsCount] = useState(0)
    const [state, setMyState] = useState({
        adField:[],
        filterId:null,
        extraData:[],
        filter:[],
        filterRequest:[],
        open:'',
        openType:'',
        taskTypePeriod:false
    })

    const setState = (st, func) => {
        Object.keys(st).map((key) => { state[key] = st[key]});
        setMyState(prevState => { return {...prevState, ...state} });
        if(func) func();
    };

    const taskStatusColor = (id) => {
        switch (id) {
            case "1": return "#bbbbbb";
            case "2": return "#c2263f";
            case "3": return "#62a091";
            case "4": return "rgb(255, 193, 100)";
            case "5": return "rgb(239, 111, 0)";
        }
    }

    const getItems = () => {
        setLoading(true)
        const newBoard = {columns:[]}
        myaxios.get(`/ajax/${name}/kanban${state.taskTypePeriod ? "-by-periods" : ""}?per-page=${page}&expand=owner,owners` + convertFilter()).then(res => {
            let items = res.data.items.map(({ items, status, title, id, pagination }) => (
                {
                    id: state.taskTypePeriod ? id : status.id,
                    title: state.taskTypePeriod ? title : status.value,
                    color: state.taskTypePeriod ? taskStatusColor(id) : status.color,
                    cards: items,
                    order: state.taskTypePeriod ? id : status.order,
                    pagination: pagination
                }
            ))
            items.sort(function(a, b) {
                return a.order - b.order;
            });
            items.map(item => {
                if(itemsCount < item.pagination._meta.totalCount){
                    setItemsCount(item.pagination._meta.totalCount);
                }
            })
            newBoard.columns = items
            setBoard({...newBoard})
            setTimeout(() => setLoading(false), 500)
            setTimeout(() => window.scrollTo(0,document.body.scrollHeight), 500);
        })
    }

    useEffect(() => {
        localstorageSettings("post", "taskView", "kanban");
        getAdditionalParams()
    }, [])

    const toDate = (id, start_filter = "00:00:00", end_filter = "00:00:00") => {
        let nowDate = new Date();
        let useDate, result;
        useDate = new Date(nowDate.getTime() + (86400000 * Number(id)))
        result = `${useDate.getFullYear() % 10000}-${useDate.getMonth() + 1}-${useDate.getDate()} ${start_filter}`
        return result
    }

    const onDrag = (a, item, position, toPosition) => {
        if(toPosition.toColumnId === "2"){
            setTimeout(getItems, 1000)
        }

        if(state.taskTypePeriod){
            const switchDate = (st) => {
                switch (String(st)) {
                    case "1": return {start_date: null, end_date: null};
                    case "3": return {start_date: toDate(0), end_date: toDate(1)};
                    case "4": return {start_date: toDate(1), end_date: toDate(2)};
                    case "5": return {start_date: toDate(2), end_date: toDate(3)};
                }
            }
            item.drug = true;
            myaxios.put(`/ajax/${name}/${item.id}`, {
                [withStatusValue]: item[withStatusValue],
                status_id: state.taskTypePeriod ? item.status_id :  toPosition.toColumnId,
                ...switchDate(toPosition.toColumnId)
                //toDate(toPosition.toColumnId)
            }).then(res => {
                if(!res) return
                if(res.status === 200){
                    Notif("save")
                } else {
                    setLoading(true)
                    setTimeout(getItems, 1000)
                }
            })

            return;
        }
        
        item.drug = true;
        item.status_id = toPosition.toColumnId
        const newBoard = JSON.parse(JSON.stringify(board))
        let elIndex = newBoard.columns[newBoard.columns.findIndex(el => el.id === position.fromColumnId)].cards.findIndex(el => el.id === item.id)
        item.status = {
            color: a.columns[a.columns.findIndex(el => el.id === toPosition.toColumnId)].color,
            value: a.columns[a.columns.findIndex(el => el.id === toPosition.toColumnId)].title,
            name: a.columns[a.columns.findIndex(el => el.id === toPosition.toColumnId)].title,
        }

        newBoard.columns[newBoard.columns.findIndex(el => el.id === position.fromColumnId)].cards.splice(elIndex, 1)
        newBoard.columns[newBoard.columns.findIndex(el => el.id === toPosition.toColumnId)].cards.unshift(item)
        setBoard({...newBoard})

        myaxios.put(`/ajax/${name}/${item.id}`, {
            [withStatusValue]: item[withStatusValue],
            status_id: toPosition.toColumnId
        }).then(res => {
            if(!res) return
            if(res.status === 200){
                Notif("save")
            } else {
                setLoading(true)
                setTimeout(getItems, 1000)
            }
        })
    }

    const getFilter = () => {
        myaxios.get(`/ajax/filter?filter[entity_type]=${entity[name].entityNumb}&[user_id]=${userId}`).then(res => {
            if(res.data.items[0]){
                setState({
                    filterRequest: res.data.items[0].filterAttributes || [],
                    filter: JSON.parse(JSON.stringify(res.data.items[0].filterAttributes)) || [],
                    extraData: res.data.items[0].columns || [],
                    filterId: res.data.items[0].id
                }, getItems)
            } else {
                getItems()
            }
        })
    }

    const convertAddFields = (items) => {
        let arr = [];
        items.map(item => {
            arr.push({
                value: item.field_id,
                label: item.label,
                fieldType: (item.type === "select" || item.type === "multiselect") ? 'select' : (item.type === "date") ? 'date' : 'text',
                options: item.options
            })
        });
        let arr2 = entity[name].fArr
        arr2 = arr2.concat(arr);
        return arr2;
    }

    const getAdditionalParams = () => {
        if(entity[name].adField){
            myaxios.get(`/ajax/eav/attribute?entity_type=${entity[name].entityNumb}`).then(res => {
                if(res) setState({ adField: convertAddFields(res.data.items)}, getFilter);
            });

        } else {
            if(entity[name].fArr){
                setState({ adField: entity[name].fArr }, getFilter)
            } else {
                getItems()
            }
        }
    };

    const convertFilterChild = (name) => {
        let arr = [];
        state.filter.map((item,index) => {
            if(item.field === name){
                arr.push({
                    value:item.value,
                    operator:item.operator
                })
            }
        });
        return arr;
    }
    const convertFilter = () => {
        let obj = {};
        let link = '';
        state.filter.filter(item => item.value).map((item,index) => {
            obj[item.field] = {arr:[]};
        });


        Object.keys(obj).map((key, index) => {
            obj[key].arr = convertFilterChild(key);
        });

        Object.keys(obj).map((key, index) => {
            obj[key].arr.map((item,ind) => {
                if(item.operator === 'like' || item.operator === 'nlike'){
                    link+= `&filter[or][][${key}][${item.operator}]=${item.value}`
                }
                if(item.operator === 'eq' || item.operator === 'neq' || item.operator === '!=' || item.operator === '>' || item.operator === '<' || item.operator === '>=' || item.operator === '<='){
                    link+= `&filter[${key}][${item.operator}]=${item.value}`
                }
                if(item.operator === 'in' || item.operator === 'nin'){
                    if(item.operator === 'nin'){
                        link+= `&filter[${key}][${item.operator}][]=${item.value}` //&filter[or][][${key}][is]=NULL
                    } else {
                        link+= `&filter[${key}][${item.operator}][]=${item.value}`
                    }

                }
            })
        });
        return link;
    }

    const renderItems = item => {
        if(item.fieldType === "entity" || item.fieldType === "lookup" || item.fieldType === "tags" || item.fieldType === "products"){
            if(item.obj){
                return item.obj[state.adField[searchIndex(state.adField, item.field)].filterSearch]
            }
        } else if(item.fieldType === "select"){
            if(item.value){
                let index = searchIndex(state.adField, item.field);
                return state.adField[index].options[searchIndex(state.adField[index].options, item.value, "id")].label;
            }
        } else {
            return item.value
        }
    };

    useEffect(() => {
        localstorageSettings("post", `${name}View`, "kanban")
        if(page > 10){
            setLoading(true)
            getItems()
        }
    }, [page])

    const saveFilter = (params) => {
        if(state.filterId){
            myaxios.put(`/ajax/filter/${state.filterId}`, {
                name: entity[name].exportType,
                entity_type:entity[name].entityNumb,
                user_id: userId || 1,
                filter_attributes: state.filter.filter(item => item.value) ,
                columns: state.extraData
            }).then(res => {
                if(res){
                    if(res.status === 200 || res.status === 201 || res.status === 204){
                        setState({ open:null, openType:'', page: 1 }, getFilter)
                    }
                }
            })
        } else {
            myaxios.post(`/ajax/filter`, {
                name: entity[name].exportType,
                entity_type: entity[name].entityNumb,
                user_id: userId || 1,
                filter_attributes: state.filter.filter(item => item.value),
                columns: state.extraData
            }).then(res => {
                if(res){
                    if(res.status === 200 || res.status === 201 || res.status === 204){
                        setState({ open:null, openType:'', page: 1 }, getFilter)
                    }
                }
            })
        }
    };

    const addItem = (item) => {
        const newBoard = board
        newBoard.columns[0].cards.unshift(item)
        setBoard({...newBoard})
    }

    const deleteItem = (status, id) => {
        let status_id = board.columns.findIndex(el => +el.id === +status);
        let el_id = board.columns[board.columns.findIndex(el => +el.id === +status)].cards.findIndex(el => +el.id === +id);

        myaxios.delete(`/ajax/${name}/${id}`).then(res => {
            if(res.status === 200 || res.status === 204){
                getFilter()
            }
            /*if(!res.data) return false;
            if(res.status === 200 || res.status === 201 || res.status === 204){
                /!*const newBoard = board
                newBoard.columns[status_id].cards.splice(el_id, 1)
                setBoard({...newBoard})*!/
                getFilter()
            }*/
        })
    }

    const {filter, filterRequest} = state;
    return <div className="page" style={{ overflowX:"hidden" }}>
        {loading && <Loader contain />}
        <div className="butt-group">
            {entity[name].calendar ? <a onClick={() => history.push(entity[name].calendar)} style={{ float:"left" }} className="btns"><i className="flaticon-calendar-3" /></a> : null}
            <a onClick={() => {history.push(list); localstorageSettings("post", `${name}View`, "list")} } style={{ float:"left" }} className="btns"><i className="flaticon-list-2" /></a>
            <a  onClick={() => updateData(addButton.value)} className="mdn fl-l btni">{addButton.name}</a>
            <div className="mobile-block" style={{ display: "none" }}>
                <a  onClick={() => updateData(addButton.value)} className="fl-l btni"><span className="mobile-close">&#10005;</span></a>
            </div>
            <div className="mdn">
                <KanbanFastAdd
                    columns={board.columns}
                    config={fastAddConfig}
                    addItem={getFilter}
                    name={name}
                    taskTypePeriod={state.taskTypePeriod}
                />
            </div>

            <a onClick={() => setState({ open:'r-open', openType:'filter' })} data-tooltip="Фильтр" className="fl-r btns"><i className="flaticon-interface-3">{null}</i></a>
            {name === "task" && <div className="kanban-board-sort-buttons">
                <a className={`${!state.taskTypePeriod && "kanban-board-sort-buttons-active"}`} onClick={() => { setState({ taskTypePeriod: false }, getItems) }}>По статусам</a>
                <a className={`${state.taskTypePeriod && "kanban-board-sort-buttons-active"}`} onClick={() => { setState({ taskTypePeriod: true }, getItems) }}>По дням</a>
            </div>}
        </div>
        <div className="clear" />
        <div style={{marginTop:'10px'}}>
            {
                filterRequest.length > 0 ? filterRequest.map((item,index) =>
                    item.value && <div key={index} className="filterItem">
                        <em>{state.adField[searchIndex(state.adField, item.field)] ? state.adField[searchIndex(state.adField, item.field)].label : ''}:</em> [{item.operator}] <b> </b>
                        {renderItems(item)}
                        <a onClick={() => {
                            if(filter.length > 1){
                                filter.splice(index, 1);
                                setState({ filter }, saveFilter);
                            } else {
                                filter[0].value = "";
                                filter[0].obj = null
                                setState({ filter }, saveFilter);
                            }
                        }}>x</a>
                    </div>
                ) : null
            }
        </div>

        <div style={{ marginBottom: '10px' }} className="clear" />
        <CSSTransition in={!loading} timeout={200} classNames="my-node" unmountOnExit>
            <Board
                onCardDragEnd={onDrag}
                disableColumnDrag
                allowRemoveLane
                //allowRenameColumn
                //allowRemoveCard
                onLaneRemove={console.log}
                onCardRemove={console.log}
                onLaneRename={console.log}
                initialBoard={board}
                //allowAddCard={{ on: "top" }}
                onNewCardConfirm={draftCard => ({
                    id: new Date().getTime(),
                    ...draftCard
                })}
                onCardNew={console.log}
                renderCard={(content, { removeCard, dragging }) => (
                    <div className="kanbanItemParent" style={{ position: "relative", overflow: "hidden" }}>
                        <Item history={history} content={content} dragging={dragging} removeCard={removeCard} />
                        {!content.drug && <a onClick={() => deleteItem(content.status_id, content.id)} className="kanban-board-delete-item"><i className="flaticon-delete-1" /></a>}
                    </div>
                )}
                renderColumnHeader={({ title, id, color }, { removeColumn, renameColumn, addCard }) => (
                    <Header title={title} id={id} color={color} taskTypePeriod={state.taskTypePeriod} />
                )}
            >
                {board}
            </Board>
        </CSSTransition>
        {itemsCount > page && <button className="kanbanMore" onClick={() => { setPage(page + 10); }}>Загрузить еще</button>}



        <React.Fragment>
            <div className="bpmModal">
                <div style={{ right: -800 }} className={"right-modal scrollbar " + state.open}>
        {state.openType === "filter" ? <div className="sbform create-activ">
            <div className="rmod-header">
                <b>Фильтр</b>
                <a className="mclose 13" style={{marginRight: "60px"}} onClick={()=> setState({ open:null,openType:'' })}><i className="flaticon-close" /></a>
                <div className="togglelist"><button onClick={() => saveFilter(true)} className="rmod-ok"><i className="flaticon-interface-1" /></button></div>
            </div>
            <div className="mod-cont">
                {name === "task" && <div>
                    <div onClick={() => {
                        let newFilter = [
                            {field: "start_date",fieldType: "date",input_type: "date",operator: ">",value: toDate(-1, "23:59:59")},
                            {field: "end_date",fieldType: "date",input_type: "date",operator: "<",value: toDate(1,"00:00:01")},
                        ]
                        setState({ filter: newFilter }, () => saveFilter(true))
                    }} className="filterItem">Сегодня</div>
                    <div onClick={() => {
                        let newFilter = [
                            {field: "start_date",fieldType: "date",input_type: "date",operator: ">",value: toDate(0, "23:59:59")},
                            {field: "end_date",fieldType: "date",input_type: "date",operator: "<",value: toDate(2,"00:00:01")},
                        ]
                        setState({ filter: newFilter }, () => saveFilter(true))
                    }}
                         className="filterItem">Завтра</div>
                    <div onClick={() => {
                        let newFilter = [
                            {field: "start_date",fieldType: "date",input_type: "date",operator: ">",value: toDate(1, "23:59:59")},
                            {field: "end_date",fieldType: "date",input_type: "date",operator: "<",value: toDate(3,"00:00:01")},
                        ]
                        setState({ filter: newFilter }, () => saveFilter(true))
                    }} className="filterItem">Послезавтра</div>
                </div>}

                <div className="connection" style={{marginTop: "10px"}}><span>Фильтр</span><div className="toggleparrent">
                    <button
                        onClick={() => {
                            filter.push(
                                {
                                    field: state.adField[0].value,
                                    operator: operator[state.adField[0].fieldType][0].value,
                                    value:'',
                                    fieldType:state.adField[0].fieldType,
                                    options: state.adField[0].options,
                                    obj:null
                                }
                            );
                            setState({ filter })
                        }

                        }
                        className="btni">+</button>
                </div></div>
                <table className="filterBpm" style={{width: "100%"}}>
                    <thead>
                    <tr>
                        <td style={{padding: "0px 5px", width: "20%"}}>Поле</td>
                        <td style={{padding: "0px 5px", width: "20%"}}>Оператор</td>
                        <td style={{padding: "0px 5px"}}>Значение</td>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        filter ? filter.map((item,i) =>
                            <FilterItem
                                filter={filter}
                                key={i}
                                fields={state.adField}
                                field={item.field}
                                operator={item.operator}
                                //options={item.options}
                                options={state.adField[searchIndex(state.adField, item.field)].options}
                                type={item.type}
                                value={item.value}
                                arr={item.arr}
                                search={item.search}
                                index={i}
                                fieldType={item.fieldType}
                                obj={item.obj}
                                setField={(type, e) => {
                                    filter[i][type] = e;
                                    setState({ filter });
                                }}
                                delItem={i => {
                                    //delete filter[i];
                                    filter.splice(i, 1)
                                    setState({ filter });
                                }}
                                updData={(ind, id, arr) => {
                                    filter[i].obj = arr;
                                    filter[i].value = id;
                                    setState({ filter });
                                }}
                                unLink={() => {
                                    filter[i].obj = null;
                                    filter[i].value = '';
                                    setState({ filter });
                                }}
                            />
                        ) : null
                    }
                    </tbody>
                </table>
            </div>
        </div> : null}
                </div></div>
            {(state.open === "r-open") ? <Bgdrop close={() => setState({ open:'', openType:'', openModalId:null })} /> : null}
        </React.Fragment>


    </div>
}

export default KanbanBoard

//document.body.style.overflowX = "hidden";
/*
class KanbanBoard extends React.Component {
    constructor(props) {
        super(props);
        this.state = ({
            isLoading: true,
            projects: [],
            draggedOverCol: 0,
            leadlist:[],
            columns: []
        });
        this.handleOnDragEnter = this.handleOnDragEnter.bind(this);
        this.handleOnDragEnd = this.handleOnDragEnd.bind(this);
    }

    loadStage = () => {
        myaxios.get(`/ajax/lookup/search?type=opp_stage&value=`, {method: 'get',
        }).then((responce) =>{
            if(responce.status === 200 || responce.status === 201 || responce.status === 204) {
                this.setState({ columns: responce.data.items })
            }
        })
    }

    loadData = (to) =>{
        myaxios.get(`/ajax/opportunity${this.props.location.search}${(this.props.location.search) ? "&" : "?"}expand=contacts,account,stages,contact&sort=${this.state.sortValue}}`
            , {method: 'get',
            }).then((responce) =>{
            if(responce.status === 200 || responce.status === 201 || responce.status === 204) {
                this.setState({
                    projects : responce.data.items,
                    totalCount : responce.data._meta.totalCount,
                    pageCount : responce.data._meta.pageCount,
                    loading: false,
                    filterTch: true
                })
            }
        })
    };

    sendForm = (id, stage_id) => {
        this.setState({ loading: true });
        myaxios.put(`/ajax/opportunity/${id.id}`, {
            stage_id: stage_id,
        }).then(res => {
            //this.setState({tch: true, loading: false});
            Notif("save")
        })
    };

    componentDidMount() {
        this.loadStage();
        this.loadData();
    }

    //this is called when a Kanban card is dragged over a column (called by column)
    handleOnDragEnter(e, stageValue) {
        this.setState({ draggedOverCol: stageValue });
        console.log("handleOnDragEnter", stageValue)
        return stageValue;
    }

    //this is called when a Kanban card dropped over a column (called by card)
    handleOnDragEnd(e, project) {
        if(this.state.draggedOverCol === 0) {
            this.setState({ draggedOverCol: false });
            Notif("Error", "Перенос на данный этап невозможен")
            return false
        }
        const updatedProjects = this.state.projects.slice(0);
        if(project.stage_id !== this.state.draggedOverCol) {
            this.sendForm(project, this.state.draggedOverCol)
        }
        updatedProjects.find((projectObject) => {return projectObject.name === project.name;}).stage_id = this.state.draggedOverCol;
        this.setState({ projects: updatedProjects, draggedOverCol: false });
        setTimeout(this.props.fls)
    }

    handleClick = index => {
        console.log('Card click:', index)
    };

    render() {
        return  (
            <div className="page">
                <div className="butt-group">
                    <div className="toogle-bottom fl-r">
                        <a className="fl-r btns"><i className="flaticon-more" /></a>
                        <ul className="toogle-list">
                            <li><a><i className="flaticon-folder-2" /> <b>Экспорт</b></a></li>
                            <li><a><i className="flaticon-folder-3" /> <b>Импорт</b></a></li>
                        </ul>
                    </div>

                    <div className="toogle-bottom fl-r">
                        <a className="fl-r btns"><i className="flaticon-interface-3">{null}</i></a>
                        <ul className="toogle-list">
                            <li><a><i className="flaticon-interface-8" /> <b>Фильтр</b></a></li>
                            <li><a><i className="flaticon-list-2" /> <b>Сортировка</b></a></li>
                        </ul>
                    </div>

                    <a onClick={() => {this.props.updateData("AddOpportunity", "")}} className="fl-l btni">Добавить сделку</a>
                </div>
                <div className="clear"></div>
                <div className="example__container">
                    <ScrollContainer className="scroll-container" horizontal={true} vertical={true} ignoreElements=".kanban-item, .okBtn">
                        <div
                            style={{
                                width: "500px",
                                height: "200px",
                                background:"red",
                                bottom:0,
                                right:0,
                                position:"fixed"
                            }}
                            onDragEnd={e => console.log("enddddddddd", e)}
                            //onDragEnter={e => console.log("eeeeeee", e)}
                            className="webpackblock"
                        >

                        </div>
                        <div className="fullContain">
                            {this.state.columns.map((column) => {
                                return (
                                    <KanbanColumn
                                        name={ column.value }
                                        stage={ column.id }
                                        color={ column.color }
                                        dr={this.state.draggedOverCol}
                                        projects={ this.state.projects.filter((project) => {return parseInt(project.stage_id, 10) === column.id;}) }
                                        onDragEnter={ this.handleOnDragEnter }
                                        onDragEnd={ this.handleOnDragEnd }
                                        key={ column.stage }
                                    />
                                );
                            })}
                        </div>
                    </ScrollContainer>
                </div>
            </div>
        );
    }
}

export default withRouter(KanbanBoard);

const cardStyle = {};

class KanbanColumn extends React.Component {
    constructor(props) {
        super(props);
        this.state = ({ mouseIsHovering: false });
    }

    componentWillReceiveProps(nextProps) {
        this.state = ({ mouseIsHovering: false });
    }

    generateKanbanCards(color) {
        return this.props.projects.slice(0).map((project) => {
            return (
                <div onClick={this.props.onClick} style={cardStyle} draggable={true} onDragEnd={(e) => {this.props.onDragEnd(e, project);}} >
                    <div className="kanban-item">
                        <div className="kanban-item">
                            <div style={{ borderLeft: `7px solid ${color}` }} className="lead_item">
                                <b><a onClick={() => this.props.history.push('/opportunity/' + project.id)}>{project.name}</a></b>
                                <em>{project.created_at}</em>
                                <div className="right-task">
                                    <a><i className="flaticon-support" /></a>
                                    <a><i className="flaticon-chat-1" /></a>
                                    <a><i className="flaticon-email" /></a>
                                </div>
                                <div className="bottom-task">
                                    <a className="telclall it-task">Нет задач</a>
                                    <a className="activity-create it-add-task" title="Добавить задачу">Новая задача</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }

    render() {
        const columnStyle = {'display': 'inline-block', 'verticalAlign': 'top', 'marginRight': '5px', 'marginBottom': '5px', 'paddingLeft': '5px','paddingTop': '0px',
            'width': '310px','backgroundColor': (this.props.dr === this.props.stage) ? '#ebebeb' : '', minHeight: "200px"};
        return  (
            <div
                className="kanbanCol"
                style={columnStyle}
                onDragEnter={(e) => {this.setState({ mouseIsHovering: true }); this.props.onDragEnter(e, this.props.stage);}}
                onDragExit={(e) => {this.setState({ mouseIsHovering: false });}}
                fls={this.props.fls}
            >
                <div style={{ borderBottom: `4px solid ${this.props.color}` }} className="kanban-board-header"><div className="kanban-title-board">{this.props.name}</div></div>
                {this.generateKanbanCards(this.props.color)}
                <br/>
            </div>);
    }
}*/
