import React, {useEffect, useState} from "react";
import Modal from "../../components/Modal/Modal";
import {GenerateForm} from "../../components/cardViewComponents/GenerateForm";
import {validationData} from "../../components/UI/formValid";
import {validationConfig} from "../../components/cardViewComponents/validationConfig";
import Notif from "../../components/Notif";
import {getOpportunityPrepareService, createOpportunityService} from "../../services/opportunity/opportunityService";
import {createEvent} from "../../services/other/events";

const fieldsConfig = {
    name: {
        label: 'Название',
        type: 'text',
        className: 'w100',
        validation: { required: true, maxLength: 255 }, zone: 1
    },
    amount: {
        label: 'Бюджет',
        type: 'number',
        className: 'w60',
        validation: { number: true, maxLength: 255 }, zone: 1
    },
    currency_id: {
        label: 'Валюта',
        type: 'entity',
        link: "/ajax/lookup/search?type=currency&value=",
        result: 'name',
        entity_from: 'currency',
        entityName: 'name',
        className: 'w40',
        minLength: 0, zone: 1
    },
    main_contact_id: {
        label: 'Контакт',
        type: 'entity',
        link: "/ajax/contact?filter[name][like]=",
        result: 'name',
        entity_from: 'contact',
        entityName: 'name',
        className: 'w100',
        minLength: 0, zone: 1
    },
    account_id: {
        label: 'Контрагент',
        type: 'entity',
        link: "/ajax/account?filter[name][like]=",
        result: 'name',
        entity_from: 'account',
        entityName: 'name',
        className: 'w100',
        minLength: 0, zone: 1
    },
    owner_id: {
        label: 'Ответственный',
        type: 'entity',
        link: "/ajax/settings/user?filter[name][like]=",
        result: 'name',
        entity_from: 'owner',
        entityName: 'name',
        className: 'w100',
        minLength: 0, zone: 1
    },
}

export const OpportunityModal = ({ isOpen, close, history, save, data: modalData = {} }) => {
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState({})
    const [disabled, setDisabled] = useState({})
    const [values, setValues] = useState({
        name: '',
        amount: '',
        currency_id: '',
        currency: null,
        stage_id: '',
        owner_id: null,
        owner: null,
        main_contact_id: modalData && modalData.contact_id,
        contact: modalData && modalData.contact,
        account_id: modalData && modalData.account_id,
        account: modalData && modalData.account,
        tch: false
    })

    const getPrepare = async () => {
        const { status, data } = await getOpportunityPrepareService()
        if((status === 200 || status === 201) && data) {
            setValues({
                ...values,
                stage_id: data.stages[0].id,
                name: 'Сделка №' + data.nextId
            })
        }
        setLoading(false)
    }

    const saveItem = async () => {
        let obj = {}
        if(!values.main_contact_id && !values.account_id) {
            obj = {
                main_contact_id: 'К сделке должен быть привязан контакт или контрагент',
                account_id: 'К сделке должен быть привязан контакт или контрагент'
            }
        }

        const errorsData = { ...validationData(values, validationConfig(fieldsConfig)), ...obj };

        setErrors(errorsData)
        if(Object.keys(errorsData).length > 0) {
            Notif('required-fields')
            return false;
        }

        setLoading(true)
        const { name, stage_id, owner_id, currency_id, amount, main_contact_id, account_id } = values
        const { status, data } = await createOpportunityService({ data: {
                name, stage_id, owner_id, currency_id, amount, main_contact_id, account_id,
                project_id: modalData && modalData.project_id,
                order_id: modalData && modalData.order_id
            }})

        if((status === 200 || status === 201) && data.id) {
            if(save) {
                save(); setTimeout(close, 200);
                createEvent({ user_id: window.location.host, user_name: "---", event_type: "createOpportunity" }).then()
            } else {
                history.push(`/opportunity/${data.id}`)
                createEvent({ user_id: window.location.host, user_name: "---", event_type: "createOpportunity" }).then()
            }
            Notif('save')
        }
    }

    useEffect(() => {
        getPrepare().then()
    }, [])

    const changeFields = (key, value, item) => {
        let obj = {}
        if(item) {
            if(item === 'clear') obj[fieldsConfig[key].entity_from] = null
            else obj[fieldsConfig[key].entity_from] = item
        }

        setValues({ ...values, ...obj, [key]: item ? item.id : value, tch: false })
    }

    return (
        <Modal isOpen={isOpen} save={saveItem} close={close} loading={loading} showLeaveModal tch={values.tch} title={'Создать сделку'}>
            <GenerateForm
                config={fieldsConfig}
                { ...{ values, errors, disabled } }
                onChange={changeFields}
                onSaveData={saveItem}
            />
        </Modal>
    )
}