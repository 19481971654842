import React, {useEffect, useState} from "react";
import myaxios from "../../Axios";
import Textarea from "../../UI/Textarea";
import Loader from "../../UI/Loader";
import Notif from "../../Notif";
import MainEditor from "../../UI/Editor";

type Props = {
    entity: string,
    id: number
}

type Item = {
    id: number,
    created_at: string,
    reply_to: number | null,
    content: string,
    owner: {
        id: number,
        name: string,
        photo: string | null
    } | null,
    reply_to_content: string | null
}

type Post = {
    content: string,
    reply_to?: number
}

const EntityComments:React.FC<Props> = ({ entity, id }) => {
    const [list, setList] = useState<Item[]>([]);
    const [loading, setLoading] = useState(true);
    const [text, setText] = useState(``);
    const [replyItem, setReplyItem] = useState<Item | null>(null);
    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        getList()
    }, [])

    const getList = () => {
        myaxios.get(`/ajax/${entity}/${id}/comments?per-page=10`).then(res => {
            if(res && res.status === 200){
                setList(res.data.items);
                setTotalPage(res.data._meta.pageCount);
                setLoading(false);
            }
        })
    }

    const yetList = () => {
        let page = currentPage + 1;
        setCurrentPage(page)
        myaxios.get(`/ajax/${entity}/${id}/comments?per-page=10&page=${page}`).then(res => {
            if(res && res.status === 200){
                const newItems = JSON.parse(JSON.stringify(list));
                setList([...newItems, ...res.data.items]);
                setTotalPage(res.data._meta.pageCount);
            }
        })
    }

    const postComment = () => {
        if(text.length > 1) {
            setLoading(true);
            const arr:Post = {content: text};
            if(replyItem) arr.reply_to = replyItem.id;
            myaxios.post(`/ajax/${entity}/${id}/comment`, arr).then(res => {
                if(res.status === 200 || res.status === 201){
                    const newItems = JSON.parse(JSON.stringify(list));
                    newItems.unshift({
                        id: res.data.id,
                        content: res.data.content,
                        reply_to: res.data.reply_to,
                        owner: res.data.owner || null,
                        reply_to_content: res.data.reply_to_content
                    })
                    setReplyItem(null);
                    setList([...newItems]);
                    setText(``);
                    setLoading(false);
                }
            }).catch(e => {
                setLoading(false);
            })
        } else {
            Notif("required-fields", "Поле не может быть пустым")
        }
    }


    return loading ? <Loader contain /> : <div id="comment-item" className="tab-container">
        <div className="tab-content sbform" style={{ marginBottom: "5px" }}>
            {replyItem && <div>
                <p className="comment-item__reply_to_content">
                    {replyItem.content}
                    <a onClick={() => setReplyItem(null)} className="comment-item__reply_to_content-cancel">Отменить</a>
                </p>
            </div>}

            {/* @ts-ignore */}
            <MainEditor
                toolbar={'bold italic underline strikethrough | fontsizeselect  |  numlist bullist forecolor backcolor removeformat | pagebreak'}
                menubar={'a'}
                value={text}
                height={150}
                valid={true}
                editorStyles
                style={{  }}
                className={''}
                required={false}
                tooltip={''}
                label={''}
                errorMessage={''}
                onChange={(txt: string) => setText(txt)}
                disabled={false}
                plugins={null}
            />
            <button onClick={() => postComment()} className="btn-border-radius btn-m">Добавить</button>
        </div>
        <div className="full-modal-right-hr" />
        {
            list && list.map(({ created_at, owner = { name: 'Иванов Иван' }, reply_to_content, content }) => (
                <div className="comment-item">
                    <div className="comment-item__owner">
                        <div className="user-dropdown-current-single">
                            <div className="user-dropdown-current">{owner && owner.name.substr(0, 2)}</div>
                            <em style={{ color: '#464646' }}>{owner && owner.name}</em>
                        </div>
                        <em>{created_at}</em>
                    </div>
                    <div className="comment-item__content">
                        <div className="comment-item__content-left editBlc ">
                            <p dangerouslySetInnerHTML={{ __html: content }} />
                            {/*<a href="#comment-item" onClick={() => {
                                setReplyItem(item)
                            }}
                                className="comment-item__answer-button">Ответить</a>*/}
                        </div>
                        {/*<div className="comment-item__content-right">
                            {item.created_at}
                        </div>*/}
                    </div>
                    {reply_to_content && <div className="comment-item__reply_to_content">
                        {reply_to_content}
                    </div>}
                </div>
            ))
        }
        {totalPage > currentPage && <a style={{
            margin: '0 auto',
            display: 'table'
        }} className="btn-m" onClick={yetList}>Показать еще</a>}
    </div>
}

export default EntityComments