import React, {useEffect, useState} from "react";
import {BpDateWithPeriod, parseDataForPeriod, saveTimePeriod} from "../../BpHelpers";
import {validationData} from "../../../UI/formValid";
import Modal from "../../../Modal";
import Input from "../../../UI/Input";
import {getSendPulseAddressBookService} from "../../../../services/integrations/sendpulse";
import Notif from "../../../Notif";
import Myselect from "../../../UI/Select";

const validationRules = {
    name: { required: true, maxLength: 255 },
    type: { required: true, maxLength: 255 },
    book_id: { required: true, maxLength: 255 },
}

export const ActionSendPulse = ({ close, metaData, saveNode }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState({})
    const [values, setValues] = useState({
        name: '',
        type: 'subscribe',
        book_id: '',
        addressBooks: [],
        time_value: 0,
        time_valuePeriod: 'minutes',
        tch: true
    })

    const getData = async () => {
        try {
            const { status, data } = await getSendPulseAddressBookService()

            if(status !== 200) {
                Notif()
            }

            if(data.data.length === 0) {
                Notif('Error', 'Создайте адресную книгу в Sendpulse'); close()
                return;
            }

            if(metaData) {
                const { name, time_value, metaData: { type, book_id }} = metaData
                setValues({
                    ...values, name, addressBooks: data.data, type, book_id,
                    time_value: parseDataForPeriod(time_value).value,
                    time_valuePeriod: parseDataForPeriod(time_value).period,
                })
            } else {
                setValues({
                    ...values,
                    addressBooks: data.data,
                    book_id: data.data[0].id
                })
            }

        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        setIsOpen(true)
        setLoading(false)
        getData().then()
    }, [])

    const saveData = () => {
        const validErrors = validationData(values, validationRules)
        if(Object.keys(validErrors).length > 0) {
            setErrors(validErrors)
            return false;
        }
        setErrors({})
        setIsOpen(false)

        const { name, time_value, time_valuePeriod, type, book_id } = values
        setTimeout(() => saveNode({
            id: metaData && metaData.id,
            name,
            nodeType: 'node',
            time_value: saveTimePeriod(time_value, time_valuePeriod),
            is_scheduled: time_value > 0,
            type: "sendpulse_addressbook",
            metaData: {
                type, book_id
            }
        }), 100)
    }

    const closeModal = () => {
        setIsOpen(false); setTimeout(close, 200);
    }

    return (
        <Modal showLeaveModal tch={values.tch} isOpen={isOpen} title={'SendPulse'} loading={loading}
               save={saveData} parentClassName={'bpmModal'} close={closeModal}>
            <div className={'sbform'}>
                <Input
                    label={'Название'}
                    value={values.name}
                    onChange={e => setValues({...values, name: e.target.value, tch: false })}
                    errorMessage={errors.name} valid={!errors.name} shouldValidate
                />
                <Myselect
                    label={'Тип'}
                    options={[ { value: 'subscribe', label: 'Подписать' }, { value: 'unsubscribe', label: 'Отписать' } ]} value={values.type}
                    onChange={e => setValues({ ...values, type: e.target.value, tch: false })}
                    errorMessage={errors.type} valid={!errors.type} shouldValidate
                />
                <Myselect
                    label={'Адресная книга'}
                    options={values.addressBooks} value={values.book_id} empty val={'id'} lab={'name'}
                    onChange={e => setValues({ ...values, book_id: e.target.value, tch: false })}
                    errorMessage={errors.book_id} valid={!errors.book_id} shouldValidate
                />
                <BpDateWithPeriod
                    label={'Отложенный запуск'}
                    name={'time_value'} periodName={'time_valuePeriod'}
                    values={values} errors={errors}
                    setValues={(type, value) => setValues({...values, [type]: value, tch: false })}
                    tooltip={'Время задержки выполнения процесса'}
                />
            </div>
        </Modal>
    )
}