import {entity} from "../../../components/ListComponentEntity";
import {NavLink} from "react-router-dom";
import React from "react";
import {NewList} from "../../../components/NewList/NewList";

export const ListTransferNew = (props) => {
    return (
        <NewList
            { ...props } stockNav additionalFieldsExists isFilterExists
            entity={'stock/transfer'}
            entity_type={20}
            requiredFields={'id,name,from_id,to_id,date'}
            requiredExpand={'from,to'}
            deleteToShowColumn={'id,name,from_id,to_id,date'}
            filterFields={entity.transfer.fArr}
            singleSearch={entity.transfer.fArr[0]}
            headingColumns={['Номер', 'Со склада', 'На склад', 'Дата']}
            RenderColumn={(item) => (
                <>
                    <td>
                        <NavLink to={`/stock/transfer/${item.id}`}  className={'nowrap-list-column'}>{item.name}</NavLink>
                    </td>
                    <td>
                        {item.from && <span className="label-st" style={{ backgroundColor: 'rgb(255, 193, 100)' }}>{item.from.name}</span>}
                    </td>
                    <td>
                        {item.to && <span className="label-st" style={{ backgroundColor: 'rgb(255, 193, 100)' }}>{item.to.name}</span>}
                    </td>
                    <td>
                        <div>{item.date}</div>
                    </td>
                </>
            )}
            createButtonRender={(setModalType) => <button onClick={() => setModalType('transfer')} className="open-modal-button btni">Новое перемещение</button>}
            rightNavbarConfig={{
                additionalFields: '/fieldsettings/20',
                columns: true
            }}
            massDoConfig={{ deleteAll: `/ajax/stock/transfer/delete-all` }}
            contextNav={{ edit: true, task: true, print: true, delete: true }}
            sort={entity.transfer.fArr.filter(el => el.sort)}
        />
    )
}