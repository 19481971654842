import React, {useState} from "react";
import Modal from "../components/Modal/Modal";
import Loader from "../components/UI/Loader";
import {FieldGen, isFormValid, setField} from "../components/fieldGen";
import myaxios from "../components/Axios";
import Notif from "../components/Notif";

const SupportModal = ({ isOpen, close }) => {
    const [state, setMyState] = useState({
        loading:false,
        valid:false,
        type:null,
        formControl: {

            name: {
                label:"Ваше имя",
                type:"input",
                value:"",
                valid:true
            },
            phone: {
                label:"Номер телефона",
                type:"input",
                value:"",
                valid:true,
                validation:{required:true, minLength:7}
            }
        }
    })

    const setState = (st, func) => {
        Object.keys(st).map((key) => { state[key] = st[key]});
        setMyState(prevState => { return {...prevState, ...state} });
        if(func) func();
    };

    const sendToMail = () => {
        setState({ loading: true })
        myaxios.post(`/ajax/site/send-email`, {
            body: `
            Пользователь оставил заявку на внедрение CRM системы 
            Имя пользователя: ${state.formControl.name.value}
            Номер телефона пользователя: ${state.formControl.phone.value}
            Тип заявки: ${state.type}
        `,
            subject:"Новая заявка из CRM системы",
            to:"techua.com@gmail.com"
        }).then(res => {
            setState({ loading: false })
            if(res.status === 200 || res.status === 201){
                Notif("save", "Заявка успешно отправлена! Мы свяжемся с Вами в ближайшее время!");
                close()
            }
        }).catch(() => setState({ loading: false }))
    }

    return state.loading ? <Loader contain /> : (
        <Modal title="Поддержка CRM" isOpen={isOpen} close={close} save={close}>
            {!state.type ? <div>
                <a target="_blank" href="https://sboxcrm.com/docs" className="full-width-button">Документация CRM</a>
                <a onClick={() => setState({ type: "demo CRM" })} className="full-width-button">Бесплатная демонстрация CRM</a>
                <a onClick={() => setState({ type: "Внедрение CRM" })} className="full-width-button">Заказать внедрение CRM</a>
                <a href="#chatraChatExpanded" className="full-width-button">Онлайн чат технической поддержки</a>
            </div> :
            <div>
                <FieldGen
                    arr={state.formControl}
                    setField={(e, type) => setState({formControl: setField(state.formControl, type, e)})}
                />
                <a onClick={() => setState(isFormValid(state), sendToMail())} className="full-width-button">Отправить</a>
                <a className="slim-link" onClick={() => setState({ type: null })} style={{  }}>Отменить</a>
            </div>}
        </Modal>
    )
}

export default SupportModal;