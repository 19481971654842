import React, {useEffect, useState} from "react";
import {NewAutoSelect} from "../NewUi/NewAutoSelect";
import {NavLink} from "react-router-dom";
import {createAccountService} from "../../services/account/accountService";
import Notif from "../Notif";
import {validationData} from "../UI/formValid";
import {ModalLoader} from "../UI/Loader";
import {createContactService} from "../../services/contact/contactService";

const validationConfig = {
    first_name: {required: true, maxLength: 255},
    mobile_phone: {maxLength: 255, number: true},
    work_phone: {maxLength: 255, number: true},
    email: {maxLength: 255, email: true},
}

const initialValue = {
    first_name: '',
    mobile_phone: '',
    work_phone: '',
    email: '',
}

export const ContactCardRender = ({ contact, setDataValues }) => {
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [values, setValues] = useState({
        first_name: '',
        mobile_phone: '',
        work_phone: '',
        email: ''
    })

    useEffect(() => {
        setValues(JSON.parse(JSON.stringify(initialValue)))
    }, [contact])

    const saveContact = async () => {
        const errorsData = validationData(values, validationConfig)
        setErrors(errorsData)
        if(Object.keys(errorsData).length > 0) {
            Notif('required-fields')
            return
        }

        setLoading(true)
        try {
            const { data, status } = await createContactService({ data: values })
            if((status === 200 || status === 201) && data.id) {
                Notif('save', 'Контрагент создан')
                setDataValues({ contact: data, contact_id: data.id, main_contact_id: data.id })
            }
        } catch (e) { }
        setLoading(false)
    }

    return (
        <div className={'contact-left-col account_col'}>
            {loading && <ModalLoader />}
            {contact && <a onClick={() => setDataValues({ contact: null, contact_id: '', main_contact_id: '' })} className="lead-form-close">&#10005;</a>}
            <h3>Контакт</h3>
            {
                contact && <div>
                    <div className={'card-block'}>
                        <em>Название</em>
                        <NavLink to={`/contact/${contact.id}`}>{contact.name}</NavLink>
                    </div>
                    {contact.mobile_phone && <div className={'card-block'}>
                        <em>Мобильный телефон</em>
                        <span>{contact.mobile_phone}</span>
                    </div>}
                    {contact.work_phone && <div className={'card-block'}>
                        <em>Рабочий телефон</em>
                        <span>{contact.work_phone}</span>
                    </div>}
                    {contact.email && <div className={'card-block'}>
                        <em>Email</em>
                        <span>{contact.email}</span>
                    </div>}
                    {!!contact.personal_discount && <div className={'card-block'}>
                        <em>Персональная скидка</em>
                        <span>{contact.personal_discount}%</span>
                    </div>}
                </div>
            }
            {!contact && <>
                <NewAutoSelect
                    label={'Название'}
                    searchBy={'name'}
                    link={`/ajax/contact?fields=id,name,first_name,email,mobile_phone,work_phone&filter[name][like]=`}
                    updateData={(item) => setDataValues({ contact: item, contact_id: item.id, main_contact_id: item.id })}
                    entity={null} minLength={1}
                    onChange={e => setValues(({ ...values, first_name: e.target.value }))}
                    valid={!errors.first_name} errorMessage={errors.first_name}
                />
                <NewAutoSelect
                    label={'Мобильный телефон'}
                    searchBy={'mobile_phone'} searchByAlternative={'work_phone'}
                    link={`/ajax/contact/search?phone=`}
                    updateData={(item) => setDataValues({ contact: item, contact_id: item.id, main_contact_id: item.id })}
                    entity={null} minLength={1} messageEmptyItem={'Телефон не заполнен'}
                    onChange={e => setValues(({ ...values, mobile_phone: e.target.value }))}
                    valid={!errors.mobile_phone} errorMessage={errors.mobile_phone}
                />
                <NewAutoSelect
                    label={'Рабочий телефон'}
                    searchBy={'mobile_phone'} searchByAlternative={'work_phone'}
                    link={`/ajax/contact/search?phone=`}
                    updateData={(item) => setDataValues({ contact: item, contact_id: item.id, main_contact_id: item.id })}
                    entity={null} minLength={1} messageEmptyItem={'Телефон не заполнен'}
                    onChange={e => setValues(({ ...values, work_phone: e.target.value }))}
                    valid={!errors.work_phone} errorMessage={errors.work_phone}
                />
                <NewAutoSelect
                    label={'Email'}
                    searchBy={'email'}
                    link={`/ajax/contact?fields=id,name,first_name,email,mobile_phone,work_phone&filter[email][like]=`}
                    updateData={(item) => setDataValues({ contact: item, contact_id: item.id, main_contact_id: item.id })}
                    entity={null} minLength={1} messageEmptyItem={'Email не заполнен'}
                    onChange={e => setValues(({ ...values, email: e.target.value }))}
                    valid={!errors.email} errorMessage={errors.email}
                />
                {values.first_name.trim().length > 1 && <button
                    onClick={saveContact}
                    style={{ marginTop: '10px', width: '100%' }}
                    className="success btni">Создать контакт
                </button>}
            </>}
        </div>
    )
}

export const ContactCard = React.memo(ContactCardRender)