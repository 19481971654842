import React, {useRef, useState} from "react";
import {useOnClickOutside} from "../hooks/useOnClickOutside";
import {CSSTransition} from "react-transition-group";

export const DropDownButton = ({ label, items }) => {
    const [open, setOpen] = useState(false)
    const dropdownRef = useRef(null)
    useOnClickOutside({ ref: dropdownRef, handler: () => setOpen(false) })

    return (
        <div ref={dropdownRef} className={'dropdown-parent ml5'} style={{ position: 'relative' }}>
            <button onClick={() => setOpen(!open)} className={'success btni'}>{label}</button>
            <CSSTransition in={open} timeout={200} classNames="slide" unmountOnExit>
                <ul className="toogle-list" style={{ display: 'block' }}>
                    {items({ close: () => setOpen(false) })}
                </ul>
            </CSSTransition>
        </div>
    )
}