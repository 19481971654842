import React, {useContext} from "react";
import fbIcon from '../../img/messengers/facebook-ms.png';
import tgIcon from '../../img/messengers/telegram-ms.png';
import viberIcon from '../../img/messengers/viber-ms.png';
import instagramIcon from '../../img/messengers/instagram-ms.png';
import {GlobalState} from "../../Layout/Layout";

const switchImg = (platform) => {
    switch (platform) {
        case "instagram": return instagramIcon;
        case "telegram": return tgIcon;
        case "facebook": return fbIcon;
        case "viber": return viberIcon;
        default: return viberIcon;
    }
}

export const MessengersInCards = ({ chats, contact }) => {
    const { setState, state } = useContext(GlobalState)

    const pushChatToState = (item) => {
        if(!state.openChats.find(el => el.id === item.id)) {
            setState('openChats', [...state.openChats,
                {
                    user: {...item, id: item.id, contact },
                    bot_id: item.id,
                    type: item.platform,
                    name: item.username,
                    id: item.id
                }])
        }
    }

    return (
        <div>
            {
                chats.map(item => <div
                    onClick={() => pushChatToState(item)}
                    className={'card-messengers-item'}>
                    <img src={switchImg(item.platform)} alt=""/>
                </div>)
            }
        </div>
    )
}