import React from 'react';
import HistoryItem from './history-item';
import Notif from '../Notif';
import myaxios from '../../components/Axios';
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from '../../components/UI/Loader';
import {CSSTransition} from "react-transition-group";
import MainEditor from "../UI/Editor";


class History extends React.Component {
    state = {
        textfrom: '',
        historyArr: [],
        pageNumber: 1,
        pageCount: 1,
        perPage: 20,
        totalCount: Number,
        isShowComment: true,
        loading: true
    };

    getHistoryData() {
        this.setState({ loading: true })
        const isComment = `&filter[or][][event_type][${this.state.isShowComment ? 'like' : 'nlike'}]=comment`
        myaxios.get(`/ajax/${this.props.type}/${this.props.id}/history?per-page=${this.state.perPage}${this.props.created_at ? `&filter[created_at][>]=${this.props.created_at}` : ''}${isComment}`, {
            method: 'get',
        }).then((response) => {
            this.setState({loading: false, historyArr: response.data.items, totalCount: response.data._meta.totalCount, links: response.data._links, pageCount: response.data._meta.pageCount});
        }).catch((error)=>{
            Notif("Error", error.response ? error.response.data.message : "Попробуйте повторить позже или обратитесь в техническую поддержку.");
            this.setState({ loading: false })
        });
    }

    componentDidMount(){
        this.getHistoryData()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.isShowComment !== prevState.isShowComment) {
            this.getHistoryData()
        }
    }

    fetchMoreData = () => {
        const isComment = `&filter[or][][event_type][${this.state.isShowComment ? 'like' : 'nlike'}]=comment`
        if(this.state.pageNumber < this.state.pageCount) {
            this.state.pageNumber++;
            setTimeout(() => {
                myaxios.get(`/ajax/${this.props.type}/${this.props.id}/history?per-page=${this.state.perPage}${isComment}`, {
                    params: {
                        page: this.state.pageNumber
                    }
                }).then((response) => {
                    this.setState({
                        historyArr: this.state.historyArr.concat(response.data.items)
                    });
                })
            }, 1000);
        }
    };
    handleTextarea = (e) => {
        this.setState({
            textfrom: e.target.value,
        });
    };
    addEl = (e) => {
        e.preventDefault();
        if(this.state.textfrom.length > 3) {
            this.setState({ loading: true })
            myaxios.post(`/ajax/${this.props.type}/${this.props.id}/comment`, {
                content: this.state.textfrom
            }).then((response) => {
                if(response.status === 201){
                    let historyArr = this.state.historyArr;
                     historyArr.unshift({id: response.data.id, time: "только что", event_type: "comment", model: {content: this.state.textfrom} });
                     this.setState({ historyArr: historyArr, textfrom: '', loading: false});
                     Notif("save", "Комментарий " + this.state.textfrom.substring(0,18) + "... успешно добавлен!");
                }
            })
        } else {
            Notif("required-fields", "Поле комментарий не может содержать меньше 3-х символов");
        }
        this.setState({ loading: false })

    };
  render(){
      return <div className="tab-content sbform">
          <div className={'comment-tabs'}>
              <a onClick={() => this.setState({ isShowComment: !this.state.isShowComment })} className={this.state.isShowComment && 'comment-tab-active'}>Комментарии</a>
              <a onClick={() => this.setState({ isShowComment: !this.state.isShowComment })} className={!this.state.isShowComment && 'comment-tab-active'}>История изменений</a>
          </div>
          {this.state.loading && <Loader contain/>}
      <CSSTransition in={!this.state.loading} timeout={200} classNames="slide" unmountOnExit><div>
          {this.state.isShowComment && <div className={'history-comment-editor'}>
              <MainEditor
                  toolbar={'bold italic underline strikethrough | fontsizeselect  |  numlist bullist forecolor backcolor removeformat | pagebreak'}
                  menubar={'a'}
                  value={this.state.textfrom}
                  height={150}
                  valid={true}
                  editorStyles
                  style={{  }}
                  className={''}
                  required={false}
                  tooltip={''}
                  label={''}
                  errorMessage={''}
                  onChange={(textfrom) => this.setState({ textfrom })}
                  disabled={false}
                  plugins={null}
              />
              <button onClick={this.addEl} className="btn-border-radius btn-m">Добавить</button>
          </div>}

      {/*<label>Добавить комментарий</label>
      <form onSubmit={this.addEl}>
          <textarea name="" id="" cols="30" rows="3" onChange={this.handleTextarea} value={this.state.textfrom} />
          <input type="submit" className="btn-m" value="Добавить"/>
      </form>*/}
      <div className="contact-chronology m-demo mt20">
          <div className="m-demo__preview">
              <div className="m-list-timeline">
                  <div className="m-list-timeline__items">


                      <InfiniteScroll
                          dataLength={this.state.historyArr.length}
                          next={this.fetchMoreData}
                          hasMore={true}
                          loader={(this.state.pageNumber < this.state.pageCount) ? <p style={{textAlign: "center"}}>Загрузка</p> : null}
                      >
                          {
                              this.state.historyArr.map((item, index) =>
                                  <HistoryItem
                                      key={index}
                                      id={item.id}
                                      type={item.event_type}
                                      time={item.created_at}
                                      icon={item.icon}
                                      leadLink={item.leadLink}
                                      title={item.leanTitle}
                                      phase={item.phase}
                                      failStatus={item.failStatus}
                                      coment={item.model}
                                      model={item.model}
                                      message={item.message}
                                      sum={item.sum}
                                      user={item.user}
                                      field={item.fieldLabel}
                                      oldField={item.old_value}
                                      newField={item.new_value}
                                      taskText={item.taskText}
                                      taskstatus={item.taskstatus}
                                      invoicelink={item.invoicelink}
                                      invoicenumb={item.invoicenumb}
                                      calltype={item.calltype}
                                      callnumber={item.callnumber}
                                      callstatus={item.callstatus}
                                      calltime={item.calltime}
                                      callrecordlink={item.callrecordlink}
                                      callcomment={item.callcomment}
                                      emailsubject={item.emailsubject}
                                      emaillink={item.emaillink}
                                      emailtype={item.emailtype}
                                      createdBy={item.createdBy}
                                  />
                              )
                          }
                      </InfiniteScroll>
              </div>
          </div>
      </div>
  </div></div>
      </CSSTransition>
    </div>
  }};

export default History;