import React,{ Component } from 'react';
import Input from '../components/UI/Input';
import Myselect from '../components/UI/Select';
import formValid from '../components/UI/formValid';
import Notif from '../components/Notif';
import myaxios from '../components/Axios';
import AutoSelect from '../components/UI/AutoSelect';

class AddMachine extends Component {
    state = {
        formControl: {
            machine: {value:'',arr:null,valid:true,validation:{required:true}},
            quantity: {value:'1',valid:true,validation:{required:false, number:true}},
            quantityFact: {value:''},
            parent: {value: '', options: []},
            status: {value: 'inactive'},
            plan: {value: ''}
        },
        tch: true,
        facts:[],
    };
    showEditWork = () => {
        myaxios.get(`/ajax/project/work/${this.props.projectId}/machine-plan/${this.props.machineId}`, {
            method: 'get',
        }).then((response) => {
            const formControl = this.state.formControl;
            formControl.plan.value = response.data.plan;
            formControl.status.value = response.data.status;
            formControl.machine.arr = response.data.machine;
            formControl.machine.value = response.data.machine_id;
            formControl.quantity.value = response.data.plan_quantity;
            formControl.quantityFact.value = response.data.factQuantity;
            this.setState({ formControl, facts: response.data.facts })
        })
    };
    editRes = () => {
        this.setState({ tch: true });
        myaxios.put(`/ajax/project/work/${this.props.projectId}/edit-machine/${this.props.machineId}`, {
            status: this.state.formControl.status.value,
            plan: this.state.formControl.plan.value,
            machine_id: this.state.formControl.machine.value,
            plan_quantity: this.state.formControl.quantity.value
        }).then((response) => {
            if(response.status === 200 || response.status === 201 || response.status === 204) {
                setTimeout(() => this.props.refresh(), 100)
                Notif("save", "Ресурс успешно добавлен")
            }
        })
    };
    componentDidMount(){
        if(this.props.machineId) setTimeout(this.showEditWork(), 200);
    }
    isFormValid = () => {
        let formControl = this.state.formControl;
        let isFormValid = true;
        Object.keys(formControl).map((key, index) => {
            formControl[key].valid = formValid(formControl[key].value, formControl[key].validation)
            isFormValid = formControl[key].valid && isFormValid
        });
        this.setState({
            formControl, isFormValid
        });
        return isFormValid;
    };
    sendForm = event => {
        event.preventDefault();
        if(this.isFormValid()){
            this.setState({ tch: true });
            myaxios.post(`/ajax/project/work/${this.props.projectId}/link-machine/${this.state.formControl.machine.value}`, {
                status: this.state.formControl.status.value,
                plan: this.state.formControl.plan.value,
                plan_quantity: this.state.formControl.quantity.value
            }).then(res => {
                if(res.status === 200) {
                    setTimeout(() => this.props.refresh(), 100)
                    Notif("save", "Ресурс успешно добавлен")
                }
            })
        } else Notif("Error")
    };
    setField = (event, type) => {
        const formControl = {  ...this.state.formControl };
        const control = { ...formControl[type] };
        control.value = event.target.value;
        formControl[type] = control;
        this.setState({formControl, tch: false}) };

    updateData = (id, arr, addType, endParrans) => {
        const formControl = this.state.formControl;
        formControl[addType].arr = arr;
        formControl[addType].value = id;
        this.setState({ formControl, tch: false })
    };
    unLink = (type, typeID, id) => {
        const formControl = this.state.formControl;
        formControl[type].arr = null;
        formControl[type].value = null;
        this.setState({ formControl, tch: false })
    };
    render(){
        return <div className="sbform create-activ">
            <div className="rmod-header">
                <b>{this.props.id} {(this.props.id) ? "Редактировать ресурс" : "Добавить ресурс"}</b>
                <div className="togglelist"><button onClick={(this.props.machineId) ? this.editRes : this.sendForm} className="rmod-ok" disabled={this.state.tch} ><i className="flaticon-interface-1" /></button></div>
            </div>
            <div className="mod-cont">
                <div className="relt">
                    {
                        (this.state.formControl.machine.arr) ?
                            <div className="inp">
                                <label>Станок</label>
                                <div className="fgroup rsb">
                                    <b>{this.state.formControl.machine.arr.name}</b>
                                    <a title="Отвязать" onClick={()=>this.unLink("machine")} className="unlink">x</a>
                                </div>
                            </div> :
                            <AutoSelect
                                label="Станок"
                                link={'/ajax/lookup/search?type=machine&value='}
                                errorMessage={'Поле не может быть пустым'}
                                shouldValidate={true}
                                valid={this.state.formControl.machine.valid}
                                //value={this.state.formControl.machine.value}
                                minLength="0"
                                result="name"
                                addType="machine"
                                setField={()=>{}}
                                updateData={this.updateData}
                            />
                    }
                </div>
                <Input
                    label="Плановые трудозатраты"
                    type="text"
                    value={this.state.formControl.plan.value}
                    onChange={event => this.setField(event, "plan")}
                    shouldValidate={false}
                />
                {/*<Input
                    label="Количество деталей (план)"
                    type="number"
                    value={this.state.formControl.quantity.value}
                    valid={this.state.formControl.quantity.valid}
                    onChange={event => this.setField(event, "quantity")}
                    shouldValidate={true}
                />
                <Input
                    label="Количество выполненных деталей"
                    type="text"
                    value={this.state.formControl.quantityFact.value}
                    //onChange={event => this.setField(event, "plan")}
                    shouldValidate={false}
                />*/}
                <Myselect
                    value={this.state.formControl.status.value}
                    label="Статус"
                    options={[
                        {value: "inactive", label: "Не начат"},
                        {value: "active", label: "В работе"},
                        {value: "partially", label: "Частично выполнен"},
                        {value: "completed", label: "Завершен"}
                    ]}
                    onChange={event => this.setField(event, "status")}
                    shouldValidate={false}
                />
                {/*<Input
                    label="Фактические трудозатраты"
                    type="text"
                    value={this.state.formControl.fact.value}
                    onChange={event => this.setField(event, "fact")}
                    disabled={true}
                    shouldValidate={false}
                />*/}
                <div className="body-table">
                    <table className="miniTable" style={{ width: "100%" }}>
                        <thead>
                        <td>Сотрудник</td>
                        <td>Факт</td>
                        <td>Комментарий</td>
                        </thead>
                        <tbody>
                        {
                            (this.state.facts) ? this.state.facts.map((item, index) =>
                                <tr>
                                    <td>{(item.user) ? item.user.name : null}</td>
                                    <td>{item.fact}</td>
                                    <td>{item.comment}</td>
                                </tr>
                            ) : null
                        }
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    }}

export default AddMachine;