import React, {useEffect, useState} from "react";
import {FieldGen, setField, unLink, updateData} from "../../fieldGen";
import Input from "../../UI/Input";
import {FlowInput} from "../../marketing/chatBot/AllModal";
import formValid from "../../UI/formValid";

const HttpRequest = (props) => {
    const [state, setMyState] = useState({
        formControl: {
            name: {
                label: "Название",
                type: 'input',
                value:'',
                valid: true,
                validation: {required: true}
            },
            time_value: {
                type: 'input',
                label: 'Отложенный запуск на N секунд',
                tooltip: 'Процесс запустится через N секунд, после того, как сработает триггер',
                inputType: 'number',
                value: '',
                valid: true,
                validation: {required: false}
            },
            uri: {
                label: "Request URL",
                type: 'input',
                value:'',
                valid: true,
                inputType: 'url',
                validation: {required: true, website: true}
            },
            method: {
                label: "Метод",
                type: 'select',
                value:'GET',
                options:[
                    {value:'GET', label:'GET'},
                    {value:'POST', label:'POST'},
                    {value:'PUT', label:'PUT'},
                    {value:'PATCH', label:'PATCH'},
                    {value:'DELETE', label:'DELETE'},
                ],
                valid: true,
                validation: {required: true}
            },
            content_type: {
                label: "Тип данных",
                type: 'select',
                value:'none',
                options:[
                    {value:'none', label:'Нет'},
                    {value:'form', label:'FORM'},
                    {value:'json', label:'JSON'},
                    /*{value:'raw_body', label:'Raw Body'},*/
                ],
                valid: true,
                validation: {required: true}
            }
        },
        headers:[],
        params:[]
    });

    const setState = (st, func) => {
        Object.keys(st).map((key) => { state[key] = st[key]});
        setMyState(prevState => { return {...prevState, ...state} });
        if(func) func();
    };

    const convert = (type) => {
        let params = {};
        state[type].map(item => {
            params[item.key] = item.value
        });
        return params
    }

    const convertToArr = (obj) => {
        let arr = [];
        Object.keys(obj).map(key => {
            arr.push({key: key, value: obj[key]})
        });
        return arr;
    }


    const sendForm = () => {
        if(isFormValid()){
            if(props.elArr){
                setTimeout(
                    props.updateNode({
                        id:state.id,
                        name: state.formControl.name.value,
                        time_value: state.formControl.time_value.value,
                        is_scheduled: (state.formControl.time_value.value > 1),
                        metaData: {
                            uri: state.formControl.uri.value,
                            method: state.formControl.method.value,
                            content_type: state.formControl.content_type.value,
                            headers: convert("headers"),
                            params: convert("params")
                        },
                        nodeType: "node",
                        type:"http_request",
                    })
                )
            } else {
                setTimeout(
                    props.createNode({
                        name: state.formControl.name.value,
                        time_value: state.formControl.time_value.value,
                        is_scheduled: (state.formControl.time_value.value > 1),
                        metaData: {
                            uri: state.formControl.uri.value,
                            method: state.formControl.method.value,
                            content_type: state.formControl.content_type.value,
                            headers: convert("headers"),
                            params: convert("params")
                        },
                        nodeType: "node",
                        type:"http_request",
                    })
                )
            }
        }
    };

    useEffect(() => {
        getParams();
    },[]);

    const getParams = () => {
        if(props.elArr){
            let formControl = state.formControl;
            formControl.name.value = props.elArr.metaData ? props.elArr.name : '';
            formControl.time_value.value = props.elArr.metaData ? props.elArr.time_value : '';
            formControl.uri.value = props.elArr.metaData ? props.elArr.metaData.uri : '';
            formControl.method.value = props.elArr.metaData ? props.elArr.metaData.method : '';
            formControl.content_type.value = props.elArr.metaData ? props.elArr.metaData.content_type : '';
            setState({ formControl, id: props.elArr.id || null, headers: convertToArr(props.elArr.metaData.headers), params: convertToArr(props.elArr.metaData.params), })
        }
    }

    const isFormValid = () => {
        let formControl = state.formControl;
        let isFormValid = true;
        Object.keys(formControl).map((key, index) => {
            formControl[key].valid = formValid(formControl[key].value, formControl[key].validation);
            isFormValid = formControl[key].valid && isFormValid
        });
        setState({formControl});
        return isFormValid;
    };

    return <div>
        <div className="rmod-header">
            <b>HTTP Request</b>
            <a onClick={props.close} style={{ marginRight: "60px" }} className="mclose"><i className="flaticon-close" /></a>
            <div className="togglelist"><button onClick={sendForm} className="rmod-ok" disabled=""><i className="flaticon-interface-1" /></button></div>
        </div>
        <div className="mod-cont sbform">
            <FieldGen
                arr={state.formControl}
                setField={(e, type) => {
                    setState({ formControl: setField(state.formControl, type, e) })
                }}
                updateData={(id, arr, type) => setState({ formControl: updateData(state.formControl,id, arr, type) })}
                unLink={(type) => setState({ formControl: unLink(state.formControl, type)})}
            />
            <Headers state={state} setState={setState} type="headers" />
            {state.formControl.content_type.value === "form" || state.formControl.content_type.value === "json" ? <Data state={state} setState={setState} type="headers" /> : null}
        </div>
    </div>
}

export default HttpRequest

const Headers = ({ state, setState }) => {

    const delElement = (index) => {
        const headers = state.headers;
        delete headers[index];
        setState({ headers })
    };

    const setValue = (index, type, e) => {
        const headers = state.headers;
        headers[index][type] = e;
        setState({ headers })
    }

    return <div><div className="connection" style={{marginTop: "20px"}}><span>Headers</span><div className="toggleparrent"><button
        onClick={() => {
            const headers = state.headers;
            headers.push({ key: '', value:'' })
            setState({ headers })
        }} className="btni">+</button></div></div>
        <table className="filterBpm" style={{ width: "100%" }}>
            {
                state.headers.map((item, index) =>
                    <tr>
                        <td><Input
                            value={item.key}
                            valid={item.key.length > 1}
                            onChange={e => setValue(index, "key", e.target.value)}
                        /></td>
                        <td><FlowInput
                            isPaste
                            value={item.value}
                            valid={item.value.length > 1}
                            onChange={e => setValue(index, "value", e.target.value)}
                            paste={(value, paste) => setValue(index,"value", value + '' + paste)}
                            pasteType={[]}
                        /></td>
                        <td style={{ width: "15px" }}><a onClick={() => delElement(index)} style={{ fontSize: "19px",color: "rgb(194, 38, 63)", display: 'block', textAlign: "center" }}>x</a></td>
                    </tr>
                )
            }
        </table>
    </div>
}

const Data = ({ state, setState }) => {

    const delElement = (index) => {
        const params = state.params;
        delete params[index];
        setState({ params })
    };

    const setValue = (index, type, e) => {
        const params = state.params;
        params[index][type] = e;
        setState({ params })
    }

    return <div><div className="connection" style={{marginTop: "20px"}}><span>Данные</span><div className="toggleparrent"><button
        onClick={() => {
            const params = state.params;
            params.push({ key: '', value:'' })
            setState({ params })
        }} className="btni">+</button></div></div>
        <table className="filterBpm" style={{ width: "100%" }}>
            {
                state.params.map((item, index) =>
                    <tr>
                        <td><Input value={item.key}  valid={item.key.length > 1} onChange={e => setValue(index, "key", e.target.value)} /></td>
                        <td><FlowInput
                            isPaste
                            value={item.value}
                            valid={item.value.length > 1}
                            onChange={e => setValue(index, "value", e.target.value)}
                            paste={(value, paste) => setValue(index,"value", value + '' + paste)}
                            pasteType={[]}
                        /></td>
                        <td style={{ width: "15px" }}><a onClick={() => delElement(index)} style={{ fontSize: "19px",color: "rgb(194, 38, 63)", display: 'block', textAlign: "center" }}>x</a></td>
                    </tr>
                )
            }
        </table>
    </div>
}
