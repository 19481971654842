import React from "react";
import {GenerateForm} from "../../../components/cardViewComponents/GenerateForm";
import {productConfig} from "../ProductViewNew";
import {AdditionalFieldsChanged} from "../../../components/additionalFields";
import MainEditor from "../../../components/UI/Editor";

export const InfoTab = ({ id, values, errors, disabled, setValues, changeFields, saveItem, setModalData, priceBooks, unlinkPrice = [], loading }) => {
    return (
        <div className="tab-content sbform">
            <div className="connection">
                <span onClick={() => setModalData({ type: 'priceBook' })} style={{ cursor: 'pointer' }}>Прайс-листы</span>
                <div onClick={() => setModalData({ type: 'priceBook' })} className="toggleparrent"><button className="btni">+</button>
                </div>
            </div>
            {
                priceBooks.length > 0 && <div className="sp-table body-table">
                    <table style={{ borderSpacing: '0px 2px', display: 'table' }}>
                        <thead>
                        <tr className="fst-tr">
                            <td>Название</td>
                            <td>Цена</td>
                            <td>Валюта</td>
                            <td />
                        </tr>
                        </thead>
                        <tbody>
                        {(priceBooks || []).map(item => (
                            <tr key={item.id}>
                                <td><a onClick={(item.priceBook.id !== 1) ? () => setModalData({ type: 'priceBook', item: item }) : false}>{item.priceBook.name}</a></td>
                                <td>{item.default_price}</td>
                                <td>{(item.currency) ? item.currency.name : null}</td>
                                <td><div className="toogle-bottom fl-r">
                                    <a className="is"><i className="flaticon-more" /></a>
                                    {(item.priceBook.id !== 1) ? <ul className="toogle-list">
                                        <li><a onClick={(item.priceBook.id !== 1) ? () => setModalData({ type: 'priceBook', item: item }) : false}><i className="flaticon-edit-1" /> <b>Редактировать</b></a></li>
                                        <li><a onClick={() => unlinkPrice(item.priceBook.id)} className="table-del"><i className="flaticon-delete-1" /> <b>Удалить</b></a></li>
                                    </ul> : null}
                                </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            }

            <div className="connection"><span>Параметры</span></div>
            <GenerateForm
                config={productConfig}
                { ...{ values, errors, disabled } }
                onChange={changeFields}
                onSaveData={saveItem}
                zone={2}
            />

            <div className="connection"><span>Дополнительная информация</span></div>
            <AdditionalFieldsChanged
                errors={errors}
                items={values.additionalFields}
                updateAdditionalFields={data => setValues({ additionalFields: data, tch: false })}
            />
            <div className="clear" />
            <div className="fgroup rc12">
                <MainEditor
                    label={'Описание'}
                    value={values.description}
                    height={350}
                    valid={!errors.description}
                    errorMessage={errors.description}
                    onChange={description => {
                        setValues({ description, tch: false })
                    }}
                />
            </div>
        </div>
    )
}