import React, {useRef, useState} from "react";
import {useOnClickOutside} from "../../../components/hooks/useOnClickOutside";
import {CSSTransition} from "react-transition-group";

export const StatusDropDown = ({ items = [], active, CurrentRender, ItemRender, onChange = () => {}, disabled = false }) => {
    const [dropDownOpen, setDropDownOpen] = useState(false)
    const dropdownRef = useRef(null)
    useOnClickOutside({ ref: dropdownRef, handler: () => setDropDownOpen(false) })

    const current = items && active && items.find(el => String(el.value) === String(active))
    return (
        <div className={'new-task-status'} ref={dropdownRef}>
            {
                CurrentRender ?
                    <div onClick={() => setDropDownOpen(!dropDownOpen)}>{CurrentRender(current)}</div> :
                    <div
                        style={{ backgroundColor: current && current.color }}
                        className="new-task-status-current" onClick={() => setDropDownOpen(!dropDownOpen)}>
                        {current ? current.label : 'Not status'}
                        {!disabled && <div className="new-task-status-current-icon">
                            <i className={`${dropDownOpen && 'new-task-status-current-icon-rotate'}`}>
                                &#10094;
                            </i>
                        </div>}
                    </div>
            }

            <CSSTransition in={dropDownOpen && !disabled} timeout={200} classNames="slide" unmountOnExit>
                <div className="new-task-status-items scrollbar" style={{ maxHeight: '200px' }}>
                    {
                        items.map((item) => (
                            <div key={item.value}>
                                {
                                    <>
                                        {
                                            ItemRender && item && current ? <div onClick={() => {onChange(item.value); setDropDownOpen(false)}}>{ItemRender(item)}</div> :
                                                <div
                                                    onClick={() => {
                                                        //if(item.value !== current.value) {
                                                            onChange(item.value);
                                                            setDropDownOpen(false)}
                                                        //}
                                                    }
                                                    className="new-task-status-item">
                                                    {item.label}
                                                </div>
                                        }
                                    </>
                                }
                            </div>
                        ))
                    }
                </div>
            </CSSTransition>
        </div>
    )
}