import React from 'react';

const Requisites = props => {
    return <div>
        <div className="connection">
            <span>Реквизиты</span>
            <div className="toggleparrent">
                <button onClick={()=>props.openAddress("open", "requisites")} className="btni">+</button>
            </div>
        </div>
        <div className="sp-table body-table">
            <table style={{borderSpacing: "0 2px", display: (props.requisites.length >= 1) ? "table" : "none"}}>
                <thead>
                <tr className="fst-tr">
                    <td>Название</td>
                    <td>Реквизиты</td>
                    <td style={{width: "25px"}}>{null}</td>
                </tr>
                </thead>
                <tbody>
                {
                    props.requisites.map((item, index) =>
                        <RequisitesItem
                            key={index}
                            id={item.id}
                            name={(item.name) ? item.name.slice(0, 30) : null}
                            req={(item.details) ? item.details.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '').slice(0, 70) : null}
                            open={() => props.openAddress("open", "requisites", item.id)}
                            delete={() => props.deleteModal("delete", "requisites", index, item.id)}
                        />
                    )
                }
                </tbody>
            </table>
        </div>
    </div>
}

export default Requisites;

const RequisitesItem = props => {
    return <tr>
        <td><a onClick={props.open} href={null}>{props.name}</a></td>
        <td>{props.req}</td>
        <td><div className="toogle-bottom fl-r">
            <a className="is" href="#"><i className="flaticon-more" /></a>
            <ul className="toogle-list">
                <li><a onClick={props.open} href={null}><i className="flaticon-edit-1" /> <b>Редактировать</b></a></li>
                <li><a onClick={props.delete} className="table-del"><i className="flaticon-delete-1" /> <b>Удалить</b></a></li>
            </ul>
        </div>
        </td>
    </tr>
}