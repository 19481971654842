import React, {useEffect, useState} from "react";
import myaxios from "../Axios";
import {validationData} from "../UI/formValid";
import Notif from "../Notif";
import {ModalLoader} from "../UI/Loader";
import Modal from "../Modal/Modal";
import {GenerateForm} from "./GenerateForm";
import {validationConfig} from "./validationConfig";

const addressesConfig = {
    address_type: {
        label: 'Тип',
        options: [],
        options_from: 'types',
        type: 'select',
        className: 'w100',
        validation: { required: true, maxLength: 255 },
        zone: 1,
    },
    country: {
        label: 'Страна',
        type: 'text',
        className: 'w100',
        validation: { required: true, maxLength: 255 },
        zone: 1
    },
    region: {
        label: 'Область',
        type: 'text',
        className: 'w100',
        validation: { maxLength: 255 },
        zone: 1
    },
    city: {
        label: 'Город',
        type: 'text',
        className: 'w100',
        validation: { maxLength: 255 },
        zone: 1
    },
    street: {
        label: 'Адрес',
        type: 'text',
        className: 'w100',
        validation: { maxLength: 255 },
        zone: 1
    },
    postal_code: {
        label: 'Индекс',
        type: 'number',
        className: 'w100',
        validation: { maxLength: 255, number: true },
        zone: 1
    },
}

const initialValue = {
    address_type: 'factual',
    street: '',
    city: '',
    region: '',
    country: '',
    postal_code: '',
    is_main: '',
    tch: true
}

export const AddressesModalNew = ({ entity, id }) => {
    const [loading, setLoading] = useState(true)
    const [open, setOpen] = useState(false)
    const [updateAddress, setUpdateAddress] = useState(null)
    const [items, setItems] = useState([])
    const [errors, setErrors] = useState({})
    const [values, setValues] = useState({
        types: [
            {value: "factual", label: "Фактический"},
            {value: "legal", label: "Юридический"},
            {value: "delivery_address", label: "Адрес доставки"},
        ],
        address_type: 'factual',
        street: '',
        city: '',
        region: '',
        country: '',
        postal_code: '',
        is_main: '',
        tch: true
    })

    const changeFields = (key, value, item) => {
        setValues(prevState => ({
            ...prevState,
            [key]: item ? item.id : value,
            tch: false
        }))
    }

    const getData = async () => {
        try {
            const { data, status } = await myaxios.get(`/ajax/${entity}/${id}/addresses?per-page=100`)
            if(status === 200 && data.items) {
                setItems(data.items || [])
            }

        } catch (e) { }

        setLoading(false)
    }

    useEffect(() => {
        setValues(prevState => ({ ...prevState, ...JSON.parse(JSON.stringify(initialValue)) }))
    }, [open])

    useEffect(() => {
        getData().then()
    }, [])

    const saveData = async () => {
        const errorsData = validationData(values, validationConfig(addressesConfig))
        setErrors(errorsData)
        if(Object.keys(errorsData).length > 0) {
            Notif('required-fields')
            return
        }

        const { address_type, city, country, region, street, postal_code } = values
        const postData = { address_type, city, country, region, street, postal_code }

        setLoading(true)
        try {
            const { status } = updateAddress ?
                await myaxios.post(`/ajax/${entity}/${id}/update-address/${updateAddress}`, postData) :
                await myaxios.post(`/ajax/${entity}/${id}/add-address`, postData)

            if(status === 200 || status === 201) {
                setOpen(false)
                setUpdateAddress(null)
                await getData()
                Notif('save', 'Данные успешно обновлены')
            }
        } catch (e) { }
        setLoading(false)
    }

    const deleteITem = async (address_id) => {
        if(!window.confirm('Удалить адрес?')) {
            return false;
        }

        setLoading(true)
        try {
            const { status } = await myaxios.post(`/ajax/${entity}/${id}/remove-address/${address_id}`)
            if(status === 200 || status === 204) {
                Notif('delete')
                setItems(prevState => prevState.filter(el => String(el.address_id) !== String(address_id)))
            }
        } catch (e) { }
        setLoading(false)
    }

    return (
        <div className={'addresses-new'}>
            {loading && <ModalLoader />}
            <div className="connection">
                <span onClick={() => setOpen(true)} style={{ cursor: 'pointer' }}>Адреса</span>
                <div onClick={() => setOpen(true)} className="toggleparrent"><button className="btni">+</button>
                </div>
            </div>
            {
                items.length > 0 && <div className="sp-table body-table">
                    <table style={{ borderSpacing: '0px 2px', display: 'table' }}>
                        <thead>
                        <tr className="fst-tr">
                            <td>Тип адреса</td>
                            <td>Страна</td>
                            <td>Город</td>
                            <td>Адрес</td>
                            <td>Индекс</td>
                            <td />
                        </tr>
                        </thead>
                        <tbody>
                        {
                            items && items[0] && items.map(({ address_id, address }) => (
                                <tr
                                    onDoubleClick={() => {
                                        setValues(prevState => ({ ...prevState, ...address, tch: true }))
                                        setUpdateAddress(address_id)
                                    }}
                                    onClick={(e) => e.ctrlKey && deleteITem(address_id)}
                                    className={'sp-table-tr-item'}
                                    key={address_id}
                                    data-tooltip={'1. Двойной клик для редактирования \n2. CTRL + клик для удаления'}
                                    style={{ cursor: 'pointer' }}>
                                    <td>{address.address_type && values.types.find(el => address.address_type === el.value).label}</td>
                                    <td>{address.country}/{address.region}</td>
                                    <td>{address.city}</td>
                                    <td>{address.street}</td>
                                    <td>{address.postal_code}</td>
                                    <td>
                                        <div className="toogle-bottom fl-r">
                                            <a className="is"><i className="flaticon-more" /></a>
                                            <ul className={'toogle-list'}>
                                                <li><a onClick={() => deleteITem(address_id)}>Удалить</a></li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
            }

            <Modal showLeaveModal tch={values.tch} title={'Адрес'} isOpen={open || updateAddress} save={saveData} close={() => {
                setOpen(false)
                setUpdateAddress(null)
            }}>
                <GenerateForm
                    config={addressesConfig}
                    { ...{ values, errors } }
                    onChange={changeFields}
                    disabled={{}}
                    onSaveData={saveData}
                />
            </Modal>
        </div>
    )
}