import React, {useContext, useEffect, useRef, useState} from "react";
import Input from "../../../../components/UI/Input";
import {ContactCard} from "../../../../components/cardViewComponents/ContactCard";
import Products from "../../../../components/products";
import Myselect from "../../../../components/UI/Select";
import CustomCheckbox from "../../../../components/UI/CustomCheckbox";
import {NewAutoSelect} from "../../../../components/NewUi/NewAutoSelect";
import Notif from "../../../../components/Notif";
import {getBalanceService} from "../../../../services/other/balance";
import {ModalLoader} from "../../../../components/UI/Loader";
import PrintPlates from "../../../../Modal/PrintPlates";
import {MemoButton} from "../../../../components/cardViewComponents/MemoButton";
import myaxios from "../../../../components/Axios";
import {GlobalState} from "../../../../Layout/Layout";
import {validationData} from "../../../../components/UI/formValid";
import {
    createInvoiceService,
    deleteInvoiceService,
    getInvoicePrepareService,
    updateInvoiceService
} from "../../../../services/invoice/invoiceService";

export const InvoiceCashWindow = () => {
    const [cashTitle, setCashTitle] = useState({
        1: ' Нет счета',
        2: ' Нет счета',
        3: ' Нет счета',
    })
    const [cash, setCash] = useState(1)
    const [loading, setLoading] = useState(true)
    const [values, setValues] = useState({
        statuses: [],
        stocks: [],
        balances: []
    })

    const getPrepareData = async () => {
        try {
            const { status, data } = await getInvoicePrepareService()
            if(status !== 200) {
                Notif('Error')
                setLoading(false)
                return;
            }
            const { status: balanceStatus, data: balanceData } = await getBalanceService()
            if(balanceStatus !== 200) {
                Notif('Error')
                setLoading(false)
                return;
            }
            setValues({
                statuses: data.statuses,
                stocks: data.stocks,
                balances: balanceData.items
            })
            setLoading(false)
        } catch (e) {
            Notif('Error')
            setLoading(false)
        }
    }

    useEffect(() => {
        getPrepareData().then()
    },[])

    if(loading) {
        return <ModalLoader />
    }

    return (
        <div className="page sbform">
            <div className="stocktop">
                <div className="stocktop__scroll">
                    <a onClick={() => setCash(1)} className="stockItem">
                        <i className={cash === 1 ? 'flaticon-pin' : 'flaticon-tool'} />
                        <span style={{ color: cash === 1 && '#669295' }}>{cashTitle[1]}</span>
                    </a>
                    <a onClick={() => setCash(2)} className="stockItem">
                        <i className={cash === 2 ? 'flaticon-pin' : 'flaticon-tool'} />
                        <span style={{ color: cash === 2 && '#669295' }}>{cashTitle[2]}</span>
                    </a>
                    <a onClick={() => setCash(3)} className="stockItem">
                        <i className={cash === 3 ? 'flaticon-pin' : 'flaticon-tool'} />
                        <span style={{ color: cash === 3 && '#669295' }}>{cashTitle[3]}</span>
                    </a>
                </div>
            </div>
            <CashWindowItem
                statuses={values.statuses}
                stocks={values.stocks}
                balances={values.balances}
                isOpen={cash === 1}
                setCashTitle={title => setCashTitle({ ...cashTitle, 1: title })}
            />
            <CashWindowItem
                statuses={values.statuses}
                stocks={values.stocks}
                balances={values.balances}
                isOpen={cash === 2}
                setCashTitle={title => setCashTitle({ ...cashTitle, 2: title })}
            />
            <CashWindowItem
                statuses={values.statuses}
                stocks={values.stocks}
                balances={values.balances}
                isOpen={cash === 3}
                setCashTitle={title => setCashTitle({ ...cashTitle, 3: title })}
            />
        </div>
    )
}

const initialState = {
    name: '',
    status_id: null,
    stock_id: null,
    owner_id: null,
    owner: null,
    contact_id: null,
    contact: null,
    is_reserved: 0,
    balance_id: null,
    cost: 0,
    payment: 0,
    shipment: 2,
    prefix_id: null,
    inv_id: null,
    payment_detail_id: '',
    paymentDetail: null,
    provider_id: '',
    provider: null,
    receipt: 0
}

const cashOrderWindowValidConfig = {
    inv_id: { required: true },
    prefix_id: { required: true },
    status_id: { required: true },
    stock_id: { required: true },
    // owner_id: { required: true },
    balance_id: { required: true },
    payment: { number: true },
}

const CashWindowItem = ({ statuses = [], stocks = [], balances = [], isOpen, setCashTitle }) => {
    const { state } = useContext(GlobalState)
    const cashWindowMainRef = useRef(null)
    const [orderId, setOrderId] = useState(null)
    const [values, setValues] = useState(initialState)
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [successData, setSuccessData] = useState(null)
    const [isStatusTouched, setIsStatusTouched] = useState(false)
    const [prefixes, setPrefixes] = useState([])

    const createOrder = async () => {
        setLoading(true)

        try {
            const copyInitialState = JSON.parse(JSON.stringify(initialState))
            const { data } = await getInvoicePrepareService()

            const { status, data: orderData } = await createInvoiceService({
                data: {
                    prefix_id: data.prefixes[0].id,
                    inv_id: data.nextId,
                    status_id: statuses[0].id,
                    stock_id: stocks[0].id
                }, expand: 'owner,provider,paymentDetail'
            })
            setPrefixes(data.prefixes)

            if(status === 201) {
                copyInitialState.inv_id = orderData.inv_id;
                copyInitialState.prefix_id = orderData.prefix_id;
                copyInitialState.balance_id = balances[0].id;
                copyInitialState.status_id = orderData.status_id;
                copyInitialState.stock_id = orderData.stock_id;
                copyInitialState.provider_id = orderData.provider_id;
                copyInitialState.provider = orderData.provider;
                copyInitialState.payment_detail_id = orderData.payment_detail_id;
                copyInitialState.paymentDetail = orderData.paymentDetail;
                setValues(copyInitialState)

                setOrderId(orderData.id)
                setCashTitle(`${orderData.name} (в процессе)`)
            }

        } catch (e) {}
        setLoading(false)
    }

    const [receiptData, setReceiptData] = useState(null)

    const receiptUpdate = async () => {
        if(values.receipt === 0 || values.payment === 0) {
            return;
        }
        setLoading(true)
        try {
            const { status, data } = await myaxios.post(`/ajax/invoice/${orderId}/create-receipt`)
            if(status !== 201 && !data.id) {
                Notif('Error')
                return;
            }

            setReceiptData(data)
            if(values.receipt === 1) {
                return false;
            }

            const { status: updateStatus, data: updateData } =  await myaxios.put(`/ajax/checkbox/receipt/${data.id}`, {
                total_payment: values.payment,
                type: values.receipt=== 3 ? "CASH" : "CARD"
            })

            if(updateStatus !== 200 && !updateData.id) {
                Notif('Error')
                return;
            }
            setReceiptData(updateData)

            const { status: fisStatus, data: fisData } = await myaxios.post(`/ajax/checkbox/receipt/${data.id}/send`)
            if(fisStatus !== 200 && !fisData.id) {
                Notif('Error')
                return;
            }
            setReceiptData(fisData)

        } catch (e) {}
        setLoading(false)
    }

    const updateCheck = async () => {
        setLoading(true)
        try {
            const { status, data } = await myaxios.get(`/ajax/checkbox/receipt/${receiptData.id}/update`)
            if(status === 200 && data.id) {
                setReceiptData(data)
            }
        } catch (e) {

        }
        setLoading(false)
    }

    const saveOrder = async () => {
        const errorsData = validationData(values, cashOrderWindowValidConfig);

        setErrors(errorsData)
        if(Object.keys(errorsData).length > 0) {
            Notif('required-fields')
            return
        }

        setLoading(true)
        const { name, status_id, stock_id, contact_id, is_reserved, owner_id, balance_id, payment, shipment, inv_id, prefix_id, provider_id, payment_detail_id } = values;

        const { status, data } = await updateInvoiceService({ id: orderId, data: {
                inv_id, prefix_id, owner_id, stock_id , contact_id, is_reserved, provider_id, payment_detail_id, status_id: isStatusTouched ? status_id : 4
            }
        })

        setSuccessData(prevState => ({
            ...prevState,
            order: data
        }))

        setCashTitle('Нет счета')

        if(values.payment > 0) {
            const { data: paymentData } = await myaxios.post(`/ajax/invoicepaymentnew/${orderId}/payment`, { balance_id, amount: payment, currency_id: state.baseCurrency.id, date: "2022-07-14 00:04", exchange_rate: 1 })
            setSuccessData(prevState => ({
                ...prevState,
                payment: paymentData
            }))
        }

        if(shipment >= 1) {
            const { data: shipmentData, status: shipmentStatus } = await myaxios.post(`/ajax/invoice/${orderId}/create-shipment`)
            if (shipmentStatus === 200 || shipmentStatus === 201 || shipmentStatus === 204) {
                setSuccessData(prevState => ({
                    ...prevState,
                    shipment: shipmentData
                }))
                if(shipment === 2) {
                    const {} = await myaxios.put(`/ajax/stock/shipment/${shipmentData}`, {
                        name: shipmentData.name, is_completed: 1
                    })
                }
            }
        }

        setOrderId('')
        await receiptUpdate()
        setLoading(false)
        if(cashWindowMainRef) cashWindowMainRef.current.focus()
    }

    useEffect(() => {
        if(cashWindowMainRef && isOpen) cashWindowMainRef.current.focus()
    }, [isOpen])

    const deleteItem = async () => {
        if(window.confirm('Удалить заказ?')) {
            setLoading(true)
            try {
                const { status } = await deleteInvoiceService({ id: orderId })
                if(status === 200 || status === 204) {
                    Notif('delete', 'Счет успешно удален')
                    setOrderId(null)
                    setSuccessData(null)
                    setCashTitle('Нет счета')
                    if(cashWindowMainRef) cashWindowMainRef.current.focus()
                }
            } catch (e) { console.error(e) }
            setLoading(false)
        }
    }

    const updateRequisites = async (data_id) => {
        const { status, data } = await myaxios.get(`/ajax/account/${data_id}/payment-details`)
        if (status === 200 && data && data.items && data.items[0]) {
            setValues(prevState => ({ ...prevState, payment_detail_id: data.items[0].id, paymentDetail: data.items[0] }))
        }
    }

    if(!isOpen) {
        return <></>
    }

    return (
        <div style={{ position: 'relative' }}>
            {loading && <ModalLoader />}
            <div className="newList-btn-group" style={{ marginBottom: '10px' }}>
                <div className="newList-btn-group-left">
                    <PrintPlates entityId={14} entity={`order`} id={orderId} />
                </div>
                <div className="newList-btn-group-right">
                    <a onClick={deleteItem} className="butt-group-card-wrap btni ml10">
                        <span>Удалить</span>
                        <i className="flaticon-delete-2 mdb" />
                    </a>
                    <MemoButton onClick={saveOrder} disabled={values.tch} mobileIcon={"flaticon-interface-1 mdb"} title={'Завершить'} className={'ml5'} />
                </div>
            </div>
            <div className={'cash-window-flex'}>
                <div className="cash-window-block">
                    <h3>Данные счета</h3>
                    <div style={{ display: 'flex' }}>
                        <Myselect
                            label={'Префикс'}
                            value={values.prefix_id}
                            options={prefixes} val={'id'} lab={'name'} className={'w30'}
                            valid={!errors.prefix_id} errorMessage={errors.prefix_id} shouldValidate={true} disabled={true}
                        />
                        <Input
                            label={'Номер'} required
                            value={values.inv_id} className={'w70'}
                            // onChange={e => setValues({ ...values, name: e.target.value })}
                            valid={!errors.inv_id} errorMessage={errors.inv_id} shouldValidate={true} disabled={true}
                        />
                    </div>
                    <Myselect
                        label={'Статус счета'}
                        value={values.status_id}
                        options={statuses} val={'id'} lab={'value'}
                        onChange={e => {
                            setValues({ ...values, status_id: e.target.value })
                            if(+e.target.value !== values.status_id) setIsStatusTouched(true)
                        }}
                        valid={!errors.status_id} errorMessage={errors.status_id} shouldValidate={true}
                    />
                    <Myselect
                        label={'Склад'}
                        value={values.stock_id}
                        options={stocks} val={'id'} lab={'name'}
                        onChange={e => setValues({ ...values, stock_id: e.target.value })}
                        valid={!errors.stock_id} errorMessage={errors.stock_id} shouldValidate={true}
                    />
                    <div style={{ display: 'flex' }}>
                        <NewAutoSelect
                            label={'Продавец'}
                            link={'/ajax/account?fields=id,name&filter[name][like]='}
                            entity={values.provider}
                            className={'w50'}
                            updateData={(item) => {
                                setValues(prevState => ({
                                    ...prevState,
                                    provider_id: item && item.id, provider: item,
                                    payment_detail_id: '', paymentDetail: null
                                }))
                                if(item) setTimeout(() => updateRequisites(item.id).then(), 1000)
                            }}
                            clear redirect={'account'}
                        />
                        <NewAutoSelect
                            label={'Реквизиты продавца'}
                            link={`/ajax/account/${values.provider_id}/payment-details`}
                            entity={values.paymentDetail}
                            className={'w50'}
                            updateData={(item) => setValues(prevState => ({ ...prevState, payment_detail_id: item && item.id, paymentDetail: item }))}
                            clear hidePerPage refresh
                            disabled={!values.provider}
                        />
                    </div>
                    <NewAutoSelect
                        label={'Ответственный'}
                        searchBy={'name'}
                        link={`/ajax/contact?fields=id,name,first_name,email,mobile_phone,work_phone&filter[name][like]=`}
                        updateData={(item) => setValues({ ...values, owner: item, owner_id: item.id })}
                        entity={values.owner} minLength={1}
                        valid={!errors.owner_id} errorMessage={errors.owner_id} required
                    />
                </div>
                <div className="cash-window-block">
                    <ContactCard
                        contact={values.contact}
                        setDataValues={data => {
                            setValues(({...values, ...data, tch: false}))
                        }}
                    />
                </div>
                <div className="cash-window-block">
                    <h3>Данные оплаты/склад</h3>
                    <Myselect
                        label={'Кошелек'}
                        value={values.balance_id}
                        options={balances} val={'id'} lab={'name'}
                        onChange={e => setValues({ ...values, balance_id: e.target.value })}
                        valid={!errors.balance_id} errorMessage={errors.balance_id} shouldValidate={true}
                    />
                    <Input
                        label={'Сумма заказа'}
                        value={values.cost}
                        valid={!errors.cost} errorMessage={errors.cost} shouldValidate={true}
                        disabled
                    />
                    <div style={{ display: 'flex' }}>
                        <div style={{ width: '100%' }}>
                            <Input
                                label={'Сумма оплаты'}
                                value={values.payment}
                                onChange={e => setValues({ ...values, payment: e.target.value })}
                                valid={!errors.payment} errorMessage={errors.payment} required shouldValidate={true}
                            />
                        </div>
                        {/*<Input
                            label={'Сдача'}
                            value={values.payment - values.cost}
                            disabled
                        />*/}
                    </div>
                    <Myselect
                        label={'Отгрузка'}
                        value={values.shipment}
                        options={[
                            {value:0, label:'Не создавать'},
                            {value:1, label:'Создать'},
                            {value:2, label:'Создать и провести'},
                        ]}
                        onChange={e => setValues({ ...values, shipment: e.target.value })}
                    />
                    {state && state.integrations && state.integrations.checkbox && <Myselect
                        label={'Чек'}
                        value={values.receipt}
                        options={[
                            {value:0, label:'Не создавать'},
                            {value:1, label:'Создать'},
                            {value:2, label:'Создать и фискализировать (Карта)'},
                            {value:3, label:'Создать и фискализировать (Наличные)'},
                        ]}
                        onChange={e => setValues({ ...values, receipt: e.target.value })}
                    />}
                </div>
            </div>

            {orderId && <div className="cash-window-block" style={{ width: '100%', margin: '0px 0 0 0' }}>
                <Products
                    type="invoice" id={orderId} related="13" isTotal isPrice isDiscount isValues
                    updatePrice={sum => setValues({ ...values, cost: sum, payment: sum })}
                />
            </div>}

            {!orderId && <div style={{ position: 'absolute', background: 'rgba(255,255,255,0.6)', width: '100%', height: '100%', top: 0, left: 0, display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {
                        successData && (
                            <div className={'second-table'} style={{ background: '#FFF', boxShadow: '1px 0px 20px rgb(0 0 0 / 20%)', width: '1000px', marginBottom: '5px' }}>
                                <table style={{ width: '100%' }}>
                                    <tr>
                                        <th>Счет</th>
                                        <th>Печать счета</th>
                                        {+values.shipment > 0 && successData.shipment && <th>Отгрузка</th>}
                                        {+values.shipment > 0 && successData.shipment && <th>Печать отгрузки</th>}
                                        {+values.payment > 0 && successData.payment && <th>Платеж</th>}
                                        {+values.receipt > 0 && <th>Чек</th>}
                                        {+values.receipt >= 2 && receiptData && <th style={{ width: '230px' }}>Данные чека</th>}
                                    </tr>
                                    <tr>
                                        <td>
                                            <a target={'_blank'} href={`/invoice/${successData && successData.order.id}`}>
                                                Счет: {successData && successData.order.name}
                                            </a>
                                        </td>
                                        <td>
                                            {successData && successData.order && <PrintPlates entityId={13} entity={`invoice`} id={successData.order.id} />}
                                        </td>
                                        {+values.shipment > 0 && successData.shipment && <td>
                                            <a target={'_blank'} href={`/stock/shipment/${successData && successData.shipment}`}>
                                                Отгрузка №{successData && successData.shipment}
                                            </a>
                                        </td>}
                                        {+values.shipment > 0 && successData.shipment && <td>
                                            {successData && successData.shipment && <PrintPlates entityId={19} entity={`stock/shipment`} id={successData.shipment} />}
                                        </td>}
                                        {+values.payment > 0 && successData.payment && <td>Платеж: {successData && successData.payment.name}</td>}
                                        {+values.receipt > 0 && <td>
                                            {receiptData ? <a target={'_blank'} href={`/checkbox/receipt/${receiptData.id}`}>`Чек №${receiptData.id}`</a> : 'Чек не создан'}
                                        </td>}
                                        {+values.receipt >= 2 && receiptData && <td>
                                            {receiptData.status.toLowerCase() === "created" && <button onClick={updateCheck} className="butt-group-card-wrap success fl-l btni mr10">Обновить</button>}
                                            {receiptData.status.toLowerCase() === "done" && <a target="_blank" href={`https://${window.location.host}/ajax/checkbox/receipt/${receiptData.id}/html`} className="butt-group-card-wrap success fl-l btni mr10">HTML</a>}
                                            {receiptData.status.toLowerCase() === "done" && <a target="_blank" href={`https://${window.location.host}/ajax/checkbox/receipt/${receiptData.id}/pdf`} className="butt-group-card-wrap success fl-l btni mr10">PDF</a>}
                                            {receiptData.status.toLowerCase() === "done" && <a target="_blank" href={`https://${window.location.host}/ajax/checkbox/receipt/${receiptData.id}/qr`} className="butt-group-card-wrap success fl-l btni mr10">QR</a>}
                                        </td>}
                                    </tr>
                                </table>
                            </div>
                        )
                    }
                    <button style={{ fontSize: '18px', padding: '13px 30px' }} onClick={createOrder} className={'btni'}>Создать счет</button>
                </div>
            </div>}
            <input
                ref={cashWindowMainRef} style={{ width: 0, height: 0, border: 0 }}
                onKeyDown={e => e.keyCode === 13 && createOrder()}
            />
        </div>
    )
}