import CustomCheckbox from "../../UI/CustomCheckbox";
import React from "react";

export const TableHeadComponent = ({
    massDoConfig,
    activeCheckItems,
    checkItems,
    setCheckItems,
    headingColumns,
    list,
    columns,
    filterFields,
    additionalFields
}) => {
    const findColumnLabel = (col) => ([...filterFields, ...additionalFields].find(el => el.value === col))

    return (
        <thead>
        <tr className="fst-tr">
            {!!massDoConfig && activeCheckItems && <th style={{ width: '30px' }}>
                <CustomCheckbox
                    checked={checkItems.length === +list.length}
                    style={{ marginBottom: '-8px' }}
                    onChange={() => checkItems.length === +list.length ?
                        setCheckItems([]) : setCheckItems(list.map(el => (el.id)))
                    }
                />
            </th>}
            {
                (headingColumns || []).map(item => (
                    <th key={item} style={{ textAlign: 'left', paddingBottom: 0 }}>{item}</th>
                ))
            }
            {
                (columns || []).map(col => (
                    <th key={col} style={{ textAlign: 'left', paddingBottom: 0 }}>{findColumnLabel(col) && findColumnLabel(col).label}</th>
                ))
            }
            <th />
        </tr>
        </thead>
    )
}

export const TableHead = React.memo(TableHeadComponent)