import React, {useEffect, useState, lazy} from "react";
import myaxios from "../Axios";
import Bgdrop from "../../Modal/Bgdrop";
import {Binotel, Sender, TurboSMS, Wayforpay} from "./list/Binotel";
import {NavLink} from "react-router-dom";
import {SendPulse} from "./list/SendPulse";
import {Tracker} from "./list/Tracker";
const NovaPoshta = lazy(() => import(`./list/NovaPoshta`));
const SendEmail = lazy(() => import(`../../Modal/SendEmail`));
const Horoshop = lazy(() => import(`./list/Horoshop`));

export const IntegrationList = props => {
    const [myState, setMyState] = useState({
        items:[],
        open:'',
        openType:'',
        data:[],
        is_enabled:'',
        modal:false
    });
    const sState = (st, func) => {
        Object.keys(st).map((key) => { myState[key] = st[key]});
        setMyState(prevState => { return {...prevState, ...myState} });
        if(func) func();
    };
    const getItems = () => {
        myaxios.get(`/ajax/config`).then(res => {
            sState({ items: res.data.items })
        })
    };
    useEffect(()=>{
        getItems();
    }, []);
    const close = () => {
        sState({
            open:null, openType:'',id:null, data:[], is_enabled:''
        })
    }
    const otherModals = ['sendpulse'];
    const {items, open} = myState;
    return <div className={"page"}><div className={'integration'}>
        <h1>Доступные интеграции</h1>
        <ul>
            <li>
                <img src="https://sboxcrm.com/images/integration/new-integration.jpg" alt=""/>
                <h2>Заказать</h2>
                <p>интеграцию</p>
                <a onClick={() => sState({ modal: true })}>Заказать</a>
            </li>
            <li>
                <img src="https://sboxcrm.com/images/integration/sender.jpg" alt=""/>
                <h2>CRM Tracker</h2>
                <p>tracker</p>
                <a onClick={() => sState({ openType: 'tracker' })}>Настроить</a>
            </li>
            {
                items.filter(el => el.model !== 'sender').map(item =>
                    <li>
                        <img src={item.logo} alt=""/>
                        <h2>{item.name}</h2>
                        <p>{item.description}</p>
                        <a
                            className={+item.is_enabled === 1 ? 'active' : ''}
                            onClick={(e) => {
                                e.preventDefault()
                                sState({
                                    open: 'r-open',
                                    openType: item.model,
                                    id: item.id,
                                    data: item.data,
                                    is_enabled: item.is_enabled
                                })
                            }}>{+item.is_enabled === 1 ? 'Установлено' : "Установить"}
                        </a>
                    </li>
                )
            }
            {/*<li>
                <img src="https://sboxcrm.com/images/integration/sender.jpg" alt=""/>
                <h2>Sender</h2>
                <p>Трекер</p>
                <a
                    onClick={()=>sState({ open:'r-open',openType: "sender" })}>Установить
                </a>
            </li>*/}
            <li>
                <img src="https://sboxcrm.com/images/integration/telegram.jpg" alt=""/>
                <h2>Telegram</h2>
                <p>Мессенджер</p>
                <a onClick={() => props.history.push({ pathname: '/marketing/messengers/settings/', state: 'telegram' })}>Настроить</a>
            </li>
            <li>
                <img src="https://sboxcrm.com/images/integration/viber.jpg" alt=""/>
                <h2>Viber</h2>
                <p>Мессенджер</p>
                <a onClick={() => props.history.push({ pathname: '/marketing/messengers/settings/', state: 'viber' })}>Настроить</a>
            </li>
            <li>
                <img src="https://sboxcrm.com/images/integration/fbMes.jpg" alt=""/>
                <h2>Facebook</h2>
                <p>Мессенджер</p>
                <a onClick={() => props.history.push({ pathname: '/marketing/messengers/settings/', state: 'fbMessenger' })}>Настроить</a>
            </li>
            <li>
                <img src="https://sboxcrm.com/images/integration/instagram.jpg" alt=""/>
                <h2>Instagram</h2>
                <p>Мессенджер</p>
                <a onClick={() => props.history.push({ pathname: '/marketing/messengers/settings/', state: 'instagram' })}>Настроить</a>
            </li>
            <li>
                <img src="https://sboxcrm.com/images/integration/checkbox.png" alt=""/>
                <h2>Checkbox</h2>
                <p>PPO</p>
                <NavLink to={"/settings/checkbox"}>Настроить</NavLink>
            </li>
            {/*<li>
                <img src="https://sboxcrm.com/images/integration/ggl2.png" alt=""/>
                <h2>Google Adwords</h2>
                <p>Реклама</p>
                <a>Скоро</a>
            </li>*/}
        </ul>
    </div>
        <SendEmail
            isOpen={myState.modal}
            close={() => sState({ modal:false })}
            title="Заказать интеграцию" type={"New Integration"}
        />
        <React.Fragment>
            <div className={"right-modal scrollbar " + open}>
                {myState.openType === "binotel" ? <Binotel id={myState.id} close={() => close()} refresh={() => {getItems(); close(); }} data={myState.data} is_enabled={myState.is_enabled}  /> : null}
                {myState.openType === "turbosms" ? <TurboSMS id={myState.id} close={() => close()} refresh={() => {getItems(); close(); }} data={myState.data} is_enabled={myState.is_enabled}  /> : null}
                {myState.openType === "wayforpay" ? <Wayforpay id={myState.id} close={() => close()} refresh={() => {getItems(); close(); }} data={myState.data} is_enabled={myState.is_enabled}  /> : null}
                {myState.openType === "sender" ? <Sender id={myState.id} close={() => close()} refresh={() => {getItems(); close(); }} data={myState.data} is_enabled={myState.is_enabled}  /> : null}
                {myState.openType === "np" ? <NovaPoshta id={myState.id} close={() => close()} refresh={() => {getItems(); close(); }} data={myState.data} is_enabled={myState.is_enabled}  /> : null}
            </div>
            {open ? <Bgdrop close={() => close()} /> : null}
        </React.Fragment>

        {myState.openType === "horoshop" ? <Horoshop isOpen={myState.openType === "horoshop"} id={myState.id} close={() => close()} refresh={() => {getItems(); close(); }} data={myState.data} is_enabled={myState.is_enabled}  /> : null}
        {myState.openType === 'sendpulse' && <SendPulse
            metaData={myState.data}
            saveNode={() => {getItems(); close()}}
            close={() => setMyState({ ...myState, openType: '', open: '' })}
        />}
        {myState.openType === 'tracker' && <Tracker close={() => close()} history={props.history} />}
    </div>
}
