import {createTagService} from "../../../services/other/tags";


export const createTagTest = async (entity, id, tags) => {
    try {
        const res = await createTagService(entity, id, tags)

        if (res && res.status === 200) {
            return { status: true }
        } else {
            console.error('create tag status error')
            return { status: false }
        }
    } catch (e) {
        console.error(`create tag catch error`, e)
        return { status: false }
    }
}