import React, {Component} from 'react';
import myaxios from '../components/Axios';
import Notif from '../components/Notif';

function restoreOpp(type, id) {
    myaxios.post(`/ajax/${type}/${id}/restore`).then((response)=> {
        if(response.status === 200 || response.status === 201 || response.status === 204){
            Notif('save')
        }}
    )
}


const EndBlock = props => {
    return <div className="endBlock">
        <div className="rc9">
            {(props.is_deleted === 1) ? <h2>{props.deletedName}</h2> : (props.is_closed === 1) ? <h2>Сделка провалена</h2> : (props.is_won === 1) ? <h2>Сделка успешно завершена</h2> : null}
            <p>Все действия заблокированы. Для восстановления кликните на кнопку "Восстановить"</p>
        </div>
        <div className="rc3">
            <button onClick={() => restoreOpp(props.type, props.id)} className="success fl-r btni">Восстановить</button>
        </div>
        <div className="clear" />
    </div>
}

export default EndBlock;