import React from "react";
import {InfoTable} from "../../components/cardViewComponents/InfoTable";
import {GenerateForm} from "../../components/cardViewComponents/GenerateForm";
import {opportunityConfig} from "./OpportunityViewNew";
import {AdditionalFieldsChanged} from "../../components/additionalFields";
import MainEditor from "../../components/UI/Editor";

export const InfoTab = ({ values, errors, disabled, changeFields, id, saveItem, opportunityConfig, setValues }) => {
    const { created_at, updated_at } = values

    return (
        <div className="tab-content sbform clearFieldPadding">
            <div className="connection" style={{ marginTop: 0 }}><span>Основная информация</span></div>
            <InfoTable
                arr={[
                    { value: 'Дата создания', label: created_at },
                    { value: 'Дата обновления', label: updated_at },
                ]}
            />
            <div className="connection"><span>Дополнительная информация</span></div>
            <GenerateForm
                config={opportunityConfig}
                { ...{ values, errors, disabled } }
                onChange={changeFields}
                onSaveData={saveItem}
                zone={2}
            />
            <AdditionalFieldsChanged
                errors={errors}
                items={values.additionalFields}
                updateAdditionalFields={data => setValues({ additionalFields: data, tch: false })}
            />
            <div className="clear" />
            <div className="fgroup rc12">
                <MainEditor
                    label={'Описание'}
                    value={values.description}
                    height={350}
                    valid={!errors.description}
                    errorMessage={errors.description}
                    onChange={description => {
                        if(description !== values.description) {
                            setValues({ description })
                        }
                    }}
                />
            </div>
        </div>
    )
}