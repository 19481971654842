import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

function openEl(cls, th) {
    let a = document.getElementsByClassName(`stageClass` + cls);
    for(let i = 0; i < a.length; i++){
        if(a[i].style.display === "block"){
            a[i].parentNode.children[0].children[0].children[0].innerHTML = "+"
            a[i].style.display = "none";
        } else {
            a[i].parentNode.children[0].children[0].children[0].innerHTML = "-"
            a[i].style.display = "block"
        }

    }
}

const StageItem = props => {
    return <div>
        <Item padding={10} item={props.stage} {...props} />
        {
            props.stage.descendants ? props.descendants.map((item2, index) =>
                <div style={{ display: "none" }} className={"stageClass" + props.stage.id}>
                    <Item padding={30} item={item2} {...props} />
                    {
                        item2.descendants ? item2.descendants.map((item3, index2) =>
                            <div style={{ display: "none" }} className={"stageClass" + item2.id}>
                                <Item padding={50} item={item3} {...props} />
                                {
                                    item3.descendants ? item3.descendants.map((item4, index3) =>
                                        <div style={{ display: "none" }} className={"stageClass" + item3.id}>
                                            <Item padding={70} item={item4} {...props} />
                                        </div>
                                    ) : null
                                }
                            </div>
                        ) : null
                    }
                </div>
            ) : null
        }
    </div>
};

export default withRouter(StageItem);

const Item = props => {
    const item = props.item;
    return <div style={{ paddingLeft: props.padding }} className="flexDiv">
        <div className="stageParItem" style={{ width: "50%" }}>{item.descendants ? item.descendants.length > 0 ? <span onClick={(node) => openEl(item.id, node)} className="openStage">+</span> : null : null}<a onClick={() => props.history.push(`/project/work/` + item.id)} href={null}>
            {item.name}</a></div>
        <div className="stageParItem">{item.startDate || ''}</div>
        <div className="stageParItem">{item.endDate || ''}</div>
        <div className="stageParItem">{item.percentComplete || 0}</div>
        <div className="stageParItem">{item.statusLabel}</div>
        <div className="stageParItem">
            <div className="toogle-bottom fl-r">
                <a className="is" href="#"><i className="flaticon-more" /></a>
                <ul className="toogle-list">
                    <li><a onClick={() => props.history.push(`/project/work/` + item.id)}><i className="flaticon-edit-1" /> <b>Редактировать</b></a></li>
                    <li><a onClick={() => props.stagesFunc("delete", "stages", item.id, null, item.index)} className="table-del"><i className="flaticon-delete-1" /> <b>Удалить</b></a></li>
                </ul>
            </div>
        </div>
    </div>
        }