import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import Loader from './UI/Loader';
import myaxios from './Axios';
import Notif from './Notif';
import EntityListSettings from '../Modal/EntityListSettings';
import Bgdrop from '../Modal/Bgdrop';
import Input from './UI/Input';
import Myselect from './UI/Select';
import ModAutoSelect from './UI/ModAutoSelect';
import DateTime from './UI/DateTime';
import Rfm from "../Modal/rfm";
import {TASK_PRIORITY_OBJ_COLORS, TASK_PRIORITY_OBJ} from "../const";
import CustomCheckbox from "./UI/CustomCheckbox";
import {entity} from './ListComponentEntity';
import SmsSending from "../Modal/SmsSending"
import AddPlate from "../Modal/AddPlate";
import AddExpense from "../Modal/AddExperense";
import StockNav from "../containers/stock/StockNav";
import AddRule from "../Modal/AddRule";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import SingleSearch from "./other/SingleSearchField";
import AddProviderReturn from "../Modal/AddProviderReturn";
import IconButton from "./other/IconButton";
import MessengersSending from "../Modal/MessengersSending";
import AddEmailForm from "../Modal/AddEmailForm";
import EmailCampaigns from "../Modal/emailCampaigns";
import EmailSending from "../Modal/EmailSending";
import CreateWorkOrder from "../Modal/Stock/AddWorkOrder";
import TurnoverDetail from "./other/turnoverDetail";
import withGlobalState from "./other/withGlobalState";
import FinanceButtons from "./other/FinanceButtons";
import {CommonUastalModal} from "./only-uastal/CommonModal/CommonModal";

function searchIndex(arr, id, par){
    for(let i = 0; i < arr.length; i++){
        if(arr[i]){
            if(par){
                if(arr[i].id == id){
                    return i
                }
            } else {
                if(arr[i].value == id){
                    return i
                }
            }
        }
    }
}

class ListComponent extends Component {
    state = {
        items:[],
        expand: entity[this.props.name].expand,
        extraExpand: entity[this.props.name].extraExpand,
        thList: entity[this.props.name].thList,
        bottomList: entity[this.props.name].bottomList,
        adField:[],
        loading: true,
        checkedmap: [],
        allcheck: false,
        page: 1,
        perPage: 20,
        totalCount: Number,
        pageCount: Number,
        open: null,
        openType:'',
        preFilter:[],
        filter:[],
        filterRequest:[],
        filterId:null,
        extraData:[],
        editVal:'',
        editValId: null,
        fld:'',
        required:'',
        requiredValue:'',
        fldType:'',
        openModalId:null,
        isCheckboxActive: false,
        deleteButton: false,
        contactInvoiceModal: false
    };

    refresh = () => {
        const urlParams = new URLSearchParams(this.props.location.search);
        const key = urlParams.get('page');
        this.getItems()
        /*if(+key > 1){
            this.props.history.push(this.props.match.url);
        } else {
            this.getItems()
        }*/
    }

    callRefresh = (params) => {
        this.props.history.push(this.props.match.url)
        this.getItems()
    }

    getFilter = (params) => {
        myaxios.get(`/ajax/filter?filter[entity_type]=${entity[this.props.name].entityNumb}&[user_id]=${this.props.userId}`).then(res => {
            if(res.data.items[0]){
                this.setState({
                    filterRequest: res.data.items[0].filterAttributes || [],
                    filter: JSON.parse(JSON.stringify(res.data.items[0].filterAttributes)) || [],
                    extraData: res.data.items[0].columns || [],
                    filterId: res.data.items[0].id
                }, params ? this.callRefresh : this.getItems)
            } else {
                this.callRefresh(params);
            }
        })
    }
    getItems = () => {
        console.log("params", this.state.extraData)
        myaxios.get(entity[this.props.name].getLink + this.props.location.search + `${(this.props.location.search) ? '&sort=-created_at' : '?sort=-created_at'}&expand=${this.state.expand}${this.state.extraData.length > 0 ? `,${this.state.extraExpand}` : ''}${entity[this.props.name].customFilter ? entity[this.props.name].customFilter : ''}` + this.convertFilter()).then(res => {
            this.setState({
                items: res.data.items,
                totalCount: res.data._meta ? res.data._meta.totalCount : '',
                pageCount: res.data._meta ? res.data._meta.pageCount : '',
                loading: false,
                /*filter: entity[this.props.name].fArr && this.state.filter.length === 0 ? [
                    {
                        field: entity[this.props.name].fArr[0].value,
                        fieldType: entity[this.props.name].fArr[0].fieldType,
                        obj: null,
                        operator: operator[entity[this.props.name].fArr[0].fieldType][0].value,
                        options: [],
                        value: ""
                    }
                ] : [],*/
            });
        }).then().catch((error) => {
            this.setState({
                loading: false
            });
            /*if(error.response){
                if(error.response.status === 422) {
                    let prs = JSON.parse(localStorage.getItem('filterAccount'));
                    for(let i = 0; i < error.response.data.length; i++){
                        this.clearFunc(error.response.data[i].field);
                        delete prs[error.response.data[i].field];
                        Notif("Error", error.response.data[i].message);
                    }
                    localStorage.setItem('filterAccount', JSON.stringify(prs))
                }
            } else {
                let filterParams = this.state.filterParams;
                Object.keys(filterParams).map((key) => {
                    filterParams[key].value = null;
                    if(filterParams[key].key === "multiple") filterParams[key].multiple = []
                });
                this.setState({ filterParams });
            }*/
        });
    };
    convertAddFields = (items) => {
        let arr = [];
        items.map(item => {
            arr.push({
                value: item.field_id,
                label: item.label,
                fieldType: (item.type === "select" || item.type === "multiselect") ? 'select' : (item.type === "date") ? 'date' : 'text',
                options: item.options
            })
        });
        let arr2 = entity[this.props.name].fArr
        arr2 = arr2.concat(arr);
        return arr2;
    }
    getAdditionalParams = () => {
        if(entity[this.props.name].adField){
            myaxios.get(`/ajax/eav/attribute?entity_type=${entity[this.props.name].entityNumb}`).then(res => {
                if(res) this.setState({ adField: this.convertAddFields(res.data.items)}, this.getFilter);
            });
        } else {
            if(entity[this.props.name].fArr){
                this.setState({ adField: entity[this.props.name].fArr }, this.getFilter)
            } else {
                this.getItems()
            }
        }
    };
    loading = () => {
        /*document.title = entity[this.props.name].title;
        const urlParams = new URLSearchParams(this.props.location.search);
        const key = urlParams.get('page');
        this.setState({
            page: key || 1, expand: entity[this.props.name].expand,
            extraExpand: entity[this.props.name].extraExpand,
            thList: entity[this.props.name].thList,
            bottomList: entity[this.props.name].bottomList,
            adField:[],
            checkedmap: [],
            allcheck: false,
            open: null,
            openType:'',
            filter:[],
            filterId:null,
            extraData:[]
        }, this.getItems);
        if(entity[this.props.name].fArr){
            this.getAdditionalParams();
        }*/

        document.title = entity[this.props.name].title;
        const urlParams = new URLSearchParams(this.props.location.search);
        const key = urlParams.get('page');
        this.setState({
            page: key || 1,
            expand: entity[this.props.name].expand,
            extraExpand: entity[this.props.name].extraExpand,
            thList: entity[this.props.name].thList,
            bottomList: entity[this.props.name].bottomList,
            adField:[],
            checkedmap: [],
            allcheck: false,
            open: null,
            openType:'',
            addOpenButtonModal:'',
            filterId:null,
            extraData:[],
            filter:[],
            filterRequest:[]
        }, this.getAdditionalParams);
    }
    componentDidMount(){
        this.loading();
    }
    componentDidUpdate(nextProps, nextState){
        if(this.props.location.search !== nextProps.location.search){
            setTimeout(this.getItems(), 200);
        }

        if(this.props.name !== nextProps.name){
            this.setState({ loading: true });
            this.loading();
        }
    }

    convertFilterChild = (name) => {
        let arr = [];
        this.state.filter.map((item,index) => {
            if(item.field === name){
                arr.push({
                    value:item.value,
                    operator:item.operator
                })
            }
        });
        return arr;
    }
    convertFilter = () => {
        let obj = {};
        let link = '';
        this.state.filter.filter(item => item.value).map((item,index) => {
            obj[item.field] = {arr:[]};
        });


        Object.keys(obj).map((key, index) => {
            obj[key].arr = this.convertFilterChild(key);
        });

        Object.keys(obj).map((key, index) => {
            obj[key].arr.map((item,ind) => {
                if(item.operator === 'like' || item.operator === 'nlike'){
                    link+= `&filter[or][][${key}][${item.operator}]=${item.value}`
                }
                if(item.operator === 'eq' || item.operator === 'neq' || item.operator === '!=' || item.operator === '>' || item.operator === '<' || item.operator === '>=' || item.operator === '<='){
                    link+= `&filter[${key}][${item.operator}]=${item.value}`
                }
                if(item.operator === 'in' || item.operator === 'nin'){
                    if(item.operator === 'nin'){
                        link+= `&filter[${key}][${item.operator}][]=${item.value}` //&filter[or][][${key}][is]=NULL
                    } else {
                        link+= `&filter[${key}][${item.operator}][]=${item.value}`
                    }
                }
            })
        });
        return link;
    }

    toPage = to => {
        setTimeout(this.props.history.push(`${this.props.match.url}?page=${to}`), 50);
        this.setState({ page: to })
    };

    deleteBut = () => {
        if(window.confirm("Удалить элементы?")){

            const notdel = (elements) => {
                let str = `Не удалены ${elements.length} ID: `;
                elements.map((item, index) => {
                    if(index !== elements.length) str += item + ','
                    else str += item
                });
                Notif("Error", str);
            }

            const ids = [];
            this.state.items.map(item =>  {
                if(item.checked) ids.push(item.id)
            })

            myaxios.post(entity[this.props.name].delAll, {ids: ids}).then(res =>{
                if(res){
                    if(res.status === 200 || res.status === 201 || res.status === 204){
                        this.setState({loading: false, allcheck: false});
                        Notif("delete", `Успешно удалено: ${res.data.deleted.length}`);
                        if(res.data.not_deleted.length > 0) notdel(res.data.not_deleted)
                    }
                }
            }).catch(() => this.setState({loading: false}));
            setTimeout(this.getFilter(), 500);
        }
    };

    delItemFunc = (index, id, title) =>{
        if(window.confirm()) {
            myaxios.delete(`/ajax${entity[this.props.name].deleteLink ? entity[this.props.name].deleteLink : entity[this.props.name].link}${id}`).then(res => {
                if(res){
                    if(res.status === 200 || res.status === 201 || res.status === 204){
                        const items = this.state.items.concat();
                        items.splice(index, 1);
                        this.setState({items});
                        Notif("delete", `Элемент успешно удален`);
                    }
                }
            })
        }
    };


    renderItems = item => {
        if(item.fieldType === "entity" || item.fieldType === "lookup" || item.fieldType === "tags" || item.fieldType === "products"){
            if(item.obj){
                return item.obj[this.state.adField[searchIndex(this.state.adField, item.field)].filterSearch]
            }
        } else if(item.fieldType === "select"){
            if(item.value){
                let index = searchIndex(this.state.adField, item.field);
                return this.state.adField[index].options[searchIndex(this.state.adField[index].options, item.value, "id")].label;
            }
        } else {
            return item.value
        }
    };

    saveFilter = (params) => {
        if(this.state.filterId){
            myaxios.put(`/ajax/filter/${this.state.filterId}`, {
                name: entity[this.props.name].exportType,
                entity_type:entity[this.props.name].entityNumb,
                user_id: this.props.userId || 1,
                filter_attributes: this.state.filter.filter(item => item.value) ,
                columns:this.state.extraData
            }).then(res => {
                if(res){
                    if(res.status === 200 || res.status === 201 || res.status === 204){
                        this.setState({ open:null, openType:'', page: 1 }, this.getFilter(params))
                        this.props.setSteps("about-list", true, true)
                    }
                }
            })
        } else {
            myaxios.post(`/ajax/filter`, {
                name: entity[this.props.name].exportType,
                entity_type:entity[this.props.name].entityNumb,
                user_id: this.props.userId || 1,
                filter_attributes: this.state.filter.filter(item => item.value),
                columns:this.state.extraData
            }).then(res => {
                if(res){
                    if(res.status === 200 || res.status === 201 || res.status === 204){
                        this.setState({ open:null, openType:'', page: 1 }, this.getFilter(true))
                        this.props.setSteps("about-list", true, true)
                    }
                }
            })
        }
    };
    saveField = () => {
        myaxios.put(entity[this.props.name].getLink + `/${this.state.editValId}`, {
            [this.state.required]: this.state.requiredValue,
            [this.state.fld]: this.state.editVal
        }).then(res => {
            Notif('save', 'Поле успешно сохранено');
            this.setState({ open:'', openType:'', fldType:'' })
            this.getItems();
        })
    };

    checkActiveCheckbox = () => {
        const active = [];
        this.state.items.map(item => {
            if(item.checked) active.push(item)
        })
        this.setState({ allcheck: active.length === this.state.items.length, deleteButton: active.length > 0 })
    };

    toggleChecked = (index) => {
        const items = this.state.items;
        items[index].checked = !items[index].checked
        this.setState({ items, isCheckboxActive: true }, this.checkActiveCheckbox)
    };

    toggleAllChecked = () => {
        const items = this.state.items;
        items.map(item => item.checked = !this.state.allcheck)
        this.setState({ items, allcheck: !this.state.allcheck }, this.checkActiveCheckbox)
    };

    render(){
        const {setStep = () => {}} = this.props
        const {filter, filterRequest} = this.state;
        return <div>
            {this.state.loading && <Loader contain />}
            <CSSTransition in={!this.state.loading} timeout={{ enter: 200, exit: 200 }} classNames="my-node" unmountOnExit>
        <div className="page">
            {entity[this.props.name].stock && <StockNav />}
            <div className="butt-group">
                <div className="mdn xsn toogle-bottom fl-r">
                    {entity[this.props.name].isExport || entity[this.props.name].importLink || entity[this.props.name].fieldSettings || entity[this.props.name].columnSettings ? <a className="fl-r btns"><i className="flaticon-more" /></a> : null}
                    <ul className="toogle-list">
                        {entity[this.props.name].isExport ? <li><a onClick={() => {this.props.updateData("Export", {type: entity[this.props.name].exportType, filter: this.convertFilter() } )}}><i className="flaticon-folder-2" /> <b>Экспорт</b></a></li> : null}
                        {entity[this.props.name].importLink ? <li><a onClick={() => this.props.history.push(entity[this.props.name].importLink)}><i className="flaticon-folder-3" /> <b>Импорт</b></a></li> : null}
                        {entity[this.props.name].fieldSettings ? <li><a onClick={() => this.props.history.push(entity[this.props.name].fieldSettings)}><i className="flaticon-cogwheel-1" /> <b>Настройка полей</b></a></li> : null}
                        {entity[this.props.name].columnSettings ? <li><a onClick={() => this.setState({open:'r-open',openType:'extraFields'})}><i className="flaticon-interface-8" /> <b>Настройка колонок</b></a></li> : null}
                        {entity[this.props.name].delAll ? <li><a onClick={() => this.setState({ isCheckboxActive: !this.state.isCheckboxActive })}><i className="flaticon-interface-1" /> <b>Массовые действия</b></a></li> : null}
                        <li><a target="_blank" href="https://sboxcrm.com/docs/list"><i className="flaticon-questions-circular-button" /> <b>Работа со списком</b></a></li>
                    </ul>
                </div>
                {this.props.name === "stockAnalytics" && <a target="_blank" href={`https://${window.location.host.split('.')[0]}.sboxcrm.com/ajax/stock/analytics?per-page=200&export=1${this.convertFilter()}`} className={`success btni mr10`}>Экспорт</a>}
                <div className="toogle-bottom fl-r">
                    <div className="mobile-block" style={{ display: "none" }}>
                        {entity[this.props.name].addButText && entity[this.props.name].addButLink ? <a onClick={() => {this.props.updateData(entity[this.props.name].addButLink, "")}} className="fl-l btni"><span className="mobile-close">&#10005;</span></a> : null}
                        {entity[this.props.name].addOpenButton ? <a onClick={() => this.setState({ open: 'r-open', openType:entity[this.props.name].addOpenButton, openModalId: null  })} className="fl-l btni"><span className="mobile-close">&#10005;</span></a> : null}
                        {entity[this.props.name].addOpenButtonModal ? <a onClick={() => this.setState({ addOpenButtonModal: entity[this.props.name].addOpenButtonModal })} className="fl-l btni"><span className="mobile-close">&#10005;</span></a> : null}
                        {entity[this.props.name].calendar ? <a onClick={() => this.props.history.push(entity[this.props.name].calendar)} style={{ float:"left" }} className="btns"><i className="flaticon-calendar-3" /></a> : null}
                    </div>
                    {entity[this.props.name].fArr ? <a onClick={() => this.setState({ open:'r-open', openType:'filter' })} data-tooltip="Фильтр" className="fl-r btns"><i className="flaticon-interface-3">{null}</i></a> : null}
                    {/*<ul className="toogle-list">
                     <li><a><i className="flaticon-interface-8" /> <b>Фильтр</b></a></li>
                     <li><a><i className="flaticon-list-2" /> <b>Сортировка</b></a></li>
                     <li><a><i className="flaticon-tea-cup" /> <b>Активные</b></a></li>
                     <li><a><i className="flaticon-interface-1" /> <b>Удаленные</b></a></li>
                     </ul>*/}
                </div>

                {entity[this.props.name].fArr && <SingleSearch
                    state={this.state}
                    setFilter={(filter) => this.setState({ filter: filter })}
                    saveFilter={() => this.saveFilter(true)}
                    entity={entity[this.props.name].fArr}
                    operator={operator}
                    addSingleFilter={() => {
                        let filter = [{
                            field: entity[this.props.name].fArr[0].value,
                            fieldType: entity[this.props.name].fArr[0].fieldType,
                            obj: null,
                            operator: operator[entity[this.props.name].fArr[0].fieldType][0].value,
                            options: [],
                            value: ""
                        }]
                        this.setState({ filter })
                    }}
                />}

                <div className="mdn">
                    {(this.state.deleteButton >= 1) ? <a onClick={this.deleteBut} className="fl-r btns"><i className="flaticon-delete-1">{null}</i></a> : null}
                    {entity[this.props.name].analitics ? <a data-tooltip="Отчеты" onClick={() => this.props.history.push(entity[this.props.name].analitics)} className="xsn fl-l btns" style={{float:'left'}}><i className="flaticon-diagram" /></a> : null}
                    {entity[this.props.name].calendar ? <a onClick={() => this.props.history.push(entity[this.props.name].calendar)} style={{ float:"left" }} className="btns"><i className="flaticon-calendar-3" /></a> : null}
                    {entity[this.props.name].kanban ? <a onClick={() => this.props.history.push(entity[this.props.name].kanban)} style={{ float:"left" }} className="btns"><i className="flaticon-web" /></a> : null}
                    {entity[this.props.name].addButText && entity[this.props.name].addButLink ? <a onClick={() => {setStep(); this.props.updateData(entity[this.props.name].addButLink, "")}} className="open-modal-button fl-l btni">{entity[this.props.name].addButText}</a> : null}
                    {entity[this.props.name].addOpenButton ? <a onClick={() => {setStep(); this.setState({ open: 'r-open', openType:entity[this.props.name].addOpenButton, openModalId: null  })}} className="open-modal-button fl-l btni">{entity[this.props.name].addButText}</a> : null}
                    {entity[this.props.name].addOpenButtonModal ? <a onClick={() => {setStep(); this.setState({ addOpenButtonModal: entity[this.props.name].addOpenButtonModal })}} className="open-modal-button fl-l btni">{entity[this.props.name].addButText}</a> : null}
                    {
                        /*<a onClick={() => this.setState({ contactInvoiceModal: true })} style={{ marginLeft: '5px' }} className={'open-modal-button fl-l btni'}>Массовое создание</a>*/
                    }

                    {this.props.buttons && this.props.buttons({ loading: this.loading })}
                    {entity[this.props.name].docs && <IconButton href={entity[this.props.name].docs} />}
                </div>

            </div>

            <div className="clear" />
            <div style={{marginTop:'10px'}}>
                {
                    filterRequest.length > 0 ? filterRequest.map((item,index) =>
                        item.value && <div key={index} className="filterItem">
                            <em>{this.state.adField[searchIndex(this.state.adField, item.field)] ? this.state.adField[searchIndex(this.state.adField, item.field)].label : ''}:</em> [{item.operator}] <b> </b>
                            {this.renderItems(item)}
                            <a onClick={() => {
                                if(filter.length > 1){
                                    filter.splice(index, 1);
                                    this.setState({ filter }, this.saveFilter);
                                } else {
                                    filter[0].value = "";
                                    filter[0].obj = null
                                    this.setState({ filter }, this.saveFilter);
                                }
                            }}>x</a>
                        </div>
                    ) : null
                }
            </div>



            {this.state.items.length > 0 ? <div className="contact-page sp-table">
                <table>
                    <thead>
                    <tr className="fst-tr">
                        {this.state.isCheckboxActive ? <td style={{width: '40px'}}>
                            <CustomCheckbox
                                checked={this.state.allcheck}
                                onChange={this.toggleAllChecked}
                                style={{ margin: "0 auto" }}
                            />
                        </td> : null}
                        {
                            entity[this.props.name].thList.map((item, index)=>
                                <td key={index}>{item}</td>
                            )
                        }
                        {
                            this.state.extraData ? this.state.extraData.map((item, index) =>
                                this.state.adField[searchIndex(this.state.adField, item)] ? <td data-tooltip={this.state.adField[searchIndex(this.state.adField, item)].label} key={index}>{this.state.adField[searchIndex(this.state.adField, item)].label}</td> : null
                            ) : null
                        }
                        <td style={{width: '50px'}} />
                    </tr>
                    </thead>
                    <tbody>
                    <TransitionGroup className="transition-group-screen">
                    {
                        this.state.items ? this.state.items.map((item, i) =>
                            <CSSTransition
                                key={item.id}
                                timeout={{ enter: 200, exit: 200 }}
                                classNames="slide"
                            >
                            <tr key={i} className="pull-up" onClick={e => {
                                if(e.ctrlKey) this.toggleChecked(i)
                            }}>
                                {this.state.isCheckboxActive ? <td style={{ padding: !this.state.isCheckboxActive ? "0px" : 'inherit'  }}>
                                    <CustomCheckbox
                                        checked={item.checked}
                                        onChange={() => this.toggleChecked(i)}
                                        style={{ margin: "0 auto" }}
                                    />
                                </td> : null}
                                {this.props.tr ? this.props.tr({item}) :
                                    this.state.bottomList.map((bItems, bI) =>
                                        <td key={bI}>
                                            <TdType
                                                isedit={(val) => this.setState({ open:'r-open', openType: 'editField', editVal:val, editValId: item.id, requiredValue: item[entity[this.props.name].required], required: entity[this.props.name].required, fld: bItems.top.val, fldType: bItems.top.fldType})}
                                                id={item.id}
                                                entity={entity[this.props.name].entity} {...this.props}
                                                edit={bItems.top.edit} type={bItems.top.type}
                                                val={bItems.top.val}
                                                from={bItems.top.from}
                                                bItems={bItems}
                                                desc={bItems.top.desc}
                                                item={item}
                                                link={entity[this.props.name].link}
                                                cur={bItems.top.currency}
                                                updateData={this.props.updateData}
                                                updType={entity[this.props.name].addButLink}
                                                openModal={(a,b) => this.setState({ open: 'r-open', openType:a, openModalId: b })}
                                                addOpenButtonModal={(a, b) => this.setState({ addOpenButtonModal: a, openModalId: b })}
                                                opnType={entity[this.props.name].addOpenButton}
                                                options={bItems.top.options}
                                                colors={bItems.top.colors}
                                            />
                                            {bItems.bottom ? <TdType
                                                id={item.id}
                                                isedit={(val) => this.setState({ open:'r-open', openType: 'editField', editVal:val, requiredValue: item[entity[this.props.name].required], required: entity[this.props.name].required, fld: bItems.bottom.val, fldType: bItems.bottom.fldType})}
                                                entity={entity[this.props.name].entity} {...this.props}
                                                edit={bItems.bottom.edit}
                                                type={bItems.bottom.type}
                                                from={bItems.bottom.from}
                                                val={bItems.bottom.val}
                                                bItems={bItems}
                                                desc={bItems.bottom.desc}
                                                item={item}
                                                link={entity[this.props.name].link}
                                                updateData={this.props.updateData}
                                                updType={entity[this.props.name].addButLink}
                                                options={bItems.top.options}
                                                colors={bItems.top.colors}
                                                cur={bItems.bottom.currency}
                                            /> : null}
                                        </td>
                                    )
                                }
                                <ExtraDataComp
                                    extraData={this.state.extraData}
                                    lead={item}
                                />
                                <td>
                                    <div className="toogle-bottom fl-r">
                                        <a className="is"><i className="flaticon-more"/></a>
                                        <ul className="toogle-list">
                                            <li><a onClick={() => this.props.history.push(entity[this.props.name].link + item.id)}><i className="flaticon-edit-1" /> <b>Редактировать</b></a></li>
                                            <li><a onClick={this.delItemFunc.bind(this, i, item.id)} className="table-del" href={null}><i className="flaticon-delete-1" /><b>Удалить</b></a></li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            </CSSTransition>
                        ) : null
                    }
                    </TransitionGroup>
                    </tbody>
                </table>
                <div className="mailsearch" style={{marginTop: "0px"}} >
                    <div className="mailsearch-l" style={{ width: '50%', margin:10 }}>
                        {/*<Myselect
                         value={this.state.perPage}
                         options={[
                         {value:20,label:'20'},
                         {value:50,label:'50'},
                         {value:100,label:'100'},
                         ]}
                         />*/}

                    </div>
                    <Pagination pagesAmount={Math.ceil(this.state.totalCount / this.state.perPage)} currentPage={Number(this.state.page)} setCurrentPage={e => this.toPage(e)} />
                </div>
            </div> : <span className="empty-table">В разделе нет ни одной записи</span>}


            <React.Fragment>
                {this.props.name === "smsSending" && <SmsSending isOpen={this.state.addOpenButtonModal === "SmsSending"} close={() => this.setState({ addOpenButtonModal: '' })} />}
                {this.props.name === "AddPlate" && <AddPlate isOpen={this.state.addOpenButtonModal === "AddPlate"} close={() => this.setState({ addOpenButtonModal: '' })} />}
                {this.props.name === "Expense" && <AddExpense isOpen={this.state.addOpenButtonModal === "AddExpense"} close={() => this.setState({ addOpenButtonModal: '' })} />}
                {this.props.name === "rules" && <AddRule isOpen={this.state.addOpenButtonModal === "AddRule"} close={() => this.setState({ addOpenButtonModal: '' })} />}
                {this.props.name === "stockReturn" && <AddProviderReturn isOpen={this.state.addOpenButtonModal === "AddProviderReturn"} close={() => this.setState({ addOpenButtonModal: '' })} />}
                {this.props.name === "messengersSending" && <MessengersSending isOpen={this.state.addOpenButtonModal === "MessengersSending"} close={() => this.setState({ addOpenButtonModal: '' })} />}
                {this.props.name === "emailForms" && <AddEmailForm isOpen={this.state.addOpenButtonModal === "emailForms"} close={() => this.setState({ addOpenButtonModal: '' })} />}
                {this.props.name === "emailCampaigns" && <EmailCampaigns isOpen={this.state.addOpenButtonModal === "emailCampaigns"} close={() => this.setState({ addOpenButtonModal: '' })} />}
                {this.props.name === "emailBroadcast" && <EmailSending isOpen={this.state.addOpenButtonModal === "emailBroadcast"} close={() => this.setState({ addOpenButtonModal: '' })} />}
                {this.props.name === "workorder" && <CreateWorkOrder getItems={this.getItems} isOpen={this.state.addOpenButtonModal === "workorder"} close={() => this.setState({ addOpenButtonModal: '' })} />}

                <div className="bpmModal">
                    <div style={{ right: -800 }} className={"right-modal scrollbar " + this.state.open}>
                        {(this.state.openType === "RfmAccount" || this.state.openType === "RfmContact") ?
                            <Rfm
                                refresh={() => this.getItems()}
                                id={this.state.openModalId}
                                type={(this.state.openType === "RfmAccount" ? "account" : "contact")}
                                close={() => this.setState({ open:'', openType:'', openModalId:null })}
                            /> :  null
                        }
                        {(this.state.openType === "extraFields") ?
                            <EntityListSettings filter={this.state.adField}
                                                myfilter={this.state.filter}
                                                required={entity[this.props.name].columnDel}
                                                custom={this.state.extraData}
                                                refresh={() => {this.setState({open:null, openType:''}, this.getFilter)}}
                                                opportunity={entity[this.props.name].entity}
                                                field_settings={`${entity[this.props.name].entity}_field_settings`}
                                                entityNumber={entity[this.props.name].entityNumb}
                                                userId={this.props.userId}
                                                filterId={this.state.filterId}
                                                exportType={entity[this.props.name].exportType}
                                                close={() => this.setState({ open:null, openType:'' })}

                            /> : null}
                        {this.state.openType === "filter" ? <div className="sbform create-activ">
                            <div className="rmod-header">
                                <b>Фильтр</b>
                                <a className="mclose 13" style={{marginRight: "60px"}} onClick={()=>this.setState({ open:null,openType:'' })}><i className="flaticon-close" /></a>
                                <div className="togglelist"><button onClick={() => this.saveFilter(true)} className="rmod-ok"><i className="flaticon-interface-1" /></button></div>
                            </div>
                            <div className="mod-cont">
                                <div className="connection" style={{marginTop: "10px"}}><span>Фильтр</span><div className="toggleparrent">
                                    <button
                                        onClick={() => {
                                            filter.push(
                                                {
                                                    field: this.state.adField[0].value,
                                                    operator: operator[this.state.adField[0].fieldType][0].value,
                                                    value:'',
                                                    fieldType:this.state.adField[0].fieldType,
                                                    options: this.state.adField[0].options,
                                                    obj:null
                                                }
                                            );
                                            this.setState({ filter })
                                        }

                                        }
                                        className="btni">+</button>
                                </div></div>
                                <table className="filterBpm" style={{width: "100%"}}>
                                    <thead>
                                    <tr>
                                        <td style={{padding: "0px 5px", width: "20%"}}>Поле</td>
                                        <td style={{padding: "0px 5px", width: "20%"}}>Оператор</td>
                                        <td style={{padding: "0px 5px"}}>Значение</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        filter ? filter.map((item,i) =>
                                            <FilterItem
                                                filter={filter}
                                                key={i}
                                                fields={this.state.adField}
                                                field={item.field}
                                                operator={item.operator}
                                                //options={item.options}
                                                options={this.state.adField[searchIndex(this.state.adField, item.field)].options}
                                                type={item.type}
                                                value={item.value}
                                                arr={item.arr}
                                                search={item.search}
                                                index={i}
                                                fieldType={item.fieldType}
                                                obj={item.obj}
                                                setField={(type, e) => {
                                                    filter[i][type] = e;
                                                    this.setState({ filter });
                                                }}
                                                delItem={i => {
                                                    //delete filter[i];
                                                    filter.splice(i, 1)
                                                    this.setState({ filter });
                                                }}
                                                updData={(ind, id, arr) => {
                                                    filter[i].obj = arr;
                                                    filter[i].value = id;
                                                    this.setState({ filter });
                                                }}
                                                unLink={() => {
                                                    filter[i].obj = null;
                                                    filter[i].value = '';
                                                    this.setState({ filter });
                                                }}
                                            />
                                        ) : null
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div> : null}
                        {(this.state.openType === "editField") ?
                            <div className="sbform create-activ">
                                <div className="rmod-header">
                                    <b>Редактировать поле</b>
                                    <a className="mclose 13" style={{marginRight: "60px"}} onClick={()=>this.setState({ open:null,openType:'' })}><i className="flaticon-close" /></a>
                                    <div className="togglelist"><button onClick={this.saveField} className="rmod-ok"><i className="flaticon-interface-1" /></button></div>
                                </div>
                                <div className="mod-cont">
                                    <Input
                                        value={this.state.editVal}
                                        onChange={e => this.setState({ editVal: e.target.value })}
                                        type={this.state.fldType || 'text'}
                                    />
                                </div>
                            </div> : null}
                    </div>
                </div>



                {(this.state.open === "r-open") ? <Bgdrop close={() => this.setState({ open:'', openType:'', openModalId:null })} /> : null}
            </React.Fragment>
        </div>
            </CSSTransition>
            {
                <CommonUastalModal isOpen={this.state.contactInvoiceModal} close={() => this.setState({ contactInvoiceModal: false })} />
            }

        </div>
    }
}

export default withRouter(withGlobalState(ListComponent));

const ExtraDataComp = props => {
    let lead = props.lead;
    lead.owner_id = lead.owner ? lead.owner.name : lead.owner_id;
    lead.stock_id = lead.stock ? lead.stock.name : lead.stock_id;
    lead.order_id = lead.order ? lead.order.name : lead.order_id;
    lead.acc_id = lead.account ? lead.account_id : null;
    lead.con_id = lead.contact ? lead.contact_id : null;
    lead.account_id = lead.account ? lead.account.name : lead.account_id;
    lead.contact_id = lead.contact ? lead.contact.name : lead.contact_id;
    lead.provider_id = lead.provider ? lead.provider.name : lead.provider_id;
    lead.category_id = lead.category ? lead.category.name : lead.category_id;
    lead.manufacturer_id = lead.manufacturer ? lead.manufacturer.name : lead.manufacturer_id;
    lead.opportunity_id = lead.opportunity ? lead.opportunity.name : lead.opportunity_id;
    lead.project_id = lead.project ? lead.project.name : lead.project_id;
    lead.currency_id = lead.currency ? lead.currency.name : lead.currency_id;
    if(lead.additionalFields){
        for(let i = 0; i < lead.additionalFields.length; i++){
            if(lead.additionalFields[i]){
                if(lead.additionalFields[i].type.name === "select" && lead.additionalFields[i].value){
                    lead[lead.additionalFields[i].field_id] = lead.additionalFields[i].options[searchIndex(lead.additionalFields[i].options, lead.additionalFields[i].value)] ? lead.additionalFields[i].options[searchIndex(lead.additionalFields[i].options, lead.additionalFields[i].value)].label : ''
                } else if(lead.additionalFields[i].type.name === "multiselect" && lead.additionalFields[i].value.length > 0){
                    //console.log(lead.additionalFields[i].options[searchIndex(lead.additionalFields[i].options, lead.additionalFields[i].value[0])].label)
                    let a = ``;
                    let val = lead.additionalFields[i].value;
                    let opt = lead.additionalFields[i].options;
                    for(let z = 0; z < val.length; z++){
                        a += opt[searchIndex(opt,val[z])] ? opt[searchIndex(opt,val[z])].label : '';
                        a += ', ';
                        lead[lead.additionalFields[i].field_id] = a;
                    }
                } else {
                    lead[lead.additionalFields[i].field_id] = lead.additionalFields[i].value || ''
                }
            }
        }
        /*lead.additionalFields.map((item, i) => {
         if(item){
         if(item.type.name === "select" && item.value){
         lead[item.field_id] = item.options[searchIndex(item.options, item.value)].label
         console.log("select1", item)
         }
         }
         })*/
    }
    return props.extraData.map((item, index) =>
        <td key={index} data-tooltip={lead[item]}>{(typeof lead[item] === "string") ? `${lead[item].slice(0,20)} ${lead[item].length > 20 ? '...' : ''}` : lead[item]}</td>
    )
    //.slice(0,2)

}
const FilterItem = props => {
    return <tr>
        <td>
            <Myselect
                value={props.field}
                options={props.fields}
                onChange={e => {
                    props.setField('field', e.target.value);
                    props.setField('value', "");
                    props.setField('obj', null);
                    props.setField('operator', operator[props.fields[searchIndex(props.fields, e.target.value)].fieldType][0].value);
                    props.setField('fieldType', props.fields[searchIndex(props.fields, e.target.value)].fieldType)
                    props.setField('options', props.fields[searchIndex(props.fields, e.target.value)].options)
                }}
            />
        </td>
        <td>
            <Myselect
                value={props.operator}
                options={operator[props.fieldType]}
                onChange={e => props.setField('operator', e.target.value)}
            />
        </td>
        <td>
            {
                props.fieldType === 'text' ? <Input
                        type="text"
                        value={props.value}
                        onChange={e => props.setField('value', e.target.value)}
                        shouldValidate={true}
                        valid={true}
                    /> :
                    props.fieldType === 'number' ? <Input
                            type="number"
                            value={props.value}
                            onChange={e => props.setField('value', e.target.value)}
                            valid={true}
                        /> :
                        props.fieldType === 'select' ? <Myselect
                                empty
                                value={props.value}
                                options={props.options}
                                onChange={e => props.setField('value', e.target.value)}
                                val="id"
                                lab="label"
                            /> :
                            props.fieldType === 'date' ? <DateTime
                                    value={props.value}
                                    valid={true}
                                    isClearable={false}
                                    handleChange={e => {
                                        (e) ? props.setField('value', formatDate(e)) : props.setField('value', '')
                                    }}
                                    dateFormat="dd-MMMM-yyyy"
                                    todayButton
                                /> :
                                props.fieldType === 'lookup' || props.fieldType === 'entity' || props.fieldType === 'tags'  || props.fieldType === 'products' ? <ModAutoSelect
                                    link={props.fields[searchIndex(props.fields, props.field)].filterLink}
                                    minLength={props.fields[searchIndex(props.fields, props.field)].filterLength}
                                    result={props.fields[searchIndex(props.fields, props.field)].filterSearch}
                                    event="status"
                                    valid={props.obj}
                                    shouldValidate={false}
                                    addType="status"
                                    entity={props.obj}
                                    entityName={props.fields[searchIndex(props.fields, props.field)].filterSearch}
                                    updateData={(id, arr, par2) => props.updData(props.index, id, arr)}
                                    unLink={() => props.unLink()}
                                    placeholder="Выберите значение"
                                /> : null
            }
        </td>
        {props.filter.length > 0 ? <td style={{ width:"15px" }}>
            <a onClick={() => props.delItem(props.index)} style={{ fontSize:"19px", color:"#c2263f", display:"block", textAlign:"center" }}>x</a>
        </td> : null}
    </tr>
};

const operator = {
    number: [
        {value:'eq', label:'Равно'},
        {value:'neq', label:'Не равно'},
        {value:'>', label:'Больше'},
        {value:'<', label:'Меньше'},
        /*{value:'<=', label:'Меньше равно'},
         {value:'>=', label:'Больше равно'},*/
    ],
    text: [
        {value:'like', label:'Содержит'},
        {value:'nlike', label:'Не содержит'},
    ],
    select: [
        {value:'like', label:'Содержит'},
        {value:'nlike', label:'Не содержит'},
    ],
    date: [
        {value:'like', label:'Равно'},
        {value:'nlike', label:'Не равно'},
        {value:'<', label:'Раньше'},
        {value:'>', label:'Позже'},
    ],
    lookup: [
        {value:'in', label:'Содержит'},
        {value:'nin', label:'Не содержит'},
    ],
    entity: [
        {value:'in', label:'Содержит'},
        {value:'nin', label:'Не содержит'},
    ],
    tags: [
        {value:'in', label:'Содержит'},
        {value:'nin', label:'Не содержит'},
    ],
    products: [
        {value:'in', label:'Содержит'},
        {value:'nin', label:'Не содержит'},
    ]
}

const TdType = props => {
    switch(props.type){
        case "link":
            return <div>
                {props.desc ? `${props.desc}: ` : ''}
                <a onClick={() => props.history.push(props.link + props.item.id)}>{props.item[props.val]}</a>
            </div>;
        case "text":
            return <div onClick={(props.edit) ? () => props.isedit(props.item[props.val]) : null}>
                {props.desc ? props.item[props.val] ? `${props.desc}: ` : '' : ''}
                {props.item[props.val]}{props.cur ? props.item.currency ? props.item.currency.text_symbol : '' : ''}
            </div>;
        case "currency_cost":
            return <div onClick={(props.edit) ? () => props.isedit(props.item[props.val]) : null}>
                {props.desc ? props.item[props.val] ? `${props.desc}: ` : '' : ''}
                {props.item[props.val] / props.item.exchange_rate}{props.cur ? props.item.currency ? props.item.currency.text_symbol : '' : ''}
            </div>;
        case "obj":
            return <div>
                {props.desc ? props.item[props.val] ? `${props.desc}: ` : '' : ''}
                {props.item[props.val] ? props.item[props.val][props.from] ? props.item[props.val][props.from].slice(0,40) : '' : ''}
            </div>;
        case "status":
            return <div>
                {props.item[props.val] ? <span style={{ backgroundColor: props.item[props.val].color ? props.item[props.val].color : '#ffc164'}} className="label-st">{props.item[props.val][props.from]}</span> : null}
            </div>;
        case "staticOptions":
            return <div>
                {props.item[props.val] ? <span style={{ backgroundColor: props.item[props.val] ? props.colors[props.item[props.val]] : '#f7f7f7'}} className="label-st">{props.item[props.val] ? props.options[props.item[props.val]] : ''}</span> : null}
            </div>;
        case "statusOpp":
            return <div>
                {props.item[props.val] ?
                    props.item.is_won === 1 ? <span style={{ backgroundColor: "#62a091", color: '#fff'}} className="label-st">Успешно завершена</span> :
                        props.item.is_closed === 1 ? <span style={{ backgroundColor: "#c2263f", color: '#fff'}} className="label-st">Отменена</span> :
                            <span style={{ backgroundColor: props.item[props.val].color ? props.item[props.val].color : '#ffc164'}} className="label-st">{props.item[props.val][props.from]}</span>
                    : null}
            </div>;
        case "open":
            return <div>
                <a onClick={() => props.openModal(props.opnType, props.item)}>{props.item[props.val]}</a>
            </div>;
        case "openButton":
            return <div>
                <a onClick={() => props.addOpenButtonModal(props.opnType, props.item)}>{props.from ? props.item[props.val][props.from] : props.item[props.val]}</a>
            </div>
        case "client":
            return <div>
                {props.desc && props.item.account_id || props.item.contact_id  ? `${props.desc} ` : ''}
                {
                    props.item.account ?
                        <a onClick={() => props.history.push('/account/' + props.item.account.id)}>{props.item.account ? props.item.account.name : null}</a> :
                        <a onClick={() => props.history.push('/contact/' + props.item.contact.id)}>{props.item.contact ? props.item.contact.name : null}</a>
                }
            </div>;
        case "phone":
            return <div>
                <div style={{display: "inline-block"}} className="toggleMenu">{props.item[props.val]}
                    <ul>
                        <li><a onClick={() => props.updateData("Call", {tel: props.item[props.val], entity: props.entity, id: props.id})}>Позвонить</a></li>
                        <li><a onClick={() => props.updateData("SendSms", {tel:props.item[props.val], entity: props.entity, id: props.id})}>Отравить SMS</a></li>
                    </ul></div>
            </div>;
        case "email":
            return <div>
                <div style={{display: "inline-block"}} className="toggleMenu">{props.item[props.val]}
                    <ul>
                        <li><a onClick={() => props.updateData("SendMail", {from: props.item[props.val], entity: props.entity, id: props.id})}>Отправить Email</a></li>
                    </ul></div>
            </div>;
        default: return '';
    }
}
function formatDate(date){
    if(date !== null) {
        let dd = date.getDate();
        if (dd < 10) dd = '0' + dd;
        let mm = date.getMonth() + 1;
        if (mm < 10) mm = '0' + mm;
        let yy = date.getFullYear() % 10000;
        if (yy < 10) yy = '0' + yy;
        return yy + '-' + mm + '-' + dd;
    }
}



const generatePaginationItems = (currentPage, pagesAmount, setCurrentPage) => {
    const PaginationItem = ({ number, isActive }) => <div className={`pagination__item ${isActive ? 'pagination__item--active' : ''}`} onClick={() => setCurrentPage(number)}>{number}</div>
    const Dots = () => <div className="pagination__item-dots">...</div>

    return (
        <>
            {!(currentPage < 3) ? <PaginationItem number={1} /> : null}
            {
                currentPage < 5
                    ? currentPage > 3
                    ? <PaginationItem number={currentPage - 2} />
                    : null
                    : <Dots />
            }

            {!(currentPage < 2) ? <PaginationItem number={currentPage - 1} /> : null}
            <PaginationItem isActive number={currentPage} />
            {!(currentPage > (pagesAmount - 1)) ? <PaginationItem number={currentPage + 1} /> : null}

            {
                (currentPage > (pagesAmount - 4))
                    ? (currentPage > (pagesAmount - 3))
                    ? null
                    : <PaginationItem number={currentPage + 2} />
                    : <Dots />
            }
            {!(currentPage > pagesAmount - 2) ? <PaginationItem number={pagesAmount} /> : null}
        </>
    )
}

const Pagination = ({ currentPage, pagesAmount, setCurrentPage }) => (
    <div className="pagination">
        <img style={{ maxWidth: 20 }} className="pagination__arrow-left" src="https://cdn2.iconfinder.com/data/icons/font-awesome/1792/angle-left-512.png" onClick={() => (currentPage > 1 ? setCurrentPage(currentPage - 1) : null)} />
        <div className="pagination__container">
            {generatePaginationItems(currentPage, pagesAmount, setCurrentPage)}
        </div>
        <img style={{ maxWidth: 20 }} className="pagination__arrow-right" src={"https://cdn2.iconfinder.com/data/icons/font-awesome/1792/angle-left-512.png"} onClick={() => (currentPage < pagesAmount ? setCurrentPage(currentPage + 1) : null)} />
    </div>
)

export {formatDate, operator, FilterItem}
