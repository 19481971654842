import React,{ useEffect, useState } from 'react';
import Input from '../components/UI/Input';
import Myselect from "../components/UI/Select";
import Notif from '../components/Notif';
import myaxios from '../components/Axios';
import formValid from "../components/UI/formValid";

const Rfm = props => {
    const [id, setId] = useState(null)
    const [tch, setTch] = useState(true)
    const [fc, setFc] = useState({
        name: {value: '', valid: true, validation: {required: true}},
        priority: {value: '', valid: true, validation: {required: true}},
        recency_from: {value: '0', valid: true, validation: {required: true}},
        recency_to: {value: '0', valid: true, validation: {required: true}},
        frequency_from: {value: '0', valid: true, validation: {required: true}},
        frequency_to: {value: '0', valid: true, validation: {required: true}},
        monetary_from: {value: '0', valid: true, validation: {required: true}},
        monetary_to: {value: '0', valid: true, validation: {required: true}},
        ignore: {value: ''},
        type: {value:''},
        color: {value:''},
    });
    const [section, setSection] = useState("default")

    const getInfo = () => {
        myaxios.get(`/ajax/rfm/segment/${props.id.id}`, {method: 'get',}).then((responce) =>{
            const formControl = fc;
            formControl.name.value = responce.data.name;
            setFc(prevState => {
                return {...prevState, ...formControl};
            });
        })
        const spt = (str, num) => {
            let arr = str.split('-');
            return arr[num];
        }
        const formControl = fc;
        fc.name.value = props.id.name;
        fc.recency_from.value = spt(props.id.recency, 0);
        fc.recency_to.value = spt(props.id.recency, 1);
        fc.frequency_from.value = spt(props.id.frequency, 0);
        fc.frequency_to.value = spt(props.id.frequency, 1);
        fc.monetary_from.value = spt(props.id.monetary, 0);
        fc.monetary_to.value = spt(props.id.monetary, 1);
        fc.ignore.value = props.id.ignore;
        fc.type.value = props.id.type;
        fc.color.value = props.id.color;
        fc.priority.value = props.id.priority;
        setFc(prevState => {
            return {...prevState, ...formControl};
        });
        setSection(props.id.section)
        setId(props.id.id)
    };
    useEffect(() => {
        if(props.id) getInfo()
    }, []);

    const setField = (event, type) => {
        const formControl = fc;
        formControl[type].value = event;
        setFc(prevState => {
            return {...prevState, ...formControl};
        });
        setTch(false)
    }
    const sendForm = () => {
        if(isFormValid()){
            myaxios.post('/ajax/rfm/segment', {
                name: fc.name.value,
                recency: fc.recency_from.value + '-' + fc.recency_to.value,
                frequency: fc.frequency_from.value + '-' + fc.frequency_to.value,
                monetary: fc.monetary_from.value + '-' + fc.monetary_to.value,
                ignore: fc.ignore.value,
                //invoice: fc.invoice.value,
                //order: fc.order.value,
                //opportunity: fc.opportunity.value,
                //project: fc.project.value,
                type: props.type,
                color: fc.color.value,
                section: section,
                priority: fc.priority.value
            }).then(res => {
                Notif("save", "Данные успешно сохранены!");
                setTimeout(() =>  {props.close(); props.refresh(); });
            })
        }
    }
    const isFormValid = () => {
        let formControl = fc;
        let isFormValid = true;
        Object.keys(formControl).map((key, index) => {
            formControl[key].valid = formValid(formControl[key].value, formControl[key].validation)
            isFormValid = formControl[key].valid && isFormValid
        });
        setFc(prevState => {
            return {...prevState, ...formControl};
        });
        return isFormValid;
    };
    const editForm = () => {;
        if(isFormValid()){
            myaxios.put(`/ajax/rfm/segment/${id}`, {
                name: fc.name.value,
                recency: fc.recency_from.value + '-' + fc.recency_to.value,
                frequency: fc.frequency_from.value + '-' + fc.frequency_to.value,
                monetary: fc.monetary_from.value + '-' + fc.monetary_to.value,
                ignore: fc.ignore.value,
                //invoice: fc.invoice.value,
                //order: fc.order.value,
                //opportunity: fc.opportunity.value,
                //project: fc.project.value,
                type: fc.type.value,
                color: fc.color.value,
                section: section,
                priority: fc.priority.value
            }).then(res => {
                Notif("save", "Данные успешно сохранены!");
                setTimeout(() =>  {props.close(); props.refresh(); });
            })
        }
    }

    return <div className="sbform create-activ">
        <div className="rmod-header">
            <b>Сегмент</b>
            <div className="togglelist"><button onClick={(id) ? editForm : sendForm} className="rmod-ok" disabled={tch} ><i className="flaticon-interface-1" /></button></div>
        </div>
        <div className="mod-cont">
            <Input
                className="c9"
                label="Название сегмента"
                value={fc.name.value}
                shouldValidate valid={fc.name.valid}
                onChange={event => setField(event.target.value, "name")}
            />
            <Input
                className="c3 color100"
                label="Цвет"
                type="color"
                value={fc.color.value}
                onChange={event => setField(event.target.value, "color")}
            />
            <Input
                className="c9"
                label="Приоритет"
                value={fc.priority.value}
                shouldValidate valid={fc.priority.valid}
                onChange={event => setField(event.target.value, "priority")}
                tooltip={"Приоритет, по которому будет присваиваться сегмент. Если контакт или контрагент попадет в 2 сегмента, система выберет с наивысшим приоритетом."}
            />
            <Input
                tooltip="Количество дней от последней покупки."
                className="c12"
                label="Давность (от) к-во дней"
                type="number"
                value={fc.recency_from.value}
                onChange={event => setField(event.target.value, "recency_from")}
            />
            <Input
                tooltip="Количество дней от последней покупки."
                className="c12"
                label="Давность (до) к-во дней"
                type="number"
                value={fc.recency_to.value}
                onChange={event => setField(event.target.value, "recency_to")}
            />
            <Input
                tooltip="��колько покупок клиент сделал в период, который вы указываете в поля `давность (recency)`"
                className="c12"
                label="Частота (от) к-во покупок"
                type="number"
                value={fc.frequency_from.value}
                onChange={event => setField(event.target.value, "frequency_from")}
            />
            <Input
                tooltip="Сколько покупок клиент сделал в период, который вы указываете в поля `давность (recency)`"
                className="c12"
                label="Частота (до) к-во покупок"
                type="number"
                value={fc.frequency_to.value}
                onChange={event => setField(event.target.value, "frequency_to")}
            />
            <Input
                tooltip="Количество потраченных клиентом денег (в базовой валюте), за промежуток времени, который вы указываете в поля `давность (recency)`"
                className="c12"
                label="Деньги (от) в б.в."
                type="number"
                value={fc.monetary_from.value}
                onChange={event => setField(event.target.value, "monetary_from")}
            />
            <Input
                tooltip="Количество потраченных клиентом денег (в базовой валюте), за промежуток времени, который вы указываете в поля `давность (recency)`"
                className="c12"
                label="Деньги (до) в б.в."
                type="number"
                value={fc.monetary_to.value}
                onChange={event => setField(event.target.value, "monetary_to")}
            />
            <Myselect
                tooltip="Какой показатель игнорировать при анализе данных клиента"
                className="c12"
                label="Игнорировать"
                options={[
                    {value:'', label:'Ничего'},
                    {value:'r', label:'Давность'},
                    {value:'f', label:'Частота'},
                    {value:'m', label:'Деньги'},
                ]}
                value={fc.ignore.value}
                onChange={event => setField(event.target.value, "ignore")}
            />
            <div className="clear" />
            <div
                data-tooltip="По умолчанию давность и частота считается по завершенным счетам. Если вы выбираете несколько значений, они сумируются при анализе данных клиента. То есть, если вы указали проекты и
                заказы, система просчитает сумарное количество проектов и заказов за промежуток времени, который вы указываете в поле `давность`"
                className="connection">
                <span>Из каких разделов считать параметры recency и frequency</span>
            </div>
            <div>
                <input type="radio" className="radio" id="default" name="rfm" value="default" checked={section === "default"} onClick={e => setSection("default")} /><label htmlFor="default">Счета</label>
                <input type="radio" className="radio" id="opportunity" name="rfm" value="opportunity" checked={section === "opportunity"} onClick={e => setSection("opportunity")} /><label htmlFor="opportunity">Сделки</label>
                <input type="radio" className="radio" id="order" name="rfm" value="order" checked={section === "order"} onClick={e => setSection("order")} /><label htmlFor="order">Заказы</label>
            </div>
        </div>
    </div>
}
export default Rfm;
