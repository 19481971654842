import {entity} from "../../../components/ListComponentEntity";
import {NavLink} from "react-router-dom";
import React from "react";
import {NewList} from "../../../components/NewList/NewList";

export const ListReplanishNew = (props) => {
    return (
        <NewList
            { ...props } stockNav additionalFieldsExists isFilterExists
            entity={'stock/replanish'}
            entity_type={16}
            requiredFields={'id,name,stock_id,date'}
            requiredExpand={'stock'}
            deleteToShowColumn={'id,name,stock_id,date'}
            filterFields={entity.replanish.fArr}
            singleSearch={entity.replanish.fArr[0]}
            headingColumns={['Номер', 'Статус', 'Дата']}
            RenderColumn={(item) => (
                <>
                    <td>
                        <NavLink to={`/stock/replanish/${item.id}`}  className={'nowrap-list-column'}>{item.name}</NavLink>
                    </td>
                    <td>
                        {item.stock && <span className="label-st" style={{ backgroundColor: 'rgb(255, 193, 100)' }}>{item.stock.name}</span>}
                    </td>
                    <td>
                        <div>{item.date}</div>
                    </td>
                </>
            )}
            createButtonRender={(setModalType) => <button onClick={() => setModalType('replanish')} className="open-modal-button btni">Новый приход</button>}
            rightNavbarConfig={{
                additionalFields: '/fieldsettings/16',
                columns: true
            }}
            massDoConfig={{ deleteAll: `/ajax/stock/purchase/delete-all` }}
            contextNav={{ edit: true, task: true, print: true, delete: true }}
            sort={entity.invoice.fArr.filter(el => el.sort)}
        />
    )
}