export const tabsConfig = [
    {value:"information", label:"Основная информация"},
    {value:"history", label:"Хронология"},
    {value:"product", label:"Продукты"},
    {value:"related", label:"Связи"},
    {value:"files", label:"Файлы"},
]

export const leadConfig = {
    name: {
        label: 'Название',
        type: 'text',
        className: 'w100',
        validation: { required: true, maxLength: 255 },
        zone: 1
    },
    amount: {
        label: 'Бюджет',
        type: 'text',
        className: 'w60',
        validation: { number: true, maxLength: 255 },
        zone: 1
    },
    currency_id: {
        label: 'Валюта',
        type: 'select',
        options: [],
        className: 'w40',
        options_from: 'currencies',
        // prepare: 'currencies',
        val: 'id', lab: 'name',
        validation: { required: true, maxLength: 255 },
        zone: 1,
    },
    status_id: {
        label: 'Статус',
        type: 'select',
        options: [],
        className: 'w100',
        options_from: 'statuses',
        // prepare: 'statues',
        val: 'id',
        lab: 'value',
        validation: { required: true, maxLength: 255 },
        zone: 1
    },
    type_id: {
        label: 'Тип потребности',
        type: 'select',
        options: [],
        className: 'w100',
        options_from: 'types',
        // prepare: 'types',
        val: 'id',
        lab: 'value',
        zone: 1
    },
    source_id: {
        label: 'Источник лида',
        type: 'entity',
        link: "/ajax/lookup/search?type=ld_source&value=",
        result: 'value',
        entity_from: 'source',
        entityName: 'value',
        className: 'w100',
        minLength: 0,
        zone: 1
        // redirectLink
    },
    add_way_id: {
        label: 'Как добавлен',
        type: 'entity',
        link: "/ajax/lookup/search?type=add_way&value=",
        result: 'value',
        entity_from: 'addWay',
        entityName: 'value',
        className: 'w100',
        minLength: 0,
        zone: 1
        // redirectLink
    },
    owner_id: {
        label: 'Ответственный',
        type: 'entity',
        link: "/ajax/settings/user?filter[name][like]=",
        result: 'name',
        entity_from: 'owner',
        entityName: 'name',
        className: 'w100',
        minLength: 0,
        validation: { required: true },
        zone: 1
        // redirectLink
    },
    first_name: {
        label: 'Имя',
        type: 'text',
        className: 'w50-p',
        validation: { maxLength: 255 },
        zone: 2
    },
    last_name: {
        label: 'Фамилия',
        type: 'text',
        className: 'w50-p',
        validation: { maxLength: 255 },
        zone: 2
    },
    middle_name: {
        label: 'Отчество',
        type: 'text',
        className: 'w50-p',
        validation: { maxLength: 255 },
        zone: 2
    },
    company: {
        label: 'Компания',
        type: 'text',
        className: 'w50-p',
        validation: { maxLength: 255 },
        zone: 2
    },
    phone: {
        label: 'Номер телефона',
        type: 'text',
        className: 'w50-p',
        validation: { maxLength: 255 },
        zone: 2
    },
    email: {
        label: 'Email',
        type: 'text',
        className: 'w50-p',
        validation: { maxLength: 255, email: true },
        zone: 2
    },
    description: {
        type: 'text',
        validation: { maxLength: 2000 },
    },
    photo: {},
    editorTags: {},
    created_at: {},
    updated_at: {}
}