import myaxios from "../../components/Axios";

export const testTaskData = {
    comment: "",
    description: "",
    editorTags: "",
    end_date: null,
    files: [],
    owner_id: null,
    owners: [],
    priority: "1",
    related_id: null,
    related_type: null,
    remind_at: null,
    start_date: null,
    status_id: 3,
    subject: "test",
    subtask_json: "[]",
    type_id: "3",
}

type TaskType = {
    comment?: string,
    description?: string,
    editorTags?: string,
    end_date?: string | null,
    files?: string[],
    owner_id?: null | number,
    owners?: any[],
    priority: string,
    related_id?: null | number,
    related_type?: null | number,
    related_name?: string,
    remind_at?: null | string,
    start_date?: null | string,
    status_id: number,
    subject: string,
    subtask_json: string,
    type_id: string,
    watchers?: any
}

export const getTaskPrepareService = async () => await myaxios.get(`/ajax/task/prepare`);

export const getSingleTaskService = async ({id, expand}: { id: number, expand?: string }) => await myaxios.get(`/ajax/task/${id}${expand}`);

export const createTaskService = async ({data}: { data: TaskType }) => await myaxios.post(`/ajax/task?expand=owner`, data);

export const updateTaskService = async ({data, id}: { data: TaskType, id: number }) => await myaxios.put(`/ajax/task/${id}?expand=owner`, data);

export const deleteTaskService = async ({ id }: { id: number }) => await myaxios.delete(`/ajax/task/${id}`)