import React from 'react';

const Loader = props => (
    <div className='cssload-loader'>
        <div className='cssload-inner cssload-one'></div>
        <div className='cssload-inner cssload-two'></div>
        <div className='cssload-inner cssload-three'></div>
    </div>
)

/*const Loader = props => (
    <div className="loadingio-spinner-bean-eater-92qvcha3hj">
        <div className="ldio-ss7moj7l4n">
            <div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
)*/

export const ModalLoader = props => (
    <div className={'modal-loader'}>
        <div className="lds-ripple">
            <div></div>
            <div></div>
        </div>
    </div>
);

export const LoaderOld = props => (
    <div style={{ position: (props.contain) ? "absolute" : '' }} className="loader" />
);

export default Loader;
