import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import AutoSelect from '../../../components/UI/AutoSelect';

function splitTag(arr) {
    return arr.split(',');
}

const Item = props => {
    return <tr className={`pull-up seen${props.is_seen}`}>
        <td>
            <label><input
                className="checkbox checkboxlead"
                type="checkbox"
                name="checkbox-test"
                onClick={props.delete}
                //onClick={this.setChecked}
                //defaultChecked={this.state.checked}
            /><span className="checkbox-custom"/></label>
        </td>
        <td><span className="img">{props.from.slice(0,2)}</span></td>
        <td><a style={{ width: "150px" }} onClick={() => props.history.push(`${props.path.url}/${props.thread_id}/thread`)} href={null} className="os">{(props.folder === "sent") ? props.to : props.from}</a></td>
        <td>{props.threadCount}</td>
        <td>
            {
                (props.editorTags !== '') ? (splitTag(props.editorTags) ? splitTag(props.editorTags).map((item, index) => <div className="tagMail" key={index}>{item}</div>) : null) : null
            }
            <a onClick={() => props.history.push(`${props.path.url}/${props.thread_id}/thread`)} href={null}><strong>{props.theme}</strong> - {props.body.slice(0, 30)} ...</a>
        </td>
        <td>{props.date}</td>
        <td>
            {/*<AutoSelect
                link="/ajax/mail/18/suggest?term="
                minLength="0"
                result="value"
            />*/}
            <div className="toogle-bottom fl-r">
                <a className="is"><i className="flaticon-more" /></a>
                <ul className="toogle-list">
                    {/*<li><a href="#"><i className="flaticon-exclamation-1" /> <b>В спам</b></a></li>*/}
                    <li><a onClick={props.delItem} className="table-del" href={null}><i className="flaticon-delete-1" /> <b>Удалить</b></a></li>
                    <li><a onClick={() => props.addTag(props.id)} href={null}><i className="flaticon-paper-plane" /> <b>Теги</b></a>{/*
                        <ul className="toogleListChild">
                            <li><a href="#">Новый тег 1</a></li>
                            <li><a href="#">Новый тег 1</a></li>
                            <li><a href="#">Новый тег 1</a></li>
                            <li><a href="#">Новый тег 1</a></li>
                        </ul>
                    */}</li>
                </ul>
            </div>
        </td>
    </tr>
}

export default withRouter(Item);