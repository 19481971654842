import {entity} from "../../components/ListComponentEntity";
import {NewList} from "../../components/NewList/NewList";
import React, {useCallback, useEffect, useState} from "react";
import myaxios from "../../components/Axios";
import MoneyTransfer from "../../Modal/MoneyTransfer";
import {PaymentModal} from "./PaymentModal";
import AddExpense from "../../Modal/AddExperense";

const filterConfig = [
    {value: "balance_id", label: "Кошелек", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/balance?filter[name][like]=", filterLength:"0", fieldType:'entity'},
    {value: "amount", label: "Сумма", filterField: "autoselect", filterSearch:'amount', filterLink:"/ajax/opportunity?filter[name][like]=", filterLength:"999" , fieldType:'number',sort: true},
    {value: "currency_id", label: "Валюта", filterField: "autoselect", filterSearch:'char_code', filterLink:"/ajax/lookup/search?type=currency&value=", filterLength:"0",fieldType:'lookup'},
    {value: "date", label: "Дата", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date', sort: true},
    {value: "created_at", label: "Дата создания", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999", fieldType:'date',sort: true},
    {value: "updated_at", label: "Дата изменения", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999", fieldType:'date',sort: true},
    {value: "created_by", label: "Кем создан", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0", fieldType:'entity'},
]

export const FinanceListNew = (props) => {
    const [balances, setBalances] = useState([])
    const [currencies, setCurrencies] = useState([])
    const [modalData, setModalData] = useState(null)
    const [updatedNumber, setUpdatedNumber] = useState(0)

    const openModal = (item) => {
        setModalData({ type: item.type, id: item.object.id })
    }

    const types = (type, item) => {
        switch (type) {
            case "income": return (
                <>
                    <td onClick={() => openModal(item)} style={{ padding: 0 }}>
                        <div
                            style={{ background: 'linear-gradient(45deg, rgb(128, 174, 186) 2%, rgb(105, 148, 153) 46%, rgb(102, 146, 149) 100%)' }}
                            className={'finance-list-icon'}>
                            <i className="flaticon-add" style={{ color: '#fff' }} />
                        </div>
                    </td>
                    <td onClick={() => openModal(item)}>
                        <div>{item.balance_id && balances && balances.find(el => el.id === item.balance_id) &&
                        balances.find(el => el.id === item.balance_id).name}</div>
                        <div style={{ color: "rgb(102, 146, 149)" }}>
                            {item.amount}
                            { item.balance_id && balances && balances.find(el => el.id === item.balance_id) &&
                            balances.find(el => el.id === item.balance_id).currency.text_symbol}
                        </div>
                    </td>
                    <td onClick={() => openModal(item)}>{item.date.substring(0, item.date.length - 3)}</td>
                    <td onClick={() => openModal(item)}>
                        {item.object && item.object.category && <span className={'label-st'} style={{ background: item.object.category.color || 'rgb(255, 193, 100)' }}>
                             {item.object.category.value}
                        </span>}
                    </td>
                    <td onClick={() => openModal(item)}>

                    </td>
                </>
            );
            case "transfer": return (
                <>
                    <td onClick={() => openModal(item)} style={{ padding: 0 }}>
                        <div
                            style={{
                                background: 'linear-gradient(45deg, rgb(146, 146, 146) 0%, rgb(101, 101, 101) 47%, rgb(148, 138, 138) 100%)',
                                fontSize: '26px'
                            }}
                            className={'finance-list-icon'}>
                            <i className="flaticon-more-v4" style={{ color: '#fff' }} />
                        </div>
                    </td>
                    <td onClick={() => openModal(item)}>
                        <div>{item.balance_id && balances && balances.find(el => el.id === item.balance_id) &&
                        balances.find(el => el.id === item.balance_id).name}
                            {` > `}
                            { item.to_balance_id && balances && balances.find(el => el.id === item.to_balance_id) &&
                            balances.find(el => el.id === item.to_balance_id).name}
                        </div>
                        <div style={{ color: "rgb(146, 146, 146)" }}>
                            {`${item.object.amount || 0}${balances.find(el => el.id === item.balance_id).currency.text_symbol} > ${item.object.amount_to}`}{ item.to_balance_id && balances && balances.find(el => el.id === item.to_balance_id) &&
                        balances.find(el => el.id === item.to_balance_id).currency.text_symbol}
                        </div>
                    </td>
                    <td onClick={() => openModal(item)}>{item.date.substring(0, item.date.length - 3)}</td>
                    <td onClick={() => openModal(item)}>
                        <span  className={'label-st'} style={{ background: 'rgb(217 217 217)' }}>
                             Перевод средств
                        </span>
                    </td>
                    <td onClick={() => openModal(item)}>-</td>
                </>
            );
            case "expense": return (
                <>
                    <td onClick={() => openModal(item)} style={{ padding: 0 }}>
                        <div
                            style={{
                                background: 'linear-gradient(45deg, #d37873 0%,#c96e6e 47%,#c16a6a 100%)',
                                fontSize: '20px'
                            }}
                            className={'finance-list-icon'}>
                            <i className="flaticon-up-arrow-1" style={{ color: '#fff' }} />
                        </div>
                    </td>
                    <td onClick={() => openModal(item)}>
                        <div>
                            {item.balance_id && balances && balances.find(el => el.id === item.balance_id) &&
                            balances.find(el => el.id === item.balance_id).name}
                        </div>
                        <div style={{ color: "#c16a6a" }}>
                            {item.amount}
                            {item.balance_id && balances && balances.find(el => el.id === item.balance_id) &&
                            balances.find(el => el.id === item.balance_id).currency.text_symbol}
                        </div>
                    </td>
                    <td onClick={() => openModal(item)}>{item.date.substring(0, item.date.length - 3)}</td>
                    <td onClick={() => openModal(item)}>
                        {item.object.category && <span className={'label-st'} style={{ background: item.object.category.color || 'rgb(255, 193, 100)' }}>
                             {item.object.category.value}
                        </span>}
                    </td>
                    <td onClick={() => openModal(item)}>

                    </td>
                </>
            );
        }
    }

    useEffect(() => {
        getBalances()
        getCurrencies().then()
    }, [updatedNumber])

    const getBalances = () => {
        myaxios.get(`/ajax/balance`).then(res => {
            if(res && res.data) setBalances(res.data.items)
        })
    }

    const getCurrencies = async () => {
        const { status, data } = await myaxios.get(`/ajax/settings/lookup?type=currency`)
        if(status === 200) {
            setCurrencies(data.items)
        }
    }


    const calcSum = () => {
        let sum = 0
        balances.map(item => { sum += item.amount * (item.currency || { value: 0 }).value })
        return sum.toFixed( 3 ).slice( 0, -1 )  + ' грн'
    }

    return (
        <>
            <NewList
                updateOutElements={getBalances}
                { ...props }
                entity={'balance/transaction'}
                entity_type={50}
                requiredFields={''}
                requiredExpand={'object.category'}
                deleteToShowColumn={'name,status_id,amount,payment,type_id'}
                additionalFieldsExists={false}
                isFilterExists={true}
                filterFields={filterConfig}
                // singleSearch={entity.lead.fArr[0]}
                headingColumns={['','Кошелек/Сумма','Дата/Баланс','Категория','Клиент/Сотрудник']}
                RenderColumn={(item) => (
                    types(item.type, item)
                )}
                createButtonRender={(setModalType) =>
                    <>
                        <a onClick={() => setModalData({ type: 'expense' })} className="open-modal-button fl-l btni">Добавить расход</a>
                        <a onClick={() => setModalData({ type: 'income'})}
                            style={{ marginLeft: "5px", background: "linear-gradient(45deg, #80aeba 2%,#699499 46%,#669295 100%)" }}
                            className="open-modal-button fl-l btni">Добавить доход</a>
                        <a onClick={() => setModalData({ type: "transfer" })}
                            style={{ marginLeft: "5px", background: "linear-gradient(45deg, #929292 0%,#656565 47%,#948a8a 100%)" }}
                            className="open-modal-button fl-l btni">Перевод</a>
                    </>
                }
                analytics={'/expense/report'}
                rightNavbarConfig={{ }}
                contextNav={{ print: false, delete: true }}
                sort={filterConfig.filter(el => el.sort)}
                topNav={() => (
                    <div className="stocktop" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className={'stocktop__scroll'}>
                            {
                                balances.map(item => (
                                    <a className="stockItem" style={{ minWidth: '175px' }}>
                                        <i className="flaticon-coins" />
                                        <span style={{ fontSize: '13px' }}>{item.name}: <b style={{ color: item.amount > 0 ? 'rgb(105, 148, 153)' : '#c16a6a' }}>{item.amount} {item.currency && item.currency.text_symbol}</b></span>
                                    </a>
                                ))
                            }
                        </div>
                        <div style={{ display: 'flex' }}>
                            <a className="stockItem">
                                <i className="flaticon-interface-9" />
                                <span style={{ fontSize: '13px' }}>Итого: {calcSum()}</span>
                            </a>
                            <a className="stockItem" style={{ padding: '5px' }}>
                                <i className={'flaticon-cogwheel-1'} />
                            </a>
                        </div>
                    </div>
                )}
                updatedNumber={updatedNumber}
            />

            <MoneyTransfer
                currencies={currencies}
                balances={balances}
                isOpen={modalData && modalData.type === "transfer"}
                close={() => setModalData(null)}
                id={modalData && modalData.id}
                updateData={() => {
                    setUpdatedNumber(updatedNumber + 1)
                    setModalData(null)
                }}
            />

            <PaymentModal
                id={modalData && modalData.id}
                isOpen={modalData && modalData.type === 'income'}
                currencies={currencies}
                balances={balances}
                close={() => setModalData(null)}
                updateData={() => {
                    setUpdatedNumber(updatedNumber + 1)
                    setModalData(null)
                }}
            />

            <AddExpense
                id={modalData && modalData.id}
                isOpen={modalData && modalData.type === 'expense'}
                currencies={currencies}
                balances={balances}
                close={() => setModalData(null)}
                updateData={() => {
                    setUpdatedNumber(updatedNumber + 1)
                    setModalData(null)
                }}
            />
        </>
    )
}
