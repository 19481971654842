import myaxios from "../../Axios";
import {CrudCreateFunction, CrudGetSingleItemFunction} from "../CRUD";

const getBackup = () => myaxios.get(`/ajax/settings/backup`);

const createBackup = () => myaxios.post(`/ajax/settings/backup/make?test=true`);

const restoreBackup = ({ id }) => myaxios.get(`/ajax/settings/backup/restore/${id}`)

export const backupTest = async (type, id) => {
    if(type === "create") {
        const createBackupRes = await CrudGetSingleItemFunction({
            service: createBackup,
            serviceName: "createCrudRes",
            statusCode: 200
        })
        if(!createBackupRes.status || createBackupRes.data.status !== "success") return false;
    }

    const getBackupRes = await CrudGetSingleItemFunction({
        service: getBackup,
        serviceName: 'getBackup',
        statusCode: 200
    })
    if(!getBackupRes.status) return false;

    if(type === "restore") {
        const restoreBackupRes = await CrudGetSingleItemFunction({
            service: restoreBackup,
            serviceData: { id: id },
            serviceName: 'restoreBackupRes',
            statusCode: 200
        })
        if(!restoreBackupRes.status) return false;
    }

    if(type === "create") {
        return {
            status: !!getBackupRes.data[0].filename,
            id: getBackupRes.data[0].filename
        }
    } else {
        return true;
    }
}