import React from 'react';
import CallItem from './CallItem';
import Input from '../../components/UI/Input';
import myaxios from '../../components/Axios';
import Notif from '../../components/Notif';
import Loader from '../../components/UI/Loader';
import InfiniteScroll from "react-infinite-scroll-component";

const calls = [
    {type: "outgoing", time: "Только что", duration: "2:37", number: "+38 063 123 4567", client: ""},
    {type: "incoming", time: "5 мин", duration: "2:37", number: "+38 063 123 4567", client: "Юрий Петрович"},
    {type: "missed", time: "Вчера", duration: "0:00", number: "+38 063 123 4567", client: "Сергей Петров"}
];
class Call extends React.Component {
    state = {
        number: '',
        loading: false,
        calls: [],
        pageNumber: 1,
        pageCount: 1,
        perPage: 50,
        totalCount: Number,
    };
    call = () => {
        let link = "/ajax/binotel/make-call";
        if(this.props.id) link = `/ajax/${this.props.id.entity}/${this.props.id.id}/make-call`
        if(this.state.number.length >= 1){
            this.setState({ loading: true });
            myaxios.post(link, {phone: this.state.number}).then(res => {
                if(res.status === 200 || res.status === 201 || res.status === 204){
                    Notif("call", `На номер: ${this.state.number}`);
                    setTimeout(() => this.props.close());
                }
            }).catch(() => this.setState({ loading: false }))
        }
    };
    getCalls = () => {
        myaxios.get(`/ajax/binotel?per-page=${this.state.perPage}&sort=-created_at`).then(response => {
            this.setState({ calls: response.data.items, totalCount: response.data._meta.totalCount, links: response.data._links, pageCount: response.data._meta.pageCount });
        })
    };
    fetchMoreData = () => {
        if(this.state.pageNumber < this.state.pageCount) {
            this.state.pageNumber++;
            setTimeout(() => {
                myaxios.get(`/ajax/binotel?per-page=${this.state.perPage}&sort=-created_at`, {
                    params: {
                        page: this.state.pageNumber
                    }
                }).then((response) => {
                    this.setState({
                        calls: this.state.calls.concat(response.data.items)
                    });
                })
            }, 1000);
        }
    };
    componentDidMount(){
        this.setState({ number: this.props.id ? this.props.id.tel : '' });
        this.getCalls();
    }
    render(){
        return (this.state.loading) ? <Loader contain /> : <div className="sbform fltr">
            <div className="rmod-header">
                <b>Звонки</b>
            </div>
            {this.props.phone ? <div className="mod-cont call-body">
                <div className="phone-numb">
                    <Input disabled={(this.props.id)} type="tel" placeholder="Номер телефона" value={this.state.number} onChange={e => this.setState({ number: e.target.value })} />
                        <button onClick={this.call}><i className="flaticon-support" /></button>
                </div>
                <div className="m-demo mt10">
                    <div className="m-demo__preview">
                        <div className="m-list-timeline">
                            <div className="m-list-timeline__items" id="scrollableDiv">
                                <InfiniteScroll
                                    dataLength={this.state.calls.length}
                                    next={this.fetchMoreData}
                                    hasMore={true}
                                    scrollableTarget="right-modal"
                                    loader={(this.state.pageNumber < this.state.pageCount) ? <p style={{textAlign: "center"}}>Загрузка</p> : null}
                                >
                                {
                                    this.state.calls.map((item, index) =>
                                        <CallItem
                                            key={index}
                                            id={item.call_id}
                                            type={(item.call_type === 0) ? "incoming" : "outgoing"}
                                            time={item.created_at}
                                            duration={item.billsec}
                                            number={item.external_number}
                                            client={item.client}
                                            callNumber={(numb) => this.setState({ number: numb })}
                                        />
                                    )
                                }
                                </InfiniteScroll>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : <div className="mod-cont"><p style={{ fontSize: "14px", padding:"5px 0" }}>Добавьте интеграцию с сервисом IP телефонии</p></div>}
        </div>
    }
}

export default Call;