import React, {Component} from 'react';

const entityFields = {
    contact: [
        {value:'id',label:"ID",type:'number',link:'',arr:[], filterSearch:'', minLength:"99999",edit:false,object:null,r:false},
        {value:'name',label:"ФИО",type:'text',link:'',arr:[], filterSearch:'name', minLength:"99999",edit:false,object:null,r:false},
        {value:'first_name',label:"Имя",type:'text',link:'',arr:[], filterSearch:'name', minLength:"99999",edit:true,object:null,r:true},
        {value:'last_name',label:"Фамилия",type:'text',link:'',arr:[], filterSearch:'name', minLength:"99999",edit:true,object:null,r:false},
        {value:'middle_name',label:"Отчество",type:'text',link:'',arr:[], filterSearch:'name', minLength:"99999",edit:true,object:null,r:false},
        {value:'position',label:"Должность",type:'text',link:'',arr:[], filterSearch:'name', minLength:"99999",edit:true,object:null,r:false},
        {value:'mobile_phone',label:"Мобильный телефон",type:'number',link:'',arr:[], filterSearch:'value', minLength:"99999",edit:true,object:null,r:false},
        {value:'work_phone',label:"Рабочий телефон",type:'number',link:'',arr:[], filterSearch:'value', minLength:"99999",edit:true,object:null,r:false},
        {value:'email',label:"Email",type:'text',link:'',arr:[], filterSearch:'name', minLength:"99999",edit:true,object:null,r:false},
        {value:'description',label:"Описание",type:'text',link:'',arr:[], filterSearch:'name', minLength:"99999",edit:true,object:null,r:false},
        {value:'type_id',label:"Тип контакта",type:'multy',link:'/ajax/lookup/search?type=ct_type&value=',arr:[], filterSearch:'value', minLength:"0",edit:true,object:'lookup',r:true},
        {value:'account_id',label:"Контрагент",type:'multy',link:'/ajax/account?filter[name][like]=',arr:[], filterSearch:'name', minLength:"1",edit:false,object:'entity',r:false},
        {value:'editorTags',label:"Теги",type:'multy',link:'/ajax/contact/suggest?term=',arr:[], filterSearch:'name', minLength:"0",edit:false,object:'tags',r:false},
        {value:'created_at',label:"Дата создания",type:'date',link:'',arr:[], filterSearch:'value', minLength:"99999",edit:false,object:null,r:false},
        {value:'updated_at',label:"Дата изменения",type:'date',link:'',arr:[], filterSearch:'value', minLength:"99999",edit:false,object:null,r:false},
        {value:'created_by',label:"Кем создан",type:'multy',link:'/ajax/settings/user?filter[name][like]=',arr:[], filterSearch:'name', minLength:"0",edit:false,object:'entity',r:false},
        {value:'owner_id',label:"Ответственный",type:'multy',link:'/ajax/settings/user?filter[name][like]=',arr:[], filterSearch:'name', minLength:"0",edit:false,object:'entity',r:false},        {value:'description',label:"Описание",type:'text',link:'',arr:[], filterSearch:'name', minLength:"99999",edit:true,object:null,r:false},
        /*{value:'{{segment.id}}',label:"RFM статус (новый)",type:'text',link:'',arr:[], filterSearch:'name', minLength:"99999",edit:true,object:"event",r:false},
        {value:'{{old_segment.id}}',label:"RFM статус (предыдущий)",type:'text',link:'',arr:[], filterSearch:'name', minLength:"99999",edit:true,object:"event",r:false},*/
    ],
    lead: [
        {value:'id',label:"ID",type:'number',link:'',arr:[], filterSearch:'', minLength:"99999",edit:false,object:null,r:false},
        {value:'sales_funnel_id',label:"ID воронки",type:'number',link:'',arr:[], filterSearch:'', minLength:"99999",edit:false,object:null,r:true},
        {value:'name',label:"Название лида",type:'text',link:'',arr:[], filterSearch:'', minLength:"99999",edit:true,object:null,r:true},
        {value:'first_name',label:"Имя",type:'text',link:'',arr:[], filterSearch:'', minLength:"99999",edit:true,object:null,r:false},
        {value:'last_name',label:"Фамилия",type:'text',link:'',arr:[], filterSearch:'', minLength:"99999",edit:true,object:null,r:false},
        {value:'company',label:"Контрагент",type:'text',link:'',arr:[], filterSearch:'', minLength:"99999",edit:true,object:null,r:false},
        {value:'phone',label:"Телефон",type:'text',link:'',arr:[], filterSearch:'', minLength:"99999",edit:true,object:null,r:false},
        {value:'email',label:"Email",type:'text',link:'',arr:[], filterSearch:'', minLength:"99999",edit:true,object:null,r:false},
        {value:'amount',label:"Бюджет",type:'number',link:'',arr:[], filterSearch:'', minLength:"99999",edit:true,object:null,r:false},
        {value:'status_id',label:"Статус",type:'multy',link:'/ajax/lookup/search?type=ld_status&value=',arr:[], filterSearch:'value', minLength:"0",edit:true,object:'lookup',r:false},
        {value:'source_id',label:"Источник лида",type:'multy',link:'/ajax/lookup/search?type=ld_source&value=',arr:[], filterSearch:'value', minLength:"0",edit:true,object:'lookup',r:false},
        {value:'editorTags',label:"Теги",type:'multy',link:'/ajax/lead/suggest?term=',arr:[], filterSearch:'name', minLength:"0",edit:false,object:'tags',r:false},
        {value:'created_at',label:"Дата создания",type:'date',link:'',arr:[], filterSearch:'value', minLength:"99999",edit:false,object:null,r:false},
        {value:'updated_at',label:"Дата изменения",type:'date',link:'',arr:[], filterSearch:'value', minLength:"99999",edit:false,object:null,r:false},
        {value:'created_by',label:"Кем создан",type:'multy',link:'/ajax/settings/user?filter[name][like]=',arr:[], filterSearch:'name', minLength:"0",edit:false,object:'entity',r:false},
        {value:'owner_id',label:"Ответственный",type:'multy',link:'/ajax/settings/user?filter[name][like]=',arr:[], filterSearch:'name', minLength:"0",edit:false,object:'entity',r:false},
    ],
    opportunity:[
        {value:'id',label:"ID",type:'number',link:'',arr:[], filterSearch:'', minLength:"99999",edit:false,object:null,r:false},
        {value:'name',label:"Название",type:'text',link:'',arr:[], filterSearch:'', minLength:"99999",edit:true,object:null,r:true},
        {value:'amount',label:"Бюджет",type:'number',link:'',arr:[], filterSearch:'', minLength:"99999",edit:true,object:null,r:false},
        {value:'stage_id',label:"Этап сделки",type:'multy',link:'/ajax/lookup/search?type=opp_stage&value=',arr:[], filterSearch:'value', minLength:"0",edit:true,object:'lookup',r:true},
        {value:'type_id',label:"Тип потребности",type:'multy',link:'/ajax/lookup/search?type=opp_type&value=',arr:[], filterSearch:'value', minLength:"0",edit:true,object:'lookup',r:false},
        {value:'lead_source_id',label:"Источник сделки",type:'multy',link:'/ajax/lookup/search?type=ld_source&value=',arr:[], filterSearch:'value', minLength:"0",edit:true,object:'lookup',r:false},
        {value:'account_id',label:"Контрагент",type:'multy',link:'/ajax/account?filter[name][like]=',arr:[], filterSearch:'name', minLength:"1",edit:true,object:'entity',r:false},
        {value:'main_contact_id',label:"Контакт",type:'multy',link:'/ajax/contact?filter[name][like]=',arr:[], filterSearch:'name', minLength:"1",edit:true,object:'entity',r:false},
        {value:'probability',label:"Вероятность сделки",type:'number',link:'',arr:[], filterSearch:'', minLength:"99999",edit:true,object:null,r:false},
        {value:'editorTags',label:"Теги",type:'multy',link:'/ajax/opportunity/suggest?term=',arr:[], filterSearch:'name', minLength:"0",edit:false,object:'tags',r:false},
        {value:'created_at',label:"Дата создания",type:'date',link:'',arr:[], filterSearch:'value', minLength:"99999",edit:false,object:null,r:false},
        {value:'updated_at',label:"Дата изменения",type:'date',link:'',arr:[], filterSearch:'value', minLength:"99999",edit:false,object:null,r:false},
        {value:'created_by',label:"Кем создан",type:'multy',link:'/ajax/settings/user?filter[name][like]=',arr:[], filterSearch:'name', minLength:"0",edit:false,object:'entity',r:false},
        {value:'owner_id',label:"Ответственный",type:'multy',link:'/ajax/settings/user?filter[name][like]=',arr:[], filterSearch:'name', minLength:"0",edit:false,object:'entity',r:false},

    ],
    account:[
        {value:'id',label:"ID",type:'number',link:'',arr:[], filterSearch:'', minLength:"99999",edit:false,object:null,r:false},
        {value:'name',label:"Название",type:'text',link:'',arr:[], filterSearch:'', minLength:"99999",edit:true,object:null,r:true},
        {value:'type_id',label:"Тип контрагента",type:'multy',link:'/ajax/lookup/search?type=acc_type&value=',arr:[], filterSearch:'value', minLength:"0",edit:true,object:'lookup',r:true},
        {value:'main_contact_id',label:"Контакт",type:'multy',link:'/ajax/contact?filter[name][like]=',arr:[], filterSearch:'name', minLength:"1",edit:false,object:'entity',r:false},
        {value:'industry',label:"Отрасль",type:'text',link:'',arr:[], filterSearch:'', minLength:"99999",edit:true,object:null,r:false},
        {value:'phone',label:"Телефон",type:'number',link:'',arr:[], filterSearch:'', minLength:"99999",edit:true,object:null,r:false},
        {value:'email',label:"Email",type:'text',link:'',arr:[], filterSearch:'', minLength:"99999",edit:true,object:null,r:false},
        {value:'website',label:"Сайт",type:'text',link:'',arr:[], filterSearch:'', minLength:"99999",edit:true,object:null,r:false},
        {value:'description',label:"Описание",type:'text',link:'',arr:[], filterSearch:'', minLength:"99999",edit:true,object:null,r:false},
        {value:'editorTags',label:"Теги",type:'multy',link:'/ajax/account/suggest?term=',arr:[], filterSearch:'name', minLength:"0",edit:false,object:'tags',r:false},
        {value:'created_at',label:"Дата создания",type:'date',link:'',arr:[], filterSearch:'value', minLength:"99999",edit:false,object:null,r:false},
        {value:'updated_at',label:"Дата изменения",type:'date',link:'',arr:[], filterSearch:'value', minLength:"99999",edit:false,object:null,r:false},
        {value:'created_by',label:"Кем создан",type:'multy',link:'/ajax/settings/user?filter[name][like]=',arr:[], filterSearch:'name', minLength:"0",edit:false,object:'entity',r:false},
        {value:'owner_id',label:"Ответственный",type:'multy',link:'/ajax/settings/user?filter[name][like]=',arr:[], filterSearch:'name', minLength:"0",edit:false,object:'entity',r:false},
    ],
    project:[
        {value:'id',label:"ID",type:'number',link:'',arr:[], filterSearch:'', minLength:"99999",edit:false,object:null,r:false},
        {value:'name',label:"Название",type:'text',link:'',arr:[], filterSearch:'', minLength:"99999",edit:true,object:null,r:true},
        {value:'priority',label:"Приоритет",type:'number',link:'',arr:[], filterSearch:'', minLength:"99999",edit:true,object:null,r:false},
        {value:'description',label:"Описание",type:'text',link:'',arr:[], filterSearch:'', minLength:"99999",edit:true,object:null,r:false},
        {value:'account_id',label:"Контрагент",type:'multy',link:'/ajax/account?filter[name][like]=',arr:[], filterSearch:'name', minLength:"1",edit:true,object:'entity',r:false},
        {value:'contact_id',label:"Контакт",type:'multy',link:'/ajax/contact?filter[name][like]=',arr:[], filterSearch:'name', minLength:"1",edit:true,object:'entity',r:false},
        {value:'opportunity_id',label:"Сделка",type:'multy',link:'/ajax/opportunity?filter[name][like]=',arr:[], filterSearch:'name', minLength:"1",edit:true,object:'entity',r:false},
        {value:'editorTags',label:"Теги",type:'multy',link:'/ajax/project/suggest?term=',arr:[], filterSearch:'name', minLength:"0",edit:false,object:'tags',r:false},
        {value:'start_date',label:"Дата начала",type:'date',link:'',arr:[], filterSearch:'', minLength:"99999",edit:true,object:null,r:false},
        {value:'end_date',label:"Дата завершения",type:'date',link:'',arr:[], filterSearch:'', minLength:"99999",edit:true,object:null,r:false},
        {value:'deadline',label:"Крайний срок",type:'date',link:'',arr:[], filterSearch:'', minLength:"99999",edit:true,object:null,r:false},
        {value:'created_at',label:"Дата создания",type:'date',link:'',arr:[], filterSearch:'value', minLength:"99999",edit:false,object:null,r:false},
        {value:'updated_at',label:"Дата изменения",type:'date',link:'',arr:[], filterSearch:'value', minLength:"99999",edit:false,object:null,r:false},
        {value:'created_by',label:"Кем создан",type:'multy',link:'/ajax/settings/user?filter[name][like]=',arr:[], filterSearch:'name', minLength:"0",edit:false,object:'entity',r:false},
        {value:'owner_id',label:"Ответственный",type:'multy',link:'/ajax/settings/user?filter[name][like]=',arr:[], filterSearch:'name', minLength:"0",edit:false,object:'entity',r:false},
    ],
    invoice:[
        {value:'id',label:"ID",type:'number',link:'',arr:[], filterSearch:'', minLength:"99999",edit:false,object:null,r:false},
        {value:'prefix_id',label:"Префикс",type:'multy',link:'/ajax/settings/lookup?type=inv_prefix&value=',arr:[], filterSearch:'name', minLength:"0",edit:true,object:'lookup',r:true},
        //{value:'inv_id',label:"Номер счета",type:'number',link:'',arr:[], filterSearch:'', minLength:"99999",edit:true,object:null,r:true},
        {value:'cost',label:"Сумма",type:'number',link:'',arr:[], filterSearch:'', minLength:"99999",edit:false,object:null,r:false},
        {value:'payment',label:"Сумма оплаты",type:'number',link:'',arr:[], filterSearch:'', minLength:"99999",edit:true,object:null,r:false},
        {value:'status_id',label:"Статус оплаты",type:'multy',link:'/ajax/lookup/search?type=inv_status&value=',arr:[], filterSearch:'value', minLength:"0",edit:true,object:'lookup',r:false},
        {value:'account_id',label:"Контрагент",type:'multy',link:'/ajax/account?filter[name][like]=',arr:[], filterSearch:'name', minLength:"1",edit:true,object:'entity',r:false},
        {value:'provider_id',label:"Организация",type:'multy',link:'/ajax/account?filter[name][like]=',arr:[], filterSearch:'name', minLength:"1",edit:true,object:'entity',r:false},
        {value:'opportunity_id',label:"Сделка",type:'multy',link:'/ajax/opportunity?filter[name][like]=',arr:[], filterSearch:'name', minLength:"1",edit:true,object:'entity',r:false},
        {value:'project_id',label:"Проект",type:'multy',link:'/ajax/project?filter[name][like]=',arr:[], filterSearch:'name', minLength:"1",edit:true,object:'entity',r:false},
        {value:'contact_id',label:"Контакт",type:'multy',link:'/ajax/contact?filter[name][like]=',arr:[], filterSearch:'name', minLength:"1",edit:true,object:'entity',r:false},
        {value:'currency_id',label:"Валюта",type:'multy',link:'/ajax/lookup/search?type=currency&value=',arr:[], filterSearch:'text_symbol', minLength:"0",edit:true,object:'lookup',r:false},
        {value:'comment',label:"Комментарий",type:'text',link:'',arr:[], filterSearch:'', minLength:"99999",edit:true,object:null,r:false},
        {value:'created_date',label:"Дата выставления",type:'date',link:'',arr:[], filterSearch:'', minLength:"99999",edit:true,object:null,r:false},
        {value:'payment_date',label:"Предполагаемая дата оплаты",type:'date',link:'',arr:[], filterSearch:'', minLength:"99999",edit:true,object:null,r:false},
        {value:'payed_at',label:"Фактическая дата оплаты",type:'date',link:'',arr:[], filterSearch:'', minLength:"99999",edit:true,object:null,r:false},
        {value:'editorTags',label:"Теги",type:'multy',link:'/ajax/invoice/suggest?term=',arr:[], filterSearch:'name', minLength:"0",edit:false,object:'tags',r:false},
        {value:'created_at',label:"Дата создания",type:'date',link:'',arr:[], filterSearch:'value', minLength:"99999",edit:false,object:null,r:false},
        {value:'updated_at',label:"Дата изменения",type:'date',link:'',arr:[], filterSearch:'value', minLength:"99999",edit:false,object:null,r:false},
        {value:'created_by',label:"Кем создан",type:'multy',link:'/ajax/settings/user?filter[name][like]=',arr:[], filterSearch:'name', minLength:"0",edit:false,object:'entity',r:false},
        {value:'owner_id',label:"Ответственный",type:'multy',link:'/ajax/settings/user?filter[name][like]=',arr:[], filterSearch:'name', minLength:"0",edit:false,object:'entity',r:false},
    ],
    product:[
        {value:'id',label:"ID",type:'number',link:'',arr:[], filterSearch:'', minLength:"99999",edit:false,object:null,r:false},
        {value:'name',label:"Название",type:'text',link:'',arr:[], filterSearch:'', minLength:"99999",edit:true,object:null,r:true},
        {value:'model',label:"Артикул",type:'text',link:'',arr:[], filterSearch:'', minLength:"99999",edit:true,object:null,r:false},
        {value:'base_price',label:"Базовая цена",type:'number',link:'',arr:[], filterSearch:'', minLength:"99999",edit:true,object:null,r:false},
        {value:'purchase_price',label:"Закупочная цена",type:'number',link:'',arr:[], filterSearch:'', minLength:"99999",edit:true,object:null,r:false},
        {value:'currency_id',label:"Валюта",type:'multy',link:'/ajax/lookup/search?type=currency&value=',arr:[], filterSearch:'text_symbol', minLength:"0",edit:true,object:'lookup',r:false},
        {value:'category_id',label:"Категория",type:'multy',link:'/ajax/lookup/search?type=category&value=',arr:[], filterSearch:'name', minLength:"0",edit:true,object:'lookup',r:false},
        //{value:'tax_id',label:"Налог",type:'multy',link:'/ajax/lookup/search?type=acc_type&value=',arr:[], filterSearch:'value', minLength:"0",edit:true,object:'lookup',r:false},
        {value:'editorTags',label:"Теги",type:'multy',link:'/ajax/product/suggest?term=',arr:[], filterSearch:'name', minLength:"0",edit:false,object:'tags',r:false},
        {value:'manufacturer_id',label:"Производитель",type:'multy',link:'/ajax/account?filter[name][like]=',arr:[], filterSearch:'name', minLength:"1",edit:false,object:'entity',r:false},
        {value:'description',label:"Комментарий",type:'text',link:'',arr:[], filterSearch:'', minLength:"99999",edit:true,object:null,r:false},
        {value:'created_at',label:"Дата создания",type:'date',link:'',arr:[], filterSearch:'value', minLength:"99999",edit:false,object:null,r:false},
        {value:'updated_at',label:"Дата изменения",type:'date',link:'',arr:[], filterSearch:'value', minLength:"99999",edit:false,object:null,r:false},
    ],
    order:[
        {value:'id',label:"ID",type:'number',link:'',arr:[], filterSearch:'', minLength:"99999",edit:false,object:null,r:false},
        {value:'name',label:"Название",type:'text',link:'',arr:[], filterSearch:'', minLength:"99999",edit:true,object:null,r:true},
        {value:'cost',label:"Cумма заказа",type:'number',link:'',arr:[], filterSearch:'', minLength:"99999",edit:false,object:null,r:false},
        {value:'payment_cost',label:"Cумма оплаты",type:'number',link:'',arr:[], filterSearch:'', minLength:"99999",edit:false,object:null,r:false},
        {value:'status_id',label:"Состояние заказа",type:'multy',link:'/ajax/lookup/search?type=ord_status&value=',arr:[], filterSearch:'value', minLength:"0",edit:true,object:'lookup',r:false},
        {value:'payment_type_id',label:"Тип оплаты",type:'multy',link:'/ajax/lookup/search?type=ord_payment_type&value=',arr:[], filterSearch:'value', minLength:"0",edit:true,object:'lookup',r:false},
        {value:'delivery_type_id',label:"Тип доставки",type:'multy',link:'/ajax/lookup/search?type=ord_delivery_type&value=',arr:[], filterSearch:'value', minLength:"0",edit:true,object:'lookup',r:false},
        {value:'account_id',label:"Контрагент",type:'multy',link:'/ajax/account?filter[name][like]=',arr:[], filterSearch:'name', minLength:"1",edit:true,object:'entity',r:false},
        {value:'contact_id',label:"Контакт",type:'multy',link:'/ajax/contact?filter[name][like]=',arr:[], filterSearch:'name', minLength:"1",edit:true,object:'entity',r:false},
        {value:'opportunity_id',label:"Сделка",type:'multy',link:'/ajax/opportunity?filter[name][like]=',arr:[], filterSearch:'name', minLength:"1",edit:true,object:'entity',r:false},
        {value:'editorTags',label:"Теги",type:'multy',link:'/ajax/order/suggest?term=',arr:[], filterSearch:'name', minLength:"0",edit:false,object:'tags',r:false},
        {value:'created_at',label:"Дата создания",type:'date',link:'',arr:[], filterSearch:'value', minLength:"99999",edit:false,object:null,r:false},
        {value:'updated_at',label:"Дата изменения",type:'date',link:'',arr:[], filterSearch:'value', minLength:"99999",edit:false,object:null,r:false},
        {value:'created_by',label:"Кем создан",type:'multy',link:'/ajax/settings/user?filter[name][like]=',arr:[], filterSearch:'name', minLength:"0",edit:false,object:'entity',r:false},
        {value:'owner_id',label:"Ответственный",type:'multy',link:'/ajax/settings/user?filter[name][like]=',arr:[], filterSearch:'name', minLength:"0",edit:true,object:'entity',r:false},
    ],
    document:[
        {value:'id',label:"ID",type:'number',link:'',arr:[], filterSearch:'', minLength:"99999",edit:false,object:null,r:false},
        {value:'name',label:"Название",type:'text',link:'',arr:[], filterSearch:'', minLength:"99999",edit:true,object:null,r:true},
        {value:'date',label:"Дата ",type:'date',link:'',arr:[], filterSearch:'', minLength:"99999",edit:true,object:null,r:false},
        {value:'status_id',label:"Статус документа",type:'multy',link:'/ajax/lookup/search?type=dt_status&value=',arr:[], filterSearch:'value', minLength:"0",edit:true,object:'lookup',r:false},
        {value:'type_id',label:"Тип документа",type:'multy',link:'/ajax/lookup/search?type=dt_type&value=',arr:[], filterSearch:'value', minLength:"0",edit:true,object:'lookup',r:false},
        {value:'account_id',label:"Контрагент",type:'multy',link:'/ajax/account?filter[name][like]=',arr:[], filterSearch:'name', minLength:"1",edit:true,object:'entity',r:false},
        {value:'contact_id',label:"Контакт",type:'multy',link:'/ajax/contact?filter[name][like]=',arr:[], filterSearch:'name', minLength:"1",edit:true,object:'entity',r:false},
        {value:'opportunity_id',label:"Сделка",type:'multy',link:'/ajax/opportunity?filter[name][like]=',arr:[], filterSearch:'name', minLength:"1",edit:true,object:'entity',r:false},
        {value:'project_id',label:"Проект",type:'multy',link:'/ajax/project?filter[name][like]=',arr:[], filterSearch:'name', minLength:"1",edit:true,object:'entity',r:false},
        {value:'order_id',label:"Заказ",type:'multy',link:'/ajax/order?filter[name][like]=',arr:[], filterSearch:'name', minLength:"1",edit:true,object:'entity',r:false},
        {value:'editorTags',label:"Теги",type:'multy',link:'/ajax/document/suggest?term=',arr:[], filterSearch:'name', minLength:"0",edit:false,object:'tags',r:false},
        {value:'created_at',label:"Дата создания",type:'date',link:'',arr:[], filterSearch:'value', minLength:"99999",edit:false,object:null,r:false},
        {value:'updated_at',label:"Дата изменения",type:'date',link:'',arr:[], filterSearch:'value', minLength:"99999",edit:false,object:null,r:false},
        {value:'created_by',label:"Кем создан",type:'multy',link:'/ajax/settings/user?filter[name][like]=',arr:[], filterSearch:'name', minLength:"0",edit:false,object:'entity',r:false},
        {value:'owner_id',label:"Ответственный",type:'multy',link:'/ajax/settings/user?filter[name][like]=',arr:[], filterSearch:'name', minLength:"0",edit:true,object:'entity',r:false},
    ],
}
export default entityFields;
