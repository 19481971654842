import React, {Component, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import Input from './Input';
import myaxios from '../../components/Axios';
import InfiniteScroll from "react-infinite-scroll-component";
import Notif from "../Notif";
import {useOnClickOutside} from "../hooks/useOnClickOutside";


const Item = props => {
    return <a
        className={'autoselect-item'}
        value={props.id}
        name={props.itemName}
        //onChange={props.clear}
        onClick={props.getItem}
        item={props.item}
        style={{ background: props.activeItem === props.id && 'linear-gradient(45deg, #80aeba 2%,#699499 46%,#669295 100%)', color: props.activeItem === props.id && '#fff' }}
    >{props.name}</a>
};

const AutoSelect = props => {
    const [activeItem, setActiveItem] = useState('')
    const [state, setMyState] = useState({
        arr: [],
        display: 'none',
        creatable: false,
        tim: null,
        noresult: false,
        inputId: Math.random(),
        pageNumber: 1,
        pageCount: 1,
        perPage: 20,
        totalCount: Number,
        aaa:'',
        aaaId: '',
        posList:'',
        loading: false,
        val:""
    });
    const {onKeyDown = () => {}} = props;
    const setState = (st, func) => {
        Object.keys(st).map((key) => { state[key] = st[key]});
        setMyState(prevState => { return {...prevState, ...state} });
        if(func) func();
    };

    useEffect(() => {
        setState({ aaaId: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) })
    }, [])

    const positionList = () => {
        const el = document.getElementById((state.aaaId * 100000000000000000) + "");
        let height;
        if(el){
            height = window.innerHeight - (el.getBoundingClientRect().height + el.getBoundingClientRect().top);
        } else height = 0;
        //console.log("state.aaaId",state.aaaId, height)
        if(Number(height) < 0 && !props.creatable) setState({ posList: "resize-list" })
    }

    const handleType = (event, type) => {
        if(event.keyCode === 40 || event.keyCode === 38) {
            const foundIndex = state.arr.findIndex(el => el.id === activeItem)

            if(activeItem) {
                if(event.keyCode === 40 && state.arr.length - 1 > foundIndex){
                    setActiveItem(state.arr[foundIndex + 1].id)
                } else if(event.keyCode === 38 && foundIndex !== 0){
                    setActiveItem(state.arr[foundIndex - 1].id)
                }
            } else if(state.arr[0]) {
                setActiveItem(state.arr[0].id)
            }
            return;
        }

        if(event.keyCode === 13 && !props.createTag && activeItem) {
            const foundIndex = state.arr.findIndex(el => el.id == activeItem)

            if(foundIndex === null || foundIndex === undefined) {
                return;
            }
            const clickedItems = document.getElementsByClassName('autoselect-item')
            let event = new Event("click", {bubbles: true});
            console.log(clickedItems, foundIndex)
            clickedItems[foundIndex].dispatchEvent(event);
            setActiveItem('')
            return;
        }

        if(event.keyCode === 13 && props.createTag) {
            props.updateData(null, event.target.value);
        }
        let a = event.target.value;
        setState({ val: a })
        if(a.length >= props.minLength) {
            clearTimeout(state.tim);
            state.tim = setTimeout(()=> {
                setState({loading: true})
                myaxios.get(props.link + a, {
                    method: 'get',
                }).then((response) => {
                    if(response && response.data && response.data.items){
                        inputFocus()
                        setState({
                            loading: false,
                            arr: response.data.items,
                            creatable: (props.creatable === true && response.data.items.length < 1),
                            noresult: (!props.creatable && ((response.data.items) ?  response.data.items.length < 1 : false)),
                            aaa: a, totalCount: response.data._meta ? response.data._meta.totalCount : 1, links: response.data._links, pageCount: response.data._meta ? response.data._meta.pageCount : 1,
                        }, positionList)
                    }
                })
            }, 300);
        } else if(type === "click") Notif("Error", `Начните вводить запрос в поисковое поле... Минимальное количество символов для поиска ${props.minLength}`)
        if(event.target.value.length < props.minLength || event.target.value.length < 1) {
            setState({arr: [], creatable: false, noresult: false})
        }
    };

    const fetchMoreData = () => {
        if(state.pageNumber < state.pageCount) {
            state.pageNumber++;
            setTimeout(() => {
                myaxios.get(props.link + state.aaa, {
                    params: {
                        page: state.pageNumber
                    }
                }).then((response) => {
                    setState({
                        arr: state.arr.concat(response.data.items)
                    });
                })
            }, 1000);
        }
    };


    const onBlur = (event) => {
        setTimeout(()=> {
            setState({ arr: [], creatable: false, noresult: false })
            setActiveItem('')
        }, 150)
    };

    const createTag = (event) => {
        if(event.target.value.length >= 1 && props.createTag){
            setTimeout(props.updateData(null, event.target.value), 100)
        }
    }

    function inputFocus(event) {
        if(document.getElementById(state.inputId)) document.getElementById(state.inputId).focus()
    }

    useEffect(() => {
        setTimeout(onBlur, 300)
    }, [props])

    /*var listElm = document.getElementById(state.aaaId);
    if(listElm){
        listElm.addEventListener('scroll', function() {
            if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
                //loadMore();
                //fetchMoreData()
                console.log("work", listElm)
            }
        });
    }*/

    const dropdownRef = useRef(null)
    useOnClickOutside({ ref: dropdownRef, handler: () => onBlur() })

    return <div ref={dropdownRef} className={`${props.className} ${(props.multiSelect) ? 'multiSelect' : null} AutoSelectParent ${(!props.multiSelectValid && props.multiSelect) ? 'requiredInput' : ''}`} onClick={(props.multiSelect) ? (event) => inputFocus(event) : null}>
        <div className={(props.multiSelect) ? 'itemContainer' : ''}>{
            (props.multiSelect) ? props.multiSelect.map((item, index) =>
                (item !== null) ? <div className="itemContainerItem">{(props.itemType === "object") ? item.name : item}<a title="Отвязать" onClick={()=> props.deleteItem(index)} className="unlink">x</a></div> : null
            ) : null
        }
            <Input
                className="inp"
                id={((props.multiSelect)) ? state.inputId : null}
                openEntity={props.openEntity}
                tooltip={props.tooltip}
                required={props.required}
                label={(!props.multiSelect) ? props.label : null}
                type={props.type}
                value={props.value}
                errorMessage={props.errorMessage}
                shouldValidate={props.shouldValidate}
                valid={props.valid}
                isFormValid={props.isFormValid}
                onChange={event => {props.setField(event, props.event)}}
                onKeyUp={handleType}
                onFocus={handleType}
                onClick={handleType}
                onKeyDown={(e) => {
                    onKeyDown(e);
                    if(e.keyCode === 9){
                        setState({ arr:[], noresult: false });
                    }
                }}
                onBlur={(event) => createTag(event)}
                placeholder={props.placeholder}
                multiSelect={props.multiSelect}
                disabled={props.disabled}
            >
                <em
                    style={{ display: +props.minLength > +state.val && "none" }}
                    onClick={() => {
                        if(state.arr.length > 0) {
                            onBlur()
                        } else {
                            handleType({target:{value:state.val}, keyCode:""}, "click")
                        }
                    }}
                    className={`AutoSelectParent__child ${state.arr.length > 0 && "AutoSelectParent__child-active"} 
                ${!props.valid && props.shouldValidate && "AutoSelectParent__child-novalid"}
                `}>
                    &#10094;
                </em>
            </Input>
        </div>

        <div id={(state.aaaId * 100000000000000000) + ""} className={`${state.posList} AutoSelectList scrollbar ${String(state.aaaId)}`}>
            <InfiniteScroll
                dataLength={state.arr ? state.arr.length : []}
                next={fetchMoreData}
                hasMore={true}
                scrollableTarget={(state.aaaId * 100000000000000000) + ""}
                loader={(state.pageNumber < state.pageCount && state.arr.length >= 1 ) ? <p style={{textAlign: "center", fontFamily: 'Montserrat-Light' }}>Загрузка</p> : null}
            >
                {state.arr ? state.arr.map((item, index) =>
                    <div key={item.id}>
                        <Item
                            activeItem={activeItem}
                            itemName={props.itemName}
                            id={item.id}
                            name={item[props.result]}
                            item={item}
                            //clear={clear}
                            getItem={(e) => {props.updateData(item.id, item, props.addType, props.endParrams, e); setState({arr: []}); }}
                        />
                    </div>) : null}</InfiniteScroll>

            {
                //(state.loading) ? <a>Загрузка ...</a> : null
            }
            {
                (state.creatable) ? <a onClick={() => {props.createData(props.id); setState({arr: []}) }} >Создать товар</a> : null
            }
            {
                //(state.noresult) ? <a>Нет результатов</a> : null
            }
        </div>

    </div>
}

export default AutoSelect;
