import React from 'react';
import Textarea from '../../components/UI/Textarea';
import myaxios from '../../components/Axios';
import InfiniteScroll from "react-infinite-scroll-component";
import SoundClick from '../../components/soundClick';
import {GlobalState} from '../../Layout/Layout';
import {withRouter} from 'react-router-dom';
import fileIcon from "../../fonts/icons/file.svg";
import file2Icon from "../../fonts/icons/image.svg";


function findAndReplaceLink(text) {
    if(text){
        var urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, function(url) {
            return '<a target="_blank" href="' + url + '">' + url + '</a>';
        })
    } else {
        return text
    }
}

function msgColor(type) {
    switch(type){
        case "telegram": return "rgb(55, 175, 226)";
        case "viber": return "rgb(131, 73, 149)";
        case "facebook": return "rgb(66, 103, 178)";
        default: return "rgb(187, 187, 187)";
    }
}

class MessagersList extends React.Component {
    state = {
        id:null,
        img: null,
        name:'',
        type:'',
        open:false,
        list:[],
        messages:[],
        notifications: [],
        settings:false,
        read: false,
        pageNumber: 1,
        pageCount: 1,
        perPage: 15,
        totalCount: Number,
    };
    componentDidMount(){
        this.showUsers();
        if(this.props.id) this.showMessage(this.props.id.id, this.props.id.type, this.props.id.name);
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps !== this.props){
            if(this.props.id.type === "messengers" && !this.state.id){
                this.showUsers();
            }
        }
    }
    showUsers = () => {
        myaxios.get(`/ajax/site/get-messanger-history`).then(res => {
            if(res){
                this.setState({ list: res.data.items })
            }
        });
    };
    showMessage = (id, type, name, img) => {
        this.setState({ id: id, type: type, open: true, name: name, img: img })
    }
    render(){
        return <div className="sbform create-activ">
            {/*<GlobalState.Consumer>
                {context => (
                    this.setState({ notifObject: context.state.notifObject })
                )}
            </GlobalState.Consumer>*/}
            <div className="rmod-header">
                <b>{this.state.id ? <p>{this.state.name.slice(0, 30) + " (" + this.state.type + ")"}</p> : 'Мессенджеры'}</b>
                {(this.state.id) ? <a style={{ right: "65px" }} className="mclose 13" onClick={() => {this.setState({ settings: !this.state.settings }); this.showUsers()}}><i className="flaticon-cogwheel-1" /></a> : null}
                {(this.state.id) ? <a style={{ right: "115px" }} className="mclose 13" onClick={() => {this.setState({ id:null, settings: false }); this.showUsers()}}><i className="flaticon-up-arrow-1" /></a> : null}
                <a style={{ right: "15px" }} className="mclose 13" onClick={this.props.close}><i className="flaticon-close" /></a>
            </div>
            {(this.state.id && this.state.open) ?
                <MessageContainer {...this.props} settings={this.state.settings} pars={this.props.id} toList={() => {this.setState({ id:null, name:'', img:null }); }} id={this.state.id} type={this.state.type} img={this.state.img} /> :
                <MsList setId={(id, type, name, img) => {this.showMessage(id, type, name, img) } } list={this.state.list} />
            }
        </div>
    }
}

export default withRouter(MessagersList);

const MsList = props => {
    return <div className="chatListContainer">
        {
            (props.list) ? props.list.map((item, index) =>
                <a style={{ borderLeft: `5px solid ${msgColor(item.type)}` }} key={index} onClick={() => props.setId(item.user_id, item.type, item.name, item.user)} className={`${(item.has_unseen_message > 0) ? `active` : ''} chatItem`}>
                    {/*<div className="chatFace"><img src="https://colegioclassea.com.br/wp-content/themes/PageLand/assets/img/avatar/avatar.jpg" alt=""/></div>*/}
                    <div className="chatFace"><img src={`${item.user && item.user.picture && item.user.picture.length > 5 ? item.user.picture : 'https://colegioclassea.com.br/wp-content/themes/PageLand/assets/img/avatar/avatar.jpg'}`} alt=""/></div>
                    <div className="chatName"><h4>{item.name}</h4><b style={{ color: msgColor(item.type)}}>{item.type} {item.bot ? item.bot.name : null}</b></div>
                </a>
            ) : null
        }
    </div>
};



class MessageContainer extends React.Component {
    state = {
        name: 'Bob',
        message:'',
        list:[],
        notifObject:'',
        start: false,
        bg: "",
        img:'',
        pageNumber: 1,
        pageCount: 1,
        perPage: 50,
        totalCount: Number,
        sendLoading: false
    };
    componentDidUpdate(prevProps, prevState){
        if(prevProps !== this.props){
            if(this.props.pars.type === "messengers"){
                console.log("this.props.pars", this.props.pars.user_id, this.props.id)
                if(this.props.pars.notif_type === this.props.type && this.props.pars.user_id === this.props.id){
                    const list = this.state.list;
                    list.push({direction:1, message_type: this.props.pars.message_type, text: this.props.pars.message, sender:this.props.pars.sender, created_at: "Только что"});
                    this.setState({ list });
                }
            }
        }
    }
    showMessage = () => {
        myaxios.get(`/ajax/${this.props.type}/chat/get-all-messages/${this.props.id}?expand=sender&sort=created-at&per-page=${this.state.perPage}`).then(res => {
            if(res){
                this.setState({ sendLoading: false, list: res.data.items.reverse(), start: res.data.start, totalCount: res.data._meta.totalCount, links: res.data._links, pageCount: res.data._meta.pageCount });
            }
        })
    }
    fetchMoreData = () => {
        if(this.state.pageNumber < this.state.pageCount) {
            this.state.pageNumber++;
            setTimeout(() => {
                myaxios.get(`/ajax/${this.props.type}/chat/get-all-messages/${this.props.id}?expand=sender&per-page=${this.state.perPage}&sort=created-at`, {
                    params: {
                        page: this.state.pageNumber
                    }
                }).then((response) => {
                    let list = this.state.list.reverse();
                    list = this.state.list.concat(response.data.items).reverse();
                    this.setState({
                        list
                    });
                })
            }, 1000);
        }
    };
    componentDidMount(){
        this.showMessage();
        let a = "#62a091";
        if(this.props.type === "viber"){
            this.setState({ bg:  "#834995" })
        } else if(this.props.type === "telegram"){
            this.setState({ bg: "rgb(55, 175, 226)" })
        } else {
            this.setState({ bg: "#4267b2" })
        }
    }

    handleSelectedFile = (event, type) => {
        this.setState({ sendLoading: true })
        console.log("Здесь")
        const data = new FormData();
        data.append('UploadForm[file]', event.target.files[0], event.target.files[0].name);

        myaxios.post(`https://dev.sboxcrm.com/ajax/file/upload?related_type=34`, data).then(res => {
            this.submitMessage(res.data.id, type)
        }).catch(e => this.setState({ sendLoading: false }))
    };

    submitMessage = (id, type = "text") => {
        this.setState({ sendLoading: true })
        if(this.state.message.length >= 1 || type !== "text"){
            myaxios.post(`/ajax/${this.props.type}/chat/send/${this.props.id}`, {
                type: type,
                message: this.state.message || "",
                file_id: type === "text" ? null : id
            }).then(res => {
                if(res.status === 200 || res.status === 201 || res.status === 204){
                    this.showMessage()
                    this.setState({ message:'', sendLoading: false })
                    /*const list = this.state.list;
                    list.push({direction: 0, text: this.state.message, created_at: "Только что"})
                    this.setState({ list, message:'', sendLoading: false })*/

                }
            }).catch(() => this.setState({ sendLoading: false }))
        }
    };
    editMsg = (event) => {
        if(event.ctrlKey && event.keyCode == 13) {
            this.submitMessage()
        } else {
            this.setState({ message: event.target.value })
        }
    }
    startChat = () => {
        myaxios.post(`/ajax/${this.props.type}/chat/start/${this.props.id}`).then(res => {
            if(res.status === 200 || res.status === 201 || res.status === 204){
                this.setState({ start:true })
            }
        })
    }
    stopChat = () => {
        myaxios.post(`/ajax/${this.props.type}/chat/${this.props.id}/stop`).then(res => {
            if(res.status === 200 || res.status === 201 || res.status === 204){
                this.setState({ start:false })
            }
        })
    }

    render(){
        return <div><div className="chatBlock scrollbar">
            {this.props.settings ? <ul className="chatNav">
                {this.state.start ? <li><a onClick={this.stopChat}>Завершить чат</a></li> : null}
                <li><a onClick={() => this.props.history.push(`/marketing/messengers/users/${this.props.id}`)}>Перейти в профиль</a></li>
                <li><a>Переключить менеджера</a></li>
                {/*<li><a>Сконвертировать в лид</a></li>
                <li><a>Создать контакт</a></li>
                <li><a>Найти существующий контакт</a></li>*/}
            </ul> : null}
            <div>
                {(this.state.pageNumber < this.state.pageCount) ? <button onClick={this.fetchMoreData} className="plusbutton">Загрузить еще</button> : null}
                <div className="clear"></div>
            {
                this.state.list.map((item, index) =>
                    (item.direction != "1") ? <div>
                            <div className="clear" />
                            <div className="itemClear myMessage">
                            {item.message_type === "photo" || item.message_type === "picture" ? <p><img src={item.file ? item.file.url : item.text}  alt=""/><em>{item.time}</em></p> :
                                item.message_type === "file" ?
                                    <p style={{ display: "flex" }}>
                                        <i style={{ fontSize:"26px", paddingRight:"8px" }} className="flaticon-file" />
                                        <div>
                                            <a target="_blank" href={item.file ? item.file.url : item.text} style={{ display:"block" }}>{item.file ? item.file.name : "Файл"}</a>
                                            <em>{item.time}</em>
                                        </div>
                                    </p> :
                                    <p><p dangerouslySetInnerHTML={{__html: findAndReplaceLink(item.text)}} /><em>{item.time}</em></p>}
                        </div></div> : <div>
                        <div className="clear" />
                        <div className="itemClear messageFrom">
                        <div className="msgImg">
                            {/*<img src="https://colegioclassea.com.br/wp-content/themes/PageLand/assets/img/avatar/avatar.jpg" alt=""/>*/}
                            <img src={this.props.img && this.props.img.picture ?  this.props.img.picture : 'https://colegioclassea.com.br/wp-content/themes/PageLand/assets/img/avatar/avatar.jpg'} alt=""/>
                        </div>
                            {/*file.url*/}
                        <div className="msgBlock">
                            {/*<a>{item.sender}</a>*/}
                            {item.message_type === "photo" || item.message_type === "picture" ? <p><img src={item.file ? item.file.url : item.text}  alt=""/><em>{item.time}</em></p> :
                                item.message_type === "file" ?
                                    <p style={{ display: "flex" }}>
                                        <i style={{ fontSize:"26px", paddingRight:"8px" }} className="flaticon-file" />
                                        <div>
                                            <a target="_blank" href={item.file ? item.file.url : item.text} style={{ display:"block" }}>{item.file ? item.file.name : "Файл"}</a>
                                            <em>{item.time}</em>
                                        </div>
                                    </p>
                                : <p><p dangerouslySetInnerHTML={{__html: findAndReplaceLink(item.text)}} /><em>{item.time}</em></p>}
                        </div>
                    </div></div>
                )
            }
            </div>

        </div>
            <div className="clear" />
            {this.state.start ?
                <div className="sendMessageBlock">
                    <button disabled={this.state.sendLoading} onClick={this.submitMessage}><i className="flaticon-paper-plane" /></button>
                    <Textarea disabled={this.state.sendLoading} value={this.state.message} onKeyDown={event => this.editMsg(event)} onChange={event => this.setState({ message: event.target.value })} />

                    {!this.state.sendLoading && <label
                        data-tooltip="Отправить файл"
                        htmlFor="file_message_upload" className="file_message_upload file_message_upload__file">
                        <img src={fileIcon} alt=""/>
                        <input
                            name="UploadForm[file]"
                            onChange={e => this.handleSelectedFile(e, "file")}
                            id="file_message_upload" type="file"
                            style={{ opacity: 0, width: "0", height: "0", overflow: "hidden" }}
                            accept=".pdf, .zip, .gif"
                        />
                    </label>}
                    {!this.state.sendLoading && <label
                        data-tooltip="Отправить изображение"
                        htmlFor="image_message_upload" className="file_message_upload file_message_upload__image">
                        <img src={file2Icon} alt=""/>
                        <input
                            name="UploadForm[file]"
                            onChange={e => this.handleSelectedFile(e, "photo")}
                            id="image_message_upload" type="file"
                            style={{ opacity: 0, width: "0", height: "0", overflow: "hidden" }}
                            accept=".png, .jpg, .jpeg, .gif"
                        />
                    </label>}
                </div> :
            <button onClick={this.startChat} className="startChat">Начать чат</button>}

        </div>
    }
}
