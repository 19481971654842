import React from 'react';
import axios from 'axios';
import Notif from '../components/Notif';


let sub = "/";
var full = window.location.host.split('.')[0]
if(full == "localhost:3000") sub = "https://dev.sboxcrm.com";

const myaxios = axios.create({
    baseURL: sub,
    //timeout: 10000,
    //headers: {'X-Custom-Header': 'foobar'}
});

//instance.interceptors.request.use(function (request) {console.log("error", request)});


myaxios.interceptors.response.use( response => {
    return response
}, (error) => {
    if(error.response){
        //console.log("error.response.data[0]",error.response)
        if(error.response.status === 401){
            Notif("Error", error.response.data.message);
            setTimeout(() => window.location.reload(), 100);
        } else if(error.response.status === 424) {
            Object.keys(error.response.data).map((key, index) => {
                Notif("Error", error.response.data[key][0]);
            });
        } else if(error.response.status === 404) {
            //window.location.href = "/404"
            console.log("props", error.response)
        } else if(error.response.status === 422){
            console.log("errors 422", error.response.data)
            if(!error.response.data) return false;
            if(error.response.data.message) Notif("Error", error.response.data.message);
            if(error.response.data[0]) Notif("Error", error.response.data[0].message);
            if(error.response.data.errors) Object.keys(error.response.data.errors).map(key => {
                Notif("Error", error.response.data.errors[key][0]);
            })
        } else {
            if(error.response.data){
                if(error.response.data[0]){
                    Notif("Error", error.response.data[0].message);
                } else {
                    Notif("Error", error.response.data.message);
                }
            }
        }
    }
    return error
}
);


export default myaxios;
