import React, {useEffect, useState} from "react";
import Modal from "../../Modal";
import Input from "../../UI/Input";
import {BpDateWithPeriod, parseDataForPeriod, saveTimePeriod} from "../BpHelpers";
import Myselect from "../../UI/Select";
import {validationData} from "../../UI/formValid";
import Notif from "../../Notif";

const validationRules = {
    name: { required: true, maxLength: 255 },
    uri: { required: true, maxLength: 255, url: true },
    content_type: { required: true },
    method: { required: true },
}

const toObject = (arr) => {
    return arr.reduce((req, rej) => {
        req[rej.key] = rej.value
        return req
    }, {})
}

const toArray = (obj) => {
    const arr = []
    Object.keys(obj).map(key => {
        arr.push({ key, value: obj[key] })
    })
    return arr
}

export const BpHttpRequest = ({ close, metaData, saveNode }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState({})
    const [body, setBody] = useState([])
    const [headers, setHeaders] = useState([])
    const [values, setValues] = useState({
        name: '',
        content_type: 'none',
        method: 'GET',
        uri: '',
        time_value: 0,
        time_valuePeriod: 'minutes',
        tch: true
    })

    const getData = () => {
        const { name, time_value, metaData: { content_type, method, uri, headers, params }} = metaData
        setValues({
            ...values, name, content_type, method, uri,
            time_value: parseDataForPeriod(time_value).value, time_valuePeriod: parseDataForPeriod(time_value).period
        })
        setBody(toArray(params))
        setHeaders(toArray(headers))
        setLoading(false)
    }

    useEffect(() => {
        setIsOpen(true)
        if(metaData) {
            getData()
        } else {
            setLoading(false)
        }
    }, [])
    
    const saveData = () => {
        const validErrors = validationData(values, validationRules)
        if(Object.keys(validErrors).length > 0) {
            setErrors(validErrors)
            return false;
        }
        setErrors({})
        if(body.find(el => !el.key || !el.value)) {
            Notif('Error', 'Элементы в body не заполнены')
            return false;
        }

        if(headers.find(el => !el.key || !el.value)) {
            Notif('Error', 'Элементы в headers не заполнены')
            return false;
        }

        setIsOpen(false)

        const { name, uri, content_type, method, time_value, time_valuePeriod } = values
        setTimeout(() => saveNode({
            id: metaData && metaData.id,
            name,
            nodeType: 'node',
            time_value: saveTimePeriod(time_value, time_valuePeriod),
            is_scheduled: time_value > 0,
            type: "http_request",
            metaData: {
                uri, content_type, method,
                headers: toObject(headers),
                params: toObject(body)
            }
        }), 100)
    }

    const closeModal = () => {
        setIsOpen(false); setTimeout(close, 200);
    }

    return (
        <Modal showLeaveModal tch={values.tch} isOpen={isOpen} title={'HTTP Запрос'} loading={loading}
               save={saveData} parentClassName={'bpmModal'} close={closeModal}>
            <div className="sbform">
                <Input
                    label={'Название'}
                    value={values.name}
                    onChange={e => setValues({...values, name: e.target.value, tch: false })}
                    errorMessage={errors.name} valid={!errors.name} shouldValidate
                />
                <BpDateWithPeriod
                    label={'Отложенный запуск'}
                    name={'time_value'} periodName={'time_valuePeriod'}
                    values={values} errors={errors}
                    setValues={(type, value) => setValues({...values, [type]: value, tch: false })}
                    tooltip={'Время задержки выполнения процесса'}
                />
                <Input
                    label={'Request URL'}
                    value={values.uri}
                    onChange={e => setValues({...values, uri: e.target.value, tch: false })}
                    errorMessage={errors.uri} valid={!errors.uri} shouldValidate
                />
                <Myselect
                    label={'Метод'}
                    options={[
                        { value: 'GET', label:'GET' },
                        { value: 'POST', label:'POST' },
                        { value: 'PUT', label:'PUT' },
                        { value: 'PATCH', label:'PATCH' },
                        { value: 'DELETE', label:'DELETE' }
                    ]} value={values.method}
                    onChange={e => setValues({ ...values, method: e.target.value })}
                    errorMessage={errors.method} valid={!errors.method} shouldValidate
                />
                <Myselect
                    label={'Тип данных'}
                    options={[
                        { value: 'none', label:'Нет' },
                        { value: 'form', label:'FORM' },
                        { value: 'json', label:'JSON' },
                    ]} value={values.content_type}
                    onChange={e => {
                        setValues({...values, content_type: e.target.value})
                        if(e.target.value === 'none') setBody([])
                    }}
                    errorMessage={errors.content_type} valid={!errors.content_type} shouldValidate
                />
                {(values.content_type === 'form' || values.content_type === 'json') && <div className="connection" style={{ marginTop: '10px' }}>
                    <span>Body</span>
                    <div className="toggleparrent">
                        <button onClick={() => setBody(prevState => ([ ...prevState, { key: '', value: '' } ]))} className="btni">+</button>
                    </div>
                </div>}
                {(values.content_type === 'form' || values.content_type === 'json') && <table className={'filterBpm'} style={{ width: '100%' }}>
                    {
                        body.map((item, index) => (
                            <tr key={index}>
                                <td>
                                    <Input
                                        value={item.key}
                                        onChange={e => {
                                            const parsedBody = JSON.parse(JSON.stringify(body))
                                            parsedBody[index].key = e.target.value
                                            setBody(parsedBody)
                                            setValues({ ...values, tch: false })
                                        }}
                                    />
                                </td>
                                <td>
                                    <Input
                                        value={item.value}
                                        onChange={e => {
                                            const parsedBody = JSON.parse(JSON.stringify(body))
                                            parsedBody[index].value = e.target.value
                                            setBody(parsedBody)
                                            setValues({ ...values, tch: false })
                                        }}
                                    />
                                </td>
                                <td style={{ width: '10px' }}>
                                    <span
                                        onClick={() => {
                                            setBody(body.filter((e, i) => i !== index))
                                            setValues({ ...values, tch: false })
                                        }}
                                        style={{ fontSize: '20px', color: '#c2263f', cursor: 'pointer', display: 'block' }}>&#10005;</span>
                                </td>
                            </tr>
                        ))
                    }
                </table>}


                <div className="connection" style={{ marginTop: '20px' }}>
                    <span>Headers</span>
                    <div className="toggleparrent"><button
                        onClick={() => setHeaders(prevState => ([ ...prevState, { key: '', value: '' } ]))}
                        className="btni">+</button></div>
                </div>
                <table className={'filterBpm'} style={{ width: '100%' }}>
                    {
                        headers.map((item, index) => (
                            <tr key={index}>
                                <td>
                                    <Input
                                        value={item.key}
                                        onChange={e => {
                                            const parsedHeader = JSON.parse(JSON.stringify(headers))
                                            parsedHeader[index].key = e.target.value
                                            setHeaders(parsedHeader)
                                            setValues({ ...values, tch: false })
                                        }}
                                    />
                                </td>
                                <td>
                                    <Input
                                        value={item.value}
                                        onChange={e => {
                                            const parsedHeader = JSON.parse(JSON.stringify(headers))
                                            parsedHeader[index].value = e.target.value
                                            setHeaders(parsedHeader)
                                            setValues({ ...values, tch: false })
                                        }}
                                    />
                                </td>
                                <td style={{ width: '10px' }}>
                                    <span
                                        onClick={() => {
                                            setBody(body.filter((e, i) => i !== index))
                                            setValues({ ...values, tch: false })
                                        }}
                                        style={{ fontSize: '20px', color: '#c2263f', cursor: 'pointer', display: 'block' }}>&#10005;</span>
                                </td>
                            </tr>
                        ))
                    }
                </table>
            </div>
        </Modal>
    )
}