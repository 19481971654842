import React from 'react';
import ReactDOM from 'react-dom';
import Timeline from 'react-calendar-timeline'
import 'react-calendar-timeline/lib/Timeline.css'
import moment from 'moment';
import '../../node_modules/moment/locale/ru';
import axios from 'axios';

moment.lang('ru');

var keys = {
    groupIdKey: "id",
    groupTitleKey: "title",
    groupRightTitleKey: "rightTitle",
    itemIdKey: "id",
    itemTitleKey: "title",
    itemDivTitleKey: "title",
    itemGroupKey: "group",
    itemTimeStartKey: "start",
    itemTimeEndKey: "end",
    groupLabelKey: "title"
};
function formatDate(dat){
    console.log("datedatedate",dat)
    let date = new Date(dat);
    if(date !== null) {
        let dd = date.getDate();
        if (dd < 10) dd = '0' + dd;
        let mm = date.getMonth() + 1;
        if (mm < 10) mm = '0' + mm;
        let yy = date.getFullYear() % 10000;
        if (yy < 10) yy = '0' + yy;
        let hour = date.getHours();
        let min = date.getMinutes();
        return yy + '-' + mm + '-' + dd + ' ' + hour + ':' + min;
    }
}
function getIndex(arr, id) {
    for(let i = 0; i < arr.length; i++){
        if(arr[i].id === id){
            return i
        }
    }
}
export default class GantChart extends React.Component {
    state = {
        items:[],
        minDate: Date.parse(new Date()),
        maxDate: Date.parse(new Date()),
    }
    getProject = () => {
        axios.get(this.props.link).then(res => {
            let items = [];
            let minDate = this.state.minDate;
            let maxDate = this.state.maxDate;
            for(let i = 0; i < res.data.items.length; i++){
                if(Date.parse(new Date(res.data.items[i].start_date)) < minDate) minDate = Date.parse(new Date(res.data.items[i].start_date));
                if(Date.parse(new Date(res.data.items[i].end_date)) > maxDate) maxDate = Date.parse(new Date(res.data.items[i].end_date));
                items.push({ id: res.data.items[i].id, group: res.data.items[i].id, title: res.data.items[i].name, start: Date.parse(new Date(res.data.items[i].start_date)), end: Date.parse(new Date(res.data.items[i].end_date)) })
            }
            this.setState({ items: items, minDate, maxDate });
        })
    };
    componentDidMount(){
        //this.getProject();
        let items = [];
        let minDate = this.state.minDate;
        let maxDate = this.state.maxDate;
        for(let i = 0; i < this.props.items.length; i++){
            items.push({ id: this.props.items[i].id, group: this.props.items[i].id, title: this.props.items[i].name, start: Date.parse(new Date(this.props.items[i].start_date)), end: Date.parse(new Date(this.props.items[i].end_date)) })
        }
        this.setState({ items: items, minDate, maxDate });
    }
    postItem = (id, startDate, endDate, arr) => {
        let start_date = "start_date";
        let end_date = "end_date";
        let calc = startDate + (this.state.items[getIndex(this.state.items, id)].end - this.state.items[getIndex(this.state.items, id)].start)
        axios.put(`${this.props.link}/${id}`, {
            [start_date]: formatDate(startDate),
            [end_date]: (endDate === "drag") ? formatDate(calc) : formatDate(endDate)
        }).then(res => {
            if(res.status === 200){

            } else {
                alert("Не удалось сохранить");
            }
        })
    }
    handleItemMove = (itemId, dragTime, newGroupOrder) => {
        this.postItem(itemId, dragTime, "drag", this.state.items);
        const items = this.state.items;
        this.setState({
            items: items.map(item =>
                item.id === itemId
                    ? Object.assign({}, item, {
                    start: dragTime,
                    end: dragTime + (item.end - item.start),
                })
                    : item
            )
        });
    };

    handleItemResize = (itemId, time, edge) => {
        const items = this.state.items;
        let starttm = edge === "left" ? time : items[getIndex(items, itemId)].start;
        let endtm = edge === "left" ? items[getIndex(items, itemId)].end : time;
        this.postItem(itemId, starttm, endtm, this.state.items);
        this.setState({
            items: items.map(item =>
                item.id === itemId
                    ? Object.assign({}, item, {
                    start: edge === "left" ? time : item.start,
                    end: edge === "left" ? item.end : time
                }) : item
            )
        })
    };
    render(){
        return <div style={this.props.style}>
            <Timeline
                groups={this.state.items}
                items={this.state.items}
                defaultTimeStart={moment().add(-12, 'week')}
                defaultTimeEnd={moment().add(12, 'week')}
                onItemSelect={e => console.log("123312",e)}
                //onItemMove={(itemId, dragTime, newGroupOrder, aaaaa) => { this.redTask(itemId, dragTime)  }}
                //onItemMove={(itemId, dragTime, newGroupOrder, aaaaa) => { this.redTask(itemId, dragTime)  }}
                //onItemResize={(itemId, time, edge) => console.log("(itemId, time, edge)",itemId, time, edge)}
                keys={keys}
                fullUpdate
                itemTouchSendsClick={false}
                stackItems
                itemHeightRatio={0.75}
                canMove={true}
                canResize={"both"}
                onItemMove={this.handleItemMove}
                onItemResize={this.handleItemResize}
            />
        </div>
    }
}