import {InfoTable} from "../../../components/cardViewComponents/InfoTable";
import {ContactFieldsData, ContactFieldsRender} from "../../../components/cardViewComponents/ContactFields";
import {GenerateForm} from "../../../components/cardViewComponents/GenerateForm";
import {AdditionalFieldsChanged} from "../../../components/additionalFields";
import React, {useContext} from "react";
import {AddressesModalNew} from "../../../components/cardViewComponents/AddressesModalNew";
import MainEditor from "../../../components/UI/Editor";
import {accountConfig} from "../accountConfig";
import {GlobalState} from "../../../Layout/Layout";
import Requisites from "../tab/Requisites";

export const InfoTab = ({ id, values, errors, disabled, setValues, changeFields, saveItem, setContactFields, setModalData, requisites, deleteRequisites }) => {
    const { state: { baseCurrency } } = useContext(GlobalState)
    const cost = values.invoicesDiff ? +values.invoicesDiff.cost : 0;
    const payment = values.invoicesDiff ? +values.invoicesDiff.payment : 0

    return (
        <>
            <div className="tab-content sbform">
                <div className="connection" style={{ marginTop: 0 }}><span>Основная информация</span></div>
                <InfoTable
                    arr={[
                        { value: 'Дата создания', label: values.created_at },
                        { value: 'Дата обновления', label: values.updated_at },
                        { value: 'Сумма оплат', label: `${payment && payment.toFixed(2)} ${baseCurrency && baseCurrency.text_symbol}` },
                        { value: 'Сверка по счетам', render: () => <>
                                {((payment - cost) < 0) ? <b style={{color: "#c2263f"}}>Задолженность:</b> : <b style={{color: "#62a091"}}>Задолженности нет:</b>} {(payment - cost).toFixed(2)} {baseCurrency ? baseCurrency.text_symbol : null}
                            </> },
                        { value: 'RFM сегменты', render: () => <>
                                {(values.rfmSegments || []).map(item => item && <span className="label-st" style={{ backgroundColor: item.segment && item.segment.color }} key={item.segment_id}>{item.segment && item.segment.name}</span>)}
                            </> },
                        { value: 'Сегменты', render: () => <>
                                {(values.segments || []).map(item => item && <span className="label-st" style={{ color: "#2b2b2b" }} key={item.segment_id}>{item.segment && item.segment.name}</span>)}
                            </> },
                    ]}
                />
                <ContactFieldsData
                    errors={errors}
                    contactFields={values.contactFields}
                    setValues={setContactFields}
                />
                <GenerateForm
                    config={accountConfig}
                    { ...{ values, errors, disabled } }
                    onChange={changeFields}
                    onSaveData={saveItem}
                    zone={2}
                />
                <ContactFieldsRender
                    errors={errors}
                    contactFields={values.contactFields}
                    setValues={setContactFields}
                />
                <AddressesModalNew entity={'account'} id={id} />
                <Requisites
                    requisites={requisites}
                    openAddress={(type, name, id) => setModalData({ type: 'requisites', id })}
                    deleteModal={(type, name, index, id) => deleteRequisites(id)}
                />
                <div className="connection"><span>Дополнительная информация</span></div>
                <AdditionalFieldsChanged
                    errors={errors}
                    items={values.additionalFields}
                    updateAdditionalFields={data => setValues({ additionalFields: data, tch: false })}
                />
                <div className="clear" />
                <div className="fgroup rc12">
                    <MainEditor
                        label={'Описание'}
                        value={values.description}
                        height={350}
                        valid={!errors.description}
                        errorMessage={errors.description}
                        onChange={description => {
                            if(description !== values.description) {
                                setValues({ description })
                            }
                        }}
                    />
                </div>
            </div>
        </>
    )
}