import React, {Component} from 'react';
import {Route, NavLink, Switch, withRouter} from 'react-router-dom';
import cases from "../../components/related/cases";

function entity(entity) {
    if(entity === "task"){
        return "task/view";
    } else {
        return entity
    }
}

function switchTypeLink(t) {
    switch(t){
        case "task": return "task/view";
        default: return t
    }
}

const Items = props => {
    switch (props.type){
        case "newlead": return <div style={{ background: (props.is_seen === 1) ? '#f7f7f7' : '' }} className="m-list-timeline__item">
            <span className="m-list-timeline__badge m-list-timeline__badge--success" />
            <span className={"m-list-timeline__icon " + props.icon} />
            <span onClick={() => {props.history.push(`/${props.route.entity}/${props.route.id}`); setTimeout(props.close)}} className="m-list-timeline__text">Добавлена новая сделка <a>{props.title}</a></span>
            <span className="m-list-timeline__time">{props.time}</span>
        </div>;

        case "new_lead": return <div style={{ background: (props.is_seen === 1) ? '#f7f7f7' : '' }} className="m-list-timeline__item">
            <span className="m-list-timeline__badge m-list-timeline__badge--primary" />
            <span className={"m-list-timeline__icon flaticon-network"} />
            <span onClick={() => {props.history.push(`/${props.route.entity}/${props.route.id}`); setTimeout(props.close)}} className="m-list-timeline__text"><b>{/*{props.emailtype}*/}</b> {props.message}</span>
            <span className="m-list-timeline__time">{props.time}</span>
        </div>;

        case "successful": return <div style={{ background: (props.is_seen === 1) ? '#f7f7f7' : '' }} className="m-list-timeline__item">
            <span className="m-list-timeline__badge m-list-timeline__badge--primary" />
            <span className={"m-list-timeline__icon " + props.icon} />
            <span className="m-list-timeline__text"><NavLink to={props.leadLink}>{props.title}</NavLink> успешно завершена<br /><b>Сумма: </b> {props.sum}</span>
            <span className="m-list-timeline__time">{props.time}</span>
        </div>;

        case "new_task": return <div style={{ background: (props.is_seen === 1) ? '#f7f7f7' : '' }} className="m-list-timeline__item">
            <span className="m-list-timeline__badge m-list-timeline__badge--success" />
            <span className={"m-list-timeline__icon flaticon-time-2"} />
            <span onClick={() => {props.history.push(`/task/view/${props.route.id}`); setTimeout(props.close)}} className="m-list-timeline__text">Новая задача "{props.taskText}"</span>
            <span className="m-list-timeline__time">{props.time}</span>
        </div>;

        case "task_status_change": return <div style={{ background: (props.is_seen === 1) ? '#f7f7f7' : '' }} className="m-list-timeline__item">
            <span className="m-list-timeline__badge m-list-timeline__badge--success" />
            <span className={"m-list-timeline__icon flaticon-time-2"} />
            <span onClick={() => {props.history.push(`/task/view/${props.route.id}`); setTimeout(props.close)}} className="m-list-timeline__text">Новая задача "{props.taskText}"</span>
            <span className="m-list-timeline__time">{props.time}</span>
        </div>;

        case "expiredTask": return <div style={{ background: (props.is_seen === 1) ? '#f7f7f7' : '' }} className="m-list-timeline__item">
            <span className="m-list-timeline__badge m-list-timeline__badge--danger" />
            <span className={"m-list-timeline__icon " + props.icon} />
            <span onClick={() => {props.history.push(`/task/view/${props.route.id}`); setTimeout(props.close)}} className="m-list-timeline__text">Задача "{props.taskText}" изменила статус на <span className="danger m-badge">{props.taskstatus}</span></span>
            <span className="m-list-timeline__time">{props.time}</span>
        </div>;

        case "endtask": return <div style={{ background: (props.is_seen === 1) ? '#f7f7f7' : '' }} className="m-list-timeline__item">
            <span className="m-list-timeline__badge m-list-timeline__badge--warning">{null}</span>
            <span className={"m-list-timeline__icon " + props.icon} />
            <span onClick={() => {props.history.push(`/task/view/${props.route.id}`); setTimeout(props.close)}} className="m-list-timeline__text">Задача "{props.taskText}" завершена</span>
            <span className="m-list-timeline__time">{props.time}</span>
        </div>;

        case "billstatus": return <div style={{ background: (props.is_seen === 1) ? '#f7f7f7' : '' }} className="m-list-timeline__item">
            <span className="m-list-timeline__badge m-list-timeline__badge--primary" />
            <span className={"m-list-timeline__icon " + props.icon} />
            <span className="m-list-timeline__text">Счет <NavLink to={props.invoicelink}>{props.invoicenumb}</NavLink> сменил статус на <span className="m-badge m-badge--success m-badge--wide">{props.phase}</span>
										</span>
            <span className="m-list-timeline__time">{props.time}</span>
        </div>;

        case "new_message": return <div style={{ background: (props.is_seen === 1) ? '#f7f7f7' : '' }} className="m-list-timeline__item">
            <span className="m-list-timeline__badge m-list-timeline__badge--primary" />
            <span className={"m-list-timeline__icon flaticon-email"} />
            <span onClick={() => {props.history.push(`/${props.route.entity}/${props.route.id}`); setTimeout(props.close)}} className="m-list-timeline__text"><b>{/*{props.emailtype}*/}</b> {props.message} {/*<NavLink onClick={props.emaillink}>Открыть</NavLink>*/}</span>
            <span className="m-list-timeline__time">{props.time}</span>
        </div>;

        case "new_opportunity": return <div style={{ background: (props.is_seen === 1) ? '#f7f7f7' : '' }} className="m-list-timeline__item">
            <span className="m-list-timeline__badge m-list-timeline__badge--primary" />
            <span className={"m-list-timeline__icon flaticon-share"} />
            <span onClick={() => {props.history.push(`/${props.route.entity}/${props.route.id}`); setTimeout(props.close)}} className="m-list-timeline__text"><b>{/*{props.emailtype}*/}</b> {props.message}</span>
            <span className="m-list-timeline__time">{props.time}</span>
        </div>;

        case "responsible_change": return <div style={{ background: (props.is_seen === 1) ? '#f7f7f7' : '' }} className="m-list-timeline__item">
            <span className="m-list-timeline__badge m-list-timeline__badge--primary" />
            <span className={"m-list-timeline__icon flaticon-user-ok"} />
            <span onClick={() => {props.history.push(`/${entity(props.route.entity)}/${props.route.id}`); setTimeout(props.close)}} className="m-list-timeline__text"><b>{/*{props.emailtype}*/}</b> {props.message}</span>
            <span className="m-list-timeline__time">{props.time}</span>
        </div>;

        case "new_invoice": return <div style={{ background: (props.is_seen === 1) ? '#f7f7f7' : '' }} className="m-list-timeline__item">
            <span className="m-list-timeline__badge m-list-timeline__badge--primary" />
            <span className={"m-list-timeline__icon flaticon-user-ok"} />
            <span onClick={() => {props.history.push(`/${entity(props.route.entity)}/${props.route.id}`); setTimeout(props.close)}} className="m-list-timeline__text"><b>{/*{props.emailtype}*/}</b> {props.message}</span>
            <span className="m-list-timeline__time">{props.time}</span>
        </div>;

        case "project_new": return <div style={{ background: (props.is_seen === 1) ? '#f7f7f7' : '' }} className="m-list-timeline__item">
            <span className="m-list-timeline__badge m-list-timeline__badge--primary" />
            <span className={"m-list-timeline__icon flaticon-map"} />
            <span onClick={() => {props.history.push(`/${props.route.entity}/${props.route.id}`); setTimeout(props.close)}} className="m-list-timeline__text"><b>{/*{props.emailtype}*/}</b> {props.message}</span>
            <span className="m-list-timeline__time">{props.time}</span>
        </div>;

        case "project_completed": return <div style={{ background: (props.is_seen === 1) ? '#f7f7f7' : '' }} className="m-list-timeline__item">
            <span className="m-list-timeline__badge m-list-timeline__badge--primary" />
            <span className={"m-list-timeline__icon flaticon-map"} />
            <span onClick={() => {props.history.push(`/${props.route.entity}/${props.route.id}`); setTimeout(props.close)}} className="m-list-timeline__text"><b>{/*{props.emailtype}*/}</b> {props.message}</span>
            <span className="m-list-timeline__time">{props.time}</span>
        </div>;

        case "remind_task": return <div style={{ background: (props.is_seen === 1) ? '#f7f7f7' : '' }} className="m-list-timeline__item">
            <span className="m-list-timeline__badge m-list-timeline__badge--primary" />
            <span className={"m-list-timeline__icon flaticon-music-1"} />
            <span onClick={() => {props.history.push(`/task/view/${props.route.id}`); setTimeout(props.close)}} className="m-list-timeline__text"><b>{/*{props.emailtype}*/}</b> {props.message}</span>
            <span className="m-list-timeline__time">{props.time}</span>
        </div>;

        case "overdue_task": return <div style={{ background: (props.is_seen === 1) ? '#f7f7f7' : '' }} className="m-list-timeline__item">
            <span className="m-list-timeline__badge m-list-timeline__badge--primary" />
            <span className={"m-list-timeline__icon flaticon-clock-1"} />
            <span onClick={() => {props.history.push(`/task/view/${props.route.id}`); setTimeout(props.close)}} className="m-list-timeline__text"><b>{/*{props.emailtype}*/}</b> {props.message}</span>
            <span className="m-list-timeline__time">{props.time}</span>
        </div>;

        case "regular_notification": return <div style={{ background: (props.is_seen === 1) ? '#f7f7f7' : '' }} className="m-list-timeline__item">
            <span className="m-list-timeline__badge m-list-timeline__badge--primary" />
            <span className={"m-list-timeline__icon flaticon-exclamation-2"} />
            <span onClick={() => {props.history.push(`/${switchTypeLink(props.route.entity)}/${props.route.id}`);  setTimeout(props.close)}} className="m-list-timeline__text"><b>{/*{props.emailtype}*/}</b> {props.message}</span>
            <span className="m-list-timeline__time">{props.time}</span>
        </div>;

        default: return <div style={{ background: (props.is_seen === 1) ? '#f7f7f7' : '' }} className="m-list-timeline__item">
            <span className="m-list-timeline__badge m-list-timeline__badge--primary" />
            <span className={"m-list-timeline__icon flaticon-music-1"} />
            <span className="m-list-timeline__text"><b>{/*{props.emailtype}*/}</b> {props.message}</span>
            <span className="m-list-timeline__time">{props.time}</span>
        </div>;
    }
};

export default withRouter(Items);