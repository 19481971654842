import React, {useEffect, useState} from "react";
import myaxios from "../components/Axios";
import Loader from "../components/UI/Loader";
import Modal from "../components/Modal/Modal";
import printIcon from "../img/print.svg";

export const PrintModal = ({ isOpen, close, entity, entity_type, history, data }) => {
    const [items, setItems] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        (async () => {
            if(isOpen && data) {
                const { status, data } = await myaxios.get(`/ajax/plates`)
                if(status === 200) {
                    setItems(data.items.filter(el => +el.object_type === +entity_type))
                }
                setLoading(false)
            }
        })()
    }, [isOpen])

    if(!data) return <Loader />

    return (
        <Modal isOpen={isOpen} close={close} save={close} loading={!isOpen || loading}>
            <>
                <div className="second-table" style={{ marginTop: '10px' }}>
                    <table style={{ width: '100%' }}>
                        <tr>
                            <th>Документ</th>
                            <th style={{ width: '50px' }} />
                        </tr>
                        {
                            items.map(item => (
                                <tr key={item.id}>
                                    <td><a target={'_blank'} href={`/ajax/${entity}/${data.id}/document?plates_id=${item.id}`}>{item.name}</a></td>
                                    <td>
                                        <a target={'_blank'} href={`/ajax/${entity}/${data.id}/document?plates_id=${item.id}`}>
                                            <img style={{ width: 25, display: 'block', margin: '0 auto' }} src={printIcon} alt=""/>
                                        </a>
                                    </td>
                                </tr>
                            ))
                        }
                        {items.length === 0 && <tr>
                            <td>Нет документов для печати</td>
                            <td />
                        </tr>}
                    </table>
                    <button onClick={() => history.push('/settings/plate')} className="success btni mt10">Добавить документ</button>
                </div>
            </>
        </Modal>
    )
}