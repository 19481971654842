import React, {useEffect, useState} from "react";
import {ModalLoader} from "../../components/UI/Loader";
import LeaveModal from "../../components/other/LeaveModal";
import {TagsNew} from "../../components/cardViewComponents/TagsNew";
import {MemoButton} from "../../components/cardViewComponents/MemoButton";
import IconButton from "../../components/other/IconButton";
import {GenerateForm} from "../../components/cardViewComponents/GenerateForm";
import {ContactCard} from "../../components/cardViewComponents/ContactCard";
import {AccountCard} from "../../components/cardViewComponents/AccountCard";
import {Tasks} from "../../components/Task";
import {filesFunc, TabsRender} from "../../components/fieldGen";
import {InfoTab} from "./InfoTab";
import {CSSTransition} from "react-transition-group";
import Products from "../../components/products";
import Related from "../../components/related/related";
import contact from "../contacts/Contact";
import History from "../../components/history/history";
import Files from "../../components/files/files";
import myaxios from "../../components/Axios";
import {
    deleteOpportunityService,
    getSingleOpportunityService,
    updateOpportunityService
} from "../../services/opportunity/opportunityService";
import {parseResponse, parseResponseError} from "../../components/cardViewComponents/parseFunctions";
import GetPostAditionalFields from "../../components/get_post_aditionalFields";
import {transformSaveContactFields} from "../../components/cardViewComponents/ContactFields";
import {TaskModal, updateEntityTasks} from "../tasks/TaskModal";
import {additionalFieldsValidation} from "../../components/cardViewComponents/additionalFieldsValidation";
import {validationData} from "../../components/UI/formValid";
import {validationConfig} from "../../components/cardViewComponents/validationConfig";
import Notif from "../../components/Notif";
import {deleteContactService} from "../../services/contact/contactService";
import {ConflictModal} from "../../components/cardViewComponents/ConflictModal";
import {CanDelete} from "../../Modal/CanDelete";
import {DropDownButton} from "../../components/cardViewComponents/DropDownButton";
import {FilesDropDown} from "../../components/FilesDropDown";

export const tabsConfig = [
    {value:"information", label:"Информация"},
    {value:"products", label:"Продукты"},
    {value:"related", label:"Связи"},
    {value:"history", label:"Хронология"},
    {value:"files", label:"Файлы"},
]

export const opportunityConfig = {
    name: {
        label: 'Название',
        type: 'text',
        className: 'w100',
        validation: { required: true, maxLength: 255 },
        zone: 1
    },
    amount: {
        label: 'Бюджет',
        type: 'text',
        className: 'w60',
        validation: { number: true, maxLength: 255 },
        zone: 1
    },
    currency_id: {
        label: 'Валюта',
        type: 'select',
        options: [],
        className: 'w40',
        options_from: 'currencies',
        // prepare: 'currencies',
        val: 'id', lab: 'name',
        validation: { required: true },
        zone: 1,
    },
    stage_id: {
        label: 'Этап сделки',
        type: 'select',
        options: [],
        className: 'w100',
        options_from: 'stages',
        // prepare: 'statues',
        val: 'id',
        lab: 'value',
        validation: { required: true },
        zone: 1
    },
    lead_source_id: {
        label: 'Источник сделки',
        type: 'entity',
        link: "/ajax/lookup/search?type=ld_source&value=",
        result: 'value',
        entity_from: 'source',
        entityName: 'value',
        className: 'w100',
        minLength: 0,
        zone: 1
        // redirectLink
    },
    owner_id: {
        label: 'Ответственный',
        type: 'entity',
        link: "/ajax/settings/user?filter[name][like]=",
        result: 'name',
        entity_from: 'owner',
        entityName: 'name',
        className: 'w100',
        minLength: 0,
        validation: { required: true },
        zone: 1
        // redirectLink
    },
    type_id: {
        label: 'Тип потребности',
        type: 'entity',
        link: "/ajax/lookup/search?type=opp_type&value=",
        result: 'value',
        entity_from: 'type',
        entityName: 'value',
        className: 'w50-p',
        minLength: 0,
        zone: 2
    },
    probability: {
        label: 'Вероятность сделки',
        type: 'number',
        className: 'w50-p',
        validation: { number: true, maxLength: 255, minNumber: 0, maxNumber: 100 },
        zone: 2
    },
    description: {
        type: 'text',
        validation: { maxLength: 2000 },
    },
    main_contact_id: { type: 'entity', entity_from: 'contact' },
    account_id: { type: 'entity', entity_from: 'account' },
    editorTags: {},
    created_at: {},
    updated_at: {},
}

export const OpportunityViewNew = ({ match, history, location }) => {
    const urlParams = new URLSearchParams(location.search);
    const { params: { id } } = match
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState({})
    const [disabled, setDisabled] = useState({})
    const [conflict, setConflict] = useState(false)
    const [modalData, setModalData] = useState(null)
    const [values, setValues] = useState({
        account_id: null,
        account: null,
        amount: '',
        contact_id: null,
        contact: null,
        main_contact_id: null,
        currency_id: null,
        currencies: [],
        description: '',
        name: '',
        owner_id: null,
        owner: null,
        probability: 0,
        stage_id: null,
        stages: [],
        type_id: null,
        type: null,
        lead_source_id: null,
        source: null,
        created_at: '',
        updated_at: '',
        editorTags: '',
        files: [],
        tasks: [],
        additionalFields: [],
        contactFields: [],
        begin_update_datetime: null,
        tch: true
    })

    const getData = async () => {
        try {
        const { status: currencyStatus, data: currencyData } =
            await myaxios.get(`/ajax/settings/lookup?type=currency`)


            const { status, data, response } = await getSingleOpportunityService({
                id, expand: 'contacts,account,tasks,files,stages,contact,owner,additionalFields,currency'
            })

            const parseResponseData = parseResponseError(response);
            if(parseResponseData.notFound) history.push('/404')

            if(status === 200 && data.id) {
                setValues(({
                    ...values,
                    ...parseResponse(opportunityConfig, data, {}),
                    currencies: currencyData.items,
                    stages: data.stages,
                    files: data.files,
                    tasks: data.tasks,
                    additionalFields: GetPostAditionalFields("get", data.additionalFields) || []
                }))
            }
        } catch (e) {}

        setLoading(false)
    }

    const saveItem = async (forcibly) => {
        const { name, account_id, main_contact_id, amount, currency_id, description, owner_id, probability, stage_id, account,
            type_id, lead_source_id, editorTags, updated_at } = values

        if (!main_contact_id && !account_id) {
            Notif("required-fields", 'К сделке должен быть привязан контакт или контрагент')
            return
        }

        const additionalFieldsValidationRes = additionalFieldsValidation(values.additionalFields)
        const errorsData = validationData(
            {...values, ...additionalFieldsValidationRes.values},
            {...validationConfig(opportunityConfig), ...additionalFieldsValidationRes.validationRules}
        )

        console.log(errorsData)

        setErrors(errorsData)
        if(Object.keys(errorsData).length > 0) {
            Notif('required-fields')
            return
        }

        setLoading(true)

        const updatedData = {
            name, account_id, main_contact_id, amount, currency_id, description, owner_id, probability, stage_id, account,
            type_id, lead_source_id, editorTags, begin_update_datetime: forcibly === 'ok' ? null : updated_at,
            additionalFields: GetPostAditionalFields("post", values.additionalFields)
        }

        try {
            const { status, data, response } = await updateOpportunityService({ id, data: updatedData })

            if(status === 200 && data.id) {
                Notif('save', 'Запись успешно изменена')
                setValues(({ ...values, updated_at: data.updated_at, tch: true, begin_update_datetime: null }))
            }

            const parseResponseData = parseResponseError(response);
            if(parseResponseData.saveConflict) setConflict(true)
            if(parseResponseData.validationErrors) {
                setErrors(parseResponseData.validationErrors)
            }
        } catch (e) { }

        setLoading(false)
    }

    const deleteItem = async () => {
        if(!window.confirm('Удалить элемент?')) return false

        setLoading(true)
        try {
            const { status, data } = await deleteOpportunityService({ id })
            if(status === 200 || status === 204) {
                if(!data.canDelete) {
                    Notif('delete', 'Сделка успешно удалена')
                    setValues(prevState => ({ ...prevState, tch: true }))
                    history.push('/opportunity')
                } else if(data.items && typeof data.items === 'object') {
                    setModalData({ type: 'canDelete', items: data.items })
                } else {
                    Notif('Error', 'При удалении что-то пошло не так')
                }
            }
        } catch (e) { console.error(e) }
        setLoading(false)
    }

    useEffect(() => {
        getData().then()
    }, [id])

    const changeFields = (key, value, item) => {
        let obj = {}
        if(item) {
            if(item === 'clear') obj[opportunityConfig[key].entity_from] = null
            else obj[opportunityConfig[key].entity_from] = item
        }

        setValues({
            ...values, ...obj, [key]: item ? item.id : value,
            begin_update_datetime: !values.begin_update_datetime ? new Date() : values.begin_update_datetime,
            tch: false
        })
    }

    const createInvoice = async () => {
        if(window.confirm()) {
            const { status, data } = await myaxios.post(`/ajax/opportunity/${id}/create-invoice`)
            if (status === 200 || status === 201 || status === 204) {
                history.push(`/invoice/${data}`);
                Notif("save", "Счет успешно создан")
            }
        }
    }

    return (
        <div>
            {loading && <ModalLoader />}
            <div className="page">
                <LeaveModal when={!values.tch} />
                <div className="newList-btn-group">
                    <div className="newList-btn-group-left">
                        <TagsNew
                            editorTags={values.editorTags} entity={'opportunity'}
                            setValues={data => setValues({ ...values, editorTags: data, tch: false })}
                        />
                        <MemoButton
                            onClick={() => setModalData({ type: 'task' })}
                            mobileIcon={'flaticon-notes mdb'} title={'Добавить задачу'} />
                        <DropDownButton
                            label={'Создать документ'}
                            items={({ close }) => <>
                                <li onClick={() => {createInvoice(); close()}}><a>Создать счет на основании сделки</a></li>
                            </>}
                        />
                    </div>
                    <div className="newList-btn-group-right">
                        <a onClick={deleteItem} className="butt-group-card-wrap btni ml10">
                            <span>Удалить</span>
                            <i className="flaticon-delete-2 mdb" />
                        </a>
                        {/*<div className="toogle-bottom">
                            <button onClick={() => setModalData({ type: 'qualification' })} className="mdn success btni ml5">Перевести в продажу</button>
                        </div>*/}
                        <MemoButton onClick={saveItem} disabled={values.tch} mobileIcon={"flaticon-interface-1 mdb"} title={'Сохранить'} className={'ml5'} />
                        <IconButton href="https://sboxcrm.com/docs/opportunity" />
                    </div>
                </div>

                <div className="contact-page sbform">
                    <div className="contact-left">
                        <div className="contact-left-col">
                            <h3>Данные сделки</h3>
                            <GenerateForm
                                config={opportunityConfig}
                                { ...{ values, errors, disabled } }
                                onChange={changeFields}
                                onSaveData={saveItem}
                                zone={1}
                            />
                            <div className="clear" />
                        </div>

                        {!values.account && <ContactCard
                            contact={values.contact}
                            setDataValues={data => {
                                setValues(({...values, ...data, tch: false}))
                            }}
                        />}
                        {!values.contact && <AccountCard
                            account={values.account}
                            setDataValues={data => {
                                setValues(({...values, ...data, tch: false}))
                            }}
                        />}
                    </div>
                    <div className="contact-right-col">
                        <Tasks tasks={values.tasks} openTask={data => setModalData(data)} />
                        <TabsRender
                            setState={({ layout }) => history.push(`?layout=${layout}`)}
                            state={{ layout: urlParams.get('layout') || tabsConfig[0].value }}
                            arr={tabsConfig}
                        />
                        <div className="tab-container">
                            <CSSTransition in={(urlParams.get('layout') || tabsConfig[0].value) === tabsConfig[0].value} timeout={{enter: 0, exit: 0}} classNames="my-node" unmountOnExit>
                                <InfoTab
                                    {...{ values, errors, disabled, changeFields, id, saveItem, opportunityConfig }}
                                    setValues={data => setValues(({ ...values, ...data, tch: false }))}
                                />
                            </CSSTransition>
                            <CSSTransition in={urlParams.get('layout') === tabsConfig[1].value} timeout={{enter: 0, exit: 0}} classNames="my-node" unmountOnExit>
                                <Products
                                    type="opportunity"
                                    id={id}
                                    related="1"
                                    isPrice isDiscount
                                    updatePrice={sum => setValues({ ...values, cost: sum })}
                                />
                            </CSSTransition>
                            <CSSTransition in={urlParams.get('layout') === tabsConfig[2].value} timeout={{enter: 0, exit: 0}} classNames="my-node" unmountOnExit>
                                <Related
                                    type="opportunity"
                                    id={id}
                                    related="1"
                                    contactEmail={(values.contact) ? values.contact.email : null}
                                    accountEmail={(values.account) ? values.account.email : null}
                                    opportunity_id={id}
                                    contact_id={values.main_contact_id}
                                    account_id={values.account_id}
                                    account={values.account}
                                    contact={values.contact}
                                    tasks stages invoices documents sms projects mails expense isPlates
                                />
                            </CSSTransition>
                            <CSSTransition in={urlParams.get('layout') === tabsConfig[3].value} timeout={{enter: 0, exit: 0}} classNames="my-node" unmountOnExit>
                                <History type="opportunity" id={id} />
                            </CSSTransition>
                            <CSSTransition in={urlParams.get('layout') === tabsConfig[4].value} timeout={{enter: 0, exit: 0}} classNames="my-node" unmountOnExit>
                                <FilesDropDown
                                    related_id={id}
                                    related_type={"1"}
                                    files={values.files}
                                    updateFile={filesData => setValues(prevState => ({
                                        ...prevState,
                                        files: [filesData, ...prevState.files]
                                    }))}
                                    updateFiles={files => setValues(prevState => ({ ...prevState, files }))}
                                />
                            </CSSTransition>
                        </div>
                    </div>
                </div>
            </div>

            <TaskModal
                id={modalData && modalData.id} close={() => setModalData(null)} index={modalData && modalData.index}
                task={(type, data, index) => { setValues({ ...values, tasks: updateEntityTasks(values.tasks, type, data, index) }) }}
                related={{ id, type: 1 }} isOpen={modalData && modalData.type === 'task'}
            />

            {conflict && <ConflictModal
                entity={'opportunity'} id={id} updated_at={values.begin_update_datetime}
                save={() => saveItem('ok')} close={() => setConflict(false)}
            />}

            <CanDelete
                isOpen={modalData && modalData.type === 'canDelete'} close={() => setModalData(null)}
                items={modalData && modalData.items} entity={'opportunity`'} delParent={deleteItem} name={'сделка'} history={history}
            />
        </div>
    )
}