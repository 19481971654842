import React, {Component} from 'react';
import FilterBpm from './Filter';
import Input from '../UI/Input';
import formValid from '../UI/formValid';
import Notif from '../Notif';
import Myselect from '../UI/Select';
import myaxios from '../Axios';
import entityFields from './actions/entityFieldsArr';

const condition = [
    {value:"stage", label:"При переходе в этап"},
    {value:"create", label:"При создании"},
    {value:"email", label:"При входящем письме"},
    {value:"call", label:"При входящем звонке"},
    {value:"message", label:"При входящем сообщении"},
    {value:"owner", label:"При смене ответственного"}
];


export default class ConditionBlock extends Component {
    state = {
        formControl: {
            name: {
                value:'',
                valid: true,
                validation: {required: true, minLength: 3}
            },
            condition: {
                value:'',
                valid: true,
            }
        },
        condition: [],
    };
    validateFilter = () => {
        let valid = true;
        for(let i = 0; i < this.state.condition.length; i++){
            if(this.state.condition[i].field.length == 0){
                Notif("Error", "Условие не может быть пустым");
                valid = false;
            }
        }
        return valid;
    }
    componentWillMount(){
        const formControl = this.state.formControl;
        if(this.props.data.type === "edit"){
            formControl.name.value = this.props.data.arr.name;
            formControl.condition.value = this.props.data.arr.condition;
            this.setState({ formControl, condition: this.props.data.arr.conditions })
        } else {
            //formControl.filter = [{field: '', fieldType:'', operator:'equal', obj:null, value:''}];
            this.setState({ formControl, condition: [] })
        }
    }
    setField = (type, e ) => {
        const formControl = this.state.formControl;
        formControl[type].value = e;
        formControl[type].valid = formValid(e, formControl[type].validation);
        this.setState({ formControl })
    }

    sendForm = e => {
        e.preventDefault();
        if(this.isFormValid() && this.validateFilter()){
                let arr = {
                    parent: this.props.data.type === "edit" ? this.props.data.parent : null,
                    child: this.props.data.type === "edit" ? this.props.data.parent.child : null,
                    arr:{
                        id: this.props.data.type === "edit" ? this.props.data.arr.id : null,
                        name: this.state.formControl.name.value,
                        condition: this.state.formControl.condition.value,
                        conditions: this.state.condition,
                        nodeType: "condition",
                        actions: this.props.data.type === "edit" ? this.props.data.arr.actions : []
                    }
                };
                if(this.props.data.type === "edit"){
                    setTimeout(this.props.updateDataCondition(arr));
                } else {
                    setTimeout(this.props.createCondition(arr));
                }
                setTimeout(this.props.close);
        } else {

        }
    }
    isFormValid = () => {
        let formControl = this.state.formControl;
        let isFormValid = true;
        Object.keys(formControl).map((key, index) => {
            formControl[key].valid = formValid(formControl[key].value, formControl[key].validation);
            isFormValid = formControl[key].valid && isFormValid
        });
        this.setState({formControl});
        return isFormValid;
    };
    render(){
        return <div>
            <div className="rmod-header">
                <b>Условие</b>
                <a onClick={this.props.close} style={{ marginRight: "60px" }} className="mclose"><i className="flaticon-close" /></a>
                <div className="togglelist"><a onClick={this.sendForm} className="rmod-ok" disabled=""><i className="flaticon-interface-1" /></a></div>
            </div>
            <div className="mod-cont sbform">
                <Input
                    label="Название"
                    value={this.state.formControl.name.value}
                    onChange={e => this.setField("name", e.target.value) }
                    shouldValidate={true}
                    valid={this.state.formControl.name.valid}
                />
                <FilterBpm filter={this.state.condition} entityId={this.props.entityId} entity={this.props.entity} updFilter={filter => this.setState({ condition: filter })} />
            </div>
        </div>
    }
}