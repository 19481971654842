import React, {Component} from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru';
registerLocale('ru-RU', ru);

function isInvalid({valid, shouldValidate, isFormValid}) {
    return !valid && shouldValidate && !isFormValid
}

const DateTime = props => {
    return <div className={props.className} style={props.style}>
        {
            (props.label) ? <label data-tooltip={props.tooltip}>
                {(props.tooltip) ? <div className="squerTooltipe"/> : null} {props.label} {(props.required) ? <span style={{color: "red"}}>*</span> : null}</label> : null
        }
        <DatePicker
            showMonthDropdown={props.showMonthDropdown || true}
            showYearDropdown={props.showYearDropdown || true}
            selected={Date.parse(props.value)}
            onChange={event => props.handleChange(event, props.event)}
            locale="ru-RU"
            dateFormat={props.dateFormat}
            isClearable={props.isClearable}
            showTimeSelect={props.showTimeSelect}
            timeIntervals={props.timeIntervals}
            timeFormat={props.timeFormat}
            className={(props.valid) ? "" : "requiredInput"}
            minDate={props.minDate}
            maxDate={props.maxDate}
            disabled={props.disabled}
            todayButton={props.todayButton ? "Сегодня" : false}
        />
        {
            (props.valid === false) ? <span className="errorMsg">{(props.errorMessage) || "Заполните поле"}</span> : null
        }
    </div>
}

export default DateTime;