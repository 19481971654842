import React, {useEffect, useState} from "react";
import Modal from "../../Modal";
import myaxios from "../../Axios";
import Notif from "../../Notif";
import Myselect from "../../UI/Select";
import Input from "../../UI/Input";
import {validationData} from "../../UI/formValid";
import {BpDateWithPeriod, parseDataForPeriod, saveTimePeriod} from "../BpHelpers";

const validationRules = {
    name: { required: true, maxLength: 255 },
    type: { required: true }
}

export const BpNestedProcess = ({ close, metaData, saveNode }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [isError, setIsError] = useState(false)
    const [accounts, setAccounts] = useState([])
    const [errors, setErrors] = useState({})
    const [values, setValues] = useState({
        name: '',
        type: '',
        time_value: 0,
        time_valuePeriod: 'minutes',
        tch: true
    })


    const getPrepareData = async () => {
        try {
            const { status, data } = await myaxios.get(`/ajax/processbuilder`)
            if(status === 200) {
                const invocable = data.items.filter(el => el.type === 'invocable')
                if (invocable.length === 0) {
                    setIsError(true)
                    setLoading(false)
                    return false;
                }
                setAccounts(invocable)
                if(metaData) {
                    setValues({
                        ...values,
                        time_value: parseDataForPeriod(metaData.time_value).value,
                        time_valuePeriod: parseDataForPeriod(metaData.time_value).period,
                        name: metaData.name,
                        type: metaData.metaData.data.id
                    })
                } else {
                    setValues({ ...values, type: invocable[0].id })
                }
            } else Notif('Error')
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        setIsOpen(true)
        getPrepareData().then()
    }, [])

    const saveData = () => {
        const validErrors = validationData(values, validationRules)
        if (Object.keys(validErrors).length > 0) {
            setErrors(validErrors)
            return false;
        }
        setErrors({})
        setIsOpen(false)

        setTimeout(() => saveNode({
            id: metaData && metaData.id,
            name: values.name,
            nodeType: 'node',
            time_value: saveTimePeriod(values.time_value, values.time_valuePeriod),
            is_scheduled: values.time_value > 0,
            type: "process",
            metaData: {data: { id: values.type }}
        }), 100)
    }

    const closeModal = () => {
        setIsOpen(false); setTimeout(close, 200);
    }

    if (isError) {
        return (
            <Modal showLeaveModal tch={values.tch} isOpen={isOpen} title={'Ошибка'} loading={loading}
                   save={saveData} parentClassName={'bpmModal'} close={closeModal}>
                <div style={{ fontSize: '14px', padding: '5px 0' }}>Нет вложенных процессов</div>
            </Modal>
        )
    }

    return (
        <Modal showLeaveModal tch={values.tch} isOpen={isOpen} title={'Вложенный процесс'} loading={loading}
               save={saveData} parentClassName={'bpmModal'} close={closeModal}>
            <div className="sbform">
                <Input
                    label={'Название'}
                    value={values.name}
                    onChange={e => setValues({...values, name: e.target.value, tch: false })}
                    errorMessage={errors.name} valid={!errors.name} shouldValidate
                />
                <Myselect
                    label={'Процесс'}
                    value={values.type} val={'id'} lab={'name'}
                    options={accounts}
                    onChange={e => setValues({...values, type: e.target.value, tch: false })}
                    errorMessage={errors.type} valid={!errors.type} shouldValidate
                />
                <BpDateWithPeriod
                    label={'Отложенный запуск'}
                    name={'time_value'} periodName={'time_valuePeriod'}
                    values={values} errors={errors}
                    setValues={(type, value) => setValues({...values, [type]: value, tch: false })}
                    tooltip={'Время задержки выполнения процесса'}
                />
            </div>
        </Modal>
    )
}