import React from 'react';
import DatePicker from "react-datepicker";
import StageItem from './stageItem';
import Textarea from '../../../components/UI/Textarea'
import AdditionalFields from '../../../components/additionalFields';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru';
import MainEditor from "../../../components/UI/Editor";

const Information = props => {
    return <div className="tab-content">
    <div className="sbform formlabel">
        <div className="connection" style={{ marginTop: "0px"}}>
            <span>Основная информация</span>
            {/*<div className="toggleparrent">
            <button className="btni">+</button>
                <ul>
                    <li><button>Добавить поле</button></li>
                </ul>
            </div>*/}
        </div>
        <div className="rc4">
            <label>Дата начала</label>
            <DatePicker
                onChange={event => props.handleChange(event, "startDate")}
                selected={Date.parse(props.startDate)}
                locale="ru-RU"
                dateFormat="dd-MMMM-yyyy"
                isClearable={true}
            />
        </div>
        <div className="rc4">
            <label>Дата завершения</label>
            <DatePicker
                onChange={event => props.handleChange(event, "endDate")}
                selected={Date.parse(props.endDate)}
                locale="ru-RU"
                dateFormat="dd-MMMM-yyyy"
                isClearable={true}
            />
        </div>
        <div className="rc4">
            <label>Крайний срок</label>
            <DatePicker
                onChange={event => props.handleChange(event, "deadline")}
                selected={Date.parse(props.deadline)}
                locale="ru-RU"
                dateFormat="dd-MMMM-yyyy"
                isClearable={true}
            />
        </div>
        <div className="clear" />
        <AdditionalFields items={props.additionalFields}  updateAdditionalFields={props.updateAdditionalFields} />



        <div className="connection">
            <span>Этапы проекта</span>
            <div className="toggleparrent">
                <button onClick={()=>props.stagesFunc("open", "stages")} className="btni">+</button>
                {/*<ul>
                    <li><button onClick={()=>props.stagesFunc("open", "stages")}>Добавить этап</button></li>
                </ul>*/}
            </div>
        </div>
        <div className="sp-table body-table">
            <div style={{borderSpacing: "0 5px"}} className="stageDiv stagesTable">
                {
                    props.stages.map((stage, index) =>
                        <StageItem
                            stage={stage}
                            key={index}
                            id={stage.id}
                            index={index}
                            parentId={props.parentId}
                            percentComplete={stage.execution_percent}
                            name={stage.name}
                            type={stage.type}
                            startDate={stage.start_date}
                            endDate={stage.end_date}
                            status={stage.statusLabel}
                            deadline={stage.deadline}
                            stagesFunc={props.stagesFunc}
                            descendants={stage.descendants}
                        />
                    )
                }
            </div>
        </div>


    </div>
        <div className="connection">
            <span>Команда</span>
            <div className="toggleparrent">
                <button onClick={props.openTeam} className="btni">+</button>
            </div>
        </div>
        <div className="clear" />
        <div className="sp-table body-table">
            <table style={{borderSpacing: "0"}} className="stagesTable">
                <thead />
                <tbody>
                {(props.team) ? props.team.map((item,index)=>
                    <tr key={index}>
                        <td>{(item.user) ? <a  onClick={() => props.editTeam(item)}>{item.user.name}</a> : null}</td>
                        <td>{item.role}</td>
                        <td>{(item.description) ? item.description.slice(0,50) : null}</td>
                        <td>
                            <div className="toogle-bottom fl-r">
                                <a className="is"><i className="flaticon-more" /></a>
                                <ul className="toogle-list">
                                    <li><a onClick={() => props.editTeam(item)}><i className="flaticon-edit-1" /> <b>Редактировать</b></a></li>
                                    <li><a onClick={()=> props.deleteTeam(item.user_id, index)} className="table-del"><i className="flaticon-delete-1" /> <b>Удалить</b></a></li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                ) : null
                }
                </tbody>
            </table>
        </div>
        <div className="sbform formlabel mt10">
            <MainEditor
                className="fgroup rc12"
                value={props.description}
                onChange={event => {props.setDescription(event)}}
                label="Комментарий"
                valid={true}
            />
        </div>
    </div>
    };

export default Information;