export const convertFilter = (filters) => {
    let link = ''
    filters.map(item => {
        if(item.operator === 'like' || item.operator === 'nlike'){
            link+= `&filter[or][][${item.field}][${item.operator}]=${item.value}`
        }
        if(item.operator === 'eq' || item.operator === 'neq' || item.operator === '!=' || item.operator === '>' || item.operator === '<' || item.operator === '>=' || item.operator === '<='){
            link+= `&filter[${item.field}][${item.operator}]=${item.value}`
        }
        if(item.operator === 'in' || item.operator === 'nin'){
            link+= `&filter[${item.field}][${item.operator}][]=${item.value}`
        }
    })
    return link;
}