import React, { Component } from 'react';
import myaxios from '../components/Axios';
import Input from '../components/UI/Input';
import Myselect from '../components/UI/Select';
import Textarea from '../components/UI/Textarea';
import Notif from '../components/Notif';
import formValid from '../components/UI/formValid';
import {withRouter} from 'react-router-dom';

class AddKnowledgeBasePost extends Component{
    state = {
        formControl: {
            name: {
                value: '',
                valid: false,
                validation: {
                    required: true,
                    minlength: 3
                }
            },
            category: {
                options:[],
                value: '',
            }
        },
        tch: true,
        isFormValid: true,
    };

    componentDidMount(){
            this.show()
    }
    show = () => {
        myaxios.get(`/ajax/knowledgeBase/category`, {method: 'get',
        }).then((response) => {
            const formControl = this.state.formControl;
            formControl.category.options = response.data.items;
            formControl.category.value = response.data.items ? response.data.items[0].id : null;
            this.setState({ formControl })
        })
    };
    setField = (event, type) => {
        const formControl = {  ...this.state.formControl };
        const control = { ...formControl[type] };
        control.value = event.target.value;
        formControl[type] = control;
        this.setState({
            formControl, tch: false
        })
    };
    sendForm = event => {
        event.preventDefault();
        if(this.isFormValid()){
            this.setState({ loading: true });
            myaxios.post(`/ajax/knowledgeBase/post`, {
                title: this.state.formControl.name.value,
                category_id: Number(this.state.formControl.category.value),
                content:'',
                status: 1
            }).then((response) => {
                if(response.status === 201) {
                    this.props.history.push('/knowledgeBase/' + response.data.id);
                    setTimeout(this.props.close)
                    Notif("save");
                }
            })
        } else {
            Notif("Error")
        }
    };
    isFormValid = () => {
        let formControl = this.state.formControl;
        let isFormValid = true;
        Object.keys(formControl).map((key, index) => {
            formControl[key].valid = formValid(formControl[key].value, formControl[key].validation)
            isFormValid = formControl[key].valid && isFormValid
        });
        this.setState({
            formControl, isFormValid
        });
        return isFormValid;
    };
    render(){
        return (
            <div className="sbform create-activ">
                <div className="rmod-header">
                    <b>Новая статья</b>
                    <a onClick={this.props.close} className="mclose"><i className="flaticon-close" /></a>
                    <div className="togglelist"><button onClick={this.sendForm} className="rmod-ok" disabled={this.state.tch}><i className="flaticon-interface-1" /></button></div>
                </div>
                <div className="mod-cont">
                    <Input
                        label="Название"
                        type="text"
                        className="c12"
                        errorMessage={'Поле не может содержать меньше 3-х символов'}
                        value={this.state.formControl.name.value}
                        onChange={event => this.setField(event, "name")}
                        shouldValidate={true}
                        valid={this.state.formControl.name.valid}
                        isFormValid={this.state.isFormValid}
                    />
                    <Myselect
                         label="Категория"
                         className="c12"
                         options={this.state.formControl.category.options}
                         value={this.state.formControl.category.value}
                         onChange={event => this.setField(event, "category")}
                         val="id"
                         lab="title"
                     />
                </div>
            </div>
        )}}
export default withRouter(AddKnowledgeBasePost);

