import React, {Component} from 'react';
import Input from '../../components/UI/Input';
import  MultiSelectReact  from 'multi-select-react';

/*export default class MultiSelect extends Component {
    state = {
        formControl: {
            select: {
                value: '',
                custom:[
                    {id:0, name:'Первый вариант'},
                    {id:1, name:'Второй вариант'},
                    {id:2, name:'Третий вариант'},
                    {id:3, name:'Четвертый вариант'},],
                options:[],
            }
        }
    }
    updateData = (type, id) => {
        let formControl = this.state.formControl;
        if(type === "add") {
            formControl.select.options[id] = formControl.select.custom[id];
            delete formControl.select.custom[id];
        } else {
            formControl.select.custom[id] = formControl.select.options[id];
            delete formControl.select.options[id];
        }
        this.setState({ formControl })
    }
 render(){
     return <div className="parenMultiSelect sbform">
        <ASDD
            label="Несколько значений"
            custom={this.state.formControl.select.custom}
            options={this.state.formControl.select.options}
            updateData={this.updateData}
            valid={true}
            errorMessage="ыввфыа ффывафываыфв фываыфв"
        />

         <div className="clear" style={{ marginBottom: "100px" }} />
     </div>
 }
}*/

/*function toggleBlock() {
    let display = document.getElementById('parentSelectAbsolute');
    if(display.style.display === "block") {
        display.style.display = "none"
    } else {
        display.style.display = "block";
    }
}
function onBlurFunc() {
    let display = document.getElementById('parentSelectAbsolute');
    display.style.display = "none"
}

const MultiSelect = props => {
    return <div className={`${props.className} parentSelectDiv`}>
        {(props.label) ? <label data-tooltip={props.tooltip}>
            {(props.tooltip) ? <div className="squerTooltipe"/> : null} {props.label} {(props.required) ? <span style={{color: "red"}}>*</span> : null}</label> : null}
        <div onClick={(props.options.length > 0) ? null : toggleBlock} className={`parentSelectRelative ${(props.valid) ? '' : 'requiredInput'}`}>
                {(props.options.length > 0) ? props.options.map((item, index) =>
                    <div className="itemContainerItem">{item.name} <a title="Отвязать" onClick={() => props.updateData("delete",item.id)} className="unlink">x</a></div>
                ) : <span>Выбрать</span>}
            <a onClick={toggleBlock} className="parentSelectDivIcon"><i className="flaticon-list-2" /></a>
        </div>
        {(props.valid) ? '' : <span className="errorMsg">{props.errorMessage}</span>}
        <button className="clearButtonStyle" onBlur={onBlurFunc}>
        <ul id="parentSelectAbsolute" className="parentSelectAbsolute">
            {(props.custom.length > 0) ? props.custom.map((item, index) =>
                    <li key={index} onClick={() => props.updateData("add",item.id)}>
                        <i className="flaticon-add-circular-button" /><b>{(props.labelType) ? item.label : item.name}</b>
                    </li> ) : <li>Пусто</li>
            }
        </ul></button>
    </div>
}*/

const value = [
    {value: "1", label: "hello111"},
    {value: "2", label: "hello222"},
    {value: "3", label: "hello333"},
]
const options = [
    {value: "4", label: "aas213"},
    {value: "5", label: "asf23"},
    {value: "6", label: "ggfgd412"},
]
function sortArr(value, id){
    let a = false;
    value.map(item => {
        if(item == id){
            a = true;
        }
    });
    return a;
}


export default class MultiSelect extends Component {
    state = {
        multiSelect:[],
        bottomBlock:false,
        value:'',
        custom:[]
    };
    componentWillReceiveProps(nextProps){
        if(nextProps.addType !== this.state.custom) {
            this.setState({ multiSelect: this.getParaps(this.props.custom, this.props.options) });
            setTimeout(this.postParams, 100)
        }
    }
    getParaps(value, options) {
        this.setState({ custom: this.props.addType });
        let params = [];
        if(this.props.addType){
            value.map(item =>{
                (sortArr(options, item.id)) ? params.push({id: item.value, label: item.label, value: true, newid: item.id}) : params.push({id: item.value, label: item.label, value: false, newid: item.id});
            })
        }else {
            value.map(item =>{
                (sortArr(options, item.value)) ? params.push({id: item.value, label: item.label, value: true, newid: item.id}) : params.push({id: item.value, label: item.label, value: false, newid: item.id});
            })
        }

        return params;
    }
    postParams = (params) =>{
        let postArr = [];
        let postArr2 = [];
        if(this.state.multiSelect){
            this.state.multiSelect.map(item =>
                (item.value) ? postArr.push(item.id) : null
            )
        }
        if(this.state.multiSelect){
            this.state.multiSelect.map(item =>
                (item.value) ? postArr2.push({id: item.newid, name: item.label}) : null
            )
        }
        setTimeout(() => this.props.updateData(postArr, postArr2, this.props.addType))
    };
    componentDidMount(){
        this.setState({ multiSelect: this.getParaps(this.props.custom, this.props.options) })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.custom !== prevProps.custom) this.setState({ multiSelect: this.getParaps(this.props.custom, this.props.options) })
        if(this.props.options !== prevProps.options) this.setState({ multiSelect: this.getParaps(this.props.custom, this.props.options) })
    }

    render(){
        const selectedOptionsStyles = {
            color: "#fff",
            backgroundColor: "#62a091"
        };
        const optionsListStyles = {
            backgroundColor: "#f5f5f5",
            color: "#2b2b2b"
        };
        return <div className={`${this.props.className} ${this.props.valid ? '' : 'multirequired'}`}>
            {
                (this.props.label) ? <label data-tooltip={this.props.tooltip}>
                    {(this.props.tooltip) ? <div className="squerTooltipe"/> : null} {this.props.label} {(this.props.required) ? <span style={{color: "red"}}>*</span> : null}</label> : null
            }
            <MultiSelectReact
                options={this.state.multiSelect}
                optionClicked={this.optionClicked.bind(this)}
                selectedBadgeClicked={this.selectedBadgeClicked.bind(this)}
                selectedOptionsStyles={selectedOptionsStyles}
                optionsListStyles={optionsListStyles} />
            {
                (isInvalid(this.props)) ? <span className="errorMsg">{(this.props.errorMessage) || "Заполните поле"}</span> : null
            }
        </div>
    }

    optionClicked(optionsList) {
        this.setState({ multiSelect: optionsList });
        this.postParams();
    }
    selectedBadgeClicked(optionsList) {
        this.setState({ multiSelect: optionsList });
        this.postParams();
    }
}
function isInvalid({valid, shouldValidate, isFormValid}) {
    return !valid && shouldValidate && !isFormValid
}