import myaxios from "../../components/Axios";

type Connections = {
    entity: string,
    id: string,
    name: string,
    expand?: string
}

export const getConnectionsService = async ({ entity, id, name, expand = "" }: Connections) =>
    myaxios.get(`/ajax/${entity}/${id}/related?name=${name}&per-page=5&sort=-created_at&expand=${expand}`)