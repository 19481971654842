import React, {Component, useEffect, useState} from 'react';
import AutoSelect from '../UI/AutoSelect';
import Notif from '../Notif';
import myaxios from '../../components/Axios';
import Bgdrop from '../../Modal/Bgdrop';
import AddTask from '../../Modal/AddTask';
import {withRouter} from 'react-router-dom';
import Conversions from './conversions';
import Tasks from './tasks';
import Documents from './documents';
import Propjects from './projects';
import Mails from './mails';
import Invoice from './Invoice';
import Order from './Order';
import Shipment from './Shipment';
import SendMail from '../../Modal/SendMail'
import Opportunity from './Opportunity';
import AddInvoice from '../../Modal/AddInvoice';
import {GlobalState} from '../../Layout/Layout';
import ConvertedLead from './lead';
import OpenMail from '../../Modal/OpenMail';
import SMS from './SMS';
import SendSms from '../../Modal/SendSms';
import Expense from './expense'
import Payments from './payments';
import Cases from './cases';
import WrapModal from '../../Modal/WrapModal';
import PaymentsModal from '../../Modal/Payments';
import AddExpense from "../../Modal/AddExperense";
import {CSSTransition} from "react-transition-group";
import ProviderReturn from "./providerReturn";
import Purchase from "./purchases";
import WorkOrder from "./workorders";
import {ModalListContainer} from "../NewList/components/ModalListContainer";

const API = '/ajax/'

class Related extends Component{
    state = {
        related: {
            tasks: [],
            mails: [],
            projects: [],
            conversions: [],
            completedTasks: [],
            invoices: [],
            orders: [],
            shipments: [],
            opportunities:[],
            convertedLead:null,
            sms:[]
        },
        tasks: {
            items: [],
            totalCount:'',
            perPage:5,
            pagination: true
        },
        mails: {
            items: [],
            totalCount:'',
            perPage:5,
            pagination: true
        },
        payments: {
            items: [],
            totalCount:'',
            perPage:5,
            pagination: true
        },
        cases: {
            items: [],
            totalCount:'',
            perPage:5,
            pagination: true
        },
        shipments: {
            items: [],
            totalCount:'',
            perPage:5,
            pagination: true
        },
        invoices: {
            items: [],
            totalCount:'',
            perPage:5,
            pagination: true
        },
        documents: {
            items: [],
            totalCount:'',
            perPage:5,
            pagination: true
        },
        sms: {
            items: [],
            totalCount:'',
            perPage:5,
            pagination: true
        },
        opportunities: {
            items: [],
            totalCount:'',
            perPage:5,
            pagination: true
        },
        orders: {
            items: [],
            totalCount:'',
            perPage:5,
            pagination: true
        },
        projects: {
            items: [],
            totalCount:'',
            perPage:5,
            pagination: true
        },
        convertedLead: {
            items: [],
            totalCount:'',
            perPage:5,
            pagination: true
        },
        expense: {
            items: [],
            totalCount:'',
            perPage:5,
            pagination: true
        },
        purchases: {
            items: [],
            totalCount:'',
            perPage:5,
            pagination: true
        },
        workorders: {
            items: [],
            totalCount:'',
            perPage:5,
            pagination: true
        },
        providerReturn: {
            items: [],
            totalCount:'',
            perPage:5,
            pagination: true
        },
        open: '',
        openType: '',
        openId: '',
        phone:null,
        loading: true
    };

    setPerPage = (type, func) => {
        const arr = this.state[type];
        arr.perPage += 5;
        this.setState({ [type]: arr }, func)
    };

    showRelated = (type, data) => {
        const arr = this.state[type];
        arr.items = data.items || [];
        if(arr.pagination && data._meta) arr.totalCount = data._meta.totalCount
        this.setState({ [type]: arr })
    };

    /*showRelated = () => {
        myaxios.get(`/ajax/${this.props.type}/${this.props.id}/related`).then((response) => {
            this.setState({ related: response.data })
        })
    };*/



    showTasks = async () => await myaxios.get(`${API}${this.props.type}/${this.props.id}/related?name=tasks&per-page=${this.state.tasks.perPage}&sort=-created_at`).then(res =>this.showRelated("tasks", res.data));

    showMails = async () => await myaxios.get(`${API}${this.props.type}/${this.props.id}/related?name=mails&per-page=${this.state.mails.perPage}&sort=-created_at`).then(res =>this.showRelated("mails", res.data));

    //showPayments = async () => await myaxios.get(`${API}${this.props.type}/${this.props.id}/related?name=payments&per-page=${this.state.payments.perPage}&sort=-created_at`).then(res =>this.showRelated("payments", res.data));

    showCases = async () => await myaxios.get(`${API}${this.props.type}/${this.props.id}/related?name=cases&per-page=${this.state.cases.perPage}&sort=-created_at`).then(res =>this.showRelated("cases", res.data));

    showShipments = async () => await myaxios.get(`${API}${this.props.type}/${this.props.id}/related?name=shipments&per-page=${this.state.shipments.perPage}&sort=-created_at`).then(res =>this.showRelated("shipments", res.data));

    showInvoices = async () => await myaxios.get(`${API}${this.props.type}/${this.props.id}/related?name=invoices&per-page=${this.state.invoices.perPage}&sort=-created_at&expand=currency`).then(res =>this.showRelated("invoices", res.data));

    showDocuments = async () => await myaxios.get(`${API}${this.props.type}/${this.props.id}/related?name=documents&per-page=${this.state.documents.perPage}&sort=-created_at`).then(res =>this.showRelated("documents", res.data));

    showSms = async () => await myaxios.get(`${API}${this.props.type}/${this.props.id}/related?name=sms&per-page=${this.state.sms.perPage}&sort=-created_at`).then(res =>this.showRelated("sms", res.data));

    showOpportunities = async () => await myaxios.get(`${API}${this.props.type}/${this.props.id}/related?name=opportunities&per-page=${this.state.opportunities.perPage}&sort=-created_at`).then(res =>this.showRelated("opportunities", res.data));

    showOrders = async () => await myaxios.get(`${API}${this.props.type}/${this.props.id}/related?name=orders&per-page=${this.state.orders.perPage}&sort=-created_at`).then(res =>this.showRelated("orders", res.data));

    showProjects = async () => await myaxios.get(`${API}${this.props.type}/${this.props.id}/related?name=projects&per-page=${this.state.projects.perPage}&sort=-created_at`).then(res =>this.showRelated("projects", res.data));

    showLead = async () => await myaxios.get(`${API}${this.props.type}/${this.props.id}/related?name=convertedLead&per-page=${this.state.convertedLead.perPage}&sort=-created_at`).then(res =>this.showRelated("convertedLead", res.data));

    showExpense = async () => await myaxios.get(`${API}${this.props.type}/${this.props.id}/related?name=expenses&per-page=${this.state.expense.perPage}&sort=-created_at&expand=currency,category`).then(res =>this.showRelated("expense", res.data));

    showProviderReturn = async () => await myaxios.get(`${API}${this.props.type}/${this.props.id}/related?name=providerReturns&per-page=${this.state.providerReturn.perPage}&sort=-created_at&expand=stock`).then(res =>this.showRelated("providerReturn", res.data));

    showPurchases = async () => await myaxios.get(`${API}${this.props.type}/${this.props.id}/related?name=purchases&per-page=${this.state.tasks.perPage}&sort=-created_at`).then(res =>this.showRelated("purchases", res.data));

    showWorkOrders = async () => await myaxios.get(`${API}${this.props.type}/${this.props.id}/related?name=workorders&per-page=${this.state.tasks.perPage}&sort=-created_at`).then(res =>this.showRelated("workorders", res.data));




    componentDidMount(){
        if(this.props.tasks) this.showTasks();
        if(this.props.mails) this.showMails();
        //if(this.props.payments) this.showPayments();
        if(this.props.cases) this.showCases();
        if(this.props.shipments) this.showShipments();
        if(this.props.invoices) this.showInvoices();
        if(this.props.documents) this.showDocuments();
        if(this.props.sms) this.showSms();
        if(this.props.opportunities) this.showOpportunities();
        if(this.props.orders) this.showOrders();
        if(this.props.projects) this.showProjects();
        if(this.props.convertedLead) this.showLead();
        if(this.props.expense) this.showExpense()
        if(this.props.providerReturn) this.showProviderReturn()
        if(this.props.purchases) this.showPurchases()
        if(this.props.workorders) this.showWorkOrders()
        //this.showRelated();
        this.setState({ loading: false })

        let phone = null;
        if(this.props.account){
            if(this.props.account.phone) {
                if(this.props.account.phone.length > 4) phone = this.props.account.phone
            }
        } else if(this.props.contact){
            if(this.props.contact.mobile_phone){
                if(this.props.contact.mobile_phone.length > 4) phone = this.props.contact.mobile_phone
            } else if(this.props.contact.work_phone){
                if(this.props.contact.work_phone.length > 4) phone = this.props.contact.work_phone
            }
        }
        this.setState({ phone: phone })
    }
    openModal = (type, id) => {
        this.setState({
            open: 'r-open',
            openType: type,
            openId: id,
            /*sms: false,
            phone: false,
            email: false*/
        })
    }
    closeModal = () => {
        this.setState({open:'', openType:'',openId: null})
    }
    saveModal = () => {
        this.closeModal();
    }

    completeShipment = (name, id) => {
        myaxios.put(`/ajax/stock/shipment/${id}`, {
            is_completed: 1,
            name: name,
        }).then(res => {
            if(res.status === 200) {
                this.showShipments()
            }
        })
    }

    refreshEntity = (type) => {
        if(type === "task") this.showTasks();
        else if(type === "mail") this.showMails();
        else if(type === "payment") this.showPayments();
        else if(type === "case") this.showCases();
        else if(type === "stock/shipment") this.showShipments();
        else if(type === "invoice") this.showInvoices();
        else if(type === "document") this.showDocuments();
        else if(type === "sms") this.showSms();
        else if(type === "opportunity") this.showOpportunities();
        else if(type === "order") this.showOrders();
        else if(type === "project") this.showProjects();
        else if(type === "lead") this.showLead();
        else if(type === "expense") this.showExpense();
        else if(type === "providerReturn") this.showProviderReturn();
        else if(type === "stock/purchases") this.showPurchases();
        else if(type === "workorder") this.showWorkOrders();
    }

    deleteObject = (type, id) => {
        if(window.confirm("Удалить?")) {
            myaxios.delete(`/ajax/${type}/${id}`).then(responce => {
                if(responce.status === 204 || responce.status === 200 || responce.status === 201) {
                    this.refreshEntity(type)
                    Notif("delete", "Удаление успешно")
                }
            })
        }
    };
    render(){
        return <CSSTransition in={!this.state.loading} timeout={200} classNames="tabs" unmountOnExit><div className="tab-content">
            {/*Задачи*/}
            {
                (this.props.tasks) ? <div>
                    <div className="connection" style={{ marginTop: "0px"}}><span>Задачи</span></div>
                    <Tasks openModal={this.openModal} item={this.state.tasks.items} />
                    {this.state.tasks.items.length < this.state.tasks.totalCount ? <button onClick={() => this.setPerPage("tasks", this.showTasks)} className="related-read-more">Показать еще...</button> : null}
                </div> : null
            }

            {/*Этапы*/}
            {/*{
            (this.state.related.conversions) ? <div>
                <div className="connection">
                    <span>Этапы</span>
                </div>
                <Conversions items={this.state.related.conversions} />
            </div> : null
        }*/}

            {
                (this.state.related.convertedLead) ? <div>
                    <ConvertedLead lead={this.state.convertedLead.items ? this.state.convertedLead.items[0] : null} />
                </div> : null
            }

            {/*Платежи*/}
            {/*{
            (this.props.payments) ? <div>
                <div className="connection"><span>Платежи</span>
                    <div className="toggleparrent"><button onClick={()=>this.openModal("payment", null)} className="btni">+</button></div>
                </div>
                <GlobalState.Consumer>
                    {context => (
                        <Payments baseCurrency={context.state.baseCurrency} openModal={this.openModal} item={this.state.payments.items} deleteObject={this.deleteObject} />
                    )}
                    {this.state.payments.items.length < this.state.payments.totalCount ? <button onClick={() => this.setPerPage("payments", this.showPayments)} className="related-read-more">Показать еще...</button> : null}
                </GlobalState.Consumer>
            </div> : null
        }*/}
            {/*Платежи*/}

            {/*Поддержка*/}
            {
                (this.props.cases) ? <div>
                    <div className="connection"><span>Обращения в поддержку</span>
                        <div className="toggleparrent"><button onClick={()=>this.setState({ openType: "case" })} className="btni">+</button></div>
                    </div>
                    <Cases openModal={this.openModal} item={this.state.cases.items} deleteObject={this.deleteObject} />
                    {this.state.cases.items.length < this.state.cases.totalCount ? <button onClick={() => this.setPerPage("cases", this.showCases)} className="related-read-more">Показать еще...</button> : null}
                </div> : null
            }
            {/*Поддержка*/}

            {/*Отгрузка*/}
            {
                (this.props.shipments) ? <div>
                    <div className="connection"><span>Отгрузки</span>
                        <div className="toggleparrent" />
                    </div>
                    <Shipment item={this.state.shipments.items} deleteObject={this.deleteObject} completeShipment={this.completeShipment} />
                    {this.state.shipments.items.length < this.state.shipments.totalCount ? <button onClick={() => this.setPerPage("shipments", this.showShipments)} className="related-read-more">Показать еще...</button> : null}
                </div> : null
            }
            {/*Отгрузка*/}


            {/*Счет*/}
            {
                (this.props.invoices) ? <div className="invoice-col">
                    <div className="connection"><span>Счета</span>
                        <div className="toggleparrent"><button onClick={()=> this.setState({ openType: 'invoice' })} className="btni">+</button></div>
                    </div>
                    <Invoice openModal={() => {}} item={this.state.invoices.items} deleteObject={this.deleteObject} />
                    {this.state.invoices.items.length < this.state.invoices.totalCount ? <button onClick={() => this.setPerPage("invoices", this.showInvoices)} className="related-read-more">Показать еще...</button> : null}

                </div> : null
            }
            {/*Счет*/}

            {/*Документы*/}
            {
                (this.props.documents) ? <div>
                    <div className="connection"><span>Документы</span>
                        <div className="toggleparrent"><button onClick={()=> this.setState({ openType: "document" })} className="btni">+</button></div>
                    </div>
                    <Documents openModal={this.openModal} item={this.state.documents.items} deleteObject={this.deleteObject} />
                    {this.state.documents.items.length < this.state.documents.totalCount ? <button onClick={() => this.setPerPage("documents", this.showDocuments)} className="related-read-more">Показать еще...</button> : null}
                </div> : null
            }
            {/*Документы*/}


            {/*Сделка*/}
            {
                (this.props.opportunities) ? <div>
                    <div className="connection"><span>Сделки</span>
                        <div className="toggleparrent"><button onClick={()=>this.setState({openType: "opportunity" })} className="btni">+</button></div>
                    </div>
                    <Opportunity openModal={this.openModal} item={this.state.opportunities.items} deleteObject={this.deleteObject} />
                    {this.state.opportunities.items.length < this.state.opportunities.totalCount ? <button onClick={() => this.setPerPage("opportunities", this.showOpportunities)} className="related-read-more">Показать еще...</button> : null}
                </div> : null
            }
            {/*Сделка*/}

            {/*Заказ*/}
            {
                (this.props.orders) ? <div>
                    <div className="connection"><span>Заказы</span>
                        <div className="toggleparrent"><button onClick={()=>this.setState({openType: "order"})} className="btni">+</button></div>
                    </div>
                    <Order openModal={this.openModal} item={this.state.orders.items} deleteObject={this.deleteObject} />
                    {this.state.orders.items.length < this.state.orders.totalCount ? <button onClick={() => this.setPerPage("orders", this.showOrders)} className="related-read-more">Показать еще...</button> : null}
                </div> : null
            }
            {/*Заказ*/}

            {/*Проекты*/}
            {
                (this.props.projects) ? <div>
                    <div className="connection">
                        <span>Проекты</span>
                        <div className="toggleparrent">
                            <button onClick={()=>this.openModal("project", null)} className="btni">+</button>
                        </div>
                    </div>
                    <Propjects openModal={this.openModal} item={this.state.projects.items} deleteObject={this.deleteObject} />
                    {this.state.projects.items.length < this.state.projects.totalCount ? <button onClick={() => this.setPerPage("projects", this.showProjects)} className="related-read-more">Показать еще...</button> : null}
                </div> : null
            }


            {/*Расходы*/}
            {/*{
                (this.props.expense) ? <div>
                    <div className="connection">
                        <span>Расходы</span>
                        <div className="toggleparrent">
                            <button onClick={()=>this.openModal("expense", null)} className="btni">+</button>
                        </div>
                    </div>
                    <Expense openModal={this.openModal} item={this.state.expense.items} deleteObject={this.deleteObject} />
                    {this.state.expense.items.length < this.state.expense.totalCount ? <button onClick={() => this.setPerPage("expense", this.showProjects)} className="related-read-more">Показать еще...</button> : null}
                </div> : null
            }*/}


            {/*Возвраты*/}
            {
                (this.props.providerReturn) && <div>
                    <div className="connection">
                        <span>Возвраты</span>
                        {/*<div className="toggleparrent">
                            <button onClick={()=>this.openModal("expense", null)} className="btni">+</button>
                        </div>*/}
                    </div>
                    <ProviderReturn openModal={this.openModal} item={this.state.providerReturn.items} deleteObject={this.deleteObject} />
                    {this.state.providerReturn.items.length < this.state.providerReturn.totalCount ? <button onClick={() => this.setPerPage("providerReturn", this.showProjects)} className="related-read-more">Показать еще...</button> : null}
                </div>
            }

            {/*Закупки*/}
            {
                (this.props.purchases) && <div>
                    <div className="connection">
                        <span>Закупки</span>
                        {/*<div className="toggleparrent">
                            <button onClick={()=>this.openModal("expense", null)} className="btni">+</button>
                        </div>*/}
                    </div>
                    <Purchase openModal={this.openModal} item={this.state.purchases.items} deleteObject={this.deleteObject} />
                    {this.state.purchases.items.length < this.state.purchases.totalCount ? <button onClick={() => this.setPerPage("purchases", this.showProjects)} className="related-read-more">Показать еще...</button> : null}
                </div>
            }

            {/*Закупки*/}
            {/*{
                (this.props.workorders) && <div>
                    <div className="connection">
                        <span>Заказ на производство</span>
                    </div>
                    <WorkOrder openModal={this.openModal} item={this.state.workorders.items} deleteObject={this.deleteObject} />
                    {this.state.workorders.items.length < this.state.workorders.totalCount ? <button onClick={() => this.setPerPage("workorders", this.showProjects)} className="related-read-more">Показать еще...</button> : null}
                </div>
            }*/}



            {/*SMS*/}

            {
                <div>
                    <div className="connection"><span>SMS</span>
                        <div className="toggleparrent"><button onClick={()=>this.openModal("SMS", null)}  className="btni">+</button></div>
                    </div>
                    <SMS openModal={this.openModal} item={this.state.sms.items} />
                    {this.state.sms.items.length < this.state.sms.totalCount ? <button onClick={() => this.setPerPage("sms", this.showSms)} className="related-read-more">Показать еще...</button> : null}
                </div>
            }
            {/*SMS*/}

            {/*Письма*/}
            {
                (this.props.mails) ? <div>
                    <div className="connection"><span>Email</span>
                        <div className="toggleparrent"><button onClick={()=>this.openModal("email", null)} className="btni">+</button></div>
                    </div>
                    <Mails openModal={this.openModal} item={this.state.mails.items} op={(id, folder, mail_account_id)=>this.openModal("openMail", {id, folder, mail_account_id} )} />
                    {this.state.mails.items.length < this.state.mails.totalCount ? <button onClick={() => this.setPerPage("mails", this.showMails)} className="related-read-more">Показать еще...</button> : null}
                </div> : null
            }
            {/*Письма*/}

            <ModalListContainer
                onUpdateResult={() => {
                    this.refreshEntity(this.state.openType);
                }}
                type={this.state.openType}
                data={{
                    id: this.props.id,
                    related: this.props.related,
                    contact: this.props.contact,
                    account: this.props.account,
                    main_contact_id: this.props.main_contact_id,
                    contact_id: this.props.contact_id,
                    account_id: this.props.account_id,
                    opportunity_id: this.props.opportunity_id,
                    project_id: this.props.project_id,
                    order_id: this.props.order_id,
                }}
                close={() => this.setState({ openType: '' })}
            />
            <React.Fragment>
                <div className={"right-modal scrollbar " + this.state.open}><div>
                    {(this.state.open === "r-open" && this.state.openType === "project")
                        ? <WrapModal
                            close={() => {this.setState({ open:null, openType:null });  this.saveModal()}}
                            saveProduct={this.showProjects}
                            propsData={[
                                {name: 'contact_id', to: 'value', value: this.props.contact_id || ''},
                                {name: 'account_id', to: 'value', value: this.props.account_id || ''},
                                {name: 'opportunity_id', to: 'value', value: this.props.opportunity_id || ''},
                            ]}
                            name="AddProject"
                        /> : null}
                    {(this.state.open === "r-open" && this.state.openType === "email")
                        ? <GlobalState.Consumer>
                            {context => (
                                <SendMail
                                    freeparams={`${this.props.type}/${this.props.id}`}
                                    clientEmail={this.props.accountEmail || this.props.contactEmail || null}
                                    close={() => {this.setState({ open:null, openType:null });  this.showMails()}  }
                                    id={this.state.openId}
                                    email={context.state.email}
                                    relatedId={this.props.related}
                                    isPlates={this.props.isPlates}
                                />
                            )}
                        </GlobalState.Consumer> : null}
                    {(this.state.open === "r-open" && this.state.openType === "SMS")
                        ? <GlobalState.Consumer>
                            {context => (
                                <SendSms
                                    id={{entity: this.props.type, id: Number(this.props.match.params.id), tel: this.state.phone }}
                                    sms={context.state.sms}
                                    close={() => {this.setState({ open:null, openType:null });  this.showSms()}  }
                                />
                            )}
                        </GlobalState.Consumer> : null}
                    {(this.state.open === "r-open" && this.state.openType === "openMail")
                        ? <OpenMail
                            id={this.state.openId}
                            close={() => {this.setState({ open:null, openType:null })}  }
                        /> : null}
                </div></div>
                {this.state.open ? <Bgdrop close={this.closeModal} /> : null}
            </React.Fragment>
        </div></CSSTransition>
    }}

export default withRouter(Related);
