import React, {useEffect, useRef, useState} from "react";
import {
    createTaskService, deleteTaskService,
    getSingleTaskService,
    getTaskPrepareService,
    updateTaskService
} from "../../services/task/taskService";
import Notif from "../../components/Notif";
import {Viewers} from "../../components/other/Watchers";
import Input from "../../components/UI/Input";
import MainEditor from "../../components/UI/Editor";
import EntityComments from "../../components/other/EntityComments/EntityComments";
import DateTime from "../../components/UI/DateTime";
import {FullModal} from "../../components/Modal/FullModal";
import {SubTaskItem} from "./TaskComponents/SubTaskItem";
import {StatusDropDown} from "./TaskComponents/StatusDropDown";
import Files from "../../components/files/files";
import {filesFunc} from "../../components/fieldGen";
import {validationData} from "../../components/UI/formValid";
import Switch from "../../components/UI/Switch";
import {ATTACHED_NUMBER_TO_STRING} from "../../const";
import {withRouter} from "react-router-dom";
import {TagsNew} from "../../components/cardViewComponents/TagsNew";
import {FilesDropDown} from "../../components/FilesDropDown";
import {createEvent} from "../../services/other/events";

const initialData = {
    comment: '',
    description: '',
    subject: '',
    start_date: null,
    end_date: null,
    remind_at: null,
    status_id: 3,
    owner_id: null,
    type_id: '3',
    priority: '1',
    connected: null,
    files: [],
    users: [],
    editorTags: '',
    subtask_json: [],
    is_completed: 0,
    is_private: false,
    tch: true
}

export const updateEntityTasks = (tasks = [], type, data, index) => {
    if(type === 'add') {
        return [...tasks, data]
    } else if(type === 'edit') {
        const newData = tasks;
        tasks[index] = data
        return newData;
    } else if(type === 'delete') {
        return tasks.filter(el => String(el.id) !== String(data))
    }
}

const TaskDatePicker = ({ values, setValues, minDate, maxDate, name, label }) => {
    return (
        <div className="full-modal-right-status">
            <span style={{ whiteSpace: 'nowrap' }}>{label}: </span>
            <div className={'full-modal-right-date'}>
                <i className="flaticon-calendar-3" />
                {!values[name] && <button
                    className={'full-modal-right-date-button'}
                    onClick={() => setValues(new Date())}>
                    Задать
                </button>}
                {values[name] && <DateTime
                    value={values[name]}
                    dateFormat={'dd:MM:yyyy - HH:mm'}
                    timeIntervals={'10'}
                    handleChange={setValues}
                    showTimeSelect
                    isClearable={true}
                    minDate={minDate}
                    maxDate={maxDate}
                />}
            </div>
        </div>
    )
}

export const TaskModalComponent = ({ id, close, index, task, save, isOpen, history, related, taskView }) => {
    const [statuses, setStatuses] = useState([])
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState({})
    const [values, setValues] = useState(JSON.parse(JSON.stringify(initialData)))
    const [subtaskValue, setSubtaskValue] = useState('')
    const [watchers, setWatchers] = useState(null)
    const [nameTouch, setNameTouch] = useState(false)
    const [showFiles, setShowFiles] = useState(false)

    const getPrepare = async () => {
        try {
            const { data: dataPrepare, status: prepareStatus } = await getTaskPrepareService()
            if(prepareStatus !== 200 || !dataPrepare.users) {
                Notif('error')
                return
            }
            if(id) {
                await getData(dataPrepare)
            } else {
                setValues(prevState => ({
                    ...JSON.parse(JSON.stringify(initialData)),
                    users: dataPrepare.users || [],
                }))
                setStatuses(dataPrepare.statuses || [])
            }
        } catch (e) { console.error(e) }

        setLoading(false)
    }

    const getData = async (dataPrepare) => {
        try {
            const { data, status } = await getSingleTaskService({ id, expand: '?expand=connected,owners,files,tags' })
            if(status === 200 && data.id) {
                const { comment, description, subject, start_date, end_date, remind_at, status_id, owner_id, type_id,
                    priority, subtask_json, files, editorTags, connected, is_completed } = data;

                setStatuses(dataPrepare.statuses || [])
                setValues(prevState => ({
                    ...prevState, comment, description, subject, start_date, end_date, remind_at, status_id, editorTags,
                    owner_id, type_id, priority, connected, subtask_json: (JSON.parse(subtask_json) || []),
                    users: dataPrepare.users || [],
                    files: files || [],
                    is_completed: +is_completed === 1,
                    is_private: data.is_private === 1,
                    tch: true
                }))

                setWatchers(data.watchers)
            }
        } catch (e) {

        }
        setLoading(false)
    }

    const saveTask = async () => {
        if(values.tch) return;

        const validErrors = validationData(values, {
            subject: { required: true, maxLength: 100 },
            description: { maxLength: 50000 }
        })
        if(validErrors.subject) {
            validErrors.name = validErrors.subject
            delete validErrors.subject
            setErrors(validErrors)
        } else {
            setErrors(validErrors)
        }

        if(Object.keys(validErrors).length > 0) return false

        const { comment, description, subject, start_date, end_date, remind_at, status_id, owner_id, type_id, is_private,
            priority, related_id, related_type, subtask_json, editorTags } = values;

        const sendData = {
            comment, description, subject, status_id, owner_id, type_id, is_private: +is_private,
            priority, subtask_json: JSON.stringify(subtask_json), editorTags, owners: [],
            start_date: start_date && start_date.toLocaleString(),
            end_date: end_date && end_date.toLocaleString(),
            remind_at: remind_at && remind_at.toLocaleString(),
            related_id: related ? related.id : related_id,
            related_type: related ? related.type : related_type
        }

        setLoading(true)

        try {
            const { status, data } = id ? await updateTaskService({ id, data: sendData }) : await createTaskService({ data: sendData })
            if((status === 200 || status === 201) && data.id) {
                Notif('save', `Задача успешно ${id ? 'обновлена' : 'добавлена'}`)

                if(save) {
                    save()
                    setTimeout(close, 200)
                }

                if(!taskView) close()

                if(task && id) {
                    task("edit", data, index)
                } else {
                    task("add", data, index)
                }

                createEvent({ user_id: window.location.host, user_name: "---", event_type: "createTask" }).then()
            }
        } catch (e) { console.error() }

        setValues({ ...values, tch: true })
        setLoading(false)

    }

    useEffect(() => {
        if(isOpen) {
            getPrepare().then()
        }
    }, [id, isOpen])

    const deleteTask = async () => {
        if(!id) return;
        if(!window.confirm('Удалить задачу?')) return;

        setLoading(true)
        try {
            const { status, data } = await deleteTaskService({ id: id })
            if(status === 200 && data.id) {
                task('delete', id)
                close()
            }
        } catch (e) { console.error(e) }
        setLoading(false)
    }

    const [activeTab, setActiveTab] = useState('comments')
    return (
        <FullModal showLeaveModal tch={values.tch} isOpen={isOpen} close={close} loading={loading} headerRightRender={
            <Viewers
                { ...watchers }
                setWatchers={setWatchers}
                entity={'task'}
                id={id}
            />
        }
        headerLeftRender={<>
            <div className={'task-tags-view'}>
                {/*<button onClick={() => save()}>save</button>*/}
                <TagsNew
                    editorTags={values.editorTags} entity={'task'}
                    setValues={data => setValues({ ...values, editorTags: data, tch: false })}
                />
            </div>
            <div className="modal-switch">
                <em>{values.is_private ? 'Private' : 'Public'}</em>
                <Switch onClick={() => setValues({ ...values, is_private: !values.is_private })} active={!values.is_private} />
            </div>
        </>}
        >
            <div className="full-modal-left sbform">
                <div className="full-modal-left-scrolled-block scrollbar" style={{ paddingRight: '10px' }}>
                    {(!nameTouch && id) ? <h2 onClick={() => setNameTouch(true)} style={{ color: '#3b4146' }}>{values.subject}</h2> : <Input
                        placeholder={'Название задачи'}
                        value={values.subject}
                        onChange={e => setValues({ ...values, subject: e.target.value, tch: false })} shouldValidate
                        valid={!errors.name}
                        errorMessage={errors.name}
                        onKeyDown={e => e.keyCode === 13 && saveTask()}
                    />}
                    <MainEditor
                        toolbar={'bold italic underline strikethrough link | fontsizeselect |  numlist bullist forecolor backcolor removeformat | codesample preview'}
                        menubar={'a'}
                        value={values.description}
                        height={250}
                        valid={!errors.description}
                        errorMessage={errors.description}
                        onChange={description => {
                            if(description !== values.description) {
                                setValues({ ...values, description, tch: false })
                            }
                        }}
                    />

                    <div className="new-task-subtask">
                        <h4>Список подзадач</h4>
                        {
                            values.subtask_json.map((item, index) => (
                                <SubTaskItem
                                    index={index} {...item}
                                    onClick={(e) => {
                                        if(e.ctrlKey) {
                                            setValues(prevState => ({ ...prevState, subtask_json: prevState.subtask_json.filter((el, i) => i !== index), tch: false }))
                                        } else {
                                            const items = values.subtask_json;
                                            items[index].status = !item.status;
                                            setValues({ ...values, subtask_json: items, tch: false })
                                        }

                                    }}
                                />
                            ))
                        }
                        <Input
                            value={subtaskValue}
                            placeholder={'Введите текст подзадачи и нажмите на ENTER'}
                            onChange={(e) => setSubtaskValue(e.target.value)}
                            onKeyDown={e => {
                                if(e.keyCode === 13 && subtaskValue.trim().length > 0) {
                                    const items = values.subtask_json;
                                    items.push({ status: false, text: subtaskValue })
                                    setValues(prevState => ({ ...prevState, subtask_json: items, tch: false }))
                                    setSubtaskValue('')
                                }
                            }}
                        />
                    </div>

                    <div style={{ marginTop: '20px' }} className="full-modal-right-hr" />
                    {id && <div className="comment-tabs">
                        <a onClick={() => setActiveTab('comments')} className={activeTab === 'comments' && 'comment-tab-active'}>Комментарии</a>
                        <a onClick={() => setActiveTab('files')} className={activeTab === 'files' && 'comment-tab-active'}>Файлы ({values.files.length})</a>
                    </div>}

                    {id && activeTab === 'comments' && <div className="task-entity-comments">
                        <EntityComments entity="task" id={id} />
                    </div>}
                    {id && activeTab === 'files' && <div className="task-entity-comments">
                        <FilesDropDown
                            related_id={id}
                            related_type={"5"}
                            files={values.files}
                            updateFile={filesData => setValues(prevState => ({
                                ...prevState,
                                files: [filesData, ...prevState.files]
                            }))}
                            updateFiles={files => setValues(prevState => ({ ...prevState, files }))}
                        />
                    </div>}
                </div>

                <div className="full-modal-right-attach-buttons">
                    {!values.remind_at && <div onClick={() => setValues({...values, remind_at: new Date(), tch: false })}>
                        <i className="flaticon-stopwatch" />
                        <span>Напоминание</span>
                    </div>}
                    {!values.start_date && <div onClick={() => setValues({...values, start_date: new Date(), tch: false })}>
                        <i className="flaticon-calendar-3" />
                        <span>Дата начала</span>
                    </div>}
                    {/*{id && <div onClick={() => setShowFiles(!showFiles)}>
                        <i className="flaticon-attachment" />
                        <span>Вложение</span>
                    </div>}*/}
                    {values.connected && <div onClick={() => history.push('/' + ATTACHED_NUMBER_TO_STRING[String(values.connected.related_type)] + '/' + values.connected.related_id)}>
                        <i className="flaticon-user-ok" />
                        <span>{values.connected.related_name}</span>
                    </div>}
                </div>

                <div className="new-task-files">
                    {showFiles && <Files files={[]} filesFunc={(type, index, arr) => console.log(filesFunc(type, index, arr, []))} relatedType="5" related_id={id} />}
                </div>
            </div>
            <div className="full-modal-right">
                <div>
                    <StatusDropDown
                        items={statuses.map(item => ({ value: +item.id, label: item.value }))}
                        active={values.status_id}
                        onChange={e => setValues({ ...values, status_id: e, tch: false })}
                        disabled={values.is_completed}
                    />
                    <div className="full-modal-right-hr" />

                    <div className="full-modal-right-priority">
                        <div className={'full-modal-right-status'}>
                            <span>Приоритет: </span>
                            <StatusDropDown
                                items={[
                                    {color: 'rgb(177 177 177)', value: '1', label: 'Низкий'},
                                    {color: 'rgb(230 213 59)', value: '2', label: 'Средний'},
                                    {color: 'rgb(239 154 26)', value: '3', label: 'Высокий'},
                                    {color: '#c16a6a', value: '4', label: 'Критический'},
                                ]}
                                active={values.priority || 1}
                                onChange={e => setValues({ ...values, priority: e, tch: false })}
                            />
                        </div>
                    </div>

                    {values.start_date &&
                        <TaskDatePicker
                            name={'start_date'}
                            label={'Дата начала'}
                            values={values}
                            setValues={start_date => {
                                setValues({
                                    ...values,
                                    start_date,
                                    tch: false
                                })
                            }}
                            minDate={new Date()}
                        />
                    }
                    {
                        <TaskDatePicker
                            name={'end_date'}
                            label={'Дедлайн'}
                            values={values}
                            setValues={end_date => {
                                setValues({ ...values, end_date, tch: false })
                            }}
                            minDate={values.start_date || new Date()}
                        />
                    }
                    {values.remind_at && (
                        <TaskDatePicker
                            name={'remind_at'}
                            label={'Напомнить'}
                            values={values}
                            setValues={remind_at => setValues({ ...values, remind_at, tch: false })}
                            minDate={new Date()}
                        />
                    )}
                    <div className={'full-modal-right-status'}>
                        <span>Исполнитель: </span>
                        <StatusDropDown
                            items={values.users.map(({ id, name }) => ({ value: id, label: name }))}
                            active={values.owner_id}
                            onChange={e => setValues({ ...values, owner_id: e, tch: false })}
                            CurrentRender={data => <>
                                {
                                    data ? <div className={'user-dropdown-current-single'}>
                                        <div className={'user-dropdown-current'}>{data.label && data.label.substr(0, 2)}</div>
                                        <em>{data.label}</em>
                                    </div> : <div className={'user-dropdown-current '}>
                                        <div style={{ fontSize: '27px' }}>+</div>
                                    </div>
                                }
                            </>}
                            ItemRender={(data) => {
                                return <div className={'new-task-status-item'}
                                            style={{ padding: '5px 8px', display: 'flex', alignItems: 'center'
                                            }}>
                                    <div className={'user-dropdown-current-min user-dropdown-current'}>
                                        {data.label && data.label.substr(0, 2)}
                                    </div>
                                    <div style={{ textTransform: 'capitalize' }}>{data.label}</div>
                                </div>
                            }}
                        />
                    </div>

                    {/*<div className="new-task-files">
                        {
                            (values.files || []).map(({ name, id, type }) => (
                                <div key={id} className={'new-task-files-item'}>
                                    <a href={`/ajax/file/download/${id}`}><i className={'flaticon-tool-1'} /> {'Название файла'}.{type}</a>
                                </div>
                            ))
                        }
                    </div>*/}

                </div>
                <div className="full-modal-right-buttons">
                    {id && <button onClick={deleteTask} className={'full-modal-right-buttons-delete'}>Удалить</button>}
                    <button onClick={saveTask} className={'full-modal-right-buttons-save'} disabled={values.tch}>
                        {id ? 'Сохранить' : 'Создать'}
                    </button>
                </div>
            </div>
        </FullModal>
    )
}

export const TaskModal = withRouter(React.memo(TaskModalComponent))