import React, {useRef} from "react";
import {useOnClickOutside} from "../../hooks/useOnClickOutside";
import {CSSTransition} from "react-transition-group";

export const ListContextMenu = ({ contextMenu, close, children }) => {
    const dropdownRef = useRef(null)
    useOnClickOutside({ ref: dropdownRef, handler: () => close() })

    if(!contextMenu) return <></>

    return (
        <CSSTransition in={contextMenu} timeout={200} classNames="slide" unmountOnExit>
            <div
                ref={dropdownRef}
                className={'listContext'} style={{
                top: contextMenu.y,
                left: contextMenu.x
            }}>
                {children}
            </div>
        </CSSTransition>
    )
}