import React, {Component} from 'react';
import Textarea from '../../UI/Textarea';
import Input from '../../UI/Input';
import ModAutoSelect from '../../UI/ModAutoSelect';
import formValid from '../../UI/formValid';
import myaxios from '../../Axios';
import entityFields from './entityFieldsArr';
import DateTime from '../../UI/DateTime';



export default class UpdateFields extends Component {
    state = {
        formControl: {
            name:{value:'',valid:true, validation:{required:false}},
            time_value:{value:0},
        },
        fields:[],
        filter:[],
        operators: {
            text: [{value:"==", label:"Значение"},],
            multy: [{value:"==", label:"Значение"}],
            select: [{value:"==", label:"Значение"}],
            multiselect: [{value:"==", label:"Значение"}],
            number: [{value:"==", label:"Значение"}],
            date: [{value:"==", label:"Значение"}],
        },
        id:null,
        isFormula:false,
        formulaValue:'',
        formulaParentType:'',
        formulaChildType:''
    };
    setField = (type, e ) => {
        const formControl = this.state.formControl;
        formControl[type].value = e;
        formControl[type].valid = formValid(e, formControl[type].validation);
        this.setState({ formControl })
    }
    getAdditionalParams = (arr, id) => {
        myaxios.get(`/ajax/eav/attribute?entity_type=${id}`).then(response => {
            this.setState({ fields: AditionalFilter("filter", arr, response.data.items) })
        });
    };
    componentDidMount(){
        this.getAdditionalParams(entityFields[this.props.entity], this.props.entityId)
    }
    componentWillMount(){
        if(this.props.elArr){
            let formControl = this.state.formControl;
            formControl.name.value = this.props.elArr.metaData ? this.props.elArr.name : '';
            formControl.time_value.value = this.props.elArr.metaData ? this.props.elArr.time_value : '';
            //formControl.subject.value = this.props.elArr.metaData ? this.props.elArr.metaData.subject : '';
            this.setState({ formControl, filter: this.props.elArr.metaData ? this.props.elArr.metaData.data : [], id: this.props.elArr.id || null })
        }
    }
    isFormValid = () => {
        let formControl = this.state.formControl;
        let isFormValid = true;
        Object.keys(formControl).map((key, index) => {
            formControl[key].valid = formValid(formControl[key].value, formControl[key].validation);
            isFormValid = formControl[key].valid && isFormValid
        });
        this.setState({formControl});
        return isFormValid;
    };
    sendForm = () => {
        if(this.isFormValid()){
            if(this.props.elArr){
                setTimeout(
                    this.props.updateNode({
                        id:this.state.id,
                        name: this.state.formControl.name.value,
                        time_value: this.state.formControl.time_value.value,
                        is_scheduled: (this.state.formControl.time_value.value > 1),
                        metaData: {
                            data: this.state.filter
                            //subject: this.state.formControl.subject.value,
                        },
                        nodeType: "node",
                        type:"fields",
                    })
                )
            } else {
                setTimeout(
                    this.props.createNode({
                        name: this.state.formControl.name.value,
                        time_value: this.state.formControl.time_value.value,
                        is_scheduled: (this.state.formControl.time_value.value > 1),
                        metaData: {
                            data: this.state.filter
                            //subject: this.state.formControl.subject.value,
                        },
                        nodeType: "node",
                        type:"fields",
                    })
                )
            }
        }
    };
    searchValue = (val, type) => {
        for(let i = 0; i < this.state.fields.length; i++){
            if(this.state.fields[i]){
                if(this.state.fields[i].value === val){
                    return this.state.fields[i][type]
                }
            }
        }
    }
    setFilter = (index,type,value, t) => {
        const filter = this.state.filter;
        if(type === "field"){
            filter[index]["type"] = t.target[t.target.selectedIndex].getAttribute('data-sel');
            filter[index]["rememberType"] = t.target[t.target.selectedIndex].getAttribute('data-sel');
            filter[index]["link"] = t.target[t.target.selectedIndex].getAttribute('data-link');
            filter[index].value = "";
            filter[index].obj = null;
        }
        filter[index][type] = value;
        this.setState({ filter })
    };
    delItem = index => {
        const filter = this.state.filter;
        filter.splice(index, 1);
        this.setState({ filter })
    };
    addItem = () => {
        const filter = this.state.filter;
        filter.push({field: '', type:'', operator:'==', obj:null, value:''});
        this.setState({ filter })
    };
    updData = (index,id, arr) => {
        const filter = this.state.filter;
        filter[index].obj = arr;
        filter[index].value = id;
        this.setState({ filter })
    }
    updateData = (id, arr, el, type) => {
        let formControl = this.state.formControl;
        if(type === "add"){
            formControl[el].arr = arr;
            formControl[el].value = id;
        } else {
            formControl[el].arr = null;
            formControl[el].value = '';
        }
        this.setState({ formControl })
    }
    render(){
        return this.state.isFormula ?
            <Formula
                close={() => this.setState({ isFormula: false })}
                fields={this.state.fields}
                filter={this.state.filter}
                formulaValue={this.state.formulaValue}
                changeFilter={filter => this.setState({ filter })}
                formulaParentType={this.state.formulaParentType}
                formulaChildType={this.state.formulaChildType}
                changeParent={e => this.setState({ formulaParentType: e })}
                changeChild={e => this.setState({ formulaChildType: e })}
                entity={this.props.entity}

            />
            : <div>
                <div className="rmod-header">
                    <b>Изменить поле</b>
                    <a onClick={this.props.close} style={{ marginRight: "60px" }} className="mclose"><i className="flaticon-close" /></a>
                    <div className="togglelist"><button onClick={this.sendForm} className="rmod-ok" disabled=""><i className="flaticon-interface-1" /></button></div>
                </div>
                <div className="mod-cont sbform">
                    <div className="sbform">
                        <Input
                            label={"Название"}
                            value={this.state.formControl.name.value}
                            valid={this.state.formControl.name.valid}
                            onChange={e => this.setField("name", e.target.value)}
                            shouldValidate={true}
                        />
                        <Input
                            tooltip="Процесс запустится через N секунд, после того, как сработает триггер"
                            type="number"
                            value={this.state.formControl.time_value.value}
                            onChange={e => this.setField("time_value", e.target.value)}
                            shouldValidate={false}
                            label="Отложенный запуск на N секунд"
                        />
                        <div className="connection" style={{marginTop: "10px"}}><span>Выберите поля для изменения</span>
                            <div className="toggleparrent"><button onClick={this.addItem} className="btni">+</button></div></div>
                        <table style={{width:"100%"}} className="filterBpm">
                            <thead>
                            <tr>
                                <td style={{padding:"0px 5px"}}>Поле</td>
                                <td style={{padding:"0px 5px"}}>Оператор</td>
                                <td style={{padding:"0px 5px"}}>Значение</td>
                                {this.state.filter.length > 1 ? <td /> : null}
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.filter.map((item, index) =>
                                    <FilterItem
                                        setFilter={this.setFilter}
                                        delItem={this.delItem}
                                        updData={this.updData}
                                        searchValue={this.searchValue}
                                        handleOption={this.handleOption}
                                        getFormula={(i) => this.setState({ isFormula: true, formulaValue: i })}
                                        key={index}
                                        ind={index}
                                        fields={this.state.fields}
                                        filter={this.state.filter}
                                        operators={this.state.operators}
                                    />
                                )
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
    }
}

const FilterItem = props => {
    return <tr>
        <td>
            <Myselect
                onChange={e => props.setFilter(props.ind, "field", e.target.value, e)}
                empty={props.filter[props.ind].type.length === 0}
                value={props.filter[props.ind].field}
                options={props.fields} /></td>
        <td style={{ width:"100px" }}>
            <Myselect
                onChange={e => props.setFilter(props.ind, "type", e.target.value, e)}
                value={props.filter[props.ind].type}
                options={[
                    {value:props.searchValue(props.filter[props.ind].field, "type") ,label:"Значение",type:'number',link:'',arr:[], filterSearch:'value', minLength:"99999",edit:true,object:''},
                    {value:"reference",label:"Ссылка на поле",type:'reference',link:'',arr:[], filterSearch:'value', minLength:"99999",edit:true,object:''},
                    {value:"formula",label:"Формула",type:'formula',link:'',arr:[], filterSearch:'value', minLength:"99999",edit:true,object:''},
                ]}
            /></td>
        <td>
            {(props.filter[props.ind].type === "text") ?
                <Input
                    disabled={ props.filter[props.ind].field.length < 1 }
                    onChange={e => props.setFilter(props.ind, "value", e.target.value)}
                    value={props.filter[props.ind].value}
                    shouldValidate={true}
                    valid={props.filter[props.ind].value.length >= 1}
                />
                : null}
            {(props.filter[props.ind].type === "number") ?
                <Input
                    type="number"
                    disabled={ props.filter[props.ind].field.length < 1 }
                    onChange={e => props.setFilter(props.ind, "value", e.target.value)}
                    value={props.filter[props.ind].value}
                    shouldValidate={true}
                    valid={props.filter[props.ind].value.length >= 1}
                />
                : null}
            {(props.filter[props.ind].type === "date") ?
                <DateTime
                    value={props.filter[props.ind].value}
                    valid={props.filter[props.ind].value ? String(props.filter[props.ind].value).length > 4 : false}
                    isClearable={true}
                    handleChange={e => props.setFilter(props.ind, "value", e)}
                    dateFormat="dd-MMMM-yyyy"
                    todayButton
                />
                : null}
            {(props.filter[props.ind].type === "multy") ?
                <ModAutoSelect
                    link={props.searchValue(props.filter[props.ind].field, "link")}
                    minLength={props.searchValue(props.filter[props.ind].field, "minLength")}
                    result={props.searchValue(props.filter[props.ind].field, "filterSearch")}
                    event="status"
                    valid={(props.filter[props.ind].obj)}
                    shouldValidate={false}
                    addType="status"
                    entity={props.filter[props.ind].obj}
                    entityName={props.searchValue(props.filter[props.ind].field, "filterSearch")}
                    updateData={(id, arr, par2) => props.updData(props.ind,id, arr)}
                    unLink={() => props.setFilter(props.ind, "obj", null)}
                />
                : null}
            {(props.filter[props.ind].type === "multiselect" || props.filter[props.ind].type === "select") ?
                <Myselect
                    empty
                    value={props.filter[props.ind].value}
                    options={props.searchValue(props.filter[props.ind].field, "arr")}
                    //valid={props.filter[props.ind].value ? String(props.filter[props.ind].value).length > 4 : false}
                    val="id"
                    onChange ={e => props.setFilter(props.ind, "value", e.target.value)}
                />
                : null}
            {(props.filter[props.ind].type === "reference") ?
                <Myselect
                    empty
                    value={props.filter[props.ind].value}
                    options={props.fields}
                    //valid={props.filter[props.ind].value ? String(props.filter[props.ind].value).length > 4 : false}
                    //val="id"
                    onChange ={e => props.setFilter(props.ind, "value", e.target.value)}
                />
                : null}
            {(props.filter[props.ind].type === "formula") ?
                <Input
                    type="text"
                    disabled={false}
                    onChange={e => props.setFilter(props.ind, "value", e.target.value)}
                    value={props.filter[props.ind].value}
                    shouldValidate={true}
                    valid={props.filter[props.ind].value.length >= 1}
                    onClick={ () => props.getFormula(props.ind) }
                />
                : null}

        </td>
        {props.filter.length > 0 ? <td style={{ width:"15px" }}>
            <a onClick={() => props.delItem(props.ind)} style={{ fontSize:"19px", color:"#c2263f", display:"block", textAlign:"center" }}>x</a>
        </td> : null}
    </tr>
};

function isInvalid({valid, shouldValidate, isFormValid}) {
    return !valid && shouldValidate && !isFormValid
}

const Myselect = props => {
    const vl = props.value || "";
    let val = props.val ? props.val : "value";
    return <div className={props.className}>
        {(props.label) ? <label data-tooltip={props.tooltip}>{(props.tooltip) ? <div className="squerTooltipe"/> : null} {props.label}</label> : null}
        <select value={vl} onChange={props.onChange} style={props.style} disabled={props.disabled}>
            {(props.empty) ? <option value={``} /> : null }
            {
                props.options ? props.options.map((i, index)=>
                    <option style={{ display: i.edit === false ? "none" : "" }} key={index} data-link={i.link} data-sel={i.type} value={i[val]}>{i.label}</option>
                ) : null
            }
        </select>
        {
            (isInvalid(props)) ? <span className="errorMsg">{(props.errorMessage) || "Заполните поле"}</span> : null
        }
    </div>
};

function sort(arr, value) {
    let a = true;
    arr.map(item => {
        if(item.value == value){
            a = false;
        }
    });
    return a;
}
function AditionalFilter(type, arr, additional) {
    if(type === "filter"){
        let prms = arr;
        for(let i=0; i<additional.length; i++){
            if(sort(arr, additional[i].field_id)){
                prms.push({
                    value: additional[i].field_id,
                    label: additional[i].label,
                    type: (additional[i].type === "multiselect") ? "multiselect" : (additional[i].type === "select") ? "select" : (additional[i].type === "date" || additional[i].type === "dateTime") ? 'date' : "text",
                    filterSearch:'name',
                    arr:additional[i].options,
                    link:"",
                    filterLength:"9999999999"
                })
            }
        }
        return prms
    }
}

const Formula = props => {
    function vl(v) {
        switch(props.formulaParentType){
            case "fields": return props.fields; break;
            case "operators": return [
                {value:'+', label: "Плюс +"},
                {value:'-', label: "Минус -"},
                {value:'*', label: "Умножить *"},
                {value:'/', label: "Разделить /"},
            ]; break;
            case "fns": return []; break;
            case "glb": return []; break;
        }
    }
    return <div>
        <div className="rmod-header">
            <b>Редактировать формулу</b>
            <a onClick={props.close} style={{ marginRight: "0px" }} className="mclose"><i className="flaticon-close" /></a>
            {/*<div className="togglelist"><button className="rmod-ok" disabled=""><i className="flaticon-interface-1" /></button></div>*/}
        </div>
        <div className="mod-cont sbform formulaTxt">
            <Myselect
                empty
                label="Типы значений"
                className="rc6"
                options={[
                    {value:"fields", label:"Поля"},
                    {value:"operators", label:"Операторы"},
                    {value:"fns", label:"Функции"},
                    {value:"glb", label:"Глобальные переменные"},
                ]}
                value={props.formulaParentType}
                onChange={e => {props.changeParent(e.target.value); props.changeChild('')} }
            />
            <Myselect
                empty
                label="Допустимые значения"
                className="rc6"
                options={vl(props.formulaParentType)}
                value={props.formulaChildType}
                onChange={e => props.changeChild(e.target.value)}
            />
            <div className="clear" />
            <Input label="Переменная" value={(props.formulaParentType === "fields" && props.formulaChildType !== "" ) ? `{${props.entity}.${props.formulaChildType}}` : props.formulaChildType}/>
            <Textarea
                rows="6"
                label="формула"
                value={props.filter[props.formulaValue].value}
                onChange={e => {
                    const filter = props.filter;
                    filter[props.formulaValue].value = e.target.value;
                    props.changeFilter(filter)
                }}
            />
        </div>
    </div>
}