import React, {useEffect, useRef, useState} from "react";
import {withRouter} from "react-router-dom";
import Bgdrop from "../../Modal/Bgdrop";
import Loader from "../UI/Loader";
import {CSSTransition} from "react-transition-group";
import cancelIcon from "../../fonts/icons/cancel.svg";

const Modal = ({ isOpen, save, close, title, children, loading = false, saveIcon = "flaticon-folder-3", classname, parentClassName, tch = false, showLeaveModal }) => {
    const [isLeaveModal, setIsLeaveModal] = useState(false)
    const leaveModal = useRef(null)

    const callClose = () => {
        if(showLeaveModal && !tch) {
            setIsLeaveModal(true)
            if(leaveModal && leaveModal.current) leaveModal.current.focus();
        } else {
            close()
        }
    }

    useEffect(() => {
        setIsLeaveModal(false)
    }, [isOpen])


    return <div className={parentClassName}>

    <input className={'input-hidden'} ref={leaveModal}  onKeyDown={e => {
        if(e && e.keyCode === 13) { close() }
        else if(e && e.keyCode === 27) setIsLeaveModal(false)
    }} />
    {showLeaveModal && <CSSTransition in={isLeaveModal} timeout={200} classNames="slide" unmountOnExit>
        <div className="leave-modal">
            <div className="leave-modal-container">
                <img className="leave-modal-image" src={cancelIcon} alt=""/>
                <h3 className="leave-modal-title">{"Вы уверены?"}</h3>
                <p className="leave-modal-description">{"У вас есть несохраненные данные. Если вы покинете страницу, данные не сохранятся!"}</p>
                <button className="leave-modal-confirm" onClick={() => setIsLeaveModal(false)}>Остаться</button>
                <button className="leave-modal-cancel" onClick={close}>Покинуть</button>
            </div>
        </div>
    </CSSTransition>}

    <div id="right-modal" className={`${classname} right-modal scrollbar ${isOpen && 'r-open'}`}>
        <div className="sbform create-activ">
            <div className="rmod-header">
                <b>{title}</b>
                <a onClick={callClose} className="rmod-close"><i className="flaticon-close" /></a>
                <a disabled={!tch} style={{ top: 0, opacity: tch ? 0.3 : 1, cursor: tch ? 'default' : 'pointer' }} onClick={!tch && !loading && save} className="rmod-ok" target="_blank"><i className={saveIcon} /></a>
            </div>
            <div className="mod-cont">
                {loading ? <Loader contain /> : children}
            </div>
        </div>
    </div>
        {isOpen ? <Bgdrop close={callClose} /> : null}
    </div>
}

export default withRouter(Modal);