import {entity} from "../../components/ListComponentEntity";
import {NavLink} from "react-router-dom";
import React, {useState} from "react";
import {NewList} from "../../components/NewList/NewList";
import {updateProductService} from "../../services/product/product";
import Input from "../../components/UI/Input";
import {ModalLoader} from "../../components/UI/Loader";
import Modal from "../../components/Modal/Modal";

export const ListProductNew = (props) => {
    const [loading, setLoading] = useState(false)
    const [updatedNumber, setUpdatedNumber] = useState(0)
    const [editedPriceField, setEditedPriceField] = useState(null)

    const updateSingleProductPrice = async (id) => {
        setLoading(true)
        try {
            const { status, data } = await updateProductService(editedPriceField.id, { name: editedPriceField.name, base_price: editedPriceField.base_price })
            if(status === 200 || status === 201) {
                setUpdatedNumber(prevState => prevState + 1)
                setEditedPriceField(null)
            }
        } catch (e) { }
        setLoading(false)
    }
    return loading ? <ModalLoader /> : (
        <>
            <Modal isOpen={editedPriceField} save={updateSingleProductPrice} title={editedPriceField && editedPriceField.name} close={() => setEditedPriceField(null)}>
                <div className={'sbform'}>
                    <Input
                        value={editedPriceField && editedPriceField.base_price}
                        onChange={e => {
                            setEditedPriceField(prevState => ({ ...prevState, base_price: e.target.value }))
                        }}
                    />
                </div>
            </Modal>
            <NewList
                { ...props }
                entity={'product'}
                entity_type={9}
                requiredFields={'id,name,base_price,category_id,currency_id'}
                requiredExpand={'category,currency'}
                deleteToShowColumn={'id,name,base_price,category_id,currency_id'}
                additionalFieldsExists={true}
                isFilterExists={true}
                filterFields={entity.product.fArr}
                singleSearch={entity.product.fArr[0]}
                headingColumns={['Название/цена', 'Категория']}
                updatedNumber={updatedNumber}
                RenderColumn={(item) => (
                    <>
                        <td>
                            <NavLink to={`/product/${item.id}`}  className={'nowrap-list-column'}>{item.name}</NavLink>
                            <div style={{ display: 'inline-block' }} onClick={() => setEditedPriceField({ name: item.name, id: item.id, base_price: item.base_price || 0 })}>{item.base_price}{item.currency && item.currency.text_symbol}</div>
                        </td>
                        <td>
                            {item.category && <span className="label-st" style={{ backgroundColor: 'rgb(255, 193, 100)' }}>{item.category.name}</span>}
                        </td>
                    </>
                )}
                createButtonRender={(setModalType) => <button onClick={() => setModalType('product')} className="open-modal-button btni">Новый продукт</button>}
                rightNavbarConfig={{
                    export: true,
                    additionalFields: '/fieldsettings/9',
                    columns: true,
                    import: '/import/product'
                }}
                massDoConfig={{ deleteAll: `/ajax/product/delete-all` }}
                contextNav={{ edit: true, task: false, print: false, delete: true }}
                sort={entity.product.fArr.filter(el => el.sort)}
            />
        </>
    )
}