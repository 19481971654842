import React, {useState} from "react";
import {FieldGenZone, setField, unLink, updateData, isFormValid, filesFunc} from "./fieldGen";
import myaxios from "./Axios";

const ClientCard = (props) => {
    const [contact, setContact] = useState({
        name:{
            type: 'AutoSelect',
            cls:'c12',
            label: 'ФИО',
            link: '/ajax/contact?filter[name][like]=',
            value:'',
            result:'name',
            valid: true,
            entity:null,
            entityName:"name",
            minLength:3,
            validation: {required: true},
            setField: true,
            zone: 1
        },
        mobile_phone:{
            type: 'AutoSelect',
            cls:'c12',
            label: 'Номер телефона',
            link: '/ajax/contact?filter[mobile_phone][like]=',
            value:'',
            result:'mobile_phone',
            valid: true,
            entity:null,
            entityName:"name",
            minLength:3,
            zone: 1
        },
        email:{
            type: 'AutoSelect',
            cls:'c12',
            label: 'Email',
            link: '/ajax/contact?filter[email][like]=',
            value:'',
            result:'email',
            valid: true,
            entity:null,
            entityName:"name",
            minLength:3,
            zone: 1
        },
        position:{
            value:'',
        }
    });
    const [account, setAccount] = useState({
        name:{
            type: 'AutoSelect',
            cls:'c12',
            label: 'ФИО',
            link: '/ajax/account?filter[name][like]=',
            value:'',
            result:'name',
            valid: true,
            entity:null,
            entityName:"name",
            minLength:3,
            validation: {required: true},
            setField: true,
            zone: 1
        },
        phone:{
            type: 'AutoSelect',
            cls:'c12',
            label: 'Номер телефона',
            link: '/ajax/account?filter[phone][like]=',
            value:'',
            result:'phone',
            valid: true,
            entity:null,
            entityName:"name",
            minLength:3,
            zone: 1
        },
        email:{
            type: 'AutoSelect',
            cls:'c12',
            label: 'Email',
            link: '/ajax/account?filter[email][like]=',
            value:'',
            result:'email',
            valid: true,
            entity:null,
            entityName:"name",
            minLength:3,
            zone: 1
        },
        website:{
            type: 'AutoSelect',
            cls:'c12',
            label: 'Website',
            link: '/ajax/account?filter[website][like]=',
            value:'',
            result:'website',
            valid: true,
            entity:null,
            entityName:"name",
            minLength:3,
            zone: 1
        }
    });

    const createContact = () => {
        if(contact.name.value.length > 1) myaxios.post(`/ajax/contact`, {first_name: contact.name.value, email: contact.email.value, mobile_phone: contact.mobile_phone.value}).then(res => {
            if(res.status === 200 || res.status === 201){
                props.updateDataData(res.data.id, res.data, "contact_id");
            }
        })
    };

    const createAccount = () => {
        if(account.name.value.length > 1) myaxios.post(`/ajax/account`, {name: account.name.value, email: account.email.value, phone: account.phone.value, website: account.website.value}).then(res => {
            if(res.status === 200 || res.status === 201){
                props.updateDataData(res.data.id, res.data, "account_id");
            }
        })
    };

    return <div>
        {props.contact ? !props.formControl.contact_id.entity ? <div className="contact-left-col contactsAddBlock">
            <h3>Контакт</h3>
            <FieldGenZone
                zone={1}
                arr={contact}
                setField={(e, type) => setContact(props.setField(contact, type, e))}
                setFieldAutoSelect={(e,type) => {
                    contact[type].value = e.target.value;
                    setContact(prevState => { return {...prevState, contact} });
                }}
                updateData={(id, arr) => props.updateDataData(id, arr, "contact_id")}
            />
            {contact.name.value.length > 2 ? <button onClick={createContact} className="btn-m">Создать контакт</button> : null}
            <div className="clear" />
        </div> :
            <div className="contact-left-col contactsAddBlock">
                <a onClick={()=>props.unLink("contact_id")} className="lead-form-close">x</a>
                <h3>Контакт</h3>
                <div>
                    {(props.formControl.contact_id.entity.name) ?<div className="card-block">
                        <em>ФИО</em>
                        <a onClick={() => props.history.push('/contact/' + props.formControl.contact_id.value)}>{props.formControl.contact_id.entity.name}</a>
                    </div> : null}
                    {(props.formControl.contact_id.entity.position) ?
                        <div className="card-block">
                            <em>Должность</em>
                            <span>{props.formControl.contact_id.entity.position}</span>
                        </div> : null}
                    {(props.formControl.contact_id.entity.mobile_phone) ?
                        <div className="card-block">
                            <em>Мобильный телефон</em>
                            <span className="toggleMenu">{props.formControl.contact_id.entity.mobile_phone}<ul>
                                <li><a onClick={() => props.updateDataContact("Call", {tel: props.formControl.contact_id.entity.mobile_phone, id: props.formControl.contact_id.entity.id, entity: "contact"})}>Позвонить</a></li>
                                <li><a onClick={() => props.updateDataContact("SendSms", {tel: props.formControl.contact_id.entity.mobile_phone, id: props.formControl.contact_id.entity.id, entity: "contact"})}>Отравить SMS</a></li></ul>
                            </span>
                        </div> : null}
                    {(props.formControl.contact_id.entity.work_phone) ?
                        <div className="card-block">
                            <em>Рабочий телефон</em>
                            <span className="toggleMenu">{props.formControl.contact_id.entity.work_phone}<ul>
                                <li><a onClick={() => props.updateDataContact("Call", {tel: props.formControl.contact_id.entity.work_phone, id: props.formControl.contact_id.entity.id, entity: "contact"})}>Позвонить</a></li>
                                <li><a onClick={() => props.updateDataContact("SendSms", {tel: props.formControl.contact_id.entity.work_phone, id: props.formControl.contact_id.entity.id, entity: "contact"})}>Отравить SMS</a></li></ul>
                            </span>
                        </div> : null}
                    {(props.formControl.contact_id.entity.email) ?
                        <div className="card-block">
                            <em>Email</em>
                            <span className="toggleMenu">{props.formControl.contact_id.entity.email}<ul>
                                <li><a onClick={() => props.updateDataContact("SendMail", {from: props.formControl.contact_id.entity.email} )}>Отправить Email</a></li></ul>
                            </span>
                        </div> : null}
                </div>
            </div> : null}



        {props.account ? !props.formControl.account_id.entity ? <div className="contact-left-col contactsAddBlock">
                <h3>Контрагент</h3>
                <FieldGenZone
                    zone={1}
                    arr={account}
                    setField={(e, type) => setContact(props.setField(account, type, e))}
                    setFieldAutoSelect={(e,type) => {
                        account[type].value = e.target.value;
                        setAccount(prevState => { return {...prevState, account} });
                    }}
                    updateData={(id, arr) => props.updateDataData(id, arr, "account_id")}
                />
                {account.name.value.length > 2 ? <button onClick={createAccount} className="btn-m">Создать контрагента</button> : null}
                <div className="clear" />
            </div> :
            <div className="contact-left-col contactsAddBlock">
                <a onClick={()=>props.unLink("account_id")} className="lead-form-close">x</a>
                <h3>Контрагент</h3>
                <div>
                    {(props.formControl.account_id.entity.name) ?<div className="card-block">
                        <em>ФИО</em>
                        <a onClick={() => props.history.push('/account/' + props.formControl.account_id.value)}>{props.formControl.account_id.entity.name}</a>
                    </div> : null}
                    {(props.formControl.account_id.entity.phone) ?
                        <div className="card-block">
                            <em>Мобильный телефон</em>
                            <span className="toggleMenu">{props.formControl.account_id.entity.phone}<ul>
                                <li><a onClick={() => props.updateDataContact("Call", {tel: props.formControl.account_id.entity.phone, id: props.formControl.account_id.entity.id, entity: "contact"})}>Позвонить</a></li>
                                <li><a onClick={() => props.updateDataContact("SendSms", {tel: props.formControl.account_id.entity.phone, id: props.formControl.account_id.entity.id, entity: "contact"})}>Отравить SMS</a></li></ul>
                            </span>
                        </div> : null}
                    {(props.formControl.account_id.entity.number) ?
                        <div className="card-block">
                            <em>Рабочий телефон</em>
                            <span className="toggleMenu">{props.formControl.account_id.entity.number}<ul>
                                <li><a onClick={() => props.updateDataContact("Call", {tel: props.formControl.account_id.entity.number, id: props.formControl.account_id.entity.id, entity: "contact"})}>Позвонить</a></li>
                                <li><a onClick={() => props.updateDataContact("SendSms", {tel: props.formControl.account_id.entity.number, id: props.formControl.account_id.entity.id, entity: "contact"})}>Отравить SMS</a></li></ul>
                            </span>
                        </div> : null}
                    {(props.formControl.account_id.entity.email) ?
                        <div className="card-block">
                            <em>Email</em>
                            <span className="toggleMenu">{props.formControl.account_id.entity.email}<ul>
                                <li><a onClick={() => props.updateDataContact("SendMail", {from: props.formControl.account_id.entity.email} )}>Отправить Email</a></li></ul>
                            </span>
                        </div> : null}
                    {(props.formControl.account_id.entity.website) ?
                        <div className="card-block">
                            <em>Сайт</em>
                            <span>{props.formControl.account_id.entity.website}</span>
                        </div> : null}
                </div>
            </div> : null}

    </div>
};

export default ClientCard;