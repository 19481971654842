import React, {useEffect, useRef, useState} from "react";
import Bgdrop from "../../Modal/Bgdrop";
import './full-modal.css'
import {ModalLoader} from "../UI/Loader";
import {CSSTransition} from "react-transition-group";
import cancelIcon from "../../fonts/icons/cancel.svg";

export const FullModal = ({ children, headerLeftRender, headerRightRender, close, loading, isOpen, tch, showLeaveModal }) => {
    const [isLeaveModal, setIsLeaveModal] = useState(false)
    const leaveModal = useRef(null)

    const callClose = () => {
        if(showLeaveModal && !tch) {
            setIsLeaveModal(true)
            if(leaveModal && leaveModal.current) leaveModal.current.focus();
        } else {
            close()
        }
    }

    useEffect(() => {
        setIsLeaveModal(false)
    }, [isOpen])

    return (
        <CSSTransition in={isOpen} timeout={200} classNames="my-node" unmountOnExit>
            <div className={'full-modal'}>
                <input style={{ opacity: 0, width: 0, height: 0 }} ref={leaveModal}  onKeyDown={e => {
                    if(e && e.keyCode === 13) { close() }
                    else if(e && e.keyCode === 27) setIsLeaveModal(false)
                }} />
                {showLeaveModal && <CSSTransition in={isLeaveModal} timeout={200} classNames="slide" unmountOnExit>
                    <div className="leave-modal">
                        <div className="leave-modal-container">
                            <img className="leave-modal-image" src={cancelIcon} alt=""/>
                            <h3 className="leave-modal-title">{"Вы уверены?"}</h3>
                            <p className="leave-modal-description">{"У вас есть несохраненные данные. Если вы покинете страницу, данные не сохранятся!"}</p>
                            <button className="leave-modal-confirm" onClick={() => setIsLeaveModal(false)}>Остаться</button>
                            <button className="leave-modal-cancel" onClick={close}>Покинуть</button>
                        </div>
                    </div>
                </CSSTransition>}
                <div className="full-modal-container">
                    {<CSSTransition in={loading} timeout={200} classNames="slide" unmountOnExit>
                        <ModalLoader />
                    </CSSTransition>}
                    <div className="full-modal-header">
                        <div className="full-modal-header-left">
                            {headerLeftRender}
                        </div>
                        <div className="full-modal-header-right">
                            {headerRightRender}
                            <div onClick={callClose} className="full-modal-header-close">&#10005;</div>
                        </div>
                    </div>
                    <div className={'full-modal-body'}>
                        {children}
                    </div>
                </div>
                <Bgdrop close={callClose} />
            </div>
        </CSSTransition>
    )
}