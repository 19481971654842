import React from 'react';
import Notif from '../components/Notif';
import Input from '../components/UI/Input';
import formValid from '../components/UI/formValid';
import AutoSelect from '../components/UI/AutoSelect';
import Textarea from '../components/UI/Textarea';
import myaxios from '../components/Axios';
import Loader from '../components/UI/Loader';
import Myselect from "../components/UI/Select";

function isKyr(str) {
    return /[а-я]/i.test(str);
}

function searchEntityId(id){
    switch(id){
        case "opportunity": return 1;
        case 'contact': return 2;
        case 'account': return 3;
        case 'project': return 6;
        case 'invoice': return 13;
        case "order": return 14;
        case "lead": return 23;
        case "product": return 9;
    }
}

class SendSms extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            formControl: {
                to: {
                    value:'',
                    valid:true,
                    validation:{required:true, minLength:7}
                },
                body:{
                    value:'',
                    valid:true,
                    validation:{required:true}
                }
            },
            symbol:0,
            isFormValid: true,
            tch: true,
            loading: false,
            platesList:[],
            plates:""
        };
    }

    getPlates = () => {
        myaxios.get(`/ajax/plates`).then(res => {
            this.setState({ platesList: res.data.items })
        })
    }
    componentDidMount(){
        let formControl = this.state.formControl;
        formControl.to.value = this.props.id ? this.props.id.tel : '';
        this.setState({ formControl })

        this.getPlates()
    }
    sendMail = e => {
        e.preventDefault();
        if(this.isFormValid()) {
            this.setState({ tch: true, loading: true});
            myaxios.post(`/ajax/site/send-sms`, {
                to: this.state.formControl.to.value,
                body: this.state.formControl.body.value,
                entity: this.props.id ? searchEntityId(this.props.id.entity) : null,
                entity_id: this.props.id ? this.props.id.id : null
            }).then(res => {
                if(res.status === 200 || res.status === 201 || res.status === 204) {
                    Notif("save", "SMS отправлено успешно");
                    this.setState({ tch: false, loading: false})
                }
            }).catch((error) => {
                this.setState({ tch: false, loading: false})
            });
        }};
    setField = (event, type) => {
        const formControl = {  ...this.state.formControl };
        const control = { ...formControl[type] };
        control.value = event.target.value;
        formControl[type] = control;
        this.setState({
            formControl, tch: false
        })
    };
    isFormValid = () => {
        let formControl = this.state.formControl;
        let isFormValid = true;
        Object.keys(formControl).map((key, index) => {
            formControl[key].valid = formValid(formControl[key].value, formControl[key].validation)
            isFormValid = formControl[key].valid && isFormValid
        });
        this.setState({formControl, isFormValid});
        return isFormValid;
    };
    render(){
        return (this.state.loading) ? <Loader contain /> : <form className="sbform create-activ">
            <div className="rmod-header">
                <b>Отправить SMS</b>
                <a onClick={this.props.close} style={{right: "70px"}} className="mclose"><i className="flaticon-close" /></a>
                <div className="togglelist">
                    <button onClick={this.sendMail} className="rmod-ok" disabled={this.state.tch}><i className="flaticon-paper-plane" /></button>
                </div>
            </div>
            {this.props.sms ? <div className="mod-cont">
                <Input
                    label="Кому"
                    type="text"
                    className="c12"
                    value={this.state.formControl.to.value}
                    onChange={event => this.setField(event, "to")}
                    errorMessage={"Поле не может быть короче 7-ми символов"}
                    valid={this.state.formControl.to.valid}
                    shouldValidate={true}
                    isFormValid={this.state.isFormValid}
                    disabled={(this.props.id) ? this.props.id.tel : false}
                />
                <div className="clear" />
                <Myselect
                    label="Шаблон"
                    value={this.state.plates}
                    options={this.state.platesList}
                    onChange={e => {
                        if(e.target.value !== ""){
                            const formControl = this.state.formControl;
                            formControl.body.value = this.state.platesList.filter(item => item.id == e.target.value)[0].body;
                            this.setState({ formControl, plates: e.target.value, tch: false })
                        }
                    }}
                    val="id"
                    lab="name"
                    datatype="body"
                    empty
                />
                <div className="clear" />
                <Textarea
                    label="Сообщение"
                    rows="6"
                    value={this.state.formControl.body.value}
                    onChange={event => this.setField(event, "body")}
                    errorMessage={"Поле не может быть пустым"}
                    valid={this.state.formControl.body.valid}
                    shouldValidate={true}
                    isFormValid={this.state.isFormValid}
                />
                <div className="smsBlock">
                    <em>Набрано символов: {this.state.formControl.body.value.length}</em>
                    <em>Осталось символов: {(isKyr(this.state.formControl.body.value) ? 70 : 160) - this.state.formControl.body.value.length}</em>
                    <em>{(this.state.formControl.body.value.length / 70 === 1) ? alert() : null}</em>
                </div>

            </div> : <div className="mod-cont"><p style={{ fontSize: "14px", padding:"5px 0" }}>Добавьте интеграцию с сервисом SMS рассылок</p></div>}


        </form>
    }
}

export default SendSms;