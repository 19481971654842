import React, { Component } from 'react';
import Textarea from '../../../../components/UI/Textarea';
import Loader from '../../../../components/UI/Loader';
import StageItem from '../../../project/tab/stageItem';
import MainEditor from "../../../../components/UI/Editor";


const Information = props => {
    return <div className="tab-content">
        <div className="sp-table body-table">
            <div style={{borderSpacing: "0 5px"}} className="stageDiv stagesTable">
                <div className="connection" style={{ marginTop: "0px"}}>
                    <span>Этапы проекта</span>
                    <div className="toggleparrent">
                        <button onClick={()=>props.stagesFunc("open", "stag")} className="btni">+</button>
                    </div>
                </div>
                {
                    props.children.map((stage, index) =>
                        <StageItem
                            stage={stage}
                            key={index}
                            id={stage.id}
                            index={index}
                            parentId={props.parentId}
                            percentComplete={stage.execution_percent}
                            name={stage.name}
                            type={stage.type}
                            startDate={stage.start_date}
                            endDate={stage.end_date}
                            status={stage.statusLabel}
                            deadline={stage.deadline}
                            stagesFunc={props.stagesFunc}
                            descendants={stage.descendants}
                            {...props}
                        />
                    )
                }
            </div>
        </div>

        <div className="sbform formlabel">
            <div className="connection">
                <span>Станки</span>
                <div className="toggleparrent">
                    <button onClick={() => props.openMachine()} className="btni">+</button>
                </div>
            </div>
            <div className="body-table">
                <table className="miniTable">
                    <thead>
                    <td>Тип ресурса</td>
                    <td>Статус</td>
                    {/*<td>Деталей</td>*/}
                    <td>План трудозатраты</td>
                    <td>Факт трудозатраты</td>
                    <td style={{ width: "5px" }}/>
                    <td style={{ width: "5px" }}/>
                    <td />
                    </thead>
                    <tbody>
                    {(props.machineLoading) ? <Loader contain /> :
                        (props.machines) ? props.machines.map((item, index) =>
                            <tr key={index}>
                                <td><b>{(item.machine) ? <a onClick={() => props.openMachine(item.machine_id)} >{item.machine.name}</a> : null}</b></td>
                                <td>{item.statusLabel}</td>
                                {/*<td>{item.factQuantitySum} из {item.plan_quantity}</td>*/}
                                <td>{item.plan}</td>
                                <td>{item.actual_cost}</td>
                                <td><a data-tooltip="Наверх" onClick={() => props.moveMachine(props.machines[index-1].machine_id, item.machine_id, "appendTo")} className="arrow-up">
                                    {(index !== 0) ? <i className="flaticon-up-arrow-1" /> : null}
                                </a></td>
                                <td><a data-tooltip="Вниз" onClick={() => props.moveMachine(item.machine_id, props.machines[index+1].machine_id, "appendTo")} className="arrow-down">
                                    {(index !== props.machines.length - 1) ? <i className="flaticon-up-arrow-1" /> : null}
                                </a></td>
                                <td><div className="toogle-bottom fl-r">
                                    <a className="is"><i className="flaticon-more" /></a>
                                    <ul className="toogle-list">
                                        <li><a onClick={() => props.openMachine(item.machine_id)} href={null}><i className="flaticon-edit-1" /> <b>Редактировать</b></a></li>
                                        <li><a onClick={() => props.deleteMachine(item.machine_id)} className="table-del"><i className="flaticon-delete-1" /> <b>Удалить</b></a></li>
                                    </ul>
                                </div>
                                </td>
                            </tr>
                        ) : null
                    }
                    </tbody>
                </table>
            </div>
        </div>

<div className="clear" />
    <div className="sbform formlabel">
        <div className="connection">
            <span>Ресурсы</span>
            <div className="toggleparrent">
            <button onClick={() => props.stages("open", null, null)} className="btni">+</button>
            </div>
        </div>
            <div className="body-table">
                <table className="miniTable">
                    <thead>
                    <td>Тип ресурса</td>
                    <td>Значение</td>
                    <td>План трудозатраты</td>
                    <td>Факт трудозатраты</td>
                    <td />
                    </thead>
                    <tbody>
                    {
                        props.resources.map((item, index) =>
                            <tr key={index}>
                                <td><b>{(item.type) ? item.type.name : null}</b></td>
                                <td><b>{(item.related) ? item.related.name : null}</b></td>
                                <td>{item.plan}</td>
                                <td>{item.fact_sum}</td>
                                <td><div className="toogle-bottom fl-r">
                                <a className="is"><i className="flaticon-more" /></a>
                                <ul className="toogle-list">
                                <li><a onClick={() => props.stages("open", item.id, index)} href={null}><i className="flaticon-edit-1" /> <b>Редактировать</b></a></li>
                                <li><a onClick={() => props.stages("delete", item.id, index)} className="table-del"><i className="flaticon-delete-1" /> <b>Удалить</b></a></li>
                                </ul>
                                </div>
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </table>
            </div>
    </div>
        <div className="sbform formlabel mt10">
            <MainEditor
                className="fgroup rc12"
                value={props.description}
                onChange={event => {props.setDescription(event)}}
                label="Комментарий"
                valid={true}
            />
        </div>
    </div>
    };

export default Information;