import React, {lazy, Suspense} from 'react';
import logo from '../img/logo.png'
import OpportunityView from '../containers/opportunity/LeadView';
import AccountView from '../containers/account/AccountView';
import {Route, NavLink, Switch, Redirect, withRouter, Router} from 'react-router-dom';
import RightModal from '../Modal/Modal';
import Bgdrop from '../Modal/Bgdrop';
import Mail from '../containers/mail/EmailList/EmailList';
import MailDetail from '../containers/mail/MailDetail';
import ProjectView from '../containers/project/ProjectView';
import MailFunc from '../containers/mail/MainFunc';
import StageView from '../containers/project/Stage/StageView';
import myaxios from '../components/Axios';
import KanbanBoard from '../containers/opportunity/Kanban';
import TaskView from '../containers/tasks/TaskView';
import TaskCalendar from '../containers/tasks/TaskCalendar';
import FunnelView from '../components/bp/funnelView';
import localstorageSettings from '../components/localstorageSettings';
import AnswerCall from '../Modal/AnswerCall';
import Notif from '../components/Notif';
import ExpenseView from '../containers/expenses/ExpenseView';
import ListComponent from '../components/ListComponent';
import {IntegrationList} from '../components/integration/IntegrationList';
import {TransitionGroup, CSSTransition} from "react-transition-group";
import TaskKanbanConfig from "../containers/tasks/TaskKanbanConfig";
import WorkTime from "../components/other/WorkTime";
import FeedList from "../containers/feed/FeedList";
import {CheckboxLogin} from "../Modal/Integrations/Checkbox";
import Cloud from "../components/other/Cloud";
import JoyrideMain from "../components/other/Joyride";
import {putWizard} from "../components/other/needFunctions.ts";
import SupportModal from "../Modal/SupportModal";
import Test from "../components/other/Test";
import Education from "../components/other/Education/Education";
import EducateProgress from "../components/other/Education/EducateProgress";
import {Item, outItems} from "../components/other/Education/Config"

import {
    ProjectListOther,
} from "../components/ListsForListComponent";
import Loader from "../components/UI/Loader";
import {ApiTests} from "../components/ApiTests";
import {createError} from "../services/other/events";
import {LeadViewNew} from "../containers/lead/LeadViewNew";
import {InvoiceNewList} from "../containers/invoice/InvoiceNewList";
import {NewLeadList} from "../containers/lead/NewLeadList";
import {ListOpportunityNew} from "../containers/opportunity/ListOpportunityNew";
import {ListOrderNew} from "../containers/order/ListOrderNew";
import {ListContactNew} from "../containers/contacts/ListContactNew";
import {ListAccountNew} from "../containers/account/ListAccountNew";
import {ListTaskNew} from "../containers/tasks/ListTaskNew";
import {ListDocumentNew} from "../containers/document/ListDocumentNew";
import {ListCaseNew} from "../containers/cases/ListCaseNew";
import {ListProductNew} from "../containers/products/ListProductNew";
import {ListPurchaseNew} from "../containers/stock/purchase/ListPurchaseNew";
import {ListReplanishNew} from "../containers/stock/replanish/ListeplanishNew";
import {ListShipmentNew} from "../containers/stock/shipment/ListShipmentNew";
import {ListTransferNew} from "../containers/stock/transfer/ListTransferNew";
import {ListProviderReturnNew} from "../containers/stock/providerReturn/ListProviderReturnNew";
import {ListConsumptionNew} from "../containers/stock/consumption/ListConsumptionNew";
import {ListStocktakeNew} from "../containers/stock/stocktake/ListStocktakeNew";
import {ContactViewNew} from "../containers/contacts/ContactViewNew";
import {AccountViewNew} from "../containers/account/AccountViewNew";
import {FinanceListNew} from "../containers/finance/FinanceListNew";
import {OrderViewNew} from "../containers/order/OrderViewNew";
import {DocumentViewNew} from "../containers/document/DocumentViewNew";
import {CaseViewNew} from "../containers/cases/CaseViewNew";
import {ProductViewNew} from "../containers/products/ProductViewNew";
import {InvoiceViewNew} from "../containers/invoice/InvoiceViewNew";
import {MessengersList} from "../components/Messangers/MessengersList";
import {Test2} from "../components/Test2";
import {ShipmentReport} from "../containers/stock/shipment/ShipmentAnalitics";
import {SettingsModal} from "../components/SettingsModal/SettingsModal";
import {OpportunityViewNew} from "../containers/opportunity/OpportunityViewNew";
import {CashWindow} from "../containers/order/components/CashWindow/CashWindow";
import {InvoiceCashWindow} from "../containers/invoice/components/CashWindow/CashWindow";
import OpportunityKanban from "../containers/opportunity/OpportunityKanban";
import {BusinessProcessNew} from "../components/BussinessProcessNew/BusinessProcessNew";
import {BpListNew} from "../components/BussinessProcessNew/BusinessProcessModals/BpListNew";
import {TrackerSettings} from "../containers/tracker";
import {TrackerSiteList} from "../containers/tracker/TrackerSite/TrackerSiteList";

const Goals = lazy(() => import(`../containers/dashboard/goals/Goals`));
const FieldSettings = lazy(() => import(`../components/fieldSettings`));
const FunnelViewLead = lazy(() => import(`../containers/lead/funnel/funnelView`));
const FlowList = lazy(() => import(`../components/marketing/chatBot/FlowList`));
const FlowView = lazy(() => import(`../components/marketing/chatBot/FlowView`));
const BPlist = lazy(() => import(`../components/bp/BPlist`));
const BPview = lazy(() => import(`../components/bp/funnelView`));
const KnowledgeBaseList = lazy(() => import(`../containers/knowledgeBase/List`));
const KnowledgeBaseView = lazy(() => import(`../containers/knowledgeBase/View`));
const KnowledgeBaseEdit = lazy(() => import(`../containers/knowledgeBase/Edit`));
const KnowledgeBaseCategory = lazy(() => import(`../containers/knowledgeBase/Category`));
const ReportAccount = lazy(() => import(`../containers/account/report`));
const ReportContact = lazy(() => import(`../containers/contacts/report`));
const ReportOpportunity = lazy(() => import(`../containers/opportunity/report`));
const ReportLead = lazy(() => import(`../containers/lead/report`));
const ReportOrder = lazy(() => import(`../containers/order/report`));
const ReportProduct = lazy(() => import(`../containers/products/report`));
const ReportInvoice = lazy(() => import(`../containers/invoice/report`));
const ReportSource = lazy(() => import(`../containers/dashboard/source`));
const ForEmplList = lazy(() => import(`../containers/project/ForEmployees/ForEmplList`));
const Dashboard = lazy(() => import(`../containers/dashboard/index`));
const Stock = lazy(() => import(`../containers/stock/index`));
const Shipment = lazy(() => import(`../containers/stock/shipment/Shipment`));
const Replanish = lazy(() => import(`../containers/stock/replanish/Replanish`));
const Transfer = lazy(() => import(`../containers/stock/transfer/Transfer`));
const PriceList = lazy(() => import(`../containers/stock/priceBook/PriceList`));
const StockList = lazy(() => import(`../containers/stock/Stock/StockList`));
const Import = lazy(() => import(`./import`));
const Sales = lazy(() => import(`../containers/dashboard/sales`));
const UserDetail = lazy(() => import(`../containers/dashboard/userDetail`));
const UserList = lazy(() => import(`../containers/dashboard/userList`));
const ImportList = lazy(() => import(`./importList`));
const PurchaseView = lazy(() => import(`../containers/stock/purchase/PurchaseView`));
const Stocktake = lazy(() => import(`../containers/stock/stocktake/Stocktake`));
const Consumption = lazy(() => import(`../containers/stock/consumption/Consumption`));
const ForEmployees = lazy(() => import(`../containers/project/ForEmployees/ForEmployees`));
const EmployeesStageView = lazy(() => import(`../containers/project/ForEmployees/StageView`))
const Manufacture = lazy(() => import(`../containers/dashboard/manufacture`));
const Settings = lazy(() => import(`../containers/settings/settings`));
const FinanceList = lazy(() => import(`../containers/finance/FinanceList`));
const MessengersSendingResult = lazy(() => import(`../components/other/MessengersSendingResult`));
const UastalImport = lazy(() => import(`../components/only-uastal/import`));
const Pay = lazy(() => import(`../containers/settings/tab/pay`));
const TurnoverList = lazy(() => import(`../components/other/TurnoverList`));
const Remains = lazy(() => import(`../containers/stock/remains`));
const CheckboxView = lazy(() => import(`../components/other/Checkbox/CheckboxView`));
const ReportExpense = lazy(() => import(`../containers/expenses/report`));
const WorkOrder = lazy(() => import(`../containers/stock/workorder/WorkOrder`));
const EventCalendar = lazy(() => import(`../containers/event/EventCalendar`));
const BotConstructor = lazy(() => import(`../components/marketing/chatBot/BotConstructor/BotConstructor`));
const EmailMarketing = lazy(() => import(`../components/marketing/EmailMarketing`));
const EventView = lazy(() => import(`../containers/event/EventView`));
const SmsSendingResult = lazy(() => import(`../components/other/SmsSendingResult`));
const FormBuilder = lazy(() => import(`../components/other/FormBuilder/FormBuilder`));
const LeadKanbanConfig = lazy(() => import(`../containers/lead/LeadKanbanConfig`));
const EmailSendingResult = lazy(() => import(`../components/other/EmailSendingResult`));
const EmailCampaignView = lazy(() => import(`../components/other/EmailCampaign`));
const ProviderReturn = lazy(() => import(`../containers/stock/providerReturn/providerReturn`));
const BusinessRules = lazy(() => import(`../components/BusinessRules/BusinessRules`));
const AddBroadcastEmail = lazy(() => import(`../components/marketing/AddBroadcastEmail`));
const RfmNav = lazy(() => import(`../components/marketing/rfmNav`));
const Segments = lazy(() => import(`../components/Segments`));
const UserView = lazy(() => import(`../components/marketing/chatBot/UserView`));
const Marketing = lazy(() => import(`../components/marketing/index`));
const MsNav = lazy(() => import(`../components/marketing/messengerNav`));

let fullSub = window.location.host;
let partsSub = fullSub.split('.');


// (function(d, w, c) {
//     w.ChatraID = 'XmZ5a2i5Gim3X9Eko';
//     var s = d.createElement('script');
//     w[c] = w[c] || function() { (w[c].q = w[c].q || []).push(arguments)};
//     s.async = true;
//     s.src = 'https://call.chatra.io/chatra.js';
//     if (d.head) d.head.appendChild(s);
// })(document, window, 'Chatra');
// window.ChatraSetup = {disabledOnMobile: true, colors: {buttonBg: '#669295'},
//     clientId: window.location.host
// };

var focus = true;
var title = "";

var timerId;
var titleDefault = document.title;

function setTitle(text){
    timerId = setTimeout(function tick() {
        if(String(document.title) === String(titleDefault)) document.title = text
        else document.title = titleDefault
        timerId = setTimeout(tick, 1000)
    }, 1000)
}


window.onblur = () => { focus = false; title = document.title };
window.onfocus = () =>  { focus = true; clearTimeout(timerId); document.title = titleDefault};



let burger = localstorageSettings("get", "burger");
function toggleMenu() {
    let a = document.body;
    if(a.classList.contains("stic")){
        a.removeAttribute("class", "stic");
        localstorageSettings("post", "burger", false)
    } else {
        a.setAttribute("class", "stic");
        localstorageSettings("post", "burger", true)

    }
}
let b = document.getElementById("right-modal");
if(localstorageSettings("get", "burger") === true){
    let bd = document.body;
    bd.setAttribute("class", "stic")
}

/*if(b.classList.contains("r-open")){
    bd.style.overflow = "hidden";
}*/

export const GlobalState = React.createContext();

class ErrorBoundary extends React.Component{
    state = {
        hasError: false
    };

    componentDidCatch(error, errorInfo) {
        this.setState({ hasError: true, error, errorInfo });
        if(window.location.host !== "localhost:3000") {
            createError({
                error: JSON.stringify({
                    error,
                    errorInfo,
                    pathname: window.location.pathname
                }),
                user_id: this.props.userId + "" || "1",
                user_name: "name: " + this.props.userName
            }).then();
        }
    }

    render() {
        if(this.state.hasError && window.location.host !== 'localhost:3000') {
            return <div className="page errorComp">
                <h1>Ошибка сервера</h1>
                <p>Пожалуйста, обновите страницу. Если ошибка остается, напишите в техническую поддержку. Просим извинения за неудобства. </p>
                <a onClick={() => {window.history.back(); setTimeout(() => document.location.reload(true), 50)}}>Вернуться</a>
            </div>
        } else return this.props.children;
    }
}

class Layout extends React.Component {
    state = {
        navigation: [],
        notif: 'none',
        text: '',
        sel: '',
        open: '',
        id: null,
        display: 'none',
        classModal: '',
        notifications_state: false,
        notifications_email: false,
        has_unseen_messengers: false,
        userId: null,
        username:'',
        user:null,
        userRole: null,
        wsChat:null,
        wsChatRequest:null,
        wsChatNotif:false,
        sound: localstorageSettings("get", "sound"),
        burgerMenu: localstorageSettings("get", "burger"),
        phone:true,
        sms:true,
        email:true,
        baseCurrency:{},
        bgcall:false,
        callObj:{type: '', arr:[], url: '', entities:{}},
        allowed_file_extensions: '',
        allowed_file_max_size: Number,
        notifSocket:null,
        notifObject:'',
        integrations: {
            checkbox: true
        },
        nav:true,
        stepIndex:0,
        steps:null,
        isSupport: false,
        cloud: false,
        education: false,
        educationJSON:"{}",
        isPayed: false,
        modalType: '',
        modalData: null,
        updateBackgroundData: false,
        openChats: []
    };
    notifyMe (arr) {
        let notification = new Notification (arr.title, {
            tag : "ache-mail",
            body : arr.message,
            icon : "https://sboxcrm.com/images/notify.png"
        });
        notification.onclick = function () {
            window.open(arr.url, '_blank');
        };
        //toastr.success(arr.message, arr.title)
    }
    notifSet = (arr) => {
        arr = arr || null;
        if (!("Notification" in window))
            alert ("Ваш браузер не поддерживает уведомления.");
        else if (Notification.permission === "granted" && arr !== null)
            setTimeout(() => this.notifyMe(arr), 500);
        else if (Notification.permission !== "denied") {
            Notification.requestPermission (function (permission) {
                if (!('permission' in Notification))
                    Notification.permission = permission;
                if (permission === "granted" && arr !== null)
                    setTimeout(() => this.notifyMe(arr), 500);
            });
        }
    }

    loadData = () =>{
        myaxios.get(`/ajax/settings/state`, {method: 'get',}).then((responce) =>{
            if(responce.response && responce.response.data && responce.response.data.name === "Subscription Error"){
                this.setState({ isPayed: false })
                return;
            }

            if(responce.data.has_unseen_notifications >= 1) {this.setState({ notifications_state: true })}
            if(responce.data.has_unseen_mails >= 1) {this.setState({ notifications_email: true })}
            if(responce.data.has_unseen_messengers >= 1) {this.setState({ has_unseen_messengers: true })}
            let steps = responce.data.wizard ? JSON.parse(responce.data.wizard) : null;
            if(!steps) steps = {adminEducate:{"about-crm":true}, isStartEducate: false, isFirstModal: false}

            const integrations = JSON.parse(JSON.stringify(this.state.integrations));
            integrations.checkbox = responce.data.checkbox;
            integrations.delivery = (responce.data.integrations.delivery == 1);
            integrations.mail = responce.data.integrations && +responce.data.integrations.mail === 1;
            integrations.sms = responce.data.integrations && +responce.data.integrations.sms === 1;

            this.setState({
                navigation: responce.data.menu,
                userId: (responce.data.user) ? responce.data.user.id : 1,
                username: (responce.data.user) ? responce.data.user.name : null,
                user: responce.data.user,
                userRole: (responce.data.user) ? responce.data.user.role.name : "",
                baseCurrency: responce.data.baseCurrency || null,
                wsChatNotif: responce.data.has_unread_messages >= 1,
                allowed_file_extensions: responce.data.allowed_file_extensions,
                allowed_file_max_size: responce.data.allowed_file_max_size,
                email: responce.data.has_mailboxes,
                sms: (responce.data.integrations.sms === "1"),
                phone: (responce.data.integrations.telephony === "1"),
                steps: steps,
                integrations,
                // isPayed: responce.data.is_paid
                isPayed: true
            });
            this.notifySocketConnected((responce.data.user) ? responce.data.user.id : null);
            this.conectSocket((responce.data.user) ? responce.data.user.id : null);
            console.log("error11111111")
        }).catch(error => {
            // console.log("error11111111", error.response.data)
        })
    };

    //wsChat = new WebSocket(`wss://new.sboxcrm.com/socket.io:2021?user=${this.state.userId}`);

    notifySocketConnected = id => {
        let ws;
        var full = window.location.host
        var parts = full.split('.');
        var sub = parts[0]
        if(sub == "localhost:3000") sub = "dev";
        ws = new WebSocket(`wss://${sub}.sboxcrm.com/wss:8010?user=${this.state.userId || 1}`);
        this.setState({ notifSocket: ws })
        ws.onmessage = (evt) =>{
            let parsed = JSON.parse(evt.data);
            console.log("evt",evt);
            console.log("parsed",parsed);
            setTimeout(Notif("notify", parsed.title));
            if(parsed.type) {
                if(parsed.type === "binotel_call"){
                    this.setState({ bgcall: true, callObj: {type: "new", entities: parsed.entities, client_number: parsed.client_number, has_matches: parsed.has_matches} })
                    if(!focus) setTitle("Входящий звонок")
                } else if(parsed.type === "binotel_call_completed"){
                    this.setState({ bgcall: true, callObj: {type: "end", url: parsed.url, has_matches: true} })
                } else if(parsed.type === "new_message"){
                    this.setState({ notifications_email: true })
                    if(!focus) setTitle("Новый Email")
                }  else if(parsed.type === "regular_notif"){
                    this.setState({ notifications_state: true })
                    if(!focus) setTitle("Новое уведомление")
                } else if(parsed.type === "messengers"){
                    this.setState({ id: parsed, has_unseen_messengers: true })
                    if(!focus) setTitle("Новое сообщение")
                } else if(parsed.type === "export_started" || parsed.type === "export_failed" || parsed.type === "export_done") {
                    this.setState({ updateBackgroundData: !this.state.updateBackgroundData })
                }
            } else {
                this.setState({ notifications_state: true })
            }
            this.notifSet(parsed);
        };
        ws.onopen = function() {
            console.log('opened', new Date());
        };
        ws.onclose = () => {
            console.log('closed', new Date());
            if(this.state.userId) setTimeout(this.notifySocketConnected(this.state.userId), 5000)
            //new WebSocket(`wss://new.sboxcrm.com/wss:8010?user=${this.state.userId}`)
        }
    }

    componentDidMount(){
        this.loadData();
    }
    conectSocket = (id) => {
        var full = window.location.host;
        var parts = full.split('.');
        var sub = parts[0];
        if(sub == "localhost:3000") sub = "dev";
        this.setState({ wsChat: new WebSocket(`wss://${sub}.sboxcrm.com/socket.io:2021?user=${id || 1}`) })

        this.state.wsChat.onopen = () => {
            console.log('connected', new Date())
        }

        this.onMsg();

        this.state.wsChat.onclose = () => {
            console.log('disconnected', new Date());
            // this.setState({
            //     wsChat: new WebSocket(`wss://new.sboxcrm.com/socket.io:2021?user=${id}`),
            // })
            if(this.state.userId) setTimeout(this.conectSocket(this.state.userId), 5000)
        }
    }


    onMsg = () => {
        this.state.wsChat.onmessage = evt => {
            const message = JSON.parse(evt.data);
            //this.setState({ wsChatRequest: evt.data })
            console.log(message);
            if(message.type === "message"){
                if(!focus) setTitle("Новое сообщение")
                this.setState({ wsChatNotif:true });
                Notif("message", message.data.message)
            }
        }
    }
    updateData = (value, id, classModal) => { this.setState({ sel: value, open:"r-open", id: id, classModal: classModal}) };
    popupClose = () => { this.setState({open: "", sel: "", classModal: '', id:null}) };
    clearLayout = () => { this.setState({ nav: !this.state.nav }) }
    setIndex = () => {
        if("/contact"){
            setTimeout(() => this.setState({ stepIndex: 1 }), 500)
        }
    }

    sendMailAboutNewLevel = (level) => {
        myaxios.post(`/ajax/site/send-email`, {
            body: `Пользователь ${window.location.host} повысил уровень на ${level}.`,
            subject:"Начисление баллов в CRM",
            to:"techua.com@gmail.com"
        })
    }

    setSteps = (key, data, type) => {
        let prevData = this.state.steps;
        if(type && (this.state.userId == this.state.steps.adminUserId)){

            let allItems = JSON.parse(JSON.stringify(outItems)).map((el) => (({...el, completed: (this.state.steps.adminEducate[el.key]) || false})))

            if(!prevData.adminEducate) prevData.adminEducate = {}
            if(!prevData.adminEducate[key] && prevData.adminEducate[key] !== data && (allItems[allItems.findIndex(el => el.key == key) - 1].completed)){

                prevData.adminEducate[key] = data;
                this.setState({ steps: prevData })
                putWizard(prevData)

                Notif("notify","Задание выполнено! Следующий урок открыт для просмотра.")

                if(key === "first-level-completed" || key === "second-level-completed" || key === "third-level-completed") {
                    this.sendMailAboutNewLevel(key);
                    Notif("notify","Раздел пройден. В ближайшее время вам будут начислены 7 дней к тарифному плану.")
                }
            }
        } else {
            if(prevData[key] !== data){
                prevData[key] = data
                putWizard(prevData)
                this.setState({ steps: prevData })
            }
        }
    }

    addTest = () => {
        putWizard('')
    }

    render(){
        if(!this.state.userId) return <Loader />
        return <Suspense fallback={<Loader />}><main className={!this.state.nav && "clearLayout"}>
            {this.state.integrations.checkbox && <CheckboxLogin isOpen={false} />}
            {this.state.steps !== null &&
                <JoyrideMain
                    userId={this.state.userId}
                    stepIndex={this.state.stepIndex}
                    steps={this.state.steps}
                    setSteps={this.setSteps}
                    setEducated={() => this.setState({ education: true })}
                />}
            <header>
                {/*<button onClick={this.addTest}>132312</button>*/}
                    <div className="navbar-header">
                        <a className="navbar-brand">
                            <i className="flaticon-network" />
                            <img src={logo} className="light-logo"  alt={"space box crm"}/>
                        </a>
                    </div>
                    <div className="nav-right">
                        <a className="sidebar-but" onClick={toggleMenu}><i className="flaticon-menu-button" /></a>
                        <div className="icon-right">
                            {
                                window.location.host === 'localhost:3000' || window.location.search === '?test=test' ? <button onClick={ApiTests}>StartTests</button> : ''
                            }
                            {/*<WorkTime />*/}
                            {/*<div className="header-search">
                                <input type="text" placeholder="Поиск" />
                                <a href="#"><i className="flaticon-search" /></a>
                            </div>*/}
                            {/*<div className="toogle-bottom">
                                <a><i className="flaticon-add-circular-button" /></a>
                                <ul className="toogle-list">
                                    <li><a onClick={() => {this.setState({sel: "AddTask", open: "r-open"}) }}><i className="flaticon-list-3" /> <b>Задача</b></a></li>
                                    <li><a onClick={() => {this.setState({sel: "AddContact", open: "r-open"}) }} href={null}><i className="flaticon-user" /> <b>Контакт</b></a></li>
                                    <li><a onClick={() => {this.setState({sel: "AddAccount", open: "r-open"}) }} href={null}><i className="flaticon-users" /> <b>Контрагент</b></a></li>
                                    <li><a onClick={() => {this.setState({sel: "AddLead", open: "r-open"}) }} href={null}><i className="flaticon-user-ok" /> <b>Лид</b></a></li>
                                    <li><a onClick={() => {this.setState({sel: "AddOpportunity", open: "r-open"}) }} href={null}><i className="flaticon-share" /> <b>Сделка</b></a></li>
                                    <li><a onClick={() => {this.setState({sel: "AddProject", open: "r-open"}) }} href={null}><i className="flaticon-map" /> <b>Проект</b></a></li>
                                    <li><a onClick={() => {this.setState({sel: "AddInvoice", open: "r-open"}) }}><i className="flaticon-list" /> <b>Счет</b></a></li>
                                    {this.state.email ? <li><a onClick={() => {this.setState({sel: "SendMail", open: "r-open"}) }}><i className="flaticon-email" /> <b>Письмо</b></a></li> : null}
                                    {this.state.sms ? <li><a onClick={() => {this.setState({sel: "SendSms", open: "r-open"}) }}><i className="flaticon-chat-1" /> <b>SMS сообщение</b></a></li> : null}
                                    <li><a onClick={() => {this.setState({sel: "Product", open: "r-open"}) }}><i className="flaticon-open-box" /> <b>Продукт</b></a></li>
                                    <li><a onClick={() => {this.setState({sel: "AddDocument", open: "r-open"}) }}><i className="flaticon-interface-9" /> <b>Документ</b></a></li>
                                    <li><a onClick={() => {this.setState({sel: "AddUser", open: "r-open"}) }}><i className="flaticon-user-ok" /> <b>Сотрудник</b></a></li>
                                </ul>
                            </div>*/}
                            <EducateProgress savedUser={this.state.steps && this.state.steps.adminUserId} userId={this.state.userId} onClick={() => this.setState({ education: true })} steps={this.state.steps && this.state.steps.adminEducate} />
                            {this.state.integrations.checkbox && <a onClick={() => {this.setState({ checkbox: true }) }}><i className="flaticon-interface-1" /></a>}
                            <a onClick={() => {this.setState({sel: "Call", open: "r-open"}) }}><i className="flaticon-support" /></a>
                            {/*<a onClick={() => {localstorageSettings("post", "sound", !this.state.sound); this.setState({ sound: !this.state.sound })} }
                               data-tooltip={this.state.sound ? "Выключить звук" : "Включить звук"} ><img className="spImg" src={(this.state.sound) ? speakerOn : speakerOff} /></a>*/}
                            <a className={(this.state.notifications_email) ? "icon-action" : null} onClick={() => {this.setState({sel: "AddEmailBox", open: "r-open", notifications_email: false}) }}><i className="flaticon-email" /></a>
                            {/*<a className={(this.state.wsChatNotif) ? "icon-action" : null} onClick={() => {this.setState({sel: "MessageList", open: "r-open", id: {wsChat: this.state.wsChat, request: this.state.wsChatRequest, userId: this.state.userId}, wsChatNotif: false }) }}><i className="flaticon-speech-bubble-1"/></a>
                            <a className={(this.state.has_unseen_messengers) ? "icon-action" : null} onClick={() => {this.setState({sel: "MessagersList", open: "r-open", id: this.state.notifSocket, has_unseen_messengers: false }) }}><i className="flaticon-chat-1"/></a>*/}
                            <a onClick={() => this.setState({ msgList: true })}><i className="flaticon-chat-1" /></a>
                            <a onClick={() => {
                                this.setState({sel: "Notifications", open: "r-open", notifications_state: false})
                            }} className={(this.state.notifications_state) ? "icon-action" : null} href={null}><i className="flaticon-music-2" /></a>
                            <SettingsModal exit={() => {
                                this.props.exit()
                                window.location.reload()
                            }}
                            openBackgroundProcess={() => this.setState({ updateBackgroundData: !this.state.updateBackgroundData })}
                            />
                        </div>
                    </div>
                </header>
            <MessengersList
                notifSocket={this.state.notifSocket}
                msgList={this.state.msgList}
                close={() => this.setState({ msgList: false })}
                updateBackgroundData={this.state.updateBackgroundData}
                history={this.props.history}
                openChats={this.state.openChats}
                setOpenChats={(chats) => this.setState({ openChats: chats })}

            />
            <aside className="sidebar scrollbar">
                <div className="user-info">
                    <img src="../img/profile.png" alt="" />
                        <div className="user-sb-name">
                            <div className="toogle-bottom" style={{display: "inherit"}}>
                                <a onClick={() => this.setState({ display: (this.state.display !== "block") ? "block" : "none" })}>{this.state.username}</a>
                                {/*<ul style={{top: "30px", width: "95%", display: this.state.display }} className="toogle-list">
                                    <li><NavLink onClick={() => this.setState({ display: "none" })} to="/settings"><i className="flaticon-cogwheel-1" /> <b>Настройки</b></NavLink></li>
                                    <li><a onClick={() => this.setState({ cloud: true })} ><i className="flaticon-multimedia" /> <b>Облако</b></a></li>
                                    <li><a onClick={() => {this.props.exit(); setTimeout(() => window.location.reload(), 1000) }} href={null}><i className="flaticon-logout" /> <b>Выйти</b></a></li>
                                </ul>*/}
                            </div>
                        </div>
                </div>

                <nav className="navbar">
                    <ul>
                        {/*<li onClick={() => this.setSteps("print", false, true)}><a>11111111111</a></li>*/}
                        {/*<li onClick={() => putWizard(null)}><a>testNewConnect</a></li>*/}
                        {/*<li onClick={() => this.setState({ education: true })}><a>education</a></li>*/}
                        {this.state.navigation[0] && this.state.navigation.filter(el => el.url[0] !== "/expense1").map((navig, index) =>
                                <li onClick={() => this.setIndex(navig.url[0])} className={navig.url[0].slice(1)} key={index}><NavLink to={navig.url[0]}>
                                    <i className={navig.data.icon} />
                                    <span>{navig.label}</span>
                                    {navig.counter > 0 && <b data-tooltip={navig.label + " без ответственного"} />}
                                    {/*(navig.items && navig.items.length > 0) ? <em>▼</em> : null*/}</NavLink>
                                    {/*{(navig.items) ? <div className="submenu">
                                            {navig.items.map((submenu, index) =>
                                                    <li key={index}><NavLink to={`${submenu.url[0]}`}>{(submenu.label) ? submenu.label.slice(0,20) : null}</NavLink></li>
                                                )}
                                        </div> : null}*/}
                                </li>
                            )}
                        {/*<li className="navbar__li-bottom">
                            <NavLink to={'/testprod'}>test</NavLink>
                        </li>*/}
                        <li className="navbar__li-bottom" onClick={() => this.setState({ isSupport: true })}><a><i className={"flaticon-questions-circular-button"} /><span>Поддержка CRM</span></a></li>
                    </ul>
                </nav>
            </aside>

            {this.state.isPayed ? <GlobalState.Provider value={{ state: this.state, setSteps: this.setSteps, setState: (key, data) => this.setState({ [key]: data }) }}>
                {this.state.education && <Education
                    isOpen={this.state.education}
                    close={() => this.setState({ education: false })}
                    userId={this.state.userId}
                    allSteps={this.state.steps}
                    setSteps={this.setSteps}
                />}
                <ErrorBoundary userName={this.state.username} userId={this.state.userId}>
                    <TransitionGroup className="transition-group-screen">
                    <CSSTransition
                        key={this.props.location}
                        timeout={{ enter: 200, exit: 200 }}
                        classNames="slide"
                    >
            {this.state.userId && <Switch>
                <Route path="/test" render={(props) => <Test userId={this.state.userId} role={this.state.userRole} {...props} updateData={this.updateData} />}  />
                <Route path="/test2" render={(props) => <Test2 userId={this.state.userId} role={this.state.userRole} {...props} updateData={this.updateData} />}  />
                <Route path="/feed" render={(props) => <FeedList userId={this.state.userId} role={this.state.userRole} {...props} updateData={this.updateData} />}  />

                <Route path="/payments" render={(props) => <ListComponent name="payments" userId={this.state.userId} {...props} updateData={this.updateData} />}  />
                <Route path="/settings/segment/:id" render={(props) => <Segments userId={this.state.userId} {...props} updateData={this.updateData} />}  />
                <Route path="/settings/segment" render={(props) => <ListComponent name="segments" userId={this.state.userId} {...props} updateData={this.updateData} />}  />

                <Route path="/marketing/process/:id" render={(props) => <BusinessProcessNew {...props} updateData={this.updateData} />}  />
                <Route path="/marketing/processbuilder/:id" render={(props) => <BPview {...props} updateData={this.updateData} />}  />
                <Route path="/marketing/processbuilder/" render={(props) => <BPlist {...props} updateData={this.updateData} />}  />
                <Route path="/marketing/process/" render={(props) => <BpListNew {...props} updateData={this.updateData} />}  />

                <Route path="/businessrule/:id" render={(props) => <BusinessRules {...props} />} />
                <Route path="/businessrule" render={(props) => <ListComponent name='rules' {...props} updateData={this.updateData} />} />

                <Route path="/marketing/messengers/builder/:id" render={(props) => <BotConstructor name='rules' {...props} updateData={this.updateData} />} />
                <Route path="/marketing/messengers/flows/:id" render={(props) => <FlowView {...props} updateData={this.updateData} />}  />
                <Route path="/marketing/messengers/flows" render={(props) => <FlowList {...props} updateData={this.updateData} />}  />
                <Route path="/marketing/messengers/settings" render={(props) => <FunnelViewLead {...props} updateData={this.updateData} />}  />
                <Route path="/marketing/messengers/users/:id" render={(props) => <UserView name="mssubscribe" {...props} updateData={this.updateData} />}  />
                <Route path="/marketing/messengers/users" render={(props) => <ListComponent name="mssubscribe" {...props} updateData={this.updateData} />}  />
                <Route path="/marketing/messengers/broadcast/:id" render={(props) => <MessengersSendingResult {...props} updateData={this.updateData} />}  />
                <Route path="/marketing/messengers/broadcast" render={(props) => <ListComponent name="messengersSending" {...props} updateData={this.updateData} />}  />
                <Route path="/marketing/messengers" render={(props) => <MsNav {...props} updateData={this.updateData} />}  />
                <Route path="/marketing/rfm" render={(props) => <RfmNav {...props} updateData={this.updateData} />}  />
                <Route path="/marketing/sms/:id" render={(props) => <SmsSendingResult {...props} updateData={this.updateData} />}  />
                <Route path="/marketing/sms" render={(props) => <ListComponent name="smsSending" {...props} updateData={this.updateData} />}  />

                <Route path="/marketing/mail/broadcast/email" render={(props) => <AddBroadcastEmail {...props} updateData={this.updateData} />}  />
                <Route path="/marketing/mail/broadcast/:id" render={(props) => <EmailSendingResult {...props} updateData={this.updateData} />} />
                <Route path="/marketing/mail/broadcast" render={(props) => <ListComponent name="emailBroadcast" {...props} updateData={this.updateData} />} />

                <Route path="/marketing/mail/campaigns/:id" render={(props) => <EmailCampaignView {...props} updateData={this.updateData} />} />
                <Route path="/marketing/mail/campaigns" render={(props) => <ListComponent name="emailCampaigns" {...props} updateData={this.updateData} />} />
                <Route path="/marketing/mail" render={(props) => <EmailMarketing {...props} updateData={this.updateData} />}  />

                <Route path="/marketing/forms/:id" render={props => <FormBuilder {...props} updateData={this.updateData} clearLayout={this.clearLayout} clearLayoutState={this.state.nav} />} />
                <Route path="/marketing/forms" render={(props) => <ListComponent name="emailForms" {...props} updateData={this.updateData} />} />

                <Route path="/marketing" render={(props) => <Marketing {...props} updateData={this.updateData} />}  />

                <Route path="/expense/report" userId={this.state.userId} render={(props) => <ReportExpense baseCurrency={this.state.baseCurrency ? this.state.baseCurrency.text_symbol : ''} {...props} updateData={this.updateData} />}  />
                <Route path="/expense/:id" render={(props) => <ExpenseView {...props} updateData={this.updateData} />}  />
                {/*<Route path="/expense/" render={(props) => <ListComponent name="Expense" {...props} updateData={this.updateData} />}  />*/}
                <Route path="/expense/" render={(props) => <FinanceList name="Expense" {...props} updateData={this.updateData} />}  />
                <Route path="/finance/" render={(props) => <FinanceListNew userId={this.state.userId} name="Expense" {...props} updateData={this.updateData} />}  />

                <Route path="/event/view/:id" render={(props) => <EventView {...props} updateData={this.updateData} />}  />
                <Route path="/event/calendar" render={(props) => <EventCalendar userId={this.state.userId} name="event" {...props} updateData={this.updateData} />}  />
                <Route path="/event/list" render={(props) => <ListComponent name="event" {...props} updateData={this.updateData} />}  />

                <Route path="/fieldsettings/:id" render={(props) => <FieldSettings userId={this.state.userId} {...props} updateData={this.updateData} />}  />
                /* Рабочий стол */
                <Route path="/analytics/sales"  render={(props) => <Sales userId={this.state.userId} baseCurrency={this.state.baseCurrency ? this.state.baseCurrency.text_symbol : ''} userRole={this.state.userRole} updateData={this.updateData} />}  />
                <Route path="/analytics/manufacture" baseCurrency={this.state.baseCurrency ? this.state.baseCurrency.text_symbol : ''} userId={this.state.userId} render={(props) => <Manufacture userRole={this.state.userRole} updateData={this.updateData} />}  />
                <Route path="/analytics/users/:id" userId={this.state.userId} render={(props) => <UserDetail baseCurrency={this.state.baseCurrency ? this.state.baseCurrency.text_symbol : ''} userRole={this.state.userRole} updateData={this.updateData} />}  />
                <Route path="/analytics/users" userId={this.state.userId} render={(props) => <UserList baseCurrency={this.state.baseCurrency ? this.state.baseCurrency.text_symbol : ''} userRole={this.state.userRole} updateData={this.updateData} />}  />
                <Route path="/analytics/dashboard" userId={this.state.userId} render={(props) => <Dashboard baseCurrency={this.state.baseCurrency ? this.state.baseCurrency.text_symbol : ''} {...props} userRole={this.state.userRole} updateData={this.updateData} />}  />
                <Route path="/analytics/source" userId={this.state.userId} render={(props) => <ReportSource userRole={this.state.userRole} updateData={this.updateData} />}  />
                <Route path="/analytics/goals" render={(props) => <Goals userRole={this.state.userRole} updateData={this.updateData} />}  />
                <Redirect exact from='/analytics' to='/analytics/dashboard'/>

                <Route path="/case/:id" render={(props) => <CaseViewNew userId={this.state.userId} {...props} updateData={this.updateData} />}  />
                <Route path="/case" render={(props) => <ListCaseNew name="cases" userId={this.state.userId} {...props} updateData={this.updateData} />}  />

                /* Лиды */
                <Route path="/lead/report" userId={this.state.userId} render={(props) => <ReportLead userId={this.state.userId} baseCurrency={this.state.baseCurrency ? this.state.baseCurrency.text_symbol : ''} {...props} updateData={this.updateData} />}  />
                {/*<Route path="/lead/funnel" render={(props) => <FunnelList {...props} updateData={this.updateData} />}  />*/}
                <Route path="/lead/kanban" render={(props) => <LeadKanbanConfig userId={this.state.userId} {...props} updateData={this.updateData} />}  />
                <Route path="/lead/list" render={(props) => <NewLeadList name="lead" userId={this.state.userId} {...props} updateData={this.updateData} />}  />
                <Route path="/lead/:id" render={(props) => <LeadViewNew openEntity={this.updateData} {...props} updateData={this.updateData} />} />
                <Route path="/lead2/:id" render={(props) => <LeadViewNew openEntity={this.updateData} {...props} updateData={this.updateData} />} />
                <Redirect exact from='/lead/' to={(localstorageSettings("get", "leadView") === "calendar" ? "/lead/calendar" :
                    localstorageSettings("get", "leadView") === "list" ? "/lead/list" : "/lead/kanban" )}/>

                /* Сделки */
                <Route path="/opportunity/report" userId={this.state.userId} render={(props) => <ReportOpportunity userId={this.state.userId} baseCurrency={this.state.baseCurrency ? this.state.baseCurrency.text_symbol : ''} {...props} updateData={this.updateData} />}  />
                <Route path="/opportunity/list" render={(props) => <ListOpportunityNew userId={this.state.userId} {...props} updateData={this.updateData} />}  />
                <Route path="/opportunity/kanban" render={(props) => <OpportunityKanban {...props} updateData={this.updateData} />}  />
                <Route path="/opportunity/:id" render={(props) => <OpportunityViewNew openEntity={this.updateData} {...props} updateData={this.updateData} />} />
                <Redirect exact from='/opportunity/' to={(localstorageSettings("get", "opportunityView") === "calendar" ? "/opportunity/calendar" :
                    localstorageSettings("get", "opportunityView") === "list" ? "/opportunity/list" : "/opportunity/kanban" )}/>

                /* Заказы */
                <Route path="/order/report" userId={this.state.userId} render={(props) => <ReportOrder userId={this.state.userId} baseCurrency={this.state.baseCurrency ? this.state.baseCurrency.text_symbol : ''} {...props} updateData={this.updateData} />}  />
                <Route path="/order/:id" render={(props) => <OrderViewNew {...props} updateData={this.updateData} />}  />
                {/*<Route path="/order2/:id" render={(props) => <OrderView {...props} updateData={this.updateData} />}  />*/}
                <Route path="/order" render={(props) => <ListOrderNew userId={this.state.userId} {...props} updateData={this.updateData} />}  />
                <Route path="/order-cash-window" render={(props) => <CashWindow userId={this.state.userId} {...props} updateData={this.updateData} />}  />

                /* Задачи */
                <Route path="/task/view/:id" render={(props) => <TaskView {...props} updateData={this.updateData} />}  />
                <Route path="/task/calendar" render={(props) => <TaskCalendar {...props} updateData={this.updateData} />}  />
                <Route path="/task/list" render={(props) => <ListTaskNew name="task" userId={this.state.userId} {...props} updateData={this.updateData} />}  />
                <Route path="/task/kanban" render={(props) => <TaskKanbanConfig userId={this.state.userId} {...props} updateData={this.updateData} />}  />
                <Redirect exact from='/task/' to={(localstorageSettings("get", "taskView") === "calendar" ? "/task/calendar" :
                    localstorageSettings("get", "taskView") === "list" ? "/task/list" : "/task/kanban" )}/>

                /* Контрагент */
                <Route path="/account/rfm" userId={this.state.userId} render={(props) => <ListComponent name="rfmAccount" {...props} updateData={this.updateData} />} />
                <Route path="/account/report" userId={this.state.userId} render={(props) => <ReportAccount userId={this.state.userId} baseCurrency={this.state.baseCurrency ? this.state.baseCurrency.text_symbol : ''} {...props} updateData={this.updateData} />} />
                <Route path="/account2/:id" render={(props) => <AccountView {...props} baseCurrency={this.state.baseCurrency} updateData={this.updateData} />} />
                <Route path="/account/:id" render={(props) => <AccountViewNew {...props} baseCurrency={this.state.baseCurrency} updateData={this.updateData} />} />
                <Route path="/account" render={(props) => <ListAccountNew name="account" userId={this.state.userId} {...props} updateData={this.updateData} />}  />

                /* Контакт */
                <Route path="/contact/rfm" userId={this.state.userId} render={(props) => <ListComponent name="rfmContact" {...props} updateData={this.updateData} />} />
                <Route path="/contact/report" userId={this.state.userId} render={(props) => <ReportContact userId={this.state.userId} baseCurrency={this.state.baseCurrency ? this.state.baseCurrency.text_symbol : ''} {...props} updateData={this.updateData} />} />
                <Route path="/contact/:id" render={(props) => <ContactViewNew steps={this.state.steps} setSteps={this.setSteps} run={this.state.steps && !this.state.steps.contactView} baseCurrency={this.state.baseCurrency} {...props} updateData={this.updateData} />} />
                <Route path="/contact" render={(props) => <ListContactNew setStep={() => {
                    setTimeout(() => this.setState({ stepIndex: 2 }), 500)
                }} name="contact" userId={this.state.userId} {...props} updateData={this.updateData} />}  />

                /* Счет */
                <Route path="/invoice/report" render={(props) => <ReportInvoice userId={this.state.userId} baseCurrency={this.state.baseCurrency ? this.state.baseCurrency.text_symbol : ''} {...props} updateData={this.updateData} />}  />
                <Route path="/invoice/:id" render={(props) => <InvoiceViewNew checkbox={this.state.integrations.checkbox} baseCurrencyId={this.state.baseCurrency ? this.state.baseCurrency.id : ''} {...props} updateData={this.updateData} />}  />
                <Route path="/invoice" render={(props) => <InvoiceNewList name="invoice" userId={this.state.userId} {...props} updateData={this.updateData} />}  />
                <Route path="/invoice2" render={(props) => <ListComponent name="invoice" userId={this.state.userId} {...props} updateData={this.updateData} />}  />
                <Route path="/checkbox/receipt/:id" render={(props) => <CheckboxView userId={this.state.userId} {...props} updateData={this.updateData} />}  />
                <Route path="/invoice-cash-window" render={(props) => <InvoiceCashWindow name="invoice" userId={this.state.userId} {...props} updateData={this.updateData} />}  />


                /* Проект */
                <Route path="/project/work/:id" render={(props) => <StageView {...props} updateData={this.updateData} />} />
                <Route path="/project/:id" render={(props) => <ProjectView user={this.state.user} {...props} updateData={this.updateData} />} />
                <Route path="/project" render={(props) => <ProjectListOther name="project" userId={this.state.userId} {...props} updateData={this.updateData} />}  />
                <Route path="/work/:id/machine-plan/:id" render={(props) => <EmployeesStageView userId={this.state.userId} {...props} updateData={this.updateData} />} />
                <Route path="/employees/:id" render={(props) => <ForEmployees {...props} updateData={this.updateData} />} />
                <Route path="/employees/" render={(props) => <ForEmplList {...props} updateData={this.updateData} />} />

                /* Email */
                <Route path="/mail/:id" exact render={(props) => <Mail {...props} updateData={this.updateData} />}  />
                <Route path="/mail/:id/sent" exact render={(props) => <Mail isEmail={this.state.email} {...props} updateData={this.updateData} />}  />
                <Route path="/mail/:id/inbox" exact render={(props) => <Mail isEmail={this.state.email} {...props} updateData={this.updateData} />}  />
                <Route path="/mail/:id/deteled" exact render={(props) => <Mail isEmail={this.state.email} {...props} updateData={this.updateData} />}  />
                <Route path="/mail/:id/inbox/:id" render={(props) => <MailDetail {...props} updateData={this.updateData} />}  />
                <Route path="/mail/:id/sent/:id" render={(props) => <MailDetail {...props} updateData={this.updateData} />}  />
                <Route path="/mail/:id/deleted/:id" render={(props) => <MailDetail {...props} updateData={this.updateData} />}  />
                <Route path="/mail" render={(props) => <MailFunc {...props} updateData={this.updateData} />}  />

                /* Товар */
                <Route path="/product/report" userId={this.state.userId} render={(props) => <ReportProduct userId={this.state.userId} {...props} updateData={this.updateData} />}  />
                <Route path="/product/:id" render={(props) => <ProductViewNew {...props} updateData={this.updateData} />}  />
                <Route path="/product" render={(props) => <ListProductNew name="product" userId={this.state.userId} {...props} updateData={this.updateData} />}  />

                /* Документы */
                <Route path="/document/:id" render={(props) => <DocumentViewNew {...props} updateData={this.updateData} />}  />
                <Route path="/document" render={(props) => <ListDocumentNew name="document" userId={this.state.userId} {...props} updateData={this.updateData} />}  />

                <Route path="/settings" render={(props) => <Settings userRole={this.state.user ? this.state.user.role.name : ''} user={this.state.user} userId={this.state.userId} {...props} updateData={this.updateData} />}  />
                <Route path="/integration/" render={(props) => <IntegrationList {...props} updateData={this.updateData} />}  />

                <Route path="/stock/turnover" render={(props) => <TurnoverList userId={this.state.userId} {...props} updateData={this.updateData} />}  />
                <Route path="/stock/return/:id" render={(props) => <ProviderReturn {...props} updateData={this.updateData} />}  />
                <Redirect exact from='/stock/provider-return/:id' to='/stock/return/:id'/>

                <Route path="/stock/return" render={(props) => <ListProviderReturnNew name="stockReturn" userId={this.state.userId} {...props} updateData={this.updateData} />}  />
                <Route path="/stock/consumption/:id" render={(props) => <Consumption {...props} updateData={this.updateData} />}  />
                <Route path="/stock/consumption" render={(props) => <ListConsumptionNew name="consumption" userId={this.state.userId} {...props} updateData={this.updateData} />}  />
                <Route path="/stock/stocktake/:id" render={(props) => <Stocktake {...props} updateData={this.updateData} />}  />
                <Route path="/stock/stocktake" render={(props) => <ListStocktakeNew name="stocktake" userId={this.state.userId} {...props} updateData={this.updateData} />}  />
                <Route path="/stock/purchase/:id" render={(props) => <PurchaseView baseCurrency={this.state.baseCurrency} {...props} updateData={this.updateData} />}  />
                <Route path="/stock/purchase" render={(props) => <ListPurchaseNew name="purchase" userId={this.state.userId} {...props} updateData={this.updateData} />}  />
                <Route path="/stock/stocklist" render={(props) => <StockList {...props} updateData={this.updateData} />}  />
                <Route path="/stock/pricebook" render={(props) => <PriceList {...props} updateData={this.updateData} />}  />
                <Route path="/stock/transfer/:id" render={(props) => <Transfer {...props} updateData={this.updateData} />}  />
                <Route path="/stock/transfer" render={(props) => <ListTransferNew name="transfer" userId={this.state.userId} {...props} updateData={this.updateData} />}  />
                <Route path="/stock/replanish/:id" render={(props) => <Replanish {...props} updateData={this.updateData} />}  />
                <Route path="/stock/replanish" render={(props) => <ListReplanishNew name="replanish" userId={this.state.userId} {...props} updateData={this.updateData} />}  />
                <Route path="/stock/shipment/report" render={(props) => <ShipmentReport name="shipment" userId={this.state.userId} {...props} updateData={this.updateData} />}  />
                <Route path="/stock/shipment/:id" render={(props) => <Shipment {...props} updateData={this.updateData} />}  />
                <Route path="/stock/shipment" render={(props) => <ListShipmentNew name="shipment" userId={this.state.userId} {...props} updateData={this.updateData} />}  />
                <Route path="/stock/remains/:id" render={(props) => <Remains {...props} updateData={this.updateData} />}  />

                <Route path="/stock/workorder/:id" render={(props) => <WorkOrder {...props} updateData={this.updateData} />}  />
                <Route path="/stock/workorder" render={(props) => <ListComponent name="workorder" userId={this.state.userId} {...props} updateData={this.updateData} />}  />
                <Route path="/stock" render={(props) => <Stock {...props} updateData={this.updateData} />}  />

                {/* Склад новый */}
                    {/*<Route path="/stock2/consumption/:id" render={(props) => <Consumption2 {...props} updateData={this.updateData} />}  />*/}
                {/* Склад новый */}

                <Route path="/import/product" render={(props) => <Import updateData={this.updateData} type="product" label="продуктов" />}  />
                <Route path="/import/opportunity" render={(props) => <Import updateData={this.updateData} type="opportunity" label="сделок" />}  />
                <Route path="/import/order" render={(props) => <Import updateData={this.updateData} type="order" label="заказов" />}  />
                <Route path="/import/contact" render={(props) => <Import updateData={this.updateData} type="contact" label="контактов" />}  />
                <Route path="/import/account" render={(props) => <Import updateData={this.updateData} type="account" label="контрагентов" />}  />
                <Route path="/import/lead" render={(props) => <Import updateData={this.updateData} type="lead" label="лидов" />}  />
                <Route path="/import" render={(props) => <ImportList updateData={this.updateData} />}  />

                <Route path="/emailform/:id" render={(props) => <FormBuilder {...props} updateData={this.props.updateData} clearLayoutState={this.state.nav}  clearLayout={this.clearLayout} />} />

                <Route path="/knowledgeBase/category" render={(props) => <KnowledgeBaseCategory updateData={this.updateData} />}  />
                <Route path="/knowledgeBase/:id/edit" render={(props) => <KnowledgeBaseEdit updateData={this.updateData} />}  />
                <Route path="/knowledgeBase/:id" render={(props) => <KnowledgeBaseView updateData={this.updateData} />}  />
                <Route path="/knowledgeBase" render={(props) => <KnowledgeBaseList updateData={this.updateData} />}  />

                <Route path="/tracker/:id" render={(props) => <TrackerSettings { ...props} updateData={this.updateData} />}  />
                <Route path="/tracker" render={(props) => <TrackerSiteList { ...props} updateData={this.updateData} />}  />


                {/*only uastal*/}
                <Route path="/uastal-import" render={(props) => <UastalImport updateData={this.updateData} />}  />


                <Redirect exact from='/' to='/feed'/>
                <Route render={() => <div className="page"><h1 style={{color: "red", fontSize: "40px", fontWeight: "bold"}}>404 not found</h1></div>} />


            </Switch>}
                    </CSSTransition>
            </TransitionGroup>
                </ErrorBoundary>

            {this.state.integrations.checkbox && <CheckboxLogin close={() => this.setState({ checkbox: false })} isOpen={this.state.checkbox} />}

            {this.state.bgcall ? <AnswerCall close={() => this.setState({ bgcall: false })} callObj={this.state.callObj} /> : null}
            <div className="notif" id="notif" />
            <React.Fragment>
            <div id="scrollbar"><div id="right-modal" className={`${this.state.classModal} right-modal scrollbar ` + this.state.open}>
                <RightModal setStep={this.setSteps} {...this.props} sms={this.state.sms} updateData={this.updateData} phone={this.state.phone} email={this.state.email} title={this.state.sel} id={this.state.id} close={this.popupClose} onMsg={this.onMsg} /></div></div>
                {this.state.open ? <Bgdrop close={() => {this.popupClose(); this.onMsg()}} /> : null}
            </React.Fragment>

            <Cloud allowed_file_extensions={this.state.allowed_file_extensions} isOpen={this.state.cloud} close={() => this.setState({ cloud: false })} />
            <SupportModal isOpen={this.state.isSupport} close={() => this.setState({ isSupport: false })} />
            </GlobalState.Provider> : <div className="page"><Pay /></div>}
            </main></Suspense>
        /*updateData={this.updateData}*/
    }
}

export default withRouter(Layout);
