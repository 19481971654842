import React, {Component} from 'react';
import Input from '../../UI/Input';
import formValid from '../../UI/formValid';
import myaxios from '../../Axios';
import Myselect from '../../UI/Select';

export default class SendMail extends Component {
    state = {
        formControl: {
            name:{value:'',valid:true, validation:{required:true}},
            template:{value:'',valid:true, arr:[], validation:{required:true}},
            from:{value:'',arr:[],valid:true,validation:{required:true}},
            subject:{value:''},
            body:{value:''},
            time_value:{value:0},
        },
    };

    componentWillMount(){
        if(this.props.elArr){
            let formControl = this.state.formControl;
            formControl.name.value = this.props.elArr.metaData ? this.props.elArr.name : '';
            formControl.template.value = this.props.elArr.metaData ? this.props.elArr.metaData.template : '';
            formControl.from.value = this.props.elArr.metaData ? this.props.elArr.metaData.from : '';
            formControl.time_value.value = this.props.elArr.metaData ? this.props.elArr.time_value : '';
            this.setState({ formControl })
        }
    }
    componentDidMount(){
        this.getAccount();
        this.getTemplate();
    }
    getTemplate = () => {
        const formControl = this.state.formControl;
        myaxios.get('/ajax/template').then(res => {
            formControl.template.arr = res.data.items;
            this.setState({ formControl })
        })

    };
    getAccount = () => {
        const formControl = this.state.formControl;
        myaxios.get('/ajax/mail/account?filter[username][like]=').then(res => {
            formControl.from.arr = res.data.items;
            this.setState({ formControl })
        })
    };
    setField = (type, e) => {
        let formControl = this.state.formControl;
        if(type === "template"){
            for(let i = 0; i < formControl.template.arr.length; i++){
                if(e == formControl.template.arr[i].id){
                    formControl.subject.value = formControl.template.arr[i].subject;
                    formControl.body.value = formControl.template.arr[i].body;
                }
            }
        }
        formControl[type].value = e;
        this.setState({ formControl })
    };
    isFormValid = () => {
        let formControl = this.state.formControl;
        let isFormValid = true;
        Object.keys(formControl).map((key, index) => {
            formControl[key].valid = formValid(formControl[key].value, formControl[key].validation);
            isFormValid = formControl[key].valid && isFormValid
        });
        this.setState({formControl});
        return isFormValid;
    };
    sendForm = () => {
        if(this.isFormValid()){
            if(this.props.elArr){
                setTimeout(
                    this.props.updateNode({
                        name: this.state.formControl.name.value,
                        time_value: this.state.formControl.time_value.value,
                        is_scheduled: (this.state.formControl.time_value.value > 1),
                        metaData: {
                            from: this.state.formControl.from.value,
                            template: this.state.formControl.template.value,
                            subject:this.state.formControl.subject.value,
                            body:this.state.formControl.body.value
                        },
                        nodeType: "node",
                        type:"mail",
                    })
                )
            } else {
                setTimeout(
                    this.props.createNode({
                        name: this.state.formControl.name.value,
                        time_value: this.state.formControl.time_value.value,
                        is_scheduled: (this.state.formControl.time_value.value > 1),
                        metaData: {
                            from: this.state.formControl.from.value,
                            template: this.state.formControl.template.value,
                            subject:this.state.formControl.subject.value,
                            body:this.state.formControl.body.value
                        },
                        nodeType: "node",
                        type:"mail",
                        filter:this.state.filter
                    })
                )
            }
        }
    };
    render(){
        return <div>
            <div className="rmod-header">
                <b>Отправить Email</b>
                <a onClick={this.props.close} style={{ marginRight: "60px" }} className="mclose"><i className="flaticon-close" /></a>
                <div className="togglelist"><button onClick={this.sendForm} className="rmod-ok" disabled=""><i className="flaticon-interface-1" /></button></div>
            </div>
            <div className="mod-cont sbform">
                <Input
                    label={"Название"}
                    value={this.state.formControl.name.value}
                    valid={this.state.formControl.name.valid}
                    onChange={e => this.setField("name", e.target.value)}
                    shouldValidate={true}
                />
                <Myselect
                    empty
                    label="От"
                    options={this.state.formControl.from.arr}
                    value={this.state.formControl.from.value}
                    valid={this.state.formControl.from.valid}
                    onChange={e => this.setField("from", e.target.value)}
                    shouldValidate={true}
                    lab="username"
                    val="username"
                />
                <Myselect
                    empty
                    label="Шаблон письма"
                    options={this.state.formControl.template.arr}
                    value={this.state.formControl.template.value}
                    valid={this.state.formControl.template.valid}
                    shouldValidate={true}
                    onChange={e => this.setField("template", e.target.value)}
                    lab="name"
                    val="id"
                />
                <Input
                    tooltip="Процесс запустится через N секунд, после того, как сработает триггер"
                    type="number"
                    value={this.state.formControl.time_value.value}
                    onChange={e => this.setField("time_value", e.target.value)}
                    shouldValidate={false}
                    label="Отложенный запуск на N секунд"
                />
            </div>
        </div>
    }
}
