import React, {Component} from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import myaxios from '../../../components/Axios';
import {ATTACHED_NUMBER_TO_STRING, ATTACHED_NUMBER_TO_TEXT} from "../../../const";
import Loader from "../../../components/UI/Loader";

class Remainder extends Component {
    state = {
        allStocks:[],
        reserves:[],
        invoiceReserves: [],
        transfers:[],
        stocks:[],
        purchases:[],
        transactions:[],
        workOrdersTransactions: [],
        loading: true
    }
    showPrices = () => {
        myaxios.get(`/ajax/product/${this.props.id}/movements`, {method: 'get',
        }).then((response) => {
            this.setState({
                reserves: response.data.reserves,
                invoiceReserves: response.data.invoiceReserves,
                transfers: response.data.transfers,
                workOrdersTransactions: response.data.workOrdersTransactions
            })});

        myaxios.get(`/ajax/product/${this.props.id}/goods`, {method: 'get',
        }).then((response) => this.setState({
            stocks: response.data.stocks,
            purchases: response.data.purchases,
            transactions: response.data.transactions,
            loading: false
        }));
    }

    getStocks = () => {
        myaxios.get(`/ajax/stock`).then(res => {
            if(!res) return;
            if(res.status === 200 || res.status === 201) {
                this.setState({ allStocks: res.data.items }, this.showPrices)
            }
        })
    }

    componentDidMount(){
        this.getStocks()
    }
    render(){
    return this.state.loading ? <Loader /> : <div className="tab-content sbform formlabel">
        <div className="connection" style={{ marginTop: "0px"}}>
            <span>Остатки на складах</span>
        </div>
        <div className="sp-table body-table">
            <table style={{borderSpacing: "0"}} className="stagesTable">
                <thead>
                <tr>
                    <td>Склад</td>
                    <td>Остаток</td>
                    <td>Перемещения</td>
                    <td>Резерв</td>
                    <td>Доступно</td>
                </tr>
                </thead>
                <tbody>
                    {this.state.stocks.map((item,index)=>
                        <tr key={item.id}>
                            <td>{(item.stock) ? item.stock.name : null}</td>
                            <td>{Number(item.quantity) + Number(item.reserved)}</td>
                            <td>{item.transfers}</td>
                            <td>{item.reserved}</td>
                            <td>{item.transfers < 0 ? (Number(item.total) + Number(item.transfers) - Number(item.reserved)) : (Number(item.total) - Number(item.reserved))}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>

        <div className="clear"/>
        <div className="connection">
            <span>Поставки</span>
        </div>
        <div className="sp-table body-table">
            <table style={{borderSpacing: "0"}} className="stagesTable">
                <thead>
                <tr>
                    <td>Заказ</td>
                    <td>Склад</td>
                    <td>Кол-во</td>
                    <td>Дата поставки</td>
                </tr>
                </thead>
                <tbody>
                {this.state.purchases.map((item,index)=>
                    <tr key={item.id}>
                        <td><a onClick={() => this.props.history.push('/stock/purchase/' + item.id)}>{item.name}</a></td>
                        <td>{item.stock ? item.stock.name : null}</td>
                        <td>{item.total}</td>
                        <td>{item.purchase_date}</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>

        <div className="clear"/>
        <div className="connection">
            <span>Резерв в заказах</span>
        </div>
        <div className="sp-table body-table">
            <table style={{borderSpacing: "0"}} className="stagesTable">
                <thead>
                <tr>
                    <td>Заказ</td>
                    <td>Склад</td>
                    <td>Кол-во</td>
                    <td>Дата выполения</td>
                </tr>
                </thead>
                <tbody>
                {this.state.reserves.map((item,index)=>
                    <tr key={item.id}>
                        <td><a onClick={() => this.props.history.push('/order/' + item.id)}>{item.name}</a></td>
                        <td>{item.stock ? item.stock.name : null}</td>
                        <td>{item.total}</td>
                        <td>{item.end_date}</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>

        <div className="clear"/>
        <div className="connection">
            <span>Резерв в счетах</span>
        </div>
        <div className="sp-table body-table">
            <table style={{borderSpacing: "0"}} className="stagesTable">
                <thead>
                <tr>
                    <td>Счет</td>
                    <td>Склад</td>
                    <td>Кол-во</td>
                    <td>Дата оплаты</td>
                </tr>
                </thead>
                <tbody>
                {this.state.invoiceReserves.map((item,index)=>
                    <tr key={item.id}>
                        <td><a onClick={() => this.props.history.push('/invoice/' + item.id)}>{item.name}</a></td>
                        <td>{item.stock ? item.stock.name : null}</td>
                        <td>{item.total}</td>
                        <td>{item.payment_date}</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>


        <div className="clear"/>
        <div className="connection">
            <span>Перемещение</span>
        </div>
        <div className="sp-table body-table">
            <table style={{borderSpacing: "0"}} className="stagesTable">
                <thead>
                <tr>
                    <td>Заказ</td>
                    <td>Откуда</td>
                    <td>Куда</td>
                    <td>Кол-во</td>
                    <td>Дата поставки</td>
                </tr>
                </thead>
                <tbody>
                {this.state.transfers.map((item,index)=>
                    <tr key={item.id}>
                        <td><NavLink to={`/stock/transfer/${item.id}`}>{item.name}</NavLink></td>
                        <td>{item.from ? item.from.name : null}</td>
                        <td>{item.to ? item.to.name : null}</td>
                        <td>{item.itemsCount}</td>
                        <td>{item.date}</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>




        <div className="clear"/>
        <div className="connection">
            <span>Заказ на производство</span>
        </div>
        <div className="sp-table body-table">
            <table style={{borderSpacing: "0"}} className="stagesTable">
                <thead>
                <tr>
                    <td>Заказ</td>
                    <td>Кол-во</td>
                    <td>Склад</td>
                    <td>Ответственный</td>
                    <td>Дата поставки</td>
                </tr>
                </thead>
                <tbody>
                {this.state.workOrdersTransactions.map((item,index)=>
                    <tr key={item.id}>
                        <td>{item.name}</td>
                        <td>{item.itemsCount}</td>
                        <td>{item && item.stockin_id && this.state.allStocks.find(el => el.id == item.stockin_id) && this.state.allStocks.find(el => el.id == item.stockin_id).name}</td>
                        <td>{item.owner && item.owner.name}</td>
                        <td>{item.date}</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>




        <div className="clear"/>
        <div className="connection">
            <span>Операции</span>
        </div>
        <div className="sp-table body-table">
            <table style={{borderSpacing: "0"}} className="stagesTable">
                <thead>
                <tr>
                    <td>Тип операции</td>
                    <td>Дата</td>
                    <td>Кол-во</td>
                    <td>Склад</td>
                </tr>
                </thead>
                <tbody>
                {this.state.transactions && this.state.transactions.map((item,index)=>
                    <tr key={item.id}>
                        <td>
                            <NavLink to={`/${ATTACHED_NUMBER_TO_STRING[item.related_type]}/${item.related_id}`}>
                                {ATTACHED_NUMBER_TO_TEXT[item.related_type]}
                            </NavLink>
                        </td>
                        <td>{item.related_date}</td>
                        <td>{item.quantity}</td>
                        <td>{item.stock_id && this.state.allStocks.find(el => el.id === item.stock_id) && this.state.allStocks.find(el => el.id === item.stock_id).name}</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>

    </div>
}};

export default withRouter(Remainder);