import React, {useEffect, useState} from "react";
import Modal from "../Modal/Modal";
import ModAutoSelect from "../UI/ModAutoSelect";
import myaxios from "../Axios";
import Input from "../UI/Input";
import Notif from "../Notif";
import Loader from "../UI/Loader";

const types = type => {
    switch (type) {
        case "price": return {
            title: "Изменить прайс-лист",
            link: "/ajax/pricebook",
            label:"Выберите прайс-лист",
            result: "name"
        };
        case "discount": return {
            title: "Изменить скидку"
        };
        case "stock": return {
            title: "Изменить склад",
            link: "/ajax/stock/name?filter[name][like]=",
            label:"Выберите склад",
            result: "name"
        };
        case "status": return {
            title: "Изменить статус",
            link: "/ajax/lookup/search?type=ord_item_status",
            label:"Выберите статус",
            result: "value"
        };
    }
}

const FastActions = ({ products, type, entity, entityId, setType, updateProducts }) => {
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState("");
    const [discounts, setDiscounts] = useState({
        discount:"",
        discount_amount:""
    });
    const [obj, setObj] = useState(null);

    useEffect(() => {
        setValue("");
        setDiscounts({
            discount:"0",
            discount_amount:"0"
        })
        setObj(null)
    },[type, entityId, entity, products])

    const valid = () => {
        let validate = true;
        if(type === "discount"){
            if (Number(discounts.discount) < 1 && Number(discounts.discount_amount) < 1){
                validate = false
            }
        } else if(value.length < 1) {
            validate = false
        }
        return validate
    }

    const setAction = () => {
        if(!valid()){
            Notif("Fields");
            return false
        }
        setLoading(true)
        let obj = {};
        obj.items = products;
        if(type === "discount") obj.value = discounts
        else obj.value = value
        myaxios.post(`/ajax/${entity}/${entityId}/batch-action?type=${type}`, obj).then(res => {
            setLoading(false)
            if(!res) return;
            if(res.status === 200 || res.status === 201){
                updateProducts(res.data)
                setType()
            }
        }).catch(() => setLoading(false))
    }
    
    return loading ? <Loader contain /> : <Modal save={setAction} isOpen={type} close={setType} title={type && types(type).title}>
        {
            type === "discount" ?
                <div>
                    <Input
                        label="Скидка в валюте"
                        value={discounts.discount_amount}
                        type="Number"
                        onChange={e => {
                            discounts.discount_amount = e.target.value;
                            setDiscounts({...discounts})
                        }}
                    />
                    <Input
                        label="Скидка в %"
                        type="Number"
                        value={discounts.discount}
                        onChange={e => {
                            discounts.discount = e.target.value;
                            setDiscounts({...discounts})
                        }}
                    />
                </div>
                :
                <ModAutoSelect
                    label={type && types(type).title}
                    link={type && types(type).link}
                    minLength={0}
                    result={type && types(type).result}
                    entityName={type && types(type).result}
                    entity={obj}
                    updateData={(id, obj) => {
                        setValue(id);
                        setObj(obj)
                    }}
                    unLink={() => {
                        setValue("");
                        setObj(null)
                    }}
                />

        }

    </Modal>
};

export default FastActions