import React from 'react';
import Notif from '../components/Notif';
import Input from '../components/UI/Input';
import formValid from '../components/UI/formValid';
import AutoSelect from '../components/UI/AutoSelect';
import myaxios from '../components/Axios';
import Loader from '../components/UI/Loader';
import ReactQuill from 'react-quill';
import DropzoneComponent from 'react-dropzone-component';
import '../../node_modules/dropzone/dist/min/dropzone.min.css';
import {GlobalState} from '../Layout/Layout';
import Myselect from '../components/UI/Select';
import EmailEditor from 'react-email-editor'
require('react-quill/dist/quill.snow.css');




class SendMail extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            formControl: {
                to: {
                    value: '',
                    selectvalid: true,
                    arr: (this.props.id) ? [this.props.id.from] : [],
                    /*validation: {
                     required: false,
                     email: true
                     },*/
                },
                from: {
                    value: '',
                    id: '',
                    valid: true,
                    validation: {
                        required: true,
                        email: true
                    },
                    options: []
                },
                subject: {
                    value: '',
                    valid: true,
                    validation: {
                        required: true,
                        minLength: 1
                    },
                },
            },
            hint:'0',
            templates:[],
            tmp:'',
            editorHtml: '',
            constructor:'',
            files: [],
            filesList:[],
            isFormValid: true,
            tch: true,
            loading: true,
            isConstructor: false,
            offConstr: true,
            plate_id:"",
            plates:[]
        };
        this.handleChange = this.handleChange.bind(this)
    }
    /*exportHtml = () => {
        this.editor.exportHtml(data => {
            const { design, html } = data
            this.setState({ constructor: html })
        })
    }*/
    handleChange (html) {
        this.setState({ editorHtml: html, tch: false });
    }
    componentDidMount(){
            var elements = document.querySelectorAll('.r-open');
            if(elements[0].offsetWidth < 1045){
                this.setState({ offConstr: false })
            }
        const formControl = this.state.formControl;
        let subject = '';
        if(this.props.id){
            if(this.props.id.subject){
                formControl.subject.value = `Re: ${this.props.id.subject}`
            }
        }
        if(this.props.clientEmail && !this.props.id) formControl.to.arr = [this.props.clientEmail];

        myaxios.get('/ajax/template', {method: 'get',}).then((responce) =>{
            let tmp = [];
            tmp[0] = {id:0, name:"Без шаблона", body:'',subject:''}
            if(responce){
                for(let i = 0; i < responce.data.items.length; i++){
                    tmp[responce.data.items[i].id]  = responce.data.items[i];
                }
            }
            this.setState({templates: tmp})
        });

        this.setState({ formControl, loading: false })

        this.getPlates()
    }
    sendMail = e => {
        //this.exportHtml();
        e.preventDefault();
        const files = this.state.files;
        for(let i = 0; i <= this.state.filesList.length - 1; i++){
            if(this.state.filesList[i]){
                files.unshift(this.state.filesList[i].id)
            }
        }
        let emails = [];
        Object.keys(this.state.formControl.to.arr).map((key, index) => {
            emails.unshift(this.state.formControl.to.arr[key])
        });

        this.setState({files});
        if(this.isFormValid()) {
            this.setState({ tch: true, loading: true});
            myaxios.post((this.props.freeparams) ? `/ajax/${this.props.freeparams}/compose` :`/ajax/mail/${this.state.formControl.from.id}/compose`, {
                from: this.state.formControl.from.value,
                to: (this.state.formControl.to.arr) ? emails : this.state.formControl.to.value,
                subject: this.state.formControl.subject.value,
                body: this.state.isConstructor ? this.state.constructor : this.state.editorHtml,
                reply_to: (this.props.id) ? this.props.id.id : null,
                files: this.state.files,
                plate_id: this.state.plate_id ? this.state.plate_id : null,
                //is_forward: this.props.
                mails:[]
            }).then(res => {
                if(res.status === 200 || res.status === 201 || res.status === 204){
                    this.setState({tch: true, loading: false});
                    setTimeout(() => this.props.close());
                    Notif("save", "Письмо успешно отправлено")
                } else {
                    Notif("Error");
                    this.setState({ tch: false, loading: false})
                }
            }).catch((error) => {
                this.setState({ tch: false, loading: false})
            });
        }};
    setField = (event, type) => {
        const formControl = {  ...this.state.formControl };
        const control = { ...formControl[type] };
        control.value = event.target.value;
        formControl[type] = control;
        this.setState({
            formControl, tch: false
        })
    };
    isFormValid = () => {
        let formControl = this.state.formControl;
        let isFormValid = true;
        Object.keys(formControl).map((key, index) => {
            formControl[key].valid = formValid(formControl[key].value, formControl[key].validation)
            isFormValid = formControl[key].valid && isFormValid
        });
        if(formControl.to.arr){
            if(formControl.to.arr.length < 1){
                formControl.to.selectvalid = false;
                isFormValid = false;
            }
        }
        this.setState({
            formControl, isFormValid
        });
        return isFormValid;
    };
    updateData = (id,arr) =>{
        const formControl = this.state.formControl;
        formControl.from.value = arr.username;
        formControl.from.id = id;
        this.setState({ formControl, tch: false })
    };
    files = arr => {
        const filesList = this.state.filesList;
        filesList.unshift(arr);
        this.setState({filesList});
    };
    delFile = id => {
        const filesList = this.state.filesList;
        if(id && filesList.length >= 1){
            for(let i = 0; i <= filesList.length; i++){
                if(filesList[i]){
                    if(filesList[i].id === id) {
                        delete filesList[i]
                    }
                }
            }
            this.setState({filesList});
        }
    };
    updateDataTo = (id, arr) => {
        const formControl = this.state.formControl;
        if(id){
            formControl.to.arr[id] = arr.email;
            formControl.to.value = '';
        } else {
            if( formValid(arr, {email: true}) ) {
                formControl.to.arr.push( arr )
                formControl.to.value = '';
            } else {
                Notif("Error", "Введите корректный Email")
            }
        }
        this.setState({ formControl, val: '' })
    };
    deleteItem = id => {
        const formControl = this.state.formControl;
        delete formControl.to.arr[id];
        this.setState({ formControl })
    }
    onLoad = () => {
        console.log(this.editor)
    }
    getPlates = () => {
        if(!this.props.relatedId || !this.props.isPlates) return;
        myaxios.get(`/ajax/plates/`).then(res => {
            this.setState({ plates: res.data.items.filter(item => +item.object_type === +this.props.relatedId) })
        })
    }
    render(){
        return (this.state.loading) ? <Loader contain /> : <div className="sbform create-activ">
            <div className="rmod-header">
                <b>Отправить Email</b>
                <a onClick={this.props.close} style={{right: "70px"}} className="mclose" href={null}><i className="flaticon-close" /></a>
                <div className="togglelist">
                    <button onClick={this.sendMail} className="rmod-ok" disabled={this.state.tch}><i className="flaticon-paper-plane" /></button>
                    {/*<ul>
                     <li><a onClick={this.sendMail} href={null}><i className="flaticon-paper-plane" /> Отправить</a></li>
                     <li><a href="#"><i className="flaticon-folder-2" /> В черновик</a></li>
                     </ul>*/}
                </div>
            </div>
            {this.props.email ? <div className="mod-cont">
                <AutoSelect
                    label="От"
                    link="/ajax/mail/account?filter[username][like]="
                    minLength="0"
                    result="username"
                    event="from"
                    errorMessage={"Введите корректный Email"}
                    value={this.state.formControl.from.value}
                    valid={this.state.formControl.from.valid}
                    shouldValidate={true}
                    setField={event => this.setField(event, "from")}
                    isFormValid={this.state.isFormValid}
                    updateData={this.updateData}
                />
                <label htmlFor="">Кому</label>
                <AutoSelect
                    label="Кому"
                    link="/ajax/mail/search-contact?q="
                    minLength="3"
                    result="email"
                    event="to"
                    errorMessage={"Введите корректный Email"}
                    value={this.state.formControl.to.value}
                    shouldValidate={true}
                    setField={event => this.setField(event, "to")}
                    isFormValid={this.state.isFormValid}
                    valid={true}
                    updateData={this.updateDataTo}
                    deleteItem={this.deleteItem}
                    createTag
                    multiSelect={this.state.formControl.to.arr}
                    multiSelectValid={this.state.formControl.to.selectvalid}
                    itemType={`item`}
                />
                <Input
                    label="Тема"
                    type="text"
                    className="c12"
                    value={this.state.formControl.subject.value}
                    onChange={event => this.setField(event, "subject")}
                    errorMessage={"Поле не может быть пустым"}
                    valid={this.state.formControl.subject.valid}
                    shouldValidate={true}
                    isFormValid={this.state.isFormValid}
                />
                <div className="clear" />
                <Myselect
                    label="Шаблон"
                    options={this.state.templates}
                    value={this.state.tmp}
                    onChange={e => {
                        const formControl = this.state.formControl;
                        formControl.subject.value = this.state.templates[e.target.value].subject;
                        console.log("hint", this.state.templates[e.target.value].hint)
                        this.setState({ formControl, tmp: e.target.value, editorHtml: this.state.templates[e.target.value].body, hint: this.state.templates[e.target.value].hint })
                    }}
                    lab="name"
                    val="id"
                />
                {this.props.isPlates && <Myselect
                    label="Шаблон документа"
                    options={this.state.plates}
                    value={this.state.plate_id}
                    onChange={e => this.setState({ plate_id: e.target.value })}
                    empty
                    val="id"
                    lab="name"
                />}
                {/*{
                    (this.state.offConstr) ? <Myselect
                        label="Конструктор письма"
                        options={[{value:0, label:"Выключен"}, {value:1, label:"Включен"} ]}
                        onChange={e => {this.setState({ isConstructor: e.target.value })  }}
                        value={this.state.isConstructor}
                    /> : null
                }*/}

                {/*<div className="c12">
                 <label for="">Копия</label>
                 <input type="text" />
                 </div>*/}

                <div className="c12">
                    <label for="">Текст письма</label>
                    {/*{
                        this.state.hint === "1" ?
                            <EmailEditor
                                ref={editor => {this.editor = editor; }}
                                options={
                                    { mergeTags: [
                                        {name: "First Name", value: "{{first_name}}"},
                                        {name: "Last Name", value: "{{last_name}}"}
                                    ], locale: "ru-RU",
                                        appearance: {
                                            theme: 'dark'
                                        },
                                        tools: {
                                            'custom#SLUG': {
                                                enabled: true
                                            }
                                        }
                                    }
                                }
                                onLoad={this.onLoad}
                            /> :*/}
                            {this.state.hint == 1 ? <iframe width="100%" style={{ height: '100vh' }} srcDoc={this.state.editorHtml} /> :
                                <ReactQuill
                                onChange={this.handleChange}
                                value={this.state.editorHtml}
                                modules={SendMail.modules}
                                formats={SendMail.formats}
                                bounds={'.app'}
                            />}

                </div>
                <div className="clear" />
                {/*<a className="rem" href="#"><i className="flaticon-attachment" /> Прикрепить файл</a>*/}
                {
                    this.state.filesList.map((item,index)=>
                        <div key={index} className="letterListFileItem" style={{display: "none"}}>
                            <i className="flaticon-download" />
                            {item.name}
                            <a onClick={()=>this.delFile(index)}>Удалить</a>
                        </div>
                    )
                }
                <FileUpload files={this.files} delFile={this.delFile} />
            </div> : <div className="mod-cont"><p style={{ fontSize: "14px", padding:"5px 0" }}>Добавьте как минимум 1 почтовый ящик</p>
                <a className="btn-m" onClick={() => this.props.updateData("AddEmailBox")}>Добавить ящик</a>
            </div>}


        </div>
    }
}

SendMail.modules = {
    toolbar: [
        [{size: []}],
        ['bold', 'italic', 'underline', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'},
            {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    }
}
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
SendMail.formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
]

export default SendMail;


const endpoint = '/ajax/mail/upload?related_type=10';

class FileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFile: null,
            loaded: 0,
            componentConfig: {
                iconFiletypes: ['.jpg', '.png', '.gif', '.psd', '.rar', '.xlsx'],
                showFiletypeIcon: true,
                postUrl: endpoint,
            },
            //eventHandlers: { 'addedfile': (file) => { console.log(file.xhr) } }
        }
    }
    handleselectedFile = event => {
        this.setState({
            selectedFile: event.target.files[0],
            loaded: 0,
        })
    };
    handleUpload = e => {
        e.preventDefault();
        if(this.state.selectedFile !== null) {
            const data = new FormData();
            data.append('UploadForm[file]', this.state.selectedFile, this.state.selectedFile.name);
            myaxios.post(endpoint + `?related_type=10`, data, {
                onUploadProgress: ProgressEvent => {
                    this.setState({loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100, selectedFile: null
                    })}}).then(res => {
                if(res.data.status === "uploaded") {
                    let arr = {id: res.data.id, name: res.data.name};
                    setTimeout(this.props.files(arr), 200);
                    Notif("save", "Файл успешно загружен");
                }
                if(res.data.status === "error") {
                    Notif("Error", res.data.errors.file[0])
                }
            })}};
    /*postFile = (file) => {
        console.(file);
    };*/
    render() {
        var aaa = this.props.delFile;
        return (
            <div className="previewComponent mailDropzone">
                <GlobalState.Consumer>
                    {context => (
                        <DropzoneComponent config={this.state.componentConfig}
                                           djsConfig={
                                               {
                                                   //clickable: false,
                                                   uploadMultiple: false,
                                                   paramName: 'UploadForm[file]',
                                                   dictDefaultMessage: "Перетащите файлы сюда",
                                                   dictFileTooBig: `Размер файла {{filesize}}Мб выше допустимого {{maxFilesize}}Мб`,
                                                   dictInvalidFileType: "Недопустимый тип файла",
                                                   dictUploadCanceled: "Вы отменили загрузку",
                                                   acceptedFiles: parseStr(context.state.allowed_file_extensions),
                                                   maxFilesize: (context.state.allowed_file_max_size / 1024)  / 1024,
                                                   addRemoveLinks: true,
                                                   /*init: function(){
                                                       this.on("success", function(file) {
                                                           console.log(file.xhr.response)
                                                       })
                                                   },*/
                                                   /*removedfile: (file) => {
                                                       if(file.xhr.response){
                                                           setTimeout(this.props.delFile(JSON.parse(file.xhr.response).id));
                                                       }
                                                       return true
                                                   },*/
                                                   init: function(){
                                                       this.on("removedfile", function(file) {
                                                           if(file.xhr){
                                                               let a = JSON.parse(file.xhr.response);
                                                               myaxios.delete(`/ajax/file/delete/${a.id}?related_type=10`).then(res => {})
                                                           }
                                                       })
                                                       this.on("removedfile", (file) => {
                                                           if(file.xhr){
                                                               if(file.xhr.response){
                                                                   setTimeout(() => aaa(JSON.parse(file.xhr.response).id), 100)
                                                               }
                                                           }
                                                       })
                                                   },
                                                   success: (file) => {
                                                       if(file.xhr.response){
                                                           setTimeout(this.props.files({id: JSON.parse(file.xhr.response).id, name: JSON.parse(file.xhr.response).name}), 100)
                                                       }
                                                   },
                                                   //accept: (file) => { this.postFile(file) },
                                                   /*previewTemplate: ReactDOMServer.renderToStaticMarkup(
                                                    <div className="dz-preview dz-file-preview">
                                                    <div className="dz-details">
                                                    <div className="dz-filename"><span data-dz-name="true"></span></div>
                                                    <img data-dz-thumbnail="true" />
                                                    </div>
                                                    <div className="dz-progress"><span className="dz-upload" data-dz-uploadprogress="true"></span></div>
                                                    <div className="dz-success-mark"><span>✔</span></div>
                                                    <div className="dz-error-mark"><span>✘</span></div>
                                                    <div className="dz-error-message"><span data-dz-errormessage="true"></span></div>
                                                    </div>
                                                    )*/
                                               }
                                           }
                                           eventHandlers={this.state.eventHandlers}
                            /* djsConfig={djsConfig}*/ />
                    )}
                </GlobalState.Consumer>


                {/*<input className="btn-m" type="file" name="UploadForm[file]" id="" onChange={this.handleselectedFile} />
                 <button className="btn-m ml10" onClick={this.handleUpload}>Загрузить</button>
                 <em style={{fontSize: "12px"}}> {(this.state.loaded > 1) ? `${Math.round(this.state.loaded, 2)}%` : null} </em>*/}
            </div>
        )
    }
}

function parseStr(string) {
    let a = string.split(", ");
    return "." + a.join(", .")
}
