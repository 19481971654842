import React, { Component } from 'react';
import {NavLink, withRouter} from 'react-router-dom';

const WorkOrder = props => {
    return <div className="sp-table body-table">
        <table style={{borderSpacing: "0"}} className="stagesTable">
            <thead>
            {
                (props.item.length >= 1) ? <tr>
                    <td>Название</td>
                    <td>Дата производства</td>
                    <td>Продукт</td>
                    <td>К-во</td>
                    <td>Проведено</td>
                    <td style={{width: "30px"}}/>
                </tr> : null
            }

            </thead>
            <tbody>
            {
                props.item.map((item,index)=>
                    <tr key={index}>
                        <td><a onClick={() => props.history.push('/stock/workorder/' + item.id)}>{item.name}</a></td>
                        <td>{item.end_date || "Не назначена"}</td>
                        <td><NavLink to={`/product/${item.products[0] && item.products[0].product.id}`}>{item.products[0] && item.products[0].product.name}</NavLink></td>
                        <td>{item.quantity}</td>
                        <td>{item.is_completed === 1 ? "Да" : "Нет"}</td>
                        <td>
                            <div className="toogle-bottom fl-r">
                                <a className="is" href="#"><i className="flaticon-more" /></a>
                                <ul className="toogle-list">
                                    <li><a onClick={() => props.history.push('/stock/workorder/' + item.id)}><i className="flaticon-edit-1" /> <b>Редактировать</b></a></li>
                                    <li><a onClick={()=>props.deleteObject("workorder", item.id)} className="table-del"><i className="flaticon-delete-1" /> <b>Удалить</b></a></li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                )
            }
            </tbody>
        </table>
    </div>
}

export default withRouter(WorkOrder);