import {createProductJSON, priceBookJSON} from './config'
import {
    createProductTest,
    getProductCategoryTest,
    updateProductTest,
    getSingleProductTest,
    deleteProductTest, getProductListTest, getLinkedPriceBooksTest
} from "./productView";
import {getLookUpTest} from "../Settings/LookUpTest";
import {createTagTest} from "../Settings/TagsTest";
import {createPriceBookTest, getPriceBookTest, linkPriceBookTest, unlinkPriceBookTest, deletePriceBookTest} from "./priceBook";
import {CrudGetSingleItemFunction, CrudUpdateFunction} from "../CRUD";
import {getlinkedPriceBooks, updatePriceBookService} from "../../../services/product/product";
import {ListTest} from "../other/listTest";
import {entity} from "../../ListComponentEntity";

export const productTest = async () => {
    // Получаем все валюты
    const getCurrenciesRes = await getLookUpTest('currency');
    if(!getCurrenciesRes.status) return false;

    // Получаем все налоги
    const getTaxRes = await getLookUpTest('tax');
    if(!getTaxRes.status) return false;

    // Получаем все категории продукта
    const productCategories = await getProductCategoryTest()
    if(!productCategories.status || !productCategories.data[0]) return false;

    // Создаем продукт
    createProductJSON.category_id = productCategories.data[0].id;
    createProductJSON.currency_id = getCurrenciesRes.data[0].id;
    createProductJSON.tax_id = getTaxRes.data[0].id;
    const createProductRes = await createProductTest(createProductJSON);
    if(!createProductRes.status) return false;

    // Проверяем или есть продукт в списке
    const getProductList = await getProductListTest("?sort=-created_at");
    if(!getProductList.status || !getProductList.data.find(el => +el.id === +createProductRes.product_id)) {
        console.log(getProductList.data)
        console.error("Created product absent in list")
        return false;
    }

    // Меняем название продукта
    createProductJSON.name = "Product for test 123";
    const updateProductRes = await updateProductTest(createProductRes.product_id, createProductJSON);
    if(!updateProductRes.status) return false;

    // Проверяем изменилось ли название и присвоились ли все поля
    const getSingleProductRes = await getSingleProductTest(createProductRes.product_id);
    if(
        !getSingleProductRes.status &&
        getSingleProductRes.data.name !== "Product for test 123" &&
        getSingleProductRes.data.tax_id === getTaxRes.data[0].id &&
        getSingleProductRes.data.currency_id === getCurrenciesRes[0].id
    ) {
        console.error("update product name was crush");
        return false;
    }

    // меняем категорию
    createProductJSON.category_id = productCategories.data[1].id;
    const updateProductRes2 = await updateProductTest(createProductRes.product_id, createProductJSON);
    if(!updateProductRes2.status) return false;

    // проверяем или изменилась категория
    const getSingleProductRes2 = await getSingleProductTest(createProductRes.product_id);
    if(
        !getSingleProductRes2.status &&
        !getSingleProductRes2.data.category &&
        !getSingleProductRes2.data.category.name !== productCategories.data[1].name &&
        getSingleProductRes.data.category_id !== productCategories.data[1].id
    ) {
        console.error("update product category_id was crush");
        return false;
    }

    // создаем тег
    const createTagRes = await createTagTest("product",
        createProductRes.product_id, `${"product"}_${createProductRes.product_id},${"product"}_${createProductRes.product_id}_2`
    );
    if(!createTagRes.status) return false;

    // проверяем или создался тег
    const getSingleProductRes3 = await getSingleProductTest(createProductRes.product_id);
    if(!getSingleProductRes3.status && getSingleProductRes3.data.editorTags !== `${"product"}_${createProductRes.product_id},${"product"}_${createProductRes.product_id}_2`) {
        console.error('check created tags error')
        return false
    }

    // удаляем тег
    createProductJSON.editorTags = `${"product"}_${createProductRes.product_id}_2`
    const updateProductRes3 = await updateProductTest(createProductRes.product_id, createProductJSON);
    if(!updateProductRes3.status) return false;

    // проверяем или удалился тег
    const getSingleProductRes4 = await getSingleProductTest(createProductRes.product_id);
    if(!getSingleProductRes4.status && getSingleProductRes4.data.editorTags !== `${"product"}_${createProductRes.product_id}_2`) {
        console.error('check deleted tags error')
        return false
    }

    // создаем прайс лист
    const createPriceBook = await createPriceBookTest(priceBookJSON);
    if(!createPriceBook.status) return false;

    // проверяем или прайс лист был создан
    const getPriceBook = await getPriceBookTest();
    if(!getPriceBook.status || !getPriceBook.data.find(item => +item.id === +createPriceBook.id)) {
        console.error('PriceBook create error')
        return false;
    }

    // привязываем прайс лист
    const linkPriceBook = await linkPriceBookTest(createProductRes.product_id, createPriceBook.id, { currency_id: getCurrenciesRes.data[0].id, price: 200 })
    if(!linkPriceBook.status) return false;

    // проверяем или прайс был привязан
    const getLinkedPriceBooksRes = await getLinkedPriceBooksTest(createProductRes.product_id)
    if(!getLinkedPriceBooksRes.status || !getLinkedPriceBooksRes.data.find(el => +el.price_book_id === +createPriceBook.id)) {
        console.error('Linked PriceBook view error')
        return false;
    }

    // изменяем прайс лист
    const updatePriceBookRes = await CrudUpdateFunction({ service: updatePriceBookService,
        serviceName: "updatePriceBook", statusCode: 200, serviceData: {id: createPriceBook.id, data: { description: "desc upd", name: "name upd" }} })
    if(!updatePriceBookRes.status) return false;

    // проверяем измененный прайс лист в продукте
    const getLinkedPriceBooksRes2 = await CrudGetSingleItemFunction({
        service: getlinkedPriceBooks,
        serviceName: "getLinkedPriceBooksRes2",
        serviceData: { product_id: createProductRes.product_id }
    })
    if(!getLinkedPriceBooksRes2.status || !getLinkedPriceBooksRes2.data.items.find(el => +el.price_book_id === +createPriceBook.id)) {
        console.error('Linked updated PriceBook view error')
        return false;
    }

    // отвязываем прайс лист
    const unlinkPriceBook = await unlinkPriceBookTest(createProductRes.product_id, createPriceBook.id)
    if(!unlinkPriceBook.status) return false;

    // удаляем прайс лист
    const deletePriceBookRes = await deletePriceBookTest(createPriceBook.id)
    if(!deletePriceBookRes.status) return false;

    // удаляем продукт
    const deleteProductRes = await deleteProductTest(createProductRes.product_id)
    if(!deleteProductRes.status) return false;

    // Тестируем список
    const listTestRes = await ListTest({
        filterArr: entity.product.fArr,
        entityName: 'product'
    })
    if(!listTestRes) {
        console.error("Test list have been failed!");
        return false;
    }

    // создаем продукт
    const createProductRes2 = await createProductTest(createProductJSON);
    if(!createProductRes2.status) return false;

    await console.log("productTest was been success");
    return true
}