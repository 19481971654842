import React, {useEffect, useState} from "react";
import Modal from "../Modal/Modal";
import {CSSTransition} from "react-transition-group";
import profileImg from '../../img/blank-profile-picture-973460_640.png'
import './profile-styles.css'
import myaxios from "../Axios";
import {NavLink} from "react-router-dom";

export const SettingsModal = ({ exit, openBackgroundProcess }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [userData, setUserData] = useState({

    })
    const [avatarUrl, setAvatarUrl] = useState('')
    const [loading, setLoading] = useState(true)

    const getUserInfo = async () => {
        try {
            const { status, data } = await myaxios.get(`/ajax/profile`)
            console.log('profile data',data)
            if(status === 200 && data && data.user && data.user.id) {
                setUserData(data.user)
                setAvatarUrl(data.user.contact && data.user.contact.photo)
            }
        } catch (e) { }
        setLoading(false)
    }

    useEffect(() => {
        if (isOpen) {
            getUserInfo().then()
        }
    }, [isOpen])

    return (
        <>
            <a onClick={() => setIsOpen(true)}><i className="flaticon-cogwheel-1" /></a>
            <Modal isOpen={isOpen} close={() => setIsOpen(false)} title={'Профиль'} loading={loading}>
                <div className={'modal-profile'}>
                    <div className="modal-profile__data">
                        <img src={avatarUrl || profileImg} alt=""/>
                        <div className={'modal-profile__data-info'}>
                            <h4>{userData.contact ? userData.contact.name : userData.name}</h4>
                            <em>{userData.contact ? userData.contact.position : 'Должность не указана'}</em>
                            <NavLink onClick={() => setIsOpen(false)} to={'/settings'}>Настройки</NavLink>
                            <button
                                onClick={exit}
                                className={'modal-profile__exit mr5 success fl-l btni'}>
                                Выйти
                            </button>
                        </div>
                    </div>

                    <div onClick={openBackgroundProcess} className="modal-profile__color-item">
                        <i className={'flaticon-web'} />
                        <div className={'modal-profile__color-item-text'}>
                            <span>Фоновые процессы</span>
                            <em>Открыть</em>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}