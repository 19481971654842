import React,{ Component } from 'react';
import Input from '../components/UI/Input';
import Myselect from '../components/UI/Select';
import Notif from '../components/Notif';
import myaxios from '../components/Axios';
import AutoSelect from '../components/UI/AutoSelect';



class Address extends Component {
    state = {
        formControl: {
            type: {
              value: "factual",
                options: [
                    {id: "factual", label: "Фактический"},
                    {id: "legal", label: "Юридический"},
                    {id: "delivery_address", label: "Адрес доставки"},
                ]
            },
            country: {value: ''},
            region: {value: '',},
            city: {value: '',},
            address: {value: '',},
            postal_code: {value: '',}
        },
        check: true,
        isFormValid: true,
        tch: true,
    };
    showAddress = () => {
        myaxios.get(`/ajax/${this.props.type}/${this.props.parrentId}/address/${this.props.id}`, {method: 'get',}).then((responce) =>{
            const formControl = this.state.formControl;
            formControl.address.value = responce.data.street;
            formControl.city.value = responce.data.city;
            formControl.region.value = responce.data.region;
            formControl.country.value = responce.data.country;
            formControl.postal_code.value = responce.data.postal_code;
            formControl.type.value = responce.data.address_type;
            this.setState({ formControl, check: responce.data.is_main })
        })
        };
    componentDidMount(){
        if(this.props.id){
            this.showAddress();
        }
    }
    updateData = id =>{
        this.props.history.push('/contacts/' + id);
        setTimeout(this.props.close, 300)
    };
    emptyFields = () => {
        const form = this.state.formControl;
        if(form.country.value.length > 2 || form.city.value.length > 2 || form.address.value.length > 2) {return true}
    }
    setField = (event, type) => {
        const formControl = {  ...this.state.formControl };
        const control = { ...formControl[type] };
        control.value = event.target.value;
        formControl[type] = control;
        this.setState({
            formControl, tch: !this.emptyFields()
        })
    };
    editForm = event => {
        event.preventDefault();
        if(this.emptyFields()){
            this.setState({ tch: true });
            myaxios.post(`/ajax/${this.props.type}/${this.props.parrentId}/update-address/${this.props.id}`, {
                address_type: this.state.formControl.type.value,
                street: this.state.formControl.address.value,
                city: this.state.formControl.city.value,
                region: this.state.formControl.region.value,
                country: this.state.formControl.country.value,
                postal_code: this.state.formControl.postal_code.value,
                is_main: (this.state.check) ? 1 : null
            }).then((response) => {
                if(response.status === 201 || response.status === 200) {
                    setTimeout(() => this.props.refresh(), 300);
                }
            })
        } else {
            (this.emptyFields() === true) ? Notif("required-fields") : Notif("required-fields", "Для создания адреса заполните как минимум одно контактное поле")
        }
    }
    sendForm = event => {
        event.preventDefault();
        if(this.emptyFields()){
            this.setState({ tch: true });
            myaxios.post(`/ajax/${this.props.type}/${this.props.parrentId}/add-address`, {
                address_type: this.state.formControl.type.value,
                street: this.state.formControl.address.value,
                city: this.state.formControl.city.value,
                region: this.state.formControl.region.value,
                country: this.state.formControl.country.value,
                postal_code: this.state.formControl.postal_code.value,
                is_main: this.state.check
            }).then((response) => {
                if(response.status === 201 || response.status === 200) {
                    setTimeout(() => this.props.refresh(), 300);
                }
            })
        } else {
            (this.emptyFields() === true) ? Notif("required-fields") : Notif("required-fields", "Для создания адреса заполните как минимум одно контактное поле")
        }
    };
    render(){
        return <div className="sbform create-activ">
            <div className="rmod-header">
                <b>Адрес</b>
                <a className="mclose" onClick={this.props.close}><i className="flaticon-close" /></a>
                <div className="togglelist"><button onClick={(this.props.id) ? this.editForm : this.sendForm} className="rmod-ok" disabled={this.state.tch} ><i className="flaticon-interface-1" /></button></div>
            </div>
            <div className="mod-cont">
                <Myselect
                    className="c12"
                    value={this.state.formControl.type.value}
                    label="Тип контакта"
                    options={this.state.formControl.type.options}
                    onChange={event => this.setField(event, "type")}
                    val="id"
                    lab="label"
                />
                <Input
                    className="c12"
                    label="Страна"
                    type="text"
                    value={this.state.formControl.country.value}
                    onChange={event => this.setField(event, "country")}
                />
                <Input
                    className="c12"
                    label="Область"
                    type="text"
                    value={this.state.formControl.region.value}
                    onChange={event => this.setField(event, "region")}
                />
                <Input
                    className="c12"
                    label="Город"
                    type="text"
                    value={this.state.formControl.city.value}
                    onChange={event => this.setField(event, "city")}
                />
                <Input
                    className="c12"
                    label="Адрес"
                    type="text"
                    value={this.state.formControl.address.value}
                    onChange={event => this.setField(event, "address")}
                />
                <Input
                    className="c12"
                    label="Индекс"
                    type="number"
                    value={this.state.formControl.postal_code.value}
                    onChange={event => this.setField(event, "postal_code")}
                />
                <label className="c12" style={{padding: "0px", margin: "10px 0px 0 0px"}}>
                    <input checked={this.state.check} onClick={() => this.setState({ check: (!this.state.check), tch: false })} className="checkbox checkboxlead" type="checkbox" name="checkbox-test" />
                    <span className="checkbox-custom" />
                    <em style={{ display: "inline-block", marginLeft: "4px", position: "relative", top: "2px"}}>Основной</em></label>
            </div>
        </div>
    }
}

export default Address;