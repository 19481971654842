import noimageIcon from "../../fonts/icons/noimage.svg";
import saveIcon from '../../fonts/icons/save.svg';
import {NavLink, Prompt} from "react-router-dom";
import React, {useEffect, useState} from "react";
import myaxios from "../Axios";
import Input from "../UI/Input";
import CustomCheckbox from "../UI/CustomCheckbox";
import DateTime from "../UI/DateTime";


const ProductItem = ({ item, setItem, save, deleteProduct, index, isStatus, isTotal, statuses, isValues,
                         currencyCode, onChecked, massDo, setActive, truncator, isPrice, isDiscount, isComment, isDate, disable }) => {
    //const [active, setActive] = useState(false)
    const [priceBookArr, setPriceBookArr] = useState([])
    const {
        discount = 0, discount_amount = 0, price = 0, quantity = 1, total, order_item_status, stockQuantity, active = false, values, comment, date,
        product: {id, name, thumbnail, height = 0, length = 0, weight = 0, width = 0, unit}
    } = item;

    const setItemInside = (e, type) => {
        setItem(e, type, index);
        setActive(index, true)
    };

    const keyUp = (e) => {
        if(e.keyCode === 13 && active){
            save(item.id, index);
        }
    };

    const getProd = (id) => {
        myaxios.get(`/ajax/product/${id}/prices`).then(res => {
            setPriceBookArr(res.data.items)
        })
    };

    const [feature, setFeature] = useState(false)

    return <tr className={Number(stockQuantity) < Number(quantity) && "ProductItemNotInStock"}>
        {massDo && <td style={{ border: "none" }}>
            <CustomCheckbox
                checked={item.checked}
                onChange={onChecked}
            />
        </td>}
        <td style={{ textAlign: 'center' }}>{index + 1}</td>
        <td style={{ width: "60px" }}><img style={{ maxWidth: "40px" }} src={`${thumbnail || noimageIcon}`} alt=""/></td>
        <td style={{ minWidth: "150px" }}>
            <NavLink to={`/product/${id}`}>{name}</NavLink>
            {isValues && <div className="product-feature">
                {feature ? <Input
                    value={values && JSON.parse(values)}
                    onChange={e => setItemInside(JSON.stringify(e.target.value), "values")}
                    onKeyUp={keyUp}
                /> : <p>{values && JSON.parse(values)}</p>}
            </div>}
        </td>
        <td>
            <p><b>К-во на складе:</b> <em>{stockQuantity} {unit && unit.value}</em></p>
            <p><b>Вес:</b> <em>{weight} кг</em></p>
            <p><b>Габариты (ДхШхВ):</b> <em>{`${length || 0}x${width || 0}x${height || 0}`}</em></p>
            {isValues && <p>{!feature &&
                <a onClick={setFeature}>
                    {!values ? 'Добавить свойство' : "Изменить свойство"}
                </a>}
            </p>}
        </td>
        {isStatus && <td style={{ width: "100px" }} className="productItemStatusesTd">
            <div className="productItemStatuses">
                <span className="label-st" style={{backgroundColor: "#62a091"}}>
                    {statuses.filter(item => item.id === order_item_status)[0] && statuses.filter(item => item.id === order_item_status)[0].value}
                </span>
                <ul className="productItemStatusesDrop">
                    {
                        statuses.map((item, index) => (
                            <li key={index}>
                                <a onClick={() => {
                                    setItemInside(item.id, "order_item_status")
                                }}>
                                    {item.value}
                                </a>
                            </li>
                        ))
                    }
                </ul>
            </div>

        </td>}
        <td style={{ width: "120px" }}>
            <div className="product-flax-container">
                <input
                    type="number"
                    value={quantity}
                    onChange={e => setItemInside(e.target.value, "quantity")}
                    onKeyUp={keyUp}
                    onDoubleClick={() => setItemInside("", "quantity")}
                    // onFocus={() => setItemInside("", "quantity")}
                    onBlur={() => {
                        if(!quantity || quantity == "" || quantity == 0 ) setItemInside(1, "quantity")
                    }}
                />
                <span style={{ width: "40px" }}>{unit && unit.value}</span>
            </div>
            {Number(stockQuantity) < Number(quantity) && <p className={'ProductItemNotInStockP'}>Нет на складе</p>}
        </td>
        {isPrice && <td style={{ width: "100px" }}>
            <div className="prbookrel" onBlur={() => setTimeout(() => setPriceBookArr([]), 300)}>
            <input
                type="number"
                value={Number(price)}
                onChange={e => setItemInside(e.target.value, "price")}
                onKeyUp={keyUp}
                onFocus={() => getProd(id)}
            />
                <div className="prbookabs">
                    {
                        priceBookArr && priceBookArr.map((item, index) =>
                            <a onClick={() => {
                                setItemInside(item.default_price * item.currency.value, "price")
                                setPriceBookArr([])
                            }}>
                                {item.priceBook && item.priceBook.name}: {item.default_price}{item.currency && <em>{item.currency.text_symbol}</em>}
                            </a>
                        )
                    }
                    {priceBookArr.length >= 1 && <a onClick={() => setPriceBookArr([])} className="prbookcls">Закрыть</a>}
                </div>
            </div>
        </td>}
        {isDiscount && <td style={{ width: "80px" }}>
            <div className="product-flax-container">
                <input
                    type="number"
                    value={discount_amount}
                    onChange={e => setItemInside(e.target.value, "discount_amount")}
                    onKeyUp={keyUp}
                />
                <span style={{ width: "40px" }}>{currencyCode}</span>
            </div>
            <div className="product-flax-container">
                <input
                    type="number"
                    value={discount}
                    onChange={e => {
                        if(e.target.value >= 0 && e.target.value <= 100){
                            setItemInside(e.target.value, "discount")
                        }
                    }}
                    onKeyUp={keyUp}
                />
                <span style={{ width: "40px" }}>%</span>
            </div>
        </td>}
        {isPrice && <td style={{ width: "120px" }}>
            <div className="product-flax-container">
                {isTotal ? <input
                    type="number"
                    value={total}
                    onChange={e => {
                        setItemInside(e.target.value, "total")
                        setItemInside(true, "is_total_modified")
                    }}
                    onKeyUp={keyUp}
                /> : <span>{((( (price - discount_amount) - (price * discount / 100))) * quantity).toFixed( 3 ).slice( 0, -1 )}</span>}
            </div>
        </td>}


        {isDate && <td style={{ width: "120px" }}>
            <div className="product-flax-container">
                {/*<input
                    type="date"
                    value={date}
                    onChange={e => {
                        setItemInside(e.target.value, "date")
                        setItemInside(true, "is_total_modified")
                    }}
                    onKeyUp={keyUp}
                />*/}
                <DateTime
                    value={date}
                    handleChange={e => {
                        setItemInside(e, "date")
                        setItemInside(true, "is_total_modified")
                    }}
                    dateFormat="dd-MMMM-yyyy"
                    timeIntervals="15"
                    valid
                    showTimeSelect
                    todayButton
                />
            </div>
        </td>}
        {isComment && <td style={{ width: "120px" }}>
            <div className="product-flax-container">
                <input
                    type="text"
                    value={comment}
                    onChange={e => {
                        setItemInside(e.target.value, "comment")
                        setItemInside(true, "is_total_modified")
                    }}
                    onKeyUp={keyUp}
                />
            </div>
        </td>}


        <td style={{ padding: 0, width: "25px" }}>
            {!disable && <div className="productItemActions">
                <a onClick={() => {
                    save(item.id, index);
                }} className={active && 'visible'} data-tooltip="Сохранить элемент"><img src={saveIcon} alt=""/></a>
                <a onClick={() => deleteProduct(item.id, index)} data-tooltip="Удалить элемент" className="productItemDelete">x</a>
            </div>}
        </td>
    </tr>
}

export default ProductItem