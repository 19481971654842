import React, {useEffect, useRef, useState} from "react";
import Notif from "../Notif";
import Loader from "../UI/Loader";
import AutoSelect from "../UI/AutoSelect";
import {useOnClickOutside} from "../hooks/useOnClickOutside";
import {CSSTransition} from "react-transition-group";

const EditTags = props => {
    const [state, setMyState] = useState({
        formControl: {
            to: {
                value: [],
                selectvalid: true,
                arr: [],
            },
        },
        tch: false,
        loading: false
    })


    const setState = (st, func) => {
        Object.keys(st).map((key) => { state[key] = st[key]});
        setMyState(prevState => { return {...prevState, ...state} });
        if(func) func();
    };

    useEffect(() => {
        const formControl = state.formControl;
        if(props.tags){
            formControl.to.arr = props.tags.split(',');
        }
        setState({ formControl });
    }, []);

    useEffect(() => {addTag()}, [state])

    const addTag = (event, id, tag) => {
        let a = state.formControl.to.arr.join(',');
        setTimeout(props.refreshState(a))
    };
    const setField = (event, type) => {
        const formControl = {  ...state.formControl };
        const control = { ...formControl[type] };
        control.value = event.target.value;
        formControl[type] = control;
        setState({
            formControl, tch: false
        })
    };
    const updateDataTo = (id, arr) => {
        if(arr.name === ''){
            return false;
        }
        const formControl = state.formControl;
        let tag = true;
        for(let i = 0; i <= state.formControl.to.arr.length; i++){
            if(arr.name) {
                if(state.formControl.to.arr[i] === arr.name){
                    tag = false;
                }
            } else {
                if(state.formControl.to.arr[i] === arr){
                    tag = false;
                }
            }
        }
        if(tag){
            (arr.name) ? formControl.to.arr.push(arr.name) : formControl.to.arr.push(arr)

        } else if(!tag) {
            Notif("Error", "Тег уже добавлен")
        }
        formControl.to.value = '';
        setState({ formControl, val: '' })
    };
    const deleteItem = id => {
        const formControl = state.formControl;
        formControl.to.arr.splice(id, 1);
        setState({ formControl })
    };
    return (state.loading) ? <Loader contain /> : <div className="sbform create-activ">
        <div className="clear" />
        <AutoSelect
            label={"Кому"}
            link={`/ajax/${props.type}/suggest?term=`}
            minLength="0"
            result="name"
            event="to"
            //errorMessage={"Введите корректный Email"}
            value={state.formControl.to.value}
            shouldValidate={true}
            setField={event => setField(event, "to")}
            valid={true}
            updateData={updateDataTo}
            deleteItem={deleteItem}
            createTag
            multiSelect={state.formControl.to.arr}
            multiSelectValid={true}
            itemType={`item`}
        />
    </div>
};


export const TagsNew = ({ entity, editorTags, setValues }) => {
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef(null)
    useOnClickOutside({ ref: dropdownRef, handler: () => setIsOpen(false) })

    const text = `Теги ${((editorTags.length > 0) ? `(${editorTags.split(',').length})` : '(0)')}`
    return (
        <div className="tagsName" ref={dropdownRef}>
            <a
                onClick={() => setIsOpen(!isOpen)} className="success fl-l btni mr5" >
                {isOpen ? 'Закрыть' : text}
            </a>
            <CSSTransition in={isOpen} timeout={200} classNames="slide" unmountOnExit>
                <div className="tagsName__dropdown">
                    <EditTags
                        tags={editorTags}
                        type={entity}
                        refreshState={tags => tags !== editorTags && setValues(tags)}
                    />
                </div>
            </CSSTransition>
        </div>
    )
}