 import React, {Component} from 'react';
import myaxios from '../../components/Axios';
import Notif from '../../components/Notif';
import Loader from '../../components/UI/Loader';
import {withRouter} from 'react-router-dom';
import Input from '../../components/UI/Input';
import AutoSelect from '../../components/UI/AutoSelect'
import EditTags from '../../Modal/EditTags';
import Bgdrop from '../../Modal/Bgdrop';

class MailDetail extends Component {
    state = {
        list:[],
        open:'',
        openType: "",
        openTagId: null,
        tags:"",
        stageControl: {
            name: {value: '',},
            owner: {value: ''},
        },
        owner_id: null
    };
    saveOppotunity = (id) => {
        if(this.state.owner_id){
            const stageControl = this.state.stageControl;
            this.setState({ loading:true });
            myaxios.post(`/ajax/mail/${id}/convert`, {
                data: {
                    name: "test",
                    least_loaded: null,
                    owner_id: this.state.owner_id
                }}).then((responce) =>{
                if(responce.status === 200 || responce.status === 201 || responce.status === 204) {
                    this.showMail();
                    stageControl.owner.value = '';
                    this.setState({ loading: false, stageControl, owner_id:null });
                    Notif("save", "Письмо успешно квалифицированно");
                }
            }).catch((error)=>{
                this.setState({ loading: false });
            });
        }
    }

    updateData = (id, arr) => {
        const stageControl = this.state.stageControl;
        stageControl.owner.value = arr.name;
        this.setState({
            stageControl, owner_id: id
        })
    }

    /*Изменение полей*/
    setField = (event, type) => {const stageControl = {  ...this.state.stageControl }; const control = { ...stageControl[type] };
        control.value = event.target.value; stageControl[type] = control; this.setState({stageControl, tch: false}) };
    componentDidMount(){
        this.showMail();
    }
    showMail = () => {
        myaxios.get(`/ajax${this.props.match.url}/thread?expand=related`, {method: 'get',}).then((responce) =>{
            this.setState({ list: responce.data.items })
        }).catch((error)=>{
            this.setState({ loading: false });
        });
    };
    deleteMail = (mail_id, folder, account_id) => {
        if(window.confirm()) {
            myaxios.post(`/ajax/mail/${account_id}/delete`,{
                ids: mail_id
            }).then(responce => {
                if(responce.status === 200) {
                    if(this.state.list.length >= 2){
                        this.showMail();
                    } else {
                        this.props.history.push(`/mail/${account_id}/${folder}`);
                    }
                    Notif("delete", `Письмо успешно удалено`);
                }
            })
        }
    };
    leftBar(item, arr){
        switch(item){
            case 1: return <div className="mail-contact"><b>Сделка</b><div className="card-block">
                <em>Название</em><a onClick={() => this.props.history.push('/opportunity/' + arr.id)}>{arr.name}</a>
            </div></div>; break;

            case 2: return <div className="mail-contact"><b>Контакт</b><div className="card-block">
                <em>ФИО</em><a onClick={() => this.props.history.push('/contact/' + arr.id)}>{arr.name}</a>
            </div></div>; break;

            case 3: return <div className="mail-contact"><b>Контрагент</b><div className="card-block">
                <em>Название</em><a onClick={() => this.props.history.push('/account/' + arr.id)}>{arr.name}</a>
            </div></div>; break;

            case 6: return <div className="mail-contact"><b>Проект</b><div className="card-block">
                <em>Название</em><a onClick={() => this.props.history.push('/project/' + arr.id)}>{arr.name}</a>
            </div></div>; break;

            case 13: return <div className="mail-contact"><b>Счет</b><div className="card-block">
                <em>Номер счета</em><a onClick={() => this.props.history.push('/invoice/' + arr.id)}>{arr.name}</a>
            </div></div>; break;

            case 14: return <div className="mail-contact"><b>Заказ</b><div className="card-block">
                <em>Название</em><a onClick={() => this.props.history.push('/order/' + arr.id)}>{arr.name}</a>
            </div></div>; break;

            case 23: return <div className="mail-contact"><b>Лид</b><div className="card-block">
                <em>Название</em><a onClick={() => this.props.history.push('/lead/' + arr.id)}>{arr.name}</a>
            </div></div>; break;
        }
    }
    searchId = (id, arr) => {
        console.log(id, arr)
        if(arr){
            let a = true;
            for(let i = 0; i < arr.length; i++){
                if(arr[i]){
                    if(arr[i].record_type === id) a = false;
                }
            }
            return a;
        }
    }
    render(){
        return <div className="page mail-page">
            <div className="butt-group">
                {/*<div className="tagsName">
                 <a data-tooltip={this.state.editorTags.length > 0 ? this.state.editorTags : null} onClick={() => this.setState({ open:'r-open', openType: "tags" })} className="success fl-l btni mr10" >
                 Теги ({(this.state.editorTags.length > 0) ? this.state.editorTags.split(',').length : '0'})</a>
                 </div>*/}
                <a onClick={() => this.props.updateData("SendMail", null, "fullwidthmodal")} className="success fl-l btni" href={null}>Написать письмо</a>
            </div>
            <div className="clear"/>

            {
                this.state.list.map((item, index) =>
                    <div className="emailItem" style={{ marginTop: "15px" }}>
                        <div className="mailbox">
                            {
                                <div className="mailleft" style={{width: "25%", float: "right", padding: "0 10px 0 0"}}>
                                    {(item.is_sent === 1 || !this.searchId(1, item.related) ) ? null :
                                        <div className="mail-contact">
                                            <b>Сделка</b>
                                            <div className="sbform">
                                                {
                                                    <AutoSelect
                                                        label="Ответственный"
                                                        link="/ajax/settings/user?filter[username][like]="
                                                        minLength="0"
                                                        result="name"
                                                        event="field"
                                                        value={this.state.stageControl.owner.value}
                                                        shouldValidate={false}
                                                        setField={event => this.setField(event, "owner")}
                                                        updateData={this.updateData}
                                                    />
                                                }
                                                <a onClick={() => this.saveOppotunity(item.id)} className="success btn-m">Отправить на просчет</a>
                                            </div>
                                        </div>
                                    }
                                    {
                                        item.related.map((item, index) =>
                                            this.leftBar(item.record_type, item.related)
                                        )
                                    }
                                </div>
                            }

                            <div className="mailbody"
                                 style={{
                                     width: (item.is_sent === 1) ? '70%' : "75%",
                                     background: (item.is_sent === 1) ? '' : "#fff",
                                     boxShadow: (item.is_sent === 1) ? '' :  "1px 0px 20px rgba(0, 0, 0, 0.08)",
                                     border: (item.is_sent === 1) ? '2px solid #bbb' : "2px solid #62a090",
                                     marginLeft: (item.is_sent === 1) ? "5%" : "0%"
                                 }}>
                                <div className="body-headers">
                                    <div className="wrap-h">{item.subject}</div>
                                    <div className="mail-body-time">{item.date}; Кому: <em style={{textDecoration: "underline"}}>{item.to}</em>; От: <em style={{textDecoration: "underline"}}>{item.from}; </em>
                                        <b onClick={ () => this.setState({ open: "r-open", openType: "tags", openTagId: item.id, tags:item.editorTags }) }>Теги:</b> <em style={{textDecoration: "underline"}}>{item.editorTags}</em></div>
                                    <div className="mail-body-todo">
                                        {(item.is_sent === 1) ? null : <a data-tooltip="Ответить" onClick={() => this.props.updateData("SendMail", {id: item.id, from: item.from, subject: item.subject}, "fullwidthmodal" )} href={null}><i className="flaticon-reply"/></a>}
                                        {/*<a data-tooltip="Переслать" onClick={() => this.props.updateData("ForwardMail", {id: this.props.match.params.id, from: this.state.from, subject: this.state.subject}, "fullwidthmodal" )}><i className="flaticon-more"/></a>*/}
                                        <a data-tooltip="Удалить" onClick={() => this.deleteMail(item.id, item.folder, item.mail_account_id)} href={null}><i className="flaticon-delete-1"/></a>
                                    </div>
                                </div>
                                {(item.has_html_body === 1) ?  <iframe width={"100%"} style={{ height: '100vh' }} srcDoc={item.html_body} /> : <p dangerouslySetInnerHTML={{__html: item.text_body}} />}

                                {item.attachments ? item.attachments.length >= 1 ? <em className="mail-files-em">Прикрепленные файлы</em> : null : null}
                                <div className="mail-files">
                                    {/*<a href="#"><img src="https://i1.wp.com/crosstrac.net/wp-content/uploads/2018/04/PDF-Icon.png" alt="" /></a>*/}
                                    {
                                        item.attachments ? item.attachments.map((file, index) =>
                                            <a key={index} title={item.name}
                                               href={`/ajax/mail/${item.mail_account_id}/inbox/${item.id}/download/${file.name}`}
                                               className="attachmentItem">{file.name.slice(0, 30)}</a>
                                        ) : null
                                    }
                                </div>
                                {/*<div className="mail-btn">
                                    <a onClick={() => this.props.updateData("SendMail", {id: this.props.match.params.id, from: item.from, subject: item.subject}, "fullwidthmodal" )} href={null}>Ответить <i className="flaticon-reply"/></a>
                                </div>*/}
                            </div>
                        </div>
                        <div className="clear" />
                    </div>
                )
            }
            <React.Fragment>
                <div className={`right-modal scrollbar ${this.state.open}`}><div className="save">
                    {(this.state.open === "r-open")
                        ? <EditTags
                            close={() => this.setState({ open:'', openType:'', openTagId: null, tags:"" })}
                            tags={this.state.tags}
                            type="mail"
                            id={this.state.openTagId}
                            refresh={()=>{}}
                            //refreshState={(tags) => {this.setState({ editorTags: tags })} }
                            refreshState={this.showMail}
                        /> : null}
                </div></div>
                {this.state.open ? <Bgdrop close={() => this.setState({ open:'', openTags: '', openTagId: null, tags:"" })} /> : null}
            </React.Fragment>
        </div>
    }
}

/*class MailDetail2 extends Component {
    state = {
        name: '',
        subject: '',
        from: '',
        date: '',
        text_body: '',
        html_body:'',
        attachments: [],
        loading: true,
        mail_account_id: '',
        account:null,
        contact: null,
        opportunity: null,
        invoice:null,
        order:null,
        project:null,
        lead:null,
        folder: '',
        stageControl: {
            name: {value: '',},
            owner: {value: ''},
        },
        showStageBlock: "none",
        owner_id: null,
        least_loaded: null,
        check: false,
        has_text_body:0,
        has_html_body:0,
        editorTags:'',
        open:'',
        openType:'tags'
    };
    showMail = () => {
        myaxios.get(`/ajax${this.props.match.url}?expand=related`, {method: 'get',}).then((responce) =>{
            responce.data.related.map(item =>
                (item.record_type === 1) ? this.setState({ opportunity: item.related }) :
                (item.record_type === 2) ? this.setState({ contact: item.related }) :
                (item.record_type === 3) ? this.setState({ account: item.related }) :
                (item.record_type === 13) ? this.setState({ invoice: item.related }) :
                (item.record_type === 14) ? this.setState({ order: item.related }) :
                (item.record_type === 23) ? this.setState({ lead: item.related }) :
                (item.record_type === 6) ? this.setState({ project: item.related }) : null
            )
            this.setState({
                from: responce.data.from,
                subject: responce.data.subject,
                date: responce.data.date,
                text_body: responce.data.text_body,
                html_body:responce.data.html_body,
                attachments: responce.data.attachments,
                mail_account_id: responce.data.mail_account_id,
                folder: responce.data.folder,
                name: responce.data.personal_name,
                has_text_body: responce.data.has_text_body,
                has_html_body: responce.data.has_html_body,
                loading: false,
                editorTags: responce.data.editorTags
            })
        }).catch((error)=>{
            this.setState({ loading: false });
        });
    };
    saveContact = (entity) => {
        this.setState({loading:true})
        myaxios.post(`/ajax/mail/${this.props.match.params.id}/convert-entity/${entity}`).then((responce) =>{
            if(responce.status === 200 || responce.status === 201 || responce.status === 204) {
                this.setState({ contact: {id: responce.data.data.id, name: responce.data.data.name}, loading:false });
                this.showMail();
                Notif("save", "Контакт успешно сохранен");
            }
        }).catch((error)=>{
            this.setState({ loading: false });
        });
    }

    saveOppotunity = () => {
        this.setState({ loading:true })
        myaxios.post(`/ajax/mail/${this.props.match.params.id}/convert`, {
            data: {
                name: "test",
                least_loaded: (this.state.check) ? "1" : null,
                owner_id: (!this.state.check) ? this.state.owner_id : null
            }}).then((responce) =>{
            if(responce.status === 200 || responce.status === 201 || responce.status === 204) {
                this.showMail();
                this.setState({ loading: false });
                Notif("save", "Письмо успешно квалифицированно");
            }
        }).catch((error)=>{
            this.setState({ loading: false });
        });
    }

    componentDidMount(){
        this.showMail();
    }
    deleteMail = () => {
        if(window.confirm()) {
            myaxios.post(`/ajax/mail/${this.state.mail_account_id}/delete`,{
                ids: this.props.match.params.id
            }).then(responce => {
                if(responce.status === 200) {
                    this.props.history.push(`/mail/${this.state.mail_account_id}/${this.state.folder}`);
                    Notif("delete", `Письмо успешно удалено`);
                }
            })
        }
    };

    updateData = (id, arr) => {
        const stageControl = this.state.stageControl;
        stageControl.owner.value = arr.name;
        this.setState({
            stageControl, owner_id: id
        })
    }

    /!*Изменение полей*!/
    setField = (event, type) => {const stageControl = {  ...this.state.stageControl }; const control = { ...stageControl[type] };
        control.value = event.target.value; stageControl[type] = control; this.setState({stageControl, tch: false}) };
    render(){
        return (this.state.loading) ? <Loader /> : <div className="page mail-page">
            <div className="butt-group">
                <div className="tagsName">
                    <a data-tooltip={this.state.editorTags.length > 0 ? this.state.editorTags : null} onClick={() => this.setState({ open:'r-open', openType: "tags" })} className="success fl-l btni mr10" >
                        Теги ({(this.state.editorTags.length > 0) ? this.state.editorTags.split(',').length : '0'})</a>
                </div>
                <a onClick={() => this.props.updateData("SendMail", null, "fullwidthmodal")} className="success fl-l btni" href={null}>Написать письмо</a>
            </div>
            <div className="clear"/>
            <div className="mailbox">
                <div className="mailsearch">
                </div>
                <div className="clear"/>
                <div className="mailleft" style={{width: "25%", float: "right", padding: "0 10px 0 0"}}>
                    <div style={{display: (this.state.name) ? "block" : "none"}} className="mail-contact">
                        <i className="flaticon-user"/>
                        <span>{this.state.name ? this.state.name.substring(0,30) : null}</span>
                    </div>
                    <div className="mail-contact">
                        <i className="flaticon-multimedia-3"/>
                        <span>{this.state.from ? this.state.from.substring(0,30) : null}</span>
                    </div>
                    {(!this.state.contact && !this.state.account) ?
                            <div className="mail-contact">
                                <b>Контакт</b>
                                <a onClick={() => this.saveContact("contact")} className="success btn-m">Создать контакт</a>
                                <a onClick={() => this.saveContact("account")} className="success btn-m">Создать контрагент</a>
                            </div> : null}
                    {(this.state.contact) ? <div className="mail-contact">
                            <b>Контакт</b>
                            <div className="card-block">
                                <em>ФИО</em>
                                <a onClick={() => this.props.history.push('/contact/' + this.state.contact.id)}>{this.state.contact.name}</a>
                            </div>
                        </div> : null}
                    {(this.state.account) ? <div className="mail-contact">
                        <b>Контрагент</b>
                        <div className="card-block">
                            <em>Название</em>
                            <a onClick={() => this.props.history.push('/account/' + this.state.account.id)}>{this.state.account.name}</a>
                        </div>
                    </div> : null}
                    {(this.state.invoice) ? <div className="mail-contact">
                        <b>Счет</b>
                        <div className="card-block">
                            <em>Номер счета</em>
                            <a onClick={() => this.props.history.push('/invoice/' + this.state.invoice.id)}>{this.state.invoice.name}</a>
                        </div>
                    </div> : null}
                    {(this.state.order) ? <div className="mail-contact">
                        <b>Заказ</b>
                        <div className="card-block">
                            <em>Название</em>
                            <a onClick={() => this.props.history.push('/order/' + this.state.order.id)}>{this.state.order.name}</a>
                        </div>
                    </div> : null}
                    {(this.state.project) ? <div className="mail-contact">
                        <b>Проект</b>
                        <div className="card-block">
                            <em>Название</em>
                            <a onClick={() => this.props.history.push('/project/' + this.state.project.id)}>{this.state.project.name}</a>
                        </div>
                    </div> : null}
                    {(this.state.lead) ? <div className="mail-contact">
                        <b>Лид</b>
                        <div className="card-block">
                            <em>Название</em>
                            <a onClick={() => this.props.history.push('/lead/' + this.state.lead.id)}>{this.state.lead.name}</a>
                        </div>
                    </div> : null}
                    {
                        (this.state.opportunity) ?
                            <div className="mail-contact">
                                <b>Сделка</b>
                                <div className="card-block">
                                    <em>Название</em>
                                    <a onClick={() => this.props.history.push('/opportunity/' + this.state.opportunity.id)}>{this.state.opportunity.name}</a>
                                </div>
                            </div> :
                            <div className="mail-contact">
                                <b>Сделка</b>
                                <a style={{ display: (this.state.showStageBlock === "block") ? "none" : "block" }} onClick={() => this.setState({ showStageBlock: "block" })} className="success btn-m">Отправить на просчет</a>
                                <div className="sbform" style={{display: this.state.showStageBlock}}>
                                    {/!*<Input
                                        label="Название сделки"
                                        value={this.state.stageControl.name.value}
                                        onChange={event => this.setField(event, "name")}
                                        shouldValidate={false}
                                    />*!/}
                                    {
                                        (!this.state.check) ?
                                            <AutoSelect
                                                label="Ответственный"
                                                link="/ajax/settings/user?filter[username][like]="
                                                minLength="0"
                                                result="name"
                                                event="field"
                                                value={this.state.stageControl.owner.value}
                                                shouldValidate={false}
                                                setField={event => this.setField(event, "owner")}
                                                updateData={this.updateData}
                                            /> : null
                                    }
                                    {/!*<label style={{padding: "0px", margin: "10px 0px 0 -5px"}}>
                                        <input checked={this.state.check} onClick={() => this.setState({ check: (!this.state.check) })} className="checkbox checkboxlead" type="checkbox" name="checkbox-test" />
                                        <span className="checkbox-custom" />
                                        <em style={{ display: "inline-block", marginLeft: "12px", position: "relative", top: "-2px"}}>Отдать свободному менеджеру</em></label>*!/}
                                    <a onClick={this.saveOppotunity} className="success btn-m">Отправить на просчет</a>
                                </div>
                            </div>
                    }
                </div>
                <div className="mailbody" style={{width: "75%"}}>
                    <div className="body-headers">
                        <div className="wrap-h">{this.state.subject}</div>
                        <div className="mail-body-time">{this.state.date}</div>
                        <div className="mail-body-todo">
                            <a data-tooltip="Ответить" onClick={() => this.props.updateData("SendMail", {id: this.props.match.params.id, from: this.state.from, subject: this.state.subject}, "fullwidthmodal" )} href={null}><i className="flaticon-reply"/></a>
                            {/!*<a data-tooltip="Переслать" onClick={() => this.props.updateData("ForwardMail", {id: this.props.match.params.id, from: this.state.from, subject: this.state.subject}, "fullwidthmodal" )}><i className="flaticon-more"/></a>*!/}
                            <a data-tooltip="Удалить" onClick={this.deleteMail} href={null}><i className="flaticon-delete-1"/></a>
                        </div>
                    </div>
                    {
                        (this.state.has_html_body === 1) ? <p dangerouslySetInnerHTML={{__html: this.state.html_body}} /> : <p dangerouslySetInnerHTML={{__html: this.state.text_body}} />
                    }

                    <em className="mail-files-em">Прикрепленные файлы</em>
                    <div className="mail-files">
                        {/!*<a href="#"><img src="https://i1.wp.com/crosstrac.net/wp-content/uploads/2018/04/PDF-Icon.png" alt="" /></a>*!/}
                        {
                            this.state.attachments.map((item, index) =>
                                <a key={index} title={item.name}
                                   href={`/ajax/mail/${this.state.mail_account_id}/inbox/${this.props.match.params.id}/download/${item.name}`}
                                   className="attachmentItem">{item.name.slice(0, 30)}</a>
                            )
                        }
                    </div>
                    <div className="mail-btn">
                        <a onClick={() => this.props.updateData("SendMail", {id: this.props.match.params.id, from: this.state.from, subject: this.state.subject}, "fullwidthmodal" )} href={null}>Ответить <i className="flaticon-reply"/></a>
                        {/!*<a href="#" className="danger">Сохранить контакт <i className="flaticon-user"/></a>*!/}
                    </div>
                </div>
            </div>
            <React.Fragment>
                <div className={`right-modal scrollbar ${this.state.open}`}><div className="save">
                    {(this.state.open === "r-open")
                        ? <EditTags
                            close={() => this.setState({ open:'', openType:''})}
                            tags={this.state.editorTags}
                            type="mail"
                            id={this.props.match.params.id}
                            refresh={() => {}}
                            refreshState={(tags) => {this.setState({ editorTags: tags })} }
                        /> : null}
                </div></div>
                {this.state.open ? <Bgdrop close={() => this.setState({ open:'', openTags: '' })} /> : null}
            </React.Fragment>
        </div>
    }
}*/

export default withRouter(MailDetail);
