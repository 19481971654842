import React, {useEffect, useState, useRef} from "react";
import myaxios from "../Axios";
import Loader, {ModalLoader} from "../UI/Loader";
import {ListFilter} from "./ListFilter";
import {entity} from "../ListComponentEntity";
import {NavLink} from "react-router-dom";
import Notif from "../Notif";
import {ListColumnSettings} from "./components/ListColumnSettings";
import {Pagination} from "./components/Pagination";
import {buildExpandLink} from "./components/ExtraColumnsRender";
import {ListContextMenu} from "./components/ListContextMenu";
import {useOnClickOutside} from "../hooks/useOnClickOutside";
import {CSSTransition} from "react-transition-group";
import {TableHead} from "./components/TableHead";
import {TableBody} from "./components/TableBody";
import {convertFilter} from "./components/convertFilter";
import {ModalListContainer} from "./components/ModalListContainer";
import StockNav from "../../containers/stock/StockNav";
import {CanDelete} from "../../Modal/CanDelete";

// export const TestProducts = (props) => {
//     return (
//         <NewList
//             { ...props }
//             entity={'invoice'}
//             entity_type={13}
//             requiredFields={'id,name,status_id,cost,payment,contact_id,account_id'}
//             requiredExpand={'contact,account,currency,status'}
//             deleteToShowColumn={'id,name,status_id,cost,payment,contact_id,account_id'}
//             additionalFieldsExists={true}
//             isFilterExists={true}
//             filterFields={entity.invoice.fArr}
//             singleSearch={entity.invoice.fArr[0]}
//             headingColumns={['Счет/клиент', 'Статус', 'Сумма']}
//             RenderColumn={(item) => (
//                 <>
//                     <td>
//                         <NavLink to={`/invoice/${item.id}`}  className={'nowrap-list-column'}>{item.name}</NavLink>
//                         {item.contact && <div className={'nowrap-list-column'}>Клиент: <NavLink to={`/contact/${item.contact.id}`}>{item.contact.name}</NavLink></div>}
//                         {item.account && <div className={'nowrap-list-column'}>Клиент: <NavLink to={`/account/${item.account.id}`}>{item.account.name}</NavLink></div>}
//                     </td>
//                     <td>
//                         {item.status && <span className="label-st" style={{ backgroundColor: 'rgb(255, 193, 100)' }}>{item.status.value}</span>}
//                     </td>
//                     <td>
//                         <div>К оплате: {item.cost}{item.currency.text_symbol}</div>
//                         <div>Оплачено: {item.payment}{item.currency.text_symbol}</div>
//                     </td>
//                 </>
//             )}
//             rightNavbarConfig={{
//                 export: true,
//                 additionalFields: '/fieldsettings/13',
//                 columns: true
//             }}
//             massDoConfig={{ deleteAll: `/ajax/invoice/delete-all` }}
//             contextNav={{ edit: true, task: true, print: true, delete: true }}
//             sort={entity.invoice.fArr.filter(el => el.sort)}
//         />
//     )
// }

const MassDo = ({ deleteAll, tags, onDeleteAll }) => {
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef(null)
    useOnClickOutside({ ref: dropdownRef, handler: () => setIsOpen(false) })

    return (
        <div style={{ position: 'relative', marginLeft: '5px' }} ref={dropdownRef}>
            <a onClick={() => setIsOpen(!isOpen)} className="open-modal-button btni">Действия</a>
            <CSSTransition in={isOpen} timeout={200} classNames="my-node" unmountOnExit>
                <div className="toogle-list my-node-enter-done" style={{ display: 'block', top: '35px', left: 0 }}>
                    {deleteAll && <li style={{ listStyle: 'none' }}><a onClick={onDeleteAll}><b>Удалить элементы</b></a></li>}
                    {tags && <li style={{ listStyle: 'none' }}><a><b>Добавить теги</b></a></li>}
                </div>
            </CSSTransition>
        </div>
    )
}

export const NewList = ({
    entity,
    entity_type,
    requiredFields,
    requiredExpand,
    filterFields,
    additionalFieldsExists = false,
    isFilterExists = false,
    userId,
    RenderColumn,
    headingColumns = [],
    deleteToShowColumn = '',
    history,
    rightNavbarConfig,
    updateData,
    massDoConfig,
    contextNav,
    singleSearch,
    sort,
    analytics,
    createButtonRender,
    onCreatedResult,
    stockNav,
    topNav,
    dropdownRender,
    updatedNumber,
    hidePerPage,
    updateOutElements = () => {}
}) => {
    const [loading, setLoading] = useState(true)
    const [list, setList] = useState([])
    const [isColumnsOpen, setIsColumnsOpen] = useState(false)
    const [filters, setFilters] = useState([])
    const [columns, setColumns] = useState([])
    const [sortActive, setSortActive] = useState('-created_at')
    const [filterId, setFilterId] = useState('')
    const [additionalFields, setAdditionalFields] = useState([])
    const [contextMenu, setContextMenu] = useState(null)
    const [checkItems, setCheckItems] = useState([])
    const [activeCheckItems, setActiveCheckItems] = useState(false)
    const [totalCount, setTotalCount] = useState(0)
    const [modalType, setModalType] = useState('')
    const [modalData, setModalData] = useState(null)
    const urlSearchParams = new URLSearchParams(window.location.search);
    const query = Object.fromEntries(urlSearchParams.entries());

    const perPage = localStorage.getItem(entity + 'PerPage') || 20;
    const getData = async () => {
        setLoading(true)

        let sortOrder = ''
        let resColumns = ''
        let resColumnsData = ''

        try {
            const filterData = await myaxios.get(`/ajax/filter?filter[entity_type]=${entity_type}&[user_id]=${userId}`)
            let filterLink = '';

            if(isFilterExists && filterData.data.items && filterData.data.items[0]) {
                setFilters(filterData.data.items[0].filterAttributes || [])
                if(filterData.data.items[0]){
                    filterLink = (convertFilter(filterData.data.items[0].filterAttributes || []))
                    setFilterId(filterData.data.items[0].id)
                    setColumns(filterData.data.items[0].columns || [])
                    setSortActive(filterData.data.items[0].order || '-created_at')
                }

                sortOrder = (filterData.data && filterData.data.items && filterData.data.items[0] && filterData.data.items[0].order) || '-created_at'
                resColumns = filterData.data && filterData.data.items && filterData.data.items[0] && filterData.data.items[0].columns
                resColumnsData = resColumns.length > 0 ? `,${resColumns.join(',')}` : ''
            }

            const { data, status } =
                await myaxios.get(`/ajax/${entity}?sort=${sortOrder}&per-page=${perPage}&expand=${requiredExpand}${buildExpandLink(resColumnsData)}&fields=${requiredFields}${resColumnsData}&page=${query.page || 1}${query.direct ? window.location.search.substr(9) : filterLink}`);
            if(status === 200) {
                if(additionalFieldsExists) {
                    setAdditionalFields(data.attributes.map(el => ({
                        value: el.field_id,
                        label: el.label,
                        fieldType: (el.type === "select" || el.type === "multiselect") ? 'select' : (el.type === "date") ? 'date' : 'text',
                        options: el.options
                    })))
                }
                setList(data.items)
                setTotalCount(+data._meta.totalCount)
                updateOutElements()
            }
        } catch (e) {
            console.error(e)
        }
        setLoading(false)
    }

    useEffect(() => {
        (async () => {
            await getData()
        })()
    }, [query.page, updatedNumber])

    const deleteSingleElement = async (id) => {
        if(!window.confirm('Удалить элемент?')) return;
        setLoading(true)

        const { data, status } = await myaxios.delete(`/ajax/${entity}/${id}`)
        if(status === 200 || status === 204) {
            if(!data.canDelete) {
                setContextMenu(null)
                await getData()
            } else if(data.canDelete && data.items) {
                setModalData({ type: 'canDelete', items: data.items })
            } else {
                setContextMenu(null)
                Notif('Error', 'При удалении что-то пошло не так')
            }
        }

        setLoading(false)
    }

    const saveFilter = async (params = [], clearId, editedColumns, editedSort) => {
        setLoading(true)

        const postData = {
            name: entity,
            entity_type: entity_type,
            user_id: userId || 1,
            filter_attributes: clearId ? filters.filter(el => el.id != clearId) : [...filters, ...params],
            columns: editedColumns || columns,
            order: editedSort || sortActive
        }

        const { status, data } = filterId ?
            await myaxios.put(`/ajax/filter/${filterId}`, postData) : await myaxios.post(`/ajax/filter`, postData)

        if(status === 200 || status === 201) {
            setIsColumnsOpen(false)
            Notif('save', 'Фильтр применен')
            await getData()
        }

        setLoading(false)
    };

    const deleteAll = async () => {
        if(!window.confirm('Удалить элементы?')) return;

        setLoading(true)
        try {
            const { status, data } = await myaxios.post(massDoConfig.deleteAll, { ids: checkItems })
            if(status === 200 && data) {
                if(data.deleted && data.deleted.length > 0) {
                    Notif('delete', `Удалено ${data.deleted.length} записей`)
                    setCheckItems(checkItems.filter(el => !data.deleted.includes(el)))
                }
                if(data.not_deleted && data.not_deleted.length > 0) {
                    Notif('Error', `Не удалено ${data.not_deleted.length} записей`)
                }
                await getData()
            }
        } catch (e) {

        }

        setLoading(false)
    }

    const checkElement = (id) => {
        setActiveCheckItems(true)
        !checkItems.includes(id) ?
            setCheckItems(prevState => ([...prevState, id])) :
            setCheckItems(prevState => ( prevState.filter(el => el !== id) ))
    }

    const setPerPage = (perPage) => {
        localStorage.setItem(entity + 'PerPage', perPage)
        setTimeout(() => window.location.reload(), 200)
    }

    return (
        <div className={'page'}>
            {stockNav && <StockNav />}
            {topNav && topNav()}
            {<CSSTransition in={loading} timeout={100} classNames="my-node" unmountOnExit>
                <ModalLoader />
            </CSSTransition>}
            <ListFilter
                { ...{ saveFilter, filters, rightNavbarConfig, setIsColumnsOpen, singleSearch, updateData, entity, sort, sortActive }}
                filterFields={[ ...filterFields, ...additionalFields ]}
                LeftRender={() => (
                    <>
                        {analytics && <NavLink to={analytics} data-tooltip="Отчеты" className="xsn btns"><i className="flaticon-diagram" /></NavLink>}
                        {createButtonRender && createButtonRender(setModalType)}
                        {!!massDoConfig && checkItems.length > 0 && <MassDo {...massDoConfig} onDeleteAll={deleteAll} />}
                    </>
                )}
                dropdownRender={dropdownRender}
                perPage={!hidePerPage && (localStorage.getItem(entity + 'PerPage') || 20)}
                setPerPage={setPerPage}
            />
            <div className="contact-page sp-table" style={{ marginTop: 0 }}>
                <table>
                    {list.length > 0 && <TableHead {...{
                            massDoConfig,
                            activeCheckItems,
                            checkItems,
                            setCheckItems,
                            headingColumns,
                            list,
                            columns,
                            filterFields,
                            additionalFields
                        }}
                    />}
                    {
                        list.length === 0 && <div className={'list-is-empty'}>Список пуст</div>
                    }
                    <TableBody
                        {...{
                            list,
                            history,
                            entity,
                            checkElement,
                            contextMenu,
                            setContextMenu,
                            massDoConfig,
                            activeCheckItems,
                            checkItems,
                            columns,
                            filterFields,
                            additionalFields,
                            RenderColumn,
                            setModalType,
                            setModalData
                        }}
                    />
                </table>
            </div>
            {list.length > 0 && <Pagination
                pagesAmount={Math.ceil(totalCount / perPage )}
                currentPage={+query.page || 1}
                setCurrentPage={page => {
                    history.push(`${window.location.pathname}?page=${page}`)
                    setCheckItems([])
                }}
            />}
            <ListColumnSettings
                {...{ columns, saveFilter, deleteToShowColumn, loading }}
                isOpen={isColumnsOpen}
                close={() => setIsColumnsOpen(false)}
                filterFields={[ ...filterFields, ...additionalFields ]}
            />
            {contextNav && <ListContextMenu
                contextMenu={contextMenu}
                close={() => setContextMenu(null)}
            >
                <>
                    {!!massDoConfig && <li onClick={() => { setContextMenu(null); checkElement(contextMenu.id); }} className={'listContext-delete'}>Выбрать (CTRL + клик)</li>}
                    {contextNav.edit && <li onClick={() => history.push(`/${entity}/${contextMenu.id}`)} className={'listContext-delete'}>Редактировать (double click)</li>}
                    {contextNav.task && <li onClick={() => { setModalType('task'); setContextMenu(null); setModalData({ related: { id: contextMenu.id, type: entity_type } }) }} className={'listContext-delete'}>Добавить задачу</li>}
                    {contextNav.print && <li onClick={() => { setContextMenu(null); setModalType('print'); setModalData({ id: contextMenu.id }) }} className={'listContext-delete'}>Печать</li>}
                    {contextNav.clone && <li onClick={() => history.push(`/${entity}/${contextMenu.id}`)} className={'listContext-delete'}>Клонировать</li>}
                    {contextNav.delete && <li onClick={() => deleteSingleElement(contextMenu.id)} className={'listContext-delete'}>Удалить</li>}
                </>
            </ListContextMenu>}
            <ModalListContainer
                {...{ entity, entity_type, history }}
                type={modalType}
                data={modalData}
                close={() => { setModalType(''); setModalData(null) }}
                onUpdateResult={onCreatedResult === 'update-list' && getData}
            />
            <CanDelete
                isOpen={modalData && modalData.type === 'canDelete'}
                close={() => setModalData(null)}
                items={modalData && modalData.items}
                entity={entity} delParent={deleteSingleElement}
                name={'запись'}
                id={contextMenu && contextMenu.id} history={history}
            />
        </div>
    )
}