import React, {Component} from 'react';

var placeholder = document.createElement("li");
placeholder.className = "placeholder";

class List extends React.Component {
    constructor(props) {
        super(props);
        this.state = {...props};
    }
    dragStart(e) {
        this.dragged = e.currentTarget;
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('text/html', this.dragged);
    }
    dragEnd(e) {
        this.dragged.style.display = 'block';
        this.dragged.parentNode.removeChild(placeholder);

        // update state
        var data = this.state.colors;
        var from = Number(this.dragged.dataset.id);
        var to = Number(this.over.dataset.id);
        if(from < to) to--;
        data.splice(to, 0, data.splice(from, 1)[0]);
        this.setState({colors: data});
    }
    dragOver(e) {
        e.preventDefault();
        this.dragged.style.display = "none";
        if(e.target.className === 'placeholder') return;
        this.over = e.target;
        e.target.parentNode.insertBefore(placeholder, e.target);
    }
    render() {
        var listItems = this.state.colors.map((item, i) => {
            return (
                <li
                    data-id={i}
                    key={i}
                    draggable='true'
                    onDragEnd={this.dragEnd.bind(this)}
                    onDragStart={this.dragStart.bind(this)}>{item.label}
                </li>
            )
        });
        return (
            <ul onDragOver={this.dragOver.bind(this)}>
                {listItems}
            </ul>
        )
    }
}
class LeadSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            colors: [
                {label: 'red', color: 'red'},
                {label: 'green', color: 'green'},
                {label: 'black', color: 'black'},
                {label: 'yellow', color: 'yellow'},
            ],
            el: ''
        }
    }
    test = e => {
        e.preventDefault();
        console.log(this.state.colors)
    };
    add = () => {
        if(this.state.el.length > 2){
            let colors = this.state.colors;
            colors.push(this.state.el);
            this.setState({ colors })
        }
    };
    set = event => {
        this.setState({ el: event.target.value })
    }
    render() {
        return (
            <div className="tab-content">
                <button onClick={this.test}>get</button>
                <List colors={this.state.colors} />
                <input type="text" onChange={this.set}/>
                <button onClick={this.add}>Add</button>
            </div>
        )
    }
}

export default LeadSettings;