import React, {useEffect, useState} from "react";
import {ModalLoader} from "../../components/UI/Loader";
import {parseResponse, parseResponseError} from "../../components/cardViewComponents/parseFunctions";
import GetPostAditionalFields from "../../components/get_post_aditionalFields";
import {
    deleteOrderService,
    getPrepareOrderService,
    getSingleOrderService,
    updateOrderService
} from "../../services/order/orderService";
import LeaveModal from "../../components/other/LeaveModal";
import {TagsNew} from "../../components/cardViewComponents/TagsNew";
import {MemoButton} from "../../components/cardViewComponents/MemoButton";
import IconButton from "../../components/other/IconButton";
import PrintPlates from "../../Modal/PrintPlates";
import {GenerateForm} from "../../components/cardViewComponents/GenerateForm";
import {ContactCard} from "../../components/cardViewComponents/ContactCard";
import {AccountCard} from "../../components/cardViewComponents/AccountCard";
import {Tasks} from "../../components/Task";
import {TabsRender} from "../../components/fieldGen";
import History from "../../components/history/history";
import {CSSTransition} from "react-transition-group";
import Related from "../../components/related/related";
import Products from "../../components/products";
import {InfoTab} from "./components/InfoTab";
import {getCurrencyService} from "../../services/lookup/lookupSettings";
import myaxios from "../../components/Axios";
import Notif from "../../components/Notif";
import Address from "../../Modal/Address";
import Bgdrop from "../../Modal/Bgdrop";
import CustomCheckbox from "../../components/UI/CustomCheckbox";
import {TaskModal, updateEntityTasks} from "../tasks/TaskModal";
import {DropDownButton} from "../../components/cardViewComponents/DropDownButton";
import CombineOrders from "../../Modal/CombineOrders";
import SplitOrder from "../../Modal/splitOrder";
import CenterModalContainer from "../../Modal/centerModalContainer";
import {additionalFieldsValidation} from "../../components/cardViewComponents/additionalFieldsValidation";
import {validationData} from "../../components/UI/formValid";
import {validationConfig} from "../../components/cardViewComponents/validationConfig";
import {FilesDropDown} from "../../components/FilesDropDown";

export const tabsConfig = [
    {value:"information", label:"Информация"},
    {value:"products", label:"Продукты"},
    {value:"related", label:"Связи"},
    {value:"history", label:"Хронология"},
    {value:"files", label:"Файлы"},
]

const orderConfig = {
    name: {
        label: 'Название',
        type: 'text',
        className: 'w100',
        validation: { required: true, maxLength: 255 },
        zone: 1
    },
    status_id: {
        label: 'Статус заказа',
        type: 'select',
        options: [],
        className: 'w100',
        options_from: 'statuses', val: 'id', lab: 'value',
        validation: { required: true },
        zone: 1,
    },
    is_repeat: {

    },
    stock_id: {
        label: 'Склад',
        type: 'select',
        options: [],
        className: 'w100',
        options_from: 'stocks', val: 'id', lab: 'name',
        validation: { required: true },
        zone: 1,
    },
    end_date: {
        label: 'Дата выполнения',
        type: 'date',
        className: 'w100',
        validation: { maxLength: 255 },
        zone: 1
    },
    source_id: {
        label: 'Источник заказа',
        type: 'entity',
        link: "/ajax/lookup/search?type=ld_source&value=",
        result: 'value',
        entity_from: 'source',
        entityName: 'value',
        className: 'w100',
        minLength: 0,
        zone: 1
    },
    owner_id: {
        label: 'Ответственный',
        type: 'entity',
        link: "/ajax/settings/user?filter[name][like]=",
        result: 'name',
        entity_from: 'owner',
        entityName: 'name',
        className: 'w100',
        minLength: 0,
        validation: { required: true },
        zone: 1
        // redirectLink
    },
    is_reserved: {},
    exchange_rate: {
        validation: { required: true, number: true },
    },
    currency_id: {
        validation: { required: true },
    },
    contact_id: { type: 'entity', entity_from: 'contact' },
    account_id: { type: 'entity', entity_from: 'account' },
    cost: {},
    payment_cost: {},
    address_id: {},
    description: {},
    editorTags: {},
    created_at: {},
    updated_at: {},
}

export const OrderViewNew = ({ match, history, location  }) => {
    const urlParams = new URLSearchParams(location.search);
    const { params: { id } } = match
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState({})
    const [disabled, setDisabled] = useState({})
    const [modalData, setModalData] = useState(null)
    const [values, setValues] = useState({
        name: '',
        status_id: '',
        statuses: [],
        is_repeat: '0',
        stock_id: '',
        stocks: [],
        start_date: null,
        end_date: null,
        opportunity_id: '',
        opportunity: null,
        source_id: '',
        source: null,
        owner_id: '',
        owner: null,
        is_reserved: '0',
        exchange_rate: '',
        currency_id: '',
        currencies: [],
        description: '',
        contact_id: '',
        contact: null,
        account_id: '',
        account: null,
        created_at: '',
        updated_at: '',
        editorTags: '',
        addresses: [],
        files: [],
        tasks: [],
        additionalFields: [],
        contactFields: [],
        project_id: '',
        cost: '',
        payment_cost: '',
        address_id: '',
        begin_update_datetime: null,
        updated_number: 0,
        saved_currency_id: '',
        payments: [],
        tch: true
    })

    const getPrepareOrder = async () => {
        try {
            const { data, status } = await getPrepareOrderService()
            if(status === 200) {
                await getData(data)
            }
        } catch (e) { }
        setLoading(false)
    }

    const getData = async (prepareData) => {
        setLoading(true)

        const res = await getCurrencyService('currency')

        try {
            const { data, status, response } = await getSingleOrderService({
                id, expand: 'tasks,source,contact,additionalFields,account,files,owner,currencies,stock,owner,paymentCost'
            })

            const parseResponseData = parseResponseError(response);
            if(parseResponseData.notFound) history.push('/404')

            const dataArr = {
                ...values,
                ...parseResponse(orderConfig, data, {}),
                exchange_rate: data.exchange_rate || 1,
                statuses: prepareData.statuses,
                currencies: res.data.items,
                stocks: prepareData.stocks,
                files: data.files,
                tasks: data.tasks,
                payment_cost: data.paymentCost,
                additionalFields: GetPostAditionalFields("get", data.additionalFields) || [],
                updated_number: values.updated_number += 1,
                saved_currency_id: data.currency_id
            }

            if(status === 200 && data.id) {
                setValues(dataArr)
                showAddress(dataArr)
            }
        } catch (e) { console.error('getOrderDataError', e) }

        setLoading(false)
    }

    const showAddress = (dataArr) => {
        if(dataArr.account){
            myaxios.get(`/ajax/account/${dataArr.account_id}/addresses?per-page=100`).then((response) => {
                setValues(prevState => ({ ...prevState, addresses: response.data.items }))
            })
        } else if(dataArr.contact) {
            myaxios.get(`/ajax/contact/${dataArr.contact_id}/addresses?per-page=100`).then((response) => {
                setValues(prevState => ({ ...prevState, addresses: response.data.items }))
            })
        } else {
            setValues({ ...dataArr, addresses: [], address_id: null })
        }
    }

    const saveItem = async (forcibly) => {
        const { name, status_id, is_repeat, is_reserved, end_date, source_id, owner_id, exchange_rate, currency_id, description, contact_id, account_id, stock_id,
            updated_at, editorTags, address_id} = values

        if (!contact_id && !account_id) {
            Notif("required-fields", 'К сделке должен быть привязан контакт или контрагент')
            return
        }

        const additionalFieldsValidationRes = additionalFieldsValidation(values.additionalFields)

        const errorsData = validationData(
            {...values, ...additionalFieldsValidationRes.values},
            {...validationConfig(orderConfig), ...additionalFieldsValidationRes.validationRules}
        )

        setErrors(errorsData)
        if(Object.keys(errorsData).length > 0) {
            Notif('required-fields')
            return
        }

        setLoading(true)

        try {
            const { data, status, response } = await updateOrderService({ id, data: {
                    name, description, status_id, is_repeat, is_reserved, end_date, source_id, owner_id, exchange_rate, currency_id, contact_id, account_id, address_id, stock_id,
                    editorTags, begin_update_datetime: forcibly === 'ok' ? null : updated_at,
                    additionalFields: GetPostAditionalFields("post", values.additionalFields),
                }})

            if(status === 200 && data.id) {
                Notif('save', 'Запись успешно изменена')
                setValues(({ ...values, updated_at: data.updated_at, tch: true, begin_update_datetime: null, saved_currency_id: data.currency_id }))
            }

            const parseResponseData = parseResponseError(response);
            if(parseResponseData.saveConflict) {
                if(window.confirm('Запись была изменена другим пользователем. Пересохранить запись принудительно?')){
                    await saveItem('ok')
                }
            }
            if(parseResponseData.validationErrors) {
                setErrors(parseResponseData.validationErrors)
            }

        } catch (e) { console.error(e) }

        setLoading(false)
    }

    useEffect(() => {
        getPrepareOrder().then()
    }, [id])

    const changeFields = (key, value, item) => {
        let obj = {}
        if(item) {
            if(item === 'clear') obj[orderConfig[key].entity_from] = null
            else obj[orderConfig[key].entity_from] = item
        }

        setValues({
            ...values, ...obj, [key]: item ? item.id : value,
            begin_update_datetime: !values.begin_update_datetime ? (new Date()).toLocaleString() : values.begin_update_datetime,
            tch: false
        })
    }

    const createInvoice = () => {
        if(!values.account && !values.contact) {
            Notif('Error', 'Контакт или контрагент должны быть привязаны к заказу')
            return false
        }
        if(window.confirm('Создать счет?')){
            setLoading(true)
            myaxios.post(`/ajax/order/${id}/create-invoice`).then(res => {
                if (res.status === 200 || res.status === 201 || res.status === 204) {
                    history.push(`/invoice/${res.data}`);
                    Notif("save", "Счет успешно создан")
                }
            })
            setLoading(false)
        }
    }
    const createShipment = () => {
        if(!values.account && !values.contact) {
            Notif('Error', 'Контакт или контрагент должны быть привязаны к заказу')
            return false
        }
        if(window.confirm('Создать отгрузку?')){
            setLoading(true)
            myaxios.post(`/ajax/order/${id}/create-shipment`).then(res => {
                if (res.status === 200 || res.status === 201 || res.status === 204) {
                    history.push(`/stock/shipment/${res.data}`);
                    Notif("save", "Отгрузка успешно создана")
                }
            })
            setLoading(false)
        }
    }

    const deleteItem = async () => {
        if(window.confirm('Удалить заказ?')) {
            setLoading(true)
            try {
                const { status } = await deleteOrderService({ id })
                if(status === 200 || status === 204) {
                    setValues(prevState => ({ ...prevState, tch: true }))
                    Notif('delete', 'Заказ успешно удален')
                    history.push('/order')
                }
            } catch (e) { console.error(e) }
            setLoading(false)
        }
    }

    return (
        <>
            {loading && <ModalLoader />}
            <div className="page">
                <LeaveModal when={!values.tch} />
                <div className="newList-btn-group">
                    <div className="newList-btn-group-left">
                        <TagsNew
                            editorTags={values.editorTags} entity={'order'}
                            setValues={data => setValues({ ...values, editorTags: data, tch: false })}
                        />
                        <MemoButton
                            onClick={() => setModalData({ type: 'task' })}
                            mobileIcon={'flaticon-notes mdb'} title={'Добавить задачу'} className={'mr0'} />
                        <DropDownButton
                            label={'Создать документ'}
                            items={({ close }) => <>
                                <li onClick={() => {createInvoice(); close()}}><a>Создать счет на основании заказа</a></li>
                                <li onClick={() => {createShipment(); close()}}><a>Создать отгрузку на основании заказа</a></li>
                                <li onClick={() => { setModalData({ type: 'combineOrders' }); close() }}><a>Обьеденить заказы</a></li>
                                <li onClick={() => { setModalData({ type: 'splitOrder' }); close() }}><a>Разделить заказ</a></li>
                            </>}
                        />
                        <PrintPlates entityId={14} entity={`order`} id={id} />
                    </div>
                    <div className="newList-btn-group-right">
                        <a onClick={deleteItem} className="butt-group-card-wrap btni ml10">
                            <span>Удалить</span>
                            <i className="flaticon-delete-2 mdb" />
                        </a>
                        <MemoButton onClick={saveItem} disabled={values.tch} mobileIcon={"flaticon-interface-1 mdb"} title={'Сохранить'} className={'ml5'} />
                        <IconButton href="https://sboxcrm.com/docs/order" />
                    </div>
                </div>
                <div className="clear" />

                <div className="contact-page sbform">
                    <div className="contact-left">
                        <div className="contact-left-col">
                            <h3>Данные по заказу</h3>
                            <GenerateForm
                                config={orderConfig}
                                { ...{ values, errors, disabled } }
                                onChange={changeFields}
                                onSaveData={saveItem}
                            />
                            <CustomCheckbox
                                checked={values.is_reserved === 1} label={'Резерв заказа'} style={{ margin: '10px 0' }}
                                onChange={() => setValues({ ...values, is_reserved: values.is_reserved === 1 ? 0 : 1, tch: false })}
                            />
                            <div className="clear" />
                        </div>

                        {!values.account && <ContactCard
                            contact={values.contact}
                            setDataValues={data => {
                                setValues(({...values, ...data, tch: false}))
                                showAddress({ ...values, ...data })
                            }}
                        />}
                        {!values.contact && <AccountCard
                            account={values.account}
                            setDataValues={data => {
                                setValues(({...values, ...data, tch: false}))
                                showAddress({ ...values, ...data })
                            }}
                        />}
                    </div>

                    <div className="contact-right-col">
                        <Tasks tasks={values.tasks} openTask={data => setModalData(data)} />
                        <TabsRender
                            setState={({ layout }) => history.push(`?layout=${layout}`)}
                            state={{ layout: urlParams.get('layout') || tabsConfig[0].value }}
                            arr={tabsConfig}
                        />
                        <div className="tab-container">
                            <CSSTransition in={(urlParams.get('layout') || tabsConfig[0].value) === tabsConfig[0].value} timeout={{enter: 0, exit: 0}} classNames="my-node" unmountOnExit>
                                <InfoTab
                                    {...{ values, errors, disabled, changeFields, id, saveItem }}
                                    setValues={data => setValues(({ ...values, ...data, tch: false }))}
                                    openAddress={() => setModalData({ type: 'address' })}
                                    setPaymentValues={data =>
                                        setValues(prevState => ({ ...prevState, ...data }))}
                                />
                            </CSSTransition>
                            <CSSTransition in={urlParams.get('layout') === tabsConfig[1].value} timeout={{enter: 0, exit: 0}} classNames="my-node" unmountOnExit>
                                <Products
                                    type="order"
                                    id={id}
                                    related="14"
                                    isTotal isStatus isPrice isDiscount isValues
                                    updatePrice={sum => setValues({ ...values, cost: sum })}
                                />
                            </CSSTransition>
                            <CSSTransition in={urlParams.get('layout') === tabsConfig[2].value} timeout={{enter: 0, exit: 0}} classNames="my-node" unmountOnExit>
                                <Related
                                    type="order" id={id} related="14"
                                    contactEmail={(values.contact) ? values.contact.email : null}
                                    accountEmail={(values.account) ? values.account.email : null}
                                    contact_id={values.contact_id} account_id={values.account_id}
                                    opportunity_id={values.opportunity_id} account={values.account}
                                    contact={values.contact} order_id={id}
                                    shipments invoices documents sms mails expense isPlates
                                />
                            </CSSTransition>
                            <CSSTransition in={urlParams.get('layout') === tabsConfig[3].value} timeout={{enter: 0, exit: 0}} classNames="my-node" unmountOnExit>
                                <History type="order" id={id} />
                            </CSSTransition>
                            <CSSTransition in={urlParams.get('layout') === tabsConfig[4].value} timeout={{enter: 0, exit: 0}} classNames="my-node" unmountOnExit>
                                <FilesDropDown
                                    related_id={id}
                                    related_type={"14"}
                                    files={values.files}
                                    updateFile={filesData => setValues(prevState => ({
                                        ...prevState,
                                        files: [filesData, ...prevState.files]
                                    }))}
                                    updateFiles={files => setValues(prevState => ({ ...prevState, files }))}
                                />
                            </CSSTransition>
                        </div>
                    </div>
                </div>
            </div>

            <TaskModal
                id={modalData && modalData.id} close={() => setModalData(null)} index={modalData && modalData.index}
                task={(type, data, index) => { setValues({ ...values, tasks: updateEntityTasks(values.tasks, type, data, index) }) }}
                related={{ id, type: 14 }} isOpen={modalData && modalData.type === 'task'}
            />

            <CombineOrders
                isOpen={modalData && modalData.type === 'combineOrders'}
                close={() => setModalData(null)} entity={"order"} entityId={id}
                refresh={() => { history.push(`?layout=${tabsConfig[0].value}`); Notif('save', 'Заказы успешно обьеденены'); setModalData(null) }}
            />

            {modalData && modalData.type === 'splitOrder' &&
            <CenterModalContainer isOpen={modalData && modalData.type === 'splitOrder'} title="Разделить заказ" close={() => setModalData(null)}>
                <SplitOrder entity="order" orderId={id} history={history} close={() => setModalData(null)} />
            </CenterModalContainer>}

            <React.Fragment>
                <div className={`right-modal scrollbar ${modalData && modalData.type === 'address'}`}><div className="save">
                    {(modalData && modalData.type === 'address')
                    && <Address
                        close={() => setModalData(null) }
                        type={values.account ? "account" : "contact"}
                        parrentId={( values.account ? values.account_id : values.contact_id )}
                        refresh={() => { showAddress(values); setModalData(null)} } />}
                </div>
                </div>
                {modalData && <Bgdrop close={() => setModalData(null)} />}
            </React.Fragment>
        </>
    )
}