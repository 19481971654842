import React, {useEffect, useRef, useState, useCallback, useContext} from "react";
import myaxios from "axios";
import {GlobalState} from "../Layout/Layout";
import Notif from "./Notif";


export const Test2 = () => {
    const [files, setFiles] = useState([])

    useEffect(() => {
        myaxios.get(`https://dev.sboxcrm.com/ajax/opportunity/224?expand=files`).then(({ data }) => {
            setFiles(data.files)
        })
    }, [])

    return <div className="page" style={{ background: '#fff', padding: '20px', boxSizing: 'border-box' }}>
        <FilesDropDown
            related_id={224}
            related_type={1}
            files={files}
            updateFile={filesData => setFiles(prevState => [filesData, ...prevState])}
            updateFiles={files => setFiles(files)}
        />
    </div>
}

function ext(name) {
    let m = name.match(/\.([^.]+)$/)
    return m && m[1]
}

const FilesDropDown = ({ related_id, related_type, files, updateFiles, updateFile }) => {
    const [errorMessage, setErrorMessage] = useState(false)
    const { state: { allowed_file_extensions } } = useContext(GlobalState)
    const inputRef = useRef(null)
    const labelRef= useRef(null)
    const allowedFileExtensions = allowed_file_extensions && allowed_file_extensions.split(',').map(item => item.trim())
    const [loading, setLoading] = useState(false)
    const [loadingProcess, setLoadingProcess] = useState(0)
    const [errorItems, setErrorItems] = useState([])
    const [isHover, setIsHover] = useState(false)

    const del = (id) => {
        if(window.confirm()){
            myaxios.delete(`https://dev.sboxcrm.com/ajax/file/delete/${id}`).then(res => {
                if(res.data.status === 'success') {
                    updateFiles(files.filter(el => +el.id !== +id))
                    Notif('delete', 'Файл успешно удален')
                }
            })
        }
    }

    const result = async (files, entity) => {
        for (const asyncFn of files) {
            setLoading(true)
            if(!allowedFileExtensions.includes(ext(asyncFn.name))) {
                console.log('asyncFn', asyncFn)
                setErrorItems(prevState => [{name: asyncFn.name, error: 'запрещенный тип файла'}, ...prevState])
            } else {
                const fData = new FormData();
                fData.append('UploadForm[file]', asyncFn, asyncFn.name);
                const { status, data } = await myaxios.post(`https://dev.sboxcrm.com/ajax/file/upload?related_type=${related_type}&related_id=${related_id}`, fData, {
                    onUploadProgress: ProgressEvent => {
                        setLoadingProcess((ProgressEvent.loaded / ProgressEvent.total) * 100)
                    }}
                );

                if(data.status === 'uploaded') {
                    updateFile(data)
                    Notif('save', 'Файл загружен')
                } else if (!data.status && data.errors && data.errors.file) {
                    if(data.errors.file[0]) {
                        data.errors.file.map(item => {
                            if(typeof item === 'string') {
                                Notif('Error', item)
                                setErrorItems(prevState => [{name: asyncFn.name, error: item}, ...prevState])
                            }
                        })
                    }
                }
                setLoadingProcess(0)
                setLoading(false)
            }
        }
    }

    const filesHandler = async (ev) => {
        setIsHover(false)
        if(loading && !ev.target && !ev.target.files && ev.target.files.length < 0) {
            return false;
        }
        setErrorItems([])

        await result(ev.target.files)
    }

    const dropHandler = async (ev) => {
        setIsHover(false)
        if(loading) {
            return false;
        }

        ev.preventDefault()
        // console.log('event', ev.dataTransfer.items[0].getAsFile())

        if(loading && !ev.dataTransfer && !ev.dataTransfer.items && ev.target.files.length < 0) {
            return false;
        }
        setErrorItems([])
        let arr = []
        for (let i = 0; i < ev.dataTransfer.items.length; i ++) {
            arr.push(ev.dataTransfer.items[i].getAsFile())
        }
        await result(arr)
        // uploadFiles(ev.dataTransfer.items[0].getAsFile())
    }

    function dragOverHandler(ev) {
        setIsHover(true)
        ev.preventDefault();
    }

    // 0 0 10px rgb(0 0 0 / 20%)
    return (
        <div className={'filesDropDown'}>
            <label
                style={{
                    boxShadow: isHover && '0 0 10px rgb(0 0 0 / 20%)',
                    background: isHover && 'rgb(229 229 229)'
                }}
                className={'drop_zone__container'}
                ref={labelRef}
                onDragOver={event => dragOverHandler(event)}
                onDragLeave={() => setIsHover(false)}
                onDrop={event => dropHandler(event)}
                htmlFor="drop_zone">
                <input style={{ display: 'none' }} ref={inputRef} id="drop_zone" type="file" onChange={e => filesHandler(e)} multiple />
                <div className={'filesDropDown__zone'}>
                    {loading ? `Процесс загрузки ${loadingProcess.toFixed()}%` : 'Перетащите файлы сюда'} <br />
                    {errorMessage && <em className={'filesDropDown__error-message'}>разрешенные типы файлов {allowed_file_extensions}</em>}
                </div>
            </label>

            <div className={'contact-page sp-table'} style={{ display: errorItems.length === 0 && 'none' }}>
                <table>
                    <tr className="fst-tr">
                        <td>Не загружен</td>
                        <td>Ошибка</td>
                        <td />
                    </tr>
                    {
                        errorItems.map(item => (
                            <tr style={{ height: '50px' }} key={item.id} className={'pull-up'}>
                                <td>{item.name}.{item.type}</td>
                                <td style={{ color: '#c16a6a' }}>{item.error}</td>
                            </tr>
                        ))
                    }
                </table>
            </div>


            <div className={'contact-page sp-table'} style={{ display: files.length === 0 && 'none' }}>
                <table>
                    <tr className="fst-tr">
                        <td>Название файла</td>
                        <td>Тип</td>
                        <td>Дата создания</td>
                        <td />
                    </tr>
                    {
                        files.map(item => (
                            <tr key={item.id} className={'pull-up'}>
                                <td>{item.name}</td>
                                <td>{item.type}</td>
                                <td>{item.created_at}</td>
                                <td style={{ width: '165px' }}>
                                    <a
                                        style={{ color: 'white', borderRadius: '4px', textDecoration: 'none', width: '90px', textAlign: 'center', marginTop: 0 }}
                                        target={'_blank'}
                                        href={`/ajax/file/download/${item.id}`}
                                        className="btn-m">Скачать</a>
                                    <a onClick={() => del(item.id)} className="is">
                                        <i className={'flaticon-delete-1'} />
                                    </a>
                                </td>
                            </tr>
                        ))
                    }
                </table>
            </div>
        </div>
    );
}

