import myaxios from "../../components/Axios";

export const createInvoiceService = ({ data, expand }: { data: any, expand: string }) => myaxios.post(`/ajax/invoice?expand=${expand}`, data)
export const getSingleInvoiceService = ({ id, expand }: { id: number, expand: string }) => myaxios.get(`/ajax/invoice/${id}?expand=${expand}`)
export const updateInvoiceService = ({ id, data }: { id: number, data: any }) => myaxios.put(`/ajax/invoice/${id}`, data)
export const deleteInvoiceService = ({ id }: { id: number }) => myaxios.delete(`/ajax/invoice/${id}`)
export const getInvoicePrepareService = () => myaxios.get(`/ajax/invoice/prepare`)
export const getInvoiceListService = ({ expand = "", filter = "", sort = "" }) => myaxios.get(`/ajax/invoice/`)

export const createInvoiceShipmentService = ({ inv_id }: { inv_id: number }) => myaxios.post(`/ajax/invoice/${inv_id}/create-shipment`)

export const createPaymentService = ({ inv_id, data }: { inv_id: number, data: any }) => myaxios.post(`/ajax/invoice/${inv_id}/payment`, data)