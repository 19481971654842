import React, {useEffect, useState} from "react";
import Input from "../../UI/Input";
import Myselect from "../../UI/Select";
import myaxios from "../../Axios";

const FacebookAds = (props) => {
    const [state, setMyState] = useState({
        formControl: {
            name: {
                value:'',
                valid:true,
                validation:{required:true}
            },
            account_id: {
                value:'',
                valid:true,
                options:[],
                validation:{required:true}
            },
            audience_id:{
                value:'',
                valid:true,
                options:[],
                validation:{required:true}
            }
        },
    })

    const setState = (st) => {
        Object.keys(st).map((key) => {
            state[key] = st[key]
        });
        setMyState(prevState => {
            return {...prevState, ...state}
        });
    };

    const getAudience = (accountId) => {
        setState({loading: true})
        const formControl = state.formControl;
        if (state.formControl.account_id.value.length > 0) myaxios.get(`/ajax/facebook/audiences?id=${accountId}`).then(res => {
            formControl.audience_id.value = res.data[0].id;
            formControl.audience_id.options = res.data;
            setState({formControl, loading: false})
        })
    };

    const getAcc = () => {
        const formControl = state.formControl;
        myaxios.get(`/ajax/facebook/customer-accounts`).then(res => {
            formControl.account_id.options = res.data
            setState({ formControl })
        })
    }

    useEffect(() => {
        getAcc();
        if(props.elArr){
            /*setState({
                account_id: props.elArr.metaData ? props.elArr.metaData.account_id : "",
                audience_id: props.elArr.metaData ? props.elArr.metaData.audience_id : "",
                name: props.elArr.metaData ? props.elArr.name : "",
            })*/
            //if(props.elArr.metaData.customer_id) getAudience(props.elArr.metaData.customer_id)
        }
    }, [])

    const sendForm = () => {
        if (state.name.length === 0) return;
        if (props.elArr) {
            setTimeout(
                props.updateNode({
                    id: state.id,
                    name: state.name,
                    time_value:0,
                    is_scheduled:0,
                    metaData: {
                        audience_id: state.audience,
                        account_id: state.account
                    },
                    nodeType: "node",
                    type: "fb_audience_add",
                })
            )
        } else {
            setTimeout(
                props.createNode({
                    name: state.name,
                    is_scheduled:0,
                    time_value:0,
                    metaData: {
                        audience_id: state.audience,
                        account_id: state.account
                    },
                    nodeType: "node",
                    type: "fb_audience_add",
                })
            )
        }

    };

    return <div>
        <div className="rmod-header">
            <b>Facebook Ads</b>
            <a onClick={props.close} style={{ marginRight: "60px" }} className="mclose"><i className="flaticon-close" /></a>
            <div className="togglelist"><button onClick={sendForm} className="rmod-ok" disabled=""><i className="flaticon-interface-1" /></button></div>
        </div>
        <div className="mod-cont sbform">
            <Input
                label="Название"
                value={state.name}
                onChange={e => setState({name: e.target.value})}
            />
            <Myselect
                label={"Кампании Google Ads"}
                options={state.accounts}
                value={state.account}
                val={`id`}
                lab={'name'}
                onChange={e => {
                    setState({account: e.target.value});
                    getAudience(e.target.value)
                }}
            />
            <Myselect
                label={"Аудитории"}
                options={state.audiences}
                value={state.audience}
                val={`id`}
                lab={'name'}
                onChange={e => setState({audience: e.target.value})}
            />
        </div>
    </div>
}

export default FacebookAds
