import React from "react";

export const Options = ({
    options,
    onClick,
    active
}) => {
    return (
        <div className={'create-filter-modal-options'}>
            {
                options.map(op => (
                    <div onClick={() => onClick(op)} className="create-filter-modal-option">
                        <div className="create-filter-modal-option-circle">
                            {active === op.value && <div className={'create-filter-modal-option-circle-active'} />}
                        </div>
                        <span>{op.label}</span>
                    </div>
                ))
            }
        </div>
    )
}