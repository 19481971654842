import React from "react";

const Button = ({ title, onClick, mobileIcon, disabled, className }) => {
    return (
        <button className={`${className} success fl-l btni`} onClick={onClick} disabled={disabled}>
            <span>{title}</span>
            <i className={mobileIcon} />
        </button>
    )
}

export const MemoButton = React.memo(Button)