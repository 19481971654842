import React, {useEffect, useState} from "react";
import {BpDateWithPeriod, parseDataForPeriod, saveTimePeriod} from "../BpHelpers";
import {validationData} from "../../UI/formValid";
import Modal from "../../Modal";
import Input from "../../UI/Input";

const validationRules = {
    name: { required: true, maxLength: 255 },
    amount: { required: true, maxLength: 255, number: true },
    currency_id: { required: true, maxLength: 255, number: true },
    exchange_rate: { required: true, maxLength: 255, number: true },
    balance_id: { required: true, maxLength: 255},
    category_id: { required: true, maxLength: 255, number: true }
}

export const BpCreatePayment = ({ close, metaData, saveNode }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState({})
    const [values, setValues] = useState({
        name: '',
        amount: '888',
        currency_id: '2',
        exchange_rate: '1',
        balance_id: '1922694e-5831-4f11-9992-18fc141dc97a',
        payment_date: '',
        category_id: '1',
        time_value: 0,
        time_valuePeriod: 'minutes',
        tch: true
    })

    const getData = () => {
        const { name, time_value, metaData: { data: { fields } }} = metaData
        const amount = fields.find(el => el.field === 'amount').value
        const currency_id = fields.find(el => el.field === 'currency_id').value
        const exchange_rate = fields.find(el => el.field === 'exchange_rate')
        const balance_id = fields.find(el => el.field === 'balance_id').value
        const payment_date = fields.find(el => el.field === 'payment_date').value
        const category_id = fields.find(el => el.field === 'category_id')

        setValues({
            ...values, name,
            time_value: parseDataForPeriod(time_value).value,
            time_valuePeriod: parseDataForPeriod(time_value).period,
            amount, currency_id, balance_id, payment_date,
            exchange_rate: exchange_rate && exchange_rate.value,
            category_id: category_id && category_id.value
        })
        setLoading(false)
    }

    useEffect(() => {
        setIsOpen(true)
        if(metaData) {
            getData()
        } else {
            setLoading(false)
        }
    }, [])

    const saveData = () => {
        const validErrors = validationData(values, validationRules)
        if(Object.keys(validErrors).length > 0) {
            setErrors(validErrors)
            return false;
        }
        setErrors({})
        setIsOpen(false)

        const { name, time_value, time_valuePeriod, amount, currency_id, exchange_rate, balance_id, payment_date, category_id } = values
        setTimeout(() => saveNode({
            id: metaData && metaData.id,
            name,
            nodeType: 'node',
            time_value: saveTimePeriod(time_value, time_valuePeriod),
            is_scheduled: time_value > 0,
            type: "createPayment",
            metaData: { data: { fields:
                [
                    {field: 'name', object: 'string', value: name},
                    {field: 'amount', object: 'string', value: amount},
                    {field: 'currency_id', object: 'string', value: currency_id},
                    {field: 'exchange_rate', object: 'string', value: exchange_rate},
                    {field: 'balance_id', object: 'string', value: balance_id},
                    {field: 'payment_date', object: 'string', value: payment_date},
                    {field: 'category_id', object: 'string', value: category_id},
                    {field: 'invoice_id', object: 'string', value: '{{entity.id}}'},
                ]
            }}
        }), 100)
    }

    const closeModal = () => {
        setIsOpen(false); setTimeout(close, 200);
    }

    return (
        <Modal showLeaveModal tch={values.tch} isOpen={isOpen} title={'Создать платеж'} loading={loading}
               save={saveData} parentClassName={'bpmModal'} close={closeModal}>
            <div className={'sbform'}>
                <Input
                    label={'Название'}
                    value={values.name}
                    onChange={e => setValues({...values, name: e.target.value, tch: false })}
                    errorMessage={errors.name} valid={!errors.name} shouldValidate
                />
                <div style={{ display: 'flex' }}>
                    <Input
                        label={'Сумма'}
                        value={values.amount} className={'w33'}
                        onChange={e => setValues({...values, amount: e.target.value, tch: false })}
                        errorMessage={errors.amount} valid={!errors.amount} shouldValidate
                    />
                    <Input
                        label={'ID Валюты'}
                        value={values.currency_id} className={'w33'}
                        onChange={e => setValues({...values, currency_id: e.target.value, tch: false })}
                        errorMessage={errors.currency_id} valid={!errors.currency_id} shouldValidate
                    />
                    <Input
                        label={' Курс в б.в.'}
                        value={values.exchange_rate} className={'w33'}
                        onChange={e => setValues({...values, exchange_rate: e.target.value, tch: false })}
                        errorMessage={errors.exchange_rate} valid={!errors.exchange_rate} shouldValidate
                    />
                </div>
                <Input
                    label={'ID кошелька'}
                    value={values.balance_id} className={'w100'}
                    onChange={e => setValues({...values, balance_id: e.target.value, tch: false })}
                    errorMessage={errors.balance_id} valid={!errors.balance_id} shouldValidate
                />
                <Input
                    label={'ID категории'}
                    value={values.category_id} className={'w100'}
                    onChange={e => setValues({...values, category_id: e.target.value, tch: false })}
                    errorMessage={errors.category_id} valid={!errors.category_id} shouldValidate
                />
                <Input
                    label={'Дата оплаты'}
                    value={values.payment_date} className={'w100'}
                    onChange={e => setValues({...values, payment_date: e.target.value, tch: false })}
                    errorMessage={errors.payment_date} valid={!errors.payment_date} shouldValidate
                />
                <BpDateWithPeriod
                    label={'Отложенный запуск'}
                    name={'time_value'} periodName={'time_valuePeriod'}
                    values={values} errors={errors}
                    setValues={(type, value) => setValues({...values, [type]: value, tch: false })}
                    tooltip={'Время задержки выполнения процесса'}
                />
            </div>
        </Modal>
    )
}