import {entity} from "../../components/ListComponentEntity";
import {NavLink} from "react-router-dom";
import React from "react";
import {NewList} from "../../components/NewList/NewList";

export const ListCaseNew = (props) => {
    return (
        <NewList
            { ...props }
            entity={'case'}
            entity_type={27}
            requiredFields={'id,name,type_id,status_id,contact_id,account_id'}
            requiredExpand={'type,status,contact,account'}
            deleteToShowColumn={'name,type_id,status_id,contact_id,account_id'}
            additionalFieldsExists={false}
            isFilterExists={true}
            filterFields={entity.cases.fArr}
            singleSearch={entity.cases.fArr[0]}
            headingColumns={['Название', 'Тип', 'Статус']}
            RenderColumn={(item) => (
                <>
                    <td>
                        <NavLink to={`/case/${item.id}`}  className={'nowrap-list-column'}>{item.name}</NavLink>
                        {item.contact && <div className={'nowrap-list-column'}>Клиент: <NavLink to={`/contact/${item.contact.id}`}>{item.contact.name}</NavLink></div>}
                        {item.account && <div className={'nowrap-list-column'}>Клиент: <NavLink to={`/account/${item.account.id}`}>{item.account.name}</NavLink></div>}
                    </td>
                    <td>
                        {item.type && <span className="label-st" style={{ backgroundColor: item.type.color || 'rgb(255, 193, 100)' }}>{item.type.value}</span>}
                    </td>
                    <td>
                        {item.status && <span className="label-st" style={{ backgroundColor: item.status.color || 'rgb(255, 193, 100)' }}>{item.status.value}</span>}
                    </td>
                </>
            )}
            createButtonRender={(setModalType) => <button onClick={() => setModalType('case')} className="open-modal-button btni">Новый тикет</button>}
            rightNavbarConfig={{
                columns: true,
            }}
            massDoConfig={{ deleteAll: `/ajax/case/delete-all` }}
            contextNav={{ edit: true, task: true, print: false, delete: true }}
            sort={entity.cases.fArr.filter(el => el.sort)}
        />
    )
}