import {entity} from "../../components/ListComponentEntity";
import {NavLink} from "react-router-dom";
import React from "react";
import {NewList} from "../../components/NewList/NewList";

export const ListDocumentNew = (props) => {
    return (
        <NewList
            { ...props }
            entity={'document'}
            entity_type={26}
            requiredFields={'id,name,type_id,status_id'}
            requiredExpand={'type,status'}
            deleteToShowColumn={'name,type_id,status_id'}
            additionalFieldsExists={true}
            isFilterExists={true}
            filterFields={entity.document.fArr}
            singleSearch={entity.document.fArr[0]}
            headingColumns={['Название', 'Статус', 'Тип']}
            RenderColumn={(item) => (
                <>
                    <td>
                        <NavLink to={`/document/${item.id}`}  className={'nowrap-list-column'}>{item.name}</NavLink>
                    </td>
                    <td>
                        {item.type && <span className="label-st" style={{ backgroundColor: 'rgb(255, 193, 100)' }}>{item.type.value}</span>}
                    </td>
                    <td>
                        {item.status && <span className="label-st" style={{ backgroundColor: 'rgb(255, 193, 100)' }}>{item.status.value}</span>}
                    </td>
                </>
            )}
            createButtonRender={(setModalType) => <button onClick={() => setModalType('document')} className="open-modal-button btni">Новый документ</button>}
            rightNavbarConfig={{
                additionalFields: '/fieldsettings/26',
                columns: true,
            }}
            massDoConfig={{ deleteAll: `/ajax/document/delete-all` }}
            contextNav={{ edit: true, task: true, print: true, delete: true }}
            sort={entity.document.fArr.filter(el => el.sort)}
        />
    )
}