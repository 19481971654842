import React, {useEffect, useState} from "react";
import Modal from "../../Modal/Modal";
import {
    getSendPulseAddressBookService,
    sendPulseSubscribeService,
    sendPulseSubUnScribeService
} from "../../../services/integrations/sendpulse";
import Myselect from "../../UI/Select";
import Notif from "../../Notif";

const iconStyle = { padding: 0, fontSize: '20px' }
const buttonStyle = { height: '40px', width: '40px', padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }
const ERROR_MESSAGE = 'Необходимо добавить адресную книгу.'

export const CardSendPulse = ({ related_type, id, setValues, subscriber }) => {
    const [isCalled, setIsCalled] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [addressBooks, setAddressBooks] = useState([])
    const [book_id, setBook_id] = useState('')

    const getAddressBook = async () => {
        setLoading(true)
        setIsCalled(true)
        try {
            const { status, data } = await getSendPulseAddressBookService()
            if (status === 200 && data) {
                if (data.data.length === 0) {
                    Notif('Error', ERROR_MESSAGE)
                    setIsOpen(false)
                    return
                }
                setAddressBooks(data.data)
                setBook_id(data.data[0].id)
            }
        } catch (e) {}
        setLoading(false)
    }

    const subscribe = async () => {
        setLoading(true)
        try {
            const { status, data } = await sendPulseSubscribeService({ id, related_type, book_id })
            if (status === 200 && data.success) {
                Notif('save', 'Пользователь успешно подписан.')
                setValues({ subscriber: { id: 1 } })
                setIsOpen(false)
            }
        } catch (e) {}
        setLoading(false)
    }

    const unSubscribe = async () => {
        setLoading(true)
        try {
            const { status, data } = await sendPulseSubUnScribeService({ id, related_type, book_id })
            if (status === 200 && data.success) {
                Notif('save', 'Пользователь успешно отписан.')
                setValues({ subscriber: null })
                setIsOpen(false)
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        if (isOpen && isCalled && addressBooks.length === 0) Notif('Error', ERROR_MESSAGE)
        if (isOpen && !isCalled) getAddressBook().then()
    }, [isOpen])

    return (
        <div>
            <button
                onClick={() => setIsOpen(!isOpen)}
                style={buttonStyle}
                className="mr5 success fl-l btni">
                <i style={iconStyle} className="flaticon-email" />
            </button>

            <Modal title={'SendPulse'} loading={loading} isOpen={isOpen} close={() => setIsOpen(false)}>
                <div className="sbform">
                    <Myselect
                        label={'Адресная книга'} val={'id'} lab={'name'}
                        options={addressBooks}
                        onChange={e => setBook_id(e.target.value)}
                    />
                    {!subscriber ?
                        <button onClick={subscribe} className="mt10 success btni">Подписать</button> :
                        <button onClick={unSubscribe} className="butt-group-card-wrap btni mt10">Отписать</button>}
                </div>
            </Modal>
        </div>
    )
}