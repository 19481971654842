import React from 'react';
import SoundClick from '../components/soundClick';
function Notif(type, textB) {
    const ntf = document.getElementById("notif");
    let typeNotif, iconNotif, textTop, textBottom, newDiv;
    newDiv = document.createElement("div");
    switch (type) {
        case "delete":
            typeNotif = "warning";
            iconNotif = "flaticon-delete-1";
            textTop = "Удаление успешно";
            textBottom = "Ваша запись успешно удалена";
            setTimeout(SoundClick("delete"));
            break;
        case "update":
            typeNotif = "success";
            iconNotif = "flaticon-interface-1";
            textTop = "Обновление успешно!";
            textBottom = "Ваша запись успешно обновлена";
            break;
        case "save":
            typeNotif = "success";
            iconNotif = "flaticon-interface-1";
            textTop = "Сохранение успешно!";
            textBottom = "Ваша запись успешно сохранена";
            break;
        case "notify":
            typeNotif = "info";
            iconNotif = "flaticon-alert-2";
            textTop = "Уведомление";
            textBottom = "У вас новое уведомление";
            setTimeout(SoundClick("info"));
            break;
        case "message":
            typeNotif = "success";
            iconNotif = "flaticon-chat-1";
            textTop = "Новое сообщение";
            textBottom = "Поступило новое сообщение";
            setTimeout(SoundClick("msg"));
            break;
        case "required-fields":
            typeNotif = "warning";
            iconNotif = "flaticon-list-3";
            textTop = "Заполните поля";
            textBottom = "Не заполнены обязательные поля";
            break;
        case "Fields":
            typeNotif = "warning";
            iconNotif = "flaticon-list-3";
            textTop = "Заполните поля";
            textBottom = "Не заполнены обязательные поля";
            //setTimeout(SoundClick("error"));
            break;
        case "Error":
            typeNotif = "warning";
            iconNotif = "flaticon-close";
            textTop = "Ошибка";
            textBottom = "Что-то пошло не так";
            //setTimeout(SoundClick("error"));
            break;
        case "500":
            typeNotif = "warning";
            iconNotif = "flaticon-close";
            textTop = "Ошибка";
            textBottom = "Что-то пошло не так. Попробуйте еще раз или обратитесь в тех. поддержку";
            //setTimeout(SoundClick("error"));
            break;
        case "call":
            typeNotif = "warning";
            iconNotif = "flaticon-support";
            textTop = "Исходящий вызов";
            textBottom = "Не заполнены обязательные поля";
            //setTimeout(SoundClick("error"));
            break;
    }
    (textB) ? textBottom = textB : textB = false;
  return <div>
      {
          newDiv.innerHTML = `<div class="notif-item ${typeNotif}"><i class="${iconNotif}"></i><h3>${textTop}</h3><p>${textBottom}</p></div>`
          /*newDiv.innerHTML = `<table class="notif-item ${typeNotif}"><tr><td style={{width:20px}}><i class="${iconNotif}"></i></td><td><h3>${textTop}</h3><p>${textBottom}</p></td></tr></table>`*/
      }
      {ntf.appendChild(newDiv)}
      {setTimeout(function () {newDiv.parentNode.removeChild(newDiv) }, 4500)}
  </div>;
};
export default Notif;