import React, {useEffect, useState} from "react";
import Modal from "../../../components/Modal/Modal";
import {GenerateForm} from "../../../components/cardViewComponents/GenerateForm";
import {validationData} from "../../../components/UI/formValid";
import {validationConfig} from "../../../components/cardViewComponents/validationConfig";
import Notif from "../../../components/Notif";
import myaxios from "../../../components/Axios";
import {formatDate} from "../../../components/ListComponent";

const fieldsConfig = {
    name: {
        label: 'Название',
        type: 'text',
        className: 'w100',
        validation: { required: true, maxLength: 255 }, zone: 1
    },
    stock_id: {
        label: 'Склад',
        type: 'entity',
        link: "/ajax/stock?filter[name][like]=",
        result: 'name',
        entity_from: 'stock',
        entityName: 'name',
        className: 'w100',
        minLength: 0,
        validation: { required: true }, zone: 1
    },
    date: {
        label: 'Дата прихода',
        type: 'date',
        className: 'w100',
        validation: { maxLength: 255 }, zone: 1
    },
    account_id: {
        label: 'Контрагент',
        type: 'entity',
        link: "/ajax/account?filter[name][like]=",
        result: 'name',
        entity_from: 'account',
        entityName: 'name',
        className: 'w100',
        zone: 1
    },
    provider_id: {
        label: 'Поставщик',
        type: 'entity',
        link: "/ajax/account?filter[name][like]=",
        result: 'name',
        entity_from: 'provider',
        entityName: 'name',
        className: 'w100',
        zone: 1
    },
    owner_id: {
        label: 'Ответственный',
        type: 'entity',
        link: "/ajax/settings/user?filter[name][like]=",
        result: 'name',
        entity_from: 'owner',
        entityName: 'name',
        className: 'w100',
        zone: 1
    },
}

export const ReplanishModal = ({ isOpen, close, history }) => {
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState({})
    const [disabled, setDisabled] = useState({})
    const [values, setValues] = useState({
        name: '',
        date: formatDate(new Date()),
        owner_id: null,
        owner: null,
        status_id: '',
        stock_id: null,
        stock: null,
        account_id: null,
        account: null,
        provider_id: null,
        provider: null,
        tch: true
    })

    const getPrepare = async () => {
        const { status, data } = await myaxios.get(`/ajax/stock/replanish/prepare`)
        if((status === 200 || status === 201) && data) {
            setValues({
                ...values,
                date: formatDate(new Date()),
                name: 'Приход №' + data.nextId
            })
        }
        setLoading(false)
    }

    const saveItem = async () => {
        const errorsData = validationData(values, validationConfig(fieldsConfig));
        setErrors(errorsData)
        if(Object.keys(errorsData).length > 0) {
            Notif('required-fields')
            return false;
        }

        setLoading(true)
        const { name, stock_id, date, owner_id, provider_id, account_id } = values
        const { status, data } = await myaxios.post(`/ajax/stock/replanish`, { name, stock_id, date, owner_id, provider_id, account_id })
        if((status === 200 || status === 201) && data.id) {
            history.push(`/stock/replanish/${data.id}`)
        }
        setLoading(false)
    }

    useEffect(() => {
        getPrepare().then()
    }, [])

    const changeFields = (key, value, item) => {
        let obj = {}
        if(item) {
            if(item === 'clear') obj[fieldsConfig[key].entity_from] = null
            else obj[fieldsConfig[key].entity_from] = item
        }

        setValues({ ...values, ...obj, [key]: item ? item.id : value, tch: false })
    }

    return (
        <Modal isOpen={isOpen} save={saveItem} close={close} loading={loading} showLeaveModal tch={values.tch} title={'Создать приход'}>
            <GenerateForm
                config={fieldsConfig}
                { ...{ values, errors, disabled } }
                onChange={changeFields}
                onSaveData={saveItem}
            />
        </Modal>
    )
}