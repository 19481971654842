import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

const Emails = props => {
    return <tr>
        <td>
            <a onClick={() => {props.history.push(`/mail/${props.id}/inbox`); setTimeout(props.close) }  }>{props.username}</a>
            <em className="email-em">{props.new_message_count}</em>
        </td>
        <td>
            <div className="toogle-bottom fl-r">
                <a className="is" /*href={`GET /ajax/mail/{id}/<folder:\w+>/<message_id>/download`}*/><i className="flaticon-more" /></a>
                <ul className="toogle-list">
                    <li><a onClick={() => props.editAccount(props.id)}><i className="flaticon-edit-1" /> <b>Редактировать</b></a></li>
                    <li><a onClick={e => props.deleteMailBox(e, props.id, props.index)} className="table-del"><i className="flaticon-delete-1" /> <b>Удалить</b></a></li>
                </ul>
            </div>
        </td>
    </tr>
}

export default withRouter(Emails);