import React, {Component} from 'react';
import myaxios from '../../../components/Axios';
import formValid from '../../../components/UI/formValid';
import { withRouter } from 'react-router-dom';
import Information from './tab/information';
import Files from '../../../components/files/files'
import Notif from '../../../components/Notif';
import Loader from '../../../components/UI/Loader';
import Task from "../../../components/Task";
import AddTask from '../../../Modal/AddTask';
import Bgdrop from '../../../Modal/Bgdrop';
import Myselect from '../../../components/UI/Select';
import Input from '../../../components/UI/Input';
import AddProjectRes from '../../../Modal/AddProjectRes';
import DateTime from '../../../components/UI/DateTime';
import ModAutoSelect from '../../../components/UI/ModAutoSelect';
import Related from '../../../components/related/related';
import AddMAchine from '../../../Modal/AddMachine';
import History from '../../../components/history/history';
import AddProjectStage from "../../../Modal/AddProjectStage";
import {CSSTransition} from "react-transition-group";
import LeaveModal from "../../../components/other/LeaveModal";

class StageView extends Component {
    state = {
        layout: 'information',
        loading: true,
        formControl: {
            //parent_id: {value: '', arr: null, valid: true, validation: {required: true}},
            name: {value: '', valid: true, validation: {required: true,minLength: 3}},
            stage: {value: "internal", options: []},
            status: {value: 'inactive'},
            percentComplete: {value: ''},
            responsible:  {value: '', arr: null, valid: true, validation: {required: true}},
            field: {value: ''},
            startDate: {value: '', valid: true},
            endDate: {value: '', valid: true},
            deadline: {value: '', valid: true},
            metal_type: {value: 'company'},
            description: {value:''}
        },
        users: [],
        isFormValid: true,
        tch: true,
        tasks: [],
        files: [],
        endTask: false,
        taskId: null,
        taskIndex: Number,
        stageId: '',
        open: '',
        openType: '',
        project_id: '',
        resources: [],
        machines:[],
        children:[],
        machineId:'',
        machineLoading:true,
        id:null,
        prevDesc:''
    };
    showContact = () => {
        myaxios.get(`/ajax${this.props.match.url}?expand=tasks,files,statuses,type,owner,children`, {
            method: 'get',
        }).then((response) => {
            const formControl = this.state.formControl;
            //formControl.name.value = response.data.name;
            formControl.percentComplete.value = response.data.execution_percent;
            formControl.status.value = response.data.status;
            formControl.startDate.value = response.data.start_date;
            formControl.endDate.value = response.data.end_date;
            formControl.deadline.value = response.data.deadline;
            formControl.responsible.arr = response.data.owner;
            formControl.responsible.value = response.data.owner_id;
            formControl.name.value = response.data.name;
            // formControl.parent_id.arr = response.data.type;
            // formControl.parent_id.value = response.data.type_id;
            formControl.description.value = response.data.description;
            formControl.metal_type.value = response.data.metal_type;
            this.setState({
                formControl,
                tasks: response.data.tasks,
                files: response.data.files,
                users: response.data.users,
                project_id: response.data.project_id,
                children: response.data.children,
                id:response.data.id,
                prevDesc: response.data.description
            })
        }).catch((error)=>{
            this.setState({ loading: false });
        });
    };

    showChildren = async () => {
        await myaxios.get(`/ajax${this.props.match.url}?expand=children?per-page=100`).then(res => {
            this.setState({ children: res.data.children })
        })
    }

    showMachine = () => {
        this.setState({ machineLoading: true })
        myaxios.get(`/ajax/project/work/${this.props.match.params.id}/machine-list?per-page=100`, {
            method: 'get',
        }).then((response) => {
            if(response.status === 200 || response.status === 201 || response.status === 204) {
                this.setState({ machines: response.data.items, machineLoading: false})
            }
        })
    };
    moveMachine = (machineId, toId, method) => {
        myaxios.post(`/ajax/project/work/${this.props.match.params.id}/move/${machineId}`, {
            node: toId,
            method: method
        }).then((response) => {
            this.showMachine()
        }).catch((error)=>{
            this.setState({ loading: false });
        });

    }
    deleteMachine = (id) => {
        if(window.confirm("Удалить станок?")) {
        myaxios.delete(`/ajax/project/work/${this.props.match.params.id}/unlink-machine/${id}`, {
            method: 'get',
        }).then((response) => {
            if(response.status === 200 || response.status === 201 || response.status === 204) {
                Notif("save", "Станок успешно удален")
                this.showMachine()
            }
            this.setState({ machines: response.data.items})
        })
    }}
    showResource = () => {
        myaxios.get(`/ajax${this.props.match.url}/resource-list?per-page=100`, {
            method: 'get',
        }).then((response) => {
            this.setState({ resources: response.data.items, loading: false })
        })
    };
    sendForm = event => {
        event.preventDefault();
        if(this.isFormValid()){
            this.setState({ tch: true, loading: true });
            myaxios.put(`/ajax${this.props.match.url}`, {
                name: this.state.formControl.name.value,
                //type_id: this.state.formControl.parent_id.value,
                status: this.state.formControl.status.value,
                execution_percent: this.state.formControl.percentComplete.value,
                owner_id: this.state.formControl.responsible.value,
                start_date: this.state.formControl.startDate.value,
                end_date: this.state.formControl.endDate.value,
                deadline: this.state.formControl.deadline.value,
                description: this.state.formControl.description.value,
                metal_type: this.state.formControl.metal_type.value
            }).then(res => {
                if(!res) return;
                if(res.status === 200){
                    Notif("save")
                }
                this.setState({ loading: false});
            }).catch((error)=>{
                this.setState({ loading: false });
            });
        } else Notif("Error")
    };
    componentDidMount(){
        this.showContact();
        this.showMachine();
        this.showResource();
    }
    componentDidUpdate(prevProps){
        if(prevProps !== this.props) {
            this.showContact();
            this.showMachine();
            this.showResource();
        }
    }
    isFormValid = () => {
        let formControl = this.state.formControl;
        let isFormValid = true;
        Object.keys(formControl).map((key, index) => {
            formControl[key].valid = formValid(formControl[key].value, formControl[key].validation)
            isFormValid = formControl[key].valid && isFormValid
        });
        this.setState({formControl, isFormValid});
        return isFormValid;
    };

    formatDate = (date) => {
        if(date !== null) {
            let dd = date.getDate();
            if (dd < 10) dd = '0' + dd;
            let mm = date.getMonth() + 1;
            if (mm < 10) mm = '0' + mm;
            let yy = date.getFullYear() % 10000;
            if (yy < 10) yy = '0' + yy;
            return yy + '-' + mm + '-' + dd;
        }
    }
    handleChange = (date, type) => {
        const formControl = this.state.formControl;
        formControl[type].value = (this.formatDate(date)) || '';
        this.setState({
            formControl, tch: false
        });
    };
    deleteUser = () => {
        if(window.confirm("Удалить этап?")) {
            myaxios.delete(`/ajax${this.props.match.url}`).then(res => {
                if(res.status === 200 || res.status === 201 || res.status === 204) {
                    this.props.history.push(`/project/${this.state.project_id}`);
                    Notif("delete", "Этап успешно удален")
                }
            })
        }
    };

    /*Изменение полей*/
    setField = (event, type) => {const formControl = {  ...this.state.formControl }; const control = { ...formControl[type] };
        control.value = event.target.value; formControl[type] = control; this.setState({formControl, tch: false}) };

    /*Задачи*/
    task = (type, arr, index) => {
        const tasks = this.state.tasks;
        if(type === "add") {tasks.unshift(arr)}
        else if(type === "edit"){tasks[index] = arr}
        else if(type === "delete") {delete tasks[index]}
        this.setState({tasks, open: '', openType:'', taskId:'', taskIndex:''})
    }

   stagesDo = (type, id, index) => {
        if(type === "open"){
            this.setState({ open: "r-open", openType: "stages", stageId: id })
        } else if(type === "delete"){
            if(window.confirm("Удалить ресурс?")) {
                myaxios.delete('/ajax/project/resource/' + id).then(res => {
                    if(res.status === 200 || res.status === 201 || res.status === 204) {
                        Notif("delete", "Ресурс успешно удален")
                        this.showResource()
                    }
                })
            }
        }
   }


    filesFunc = (type, index, arr) => {
        const files = this.state.files;
        if(type === "del" && arr.status === 200){
            delete files[index];
            Notif("delete", "Файл успешно удален")
        } else if(arr.status === "uploaded"){
            files.unshift({id: arr.id, name: arr.name, created_at: "Только что", type: arr.type});
            Notif("save", "Файл успешно загружен")
        } else if(arr.status === "error"){
            Notif("Error", arr.errors.file[0])
        }
        this.setState({ files })
    };
    updateData = (id, arr, addType, endParrans) => {
        const formControl = this.state.formControl;
        formControl[addType].value = id;
        formControl[addType].arr = arr;
        this.setState({ formControl, tch: false })
    };
    unLink = addType => {
        const formControl = this.state.formControl;
        formControl[addType].value = '';
        formControl[addType].arr = null;
        this.setState({ formControl, tch: false})
    }
    stagesFunc = (type, modal, id, arr, index) => {
        const formControl = this.state.formControl;
        if(type === "open") {
            this.setState({ openType: modal, open: 'r-open', stageId: (id) ? id : null})
        } else if(type === "add") {
            formControl[modal].options.unshift(arr);
            this.setState({ formControl, open: null })
        } else if(type === "delete") {
            if(window.confirm()) {
                myaxios.delete('/ajax/project/work/' + id).then(responce => {
                    if(responce.status === 200) {
                        this.showChildren()
                    }
                    Notif("delete", "Этап успено удален")
                })

            }
        }
    };

    render() {
        return (
                <div className="page">
                    {this.state.loading && <Loader/>}
                    <CSSTransition in={!this.state.loading} timeout={200} classNames="slide" unmountOnExit>
                        <div>
                            <LeaveModal when={!this.state.tch} />
                    <div className="butt-group">
                        <a onClick={() => this.props.history.push(`/project/${this.state.project_id}/work`)} className="success fl-l btni mr10">К проекту</a>
                        <a onClick={() => this.setState({ open:'r-open', openType: "task" })} className="success fl-l btni">Добавить задачу</a>
                        <button onClick={this.sendForm} className="success fl-r btni ml10" disabled={this.state.tch}>Сохранить</button>
                        <a title={'/ajax/project/' + this.props.match.params.id} onClick={this.deleteUser} className="fl-r btni ml10">Удалить</a>
                    </div>
                    <div className="clear" />
                    <div className="contact-page">
                        <div className="contact-left">
                            <div className="contact-left-col">
                                <form id="leadform" className="sbform">
                                <h3>Данные этапа</h3>
                                     {/*<ModAutoSelect
                                         label="Этап производства"
                                         link="/ajax/lookup/search?type=work_type&value="
                                         minLength="0"
                                         result="value"
                                         event="parent_id"
                                         valid={this.state.formControl.parent_id.valid}
                                         shouldValidate={false}
                                         addType="parent_id"
                                         entity={this.state.formControl.parent_id.arr}
                                         entityName="value"
                                         updateData={this.updateData}
                                         unLink={this.unLink}
                                         //redirectLink="/order/"
                                     />*/}
                                    <Input
                                        label="Название этапа"
                                        type="text"
                                        errorMessage="Поле должно содержать не меньше 3-х символов"
                                        value={this.state.formControl.name.value}
                                        onChange={event => this.setField(event, "name")}
                                        shouldValidate={true}
                                        valid={this.state.formControl.name.valid}
                                        isFormValid={this.state.isFormValid}
                                    />
                                    <Input
                                        label="% выполнения"
                                        type="text"
                                        value={this.state.formControl.percentComplete.value}
                                        onChange={event => this.setField(event, "percentComplete")}
                                        shouldValidate={false}
                                    />
                                    <Myselect
                                        value={this.state.formControl.status.value}
                                        label="Статус этапа"
                                        options={[
                                            {value: "inactive", label: "Не начат"},
                                            {value: "active", label: "В работе"},
                                            {value: "partially", label: "Частично выполнен"},
                                            {value: "completed", label: "Завершен"}
                                        ]}
                                        onChange={event => this.setField(event, "status")}
                                        shouldValidate={false}
                                    />
                                    <Myselect
                                        value={this.state.formControl.metal_type.value}
                                        label="Источник металла"
                                        options={[
                                            {value: "company", label: "От компании"},
                                            {value: "customer", label: "От клиента"},
                                        ]}
                                        onChange={event => this.setField(event, "metal_type")}
                                        shouldValidate={false}
                                    />
                                    <DateTime
                                        label="Дата начала"
                                        className="c12"
                                        event="startDate"
                                        dateFormat="dd-MMMM-yyyy"
                                        value={this.state.formControl.startDate.value}
                                        valid={this.state.formControl.startDate.valid}
                                        isClearable={true}
                                        handleChange={this.handleChange}
                                    />
                                    <DateTime
                                        label="Дата завершения"
                                        className="c12"
                                        event="endDate"
                                        dateFormat="dd-MMMM-yyyy"
                                        value={this.state.formControl.endDate.value}
                                        valid={this.state.formControl.endDate.valid}
                                        isClearable={true}
                                        handleChange={this.handleChange}
                                    />
                                    <DateTime
                                        label="Крайняя дата"
                                        className="c12"
                                        event="deadline"
                                        dateFormat="dd-MMMM-yyyy"
                                        value={this.state.formControl.deadline.value}
                                        valid={this.state.formControl.deadline.valid}
                                        isClearable={true}
                                        handleChange={this.handleChange}
                                    />
                                    <div className="clear"/>
                                    <ModAutoSelect
                                        label="Ответственный"
                                        link="/ajax/settings/user?filter[name][like]="
                                        minLength="0"
                                        result="name"
                                        event="responsible"
                                        valid={this.state.formControl.responsible.valid}
                                        shouldValidate={false}
                                        addType="responsible"
                                        entity={this.state.formControl.responsible.arr}
                                        entityName="name"
                                        updateData={this.updateData}
                                        unLink={this.unLink}
                                        //redirectLink="/order/"
                                    />
                                </form>
                            </div>
                        </div>
                        <div className="contact-right-col">
                            <div className="taskcontainer">
                                {(this.state.tasks) ? this.state.tasks.map((task, index) =>
                                    <Task
                                        key={index}
                                        openTask={(id, index) => this.setState({ open: "r-open", openType: "task", taskIndex: index, taskId: id})}
                                        index={index}
                                        id={task.id}
                                        tasktext={task.subject}
                                        end_date={task.end_date}
                                        status={task.status}
                                    />
                                ) : null}
                            </div>
                            <div className="tabs-header">
                                <ul>
                                    <li><a className={(this.state.layout === "information") ? "active" : null} onClick={() => this.setState({ layout: 'information' })}>Основная информация</a></li>
                                    <li><a className={(this.state.layout === "history") ? "active" : null} onClick={() => this.setState({ layout: 'history' })}>Хронология</a></li>
                                    <li><a className={(this.state.layout === "related") ? "active" : null} onClick={() => this.setState({ layout: 'related' })}>Связи</a></li>
                                    <li><a className={(this.state.layout === "files") ? "active" : null} onClick={() => this.setState({ layout: 'files' })}>Файлы</a></li>
                                </ul>
                            </div>
                            <div className="tab-contai er">
                                {(this.state.layout === "history") ? <History type="project/work" id={this.props.match.params.id} /> : null}
                                {(this.state.layout === "information") ? <Information
                                    setDescription={(e) => {
                                        const formControl = this.state.formControl;
                                        formControl.description.value = e;
                                        this.setState({ formControl, tch: false })
                                    }}
                                    stagesFunc={this.stagesFunc}
                                    children={this.state.children}
                                    resources={this.state.resources}
                                    machines={this.state.machines}
                                    description={this.state.formControl.description.value}
                                    setField={this.setField}
                                    stages={this.stagesDo}
                                    openMachine={(id) => this.setState({ open:"r-open", openType:"machine", machineId: id })}
                                    deleteMachine={this.deleteMachine}
                                    moveMachine={this.moveMachine}
                                    machineLoading={this.state.machineLoading}
                                    {...this.props}
                                /> : null}
                                {(this.state.layout === "related") ? <Related type="project/work" id={this.props.match.params.id} related="7" /> : null}
                                {(this.state.layout === "files") ? <Files files={this.state.files} filesFunc={this.filesFunc} relatedType="7" related_id={this.props.match.params.id}
                                /> : null}
                            </div>
                        </div>
                    </div>

                    <React.Fragment>
                        <div className={"right-modal scrollbar " + this.state.open}><div className="save">
                            {/*Задача*/}
                            {(this.state.open === "r-open" && this.state.openType === "task") ?
                                <AddTask
                                    task={this.task}
                                    id={this.state.taskId}
                                    index={this.state.taskIndex}
                                    close={() => this.setState({ open:'', openType: '', taskId:'', taskIndex: '', machineId:'' })}
                                    tie={this.props.match.params.id}
                                    relatedType="7"
                                    saveProduct={this.saveProduct} /> : null}
                            {/*Ресурс*/}
                            {(this.state.open === "r-open" && this.state.openType === "stages") ?
                                <AddProjectRes
                                    stages={this.stagesDo}
                                    projectId={this.props.match.params.id}
                                    id={this.state.stageId}
                                    refresh={() => {this.showResource(); this.setState({ open:null })}}
                                    close={() => this.setState({ open:'', openType: '', taskId:'', taskIndex: '', machineId:'' })}
                                /> : null}
                            {/*Станок*/}
                            {(this.state.open === "r-open" && this.state.openType === "machine") ?
                                <AddMAchine
                                    projectId={this.props.match.params.id}
                                    machineId={this.state.machineId}
                                    refresh={() => {this.showMachine(); this.setState({ open:null, machineId:'' })}}
                                    close={() => this.setState({ open:'', openType: '', taskId:'', taskIndex: '', machineId:'' })}
                                /> : null}
                            {(this.state.open === "r-open" && this.state.openType === "stag") ?
                                <AddProjectStage
                                    addStage={this.stagesFunc}
                                    projectId={this.state.project_id}
                                    id={this.state.stageId}
                                    stId={this.state.id}
                                    close={() => this.setState({ open:'', openType: '', taskId:'', taskIndex: '', machineId:'' })}
                                    {...this.props}

                                /> : null}
                        </div></div>
                        {this.state.open ? <Bgdrop close={() => this.setState({ open:'', openType: '', taskId:'', taskIndex: '', machineId:'' })} /> : null}
                    </React.Fragment>
                        </div>
                    </CSSTransition>
                </div>
        )
    }
}

export default withRouter(StageView);