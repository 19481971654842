import React, {useEffect, useState} from "react";
import {outItems} from "./Config"

type Props = {
    steps: Object,
    userId: number | null,
    savedUser: number | null,
    onClick: () => void
}

const EducateProgress: React.FC<Props> = ({ steps, onClick, userId, savedUser }) => {
    const [percent, setPercent] = useState<number>(0);
    const [display, setDisplay] = useState<string>("none")

    useEffect(() => {

        if(steps) {
            let data = ((Object.keys(steps).length / (outItems.length - 3)) * 100).toFixed(0)
            if((savedUser === userId) && +data < 98){
                setDisplay("block")
            }
            setPercent(+data)
        }
    })

    return <div
        style={{ display: display }}
        className="educate-progress-parent"
        onClick={onClick}
        data-tooltip={`обучение пройдено на ${percent}%`}
    >
        <div className="educate-progress-container">
            <div style={{ width: `${percent}%` }} className="educate-progress-child" />
        </div>
        <span>обучение пройдено на {percent}%</span>
    </div>
}

export default EducateProgress