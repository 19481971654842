import Input from "../UI/Input";
import Myselect from "../UI/Select";
import ModAutoSelect from "../UI/ModAutoSelect";
import React, {useEffect} from "react";
import {NewAutoSelect} from "../NewUi/NewAutoSelect";

const Field = (attributes) => {
    return (
        <>
            {(() => {
                if(!attributes.showInZone) {
                    return <></>
                }
                switch (attributes.type) {
                    case 'text':
                    case 'date':
                    case 'number':
                        return (
                            <Input
                                {...attributes}
                            />
                        )
                    case 'select':
                        return (
                            <Myselect
                                {...attributes}
                            />
                        )
                    case 'entity':
                        return (
                            <NewAutoSelect
                                {...attributes}
                                updateData={(item, event, type) => attributes.onChange(event, item || 'clear')}
                                onEnterData={(item, event, type) => attributes.onChange(event, 'onSaveDataTargetEnter')}
                                searchBy={attributes.result}
                                clear
                            />
                        )
                    default:
                        return (
                            <>Not defined</>
                        )
                }
            })()}
        </>
    )
}

const MemoField = React.memo(Field)

export const GenerateForm = ({ config, values, errors, onChange, disabled, zone = 1, onSaveData }) => {
    const fieldsRef = React.useRef()

    useEffect(() => {
        fieldsRef.current = (name, value, item) => {
            if(value !== 'onSaveDataTargetEnter') {
                onChange(name, value, item)
            } else if(value === 'onSaveDataTargetEnter' && !values.tch) {
                onSaveData()
            }
        }
    })

    const handleChange = React.useCallback((e, item) => {
        const name = e.target.getAttribute('name')
        const value = e.keyCode && e.keyCode === 13 && onSaveData ? 'onSaveDataTargetEnter' : e.target.value

        const update = () => {
            fieldsRef.current(name, value, item)
        }
        update()
    }, [])

    return (
        <div className={'form-container'}>
            {
                Object.keys(config).map(key => (
                    <MemoField
                        {...{...config[key],
                            shouldValidate: true,
                            itemName: key,
                            name: key,
                            value: values[key],
                            //disabled: disabled[key],
                            valid: !errors[key],
                            errorMessage: errors[key] || 'Ошибка валидации',
                            options: values[config[key].options_from],
                            entity: config[key].entity_from && values[config[key].entity_from],
                            showInZone: config[key].zone === zone,
                            // onKeyDown: e => handleChange(e)
                        }}
                        onChange={handleChange}
                        onKeyDown={handleChange}
                    />
                ))
            }
        </div>
    )
}