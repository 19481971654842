import {NavLink} from "react-router-dom";
import React from "react";

const returnExpand = (str) => {
    switch (str) {
        case 'opportunity_id': return 'opportunity';
        case 'lead_id': return 'lead';
        case 'owner_id': return 'owner';
        case 'stock_id': return 'stock';
        case 'order_id': return 'order';
        case 'account_id': return 'account';
        case 'contact_id': return 'contact';
        case 'main_contact_id': return 'contact';
        case 'provider_id': return 'provider';
        case 'category_id': return 'category';
        case 'manufacturer_id': return 'manufacturer';
        case 'project_id': return 'project';
        case 'currency_id': return 'currency';
        case 'document_id': return 'document';
        case 'invoice_id': return 'invoice';
        case 'source_id': return 'source';
        default: return '';
    }
}

const switchLink = (str) => {
    switch (str) {
        case 'opportunity_id': return 'opportunity';
        case 'lead_id': return 'lead';
        case 'order_id': return 'order';
        case 'account_id': return 'account';
        case 'contact_id': return 'contact';
        case 'provider_id': return 'account';
        case 'manufacturer_id': return 'account';
        case 'project_id': return 'project';
        case 'document_id': return 'document';
        case 'invoice_id': return 'invoice';
        default: return false
    }
}

export const buildExpandLink = (data) => {
    if(data) {
        return ',' + data.split(',').map(item => returnExpand(item)).filter(el => !!el)
    } else {
        return ''
    }

}

export const ExtraColumnsRender = ({ columns, filterFields, item }) => {
    const renderColumn = (value, type, fieldConfig) => {

        if(value.includes('f_') && value && item[value]) {
            if(item[value] && item[value].type === 'multiselect') {
                return <div data-tooltip={item[value].value.join(',')}><span
                    style={{ textOverflow: 'ellipsis', maxWidth: '150px', overflow: "hidden", whiteSpace: 'nowrap', display: 'block' }}
                >
                    {item[value].value.join(',')}
                </span></div>
            } else if(item[value] && item[value].type === 'select') {
                return <div data-tooltip={item[value].value}>
                    <span className={'nowrap-list-column'}>{item[value].value}</span>
                </div>
            } else {
                return <div data-tooltip={item[value].value}>
                    <span className={'nowrap-list-column'}>{item[value].value}</span>
                </div>
            }
        }

        switch (type) {
            case 'text':
            case 'number':
            case 'date': return (
                <div data-tooltip={item[value]}>
                    <span className={'nowrap-list-column'}>{item[value]}</span>
                </div>
            )
            case 'lookup':
            case 'entity': return (
                item[returnExpand(value)] && (
                    switchLink(value) ?
                        <NavLink to={switchLink(value)}>{item[returnExpand(value)][fieldConfig.filterSearch]}</NavLink> :
                        item[returnExpand(value)][fieldConfig.filterSearch]
                )
            )
            default: return 'Not defined'
        }
    }

    return (
        <>
            {
                (columns || []).map(col => {
                    const fieldConfig = filterFields.find(el => el.value === col)
                    return <td>
                        {fieldConfig && renderColumn(col, fieldConfig.fieldType, fieldConfig)}
                    </td>
                })
            }
        </>
    )
}