import React, {useCallback, useEffect, useRef, useState} from "react";
import {CSSTransition} from "react-transition-group";
import {ModalLoader} from "../UI/Loader";
import ScrollContainer from 'react-indiana-drag-scroll';
// https://bestofreactjs.com/repo/g-delmo-use-draggable-scroll-react-awesome-react-hooks

export const CustomKanban = ({
    columns,
    items,
    drugEnd,
    header,
    kanbanItem,
    dragElement,
    setDragElement,
    activeCol,
    setActiveCol,
    itemKey,
    loading,
    actionButtons = [],
    loadMore = () => {}, page, metaData
}) => {
    const [colHeight, setColHeight] = useState(window.innerHeight)

    const butStyle = {
        padding: '20px',
        width: actionButtons ? `${100 / actionButtons.length}%` : 0,
        color: '#fff',
        textAlign: 'center',
        boxSizing: 'border-box',
        fontSize: '14px',
    }

    const KanbanButtons = () => {
        return (
            <>
                {dragElement && <div style={{
                    position: 'absolute',
                    bottom: '0px',
                    width: '100%',
                    display: 'flex'
                }}>
                    {
                        actionButtons.map(item => (
                            <div
                                onDragOver={e => e.preventDefault()}
                                onDragEnter={(e) => {
                                    e.stopPropagation()
                                    setActiveCol(item.value)
                                }}
                                className={'delete-col'} style={{
                                ...butStyle,
                                background: item.color,
                                opacity: activeCol === item.value ? 1 : 0.5
                            }}>
                                {item.label}
                            </div>
                        ))
                    }
                </div>}
            </>
        )
    }

    const scrollBlock = useRef(null)
    const getColumnHeight = useCallback(() => {
        if(!scrollBlock) {
            return false
        }

        if(!scrollBlock.current) {
            return false
        }

        if(!scrollBlock.current.scrollHeight) {
            return false
        }

        if(scrollBlock.current.scrollHeight !== scrollBlock.current.clientHeight) {
            setColHeight(scrollBlock.current.scrollHeight)
        }
    }, [items, columns])

    const loadMoreTrigger = () => {
        if (scrollBlock && scrollBlock.current) {
            scrollBlock.current.addEventListener('scroll', (e) => {
                const blockHeight = scrollBlock.current.scrollHeight - scrollBlock.current.clientHeight;
                // console.log('eeee', blockHeight, scrollBlock.current.scrollTop)
                if(blockHeight.toFixed(0) === scrollBlock.current.scrollTop.toFixed(0)) {
                    loadMore(page, metaData)
                }
            })
        }
    }

    useEffect(() => {
        setTimeout(getColumnHeight, 1000)
        loadMoreTrigger()
    }, [scrollBlock, items])

    const scrollToRight = () => {
        scrollBlock.current.scrollTo({ left: scrollBlock.current.scrollLeft + 200, behavior: 'smooth' })
    }
    const scrollToLeft = () => {
        scrollBlock.current.scrollTo({ left: scrollBlock.current.scrollLeft - 200, behavior: 'smooth' })
    }

    return (
        <>
            <div ref={scrollBlock} id={'kanban-cols'} className="kanban-cols scrollbar"
                 style={{ display: 'flex', alignItems: 'flex-start', position: 'relative', flexGrow: 1, overflow: 'auto', marginLeft: dragElement && '-30px' }}
                 onDragEnter={() => { setActiveCol(null) }}
            >
                {dragElement && <div
                    style={{ width: '30px', height: colHeight, left: '0px', top: 0, position: 'sticky', zIndex: 5, flexShrink: 0 }}
                    onDragEnter={(e) => {
                        scrollToLeft()
                    }}
                />}
                {<CSSTransition in={loading} timeout={200} classNames="slide" unmountOnExit>
                    <ModalLoader />
                </CSSTransition>}
                {
                    columns.map((item, colIndex) => <div
                        onDragOver={e => e.preventDefault()}
                        onDragEnter={(e) => {
                            e.stopPropagation()
                            setActiveCol(item.id)
                        }}
                        className={'kanban-cols-item'}
                        style={{
                            height: colHeight,
                            padding: '8px',
                            minHeight: '150hv',
                            background: activeCol === item.id && '#e3e3e3',
                            // width: `${100 / columns.length}%`
                            width: '260px'
                        }}
                    >
                        {header(item)}
                        {colIndex === -1 && <div className={'custom-kanban-create-button'}>Добавить задачу</div>}
                        {
                            (items || []).filter(el => +el[itemKey] === +item.id).map((el) => <div
                                draggable
                                onDragStart={(e) => setDragElement(el.id)}
                                onDragEnd={() => {
                                    if(activeCol && String(activeCol) !== String(el[itemKey])) {
                                        drugEnd(el)
                                    } else {
                                        setActiveCol(null)
                                        setDragElement(null)
                                    }
                                }}
                            >
                                <div style={{ cursor: 'all-scroll' }}>
                                    {kanbanItem(el)}
                                </div>
                            </div>)
                        }
                    </div>)
                }
                {dragElement && <div
                    style={{ width: '30px', height: colHeight, right: 0, top: 0, position: 'fixed', zIndex: 5 }}
                    onDragOver={e => e.preventDefault()}
                    onDragEnter={(e) => {
                        scrollToRight()
                    }}
                />}
            </div>
            <KanbanButtons />
        </>
    )
}