import React, {useEffect, useState} from "react";
import Notif from "../../components/Notif";
import {
    deleteDocumentService,
    getDocumentPrepareService,
    getSingleDocumentService,
    updateDocumentService
} from "../../services/document/document";
import {parseResponse, parseResponseError} from "../../components/cardViewComponents/parseFunctions";
import GetPostAditionalFields from "../../components/get_post_aditionalFields";
import {ModalLoader} from "../../components/UI/Loader";
import LeaveModal from "../../components/other/LeaveModal";
import {TagsNew} from "../../components/cardViewComponents/TagsNew";
import {MemoButton} from "../../components/cardViewComponents/MemoButton";
import PrintPlates from "../../Modal/PrintPlates";
import {GenerateForm} from "../../components/cardViewComponents/GenerateForm";
import {ContactCard} from "../../components/cardViewComponents/ContactCard";
import {AccountCard} from "../../components/cardViewComponents/AccountCard";
import {Tasks} from "../../components/Task";
import {filesFunc, TabsRender} from "../../components/fieldGen";
import {CSSTransition} from "react-transition-group";
import {InfoTable} from "../../components/cardViewComponents/InfoTable";
import {AdditionalFieldsChanged} from "../../components/additionalFields";
import {additionalFieldsValidation} from "../../components/cardViewComponents/additionalFieldsValidation";
import {validationData} from "../../components/UI/formValid";
import {validationConfig} from "../../components/cardViewComponents/validationConfig";
import {TaskModal, updateEntityTasks} from "../tasks/TaskModal";
import Related from "../../components/related/related";
import Files from "../../components/files/files";
import {ConflictModal} from "../../components/cardViewComponents/ConflictModal";
import {FilesDropDown} from "../../components/FilesDropDown";

export const tabsConfig = [
    {value:"information", label:"Информация"},
    {value:"related", label:"Связи"},
    {value:"files", label:"Файлы"},
]

const documentConfig = {
    name: {
        label: 'Название',
        type: 'text',
        className: 'w100',
        validation: { required: true, maxLength: 255 },
        zone: 1
    },
    status_id: {
        label: 'Статус документа',
        type: 'select',
        options: [],
        className: 'w100',
        options_from: 'statuses', val: 'id', lab: 'value',
        validation: { required: true },
        zone: 1,
    },
    type_id: {
        label: 'Тип документа',
        type: 'select',
        options: [],
        className: 'w100',
        options_from: 'types', val: 'id', lab: 'value',
        validation: { required: true },
        zone: 1,
    },
    date: {
        label: 'Дата',
        type: 'date',
        className: 'w100',
        validation: { maxLength: 255 },
        zone: 1
    },
    owner_id: {
        label: 'Ответственный',
        type: 'entity',
        link: "/ajax/settings/user?filter[name][like]=",
        result: 'name',
        entity_from: 'owner',
        entityName: 'name',
        className: 'w100',
        minLength: 0,
        validation: { required: true },
        zone: 1
        // redirectLink
    },
    order_id: {
        label: 'Заказ',
        type: 'entity',
        link: "/ajax/order?fields=id,name&filter[name][like]=",
        result: 'name',
        entity_from: 'order',
        entityName: 'name',
        className: 'w50-p',
        minLength: 0,
        zone: 2,
        redirect: 'order'
    },
    opportunity_id: {
        label: 'Сделка',
        type: 'entity',
        link: "/ajax/opportunity?fields=id,name&filter[name][like]=",
        result: 'name',
        entity_from: 'opportunity',
        entityName: 'name',
        className: 'w50-p',
        minLength: 0,
        zone: 2,
        redirect: 'opportunity'
    },
    project_id: {
        label: 'Проект',
        type: 'entity',
        link: "/ajax/project?fields=id,name&filter[name][like]=",
        result: 'name',
        entity_from: 'project',
        entityName: 'name',
        className: 'w50-p',
        minLength: 0,
        zone: 2,
        redirect: 'project'
    },
    invoice_id: {
        label: 'Счет',
        type: 'entity',
        link: "/ajax/invoice?fields=id,name&filter[name][like]=",
        result: 'name',
        entity_from: 'invoice',
        entityName: 'name',
        className: 'w50-p',
        minLength: 0,
        zone: 2,
        redirect: 'invoice'
    },
    contact_id: { type: 'entity', entity_from: 'contact' },
    account_id: { type: 'entity', entity_from: 'account' },
    editorTags: {},
    created_at: {},
    updated_at: {},
}

export const DocumentViewNew = ({ match, history, location }) => {
    const urlParams = new URLSearchParams(location.search);
    const { params: { id } } = match
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState({})
    const [disabled, setDisabled] = useState({})
    const [conflict, setConflict] = useState(false)
    const [modalData, setModalData] = useState(null)
    const [values, setValues] = useState({
        name: '',
        status_id: '',
        statuses: [],
        type_id: '',
        types: [],
        owner_id: '',
        owner: null,
        date: '',
        contact_id: '',
        contact: null,
        account_id: '',
        account: null,
        opportunity_id: '',
        opportunity: null,
        project_id: '',
        project: null,
        order_id: '',
        order: null,
        invoice_id: '',
        invoice: null,
        editorTags: '',
        additionalFields: [],
        tasks: [],
        files: [],
        tch: true,
        begin_update_datetime: null,
        created_at:'',
        updated_at:'',
    })

    const getPrepare = async () => {
        try {
            const { data, status } = await getDocumentPrepareService()
            if(status === 200) {
                await getData(data)
            }
        } catch (e) { }
        setLoading(false)
    }

    const getData = async (prepareData) => {
        setLoading(true)

        try {
            const { data, status, response } = await getSingleDocumentService({
                id, expand: 'additionalFields,owner,account,contact,opportunity,project,order,invoice,files,tasks'
            })

            const parseResponseData = parseResponseError(response);
            if(parseResponseData.notFound) history.push('/404')

            const dataArr = {
                ...values,
                ...parseResponse(documentConfig, data, {}),
                statuses: prepareData.statuses,
                types: prepareData.types,
                files: data.files,
                tasks: data.tasks,
                additionalFields: GetPostAditionalFields("get", data.additionalFields) || []
            }

            if(status === 200 && data.id) {
                setValues(dataArr)
            }
        } catch (e) { console.error('getDocumentDataError', e) }

        setLoading(false)
    }

    useEffect(() => {
        setLoading(true)
        getPrepare().then()
    }, [id])

    const saveItem = async (forcibly) => {
        const { name, status_id, type_id, owner_id, date, contact_id, account_id, opportunity_id, project_id, order_id, invoice_id, editorTags, updated_at } = values
        const additionalFieldsValidationRes = additionalFieldsValidation(values.additionalFields)

        const errorsData = validationData(
            {...values, ...additionalFieldsValidationRes.values},
            {...validationConfig(documentConfig), ...additionalFieldsValidationRes.validationRules}
        )

        setErrors(errorsData)
        if(Object.keys(errorsData).length > 0) {
            Notif('required-fields')
            return
        }

        setLoading(true)

        try {
            const { data, status, response } = await updateDocumentService({ id, data: {
                    name, status_id, type_id, owner_id, date, contact_id, account_id, opportunity_id, project_id, order_id,invoice_id,
                    editorTags, begin_update_datetime: forcibly === 'ok' ? null : updated_at,
                    additionalFields: GetPostAditionalFields("post", values.additionalFields),
                }})

            if(status === 200 && data.id) {
                Notif('save', 'Запись успешно изменена')
                setValues(({ ...values, updated_at: data.updated_at, tch: true, begin_update_datetime: null }))
            }

            const parseResponseData = parseResponseError(response);
            if(parseResponseData.saveConflict) setConflict(true)
            if(parseResponseData.validationErrors) {
                setErrors(parseResponseData.validationErrors)
            }

        } catch (e) { console.error(e) }

        setLoading(false)
    }

    const deleteItem = async () => {
        if(window.confirm('Удалить документ?')) {
            setLoading(true)
            try {
                const { status } = await deleteDocumentService({ id })
                if(status === 200 || status === 204) {
                    setValues(prevState => ({ ...prevState, tch: true }))
                    Notif('delete', 'Документ успешно удален')
                    history.push('/document')
                }
            } catch (e) { console.error(e) }
            setLoading(false)
        }
    }

    const changeFields = (key, value, item) => {
        let obj = {}
        if(item) {
            if(item === 'clear') obj[documentConfig[key].entity_from] = null
            else obj[documentConfig[key].entity_from] = item
        }

        setValues({
            ...values, ...obj, [key]: item ? item.id : value,
            begin_update_datetime: !values.begin_update_datetime ? new Date() : values.begin_update_datetime,
            tch: false
        })
    }

    return (
        <>
            {loading && <ModalLoader />}
            <div className="page">
                <LeaveModal when={!values.tch} />
                <div className="newList-btn-group">
                    <div className="newList-btn-group-left">
                        <TagsNew
                            editorTags={values.editorTags} entity={'document'}
                            setValues={data => setValues({ ...values, editorTags: data, tch: false })}
                        />
                        <MemoButton
                            onClick={() => setModalData({ type: 'task' })}
                            mobileIcon={'flaticon-notes mdb'} title={'Добавить задачу'} className={'mr0'} />

                        <PrintPlates entityId={26} entity={`document`} id={id} />
                    </div>
                    <div className="newList-btn-group-right">
                        <a onClick={deleteItem} className="butt-group-card-wrap btni ml10">
                            <span>Удалить</span>
                            <i className="flaticon-delete-2 mdb" />
                        </a>
                        <MemoButton onClick={saveItem} disabled={values.tch} mobileIcon={"flaticon-interface-1 mdb"} title={'Сохранить'} className={'ml5'} />
                    </div>
                </div>
                <div className="clear" />

                <div className="contact-page sbform">
                    <div className="contact-left">
                        <div className="contact-left-col">
                            <h3>Данные документа</h3>
                            <GenerateForm
                                config={documentConfig}
                                { ...{ values, errors, disabled } }
                                onChange={changeFields}
                                onSaveData={saveItem}
                            />
                        </div>

                        {!values.account && <ContactCard
                            contact={values.contact}
                            setDataValues={data => {
                                setValues(({...values, ...data, tch: false}))
                            }}
                        />}
                        {!values.contact && <AccountCard
                            account={values.account}
                            setDataValues={data => {
                                setValues(({...values, ...data, tch: false}))
                            }}
                        />}
                    </div>

                    <div className="contact-right-col">
                        <Tasks tasks={values.tasks} openTask={data => setModalData(data)} />
                        <TabsRender
                            setState={({ layout }) => history.push(`?layout=${layout}`)}
                            state={{ layout: urlParams.get('layout') || tabsConfig[0].value }}
                            arr={tabsConfig}
                        />
                        <div className="tab-container">
                            <CSSTransition in={(urlParams.get('layout') || tabsConfig[0].value) === tabsConfig[0].value} timeout={{enter: 0, exit: 0}} classNames="my-node" unmountOnExit>
                                <div className="tab-content sbform clearFieldPadding">
                                    <InfoTable
                                        arr={[
                                            { value: 'Дата создания', label: values.created_at },
                                            { value: 'Дата обновления', label: values.updated_at },
                                        ]}
                                    />
                                    <div className="connection"><span>Основная информация</span></div>
                                    <GenerateForm
                                        config={documentConfig}
                                        { ...{ values, errors, disabled } }
                                        onChange={changeFields}
                                        onSaveData={saveItem}
                                        zone={2}
                                    />
                                    <div className="connection"><span>Дополнительная информация</span></div>
                                    <AdditionalFieldsChanged
                                        errors={errors}
                                        items={values.additionalFields}
                                        updateAdditionalFields={data => setValues({...values, additionalFields: data, tch: false })}
                                    />
                                </div>
                            </CSSTransition>
                            <CSSTransition in={urlParams.get('layout') === tabsConfig[1].value} timeout={{enter: 0, exit: 0}} classNames="my-node" unmountOnExit>
                                <Related
                                    type="document" id={id} related="26" tasks mails expense document_id={id}
                                    contactEmail={(values.contact) ? values.contact.email : null}
                                    accountEmail={(values.account) ? values.account.email : null}
                                    contact_id={values.contact_id}
                                    account_id={values.account_id}
                                />
                            </CSSTransition>
                            <CSSTransition in={urlParams.get('layout') === tabsConfig[2].value} timeout={{enter: 0, exit: 0}} classNames="my-node" unmountOnExit>
                                <FilesDropDown
                                    related_id={id}
                                    related_type={"26"}
                                    files={values.files}
                                    updateFile={filesData => setValues(prevState => ({
                                        ...prevState,
                                        files: [filesData, ...prevState.files]
                                    }))}
                                    updateFiles={files => setValues(prevState => ({ ...prevState, files }))}
                                />
                            </CSSTransition>
                        </div>
                    </div>
                </div>
            </div>

            {conflict && <ConflictModal
                entity={'document'} id={id} updated_at={values.begin_update_datetime}
                save={() => saveItem('ok')} close={() => setConflict(false)}
            />}
            <TaskModal
                id={modalData && modalData.id} close={() => setModalData(null)} index={modalData && modalData.index}
                task={(type, data, index) => { setValues({ ...values, tasks: updateEntityTasks(values.tasks, type, data, index) }) }}
                related={{ id, type: 26 }} isOpen={modalData && modalData.type === 'task'}
            />
        </>
    )
}