import React from 'react';
import Textarea from '../../components/UI/Textarea';
import axios from 'axios';
import InfiniteScroll from "react-infinite-scroll-component";
import SoundClick from '../../components/soundClick';
import withGlobalState from "../../components/other/withGlobalState";
let URL = '';

function findAndReplaceLink(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function(url) {
        return '<a target="_blank" href="' + url + '">' + url + '</a>';
    })
}

class MessageList extends React.Component {
    state = {
        id:null,
        open:false,
        users:[],
        messages:[],
        notifications: [],
        read: false,
        perPage: 50,
        totalCount: Number,
    };
    componentDidMount(){
        this.onMsg();
    }
    addMessage = (msg) => {
       //console.log("msg",msg.sender, this.state.id, this.props.id.userId);
        if(this.state.id == msg.sender.id || msg.sender.id == this.props.id.userId){
            let messages = this.state.messages;
            messages.unshift(msg);
            this.setState({ messages })
        }
        if(this.state.id == msg.sender.id){
            {setTimeout(SoundClick("click"))}
        }
    }
    onMsg = () => {
        this.props.id.wsChat.onmessage = evt => {
            const message = JSON.parse(evt.data);
            console.log("message", message);

            switch (message.type) {
                case "users":
                    this.setState({ users: JSON.parse(message.data) });
                    break;
                case "history":
                    this.setState({ messages: message.data[0], totalCount: message.data[1].totalCount,  open: true });
                    break;
                case "message":
                    this.addMessage(message.data);
                    break;
                default:
                    console.log( 'Я таких значений не знаю' );
            }
        };

        setTimeout(this.showUsers, 300);
    }
    showUsers = () => {
        let a = {data: {message: null, whom: 1}, type: "users"};
        this.props.id.wsChat.send(JSON.stringify(a))
    };

    showMessage = (id, page) => {
            this.state.pageNumber++;
            let a = {data: {message: null, whom: id, perPage:this.state.perPage}, type: "history"};
            this.props.id.wsChat.send(JSON.stringify(a));
            this.setState({id: id});
        }


    /*componentDidMount(){
        axios.get(`/ajax/notification?per-page=${this.state.perPage}`, {
            method: 'get',
        }).then((response) => {
            this.setState({notifications: response.data.items, totalCount: response.data._meta.totalCount, links: response.data._links, pageCount: response.data._meta.pageCount});
        });
    }
    fetchMoreData = () => {
        if(this.state.pageNumber < this.state.pageCount) {
            this.state.pageNumber++;
            setTimeout(() => {
                axios.get(`/ajax/notification?per-page=${this.state.perPage}`, {
                    params: {
                        page: this.state.pageNumber
                    }
                }).then((response) => {
                    this.setState({
                        notifications: this.state.notifications.concat(response.data.items)
                    });
                });
            }, 1000);
        }
    };*/
    render(){
        return <div className="sbform create-activ">
            {
                console.log(this.state.perPage)
            }
            <div className="rmod-header">
                <b>Чат</b>
                {(this.state.id) ? <a style={{ right: "65px" }} className="mclose 13" onClick={() => {this.setState({ id:null }); this.showUsers()}}><i className="flaticon-up-arrow-1" /></a> : null}
                <a style={{ right: "15px" }} className="mclose 13" onClick={this.props.close}><i className="flaticon-close" /></a>
            </div>
            {(this.state.id && this.state.open) ?
                <MessageContainer
                    userId={this.props.id.userId}
                    totalCount={this.state.totalCount}
                    perPage={this.state.perPage}
                    showMessage={(id) => this.setState({ perPage: this.state.perPage + 20 }, () => this.showMessage(this.state.id, this.state.perPage + 50))}
                    addMessage={(msg) => this.addMessage(msg)}
                    messages={this.state.messages}
                    toList={() => this.setState({ id:null })}
                    id={this.state.id}
                    ws={this.props.id}
                    setSteps={this.props.setSteps}
                /> :
                <MsList setId={(id) => {this.showMessage(id) } } users={this.state.users} />
            }

        </div>
    }
}

export default withGlobalState(MessageList);

const MsList = props => {
    return <div className="chatListContainer">
        {
            (props.users) ? props.users.map((item, index) =>
                <a key={index} onClick={() => props.setId(item.id)} className={`${(item.unreadCount > 0) ? `active` : ''} chatItem`}>
                    <div className="chatFace"><img src="https://colegioclassea.com.br/wp-content/themes/PageLand/assets/img/avatar/avatar.jpg" alt=""/></div>
                    <div className="chatName"><h4>{item.name}</h4>{(item.online) ? <b style={{ color: "#62a091 "}}>Онлайн</b> : <b style={{ color: "#c2263f "}}>Оффлайн</b>}</div>
                </a>
            ) : null
        }
    </div>
};



class MessageContainer extends React.Component {
    state = {
        name: 'Bob',
        message:''
    };

    addMessage = message =>
        this.setState(state => ({ messages: [message, ...state.messages] }));

    editMsg = (event) => {
        if(event.ctrlKey && event.keyCode == 13) {
            this.submitMessage()
        } else {
            this.setState({ message: event.target.value })
        }
    }
    submitMessage = messageString => {
        if(this.state.message.length >= 1) {
            const message = { name: this.state.name, message: messageString };
            let a = {data: {message: this.state.message, to: this.props.id}, type: "message"};
            this.props.ws.wsChat.send(JSON.stringify(a));
            this.props.addMessage( {sender: {id:this.props.userId}, message: this.state.message, timestamp: "Только что"}   );
            if(this.state.message === "Начнем работу") this.props.setSteps("inside-chat", true, true)
            this.setState({ message: '' })
        }
    }

    render(){
        return <div><div className="chatBlock">
            {/*<button onClick={() => this.props.showMessage()}>show</button>*/}
            {
                this.props.messages ? this.props.messages.map((item, index) =>
                    (item.sender.id === this.props.userId) ?
                        <div>
                            {/*{console.log(item.timestamp )}*/}
                            <div className="clear" />
                            <div className="itemClear myMessage">
                            <p><p dangerouslySetInnerHTML={{__html: findAndReplaceLink(item.message)}} />  <em>{item.timestamp}</em></p>
                        </div></div> : <div>
                        <div className="clear" />
                        <div className="itemClear messageFrom">
                        <div className="msgImg">
                            <img src="https://colegioclassea.com.br/wp-content/themes/PageLand/assets/img/avatar/avatar.jpg" alt=""/>
                        </div>
                        <div className="msgBlock">
                            <a>{item.sender.username}</a>
                            <p><p dangerouslySetInnerHTML={{__html: findAndReplaceLink(item.message)}} /> <em>{item.timestamp}</em></p>
                        </div>
                    </div></div>
                ) : null
            }


            {(this.props.perPage < this.props.totalCount) ? <button onClick={this.props.showMessage} className="plusbutton">Загрузить еще</button> : null}
        </div>
            <div className="clear" />
            <div className="sendMessageBlock">
                <button onClick={this.submitMessage}><i className="flaticon-paper-plane" /></button>
                <Textarea value={this.state.message} onKeyDown={event => this.editMsg(event)} onChange={event => this.setState({ message: event.target.value })} />
            </div>
        </div>
    }
}