import React, {Component} from 'react';
import myaxios from '../../../components/Axios';
import Item from './Item';
import SendMail from '../../../Modal/SendMail';
import Bgdrop from '../../../Modal/Bgdrop';
import Loader from '../../../components/UI/Loader';
import Notif from '../../../components/Notif';
import {Route, NavLink, Switch} from 'react-router-dom';
import Myselect from '../../../components/UI/Select';
import AutoSelect from '../../../components/UI/AutoSelect';
import DateTime from '../../../components/UI/DateTime';
import EditTags from '../../../Modal/EditTags';

const filter = [
    {value: "from", label: "От кого", filterField: "autoselect", filterSearch:'email', filterLink:"/ajax/mail/search-contact?q=", filterLength:"3"},
    {value: "to", label: "Кому", filterField: "autoselect", filterSearch:'email', filterLink:"/ajax/mail/search-contact?q=", filterLength:"3"},
    {value: "subject", label: "Тема", filterField: "autoselect", filterSearch:'name', filterLink:"", filterLength:"9999"},
    {value: "tags", label: "Теги", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/mail/suggest?term=", filterLength:"0"},
    {value: "date", label: "Дата", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999"},
];

const sort = [
    {value: "name", label: "по названию а - я"},
    {value: "-name", label: "по названию я - а"},
    {value: "is_seen", label: "Прочитанные ▲"},
    {value: "-is_seen", label: "Непрочитанные ▼"},
    {value: "-date", label: "по дате созданиия"},
    {value: "updated_at", label: "по дате изменения"},
]

export default class Mail extends Component {
    state = {
        sortValue: "-date",
        filter:false,
        filterSort:false,
        filterValue: 'from',
        filterField:'autoselect',
        filterType:'name',
        filterLink:'',
        filterLength:'5',
        filterSearch: 'name',
        filterCondition: false,
        filterArr: [],
        filterParams: {
            from:{value:null,name:"От кого",key:"value", like:true},
            to:{id:null,value:null,name:"Кому",key:"value", like:true},
            subject:{value:null,name:"Тема",key:"value", like:true},
            tags:{id:null,value:null,name:"Теги", key:"multiple", like: '',multiple:[]},
            date: {id:null,value:null,name:"Дата",key:"value", like:true},
        },
        mails: [],
        totalCount : null,
        pageCount : null,
        loading: true,
        checkedmap: [],
        allcheck: false,
        page: 1,
        sync: false,
        reply: {},
        ping: 1,
        open: '',
        openType:'',
        openWidth:'',
        openId:'',
        openTags:''
    };
    loadStorage = () => {
        const filterParams = this.state.filterParams;
        if(localStorage.getItem('filterEmail')){
            let prs = JSON.parse(localStorage.getItem('filterEmail'));
            Object.keys(prs).map((key) => {
                filterParams[key] = prs[key];
            });
            this.setState({ filterParams })
        }
        setTimeout(this.loadData, 30);
    };
    convertFilter = () => {
        let filter = [];
        Object.keys(this.state.filterParams).map((key) => {
            if(this.state.filterParams[key][this.state.filterParams[key].key]) {
                if(this.state.filterParams[key].multiple){
                    this.state.filterParams[key].multiple.map(item =>
                        (item !== null) ? filter.push(`&filter[${key}]${`[in][]`}=${item.id}`) : null
                    )
                } else {
                    filter.push(`&filter[${key}][${(this.state.filterParams[key].type === "number") ? this.state.filterParams[key].like : this.state.filterParams[key].like}]=${this.state.filterParams[key][this.state.filterParams[key].key]}`)
                }
            }
        });
        let a = '';
        filter.map((item, index) => a += item );
        return a;
    }
    loadData = () =>{
        this.setState({loading: true})
        myaxios.get(`/ajax${this.props.match.url}${this.props.location.search}${(this.props.location.search) ? "&" : "?"}sort=${this.state.sortValue}${this.convertFilter()}&thread 
        `, {method: 'get',}).then((responce) =>{
            this.setState({
                mails : responce.data.items,
                totalCount : responce.data._meta.totalCount,
                pageCount : responce.data._meta.pageCount,
                loading: false
            })
        }).catch((error) => {
            if(error.response){
                if(error.response.status === 422) {
                    let prs = JSON.parse(localStorage.getItem('filterEmail'));
                    for(let i = 0; i < error.response.data.length; i++){
                        this.clearFunc(error.response.data[i].field);
                        delete prs[error.response.data[i].field];
                        Notif("Error", error.response.data[i].message);
                    }
                    localStorage.setItem('filterEmail', JSON.stringify(prs))
                }
            }
        });
        this.sortFunc();
    }
    componentDidMount(){
        document.title = 'Почта';
            this.loadStorage()
    }
    componentWillReceiveProps(nextProps){
        if(nextProps.match.params.id !== this.props.match.params.id) {
            document.title = 'Почта';
            setTimeout(this.loadStorage, 30)
        }
    }
    toPage = to => {
        const urlParams = new URLSearchParams(this.props.location.search)
        const key = urlParams.get('page');
        if(to === "next") {
            setTimeout(this.props.history.push(`${this.props.match.url}?page=${(this.props.location.search) ? (Number(key) + 1) : 2}`), 50);
            this.setState({ page: (this.props.location.search) ? (Number(key) + 1) : 2 });
            setTimeout(this.loadStorage, 200)
        } else {
            setTimeout(this.props.history.push(`${this.props.match.url}?page=${Number(key) - 1}`), 50);
            this.setState({ page: Number(key) - 1 });
            setTimeout(this.loadStorage, 200)
        }
    }
    delete = (index) => {
        const checkedmap = this.state.checkedmap;
        const a = checkedmap.indexOf(index);
        (a === -1) ? checkedmap.unshift(index) : checkedmap.splice(a, 1);
        this.setState({checkedmap});
    };
    deleteBut = () => {
        if(window.confirm()) {
            let a = [];
            for (let i = 0; i <= this.state.checkedmap.length -1; i++) {
                a.unshift(this.state.mails[this.state.checkedmap[i]].id);
            }
            myaxios.post(`/ajax/mail/${this.props.match.params.id}/delete`,{
                ids: a
            }).then(responce => {
                if(responce.status === 200) {
                    const mails = this.state.mails;
                    for (let i = 0; i <= this.state.checkedmap.length -1; i++) {
                        delete mails[this.state.checkedmap[i]];
                    }
                    this.setState({mails, checkedmap: []})
                    this.loadData()
                    Notif("delete", `Письмо успешно удалено`);
                }
            })
        }
    };
    delItemFunc = (index, id, title) =>{
        if(window.confirm()) {
            myaxios.post(`/ajax/mail/${this.props.match.params.id}/delete`,{
                ids: [id]
            }).then(responce => {
                if(responce.status === 200) {
                    const mails = this.state.mails;
                    delete mails[index]
                    this.setState({mails});
                    Notif("delete", `Письмо успешно удалено`);
                }
            })
        }
    }
    wrapCheckbox = event => {
        let a = document.getElementsByClassName('checkboxlead');
        if(this.state.allcheck === false) {
            const checkedmap = this.state.checkedmap;
            Object.keys(a).map((key, index) => {
                a[key].checked = true;
                checkedmap.unshift(+key);
            });
            this.setState({checkedmap, allcheck: true});
        } else if(this.state.allcheck === true) {
            Object.keys(a).map((key, index) => {
                a[key].checked = false;
            });
            this.setState({checkedmap:[], allcheck: false});
        }
        let mails = this.state.mails;
    };
    emailChange = type => {
        this.setState({box: type});
        setTimeout(() => this.loadStorage(), 300)
    };
    pingMail = () => {
        myaxios.get(`/ajax/mail/${this.props.match.params.id}/ping`, {method: 'get',}).then((responce) =>{
            if(responce.status === 200 && responce.data === 1){
                setTimeout(() => this.pingMail(), 1000)
            } else {
                setTimeout(this.setState({sync: false}), 2500);
                this.loadData();
                Notif("save", "Почтовый ящик успешно синхронизирован");
                return true;
            }
        })
    }
    syncMail = id => {
        myaxios.post(`/ajax/mail/${this.props.match.params.id}/sync`, {method: 'get',}).then((responce) =>{
            if(responce.status === 200) {
                setTimeout(() => this.pingMail(), 1000)
                this.setState({sync: true});
                //setTimeout(() => this.loadData(), 2500)
            }
        })
    }
    updateData = (id, arr, type) => {
        const filterParams = this.state.filterParams;
        if(filterParams[type].multiple){
            filterParams[type].multiple[id] = {id: id, name: arr[this.state.filterSearch]}
            filterParams[type].id = id;
            filterParams[type].value = '';
        } else {
            filterParams[type].id = id;
            filterParams[type].arr = arr;
            filterParams[type].value = arr[this.state.filterSearch];
        }
        this.setState({ filterParams, filterTch: false })
    };
    setFilter = (ev) => {
        for(let i = 0; i < filter.length; i++){
            if(filter[i].value === this.state.filterValue) {
                this.setState({ filterType: this.state.filterValue, filterField: filter[i].filterField, filterSearch: filter[i].filterSearch, filterLink: filter[i].filterLink, filterLength: filter[i].filterLength })
            }
        }
    };
    sortFunc = () => {
        let filterParams = this.state.filterParams;
        let filterArr = this.state.filterArr;
        filterArr = [];
        let localStorageArr = {};
        Object.keys(filterParams).map((key) => {
            if(filterParams[key].value !== null && filterParams[key].value.trim() !== '' || ((filterParams[key].multiple) ? (filterParams[key].multiple.length >= 1) ? true : false : false) ){
                localStorageArr[key] = filterParams[key];
                if(filterParams[key].multiple) {
                    filterArr.unshift({key: key, value: filterParams[key].multiple.map(item => (item !== null) ? item.name + ', ' : ''), name: filterParams[key].name })
                } else {
                    filterArr.unshift({key: key, value: (filterParams[key].type === "number") ? `${filterParams[key].like} ${filterParams[key].value}` : filterParams[key].value, name: filterParams[key].name})
                }

            }
        });
        (filterArr.length < 1) ? localStorage.removeItem("filterContact") : localStorage.setItem('filterContact', JSON.stringify(localStorageArr));
        this.setState({ filterArr });
    };
    setFld = (event, type) => {
        const filterParams = this.state.filterParams;
        filterParams[type].value = event.target.value;
        this.setState({ filterParams, filterTch: false })
    };
    clearFunc = value => {
        const filterParams = this.state.filterParams;
        filterParams[value].value = '';
        filterParams[value].id = null;
        if(filterParams[value].multiple) filterParams[value].multiple = [];
        this.setState({ filterParams });
        this.loadData();
    };
    formatDate = (date) => {
        if(date !== null) {
            let dd = date.getDate();
            if (dd < 10) dd = '0' + dd;
            let mm = date.getMonth() + 1;
            if (mm < 10) mm = '0' + mm;
            let yy = date.getFullYear() % 10000;
            if (yy < 10) yy = '0' + yy;
            return yy + '-' + mm + '-' + dd;
        }
    }
    handleChange = (date, type) => {
        const filterParams = this.state.filterParams;
        filterParams[type].value = this.formatDate(date);
        this.setState({ filterParams, filterTch: false });
    };

    render(){
        return (this.state.loading || this.state.sync) ? <Loader/> : <div className="page mail-inbox">
            <div className="butt-group">
                <div className="toogle-bottom fl-r">
                    <a className="fl-r btns" href="#"><i className="flaticon-more" /></a>
                    <ul className="toogle-list">
                        {/*<li><a href="#"><i className="flaticon-file" /> <b>Шаблоны писем</b></a></li>*/}
                        <li><a onClick={() => {this.props.updateData("AddEmailBox", "")}}  href={null}><i className="flaticon-cogwheel-1" /> <b>Настройка ящиков</b></a></li>
                    </ul>
                </div>

                <div className="toogle-bottom fl-r">
                    <a className="fl-r btns"><i className="flaticon-interface-3">{null}</i></a>
                    <ul className="toogle-list">
                        <li><a onClick={() => this.setState({ filter: !(this.state.filter), filterSort: false})}><i className="flaticon-interface-8" /> <b>Фильтр</b></a></li>
                        <li><a onClick={() => this.setState({ filterSort: !(this.state.filterSort), filter: false})}><i className="flaticon-list-2" /> <b>Сортировка</b></a></li>
                    </ul>
                </div>

                <button disabled={this.state.sync} onClick={this.syncMail} className="fl-r btns"><i className="flaticon-refresh" /></button>
                {(this.state.checkedmap.length >= 1) ? <a onClick={this.deleteBut} className="fl-r btns" href="#"><i className="flaticon-delete-1">{null}</i></a> : null}


                <div className="filter sbform fl-r" style={{ display: (this.state.filter) ? "block" : "none" }}>
                    <div className="filter-div">
                        <div className="filterBlock">
                            <Myselect
                                label=""
                                className={(this.state.filterParams[this.state.filterValue].type === "number") ? "rc3" : "rc4"}
                                options={filter}
                                onChange={event => { this.setState({ filterValue: event.target.value }); setTimeout(this.setFilter) }}
                                value={this.state.filterValue}
                            />
                            {(this.state.filterParams[this.state.filterValue].type === "number") ? <Myselect
                                label=""
                                className="rc1"
                                options={[{value:"", label:"="},{value:">", label:">"},{value:"<", label:"<"}]}
                                onChange={event => {
                                    const filterParams = this.state.filterParams;
                                    filterParams[this.state.filterValue].like = event.target.value;
                                    this.setState({ filterParams }); setTimeout(this.setFilter)
                                }}
                                value={this.state.filterParams[this.state.filterValue].like}
                            /> : null}
                            {(this.state.filterField === "autoselect") ?
                                <AutoSelect
                                    label=""
                                    className="rc8"
                                    type={this.state.filterParams[this.state.filterValue].type || 'text'}
                                    value={this.state.filterParams[this.state.filterValue].value || ''}
                                    link={this.state.filterLink}
                                    minLength={this.state.filterLength}
                                    result={this.state.filterSearch}
                                    addType={this.state.filterType}
                                    setField={(event) => this.setFld(event, this.state.filterType)}
                                    updateData={this.updateData}
                                    placeholder={(this.state.filterParams[this.state.filterValue].multiple) ? null : "Значение"}
                                    //createTag={(this.state.filterParams[this.state.filterValue].multiple)}
                                    multiSelect={(this.state.filterParams[this.state.filterValue].multiple) ? (this.state.filterParams[this.state.filterValue].multiple) : null}
                                    multiSelectValid={true}
                                    itemType={`object`}
                                /> :
                                <DateTime
                                    className="rc8"
                                    event={this.state.filterType}
                                    locale="ru-RU"
                                    dateFormat="dd-MMMM-yyyy"
                                    value={this.state.filterParams[this.state.filterValue].value || ''}
                                    handleChange={this.handleChange}
                                />}
                            <div><button disabled={this.state.filterTch} style={{ margin: "0px", fontSize: "13px" }} onClick={this.loadData} className="btn-m"><i style={{ position: "inherit" }} className="flaticon-search-magnifier-interface-symbol" /></button></div>
                        </div>
                    </div>
                </div>

                <div className="filter sbform fl-r" style={{ display: (this.state.filterSort) ? "block" : "none" }}>
                    <div className="filter-div">
                        <div className="filterBlock">
                            <Myselect
                                label=""
                                options={sort}
                                onChange={event => { this.setState({ sortValue: event.target.value }); setTimeout(this.loadData) }}
                                value={this.state.sortValue}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="clear"/>
            <div style={{ marginTop: "10px" }}>{this.state.filterArr.map((item, index) =>
                <div className="filterItem">
                    <em>{item.name}:</em> {item.value}
                    <a onClick={() => this.clearFunc(item.key)}>x</a>
                </div>
            )}</div>


            <div className="clear" />
            <div className="mailsearch">
                <div className="mailsearch-r">
                    <span>Страница {this.state.page} из {this.state.pageCount}</span>
                    <button onClick={this.toPage.bind(this, "prev")} disabled={(this.state.page === 1)} className="pnav-left">&lt;</button>
                    <button onClick={this.toPage.bind(this, "next")} disabled={(this.state.page >= this.state.pageCount)} className="pnav-right">&gt;</button>
                </div>
            </div>
            <div className="clear" />
            <div className="mail-left">
                <a onClick={() => this.setState({ open:'r-open', openType:'mail', openWidth:'fullwidthmodal' })} className="mailButton fl-l btni" href={null}>Написать письмо</a>
                <ul className="mailsearch-l">
                    <li><NavLink to={`/mail/${this.props.match.params.id}/inbox`}  onClick={() => this.emailChange("inbox")}><i className="flaticon-tool" /> Входящие</NavLink> <span style={{ display:"none" }}>20</span> </li>
                    <li><NavLink to={`/mail/${this.props.match.params.id}/sent`} onClick={() => this.emailChange("sent")}><i className="flaticon-paper-plane" /> Отправленные</NavLink></li>
                    {/*<li><NavLink to={`/mail/${this.props.match.params.id}/trash`} onClick={() => this.emailChange("sent")}><i className="flaticon-warning-sign" /> Спам</NavLink></li>
                    <li><NavLink to={`/mail/${this.props.match.params.id}/deleted`} onClick={() => this.emailChange("sent")}><i className="flaticon-delete-1" /> Удаленные</NavLink></li>*/}
                </ul>
                <div className="clear" />
                <hr/>
            </div>
            <div className="mail-right contact-page sp-table">
                <table style={{borderSpacing: "0 0px"}} className="mailTable">
                    <thead>
                    <tr className="fst-tr">
                        <td width="40px">
                            <label><input
                                onClick={this.wrapCheckbox}
                                className="checkbox"
                                type="checkbox"
                                name="checkbox-test"
                                defaultChecked={this.state.allcheck}
                            /><span className="checkbox-custom" /></label>
                        </td>
                        <td style={{width: "60px"}} />
                        <td style={{minWidth: "160px"}}>От кого:</td>
                        <td style={{width: "30px"}} >К-во</td>
                        <td>Тема письма:</td>
                        <td style={{width: "80px"}}>Дата</td>
                        <td style={{width: "50px"}} />
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.state.mails.map((item, index) =>
                            <Item
                                key={index}
                                id={item.id}
                                to={item.to}
                                from={item.from}
                                theme={item.subject}
                                checkbox_index={index}
                                delete={this.delete.bind(this, index, item.id)}
                                delItem={() => this.delItemFunc(index, item.id, item.username)}
                                path={this.props.match}
                                is_seen={item.is_seen}
                                body={item.text_body}
                                date={item.date}
                                folder={item.folder}
                                editorTags={item.editorTags}
                                threadCount={item.threadCount}
                                thread_id={item.thread_id}
                                addTag={() => this.setState({ open: 'r-open', openType:'tags', openWidth:'right-modal', openId: item.id, openTags: item.editorTags  })}
                                //text={item.html_body}
                            />
                        )
                    }
                    </tbody>
                </table>
            </div>


            <React.Fragment>
                <div className={`${this.state.openWidth} scrollbar ${this.state.open}`}><div className="save">
                    {(this.state.open === "r-open" && this.state.openType === "mail")
                        ? <SendMail
                            close={() => this.setState({ open:'', openType:'', openWidth:'', openId: '', openTags: '' })}
                            send={this.childSendEmail}
                            replyEmail={this.state.reply.id}
                            replyId={this.state.reply.email}
                            email={this.props.isEmail}
                        /> : null}
                    {(this.state.open === "r-open" && this.state.openType === "tags")
                        ? <EditTags
                            close={() => this.setState({ open:'', openType:'', openWidth:'', openId: '', openTags: '' })}
                            tags={this.state.openTags}
                            type="mail"
                            id={this.state.openId}
                            refresh={this.loadStorage}
                            refreshState={()=>{}}
                        /> : null}
                </div></div>
                {this.state.open ? <Bgdrop close={() => this.setState({ open:'', openType:'', openWidth:'', openId: '', openTags: '' })} /> : null}
            </React.Fragment>
        </div>
    }
}