import React from "react";
import Input from "../UI/Input";

export const transformPostContactFieldsData = (arr, entity_id, entity_type) => {

    return ({
        entity_id, entity_type,
        fields: arr.filter(el => el).map(item => ({
            id: item.id,
            label: item.label,
            type: item.postType,
            value: item.value,
            field_options: item.validation
        })) || []
    })
}

export const transformSaveContactFields = (arr) => {
    if(!arr) return []

    return arr.map(item => ({
        label: item.label,
        value: item.value,
        id: item.field_id,
        postType: item.type && item.type.name,
        validation: {
            ...JSON.parse(item.field_options.rules),
            required: true
        }
    }))
}

export const contactFieldsValidation = (contactFields) => contactFields.reduce((prev, current, index) => {
    if(current) {
        prev.validationRules['c_' + index] = current['validation'];
        prev.values['c_' + index] = current.value;
    }
    return prev
}, { values: {}, validationRules: {} })

export const ContactFieldsData = ({ contactFields, errors, setValues }) => {

    const addContactField = (inputType, label, type) => {
        const changedContactInfo = JSON.parse(JSON.stringify(contactFields));

        changedContactInfo.push({
            label: label,
            type: inputType,
            value:'',
            validation:{
                email: (type === "email"),
                url: (type === "website"),
                number: (type === "number"),
                phone: (type === "phone"),
                maxLength: 100
            },
            t: inputType,
            postType: type,
            required: true
        })

        setValues(changedContactInfo)
    }

    return (
        <div>
            <div className="connection">
                <span>Контактная информация</span>
                <div className="toggleparrent">
                    <button className="btni">+</button>
                    <ul>
                        <li><button>Телефон</button>
                            <ul className="childContactBlock">
                                <li><button onClick={() => addContactField("tel", "Основной телефон", "phone")}>Основной телефон</button></li>
                                <li><button onClick={() => addContactField("tel", "Дополнительный телефон", "phone")}>Дополнительный телефон</button></li>
                                <li><button onClick={() => addContactField("tel", "Рабочий телефон", "phone")}>Рабочий телефон</button></li>
                                <li><button onClick={() => addContactField("tel", "Факс", "phone")}>Факс</button></li>
                            </ul>
                        </li>
                        <li><button>Email</button>
                            <ul className="childContactBlock">
                                <li><button onClick={() => addContactField("email", "Дополнительный Email", "email")}>Дополнительный Email</button></li>
                                <li><button onClick={() => addContactField("email", "Рабочий Email", "email")}>Рабочий Email</button></li>
                            </ul>
                        </li>
                        <li><button>Соц. сети</button>
                            <ul>
                                <li><button onClick={() => addContactField("url", "Facebook", "social")}>Facebook</button></li>
                                <li><button onClick={() => addContactField("url", "LinkedIn", "social")}>LinkedIn</button></li>
                                <li><button onClick={() => addContactField("url", "Telegram", "social")}>Telegram</button></li>
                                <li><button onClick={() => addContactField("url", "Twitter", "social")}>Twitter</button></li>
                                <li><button onClick={() => addContactField("url", "Pinterest", "social")}>Pinterest</button></li>
                                <li><button onClick={() => addContactField("url", "Instagram", "social")}>Instagram</button></li>
                                <li><button onClick={() => addContactField("url", "ВКонтакте", "social")}>ВКонтакте</button></li>
                                <li><button onClick={() => addContactField("url", "Другая", "social")}>Другая</button></li>
                            </ul>
                        </li>
                        <li><button onClick={() => addContactField("url", "Website", "website")}>Web</button></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export const ContactFieldsRender = ({ contactFields, errors, setValues }) => {
    const setField = (e, index) => {
        let changedContactInfo = JSON.parse(JSON.stringify(contactFields));
        changedContactInfo[index].value = e.target.value;
        setValues(changedContactInfo)
    }
    const deleteField = (index) => {
        if(window.confirm()){
            const changedContactInfo = JSON.parse(JSON.stringify(contactFields));
            delete changedContactInfo[index];
            setValues(changedContactInfo)
        }
    }

    return <div className={'form-container'}>
        {
            contactFields ? contactFields.filter(el => el).map((item, index) =>
                <div className="parrentContactField w50-p">
                    <Input
                        key={index} required shouldValidate del
                        label={item.label}
                        value={item.value}
                        type={item.type}
                        valid={!errors['c_' + index]}
                        errorMessage={errors['c_' + index]}
                        t={item.type}
                        onChange={event => setField(event, index)}
                        deleteFunc={()=> deleteField(index)}
                    />
                </div>
            ) : null
        }
    </div>
}