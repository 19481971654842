import React, {useEffect, useState} from 'react';
import myaxios from "../../../components/Axios";
import Bgdrop from "../../../Modal/Bgdrop";
import PaymentsModal from "../../../Modal/Payments";
import Notif from "../../../components/Notif";
import {PaymentModal} from "../../finance/PaymentModal";

const OldPayments = ({ id: inv_id, values, setValues, type, updated_at }) => {
    const [balances, setBalances] = useState([])
    const [currencies, setCurrencies] = useState([])
    const [items, setItems] = useState([])
    const [modalData, setIsOpen] = useState(null)

    const getAllBalances = async () => {
        const { status, data } = await myaxios.get(`/ajax/balance`)
        if(status === 200) {
            setBalances(data.items)
        }
    }

    const getAllCurrencies = async () => {
        const { status, data } = await myaxios.get(`/ajax/settings/lookup?type=currency`)
        if(status === 200) {
            setCurrencies(data.items)
        }
    }

    const changePaymentSum = (items) => {
        let sum = 0;
        items.map(({ amount, exchange_rate }) => {
            sum += (amount || 0) * (exchange_rate || 1)
        })
        setValues(sum, items)
    }

    const getItems = async () => {
        let link = `/ajax/invoicepaymentnew/${inv_id}/payments?per-page=100&sort=-created_at`
        if(type === 'order') {
            link = `/ajax/order/${inv_id}/payments?per-page=100&sort=-created_at`
        }

        const { status, data } = await myaxios.get(link)
        if(status === 200) {
            setItems(data.items)
            changePaymentSum(data.items)
            setIsOpen(null)
        }
    }

    const delPayment = async (pay_id) => {
        let link = `/ajax/invoicepaymentnew/${inv_id}/payment/${pay_id}`
        if(type === 'order') {
            link = `/ajax/order/${inv_id}/payments/${pay_id}`
        }

        if(window.confirm()){
            const { status } = await myaxios.delete(link)
            if(status === 200 || status === 204) { await getItems(); Notif('delete', 'Платеж успешно удален') }
        }
    }

    useEffect(() => {
        getAllBalances().then()
        getAllCurrencies().then()
    }, [])

    useEffect(() => {
        console.log('adssdasad', updated_at)
        if (updated_at) {
            getItems().then()
        }
    }, [updated_at])

    return <>
        <div onClick={() => setIsOpen({ open: true })} className="connection"><span>Платежи</span><div className="toggleparrent"><button className="btni">+</button></div></div>
        <div className="sp-table body-table">
        <table style={{borderSpacing: "0"}} className="stagesTable">
            <thead>
            {
                (items.length >= 1) ? <tr>
                    <td>Название</td>
                    <td>Дата оплаты</td>
                    <td>Сумма</td>
                    {/*<td data-tooltip="Сумма в валюте счета">в в.с.</td>*/}
                    <td style={{width: "30px"}}/>
                </tr> : null
            }

            </thead>
            <tbody>
            {
                items.map(({ id, date, name, paymentMethod, amount, currency, exchange_rate },index)=>
                    <tr onDoubleClick={() => setIsOpen({ open: true, id })} key={index}>
                        <td><a onClick={() => setIsOpen({ open: true, id })}>{name}</a></td>
                        <td>{date}</td>
                        <td>{amount} {currency ? currency.text_symbol : ''}</td>
                        {/*<td>{amount * exchange_rate}</td>*/}
                        <td>
                            <div className="toogle-bottom fl-r">
                                <a className="is" href="#"><i className="flaticon-more" /></a>
                                <ul className="toogle-list">
                                    <li><a onClick={() => delPayment(id)} className="table-del"><i className="flaticon-delete-1" /> <b>Удалить</b></a></li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                )
            }
            </tbody>
        </table>
    </div>

        <PaymentModal
            close={() => setIsOpen(null)}
            isOpen={!!modalData}
            currencies={currencies}
            balances={balances}
            id={modalData && modalData.id}
            updateData={getItems}
            type={type}
            entityId={inv_id}
            entity_currency_id={values.saved_currency_id}
        />

        {/*<div>
            <div className={`right-modal scrollbar ${modalData && modalData.open && 'r-open'}`}>
                <div className="save">
                    {modalData && modalData.open && <PaymentsModal
                        id={modalData && modalData.id}
                        currency_id={values.currency_id}
                        invoice_id={inv_id}
                        close={() => setIsOpen(false)}
                        saveProduct={getItems}
                    />}
                </div>
            </div>
            {modalData && modalData.open && <Bgdrop close={() => setIsOpen(false)} />}
        </div>*/}
    </>
}

export default React.memo(OldPayments);

function status(val) {
    switch (String(val)) {
        case "1": return "Новый";
        case "2": return "Истек";
        case "3": return "Ожидает";
        case "4": return "Оплачен";
        case "5": return "Неудачный";
        default: return "";
    }
}