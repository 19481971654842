import React, { Component } from 'react';
import Input from '../../../components/UI/Input';
import Myselect from '../../../components/UI/Select';
import Textarea from '../../../components/UI/Textarea';
import Address from '../../../components/address/Address';
import Requisites from './Requisites';
import ContactFields from '../../../components/contactFields';
import AdditionalFields from '../../../components/additionalFields';
import MainEditor from "../../../components/UI/Editor";
import {truncator} from '../../../components/fieldGen'


const Information = props => {
    return <div className="tab-content">
    <div className="sbform formlabel">
        <div className="connection" style={{ marginTop: "0px"}}><span>Основная информация</span></div>
        <div className="tWrap">
            <table className="stagesTable body-table" style={{ borderSpacing: 0 }}>
                <tbody>
                <tr>
                    <td>Дата создания</td>
                    <td>{props.created_at}</td>
                </tr>
                <tr>
                    <td>Дата обновления</td>
                    <td>{props.updated_at}</td>
                </tr>
                <tr>
                    <td>Сумма оплат</td>
                    <td>{truncator(props.payment, 2)} {props.baseCurrency ? props.baseCurrency.text_symbol : null}</td>
                </tr>
                <tr>
                    <td>Сверка по счетам</td>
                    <td>{((props.payment - props.cost) < 0) ? <b style={{color: "#c2263f"}}>Задолженность:</b> : <b style={{color: "#62a091"}}>Задолженности нет:</b>} {truncator(props.payment - props.cost, 2)} {props.baseCurrency ? props.baseCurrency.text_symbol : null}</td>
                </tr>
                <tr>
                    <td>RFM сегменты</td>
                    <td>{props.rfmSegments.map(item => <span className="label-st" style={{ backgroundColor: item.segment.color }} key={item.segment_id}>{item.segment.name}</span>)}</td>
                </tr>
                <tr>
                    <td>Сегменты</td>
                    <td>{props.segments.map(item => <span className="label-st" style={{ color: "#2b2b2b" }} key={item.segment_id}>{item.segment.name}</span>)}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div className="connection" style={{ marginTop: "10px"}}>
            <span>Контактная информация</span>
            <div className="toggleparrent">
            <button className="btni">+</button>
                <ul>
                    <li><button>Телефон</button>
                        <ul className="childContactBlock">
                            <li><button onClick={() => props.addContactField("tel", "Основной телефон", "phone")}>Основной телефон</button></li>
                            <li><button onClick={() => props.addContactField("tel", "Дополнительный телефон", "phone")}>Дополнительный телефон</button></li>
                            <li><button onClick={() => props.addContactField("tel", "Рабочий телефон", "phone")}>Рабочий телефон</button></li>
                            <li><button onClick={() => props.addContactField("tel", "Факс", "phone")}>Факс</button></li>
                        </ul>
                    </li>
                    <li><button>Email</button>
                        <ul className="childContactBlock">
                            <li><button onClick={() => props.addContactField("email", "Дополнительный Email", "email")}>Дополнительный Email</button></li>
                            <li><button onClick={() => props.addContactField("email", "Рабочий Email", "email")}>Рабочий Email</button></li>
                        </ul>
                    </li>
                    <li><button>Соц. сети</button>
                        <ul>
                            <li><button onClick={() => props.addContactField("url", "Facebook", "social")}>Facebook</button></li>
                            <li><button onClick={() => props.addContactField("url", "LinkedIn", "social")}>LinkedIn</button></li>
                            <li><button onClick={() => props.addContactField("url", "Telegram", "social")}>Telegram</button></li>
                            <li><button onClick={() => props.addContactField("url", "Twitter", "social")}>Twitter</button></li>
                            <li><button onClick={() => props.addContactField("url", "Pinterest", "social")}>Pinterest</button></li>
                            <li><button onClick={() => props.addContactField("url", "Instagram", "social")}>Instagram</button></li>
                            <li><button onClick={() => props.addContactField("url", "ВКонтакте", "social")}>ВКонтакте</button></li>
                            <li><button onClick={() => props.addContactField("url", "Другая", "social")}>Другая</button></li>
                        </ul>
                    </li>
                    <li><button onClick={() => props.addContactField("url", "Website", "website")}>Web</button></li>
                </ul>
            </div>
        </div>
        <Input
            label="Основной телефон"
            className="fgroup rc6"
            type="text"
            errorMessage={'Поле может содержать только цифры'}
            value={props.wrapPhone}
            onChange={event => props.setField(event, "wrapTel")}
            shouldValidate={true}
            valid={props.wrapPhoneValid}
            isFormValid={props.isFormValid}
        />
        <Input
            label="Дополнительный телефон"
            className="fgroup rc6"
            errorMessage={'Поле может содержать только цифры'}
            type="text"
            value={props.dopPhone}
            onChange={event => props.setField(event, "dopTel")}
            shouldValidate={true}
            valid={props.dopPhoneValid}
            isFormValid={props.isFormValid}
        />
        <Input
            label="Email"
            className="fgroup rc6"
            errorMessage={'Введите корректный Email'}
            type="text"
            value={props.email}
            onChange={event => props.setField(event, "email")}
            shouldValidate={true}
            valid={props.emailValid}
            isFormValid={props.isFormValid}
        >
            {+props.subscriber.is_active !== 1 ? <button onClick={props.subscribeEmail} className="input-child-not-active">
                <i data-tooltip="Email не подтвержден. Нажмите, чтобы отправить письмо подтверждения" className="flaticon-danger" />
            </button> : <button className="input-child-link"><i data-tooltip="Email подтвержден" className="flaticon-mail-1" /></button>}
        </Input>
        <Input
            label="Website"
            className="fgroup rc6"
            type="text"
            errorMessage={'URL заполнен некорректно'}
            value={props.website}
            onChange={event => props.setField(event, "website")}
            shouldValidate={true}
            valid={props.websiteValid}
            isFormValid={props.isFormValid}
        />
        <ContactFields contactInfo={props.contactInfo} updateContactInfo={props.updateContactInfo} />
        <div className="clear" />
        <Address
            address={props.address}
            openAddress={props.openAddress}
            deleteModal={props.deleteModal}
        />
        <Requisites
            requisites={props.requisites}
            openAddress={props.openAddress}
            deleteModal={props.deleteModal}
        />
        <div className="clear" />
        <div className="connection">
            <span>Дополнительная информация</span>
        </div>
        <AdditionalFields items={props.additionalFields}  updateAdditionalFields={props.updateAdditionalFields} />
        {/*<Myselect
            label="Количество сотрудников"
            className="fgroup rc6"
            options={props.employeesOptions}
            value={props.employees}
            shouldValidate={false}
            val="id"
            lab={"label"}
            onChange={event => {props.setField(event, "employees")}}
            isFormValid={props.isFormValid}
        />
        <Myselect
            label="Годовой оборот"
            className="fgroup rc6"
            options={props.turnoverOptions}
            value={props.turnover}
            shouldValidate={false}
            val="id"
            lab={"label"}
            onChange={event => {props.setField(event, "turnover")}}
            isFormValid={props.isFormValid}
        />*/}
        <MainEditor
            className="fgroup rc12"
            value={props.comment}
            onChange={event => {props.setDescription(event)}}
            label="Комментарий"
            valid={true}
        />

        {/*<div className="connection">
            <span>Команда</span>
            <div className="toggleparrent">
                <button onClick={props.openTeam} className="btni">+</button>
            </div>
        </div>
        <div className="clear" />
        <div className="sp-table body-table">
            <table style={{borderSpacing: "0"}} className="stagesTable">
                <thead />
                <tbody>
                {(props.team) ? props.team.map((item,index)=>
                    <tr key={index}>
                        <td>{(item.user) ? <a  onClick={() => props.editTeam(item)}>{item.user.name}</a> : null}</td>
                        <td>{item.role}</td>
                        <td>{(item.description) ? item.description.slice(0,50) : null}</td>
                        <td>
                            <div className="toogle-bottom fl-r">
                                <a className="is"><i className="flaticon-more" /></a>
                                <ul className="toogle-list">
                                    <li><a onClick={() => props.editTeam(item)}><i className="flaticon-edit-1" /> <b>Редактировать</b></a></li>
                                    <li><a onClick={()=> props.deleteTeam(item.user_id, index)} className="table-del"><i className="flaticon-delete-1" /> <b>Удалить</b></a></li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                ) : null
                }
                </tbody>
            </table>
        </div>*/}


    </div>
    </div>
    };

export default Information;