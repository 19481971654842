import React, {useEffect, useState} from 'react';
import FilesItem from './files-item';
import FileUpload from './file-upload';
import myaxios from '../../components/Axios';
import {CSSTransition} from "react-transition-group";

const Files = props => {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(false)
    }, [])

    const del = (id, index) => {
        if(window.confirm()){
            myaxios.delete(`/ajax/file/delete/${id}`).then(res => {
                setTimeout(() => props.filesFunc("del", index, res))
            })

        }
    }
  return <div className="tab-content download-tab">
      <CSSTransition in={!loading} timeout={200} classNames="tabs" unmountOnExit><div>
      <FileUpload
          //add={props.filesFunc}
          add={(a,b,c) => props.filesFunc(a,b,JSON.parse(c))}
          relatedType={props.relatedType}
          related_id={props.related_id}
          files={props.files}
          del={() => alert()}
      />
      <div className="contact-page sp-table">
          <table>
              <thead>
              <tr className="fst-tr">
                  <td>Название файла</td>
                  <td>Тип</td>
                  <td>Дата создания</td>
                  <td style={{width: "30px"}}>{null}</td>
              </tr>
              </thead>
              <tbody>
              {
                  (props.files) ? props.files.map((item, index) =>
                      <FilesItem linkDownload={`/ajax/file/download/${item.id}`} index={index} key={index} del={del} id={item.id} type={item.type} img={item.img} title={item.name} createDate={item.created_at}/>
                  ) : null
              }
              </tbody>
          </table>
      </div></div>
      </CSSTransition>
  </div>
};

export default Files;