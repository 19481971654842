import React, {Component} from 'react';
import myaxios from '../../components/Axios';
import formValid from '../../components/UI/formValid';
import {withRouter} from 'react-router-dom';
import WrapForm from './WrapForm';
import Product from './tab/product/product';
import Related from '../../components/related/related';
import Files from '../../components/files/files'
import Notif from '../../components/Notif';
import Loader from '../../components/UI/Loader';
import Task from "../../components/Task";
import LeadSettings from './tab/settings';
import AddTask from '../../Modal/AddTask';
import Bgdrop from '../../Modal/Bgdrop';
import Attached from './attached/attached';
import Info from './tab/info/info';
import History from '../../components/history/history';
import EndOpportunity from '../../Modal/EndOpportunity';
import Team from '../../Modal/Team';
import EditTags from '../../Modal/EditTags';
import EndBlock from '../../components/EndBlock';
import GetPostAditionalFields from '../../components/get_post_aditionalFields';
import {CSSTransition} from "react-transition-group";
import LeaveModal from "../../components/other/LeaveModal";
import IconButton from "../../components/other/IconButton";

class OpportunityView extends Component {
    state = {
        layout: 'info',
        leaddetail: '',
        loading: true,
        user: [],
        inputValue: '',
        formControl: {
            name: {
                value: '',
                valid: false,
                validation: {
                    required: true,
                    minLength: 3
                }
            },
            amount: {
                value: '',
                valid: true,
                validation: {
                    required: false,
                    number: true
                }
            },
            currency:{value:'', arr: null, valid:true, validation:{required:true}},
            stage: {
                value: '',
                defaultValue: [],
            },
            wrapContact: {
                value: '',
                options: []
            },
            accounts: {
                value: '',
                options: {}
            },
            account:{value:'', arr:null},
            contact:{value:'', arr:null},
            owner:{value:'', arr:null},
            source:{value:'', arr:null},
            type:{value:'', arr:null},
            maturityNeeds: { value: ''},
            typeOfNeed: { value: ''},
            maturityComment: { value: ''},
            description: {value:''},
            next_step: {value:''},
            probability:{value:'',valid:true,validation:{number:true}},
            expected_revenue:{value:'',valid:true,validation:{number:true}},
            is_repeat: {value: 0},
        },
        isFormValid: true,
        attached: {
            account:{value:'', arr:null},
            contact:{value:'', arr:null},
            owner:{value:'', arr:null},
            source:{value:'', arr:null},
            type:{value:'', arr:null}
        },
        contactInfo:[],
        additionalFields:[],
        selectTag:[],
        tch: true,
        contacts: [],
        tasks: [],
        files: [],
        endTask: false,
        taskId: null,
        taskIndex: null,
        endOpportunity: false,
        products: [],
        open: '',
        openType:'',
        teamId: null,
        editorTags:'',
        is_deleted:0,
        is_won: 0,
        is_closed: 0,
        created_at:'',
        updated_at:'',
        prevDesc:''
    };
    getOpportunity = () =>{
        myaxios.get(`/ajax/opportunity/${this.props.match.params.id}?expand=contacts,account,tasks,files,stages,contact,owner,additionalFields,currency`, {
            method: 'get',
        }).then((response) => {
            let formControl = this.state.formControl;
            formControl.name.value = response.data.name || '';
            formControl.amount.value = response.data.amount || '';
            formControl.stage.defaultValue = response.data.stages;
            formControl.stage.value = response.data.stage_id || '';
            formControl.wrapContact.options = response.data.contacts;
            formControl.wrapContact.value = response.data.main_contact_id || '';
            formControl.accounts.options = response.data.account;
            formControl.description.value = response.data.description || '';
            formControl.expected_revenue.value = response.data.expected_revenue || '';
            formControl.probability.value = response.data.probability || '';
            formControl.next_step.value = response.data.next_step || '';
            formControl.currency.value = response.data.currency_id || '';
            formControl.currency.arr = response.data.currency;
            formControl.is_repeat.value = response.data.is_repeat || '';
            formControl.contact.arr = response.data.contact;
            formControl.account.arr = response.data.account;
            formControl.contact.value = response.data.main_contact_id || '';
            formControl.account.value = response.data.account_id || '';
            formControl.owner.value = response.data.owner_id || '';
            formControl.owner.arr = response.data.owner;
            formControl.source.arr = response.data.source;
            formControl.type.arr = response.data.type;
            formControl.type.value = response.data.type_id || '';
            this.setState({
                tasks: response.data.tasks,
                formControl,
                loading: false,
                user: response.data,
                files: response.data.files,
                products: response.data.products,
                editorTags: response.data.editorTags,
                is_deleted: response.data.is_deleted,
                is_won: response.data.is_won,
                is_closed: response.data.is_closed,
                additionalFields: GetPostAditionalFields("get", response.data.additionalFields) || [],
                created_at: response.data.created_at,
                updated_at: response.data.updated_at,
                prevDesc: response.data.description
            });
        }).catch((error)=>{
            this.setState({ loading: false });
        });
    }
    getTeam = () =>{
        myaxios.get(`/ajax/opportunity/${this.props.match.params.id}/team?expand=user&per-page=100`, {
            method: 'get',
        }).then((response) => {
            this.setState({team: response.data.items});
        })
    }
    componentDidMount(){
        this.getOpportunity();
        this.getTeam();
    }
    componentWillReceiveProps(nextProps){
        if(nextProps.match.params.id !== this.props.match.params.id) {
            this.getOpportunity();
            this.getTeam();
        }
    }
    handleChange = (selectTag) => {
        this.setState({ selectTag, tch: false });
    };
    deleteUser = () => {
        if(window.confirm("Удалить сделку?")) {
            myaxios.delete('/ajax/opportunity/' + this.props.match.params.id).then(res => {
                if(res.status === 200 || res.status === 201 || res.status === 204){
                    this.props.history.push(`/opportunity`);
                    Notif("delete", "Сделка успено удалена")
                }
            }).catch((error)=>{
                this.setState({ loading: false });
            });
        }
    };
    setField = (event, type) => {
        const formControl = {  ...this.state.formControl };
        const control = { ...formControl[type] };
        control.value = event.target.value;
        formControl[type] = control;
        this.setState({
            formControl, tch: false
        })
    };

    sendForm = event => {
        event.preventDefault();
        if(this.isFormValid()){
            this.setState({ tch: true, loading: true });
            myaxios.put(`/ajax/opportunity/${this.props.match.params.id}`, {
                name: this.state.formControl.name.value,
                amount: this.state.formControl.amount.value,
                stage: this.state.formControl.stage.value,
                owner_id: this.state.formControl.owner.value,
                stage_id: this.state.formControl.stage.value,
                main_contact_id: this.state.formControl.contact.value,
                account_id: this.state.formControl.account.value,
                description: this.state.formControl.description.value,
                type_id: this.state.formControl.type.value,
                lead_source_id: this.state.formControl.source.value,
                next_step: this.state.formControl.next_step.value,
                probability: this.state.formControl.probability.value,
                expected_revenue: this.state.formControl.expected_revenue.value,
                editorTags: this.state.editorTags,
                additionalFields: GetPostAditionalFields("post", this.state.additionalFields),
                contactFields: {fields: GetPostAditionalFields('postContact', this.state.contactInfo), entity_type:1, entity_id: this.props.match.params.id},
                currency_id: this.state.formControl.currency.value,
                is_repeat: this.state.formControl.is_repeat.value
            }).then(res => {
                if(!res) return;
                if(res.status === 200) Notif("save")
                this.setState({ loading: false });
            }).catch((error)=>{
                this.setState({ loading: false });
            });
        } else Notif("Error", "Заполните обязательные поля")
    };
    isFormValid = () => {
        let formControl = this.state.formControl;
        let additionalFields = this.state.additionalFields;
        let contactInfo = this.state.contactInfo;
        let isFormValid = true;
        Object.keys(contactInfo).map((key, index) => {
            contactInfo[key].valid = formValid(contactInfo[key].value, contactInfo[key].validation);
            isFormValid = contactInfo[key].valid && isFormValid
        });
        Object.keys(additionalFields).map((key, index) => {
            additionalFields[key].valid = formValid(additionalFields[key].value, additionalFields[key].validation);
            isFormValid = additionalFields[key].valid && isFormValid
        });
        Object.keys(formControl).map((key, index) => {
            formControl[key].valid = formValid(formControl[key].value, formControl[key].validation);
            isFormValid = formControl[key].valid && isFormValid
        });
        this.setState({
            formControl, additionalFields, contactInfo, isFormValid
        });
        return isFormValid;
    };
    task = (type, arr, index) => {
        const tasks = this.state.tasks;
        if(type === "add") {tasks.unshift(arr)}
        else if(type === "edit"){tasks[index] = arr}
        else if(type === "delete") {delete tasks[index]}
        this.setState({tasks, open: '', openType:'', taskId:'', taskIndex:''})
    }
    filesFunc = (type, index, arr) => {
        const files = this.state.files;
        if(type === "del" && arr.status === 200){
            delete files[index];
            Notif("delete", "Файл успешно удален")
        } else if(arr.status === "uploaded"){
            files.unshift({id: arr.id, name: arr.name, created_at: "Только что", type: arr.type});
            Notif("save", "Файл успешно загружен")
        } else if(arr.status === "error"){
            Notif("Error", arr.errors.file[0])
        }
        this.setState({ files })
    };
    updateData = (id, arr, addType, endParrans) => {
        const formControl = this.state.formControl;
        formControl[addType].arr = arr;
        formControl[addType].value = id;
        this.setState({formControl, tch: false})
    };
    unLink = (type, typeID, id) => {
        if(window.confirm()) {
            const formControl = this.state.formControl;
            formControl[type].arr = null;
            formControl[type].value = '';
            this.setState({formControl, tch: false})
        }
    };
    completedOpportunity = () => {
        if(window.confirm()) {
            myaxios.post(`/ajax/opportunity/${this.props.match.params.id}/complete`).then(responce => {
                if (responce.status === 201) {
                    this.props.history.push('/opportunity/');
                    Notif("save", "Сделка успешно завершена")
                }
            }).catch((error)=>{
                this.setState({ loading: false });
            });
        }
    };
    createOrder = () => {
        if(window.confirm()){
            myaxios.post(`/ajax/opportunity/${this.props.match.params.id}/create-order`).then(responce => {
                if (responce.status === 200 || responce.status === 201 || responce.status === 204) {
                    this.props.history.push(`/order/${responce.data}`);
                    Notif("save", "Сделка успешно завершена")
                }
            })
        }
    }
    createInvoice = () => {
        if(window.confirm()){
            myaxios.post(`/ajax/opportunity/${this.props.match.params.id}/create-invoice`).then(responce => {
                if (responce.status === 200 || responce.status === 201 || responce.status === 204) {
                    this.props.history.push(`/invoice/${responce.data}`);
                    Notif("save", "Сделка успешно завершена")
                }
            })
        }
    }
    deleteTeam = id => {
        if(window.confirm()){
            myaxios.post(`/ajax/opportunity/${this.props.match.params.id}/unlink-member/${id}`).then(responce => {
                if (responce.status === 200 || responce.status === 201 || responce.status === 204) {
                    this.getTeam();
                    Notif("save")
                }
            })
        }
    }
    render() {
        return (
            <div className="page">
                {this.state.loading && <Loader/>}
                <CSSTransition in={!this.state.loading} timeout={200} classNames="slide" unmountOnExit>
                    <div>
                <LeaveModal when={!this.state.tch} />
                {(this.state.is_deleted === 1 || this.state.is_closed === 1 || this.state.is_won === 1) ?
                <EndBlock deletedName="Сделка удалена" type="opportunity" is_deleted={this.state.is_deleted} is_closed={this.state.is_closed} is_won={this.state.is_won} id={this.props.match.params.id} /> :
                <div className="butt-group butt-group-card">
                    <div className="tagsName">
                        <a data-tooltip={this.state.editorTags.length > 0 ? this.state.editorTags : null} onClick={() => this.setState({ open:'r-open', openType: "tags" })} className="success fl-l btni mr10" >
                            Теги ({(this.state.editorTags.length > 0) ? this.state.editorTags.split(',').length : '0'})</a>
                    </div>
                    <button className="success fl-l btni mr10" onClick={() => this.setState({ open:'r-open', openType: "task" })}>
                        <span>Добавить задачу</span>
                        <i className="flaticon-clock-1 mdb" />
                    </button>
                    <div className="toogle-bottom fl-l aab">
                        <button onClick={false} className="mdn success fl-r btni">Создать</button>
                        <ul className="toogle-list" style={{width: "250px", left: 0}}>
                            <li><a onClick={this.createOrder} href={null}><i className="flaticon-cart"/> <b>Заказ на основании сделки</b></a></li>
                            <li><a onClick={this.createInvoice} href={null}><i className="flaticon-coins"/> <b>Счет на основании сделки</b></a></li>
                        </ul>
                    </div>
                    <IconButton href="https://sboxcrm.com/docs/opportunity" />
                    <button onClick={this.sendForm} className="butt-group-card-wrap success fl-r btni ml5" disabled={this.state.tch}>
                        <span>Сохранить</span>
                        <i className="flaticon-interface-1 mdb" />
                    </button>

                    <div className="mdn toogle-bottom fl-r">
                        <button onClick={false} className="success fl-r btni ml5">
                            <span>Завершить</span>
                            <i className="flaticon-lock mdb" />
                        </button>
                        <ul className="toogle-list" style={{width: "220px"}}>
                            <li><a onClick={this.completedOpportunity} href={null}><i className="flaticon-interface-1"/> <b>Успешно реализована</b></a></li>
                            <li className="failreason"><a onClick={() => this.setState({ endOpportunity: true })} href={null}><i className="flaticon-danger"/> <b>Провалена</b></a></li>
                        </ul>
                    </div>

                    <a title={'/ajax/opportunity/' + this.props.match.params.id} onClick={this.deleteUser} className="butt-group-card-wrap fl-r btni ml5" href="#">
                        <span>Удалить</span>
                        <i className="flaticon-delete-2 mdb" />
                    </a>
                </div>}
                <div className="clear" />
                <div className="contact-page">
                    <div className="contact-left">
                        <div className="contact-left-col">
                            <h3>Данные по сделке</h3>
                            <WrapForm
                                setField={this.setField}
                                isFormValid={this.state.isFormValid}
                                stageOptions={this.state.formControl.stage.defaultValue}
                                stageOptionsValue={this.state.formControl.stage.value}
                                amountValue={this.state.formControl.amount.value}
                                amountValid={this.state.formControl.amount.valid}
                                shouldValidate={true}
                                nameValid={this.state.formControl.name.valid}
                                nameValue={this.state.formControl.name.value}
                                updateData={this.updateData}
                                unLink={this.unLink}
                                owner={this.state.formControl.owner.arr}
                                source={this.state.formControl.source.arr}
                                currency={this.state.formControl.currency}
                                is_repeat={this.state.formControl.is_repeat.value}
                            />
                        </div>
                        <Attached
                            setField={this.setAttachedField}
                            updateData={this.updateData}
                            unLink={this.unLink}
                            openEntity={this.props.openEntity}
                            account={(this.state.formControl.account) ? this.state.formControl.account.arr : null}
                            contact={(this.state.formControl.contact) ? this.state.formControl.contact.arr : null}
                            updateDataContact={this.props.updateData}
                        />
                    </div>
                    <div className="contact-right-col">
                        <div className="taskcontainer">
                            {(this.state.tasks) ? this.state.tasks.map((task, index) =>
                                    <Task
                                        key={index}
                                        openTask={(id, index) => this.setState({ open: "r-open", openType: "task", taskIndex: index, taskId: id})}
                                        index={index}
                                        id={task.id}
                                        tasktext={task.subject}
                                        end_date={task.end_date}
                                        status={task.status}
                                    />
                             ) : null}
                        </div>
                        <div className="tabs-header">
                            <ul>
                                <li><a className={(this.state.layout === "info") ? "active" : null} onClick={() => this.setState({ layout: 'info' })}>Основная информация</a></li>
                                <li><a className={(this.state.layout === "history") ? "active" : null} onClick={() => this.setState({ layout: 'history' })}>Хронология</a></li>
                                <li><a className={(this.state.layout === "Product") ? "active" : null} onClick={() => this.setState({ layout: 'Product' })}>Продукты</a></li>
                                <li><a className={(this.state.layout === "related") ? "active" : null} onClick={() => this.setState({ layout: 'related' })}>Связи</a></li>
                                <li><a className={(this.state.layout === "files") ? "active" : null} onClick={() => this.setState({ layout: 'files' })}>Файлы</a></li>
                            </ul>
                        </div>
                        <div className="tab-container">
                            {(this.state.layout === "info") ?
                                <Info
                                    setDescription={(e) => {
                                        const formControl = this.state.formControl;
                                        formControl.description.value = e;
                                        this.setState({ formControl, tch: e === this.state.prevDesc })
                                    }}
                                    created_at={this.state.created_at}
                                    updated_at={this.state.updated_at}
                                    team={this.state.team}
                                    openTeam={() => this.setState({ open:"r-open", openType: "team" })}
                                    editTeam={arr => this.setState({ open:"r-open", openType: "team", teamId: arr })}
                                    description={this.state.formControl.description.value}
                                    next_step={this.state.formControl.next_step.value}
                                    probability={this.state.formControl.probability.value}
                                    expected_revenue={this.state.formControl.expected_revenue.value}
                                    expected_revenue_valid={this.state.formControl.expected_revenue.valid}
                                    probability_valid={this.state.formControl.probability.valid}
                                    type={this.state.formControl.type.arr}
                                    updateData={this.updateData}
                                    unLink={this.unLink}
                                    setField={this.setField}
                                    deleteTeam={this.deleteTeam}
                                    additionalFields={this.state.additionalFields}
                                    updateAdditionalFields={(items) => this.setState({ additionalFields: items, tch: false })}
                            /> : null}
                            {(this.state.layout === "history") ? <History type="opportunity" id={this.props.match.params.id} /> : null}
                            {(this.state.layout === "Product") ? <Product
                                products={this.state.products}
                                type="opportunity"
                                id={this.props.match.params.id}
                                related="1"
                            /> : null}

                            {(this.state.layout === "related") ? <Related
                                type="opportunity"
                                id={this.props.match.params.id}
                                related="1"
                                contactEmail={(this.state.formControl.contact.arr) ? this.state.formControl.contact.arr.email : null}
                                accountEmail={(this.state.formControl.account.arr) ? this.state.formControl.account.arr.email : null}
                                opportunity_id={this.props.match.params.id}
                                contact_id={this.state.formControl.contact.value}
                                account_id={this.state.formControl.account.value}
                                account={this.state.formControl.account.arr}
                                contact={this.state.formControl.contact.arr}
                                f_otgr={this.state.additionalFields}
                                f_dscr={this.state.additionalFields}
                                tasks
                                stages
                                invoices
                                documents
                                sms
                                orders
                                projects
                                mails
                                expense
                                isPlates
                            />: null}

                            {(this.state.layout === "files") ? <Files
                                files={this.state.files}
                                filesFunc={this.filesFunc}
                                relatedType="1"
                                related_id={this.props.match.params.id}
                                clientEmail=""
                            /> : null}
                        </div>
                    </div>
                </div>
                {(this.state.endOpportunity) ? <div className="modal-center"><EndOpportunity id={this.props.match.params.id} close={()=>this.setState({ endOpportunity: false })} /></div> : null}
                <React.Fragment>
                    <div className={"right-modal scrollbar " + this.state.open}><div className="save">
                        {(this.state.open === "r-open" && this.state.openType === "task")
                            ? <AddTask
                                task={this.task}
                                id={this.state.taskId}
                                index={this.state.taskIndex}
                                close={() => this.setState({ open:'', openType: '', taskId:'', taskIndex: '' })}
                                tie={this.props.match.params.id}
                                relatedType="1"
                            /> : null}

                        {(this.state.open === "r-open" && this.state.openType === "team")
                            ? <Team
                                id={(this.state.teamId) ? this.state.teamId.user_id : null}
                                arr={this.state.teamId}
                                entity="opportunity"
                                parrentId={this.props.match.params.id}
                                close={() => this.setState({ open:'', openType:'', teamId: null })}
                                refresh={() => this.getTeam()}
                            /> : null}
                        {(this.state.open === "r-open" && this.state.openType === "tags")
                            ? <EditTags
                                close={() => this.setState({ open:'', openType:''})}
                                tags={this.state.editorTags}
                                type="opportunity"
                                id={this.props.match.params.id}
                                refresh={() => {}}
                                refreshState={(tags) => {this.setState({ editorTags: tags })} }
                            /> : null}
                    </div></div>
                    {this.state.open ? <Bgdrop close={() => this.setState({ open:'', openType: '', taskId:'', taskIndex: '', teamId: null })} /> : null}
                </React.Fragment>
                    </div>
                </CSSTransition>
            </div>
        )
    }
}

export default withRouter(OpportunityView);
