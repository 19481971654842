import React, {Component} from 'react';
import AutoSelect from '../../../components/UI/AutoSelect';
import {withRouter} from 'react-router-dom';

const Attached = props => {
    return <div>
        {
            (props.account) ? <div>
                <div className="contact-left-col contactsAddBlock">
                    <a onClick={()=>props.unLink("account", "3", props.account.id)} className="lead-form-close">x</a>
                    <h3>Клиент</h3>
                    <div>
                        {(props.account.name) ?
                            <div className="card-block">
                                <em>Название</em>
                                <a  onClick={() => props.history.push('/account/' + props.account.id)}>{props.account.name}</a>
                            </div> : null}
                        {(props.account.phone) ?
                            <div className="card-block">
                                <em>Основной номер телефона</em>
                                <span className="toggleMenu">{props.account.phone}<ul>
                                    <li><a onClick={() => props.updateDataContact("Call", {tel: props.account.phone, id: props.account.id, entity: "account"})}>Позвонить</a></li>
                                    <li><a onClick={() => props.updateDataContact("SendSms", {tel: props.account.phone, id: props.account.id, entity: "account"})}>Отравить SMS</a></li></ul>
                                </span>
                            </div> : null}
                        {(props.account.number) ?
                            <div className="card-block">
                                <em>Дополнительный номер телефона</em>
                                <span className="toggleMenu">{props.account.number}<ul>
                                    <li><a onClick={() => props.updateDataContact("Call", {tel: props.account.number, id: props.account.id, entity: "account"})}>Позвонить</a></li>
                                    <li><a onClick={() => props.updateDataContact("SendSms", {tel: props.account.number, id: props.account.id, entity: "account"})}>Отравить SMS</a></li></ul>
                                </span>
                            </div> : null}
                        {(props.account.email) ?
                            <div className="card-block">
                                <em>Email</em>
                                <span className="toggleMenu">{props.account.email}<ul>
                                    <li><a onClick={() => props.updateDataContact("SendMail", {from: props.account.email})}>Отправить Email</a></li></ul>
                                </span>
                            </div> : null}
                        {(props.account.website) ?
                            <div className="card-block">
                                <em>Email</em>
                                <a target="_blank" href={props.account.website}>{props.account.website}</a>
                            </div> : null}
                    </div>
                </div>
            </div> : null
        }
        {
            (props.contact) ? <div>
                <div className="contact-left-col contactsAddBlock">
                    <a onClick={()=>props.unLink("contact", "2", props.contact.id)} className="lead-form-close">x</a>
                    <h3>Клиент</h3>
                    <div>
                        {(props.contact.name) ?
                            <div className="card-block">
                                <em>ФИО</em>
                                <a onClick={() => props.history.push('/contact/' + props.contact.id)}>{props.contact.name}</a>
                            </div> : null}
                        {(props.contact.position) ?
                            <div className="card-block">
                                <em>Должность</em>
                                <span>{props.contact.position}</span>
                            </div> : null}
                        {(props.contact.mobile_phone) ?
                            <div className="card-block">
                                <em>Мобильный телефон</em>
                                <span className="toggleMenu">{props.contact.mobile_phone}<ul>
                                    <li><a onClick={() => props.updateDataContact("Call", {tel: props.contact.mobile_phone, id: props.contact.id, entity: "contact"})}>Позвонить</a></li>
                                    <li><a onClick={() => props.updateDataContact("SendSms", {tel: props.contact.mobile_phone, id: props.contact.id, entity: "contact"})}>Отравить SMS</a></li></ul>
                                </span>
                            </div> : null}
                        {(props.contact.work_phone) ?
                            <div className="card-block">
                                <em>Рабочий телефон</em>
                                <span className="toggleMenu">{props.contact.work_phone}<ul>
                                    <li><a onClick={() => props.updateDataContact("Call", {tel: props.contact.work_phone, id: props.contact.id, entity: "contact"})}>Позвонить</a></li>
                                    <li><a onClick={() => props.updateDataContact("SendSms", {tel: props.contact.work_phone, id: props.contact.id, entity: "contact"})}>Отравить SMS</a></li></ul>
                                </span>
                            </div> : null}
                        {(props.contact.email) ?
                            <div className="card-block">
                                <em>Email</em>
                                <span className="toggleMenu">{props.contact.email}<ul>
                                    <li><a onClick={() => props.updateDataContact("SendMail", {from: props.contact.email})}>Отправить Email</a></li></ul>
                                </span>
                            </div> : null}
                    </div>
                </div>
            </div> : null
        }
        {
            (!props.account && !props.contact) ? <div className="contact-left-col">
                <h3>Контрагент</h3>
                <form className="sbform" action="#">
                    <AutoSelect
                        label="Название контрагента"
                        link={`/ajax/account?filter[name][like]=`}
                        minLength="1"
                        result="name"
                        event="test"
                        addType="account"
                        endParrams="3"
                        shouldValidate={false}
                        setField={() => {}}
                        updateData={props.updateData}
                    />
                </form>
            </div> : null
        }
        {
            (!props.contact && !props.account) ? <div className="contact-left-col">
                <h3>Основной контакт</h3>
                <form className="sbform" action="#">
                    <AutoSelect
                        label="ФИО"
                        link={`/ajax/contact?filter[name][like]=`}
                        minLength="1"
                        result="name"
                        event="name"
                        addType="contact"
                        endParrams="2"
                        shouldValidate={false}
                        setField={() => {}}
                        updateData={props.updateData}
                    />
                </form>
            </div> : null
        }

    </div>
};

export default withRouter(Attached);