import React, {useEffect, useState} from "react";
import {parseResponse, parseResponseError} from "../../components/cardViewComponents/parseFunctions";
import GetPostAditionalFields from "../../components/get_post_aditionalFields";
import {
    contactFieldsValidation,
    transformPostContactFieldsData,
    transformSaveContactFields
} from "../../components/cardViewComponents/ContactFields";
import {
    deleteAccountService,
    getSingleAccountService,
    updateAccountService
} from "../../services/account/accountService";
import {additionalFieldsValidation} from "../../components/cardViewComponents/additionalFieldsValidation";
import {validationData} from "../../components/UI/formValid";
import {validationConfig} from "../../components/cardViewComponents/validationConfig";
import Notif from "../../components/Notif";
import LeaveModal from "../../components/other/LeaveModal";
import {TagsNew} from "../../components/cardViewComponents/TagsNew";
import {MemoButton} from "../../components/cardViewComponents/MemoButton";
import IconButton from "../../components/other/IconButton";
import UploadAvatar from "../../components/other/UploadAvatar";
import {GenerateForm} from "../../components/cardViewComponents/GenerateForm";
import {accountConfig} from "./accountConfig";
import {ContactCard} from "../../components/cardViewComponents/ContactCard";
import {Tasks} from "../../components/Task";
import {filesFunc, TabsRender} from "../../components/fieldGen";
import {tabsConfig} from "../contacts/contactConfig";
import {InfoTab} from "./Components/InfoTab";
import {CSSTransition} from "react-transition-group";
import {ModalLoader} from "../../components/UI/Loader";
import History from "../../components/history/history";
import Related from "../../components/related/related";
import Files from "../../components/files/files";
import {TaskModal, updateEntityTasks} from "../tasks/TaskModal";
import Qualification from "../../Modal/Qualification";
import Bgdrop from "../../Modal/Bgdrop";
import Requisites, {RequisitesModalNew} from "../../Modal/Requisites";
import myaxios from "../../components/Axios";
import {ConflictModal} from "../../components/cardViewComponents/ConflictModal";
import {FilesDropDown} from "../../components/FilesDropDown";

export const AccountViewNew = ({ match, history, location }) => {
    const urlParams = new URLSearchParams(location.search);
    const { params: { id } } = match
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState({})
    const [disabled, setDisabled] = useState({})
    const [conflict, setConflict] = useState(false)
    const [modalData, setModalData] = useState(null)
    const [requisites, setRequisites] = useState([])
    const [values, setValues] = useState({
        description: '',
        email: '',
        industry: '',
        main_contact_id: '',
        contact: null,
        name: '',
        number: '',
        owner_id: '',
        owner: null,
        personal_discount: 0,
        phone: '',
        type_id: '',
        types: [],
        website: '',
        photo: '',
        created_at: '',
        updated_at: '',
        editorTags: '',
        files: [],
        tasks: [],
        additionalFields: [],
        contactFields: [],
        begin_update_datetime: null,
        tch: true
    })

    const getAccountData = async () => {
        try {
            const { data, status, response } = await getSingleAccountService({
                id, expand: 'invoicesDiff,tasks,files,contact,types,contact,owner,contactFields,additionalFields,segments,rfmSegments'
            })

            const parseResponseData = parseResponseError(response);
            if(parseResponseData.notFound) history.push('/404')

            if(status === 200 && data.id) {
                setValues(({
                    ...values,
                    ...parseResponse(accountConfig, data, {}),
                    types: data.types,
                    files: data.files,
                    tasks: data.tasks,
                    additionalFields: GetPostAditionalFields("get", data.additionalFields) || [],
                    contactFields: transformSaveContactFields(data.contactFields),
                }))
            }
        } catch (e) { console.error('getAccountDataError', e) }

        setLoading(false)
    }

    const showRequisites = async () => {
        const { data, status } = await myaxios.get(`/ajax/account/${id}/payment-details?per-page=100`)
        if(status === 200 && data && data.items) {
            setRequisites(data.items)
        }
    }

    const deleteRequisites = async (requisites_id) => {
        if (!window.confirm('Удалить реквизиты?')) return;
        const { status } = await myaxios.delete(`/ajax/account/${id}/payment-detail/${requisites_id}`)
        if(status === 200 || status === 201 || status === 204) {
            Notif('delete', 'Реквизиты удалены')
            showRequisites().then()
        }
    }

    const saveItem = async (forcibly) => {
        const { description, email, industry, main_contact_id, name, number, phone, owner_id, personal_discount, type_id, website, photo, editorTags, updated_at } = values

        const additionalFieldsValidationRes = additionalFieldsValidation(values.additionalFields)
        const contactFieldsValidationRes = contactFieldsValidation(values.contactFields)

        const errorsData = validationData(
            {...values, ...contactFieldsValidationRes.values, ...additionalFieldsValidationRes.values},
            {...validationConfig(accountConfig), ...contactFieldsValidationRes.validationRules, ...additionalFieldsValidationRes.validationRules}
        )

        setErrors(errorsData)
        if(Object.keys(errorsData).length > 0) {
            Notif('required-fields')
            return
        }

        setLoading(true)

        try {
            const { data, status, response } = await updateAccountService({ id, data: {
                    description, email, industry, main_contact_id, name, number, phone, owner_id, personal_discount, type_id, website, photo,
                    editorTags, begin_update_datetime: forcibly === 'ok' ? null : updated_at,
                    contactFields: transformPostContactFieldsData(values.contactFields, id, 3 ),
                    additionalFields: GetPostAditionalFields("post", values.additionalFields),
                }})

            if(status === 200 && data.id) {
                Notif('save', 'Запись успешно изменена')
                setValues(({ ...values, updated_at: data.updated_at, tch: true, begin_update_datetime: null }))
            }

            const parseResponseData = parseResponseError(response);
            if(parseResponseData.saveConflict) setConflict(true)
            if(parseResponseData.validationErrors) {
                setErrors(parseResponseData.validationErrors)
            }

        } catch (e) { console.error(e) }

        setLoading(false)
    }

    const deleteItem = async () => {
        if(!window.confirm('Удалить элемент?')) return false

        setLoading(true)
        try {
            const { status } = await deleteAccountService({ id })
            if(status === 200 || status === 204) {
                setValues(prevState => ({ ...prevState, tch: true }))
                Notif('delete', 'Контрагент успешно удален')
                history.push('/account')
            }
        } catch (e) { console.error(e) }
        setLoading(false)
    }

    useEffect(() => {
        getAccountData().then()
        showRequisites().then()
    }, [id])

    const changeFields = (key, value, item) => {
        let obj = {}
        if(item) {
            if(item === 'clear') obj[accountConfig[key].entity_from] = null
            else obj[accountConfig[key].entity_from] = item
        }

        setValues({
            ...values, ...obj, [key]: item ? item.id : value,
            begin_update_datetime: !values.begin_update_datetime ? new Date() : values.begin_update_datetime,
            tch: false
        })
    }

    return <>
        {loading && <ModalLoader />}
        <div className="page">
            <LeaveModal when={!values.tch} />
            <div className="newList-btn-group">
                <div className="newList-btn-group-left">
                    <TagsNew
                        editorTags={values.editorTags} entity={'account'}
                        setValues={data => setValues({ ...values, editorTags: data, tch: false })}
                    />
                    <MemoButton
                        onClick={() => setModalData({ type: 'task' })}
                        mobileIcon={'flaticon-notes mdb'} title={'Добавить задачу'} className={'mr5'} />
                </div>
                <div className="newList-btn-group-right">
                    <a onClick={deleteItem} className="butt-group-card-wrap btni ml10">
                        <span>Удалить</span>
                        <i className="flaticon-delete-2 mdb" />
                    </a>
                    <MemoButton onClick={saveItem} disabled={values.tch} mobileIcon={"flaticon-interface-1 mdb"} title={'Сохранить'} className={'ml5'} />
                    <IconButton href="https://sboxcrm.com/docs/account" />
                </div>
            </div>

            <div className="contact-page sbform">
                <div className="contact-left">
                    <div className="contact-left-col" style={{ marginTop: "45px" }}>
                        <h3>Данные контрагента</h3>
                        <UploadAvatar
                            img={values.photo}
                            setImg={(photo) => setValues(({ ...values, photo, tch: false }))}
                            cancelImg={() => setValues(({ ...values, photo: '', tch: false }))}
                            related_id={id}
                            related_type="3"
                        />
                        <GenerateForm
                            config={accountConfig}
                            { ...{ values, errors, disabled } }
                            onChange={changeFields}
                            onSaveData={saveItem}
                        />
                        <div className="clear" />
                    </div>
                    <ContactCard
                        contact={values.contact}
                        setDataValues={data => setValues(({ ...values, ...data, tch: false }))}
                    />
                </div>

                <div className="contact-right-col">
                    <Tasks tasks={values.tasks} openTask={data => setModalData(data)} />
                    <TabsRender
                        setState={({ layout }) => history.push(`?layout=${layout}`)}
                        state={{ layout: urlParams.get('layout') || tabsConfig[0].value }}
                        arr={tabsConfig}
                    />
                    <div className="tab-container">
                        <CSSTransition in={(urlParams.get('layout') || tabsConfig[0].value) === tabsConfig[0].value} timeout={200} classNames="my-node" unmountOnExit>
                            <InfoTab
                                {...{ values, errors, disabled, changeFields, id, saveItem }}
                                setContactFields={data => setValues({ ...values, contactFields: data, tch: false })}
                                setValues={data => setValues(({ ...values, ...data, tch: false }))}
                                setModalData={setModalData} requisites={requisites} deleteRequisites={deleteRequisites}
                            />
                        </CSSTransition>

                        <CSSTransition in={urlParams.get('layout') === tabsConfig[1].value} timeout={{enter: 0, exit: 0}} classNames="my-node" unmountOnExit>
                            <History type="account" id={id} />
                        </CSSTransition>

                        <CSSTransition in={urlParams.get('layout') === tabsConfig[2].value} timeout={{enter: 0, exit: 0}} classNames="my-node" unmountOnExit>
                            <Related
                                type="account" id={id} related="3"
                                accountEmail={values.email} account_id={id} account={{name: values.name}}
                                tasks cases invoices documents sms opportunities orders projects mails expense
                            />
                        </CSSTransition>

                        <CSSTransition in={urlParams.get('layout') === tabsConfig[3].value} timeout={{enter: 0, exit: 0}} classNames="my-node" unmountOnExit>
                            <FilesDropDown
                                related_id={id}
                                related_type={"3"}
                                files={values.files}
                                updateFile={filesData => setValues(prevState => ({
                                    ...prevState,
                                    files: [filesData, ...prevState.files]
                                }))}
                                updateFiles={files => setValues(prevState => ({ ...prevState, files }))}
                            />
                        </CSSTransition>
                    </div>
                </div>
            </div>
            {
                console.log('111', values.begin_update_datetime)
            }
            {conflict && <ConflictModal
                entity={'account'} id={id} updated_at={values.begin_update_datetime}
                save={() => saveItem('ok')} close={() => setConflict(false)}
            />}
            <TaskModal
                id={modalData && modalData.id}
                close={() => setModalData(null)}
                index={modalData && modalData.index}
                task={(type, data, index) => {
                    setValues({ ...values, tasks: updateEntityTasks(values.tasks, type, data, index) })
                }}
                related={{ id, type: 3 }}
                isOpen={modalData && modalData.type === 'task'}
            />
            <div className={`right-modal scrollbar ${modalData && modalData.type === 'requisites' && 'r-open'}`}><div className="save">
                {modalData && modalData.type === 'requisites' && <RequisitesModalNew
                    id={modalData && modalData.id}
                    parrentId={id}
                    addAddress={() =>{}}
                    refresh={() => {showRequisites().then(); setModalData(null)}}
                    close={() => setModalData(null)}
                />}
            </div>
            </div>
            {modalData && modalData.type === 'requisites' &&  <Bgdrop close={() => setModalData(null)} />}
        </div>
    </>
}