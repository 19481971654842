import React, {Component} from 'react';
import Textarea from '../components/UI/Textarea';
import Notif from '../components/Notif';
import myaxios from '../components/Axios';
import formValid from '../components/UI/formValid';
import ModAutoSelect from '../components/UI/ModAutoSelect';
import {withRouter} from 'react-router-dom';

class AnswerCall extends Component{
    state = {
        result: false,
        resultCall:'',
        entities: {},
        created: false,
        client_number: ''
    };
    componentWillReceiveProps(nextProps){
        if(nextProps.callObj !== nextProps.callObj.type) {
            if(!this.props.callObj.has_matches){
                this.setState({ created: true });
            }
            this.setState({ result: this.props.callObj.type === "end", entities: this.props.callObj.type === "new" ? (this.props.callObj.entities) : {}, client_number: this.props.callObj.client_number });

        }
    }
    componentDidMount(){
        if(!this.props.callObj.has_matches){
            this.setState({ created: true });
        }
        this.setState({ result: this.props.callObj.type === "end", entities: this.props.callObj.type === "new" ? (this.props.callObj.entities) : {}, client_number: this.props.callObj.client_number  });
    }
    postResult = () => {
        myaxios.put(`https://dev.sboxcrm.com${this.props.callObj.url}`, {result: this.state.resultCall}).then(res =>{
            if(res.status === 200 || res.status === 201 || res.status === 204){
                Notif("save");
                setTimeout(() => {this.props.close()});
            }
        })
    }
    createEntity = (entity)=> {
        let obj = {};
        if(entity === "account") {obj = {name: "Без имени", phone: this.state.client_number}}
        else if(entity === "contact") {obj = {first_name: "Без имени", mobile_phone: this.state.client_number}}
        else if(entity === "lead") {obj = {name: "Без имени", phone: this.state.client_number}}
        myaxios.post(`/ajax/${entity}`, obj).then((response) => {
            if(response.status === 201 || response.status === 200) {
                this.props.history.push("/" + entity + "/" + response.data.id);
                setTimeout(this.props.close, 300);
                Notif("save");
            }
        })
    }
    render(){
    return <div className="bgcall">
        {this.state.created ? <form className="sbform modal-ok scrollbar" style={{ maxHeight: "500px" }}>
        <div className="modal-head">
            <a onClick={() => {this.props.close()}} className="modal-head-close" href={null}><i className="flaticon-close" /></a>
            <b>Нет совпадений</b>
        </div>
        <div className="modal-body">
            <p style={{ textAlign: "center" }}>В системе не найдено совпадений с номером {this.state.client_number}</p>
            <a className="m-green" onClick={() => this.createEntity("lead")}>Создать лид</a>
            <a className="m-green" onClick={() => this.createEntity("contact")}>Создать контакт</a>
            <a className="m-green" onClick={() => this.createEntity("account")}>Создать контрагент</a>
        </div>
    </form> :
            <form className="sbform modal-ok scrollbar" style={{ maxHeight: "500px" }}>
                <div className="modal-head">
                    <a onClick={() => {this.props.close()}} className="modal-head-close" href={null}><i className="flaticon-close" /></a>
                    <b>Новый звонок</b>
                </div>
                {this.state.result ? <div>
                    <div className="modal-body">
                        <Textarea label="Результат звонка" rows="5" value={this.state.resultCall} onChange={e => this.setState({ resultCall: e.target.value })} />
                        <a className="m-red" onClick={this.postResult}>Сохранить</a>
                    </div>
                </div> : null}
                <div className="modal-body">
                    {
                        this.state.entities.contacts ? this.state.entities.contacts.data.map((item, index) =>
                            <div key={index} className="callItem">
                                <h3>Контакт</h3>
                                <span>{item.name}</span>
                                <a target="_blank" href={`/contact/${item.id}`}>Открыть</a>
                            </div>
                        ) : null
                    }
                    {
                        this.state.entities.accounts ? this.state.entities.accounts.data.map((item, index) =>
                            <div key={index} className="callItem">
                                <h3>Контрагент</h3>
                                <span>{item.name}</span>
                                <a target="_blank" href={`/account/${item.id}`}>Открыть</a>
                            </div>
                        ) : null
                    }
                    {
                        this.state.entities.leads ? this.state.entities.leads.data.map((item, index) =>
                            <div key={index} className="callItem">
                                <h3>Лид</h3>
                                <span>{item.name}</span>
                                <a target="_blank" href={`/lead/${item.id}`}>Открыть</a>
                            </div>
                        ) : null
                    }
                    {/*<a className="m-red" href={null}>Закрыть</a>*/}
                </div>
            </form>
        }</div>
}}

export default withRouter(AnswerCall);