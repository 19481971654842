import React from "react";
import {GlobalState} from "../../Layout/Layout";

const withGlobalState = (Wrapped) => {
    return (props) => (
        <GlobalState.Consumer>
            {({state, setSteps, setState}) => {
                return (
                    <Wrapped {...props} state={state} setSteps={setSteps} setState={setState} />
                )
            }}
        </GlobalState.Consumer>
    )
}

export default withGlobalState