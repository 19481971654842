import React, {useEffect, useState} from "react";
import myaxios from "../../Axios";
import entityFieldsArr from "../../bp/actions/entityFieldsArr";
import {
    BpDateWithPeriod,
    FieldsData,
    Formula,
    parseDataForPeriod,
    saveTimePeriod,
    searchEntityByName,
    searchEntityId
} from "../BpHelpers";
import Notif from "../../Notif";
import {validationData} from "../../UI/formValid";
import Modal from "../../Modal";
import Input from "../../UI/Input";
import Myselect from "../../UI/Select";

const validationRules = {
    name: { required: true, maxLength: 255 }
}

export const BpCreateRecord = ({ close, metaData, saveNode, entity_type }) => {
    const [isFieldOpen, setIsFieldOpen] = useState(true)
    const [formula, setFormula] = useState(null)
    const [tch, setTch] = useState(true)
    const [isOpen, setIsOpen] = useState(false)
    const [attributes, setAttributes] = useState([])
    const [entityConfigArr, setEntityConfigArr] = useState([])
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState({})
    const [fields, setFields] = useState([])
    const [entity, setEntity] = useState('')
    const [values, setValues] = useState({
        name: '',
        type: '',
        time_value: 0,
        time_valuePeriod: 'minutes',
        entity: '',
        tch: true
    })

    const getAdditionalFields = async (value, isEdit) => {
        try {
            const { status, data } = await myaxios.get(`/ajax/eav/attribute?entity_type=${searchEntityByName(value)}`)
            if(status === 200) {
                setAttributes(data.items.map(el => ({
                    ...el, value: el.field_id,
                    type: el.type === 'input' ? JSON.parse(el.field_options.rules).input_type : el.type
                })) || [])
                setEntityConfigArr([
                    ...entityFieldsArr[value],
                    ...data.items.map(el => ({
                        ...el, value: el.field_id,
                        type: el.type === 'input' ? JSON.parse(el.field_options.rules).input_type : el.type
                    }))
                ] || [])

                const newFields = []
                entityFieldsArr[value].filter(el => el.r).map(item => {
                    newFields.push({
                        field: item.value,
                        link: item.item,
                        rememberType: item.type,
                        type: 'value',
                        operator: '==',
                        obj:null,
                        value:''
                    })
                })
                setFields(newFields)
                if(metaData && !isEdit) {
                    setValues({
                        ...values,
                        time_value: parseDataForPeriod(metaData.time_value).value,
                        time_valuePeriod: parseDataForPeriod(metaData.time_value).period,
                        name: metaData.name,
                    })
                    setEntity(searchEntityId(metaData.metaData.data.recordType))
                    setFields(metaData.metaData.data.fields.map(el => ({
                        ...el,
                        type: (el.type === 'reference' || el.type === 'formula') ? el.type : 'value'
                    })))
                }
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        setIsOpen(true)
        if(metaData) {
            getAdditionalFields(searchEntityId(metaData.metaData.data.recordType)).then()
        } else setLoading(false)
    }, [])

    const saveData = () => {
        if(fields.length === 0) {
            Notif('Error', 'Необходимо заполнить поля для изменения!'); return;
        }
        if(fields.find(el => !el.value)) {
            Notif('Error', 'Не заполнены значения у полей!'); return;
        }
        const validErrors = validationData(values, validationRules)
        if (Object.keys(validErrors).length > 0) {
            setErrors(validErrors)
            return false;
        }
        setErrors({})
        setIsOpen(false)

        setTimeout(() => saveNode({
            id: metaData && metaData.id,
            name: values.name,
            nodeType: 'node',
            time_value: saveTimePeriod(values.time_value, values.time_valuePeriod),
            is_scheduled: values.time_value > 0,
            type: "createRecord",
            metaData: {
                data: {
                    fields: fields.map(el => ({
                        ...el,
                        type: el.type === 'value' ? el.rememberType : el.type,
                        object: returnObject(el.type)
                    })),
                    recordType: searchEntityByName(entity)
                }
            }
        }), 100)
    }

    const returnObject = (type) => {
        if(type === 'reference' || type === 'formula') {
            return type
        } else if(type === 'multy') {
            return 'lookup'
        } else {
            return 'string'
        }
    }

    const addElement = () => {
        const field = entityConfigArr[0]
        const parsedConditions = JSON.parse(JSON.stringify(fields))
        parsedConditions.push({
            field: entityConfigArr[0].value,
            link: '',
            rememberType: field.type,
            type: 'value',
            operator: '==',
            obj:null,
            value:''
        })
        setFields(parsedConditions)
    }

    const deleteElement = (index) => {
        setFields(fields.filter((e, i) => i !== index))
        setTch(false)
    }

    const closeModal = () => {
        setIsOpen(false); setTimeout(close, 200);
    }

    return (
        <Modal showLeaveModal tch={tch} isOpen={isOpen} title={'Создать сущность'} loading={loading}
               save={saveData} parentClassName={'bpmModal'} close={closeModal}>
            <div className="sbform">
                {formula &&
                    <Formula
                        formula={formula}
                        setFields={(fields) => setFields(fields)}
                        setFormula={setFormula}
                        attr={[
                            ...(attributes || []).map(el => ({ ...el, value: el.field_id })),
                            ...entityFieldsArr[searchEntityId(entity_type)].filter(el => el.edit)
                        ]}
                    />}
                <Input
                    label={'Название'}
                    value={values.name}
                    onChange={e => {
                        setValues({...values, name: e.target.value})
                        setTch(false)
                    }}
                    errorMessage={errors.name} valid={!errors.name} shouldValidate
                />
                <Myselect
                    label={'Выбрать сущность'} empty
                    value={entity}
                    options={[
                        {value:'lead', label: "Лид"},
                        {value:'opportunity', label: "Сделка"},
                        {value:'order', label: "Заказ"},
                        {value:'account', label: "Контрагент"},
                        {value:'contact', label: "Контакт"},
                        {value:'invoice', label: "Счет"},
                        {value:'project', label: "Проект"},
                        {value:'document', label: "Документ"},
                        {value:'product', label: "Продукт"},
                    ]}
                    onChange={e => {
                        setEntity(e.target.value)
                        getAdditionalFields(e.target.value, true).then()
                    }}
                />
                <BpDateWithPeriod
                    label={'Отложенный запуск'}
                    name={'time_value'} periodName={'time_valuePeriod'}
                    values={values} errors={errors}
                    setValues={(type, value) => {
                        setValues({...values, [type]: value})
                        setTch(false)
                    }}
                    tooltip={'Время задержки выполнения процесса'}
                />
                {!loading && entity && <div className="connection" style={{ marginTop: '10px' }}><span>Выберите поля для изменения</span>
                    <div onClick={addElement} className="toggleparrent"><button className="btni">+</button></div>
                </div>}
                {!loading && entity && <table className="filterBpm" style={{ width: '100%' }}>
                    <thead>
                    <tr>
                        <td style={{ padding: '0px 5px', width: '200px' }}>Поле</td>
                        <td style={{ padding: '0px 5px', width: '150px' }}>Тип</td>
                        <td style={{ padding: '0px 5px' }}>Значение</td>
                        <td style={{ width: '10px' }} />
                    </tr>
                    </thead>
                    <tbody>
                    {
                        fields.map((item, index) => {
                            const field = entityConfigArr.find(el => String(el.value) === String(item.field))
                            return (
                                <tr key={index}>
                                    <td>
                                        <Myselect
                                            value={item.field}
                                            options={entityConfigArr}
                                            onChange={e => {
                                                const parsedConditions = JSON.parse(JSON.stringify(fields))
                                                const findField = entityConfigArr.find(el => String(el.value) === String(e.target.value))
                                                parsedConditions[index].field = e.target.value
                                                parsedConditions[index].value = ''
                                                parsedConditions[index].obj = null
                                                parsedConditions[index].rememberType = findField.type
                                                setFields(parsedConditions)
                                                setIsFieldOpen(true)
                                            }}
                                            onFocus={() => setIsFieldOpen(false)}
                                            onBlur={() => setIsFieldOpen(true)}
                                            disabled={entityFieldsArr[entity].find(el => el.value === item.field) && entityFieldsArr[entity].find(el => el.value === item.field).r}
                                        />
                                    </td>
                                    <td>
                                        <Myselect
                                            value={item.type}
                                            options={[
                                                { value: 'value', label: 'Значение' },
                                                { value: 'reference', label: 'Ссылка на поле' },
                                                { value: 'formula', label: 'Формула' }
                                            ]}
                                            onChange={e => {
                                                const parsedConditions = JSON.parse(JSON.stringify(fields))
                                                parsedConditions[index].type = e.target.value
                                                parsedConditions[index].value = ''
                                                parsedConditions[index].obj = null
                                                setFields(parsedConditions)
                                            }}
                                        />
                                    </td>
                                    <td>
                                        {isFieldOpen && field && item.type === 'value' &&
                                            <FieldsData
                                                { ...item }
                                                fieldConfig={field}
                                                onChange={val => {
                                                    const parsedConditions = JSON.parse(JSON.stringify(fields))
                                                    parsedConditions[index].value = val
                                                    setFields(parsedConditions)
                                                    setTch(false)
                                                }}
                                                updateData={(val) => {
                                                    const parsedConditions = JSON.parse(JSON.stringify(fields))
                                                    parsedConditions[index].value = val.id
                                                    parsedConditions[index].obj = val
                                                    setFields(parsedConditions)
                                                    setTch(false)
                                                }}
                                            />
                                        }
                                        {
                                            field && item.type === 'reference' && (
                                                <Myselect
                                                    value={item.value} empty
                                                    options={[
                                                        ...entityFieldsArr[searchEntityId(entity_type)],
                                                        ...attributes
                                                    ]}
                                                    onChange={(e) => {
                                                        const parsedConditions = JSON.parse(JSON.stringify(fields))
                                                        parsedConditions[index].value = e.target.value
                                                        setFields(parsedConditions)
                                                    }}
                                                />
                                            )
                                        }
                                        {
                                            field && item.type === 'formula' && (
                                                <Input
                                                    value={item.value}
                                                    onClick={() => setFormula({ index, value: item.value, fields })}
                                                />
                                            )
                                        }
                                    </td>
                                    <td>
                                        {entityFieldsArr[entity].find(el => el.value === item.field) && !entityFieldsArr[entity].find(el => el.value === item.field).r && <span
                                            onClick={() => deleteElement(index)}
                                            style={{ fontSize: '20px', color: '#c2263f', cursor: 'pointer', display: 'block' }}>&#10005;</span>}
                                    </td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>}
            </div>
        </Modal>
    )
}