import React from 'react';
import Notif from '../components/Notif';
import Input from '../components/UI/Input';
import formValid from '../components/UI/formValid';
import AutoSelect from '../components/UI/AutoSelect';
import myaxios from '../components/Axios';
import Loader from '../components/UI/Loader';
import ReactQuill from 'react-quill';
require('react-quill/dist/quill.snow.css');



class ForwardMail extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            formControl: {
                to: {
                    value: '',
                    selectvalid: true,
                    arr: (this.props.id) ? [this.props.id.from] : [],
                    /*validation: {
                        required: false,
                        email: true
                    },*/
                },
                from: {
                    value: '',
                    id: '',
                    valid: true,
                    validation: {
                        required: true,
                        email: true
                    },
                    options: []
                },
                subject: {
                    value: (this.props.id) ? `Re: ${this.props.id.subject}` : '',
                    valid: true,
                    validation: {
                        required: true,
                        minLength: 1
                    },
                },
            },
            editorHtml: '',
            files: [],
            filesList:[],
            isFormValid: true,
            tch: true,
            loading: true
        };
        this.handleChange = this.handleChange.bind(this)
    }
    handleChange (html) {
        this.setState({ editorHtml: html, tch: false });
    }
    componentDidMount(){
        const formControl = this.state.formControl;
        if(this.props.clientEmail && !this.props.id) formControl.to.value = this.props.clientEmail;
        this.setState({ formControl, loading: false })
    }
    sendMail = e => {
        e.preventDefault();
        const files = this.state.files;
        for(let i = 0; i <= this.state.filesList.length - 1; i++){
                files.unshift(this.state.filesList[i].id)
        }
        let emails = [];
        Object.keys(this.state.formControl.to.arr).map((key, index) => {
            emails.unshift(this.state.formControl.to.arr[key])
        });

        this.setState({files});
        if(this.isFormValid()) {
            this.setState({ tch: true, loading: true})
            myaxios.post((this.props.freeparams) ? `/ajax/${this.props.freeparams}/compose` :`/ajax/mail/${this.state.formControl.from.id}/compose`, {
                from: this.state.formControl.from.value,
                to: (this.state.formControl.to.arr) ? emails : this.state.formControl.to.value,
                subject: this.state.formControl.subject.value,
                body: this.state.editorHtml,
                //reply_to: (this.props.id) ? this.props.id.id : null,
                files: this.state.files,
                is_forward: '',
                mails:[]
            }).then(res => {
                if(res.status === 200 || res.status === 201 || res.status === 204){
                    this.setState({tch: true, loading: false});
                    setTimeout(() => this.props.close());
                    Notif("save", "Письмо успешно отправлено")
                } else {
                    Notif("Error");
                    this.setState({ tch: false, loading: false})
                }
            }).catch((error) => {
                this.setState({ tch: false, loading: false})
            });
        }};
    setField = (event, type) => {
        const formControl = {  ...this.state.formControl };
        const control = { ...formControl[type] };
        control.value = event.target.value;
        formControl[type] = control;
        this.setState({
            formControl, tch: false
        })
    };
    isFormValid = () => {
        let formControl = this.state.formControl;
        let isFormValid = true;
        Object.keys(formControl).map((key, index) => {
            formControl[key].valid = formValid(formControl[key].value, formControl[key].validation)
            isFormValid = formControl[key].valid && isFormValid
        });
        if(formControl.to.arr){
            if(formControl.to.arr.length < 1){
                formControl.to.selectvalid = false;
                isFormValid = false;
            }
        }
        this.setState({
            formControl, isFormValid
        });
        return isFormValid;
    };
    updateData = (id,arr) =>{
        const formControl = this.state.formControl;
        formControl.from.value = arr.username;
        formControl.from.id = id;
        this.setState({ formControl, tch: false })
    };
    files = arr => {
        const filesList = this.state.filesList;
        filesList.unshift(arr);
        this.setState({filesList});
    };
    delFile = index => {
        if(window.confirm()){
            const filesList = this.state.filesList;
            filesList.splice(index, 1);
            this.setState({filesList});
        }
    };
    updateDataTo = (id, arr) => {
        const formControl = this.state.formControl;
        if(id){
            formControl.to.arr[id] = arr.email;
            formControl.to.value = '';
        } else {
            if( formValid(arr, {email: true}) ) {
                formControl.to.arr.push( arr )
                formControl.to.value = '';
            } else {
                Notif("Error", "Введите корректный Email")
            }
        }
        this.setState({ formControl, val: '' })
    };
    deleteItem = id => {
        const formControl = this.state.formControl;
        delete formControl.to.arr[id];
        this.setState({ formControl })
    }
    render(){
        return (this.state.loading) ? <Loader contain /> : <form className="sbform create-activ">
            <div className="rmod-header">
                <b>Отправить Email</b>
                <a onClick={this.props.close} style={{right: "70px"}} className="mclose" href={null}><i className="flaticon-close" /></a>
                <div className="togglelist">
                    <button onClick={this.sendMail} className="rmod-ok" disabled={this.state.tch}><i className="flaticon-paper-plane" /></button>
                    {/*<ul>
                        <li><a onClick={this.sendMail} href={null}><i className="flaticon-paper-plane" /> Отправить</a></li>
                        <li><a href="#"><i className="flaticon-folder-2" /> В черновик</a></li>
                    </ul>*/}
                </div>
            </div>
            <div className="mod-cont">
                <AutoSelect
                    label="От"
                    link="/ajax/mail/account?filter[username][like]="
                    minLength="0"
                    result="username"
                    event="from"
                    errorMessage={"Введите корректный Email"}
                    value={this.state.formControl.from.value}
                    valid={this.state.formControl.from.valid}
                    shouldValidate={true}
                    setField={event => this.setField(event, "from")}
                    isFormValid={this.state.isFormValid}
                    updateData={this.updateData}
                />
                <label htmlFor="">Кому</label>
                <AutoSelect
                    label="Кому"
                    link="/ajax/mail/search-contact?q="
                    minLength="3"
                    result="email"
                    event="to"
                    errorMessage={"Введите корректный Email"}
                    value={this.state.formControl.to.value}
                    shouldValidate={true}
                    setField={event => this.setField(event, "to")}
                    isFormValid={this.state.isFormValid}
                    valid={true}
                    updateData={this.updateDataTo}
                    deleteItem={this.deleteItem}
                    createTag
                    multiSelect={this.state.formControl.to.arr}
                    multiSelectValid={this.state.formControl.to.selectvalid}
                    itemType={`item`}
                />
                <Input
                    label="Тема"
                    type="text"
                    className="c12"
                    value={this.state.formControl.subject.value}
                    onChange={event => this.setField(event, "subject")}
                    errorMessage={"Поле не может быть пустым"}
                    valid={this.state.formControl.subject.valid}
                    shouldValidate={true}
                    isFormValid={this.state.isFormValid}
                />
                {/*<div className="c12">
                    <label for="">Копия</label>
                    <input type="text" />
                </div>*/}

                <div className="c12">
                    <label for="">Текст письма</label>
                    <ReactQuill
                        onChange={this.handleChange}
                        value={this.state.editorHtml}
                        modules={ForwardMail.modules}
                        formats={ForwardMail.formats}
                        bounds={'.app'}
                    />
                </div>
                <div className="clear" />
                {/*<a className="rem" href="#"><i className="flaticon-attachment" /> Прикрепить файл</a>*/}
                {
                    this.state.filesList.map((item,index)=>
                        <div key={index} className="letterListFileItem">
                            <i className="flaticon-download" />
                            {item.name}
                            <a onClick={()=>this.delFile(index)}>Удалить</a>
                        </div>
                    )
                }
                <FileUpload files={this.files} />
            </div>
        </form>
    }
}

ForwardMail.modules = {
    toolbar: [
        [{size: []}],
        ['bold', 'italic', 'underline', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'},
            {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    }
}
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
ForwardMail.formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
]

export default ForwardMail;


const endpoint = '/ajax/mail/upload'

class FileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFile: null,
            loaded: 0,
        }
    }
    handleselectedFile = event => {
        this.setState({
            selectedFile: event.target.files[0],
            loaded: 0,
        })
    };
    handleUpload = e => {
        e.preventDefault();
        if(this.state.selectedFile !== null) {
            const data = new FormData();
            data.append('UploadForm[file]', this.state.selectedFile, this.state.selectedFile.name);
            myaxios.post(endpoint + `?related_type=10`, data, {
                onUploadProgress: ProgressEvent => {
                    this.setState({loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100, selectedFile: null
                    })}}).then(res => {
                        console.log(res);
                        if(res.data.status === "uploaded") {
                            let arr = {id: res.data.id, name: res.data.name};
                            setTimeout(this.props.files(arr), 200);
                            Notif("save", "Файл успешно загружен")
                        }
                        if(res.data.status === "error") {
                            Notif("Error", res.data.errors.file[0])
                        }
            }).catch((error) => {Notif("Error", error)})}};

    render() {
        return (
            <div className="previewComponent">
                <input className="btn-m" type="file" name="UploadForm[file]" id="" onChange={this.handleselectedFile} />
                <button className="btn-m ml10" onClick={this.handleUpload}>Загрузить</button>
                <em style={{fontSize: "12px"}}> {(this.state.loaded > 1) ? `${Math.round(this.state.loaded, 2)}%` : null} </em>
            </div>
        )
    }
}

const Fields = [
    {
        id: "1",
        label: "Название",
        fieldType: "input", //текст, select, date, multiselect, datetime, number, процент, ссылка, почта, текстовое поле
        options: [], // для селекта и мультиселекта
        validation: {} // обьект с парамметрами для валидации
    }
]