import React, {Component} from 'react';
import AutoSelect from './AutoSelect';
import {withRouter} from 'react-router-dom';
import closeIcon from '../../fonts/icons/cancel.svg'


function areEqual(prevProps, nextProps) {
    // if(
    //     (prevProps.value === nextProps.value) &&
    //     (prevProps.entity === nextProps.entity) &&
    //     (prevProps.link === nextProps.link) &&
    //     (prevProps.errorMessage === nextProps.errorMessage) &&
    //     (prevProps.disabled === nextProps.disabled) &&
    //     (prevProps.valid === nextProps.valid) &&
    //     (prevProps.required === nextProps.required) &&
    //     (prevProps.minLength === nextProps.minLength)
    // ) {
    //     return true
    // }
}

const ModAutoSelect = props => {
    return <div className={`${props.parrentClass} relt`}>
        {(props.entity) ?
            <div className={`inp ${props.className}`}>{(props.label) ? <label>{props.label}</label> : null}
                <div className="rsb" onClick={e => {if(e.ctrlKey) props.unLink(props.event) }}>
                    <div className="clear" />
                    {props.ownerLink ? <a onClick={() => props.history.push(`/contact/${(props.ownerLink) ? props.ownerLink.id : null}`)}>{props.entity[props.entityName]}</a> : (props.redirectLink) ? <a onClick={() => props.history.push(props.redirectLink + props.entity.id)}>{props.entity[props.entityName]}</a> : props.entity[props.entityName] }
                    {props.disabled ? null : <a name={props.itemName} value={''} title="Отвязать" onClick={(e)=> props.unLink(props.event, e)} className="unlink">
                        {/*<img style={{ width: "25px", marginTop: "-1px", marginRight: "-4px" }} src={closeIcon} alt="" />*/}
                        &#10005;
                    </a>}</div></div> :
            <AutoSelect
                required={props.required}
                tooltip={props.tooltip}
                label={props.label}
                className={props.className}
                link={props.link}
                minLength={props.minLength}
                result={props.result}
                event={props.event}
                addType={props.addType}
                endParrams={props.endParrams}
                errorMessage={props.errorMessage}
                shouldValidate={true}
                valid={props.valid}
                setField={()=>{}}
                updateData={props.updateData}
                disabled={props.disabled}
                //placeholder={!props.entity ? "Начните писать здесь" : props.placeholder}
                placeholder={props.placeholder}
                itemName={props.itemName}
            />}
    </div>
}


export default withRouter(React.memo(ModAutoSelect, areEqual));
