import {backupTest} from "../other/backupTest";
import {getLookUpTest} from "../Settings/LookUpTest";
import {
    checkUpdatedEntity,
    CrudCreateFunction,
    CrudDeleteFunction,
    CrudGetSingleItemFunction,
    CrudUpdateFunction
} from "../CRUD";
import {
    createOpportunityService,
    getOpportunityPrepareService,
    getSingleOpportunityService, updateOpportunityService, deleteOpportunityService
} from "../../../services/opportunity/opportunityService";
import {getAllUsersService} from "../../../services/other/users";
import {getContactListService} from "../../../services/contact/contactService";
import {ProductTest} from "../other/productTest";
import {deleteLeadService} from "../../../services/lead/leadService";
import {ListTest} from "../other/listTest";
import {entity} from "../../ListComponentEntity";

const initialData = {
    account_id: "",
    amount: "123",
    currency_id: "",
    main_contact_id: "",
    name: "test opportunity",
    order_id: "",
    owner_id: null,
}

const updatedData = {
    account_id: null,
    amount: "500",
    currency_id: null,
    description: "<p>test</p>",
    editorTags: "",
    is_repeat: "1",
    lead_source_id: null,
    main_contact_id: null,
    name: "Opportunity #177 edited",
    owner_id: null,
    probability: "20",
    stage_id: null,
    type_id: null
}

const OpportunityJsonAllFieldsConfig = {
    owner: { type: 'id', value: 'owner_id' },
    currency: { type: 'id', value: 'currency_id' },
    amount: { type: 'include', value: 'amount' },
    source: { type: 'id', value: 'lead_source_id' },
    contact: { type: 'id', value: 'main_contact_id' },
    stage: { type: 'id', value: 'stage_id' },
}

export const OpportunityTest = async () => {
    // Создаем бекап
    const createBackupRes = await backupTest("create");
    if(!createBackupRes.status) {
        console.error("create backup have been failed")
        return false
    }

    // ----- Получаем все валюты
    const getCurrenciesRes = await getLookUpTest('currency');
    if(!getCurrenciesRes.status) return false;

    // ----- Получаем все источники
    const getSourceRes = await getLookUpTest('ld_source');
    if(!getSourceRes.status) return false;

    // ----- Получаем все типы
    const getTypesRes = await getLookUpTest('opp_type');
    if(!getTypesRes.status) return false;

    // ----- Получаем контакты
    const getContactsRes = await CrudGetSingleItemFunction({ service: getContactListService, serviceData: { link: '?perPage=1' } });
    if(!getContactsRes.status) return false;

    // ----- Получаем всех пользователей
    const getUsersRes = await CrudGetSingleItemFunction({ service: getAllUsersService });
    if(!getUsersRes.status) return false;

    // ----- Получаем и проверяем предварительные данные в Prepare
    const getOpportunityPrepareRes = await CrudGetSingleItemFunction({
        service: getOpportunityPrepareService,
        serviceName: "getOpportunityPrepare"
    })
    if(!getOpportunityPrepareRes.status) return false;
    if(!getOpportunityPrepareRes.data.nextId) {
        console.error("nextId is empty")
        return false
    }
    if(!getOpportunityPrepareRes.data.stages[0]) {
        console.error("Opportunity prepare stages empty")
        return false
    }

    // Создаем сделку и проверяем ответственного и валюту
    initialData.name = `Opportunity #${getOpportunityPrepareRes.data.nextId}`;
    if(window.location.host === 'localhost:3000') initialData.owner_id = getUsersRes.data.items[0].id;

    const createOpportunityRes = await CrudCreateFunction({
        service: createOpportunityService,
        serviceData: { data: initialData },
        serviceName: "createOpportunityRes"
    })
    if(!createOpportunityRes.status || !createOpportunityRes.data.id) return false;

    const OpportunityDataAfterCreate = await CrudGetSingleItemFunction({
        service: getSingleOpportunityService,
        serviceData: {
            id: createOpportunityRes.data.id,
            expand: 'owner,currency,stage'
        }
    })
    if(!OpportunityDataAfterCreate.status) return false;
    if(
        !OpportunityDataAfterCreate.data.currency ||
        !OpportunityDataAfterCreate.data.currency_id ||
        !OpportunityDataAfterCreate.data.stage ||
        !OpportunityDataAfterCreate.data.stage_id ||
        !OpportunityDataAfterCreate.data.owner_id ||
        !OpportunityDataAfterCreate.data.owner
    ) {
        console.error(`Opportunity created fields have been failed`)
        return false
    }

    // Проставить и проверить все остальные заполненные поля
    updatedData.currency_id = getCurrenciesRes.data[1].id;
    updatedData.type_id = getTypesRes.data[0].id;
    updatedData.owner_id = getUsersRes.data.items[1].id;
    updatedData.lead_source_id = getSourceRes.data[0].id;
    updatedData.main_contact_id = getContactsRes.data.items[0].id;
    updatedData.stage_id = getOpportunityPrepareRes.data.stages[1].id;

    const opportunityUpdateRes = await CrudUpdateFunction({
        service: updateOpportunityService,
        serviceData: {
            id: createOpportunityRes.data.id,
            data: updatedData
        },
        serviceName: "opportunityUpdateRes"
    })
    if(!opportunityUpdateRes.status) return false;


    const OpportunityDataAfterUpdate = await CrudGetSingleItemFunction({
        service: getSingleOpportunityService,
        serviceData: {
            id: createOpportunityRes.data.id,
            expand: 'contacts,contact,owner,currency,stage'
        }
    })
    if(!OpportunityDataAfterUpdate.status) return false;
    if(!checkUpdatedEntity(OpportunityDataAfterUpdate.data, updatedData, OpportunityJsonAllFieldsConfig)) return false;

    // Тестирование продуктов
    const productTestRes = await ProductTest({ entity: 'opportunity', entityId: createOpportunityRes.data.id })
    if(!productTestRes) {
        console.error("Product test have been failed!")
        return false;
    }

    // Удаляем сделку
    const deleteOpportunityRes = await CrudDeleteFunction({
        service: deleteOpportunityService,
        serviceData: { id: createOpportunityRes.data.id },
        serviceName: "deleteOpportunityRes",
    })
    if(!deleteOpportunityRes.status) return false;

    // Тестируем список
    const listTestRes = await ListTest({
        filterArr: entity.opportunity.fArr,
        entityName: 'opportunity'
    })
    if(!listTestRes) {
        console.error("Test list have been failed!");
        return false;
    }

    // Восстанавливаем бекап
    const restoreBackupRes = await backupTest("restore", createBackupRes.id);
    if(!restoreBackupRes) {
        console.error("restore backup have been failed");
        return false;
    }

    await console.log("Opportunity Test have been success");
    return true;
}