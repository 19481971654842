import React, {Component} from 'react';
import myaxios from '../../components/Axios';
import {withRouter} from 'react-router-dom';
import Loader from '../../components/UI/Loader';
import Notif from '../../components/Notif';
import AddTask from '../../Modal/AddTask';
import Bgdrop from '../../Modal/Bgdrop';
import BigCalendar from 'react-big-calendar'
import moment from 'moment';
import '../../../node_modules/moment/locale/ru';
import '../../../node_modules/react-big-calendar/lib/css/react-big-calendar.css';
import localstorageSettings from "../../components/localstorageSettings";

const localizer = BigCalendar.momentLocalizer(moment);

class TaskCalendar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            events: [],
            tasks:[],
            open:'',
            openType: '',
            taskId:'',
            taskIndex: '',
            start:null,
            end:null
        }

        this.moveEvent = this.moveEvent.bind(this)
    }

    componentDidMount(){
        localstorageSettings("post", "taskView", "calendar");
        document.title = 'Задачи';
        this.loadData();
    }
    loadData = () =>{
        myaxios.get(`/ajax/task?paginate=0`,
            {method: 'get',}).then((response) =>{
            let a = [];
            for(let i = 0; i < response.data.items.length; i++){
                a.push({
                    id: response.data.items[i].id,
                    title: response.data.items[i].subject,
                    start: (response.data.items[i].start_date) ? new Date(response.data.items[i].start_date) : new Date(response.data.items[i].end_date),
                    end: new Date(response.data.items[i].end_date),
                    bg: (response.data.items[i].type) ? response.data.items[i].type.color : '#bbb',
                })
            }
            this.setState({
                tasks: a
            })
        })
    }

    moveEvent({ event, start, end, isAllDay: droppedOnAllDaySlot }) {
        const { events } = this.state

        const idx = events.indexOf(event)
        let allDay = event.allDay

        if (!event.allDay && droppedOnAllDaySlot) {
            allDay = true
        } else if (event.allDay && !droppedOnAllDaySlot) {
            allDay = false
        }

        const updatedEvent = { ...event, start, end, allDay }

        const nextEvents = [...events]
        nextEvents.splice(idx, 1, updatedEvent)

        this.setState({
            events: nextEvents,
        })

        // alert(`${event.title} was dropped onto ${updatedEvent.start}`)
    }

    resizeEvent = ({ event, start, end }) => {
        const { events } = this.state

        const nextEvents = events.map(existingEvent => {
            return existingEvent.id == event.id
                ? { ...existingEvent, start, end }
                : existingEvent
        })

        this.setState({
            events: nextEvents,
        })

        //alert(`${event.title} was resized to ${start}-${end}`)
    }

    render(){
        return <div className="page">
            <div className="butt-group">
                <a onClick={() => {this.props.history.push(`/task/list`); localstorageSettings("post", "taskView", "list")} } style={{ float:"left" }} className="btns"><i className="flaticon-list-2" /></a>
                <a onClick={() => {this.props.history.push(`/task/kanban`); localstorageSettings("post", "taskView", "kanban")} } style={{ float:"left" }} className="btns"><i className="flaticon-web" /></a>
                <a onClick={() => this.setState({ open:'r-open' })} className="fl-l btni">Добавить задачу</a>
            </div>
            <div style={{ paddingBottom: "20px" }} className="clear" />
            <div className="calendar">
                <BigCalendar
                    localizer={localizer}
                    startAccessor="start"
                    endAccessor="end"
                    events={this.state.tasks}
                    onSelectEvent={event => this.setState({ open:'r-open', taskId:event.id, taskIndex: '' })}
                    onSelectSlot={({start, end}) => this.setState({ open:'r-open', start: start, end: end })}
                    eventPropGetter={(event, start, end, isSelected) => (Date.parse(end) < Date.parse(new Date())) ? {style: {background: "#c2263f"}} : {style: {background: event.bg}}}
                    selectable
                    onEventDrop={this.moveEvent}
                    resizable
                    onEventResize={this.resizeEvent}
                    //onDragStart={console.log}
                    defaultView={BigCalendar.Views.MONTH}
                    //onRangeChange={a => console.log("onRangeChange", a)}
                    locale="ru"
                    //eventPropGetter={(event: Object, start: Date, end: Date, isSelected: boolean) => { className?: string, style?: Object }}
                    messages={{
                        date: 'Дата',
                        time: 'Время',
                        event: 'Событие',
                        allDay: 'Весь день',
                        week: 'Неделя',
                        work_week: 'Рабочая неделя',
                        day: 'День',
                        month: 'Месяц',
                        previous: 'Назад',
                        next: 'Вперед',
                        yesterday: 'Yesterday',
                        tomorrow: 'Tomorrow',
                        today: 'Сегодня',
                        agenda: 'Список',
                        noEventsInRange: 'Нет событий на данный момент.',

                        showMore: total => `+${total} more`,
                    }}
                    views={['month', 'week', 'day', 'agenda']}
                />
            </div>
            <React.Fragment>
                <div className={"right-modal scrollbar " + this.state.open}><div className="save">
                    {(this.state.open === "r-open")
                        ? <AddTask
                            task={() => {this.loadData();}}
                            id={this.state.taskId}
                            index={this.state.taskIndex}
                            startDate={this.state.start}
                            endDate={this.state.end}
                            close={() => this.setState({ open:'', openType: '', taskId:'', taskIndex: '', start: null, end: null })}
                        /> : null}
                </div></div>
                {this.state.open ? <Bgdrop close={() => this.setState({ open:'', openType: '', taskId:'', taskIndex: '', start: null, end: null })} /> : null}
            </React.Fragment>
        </div>
    }
}
export default withRouter(TaskCalendar);