import {CrudGetSingleItemFunction} from "../CRUD";
import myaxios from "../../Axios";

const lookupModel = ({ link = '/ajax/invoice' }) => myaxios.get(link);

export const ListTest = async ({ filterArr, entityName }) => {

    async function processArray(array) {
        for (const { fieldType, filterLink, value } of array) {
            if(fieldType === "lookup" || fieldType === "products" || fieldType === "entity") {
                const filterLinkRes = await CrudGetSingleItemFunction({
                    service: lookupModel,
                    serviceData: { link: filterLink }
                })
                if(!filterLinkRes.status) return false;

                const entityRes = await CrudGetSingleItemFunction({
                    service: lookupModel,
                    serviceData: { link: `/ajax/${entityName}?filter[${value}][in][]=${filterLinkRes.data.items[0].id}` }
                })
                if(!entityRes.status || !entityRes.data.items) return false;
            } else if(fieldType === "number") {
                const entityRes = await CrudGetSingleItemFunction({
                    service: lookupModel,
                    serviceData: { link: `/ajax/${entityName}?filter[${value}][eq]=1` }
                })
                if(!entityRes.status || !entityRes.data.items) return false;
            } else if(fieldType === "text") {
                const entityRes = await CrudGetSingleItemFunction({
                    service: lookupModel,
                    serviceData: { link: `/ajax/${entityName}?filter[or][][${value}][like]=test` }
                })
                if(!entityRes.status || !entityRes.data.items) return false;
            } else if(fieldType === "date") {
                const entityRes = await CrudGetSingleItemFunction({
                    service: lookupModel,
                    serviceData: { link: `/ajax/${entityName}?filter[or][][${value}][like]=2021-10-13` }
                })
                if(!entityRes.status || !entityRes.data.items) return false;
            }
        }
        return true
    }

    const res = await processArray(filterArr)
    if(!res) {
        console.error(`${entityName} list have been failed`);
        return false;
    }

    return true;
}