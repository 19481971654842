import React, {useState} from "react";
import {FieldGen, setField, unLink, updateData} from "../components/fieldGen";
import myaxios from "../components/Axios";
import {withRouter} from "react-router-dom";
import formValid from "../components/UI/formValid";

function switchType(type) {
    switch (type) {
        case "account": return [
            {type: 3},
            {type: 1},
            {type: 14},
            {type: 13},
            {type: 6},
            {type: 26},
        ];
        case "contact": return [
            {type: 2},
            {type: 1},
            {type: 14},
            {type: 13},
            {type: 6},
            {type: 26},
        ];
        case "lead": return [
            {type: 23},
        ];
        case "users": return [
            {type: 23},
            {type: 2},
            {type: 23},
        ];
        default: return [];
    }
}

export const Segment = props => {
    const [state, setMyState] = useState({
        formControl: {
            name: {
                type: 'input',
                label: 'Название',
                value: '',
                valid: true,
                validation: {required: true}
            },
            type: {
                type: 'select',
                label: 'Тип сегмента',
                value: '',
                options:[
                    {value:'static', label:'Статический'},
                    {value:'dynamic', label:'Динамический'},
                ],
                valid: true,
                empty: true,
                validation: {required: true}
            },
            filter_logic: {
                type: 'select',
                label: 'Логика',
                value: 'all',
                options:[
                    {value:'all', label:'Все значения'},
                    {value:'one', label:'Одно из значений'},
                ],
                valid: true,
                empty: true,
                validation: {required: true}
            },
            customer_type: {
                type: 'select',
                label: 'Тип сущности',
                value: '',
                options:[
                    {value:'account', label:'Контрагенты'},
                    {value:'contact', label:'Контакты'},
                    //{value:'lead', label:'Лиды'},
                    /*{value:'users', label:'Подписчики'},*/
                ],
                empty: true,
                valid: true,
                validation: {required: true}
            },
            description: {
                type: 'textarea',
                label: 'Описание',
                value: '',
                valid: true,
                rows:5,
                validation: {required: false}
            },
            is_active: {value:1}
        },
        filters:[]
    });
    const setState = (st, func) => {
        Object.keys(st).map((key) => { state[key] = st[key]});
        setMyState(prevState => { return {...prevState, ...state} });
        if(func) func();
    };
    const isFormValid = () => {
        let formControl = state.formControl;
        let isFormValid = true;
        Object.keys(formControl).map((key, index) => {
            formControl[key].valid = formValid(formControl[key].value, formControl[key].validation)
            isFormValid = formControl[key].valid && isFormValid
        });
        setState({ formControl });
        return isFormValid;
    };
    const postData = () => {
        if(isFormValid()){
            myaxios.post(`/ajax/settings/segment`, {
                name: state.formControl.name.value,
                type: state.formControl.type.value,
                customer_type: state.formControl.customer_type.value,
                description: state.formControl.description.value,
                is_active: state.formControl.is_active.value,
                filter_logic: state.formControl.filter_logic.value,
                filters: state.filters
            }).then(res => {
                if(res.status === 200 || res.status === 201 || res.status === 204){
                    props.history.push('/settings/segment/' + res.data.id);
                    props.close()
                }
            })
        }
    };
    return <div className="sbform create-activ">
        <div className="rmod-header">
            <b>Платежи</b>
            <a onClick={props.close} className="rmod-close"><i className="flaticon-close" /></a>
            <div className="togglelist"><a onClick={postData} className="rmod-ok" target="_blank"><i className="flaticon-folder-3" /></a></div>
        </div>
        <div className="mod-cont">
            <FieldGen
                arr={state.formControl}
                setField={(e, type) => {
                    setState({
                        formControl: setField(state.formControl, type, e),
                        filters: (type === "customer_type") ? switchType(e) : state.filters
                    })
                }}
                updateData={(id, arr, type) => setState({ formControl: updateData(state.formControl,id, arr, type) })}
                unLink={(type) => setState({ formControl: unLink(state.formControl, type)})}
            />
        </div>
    </div>
};