import React, {useState} from "react";
import closeIcon from "../../fonts/icons/cancel.svg";
import noImageIcon from "../../fonts/icons/noimage.svg";
import myaxios from "../Axios";
import Notif from "../Notif";
import {ModalLoader} from "../UI/Loader";

const UploadAvatar = ({ img, setImg, cancelImg, related_id, related_type }) => {
    const [open, setOpen] = useState(false)
    const [load, setLoad] = useState(false)

    const handleUpload = (selectedFile) => {
        if(selectedFile !== null) {
            setLoad(true)
            const data = new FormData();
            data.append('UploadForm[file]', selectedFile, selectedFile.name);
            myaxios.post(`/ajax/file/upload?related_type=${related_type}&related_id=${related_id}`, data, {
                onUploadProgress: ProgressEvent => {
                    /*this.setState({
                        loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100
                    })*/
                }}).then(res => {
                if (!res.data.status && res.data.errors && res.data.errors.file) {
                    if(res.data.errors.file[0]) {
                        res.data.errors.file.map(item => {
                            if(typeof item === 'string') {
                                Notif('Error', item)
                            }
                        })
                    }
                } else {
                    setImg(res.data.url)
                    Notif('save', 'Фото успешно загружено')
                }
            }).catch((error) => {
                Notif("Error", error)
            })
            setLoad(false)
        }
    };

    const handleFile = ({ target = {} }) => {
        if(target.files) {
            handleUpload(target.files[0])
            //setFile(target.files[0]);
        }
    };

    return load ? <ModalLoader /> : (
        <>
            {img && <img src={closeIcon} className="contact-left-col-img-close" onClick={cancelImg} alt="" />}
            {img ? <div onClick={() => setOpen(!open)} className={`contact-left-col-img ${open && "open-image"}`} style={{ background: `url(${img})` }} /> :
                <label htmlFor="file">
                    <a>
                        <img className="contact-left-col-img" src={noImageIcon}  alt=""/>
                    </a>
                    <input onChange={handleFile} type="file" name="file" id="file"/>
                </label>}
        </>
    )
}

export default UploadAvatar