import React,{ Component } from 'react';
import Input from '../components/UI/Input';
import Myselect from '../components/UI/Select';
import formValid from '../components/UI/formValid';
import Notif from '../components/Notif';
import myaxios from '../components/Axios';
import AutoSelect from '../components/UI/AutoSelect';

class AddProjectRes extends Component {
    state = {
        formControl: {
            type: {value: '', options: []},
            related_type: {value: '', arr:null, valid: true, validation: {required: true}},
            plan: {value: ''},
            fact: {value: ''}
        },
        tch: true,
        facts:[],
        link:'/ajax/product?filter[name][like]='
    };
    showContact = () => {
        myaxios.get(`/ajax/project/work/prepare`, {
            method: 'get',
        }).then((response) => {
            let formControl = this.state.formControl;
            formControl.type.options = response.data.resource_types;
            formControl.type.value = response.data.resource_types[0] ? response.data.resource_types[0].id : '';
            this.setState({ formControl });
        })
    };
    showEditWork = () => {
        myaxios.get(`/ajax/project/resource/${this.props.id}?expand=facts`, {
            method: 'get',
        }).then((response) => {
            console.log("showEditWork", response.data)
            const formControl = this.state.formControl;
            formControl.plan.value = response.data.plan;
            formControl.related_type.value = response.data.related_id;
            formControl.type.value = response.data.type_id;
            formControl.related_type.arr = response.data.related;

            if(response.data.type_id == "2") this.setState({ link: '/ajax/product?filter[name][like]=' });
            else if(response.data.type_id == "3") this.setState({ link: '/ajax/settings/user?filter[name][like]=' });

            this.setState({ formControl, facts: response.data.facts })
        })
    };
    editRes = () => {
        this.setState({ tch: true });
        myaxios.put(`/ajax/project/resource/${this.props.id}`, {
            name: "test",
            type_id: this.state.formControl.type.value,
            related_id: this.state.formControl.related_type.value,
            plan: this.state.formControl.plan.value,
        }).then((response) => {
            if(response.status === 200 || response.status === 201 || response.status === 204) {
                setTimeout(() => this.props.refresh(), 100)
                Notif("save", "Ресурс успешно добавлен")
            }
        })
    };
    componentDidMount(){
        this.showContact()
        if(this.props.id) setTimeout(this.showEditWork(), 200);
    }
    isFormValid = () => {
        let formControl = this.state.formControl;
        let isFormValid = true;
        Object.keys(formControl).map((key, index) => {
            formControl[key].valid = formValid(formControl[key].value, formControl[key].validation)
            isFormValid = formControl[key].valid && isFormValid
        });
        this.setState({
            formControl, isFormValid
        });
        return isFormValid;
    };
    sendForm = event => {
        event.preventDefault();
        if(this.isFormValid()){
            this.setState({ tch: true });
            myaxios.post(`/ajax/project/work/${this.props.projectId}/add-resource`, {
                name: "test",
                type_id: this.state.formControl.type.value,
                related_id: this.state.formControl.related_type.value,
                plan: this.state.formControl.plan.value,
                //fact: this.state.formControl.fact.value,
                /*type_id: 1*/
             }).then(res => {
             if(res.status === 200) {
                 setTimeout(() => this.props.refresh(), 100)
                 Notif("save", "Ресурс успешно добавлен")
             }
             })
        } else Notif("Error")
    };
    /*Изменение полей*/
    setField = (event, type) => {const formControl = {  ...this.state.formControl };
        const control = { ...formControl[type] };
        control.value = event.target.value;
        if(type === "type"){
            if(event.target.value === "1") this.setState({ link: '/ajax/project/resource/search-machine/' });
            else if(event.target.value === "2") this.setState({ link: '/ajax/product?filter[name][like]=' });
            else if(event.target.value === "3") this.setState({ link: '/ajax/settings/user?filter[name][like]=' });
        }
        formControl[type] = control;
        this.setState({formControl, tch: false}) };
    /*Валидация формы*/

    updateData = (id, arr, addType, endParrans) => {
        const formControl = this.state.formControl;
        formControl[addType].arr = arr;
        formControl[addType].value = id;
        this.setState({ formControl, tch: false })
    };
    unLink = (type, typeID, id) => {
        const formControl = this.state.formControl;
        formControl[type].arr = null;
        formControl[type].value = null;
        this.setState({ formControl, tch: false })
    };
    render(){
        return <div className="sbform create-activ">
            <div className="rmod-header">
                <b>{this.props.id} {(this.props.id) ? "Редактировать ресурс" : "Добавить ресурс"}</b>
                <div className="togglelist"><button onClick={(this.props.id) ? this.editRes : this.sendForm} className="rmod-ok" disabled={this.state.tch} ><i className="flaticon-interface-1" /></button></div>
            </div>
            <div className="mod-cont">
                <Myselect
                    value={this.state.formControl.type.value}
                    label="Вид ресурса"
                    options={this.state.formControl.type.options}
                    onChange={event => this.setField(event, "type")}
                    shouldValidate={false}
                    val="id"
                    lab="name"
                />
                <div className="clear"/>
                {/*<AutoSelect
                    label="Значение"
                    link="/ajax/project/resource/search-machine/"
                    errorMessage="Поле не может быть пустым"
                    minLength="1"
                    result="name"
                    event="name"
                    value={this.state.formControl.related_type.value}
                    valid={this.state.formControl.related_type.valid}
                    shouldValidate={true}
                    setField={event => this.setField(event, "related_type")}
                    updateData={this.updateData}
                />*/}


                <div className="relt">
                    {
                        (this.state.formControl.related_type.arr) ?
                            <div className="inp">
                                <label>Значение</label>
                                <div className="fgroup rsb">
                                    <b>{this.state.formControl.related_type.arr.name}</b>
                                    <a title="Отвязать" onClick={()=>this.unLink("related_type")} className="unlink">x</a>
                                </div>
                            </div> :
                            <AutoSelect
                                label="Значение"
                                link={this.state.link}
                                errorMessage={'Поле не может быть пустым'}
                                shouldValidate={true}
                                valid={this.state.formControl.related_type.valid}
                                value={this.state.formControl.related_type.value}
                                setField={event => this.setField(event, "related_type")}
                                minLength="1"
                                result="name"
                                addType="related_type"
                                //setField={()=>{}}
                                updateData={this.updateData}
                            />
                    }
                </div>
                <Input
                    label="Плановые трудозатраты"
                    type="text"
                    value={this.state.formControl.plan.value}
                    onChange={event => this.setField(event, "plan")}
                    shouldValidate={false}
                />
                <Input
                    label="Фактические трудозатраты"
                    type="text"
                    value={this.state.formControl.fact.value}
                    onChange={event => this.setField(event, "fact")}
                    disabled={true}
                    shouldValidate={false}
                />
                <div className="body-table">
                    <table className="miniTable" style={{ width: "100%" }}>
                        <thead>
                            <td>Сотрудник</td>
                            <td>Факт</td>
                        </thead>
                        <tbody>
                        {
                            (this.state.facts) ? this.state.facts.map((item, index) =>
                                <tr>
                                    <td>{item.user_id}</td>
                                    <td>{item.fact}</td>
                                </tr>
                            ) : null
                        }
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    }}

export default AddProjectRes;