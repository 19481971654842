import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import myaxios from '../../components/Axios';

const MailFunc = props => {
    myaxios.get(`/ajax/mail/account`, {method: 'get',}).then((responce) =>{
        if(responce.data.items.length >= 1) {
            setTimeout(() => props.history.push(`/mail/${responce.data.items[0].id}/inbox`), 100)
        }
    })
    return <div className="page">
        <a onClick={() => {props.updateData("AddEmailBox", "")}} className="fl-l btni" href="#">Добавить почтовый ящик</a>
    </div>
}

export default withRouter(MailFunc);