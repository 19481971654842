import React, {useEffect, useState} from "react";
import Modal from "../../Modal/Modal";
import MultiSelect from "../../UI/MultiSelect";

export const ListColumnSettings = ({ isOpen, columns = [], saveFilter, filterFields, deleteToShowColumn, loading, close }) => {
    const [editedColumns, setEditedColumns] = useState([])
    const [tch, setTch] = useState(false)

    useEffect(() => {
        if(isOpen) {
            setEditedColumns(columns)
        }
    }, [isOpen])

    const saveData = async () => {
        if(tch) {
            saveFilter([], null, editedColumns)
        }
    }

    if(!filterFields || !deleteToShowColumn) {
        return <>Error</>
    }

    const clearData = filterFields.filter(el => !(deleteToShowColumn + ',products,created_by,tags,country,city,address').split(',').includes(el.value))

    return (
        <Modal isOpen={isOpen} title={'Настройка колонок'} tch={!tch} save={saveData} loading={loading} close={close}>
            <>
                <MultiSelect
                    custom={clearData}
                    options={editedColumns}
                    updateData={(data) => {
                        setEditedColumns([ ...data ])
                        setTch(true)
                    }}
                    valid
                />
            </>
        </Modal>
    )
}