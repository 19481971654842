import React, { Component } from 'react';

const Tasks = props => {
    return <div className="sp-table body-table">
        <table style={{borderSpacing: "0"}} className="stagesTable">
            <thead>
            {
                (props.item.length >= 1) ?
                    <tr>
                        <td>Текст</td>
                        <td>Статус</td>
                        <td>Завершение</td>
                    </tr> : null
            }
            </thead>
            <tbody>
            {
                props.item && props.item.map((item,index)=>
                    <tr key={index}>
                        <td><a onClick={()=> props.openModal("task",item.id)}>{item.subject}</a></td>
                        <td>{(item.status) ? item.status.value : null}</td>
                        <td>{item.end_date}</td>
                    </tr>
                )
            }
            </tbody>
        </table>
    </div>
}

export default Tasks;
