import React, {Component} from 'react';
import Myselect from '../../../../components/UI/Select';
import Textarea from '../../../../components/UI/Textarea';
import Input from '../../../../components/UI/Input';
import AutoSelect from '../../../../components/UI/AutoSelect';
import AdditionalFields from '../../../../components/additionalFields';
import MainEditor from "../../../../components/UI/Editor";

const Info = props => {
    return <div className="tab-content">
        <div className="sbform formlabel">
            <div className="connection" style={{ marginTop: "0px"}}>
                <span>Основная информация</span>
            </div>
            <div className="tWrap">
                <table className="stagesTable body-table" style={{ borderSpacing: 0 }}>
                    <tbody>
                    <tr>
                        <td>Дата создания</td>
                        <td>{props.created_at}</td>
                    </tr>
                    <tr>
                        <td>Дата обновления</td>
                        <td>{props.updated_at}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className="connection" style={{ marginTop: "10px"}}>
                <span>Основная информация</span>
            </div>
            <div className="clear" />
            <div className="relt">
                {(props.type) ?
                    <div className="inp fgroup rc6"><label>Тип потребности</label>
                        <div className="rsb"><b>{props.type.value}</b>
                            <a title="Отвязать" onClick={()=> props.unLink("type")} className="unlink">x</a></div></div> :
                    <AutoSelect
                        label="Тип потребности"
                        link={`/ajax/lookup/search?type=opp_type&value=`}
                        minLength={`0`}
                        className="fgroup rc6"
                        result="value"
                        event="type"
                        addType="type"
                        endParrams="1"
                        setField={()=>{}}
                        updateData={props.updateData}
                    />}
            </div>
            {/*<Input
                label="Следующий шаг"
                type="text"
                className="fgroup rc6"
                value={props.next_step}
                onChange={event => props.setField(event, "next_step")}
            />*/}
            <Input
                label="Вероятность сделки"
                type="number"
                min="0"
                max="100"
                className="fgroup rc6"
                value={props.probability}
                errorMessage="Значение должно быть числом"
                valid={props.probability_valid}
                shouldValidate={true}
                onChange={event => props.setField(event, "probability")}
            />
            {/*<Input
                label="Ожидаемый доход"
                type="text"
                className="fgroup rc6"
                errorMessage="Значение должно быть числом"
                value={props.expected_revenue}
                valid={props.expected_revenue_valid}
                shouldValidate={true}
                onChange={event => props.setField(event, "expected_revenue")}
            />*/}
            <AdditionalFields items={props.additionalFields}  updateAdditionalFields={props.updateAdditionalFields} />
            <MainEditor
                className="fgroup rc12"
                value={props.description}
                label="Комментарий"
                onChange={event => {props.setDescription(event)}}
                valid={true}
            />
        </div>
    </div>
};

export default Info;