import React, {useEffect, useState} from "react";
import {FieldGenZone, isFormValid} from "../components/fieldGen";
import Modal from "../components/Modal/Modal";
import myaxios from "../components/Axios";
import Notif from "../components/Notif";
import Input from "../components/UI/Input";
import Myselect from "../components/UI/Select";
import DateTime from "../components/UI/DateTime";
import {validationData} from "../components/UI/formValid";

function formatDate(date){
    if(date !== null) {
        date = new Date(date)
        let dd = date.getDate();
        if (dd < 10) dd = '0' + dd;
        let mm = date.getMonth() + 1;
        if (mm < 10) mm = '0' + mm;
        let yy = date.getFullYear() % 10000;
        if (yy < 10) yy = '0' + yy;

        let hh = date.getHours();
        if (hh < 10) hh = '0' + hh;

        let min = date.getMinutes();
        if (min < 10) min = '0' + min;

        return yy + '-' + mm + '-' + dd + " " + hh + ":" + min;
    }
}

const validationConfig = {

}

const initialState = {
    from_id: '',
    from_amount: '',
    to_id: '',
    to_amount: '',
    date: new Date()
}

const MoneyTransfer = ({ id, close, currencies, balances, isOpen, updateData }) => {
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState({})
    const [values, setValues] = useState({})

    const getTransferData = async () => {
        setLoading(true)
        if(id) {
            const { status, data } = await myaxios.get(`/ajax/balance/transfer/${id}?expand=owner,category`)
            const { from_balance_id, amount, to_balance_id, amount_to, date } = data;
            if(status === 200) {
                setValues({
                    ...JSON.parse(JSON.stringify(initialState)),
                    from_id: from_balance_id, from_amount: amount, to_id: to_balance_id, to_amount: amount_to, date
                })
            }
        } else {
            setValues({
                ...JSON.parse(JSON.stringify(initialState)),
                from_id: balances[0].id,
                to_id: balances[1].id,
            })
        }
        setLoading(false)
    }

    useEffect(() => {
        if(isOpen) getTransferData().then()
        setErrors({})
    },[balances, currencies, id])

    const saveData = async () => {
        const errorsData = validationData(values, validationConfig);
        setErrors(errorsData)
        if(Object.keys(errorsData).length > 0) {
            Notif('required-fields')
            return false;
        }

        const { from_id, from_amount, to_id, to_amount, date } = values
        const postData = { from: from_id, amount: from_amount, to: to_id, amount_to: to_amount, date: formatDate(date) }

        const { status, data } = id ? await myaxios.put(`/ajax/balance/transfer/${id}`, postData) : await myaxios.post(`/ajax/balance/transfer`, postData)
        if(status === 200 || status === 201) {
            updateData()
        }
    }

    return (
        <Modal isOpen={isOpen} title={'Перевод'} save={saveData} close={close} loading={loading}>
            <div>
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '70%' }}>
                        <Myselect
                            label={'С кошелька'}
                            value={values.from_id}
                            options={balances} val={'id'} lab={'name'}
                            onChange={e => {
                                const fromCurrency = balances.find(el => String(e.target.value) === el.id).currency.value
                                const toCurrency = balances.find(el => String(values.to_id) === el.id).currency.value
                                const exchange_rate = fromCurrency / toCurrency

                                setValues({
                                    ...values, from_id: e.target.value, to_amount: (values.from_amount * exchange_rate).toFixed(2)
                                })
                            }}
                            valid={!errors.from_id} errorMessage={errors.from_id} shouldValidate
                        />
                    </div>
                    <div style={{ width: '30%' }}>
                        <Input
                            label={'Сумма'}
                            value={values.from_amount}
                            onChange={e => {
                                const fromCurrency = balances.find(el => String(values.from_id) === el.id).currency.value
                                const toCurrency = balances.find(el => String(values.to_id) === el.id).currency.value
                                const exchange_rate = fromCurrency / toCurrency
                                setValues({...values, from_amount: e.target.value, to_amount: (e.target.value * exchange_rate).toFixed(2) })
                            }}
                            valid={!errors.from_amount} errorMessage={errors.from_amount} shouldValidate
                        />
                    </div>
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '70%' }}>
                        <Myselect
                            label={'С кошелька'}
                            value={values.to_id}
                            options={balances} val={'id'} lab={'name'}
                            onChange={e => {
                                const fromCurrency = balances.find(el => String(values.from_id) === el.id).currency.value
                                const toCurrency = balances.find(el => String(e.target.value) === el.id).currency.value
                                const exchange_rate = fromCurrency / toCurrency

                                setValues({
                                    ...values, to_id: e.target.value, to_amount: (values.from_amount * exchange_rate).toFixed(2)
                                })
                            }}
                            valid={!errors.to_id} errorMessage={errors.to_id} shouldValidate
                        />
                    </div>
                    <div style={{ width: '30%' }}>
                        <Input
                            label={'Сумма'}
                            value={values.to_amount}
                            onChange={e => setValues({ ...values, to_amount: e.target.value })}
                            valid={!errors.to_amount} errorMessage={errors.to_amount} shouldValidate
                        />
                    </div>
                </div>
                <DateTime
                    label={'Дата'}
                    value={values.date}
                    timeIntervals="1"
                    showTimeSelect
                    dateFormat="dd-MM-yyyy HH:mm"
                    timeFormat="HH:mm"
                    handleChange={date => setValues({ ...values, date })}
                    valid={!errors.date} errorMessage={errors.date}
                />
            </div>
        </Modal>
    )
}

const st = {
    formControl: {
        from_id: {
            type: "select",
            label: "Кошелек (откуда)",
            cls: "c8",
            value: "",
            options: [],
            valid: true,
            validation: {required: true},
            lab: "name",
            val: "id",
            zone: 1
        },
        from_amount: {
            type: 'input',
            label: 'Сумма',
            cls: "c4",
            value:"",
            inputType:'number',
            valid: true,
            validation: {required: true},
            post: "number",
            zone: 1
        },
        to_id: {
            type: "select",
            label: "Кошелек (куда)",
            cls: "c8",
            value: "",
            options: [],
            valid: true,
            validation: {required: true},
            lab: "name",
            val: "id",
            zone: 1
        },
        to_amount: {
            type: 'input',
            label: 'Сумма',
            cls: "c4",
            value:"",
            inputType:'number',
            valid: true,
            validation: {required: true},
            post: "number",
            zone: 1
        },
        datetime: {
            type: 'dateTime',
            label: 'Дата и время',
            cls: "c12",
            value: new Date(),
            valid: true,
            validation: {required: true},
            zone: 1
        },
    },
    course: "",
}
const MoneyTransfer2 = ({ isOpen, close, loading, id, currencies, balances }) => {
    const [state, setMyState] = useState({});

    const setState = (st, func) => {
        Object.keys(st).map((key) => { state[key] = st[key]});
        setMyState(prevState => { return {...prevState, ...state} });
        if(func) func();
    };


    const getSingleTransfer = () => {
        if(id){
            myaxios.get(`/ajax/balance/transfer/${id}`).then(res => {
                if(res && res.data){
                    const formControl = JSON.parse(JSON.stringify(state.formControl));
                    formControl.to_amount.value = res.data.to_amount;
                    formControl.from_amount.value = res.data.from_amount;
                    formControl.from_id.value = res.data.from_id;
                    formControl.to_id.value = res.data.to_id;
                    formControl.date.value = res.data.date;
                    setState({ formControl })
                }
            })
        } else {

        }
    }

    useEffect(() => {
        if(isOpen){
            getSingleTransfer()
        }
    }, [isOpen])



    const setFields = (e, type) => {
        const formControl = JSON.parse(JSON.stringify(state.formControl));
        formControl[type].value = e;

        if(type === "from_amount" || type === "from_id" || type === "to_id"){
            const fromCurrencyId = balances.find(el => formControl.from_id.value === el.id).currency_id
            const toCurrencyId = balances.find(el => formControl.to_id.value === el.id).currency_id
            const from = currencies.find(el => el.id === fromCurrencyId).value
            const to = currencies.find(el => el.id === toCurrencyId).value
            const curs =  from / to;
            if(Number(formControl.from_amount.value) > 0){
                formControl.to_amount.value = (formControl.from_amount.value * curs).toFixed(2)
            } else {
                formControl.to_amount.value = ""
            }
        }

        setState({ formControl })
    }

    const save = () => {
        if(state.valid){
            myaxios.post(`/ajax/balance/transfer`, {
                to_amount: state.formControl.to_amount.value,
                from_amount: state.formControl.from_amount.value,
                from_id: state.formControl.from_id.value,
                to_id: state.formControl.to_id.value,
                date: state.formControl.datetime.value
            }).then(res => {
                if(res.status === 200 || res.status === 201){
                    close()
                    loading()
                    Notif("save", "Перевод успешно добавлен")
                }
            })
        }
    }

    return (
        <div>
            <Modal save={() => setState(isFormValid(state), save())} title="Перевод" isOpen={isOpen} close={close}>
                <FieldGenZone
                    zone={1}
                    arr={state.formControl}
                    setField={setFields}
                />
            </Modal>
        </div>
    )
}

export default MoneyTransfer