import React, {useEffect, useState} from "react";
import Modal from "../../components/Modal/Modal";
import {GenerateForm} from "../../components/cardViewComponents/GenerateForm";
import {validationData} from "../../components/UI/formValid";
import {validationConfig} from "../../components/cardViewComponents/validationConfig";
import Notif from "../../components/Notif";
import {createOrderService, getPrepareOrderService} from "../../services/order/orderService";
import {createEvent} from "../../services/other/events";

const fieldsConfig = {
    name: {
        label: 'Название',
        type: 'text',
        className: 'w100',
        validation: { required: true, maxLength: 255 }, zone: 1
    },
    status_id: {
        label: 'Статус заказа',
        type: 'select',
        options: [],
        className: 'w100',
        options_from: 'statuses',
        prepare: 'statuses', val: 'id', lab: 'value',
        validation: { required: true, maxLength: 255 }, zone: 1
    },
    contact_id: {
        label: 'Контакт',
        type: 'entity',
        link: "/ajax/contact?filter[name][like]=",
        result: 'name',
        entity_from: 'contact',
        entityName: 'name',
        className: 'w100',
        minLength: 0, zone: 1
    },
    account_id: {
        label: 'Контрагент',
        type: 'entity',
        link: "/ajax/account?filter[name][like]=",
        result: 'name',
        entity_from: 'account',
        entityName: 'name',
        className: 'w100',
        minLength: 0, zone: 1
    },
    owner_id: {
        label: 'Ответственный',
        type: 'entity',
        link: "/ajax/settings/user?filter[name][like]=",
        result: 'name',
        entity_from: 'owner',
        entityName: 'name',
        className: 'w100',
        minLength: 0, zone: 1
    }
}

export const OrderModal = ({ isOpen, close, history, save, data: modalData = {} }) => {
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState({})
    const [disabled, setDisabled] = useState({})
    const [values, setValues] = useState({
        name: '',
        status_id: '',
        statuses: [],
        owner_id: null,
        owner: null,
        contact_id: modalData && modalData.contact_id,
        contact: modalData && modalData.contact,
        account_id: modalData && modalData.account_id,
        account: modalData && modalData.account,
        tch: false
    })

    const getPrepare = async () => {
        const { status, data } = await getPrepareOrderService()
        if((status === 200 || status === 201) && data) {
            setValues({
                ...values,
                status_id: data.statuses[0].id,
                statuses: data.statuses,
                name: 'Заказ №' + data.nextId
            })
        }
        setLoading(false)
    }

    const saveItem = async () => {
        let obj = {}
        if(!values.contact_id && !values.account_id) {
            obj = {
                contact_id: 'К заказу должен быть привязан контакт или контрагент',
                account_id: 'К заказу должен быть привязан контакт или контрагент'
            }
        }

        const errorsData = { ...validationData(values, validationConfig(fieldsConfig)), ...obj };

        setErrors(errorsData)
        if(Object.keys(errorsData).length > 0) {
            Notif('required-fields')
            return false;
        }

        setLoading(true)
        const { name, status_id, owner_id, contact_id, account_id } = values
        const { status, data } = await createOrderService({ data: {
            name, status_id, owner_id, contact_id, account_id
        }})
        if((status === 200 || status === 201) && data.id) {
            if(save) {
                save(); setTimeout(close, 200);
                createEvent({ user_id: window.location.host, user_name: "---", event_type: "createOrder" }).then()
            } else {
                history.push(`/order/${data.id}`)
                createEvent({ user_id: window.location.host, user_name: "---", event_type: "createOrder" }).then()
            }
            Notif('save')
        }
    }

    useEffect(() => {
        getPrepare().then()
    }, [])

    const changeFields = (key, value, item) => {
        let obj = {}
        let deleteObj = {}
        if(item) {
            if(item === 'clear') obj[fieldsConfig[key].entity_from] = null
            else obj[fieldsConfig[key].entity_from] = item
        }

        if(key === 'contact_id') {
            deleteObj['account_id'] = null
            deleteObj['account'] = null
        } else if(key === 'account_id') {
            deleteObj['contact_id'] = null
            deleteObj['contact'] = null
        }

        setValues({ ...values, ...obj, [key]: item ? item.id : value, ...deleteObj, tch: false })
    }

    return (
        <Modal isOpen={isOpen} save={saveItem} close={close} loading={loading} showLeaveModal tch={values.tch} title={'Создать заказ'}>
            <GenerateForm
                config={fieldsConfig}
                { ...{ values, errors, disabled } }
                onChange={changeFields}
                onSaveData={saveItem}
            />
        </Modal>
    )
}