import React from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-haml"
import "ace-builds/src-noconflict/ext-language_tools"
import "ace-builds/src-noconflict/theme-monokai"
import {TRACKER_URL} from "../../../components/integration/list/Tracker";
import Switch from "../../../components/UI/Switch";
import Input from "../../../components/UI/Input";

export const MainInfo = ({ id, getData, setSiteSettings, siteSettings }) => {
    return (
        <>
            <p style={{ fontFamily: '\'Montserrat-Light\', sans-serif', fontSize: '14px', marginTop: '10px', paddingBottom: '1px' }}>Скопируйте код и разместите межу тегами HEAD на вашем сайте. </p>
            <AceEditor
                mode="html"
                theme="monokai"
                width="100%"
                height="100px"
                fontSize={14}
                readOnly={true}
                value={`<meta name="oneweb-script-site" content="${id}">
<script async src="${TRACKER_URL}/oneweb-tracker.js"></script>`}
            />
            <div className="second-table second-table-white-td sbform">
                <table style={{ width: '100%', marginTop: '20px' }}>
                    <tr>
                        <th>Функция</th>
                        <th></th>
                    </tr>
                    <tr>
                        <td style={{ background: '#fff' }}>Статистика посещений сайта</td>
                        <td style={{ padding: '5px' }} align={'center'}>
                            <Switch
                                active={false}
                                onClick={() => {}}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td style={{ background: '#fff' }}>Виджет обратного звонка</td>
                        <td style={{ padding: '5px' }} align={'center'}>
                            <Switch
                                active={false}
                                onClick={() => {}}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td style={{ background: '#fff' }}>Маска для номера телефона</td>
                        <td style={{ width: '400px', padding: '6px' }} align={'center'}>
                            <Input />
                        </td>
                    </tr>
                    <tr>
                        <td style={{ background: '#fff' }}>Facebook messenger</td>
                        <td style={{ width: '400px', padding: '6px' }} align={'center'}>
                            <Input />
                        </td>
                    </tr>
                    <tr>
                        <td style={{ background: '#fff' }}>Instagram messenger</td>
                        <td style={{ width: '400px', padding: '6px' }} align={'center'}>
                            <Input />
                        </td>
                    </tr>
                    <tr>
                        <td style={{ background: '#fff' }}>Telegram messenger</td>
                        <td style={{ width: '400px', padding: '6px' }} align={'center'}>
                            <Input />
                        </td>
                    </tr>
                    <tr>
                        <td style={{ background: '#fff' }}>Viber messenger</td>
                        <td style={{ width: '400px', padding: '6px' }} align={'center'}>
                            <Input />
                        </td>
                    </tr>
                </table>
            </div>
        </>
    )
}