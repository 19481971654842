import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import Modal from "../components/Modal/Modal";
import {FieldGenZone, setField, unLink, updateData, isFormValid, postDt} from "../components/fieldGen";
import myaxios from "../components/Axios";
import Notif from "../components/Notif";
import Myselect from "../components/UI/Select";
import Input from "../components/UI/Input";
import DateTime from "../components/UI/DateTime";
import {NewAutoSelect} from "../components/NewUi/NewAutoSelect";
import {validationData} from "../components/UI/formValid";
import Textarea from "../components/UI/Textarea";

function formatDate(date){
    if(date !== null) {
        date = new Date(date)
        let dd = date.getDate();
        if (dd < 10) dd = '0' + dd;
        let mm = date.getMonth() + 1;
        if (mm < 10) mm = '0' + mm;
        let yy = date.getFullYear() % 10000;
        if (yy < 10) yy = '0' + yy;

        let hh = date.getHours();
        if (hh < 10) hh = '0' + hh;

        let min = date.getMinutes();
        if (min < 10) min = '0' + min;

        return yy + '-' + mm + '-' + dd + " " + hh + ":" + min;
    }
}

const validationConfig = {
    amount: {
        required: true,
        number: true,
        maxLength: 255
    },
    balance_id: { required: true },
    currency_id: { required: true },
    exchange_rate: { required: true, number: true },
    datetime: { required: true },
    description: { maxLength: 255 }
}

const initialState = {
    amount: '',
    balance_id: '',
    balances: [],
    category_id: '',
    category: null,
    currency_id: '',
    currencies: [],
    exchange_rate: '1',
    description: '',
    datetime: new Date(),
    owner_id: null,
    owner: null
}

const AddExpense = ({ isOpen, currencies, balances, id, close, updateData }) => {
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState({})
    const [values, setValues] = useState({})

    const getPaymentData = async () => {
        setLoading(true)
        if(id) {
            const { status, data } = await myaxios.get(`/ajax/expense/${id}?expand=category`)
            const { amount, balance_id, category_id, currency_id, exchange_rate, datetime, owner_id, owner, category, description } = data;
            setValues({
                ...JSON.parse(JSON.stringify(initialState)),
                balances: balances,
                currencies,
                amount, balance_id, category_id, currency_id, exchange_rate: exchange_rate || 1, datetime, owner_id, owner, category, description
            })
        } else {
            setValues({
                ...JSON.parse(JSON.stringify(initialState)),
                balances: balances,
                balance_id: balances[0].id,
                currencies,
                currency_id: currencies.find(el => el.id === balances[0].currency_id).id
            })
        }
        setLoading(false)
    }

    useEffect(() => {
        if(isOpen) getPaymentData().then()
        setErrors({})
    },[balances, currencies, id])

    const saveData = async () => {
        const errorsData = validationData(values, validationConfig);
        setErrors(errorsData)
        if(Object.keys(errorsData).length > 0) {
            Notif('required-fields')
            return false;
        }

        const { amount, balance_id, category_id, currency_id, exchange_rate, datetime, owner_id, description } = values
        const postData = {
            amount, balance_id, currency_id, exchange_rate,  owner_id, description,
            datetime: formatDate(datetime), category_id: category_id || null, name: 'expense'
        }

        const { status, data } = id ? await myaxios.put(`/ajax/expense/${id}`, postData) : await myaxios.post(`/ajax/expense`, postData)
        if(status === 200 || status === 201) {
            updateData()
        }
    }

    return (
        <Modal isOpen={isOpen} title={'Расход'} save={saveData} close={close} loading={loading}>
            <div>
                <Myselect
                    label={'Кошелек'}
                    value={values.balance_id}
                    options={values.balances} val={'id'} lab={'name'}
                    onChange={e => {
                        const balance = values.balances.find(el => el.id === e.target.value)
                        const currency = values.currencies.find(el => el.id === balance.currency_id)
                        setValues({
                            ...values,
                            balance_id: e.target.value,
                            currency_id: currency && currency.id
                        })
                    }}
                    valid={!errors.balance_id} errorMessage={errors.balance_id} shouldValidate
                />
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '70%' }}>
                        <Input
                            label={'Сумма'}
                            value={values.amount}
                            onChange={e => setValues({ ...values, amount: e.target.value })}
                            valid={!errors.amount} errorMessage={errors.amount} shouldValidate
                        />
                    </div>
                    <div style={{ width: '30%' }}>
                        <Myselect
                            label={'Валюта'} val={'id'} lab={'name'} disabled
                            value={values.currency_id}
                            options={values.currencies}
                            onChange={e => setValues({ ...values, currency_id: e.target.value })}
                            valid={!errors.currency_id} errorMessage={errors.currency_id} shouldValidate
                        />
                    </div>
                </div>
                <DateTime
                    label={'Дата'}
                    value={values.datetime}
                    timeIntervals="1"
                    showTimeSelect
                    dateFormat="dd-MM-yyyy HH:mm"
                    timeFormat="HH:mm"
                    handleChange={datetime => setValues({ ...values, datetime })}
                    valid={!errors.datetime} errorMessage={errors.datetime}
                />
                <NewAutoSelect
                    label={'Категория'}
                    searchBy={'value'}
                    entity={values.category}
                    link={'/ajax/lookup/search?type=expense_category&value='}
                    updateData={(category) => setValues({ ...values, category_id: category && category.id, category })}
                    onEnterData={(category) => setValues({ ...values, category_id: category && category.id, category })}
                    clear
                />
                <Textarea
                    label={'Описание'}
                    value={values.description}
                    onChange={e => setValues({ ...values, description: e.target.value })}
                    valid={!errors.description} errorMessage={errors.description} shouldValidate rows={5}
                />
            </div>
        </Modal>
    )
}


export default withRouter(AddExpense)