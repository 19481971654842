import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

const SMS = props => {
    {
        return <div className="sp-table body-table">
            <table style={{borderSpacing: "0"}} className="stagesTable">
                <thead>
                {
                    (props.item.length >= 1) ? <tr>
                        <td>Телефон</td>
                        <td>Статус</td>
                        <td>Текст сообщения</td>
                        <td>Дата</td>
                        <td style={{width: "30px"}}/>
                    </tr> : null
                }

                </thead>
                <tbody>
                {
                    props.item ? props.item.map((item,index)=>
                        <tr key={index}>
                            <td>{item.phone}</td>
                            <td>{item.message}</td>
                            <td>{item.text}</td>
                            <td>{item.date_sent}</td>
                            <td>
                                <div className="toogle-bottom fl-r">
                                    <b className="is"><i className="flaticon-more" /></b>
                                </div>
                            </td>
                        </tr>
                    ) : null
                }
                </tbody>
            </table>
        </div>
    }
}

export default withRouter(SMS);
