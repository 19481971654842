import React, {Component} from 'react';
import AddressItem from './AddressItem';

const Address = props => {
    return <div>
        <div className="connection">
            <span>Адреса</span>
            <div className="toggleparrent">
                <button onClick={()=>props.openAddress("open", "address")} className="btni add-address-button">+</button>
            </div>
        </div>
        <div className="sp-table body-table">
            <table style={{borderSpacing: "0 2px", display: (props.address.length >= 1) ? "table" : "none"}}>
                <thead>
                <tr className="fst-tr">
                    <td>Тип адреса</td>
                    <td>Страна</td>
                    <td>Город</td>
                    <td>Адрес</td>
                    <td>Индекс</td>
                    <td style={{width: "25px"}}>{null}</td>
                </tr>
                </thead>
                <tbody>
                {
                    props.address.map((item, index) =>
                        <AddressItem
                            key={index}
                            id={item.address.id}
                            type={item.address.type}
                            address={item.address.street}
                            city={item.address.city}
                            country={item.address.country}
                            inx={item.address.postal_code}
                            open={() => props.openAddress("open", "address", item.address.id)}
                            delete={() => props.deleteModal("delete", "address", index, item.address.id)}
                        />
                    )
                }
                </tbody>
            </table>
        </div>
    </div>
}

export default Address;
