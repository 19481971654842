import React from 'react';
import {withRouter} from 'react-router-dom';
/*
 newlead //новый лид
 phaseChange //смена статуса
 leadFail //провалена
 successful //сделка успешно завершена
 changedField //изменено поле
 newtask // новая задача
 expiredTask // задача просрочена
 endtask
 billcreated

 {props.icon} иконка
 {props.time} время
 {props.leadLink} ссылка сделки
 {props.title} название сделки
 {props.phase} название этапа
 {props.sum} сумма
 {props.user} // пользователь
 {props.field} // изменяемое поле
 {props.oldField} // старое поле
 {props.newField} //новое поле
 {props.invoicenumb} //номер счета
 {props.invoicenumb} //ссылка на счет
 {props.calltype}
 {props.callnumber}
 {props.callstatus}
 {props.calltime}
 {props.callrecordlink}
 {props.callcomment}
*/
function checkStatus(status) {
    switch(status){
        case "CANCEL": return "ОТМЕНЕН"; break;
        case "ANSWER": return "ПРИНЯТ"; break;
        case "NOANSWER": return "НЕТ ОТВЕТА"; break;
        case "BUSY": return "ЗАНЯТ"; break;
    }
}
function parsedJson(model, parrams) {
    if(model){
        if(model.callDetails){
            let a = JSON.parse(model.callDetails)
            return a[parrams];
        }
    }
}
const HistoryItem = props => {
switch (props.type){
    case "opportunity_linked": return <div className="m-list-timeline__item">
        <span className="m-list-timeline__badge m-list-timeline__badge--success" />
        <span className={"m-list-timeline__icon flaticon-add"} />
        <span className="m-list-timeline__text">Добавлена новая сделка {(props.model) ? <a onClick={() => props.history.push('/opportunity/' + props.model.id)}>{props.model.name}</a> : null}</span>
        <span className="m-list-timeline__time">{props.time}</span>
    </div>;
    case "opportunity_change_stage": return <div className="m-list-timeline__item">
        <span className="m-list-timeline__badge m-list-timeline__badge--danger" />
        <span className={"m-list-timeline__icon flaticon-share"} />
        <span className="m-list-timeline__text"> Сделка сменила этап на <span className="m-badge m-badge--success m-badge--wide" style={{background: props.model.color, color: "#2b2b2b"}}>{(props.model) ? props.model.value : null}</span></span>
        <span className="m-list-timeline__time">{props.time}</span>
    </div>;
    case "leadFail": return <div className="m-list-timeline__item">
        <span className="m-list-timeline__badge m-list-timeline__badge--warning" />
        <span className={"m-list-timeline__icon flaticon-warning-sign"} />
        <span className="m-list-timeline__text">
		<a href={props.leadLink}>{props.title}</a> провалена. <br /><b>Статус:</b> {props.failStatus}. <br /><b>Комментарий: </b>{props.coment}</span>
        <span className="m-list-timeline__time">{props.time}</span>
    </div>;
    case "successful": return <div className="m-list-timeline__item">
        <span className="m-list-timeline__badge m-list-timeline__badge--primary" />
        <span className={"m-list-timeline__icon flaticon-business"} />
        <span className="m-list-timeline__text"><a href={props.leadLink}>{props.title}</a> успешно завершена<br /><b>Сумма: </b> {props.sum}</span>
        <span className="m-list-timeline__time">{props.time}</span>
    </div>;
    case "field_history": return <div className="m-list-timeline__item">
        <span className="m-list-timeline__badge m-list-timeline__badge--brand" />
        <span className={"m-list-timeline__icon flaticon-interface-9"} />
        <span className="m-list-timeline__text">Пользователь {props.createdBy ? props.createdBy.name : ''} изменил {props.field} c "{props.oldField}" на "{props.newField}"</span>
        <span className="m-list-timeline__time">{props.time}</span>
    </div>;
    case "task_linked": return <div className="m-list-timeline__item">
        <span className="m-list-timeline__badge m-list-timeline__badge--success" />
        <span className={"m-list-timeline__icon flaticon-time-2"} />
        <span className="m-list-timeline__text"><b>Создана новая задача</b> "{(props.model) ? props.model.subject : null}"</span>
        <span className="m-list-timeline__time">{props.time}</span>
    </div>;
    case "expiredTask": return <div className="m-list-timeline__item">
        <span className="m-list-timeline__badge m-list-timeline__badge--danger" />
        <span className={"m-list-timeline__icon flaticon-music-1"} />
        <span className="m-list-timeline__text">Задача "{props.taskText}" изменила статус на <span className="danger m-badge">{props.taskstatus}</span></span>
        <span className="m-list-timeline__time">{props.time}</span>
    </div>;
    case "task_completed": return <div className="m-list-timeline__item">
        <span className="m-list-timeline__badge m-list-timeline__badge--warning">{null}</span>
        <span className={"m-list-timeline__icon flaticon-calendar-2"} />
        <span className="m-list-timeline__text">Задача "{(props.model) ? props.model.subject : null}" завершена</span>
        <span className="m-list-timeline__time">{props.time}</span>
    </div>;
    case "billcreated": return <div className="m-list-timeline__item">
        <span className="m-list-timeline__badge m-list-timeline__badge--primary" />
        <span className={"m-list-timeline__icon flaticon-coins"} />
        <span className="m-list-timeline__text">Создан счет <a href={props.invoicelink}>{props.invoicenumb}</a></span>
        <span className="m-list-timeline__time">{props.time}</span>
    </div>;
    case "sms_sent": return <div className="m-list-timeline__item">
        <span className="m-list-timeline__badge m-list-timeline__badge--primary" />
        <span className={"m-list-timeline__icon flaticon-chat-1"} />
        <span className="m-list-timeline__text">SMS {props.model ? <span>{props.model ? props.model.message : ''}</span> : null}<br />
        <b>Номер: </b> {props.model ? props.model.phone : ''} <br />
        <b>Текст: </b> {props.model ? props.model.text : ''}
        </span>
        <span className="m-list-timeline__time">{props.time}</span>
    </div>;
    case "billstatus": return <div className="m-list-timeline__item">
        <span className="m-list-timeline__badge m-list-timeline__badge--primary" />
        <span className={"m-list-timeline__icon flaticon-coins"} />
        <span className="m-list-timeline__text">Счет <a href={props.invoicelink}>{props.invoicenumb}</a> сменил статус на <span className="m-badge m-badge--success m-badge--wide">{props.phase}</span>
										</span>
        <span className="m-list-timeline__time">{props.time}</span>
    </div>;
    case "call": return <div className="m-list-timeline__item">
        <span className="m-list-timeline__badge m-list-timeline__badge--success" />
        <span className={"m-list-timeline__icon flaticon-support"} />

        {
            (parsedJson(props.model, "callType") === "0") ?
            <span className="m-list-timeline__text"><b style={{ fontSize: "12px"}}>Входящий звонок</b> c номера {parsedJson(props.model, "externalNumber")} на номер <em style={{ textDecoration: "underline" }}>{parsedJson(props.model, "pbxNumberData") ? parsedJson(props.model, "pbxNumberData").number : null}</em>
            {(parsedJson(props.model, "disposition") === "ANSWER") ? <a target="_blank" style={{ paddingLeft: "10px" }} href={parsedJson(props.model, "linkToCallRecordInMyBusiness")}>Прослушать</a> : null} <br />
            <b style={{ fontSize: "12px" }}>Статус:</b> {checkStatus(parsedJson(props.model, "disposition"))} <em>{props.calltime}</em>
            <b style={{ fontSize: "12px", paddingLeft: "10px" }}>Время ожидания:</b> {parsedJson(props.model, "waitsec")} <em>{props.calltime} сек.</em>
            <b style={{ fontSize: "12px", paddingLeft: "10px" }}>Длительность:</b> {parsedJson(props.model, "billsec")} <em>{props.calltime} сек</em>
            <b style={{ fontSize: "12px", paddingLeft: "10px" }}>Принял:</b> {props.model ? (props.model.user ? props.model.user.name : null) : null} <em>{props.calltime}</em>
            {props.model ? props.model.result ? <div><b style={{ fontSize: "12px" }}>Результат:</b> <em>{props.model.result}</em></div> : null : null}
            </span> :

            <span className="m-list-timeline__text"><b style={{ fontSize: "12px"}}>Исходящий звонок</b> от {props.model ? (props.model.user ? props.model.user.name : null) : null} на номер <em style={{ textDecoration: "underline" }}>{parsedJson(props.model, "externalNumber")}</em>
            {(parsedJson(props.model, "disposition") === "ANSWER") ? <a target="_blank" style={{ paddingLeft: "10px" }} href={parsedJson(props.model, "linkToCallRecordInMyBusiness")}>Прослушать</a> : null} <br />
            <b style={{ fontSize: "12px" }}>Статус:</b> {checkStatus(parsedJson(props.model, "disposition"))} <em>{props.calltime}</em>
            <b style={{ fontSize: "12px", paddingLeft: "10px" }}>Время ожидания:</b> {parsedJson(props.model, "waitsec")} <em>{props.calltime} сек.</em>
            <b style={{ fontSize: "12px", paddingLeft: "10px" }}>Длительность:</b> {parsedJson(props.model, "billsec")} <em>{props.calltime} сек</em>
            {props.model ? props.model.result ? <div><b style={{ fontSize: "12px" }}>Результат:</b> <em>{props.model.result}</em></div> : null : null}
            </span>
        }

        <span className="m-list-timeline__time">{props.time}</span>
    </div>;
    case "comment": return <div className="m-list-timeline__item">
        <span className="m-list-timeline__badge m-list-timeline__badge--warning" />
        <span className={"m-list-timeline__icon flaticon-notes"} />
        <span className="m-list-timeline__text"><b>
            {(props.model) ? (props.model.owner ? props.model.owner.name : '') : ''} добавил комментарий: </b>
            {(props.coment) ? <p dangerouslySetInnerHTML={{ __html: props.coment.content }} /> : null}
        </span>
        <span className="m-list-timeline__time">{props.time}</span>
    </div>;
    case "email": return <div className="m-list-timeline__item">
        <span className="m-list-timeline__badge m-list-timeline__badge--primary" />
        <span className={"m-list-timeline__icon flaticon-email"} />
        <span className="m-list-timeline__text"><b>{props.emailtype}: </b> {props.emailsubject} <a href={props.emaillink}>Открыть</a></span>
        <span className="m-list-timeline__time">{props.time}</span>
    </div>;
    case "contact_linked": return <div className="m-list-timeline__item">
        <span className="m-list-timeline__badge m-list-timeline__badge--primary" />
        <span className={"m-list-timeline__icon flaticon-user"} />
        <span className="m-list-timeline__text"><b>Привязан контакт: </b>{(props.model) ? <a onClick={() => props.history.push('/contact/' + props.model.id)}>{props.model.name}</a> : null}</span>
        <span className="m-list-timeline__time">{props.time}</span>
    </div>;
    case "created": return <div className="m-list-timeline__item">
        <span className="m-list-timeline__badge m-list-timeline__badge--warning" />
        <span className={"m-list-timeline__icon flaticon-app"} />
        <span className="m-list-timeline__text"><b>Создано: {props.time}</b></span>
        <span className="m-list-timeline__time">{props.time}</span>
    </div>;
    case "opportunity_change_owner": return <div className="m-list-timeline__item">
        <span className="m-list-timeline__badge m-list-timeline__badge--warning" />
        <span className={"m-list-timeline__icon flaticon-users"} />
        <span className="m-list-timeline__text"><b>{props.message} на</b> {(props.model) ? props.model.name : null}</span>
        <span className="m-list-timeline__time">{props.time}</span>
    </div>;
    case "product_unlinked": return <div className="m-list-timeline__item">
        <span className="m-list-timeline__badge m-list-timeline__badge--warning" />
        <span className={"m-list-timeline__icon flaticon-open-box"} />
        <span className="m-list-timeline__text">
            <b>Продукт</b> {props.model ? <a onClick={() => props.history.push('/product/' + props.model.id)}>{props.model.name}</a> : null} <b>отвязан</b></span>
        <span className="m-list-timeline__time">{props.time}</span>
    </div>;
    case "product_linked": return <div className="m-list-timeline__item">
        <span className="m-list-timeline__badge m-list-timeline__badge--warning" />
        <span className={"m-list-timeline__icon flaticon-open-box"} />
        <span className="m-list-timeline__text">
            <b>Продукт</b> {props.model ? <a onClick={() => props.history.push('/product/' + props.model.id)}>{props.model.name}</a> : null} <b>привязан</b></span>
        <span className="m-list-timeline__time">{props.time}</span>
    </div>;
    case "deleted": return <div className="m-list-timeline__item">
        <span className="m-list-timeline__badge m-list-timeline__badge--warning" />
        <span className={"m-list-timeline__icon flaticon-delete-2"} />
        <span className="m-list-timeline__text">Запись удалена</span>
        <span className="m-list-timeline__time">{props.time}</span>
    </div>;
    default: return <div className="m-list-timeline__item">
        <span className="m-list-timeline__badge m-list-timeline__badge--warning" />
        <span className={"m-list-timeline__icon flaticon-pie-chart"} />
        <span className="m-list-timeline__text"><b>{props.message}</b></span>
        <span className="m-list-timeline__time">{props.time}</span>
    </div>;
}
};

export default withRouter(HistoryItem);