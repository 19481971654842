import React, {useEffect, useState} from "react";
import Modal from "../../components/Modal/Modal";
import {FieldGenZone, isFormValid, postDt, setField, unLink, updateData} from "../../components/fieldGen";
import myaxios from "../../components/Axios";
import {CSSTransition} from "react-transition-group";
import workOrderConfig from "../../containers/stock/workorder/workOrderConfig";
import Notif from "../../components/Notif";
import {withRouter} from 'react-router-dom'
import ModAutoSelect from "../../components/UI/ModAutoSelect";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";

const CreateWorkOrder = ({ isOpen, close, history, getItems }) => {
    const [state, setMyState] = useState({
        formControl: {},
        loading: true
    })
    const [products, setProducts] = useState([]);

    const setState = (st, func) => {
        Object.keys(st).map((key) => { state[key] = st[key]});
        setMyState(prevState => { return {...prevState, ...state} });
        if(func) func();
    };

    useEffect(() => {
        if(isOpen){
            setState({ loading: true })
            getPrepare()
        }
    },[isOpen])

    const getPrepare = () => {
                myaxios.get(`/ajax/workorder/prepare`).then(res => {
            if(!res) return Notif("Error")
            const formControl = JSON.parse(JSON.stringify(workOrderConfig));
            formControl.name.value = "Заказ на производство #" + res.data.nextId;
            formControl.status_id.options = res.data.statuses;
            formControl.status_id.value = res.data.statuses[0].id;
            setState({ formControl, loading: false })
        })
    }

    const save = () => {
        if(!state.valid) return;
        setState({ tch: true, loading: true })

        let obj = postDt(state.formControl);
        myaxios.post(`/ajax/workorder`, obj).then(res => {
            if(!res) return Notif("Error");
            if(res.status === 200 || res.status === 201){
                Notif("save")
                setState({ tch: true })
                history.push(`/stock/workorder/${res.data.id}`)
                //getItems();
                //close()
            }
            setState({ loading: false })
        }).catch(error => setState({ loading: false }))
    }

    return (
        <Modal loading={state.loading} title="Заказ на производство" isOpen={isOpen} close={close} save={() => setState(isFormValid(state), save())}>
            {<div>
                <FieldGenZone
                    zone={1}
                    arr={state.formControl}
                    setField={(e, type) => setState({formControl: setField(state.formControl, type, e), tch: false})}
                    updateData={(id, arr, type) => setState({ formControl: updateData(state.formControl,id, arr, type), tch: false })}
                    unLink={(type) => setState({ formControl: unLink(state.formControl, type), tch: false})}
                />
                <div style={{ display: "none" }}>
                    <div className="clear" />
                    <div style={{ marginTop: "20px" }} className="connection"><span>Продукты</span>
                        <button onClick={() => {
                            const newProd = products;
                            newProd.unshift({id:"", arr:null, quantity:""})
                            setProducts([...newProd])
                        }} className="btni">+</button>
                    </div>
                    <table className="filterBpm" style={{width: "100%"}}>
                        {
                            products.map((item, index) => (
                                <tr key={index}>
                                    <td style={{ width: "60%" }}>
                                        <ModAutoSelect
                                            link="/ajax/product?filter[name][like]="
                                            minLength="0"
                                            result="name"
                                            event="account"
                                            errorMessage="Контакт или контрагент должны быть обязательно привязанны к счету"
                                            valid={true}
                                            shouldValidate={true}
                                            addType="account"
                                            entity={item.arr}
                                            entityName="name"
                                            updateData={(id, arr) => {
                                                const newProd = products;
                                                newProd[index].arr = arr;
                                                newProd[index].id = id;
                                                setProducts([...newProd])
                                            }}
                                            unLink={() => {
                                                const newProd = products;
                                                newProd[index].arr = null;
                                                newProd[index].id = "";
                                                setProducts([...newProd])
                                            }}
                                            //redirectLink="/order/"
                                        />
                                    </td>
                                    <td>
                                        <Input
                                            type="number"
                                            value={item.quantity}
                                            onChange={(e) => {
                                                const newProd = products;
                                                newProd[index].quantity = e.target.value;
                                                setProducts([...newProd])
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <a style={{fontSize: "19px", color: "rgb(194, 38, 63)", display: "block", textAlign: "center"}}>x</a>
                                    </td>
                                </tr>
                            ))
                        }
                    </table>

                    {/*<ModAutoSelect
                        label="Контрагент"
                        link="/ajax/account?filter[name][like]="
                        minLength="1"
                        result="name"
                        event="account"
                        errorMessage="Контакт или контрагент должны быть обязательно привязанны к счету"
                        valid={this.state.formControl.contact.valid}
                        shouldValidate={true}
                        addType="account"
                        entity={this.state.formControl.account.arr}
                        entityName="name"
                        updateData={this.updateData}
                        unLink={this.unLink}
                        //redirectLink="/order/"
                    />*/}
                </div>
            </div>}
        </Modal>
    )
}

export default withRouter(CreateWorkOrder)