import { productTest } from './Product/index'
import {leadTest} from "./Lead";
import {OpportunityTest} from "./Opportunity";
import {ListTests} from "./ListTests/ListTests";

export async function ApiTests() {
    const productTestRes = await productTest();
    if (!productTestRes) {
        console.error("ERROR!!! Product test have been failed!")
        return false;
    }

    const leadTestRes = await leadTest();
    if (!leadTestRes) {
        console.error("ERROR!!! Lead test have been failed!")
        return false;
    }

    const opportunityTestRes = await OpportunityTest();
    if (!opportunityTestRes) {
        console.error("ERROR!!! Opportunity test have been failed!")
        return false;
    }

    const AllListTest = await ListTests()
    if(!AllListTest) {
        console.log('List tests Error')
        return
    }


    await alert("Test have been true")
}