import {TASK_PRIORITY_OBJ, TASK_PRIORITY_OBJ_COLORS} from "../const";

export const entity = {
    lead: {
        title:'Лиды',
        entity:'lead',
        entityNumb:23,
        link:'/lead/',
        addButText:'Добавить лид',
        addButLink:"AddLead",
        exportType: 'lead',
        isExport: true,
        kanban: "/lead/kanban",
        importLink:'/import/lead/',
        fieldSettings:'/fieldsettings/23',
        analitics: '/lead/report/',
        columnSettings: true,
        columnDel: ['name', 'amount', 'created_at', 'status_id', 'lead_source_id', 'tags', 'phone', 'email', 'source_id', 'tags',
            'created_by', 'type_id'],
        adField: true,
        getLink:'/ajax/lead',
        delAll:`/ajax/lead/delete-all`,
        expand:'currency',
        extraExpand:'additionalFields,owner',
        thList:['Название', 'Данные о потребности', 'Статус'],
        bottomList: [
            {top:{type:'link',desc:null,val:'name'}, bottom:{type:'text',desc:'Бюджет',val:'amount',currency:true}},
            {top:{type:'obj',desc:'Тип',val:'type', from:'value'}, bottom:{type:'obj',desc:'Зрелость',val:'needObject', from:'value'}},
            {top:{type:'status',desc:null,val:'status', from:'value'}},
        ],
        fArr:[
            {value: "name", label: "Название лида", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/lead?filter[name][like]=", filterLength:"1" , fieldType:'text', sort: true},
            {value: "id", label: "ID", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/lead?filter[name][like]=", filterLength:"99999" , fieldType:'number', sort: true},
            {value: "first_name", label: "Имя", filterField: "autoselect", filterSearch:'first_name', filterLink:"/ajax/lead?filter[first_name][like]=", filterLength:"1" , fieldType:'text', sort: true},
            {value: "last_name", label: "Фамилия", filterField: "autoselect", filterSearch:'last_name', filterLink:"/ajax/lead?filter[last_name][like]=", filterLength:"1" , fieldType:'text'},
            {value: "company", label: "Контрагент", filterField: "autoselect", filterSearch:'company', filterLink:"/ajax/lead?filter[company][like]=", filterLength:"1" , fieldType:'text'},
            {value: "phone", label: "Телефон", filterField: "autoselect", filterSearch:'phone', filterLink:"/ajax/lead?filter[phone][like]=", filterLength:"1" , fieldType:'text'},
            {value: "email", label: "Email", filterField: "autoselect", filterSearch:'email', filterLink:"/ajax/lead?filter[email][like]=", filterLength:"1" , fieldType:'text'},
            {value: "amount", label: "Бюджет", filterField: "autoselect", filterSearch:'amount', filterLink:"/ajax/lead?filter[amount][like]=", filterLength:"99999" , fieldType:'number', sort: true},
            {value: "currency_id", label: "Валюта", filterField: "autoselect", filterSearch:'char_code', filterLink:"/ajax/lookup/search?type=currency&value=", filterLength:"0",fieldType:'lookup'},
            {value: "status_id", label: "Статус", filterField: "autoselect", filterSearch:'value', filterLink:"/ajax/lookup/search?type=ld_status&value=", filterLength:"0", fieldType:'lookup', sort: true},
            {value: "type_id", label: "Тип потребности", filterField: "autoselect", filterSearch:'value', filterLink:"/ajax/lookup/search?type=opp_type&value=", filterLength:"0", fieldType:'lookup'},
            {value: "source_id", label: "Источник лида", filterField: "autoselect", filterSearch:'value', filterLink:"/ajax/lookup/search?type=ld_source&value=", filterLength:"0", fieldType:'lookup'},
            {value: "tags", label: "Теги", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/lead/suggest?term=", filterLength:"0", fieldType:'tags'},
            {value: "created_at", label: "Дата создания", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999", fieldType:'date', sort: true},
            {value: "updated_at", label: "Дата изменения", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999", fieldType:'date', sort: true},
            {value: "created_by", label: "Кем создан", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0", fieldType:'entity'},
            {value: "owner_id", label: "Ответственный", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0", fieldType:'entity'},
            // {value: "is_deleted", label: "Удален", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/lead?filter[name][like]=", filterLength:"99999" , fieldType:'number'},
        ]
    },
    opportunity: {
        title:'Сделки',
        entity:'opportunity',
        entityNumb:1,
        link:'/opportunity/',
        addButText:'Добавить сделку',
        addButLink:"AddOpportunity",
        exportType: 'opportunity',
        isExport: true,
        importLink:'/import/opportunity/',
        fieldSettings:'/fieldsettings/1',
        analitics: '/opportunity/report/',
        columnSettings: true,
        columnDel: ['name', 'amount', 'account_id', 'created_at', 'stage_id', 'main_contact_id', 'lead_source_id', 'tags',
            'created_by', 'type_id'],
        adField: true,
        getLink:'/ajax/opportunity',
        delAll:`/ajax/opportunity/delete-all`,
        expand:'currency,account,contact,owner',
        extraExpand:'additionalFields',
        thList:['Название', 'Клиент', 'Этап сделки'],
        bottomList: [
            {top:{type:'link',desc:null,val:'name'}, bottom:{type:'text',desc:'Бюджет',val:'amount',currency:true}},
            {top:{type:'client', val:'main_contact_id',desc:''}, bottom:{type:'obj',desc:'',val:'type', from:'value'}},
            {top:{type:'statusOpp',desc:null,val:'stage', from:'value'}},
        ],
        fArr:[
            {value: "name", label: "Название", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/opportunity?filter[name][like]=", filterLength:"5" , fieldType:'text',sort: true},
            {value: "currency_id", label: "Валюта", filterField: "autoselect", filterSearch:'char_code', filterLink:"/ajax/lookup/search?type=currency&value=", filterLength:"0",fieldType:'lookup'},
            {value: "amount", label: "Бюджет", filterField: "autoselect", filterSearch:'amount', filterLink:"/ajax/opportunity?filter[name][like]=", filterLength:"999" , fieldType:'number',sort: true},
            {value: "stage_id", label: "Этап сделки", filterField: "autoselect", filterSearch:'value', filterLink:"/ajax/lookup/search?type=opp_stage&value=", filterLength:"0", fieldType:'lookup',sort: true},
            {value: "type_id", label: "Тип потребности", filterField: "autoselect", filterSearch:'value', filterLink:"/ajax/lookup/search?type=opp_type&value=", filterLength:"0", fieldType:'lookup'},
            {value: "lead_source_id", label: "Источник сделки", filterField: "autoselect", filterSearch:'value', filterLink:"/ajax/lookup/search?type=ld_source&value=", filterLength:"0", fieldType:'lookup'},
            {value: "account_id", label: "Контрагент", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/account?filter[name][like]=", filterLength:"0", fieldType:'entity'},
            {value: "main_contact_id", label: "Контакт", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/contact?filter[name][like]=", filterLength:"0", fieldType:'entity'},
            //{value: "probability", label: "Вероятность сделки", filterField: "autoselect", filterSearch:'probability', filterLink:"", filterLength:"100", fieldType:'number'},
            {value: "tags", label: "Теги", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/opportunity/suggest?term=", filterLength:"0", fieldType:'tags'},
            {value: "created_at", label: "Дата создания", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999", fieldType:'date',sort: true},
            {value: "updated_at", label: "Дата изменения", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999", fieldType:'date',sort: true},
            {value: "created_by", label: "Кем создан", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0", fieldType:'entity'},
            {value: "owner_id", label: "Ответственный", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0", fieldType:'entity'},
            //{value: "is_won", label: "Успешно завершена", filterField: "autoselect", filterSearch:'probability', filterLink:"", filterLength:"100", fieldType:'number'},
            //{value: "is_closed", label: "Отменена", filterField: "autoselect", filterSearch:'probability', filterLink:"", filterLength:"99999", fieldType:'number'},
            //{value: "is_deleted", label: "Удалена", filterField: "autoselect", filterSearch:'probability', filterLink:"", filterLength:"99999", fieldType:'number'},
        ]
    },
    task: {
        title:'Задачи',
        entity:'task',
        entityNumb:5,
        link:'/task/view/',
        deleteLink:'/task/',
        addButText:'Добавить задачу',
        addButLink:"AddTask",
        exportType: 'task',
        calendar: '/task/calendar/',
        kanban: '/task/kanban/',
        isExport: false,
        //importLink:'/import/opportunity/',
        //fieldSettings:'/fieldsettings/1',
        //analitics: '/opportunity/report/',
        columnSettings: false,
        columnDel: ['name', 'amount', 'account_id', 'created_at', 'stage_id', 'main_contact_id', 'lead_source_id', 'tags',
            'created_by', 'type_id'],
        adField: false,
        getLink:'/ajax/task',
        delAll:`/ajax/task/delete-all`,
        expand:'currency,account,contact,owner',
        extraExpand:'additionalFields',
        thList:['Тема', 'Статус', 'Тип', 'Приоритет', 'Теги'],
        bottomList: [
            {top:{type:'link',desc:null,val:'subject'}},
            {top:{type:'status',desc:'',val:'status', from:'value'}},
            {top:{type:'status',desc:'',val:'type', from:'value'}},
            {top:{type:'staticOptions',desc:'',val:'priority', from:'value', options: TASK_PRIORITY_OBJ, colors: TASK_PRIORITY_OBJ_COLORS }},
            {top:{type:'text',desc:null,val:'editorTags'}},
        ],
        fArr:[
            {value: "subject", label: "Название", filterField: "autoselect", filterSearch:'subject', filterLink:"/ajax/task?filter[subject][like]=", filterLength:"5", fieldType:'text', sort: true},
            {value: "owner_id", label: "Исполнитель", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0", fieldType:'entity'},
            {value: "priority", label: "Приоритет", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"9999", fieldType:'select', options:[
                    {id: '1', value: '1', label: 'Низкий'},
                    {id: '2', value: '2', label: 'Средний'},
                    {id: '3', value: '3', label: 'Высокий'},
                    {id: '4', value: '4', label: 'Критический'},
                ], sort: true},
            {value: "status_id", label: "Статус задачи", filterField: "autoselect", filterSearch:'value', filterLink:"/ajax/lookup/search?type=tsk_status&value=", filterLength:"0", fieldType:'lookup', sort: true},
            {value: "start_date", label: "Дата начала", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date', sort: true},
            {value: "end_date", label: "Дата завершения", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date', sort: true},
            {value: "description", label: "Описание задачи", filterField: "autoselect", filterSearch:'subject', filterLink:"/ajax/task?filter[subject][like]=", filterLength:"5", fieldType:'text'},
            // {value: "comment", label: "Комментарий исполнителя", filterField: "autoselect", filterSearch:'subject', filterLink:"/ajax/task?filter[subject][like]=", filterLength:"5", fieldType:'text'},
            {value: "type_id", label: "Тип задачи", filterField: "autoselect", filterSearch:'value', filterLink:"/ajax/lookup/search?type=tsk_type&value=", filterLength:"0", fieldType:'lookup'},
            {value: "tags", label: "Теги", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/task/suggest?term=", filterLength:"0", fieldType:'tags'},
            {value: "created_at", label: "Дата создания", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999", fieldType:'date', sort: true},
            {value: "updated_at", label: "Дата изменения", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999", fieldType:'date', sort: true},
            {value: "created_by", label: "Кем создана", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0", fieldType:'entity'},
            // {value: "backlog", label: "Backlog", filterField: "autoselect", filterSearch:'backlog', filterLink:"", filterLength:"99999", fieldType:'number'},
            /*{value: "lead_id", label: "Лид", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/lead?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "opportunity_id", label: "Сделка", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/opportunity?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "order_id", label: "Заказ", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/order?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "account_id", label: "Контрагент", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/account?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "contact_id", label: "Контакт", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/contact?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "invoice_id", label: "Счет", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/invoice?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "project_id", label: "Проект", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/project?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "document_id", label: "Документ", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/document?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "case_id", label: "Тикет", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/case?filter[name][like]=", filterLength:"0",fieldType:'entity'},*/
        ]
    },
    order: {
        title:'Заказы',
        entity:'order',
        entityNumb:14,
        link:'/order/',
        addButText:'Добавить заказ',
        addButLink:"AddOrder",
        exportType: 'order',
        isExport: false,
        //importLink:'/import/order/',
        fieldSettings:'/fieldsettings/14',
        analitics: '/order/report/',
        columnSettings: true,
        columnDel: ['name', 'tags', 'account_id', 'opportunity_id', 'contact_id', 'payment_type_id', 'delivery_type_id', 'stock_id',
            'status_id', 'created_by', 'cost'],
        adField: true,
        getLink:'/ajax/order',
        delAll:`/ajax/order/delete-all`,
        expand:'account,contact,currency',
        extraExpand:'additionalFields,owner',
        thList:['Номер', 'Клиент', 'Статус', 'Сумма заказа'],
        bottomList: [
            {top:{type:'link',desc:null,val:'name'}},
            {top:{type:'client', val:'main_contact_id',desc:''}},
            {top:{type:'status',desc:null,val:'status', from:'value'}},
            {top:{type:'text',desc:null,val:'cost', from:'value',currency:true}},
        ],
        fArr:[
            {value: "name", label: "Название", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/order?filter[name][like]=", filterLength:"5", fieldType:'text',sort: true},
            {value: "currency_id", label: "Валюта", filterField: "autoselect", filterSearch:'char_code', filterLink:"/ajax/lookup/search?type=currency&value=", filterLength:"0",fieldType:'lookup'},
            {value: "cost", label: "Cумма заказа", filterField: "autoselect", filterSearch:'cost', filterLink:"", filterLength:"100", fieldType:'number',sort: true},
            /*{value: "payment_cost", label: "Cумма оплаты", filterField: "autoselect", filterSearch:'payment_cost', filterLink:"", filterLength:"100", fieldType:'number',sort: true},*/
            {value: "paymentCost", label: "Cумма оплаты", filterField: "autoselect", filterSearch:'payment_cost', filterLink:"", filterLength:"100", fieldType:'number',sort: true},
            {value: "status_id", label: "Состояние заказа", filterField: "autoselect", filterSearch:'value', filterLink:"/ajax/lookup/search?type=ord_status&value=", filterLength:"0", fieldType:'lookup',sort: true},
            // {value: "payment_type_id", label: "Тип оплаты", filterField: "autoselect", filterSearch:'value', filterLink:"/ajax/lookup/search?type=ord_payment_type&value=", filterLength:"0", fieldType:'lookup'},
            // {value: "delivery_type_id", label: "Тип доставки", filterField: "autoselect", filterSearch:'value', filterLink:"/ajax/lookup/search?type=ord_delivery_type&value=", filterLength:"0", fieldType:'lookup'},
            {value: "account_id", label: "Контрагент", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/account?filter[name][like]=", filterLength:"0", fieldType:'entity'},
            {value: "contact_id", label: "Контакт", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/contact?filter[name][like]=", filterLength:"0", fieldType:'entity'},
            {value: "opportunity_id", label: "Сделка", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/opportunity?filter[name][like]=", filterLength:"0", fieldType:'entity'},
            {value: "stock_id", label: "Склад", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/stock?filter[name][like]=", filterLength:"0", fieldType:'entity'},
            {value: "tags", label: "Теги", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/order/suggest?term=", filterLength:"0", fieldType:'tags'},
            {value: "products", label: "Продукты", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/product?filter[name][like]=", filterLength:"0", fieldType:'products'},
            {value: "created_at", label: "Дата создания", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999", fieldType:'date',sort: true},
            {value: "updated_at", label: "Дата изменения", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999", fieldType:'date',sort: true},
            {value: "created_by", label: "Кем создан", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0", fieldType:'entity'},
            {value: "owner_id", label: "Ответственный", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0", fieldType:'entity'},
            // {value: "is_deleted", label: "Удален", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/lead?filter[name][like]=", filterLength:"99999" , fieldType:'number'},
        ]
    },
    account: {
        title:'Контрагенты',
        entity:'account',
        entityNumb:3,
        link:'/account/',
        addButText:'Новый контрагент',
        addButLink:"AddAccount",
        exportType: 'account',
        isExport: true,
        importLink:'/import/account/',
        fieldSettings:'/fieldsettings/3',
        analitics: '/account/report/',
        columnSettings: true,
        columnDel: ['name', 'contact_id','tags', "type_id", "email", "website", "phone", 'created_by'],
        adField: true,
        getLink:'/ajax/account',
        delAll:`/ajax/account/delete-all`,
        expand:'type,contact',
        extraExpand:'additionalFields,owner',
        thList:['Название', 'Информация', '', ''],
        bottomList: [
            {top:{type:'link',desc:null,val:'name'}, bottom:{type:'obj',desc:'Контакт',val:'contact', from:'name'}},
            {top:{type:'text', val:'website',desc:'web'}, bottom:{type:'phone',desc:'Тел',val:'phone', from:'phone'}},
            {top:{type:'status',desc:null,val:'type', from:'value'}},
            {top:{type:'email',desc:null,val:'email', from:'value',}, bottom:{type:'obj',desc:'',val:'mainAddress', from:'address'}},
        ],
        fArr:[
            {value: "name", label: "Название", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/account?filter[name][like]=", filterLength:"5", fieldType:'text',sort: true},
            {value: "type_id", label: "Тип контрагента", filterField: "autoselect", filterSearch:'value', filterLink:"/ajax/lookup/search?type=acc_type&value=", filterLength:"0" , fieldType:'lookup',sort: true},
            {value: "contact_id", label: "Контакт", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/contact?filter[name][like]=", filterLength:"1", fieldType:'entity'},
            {value: "industry", label: "Отрасль", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/account?filter[industry][like]=", filterLength:"3", fieldType:'text',sort: true},
            {value: "phone", label: "Телефон", filterField: "autoselect", filterSearch:'phone', filterLink:"/ajax/account?filter[phone][like]=", filterLength:"50", fieldType:'text'},
            {value: "email", label: "Email", filterField: "autoselect", filterSearch:'email', filterLink:"/ajax/account?filter[email][like]=", filterLength:"3", fieldType:'text'},
            {value: "website", label: "Сайт", filterField: "autoselect", filterSearch:'website', filterLink:"/ajax/account?filter[website][like]=", filterLength:"3", fieldType:'text'},
            {value: "description", label: "Описание", filterField: "autoselect", filterSearch:'description', filterLink:"/ajax/account?filter[description][like]=", filterLength:"5", fieldType:'text'},
            {value: "tags", label: "Теги", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/account/suggest?term=", filterLength:"0", fieldType:'tags'},
            {value: "country", label: "Страна", filterField: "autoselect", filterSearch:'description', filterLink:"/ajax/account?filter[country][like]=", filterLength:"5", fieldType:'text',sort: true},
            {value: "city", label: "Город", filterField: "autoselect", filterSearch:'description', filterLink:"/ajax/account?filter[city][like]=", filterLength:"5", fieldType:'text',sort: true},
            {value: "address", label: "Адрес", filterField: "autoselect", filterSearch:'description', filterLink:"/ajax/account?filter[address][like]=", filterLength:"5", fieldType:'text'},
            {value: "created_at", label: "Дата создания", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999", fieldType:'date',sort: true},
            {value: "updated_at", label: "Дата изменения", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999", fieldType:'date',sort: true},
            {value: "created_by", label: "Кем создан", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0", fieldType:'entity'},
            {value: "owner_id", label: "Ответственный", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0", fieldType:'entity'},
            // {value: "is_deleted", label: "Удален", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/lead?filter[name][like]=", filterLength:"99999" , fieldType:'number'},
        ]
    },
    contact: {
        title:'Контакты',
        entity:'contact',
        entityNumb:2,
        link:'/contact/',
        addButText:'Новый контакт',
        addButLink:"AddContact",
        exportType: 'contact',
        isExport: true,
        importLink:'/import/contact/',
        fieldSettings:'/fieldsettings/2',
        analitics: '/contact/report/',
        columnSettings: true,
        columnDel: ['name', 'amount', 'account_id', "type_id",'tags', "work_phone", "mobile_phone", "position", 'created_by', "email", "phone"],
        adField: true,
        getLink:'/ajax/contact',
        delAll:`/ajax/contact/delete-all`,
        expand:'account,type',
        extraExpand:'additionalFields,owner',
        thList:['ФИО контакта', 'Статус', 'Почта', 'Телефоны'],
        bottomList: [
            {top:{type:'link',desc:null,val:'name'}, bottom:{type:'obj',desc:'Контакт',val:'contact', from:'name'}},
            {top:{type:'status',desc:null,val:'type', from:'value'}},
            {top:{type:'email',desc:null,val:'email', from:'value'}},
            {top:{type:'phone',desc:'Тел',val:'mobile_phone', from:'phone'}, bottom:{type:'phone',desc:'Тел',val:'work_phone', from:'phone'}},
        ],
        fArr:[
            {value: "name", label: "ФИО", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/contact?filter[name][like]=", filterLength:"5",fieldType:'text',sort: true},
            {value: "type_id", label: "Тип контакта", filterField: "autoselect", filterSearch:'value', filterLink:"/ajax/lookup/search?type=ct_type&value=", filterLength:"0",fieldType:'lookup',sort: true},
            {value: "account_id", label: "Контрагент", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/account?filter[name][like]=", filterLength:"1",fieldType:'entity'},
            {value: "position", label: "Должность", filterField: "autoselect", filterSearch:'position', filterLink:"/ajax/contact?filter[position][like]=", filterLength:"1",fieldType:'text',sort: true},
            {value: "phone", label: "Телефон", filterField: "autoselect", filterSearch:'phone', filterLink:"/ajax/contact?filter[phone][like]=", filterLength:"50",fieldType:'text'},
            {value: "email", label: "Email", filterField: "autoselect", filterSearch:'email', filterLink:"/ajax/contact?filter[email][like]=", filterLength:"3",fieldType:'text'},
            {value: "description", label: "Описание", filterField: "autoselect", filterSearch:'description', filterLink:"/ajax/contact?filter[description][like]=", filterLength:"5",fieldType:'text'},
            {value: "tags", label: "Теги", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/contact/suggest?term=", filterLength:"0",fieldType:'tags'},
            {value: "country", label: "Страна", filterField: "autoselect", filterSearch:'description', filterLink:"/ajax/account?filter[country][like]=", filterLength:"5", fieldType:'text',sort: true},
            {value: "city", label: "Город", filterField: "autoselect", filterSearch:'description', filterLink:"/ajax/account?filter[city][like]=", filterLength:"5", fieldType:'text',sort: true},
            {value: "address", label: "Адрес", filterField: "autoselect", filterSearch:'description', filterLink:"/ajax/account?filter[address][like]=", filterLength:"5", fieldType:'text'},
            {value: "created_at", label: "Дата создания", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date',sort: true},
            {value: "updated_at", label: "Дата изменения", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date',sort: true},
            {value: "created_by", label: "Кем создан", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "owner_id", label: "Ответственный", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            //{value: "is_deleted", label: "Удален", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/lead?filter[name][like]=", filterLength:"99999" , fieldType:'number'},
        ]
    },
    cases: {
        title:'Обращения',
        entity:'case',
        entityNumb:27,
        link:'/case/',
        addButText:'Новый тикет',
        addButLink:"AddCases",
        //exportType: 'case',
        isExport: false,
        //importLink:'/import/case/',
        //fieldSettings:'/fieldsettings/27',
        //analitics: '/case/report/',
        columnSettings: true,
        columnDel: ['name', 'number', 'account_id', "type_id",'tags', "status_id", "source_id", 'created_by'],
        adField: false,
        getLink:'/ajax/case',
        delAll:`/ajax/case/delete-all`,
        expand:'contact,account',
        extraExpand:'additionalFields,owner',
        thList:['Тема обращения','Статус','Тип', 'Клиент'],
        bottomList: [
            {top:{type:'link',desc:null,val:'name'}},
            {top:{type:'status',desc:null,val:'status', from:'value'}},
            {top:{type:'status',desc:null,val:'type', from:'value'}},
            {top:{type:'client', val:'contact_id',desc:''}},
        ],
        fArr:[
            {value: "number", label: "Номер обращения", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/case?filter[name][like]=", filterLength:"5",fieldType:'number',sort: true},
            {value: "name", label: "Тема обращения", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/case?filter[name][like]=", filterLength:"5",fieldType:'text'},
            {value: "status_id", label: "Статус обращения", filterField: "autoselect", filterSearch:'value', filterLink:"/ajax/lookup/search?type=case_status&value=", filterLength:"0",fieldType:'lookup',sort: true},
            {value: "type_id", label: "Тип обращения", filterField: "autoselect", filterSearch:'value', filterLink:"/ajax/lookup/search?type=case_type&value=", filterLength:"0",fieldType:'lookup',sort: true},
            {value: "source_id", label: "Источник обращения", filterField: "autoselect", filterSearch:'value', filterLink:"/ajax/lookup/search?type=case_source&value=", filterLength:"0",fieldType:'lookup'},
            {value: "reason", label: "Причина обращения", filterField: "autoselect", filterSearch:'description', filterLink:"/ajax/contact?filter[description][like]=", filterLength:"5",fieldType:'text'},
            {value: "description", label: "Результат", filterField: "autoselect", filterSearch:'description', filterLink:"/ajax/contact?filter[description][like]=", filterLength:"5",fieldType:'text'},
            {value: "contact_id", label: "Контакт", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/contact?filter[name][like]=", filterLength:"1",fieldType:'entity'},
            {value: "account_id", label: "Контрагент", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/account?filter[name][like]=", filterLength:"1",fieldType:'entity'},
            {value: "tags", label: "Теги", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/case/suggest?term=", filterLength:"0",fieldType:'tags'},
            {value: "created_at", label: "Дата создания", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date',sort: true},
            {value: "updated_at", label: "Дата изменения", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date',sort: true},
            {value: "created_by", label: "Кем создан", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "owner_id", label: "Ответственный", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            //{value: "is_deleted", label: "Удален", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/lead?filter[name][like]=", filterLength:"99999" , fieldType:'number'},
        ]
    },
    invoice: {
        title:'Счета',
        entity:'invoice',
        entityNumb:13,
        link:'/invoice/',
        addButText:'Новый счет',
        addButLink:"AddInvoice",
        exportType: 'invoice',
        isExport: true,
        //importLink:'/import/invoice/',
        fieldSettings:'/fieldsettings/13',
        analitics: '/invoice/report/',
        columnSettings: true,
        columnDel: ['is_deleted','name','inv_id','tags', 'created_by', "cost","status_id","payment"],
        adField: true,
        getLink:'/ajax/invoice',
        delAll:`/ajax/invoice/delete-all`,
        expand:'currency,account,contact',
        // extraExpand:'owner,project,opportunity,order,provider',
        extraExpand: '',
        thList:['Номер счета', 'Дата создания', 'Статус', 'Сумма к оплате', 'Комментарий'],
        bottomList: [
            {top:{type:'link',desc:null,val:'name'}, bottom:{type:'client',desc:'Клиент',val:'contact', from:'name'}},
            {top:{type:'text',desc:null,val:'created_at', from:'value'}},
            {top:{type:'status',desc:null,val:'status', from:'value'}},
            {top:{type:'currency_cost',desc:'К оплате',val:'cost', currency:true}, bottom:{type:'text',desc:'Оплачено',val:'payment',currency:true}},
            {top:{type:'text',desc:null,val:'comment', from:'value'}},
        ],
        fArr:[
            {value: "name", label: "Название", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/invoice?filter[inv_id]=", filterLength:"999",fieldType:'text', sort: true},
            {value: "currency_id", label: "Валюта", filterField: "autoselect", filterSearch:'char_code', filterLink:"/ajax/lookup/search?type=currency&value=", filterLength:"0",fieldType:'lookup'},
            {value: "cost", label: "Сумма", filterField: "autoselect", filterSearch:'cost', filterLink:"/ajax/invoice?filter[cost][like]=", filterLength:"999",fieldType:'number', sort: true},
            {value: "payment", label: "Сумма оплаты", filterField: "autoselect", filterSearch:'payment', filterLink:"/ajax/invoice?filter[payment][like]=", filterLength:"999",fieldType:'number', sort: true},
            {value: "status_id", label: "Статус оплаты", filterField: "autoselect", filterSearch:'value', filterLink:"/ajax/lookup/search?type=inv_status&value=", filterLength:"0",fieldType:'lookup'},
            {value: "account_id", label: "Контрагент", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/account?filter[name][like]=", filterLength:"1",fieldType:'entity'},
            {value: "provider_id", label: "Организация", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/account?filter[name][like]=", filterLength:"1",fieldType:'entity'},
            {value: "opportunity_id", label: "Сделка", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/opportunity?filter[name][like]=", filterLength:"1",fieldType:'entity'},
            {value: "project_id", label: "Проект", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/project?filter[name][like]=", filterLength:"1",fieldType:'entity'},
            {value: "order_id", label: "Заказ", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/order?filter[name][like]=", filterLength:"0", fieldType:'entity'},
            {value: "contact_id", label: "Контакт", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/contact?filter[name][like]=", filterLength:"1",fieldType:'entity'},
            {value: "comment", label: "Комментарий", filterField: "autoselect", filterSearch:'description', filterLink:"/ajax/invoice?filter[comment][like]=", filterLength:"5",fieldType:'text'},
            {value: "created_date", label: "Дата выставления", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date', sort: true},
            {value: "payment_date", label: "Предполагаемая дата оплаты", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date', sort: true},
            {value: "payed_at", label: "Фактическая дата оплаты", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date', sort: true},
            {value: "products", label: "Продукты", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/product?filter[name][like]=", filterLength:"0", fieldType:'products'},
            /*{value: "payment_date", label: "Дата оплаты", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999" ,fieldType:'date'},*/
            {value: "tags", label: "Теги", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/invoice/suggest?term=", filterLength:"0",fieldType:'tags'},
            {value: "created_at", label: "Дата создания", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date', sort: true},
            {value: "updated_at", label: "Дата изменения", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date', sort: true},
            {value: "created_by", label: "Кем создан", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "owner_id", label: "Ответственный", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "is_deleted", label: "Удален", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/lead?filter[name][like]=", filterLength:"99999" , fieldType:'number'},
        ]
    },
    payments: {
        title:'Пратежи',
        entity:'payments',
        entityNumb:13,
        link:'/invoice/payments/',
        addButText:'Новый счет',
        addButLink:"AddInvoice",
        exportType: 'payment',
        isExport: true,
        //importLink:'/import/invoice/',
        //fieldSettings:'/fieldsettings/13',
        //analitics: '/invoice/report/',
        columnSettings: true,
        columnDel: ['is_deleted','name','inv_id','tags', 'created_by', "cost","status_id","payment"],
        adField: true,
        getLink:'/ajax/invoice/payments',
        delAll:`/ajax/payments/delete-all`,
        deleteLink: `/ajax/invoice/payment/`,
        expand:'currency',
        extraExpand:'additionalFields,owner,account,contact,project,opportunity,order,provider',
        thList:['Номер счета', 'Дата оплаты', 'Статус', 'Сумма к оплате'],
        bottomList: [
            {top:{type:'link',desc:null,val:'name'}, bottom:{type:'obj',desc:'Контакт',val:'contact', from:'name'}},
            {top:{type:'text',desc:null,val:'payment_date', from:'value'}},
            {top:{type:'status',desc:null,val:'status', from:'value'}},
            {top:{type:'currency_cost',desc:'К оплате',val:'cost', currency:true}, bottom:{type:'text',desc:'Оплачено',val:'payment',currency:true}},
        ],
        fArr:[
            {value: "name", label: "Название", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/invoice?filter[inv_id]=", filterLength:"999",fieldType:'text'},
            {value: "currency_id", label: "Валюта", filterField: "autoselect", filterSearch:'char_code', filterLink:"/ajax/lookup/search?type=currency&value=", filterLength:"0",fieldType:'lookup'},
            {value: "cost", label: "Сумма", filterField: "autoselect", filterSearch:'cost', filterLink:"/ajax/invoice?filter[cost][like]=", filterLength:"999",fieldType:'number'},
            {value: "payment", label: "Сумма оплаты", filterField: "autoselect", filterSearch:'payment', filterLink:"/ajax/invoice?filter[payment][like]=", filterLength:"999",fieldType:'number'},
            {value: "status_id", label: "Статус оплаты", filterField: "autoselect", filterSearch:'value', filterLink:"/ajax/lookup/search?type=inv_status&value=", filterLength:"0",fieldType:'lookup'},
            {value: "account_id", label: "Контрагент", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/account?filter[name][like]=", filterLength:"1",fieldType:'entity'},
            {value: "provider_id", label: "Организация", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/account?filter[name][like]=", filterLength:"1",fieldType:'entity'},
            {value: "opportunity_id", label: "Сделка", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/opportunity?filter[name][like]=", filterLength:"1",fieldType:'entity'},
            {value: "project_id", label: "Проект", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/project?filter[name][like]=", filterLength:"1",fieldType:'entity'},
            {value: "contact_id", label: "Контакт", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/contact?filter[name][like]=", filterLength:"1",fieldType:'entity'},
            {value: "description", label: "Комментарий", filterField: "autoselect", filterSearch:'description', filterLink:"/ajax/invoice?filter[comment][like]=", filterLength:"5",fieldType:'text'},
            {value: "created_date", label: "Дата выставления", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date'},
            {value: "payment_date", label: "Предполагаемая дата оплаты", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date'},
            {value: "payed_at", label: "Фактическая дата оплаты", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date'},
            /*{value: "payment_date", label: "Дата оплаты", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999" ,fieldType:'date'},*/
            {value: "tags", label: "Теги", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/invoice/suggest?term=", filterLength:"0",fieldType:'tags'},
            {value: "created_at", label: "Дата создания", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date'},
            {value: "updated_at", label: "Дата изменения", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date'},
            {value: "created_by", label: "Кем создан", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "owner_id", label: "Ответственный", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "is_deleted", label: "Удален", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/lead?filter[name][like]=", filterLength:"99999" , fieldType:'number'},
        ]
    },
    project: {
        title:'Проекты',
        entity:'project',
        entityNumb:6,
        link:'/project/',
        addButText:'Новый проект',
        addButLink:"AddProject",
        exportType: 'project',
        isExport: false,
        //importLink:'/import/contact/',
        fieldSettings:'/fieldsettings/6',
        //analitics: '/project/report/',
        columnSettings: true,
        columnDel: ['name', 'amount', 'account_id', "type_id",'tags', "phone", "position", 'created_by', "email"],
        adField: true,
        getLink:'/ajax/project',
        delAll:`/ajax/project/delete-all`,
        expand:'contact,account',
        extraExpand:'additionalFields,owner,opportunity',
        thList:['Название', 'Статус', 'Крайний срок'],
        bottomList: [
            {top:{type:'link',desc:null,val:'name'}, bottom:{type:'client',desc:'Клиент',val:'contact_id', from:'name'}},
            {top:{type:'text',desc:null,val:'statusLabel', from:'value'}},
            {top:{type:'text',desc:null,val:'deadline', from:'value'}},],
        fArr:[
            {value: "name", label: "Название", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/project?filter[name][like]=", filterLength:"5",fieldType:'text'},
            {value: "priority", label: "Приоритет", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/project?filter[priority][like]=", filterLength:"999",fieldType:'number'},
            {value: "description", label: "Описание", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/project?filter[description][like]=", filterLength:"5" ,fieldType:'text'},
            {value: "account_id", label: "Контрагент", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/account?filter[name][like]=", filterLength:"1",fieldType:'entity'},
            {value: "contact_id", label: "Контакт", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/contact?filter[name][like]=", filterLength:"1",fieldType:'entity'},
            {value: "opportunity_id", label: "Сделка", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/opportunity?filter[name][like]=", filterLength:"1",fieldType:'entity'},
            {value: "tags", label: "Теги", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/project/suggest?term=", filterLength:"0",fieldType:'tags'},
            {value: "start_date", label: "Дата начала", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date'},
            {value: "end_date", label: "Дата завершения", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date'},
            {value: "deadline", label: "Крайний срок", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date'},
            {value: "created_at", label: "Дата создания", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date'},
            {value: "updated_at", label: "Дата изменения", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date'},
            {value: "created_by", label: "Кем создан", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "owner_id", label: "Ответственный", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "is_deleted", label: "Удален", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/lead?filter[name][like]=", filterLength:"99999" , fieldType:'number'},
        ]
    },
    document: {
        title:'Документы',
        entity:'document',
        entityNumb:26,
        link:'/document/',
        addButText:'Новый документ',
        addButLink:"AddDocument",
        exportType: 'document',
        isExport: false,
        //importLink:'/import/document/',
        fieldSettings:'/fieldsettings/26',
        //analitics: '/document/report/',
        columnSettings: true,
        columnDel: ['name','tags', 'created_by', "type_id","status_id", "account_id"],
        adField: true,
        getLink:'/ajax/document',
        delAll:`/ajax/document/delete-all`,
        expand:'owner,status,type,account,contact',
        extraExpand:'additionalFields,project,opportunity,order',
        thList:['Название', 'Тип', 'Статус'],
        bottomList: [
            {top:{type:'link',desc:null,val:'name'}, bottom:{type:'client',desc:'Клиент',val:'contact_id', from:'name'}},
            {top:{type:'status',desc:null,val:'type', from:'value'}},
            {top:{type:'status',desc:null,val:'status', from:'value'}},],
        fArr:[
            {value: "name", label: "Название", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/document?filter[name]=", filterLength:"999",fieldType:'text',sort: true},
            {value: "status_id", label: "Статус документа", filterField: "autoselect", filterSearch:'value', filterLink:"/ajax/lookup/search?type=dt_status&value=", filterLength:"0",fieldType:'lookup',sort: true},
            {value: "type_id", label: "Тип документа", filterField: "autoselect", filterSearch:'value', filterLink:"/ajax/lookup/search?type=dt_type&value=", filterLength:"0",fieldType:'lookup',sort: true},
            {value: "account_id", label: "Контрагент", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/account?filter[name][like]=", filterLength:"1",fieldType:'entity'},
            {value: "order_id", label: "Заказ", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/order?filter[name][like]=", filterLength:"1",fieldType:'entity'},
            {value: "opportunity_id", label: "Сделка", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/opportunity?filter[name][like]=", filterLength:"1",fieldType:'entity'},
            {value: "project_id", label: "Проект", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/project?filter[name][like]=", filterLength:"1",fieldType:'entity'},
            {value: "contact_id", label: "Контакт", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/contact?filter[name][like]=", filterLength:"1",fieldType:'entity'},
            {value: "description", label: "Комментарий", filterField: "autoselect", filterSearch:'description', filterLink:"/ajax/invoice?filter[comment][like]=", filterLength:"5",fieldType:'text'},
            {value: "date", label: "Дата", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date',sort: true},
            {value: "tags", label: "Теги", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/document/suggest?term=", filterLength:"0",fieldType:'tags'},
            {value: "created_at", label: "Дата создания", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date',sort: true},
            {value: "updated_at", label: "Дата изменения", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date',sort: true},
            {value: "created_by", label: "Кем создан", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "owner_id", label: "Ответственный", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            //{value: "is_deleted", label: "Удален", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/lead?filter[name][like]=", filterLength:"99999" , fieldType:'number'},
        ]
    },
    product: {
        title:'Продукты',
        entity:'product',
        entityNumb:9,
        link:'/product/',
        addButText:'Добавить продукт',
        addButLink:"Product",
        exportType: 'product',
        isExport: true,
        importLink:'/import/product/',
        fieldSettings:'/fieldsettings/9',
        //analitics: '/document/report/',
        columnSettings: true,
        columnDel: ['name','tags', 'created_by', "model","base_price", 'curency'],
        adField: true,
        getLink:'/ajax/product',
        delAll:`/ajax/product/delete-all`,
        expand:'owner,status,type,account,contact,provider,manufacturer',
        extraExpand:'additionalFields,project,opportunity,order,category',
        thList:['Название', 'Базовая цена'],
        required:'name',
        bottomList: [
            {top:{type:'link',desc:null,val:'name'}},
            /*{top:{type:'text',desc:null,val:'model', from:'value', edit:true}},*/
            {top:{type:'text',desc:null,val:'base_price', from:'value',currency:true, edit:true, fldType:'number'}},],
        fArr:[
            {value: "name", label: "Название", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/product?filter[name][like]=", filterLength:"1",fieldType:'text',sort: true},
            {value: "sku", label: "Артикул", filterField: "autoselect", filterSearch:'sku', filterLink:"/ajax/product?filter[model][like]=", filterLength:"1" ,fieldType:'text',sort: true},
            {value: "model", label: "Модель", filterField: "autoselect", filterSearch:'model', filterLink:"/ajax/product?filter[model][like]=", filterLength:"1" ,fieldType:'text',sort: true},
            {value: "base_price", label: "Базовая цена", filterField: "autoselect", filterSearch:'base_price', filterLink:"/ajax/product?filter[base_price][like]=", filterLength:"999",fieldType:'number',sort: true},
            {value: "purchase_price", label: "Закупочная цена", filterField: "autoselect", filterSearch:'purchase_price', filterLink:"/ajax/product?filter[purchase_price][like]=", filterLength:"999",fieldType:'number',sort: true},
            {value: "currency_id", label: "Валюта", filterField: "autoselect", filterSearch:'char_code', filterLink:"/ajax/lookup/search?type=currency&value=", filterLength:"0",fieldType:'lookup',sort: true},
            {value: "category_id", label: "Категория", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/lookup/search?type=category&value=", filterLength:"0",fieldType:'lookup',sort: true},
            /*{value: "tax_id", label: "Налог", filterField: "autoselect", filterSearch:'value', filterLink:"/ajax/lookup/search?type=acc_type&value=", filterLength:"0",fieldType:'lookup'},*/
            {value: "tags", label: "Теги", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/product/suggest?term=", filterLength:"0",fieldType:'tags'},
            {value: "manufacturer_id", label: "Производитель", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/account?filter[name][like]=", filterLength:"1",fieldType:'entity',sort: true},
            {value: "provider_id", label: "Поставщик", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/account?filter[name][like]=", filterLength:"1",fieldType:'entity',sort: true},
            {value: "description", label: "Комментарий", filterField: "autoselect", filterSearch:'description', filterLink:"/ajax/product?filter[description][like]=", filterLength:"1",fieldType:'text'},
            {value: "created_at", label: "Дата создания", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date',sort: true},
            {value: "updated_at", label: "Дата изменения", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date',sort: true},
        ]
    },
    purchase: {
        title:'Закупки',
        entity:'purchase',
        entityNumb:16,
        link:'/stock/purchase/',
        addButText:'Добавить закупку',
        addButLink:"Purchase",
        exportType: 'purchase',
        isExport: false,
        adField: true,
        //importLink:'/import/purchase/',
        fieldSettings:'/fieldsettings/16',
        //analitics: '/document/report/',
        columnSettings: true,
        columnDel: ['name','account', 'created_by', "account","cost", 'currency','products'],
        getLink:'/ajax/stock/purchase',
        delAll:`/ajax/stock/purchase/delete-all`,
        expand:'provider,account,status,currency',
        extraExpand:'stock,owner',
        stock: true,
        thList:['Номер', 'Поставщик', 'Статус', 'Сумма заказа'],
        bottomList: [
            {top:{type:'link',desc:null,val:'name'}},
            {top:{type:'client',desc:null,val:'account', from:'name'}},
            {top:{type:'status',desc:null,val:'status', from:'value'}},
            {top:{type:'text',desc:null,val:'cost', from:'value',currency:true}},],
        fArr:[
            {value: "name", label: "Название", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/product?filter[name][like]=", filterLength:"1",fieldType:'text',sort: true},
            {value: "cost", label: "Сумма заказа", filterField: "autoselect", filterSearch:'model', filterLink:"/ajax/product?filter[model][like]=", filterLength:"1" ,fieldType:'number',sort: true},
            {value: "stock_id", label: "Склад", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/stock?filter[name][like]=", filterLength:"0",fieldType:'entity',sort: true},
            {value: "account_id", label: "Поставщик", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/account?filter[name][like]=", filterLength:"0",fieldType:'entity',sort: true},
            {value: "products", label: "Продукты", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/product?filter[name][like]=", filterLength:"0", fieldType:'products'},
            {value: "created_at", label: "Дата создания", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date',sort: true},
            {value: "updated_at", label: "Дата изменения", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date',sort: true},
            {value: "created_by", label: "Кем создан", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "owner_id", label: "Ответственный", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0",fieldType:'entity'},
        ]
    },
    replanish: {
        title:'Приход',
        entity:'replanish',
        entityNumb:17,
        link:'/stock/replanish/',
        addButText:'Добавить приход',
        addButLink:"Replanish",
        exportType: 'replanish',
        isExport: false,
        stock: true,
        //importLink:'/import/purchase/',
        fieldSettings:'/fieldsettings/17',
        //analitics: '/document/report/',
        columnSettings: true,
        columnDel: ['name','stock_id','created_by',"date","purchase_id",'currency','products'],
        adField: true,
        getLink:'/ajax/stock/replanish',
        delAll:`/ajax/replanish/delete-all`,
        expand:'stock,order,provider,purchase',
        extraExpand:'owner',
        thList:['Номер', 'Склад', 'Дата прихода', 'Заказ'],
        bottomList: [
            {top:{type:'link',desc:null,val:'name'}},
            {top:{type:'obj',desc:null,val:'stock', from:'name'}},
            {top:{type:'text',desc:null,val:'date', from:'value'}},
            {top:{type:'obj',desc:null,val:'purchase', from:'name'}},],
        fArr:[
            {value: "name", label: "Название", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/product?filter[name][like]=", filterLength:"1",fieldType:'text',sort: true},
            {value: "date", label: "Дата отгрузки", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/product?filter[name][like]=", filterLength:"1",fieldType:'date',sort: true},
            {value: "stock_id", label: "Склад", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/stock?filter[name][like]=", filterLength:"0",fieldType:'entity',sort: true},
            {value: "account_id", label: "Контрагент", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/account?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "products", label: "Продукты", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/product?filter[name][like]=", filterLength:"0", fieldType:'products'},
            {value: "created_at", label: "Дата создания", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date',sort: true},
            {value: "updated_at", label: "Дата изменения", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date',sort: true},
            {value: "created_by", label: "Кем создан", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "owner_id", label: "Ответственный", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "is_completed", label: "Проведено", filterField: "autoselect", filterSearch:'name', filterLink:"", filterLength:"9999",fieldType:'number',sort: true},
        ]
    },
    shipment: {
        title:'Отгрузки',
        entity:'shipment',
        entityNumb:19,
        link:'/stock/shipment/',
        addButText:'Добавить отгрузку',
        addButLink:"Shipment",
        exportType: 'shipment',
        isExport: false,
        stock: true,
        //importLink:'/import/purchase/',
        fieldSettings:'/fieldsettings/19',
        //analitics: '/document/report/',
        columnSettings: true,
        columnDel: ['name','stock_id','created_by',"date",'currency','products'],
        adField: true,
        getLink:'/ajax/stock/shipment',
        delAll:`/ajax/shipment/delete-all`,
        expand:'stock',
        extraExpand:'owner,account,contact,invoice,order',
        thList:['Номер', 'Склад', 'Дата отгрузки'],
        bottomList: [
            {top:{type:'link',desc:null,val:'name'}},
            {top:{type:'obj',desc:null,val:'stock', from:'name'}},
            {top:{type:'text',desc:null,val:'date', from:'value'}},
            ],
        fArr:[
            {value: "name", label: "Название", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/product?filter[name][like]=", filterLength:"1",fieldType:'text',sort: true},
            {value: "id", label: "ID", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/lead?filter[name][like]=", filterLength:"99999" , fieldType:'number',sort: true},
            {value: "cost", label: "Сумма", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/product?filter[name][like]=", filterLength:"1",fieldType:'text',sort: true},
            {value: "date", label: "Дата отгрузки", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/product?filter[name][like]=", filterLength:"1",fieldType:'date',sort: true},
            {value: "order_id", label: "Заказ", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/order?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "invoice_id", label: "Счет", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/invoice?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "stock_id", label: "Склад", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/stock?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "account_id", label: "Контрагент", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/account?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "contact_id", label: "Контакт", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/contact?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "products", label: "Продукты", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/product?filter[name][like]=", filterLength:"0", fieldType:'products'},
            {value: "created_at", label: "Дата создания", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date',sort: true},
            {value: "updated_at", label: "Дата изменения", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date',sort: true},
            {value: "created_by", label: "Кем создан", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "owner_id", label: "Ответственный", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "is_completed", label: "Проведено", filterField: "autoselect", filterSearch:'name', filterLink:"", filterLength:"9999",fieldType:'number',sort: true},
        ]
    },
    transfer: {
        title:'Перемещение',
        entity:'transfer',
        entityNumb:20,
        link:'/stock/transfer/',
        addButText:'Создать перемещение',
        addButLink:"Transfer",
        exportType: 'transfer',
        isExport: false,
        stock: true,
        //importLink:'/import/purchase/',
        fieldSettings:'/fieldsettings/20',
        //analitics: '/document/report/',
        columnSettings: true,
        columnDel: ['name','from_id','to_id','created_by',"date","order_id",'curency','products'],
        adField: true,
        getLink:'/ajax/stock/transfer',
        delAll:`/ajax/transfer/delete-all`,
        expand:'order,provider,order,from,to,provider,account',
        extraExpand:'owner',
        thList:['Номер', 'Со склада', 'На склад', 'Дата'],
        bottomList: [
            {top:{type:'link',desc:null,val:'name'}},
            {top:{type:'obj',desc:null,val:'from', from:'name'}},
            {top:{type:'obj',desc:null,val:'to', from:'name'}},
            {top:{type:'text',desc:null,val:'date', from:'value'}}
        ],
        fArr:[
            {value: "name", label: "Название", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/product?filter[name][like]=", filterLength:"1",fieldType:'text',sort: true},
            {value: "date", label: "Дата отгрузки", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/product?filter[name][like]=", filterLength:"1",fieldType:'date',sort: true},
            {value: "from_id", label: "Со склада", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/stock?filter[name][like]=", filterLength:"0",fieldType:'entity',sort: true},
            {value: "to_id", label: "На склад", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/stock?filter[name][like]=", filterLength:"0",fieldType:'entity',sort: true},
            {value: "account_id", label: "Контрагент", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/account?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "products", label: "Продукты", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/product?filter[name][like]=", filterLength:"0", fieldType:'products'},
            {value: "created_at", label: "Дата создания", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date',sort: true},
            {value: "updated_at", label: "Дата изменения", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date',sort: true},
            {value: "created_by", label: "Кем создан", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "owner_id", label: "Ответственный", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "is_completed", label: "Проведено", filterField: "autoselect", filterSearch:'name', filterLink:"", filterLength:"9999",fieldType:'number',sort: true},
        ]
    },
    consumption: {
        title:'Списания',
        entity:'consumption',
        entityNumb:18,
        link:'/stock/consumption/',
        addButText:'Добавить списание',
        addButLink:"Consumption",
        exportType: 'consumption',
        isExport: false,
        stock: true,
        //importLink:'/import/purchase/',
        fieldSettings:'/fieldsettings/18',
        //analitics: '/document/report/',
        columnSettings: true,
        columnDel: ['name','stock_id','created_by',"date","order_id",'currency','products'],
        adField: true,
        getLink:'/ajax/stock/consumption',
        delAll:`/ajax/consumption/delete-all`,
        expand:'stock,provider',
        extraExpand:'owner,account',
        thList:['Номер', 'Склад', 'Дата'],
        bottomList: [
            {top:{type:'link',desc:null,val:'name'}},
            {top:{type:'obj',desc:null,val:'stock', from:'name'}},
            {top:{type:'text',desc:null,val:'date', from:'value'}},
        ],
        fArr:[
            {value: "name", label: "Название", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/product?filter[name][like]=", filterLength:"1",fieldType:'text',sort: true},
            {value: "date", label: "Дата отгрузки", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/product?filter[name][like]=", filterLength:"1",fieldType:'date',sort: true},
            {value: "stock_id", label: "Склад", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/stock?filter[name][like]=", filterLength:"0",fieldType:'entity',sort: true},
            {value: "provider_id", label: "Организация", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/account?filter[name][like]=", filterLength:"0",fieldType:'entity',sort: true},
            {value: "products", label: "Продукты", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/product?filter[name][like]=", filterLength:"0", fieldType:'products'},
            {value: "created_at", label: "Дата создания", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date',sort: true},
            {value: "updated_at", label: "Дата изменения", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date',sort: true},
            {value: "created_by", label: "Кем создан", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "owner_id", label: "Ответственный", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "is_completed", label: "Проведено", filterField: "autoselect", filterSearch:'name', filterLink:"", filterLength:"9999",fieldType:'number',sort: true},
        ]
    },
    stockReturn: {
        title:'Возвраты',
        entity:'provider-return',
        entityNumb:32,
        link:'/stock/return/',
        addButText:'Добавить возврат',
        //addButLink:"AddProviderReturn",
        addOpenButtonModal:'AddProviderReturn',
        exportType: 'provider-return',
        isExport: false,
        stock: true,
        //importLink:'/import/purchase/',
        fieldSettings:'/fieldsettings/32',
        //analitics: '/document/report/',
        columnSettings: true,
        columnDel: ['name','stock_id' ,"date",'products'],
        adField: true,
        getLink:'/ajax/stock/provider-return',
        delAll:`/ajax/stock/provider-return/delete-all`,
        deleteLink: "/stock/provider-return/",
        expand:'stock',
        extraExpand:'owner,account,contact,order,invoice',
        thList:['Номер', 'Склад', 'Дата'],
        bottomList: [
            {top:{type:'link',desc:null,val:'name'}},
            {top:{type:'obj',desc:null,val:'stock', from:'name'}},
            {top:{type:'text',desc:null,val:'date', from:'value'}},
        ],
        fArr:[
            {value: "name", label: "Название", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/product?filter[name][like]=", filterLength:"1",fieldType:'text',sort: true},
            {value: "date", label: "Дата отгрузки", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/product?filter[name][like]=", filterLength:"1",fieldType:'date',sort: true},
            {value: "stock_id", label: "Склад", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/stock?filter[name][like]=", filterLength:"0",fieldType:'entity',sort: true},
            {value: "account_id", label: "Контрагент", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/account?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "contact_id", label: "Контакт", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/contact?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "order_id", label: "Заказ", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/order?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "invoice_id", label: "Счет", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/invoice?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "products", label: "Продукты", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/product?filter[name][like]=", filterLength:"0", fieldType:'products'},
            {value: "created_at", label: "Дата создания", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date',sort: true},
            {value: "updated_at", label: "Дата изменения", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date',sort: true},
            {value: "created_by", label: "Кем создан", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "owner_id", label: "Ответственный", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "is_completed", label: "Проведено", filterField: "autoselect", filterSearch:'name', filterLink:"", filterLength:"9999",fieldType:'number',sort: true},
        ]
    },
    stocktake: {
        title:'Инвентаризации',
        entity:'stocktake',
        entityNumb:21,
        link:'/stock/stocktake/',
        addButText:'Добавить инвентаризацию',
        addButLink:"Stocktake",
        exportType: 'stocktake',
        isExport: false,
        stock: true,
        //importLink:'/import/purchase/',
        fieldSettings:'/fieldsettings/21',
        //analitics: '/document/report/',
        columnSettings: true,
        columnDel: ['name','stock_id','created_by',"date",'currency','products'],
        adField: true,
        getLink:'/ajax/stock/stocktake',
        delAll:`/ajax/stocktake/delete-all`,
        expand:'stock,provider',
        extraExpand:'owner,account',
        thList:['Номер', 'Склад', 'Дата'],
        bottomList: [
            {top:{type:'link',desc:null,val:'name'}},
            {top:{type:'obj',desc:null,val:'stock', from:'name'}},
            {top:{type:'text',desc:null,val:'date', from:'value'}},
        ],
        fArr:[
            {value: "name", label: "Название", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/product?filter[name][like]=", filterLength:"1",fieldType:'text',sort: true},
            {value: "date", label: "Дата отгрузки", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/product?filter[name][like]=", filterLength:"1",fieldType:'date',sort: true},
            {value: "stock_id", label: "Склад", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/stock?filter[name][like]=", filterLength:"0",fieldType:'entity',sort: true},
            {value: "products", label: "Продукты", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/product?filter[name][like]=", filterLength:"0", fieldType:'products'},
            {value: "created_at", label: "Дата создания", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date',sort: true},
            {value: "updated_at", label: "Дата изменения", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date',sort: true},
            {value: "created_by", label: "Кем создан", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "owner_id", label: "Ответственный", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0",fieldType:'entity'},
        ]
    },
    mssubscribe: {
        title:'Подписчики',
        entity:'users',
        entityNumb:2,
        link:'/marketing/messengers/users/',
        addButText:false,
        addButLink:false,
        exportType: false,
        isExport: false,
        columnSettings: false,
        columnDel: [],
        getLink:'/ajax/bot/user',
        delAll:`/ajax/bot/user/delete-all`,
        expand:'account,type',
        extraExpand:'',
        thList:['Имя', 'Платформа'],
        bottomList: [
            {top:{type:'link',desc:null,val:'username'}},
            {top:{type:'text',desc:null,val:'platform'}},
            /*{top:{type:'status',desc:null,val:'type', from:'value'}},
            {top:{type:'email',desc:null,val:'email', from:'value'}},
            {top:{type:'phone',desc:'Тел',val:'mobile_phone', from:'phone'}, bottom:{type:'phone',desc:'Тел',val:'work_phone', from:'phone'}},*/
        ],
    },

    rfmAccount: {
        title:'RFM анализ контрагентов',
        entity:'contact',
        entityNumb:2,
        link:'/rfm/segment/',
        addButText:'Добавить сегмент',
        //addButLink:'RfmAccount',
        addOpenButton:'RfmAccount',
        exportType: false,
        isExport: false,
        columnSettings: false,
        columnDel: [],
        getLink:'/ajax/rfm/segment/',
        //delAll:`/ajax/rfm/segment/delete-all`,
        customFilter: '&filter[type][like]=account',
        expand:'type',
        extraExpand:'',
        thList:['Название', 'recency', 'frequency', 'monetary', 'ignore'],
        bottomList: [
            {top:{type:'open',desc:null,val:'name'}},
            {top:{type:'text',desc:null,val:'recency'}},
            {top:{type:'text',desc:null,val:'frequency'}},
            {top:{type:'text',desc:null,val:'monetary'}},
            {top:{type:'text',desc:null,val:'ignore'}},
        ],
    },

    rfmContact: {
        title:'RFM анализ контактов',
        entity:'contact',
        entityNumb:2,
        link:'/rfm/segment/',
        addButText:'Добавить сегмент',
        //addButLink:'RfmContact',
        addOpenButton:'RfmContact',
        exportType: false,
        isExport: false,
        columnSettings: false,
        columnDel: [],
        getLink:'/ajax/rfm/segment',
        //delAll:`/ajax/rfm/segment/delete-all`,
        expand:'type',
        customFilter: '&filter[type][like]=contact',
        extraExpand:'',
        thList:['Название', 'recency', 'frequency', 'monetary', 'ignore'],
        bottomList: [
            {top:{type:'open',desc:null,val:'name'}},
            {top:{type:'text',desc:null,val:'recency'}},
            {top:{type:'text',desc:null,val:'frequency'}},
            {top:{type:'text',desc:null,val:'monetary'}},
            {top:{type:'text',desc:null,val:'ignore'}},
        ],
    },


    smsSending: {
        title:'SMS рассылка',
        link:'/marketing/sms/',
        addButText:'Создать рассылку',
        //addButLink:'RfmContact',
        addOpenButtonModal:'SmsSending',
        exportType: false,
        isExport: false,
        columnSettings: false,
        columnDel: [],
        getLink:'/ajax/sms/',
        expand:'type',
        thList:['Название', 'Дата', 'Статус'],
        bottomList: [
            {top:{type:'link',desc:null,val:'name'}},
            {top:{type:'text',desc:null,val:'created_at'}},
            {top:{type:'text',desc:null,val:'broadcast_status'}},
        ],
    },

    messengersSending: {
        title:'Рассылка в мессенджерах',
        link:'/marketing/messengers/broadcast/',
        addButText:'Создать рассылку',
        //addButLink:'RfmContact',
        addOpenButtonModal:'MessengersSending',
        exportType: false,
        isExport: false,
        columnSettings: false,
        columnDel: [],
        getLink:'/ajax/bot/broadcast-history',
        expand:'type',
        deleteLink: "/bot/",
        thList:['Название', 'Дата', 'Статус'],
        bottomList: [
            {top:{type:'link',desc:null,val:'name'}},
            {top:{type:'text',desc:null,val:'created_at'}},
            {top:{type:'text',desc:null,val:'broadcast_status'}},
        ],
    },

    AddPlate: {
        title:'Шаблоны документов',
        link:'/settings/plate/',
        addButText:'Добавить шаблон',
        //addButLink:'RfmContact',
        addOpenButtonModal:'AddPlate',
        exportType: false,
        isExport: false,
        columnSettings: false,
        columnDel: [],
        getLink:'/ajax/plates/',
        deleteLink: "/plates/",
        expand:'type',
        thList:['Название'],
        bottomList: [
            {top:{type:'link',desc:null,val:'name'}},
            /*{top:{type:'text',desc:null,val:'created_at'}},
            {top:{type:'text',desc:null,val:'broadcast_status'}},*/
        ],
    },
    Expense: {
        title:'Расходы',
        entity:'expense',
        link:'/balance/f9374893-0fb6-4439-b336-18961cfd7a8e/history',
        addButText:'Добавить расход',
        entityNumb:31,
        analitics:"/expense/report",
        //addButLink:'RfmContact',
        addOpenButtonModal:'AddExpense',
        exportType: "expense",
        isExport: false,
        columnSettings: true,
        columnDel: ['name', 'amount', 'account_id', 'created_at', 'contact_id', 'tags','created_by', 'category', 'datetime'],
        getLink:'/ajax/balance/transaction/all',
        deleteLink: "/expense/",
        delAll:`/ajax/expense/delete-all`,
        expand:'category,currency,contact,account',
        extraExpand:'category,owner,lead,opportunity,order,project,case',
        thList:['Название', "Дата", "Кошелек", "Категория", "Клиент/Сотрудник"],
        bottomList: [
            {top:{type:'link',desc:null,val:'name'}, bottom:{type:'text',val:'amount', currency:true}},
            {top:{type:'text',desc:null,val:'datetime'}},
            {top:{type:'status',desc:null,val:'category', from: "value"}},
            {top:{type:'client',desc:'',val:'contact_id', from:'name'}},
        ],
        fArr:[
            {value: "id", label: "ID", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/expense?filter[name][like]=", filterLength:"9999",fieldType:'text'},
            {value: "name", label: "Название", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/expense?filter[name][like]=", filterLength:"2",fieldType:'text'},
            {value: "amount", label: "Сумма", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/expense?filter[name][like]=", filterLength:"99999",fieldType:'number'},
            {value: "currency_id", label: "Валюта", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/lookup/search?type=currency&value=", filterLength:"0",fieldType:'lookup'},
            {value: "payment_method_id", label: "Способ оплаты", filterField: "autoselect", filterSearch:'value', filterLink:"/ajax/lookup/search?type=payment_method&value=", filterLength:"0",fieldType:'lookup'},
            {value: "category_id", label: "Категория", filterField: "autoselect", filterSearch:'value', filterLink:"/ajax/lookup/search?type=expense_category&value=", filterLength:"0",fieldType:'lookup'},
            {value: "account_id", label: "Контрагент", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/account?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "contact_id", label: "Контакт", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/contact?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "lead_id", label: "Лид", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/lead?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "opportunity_id", label: "Сделка", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/opportunity?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "order_id", label: "Заказ", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/order?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "project_id", label: "Проект", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/project?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "case_id", label: "Обращение", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/case?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "tags", label: "Теги", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/expense/suggest?term=", filterLength:"0",fieldType:'tags'},
            {value: "created_at", label: "Дата создания", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date'},
            {value: "updated_at", label: "Дата изменения", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date'},
            {value: "created_by", label: "Кем создан", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "owner_id", label: "Ответственный", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/settings/user?filter[name][like]=", filterLength:"0",fieldType:'entity'}
        ]
    },


    segments: {
        title:'Добавить сегмент',
        entity:'segment',
        entityNumb:2,
        link:'/settings/segment/',
        addButText:'Добавить сегмент',
        addButLink:'Segment',
        exportType: false,
        isExport: false,
        columnSettings: false,
        columnDel: [],
        getLink:'/ajax/settings/segment',
        delAll:`/ajax/settings/segment/delete-all`,
        expand:'',
        //customFilter: '&filter[type][like]=contact',
        extraExpand:'',
        thList:['Название сегмента', 'К-во пользователей'],
        bottomList: [
            {top:{type:'link',desc:null,val:'name'}},
            {top:{type:'text',desc:null,val:'usersSegmented'}},
        ],
    },


    rules: {
        title:'Бизнес правила',
        entity:'businessrule',
        link:'/businessrule/',
        addButText:'Добавить правило',
        //addButLink:'RfmContact',
        addOpenButtonModal:'AddRule',
        exportType: false,
        isExport: false,
        columnSettings: false,
        columnDel: [],
        getLink:'/ajax/businessrule',
        delAll:`/ajax/businessrule/delete-all`,
        expand:'',
        docs: "https://sboxcrm.com/docs/b-rules",
        //customFilter: '&filter[type][like]=contact',
        extraExpand:'',
        thList:['Название правила'],
        bottomList: [
            {top:{type:'link',desc:null,val:'name'}},
        ],
    },


    emailForms: {
        title:'Создать форму',
        entity:'segment',
        entityNumb:2,
        link:'/marketing/forms/',
        addButText:'Создать форму',
        //addButLink:'emailForms',
        addOpenButtonModal:'emailForms',
        exportType: false,
        isExport: false,
        columnSettings: false,
        columnDel: [],
        getLink:'/ajax/mail/broadcast/form',
        deleteLink: "/mail/broadcast/form/",
        //delAll:`/ajax/settings/segment/delete-all`,
        expand:'',
        //customFilter: '&filter[type][like]=contact',
        extraExpand:'',
        thList:['Название формы'],
        bottomList: [
            {top:{type:'link',desc:null,val:'name'}},
            //{top:{type:'text',desc:null,val:'usersSegmented'}},
        ],
    },

    emailCampaigns: {
        title:'Создать форму',
        entity:'segment',
        entityNumb:2,
        link:'/marketing/mail/campaigns/',
        addButText:'Создать список',
        //addButLink:'emailForms',
        addOpenButtonModal:'emailCampaigns',
        exportType: false,
        isExport: false,
        columnSettings: false,
        columnDel: [],
        getLink:'/ajax/mail/broadcast/campaign',
        deleteLink: "/mail/broadcast/campaign/",
        //delAll:`/ajax/settings/segment/delete-all`,
        expand:'',
        //customFilter: '&filter[type][like]=contact',
        extraExpand:'',
        thList:['Название списка'],
        bottomList: [
            {top:{type:'link',desc:null,val:'name'}},
            //{top:{type:'text',desc:null,val:'usersSegmented'}},
        ],
    },

    emailSubscriber: {
        title:'Подписчики',
        entity:'segment',
        entityNumb:2,
        link:'/marketing/mail/campaigns/',
        addButText:'Создать список',
        //addButLink:'emailForms',
        addOpenButtonModal:'emailCampaigns',
        exportType: false,
        isExport: false,
        columnSettings: false,
        columnDel: [],
        getLink:'/ajax/mail/broadcast/campaign',
        deleteLink: "/mail/broadcast/campaign/",
        //delAll:`/ajax/settings/segment/delete-all`,
        expand:'',
        //customFilter: '&filter[type][like]=contact',
        extraExpand:'',
        thList:['Название списка'],
        bottomList: [
            {top:{type:'link',desc:null,val:'name'}},
            //{top:{type:'text',desc:null,val:'usersSegmented'}},
        ],
    },
    emailBroadcast: {
        title:'Email рассылки',
        entity:'segment',
        entityNumb:2,
        link:'/marketing/mail/broadcast/',
        addButText:'Создать рассылку',
        //addButLink:'emailForms',
        addOpenButtonModal:'emailBroadcast',
        exportType: false,
        isExport: false,
        columnSettings: false,
        columnDel: [],
        getLink:'/ajax/mail/broadcast',
        deleteLink: "/mail/broadcast/",
        //delAll:`/ajax/settings/segment/delete-all`,
        expand:'',
        //customFilter: '&filter[type][like]=contact',
        extraExpand:'',
        thList:['Название рассылки', "Дата рассылки", "К-во писем"],
        bottomList: [
            {top:{type:'link',desc:null,val:'name'}},
            {top:{type:'text',desc:null,val:'created_at'}},
            {top:{type:'text',desc:null,val:'sent_count'}},
            //{top:{type:'text',desc:null,val:'usersSegmented'}},
        ],
    },
    stockAnalytics: {
        title:'',
        entity:'segment',
        entityNumb:15,
        link:'/marketing/mail/broadcast/',
        //addButText:'Создать рассылку',
        //addButLink:'emailForms',
        //addOpenButtonModal:'emailBroadcast',
        exportType: "turnover",
        isExport: false,
        columnSettings: false,
        columnDel: [],
        getLink:'/ajax/stock/analytics',
        //deleteLink: "/mail/broadcast/",
        //delAll:`/ajax/settings/segment/delete-all`,
        expand:'',
        //customFilter: '&filter[type][like]=contact',
        extraExpand:'',
        thList:['Название', "Отгрузки", "Приходы", "Списания", "Возвраты", "Перемещения со склада","Перемещение на склад"],
        bottomList: [
            {top:{type:'openButton',desc:'',val:'product', from:'name'}},
            {top:{type:'text',desc:null,val:'shipmentsCount'}},
            {top:{type:'text',desc:null,val:'replanishCount'}},
            {top:{type:'text',desc:null,val:'consumptionCount'}},
            {top:{type:'text',desc:null,val:'providerReturnCount'}},
            {top:{type:'text',desc:null,val:'transferCountFrom'}},
            {top:{type:'text',desc:null,val:'transferCount'}},
            /*{top:{type:'text',desc:null,val:'balance'}},*/
            //{top:{type:'text',desc:null,val:'usersSegmented'}},
        ],
        fArr:[
            {value: "name", label: "Название", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/product?filter[name][like]=", filterLength:"2",fieldType:'text'},
            {value: "stock_id", label: "Склад", filterField: "autoselect", filterSearch:'name', filterLink:"/ajax/stock?filter[name][like]=", filterLength:"0",fieldType:'entity'},
            {value: "completed_at", label: "Дата", filterField: "date", filterSearch:'description', filterLink:"", filterLength:"9999",fieldType:'date'},
        ]
    },
    workorder: {
        title:'',
        entity:'segment',
        entityNumb:2,
        link:'/stock/workorder/',
        addButText:'Создать заказ на производство',
        //addButLink:'emailForms',
        addOpenButtonModal:'workorder',
        stock: true,
        exportType: false,
        isExport: false,
        columnSettings: false,
        columnDel: [],
        getLink:'/ajax/workorder',
        deleteLink: "/workorder/",
        //delAll:`/ajax/settings/segment/delete-all`,
        expand:'',
        //customFilter: '&filter[type][like]=contact',
        extraExpand:'',
        thList:['Название', "Статус", "Дата создания"],
        bottomList: [
            {top:{type:'link',desc:null,val:'name'}},
            {top:{type:'obj',desc:'',val:'status', from:'value'}},
            {top:{type:'text',desc:'',val:'created_at'}}
            //{top:{type:'text',desc:null,val:'usersSegmented'}},
        ],
    },
};
