import myaxios from "../../components/Axios";

type Watcher = {
    userId: number,
    entity: string,
    entityId: number
}

export const subscribeWatcherService = ({ userId, entity, entityId }: Watcher) =>
    myaxios.post(`/ajax/${entity}/${entityId}/watch/${userId}`)

export const unsubscribeWatcherService = ({ userId, entity, entityId }: Watcher) =>
    myaxios.delete(`/ajax/${entity}/${entityId}/unwatch/${userId}`)