import React from 'react';

const AllEmailBox = () => {
  return <form className="sbform fltr">
      <div className="rmod-header">
          <b>Почтовые ящики</b>
      </div>
      <div className="mod-cont">
          <table className="noshadow" style={{width: "100%"}}>
              <thead>
              <tr className="fst-tr">
                  <td>Почта:</td>
                  <td>Статус</td>
                  <td style={{width: "50px"}} />
              </tr>
              </thead>
              <tbody>
              <tr className="pull-up">
                  <td>
                      <a href="#" className="os">info@website.com</a>
                  </td>
                  <td><span className="label-st">Включено</span></td>
                  <td>
                      <div className="toogle-bottom fl-r">
                          <a className="is" href="#"><i className="flaticon-more" /></a>
                          <ul className="toogle-list">
                              <li><a href="#"><i className="flaticon-edit-1" /> <b>Редактировать</b></a></li>
                              <li><a className="table-del" href="#"><i className="flaticon-delete-1" /> <b>Удалить</b></a></li>
                          </ul>
                      </div>
                  </td>
              </tr>
              <tr className="pull-up">
                  <td>
                      <a href="#" className="os">info@website.com</a>
                  </td>
                  <td><span className="label-st">Включено</span></td>
                  <td>
                      <div className="toogle-bottom fl-r">
                          <a className="is" href="#"><i className="flaticon-more" /></a>
                          <ul className="toogle-list">
                              <li><a href="#"><i className="flaticon-edit-1" /> <b>Редактировать</b></a></li>
                              <li><a className="table-del" href="#"><i className="flaticon-delete-1" /> <b>Удалить</b></a></li>
                          </ul>
                      </div>
                  </td>
              </tr>
              <tr className="pull-up">
                  <td>
                      <a href="#" className="os">info@website.com</a>
                  </td>
                  <td><span className="label-st">Включено</span></td>
                  <td>
                      <div className="toogle-bottom fl-r">
                          <a className="is" href="#"><i className="flaticon-more" /></a>
                          <ul className="toogle-list">
                              <li><a href="#"><i className="flaticon-edit-1" /> <b>Редактировать</b></a></li>
                              <li><a className="table-del" href="#"><i className="flaticon-delete-1" /> <b>Удалить</b></a></li>
                          </ul>
                      </div>
                  </td>
              </tr>
              </tbody>
          </table>
          <a className="btn-m mt10" href="#">Добавить ящик</a>
      </div>
  </form>
};

export default AllEmailBox;