import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

const ConvertedLead = props => {
    return <div>
        <div className="connection"><span>Лиды</span></div>
        <div className="sp-table body-table">
            <table style={{borderSpacing: "0"}} className="stagesTable">
                <thead />
                <tbody>
                <tr>
                    <td>{props.lead && <a onClick={() => props.history.push('/lead/' + props.lead.id)}>{props.lead.name}</a>}</td>
                    <td>{props.lead && props.lead.type ? props.lead.type.value : null}</td>
                    <td>{props.lead && props.lead.status ? props.lead.status.value : null}</td>
                </tr>
                </tbody>
            </table></div>
    </div>
}

export default withRouter(ConvertedLead);