import React, {useEffect, useState} from "react";
import myaxios from "../../components/Axios";
import {FeedPost} from "./FeedItems";
import NewFeed from "./NewFeed";
import Input from "../../components/UI/Input";
import Notif from "../../components/Notif";
import {CSSTransition, TransitionGroup} from "react-transition-group";

const FeedList = ({ history }) => {
    const [state, setMyState] = useState({
        items:[],
        todayTasks:[],
        endDateTasks: [],
        loading:false,
        open: false,
        subject:""
    });
    const setState = (st, func) => {
        Object.keys(st).map((key) => { state[key] = st[key]});
        setMyState(prevState => { return {...prevState, ...state} });
        if(func) func();
    };
    useEffect(() => {
        getData()
    },[])

    const getData = () => {
        myaxios.get(`/ajax/feed`).then(res => {
            setState({
                items: res.data.items
            }, getTodayTask)
        })
    }

    const getTodayTask = () => {
        /*myaxios.get(`/ajax/task/kanban-by-periods?per-page=10&filter[status_id][nin][]=1`).then(res => {
            setState({
                todayTasks:res.data.items.find(el => el.id === "3").items,
                endDateTasks: res.data.items.find(el => el.id === "2").items,
            })
        })*/
    }

    const postItem = (title, content) => {
        myaxios.post(`/ajax/feed`, {
            title: title,
            content: content
        }).then(res => {
            if(res.status === 200 || res.status === 201){
                setState({ open: false });
                getData()
            }
        })
    }

    const toDate = (id, start_filter = "00:00:00", end_filter = "00:00:00") => {
        let nowDate = new Date();
        let useDate, result;
        useDate = new Date(nowDate.getTime() + (86400000 * Number(id)))
        result = `${useDate.getFullYear() % 10000}-${useDate.getMonth() + 1}-${useDate.getDate()} ${start_filter}`
        return result
    }

    const addTodayTask = (e) => {

        const switchDate = (st) => {
            switch (String(st)) {
                case "1": return {start_date: null, end_date: null};
                case "3": return {start_date: toDate(0), end_date: toDate(1)};
                case "4": return {start_date: toDate(1), end_date: toDate(2)};
                case "5": return {start_date: toDate(2), end_date: toDate(3)};
            }
        }

        if(e.keyCode === 13 && state.subject.length > 0){
            myaxios.post(`/ajax/task`, {
                subject: state.subject,
                ...switchDate("3")
            }).then(res => {
                if(res.status === 200 || res.status === 201){
                    Notif("save", "Задача успешно добавлена!")
                    const todayTasks = state.todayTasks;
                    todayTasks.unshift(res.data)
                    setState({ todayTasks, subject: "" })
                }
            })
        }
    }

    const delItem = id => {
        myaxios.delete(`/ajax/feed/${id}`).then(res => {
            if(res.status === 200 || res.status === 201 || res.status === 204){
                getData()
            }
        })
    }

    const deleteTaskItem = (id) => {
        if(!window.confirm("Удалить задачу?")) return;

        myaxios.delete(`/ajax/task/${id}`).then(res => {
            if(res.status === 200 || res.status === 201 || res.status === 204){
                Notif("delete", "Задача удалена!")
                getTodayTask()
            }
        })
    }

    const successTask = (item) => {
        if(!window.confirm("Завершить задачу?")) return;

        myaxios.put(`/ajax/task/${item.id}`, {
            subject: item.subject,
            status_id: "1"
        }).then(res => {
            if(res.status === 200 || res.status === 201 || res.status === 204){
                Notif("save", "Задача успешно завершена!")
                getTodayTask()
            }
        })
    }

    return (
        <div className="page feed">
            <div className="rc8">
                <button
                    onClick={() => setState({ open: true })}
                    className="success btni mr10">
                    <span>Создать новость</span>
                </button>
                <div className="feed_list">
                    {
                        state.items.map(item => (
                            <FeedPost key={item.id} {...item} />
                        ))
                    }
                </div>
            </div>
            <div className="rc4" style={{ marginTop: "50px" }}>
                {/*<button className="success btni mr10" style={{ width:"100%" }}>Добавить задачу</button>*/}
                {
                    //console.log("1321", state.todayTasks, state.endDateTasks)
                }
                <div className="left-heading-success">Задачи на сегодня</div>
                <div className="feed-right-body">
                    <TransitionGroup className="transition-group-screen">
                    {
                        state.todayTasks && state.todayTasks.map(item => (
                            <CSSTransition key={item.id} timeout={200} classNames="slide">
                                <div key={item.id} className="feed-right-body-item">
                                    <div onClick={() => successTask(item)} className="feed-right-body-item-checkbox" />
                                    <div className="feed-right-body-item-text">
                                        <span onClick={() => history.push(`/task/view/${item.id}`)}>{item.subject}</span>
                                        <em>{item.end_date}</em>
                                    </div>
                                    <div onClick={() => deleteTaskItem(item.id)} className="feed-right-body-item-actions">
                                        <i className="flaticon-delete-2" />
                                    </div>
                                </div>
                            </CSSTransition>
                        ))
                    }
                    </TransitionGroup>
                    <Input
                        className="feed-right-body-item-input"
                        value={state.subject}
                        onChange={e => setState({ subject: e.target.value })}
                        placeholder="Название задачи"
                        onKeyDown={(e) => addTodayTask(e)}
                    />
                </div>

                <div className="left-heading-success left-heading-end">Просроченные задачи</div>
                <div className="feed-right-body">
                    <TransitionGroup className="transition-group-screen">
                    {
                        state.endDateTasks && state.endDateTasks.map(item => (
                            <CSSTransition key={item.id} timeout={200} classNames="slide">
                                <div key={item.id} className="feed-right-body-item">
                                    <div onClick={() => successTask(item)} className="feed-right-body-item-checkbox" />
                                    <div className="feed-right-body-item-text">
                                        <span onClick={() => history.push(`/task/view/${item.id}`)}>{item.subject}</span>
                                        <em>{item.end_date}</em>
                                    </div>
                                    <div onClick={() => deleteTaskItem(item.id)} className="feed-right-body-item-actions">
                                        <i className="flaticon-delete-2" />
                                    </div>
                                </div>
                            </CSSTransition>
                        ))
                    }
                    </TransitionGroup>
                </div>

            </div>

            <NewFeed
                open={state.open}
                close={() => setState({ open: false })}
                save={postItem}
            />
        </div>
    )
}

export default FeedList;