import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';

const Expense = props => {
    return <div className="sp-table body-table">
        <table style={{borderSpacing: "0"}} className="stagesTable">
            <thead>
            {
                (props.item.length >= 1) ? <tr>
                    <td>Название</td>
                    <td>Сумма</td>
                    <td>Категория</td>
                    <td>Дата</td>
                    <td style={{width: "30px"}}/>
                </tr> : null
            }

            </thead>
            <tbody>
            {
                props.item.map((item,index)=>
                    <tr key={index}>
                        <td><a onClick={() => props.history.push('/expense/' + item.id)}>{item.name}</a></td>
                        <td>{item.amount} {item.currency && item.currency.text_symbol}</td>
                        <td>{item.category && item.category.value}</td>
                        <td>{item.datetime}</td>
                        <td>
                            <div className="toogle-bottom fl-r">
                                <a className="is" href="#"><i className="flaticon-more" /></a>
                                <ul className="toogle-list">
                                    <li><a onClick={() => props.history.push('/expense/' + item.id)}><i className="flaticon-edit-1" /> <b>Редактировать</b></a></li>
                                    <li><a onClick={()=>props.deleteObject("expense", item.id)} className="table-del"><i className="flaticon-delete-1" /> <b>Удалить</b></a></li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                )
            }
            </tbody>
        </table>
    </div>
}

export default withRouter(Expense);