import React, {useEffect, useState} from "react";
import saveIcon from '../../fonts/icons/save.svg'
import Input from "../UI/Input";
import Myselect from "../UI/Select";
import DateTime from "../UI/DateTime";
import ModAutoSelect from "../UI/ModAutoSelect";
import {CSSTransition} from "react-transition-group";
import {entity} from "../ListComponentEntity";

const SingleSearch = (props) => {
    const {filter} = props.state
    useEffect(() => {
        if(!props.state.filter[0]){
            props.addSingleFilter()
        }
    }, [filter])

    return props.state.filter[0] ? <SingleSearchComponent {...props} /> : <div />
}

const SingleSearchComponent = ({ entity, state, setFilter, saveFilter, operator }) =>  {
    const [isOpen, setOpen] = useState(false)
    const [touch, setTouch] = useState(false)
    const [loading, setLoading] = useState(false)
    let item = state.filter[0]
    let element = entity.find(field => item.field === field.value) || {}

    const setField = (type, value) => {
        const filter = state.filter
        filter[0][type] = value
        setFilter(filter)
        setTouch(true)
    }

    useEffect(() => {
        setLoading(false)
    }, [])

    return loading ? <div /> : (
        <div className="single-search" style={{ float: "right" }}>
            <span onClick={() => setOpen(!isOpen)} className="single-search-label">Поиск по: { element.label }</span>
            <div className="single-search-container sbform">
                <FilterItem
                    item={item}
                    setField={setField}
                    entity={entity}
                    field={item.field}
                    saveFilter={saveFilter}
                    setTouch={setTouch}
                    state={state}
                />
                {/*<i onClick={setOpen} className="flaticon-interface-2" />*/}
                {touch && <i onClick={() => {
                    setOpen(false)
                    setTouch(false)
                    saveFilter()
                }} className="flaticon-search" />}
            </div>
            <CSSTransition in={isOpen} timeout={200} classNames="my-node" unmountOnExit>
            <ul className="single-search-sub-items">
                {
                    state.adField && state.adField.map((item, index) => (
                        <li key={item.value}>
                            <a onClick={() => {
                                const filter = state.filter
                                filter[0].field = item.value
                                filter[0].fieldType = state.adField.find(field => field.value === item.value).fieldType
                                filter[0].value = "";
                                filter[0].obj = null;
                                filter[0].operator = operator[state.adField.find(field => field.value === item.value).fieldType][0].value
                                setFilter(filter)
                                setOpen(false)
                                setTouch(true)
                                /*setField("field", item.value);
                                setField("fieldType", element.fieldType);
                                setField("value", "");
                                setField("obj", null);
                                setField("operator", operator[element.fieldType][0].value);*/
                            }}>
                                {item.label}
                            </a>
                        </li>
                    ))
                }
            </ul>
            </CSSTransition>
        </div>
    )
}

export default SingleSearch

const FilterItem = ({ item, setField, entity, field, saveFilter, setTouch, state }) => {
    const {fieldType, value, obj} = item
    const fieldObject = state.adField.find(el => field === el.value)

    if(fieldType === "text" || fieldType === "number"){
        return <Input
            value={value}
            type={fieldType}
            onChange={e => setField('value', e.target.value)}
            valid
        />
    } else if(fieldType === "select") {
        return (
            <Myselect
                empty
                value={value}
                options={fieldObject.options}
                onChange={e => setField('value', e.target.value)}
                val="id"
                lab="label"
            />
        )
    } else if(fieldType === "date"){
        return (
            <DateTime
                value={value}
                valid={true}
                isClearable={false}
                handleChange={e => {
                    (e) ? setField('value', formatDate(e)) : setField('value', '')
                }}
                dateFormat="dd-MMMM-yyyy"
                todayButton
            />
        )
    } else if(fieldType === "lookup" || fieldType === "entity" || fieldType === "tags" || fieldType === "products"){
        return (
            <ModAutoSelect
                link={fieldObject.filterLink}
                minLength={fieldObject.filterLength}
                result={fieldObject.filterSearch}
                event="status"
                valid={true}
                shouldValidate={false}
                addType="status"
                entity={obj}
                entityName={fieldObject.filterSearch}
                updateData={(id, arr, par2) => {
                    setField("value", id);
                    setField("obj", arr);
                }}
                unLink={() => {
                    setField("value", '');
                    setField("obj", null);
                }}
                placeholder="Выберите значение"
            />
        )
    } else {
        return false
    }
}

function formatDate(date){
    if(date !== null) {
        let dd = date.getDate();
        if (dd < 10) dd = '0' + dd;
        let mm = date.getMonth() + 1;
        if (mm < 10) mm = '0' + mm;
        let yy = date.getFullYear() % 10000;
        if (yy < 10) yy = '0' + yy;
        return yy + '-' + mm + '-' + dd;
    }
};

/*
const FilterFieldsType = (props) => {
    switch (props.type) {
        case "text": return (
            <Input
                type="text"
                value={props.value}
                onChange={e => props.setField('value', e.target.value)}
                shouldValidate={true}
                valid={true}
            />
        );

        case "select": return (
            <Myselect
                empty
                value={props.value}
                options={props.options}
                onChange={e => props.setField('value', e.target.value)}
                val="id"
                lab="label"
            />
        );

        case "date": return (
            <DateTime
                value={props.value}
                valid={true}
                isClearable={false}
                handleChange={e => {
                    (e) ? props.setField('value', formatDate(e)) : props.setField('value', '')
                }}
                dateFormat="dd-MMMM-yyyy"
                todayButton
            />
        );

        default: return (
            <ModAutoSelect
                link={props.fields[searchIndex(props.fields, props.field)].filterLink}
                minLength={props.fields[searchIndex(props.fields, props.field)].filterLength}
                result={props.fields[searchIndex(props.fields, props.field)].filterSearch}
                event="status"
                valid={props.obj}
                shouldValidate={false}
                addType="status"
                entity={props.obj}
                entityName={props.fields[searchIndex(props.fields, props.field)].filterSearch}
                updateData={(id, arr, par2) => props.updData(props.index, id, arr)}
                unLink={() => props.unLink()}
                placeholder="Выберите значение"
            />
        )
    }
}*/
