import React, {Component} from 'react';
import Textarea from '../../UI/Textarea';
import Input from '../../UI/Input';
import ModAutoSelect from '../../UI/ModAutoSelect';
import formValid from '../../UI/formValid';
import Myselect from '../../UI/Select';
import myaxios from '../../Axios';

function getUserId(id) {
    switch(id){
        case "opportunity": return "opportunity_id";
        case "lead": return "lead_id";
        case "project": return "project_id";
        default: return "related_id";
    }
}

function getTypeId(id) {
    switch(id){
        case "lead": return 55;
        case "project": return 56;
        case "opportunity": return 54;
        default: return 53;
    }
}

export default class CreateRelationUser extends Component {
    state = {
        formControl: {
            time_value:{value:0},
            name:{value:'',valid:true, validation:{required:true}},
            user_id:{value:'',valid:true, validation:{required:true}},
            role:{value:''},
            description:{value:''},
        },
        is_scheduled:false,
        conditions: [],
        id:null
    };
    componentWillMount(){
        if(this.props.elArr){
            console.log("this.props.elArr.metaData.data", this.props.elArr.metaData.data)
            let formControl = this.state.formControl;
            formControl.name.value = this.props.elArr.metaData ? this.props.elArr.name : '';
            formControl.user_id.value = this.props.elArr.metaData ? this.props.elArr.metaData.data.fields[2].value : '';
            formControl.role.value = this.props.elArr.metaData ? this.props.elArr.metaData.data.fields[3].value : '';
            formControl.description.value = this.props.elArr.metaData ? this.props.elArr.metaData.data.fields[4].value : '';
            this.setState({ formControl, id: this.props.elArr.id || null })
        }
    }
    setField = (type, e) => {
        let formControl = this.state.formControl;
        formControl[type].value = e;
        this.setState({ formControl })
    };
    isFormValid = () => {
        let formControl = this.state.formControl;
        let isFormValid = true;
        Object.keys(formControl).map((key, index) => {
            formControl[key].valid = formValid(formControl[key].value, formControl[key].validation);
            isFormValid = formControl[key].valid && isFormValid
        });
        this.setState({formControl});
        return isFormValid;
    };
    sendForm = () => {
        if(this.isFormValid()){
            if(this.props.elArr){
                setTimeout(
                    this.props.updateNode({
                        id:this.state.id,
                        name: this.state.formControl.name.value,
                        time_value: this.state.formControl.time_value.value,
                        is_scheduled: (this.state.formControl.time_value.value > 1),
                        metaData: {
                            data: {
                                recordType: getTypeId(this.props.entity),
                                fields: [
                                    {
                                        field: 'related_type',
                                        object: 'string',
                                        value: (this.props.entity == 23 || this.props.entity == 1 || this.props.entity == 6) ? null : this.props.entityId,
                                    },
                                    {
                                        field: getUserId(this.props.entity),
                                        object: 'reference',
                                        value: "id",
                                    },
                                    {
                                        field: 'user_id',
                                        object: 'string',
                                        value: this.state.formControl.user_id.value,
                                    },
                                    {
                                        field: 'role',
                                        object: 'string',
                                        value: this.state.formControl.role.value,
                                    },
                                    {
                                        field: 'description',
                                        object: 'string',
                                        value: this.state.formControl.description.value,
                                    },
                                ]
                            }
                        },
                        nodeType: "node",
                        type:"createRelation",
                    })
                )
            } else {
                setTimeout(
                    this.props.createNode({
                        name: this.state.formControl.name.value,
                        time_value: this.state.formControl.time_value.value,
                        is_scheduled: (this.state.formControl.time_value.value > 1),
                        metaData: {
                            data: {
                                recordType: getTypeId(this.props.entity),
                                fields: [
                                    {
                                        field: 'related_type',
                                        object: 'string',
                                        value: (this.props.entityId == 23 || this.props.entityId == 1 || this.props.entityId == 6) ? null : this.props.entityId,
                                    },
                                    {
                                        field: getUserId(this.props.entity),
                                        object: 'reference',
                                        value: "id",
                                    },
                                    {
                                        field: 'user_id',
                                        object: 'string',
                                        value: this.state.formControl.user_id.value,
                                    },
                                    {
                                        field: 'role',
                                        object: 'string',
                                        value: this.state.formControl.role.value,
                                    },
                                    {
                                        field: 'description',
                                        object: 'string',
                                        value: this.state.formControl.description.value,
                                    },
                                ]
                            }
                        },
                        nodeType: "node",
                        type:"createRelation",
                    })
                )
            }
        }
    };
    updateData = (id, arr, el, type) => {
        let formControl = this.state.formControl;
        if(type === "add"){
            formControl[el].arr = arr;
            formControl[el].value = id;
        } else {
            formControl[el].arr = null;
            formControl[el].value = '';
        }
        this.setState({ formControl })
    }
    updateDataRelated = (id, arr, addType, endParrans) => {
        const formControl = this.state.formControl;
        formControl[addType].value = id;
        formControl[addType].arr = arr;
        this.setState({ formControl, tch: false })
    };
    unLink = addType => {
        const formControl = this.state.formControl;
        formControl[addType].value = '';
        formControl[addType].arr = null;
        this.setState({ formControl, tch: false})
    }
    render(){
        return <div>
            <div className="rmod-header">
                <b>Добавить в команду</b>
                <a onClick={this.props.close} style={{ marginRight: "60px" }} className="mclose"><i className="flaticon-close" /></a>
                <div className="togglelist"><button onClick={this.sendForm} className="rmod-ok" disabled=""><i className="flaticon-interface-1" /></button></div>
            </div>
            <div className="mod-cont sbform">
                <Input
                    label={"Название действия"}
                    value={this.state.formControl.name.value}
                    valid={this.state.formControl.name.valid}
                    onChange={e => this.setField("name", e.target.value)}
                    shouldValidate={true}
                />
                <Input
                    label={"ID сотрудника"}
                    value={this.state.formControl.user_id.value}
                    valid={this.state.formControl.user_id.valid}
                    onChange={e => this.setField("user_id", e.target.value)}
                    shouldValidate={true}
                />
                <Input
                    label={"Роль"}
                    value={this.state.formControl.role.value}
                    onChange={e => this.setField("role", e.target.value)}
                    shouldValidate={false}
                />
                <Input
                    label={"Описание"}
                    value={this.state.formControl.description.value}
                    onChange={e => this.setField("description", e.target.value)}
                    shouldValidate={false}
                />
            </div>
        </div>
    }
}

function returnEntity(entity) {
    switch(entity){
        case "lead": return [
            {value:'product',label:'Продукт',related:true},
        ]; break;

        case "opportunity": return [
            {value:'order',label:'Заказ',related:true},
            {value:'account',label:'Контрагент',related:true},
            {value:'contact',label:'Контакт',related:true},
            {value:'invoice',label:'Счет',related:true},
            {value:'project',label:'Проект',related:true},
            {value:'product',label:'Продукт',related:true},
        ]; break;

        case "order": return [
            {value:'lead',label:'Лид',related:true},
            {value:'opportunity',label:'Сделка',related:true},
            {value:'account',label:'Контрагент',related:true},
            {value:'contact',label:'Контакт',related:true},
            {value:'invoice',label:'Счет',related:true},
            {value:'project',label:'Проект',related:true},
            {value:'product',label:'Продукт',related:true},
        ]; break;

        case "account": return [
            {value:'opportunity',label:'Сделка',related:true},
            {value:'order',label:'Заказ',related:true},
            {value:'contact',label:'Контакт',related:true},
            {value:'invoice',label:'Счет',related:true},
            {value:'project',label:'Проект',related:true},
        ]; break;

        case "contact": return [
            {value:'opportunity',label:'Сделка',related:true},
            {value:'order',label:'Заказ',related:true},
            {value:'account',label:'Контрагент',related:true},
            {value:'invoice',label:'Счет',related:true},
            {value:'project',label:'Проект',related:true},
        ]; break;

        case "invoice": return [
            {value:'opportunity',label:'Сделка',related:true},
            {value:'order',label:'Заказ',related:true},
            {value:'contact',label:'Контакт',related:true},
            {value:'project',label:'Проект',related:true},
            {value:'product',label:'Продукт',related:true},
        ]; break;

        case "project": return [
            {value:'opportunity',label:'Сделка',related:true},
            {value:'account',label:'Контрагент',related:true},
            {value:'contact',label:'Контакт',related:true},
            {value:'invoice',label:'Счет',related:true},
        ]; break;

    }
}