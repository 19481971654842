import React, {useContext, useRef, useState} from "react";
import {GlobalState} from "../../Layout/Layout";
import {useOnClickOutside} from "../hooks/useOnClickOutside";
import {subscribeWatcherService, unsubscribeWatcherService} from "../../services/other/watchers";
import Notif from "../Notif";
import {CSSTransition} from "react-transition-group";
import {ModalLoader} from "../UI/Loader";
import ModAutoSelect from "../UI/ModAutoSelect";

export const Viewers = ({ isWatching = false, items = [], entity, id, setWatchers }) => {
    const [loading, setLoading] = useState(false)
    const [viewersOpen, setViewersOpen] = useState(false)
    const [isUpdate, setIsUpdate] = useState(false)
    const { state } = useContext(GlobalState)
    const dropdownRef = useRef(null)
    useOnClickOutside({ ref: dropdownRef, handler: () => setViewersOpen(false) })

    const subscribeWatcher = async (item) => {
        setLoading(true)
        try {
            const { data, status } = await subscribeWatcherService({
                entity, entityId: id, userId: item.id
            })
            if(status === 201 && data.user_id) {
                setWatchers({
                    items: [...items, {user: item}],
                    isWatching: item.id === state.userId ? true : isWatching
                })
                Notif('save', 'Наблюдатель успешно добавлен')
            }
        } catch (e) { console.error(e) }

        setLoading(false)
    }
    const unsubscribeWatcher = async (user_id) => {
        if(!window.confirm('Удалить наблюдателя?')) {
            return false;
        }
        setLoading(true)

        try {
            const { status, data } = await unsubscribeWatcherService({
                entity, entityId: id, userId: user_id
            })
            if(status === 200 && data.items) {
                setWatchers({
                    isWatching: user_id === state.userId ? false : isWatching,
                    items: data.items
                })
                Notif('delete', 'Наблюдатель успешно удален')
            }
        } catch (e) { console.error(e) }

        setLoading(false)
    }

    if(!entity || !id) return <></>

    return (
        <div className={'viewers'} ref={dropdownRef}>
            <div className={'viewers-container'} onClick={() => setViewersOpen(!viewersOpen)}>
                <em>{items.length}</em>
                <i className="flaticon-medical" />
            </div>

            <CSSTransition in={viewersOpen} timeout={200} classNames="slide" unmountOnExit>
                <div className={'viewers-dropdown'}>
                    {loading && <ModalLoader contain />}
                    <div
                        onClick={() => isWatching ? unsubscribeWatcher(state.userId) : subscribeWatcher({ id: state.userId, name: 'Я' })}
                        className="viewers-is-view">
                        <i className="flaticon-medical" />
                        <span>{!isWatching ? 'Начать отслеживание' : 'Прекратить отслеживание'}</span>
                    </div>
                    <div className={'viewers-list'}>
                        {
                            items.length > 0 ? <div className={'viewers-list-data'}>
                                {
                                    items.map(({ user, id }) => (
                                        <div className={'new-task-status-item'}
                                             onClick={() => unsubscribeWatcher(user.id)}
                                             style={{ padding: '5px 8px', display: 'flex', alignItems: 'center' }}>
                                            <div className={'user-dropdown-current-min user-dropdown-current'}>
                                                {user && user.name && user.name.substr(0, 2)}
                                            </div>
                                            <div style={{ textTransform: 'capitalize', fontSize: '14px' }}>{user && user.name}</div>
                                        </div>
                                    ))
                                }
                            </div> : <div className="viewers-list-empty">
                                Запись никто не отслеживает
                            </div>
                        }
                    </div>
                    <div className="full-modal-right-hr" />
                    <div className={'viewers-update sbform'}>
                        <div className="viewers-is-view" style={{ border: 'none' }}>
                            <i className="flaticon-users" />
                            <span onClick={() => setIsUpdate(!isUpdate)}>Добавить пользователя</span>
                        </div>
                        <div>
                            {
                                isUpdate &&
                                <ModAutoSelect
                                    link="/ajax/settings/user?filter[name][like]="
                                    minLength="0"
                                    result="name"
                                    valid
                                    entity={null}
                                    entityName="name"
                                    updateData={(id, user) => subscribeWatcher(user)}
                                    unLink={() => {}}
                                />
                            }
                        </div>
                    </div>
                </div>
            </CSSTransition>
        </div>
    )
}