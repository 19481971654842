import React, {useState} from "react";
import Modal from "../components/Modal/Modal";
import ModAutoSelect from "../components/UI/ModAutoSelect";
import {FieldGen, isFormValid, setField, unLink, updateData} from "../components/fieldGen";
import myaxios from "../components/Axios";
import Notif from "../components/Notif";
import Loader from "../components/UI/Loader";

const CombineOrders = ({ isOpen, close, refresh, entity, entityId }) => {
    const [state, setMyState] = useState({
        formControl: {
            status_id: {
                type: 'select',
                label: 'Тип обьединения товаров',
                post: "number",
                value:'current',
                options:[
                    {value: "current", label: 'Количество товаров берем из текущей записи'},
                    {value: "both", label: 'Количество товаров суммируем'},
                    {value: "target", label: 'Количество товаров берем из записи, с которой соединяем'},
                ],
                valid: true,
                validation: {required: true},
            },
            order_id: {
                type: 'modAutoSelect',
                cls:'c12',
                label: 'С чем обьединяем',
                link: `/ajax/${entity}?filter[name][like]=`,
                value:'',
                result:'name',
                valid: true,
                entity:null,
                from:entity,
                entityName:"name",
                validation: {required: true},
            },
        },
        loading: false,
        valid: false
    })

    const setState = (st, func) => {
        Object.keys(st).map((key) => { state[key] = st[key]});
        setMyState(prevState => { return {...prevState, ...state} });
    };

    const postData = () => {
        if(+state.formControl.order_id.value === +entityId) return;
        if(!state.valid) return;

        setState({ loading: true })
        myaxios.post(`/ajax/${entity}/${entityId}/merge/${state.formControl.order_id.value}?mode=${state.formControl.status_id.value}`, {

        }).then(res => {
            if(!res) return;
            if(res.status === 200){
                Notif("save", "Заказы успешно объединены")
                refresh()
            }
            setState({ loading: false })
        }).catch(() => setState({ loading: false }))
    }

    return (
        state.loading ? <Loader contain /> :
         <Modal isOpen={isOpen} close={close} title="Обьединить заказы" save={() => setState(isFormValid(state), postData())}>
            <FieldGen
                arr={state.formControl}
                setField={(e, type) => setState({formControl: setField(state.formControl, type, e), tch: false})}
                updateData={(id, arr, type) => setState({ formControl: updateData(state.formControl,id, arr, type), tch: false })}
                unLink={(type) => setState({ formControl: unLink(state.formControl, type), tch: false})}
            />
        </Modal>
    )
}

export default CombineOrders