import React, {useContext, useEffect, useState} from "react";
import myaxios from "../Axios";
import {LoaderOld} from "../UI/Loader";

export const FixedBackgroundProcess = ({ updateBackgroundData }) => {
    const [loading, setLoading] = useState(true)
    const [hide, setHide] = useState(false)
    const [bgProcess, setBgProcess] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [firstLoading, setFirstLoading] = useState(false)

    const getAllProcess = async () => {
        const { status, data } = await myaxios.get(`/ajax/queue`)
        if(status === 200 && data) {
            setBgProcess(data.items)
            setLoading(false)
        }
    }

    const clearAllProcess = async () => {
      const { status, data } = await myaxios.post(`/ajax/queue/clear`)
        if(status === 200) {
            await getAllProcess()
        }
    }

    useEffect(() => {
        getAllProcess().then()

        setFirstLoading(true)
        if(firstLoading) setIsOpen(true)
    }, [updateBackgroundData])

    const exportStatuses = {
        '1': {label: 'Начался', color: ''},
        '2': {label: 'В процессе', color: ''},
        '3': {label: 'Завершен', color: ''},
        '4': {label: 'Ошибка', color: ''}
    }

    const BgItems = (item) => {
        switch (item.type) {
            case "export": return (
                <a href={'/ajax/file/download/' + item.file_id} target={'_blank'} className="timeline-item timeline-item-completed" style={{ display: 'block' }}>
                    <div className="timeline-media bg-light-primary">
                        <div className="svg-icon svg-icon-primary svg-icon-md">
                            <i className="flaticon-folder-3" />
                        </div>
                    </div>
                    <div className="timeline-desc timeline-desc-light-primary">
                        <span style={{ fontSize: '14px' }} className="font-weight-bolder text-primary">{item.filename || 'no name'}</span>
                        {item.status && <p className="font-weight-normal text-dark-50 pb-2" color={exportStatuses[item.status].color}>
                            {exportStatuses[item.status].label}</p>
                        }
                    </div>
                </a>
            )
            case "import": return (
                <div className="timeline-item timeline-item-disabled">
                    <div className="timeline-media bg-light-primary">
                        <div className="svg-icon svg-icon-primary svg-icon-md">
                            <i className="flaticon-folder-2" />
                        </div>
                    </div>
                    <div className="timeline-desc timeline-desc-light-primary">
                        <span style={{ fontSize: '14px' }} className="font-weight-bolder text-primary">{item.filename || 'no name'}</span>
                        {item.status && <p className="font-weight-normal text-dark-50 pb-2" color={exportStatuses[item.status].color}>
                            {exportStatuses[item.status].label}</p>
                        }
                    </div>
                </div>
            )
            default: return (<></>)
        }
    }

    if(!isOpen) {
        return <></>
    }

    return (
        <div className={'chat-item'} style={{ position: 'relative', height: hide ? '50px' : '' }}>
            {loading && <LoaderOld contain />}
            <div className="chat-item__header">
                <div className="chat-item__user">
                    <div className="chat-item__name">
                        <h4>Фоновые процессы</h4>
                    </div>
                </div>
                <div className="chat-item__buttons">
                    <div onClick={() => setHide(!hide)} className="chat-item__buttons-hide">_</div>
                    <div onClick={() => setIsOpen(false)} className="chat-item__buttons-close">&#10005;</div>
                </div>
            </div>
            {!hide && <div className="chat-item__body">
                {bgProcess.length > 0 && <button
                    style={{
                        background: 'linear-gradient(45deg, #80aeba 2%,#699499 46%,#669295 100%)',
                        border: 'none',
                        fontFamily: 'Montserrat-Light, sans-serif',
                        color: '#fff',
                        padding: '10px'
                    }}
                    onClick={clearAllProcess}
                >Очистить</button>}
                <div className="example-preview">
                    <div className="timeline timeline-5">
                        <div className="timeline-items">
                            {
                                bgProcess.map(item => BgItems(item))
                            }
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    )
}