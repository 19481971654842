import React from 'react';

const FilesItem = props => {
    return <tr className="pull-up">
        <td><a href={props.linkDownload}>{props.title}</a></td>
        <td>{props.type}</td>
        <td>{props.createDate}</td>
        <td>
            <div className="toogle-bottom fl-r">
                <a className="is" href="#"><i className="flaticon-more">{null}</i></a>
                <ul className="toogle-list">
                    <li><a href={props.linkDownload}><i className="flaticon-download">{null}</i> <b>Скачать</b></a></li>
                    <li><a onClick={() => props.del(props.id, props.index)} className="table-del" href={null}><i className="flaticon-delete-1">{null}</i> <b>Удалить</b></a></li>
                </ul>
            </div>
        </td>
    </tr>
};

export default FilesItem;
