import React, {Component} from 'react';
import googleIcon from '../../fonts/icons/google.svg'

export default class AddActions extends Component {
    render(){
        return <div>
            <div className="rmod-header">
                <b></b>
                <a onClick={this.props.close} style={{ marginRight: {/*"60px"*/} }} className="mclose"><i className="flaticon-close" /></a>
                {/*<div className="togglelist"><button className="rmod-ok" disabled=""><i className="flaticon-interface-1" /></button></div>*/}
            </div>
            <div className="mod-cont">
                {this.props.entityId !== 9 ? <div className="itemSourceCont">
                    <i className="flaticon-notes"/>
                    <h2>Добавить задачу</h2>
                    <a onClick={() => this.props.openEntity("r-open", "task")}>Добавить</a>
                </div> : null}
                {this.props.entityId !== 9 ? <div className="itemSourceCont">
                    <i className="flaticon-email"/>
                    <h2>Отправить Email</h2>
                    <a onClick={() => this.props.openEntity("r-open", "mail")}>Добавить</a>
                </div> : null}
                <div className="itemSourceCont">
                    <i className="flaticon-interface-10"/>
                    <h2>Редактировать теги</h2>
                    <a onClick={() => this.props.openEntity("r-open", "tags")}>Добавить</a>
                </div>
                <div className="itemSourceCont">
                    <i className="flaticon-interface-9"/>
                    <h2>Изменить поле</h2>
                    <a onClick={() => this.props.openEntity("r-open", "fields")}>Добавить</a>
                </div>
                <div className="itemSourceCont">
                    <i className="flaticon-music-2"/>
                    <h2>Уведомление</h2>
                    <a onClick={() => this.props.openEntity("r-open", "notification")}>Добавить</a>
                </div>
                {this.props.bpType !== "invocable" && this.props.entityId !== 9 ? <div className="itemSourceCont">
                    <i className="flaticon-file-1"/>
                    <h2>Добавить сущность</h2>
                    <a onClick={() => this.props.openEntity("r-open", "createRecord")}>Добавить</a>
                </div> : null}
                {this.props.entityId === 1 || this.props.entityId === 13 || this.props.entityId === 14 || this.props.entityId === 23 ? <div className="itemSourceCont">
                    <i className="flaticon-more-v4"/>
                    <h2>Добавить продукт</h2>
                    <a onClick={() => this.props.openEntity("r-open", "createRelation")}>Добавить</a>
                </div> : null}
                {(this.props.entityId !== 26 || this.props.entityId !== 9) && this.props.entityId !== 9 ? <div className="itemSourceCont">
                    <i className="flaticon-user-ok"/>
                    <h2>Добавить в команду</h2>
                    <a onClick={() => this.props.openEntity("r-open", "createRelationUser")}>Добавить</a>
                </div> : null}
                {this.props.entityId !== 9 ? <div className="itemSourceCont">
                    <i className="flaticon-speech-bubble-1"/>
                    <h2>Отправить SMS</h2>
                    <a onClick={() => this.props.openEntity("r-open", "sendSms")}>Добавить</a>
                </div> : null}
                {this.props.entityId !== 9 ? <div className="itemSourceCont">
                    <i className="flaticon-add"/>
                    <h2>Send Message (messengers)</h2>
                    <a onClick={() => this.props.openEntity("r-open", "sendMessenger")}>Добавить</a>
                </div> : null}
                {this.props.bpType !== "invocable" && this.props.entityId !== 9 ? <div className="itemSourceCont">
                    <i className="flaticon-background"/>
                    <h2>Вложенный процесс</h2>
                    <a onClick={() => this.props.openEntity("r-open", "process")}>Добавить</a>
                </div> : null}
                {/*{this.props.entityId !== 9 ? <div className="itemSourceCont">
                    <i className="flaticon-facebook-letter-logo"/>
                    <h2>Facebook Ads</h2>
                    <a onClick={() => this.props.openEntity("r-open", "fb_audience_add")}>Добавить</a>
                </div> : null}
                {this.props.entityId !== 9 ? <div className="itemSourceCont">
                    <img style={{ height: "43px", marginTop: "2px" }} src={googleIcon} />
                    <h2>Google Ads</h2>
                    <a onClick={() => this.props.openEntity("r-open", "gl_audience_add")}>Добавить</a>
                </div> : null}*/}
                <div className="itemSourceCont">
                    <i className="flaticon-internet"/>
                    <h2>HTTP Request</h2>
                    <a onClick={() => this.props.openEntity("r-open", "http_request")}>Добавить</a>
                </div>
                {this.props.entityId === 13 && <div className="itemSourceCont">
                    <i className="flaticon-coins"/>
                    <h2>Добавить платеж</h2>
                    <a onClick={() => this.props.openEntity("r-open", "createPayment")}>Добавить</a>
                </div>}
            </div>
        </div>
    }
}
