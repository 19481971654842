import React, {Component, Fragment} from 'react';
import Bgdrop from '../../../../Modal/Bgdrop';
import WrapModal from '../../../../Modal/WrapModal';
import ProductItem from './product-item';
import myaxios from '../../../../components/Axios';
import AutoSelect from '../../../../components/UI/AutoSelect';
import Notif from '../../../../components/Notif';
import Myselect from '../../../../components/UI/Select';

const opt = [
    {value: "name", label: "Название"},
    {value: 'model', label: "Артикул"}
]
class Product extends Component {
    state = {
        products: [],
        tieProduct: [],
        toggleButton: true,
        search: '',
        open: '',
        searchType: 'name'
    };
    updateProd = () => {
        const products = this.state.products;
        myaxios.get(`/ajax/${this.props.type}/${this.props.id}/products?per-page=100`, {
            method: 'get',
        }).then((response) => {
            for(let i = 0; i < response.data.items.length; i++){
                products[response.data.items[i].product_id] = response.data.items[i]
            }
            this.setState({ products });
        });
    }
    componentDidMount(){
        this.updateProd();
    }
    updateData = (id, arr) =>{
        myaxios.post(`/ajax/${this.props.type}/${this.props.id}/link-product`, {
            id: id,
        }).then((response) => {
            if(response.status === 200){
                this.updateProd()
                Notif("save", "Продукт успешно привязан")
            }
        })
    };
    deleteData = id => {
        if(window.confirm()) {
            myaxios.post(`/ajax/${this.props.type}/${this.props.id}/unlink-product`, {
                id: id,
            }).then((response) => {
                const products = this.state.products;
                delete products[id];
                Notif("delete");
                this.setState({ products })
            })
        }
    };
    saveItem = id => {
        console.log(id)
        myaxios.post(`/ajax/${this.props.type}/${this.props.id}/edit-product-item/${id}`, {
            price: this.state.products[id].price,
            discount: this.state.products[id].discount,
            quantity: this.state.products[id].quantity
        }).then((response) => {
            if(response.status === 200){
                Notif("save")
            }
        })
    };
    setPrice = (event, id) => {
        const products = this.state.products;
        products[id].price = event;
        this.setState({ products })
    }
    setField = (event, type) => {
        this.setState({ search: event.target.value })
    };
    setFl = (event, id, type) => {
        const products = this.state.products;
        if(type === "discount"){
            if(event.target.value >= 0 && event.target.value <= 100) {
                products[id][type] = event.target.value;
                this.setState({ products })
            } else {
                event.target.value = false
            }
        }
        products[id][type] = event.target.value;
        this.setState({ products })
    };
    searchType = (event) => {
        this.setState({ searchType: event.target.value })
    }
    createData = id => {
        this.setState({ open: 'r-open' })
    };
    popupClose = () => {
        this.setState({ open: '' })
    };
    saveProduct = (id) => {
        this.updateData(id);
        this.setState({ open: '' })
    };
    render(){
        return <div className="tab-content product-tab sbform">
            <div className="serch-product">
                <AutoSelect
                    placeholder="Поиск по продуктам"
                    link={`/ajax/product?filter[${this.state.searchType}][like]=`}
                    minLength="1"
                    result={this.state.searchType}
                    event="name"
                    setField={this.setField}
                    updateData={this.updateData}
                    value={this.state.search}
                    id={this.props.id}
                    createData={this.createData}
                    creatable
                />
            </div>
            <Myselect
                style={{ width: "250px", marginLeft: "0px", float:'left' }}
                options={opt}
                onChange={this.searchType}
                value={this.state.searchType}
            />
            <div className="producttable contact-page sp-table">
                <table>
                    <thead>
                    <tr className="fst-tr">
                        <td>Название</td>
                        <td>Кол-во</td>
                        <td>Скидка (%)</td>
                        <td>Сумма</td>
                        <td>Итого</td>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.state.products.map((product, index) =>
                            <ProductItem
                                key={index}
                                id={product.product_id}
                                name={(product.product) ? product.product.name : null}
                                quantity={product.quantity}
                                setField={this.setFl}
                                setPrice={this.setPrice}
                                discount={product.discount}
                                event={product.product_id}
                                sum={product.price}
                                totalPrice={product.price * (product.quantity * (1 - (product.discount * 0.01)))}
                                saveItem={this.saveItem}
                                delete={this.deleteData}
                            />
                        )
                    }
                    </tbody>
                </table>
            </div>


            <React.Fragment>
                <div className={"right-modal scrollbar " + this.state.open}><div>
                    {(this.state.open === "r-open") ? <WrapModal close={this.popupClose} saveProduct={this.saveProduct} name="Product" /> : null}
                </div></div>
                {this.state.open ? <Bgdrop close={this.popupClose} /> : null}
            </React.Fragment>
        </div>
    }};

export default Product;