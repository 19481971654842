import React, {useEffect, useState} from "react";
import Modal from "../../components/Modal/Modal";
import {GenerateForm} from "../../components/cardViewComponents/GenerateForm";
import {validationData} from "../../components/UI/formValid";
import {validationConfig} from "../../components/cardViewComponents/validationConfig";
import Notif from "../../components/Notif";
import { getProductPrepareService, createProductService } from "../../services/product/product";
import {createEvent} from "../../services/other/events";

const fieldsConfig = {
    category_id: {
        label: 'Категория',
        type: 'entity',
        link: "/ajax/lookup/search?type=category&value=",
        result: 'name',
        entity_from: 'category',
        entityName: 'name',
        className: 'w100',
        minLength: 0,
        validation: { required: true }, zone: 1
    },
    name: {
        label: 'Название',
        type: 'text',
        className: 'w100',
        validation: { required: true, maxLength: 255 }, zone: 1
    },
    sku: {
        label: 'Артикул',
        type: 'text',
        className: 'w100',
        validation: { required: true, maxLength: 255 }, zone: 1
    },
    base_price: {
        label: 'Базовая цена',
        type: 'number',
        className: 'w100',
        validation: { number: true, maxLength: 255 }, zone: 1
    },
    purchase_price: {
        label: 'Закупочная цена',
        type: 'number',
        className: 'w100',
        validation: { maxLength: 255 }, zone: 1
    },
    unit_id: {
        label: 'Единица измерения',
        type: 'select',
        options: [],
        className: 'w100',
        options_from: 'units',
        prepare: 'units', val: 'id', lab: 'value',
        validation: { required: true, maxLength: 255 }, zone: 1
    },
    currency_id: {
        label: 'Валюта',
        type: 'select',
        options: [],
        className: 'w100',
        options_from: 'currencies',
        prepare: 'currencies', val: 'id', lab: 'name',
        validation: { required: true, maxLength: 255 }, zone: 1
    },
}

export const ProductModal = ({ isOpen, close, history }) => {
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState({})
    const [disabled, setDisabled] = useState({})
    const [values, setValues] = useState({
        category_id: '',
        category: null,
        base_price: '',
        currency_id: '',
        currencies: [],
        sku: '',
        name: '',
        purchase_price: '',
        unit_id: null,
        units: [],
        tch: true
    })

    const getPrepare = async () => {
        const { status, data } = await getProductPrepareService()
        if((status === 200 || status === 201) && data) {
            setValues({
                ...values,
                units: data.units,
                unit_id: data.units[0].id,
                currencies: data.currencies,
                currency_id: data.currencies[0].id
            })
        }
        setLoading(false)
    }

    const saveItem = async () => {
        const errorsData = validationData(values, validationConfig(fieldsConfig));
        setErrors(errorsData)
        if(Object.keys(errorsData).length > 0) {
            Notif('required-fields')
            return false;
        }

        setLoading(true)
        const { category_id, base_price, currency_id, sku, name, purchase_price, unit_id } = values
        const { status, data } = await createProductService({ category_id, base_price, currency_id, sku, name, purchase_price, unit_id })
        if((status === 200 || status === 201) && data.id) {
            history.push(`/product/${data.id}`)
            createEvent({ user_id: window.location.host, user_name: "---", event_type: "createProduct" }).then()
        }
        setLoading(false)
    }

    useEffect(() => {
        getPrepare().then()
    }, [])

    const changeFields = (key, value, item) => {
        let obj = {}
        if(item) {
            if(item === 'clear') obj[fieldsConfig[key].entity_from] = null
            else obj[fieldsConfig[key].entity_from] = item
        }

        setValues({ ...values, ...obj, [key]: item ? item.id : value, tch: false })
    }

    return (
        <div>
            <Modal isOpen={isOpen} save={saveItem} close={close} loading={loading} showLeaveModal tch={values.tch} title={'Создать продукт'}>
                <GenerateForm
                    config={fieldsConfig}
                    { ...{ values, errors, disabled } }
                    onChange={changeFields}
                    onSaveData={saveItem}
                />
            </Modal>
        </div>

    )
}