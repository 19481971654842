import React, {Component} from 'react';
import myaxios from '../../Axios';
import Input from '../../UI/Input';
import formValid from '../../UI/formValid';
import { Picker } from 'emoji-mart';
import '../../../../node_modules/emoji-mart/css/emoji-mart.css';
import Loader from '../../../components/UI/Loader';
import Notif from '../../../components/Notif';
import Myselect from '../../../components/UI/Select';
import Components from './AllModalComponents'
import {withRouter} from "react-router-dom";

class MainModal extends Component {
    state = {
        formControl: {},
        tch: true,
        loading: true,
        filter:[],
        questions:[],
        attributes:[],
        slugs:[],
        headers: [],
        params:[]
    };
    componentDidMount(){
        //this.setState({ formControl: JSON.parse(JSON.stringify(Components[this.props.name].formControl)) })
        this.getPrepare();

        let slugs = [];
        if(this.props.order){
            this.props.slugs.map(item => {
                console.log("orders", item.order, this.props.order);
                if(item.order < this.props.order){
                    slugs.push({ value: `{{${item.slug}.parsed_answer}}`, label:`Вопрос: ${item.label}` })
                }
            })
        } else {
            this.props.slugs.map(item => {
                slugs.push({ value: `{{${item.slug}.parsed_answer}}`, label:`Вопрос: ${item.label}` })
            })
        }
        this.props.attr.map(item => {
            slugs.push({value:`{{trigger.attributes__${item.name}}}`, label:`Атрибут: ${item.name}`})
        });
        slugs.push({value:"{{trigger.message}}",label:'Сообщение'});
        slugs.push({value:"{{trigger.bot_platform}}",label:'Платформа'});
        slugs.unshift({value:"{{trigger.user_id}}",label:'User ID'});
        slugs.unshift({value:"{{trigger.user_name}}",label:'Имя'});
        this.setState({ slugs: slugs, filter: this.props.filters || [] });


        const formControl = JSON.parse(JSON.stringify(Components[this.props.name].formControl));
        if(formControl.to) formControl.to.options = this.props.attr;
        /*cObject.keys(formControl).map((key, index) => {
            if(key !== "conditions_met" || !formControl[key].noClear){
                formControl[key].value = "";
            }
        });*/

        if(!this.props.id && Components[this.props.name].formControl.name){
            formControl.name.value = this.props.name + "-" + Math.ceil(Math.random() * 10000000)
        }
        this.setState({ formControl }, this.getAcc)
    }

    getAcc = () => {
        if(this.props.name === "send_mail"){
            myaxios.get(`/ajax/mail/account`).then(res => {
                const formControl = this.state.formControl
                if(res){
                    formControl.account_id.options = res.data.items;
                }
                this.setState({ formControl })
            })
        }
    }

    toArray = () => {
        let arr = [];
        const formControl = this.state.formControl;
        Object.keys(this.state.formControl).map((key, index) => {
            arr.push({
                fieldType: key,
                value: formControl[key].value,
                label: formControl[key].label,
                valid: formControl[key].valid,
                errorMessage: formControl[key].errorMessage,
                field: formControl[key].field,
                type:formControl[key].type,
                isEmoji: formControl[key].isEmoji,
                isPaste: formControl[key].isPaste,
                parseType: formControl[key].parseType,
                options: formControl[key].options,
                empty: formControl[key].empty,
                lab: formControl[key].lab,
                val: formControl[key].val,
                tooltip: formControl[key].tooltip
            })
        });
        return arr;
    };

    convertSendRequest(type){
        let params = {}
        this.state[type].map(item => {
            params[item.key] = item.value
        })
        return params
    }

    postContent = () => {
        if(this.isFormValid()){
            //this.setState({ tch: true, loading: true });
            let arr = {};
            let meta_data = {};
            Object.keys(this.state.formControl).map((key, index) => {
                if(this.state.formControl[key].invest){
                    meta_data[key] = this.state.formControl[key].value
                } else {
                    arr[key] = this.state.formControl[key].value;
                }
            });
            arr['meta_data'] = meta_data;
            arr.filters = this.state.filter ? this.state.filter.filter(el => el) : [];
            arr.type = Components[this.props.name].type;
            if(this.props.name === "question"){
                arr.meta_data.value = this.state.questions.filter(el => el);
            }
            if(this.props.name === "attributes"){
                arr.meta_data.attributes = this.state.attributes;
            }
            if(this.props.name === "SendRequest"){
                arr.meta_data.params = this.convertSendRequest(`params`)
                arr.meta_data.headers = this.convertSendRequest(`headers`)
            }

            if(this.props.id){
                if(Components[this.props.name].trigger) {
                    arr.type = Components[this.props.name].type;
                    myaxios.put(`/ajax/bot/trigger/${this.props.id}`, arr).then(res => {
                        if(res.status === 200 || res.status === 201 || res.status === 204){
                            Notif('save', 'Запись успешно сохранена');
                            setTimeout(this.props.close, 200);
                            setTimeout(this.props.refresh, 200)
                        }
                    })
                } else {
                    myaxios.put(`${Components[this.props.name].post.link}/${this.props.id}`, arr).then(res => {
                        if(res.status === 200 || res.status === 201 || res.status === 204){
                            Notif('save', 'Запись успешно сохранена');
                            setTimeout(this.props.close, 200);
                            setTimeout(this.props.refresh, 200)
                        }
                    })
                }
            } else {
                arr.time_condition = this.props.addParentId + "";

                if(Components[this.props.name].trigger) {
                    arr.type = Components[this.props.name].type;
                    myaxios.post(`/ajax/bot/${this.props.parentId}/trigger`, arr).then(res => {
                        if(res.status === 200 || res.status === 201 || res.status === 204){
                            Notif('save', 'Запись успешно сохранена');
                            setTimeout(this.props.close, 200);
                            setTimeout(this.props.refresh, 200)
                            this.props.history.push(`/marketing/messengers/builder/${this.props.parentId}`)
                        }
                    })
                } else {
                    arr.type = Components[this.props.name].type;
                    arr.order = this.props.order;
                    myaxios.post(`/ajax/bot/${this.props.parentId}/task`, arr).then(res => {
                        if(res.status === 200 || res.status === 201 || res.status === 204){
                            Notif('save', 'Запись успешно сохранена');
                            setTimeout(this.props.close, 200);
                            setTimeout(this.props.refresh, 200)
                        }
                    })
                }
            }
        }
    };
    getPrepare = () => {
        if(Components[this.props.name].prepare){
            myaxios.get(Components[this.props.name].prepare.link).then(res => {
                const {formControl} = this.state;
                formControl[Components[this.props.name].prepare.options].options = res ? res.data.items : []
                this.setState({ formControl }, this.getContent );
            })
        } else this.getContent();

    }
    convertObjectToArray(obj){
        let params = [];
        Object.keys(obj).map(key => {
            params.push({ key: key, value: obj[key] })
        })
        return params
    }
    getContent = () => {
        if(this.props.id){
            myaxios.get(Components[this.props.name].get.link + '/' + this.props.id).then(res => {
                const formControl = this.state.formControl;
                let filter = [];
                let questions = [];
                let attributes = [];
                let params = [];
                let headers = [];
                filter = res.data.filters || [];
                Components[this.props.name].get.data.map((item, index) => {
                    //formControl[item].value = get[item];
                    if(item.invest){
                        formControl[item.name][item.to] = res.data[item.invest][item.name];
                    } else {
                        formControl[item.name][item.to] = res.data[item.name];
                    }
                    if(this.props.name === "question"){
                        questions = res.data.meta_data.value;
                    }
                    if(this.props.name === "attributes"){
                        attributes = res.data.meta_data.attributes;
                    }
                    if(this.props.name === "SendRequest"){
                        params = this.convertObjectToArray(res.data.meta_data.params);
                        headers = this.convertObjectToArray(res.data.meta_data.headers);
                    }
                });
                this.setState({ formControl, filter, questions, attributes, params, headers, loading: false })
            })
        } else this.setState({ loading: false })

        /*Components.sendMessage.get.data.map((item, index) => {
            //formControl[item].value = get[item];
            if(item.invest){
                formControl[item.name][item.to] = get[item.invest][item.name];
            } else {
                formControl[item.name][item.to] = get[item.name];
            }
            console.log(formControl)
        });*/

    };
    isFormValid = () => {
        let formControl = this.state.formControl;
        let isFormValid = true;
        Object.keys(formControl).map((key, index) => {
            formControl[key].valid = formValid(formControl[key].value, formControl[key].validation);
            isFormValid = formControl[key].valid && isFormValid
        });
        this.setState({ formControl });
        return isFormValid;
    };
    setField = (type, e) => {
        const formControl = this.state.formControl;
        if(this.props.name === "send_mail" && type === "template"){
            myaxios.get(`/ajax/template/${e}`).then(res => {
                if(res){
                    formControl.body.value = res.data.body;
                    formControl.subject.value = res.data.subject;
                }
            })
        }
        formControl[type].value = e;
        formControl[type].valid = formValid(formControl[type].value, formControl[type].validation);
        this.setState({ formControl, tch: false })
    }
    setFieldFilter = (type, index, val) => {
        const filter = this.state.filter;
        filter[index][type] = val;
        this.setState({ filter, tch: false })
    }
    deleteItemFilter = index => {
        const filter = this.state.filter;
        delete filter[index];
        this.setState({ filter, tch: false })
    }
    setFieldQuestions = (index, val) => {
        const questions = this.state.questions;
        questions[index] = val;
        this.setState({ questions, tch: false })
    }
    deleteItemQuestions = index => {
        const questions = this.state.questions;
        delete questions[index];
        this.setState({ questions, tch: false })
    }
    setFieldAttr = (type, index, val) => {
        const attributes = this.state.attributes;
        attributes[index][type] = val;
        this.setState({ attributes, tch: false })
    }
    deleteItemAttr = index => {
        const attributes = this.state.attributes;
        delete attributes[index];
        this.setState({ attributes, tch: false })
    }
    render(){
        const {filter, questions, attributes} = this.state;
        return this.state.loading ? <Loader contain /> : <div className="sbform create-activ">
            <div className="rmod-header"><b>{Components[this.props.name].title}</b>
                <a onClick={this.props.close} style={{ marginRight: 60 }} className="mclose"><i className="flaticon-close" /></a>
                <div className="togglelist"><button onClick={this.postContent} className="rmod-ok" disabled={this.state.tch}><i className="flaticon-interface-1" /></button></div>
            </div>
            <div className="mod-cont">
                {
                    this.toArray().map((item, index) =>
                    <div key={index} style={{ position: 'relative' }}>
                        {
                            item.field === 'textarea' ?
                                <FlowTextarea
                                    label={item.label}
                                    valid={item.valid}
                                    value={item.value}
                                    paste={(value, paste) => this.setField(item.fieldType, value + '' + paste)}
                                    onChange={e => this.setField(item.fieldType, e.target.value)}
                                    isEmoji={item.isEmoji}
                                    isPaste={item.isPaste}
                                    parseType={this.state.slugs}
                                    tooltip={item.tooltip}
                                /> :
                            item.field === 'input' ?
                                <FlowInput
                                    label={item.label}
                                    valid={item.valid}
                                    value={item.value}
                                    paste={(value, paste) => this.setField(item.fieldType, value + '' + paste)}
                                    onChange={e => this.setField(item.fieldType, e.target.value)}
                                    isEmoji={item.isEmoji}
                                    isPaste={item.isPaste}
                                    parseType={this.state.slugs}
                                    tooltip={item.tooltip}
                                /> :
                            item.field === 'select' ?
                                <Myselect
                                    label={item.label}
                                    valid={item.valid}
                                    onChange={e => this.setField(item.fieldType, e.target.value)}
                                    value={item.value}
                                    errorMessage={item.errorMessage}
                                    options={item.options}
                                    empty={item.empty}
                                    val={item.val}
                                    lab={item.lab}
                                    tooltip={item.tooltip}
                                    shouldValidate={true}
                                /> :
                            item.field === 'hidden' ? ''
                            : null
                        }
                    </div>
                    )
                }
                {
                    this.props.children ? this.props.children({
                        formControl: this.state.formControl,
                        state: this.state,
                        setField: this.setField,
                        modalSlugs: this.state.slugs,
                        setState: (childType, childValue) => this.setState({ [childType]: childValue })
                    }) : null
                }
                <div className="valuesBlock" style={{ display: this.props.name === 'attributes' ? '' : 'none' }}>
                    <div className="connection" style={{marginTop: "20px"}}><span>Аттрибуты</span>
                        <div className="toggleparrent"><button onClick={() => {
                            attributes.push({key:1,value:"Первый"})
                            this.setState({ filter })
                        }} className="btni">+</button></div></div>
                    <table style={{width:"100%"}} className="filterBpm">
                        {
                            attributes ? attributes.map((item, i) =>
                                <tr key={i}>
                                    <td>
                                        <Myselect
                                            value={item.key}
                                            empty
                                            options={this.props.attr}
                                            onChange={e => this.setFieldAttr('key', i, e.target.value)}
                                            val="id"
                                            lab="name"
                                        />
                                    </td>
                                    <td>
                                        <FlowInput
                                            value={item.value}
                                            isPaste
                                            parseType={this.state.slugs}
                                            valid={true}
                                            onChange={e => this.setFieldAttr('value', i, e.target.value)}
                                            paste={(value, paste) => this.setFieldAttr('value', i, value + '' + paste)}
                                        />
                                    </td>
                                    {attributes.length > 0 ? <td style={{ width:"15px" }}>
                                        <a onClick={() => this.deleteItemAttr(i)} style={{ fontSize:"19px", color:"#c2263f", display:"block", textAlign:"center" }}>x</a>
                                    </td> : null}
                                </tr>
                            ) : null
                        }
                    </table>
                </div>

                <div className="valuesBlock" style={{ display: this.props.name === 'question' ? this.state.formControl.type.value === 'choice' ? '' : 'none' : 'none' }}>
                    <div className="connection" style={{marginTop: "20px"}}><span>Значения</span>
                        <div className="toggleparrent"><button onClick={() => {
                            questions.push('Вариант')
                            this.setState({ filter })
                        }} className="btni">+</button></div></div>
                    <table style={{width:"100%"}} className="filterBpm">
                        {
                            questions ? questions.map((item, index) =>
                                <tr key={index}>
                                    <td>
                                        <FlowInput
                                            value={questions[index]}
                                            isPaste
                                            isEmoji
                                            parseType={this.state.slugs}
                                            valid={true}
                                            onChange={e => this.setFieldQuestions(index, e.target.value)}
                                            paste={(value, paste) => this.setFieldQuestions(index, value + ' ' + paste)}
                                        />
                                    </td>
                                    {questions.length > 0 ? <td style={{ width:"15px" }}>
                                        <a onClick={() => this.deleteItemQuestions(index)} style={{ fontSize:"19px", color:"#c2263f", display:"block", textAlign:"center" }}>x</a>
                                    </td> : null}
                                </tr>
                            ) : null
                        }
                    </table>
                </div>

                <div className="chatBotFilter">
                    {
                        console.log("dom", filter)
                    }
                    <div className="connection" style={{marginTop: "20px"}}><span>Фильтр</span>
                        <div className="toggleparrent"><button onClick={() => {
                            console.log("click", filter)
                            filter.push({field:'', operator:'==',value:'', type:'string'});
                            this.setState({ filter })
                        }} className="btni">+</button></div></div>
                    <table style={{width:"100%"}} className="filterBpm">
                        <thead>
                        <tr>
                            <td style={{padding:"0px 5px"}}>Поле</td>
                            <td style={{padding:"0px 5px"}}>Оператор</td>
                            <td style={{padding:"0px 5px"}}>Значение</td>
                            {filter ? filter.length > 1 ? <td /> : null : null}
                        </tr>
                        </thead>
                        <tbody>
                        {
                            filter ? filter.map((item,index) =>
                                <tr key={index}>
                                    <td>
                                        <Myselect
                                            empty
                                            value={filter[index].field}
                                            options={this.state.slugs}
                                            onChange={e => this.setFieldFilter('field', index, e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <Myselect
                                            value={filter[index].operator}
                                            options={[
                                                {value: '==', label: 'Равно'},
                                                {value: '!=', label: 'Не равно'},
                                                {value: 'match', label: 'Содержит'},
                                                {value: 'nmatch', label: 'Не содержит'},
                                                /*{value: 'empty', label: 'Пусто'},
                                                {value: 'no_empty', label: 'Не пусто'},*/
                                            ]}
                                            onChange={e => this.setFieldFilter('operator', index, e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <FlowInput
                                            value={filter[index].value}
                                            isPaste
                                            parseType={this.state.slugs}
                                            type={filter[index].type}
                                            valid={true}
                                            onChange={e => this.setFieldFilter('value', index, e.target.value)}
                                            paste={(value, paste) => this.setFieldFilter('value', index, value + ' ' + paste)}
                                        />
                                    </td>
                                    {filter.length > 0 ? <td style={{ width:"15px" }}>
                                        <a onClick={() => this.deleteItemFilter(index)} style={{ fontSize:"19px", color:"#c2263f", display:"block", textAlign:"center" }}>x</a>
                                    </td> : null}
                                </tr>
                            ) : null
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    }
}

export default withRouter(MainModal)

const Paste = {
    standart: [
        'Respoce Path',
        'Bot ID',
        'Bot connection ID',
        'Bot Name',
        'Bot platform',
        'User ID',
        'User Name',
        'User First_name',
        'User Last_name',
        'Group'
    ]
}

export const FlowInput = props => {
    return <div>
        {
            (props.label) ? <label data-tooltip={props.tooltip} style={{display: "block"}}>
                {(props.tooltip) ? <div className="squerTooltipe"/> : null} {props.label} {(props.required) ? <span style={{color: "red"}}>*</span> : null}</label> : null
        }
            <div className="flowInput" style={{ border: props.valid ? null : '1px solid #f4516c' }}>
                <input value={props.value} type={props.type} onChange={props.onChange} />
                {props.isPaste ? <b><i className="flaticon-network" />
                    <ul className="flowToogleBlock scrollbar">
                        {
                            props.parseType ? props.parseType.map((paste, indexp) =>
                                <li key={indexp}><a onClick={() => props.paste(props.value, paste.value)}>{paste.label}</a></li>
                            ) : null
                        }
                    </ul>
                </b> : null}
                {props.isEmoji ? <b><i className="flaticon-user" />
                    <ul style={{ maxHeight: 350 }} className="flowToogleBlock scrollbar">
                        <Picker onSelect={(a) => props.paste(props.value, a.native)}  i18n={{ search: 'Recherche', categories: { search: 'Résultats de recherche', recent: 'Récents' } }} />
                    </ul>
                </b> : null}
            </div>
            {props.valid ? null : <span className="errorMsg">{(props.errorMessage) || "Заполните поле"}</span>}
        </div>
}

const FlowTextarea = props => {
    return <div>
        {
            (props.label) ? <label data-tooltip={props.tooltip} style={{display: "block"}}>
                {(props.tooltip) ? <div className="squerTooltipe"/> : null} {props.label} {(props.required) ? <span style={{color: "red"}}>*</span> : null}</label> : null
        }
        <div className="flowTextarea flowInput" style={{ border: props.valid ? null : '1px solid #f4516c' }}>
            <textarea rows={4} value={props.value} onChange={props.onChange} />
            {props.isPaste ? <b><i className="flaticon-network" />
                <ul className="flowToogleBlock scrollbar">
                    {
                        props.parseType ? props.parseType.map((paste, indexp) =>
                            <li key={indexp}><a onClick={() => props.paste(props.value, paste.value)}>{paste.label}</a></li>
                        ) : null
                    }
                </ul>
            </b> : null}
            {props.isEmoji ? <b><i className="flaticon-user" />
                <ul style={{ maxHeight: 350 }} className="flowToogleBlock scrollbar">
                    <Picker onSelect={(a) => props.paste(props.value, a.native)}  i18n={{ search: 'Recherche', categories: { search: 'Résultats de recherche', recent: 'Récents' } }} />
                </ul>
            </b> : null}
        </div>
        {props.valid ? null : <span className="errorMsg">{(props.errorMessage) || "Заполните поле"}</span>}
    </div>
}
