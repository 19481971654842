import React, {Component} from 'react';
import Input from '../components/UI/Input';
import formValid from '../components/UI/formValid';
import Notif from '../components/Notif';
import myaxios from '../components/Axios';
import {withRouter} from 'react-router-dom';
import AutoSelect from '../components/UI/AutoSelect';
import Loader from "../components/UI/Loader";
import withGlobalState from "../components/other/withGlobalState";

class AddUser extends Component {
    state = {
        formControl: {
            group: {
                value: '',
                errorMessage: "Выберите подразделение",
                arr:null,
                valid: true,
                validation: {
                    required: true,
                }
            },
            name: {
                value: '',
                errorMessage: "Поле не может содержать меньше 3-х символов",
                valid: true,
                validation: {
                    required: true,
                    minLength: 3
                }
            },
            email: {
                value: '',
                errorMessage: "Введите корректный email",
                valid: true,
                validation: {
                    required: true,
                    email: true
                }
            },
            password: {
                value: '',
                errorMessage: "Поле не может содержать меньше 6-ти символов",
                valid: true,
                validation: {
                    required: true,
                    minLength: 6
                }
            },
            passwordReplay: {
                value: '',
                errorMessage: "Поле не может содержать меньше 6-ти символов",
                valid: true,
                validation: {
                    required: true,
                    minLength: 6
                }
            },
            phone: {value: ''},
        },
        notify: 1,
        isFormValid: true,
        tch:true,
        loading:false
    };

    setField = (event, type) => {
        const formControl = {  ...this.state.formControl };
        const control = { ...formControl[type] };
        control.value = event.target.value;
        formControl[type] = control;
        this.setState({
            formControl, tch: false
        })
    };
    passwordNotcopy = () => {
        let isValid = true;
        if(this.state.formControl.password.value !== this.state.formControl.passwordReplay.value) {
            isValid = false;
        }
        return isValid;
    };
    sendForm = event => {
        event.preventDefault();
        if(this.isFormValid() && this.passwordNotcopy()){
            this.setState({ loading: true });
            this.setState({ tch: true });
            myaxios.post(`/ajax/settings/user`, {
                name: this.state.formControl.name.value,
                phone: this.state.formControl.phone.value,
                email: this.state.formControl.email.value,
                password: this.state.formControl.password.value,
                password_repeat: this.state.formControl.passwordReplay.value,
                role: this.state.formControl.group.value,
                notify: this.state.notify
            }).then((response) => {
                if(response.status === 201 || response.status === 200 || response.status === 204) {
                    this.props.history.push('/settings/users/' + response.data.id);
                    setTimeout(this.props.close, 300);
                    if(this.state.formControl.group.arr && this.state.formControl.group.arr.name === "test") this.props.setSteps("users", true, true)
                    Notif("save");
                }
                this.setState({ loading: false })
            }).catch(() => this.setState({ loading: false }))
        } else {
            (!this.passwordNotcopy()) ? Notif("Error", "Пароли не совпадают") : Notif("required-fields")
        }
    };
    isFormValid = () => {
        let formControl = this.state.formControl;
        let isFormValid = true;
        Object.keys(formControl).map((key, index) => {
            formControl[key].valid = formValid(formControl[key].value, formControl[key].validation);
            isFormValid = formControl[key].valid && isFormValid
        });
        this.setState({
            formControl, isFormValid
        });
        return isFormValid;
    };
    updateData = (id, arr) => {
        const formControl = this.state.formControl;
        formControl.group.value = arr.name;
        formControl.group.arr = arr;
        this.setState({ formControl });
    }
    unlink = () => {
        const formControl = this.state.formControl;
        formControl.group.value = '';
        formControl.group.arr = null;
        this.setState({ formControl });
    }
    render(){
        return this.state.loading ? <Loader contain /> : <form className="sbform create-activ">
            <div className="rmod-header">
                <b>Новый пользователь</b>
                <div className="togglelist"><a onClick={this.sendForm} className="rmod-ok"><i className="flaticon-folder-2" /></a>
                </div>
            </div>
            <div className="mod-cont">
                <div className="relt">
                    {
                        (this.state.formControl.group.arr) ?
                            <div className="inp">
                                <label>Подразделение</label>
                                <div className="rsb">
                                    <b>{this.state.formControl.group.arr.name}</b>
                                    <a title="Отвязать" onClick={this.unlink} className="unlink">x</a>
                                </div>
                            </div> :
                            <AutoSelect
                                required
                                label="Подразделение"
                                link="/ajax/rbac/role?filter[name][like]="
                                minLength="0"
                                result="name"
                                event="group"
                                errorMessage={this.state.formControl.group.errorMessage}
                                valid={this.state.formControl.group.valid}
                                shouldValidate={true}
                                setField={()=>{}}
                                updateData={this.updateData}
                            />
                    }
                </div>
                <Input
                    label="ФИО"
                    type="text"
                    errorMessage={this.state.formControl.name.errorMessage}
                    value={this.state.formControl.name.value}
                    onChange={event => this.setField(event, "name")}
                    shouldValidate={true}
                    valid={this.state.formControl.name.valid}
                    required
                />
                <Input
                    label="Email"
                    type="email"
                    errorMessage={this.state.formControl.email.errorMessage}
                    value={this.state.formControl.email.value}
                    onChange={event => this.setField(event, "email")}
                    shouldValidate={true}
                    valid={this.state.formControl.email.valid}
                    required
                />
                <Input
                    label="Номер телефона"
                    type="phone"
                    value={this.state.formControl.phone.value}
                    onChange={event => this.setField(event, "phone")}
                />
                <Input
                    label="Пароль"
                    type="password"
                    errorMessage={this.state.formControl.password.errorMessage}
                    value={this.state.formControl.password.value}
                    onChange={event => this.setField(event, "password")}
                    shouldValidate={true}
                    valid={this.state.formControl.password.valid}
                    autocomplete="off"
                    required
                />
                <Input
                    label="Повторите пароль"
                    type="password"
                    errorMessage={this.state.formControl.passwordReplay.errorMessage}
                    value={this.state.formControl.passwordReplay.value}
                    onChange={event => this.setField(event, "passwordReplay")}
                    shouldValidate={true}
                    valid={this.state.formControl.passwordReplay.valid}
                    autocomplete="off"
                    required
                />
                <label className="c12" style={{padding: "0px", margin: "10px 0px 0px"}}>
                    <input onChange={e => this.setState({ notify: this.state.notify === 1 ? 0 : 1 })} className="checkbox checkboxlead" type="checkbox" name="checkbox-test" checked={this.state.notify === 1} />
                    <span className="checkbox-custom" />
                    <em style={{display: "inlineBlock", marginLeft: 4, position: "relative", top: 2, fontSize: 13}}>Отправить приглашение</em>
                </label>
                <div className="clear" />
            </div>
        </form>
    }
}

export default withRouter(withGlobalState(AddUser));
