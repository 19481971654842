import React, {useState} from 'react';
import Input from '../components/UI/Input';
import Notif from '../components/Notif';
import formValid from '../components/UI/formValid';
import myaxios from '../components/Axios';
import Loader from "../components/UI/Loader";

const ResetPassword = ({ reset }) => {
    const [state, setMyState] = useState({
        formControl: {
            email: {value:'', valid:true, validation: {required: true, email: true}},
        },
        access: false,
        loading: false
    });
    const setState = (st, func) => {
        Object.keys(st).map((key) => { state[key] = st[key]});
        setMyState(prevState => { return {...prevState, ...state} });
        if(func) func();
    };
    const setField = (event, type) =>{
        const formControl = state.formControl;
        formControl[type].value = event.target.value;
        setState({ formControl })
    };
    const isFormValid = () => {
        let formControl = state.formControl;
        let isFormValid = true;
        Object.keys(formControl).map((key, index) => {
            formControl[key].valid = formValid(formControl[key].value, formControl[key].validation)
            isFormValid = formControl[key].valid && isFormValid
        });
        setState({
            formControl, isFormValid
        });
        return isFormValid;
    };
    const sendForm = event => {
        if(isFormValid()){
            setState({ loading: true })
            myaxios.post(`/ajax/site/request-password-reset`, {
                email: state.formControl.email.value
            }).then(res => {
                setState({ loading: false })
                if(res.data.status === "success"){
                    Notif('save', `Новый пароль отправлен на почту ${state.formControl.email.value}`)
                    setState({ access: true })
                    setTimeout(() => reset())
                } else {
                    Notif("Error", "Ошибка валидации")
                }
            })
        }
    };
    return state.loading ? <Loader contain /> : <div className="login">
        <div className="sidebarLogin sbform">
            <img src="https://sboxcrm.com/images/logocrm.png" alt=""/>
            <Input
                className="rc12"
                type="text"
                placeholder="Логин (Email)"
                value={state.formControl.email.value}
                valid={state.formControl.email.valid}
                shouldValidate={true}
                onChange={event => setField(event, "email")}
            />
            <button onClick={sendForm}>Сбросить пароль</button>
            <a style={{ fontSize: "14px", textAlign:"center", display:"block", marginTop:"3px" }} onClick={() => reset()}>Отменить</a>
        </div>
    </div>
}

export default ResetPassword;
