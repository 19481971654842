import React, { Component } from 'react';
import myaxios from '../components/Axios';
import formValid from '../components/UI/formValid';
import Input from '../components/UI/Input';
import Myselect from '../components/UI/Select';
import Notif from '../components/Notif';
import AutoSelect from '../components/UI/AutoSelect';
import ModAutoSelect from '../components/UI/ModAutoSelect';
import {withRouter} from 'react-router-dom';
import {createEvent} from "../services/other/events";

class AddInvoice extends Component {
    state = {
        formControl: {
            name: {value: '', valid: true, validation: {required: true, minLength: 1}},
            prefix: {
                value:'',
            },
            status: {
                value: '',
                options: []
            },
            requisites: {
                value: '',
                options: []
            },
            responsible: {
                value: '',
                arr:null,
                owner_id: null
            },
            contact: {
                value: (this.props.contact_id) || '',
                arr: (this.props.contact) || null,
                valid: true,
                validation: {required: (this.props.contact !== null)}},
            account: {
                value: (this.props.account_id) || '',
                arr: (this.props.account) || null,
                valid: true,
                validation: {required: (this.props.account !== null)}},
            /*currency: { value: '' },
            course: { value: '' },
            accountName: { value: '' },
            address: { value: '' },
            comment: { value: '' },*/
        },
        client: null,
        clientId: '',
        accountName: '',
        accountAddress: '',
        isFormValid: true,
        tch: false,
        loading: true,
        prefix:'',
        prefixes:[]
    };
    getPrepeare = () => {
        myaxios.get(`/ajax/invoice/prepare`, {
            method: 'get',
        }).then((response) => {
            let formControl = this.state.formControl;
            formControl.status.options = response.data.statuses;
            formControl.status.value = response.data.statuses[0].id;
            formControl.name.value = response.data.inv_id;
            formControl.prefix.value = response.data.prefixes[0].id;
            formControl.name.value = Number(response.data.prefixes[0].count);
            this.setState({ formControl, prefix: response.data.prefix, prefixes: response.data.prefixes })
        })
    };
    componentDidMount(){
        this.getPrepeare();
    }
    searchPrefixId = (id) => {
        for(let i = 0; i < this.state.prefixes.length; i++){
            if(this.state.prefixes[i].id == id) return Number(this.state.prefixes[i].count)
        }
    }
    setField = (event, type) => {
        console.log(event.target.value, type)
        const formControl = {  ...this.state.formControl };
        const control = { ...formControl[type] };
        control.value = event.target.value;
        if(type == "prefix") formControl.name.value = this.searchPrefixId(event.target.value)
        formControl[type] = control;
        this.setState({
            formControl, tch: false
        })
    };
    sendForm = event => {
        event.preventDefault();
        if(this.isFormValid()){
            this.setState({ loading: true, tch: true });
            myaxios.post(`/ajax/invoice`, {
                inv_id: this.state.formControl.name.value,
                custom_name: this.state.accountName,
                custom_address: this.state.accountAddress,
                owner_id: this.state.formControl.responsible.value,
                contact_id: this.state.formControl.contact.value,
                opportunity_id: this.props.opportunity_id,
                order_id: this.props.order_id,
                project_id: this.props.project_id,
                account_id: this.state.formControl.account.value,
                status_id: this.state.formControl.status.value,
                prefix_id: this.state.formControl.prefix.value,
                startDate: new Date(),
                endDate: new Date(),
            }).then((response) => {
                if(response.status === 200 || response.status === 201 || response.status === 204) {
                    setTimeout(this.props.history.push('/invoice/' + response.data.id), 200);
                    setTimeout(this.props.close);

                    createEvent({
                        event_type: "createTask",
                        user_id: this.state.formControl.responsible.value + "", user_name: "---"
                    }).then();
                }
            }).catch((error)=>{
                this.setState({ loading: false, tch: true });
            });
        } else {
            Notif("Error");
        }
    };
    isFormValid = () => {
        let formControl = this.state.formControl;
        let isFormValid = true;
        Object.keys(formControl).map((key, index) => {
            formControl[key].valid = formValid(formControl[key].value, formControl[key].validation)
            isFormValid = formControl[key].valid && isFormValid
        });
        this.setState({
            formControl, isFormValid
        });
        return isFormValid;
    };
    updateData = (id, arr, addType, endParrans) => {
        const formControl = this.state.formControl;
        formControl[addType].value = id;
        formControl[addType].arr = arr;
        if(addType === "account") {
            formControl.contact.validation.required = false;
        } else if(addType === "contact") {
            formControl.account.validation.required = false;
        }
        this.setState({ formControl, tch: false })
    };
    unLink = addType => {
        const formControl = this.state.formControl;
        formControl[addType].value = '';
        formControl[addType].arr = null;
        formControl.contact.validation.required = true;
        formControl.account.validation.required = true;
        this.setState({ formControl, tch: false})
    }
    render(){
        return <form className="sbform create-activ">
            <div className="rmod-header">
                <b>Создать счет</b>
                <a className="mclose 13" onClick={this.props.close}><i className="flaticon-close" /></a>
                <div className="togglelist"><button onClick={this.sendForm} className="rmod-ok" disabled={this.state.tch}><i className="flaticon-folder-2" /></button>
                    {/*                    <ul>
                     <li><a href="#"><i className="flaticon-folder-2" /> Сохранить</a></li>
                     <li><a href="#"><i className="flaticon-delete-1" /> Удалить</a></li>
                     </ul>*/}
                </div>
            </div>
            <div className="mod-cont">
                {/*<Input
                    className="c3"
                    style={{ padding: "0" }}
                    label="Префикс"
                    value={this.state.prefix}
                    disabled={true}
                />*/}
                <Myselect
                    style={{ padding: "0" }}
                    className="c3"
                    label="Префикс"
                    options={this.state.prefixes}
                    val="id"
                    lab="name"
                    onChange={event => this.setField(event, "prefix")}
                    value={this.state.formControl.prefix.value}
                />
                <Input
                    className="c9"
                    label="Номер счета"
                    type="text"
                    shouldValidate={true}
                    value={this.state.formControl.name.value}
                    onChange={event => this.setField(event, "name")}
                    valid={this.state.formControl.name.valid}
                    disabled={true}
                />
                <div className="clear" />
                {/*<Myselect
                    label="Статус"
                    value={this.state.formControl.status.value}
                    options={this.state.formControl.status.options}
                    onChange={event => this.setField(event, "status")}
                    val="id"
                    lab="description"
                />*/}

                <div style={{ display: (this.state.formControl.contact.arr) ? "none" : '' }}>
                <ModAutoSelect
                    label="Контрагент"
                    link="/ajax/account?filter[name][like]="
                    minLength="1"
                    result="name"
                    event="account"
                    errorMessage="Контакт или контрагент должны быть обязательно привязанны к счету"
                    valid={this.state.formControl.contact.valid}
                    shouldValidate={true}
                    addType="account"
                    entity={this.state.formControl.account.arr}
                    entityName="name"
                    updateData={this.updateData}
                    unLink={this.unLink}
                    //redirectLink="/order/"
                /></div>
                <div style={{ display: (this.state.formControl.account.arr) ? "none" : '' }}>
                <ModAutoSelect
                    label="Контакт"
                    link="/ajax/contact?filter[name][like]="
                    minLength="1"
                    result="name"
                    event="contact"
                    errorMessage="Контакт или контрагент должны быть обязательно привязанны к счету"
                    valid={this.state.formControl.contact.valid}
                    shouldValidate={true}
                    addType="contact"
                    entity={this.state.formControl.contact.arr}
                    entityName="name"
                    updateData={this.updateData}
                    unLink={this.unLink}
                    //redirectLink="/order/"
                /></div>
                <ModAutoSelect
                    label="Ответственный"
                    link="/ajax/settings/user?filter[name][like]="
                    minLength="0"
                    result="name"
                    event="responsible"
                    valid={true}
                    shouldValidate={false}
                    addType="responsible"
                    entity={this.state.formControl.responsible.arr}
                    entityName="name"
                    updateData={this.updateData}
                    unLink={this.unLink}
                    //redirectLink="/order/"
                />
            </div>
        </form>
    }
}
export default withRouter(AddInvoice);