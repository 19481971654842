import React, {useEffect, useState} from "react";
import Modal from "../../../components/Modal/Modal";
import {FieldGen, setField, unLink, updateData} from "../../../components/fieldGen";
import myaxios from "../../../components/Axios";
import Notif from "../../../components/Notif";
import {withRouter} from "react-router-dom";
import formValid from "../../../components/UI/formValid";

const CheckboxLogin = ({ isOpen, close, success, history }) => {
    const [state, setMyState] = useState({
        formControl: {
            cashbox_id: {
                empty:true,
                type: 'select',
                label: 'Касса',
                value:'',
                options:[],
                valid: true,
                validation: {required: true},
                val:"id",
                lab:"name"
            },
            cashier_id: {
                empty:true,
                type: 'select',
                label: 'Кассир',
                value:'',
                options:[],
                valid: true,
                validation: {required: true},
                val:"id",
                lab:"full_name"
            },
        },
        loading:true,
        valid:false,
        shift:null,
        cashiers:[],
        cashboxes:[]
    })

    const setState = (st, func) => {
        Object.keys(st).map((key) => { state[key] = st[key]});
        setMyState(prevState => { return {...prevState, ...state} });
        if(func) func();
    };

    const getCurrentShift = () => {
        myaxios.get(`/ajax/checkbox/cashier/shift`).then(res => {
            setState({ shift: res.data, loading: false })
        }).catch(() => setState({ loading: false }))
    }

    const getCashier = () => {
        myaxios.get(`/ajax/checkbox/cashier`).then(res => {
            if(res.status === 200 || res.status === 201){
                const formControl = state.formControl;
                formControl.cashier_id.options = res.data.items
                formControl.cashier_id.value = res.data.items[0] && res.data.items[0].id;
                setState({ formControl })
            }
        })
    }

    const getCahBox = () => {
        myaxios.get(`/ajax/checkbox/cashbox`).then(res => {
            if(res.status === 200 || res.status === 201){
                const formControl = state.formControl;
                formControl.cashbox_id.options = res.data.items
                formControl.cashbox_id.value = res.data.items[0] && res.data.items[0].id;
                setState({ formControl })
            }
        })
    }

    const isFormValid = () => {
        let formControl = state.formControl;
        let isFormValid = true;

        Object.keys(formControl).map((key, index) => {
            formControl[key].valid = formValid(formControl[key].value, formControl[key].validation)
            isFormValid = formControl[key].valid && isFormValid
        });
        setState({ formControl, isFormValid });
        return isFormValid;
    };

    const createShift = () => {
        if(isFormValid()){
            setState({ loading: true })
            myaxios.post(`/ajax/checkbox/shift?id=${state.formControl.cashier_id.value}&cashbox_id=${state.formControl.cashbox_id.value}`).then(res => {
                if(res.status === 200 || res.status === 201){
                    getCurrentShift();
                    Notif("save", "Смена успешно открыта!");
                }
            }).catch(() => setState({ loading: false }))
        }
    }

    const closeShift = () => {
        setState({ loading: true })
        myaxios.post(`/ajax/checkbox/shift/close/${state.shift.user.id}`).then(res => {
            if(res.status === 200 || res.status === 201){
                setState({ shift: null, loading: false })
                Notif("save", "Смена завершена!");
            }
        }).catch(() => setState({ loading: false }))
    }

    useEffect(() => {
        if(isOpen){
            getCurrentShift();
            getCashier();
            getCahBox();
        }
    },[isOpen])

    return <Modal
        saveIcon="flaticon-cogwheel-1"
        loading={state.loading}
        title="CheckBox"
        isOpen={isOpen}
        save={() => {
            history.push("/settings/checkbox");
            close()
        }}
        close={close}
    >

        {!state.shift && <FieldGen
            arr={state.formControl}
            setField={(e, type) => setState({formControl: setField(state.formControl, type, e)})}
        />}

        {state.shift ?
            <a style={{
                display: "block",
                textAlign: "center",
                fontSize:"14px",
                fontWeight:"bold",
                marginTop: "10px",
                textTransform: "uppercase",
                padding:"12px"
            }} onClick={closeShift} className="success btni">Закрыть смену</a> :

            <a style={{
                display: "block",
                textAlign: "center",
                fontSize:"14px",
                fontWeight:"bold",
                marginTop: "10px",
                textTransform: "uppercase",
                padding:"12px"
            }} onClick={createShift} className="success btni">Открыть смену</a>
        }
    </Modal>
}

export default withRouter(CheckboxLogin)