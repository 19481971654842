import React, {useEffect, useState} from "react";
import CenterModalContainer from "../../../Modal/centerModalContainer";
import myaxios from "../../Axios";
import formValid from "../../UI/formValid";
import {CommonModalView} from "./FirstStepVIew";
import {createContactService} from "../../../services/contact/contactService";
import Notif from "../../Notif";
import {
    createInvoiceService,
    createInvoiceShipmentService,
    createPaymentService
} from "../../../services/invoice/invoiceService";
import {formatDate} from "../../ListComponent";
import {CSSTransition} from "react-transition-group";
import {SecondStepView} from "./SecondStepView";

const service = (link) => myaxios.get(link);

const validationRules = {
    first_name: { required: true },
    type_id: { required: true },
    provider_id: { required: true },
    payment_detail_id: { required: true },
    created_date: { required: true },
}

export const CommonUastalModal = ({ isOpen, close }) => {
    const [contactFields, setContactFields] = useState({
        email: "",
        first_name: "",
        last_name: "",
        mobile_phone: "",
        owner_id: null,
        type_id: null,
        prefix_id: '',
        provider_id: '',
        status_id: '',
        currency_id: '',
        stock_id: '',
        payment_detail_id: '',
        created_date: new Date(),
        payment_date: new Date(),
        payed_at: new Date(),
        payment_name: '',
        payment_amount: '',
        payment_status: '',
        payment_method_id: 2,
        status: "4",
        balance_id: '',
        isCreateShipment: false
    })
    const [errors, setErrors] = useState({});
    const [entities, setEntities] = useState({});
    const [activeStep, setActiveStep] = useState("first");
    const [createdEntities, setCreatedEntities] = useState({})
    const [isFinish, setIsFinish] = useState(false)

    const transformArr = async (type, arr, id) => {
        if(type === "provider_id") {
            if(arr) {
                const { data } = await service(`/ajax/account/${id}/payment-details`);
                if(data.items[0]) {
                    return {
                        entities: { ...entities, payment_detail_id: data.items[0] },
                        contactFields: { ...contactFields, payment_detail_id: data.items[0].id }
                    }
                }
            } else {
                return {
                    entities: { ...entities, payment_detail_id: null },
                    contactFields: { ...contactFields, payment_detail_id: '' }
                }
            }
        }

        return { entities, contactFields }
    }

    const updateData = async (type, arr, id) => {
        const data = await transformArr(type, arr, id);
        setEntities({
            ...data.entities,
            [type]: arr
        })

        setContactFields({
            ...data.contactFields,
            [type]: id
        })
    }

    useEffect(() => {
        (async () => {
            const contactTypes = await service('/ajax/lookup/search?type=ct_type&per-page=1&fields=id,value&order=0&value=кли');
            const invoiceRes = await service('/ajax/invoice/prepare');
            const paymentDetails = await service(`/ajax/account/${invoiceRes.data.defaultAccount.id}/payment-details`)
            const paymentPrepare = await service(`/ajax/payment/prepare`)

            setEntities({
                ...entities,
                type_id: contactTypes.data.items[0],
                prefix_id: invoiceRes.data.prefixes[0],
                provider_id: invoiceRes.data.defaultAccount,
                stock_id: invoiceRes.data.stocks,
                currency_id: invoiceRes.data.currencies[0],
                payment_detail_id: paymentDetails && paymentDetails.data ? paymentDetails.data.items[0] : null,
                status_id: invoiceRes.data.statuses,
                balance_id: paymentPrepare.data.balances
            })

            setContactFields({
                ...contactFields,
                type_id: contactTypes.data.items[0].id,
                prefix_id: invoiceRes.data.prefixes[0].id,
                provider_id: invoiceRes.data.defaultAccount.id,
                stock_id: invoiceRes.data.stocks[0].id,
                currency_id: invoiceRes.data.currencies[0].id,
                payment_detail_id: paymentDetails && paymentDetails.data.items[0] ? paymentDetails.data.items[0].id : '',
                status_id: invoiceRes.data.statuses[0].id,
                payment_name: 'PAY-' + paymentPrepare.data.nextId,
                payment_status: "4",
                payment_method_id: 2,
                balance_id: paymentPrepare.data.balances[0].id
            })
        })()
    }, [])

    const validationFields = () => {
        let isValid = true;
        const validationErrors = {};
        Object.keys(validationRules).map(key => {
            const result = formValid(contactFields[key], validationRules[key]);
            isValid = result && isValid;
            if(!result) validationErrors[key] = true;
        })
        setErrors(validationErrors)
        return isValid;
    }

    const contactData = {
        email: contactFields.email,
        first_name: contactFields.first_name,
        last_name: contactFields.last_name,
        mobile_phone: contactFields.mobile_phone,
        owner_id: contactFields.owner_id || null,
        type_id: contactFields.type_id
    }

    const invoiceData = {
        account_id: null,
        contact_id: null,
        owner_id: contactFields.owner_id || null,
        prefix_id: contactFields.prefix_id,
        status_id: contactFields.status_id,
        inv_id: entities.prefix_id && entities.prefix_id.count,
        payment_date: formatDate(contactFields.payment_date),
        //payed_at: contactFields.payed_at,
        stock_id: contactFields.stock_id,
        provider_id: contactFields.provider_id,
        payment_detail_id: contactFields.payment_detail_id
    }

    const paymentData = {
        amount: contactFields.payment_amount,
        balance_id: contactFields.balance_id,
        currency_id: contactFields.currency_id,
        exchange_rate: "1",
        name: contactFields.payment_name,
        payment_date: new Date(),
        payment_method_id: 2,
        status: "4",
    }

    const toNextStep = async () => {
        if(validationFields()) {
            let createContactRes;
            if(!createdEntities.contact) {
                createContactRes = await createContactService({ data: contactData })
                if(createContactRes.status !== 201) {
                    Notif("Error", "Что-то пошло не так.")
                    return false;
                }
                Notif("save", "Контакт создан")
                setCreatedEntities({ ...createdEntities, contact: createContactRes.data })
            }


            const createInvoice = await createInvoiceService({ data:
                    { ...invoiceData, contact_id: createdEntities.contact ? createdEntities.contact.id : createContactRes.data.id }
            })
            if(createInvoice.status !== 201) {
                Notif("Error", "Что-то пошло не так.")
                return false;
            }
            Notif("save", "Счет создан");
            setCreatedEntities({ ...createdEntities, invoice: createInvoice.data })

            setActiveStep("second");
        }

    }

    const saveResult = async () => {
        let createPayment;
        const entities = {}
        if(contactFields.payment_amount) {
            createPayment = await createPaymentService({ inv_id: createdEntities.invoice.id, data: paymentData })
            if(createPayment.status !== 200) {
                Notif("Error", "Что-то пошло не так.")
                return false;
            }
            entities.payment = createPayment.data;
            Notif("save", "Платеж создан");
        }

        let createShipment;
        if(contactFields.isCreateShipment) {
            createShipment = await createInvoiceShipmentService({ inv_id: createdEntities.invoice.id });
            if(createShipment.status !== 201) {
                Notif("Error", "Что-то пошло не так.")
                return false;
            }
            entities.shipment = createShipment.data
        }
        await setCreatedEntities({ ...createdEntities, ...entities })
        setIsFinish(true);
    }

    const propsData = {
        contactFields,
        setContactFields,
        errors,
        entities,
        toNextStep,
        updateData,
        activeStep,
        createdEntities,
        setCreatedEntities,
        saveResult,
        close,
        isFinish
    }

    return (
        <CenterModalContainer isOpen={isOpen} close={close} title={'test'} isDisabledModal>
            <div>
                <CSSTransition in={activeStep === "first"} timeout={200} classNames="slide" unmountOnExit>
                    <CommonModalView { ...propsData } />
                </CSSTransition>
                <CSSTransition in={activeStep === "second"} timeout={200} classNames="slide" unmountOnExit>
                    <SecondStepView {...propsData} />
                </CSSTransition>
            </div>

        </CenterModalContainer>
    )
}